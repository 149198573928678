import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderBase } from '../order.base';
import { Order } from '../order';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-order-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './order-display.component.pug',
  styleUrls: ['./order-display.component.sass'],
})
export class OrderDisplayComponent implements OnInit {
  @Input() public order?: OrderBase | Order;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<OrderBase>;

  constructor() {}

  ngOnInit(): void {
    console.log('Detail route', this.detail_route);
  }
}
