import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Orderprocessstorage } from '../../../../models/orders/orderprocessstorage/orderprocessstorage';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';

export interface OrderprocessstorageDetailViewParams extends BaseRouteParams {
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  orderprocess_detail_route: RouteConfigItem;
  orderprocessstorage_detail_route: RouteConfigItem;
}

export interface OrderprocessstorageDetailViewData extends OrderprocessstorageDetailViewParams {
  orderprocessstorage: Orderprocessstorage;
  orderprocess?: Orderprocess;
}
