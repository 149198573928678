import { Component } from '@angular/core';

import { map, Observable, Subject } from 'rxjs';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { TariftypeMetadataListComponent } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata-list/tariftype-metadata-list.component';
import { TariftypeMetadataCreateComponent } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata-create/tariftype-metadata-create.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TariftypeDocument } from '../../../../models/tarifs/tariftype-document/tariftype-document';
import { TariftypeMetadataBase } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata.base';
import { RI } from '../../../../constants';
import { TariftypeDocumentManageComponent } from '../../../../models/tarifs/tariftype-document/tariftype-document-manage/tariftype-document-manage.component';

export interface TariftypeDocumentDetailViewParams extends BaseRouteParams {
  tariftypemetadata_detail_route: RouteConfigItem;
}

export interface TariftypeDocumentDetailViewData extends TariftypeDocumentDetailViewParams {
  tariftypedocument: TariftypeDocument;
}

@Component({
  selector: 'lvadg-tariftype-document-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    TariftypeDocumentManageComponent,
    TariftypeMetadataListComponent,
    TariftypeMetadataCreateComponent,
  ],
  templateUrl: './tariftype-document-detail-view.component.pug',
  styleUrls: ['./tariftype-document-detail-view.component.sass'],
})
export class TariftypeDocumentDetailViewComponent extends BaseDetailViewComponent<
  TariftypeDocumentDetailViewData,
  TariftypeDocument
> {
  public reload$ = new Subject<void | boolean>();
  public tariftypeDocumentFilter$!: Observable<FilterDefaults>;
  public RI = RI;
  public createMetadata: boolean = false;
  public tariftypemetadata_detail_route?: Link<TariftypeMetadataBase>;

  public override setRouteData(data: TariftypeDocumentDetailViewData) {
    super.setRouteData(data);
    this.tariftypemetadata_detail_route = new Link(data.tariftypemetadata_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tariftypeDocumentFilter$ = this.data$.pipe(map((d) => ({ document: d.tariftypedocument.id })));
  }
}
