import {
  BaseRouteParams,
  RData,
  RouteConfigItem,
  RoutesDefinition,
} from "@solidev/data";
import { Offer } from "../../../../models/offers/offer/offer";
import { Offertype } from "../../../../models/offers/offertype/offertype";
import { OffertypeStorage } from "../../../../models/offers/offertype-storage/offertype-storage";
import { RI, RS } from "../../../../constants";
import {
  OfferEditViewComponent,
  OfferEditViewParams,
} from "./edit/offer-edit-view.component";
import {
  OfferArticlesViewComponent,
  OfferArticlesViewParams,
} from "./articles/offer-articles-view.component";
import {
  OfferDocumentsViewComponent,
  OfferDocumentsViewParams,
} from "./documents/offer-documents-view.component";
import {
  OfferDestinationsViewComponent,
  OfferNotificationDestinationsViewParams,
} from "./destinations/offer-destinations-view.component";
import {
  OfferNotificationsViewComponent,
  OfferNotificationsViewParams,
} from "./notifications/offer-notifications-view.component";
import { OfferNotificationsStatusesViewComponent } from "./notifications-statuses/offer-notifications-statuses-view.component";
import {
  OfferProductsViewComponent,
  OfferProductsViewParams,
} from "./products/offer-products-view.component";
import {
  OfferPrintPreviewViewComponent,
  OfferPrintPreviewViewParams,
} from "./print-preview/offer-print-preview-view.component";
import {
  OfferRestosViewComponent,
  OfferRestosViewParams,
} from "./restos/offer-restos-view.component";
import {
  OfferDisplayViewComponent,
  OfferDisplayViewParams,
} from "./display/offer-display-view.component";
import {
  OfferTextsViewComponent,
  OfferTextsViewParams,
} from "./texts/offer-texts-view.component";

export interface OFFER_ROUTES {
  offer_detail_route?: RouteConfigItem;
  offer_edit_route?: RouteConfigItem;
  offer_documents_route?: RouteConfigItem;
  offer_print_preview_route?: RouteConfigItem;
  offer_notifications_route?: RouteConfigItem;
  offer_destinations_route?: RouteConfigItem;
  offer_notification_statuses_route?: RouteConfigItem;
  offer_products_route?: RouteConfigItem;
  offer_articles_route?: RouteConfigItem;
  offer_restos_route?: RouteConfigItem;
  offer_texts_route?: RouteConfigItem;
}

export interface OFFER_LINKS_ROUTES {
  product_detail_route: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
  producer_detail_route?: RouteConfigItem;
  contact_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
}

export interface OfferViewParams extends BaseRouteParams {
  o_menu: OFFER_ROUTES;
}

export interface OfferViewData extends OfferViewParams {
  offer: Offer;
  offertype?: Offertype;
  offertypestorage?: OffertypeStorage;
}

export const gen_offer_detail_routes = (
  routes: OFFER_ROUTES,
  link_routes: OFFER_LINKS_ROUTES,
  default_route: RouteConfigItem,
): RoutesDefinition => {
  const gen_routes: RoutesDefinition = [];
  if (routes.offer_detail_route) {
    gen_routes.push({
      path: "",
      pathMatch: "full",
      data: RData<OfferDisplayViewParams>({
        skip: true,
        route: routes.offer_detail_route || default_route,
        o_menu: routes,
        icon: RI.offer,
      }),
      component: OfferDisplayViewComponent,
    });
  }
  if (routes.offer_edit_route) {
    gen_routes.push({
      path: RS.edit,
      data: RData<OfferEditViewParams>({
        title: "Édition",
        route: routes.offer_edit_route || default_route,
        o_menu: routes,
        icon: RI.edit,
      }),
      component: OfferEditViewComponent,
    });
  }
  if (routes.offer_documents_route) {
    gen_routes.push({
      path: RS.documents,
      data: RData<OfferDocumentsViewParams>({
        title: "Documents",
        route: routes.offer_documents_route || default_route,
        o_menu: routes,
        icon: RI.document,
      }),
      component: OfferDocumentsViewComponent,
    });
  }
  if (routes.offer_print_preview_route) {
    gen_routes.push({
      path: RS.print,
      data: RData<OfferPrintPreviewViewParams>({
        title: "Mise en page",
        route: routes.offer_print_preview_route || default_route,
        o_menu: routes,
        icon: RI.print,
      }),
      component: OfferPrintPreviewViewComponent,
    });
  }
  if (routes.offer_notifications_route) {
    gen_routes.push({
      path: RS.offernotifications,
      data: RData<OfferNotificationsViewParams>({
        title: "Notifications",
        route: routes.offer_notifications_route || default_route,
        o_menu: routes,
        icon: RI.contact,
      }),
      component: OfferNotificationsViewComponent,
    });
  }
  if (routes.offer_destinations_route) {
    gen_routes.push({
      path: RS.offernotificationdestinations,
      data: RData<OfferNotificationDestinationsViewParams>({
        title: "Destinations",
        route: routes.offer_destinations_route || default_route,
        o_menu: routes,
        contact_detail_route: link_routes.contact_detail_route,
        user_detail_route: link_routes.user_detail_route,
        client_detail_route: link_routes.client_detail_route,
        storage_detail_route: link_routes.storage_detail_route,
        group_detail_route: link_routes.group_detail_route,
        resto_detail_route: link_routes.resto_detail_route,
        icon: RI.contact,
      }),
      component: OfferDestinationsViewComponent,
    });
  }
  if (routes.offer_notification_statuses_route) {
    gen_routes.push({
      path: RS.offernotificationstatuses,
      data: RData<OfferNotificationsViewParams>({
        title: "Statuts des notifications",
        route: routes.offer_notification_statuses_route || default_route,
        o_menu: routes,
        icon: RI.contact,
      }),
      component: OfferNotificationsStatusesViewComponent,
    });
  }
  if (routes.offer_products_route) {
    gen_routes.push({
      path: RS.products,
      data: RData<OfferProductsViewParams>({
        title: "Produits",
        route: routes.offer_products_route || default_route,
        product_detail_route: link_routes.product_detail_route,
        family_detail_route: link_routes.family_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        o_menu: routes,
        icon: RI.product,
      }),
      component: OfferProductsViewComponent,
    });
  }
  if (routes.offer_articles_route) {
    gen_routes.push({
      path: RS.articles,
      data: RData<OfferArticlesViewParams>({
        title: "Articles",
        route: routes.offer_articles_route || default_route,
        o_menu: routes,
        icon: RI.catalog,
      }),
      component: OfferArticlesViewComponent,
    });
  }
  if (routes.offer_restos_route) {
    gen_routes.push({
      path: RS.restos,
      data: RData<OfferRestosViewParams>({
        title: "Restos",
        route: routes.offer_restos_route || default_route,
        o_menu: routes,
        icon: RI.resto,
      }),
      component: OfferRestosViewComponent,
    });
  }
  if (routes.offer_texts_route) {
    gen_routes.push({
      path: RS.texts,
      data: RData<OfferTextsViewParams>({
        title: "Textes",
        route: routes.offer_texts_route || default_route,
        o_menu: routes,
        icon: RI.texts,
      }),
      component: OfferTextsViewComponent,
    });
  }
  return gen_routes;
};
