export interface MeteoFull {
  "type": "FeatureCollection";
  "features": MeteoFeature[];
}

export interface MeteoFeature {
  "type": "Feature";
  "geometry": {
    "type": "Point",
    "coordinatres": [number, number]
  };
  "properties": {
    name: string,
    insee: string,
    country: string,
    altitude: number,
    forecast: Forecast[];
    french_department: string;
    rain_product_available: number;
    timezone: string;
    bulletin_cote: number;
    daily_forecast: DailyForecast[];
  };
  update_time: Date;
}

interface Forecast {
  T: number;
  iso0: number;
  time: Date;
  P_sea: number;
  rain_1h: number | null;
  rain_3h: number | null;
  rain_6h: number | null;
  rain_12h: number | null;
  rain_24h: number | null;
  snow_1h: number | null;
  snow_3h: number | null;
  snow_6h: number | null;
  snow_12h: number | null;
  snow_24: number | null;
  wind_icon: string;
  moment_day: "soirée" | "nuit" | "matin" | "après-midi";
  wind_speed: number | null;
  T_windchill: number;
  weather_icon: string;
  wind_direction: number;
  rain_snow_limit: "Non pertinent" | number;
  wind_speed_gust: number;
  relative_humidity: number;
  total_cloud_cover: number;
  weather_description: string;
  weather_confidence_index: number;
}


interface DailyForecast {
  time: Date;
  T_max: number;
  T_min: number;
  T_sea: number | null;
  uv_index: number;
  sunset_time: Date;
  sunrise_time: Date;
  daily_weather_icon: "p2j";
  relative_humidity_max: number;
  relative_humidity_min: number;
  total_precipitation_24h: number;
  daily_weather_description: string;
}
