
<div class="tab-content">
  <div class="tab-title">
    <h1>
      <lvadg-icon ri="provider">Fournisseurs</lvadg-icon>
    </h1>
    <div class="actions">
      <button class="btn btn-primary" (click)="create=!create">
        <lvadg-icon ri="add">Ajouter un fournisseur</lvadg-icon>
      </button>
    </div>
  </div>
</div>
<div class="card my-3" *ngIf="create">
  <div class="card-header">
    <lvadg-icon ri="add">Ajouter un fournisseur</lvadg-icon>
  </div>
  <div class="card-body">
    <lvadg-provider-create (cancelled)="create=false" (created)="reload$.next(true); create=false; detail_route.navigate({provider: $event})"></lvadg-provider-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-provider-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [reload]="reload$" [dispMap]="'local'"></lvadg-provider-list>
</ng-container>