
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-offer-product-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-product-create.component.pug',
  styleUrls: ['./offer-product-create.component.sass']
})
export class OfferProductCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
