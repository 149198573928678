import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifBase } from '../tarif.base';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-tarif-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './tarif-display.component.pug',
  styleUrls: ['./tarif-display.component.sass'],
})
export class TarifDisplayComponent {
  @Input() public tarif?: TarifBase;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<TarifBase>;
  @Input() public classes: Record<string, boolean> = {};

  constructor() {}
}
