import {
  Component,
  inject,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Storage } from "../../../../../models/structures/storage/storage";
import { STORAGE_ROUTES } from "../menu";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProductListComponent } from "../../../../../models/catalog/product/product-list/product-list.component";
import { StorageNavComponent } from "../_nav/storage-nav.component";
import { map, Observable, Subject } from "rxjs";
import { ProducerBase } from "../../../../../models/structures/producer/producer.base";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
  ProductBase,
} from "../../../../../models/catalog/product/product.base";
import {
  STORAGE_USER_LEVELS,
  StorageBase,
} from "../../../../../models/structures/storage/storage.base";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { ProductCreateComponent } from "../../../../../models/catalog/product/product-create/product-create.component";
import {
  PrintContext,
  PrintSettings,
} from "../../../../../models/layouts/print-settings";
import { PRODUCT_PRINT_SETTINGS } from "../../../../../models/catalog/product/product.print";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";

export interface StorageProductsViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
  producer_detail_route?: RouteConfigItem;
  product_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  local: LOCAL_FILTERS;
  type?: PRODUCT_TYPES;
}

export interface StorageProductsViewData extends StorageProductsViewParams {
  storage: Storage;
}

@Component({
  selector: "lvadg-storage-products-view",
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    HeaderActionComponent,
    ProductListComponent,
    ProductCreateComponent,
    StorageNavComponent,
  ],
  templateUrl: "./storage-products-view.component.pug",
  styleUrls: ["./storage-products-view.component.sass"],
})
export class StorageProductsViewComponent extends BaseDetailViewComponent<
  StorageProductsViewData,
  Storage
> {
  public storageFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public producer_detail_route?: Link<ProducerBase>;
  public product_detail_route?: Link<ProductBase>;
  public storage_detail_route?: Link<StorageBase>;
  public default_fields: string[] = [
    "name",
    "labels_details",
    "seasons",
    "producer_details",
    "storage_relations_details",
    "actions",
  ];
  public printSettings: PrintSettings = PRODUCT_PRINT_SETTINGS;
  public printContext: PrintContext = {};

  // Header settings
  public subtitle = signal("Produits");
  // Create settings
  public canCreate = signal<boolean>(false);
  public ofc$ = inject(NgbOffcanvas);
  public createOfcRef?: NgbOffcanvasRef;
  @ViewChild("createOfcSlot", { read: TemplateRef })
  createOfcSlot!: TemplateRef<unknown>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageProductsViewData) {
    super.setRouteData(data);
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(
        data.producer_detail_route,
        data,
        this._router,
      );
    }
    if (data.product_detail_route) {
      this.product_detail_route = new Link(
        data.product_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(
        data.storage_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage) {
      this.canCreate.set(data.storage.have_level(STORAGE_USER_LEVELS.ADMIN));
      this.printContext.storage = data.storage;
    }
    if (data.local === LOCAL_FILTERS.LOCAL_CONTEXT) {
      this.subtitle.set("Produits locaux");
      this.printContext.local = data.local;
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.storage.id,
        local: data.local,
      })),
    );
  }

  public create() {
    if (this.createOfcRef) {
      this.createOfcRef.close();
      delete this.createOfcRef;
    } else {
      this.createOfcRef = this.ofc$.open(this.createOfcSlot, {
        position: "bottom",
        panelClass: "h-75",
      });
    }
  }
}
