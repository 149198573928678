import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferViewData, OfferViewParams } from "../menu";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Offer } from "../../../../../models/offers/offer/offer";
import {
  DataMessageService,
  FilterDefaults,
  TabMemoryService,
} from "@solidev/data";
import { map, Observable, Subject } from "rxjs";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { OfferRestoListComponent } from "../../../../../models/offers/offer-resto/offer-resto-list/offer-resto-list.component";
import { OfferResto } from "../../../../../models/offers/offer-resto/offer-resto";
import { Contact } from "../../../../../models/users/contact/contact";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OfferActionService } from "../../../../../models/offers/offer/offer-action.service";
import { OFFER_USER_LEVEL } from "../../../../../models/offers/offer/offer.base";
import { RestoListComponent } from "../../../../../models/structures/resto/resto-list/resto-list.component";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { Resto } from "../../../../../models/structures/resto/resto";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";

export interface OfferRestosViewParams extends OfferViewParams {}

export interface OffertRestosViewData
  extends OfferViewData,
    OfferRestosViewParams {}

@Component({
  selector: "lvadg-offer-restos-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OfferRestoListComponent,
    RestoListComponent,
    OfferNavComponent,
    IconComponent,
    TodoComponent,
  ],
  templateUrl: "./offer-restos-view.component.pug",
  styleUrls: ["./offer-restos-view.component.sass"],
})
export class OfferRestosViewComponent extends BaseDetailViewComponent<
  OffertRestosViewData,
  Offer
> {
  public offer!: Offer;
  public restoFilter$!: Observable<FilterDefaults>;
  public restoAddFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields: string[] = [
    "resto_details",
    "resto_client_details",
    "resto_location_details",
    "storage_resto_relation_details",
    "status",
    "actions",
  ];
  public resto_add_default_fields: string[] = [
    "cvva",
    "name",
    "type",
    "client_details",
    "location_details",
    "storage_relation_details",
    "status",
    "actions",
  ];

  public readonly OUL = OFFER_USER_LEVEL;

  @ViewChild("ofcslot_add", { static: false })
  public ofcSlotAdd!: ElementRef<HTMLDivElement>;
  public ofcInstanceAdd?: NgbOffcanvasRef;
  @ViewChild("ofcslot_remove", { static: false })
  public ofcSlotRemove!: ElementRef<HTMLDivElement>;
  public ofcInstanceRemove?: NgbOffcanvasRef;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _oa: OfferActionService,
    private _ofc: NgbOffcanvas,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.restoFilter$ = this.data$.pipe(
      map((data) => {
        return {
          offer: data.offer.id,
        };
      }),
    );
    this.restoAddFilter$ = this.data$.pipe(
      map((data) => {
        return {
          for_offer: data.offer.id,
        };
      }),
    );
  }

  public override setRouteData(data: OffertRestosViewData) {
    super.setRouteData(data);
    this.offer = data.offer;
  }

  public async onOfferRestoAction(
    offer: Offer,
    $event: ModelListAction<OfferResto, Contact>,
  ) {
    if ($event.action === "delete" && $event.model) {
      await this._oa.action(offer, {
        action: "remove_resto",
        offerresto: $event.model.id,
      });
      this.reload$.next(true);
    } else if ($event.action === "toggle_status" && $event.model) {
      await this._oa.action(offer, {
        action: "toggle_resto_status",
        offerresto: $event.model.id,
      });
      this.reload$.next(true);
    }
  }

  public async syncRestos() {
    await this._oa.action(this.offer, {
      action: "sync_restos",
    });
    this.reload$.next(true);
  }

  public async openRemoveAll() {
    this.ofcInstanceRemove = this._ofc.open(this.ofcSlotRemove, {
      position: "end",
    });
  }

  public async processRemoveAll() {
    await this._oa.action(this.offer, {
      action: "remove_all_restos",
    });
    this.reload$?.next(true);
    this.ofcInstanceRemove?.dismiss();
  }

  public async addResto() {
    if (!this.offer.client) {
      this._msgs.error(
        "L'ajout d'un restaurant n'est possible que pour les offres clients",
      );
      return;
    }
    this.ofcInstanceAdd = this._ofc.open(this.ofcSlotAdd, {
      panelClass: "w-75",
      position: "end",
    });
  }

  public async onRestoAddAction($event: ModelListAction<Resto>) {
    if ($event.action === "offer_add" && $event.model) {
      await this._oa.action(this.offer, {
        action: "add_resto",
        resto: $event.model.id,
      });
    }
    this.reload$.next(true);
  }
}
