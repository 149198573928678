import { Injectable } from "@angular/core";
import { Offer } from "./offer";
import { OpLog } from "../../../components/utils/oplog/oplog";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";
import { OffertypeActionResult } from "../offertype/offertype-action.service";
import { OFFERNOTIFICATION_TYPES } from "../offer-notification/offer-notification.base";
import { Contact } from "../../users/contact/contact";
import {
  OFFERNOTIFICATIONDESTINATION_TARGET,
  OFFERNOTIFICATIONDESTINATION_TYPES,
} from "../offer-notification-destination/offer-notification-destination.base";

interface OfferAddProductLink {
  action: "add_product_link";
  offerproduct: number;
  article?: number;
  earticle?: number;
  att?: number;
  at?: number;
}

interface OfferAddTarif {
  action: "add_tarif";
  tarif: number;
}

interface OfferRemoveTarif {
  action: "remove_tarif";
  tarif: number;
}

interface OfferRemoveProductLink {
  action: "remove_product_link";
  link: number;
}

interface OfferAddProduct {
  action: "add_product";
  product: number;
}

interface OfferRemoveProduct {
  action: "remove_product";
  offerproduct: number;
}

interface OfferRemoveAllProducts {
  action: "remove_all_products";
}

interface OfferImportProducts {
  action: "import_products";
  source: number;
}

interface OfferImportTexts {
  action: "import_texts";
  source: number;
}

interface OfferSyncRestos {
  action: "sync_restos";
}

interface OfferToggleRestoStatus {
  action: "toggle_resto_status";
  offerresto: number;
}

interface OfferRemoveAllRestos {
  action: "remove_all_restos";
}

interface OfferAddResto {
  action: "add_resto";
  resto: number;
}

interface OfferRemoveResto {
  action: "remove_resto";
  offerresto: number;
}

interface OfferCreateLayout {
  action: "create_layout";
}

interface OfferLaunchPrint {
  action: "launch_print";
}

export interface OfferAddDestination {
  action: "add_offer_destination";
  type: OFFERNOTIFICATIONDESTINATION_TYPES;
  target: OFFERNOTIFICATIONDESTINATION_TARGET;
  new_contact?: Contact;
  resto_id?: number;
  contact_id?: number;
  user_id?: number;
  group_id?: number;
}

interface OfferRemoveDestination {
  action: "remove_offer_destination";
  destination_id: number;
  full: "true" | "false";
}

interface OfferToggleDestination {
  action: "toggle_offer_destination";
  destination_id: number;
}

interface OfferCreateNotification {
  action: "create_notification";
  type: OFFERNOTIFICATION_TYPES;
  title: string;
  message: string;
}

interface OfferSendNotification {
  action: "send_notification";
  offernotification: number;
}

interface OfferCancelNotification {
  action: "cancel_notification";
  offernotification: number;
}

interface OfferUpdateNotificationDestinations {
  action: "update_notification_destinations";
}

interface OfferSyncTexts {
  action: "sync_texts";
}

interface OfferAddFreeText {
  action: "add_free_text";
  position: number;
  title: string;
  text: string;
}

interface OfferEditFreeText {
  action: "edit_free_text";
  offertext: number;
  title: string;
  text: string;
}

interface OfferDeleteText {
  action: "delete_text";
  offertext: number;
}

interface OfferMoveText {
  action: "move_text";
  offertext: number;
  position: number;
}

interface OfferGetAvailableTexts {
  action: "get_available_texts";
}

interface OfferSelectVariantText {
  action: "select_variant_text";
  offertext: number;
  offertype_texttype_text: number;
}

interface OfferAddPredefinedText {
  action: "add_predefined_text";
  position: number;
  offertype_texttype_text: number;
}

interface OfferPublish {
  action: "publish";
}

interface OfferUnpublish {
  action: "unpublish";
}

export type OfferActionParams =
  | OfferAddProductLink
  | OfferRemoveProductLink
  | OfferAddTarif
  | OfferRemoveTarif
  | OfferAddProduct
  | OfferRemoveProduct
  | OfferRemoveAllProducts
  | OfferImportProducts
  | OfferSyncRestos
  | OfferRemoveAllRestos
  | OfferToggleRestoStatus
  | OfferAddResto
  | OfferRemoveResto
  | OfferCreateLayout
  | OfferLaunchPrint
  | OfferCreateNotification
  | OfferSendNotification
  | OfferCancelNotification
  | OfferUpdateNotificationDestinations
  | OfferAddDestination
  | OfferRemoveDestination
  | OfferToggleDestination
  | OfferSyncTexts
  | OfferAddFreeText
  | OfferEditFreeText
  | OfferDeleteText
  | OfferMoveText
  | OfferImportTexts
  | OfferGetAvailableTexts
  | OfferSelectVariantText
  | OfferAddPredefinedText
  | OfferPublish
  | OfferUnpublish;

export interface OfferActionResult<T> {
  offer?: Offer;
  log?: OpLog;
  data?: T;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: "root",
})
export class OfferActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action<T>(
    o: Offer,
    action: OfferActionParams,
    messages: Record<string, string> = {},
  ): Promise<OfferActionResult<T>> {
    try {
      const res = await firstValueFrom(
        o.action<Offer, OffertypeActionResult<T>>("POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"] && messages["success"].length > 0) {
        this._msgs.success(messages["success"]);
      } else if (res.message && res.message.length > 0) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { offer: o, message: message, success: false };
    }
  }
}
