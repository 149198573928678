
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-article-similarity-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-similarity-display.component.pug',
  styleUrls: ['./article-similarity-display.component.sass']
})
export class ArticleSimilarityDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
