import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Iocode } from '../iocode';

@Component({
  selector: 'lvadg-iocode-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './iocode-display.component.pug',
  styleUrls: ['./iocode-display.component.sass'],
})
export class IocodeDisplayComponent {
  @Input() iocode?: Iocode;
  @Input() public mode: 'code' | 'link' | 'qr' = 'code';
  @Input() public qrsize: 'line' | 'small' | 'medium' | 'large' = 'medium';
}
