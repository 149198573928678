import { Component } from '@angular/core';

import { OrderprocessstoragePermissionListViewData } from './orderprocessstorage-permission-list-view.component.params';
import { Subject } from 'rxjs';
import { Link } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import {
  OrderprocessstoragePermissionListComponent
} from "../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission-list/orderprocessstorage-permission-list.component";
import {
  OrderprocessstoragePermissionCreateComponent
} from "../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission-create/orderprocessstorage-permission-create.component";
import {BaseListViewComponent} from "../../../../components/baseview/baselistview.component";
import {
  OrderprocessstoragePermission
} from "../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission";
import {UserBase} from "../../../../models/users/user/user.base";
import {GroupBase} from "../../../../models/users/group/group.base";
import {OrderprocessBase} from "../../../../models/orders/orderprocess/orderprocess.base";
import {OrderprocessstorageBase} from "../../../../models/orders/orderprocessstorage/orderprocessstorage.base";

@Component({
  selector: 'lvadg-orderprocessstorage-permission-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    OrderprocessstoragePermissionListComponent,
    OrderprocessstoragePermissionCreateComponent,
  ],
  templateUrl: './orderprocessstorage-permission-list-view.component.pug',
  styleUrls: ['./orderprocessstorage-permission-list-view.component.sass'],
})
export class OrderprocessstoragePermissionListViewComponent extends BaseListViewComponent<
  OrderprocessstoragePermissionListViewData,
  OrderprocessstoragePermission
> {
  public create: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public orderprocess_detail_route!: Link<OrderprocessBase>;
  public orderprocessstorage_detail_route!: Link<OrderprocessstorageBase>;

  public override setRouteData(data: OrderprocessstoragePermissionListViewData) {
    super.setRouteData(data);
    this.user_detail_route = new Link(data.user_detail_route, this);
    this.group_detail_route = new Link(data.group_detail_route, this);
    this.orderprocess_detail_route = new Link(data.orderprocess_detail_route, this);
    this.orderprocessstorage_detail_route = new Link(data.orderprocessstorage_detail_route, this);
  }
}
