
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { DocumentPermission } from "./document-permission";

@Injectable({
  providedIn: 'root'
})
export class DocumentPermissionService extends Collection<DocumentPermission> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/documentpermissions", DocumentPermission);
  }
}
