
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OfferResto } from "./offer-resto";

@Injectable({
  providedIn: 'root'
})
export class OfferRestoService extends Collection<OfferResto> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offerrestos", OfferResto);
  }
}
