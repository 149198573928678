import { RoutesConfig } from '@solidev/data';
import {
  ArticleBase,
  ArticleTarifTemplate,
  ArticleTarifTemplateRelation,
  Earticle,
  FamilyBase,
  RS,
  Tarif,
  TarifDocumentBase,
  TarifLifecycleStepBase,
  TarifNotification,
  TarifNotificationDestination,
  TarifNotificationStatus,
  TarifRelation,
  Tariftype,
  TariftypeDocument,
  TariftypeDocumentBase,
  TariftypeMetadata,
  TariftypePermissionBase,
  TariftypeRegionBase,
  TariftypeRegionPermissionBase,
  TariftypeRelation,
  TariftypeRelationBase,
  TariftypeStorageBase,
  TariftypeStoragePermissionBase,
} from '@vivalya/lvadg';

export const ADMIN_TARIFS_ROUTES_NAMES = [
  'tarifs',
  'tarifs_articletariftemplaterelations',
  'tarifs_articletariftemplaterelations_articletariftemplaterelation',
  'tarifs_articletariftemplates',
  'tarifs_articletariftemplates_articletariftemplate',
  'tarifs_lifecyclesteps',
  'tarifs_lifecyclesteps_lifecyclestep',
  'tarifs_stats',
  'tarifs_stats_articles',
  'tarifs_stats_articles_article',
  'tarifs_stats_families',
  'tarifs_stats_families_family',
  'tarifs_tarifdocuments',
  'tarifs_tarifdocuments_tarifdocument',
  'tarifs_tarifnotificationdestinations',
  'tarifs_tarifnotificationdestinations_tarifnotificationdestination',
  'tarifs_tarifnotifications',
  'tarifs_tarifnotifications_tarifnotification',
  'tarifs_tarifnotificationstatuses',
  'tarifs_tarifnotificationstatuses_tarifnotificationstatus',
  'tarifs_tarifrelations',
  'tarifs_tarifrelations_tarifrelation',
  'tarifs_tarifs',
  'tarifs_tariftypedocuments',
  'tarifs_tariftypedocuments_tariftypedocument',
  'tarifs_tariftypemetadatas',
  'tarifs_tariftypemetadatas_tariftypemetadata',
  'tarifs_tariftypepermissions',
  'tarifs_tariftypepermissions_tariftypepermission',
  'tarifs_tariftyperegionpermissions',
  'tarifs_tariftyperegionpermissions_tariftyperegionpermission',
  'tarifs_tariftyperegions',
  'tarifs_tariftyperegions_tariftyperegion',
  'tarifs_tariftyperelations',
  'tarifs_tariftyperelations_tariftyperelation',
  'tarifs_tariftypes',
  'tarifs_tariftypes_tariftype',
  'tarifs_tariftypes_tariftype_articles',
  'tarifs_tariftypes_tariftype_articletariftemplate',
  'tarifs_tariftypes_tariftype_articletariftemplates',
  'tarifs_tariftypes_tariftype_articletariftemplates_articletariftemplate',
  'tarifs_tariftypes_tariftype_articletariftemplates_earticle',
  'tarifs_tariftypes_tariftype_catalog',
  'tarifs_tariftypes_tariftype_destinations',
  'tarifs_tariftypes_tariftype_documents',
  'tarifs_tariftypes_tariftype_documents_document',
  'tarifs_tariftypes_tariftype_documents_document_metadata',
  'tarifs_tariftypes_tariftype_lifecycle',
  'tarifs_tariftypes_tariftype_manage',
  'tarifs_tariftypes_tariftype_mappings',
  'tarifs_tariftypes_tariftype_notification_logs',
  'tarifs_tariftypes_tariftype_notifications',
  'tarifs_tariftypes_tariftype_permissions',
  'tarifs_tariftypes_tariftype_prices_logs',
  'tarifs_tariftypes_tariftype_regions',
  'tarifs_tariftypes_tariftype_relations',
  'tarifs_tariftypes_tariftype_relations_relation',
  'tarifs_tariftypes_tariftype_storages',
  'tarifs_tariftypes_tariftype_tarif',
  'tarifs_tariftypes_tariftype_tarifs',
  'tarifs_tariftypes_tariftype_tarifs_logs',
  'tarifs_tariftypes_tariftype_tarifs_tarif',
  'tarifs_tariftypes_tariftype_tarifs_tarif_documents',
  'tarifs_tariftypes_tariftype_tarifs_tarif_lifecycle',
  'tarifs_tariftypes_tariftype_tarifs_tarif_lines',
  'tarifs_tariftypes_tariftype_tarifs_tarif_manage',
  'tarifs_tariftypes_tariftype_tarifs_tarif_notifications',
  'tarifs_tariftypes_tariftype_tarifs_tarif_notifications_logs',
  'tarifs_tariftypes_tariftype_tarifs_tarif_relations',
  'tarifs_tariftypestoragepermissions',
  'tarifs_tariftypestoragepermissions_tariftypestoragepermission',
  'tarifs_tariftypestorages',
  'tarifs_tariftypestorages_tariftypestorage',
] as const;

export const ADMIN_TARIFS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_TARIFS_ROUTES_NAMES)[number]> = {
  tarifs: {
    params: [],
    route: () => ['/', RS.tarifs],
    name: 'tarifs',
  },
  tarifs_articletariftemplaterelations: {
    params: [],
    route: () => ['/', RS.tarifs, RS.articletariftemplaterelations],
    name: 'tarifs_articletariftemplaterelations',
  },
  tarifs_articletariftemplaterelations_articletariftemplaterelation: {
    params: ['articletariftemplaterelation'],
    route: (attr: ArticleTarifTemplateRelation) => ['/', RS.tarifs, RS.articletariftemplaterelations, attr.id],
    name: 'tarifs_articletariftemplaterelations_articletariftemplaterelation',
  },
  tarifs_articletariftemplates: {
    params: [],
    route: () => ['/', RS.tarifs, RS.articletariftemplates],
    name: 'tarifs_articletariftemplates',
  },
  tarifs_articletariftemplates_articletariftemplate: {
    params: ['articletariftemplate'],
    route: (att: ArticleTarifTemplate) => ['/', RS.tarifs, RS.articletariftemplates, att.id],
    name: 'tarifs_articletariftemplates_articletariftemplate',
  },
  tarifs_lifecyclesteps: {
    params: [],
    route: () => ['/', RS.tarifs, RS.lifecyclesteps],
    name: 'tarifs_lifecyclesteps',
  },
  tarifs_lifecyclesteps_lifecyclestep: {
    params: ['tariflifecyclestep'],
    route: (ls: TarifLifecycleStepBase) => ['/', RS.tarifs, RS.lifecyclesteps, ls.id],
    name: 'tarifs_lifecyclesteps',
  },
  tarifs_stats: {
    params: [],
    route: () => ['/', RS.tarifs, RS.stats],
    name: 'tarifs_stats',
  },
  tarifs_stats_articles: {
    params: [],
    route: () => ['/', RS.tarifs, RS.stats, RS.articles],
    name: 'tarifs_stats_articles',
  },
  tarifs_stats_articles_article: {
    params: ['article'],
    route: (article: ArticleBase) => ['/', RS.tarifs, RS.stats, RS.articles, article.id],
    name: 'tarifs_stats',
  },
  tarifs_stats_families: {
    params: [],
    route: () => ['/', RS.tarifs, RS.stats, RS.families],
    name: 'tarifs_stats_families',
  },
  tarifs_stats_families_family: {
    params: ['family'],
    route: (family: FamilyBase) => ['/', RS.tarifs, RS.stats, RS.families, family.id],
    name: 'tarifs_stats_families_family',
  },
  tarifs_tarifdocuments: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tarifdocuments],
    name: 'tarifs_tarifdocuments',
  },
  tarifs_tarifdocuments_tarifdocument: {
    params: ['tarifdocument'],
    route: (td: TarifDocumentBase) => ['/', RS.tarifs, RS.tarifdocuments, td.id],
    name: 'tarifs_tarifdocuments_tarifdocument',
  },
  tarifs_tarifnotificationdestinations: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tarifnotificationdestinations],
    name: 'tarifs_tarifnotificationdestinations',
  },
  tarifs_tarifnotificationdestinations_tarifnotificationdestination: {
    params: ['tarifnotificationdestination'],
    route: (tng: TarifNotificationDestination) => ['/', RS.tarifs, RS.tarifnotificationdestinations, tng.id],
    name: 'tarifs_tarifnotificationdestinations_tarifnotificationdestination',
  },
  tarifs_tarifnotifications: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tarifnotifications],
    name: 'tarifs_tarifnotifications',
  },
  tarifs_tarifnotifications_tarifnotification: {
    params: ['tarifnotification'],
    route: (tn: TarifNotification) => ['/', RS.tarifs, RS.tarifnotifications, tn.id],
    name: 'tarifs_tarifnotifications_tarifnotification',
  },
  tarifs_tarifnotificationstatuses: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tarifnotificationstatuses],
    name: 'tarifs_tarifnotificationstatuses',
  },
  tarifs_tarifnotificationstatuses_tarifnotificationstatus: {
    params: ['tarifnotificationstatus'],
    route: (tnstatus: TarifNotificationStatus) => ['/', RS.tarifs, RS.tarifnotificationstatuses, tnstatus.id],
    name: 'tarifs_tarifnotificationstatuses_tarifnotificationstatus',
  },
  tarifs_tarifrelations: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tarifrelations],
    name: 'tarifs_tarifrelations',
  },
  tarifs_tarifrelations_tarifrelation: {
    params: ['tarifrelation'],
    route: (tarifrelation: TarifRelation) => ['/', RS.tarifs, RS.tarifrelations, tarifrelation.id],
    name: 'tarifs_tarifrelations_tarifrelation',
  },
  tarifs_tarifs: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tarifs],
    name: 'tarifs_tarifs',
  },
  tarifs_tariftypedocuments: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tariftypedocuments],
    name: 'tarifs_tariftypedocuments',
  },
  tarifs_tariftypedocuments_tariftypedocument: {
    params: ['tariftypedocument'],
    route: (td: TariftypeDocumentBase) => ['/', RS.tarifs, RS.tariftypedocuments, td.id],
    name: 'tarifs_tarifdocuments_tarifdocument',
  },
  tarifs_tariftypemetadatas: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tariftypemetadatas],
    name: 'tarifs_tariftypemetadatas',
  },
  tarifs_tariftypemetadatas_tariftypemetadata: {
    params: ['tariftypemetadata'],
    route: (td: TariftypeMetadata) => ['/', RS.tarifs, RS.tariftypemetadatas, td.id],
    name: 'tarifs_tarifmetadatas_tarifmetadata',
  },
  tarifs_tariftypepermissions: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tariftypepermissions],
    name: 'tarifs_tariftypepermissions',
  },
  tarifs_tariftypepermissions_tariftypepermission: {
    params: ['tariftypepermission'],
    route: (ttp: TariftypePermissionBase) => ['/', RS.tarifs, RS.tariftypepermissions, ttp.id],
    name: 'tarifs_tariftypepermissions_tariftypepermission',
  },
  tarifs_tariftyperegionpermissions: {
    params: [],
    route: () => ['/', RS.tarifs, RS.regionpermissions],
    name: 'tarifs_tariftyperegionpermissions',
  },
  tarifs_tariftyperegionpermissions_tariftyperegionpermission: {
    params: ['tariftyperegionpermission'],
    route: (ttp: TariftypeRegionPermissionBase) => ['/', RS.tarifs, RS.regionpermissions, ttp.id],
    name: 'tarifs_tariftyperegionpermissions_tariftyperegionpermission',
  },
  tarifs_tariftyperegions: {
    params: [],
    route: () => ['/', RS.tarifs, RS.regions],
    name: 'tarifs_tariftyperegions',
  },
  tarifs_tariftyperegions_tariftyperegion: {
    params: ['tariftyperegion'],
    route: (ttp: TariftypeRegionBase) => ['/', RS.tarifs, RS.regions, ttp.id],
    name: 'tarifs_tariftyperegions_tariftyperegion',
  },
  tarifs_tariftyperelations: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tariftyperelations],
    name: 'tarifs_tariftyperelations',
  },
  tarifs_tariftyperelations_tariftyperelation: {
    params: ['tariftyperelation'],
    route: (tariftyperelation: TariftypeRelation) => ['/', RS.tarifs, RS.tariftyperelations, tariftyperelation.id],
    name: 'tarifs_tariftyperelations_tariftyperelation',
  },
  tarifs_tariftypes: {
    params: [],
    route: () => ['/', RS.tarifs, RS.tariftypes],
    name: 'tarifs_tariftypes',
  },
  tarifs_tariftypes_tariftype: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id],
    name: 'tarifs_tariftypes_tariftype',
  },
  tarifs_tariftypes_tariftype_articles: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.articles],
    name: 'tarifs_tariftypes_tariftype_articles',
  },
  tarifs_tariftypes_tariftype_articletariftemplate: {
    params: ['tariftype', 'articletariftemplate'],
    route: (tariftype: Tariftype, att: ArticleTarifTemplate) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.atts,
      att.id,
    ],
    name: 'tarifs_tariftypes_tariftype_articletariftemplate',
  },
  tarifs_tariftypes_tariftype_articletariftemplates: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.articletariftemplates],
    name: 'tarifs_tariftypes_tariftype_articletariftemplate',
  },
  tarifs_tariftypes_tariftype_articletariftemplates_articletariftemplate: {
    params: ['tariftype', 'articletariftemplate'],
    route: (tariftype: Tariftype, att: ArticleTarifTemplate) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.articletariftemplates,
      att.id,
    ],
    name: 'tarifs_tariftypes_tariftype_articletariftemplates_articletariftemplate',
  },
  tarifs_tariftypes_tariftype_articletariftemplates_earticle: {
    params: ['tariftype', 'earticle'],
    route: (tariftype: Tariftype, ea: Earticle) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.articletariftemplates,
      RS.earticle,
      ea.id,
    ],
    name: 'tarifs_tariftypes_tariftype_articletariftemplates_earticle',
  },
  tarifs_tariftypes_tariftype_catalog: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.catalog],
    name: 'tarifs_tariftypes_tariftype_catalog',
  },
  tarifs_tariftypes_tariftype_destinations: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tarifnotificationdestinations],
    name: 'tarifs_tariftypes_tariftype_destinations',
  },
  tarifs_tariftypes_tariftype_documents: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.documents],
    name: 'tarifs_tariftypes_tariftype_documents',
  },
  tarifs_tariftypes_tariftype_documents_document: {
    params: ['tariftype', 'tariftypedocument'],
    route: (tariftype: Tariftype, ttdoc: TariftypeDocument) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.documents,
      ttdoc.id,
    ],
    name: 'tarifs_tariftypes_tariftype_documents_document',
  },
  tarifs_tariftypes_tariftype_documents_document_metadata: {
    params: ['tariftype', 'tariftypedocument', 'tariftypemetadata'],
    route: (tariftype: Tariftype, ttdoc: TariftypeDocument, ttmeta: TariftypeMetadata) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.documents,
      ttdoc.id,
      RS.tariftypemetadatas,
      ttmeta.id,
    ],
    name: 'tarifs_tariftypes_tariftype_documents_document_metadata',
  },
  tarifs_tariftypes_tariftype_lifecycle: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.lifecycle],
    name: 'tarifs_tariftypes_tariftype_lifecycle',
  },
  tarifs_tariftypes_tariftype_manage: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.edit],
    name: 'tarifs_tariftypes_tariftype_manage',
  },

  tarifs_tariftypes_tariftype_mappings: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.mappings],
    name: 'tarifs_tariftypes_tariftype_mappings',
  },
  tarifs_tariftypes_tariftype_notification_logs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tarifnotificationstatuses],
    name: 'tarifs_tariftypes_tariftype_notification_logs',
  },
  tarifs_tariftypes_tariftype_notifications: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tarifnotifications],
    name: 'tarifs_tariftypes_tariftype_notifications',
  },
  tarifs_tariftypes_tariftype_permissions: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tariftypepermissions],
    name: 'tarifs_tariftypes_tariftype_permissions',
  },
  tarifs_tariftypes_tariftype_prices_logs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.articletariflogs],
    name: 'tarifs_tariftypes_tariftype_prices_logs',
  },
  tarifs_tariftypes_tariftype_regions: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.genericregions],
    name: 'tarifs_tariftypes_tariftype_regions',
  },
  tarifs_tariftypes_tariftype_relations: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tariftyperelations],
    name: 'tarifs_tariftypes_tariftype_relations',
  },
  tarifs_tariftypes_tariftype_relations_relation: {
    params: ['tariftype', 'tariftyperelation'],
    route: (tariftype: Tariftype, ttrel: TariftypeRelationBase) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tariftyperelations,
      ttrel.id,
    ],
    name: 'tarifs_tariftypes_tariftype_relations_relation',
  },
  tarifs_tariftypes_tariftype_storages: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.storages],
    name: 'tarifs_tariftypes_tariftype_storages',
  },
  tarifs_tariftypes_tariftype_tarif: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tarif, tarif.id],
    name: 'tarifs_tariftypes_tariftype_tarif',
  },

  tarifs_tariftypes_tariftype_tarifs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tarifs],
    name: 'tarifs_tariftypes_tariftype_tarifs',
  },
  tarifs_tariftypes_tariftype_tarifs_logs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tariflogs],
    name: 'tarifs_tariftypes_tariftype_tarifs_logs',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => ['/', RS.tarifs, RS.tariftypes, tariftype.id, RS.tarifs, tarif.id],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_documents: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.documents,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_documents',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_lifecycle: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.lifecycle,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_lifecycle',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_lines: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.prices,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_lines',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_manage: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.edit,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_manage',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_notifications: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.tarifnotifications,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_notifications',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_notifications_logs: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.tarifnotificationstatuses,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_notifications_logs',
  },
  tarifs_tariftypes_tariftype_tarifs_tarif_relations: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.tarifs,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.tarifrelations,
    ],
    name: 'tarifs_tariftypes_tariftype_tarifs_tarif_relations',
  },
  tarifs_tariftypestoragepermissions: {
    params: [],
    route: () => ['/', RS.tarifs, RS.storagepermissions],
    name: 'tarifs_tariftypestoragepermissions',
  },
  tarifs_tariftypestoragepermissions_tariftypestoragepermission: {
    params: ['tariftypestoragepermission'],
    route: (ttp: TariftypeStoragePermissionBase) => ['/', RS.tarifs, RS.storagepermissions, ttp.id],
    name: 'tarifs_tariftypestoragepermissions_tariftypestoragepermission',
  },
  tarifs_tariftypestorages: {
    params: [],
    route: () => ['/', RS.tarifs, RS.storages],
    name: 'tarifs_tariftypestorages',
  },
  tarifs_tariftypestorages_tariftypestorage: {
    params: ['tariftypestorage'],
    route: (ttp: TariftypeStorageBase) => ['/', RS.tarifs, RS.storages, ttp.id],
    name: 'tarifs_tariftypestorages_tariftypestorage',
  },
};
