import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Product } from '../../../../../models/catalog/product/product';
import { PRODUCT_ROUTES } from '../menu';
import { CommonModule } from '@angular/common';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { ProductNavComponent } from '../_nav/product-nav.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';
import { map, Observable, Subject } from 'rxjs';
import { Producer } from '../../../../../models/structures/producer/producer';
import { Storage } from '../../../../../models/structures/storage/storage';
import { Article } from '../../../../../models/catalog/article/article';

export interface ProductArticlesViewParams extends BaseRouteParams {
  p_menu: PRODUCT_ROUTES;
  article_detail_route: RouteConfigItem;
}

export interface ProductArticlesViewData extends ProductArticlesViewParams {
  product: Product;
  producer?: Producer;
  storage?: Storage;
}

@Component({
  selector: 'lvadg-product-articles-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, ProductNavComponent, ArticleListComponent],
  templateUrl: './product-articles-view.component.pug',
  styleUrl: './product-articles-view.component.sass',
})
export class ProductArticlesViewComponent extends BaseDetailViewComponent<ProductArticlesViewData, Product> {
  public productFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public article_detail_route?: Link<Article>;
  public default_fields = ['id', 'code', 'libelle', 'egalim_details', 'origine_details', 'score'];

  public override postNgOnInit() {
    super.postNgOnInit();
    this.productFilter$ = this.data$.pipe(map((data) => ({ product: data.product.id })));
  }

  public override setRouteData(data: ProductArticlesViewData) {
    super.setRouteData(data);
    if (data.article_detail_route) {
      this.article_detail_route = new Link(data.article_detail_route, data, this._router);
    }
  }
}
