import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Calibre } from '../../../../../models/catalog/meta/calibre/calibre';

export interface CalibreDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface CalibreDetailViewData extends CalibreDetailViewParams {
  calibre: Calibre;
}
