
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { ArticleTarifLogService} from "./article-tarif-log.service";
import { ArticleTarifLog } from "./article-tarif-log";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifLogResolver  {
  constructor(private _rs: ArticleTarifLogService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleTarifLog> {
    return this._rs.fetch(+route.params["article-tarif-logId"])
  }
}
