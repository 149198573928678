
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-eamapping-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './eamapping-display.component.pug',
  styleUrls: ['./eamapping-display.component.sass']
})
export class EamappingDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
