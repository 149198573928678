import { Component } from '@angular/core';
import { PresentationListViewData } from './presentation-list-view.component.params';
import { Subject } from 'rxjs';
import { AllergenListViewData } from '../../allergen/list/allergen-list-view.component.params';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { PresentationCreateComponent } from '../../../../../models/catalog/meta/presentation/presentation-create/presentation-create.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { PresentationListComponent } from '../../../../../models/catalog/meta/presentation/presentation-list/presentation-list.component';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Presentation } from '../../../../../models/catalog/meta/presentation/presentation';

@Component({
  selector: 'lvadg-presentation-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    PresentationCreateComponent,
    HeaderActionComponent,
    IconComponent,
    PresentationListComponent,
  ],
  templateUrl: './presentation-list-view.component.pug',
  styleUrls: ['./presentation-list-view.component.sass'],
})
export class PresentationListViewComponent extends BaseListViewComponent<PresentationListViewData, Presentation> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
