import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, PFactorcPipe, RouteConfigItem } from '@solidev/data';
import { VivalyaBill } from '../../../../models/sales/vivalya-bill/vivalya-bill';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { VivalyaBillItemListComponent } from '../../../../models/sales/vivalya-bill-item/vivalya-bill-item-list/vivalya-bill-item-list.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { map, Observable } from 'rxjs';
import { StorageDisplayComponent } from '../../../../models/structures/storage/storage-display/storage-display.component';
import { ClientDisplayComponent } from '../../../../models/structures/client/client-display/client-display.component';
import { RestoDisplayComponent } from '../../../../models/structures/resto/resto-display/resto-display.component';
import { Storage } from '../../../../models/structures/storage/storage';
import { Resto } from '../../../../models/structures/resto/resto';
import { Client } from '../../../../models/structures/client/client';
import { Member } from '../../../../models/structures/member/member';
import { Article } from '../../../../models/catalog/article/article';
import { Earticle } from '../../../../models/catalog/earticle/earticle';
import { VivalyaBillItem } from '../../../../models/sales/vivalya-bill-item/vivalya-bill-item';

export interface VivalyaBillDetailViewParams extends BaseRouteParams {
  billitem_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  member_detail_route?: RouteConfigItem;
  article_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
}

export interface VivalyaBillDetailViewData extends VivalyaBillDetailViewParams {
  vivalyabill: VivalyaBill;
}

@Component({
  selector: 'lvadg-vivalya-bill-detail-view',
  standalone: true,
  imports: [
    CommonModule,
    VivalyaBillItemListComponent,
    HeaderActionComponent,
    StorageDisplayComponent,
    ClientDisplayComponent,
    RestoDisplayComponent,
    PFactorcPipe,
  ],
  templateUrl: './vivalya-bill-detail-view.component.pug',
  styleUrls: ['./vivalya-bill-detail-view.component.sass'],
})
export class VivalyaBillDetailViewComponent extends BaseDetailViewComponent<VivalyaBillDetailViewData, VivalyaBill> {
  public billitem_detail_route?: Link<VivalyaBillItem>;
  public storage_detail_route?: Link<Storage>;
  public resto_detail_route?: Link<Resto>;
  public client_detail_route?: Link<Client>;
  public member_detail_route?: Link<Member>;
  public article_detail_route?: Link<Article>;
  public earticle_detail_route?: Link<Earticle>;

  public billFilter$!: Observable<FilterDefaults>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.billFilter$ = this.data$.pipe(map((data) => ({ bill: data.vivalyabill.id })));
  }

  public override setRouteData(data: VivalyaBillDetailViewData) {
    super.setRouteData(data);
    if (data.billitem_detail_route) {
      this.billitem_detail_route = new Link(data.billitem_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(data.storage_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link(data.resto_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link(data.client_detail_route, data, this._router);
    }
    if (data.member_detail_route) {
      this.member_detail_route = new Link(data.member_detail_route, data, this._router);
    }
    if (data.article_detail_route) {
      this.article_detail_route = new Link(data.article_detail_route, data, this._router);
    }
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(data.earticle_detail_route, data, this._router);
    }
  }
}
