
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { SupportMessageService} from "./support-message.service";
import { SupportMessage } from "./support-message";

@Injectable({
  providedIn: 'root'
})
export class SupportMessageResolver implements Resolve<SupportMessage> {
  constructor(private _rs: SupportMessageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SupportMessage> {
    return this._rs.fetch(+route.params["supportmessageId"])
  }
}
