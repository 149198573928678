
<ng-template #labels>
  <div class="row">
    <div class="col" *ngIf="article.egalim_status===EGS.EGALIM">
      <div class="egalim"><span>EG<wbr>alim</span></div>
    </div>
    <div class="col" *ngIf="article.egalim_status===EGS.EQ_EGALIM">
      <div class="egalimplus"><span>EQUI<wbr>VA<wbr>LENCE EG<wbr>alim</span></div>
    </div>
    <div class="col" *ngIf="article.local === AL.LOCAL">
      <div class="egalim"><span>LOCAL</span></div>
    </div>
    <div class="col" *ngIf="article.local === AL.ULTRALOCAL">
      <div class="egalim"><span>ULTRA LOCAL</span></div>
    </div>
    <div class="col" *ngFor="let e of article.egalim_details">
      <div class="d-flex flex-column align-items-center ps-4 pe-4">
        <lvadg-label-display [label]="e" mode="list-preview" [hover]="false"></lvadg-label-display>
        <div class="text-center labeltext">{{e.name}}</div>
        <div class="text-center"><small *ngIf="e.url"><a [href]="e.url" target="_blank">+ d'infos</a></small></div>
      </div>
    </div>
  </div>
</ng-template>
<div class="row g-3" *ngIf="article">
  <div class="col-12 col-lg-3">
    <div class="row">
      <div class="col-6 col-lg-12">
        <lvadg-image-display *ngIf="article.clogo_details" [image]="article.clogo_details" mode="large"></lvadg-image-display>
      </div>
      <div class="col-6 col-lg-12">
        <!-- p(*ngIf="article.content") {{article.content}}-->
        <ng-container *ngIf="article.providerdocuments_details?.length || article.articledocuments_details?.length">
          <h2 class="first">Documents</h2>
          <div class="certificates">
            <ng-container *ngFor="let pd of article.providerdocuments_details">
              <div class="certificate">
                <lvadg-provider-document-display [providerdocument]="pd" mode="details"></lvadg-provider-document-display>
              </div>
            </ng-container>
            <ng-container *ngFor="let ad of article.articledocuments_details">
              <div class="certificate">
                <lvadg-article-document-display [articledocument]="ad" mode="details"></lvadg-article-document-display>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="d-block d-lg-none">
          <h2>Labels et certifications</h2>
          <ng-container *ngTemplateOutlet="labels"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-9">
    <div class="row">
      <div class="col-7">
        <h2 class="first">Nomenclature et saisonnalité</h2>
        <dl>
          <dt>Nom</dt>
          <dd>{{article.name}}</dd>
          <!--ng-container(*ngIf="article.url")-->
          <!--  dt Plus d'infos-->
          <!--  dd-->
          <!--    a([href]="article.url", target="_blank") {{article.url|bsacFormatTrim:25}}-->
          <dt>Code</dt>
          <dd>{{article.code}}</dd>
          <ng-container *ngIf="article.scname">
            <dt>Nom scientifique</dt>
            <dd>{{article.scname||'-'}}</dd>
          </ng-container>
          <dt>Libellé complet</dt>
          <dd>{{article.libelle}}</dd>
          <dt>Classification</dt>
          <dd><span class="me-2" *ngFor="let f of article.family_details?.tree_path">&gt; {{f.name}}</span></dd>
          <dt>Saisonnalité</dt>
          <dd>
            <lvadg-seasons-manage [seasons]="article.seasons" [per_line]="12" [editable]="false" size="medium"></lvadg-seasons-manage>
          </dd>
        </dl>
        <h2>Informations nutritionnelles</h2>
        <p class="text-info" *ngIf="article.ciqual"><a [href]="'https://ciqual.anses.fr/#/aliments/'+article.ciqual_alim_details?.alim_code+'/data'" target="_blank"><i class="fa fa-external-link me-2"></i>Source: CIQUAL</a></p>
        <dl *ngIf="!article.allergenes_details">
          <dt>Allergènes<small class="ms-2">Informations non contractuelles</small></dt>
          <dd><span class="me-2" *ngFor="let a of article.allergenes_details">{{a.code||a.name}}</span></dd>
        </dl>
        <dl *ngIf="article.recette">
          <dt>Recette</dt>
          <dd>{{article.recette||'-'}}</dd>
        </dl>
        <div class="maxh">
          <table class="table table-sm table-borderless table-striped" *ngIf="article.ciqual_alim">
            <tbody>
              <tr *ngFor="let c of article.ciqual_alim_details?.values_details">
                <td>{{c.column_name}}</td>
                <td>
                  <ng-container *ngIf="c.value!=null">{{c.less?'< ': ''}} {{c.value/100000}} {{c.column_unit}}</ng-container>
                  <ng-container *ngIf="c.value==null">{{c.traces?'traces':''}}</ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-5">
        <div class="d-none d-lg-block">
          <h2 class="first">Labels et certifications</h2>
          <ng-container *ngTemplateOutlet="labels"></ng-container>
          <h2>Conditionnement et facturation</h2>
        </div>
        <div class="d-block d-lg-none">
          <h2 class="first">Conditionnement et facturation</h2>
        </div>
        <dl>
          <dt>Colisage</dt>
          <dd>{{article.colisage}}</dd>
          <dt>Poids min / max (kg)</dt>
          <dd>{{article.poids_min|pfactor:article:"poids_min"}} / {{article.poids_max|pfactor:article:"poids_max"}}</dd>
          <dt>UCI<small class="ms-2">Unité de Conditionnement Indivisible</small></dt>
          <dd>{{article.uci}}</dd>
          <dt>UDF<small class="ms-2">Unité de facturation</small></dt>
          <dd>{{article.udf_unit}}</dd>
          <ng-container *ngIf="article.udv_value || article.udv_unit">
            <dt>UDV<small class="ms-2">Unité de vente</small></dt>
            <dd>{{article.udv_value|pfactor:article:'udv_value'}} {{article.udv_unit}}</dd>
          </ng-container>
          <ng-container>
            <dt>Mode de déconditionnement</dt>
            <dd>{{article.decondi_mode|pchoice:article:'decondi_mode'}}</dd>
          </ng-container>
        </dl>
        <h2>Infos qualité</h2>
        <dl>
          <ng-container *ngIf="article.local &amp;&amp; article.local!= '-'">
            <dt>Local</dt>
            <dd>
              <ng-container *ngIf="article.local=='L'">oui</ng-container>
              <ng-container *ngIf="article.local=='U'">oui (ultralocal)</ng-container>
            </dd>
          </ng-container>
          <dt>Calibre</dt>
          <dd>
            <div>{{article.calibre_details?.code||article.calibre_details?.name}}</div>
            <!--div.small(*ngIf="article.calibre_details?.content") {{article.calibre_details?.content}}-->
            <!--div.small(*ngIf="article.calibre_details?.poids||article.calibre_details?.taille||article.calibre_details?.nombre") Infos calibre :-->
            <!--  ul-->
            <!--    li(*ngIf="article.calibre_details?.poids") poids {{article.calibre_details?.poids}}-->
            <!--    li(*ngIf="article.calibre_details?.taille") taille {{article.calibre_details?.taille}}-->
            <!--    li(*ngIf="article.calibre_details?.nombre") nombre par kg : {{article.calibre_details?.nombre}}-->
          </dd>
          <dt>Catégorie</dt>
          <dd>{{article.calibre_details?.name||article.categorie_details?.code||"-"}}</dd>
          <dt>Process</dt>
          <dd>{{article.process_details?.name||article.process_details?.code||'-'}}</dd>
          <dt>Traitement</dt>
          <dd>{{article.traitement_details?.name||article.traitement_details?.code||'-'}}</dd>
          <ng-container *ngIf="article.production">
            <dt>Production</dt>
            <dd>{{article.production_details?.name||article.production_details?.code||'-'}}</dd>
          </ng-container>
          <ng-container *ngIf="article.marque">
            <dt>Marque</dt>
            <dd>
              <div>{{article.marque_details?.name||article.marque_details?.code||'-'}}</div>
              <!--div(*ngIf="article.marque_details?.producer_details?.name")-->
              <!--  a([routerLink]="routes.detail('fl', 'producer', article.marque_details?.producer_details)")-->
              <!--    i.fa.fa-link.me-2-->
              <!--    | {{article.marque_details?.producer_details?.name}}-->
              <!--div(*ngIf="article.marque_details?.folder")-->
              <!--  a([routerLink]="routes.detail('fl', 'documents', article.marque_details?.folder_details?.slug+'-'+article.marque_details?.folder_details?.id)")-->
              <!--    i.fa.fa-folder.me-2-->
              <!--    | {{article.marque_details?.folder_details?.title}}-->
            </dd>
          </ng-container>
          <ng-container *ngIf="article.fournisseur">
            <dt>Fournisseur</dt>
            <dd>{{article.fournisseur_details?.name}}</dd>
          </ng-container>
          <dt>DLC / DDM</dt>
          <dd>
            <div *ngIf="article.dlc_value">DLC {{article.dlc_value}} {{article.dlc_unit}}</div>
            <div *ngIf="article.ddm_value">DDM {{article.ddm_value}} {{article.ddm_unit}}</div>
            <ng-container *ngIf="!article.dlc_value &amp;&amp; !article.ddm_value"></ng-container>
          </dd>
          <dt>Origine</dt>
          <dd>{{article.origine_details?.code||article.origine_details?.name||'-'}}</dd>
          <dt>Région</dt>
          <dd>{{article.region_details?.code||article.region_details?.name||'-'}}</dd>
          <dt>Usage / divers</dt>
          <dd>{{article.usage_details?.code||article.usage_details?.name||'-'}}</dd>
        </dl>
      </div>
    </div>
  </div>
</div>