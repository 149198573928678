import {charField, DataModel, foreignKeyField, integerField, primaryField,} from '@solidev/data';

// FIXME: rename ?
export enum TARIFNOTIFICATION_TYPE {
  NOTIF_INITIAL_PUBLICATION = 'NT_PUB',
  NOTIF_UPDATE_PUBLICATION = 'NT_UPD',
  NOTIF_PUBLICATION_ALL = 'NT_ALL',
  NEGO_ALL = 'NE_ALL',
  ALARM_NO_CURRENT = 'AL_NOCUR',
  ALARM_DATE_PUBLICATION = 'AL_DTPUB',
  ALARM_DATE_VALIDATION = 'AL_DTVAL',
  ALARM_DATE_PREPARATION = 'AL_DTPRP',
  ALARM_MISSING_STORAGE = 'AL_MISST',
  ALARM_ALL = 'AL_ALL',
  ALL = 'ALL',
}

export class TarifNotificationDestinationBase extends DataModel {
  static override readonly __name: string = 'TarifNotificationDestinationBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
  })
  public tariftype!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur',
    related: 'User',
  })
  public user?: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
  })
  public group?: number;

  @foreignKeyField({
    name: 'contact',
    description: 'ID Contact',
    related: 'Contact',
  })
  public contact?: number;

  @charField({
    name: 'notification',
    description: 'Type de notification',
    maxLength: 10,
    choices: [
      {
        value: TARIFNOTIFICATION_TYPE.NOTIF_INITIAL_PUBLICATION,
        desc: 'Notifications de publication initiale',
      },
      {
        value: TARIFNOTIFICATION_TYPE.NOTIF_UPDATE_PUBLICATION,
        desc: 'Notifications de publication initiale',
      },
      {
        value: TARIFNOTIFICATION_TYPE.NOTIF_PUBLICATION_ALL,
        desc: 'Toutes les notifications de publication',
      },
      {
        value: TARIFNOTIFICATION_TYPE.NEGO_ALL,
        desc: 'Tous les emails de négociation',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALARM_NO_CURRENT,
        desc: 'Alarme tarif manquant',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALARM_DATE_PUBLICATION,
        desc: 'Alarme date de publication',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALARM_DATE_VALIDATION,
        desc: 'Alarme date de validation',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALARM_DATE_PREPARATION,
        desc: 'Alarme date de prépration',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALARM_MISSING_STORAGE,
        desc: 'Alarme données dépôt manquantes',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALARM_ALL,
        desc: 'Toutes les alarmes',
      },
      {
        value: TARIFNOTIFICATION_TYPE.ALL,
        desc: 'Tous les messages (alarmes, notifications et négociation)',
      },
    ],
  })
  public notification!: TARIFNOTIFICATION_TYPE;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
