
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMapLayer } from '../user-map-layer';
@Component({
  selector: 'lvadg-user-map-layer-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-map-layer-manage.component.pug',
  styleUrls: ['./user-map-layer-manage.component.sass']
})
export class UserMapLayerManageComponent implements OnInit {
  @Input() public usermaplayer?: UserMapLayer;

  constructor() { }

  ngOnInit(): void {
  }

}
