import {
  Component,
  inject,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { PROVIDER_ROUTES } from "../menu";
import { Provider } from "../../../../../models/structures/provider/provider";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { map, Observable, Subject } from "rxjs";
import { ProviderDocument } from "../../../../../models/structures/provider-document/provider-document";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { Article } from "../../../../../models/catalog/article/article";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { ProviderDocumentCreateComponent } from "../../../../../models/structures/provider-document/provider-document-create/provider-document-create.component";
import { ProviderNavComponent } from "../_nav/provider-nav.component";
import { ProviderDocumentListComponent } from "../../../../../models/structures/provider-document/provider-document-list/provider-document-list.component";
import { ProviderDocumentManageComponent } from "../../../../../models/structures/provider-document/provider-document-manage/provider-document-manage.component";
import {ADMIN_GROUPS} from "../../../../../constants";
import {AuthService} from "../../../../../models/users/auth.service";

export interface ProviderProviderdocumentsViewParams extends BaseRouteParams {
  pr_menu: PROVIDER_ROUTES;
  article_detail_route: RouteConfigItem;
}

export interface ProviderProviderdocumentsViewData
  extends ProviderProviderdocumentsViewParams {
  provider: Provider;
}

@Component({
  selector: "lvadg-provider-providerdocuments-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProviderNavComponent,
    ProviderDocumentListComponent,
    ProviderDocumentManageComponent,
    IconComponent,
    ProviderDocumentCreateComponent,
  ],
  templateUrl: "./provider-providerdocuments-view.component.pug",
  styleUrl: "./provider-providerdocuments-view.component.sass",
})
export class ProviderProviderdocumentsViewComponent extends BaseDetailViewComponent<
  ProviderProviderdocumentsViewData,
  Provider
> {
  public providerFilter$!: Observable<FilterDefaults>;
  public article_detail_route?: Link<Article>;
  public canEdit = signal(false);
  public currentUser = inject(AuthService).currentUser;
  public createDocument = false;
  public currentDocument = signal<ProviderDocument | null>(null);
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  @ViewChild("docEditSlot", { static: false })
  public docEditSlot?: TemplateRef<HTMLDivElement>;
  public default_fields = [
    "type",
    "label_details",
    "filename",
    "articles_details",
    "status",
    "start",
    "end",
  ];
  private _ofc = inject(NgbOffcanvas);
  private _docEditInstance?: NgbOffcanvasRef;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.providerFilter$ = this.data$.pipe(
      map((data) => ({ provider: data.provider.id })),
    );
  }

  public override setRouteData(data: ProviderProviderdocumentsViewData) {
    super.setRouteData(data);
    if (data.article_detail_route) {
      this.article_detail_route = new Link(
        data.article_detail_route,
        data,
        this._router,
      );
    }
    this.canEdit.set(
      !!(
        this.currentUser &&
        (this.currentUser.is_superuser ||
          this.currentUser.have_group(ADMIN_GROUPS.providers_editor))
      ),
    );
    if(this.canEdit())
    {
      this.default_fields = [
        "id",
        "type",
        "label_details",
        "filename",
        "articles_details",
        "status",
        "start",
        "end",
        "actions",
      ]
    }

  }

  public onAction(
    action: ModelListAction<ProviderDocument>,
    data: ProviderProviderdocumentsViewData,
  ) {
    if (action.action === "edit" && action.model) {
      this.currentDocument.set(action.model!);
      if (this.docEditSlot) {
        this._docEditInstance = this._ofc.open(this.docEditSlot, {
          position: "end",
          panelClass: "w-75",
        });
        this._docEditInstance.closed.subscribe(() => {
          this.currentDocument.set(null);
          this.reload$.next(true);
        });
      }
    }
  }
}
