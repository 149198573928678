
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { ArticleDocumentService} from "./article-document.service";
import { ArticleDocument } from "./article-document";

@Injectable({
  providedIn: 'root'
})
export class ArticleDocumentResolver implements Resolve<ArticleDocument> {
  constructor(private _rs: ArticleDocumentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleDocument> {
    return this._rs.fetch(+route.params["articledocumentId"])
  }
}
