import { TarifLogBase } from './tarif-log.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { TarifBase } from '../tarif/tarif.base';
import { TarifLifecycleStep } from '../tarif-lifecycle-step/tarif-lifecycle-step';

export class TarifLog extends TarifLogBase {
  static override readonly __name: string = 'TarifLog';

  @detailsField({
    model: UserBase,
    description: 'Auteur',
    readonly: true,
  })
  public author_details?: UserBase;

  @detailsField({
    model: TarifBase,
    description: 'Tarif',
    readonly: true,
  })
  public tarif_details?: TarifBase;

  @detailsField({
    model: TarifLifecycleStep,
    description: 'Étape avant',
    readonly: true,
  })
  public ptstep_details?: TarifLifecycleStep;

  @detailsField({
    model: TarifLifecycleStep,
    description: 'Étape après',
    readonly: true,
  })
  public tstep_details?: TarifLifecycleStep;
}
