import { Component } from '@angular/core';
import { BaseRouteParams } from '@solidev/data';
import { Client } from '../../../../models/structures/client/client';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { ClientManageComponent } from '../../../../models/structures/client/client-manage/client-manage.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

export interface ClientDetailViewParams extends BaseRouteParams {}

export interface ClientDetailViewData extends ClientDetailViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ClientManageComponent, IconComponent],
  templateUrl: './client-detail-view.component.pug',
  styleUrls: ['./client-detail-view.component.sass'],
})
export class ClientDetailViewComponent extends BaseDetailViewComponent<ClientDetailViewData, Client> {
  public override setRouteData(data: ClientDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
