
<ng-container *ngIf="data$|async; let data">
  <ng-template #createOfcSlot let-offcanvas>
    <div class="offcanvas-header">
      <lvadg-icon ri="add">Ajouter un producteur</lvadg-icon>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body">
      <div class="container">
        <lvadg-producer-user-create [type]="createProducerType()" (cancelled)="create()" (created)="reload$.next(true); create(); producer_detail_route?.navigate({producer: $event})"></lvadg-producer-user-create>
      </div>
    </div>
  </ng-template>
  <lvadg-storage-nav [storage]="data.storage" [routes]="data.s_menu"></lvadg-storage-nav>
  <lvadg-header-action [title]="data.storage._display" level="h1" ri="storage" [subtitle]="subtitle()">
    <ng-container actions>
      <ng-container *ngIf="data.storage.type!==STORAGE_TYPES.FLSEA">
        <button class="btn btn-outline-primary btn-sm" (click)="create(data.storage)" *ngIf="canCreate()">
          <lvadg-icon ri="add">Ajouter un producteur</lvadg-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="data.storage.type===STORAGE_TYPES.FLSEA">
        <button class="btn btn-outline-primary btn-sm" (click)="create(data.storage, PRODUCER_TYPES.MR)" *ngIf="canCreate()">
          <lvadg-icon ri="add">Ajouter un producteur marée</lvadg-icon>
        </button>
        <button class="btn btn-outline-primary btn-sm" (click)="create(data.storage, PRODUCER_TYPES.FL)" *ngIf="canCreate()">
          <lvadg-icon ri="add">Ajouter un producteur F&L</lvadg-icon>
        </button>
      </ng-container>
    </ng-container>
  </lvadg-header-action>
  <lvadg-producer-list [name]="data.route.name" [keep]="false" [reload]="reload$" [filter]="storageFilter$" [detail_route]="producer_detail_route" [storage_detail_route]="storage_detail_route" [default_fields]="default_fields" [print_settings]="printSettings" [print_context]="{storage: data.storage}" [cdatakeys]="data.cdatakeys" [dispMap]="'local'" [actions]="['print_affiche', 'print_fiche', 'print_fiche_products']"></lvadg-producer-list>
</ng-container>