
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TarifLog } from "./tarif-log";

@Injectable({
  providedIn: 'root'
})
export class TarifLogService extends Collection<TarifLog> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tariflogs", TarifLog);
  }
}
