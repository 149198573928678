import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ADMIN_MENU, IMenuItem } from '../../routing/menu';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbOffcanvas, NgbOffcanvasModule, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, Print, PrintCurrentIconComponent, RI, SPECIAL_GROUPS } from '@vivalya/lvadg';
import { ADMIN_ROUTES } from '../../routing/main';
import { environment } from '../../../environments/environment';
import { Link } from '@solidev/data';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'lva-header',
  standalone: true,
  imports: [CommonModule, RouterModule, NgbOffcanvasModule, PrintCurrentIconComponent, NgbDropdownModule],
  templateUrl: './header.component.pug',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  public menu$!: Observable<IMenuItem[]>;
  public ROUTES = ADMIN_ROUTES;
  public RI = RI;
  public env = environment;
  public print_detail_route = new Link<Print>(this.ROUTES.layouts_prints_print, {});
  public print_list_route = new Link<void>(this.ROUTES.layouts_prints, {});
  @ViewChild('sidemenu', { static: true })
  public ofct!: TemplateRef<HTMLDivElement>;
  public smenu?: NgbOffcanvasRef;

  constructor(
    public auth: AuthService,
    private _ofc: NgbOffcanvas,
  ) {}

  ngOnInit(): void {
    this.menu$ = this.auth.currentUser$.pipe(
      map((u) => {
        return ADMIN_MENU.filter((i) => {
          for (const g of i.groups || [SPECIAL_GROUPS.superuser]) {
            if (g === SPECIAL_GROUPS.all) {
              return true;
            }
            if (g === SPECIAL_GROUPS.superuser && u?.is_superuser) {
              return true;
            }
            if (g === SPECIAL_GROUPS.staff && u?.is_staff) {
              return true;
            }
            if (u?.current_groups_slugs && u.current_groups_slugs.indexOf(`${g}`) !== -1) {
              return true;
            }
          }
          return false;
        });
      }),
    );
  }

  toggleSideMenu() {
    if (!this.smenu) {
      this.smenu = this._ofc.open(this.ofct, { position: 'start' });
      this.smenu.result.then(
        () => {
          delete this.smenu;
        },
        () => {
          delete this.smenu;
        },
      );
    } else {
      this.smenu.close();
      delete this.smenu;
    }
  }

  closeSideMenu() {
    this.smenu?.close();
    delete this.smenu;
  }
}
