import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { TariftypeStorage } from '../tariftype-storage';
import { StorageService } from '../../../structures/storage/storage.service';
import { DispeditComponent } from '@solidev/data';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { TARIFTYPE_STORAGE_MODE } from '../../tariftype/tariftype.base';

@Component({
  selector: 'lvadg-tariftype-storage-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tariftype-storage-create.component.pug',
  styleUrls: ['./tariftype-storage-create.component.sass'],
})
export class TariftypeStorageCreateComponent implements OnInit {
  @Input() public tariftype?: Tariftype;
  public tts!: TariftypeStorage;
  public tariftypeFilter = {
    fields: ['id', 'code', 'name'].join(','),
    storages_modes: [
      TARIFTYPE_STORAGE_MODE.PRICE_DISPO_STORAGE,
      TARIFTYPE_STORAGE_MODE.DISPO_PER_STORAGE,
      TARIFTYPE_STORAGE_MODE.PRICE_DISPO_STORAGE,
    ].join(','),
  };
  @Output() public created = new EventEmitter<void>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    public storages$: StorageService,
    public tariftype$: TariftypeService,
    private _ttact: TariftypeActionService,
  ) {}

  ngOnInit(): void {
    this.tts = new TariftypeStorage();
    if (this.tariftype) {
      this.tts.tariftype = this.tariftype.id;
      this.tts.tariftype_details = this.tariftype;
    }
  }

  public async save() {
    if (this.tariftype) {
      const res = await this._ttact.action(this.tariftype, {
        action: 'add_storage',
        storage: this.tts.storage,
      });
      if (res.success) {
        this.created.emit();
      }
    }
  }
}
