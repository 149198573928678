
<ng-container *ngIf="data$|async; let data">
  <ng-template #createOfcSlot let-offcanvas>
    <div class="offcanvas-header">
      <lvadg-icon ri="add">Ajouter un produit</lvadg-icon>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body">
      <div class="container">
        <lvadg-product-create (cancelled)="create()" (created)="reload$.next(true); create()"></lvadg-product-create>
      </div>
    </div>
  </ng-template>
  <lvadg-storage-nav [storage]="data.storage" [routes]="data.s_menu"></lvadg-storage-nav>
  <lvadg-header-action [title]="data.storage._display" level="h1" ri="storage" [subtitle]="subtitle()">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="create()" *ngIf="canCreate()">
        <lvadg-icon ri="add">Ajouter un produit</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-product-list [name]="data.route.name" [keep]="false" [reload]="reload$" [filter]="storageFilter$" [type]="data.type" [detail_route]="product_detail_route" [producer_detail_route]="producer_detail_route" [storage_detail_route]="storage_detail_route" [default_fields]="default_fields" [print_settings]="printSettings" [print_context]="printContext" [select]="true" [actions]="['print_fiche', 'print_affiche', 'edit', 'delete']" [dispMap]="'local'"></lvadg-product-list>
</ng-container>