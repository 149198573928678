import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutData, LayoutTree } from "../../layout/layout";
import {
  TemplateChoice,
  TemplateFragment,
  TemplateParam,
  TemplateProvider,
} from "../../layout/template";

@Component({
  selector: "lvadg-layout-template-provider-select-image",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./layout-template-provider-select-image.component.pug",
  styleUrls: ["./layout-template-provider-select-image.component.sass"],
})
export class LayoutTemplateProviderSelectImageComponent {
  @Input() public tree!: LayoutTree;
  @Input() public layout!: LayoutData;
  @Input() public fragment!: TemplateFragment;
  @Input() public param!: TemplateParam;
  @Input() public provider!: TemplateProvider;
  @Input() public display: "logo" | "header" = "logo";

  public select(choice: TemplateChoice) {
    this.tree.set_fragment_param({
      layout: this.layout,
      group: this.layout.group,
      fragment: this.fragment,
      param: this.param,
      provider: this.provider,
      choice,
    });
  }

  public resize(img: string | null): string {
    if (!img) {
      return "";
    }
    // Split the image extension
    const split = img.split(".");
    const ext = split.pop();
    if (!ext) {
      return img;
    }
    // Add --cover_230x50 to the image name and join the extension back
    return `${split.join(".")}--cover_230x50.${ext}`;
  }
}
