import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Origin } from './origin';

@Injectable({
  providedIn: 'root',
})
export class OriginService extends Collection<Origin> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/origins', Origin);
  }
}
