
<ng-container *ngIf="list">
  <div class="d-flex flex-row justify-content-between">
    <div class="mx-2">
      <button class="btn btn-sm btn-outline-primary" (click)="displayProducers.set(true)" *ngIf="!displayProducers()">Afficher les producteurs</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayProducers.set(false)" *ngIf="displayProducers()">Masquer les producteurs</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayProducersLegend.set(true)" *ngIf="displayProducers() &amp;&amp; !displayProducersLegend()">Afficher la légende</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayProducersLegend.set(false)" *ngIf="displayProducers() &amp;&amp; displayProducersLegend()">Masquer la légende</button>
    </div>
    <div class="mx-2">
      <button class="btn btn-sm btn-outline-primary" (click)="displayStorages.set(true)" *ngIf="!displayStorages()">Afficher les dépôts</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayStorages.set(false)" *ngIf="displayStorages()">Masquer les dépôts</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayStoragesLegend.set(true)" *ngIf="displayStorages() &amp;&amp; !displayStoragesLegend()">Afficher la légende</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayStoragesLegend.set(false)" *ngIf="displayStorages() &amp;&amp; displayStoragesLegend()">Masquer la légende</button>
    </div>
  </div>
</ng-container>
<div #map id="map"></div>