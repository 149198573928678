
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Geocommune } from "./geocommune";

@Injectable({
  providedIn: 'root'
})
export class GeocommuneService extends Collection<Geocommune> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/geocommunes", Geocommune);
  }
}
