import { IMenuItem } from '../../routing/menu';
import { ADMIN_ROUTES } from '../../routing/main';

export const ADMIN_TARIFS_MENU: IMenuItem[] = [
  {
    title: 'Tarifs',
    altTitle: 'Gestion des tarifs',
    route: ADMIN_ROUTES.tarifs.route(),
    children: [
      { title: 'Modèles', route: ADMIN_ROUTES.tarifs_tariftypes.route() },
      { title: 'Tarifs', route: ADMIN_ROUTES.tarifs_tarifs.route() },
      {
        title: 'Permissions tarifs',
        route: ADMIN_ROUTES.tarifs_tariftypepermissions.route(),
      },
      {
        title: 'Associations tarifs/dépôts',
        route: ADMIN_ROUTES.tarifs_tariftypestorages.route(),
      },
      {
        title: 'Permissions tarifs/dépôts',
        route: ADMIN_ROUTES.tarifs_tariftypestoragepermissions.route(),
      },
      {
        title: 'Associations tarifs/régions',
        route: ADMIN_ROUTES.tarifs_tariftyperegions.route(),
      },
      {
        title: 'Permissions tarifs/régions',
        route: ADMIN_ROUTES.tarifs_tariftyperegionpermissions.route(),
      },
      {
        title: 'Relations entre modèles',
        route: ADMIN_ROUTES.tarifs_tariftyperelations.route(),
      },
      {
        title: 'Relations entre tarifs',
        route: ADMIN_ROUTES.tarifs_tarifrelations.route(),
      },
      {
        title: 'Relations entre articles de tarifs',
        route: ADMIN_ROUTES.tarifs_articletariftemplaterelations.route(),
      },
      {
        title: 'Articles de tarifs',
        route: ADMIN_ROUTES.tarifs_articletariftemplates.route(),
      },
      {
        title: 'Documents de tarifs',
        route: ADMIN_ROUTES.tarifs_tarifdocuments.route(),
      },
      {
        title: 'Documents de modèles de tarifs',
        route: ADMIN_ROUTES.tarifs_tariftypedocuments.route(),
      },
      {
        title: 'Paramètres de modèles de tarifs',
        route: ADMIN_ROUTES.tarifs_tariftypemetadatas.route(),
      },

      {
        title: 'Destinations des notifications',
        route: ADMIN_ROUTES.tarifs_tarifnotificationdestinations.route(),
      },
      {
        title: 'Notifications envoyées',
        route: ADMIN_ROUTES.tarifs_tarifnotifications.route(),
      },
      {
        title: 'Détails de réception des notifications',
        route: ADMIN_ROUTES.tarifs_tarifnotificationstatuses.route(),
      },
      {
        title: 'Gestion des cycles de vie',
        route: ADMIN_ROUTES.tarifs_lifecyclesteps.route(),
      },

      {
        title: 'Statistiques',
        route: ADMIN_ROUTES.tarifs_stats.route(),
      },
    ],
  },
];
