
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-rnmmarche-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmmarche-display.component.pug',
  styleUrls: ['./rnmmarche-display.component.sass']
})
export class RnmmarcheDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
