
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OfferNotificationService} from "./offer-notification.service";
import { OfferNotification } from "./offer-notification";

@Injectable({
  providedIn: 'root'
})
export class OfferNotificationResolver implements Resolve<OfferNotification> {
  constructor(private _rs: OfferNotificationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OfferNotification> {
    return this._rs.fetch(+route.params["offernotificationId"])
  }
}
