import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';
import { TARIF_ROUTES } from '../menu';
import { TARIFTYPE_LIFECYCLE, TARIFTYPE_USER_LEVEL } from '../../../../../models/tarifs/tariftype/tariftype.base';

export interface TarifDisplayViewParams extends BaseRouteParams {
  menu?: TARIF_ROUTES;
}

export interface TarifDisplayViewData extends TarifDisplayViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-display-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, TarifDetailNavComponent],
  templateUrl: './tarif-display-view.component.pug',
  styleUrls: ['./tarif-display-view.component.sass'],
})
export class TarifDisplayViewComponent extends BaseDetailViewComponent<TarifDisplayViewData, Tarif> {
  public TTUL = TARIFTYPE_USER_LEVEL;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifDisplayViewData) {
    super.setRouteData(data);
    if (
      data.menu?.tarif_lines_route &&
      data.tariftype.have_level(TARIFTYPE_USER_LEVEL.storage) &&
      data.tariftype.lifecycle !== TARIFTYPE_LIFECYCLE.UPLOAD
    ) {
      this._router.navigate(data.menu.tarif_lines_route.route(data.tariftype, data.tarif));
      return;
    }
    if (data.menu?.tarif_manage_route && data.tariftype.have_level(TARIFTYPE_USER_LEVEL.pilote)) {
      this._router.navigate(data.menu.tarif_manage_route.route(data.tariftype, data.tarif));
      return;
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }
}
