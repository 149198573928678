import {
  booleanField,
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from "@solidev/data";
import { ContentType } from "../../structures/structure.base";
import { EGALIM_STATUS } from "../common";

export enum PRODUCT_TYPES {
  LVADG_FL = "LVF",
  LVADG_SEA = "LVM",
  PROVIDER = "PRV",
}

export enum PRODUCT_USER_LEVELS {
  SUPERUSER = "sadmin",
  WRITE = "write",
  READ = "read",
}

export enum ProductStatus {
  VALID = "OK",
  INVALID = "KO",
}

export enum LOCAL_FILTERS {
  NO_FILTER = "no",
  GLOBAL_CONTEXT = "global_context",
  GLOBAL_FULL = "global_full",
  GLOBAL_MEMBER = "global_member",
  GLOBAL_STORAGE = "global_storage",
  GLOBAL_RESTO = "global_resto",
  LOCAL_CONTEXT = "local_context",
  LOCAL_FULL = "local_full",
  LOCAL_MEMBER = "local_member",
  LOCAL_STORAGE = "local_storage",
  LOCAL_RESTO = "local_resto",
}

export class ProductBase extends DataModel {
  static override readonly __name: string = "ProductBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: "flags",
    description: "Flags",
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: PRODUCT_TYPES.LVADG_FL,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: PRODUCT_TYPES.LVADG_FL,
        desc: "Produit lavieadugout - FL",
      },
      {
        value: PRODUCT_TYPES.LVADG_SEA,
        desc: "Produit lavieadugout - Marée",
      },
      {
        value: PRODUCT_TYPES.PROVIDER,
        desc: "Produit fournisseur",
      },
    ],
    priority: 800,
  })
  public type: PRODUCT_TYPES = PRODUCT_TYPES.LVADG_FL;

  @charField({
    name: "name",
    description: "Nom",
    maxLength: 200,
    priority: 900,
  })
  public name!: string;

  @detailsField({
    name: "seasons",
    description: "Saisonnalité",
    defaultValue: [],
    priority: 500,
  })
  public seasons: number[] = [];

  @foreignKeyField({
    name: "logo",
    description: "ID Logo",
    related: "Logo",
    priority: -1,
  })
  public logo!: number;

  @textField({
    name: "content_src",
    description: "Description (source)",
    required: false,
    priority: 500,
  })
  public content_src?: string;

  @charField({
    name: "egalim_status",
    description: "État Égalim",
    defaultValue: EGALIM_STATUS.NON_EGALIM,
    maxLength: 2,
    choices: [
      { value: EGALIM_STATUS.NON_EGALIM, desc: "Non égalim" },
      { value: EGALIM_STATUS.EQ_EGALIM, desc: "Eq. égalim" },
      { value: EGALIM_STATUS.EGALIM, desc: "Egalim" },
    ],
    priority: 840,
  })
  public egalim_status: EGALIM_STATUS = EGALIM_STATUS.NON_EGALIM;

  @textField({
    name: "content_html",
    description: "Description (html)",
    required: false,
    priority: -1,
  })
  public content_html?: string;

  @charField({
    name: "content_type",
    description: "Description (type)",
    required: false,
    choices: [
      { value: ContentType.TEXT, desc: "Texte brut" },
      { value: ContentType.MARKDOWN, desc: "Markdown" },
      { value: ContentType.HTML, desc: "HTML" },
    ],
    defaultValue: ContentType.MARKDOWN,
    priority: -1,
  })
  public content_type: ContentType = ContentType.MARKDOWN;

  @foreignKeyField({
    name: "family",
    description: "ID Famille",
    related: "Family",
    priority: -1,
  })
  public family!: number;

  @foreignKeyField({
    name: "producer",
    description: "ID Producteur",
    related: "Producer",
    priority: -1,
  })
  public producer!: number;

  @foreignKeyField({
    name: "provider",
    description: "ID Fournisseur",
    related: "Provider",
    priority: -1,
  })
  public provider!: number;

  @foreignKeyField({
    name: "ciqual_alim",
    description: "ID Ciqual Alim",
    priority: -1,
  })
  public ciqual_alim?: number;

  @charField({
    name: "status",
    description: "État",
    defaultValue: ProductStatus.VALID,
    required: true,
    maxLength: 2,
    choices: [
      { value: ProductStatus.VALID, desc: "Valide" },
      { value: ProductStatus.INVALID, desc: "Invalide" },
    ],
    priority: 101,
  })
  public status: ProductStatus = ProductStatus.VALID;

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -1,
  })
  public orig_id!: number;

  @booleanField({
    name: "in_offer",
    description: "Dans l'offre ?",
    priority: -1,
    readonly: true,
  })
  public in_offer!: boolean;

  @manyToManyField({
    name: "images",
    description: "IDs images",
    defaultValue: [],
    related: "Image",
    priority: -1,
  })
  public images: number[] = [];

  @manyToManyField({
    name: "labels",
    description: "IDs labels",
    defaultValue: [],
    related: "Label",
    priority: -1,
  })
  public labels: number[] = [];
  @detailsField({
    name: "user_level",
    description: "Droits utilisateur",
    readonly: true,
  })
  public user_level: PRODUCT_USER_LEVELS[] = [];

  public have_level(level: PRODUCT_USER_LEVELS): boolean {
    return this.user_level.includes(level);
  }
}
