
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TariftypeDocument } from "./tariftype-document";

@Injectable({
  providedIn: 'root'
})
export class TariftypeDocumentService extends Collection<TariftypeDocument> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tariftypedocuments", TariftypeDocument);
  }
}
