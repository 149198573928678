
<form [formGroup]="createForm">
  <div class="row g-3 mb-3">
    <div class="col-12" *ngIf="!article">
      <data-dispedit [model]="doc" [form]="createForm" field="articles" [collection]="articles$" [filter]="{fields: ['id', 'libelle'].join(',')}">Articles</data-dispedit>
    </div>
    <div class="col-12">
      <data-dispedit [model]="doc" [form]="createForm" field="type">Type</data-dispedit>
    </div>
  </div>
</form>
<lvadg-media-upload [service]="articledocument$" [enabled]="createForm.valid &amp;&amp; (!!article || !!createForm.value.articles)" [model]="doc" [prepare]="uploadPrepare" (uploadDone)="uploaded.emit($event)"></lvadg-media-upload>