
<ng-container *ngIf="data$|async; let data">
  <lvadg-producer-nav [producer]="data.producer" [storage]="data.storage" [routes]="data.pr_menu"></lvadg-producer-nav>
  <lvadg-header-action [title]="data.producer.name" level="h2" ri="producer" subtitle="Relations producteur">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="createRelation=!createRelation"><i class="bi bi-plus-circle me-2"></i>Ajouter une relation</button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createRelation">
    <div class="card-header">
      <lvadg-icon ri="relation">Nouvelle relation</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-relation-create [producer]="data.producer" (created)="createRelation=false; reload$.next(true)" (cancelled)="createRelation=false"></lvadg-relation-create>
    </div>
  </div>
  <lvadg-relation-list [name]="data.route.name+'-relations'" [filter]="producerFilter$" [reload]="reload$" [actions]="['delete']" [detail_route]="relation_detail_route" [storage_detail_route]="storage_detail_route"></lvadg-relation-list>
</ng-container>