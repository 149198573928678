import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { LogUsage } from "../log-usage";
import { LogUsageService } from "../log-usage.service";
import {
  FiltersParams,
  Link,
  ModelListDatetimeFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { UserDisplayComponent } from "../../../users/user/user-display/user-display.component";
import { User } from "../../../users/user/user";

@Component({
  selector: "lvadg-log-usage-list",
  standalone: true,
  templateUrl: "./log-usage-list.component.pug",
  styleUrls: ["./log-usage-list.component.sass"],
  imports: [...MODELLIST_IMPORTS, UserDisplayComponent],
})
export class LogUsageListComponent extends ModellistComponent<LogUsage> {
  @Input() user_detail_route?: Link<User>;

  constructor(
    coll: LogUsageService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "methods",
          "ip",
          "timestamp__start",
          "timestamp__end",
          "user_details",
        ],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectMultiFilter({
          field: "methods",
          name: "methods",
          label: "Méthode(s)",
          choices: [
            { value: "GET", desc: "GET" },
            { value: "POST", desc: "POST" },
            { value: "PUT", desc: "PUT" },
            { value: "DELETE", desc: "DELETE" },
            { value: "PATCH", desc: "PATCH" },
            { value: "OPTIONS", desc: "OPTIONS" },
            { value: "HEAD", desc: "HEAD" },
            { value: "TRACE", desc: "TRACE" },
            { value: "CONNECT", desc: "CONNECT" },
          ],
        }),
        new ModelListTextFilter({
          field: "action",
          name: "action",
          label: "Recherche par action",
        }),
        new ModelListTextFilter({
          field: "view",
          name: "view",
          label: "Recherche par vue",
        }),
        new ModelListTextFilter({
          field: "user_details",
          name: "user_details",
          label: "Recherche par utilisateur",
        }),
        new ModelListTextFilter({
          field: "ip",
          name: "ip",
          label: "Recherche par IP (complète)",
        }),
        new ModelListDatetimeFilter({
          field: "timestamp__start",
          name: "timestamp__start",
          label: "Recherche par date postérieure à",
        }),
        new ModelListDatetimeFilter({
          field: "timestamp__end",
          name: "timestamp__end",
          label: "Recherche par date antérieure à",
        }),
      ],
    };
  }
}
