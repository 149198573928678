import { charField, DataModel, integerField, primaryField } from '@solidev/data';

export enum META_BASE_TYPE {
  FL = 'FL',
  SEA = 'MR',
  UNKNOWN = 'MS',
}

export class ArticleMetaBase extends DataModel {
  static override readonly __name: string = 'ArticleMetaBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'vvid',
    description: 'ID Vivalya (deprecated)',
    maxLength: 16,
    priority: -10,
  })
  public vvid!: string;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 100,
    priority: 900,
  })
  public code!: string;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: META_BASE_TYPE.UNKNOWN,
    required: true,
    maxLength: 2,
    choices: [
      {
        value: META_BASE_TYPE.FL,
        desc: 'Fruits et légumes',
      },
      {
        value: META_BASE_TYPE.SEA,
        desc: 'Marée',
      },
      {
        value: META_BASE_TYPE.UNKNOWN,
        desc: 'Non précisé',
      },
    ],
    priority: 800,
  })
  public type: string = META_BASE_TYPE.UNKNOWN;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 100,
    priority: 600,
  })
  public name!: string;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -100,
  })
  public orig_id!: number;

  @integerField({
    name: 'articles_count',
    description: "Nombre d'articles",
    priority: 400,
    readonly: true,
  })
  public articles_count?: number;

  public _display(): string {
    if (this.name && this.code !== this.name) {
      return `[${this.code}] ${this.name}`;
    }
    return `${this.code}`;
  }
}
