
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { SupportMessageCommentService} from "./support-message-comment.service";
import { SupportMessageComment } from "./support-message-comment";

@Injectable({
  providedIn: 'root'
})
export class SupportMessageCommentResolver implements Resolve<SupportMessageComment> {
  constructor(private _rs: SupportMessageCommentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SupportMessageComment> {
    return this._rs.fetch(+route.params["supportmessagecommentId"])
  }
}
