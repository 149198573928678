import { Component } from '@angular/core';

import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { TarifListComponent } from '../../../../models/tarifs/tarif/tarif-list/tarif-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Tarif } from '../../../../models/tarifs/tarif/tarif';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';

export interface TarifListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
}

export interface TarifListViewData extends TarifListViewParams {}

@Component({
  selector: 'lvadg-tarif-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifListComponent],
  templateUrl: './tarif-list-view.component.pug',
  styleUrls: ['./tarif-list-view.component.sass'],
})
export class TarifListViewComponent extends BaseListViewComponent<TarifListViewData, Tarif> {
  public tariftype_detail_route!: Link<TariftypeBase>;
  public default_fields = ['id', 'name', 'type_details', 'datestart', 'dateend', 'status', 'documents_details'];

  public override setRouteData(data: TarifListViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, this);
  }
}
