import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../../../../../models/structures/member/member';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { StorageListComponent } from '../../../../../models/structures/storage/storage-list/storage-list.component';
import { MEMBER_ROUTES } from '../menu';
import { MemberNavComponent } from '../_nav/member-nav.component';
import { map, Observable, Subject } from 'rxjs';
import { Storage } from '../../../../../models/structures/storage/storage';

export interface MemberStoragesViewParams extends BaseRouteParams {
  m_menu: MEMBER_ROUTES;
  storage_detail_route: RouteConfigItem;
}

export interface MemberStoragesViewData extends MemberStoragesViewParams {
  member: Member;
}

@Component({
  selector: 'lvadg-member-storages-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, StorageListComponent, MemberNavComponent],
  templateUrl: './member-storages-view.component.pug',
  styleUrls: ['./member-storages-view.component.sass'],
})
export class MemberStoragesViewComponent extends BaseDetailViewComponent<MemberStoragesViewData, Member> {
  public memberFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public storage_detail_route?: Link<Storage>;
  public default_fields: string[] = [];

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: MemberStoragesViewData) {
    super.setRouteData(data);
    this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.memberFilter$ = this.data$.pipe(map((data) => ({ member: data.member.id })));
  }
}
