import { Injectable } from '@angular/core';
import { Orderprocess } from './orderprocess';
import { Order } from '../order/order';
import { DataMessageService } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../components/utils/errormessage';

export interface OrderProcessActionResult {
  orderprocess: Orderprocess;
  order?: Order;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrderprocessActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action(
    ops: Orderprocess,
    action: string,
    body: Record<string, any>,
    messages: Record<string, string> = {}
  ): Promise<OrderProcessActionResult> {
    try {
      const res = await firstValueFrom(
        ops.action<Orderprocess, OrderProcessActionResult>('POST', 'action', {
          body: {
            action,
            ...body,
          },
        })
      );
      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { orderprocess: ops, message: message, success: false };
    }
  }
}
