
<ng-template #catalogSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4>Catalogue / ajout d'articles</h4>
    <button class="btn-close" (click)="offcanvas.close()"></button>
  </div>
  <div class="offcanvas-body" *ngIf="data$|async; let data">
    <lvadg-article-list [name]="data.route.name+'_catalog'" [actions]="['tariftype_add']" [filter]="catalogTarifTypeFilter$" [default_fields]="catalog_default_fields" (action)="catalogAction(data.tariftype, $event)" [reload]="reloadTarif$"></lvadg-article-list>
  </div>
</ng-template>
<ng-container *ngIf="tdata$|async; let data">
  <nav class="nav-tabs nav-justified" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="prices" *ngIf="data.tariftype.have_level(TTUL.storage) &amp;&amp; data.tariftype.lifecycle!==TTLC.UPLOAD"><a ngbNavLink>Lignes de tarif</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-header-action level="h2" [title]="data.tarif.name" subtitle="détail des prix">
            <ng-container actions><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a>
              <button class="btn btn-outline-warning btn-sm" *ngIf="canEdit(data, TTUL.pilote)" title="Mise à jour des articles" (click)="updateArticles(data)"><i class="bi bi-arrow-clockwise me-2"></i>Articles</button>
              <button class="btn btn-outline-warning btn-sm" *ngIf="canEdit(data, TTUL.pilote)" title="Ajout d'articles" (click)="openCatalog()"><i class="bi bi-plus-circle me-2"></i>Article</button>
              <data-safe-delete display="fbutton" [model]="data.tarif" *ngIf="canDelete(data)" (deleted)="tariftype_detail_route?.navigate($any({id: data.tarif.tariftype}))" [messages]="{success: 'Tarif supprimé avec succès', error: 'Erreur lors de la suppression du tarif'}"></data-safe-delete>
            </ng-container>
          </lvadg-header-action>
          <lvadg-article-tarif-list [name]="data.route.name+&quot;_&quot;+data.tariftype.code+&quot;_lines&quot;" [tarif]="data.tarif" [filter]="tarifFilter$" [reload]="reloadTarif$" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" [family_detail_route]="family_detail_route" [tarif_detail_route]="tarif_detail_route" [default_fields]="articletarif_default_fields"></lvadg-article-tarif-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="links" *ngIf="data.tariftype.have_level(TTUL.pilote) &amp;&amp; data.tariftype.lifecycle!==TTLC.UPLOAD"><a ngbNavLink>Tarifs liés</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>{{data.tarif.name}}<small class="ms-1">tarifs liés</small></h2>
            <div class="actions"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a></div>
          </div>
          <lvadg-tariftype-relation-list [name]="data.route.name+&quot;_links&quot;" [default_fields]="relations_default_fields" [tariftype_detail_route]="tariftype_detail_route" [tarif_detail_route]="tarif_detail_route" [dest_tarif]="data.tarif" [filter]="tarifFilter$" [reload]="reloadTarif$"></lvadg-tariftype-relation-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="details" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>{{data.tarif.name}}<small class="ms-1">détails tarif</small></h2>
            <div class="actions"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a>
              <data-safe-delete display="fbutton" [model]="data.tarif" *ngIf="canDelete(data)" (deleted)="tariftype_detail_route?.navigate($any({id: data.tarif.tariftype}))" [messages]="{success: 'Tarif supprimé avec succès', error: 'Erreur lors de la suppression du tarif'}"></data-safe-delete>
            </div>
          </div>
          <lvadg-tarif-manage [tarif]="data.tarif" [tariftype]="data.tariftype"></lvadg-tarif-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="documents" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Documents</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>{{data.tarif.name}}<small class="ms-1">documents associés</small></h2>
            <div class="actions"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a></div>
          </div>
          <div class="row mb-3" *ngIf="data.tariftype.have_level(TTUL.sadmin)">
            <div class="col-6">
              <h4>Ajout de document</h4>
              <lvadg-tarif-document-create [tarif]="data.tarif"></lvadg-tarif-document-create>
            </div>
            <div class="col-6">
              <h4>Exportations</h4>
              <lvadg-tarif-export [tarif]="data.tarif" (exported)="reloadTarif$.next(true)"></lvadg-tarif-export>
            </div>
          </div>
          <lvadg-tarif-document-list [name]="data.route.name+&quot;_documents&quot;" [default_fields]="documents_default_fields" [actions]="['delete']" [filter]="tarifFilter$" [reload]="reloadTarif$"></lvadg-tarif-document-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="notifications" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Notifications</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>{{data.tarif.name}}<small class="ms-1">notifications du tarif</small></h2>
            <div class="actions"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a></div>
          </div>
          <lvadg-tarif-notification-list [name]="data.route.name+&quot;_notifications&quot;" [default_fields]="tn_default_fields" [filter]="tarifFilter$" [reload]="reloadTarif$"></lvadg-tarif-notification-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="destinataires" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Suivi des envois</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>{{data.tarif.name}}<small class="ms-1">suivi des envois</small></h2>
            <div class="actions"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route($any({id: data.tarif.tariftype}))"><i class="bi bi-table me-2"></i>Modèle</a></div>
          </div>
          <lvadg-tarif-notification-status-list [name]="data.route.name+&quot;_notifications_statuses&quot;" [default_fields]="tns_default_fields" [filter]="tarifFilter$" [reload]="reloadTarif$"></lvadg-tarif-notification-status-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="lifecycle"  *ngIf="data.tariftype.have_level(TTUL.pilote)"><a class="fw-bold" ngbNavLink>Cycle de vie</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>{{data.tarif.name}}<small class="ms-1">cycle de vie</small></h2>
            <div class="actions"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route($any({id: data.tarif.tariftype}))"><i class="bi bi-table me-2"></i>Modèle</a></div>
          </div>
          <!--lvadg-tarif-lifecycle([tarif]="data.tarif", [tariftype]="data.tariftype",-->
          <!--  [nav]="navId", (changed)="updateTarif($event)")-->
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>