import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarif } from '../article-tarif';
import { ArticleTarifItem } from '../../article-tarif-item/article-tarif-item';
import { ArticleTarifStatus } from '../article-tarif.base';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Editor } from './editor';
import { StorageBase } from '../../../structures/storage/storage.base';
import { RnmmarcheBase } from '../../../rnm/rnmmarche/rnmmarche.base';
import { PRICE_FACTOR } from '../../../../constants';
import { TarifActionResult } from '../../tarif/tarif-action.service';
import { GenericregionBase } from '../../../locations/genericregion/genericregion.base';

@Component({
  selector: 'lvadg-article-tarif-price-dispedit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './article-tarif-price-dispedit.component.pug',
  styleUrls: ['./article-tarif-price-dispedit.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleTarifPriceDispeditComponent implements OnInit, OnChanges, OnDestroy {
  /** Articletarif instance (mandatory) */
  @Input() public at!: ArticleTarif;
  /** Articletarifitem instance */
  @Input() public ati?: ArticleTarifItem;
  /** Editor type : price/decondi/storage/region/rnm */
  @Input() public type: 'price' | 'decondi' | 'storage' | 'region' | 'rnm' = 'price';
  /** Editor mode : price/dispo/dispo+price */
  @Input() public mode: 'price' | 'dispo' | 'dispoprice' = 'price';
  /** Is editable : boolean */
  @Input() public editable: boolean = true;
  /** Save on blur if true */
  @Input() saveOnBlur: boolean = false;
  /** Editor index in table (from row index) */
  @Input() public index!: number;
  /** Save function */
  @Input() public saveFn?: (
    editor: Editor,
    value: number | string | null | undefined,
    quantity: number | null | undefined,
  ) => Promise<TarifActionResult>;
  /** Focus subject, tells which editor should receive focus */
  @Input() public focus$?: Observable<Editor>;

  @Input() public storage?: StorageBase;
  @Input() public region?: GenericregionBase;
  @Input() public rnm?: RnmmarcheBase;

  /** Emit editorData to be registered (on creation and changes) */
  @Output() public register = new EventEmitter<Editor>();
  /** Emit editorData to be unregistered (on destory and changes) */
  @Output() public unregister = new EventEmitter<Editor>();
  /** Emit to focus next editor */
  @Output() public next = new EventEmitter<Editor>();
  /** Emit to focus previous editor */
  @Output() public previous = new EventEmitter<Editor>();
  /** Editor have been selected, sends current articleTarif */
  @Output() public selected = new EventEmitter<ArticleTarif>();

  /** Editor view child, for focus and selection */
  @ViewChild('editor') editorEl?: ElementRef<HTMLInputElement>;
  @ViewChild('dispo') dispoEl?: ElementRef<HTMLInputElement>;

  /** Current editor data */
  public editor!: Editor;
  public previousPrice?: string;
  public priceDisp?: string;
  public changed: boolean = false;
  /** Availability */
  public available: number = 0;

  /** Editor control */
  public editCtrl = new FormControl<string | null | undefined>('');
  public checkCtrl = new FormControl<boolean | null | undefined>(undefined);
  private _subscriptions = new Subject<void>();

  /**
   * Return true if editor is editable (editable from column and articleTarifTemplate status)
   */
  public get isEditable() {
    return this.editable && this.at.status != ArticleTarifStatus.INACTIVE;
  }

  public get isPristine() {
    if (this.ati) {
      return this.ati.status === ArticleTarifStatus.TOVALIDATE;
    }
    if (this.storage || this.region) {
      return true;
    }
    return this.at.status === ArticleTarifStatus.TOVALIDATE;
  }

  public ngOnInit() {
    this.editor = new Editor({
      at: this.at,
      type: this.type,
      mode: this.mode,
      index: this.index,
      storage: this.storage,
      region: this.region,
      rnm: this.rnm,
    });
    if (this.focus$) {
      this.focus$.pipe(takeUntil(this._subscriptions)).subscribe((editor) => {
        if (editor.type === this.editor.type && editor.id === this.editor.id && this.isEditable) {
          if (this.editorEl) {
            this.editorEl.nativeElement.focus({ preventScroll: false });
            this.editorEl.nativeElement.select();
          } else if (this.dispoEl) {
            this.dispoEl.nativeElement.focus({ preventScroll: false });
            this.dispoEl.nativeElement.select();
          }
        }
      });
    }
    if (this.type === 'rnm') {
      this.editable = false;
    }
  }

  public ngOnChanges() {
    this.editor = new Editor({
      at: this.at,
      type: this.type,
      mode: this.mode,
      index: this.index,
      storage: this.storage,
      region: this.region,
      rnm: this.rnm,
    });
    if (this.isEditable) {
      this.register.emit(this.editor);
    } else {
      this.unregister.emit(this.editor);
    }

    switch (this.type) {
      case 'storage':
        if (this.storage) {
          this.ati = this.at.bystorage_items_details.get(this.storage.id);
          if (this.ati) {
            this.setPrice(this.ati.price, this.ati.status);
            this.available = this.ati.available;
            this.checkCtrl.setValue(!!this.available, { emitEvent: false });
          }
        }
        break;
      case 'region':
        if (this.region) {
          this.ati = this.at.byregion_items_details.get(this.region.id);
          if (this.ati) {
            this.setPrice(this.ati.price, this.ati.status);
            this.available = this.ati.available;
            this.checkCtrl.setValue(!!this.available, { emitEvent: false });
          }
        }
        break;
      case 'price':
        this.setPrice(this.at.price, this.at.status);
        break;
      case 'rnm':
        throw Error('Not implemented');
      case 'decondi':
        throw Error('Not implemented');
    }
  }

  public ngOnDestroy() {
    this.unregister.emit(this.editor);
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  /**
   * Set form price from rprice number or null
   * Use formatPrice to get price format
   * @param rprice
   * @param status
   */
  public setPrice(rprice: number | null, status: ArticleTarifStatus) {
    if (status === ArticleTarifStatus.INACTIVE) {
      this.priceDisp = 'NA';
      this.previousPrice = this.priceDisp;
      this.editCtrl.setValue('NA', { emitEvent: false });
    } else {
      if (rprice !== null && rprice !== 0) {
        const pd = this.formatPrice(rprice);
        this.editCtrl.setValue(pd, { emitEvent: false });
        this.previousPrice = pd;
        this.priceDisp = `${pd} €`;
      } else {
        this.priceDisp = 'NC';
        this.previousPrice = 'NC';
        this.editCtrl.setValue('NC', { emitEvent: false });
      }
    }
  }

  public toggleAvailable(event: any, prevent: boolean = false) {
    if (prevent) {
      event.preventDefault();
    }
    this.available = this.available ? 0 : 1;
    this.checkCtrl.setValue(!!this.available, { emitEvent: false });
    this.save(false, false);
  }

  /**
   * Format EU price.
   * @param price number
   */
  public formatPrice(price: number): string {
    return (price / PRICE_FACTOR).toLocaleString('fr-FR', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
      useGrouping: false,
    });
  }

  onFocus($event: FocusEvent | MouseEvent) {
    this.selected.emit(this.at);
  }

  onBlur($event: FocusEvent) {
    if (this.saveOnBlur) {
      this.save(false, false);
    }
  }

  public async save(up: boolean, down: boolean) {
    const value = this.editCtrl.value;
    let move = up || down;
    let save = false;
    // Save conditions
    if (this.mode === 'price' && this.type === 'price') {
      save = this.at.status === ArticleTarifStatus.TOVALIDATE || value !== this.previousPrice;
    } else if (this.mode === 'price' && this.type === 'storage') {
      save = !this.ati || this.ati.status === ArticleTarifStatus.TOVALIDATE || value !== this.previousPrice;
    } else if (this.mode === 'price' && this.type === 'region') {
      save = !this.ati || this.ati.status === ArticleTarifStatus.TOVALIDATE || value !== this.previousPrice;
    } else if ((this.mode === 'dispo' || this.mode === 'dispoprice') && this.type === 'storage') {
      save = true;
    } else if ((this.mode === 'dispo' || this.mode === 'dispoprice') && this.type === 'region') {
      save = true;
    }

    if (save && this.saveFn) {
      const res = await this.saveFn(this.editor, this.editCtrl.value, this.available);
      if (!res.success) {
        move = false;
      }
    }
    if (move) {
      if (up) {
        this.next.emit(this.editor);
      }
      if (down) {
        this.previous.emit(this.editor);
      }
    }
  }

  onCheckCellFocus($event: MouseEvent) {
    this.selected.emit(this.at);
    if (this.dispoEl) {
      this.dispoEl.nativeElement.focus({ preventScroll: false });
      this.dispoEl.nativeElement.select();
    }
  }
}
