
<button class="btn btn-primary float-end" (click)="create=!create"><i class="bi bi-plus-circle me-2"></i>Ajouter une permission</button>
<h1 class="page-title">Permissions de dépôt</h1>
<div class="card my-3" *ngIf="create">
  <div class="card-header">Ajouter une permission</div>
  <div class="card-body">
    <lvadg-orderprocessstorage-permission-create (created)="reload$.next(); create=false" (cancelled)="create=false"></lvadg-orderprocessstorage-permission-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-orderprocessstorage-permission-list [name]="data.route.name" [detail_route]="detail_route" [reload]="reload$" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [orderprocess_detail_route]="orderprocess_detail_route" [orderprocessstorage_detail_route]="orderprocessstorage_detail_route"></lvadg-orderprocessstorage-permission-list>
</ng-container>