import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifNotificationStatus } from '../tarif-notification-status';
import { TarifNotificationStatusService } from '../tarif-notification-status.service';
import {
  FieldsParams,
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { TarifBase } from '../../tarif/tarif.base';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { UserBase } from '../../../users/user/user.base';
import { TarifNotificationBase } from '../../tarif-notification/tarif-notification.base';
import { TarifNotificationStatusStatus } from '../tarif-notification-status.base';
import { TarifNotification } from '../../tarif-notification/tarif-notification';

@Component({
  selector: 'lvadg-tarif-notification-status-list',
  standalone: true,
  templateUrl: './tarif-notification-status-list.component.pug',
  styleUrls: ['./tarif-notification-status-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, UserDisplayComponent],
})
export class TarifNotificationStatusListComponent extends ModellistComponent<TarifNotificationStatus> {
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public tarifnotification_detail_route?: Link<TarifNotificationBase>;

  constructor(
    coll: TarifNotificationStatusService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'type', 'priority', 'status'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Recherche par état',
          help: 'Sélectionnez un état de notification',
          model: TarifNotificationStatus,
        }),
        new ModelListSelectFilter({
          field: 'notification__type',
          name: 'type',
          label: 'Recherche par type',
          model: TarifNotification,
          help: 'Sélectionnez un type de notification',
          mfield: 'type',
        }),
        new ModelListSelectFilter({
          field: 'notification__priority',
          name: 'priority',
          label: 'Recherche par priorité',
          model: TarifNotification,
          help: 'Sélectionnez une priorité',
          mfield: 'priority',
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const f = super.getFields();
    f.custom?.push({
      name: 'notification__tariftype',
      label: 'Modèle de tarif',
    });
    f.custom?.push({
      name: 'notification__tarif',
      label: 'Tarif',
    });
    f.custom?.push({
      name: 'notification__created',
      label: 'Date de création notification',
    });
    f.custom?.push({
      name: 'notification__updated',
      label: 'Date de MAJ notification',
    });
    f.custom?.push({
      name: 'notification__status',
      label: 'Etat notification',
    });
    f.custom?.push({
      name: 'notification__type',
      label: 'Type de notification',
    });
    f.custom?.push({
      name: 'notification__priority',
      label: 'Priorité',
    });

    return f;
  }

  public override getRowClasses(row: TarifNotificationStatus): string[] {
    const cls = super.getRowClasses(row);
    if (
      [
        TarifNotificationStatusStatus.OPENED,
        TarifNotificationStatusStatus.CLICKED,
      ].indexOf(row.status) !== -1
    ) {
      cls.push('text-success');
    }
    if ([TarifNotificationStatusStatus.SENT].indexOf(row.status) !== -1) {
      cls.push('text-info');
    }
    if (
      [
        TarifNotificationStatusStatus.INVALID,
        TarifNotificationStatusStatus.FATAL_ERROR,
      ].indexOf(row.status) !== -1
    ) {
      cls.push('table-danger');
    }
    if (
      [TarifNotificationStatusStatus.TEMPORARY_ERROR].indexOf(row.status) !== -1
    ) {
      cls.push('table-warning');
    }

    return cls;
  }
}
