import {
  charField,
  DataModel,
  datetimeField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum RnmmarcheType {
  FL = 'FL',
  SEA = 'MR',
}

export class RnmmarcheBase extends DataModel {
  static override readonly __name: string = 'RnmmarcheBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 5,
  })
  public code!: string;

  @integerField({
    name: 'facode',
    description: 'Code FA',
  })
  public facode!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: RnmmarcheType.FL,
    maxLength: 2,
    choices: [
      { value: RnmmarcheType.FL, desc: 'Grossistes : F&L' },
      { value: RnmmarcheType.SEA, desc: 'Grossistes : marée' },
    ],
  })
  public type: RnmmarcheType = RnmmarcheType.FL;

  @charField({
    name: 'sname',
    description: 'Nom court',
    maxLength: 50,
  })
  public sname!: string;

  @charField({
    name: 'lname',
    description: 'Nom complet',
    maxLength: 200,
  })
  public lname!: string;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;

  public get _display(): string {
    return `[${this.code}] ${this.lname}`;
  }
}
