
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { LogUsage } from "./log-usage";

@Injectable({
  providedIn: 'root'
})
export class LogUsageService extends Collection<LogUsage> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/logusages", LogUsage);
  }
}
