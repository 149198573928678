import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Resto } from '../../../../../models/structures/resto/resto';
import { RESTO_ROUTES } from '../menu';
import { RestoNavComponent } from '../_nav/resto-nav.component';
import { UserListComponent } from '../../../../../models/users/user/user-list/user-list.component';
import { User } from '../../../../../models/users/user/user';
import { Group } from '../../../../../models/users/group/group';

export interface RestoUsersViewParams extends BaseRouteParams {
  r_menu: RESTO_ROUTES;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
}

export interface RestoUsersViewData extends RestoUsersViewParams {
  resto: Resto;
}

@Component({
  selector: 'lvadg-resto-users-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, RestoNavComponent, UserListComponent, IconComponent],
  templateUrl: './resto-users-view.component.pug',
  styleUrls: ['./resto-users-view.component.sass'],
})
export class RestoUsersViewComponent extends BaseDetailViewComponent<RestoUsersViewData, Resto> {
  public restoFilter$!: Observable<FilterDefaults>;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public reload$ = new Subject<void | boolean>();

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: RestoUsersViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link<Group>(data.group_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.restoFilter$ = this.data$.pipe(map((d) => ({ resto: d.resto.id })));
  }
}
