import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifLifecycleStep } from '../tarif-lifecycle-step';
import { TarifLifecycleStepService } from '../tarif-lifecycle-step.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-list',
  standalone: true,
  templateUrl: './tarif-lifecycle-step-list.component.pug',
  styleUrls: ['./tarif-lifecycle-step-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, TariftypeDisplayComponent],
})
export class TarifLifecycleStepListComponent extends ModellistComponent<TarifLifecycleStep> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;

  constructor(
    coll: TarifLifecycleStepService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
