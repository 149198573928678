import { Component, Input, model, signal } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Product } from "../product";
import { ProductService } from "../product.service";
import {
  BanAdapter,
  FieldsParams,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListFilterGroup,
  ModelListGeodistanceFilter,
  ModelListNumberFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  ModelListTreeFilter,
  SafeDeleteComponent,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { LabelService } from "../../label/label.service";
import { LABEL_TYPE } from "../../label/label.base";
import {
  delay,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
} from "rxjs";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { LabelDisplayComponent } from "../../label/label-display/label-display.component";
import { ProducerBase } from "../../../structures/producer/producer.base";
import { Producer } from "../../../structures/producer/producer";
import { ProducerDisplayComponent } from "../../../structures/producer/producer-display/producer-display.component";
import { RelationDisplayComponent } from "../../../structures/relation/relation-display/relation-display.component";
import { Family } from "../../family/family";
import { FamilyDisplayComponent } from "../../family/family-display/family-display.component";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { MemberService } from "../../../structures/member/member.service";
import { ProducerService } from "../../../structures/producer/producer.service";
import { StorageService } from "../../../structures/storage/storage.service";
import { HttpClient } from "@angular/common/http";
import { DistanceCacheService } from "../../../locations/distance/distance-cache.service";
import { DistanceCacheDisplayComponent } from "../../../locations/distance/distance-cache-display/distance-cache-display.component";
import { GeodepartementService } from "../../../locations/geodepartement/geodepartement.service";
import { GeoregionService } from "../../../locations/georegion/georegion.service";
import { GeocommuneService } from "../../../locations/geocommune/geocommune.service";
import { FamilyService } from "../../family/family.service";
import { FAMILY_TYPE } from "../../family/family.base";
import { StorageBase } from "../../../structures/storage/storage.base";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
  PRODUCT_USER_LEVELS,
} from "../product.base";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { ProducerDocumentDisplayComponent } from "../../../structures/producer-document/producer-document-display/producer-document-display.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { SelectionService } from "../../../../components/selection/selection.service";
import { SelectItemComponent } from "../../../../components/selection/select-item/select-item.component";
import { SelectHeaderComponent } from "../../../../components/selection/select-header/select-header.component";
import { QuickprintButtonComponent } from "../../../layouts/print/quickprint-button/quickprint-button.component";
import { NgStringPipesModule } from "ngx-pipes";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { ProductsMapComponent } from "../../../locations/mapping/products-map/products-map.component";
import { IocodeDisplayComponent } from "../../../lvadgio/iocode/iocode-display/iocode-display.component";
import { CdataKey } from "../../../cdata/cdata-key/cdata-key";
import { CdataDisplayComponent } from "../../../cdata/cdata/cdata-display/cdata-display.component";
import { ProductManageComponent } from "../product-manage/product-manage.component";

@Component({
  selector: "lvadg-product-list",
  standalone: true,
  templateUrl: "./product-list.component.pug",
  styleUrls: ["./product-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    SeasonsManageComponent,
    LabelDisplayComponent,
    ImageDisplayComponent,
    ProducerDisplayComponent,
    ProducerDocumentDisplayComponent,
    LocationDisplayComponent,
    RelationDisplayComponent,
    FamilyDisplayComponent,
    SafeDeleteComponent,
    DistanceCacheDisplayComponent,
    IconComponent,
    SelectItemComponent,
    SelectHeaderComponent,
    QuickprintButtonComponent,
    NgStringPipesModule,
    TodoComponent,
    ProductsMapComponent,
    IocodeDisplayComponent,
    CdataDisplayComponent,
    ProductManageComponent,
  ],
})
export class ProductListComponent extends ModellistComponent<Product> {
  @Input() public producer_detail_route?: Link<Producer | ProducerBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public local?: LOCAL_FILTERS;
  @Input() public type?: PRODUCT_TYPES;
  @Input() public family_detail_route?: Link<Family>;
  @Input() public storage?: StorageBase;
  @Input() public producer?: ProducerBase;
  @Input() public select = true;
  /** Custom data allowed keys */
  @Input() public cdatakeys: CdataKey[] = [];
  public displayEditors = model(false);
  public print_filters_whitelist = signal<string[]>([]);

  public readonly PRODUCT_TYPES = PRODUCT_TYPES;
  public readonly PUL = PRODUCT_USER_LEVELS;
  public seasons_perline: number = 6;
  public simple_name = false;

  public producerDistanceRef$!: Observable<{
    pos: [number, number];
    label: string;
  }>;
  public xlsxMode = false;
  public storageDistanceRef$!: Observable<{
    pos: [number, number];
    label: string;
  }>;

  constructor(
    public labels$$: LabelService,
    coll: ProductService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _members: MemberService,
    private _storages: StorageService,
    private _producers: ProducerService,
    private _http: HttpClient,
    private _distc: DistanceCacheService,
    private _departments: GeodepartementService,
    private _regions: GeoregionService,
    private _communes: GeocommuneService,
    private _families: FamilyService,
    private _select: SelectionService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    this.print_filters_whitelist.set([
      "local",
      "type",
      "storages",
      "storage_near",
      "storage_producer_distance",
      "members",
      "filter_storage_relations",
      "storage",
      "producer",
    ]);
    const adapter = new BanAdapter(
      this._http,
      "https://gis.lavieadugout.fr/geocoder/search",
    );
    const product_filters = new ModelListFilterGroup({
      name: "fl_product_filters",
      label: "Caractéristiques produit",
      desc: "Filtrer sur les caractéristiques du produit",
      position: 1,
      filters: [
        // Recherche par type
        new ModelListSelectFilter({
          field: "ftype",
          name: "ftype",
          label: "Recherche par type",
          model: Product,
          mfield: "type",
        }),
        // Recherche par état Égalim
        new ModelListSelectFilter({
          field: "egalim_status",
          name: "egalim_status",
          label: "Recherche par état Égalim",
          model: Product,
        }),
        // Recherche par labels F&L
        new ModelListSelectMultiFilter({
          field: "labels_fl",
          name: "labels_fl",
          label: "Recherche par label(s) F&L",
          choices: this.labels$$.byType(LABEL_TYPE.FL).pipe(
            map((v) =>
              v.map((l) => {
                return { desc: l.name, value: l.id };
              }),
            ),
          ),
        }),
        // Recherche par labels marée
        new ModelListSelectMultiFilter({
          field: "labels_sea",
          name: "labels_sea",
          label: "Recherche par label(s) marée",
          choices: this.labels$$.byType(LABEL_TYPE.SEA).pipe(
            map((v) =>
              v.map((l) => {
                return { desc: l.name, value: l.id };
              }),
            ),
          ),
        }),

        // Saisonnalité
        new ModelListSelectMultiFilter({
          field: "seasons",
          name: "seasons",
          label: "Saisonnalité(s)",
          choices: [
            { desc: "Janvier", value: "1" },
            { desc: "Février", value: "2" },
            { desc: "Mars", value: "3" },
            { desc: "Avril", value: "4" },
            { desc: "Mai", value: "5" },
            { desc: "Juin", value: "6" },
            { desc: "Juillet", value: "7" },
            { desc: "Août", value: "8" },
            { desc: "Septembre", value: "9" },
            { desc: "Octobre", value: "10" },
            { desc: "Novembre", value: "11" },
            { desc: "Décembre", value: "12" },
          ],
        }),
      ],
    });

    const family_filters = new ModelListFilterGroup({
      name: "family_filters",
      label: "Filtres famille",
      desc: "Filtrer sur les familles",
      position: 2,
      filters: [
        // Famille (exacte)
        // new ModelListTreeFilter({
        //   field: 'exact_family',
        //   name: 'exact_family',
        //   label: 'Famille (exacte) - sélection',
        //   help: '---------------------',
        //   filter: {
        //     fields: ['id', 'name', 'parent'].join(','),
        //     type: FAMILY_TYPE.VIVALYA,
        //   },
        //   display: (v) => `${v.name}`,
        //   collection: this._families,
        // }),
        // Famille et sous-familles
        new ModelListTreeFilter({
          field: "parent_family",
          name: "parent_family",
          label: "Famille et sous-famille - sélection",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          help: "---------------------",
          display: (v) => `${v.name}`,
          collection: this._families,
        }),
        // Recherche par famille(s) exacte(s)
        // new ModelListAutocompleteMultiFilter({
        //   field: 'exact_families',
        //   name: 'exact_families',
        //   label: 'Recherche par famille(s) exacte(s)',
        //
        //   collection: this._families,
        // }),
        // Recherche par famille(s) contenant
        new ModelListAutocompleteMultiFilter({
          field: "parent_families",
          name: "parent_families",
          label: "Recherche par famille(s) et sous-familles",
          collection: this._families,
        }),
      ],
    });

    const producer_filters = new ModelListFilterGroup({
      name: "producers_filters",
      label: "Filtres producteur",
      desc: "Filtrer sur les producteurs",
      position: 3,
      filters: [
        // Recherche par producteurs
        new ModelListAutocompleteMultiFilter({
          field: "producers",
          name: "producers",
          label: "Recherche par producteur(s)",
          collection: this._producers,
        }),
        // Recherche par producteur à proximité
        new ModelListGeodistanceFilter({
          name: "producer_near",
          field: "producer_near",
          label: "Recherche producteur à proximité de  (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: "Ville ou adresse (en France)",
          geolocator: (pos: string) => adapter.search(pos),
        }),
        // Commune du producteur
        new ModelListAutocompleteMultiFilter({
          field: "producer_communes",
          name: "producer_communes",
          label: "Commune(s) du producteur",
          collection: this._communes,
          filter: { country_code: "FR" },
        }),
        // Département du producteur
        new ModelListAutocompleteMultiFilter({
          field: "producer_departments",
          name: "producer_departments",
          label: "Département(s) du producteur",
          collection: this._departments,
          filter: { country_code: "FR" },
        }),
        // Région du producteur
        new ModelListAutocompleteMultiFilter({
          field: "producer_regions",
          name: "producer_regions",
          label: "Région(s) du producteur",
          collection: this._regions,
          filter: { country_code: "FR" },
        }),
      ],
    });

    const storage_filters = new ModelListFilterGroup({
      name: "storage_filters",
      label: "Filtres dépôt",
      desc: "Filtrer sur les dépôts",
      position: 4,
      filters: [
        // Recherche par dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storages",
          name: "storages",
          label: "Recherche par dépôt(s)",
          collection: this._storages,
        }),
        // Dépôt proche de
        new ModelListGeodistanceFilter({
          name: "storage_near",
          field: "storage_near",
          label: "Recherche dépôt à proximité de (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: "Ville ou adresse (en France)",
          geolocator: (pos: string) => adapter.search(pos),
        }),
        // Recherche par distance dépôt-producteur (au moins un)
        new ModelListNumberFilter({
          name: "storage_producer_distance",
          field: "storage_producer_distance",
          label: "Recherche par distance dépôt-producteur",
          desc: "Distance en km",
        }),
        // Recherche par adhérent
        new ModelListAutocompleteMultiFilter({
          field: "members",
          name: "members",
          label: "Recherche par adhérent(s)",
          collection: this._members,
        }),
        // Commune du dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storage_communes",
          name: "storage_communes",
          label: "Commune(s) du dépôt",
          collection: this._communes,
          filter: { country_code: "FR" },
        }),
        // Département du dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storage_departments",
          name: "storage_departments",
          label: "Département(s) du dépôt",
          collection: this._departments,
          filter: { country_code: "FR" },
        }),
        // Région du dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storage_regions",
          name: "storage_regions",
          label: "Région(s) du dépôt",
          collection: this._regions,
          filter: { country_code: "FR" },
        }),
      ],
    });

    const other_filters = new ModelListFilterGroup({
      name: "other_filters",
      label: "Autres filtres",
      desc: "Filtrer sur d'autres critères",
      position: 5,
      filters: [
        // Filtrer les dépôts
        // Au niveau de l'API, si l'utilisateur n'est pas superadmin, le filtrage est automatique
        // TODO: masquer ce filtre si l'utilisateur n'est pas superadmin
        new ModelListSelectFilter({
          name: "filter_storage_relations",
          field: "filter_storage_relations",
          label: "Filtrer les dépôts",
          choices: [
            { desc: "Afficher tous les dépôts", value: "0" },
            { desc: "Filtrer les dépôts", value: "1" },
          ],
        }),
        new ModelListSelectFilter({
          name: "have_offer",
          field: "have_offer",
          label: "Présent dans au moins une offre ?",
          choices: [
            { desc: "Oui", value: "any" },
            { desc: "Oui, avec un lien article", value: "withlink" },
            { desc: "Non", value: "none" },
          ],
        }),
      ],
    });

    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "egalim_status",
          this.type === PRODUCT_TYPES.LVADG_SEA ? "labels_sea" : "labels_fl",
          "producers",
          "parent_families",
          "storages",
          "producer_near",
          "storage_producer_distance",
        ],
      filters: [
        // Recherche par texte
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        product_filters,
        family_filters,
        producer_filters,
        storage_filters,
        other_filters,
      ],
    };
  }

  public override getFields(): FieldsParams {
    const flds = super.getFields();
    flds.custom!.push({
      name: "search_producer_near_distances",
      label: "Proximité producteur",
      priority: 140,
    });
    flds.custom!.push({
      name: "search_storage_near_distances",
      label: "Proximité dépôt",
      priority: 140,
    });
    if (this.select) {
      flds.custom?.push({
        name: "select",
        label: "Sélection",
        priority: 2000,
      });
      if (!flds.defaults) {
        flds.defaults = [];
      }
      flds.defaults.unshift("select");
    }
    // Add custom data fields
    let i = 0;
    for (const k of this.cdatakeys) {
      flds.custom!.push({
        name: `cdata_${k.id}`,
        label: k.name,
        priority: 500 + i,
      });
      i++;
    }

    return flds;
  }

  public override preNgOnInit() {
    super.preNgOnInit();
    if (!this.filter) {
      this.filter = of({
        local: this.local,
        type: this.type,
        storage: this.storage?.id,
        producer: this.producer?.id,
      });
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    const producerDistance = this.list.filters.enabled.pipe(
      mergeMap((v) => v),
      filter((v) => v.name === "producer_near"),
      filter((v) => !!(v.get() && v.get()!.value)),
    );
    this.producerDistanceRef$ = producerDistance.pipe(
      delay(1000),
      map((v) => {
        const val = v.get()!.value;
        const label = v.get()!.desc;
        return {
          label,
          pos: [+val.split(",")[2], +val.split(",")[3]],
        };
      }),
    );

    const storageDistance = this.list.filters.enabled.pipe(
      mergeMap((v) => v),
      filter((v) => v.name === "storage_near"),
      filter((v) => !!(v.get() && v.get()!.value)),
    );
    this.storageDistanceRef$ = storageDistance.pipe(
      delay(1000),
      map((v) => {
        const val = v.get()!.value;
        const label = v.get()!.desc;
        return {
          label,
          pos: [+val.split(",")[2], +val.split(",")[3]],
        };
      }),
    );
    this.dispPrint = true;
    if (this.select) {
      this.selection = this._select.getSelection("articles", this.list);
    }
  }

  public toggleSeasons() {
    const perline = [2, 3, 4, 6, 12];
    const perlineIndex = perline.indexOf(this.seasons_perline);
    if (perlineIndex < perline.length - 1) {
      this.seasons_perline = perline[perlineIndex + 1];
    } else {
      this.seasons_perline = perline[0];
    }
  }

  public override getRowClasses(row: Product): string[] {
    const cls = super.getRowClasses(row);
    if (row.in_offer) {
      cls.push("table-primary");
    }
    return cls;
  }

  public async editProduct(row: Product) {
    const product = await firstValueFrom(this.coll.fetch(row.id));
    this.ofc(product, "edit", null, {
      position: "end",
      reload: true,
      panelClass: "w-75",
    });
  }
}
