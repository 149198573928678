import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Process } from '../../../../../models/catalog/meta/process/process';

export interface ProcessDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface ProcessDetailViewData extends ProcessDetailViewParams {
  process: Process;
}
