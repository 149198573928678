
<div class="row g-3 mt-3">
  <div class="col-md-3" *ngFor="let m of menu$|async">
    <ng-container *ngIf="!m.children || m.children.length == 0">
      <div class="card h-100">
        <div class="card-header text-uppercase">{{m.title}}</div>
        <nav class="list-group list-group-flush"><a class="list-group-item list-group-item-action" [routerLink]="m.route"><i class="bi me-2" [ngClass]="m.icon"></i>{{m.title}}</a></nav>
      </div>
    </ng-container>
    <ng-container *ngIf="m.children &amp;&amp; m.children.length">
      <div class="card h-100">
        <div class="card-header text-uppercase"><i class="bi me-2" [ngClass]="m.icon"></i><a class="fw-bold text-black" [routerLink]="m.route">{{m.title}}</a></div>
        <nav class="list-group list-group-flush mxh-200">
          <ng-container *ngFor="let s of m.children"><a class="list-group-item list-group-item-action" [routerLink]="s.route"><i class="bi me-2" [ngClass]="s.icon"></i>{{s.title}}</a></ng-container>
        </nav>
      </div>
    </ng-container>
  </div>
</div>