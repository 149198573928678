import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Storage } from '../../../../../models/structures/storage/storage';
import { STORAGE_ROUTES } from '../menu';
import { UserListComponent } from '../../../../../models/users/user/user-list/user-list.component';
import { User } from '../../../../../models/users/user/user';
import { Group } from '../../../../../models/users/group/group';
import { StorageNavComponent } from '../_nav/storage-nav.component';

export interface StorageUsersViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
}

export interface StorageUsersViewData extends StorageUsersViewParams {
  storage: Storage;
}

@Component({
  selector: 'lvadg-storage-users-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, StorageNavComponent, IconComponent, UserListComponent],
  templateUrl: './storage-users-view.component.pug',
  styleUrls: ['./storage-users-view.component.sass'],
})
export class StorageUsersViewComponent extends BaseDetailViewComponent<StorageUsersViewData, Storage> {
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public storageFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageUsersViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link<Group>(data.group_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(map((d) => ({ storage: d.storage.id })));
  }
}
