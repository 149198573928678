import { Component } from "@angular/core";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { PROVIDER_ROUTES } from "../menu";
import { Provider } from "../../../../../models/structures/provider/provider";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { map, Observable, Subject } from "rxjs";
import { PrintContext } from "../../../../../models/layouts/print-settings";
import { ARTICLE_PRINT_SETTINGS } from "../../../../../models/catalog/article/article-print-settings";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { ProviderNavComponent } from "../_nav/provider-nav.component";
import { ArticleListComponent } from "../../../../../models/catalog/article/article-list/article-list.component";

export interface ProviderArticlesViewParams extends BaseRouteParams {
  pr_menu: PROVIDER_ROUTES;
  article_detail_route: RouteConfigItem;
}

export interface ProviderArticlesViewData extends ProviderArticlesViewParams {
  provider: Provider;
}

@Component({
  selector: "lvadg-provider-articles-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProviderNavComponent,
    ArticleListComponent,
    IconComponent,
  ],
  templateUrl: "./provider-articles-view.component.pug",
  styleUrl: "./provider-articles-view.component.sass",
})
export class ProviderArticlesViewComponent extends BaseDetailViewComponent<
  ProviderArticlesViewData,
  Provider
> {
  public article_detail_route?: Link<Article>;
  public providerFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields: string[] = [
    "code",
    "libelle",
    "clogo_details",
    "family_details",
    "udf_unit",
    "udv",
    "egalim_details",
    "origine_details",
    "local",
    "egalim_status",
    "calibre_details",
    "categorie_details",
    "colisage",
    "uci",
    "fournisseur_details",
    "actions",
  ];
  public print_settings = ARTICLE_PRINT_SETTINGS;
  public print_context: PrintContext = {};

  public override postNgOnInit() {
    super.postNgOnInit();
    this.providerFilter$ = this.data$.pipe(
      map((d) => ({
        fournisseurs: d.provider.id,
      })),
    );
  }

  public override setRouteData(data: ProviderArticlesViewData) {
    super.setRouteData(data);
    if (data.article_detail_route) {
      this.article_detail_route = new Link<Article>(
        data.article_detail_route,
        data,
        this._router,
      );
    }
    if (data.provider) {
      this.print_context.provider = data.provider;
    }
  }
}
