import { OrderprocessstorageBase } from './orderprocessstorage.base';
import { detailsField } from '@solidev/data';
import { OrderprocessBase } from '../orderprocess/orderprocess.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { PlatformBase } from '../../structures/platform/platform.base';
import { OrderprocessstoragePermission } from '../orderprocessstorage-permission/orderprocessstorage-permission';

export class Orderprocessstorage extends OrderprocessstorageBase {
  static override readonly __name: string = 'Orderprocessstorage';
  @detailsField({
    model: OrderprocessBase,
    description: 'Processus de commande',
    readonly: true,
  })
  public process_details?: OrderprocessBase;

  @detailsField({
    model: StorageBase,
    description: 'Dépôt',
    readonly: true,
  })
  public storage_details?: StorageBase;

  @detailsField({
    model: PlatformBase,
    description: 'Platform',
    readonly: true,
  })
  public platform_details?: PlatformBase;

  @detailsField({
    model: OrderprocessstoragePermission,
    description: 'Permissions',
    readonly: true,
    many: true,
  })
  public perms_details?: OrderprocessstoragePermission[];
}
