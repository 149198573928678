
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Cdata } from '../cdata';
@Component({
  selector: 'lvadg-cdata-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdata-manage.component.pug',
  styleUrls: ['./cdata-manage.component.sass']
})
export class CdataManageComponent implements OnInit {
  @Input() public cdata?: Cdata;

  constructor() { }

  ngOnInit(): void {
  }

}
