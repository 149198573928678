import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ClientService } from '../client.service';
import { firstValueFrom } from 'rxjs';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { Client } from '../client';

@Component({
  selector: 'lvadg-client-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './client-create.component.pug',
  styleUrls: ['./client-create.component.sass'],
})
export class ClientCreateComponent implements OnInit {
  public client!: Client;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<Client>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _clients: ClientService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.client = new Client(this._clients);
  }

  public async save() {
    this.client.fromJson(
      { parent: null, ...this.createForm.value },
      { partial: true }
    );
    try {
      await firstValueFrom(this.client.save({ updateModel: true }));
      this.created.emit(this.client);
      this._msgs.success('Client créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
