import { EarticleBase } from './earticle.base';
import { detailsField } from '@solidev/data';
import { Client } from '../../structures/client/client';
import { Storage } from '../../structures/storage/storage';
import { Member } from '../../structures/member/member';
import { Eacollection } from '../eacollection/eacollection';

export class Earticle extends EarticleBase {
  static override readonly __name: string = 'Earticle';

  @detailsField({
    description: 'Client',
    model: Client,
    readonly: true,
    priority: 850,
  })
  public client_details?: Client;

  @detailsField({
    description: 'Storage',
    model: Storage,
    readonly: true,
    priority: 850,
  })
  public storage_details?: Storage;

  @detailsField({
    description: 'Member',
    model: Member,
    readonly: true,
    priority: 850,
  })
  public member_details?: Member;

  @detailsField({
    description: 'Collection',
    model: Eacollection,
    readonly: true,
    priority: 850,
  })
  public collection_details?: Eacollection;
}
