
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { BrandService} from "./brand.service";
import { Brand } from "./brand";

@Injectable({
  providedIn: 'root'
})
export class BrandResolver  {
  constructor(private _rs: BrandService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Brand> {
    return this._rs.fetch(+route.params["brandId"])
  }
}
