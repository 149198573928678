import { OrdergroupBase } from './ordergroup.base';
import { detailsField } from '@solidev/data';
import { OrderprocessBase } from '../orderprocess/orderprocess.base';

export class Ordergroup extends OrdergroupBase {
  static override readonly __name: string = 'Ordergroup';

  @detailsField({
    model: OrderprocessBase,
    description: 'Process commande',
    readonly: true,
  })
  public process_details?: OrderprocessBase;
}
