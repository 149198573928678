import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderitemListComponent } from '../../orderitem/orderitem-list/orderitem-list.component';
import { firstValueFrom, map, Observable, Subject, switchMap } from 'rxjs';
import {
  DataMessageService,
  DispeditComponent,
  FilterDefaults,
  Link,
  PChoicePipe,
  PFactorcPipe,
  PFactorPipe,
} from '@solidev/data';
import { Order } from '../order';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OrderStatus } from '../order.base';
import { Orderitem } from '../../orderitem/orderitem';
import { OrderProcessUserLevel } from '../../orderprocess/orderprocess.base';
import { OrdergroupDisplayComponent } from '../../ordergroup/ordergroup-display/ordergroup-display.component';
import { OrdergroupBase } from '../../ordergroup/ordergroup.base';

@Component({
  selector: 'lvadg-order-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    OrderitemListComponent,
    PFactorcPipe,
    PChoicePipe,
    DispeditComponent,
    PFactorPipe,
    OrdergroupDisplayComponent,
  ],
  templateUrl: './order-edit.component.pug',
  styleUrls: ['./order-edit.component.sass'],
})
export class OrderEditComponent implements OnInit {
  @Input() public data$!: Observable<{ order: Order }>;
  @Input() public ordergroup_detail_route?: Link<OrdergroupBase>;
  public order$!: Observable<Order>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public orderitems_default_fields = [
    'item_details',
    'colisage',
    'quantity_edit',
    'udv_unit',
    'udf_unit',
    'unit_price',
    'total_weight',
    'total_price',
    'status',
  ];
  public OrderStatus = OrderStatus;
  public orderFilter$?: Observable<FilterDefaults>;
  public dates$!: Observable<Date[]>;
  public customEditDate: boolean = false;
  public deliveryDateControl = new FormControl('');
  public OrderProcessUserLevel = OrderProcessUserLevel;

  constructor(private _msgs: DataMessageService) {}

  public ngOnInit(): void {
    this.order$ = this.data$.pipe(
      map((d) => {
        return d.order;
      })
    );
    this.orderFilter$ = this.order$.pipe(
      map((o) => {
        return { order: o.id };
      })
    );
    this.dates$ = this.order$.pipe(
      switchMap((o) => o.action<Order, Date[]>('GET', 'allowed_delivery_dates'))
    );
  }

  public async action(
    order: Order,
    action: string,
    params: Record<string, any> = {}
  ) {
    try {
      const res = await firstValueFrom(
        order.action<
          Order,
          { order: Order; items: Orderitem[]; message: string }
        >('POST', 'action', {
          body: {
            action,
            ...params,
          },
        })
      );
      order.fromJson(res.order, { partial: true });
      this.reload$.next();
    } catch (e) {
      console.log(e);
      if ((e as any).error?.message) {
        this._msgs.warning((e as any).error.message);
      } else {
        this._msgs.error('Erreur inconnue', `{e}`, e);
      }
    }
  }

  public isEditable(order: Order): boolean {
    return [OrderStatus.PREPARATION].indexOf(order.status) !== -1;
  }
}
