import { Component } from "@angular/core";
import { OffertypeTextManageComponent } from "../../../../models/offers/offertype-text/offertype-text-manage/offertype-text-manage.component";
import {
  BaseRouteParams,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
} from "@solidev/data";
import { OffertypeNavComponent } from "../../offertypes/detail/_nav/offertype-nav.component";
import { RouterLink } from "@angular/router";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { BaseDetailViewComponent } from "../../../../components/baseview/basedetailview.component";
import { OffertypeText } from "../../../../models/offers/offertype-text/offertype-text";
import { OFFERTYPE_ROUTES } from "../../offertypes/detail/menu";
import { Offertype } from "../../../../models/offers/offertype/offertype";
import { OffertypeTextType } from "../../../../models/offers/offertype-text-type/offertype-text-type";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";

export interface OffertypeTextViewParams extends BaseRouteParams {
  ot_menu?: OFFERTYPE_ROUTES;
  offertype_text_type_list_route?: RouteConfigItem;
  offertype_text_type_detail_route?: RouteConfigItem;
}

export interface OffertypeTextViewData extends OffertypeTextViewParams {
  offertype?: Offertype;
  offertypetexttype?: OffertypeTextType;
  offertypetext: OffertypeText;
}

@Component({
  selector: "lvadg-offertype-text-view",
  standalone: true,
  imports: [
    OffertypeTextManageComponent,
    SafeDeleteComponent,
    OffertypeNavComponent,
    RouterLink,
    HeaderActionComponent,
    IconComponent,
    CommonModule,
  ],
  templateUrl: "./offertype-text-view.component.pug",
  styleUrl: "./offertype-text-view.component.sass",
})
export class OffertypeTextViewComponent extends BaseDetailViewComponent<
  OffertypeTextViewData,
  OffertypeText
> {
  public offertype_text_type_list_route?: Link<Offertype>;
  public offertype_text_type_detail_route?: Link<OffertypeTextType>;

  public override setRouteData(data: OffertypeTextViewData) {
    super.setRouteData(data);
    if (data.offertype_text_type_detail_route) {
      this.offertype_text_type_detail_route = new Link(
        data.offertype_text_type_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertype_text_type_list_route) {
      this.offertype_text_type_list_route = new Link(
        data.offertype_text_type_list_route,
        data,
        this._router,
      );
    }
  }
}
