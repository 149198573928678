
<ng-container *ngIf="data$|async; let data">
  <ng-template let-offcanvas #docEditSlot>
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">Documents producteur</h5>
      <button class="btn-close" (click)="offcanvas.close()"></button>
    </div>
    <div class="offcanvas-body" *ngIf="currentDocument(); let cd">
      <lvadg-producer-document-manage [producerdocument]="cd"></lvadg-producer-document-manage>
    </div>
  </ng-template>
  <lvadg-producer-nav [producer]="data.producer" [storage]="data.storage" [routes]="data.pr_menu"></lvadg-producer-nav>
  <lvadg-header-action [title]="data.producer.name" level="h1" ri="producer" subtitle="Documents producteur">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="createDocument=!createDocument" *ngIf="canCreate()"><i class="bi bi-plus-circle me-2"></i>Ajouter un document</button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createDocument">
    <div class="card-body">
      <lvadg-producer-document-create [producer]="data.producer" (uploaded)="createDocument=false; reload$.next(true)"></lvadg-producer-document-create>
    </div>
  </div>
  <lvadg-producer-document-list [name]="data.route.name+'-documents'" [filter]="producerFilter$" [reload]="reload$" [actions]="canCreate() ? ['delete', 'edit'] : []" [product_detail_route]="product_detail_route" [default_fields]="default_fields" (action)="onAction($event, data)"></lvadg-producer-document-list>
</ng-container>