import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Treatment } from '../treatment';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-treatment-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './treatment-manage.component.pug',
  styleUrls: ['./treatment-manage.component.sass'],
})
export class TreatmentManageComponent {
  @Input() public treatment!: Treatment;

  constructor() {}
}
