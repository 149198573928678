import { Injectable } from "@angular/core";
import { Collection, DataBackend } from "@solidev/data";
import { Label } from "./label";
import { map, Observable, ReplaySubject } from "rxjs";
import { LABEL_TYPE } from "./label.base";

@Injectable({
  providedIn: "root",
})
export class LabelService extends Collection<Label> {
  private _labels?: ReplaySubject<Map<number, Label>>;

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/labels", Label);
  }

  /** Get label by id, caching all labels values on first call */
  public get(id: number): Observable<Label | undefined> {
    // FIXME: check order of pipe / need for asObservable ?
    return this.prefetch()
      .asObservable()
      .pipe(map((v) => v.get(id)));
  }

  /** Get labels by type, caching all labels values on first call */
  public byType(type: LABEL_TYPE): Observable<Label[]> {
    return this.prefetch()
      .asObservable()
      .pipe(map((v) => [...v.values()].filter((l) => l.type === type)));
  }

  /** Get all labels by types, caching all labels values on first call */
  public byTypes(...types: LABEL_TYPE[]): Observable<Label[]> {
    return this.prefetch()
      .asObservable()
      .pipe(map((v) => [...v.values()].filter((l) => types.includes(l.type))));
  }

  private prefetch(): ReplaySubject<Map<number, Label>> {
    if (!this._labels) {
      this._labels = new ReplaySubject<Map<number, Label>>(1);
      this.list({
        fields: [
          "id",
          "name",
          "code",
          "type",
          "logo_details",
          "catalog_only",
          "egalim",
          "egalim_type",
          "egalim_eq",
          "ordering",
        ].join(","),
        ordering: "-ordering,code,name",
        page_size: 1000,
      }).subscribe((res) => {
        this._labels!.next(new Map<number, Label>(res.map((v) => [v.id, v])));
      });
    }
    return this._labels;
  }
}
