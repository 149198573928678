import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Relation } from "../relation";
import { StorageBase } from "../../storage/storage.base";
import { Link } from "@solidev/data";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lvadg-relation-display",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./relation-display.component.pug",
  styleUrls: ["./relation-display.component.sass"],
})
export class RelationDisplayComponent {
  @Input() relation!: Relation;
  @Input() public mode: "line" = "line";
  @Input() public distance: boolean = true;
  @Input() public location: boolean = true;
  @Input() public detail_route?: Link<Relation>;
  @Input() public storage_detail_route?: Link<StorageBase>;

  constructor() {}
}
