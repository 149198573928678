import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { OrderprocessDisplayViewData } from '../orderprocess-display-view/orderprocess-display-view.component.params';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';
import { OrderprocessManageComponent } from '../../../../models/orders/orderprocess/orderprocess-manage/orderprocess-manage.component';

@Component({
  selector: 'lvadg-orderprocess-edit-view',
  standalone: true,
  imports: [CommonModule, OrderprocessManageComponent],
  templateUrl: './orderprocess-edit-view.component.pug',
  styleUrls: ['./orderprocess-edit-view.component.sass'],
})
export class OrderprocessEditViewComponent extends BaseDetailViewComponent<OrderprocessDisplayViewData, Orderprocess> {}
