
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="offertypestorage">
  <!-- Summary-->
  <li class="nav-item" *ngIf="offertype_storage_detail_route"><a class="nav-link" [routerLink]="offertype_storage_detail_route.route({offertypestorage: offertypestorage})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Résumé</a></li>
  <!-- Edit-->
  <li class="nav-item" *ngIf="offertype_storage_edit_route &amp;&amp; ot.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_storage_edit_route.route({offertypestorage: offertypestorage})" routerLinkActive="active">Paramétrage</a></li>
  <!-- Permissions-->
  <li class="nav-item" *ngIf="offertype_storage_permissions_route &amp;&amp; ot.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_storage_permissions_route.route({offertypestorage: offertypestorage})" routerLinkActive="active">Permissions</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="offertype_storage_offers_route &amp;&amp; offertypestorage.have_level(OTSUL.anon)"><a class="nav-link fw-bold" [routerLink]="offertype_storage_offers_route.route({offertypestorage: offertypestorage})" routerLinkActive="active">Offres</a></li>
</ul>