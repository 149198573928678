import { PROVIDER_LINKS_ROUTES, PROVIDER_ROUTES } from "./menu";
import { RData, RoutesDefinition } from "@solidev/data";
import { RS } from "../../../../constants";
import {
  ProviderDisplayViewComponent,
  ProviderDisplayViewParams,
} from "./display/provider-display-view.component";
import {
  ProviderEditViewComponent,
  ProviderEditViewParams,
} from "./edit/provider-edit-view.component";
import {
  ProviderArticlesViewComponent,
  ProviderArticlesViewParams,
} from "./articles/provider-articles-view.component";
import {
  ProviderProviderdocumentsViewComponent,
  ProviderProviderdocumentsViewParams,
} from "./documents/provider-providerdocuments-view.component";

export const gen_provider_detail_views = (
  routes: PROVIDER_ROUTES,
  link_routes: PROVIDER_LINKS_ROUTES,
): RoutesDefinition => {
  const gen_routes: RoutesDefinition = [];
  if (routes.provider_detail_route) {
    gen_routes.push({
      path: "",
      pathMatch: "full",
      data: RData<ProviderDisplayViewParams>({
        skip: true,
        route: routes.provider_detail_route,
        pr_menu: routes,
      }),
      component: ProviderDisplayViewComponent,
    });
  }
  if (routes.provider_edit_route) {
    gen_routes.push({
      path: RS.edit,
      data: RData<ProviderEditViewParams>({
        title: "Édition",
        route: routes.provider_edit_route,
        provider_list_route: link_routes.provider_list_route,
        pr_menu: routes,
      }),
      component: ProviderEditViewComponent,
    });
  }

  if (routes.provider_documents_route) {
    gen_routes.push({
      path: RS.documents,
      data: RData<ProviderProviderdocumentsViewParams>({
        title: "Documents",
        route: routes.provider_documents_route,
        article_detail_route: link_routes.article_detail_route,
        pr_menu: routes,
      }),
      component: ProviderProviderdocumentsViewComponent,
    });
  }

  if (routes.provider_articles_route) {
    gen_routes.push({
      path: RS.articles,
      data: RData<ProviderArticlesViewParams>({
        title: "Articles",
        route: routes.provider_articles_route,
        article_detail_route: link_routes.article_detail_route,
        pr_menu: routes,
      }),
      component: ProviderArticlesViewComponent,
    });
  }

  return gen_routes;
};
