import {
  charField,
  foreignKeyField,
  manyToManyField,
  primaryField,
} from "@solidev/data";
import { BaseFile } from "../../documents/basefile";

export enum PRODUCERDOCUMENT_TYPES {
  DROIT_IMAGE = "DIM",
  FICHE_TECHNIQUE = "FTC",
  LABEL_PRODUIT = "LPR",
  CERTIFICATION = "CER",
  LABEL_STRUCTURE = "LST",
  INTERNAL_DOC = "DCI",
  INCONNU = "UNK",
}

export enum PRODUCERDOCUMENT_STATUSES {
  VALID = "OK",
  OK_NO_UPDATE_EXP_15D = "PRE1",
  OK_NO_UPDATE_EXP_1M = "PRE2",
  FUTUR = "FUT",
  ARCHIVED_NO_UPDATE = "ARCN",
  ARCHIVED_WITH_UPDATE = "ARCM",
  INVALID = "KO",
}

export class ProducerDocumentBase extends BaseFile {
  static override readonly __name: string = "ProducerDocumentBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @foreignKeyField({
    name: "producer",
    description: "ID Producteur",
    related: "Producer",
    priority: -1,
  })
  public producer!: number;

  @foreignKeyField({
    name: "label",
    description: "ID Label",
    related: "Label",
    priority: -1,
  })
  public label!: number;

  @manyToManyField({
    name: "products",
    description: "Produits",
    related: "Product",
    priority: -1,
  })
  public products!: number[];

  @charField({
    name: "type",
    description: "Type",
    defaultValue: PRODUCERDOCUMENT_TYPES.DROIT_IMAGE,
    maxLength: 4,
    choices: [
      { value: PRODUCERDOCUMENT_TYPES.DROIT_IMAGE, desc: "Droit image" },
      {
        value: PRODUCERDOCUMENT_TYPES.FICHE_TECHNIQUE,
        desc: "Fiche technique",
      },
      { value: PRODUCERDOCUMENT_TYPES.LABEL_PRODUIT, desc: "Label produit" },
      { value: PRODUCERDOCUMENT_TYPES.CERTIFICATION, desc: "Certification" },
      {
        value: PRODUCERDOCUMENT_TYPES.LABEL_STRUCTURE,
        desc: "Label structure",
      },
      { value: PRODUCERDOCUMENT_TYPES.INCONNU, desc: "Inconnu" },
      { value: PRODUCERDOCUMENT_TYPES.INTERNAL_DOC, desc: "Document interne" },
    ],
    priority: 900,
  })
  public type: PRODUCERDOCUMENT_TYPES = PRODUCERDOCUMENT_TYPES.DROIT_IMAGE;

  @charField({
    name: "status",
    description: "État",
    defaultValue: PRODUCERDOCUMENT_STATUSES.VALID,
    maxLength: 4,
    choices: [
      { value: PRODUCERDOCUMENT_STATUSES.VALID, desc: "Valide" },
      { value: PRODUCERDOCUMENT_STATUSES.INVALID, desc: "Invalide" },
      { value: PRODUCERDOCUMENT_STATUSES.FUTUR, desc: "Futur" },
      {
        value: PRODUCERDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE,
        desc: "Archivé sans mise à jour",
      },
      {
        value: PRODUCERDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE,
        desc: "Archivé avec mise à jour",
      },
      {
        value: PRODUCERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D,
        desc: "Valide sans mise à jour, expire dans -15 jours",
      },
      {
        value: PRODUCERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M,
        desc: "Valide sans mise à jour expire dans -1 mois",
      },
    ],
    priority: 150,
  })
  public status: PRODUCERDOCUMENT_STATUSES = PRODUCERDOCUMENT_STATUSES.VALID;

  public get is_valid(): boolean {
    return (
      this.status === PRODUCERDOCUMENT_STATUSES.VALID ||
      this.status === PRODUCERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D ||
      this.status === PRODUCERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M
    );
  }
}
