import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';

export enum OrderGroupStatus {
  PREPARATION = 'PRP',
  VALIDATED = 'VAL',
  CONSOLIDATED = 'CNS',
  SENT = 'SNT',
  CLOSED = 'CLS',
  CANCELLED = 'CAN',
}

export class OrdergroupBase extends DataModel {
  static override readonly __name: string = 'OrdergroupBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 20,
  })
  public code!: string;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'process',
    description: 'ID Process',
    related: 'OrderProcess',
  })
  public process!: number;

  @dateField({
    name: 'date',
    description: 'Date de consolidation',
  })
  public date!: Date;

  @dateField({
    name: 'delivery_date',
    description: 'Date de livraison',
  })
  public delivery_date!: Date;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: OrderGroupStatus.PREPARATION,
    required: true,
    maxLength: 3,
    choices: [
      { value: OrderGroupStatus.PREPARATION, desc: 'En préparation' },
      { value: OrderGroupStatus.CONSOLIDATED, desc: 'Consolidée' },
      { value: OrderGroupStatus.SENT, desc: 'Envoyée' },
      { value: OrderGroupStatus.CLOSED, desc: 'Fermée' },
      { value: OrderGroupStatus.CANCELLED, desc: 'Annulée' },
    ],
  })
  public status: OrderGroupStatus = OrderGroupStatus.PREPARATION;
}
