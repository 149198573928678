import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TARIFTYPE_USER_LEVEL, TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { map, Observable, Subject } from 'rxjs';
import { TarifDocumentCreateComponent } from '../../../../../models/tarifs/tarif-document/tarif-document-create/tarif-document-create.component';
import { TarifExportComponent } from '../../../../../models/tarifs/tarif/tarif-export/tarif-export.component';
import { TarifDocumentListComponent } from '../../../../../models/tarifs/tarif-document/tarif-document-list/tarif-document-list.component';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';
import { TARIF_ROUTES } from '../menu';

export interface TarifDocumentsViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifDocumentsViewData extends TarifDocumentsViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-documents-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    HeaderActionComponent,
    TarifDocumentCreateComponent,
    TarifExportComponent,
    TarifDocumentListComponent,
    TarifDetailNavComponent,
  ],
  templateUrl: './tarif-documents-view.component.pug',
  styleUrls: ['./tarif-documents-view.component.sass'],
})
export class TarifDocumentsViewComponent extends BaseDetailViewComponent<TarifDocumentsViewData, Tarif> {
  public tariftype_detail_route!: Link<TariftypeBase>;
  public TTUL = TARIFTYPE_USER_LEVEL;
  public reloadTarif$: Subject<void | boolean> = new Subject<void | boolean>();
  public documents_default_fields = [
    'id',
    'image_details',
    'title',
    'filename',
    'filesize',
    'type',
    'status',
    'flags',
    'created',
    'updated',
    'actions',
  ];
  public tarifFilter$!: Observable<FilterDefaults>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifDocumentsViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<Tariftype>(data.tariftype_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifFilter$ = this.data$.pipe(
      map((d) => {
        return { tarif: d.tarif.id };
      }),
    );
  }
}
