import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offertype } from '../offertype';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { OffertypeService } from '../offertype.service';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { ClientService } from '../../../structures/client/client.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-offertype-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './offertype-create.component.pug',
  styleUrls: ['./offertype-create.component.sass'],
})
export class OffertypeCreateComponent implements OnInit {
  @Output() public created = new EventEmitter<Offertype>();
  @Output() public cancelled = new EventEmitter<void>();
  public ot!: Offertype;
  public form = new FormGroup({});

  constructor(
    private _msgs: DataMessageService,
    private _ots: OffertypeService,
    public clients$: ClientService,
  ) {}

  ngOnInit(): void {
    this.ot = new Offertype(this._ots);
  }

  public async save() {
    if (this.form.valid) {
      this.ot.fromJson(this.form.value, { partial: true });
      try {
        await firstValueFrom(this.ot.save({ updateModel: true }));
        this.created.emit(this.ot);
        this._msgs.success("Modèle d'offre créé avec succès");
      } catch (err) {
        const ectx = getErrorContext(err, {});
        this._msgs.error("Erreur lors de la création du modèle d'offre", ectx.message, ectx.context);
      }
    }
  }
}
