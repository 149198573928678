import { PrintSettings } from "../../layouts/print-settings";

export const PRODUCT_PRINT_SETTINGS: PrintSettings = [
  {
    label: "Fiche produit",
    provider: "product_with_nutri",
    type: "single",
    actions: ["button"],
    template: "product_detail",
    limit: 1,
  },
  {
    label: "Fiches produits",
    provider: "products_with_nutri",
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "product_detail",
    limit: 100,
  },
  {
    label: "Fiches produits",
    provider: "products_with_nutri_queryset",
    type: "queryset",
    actions: ["quick_print", "add_to_print"],
    template: "product_detail",
    limit: 100,
  },
  {
    label: "Liste produits",
    type: "queryset",
    provider: "products_short_queryset",
    actions: ["quick_print", "add_to_print"],
    template: "product_list",
    limit: 2000,
  },
  {
    label: "Liste produits",
    type: "selection",
    provider: "products_short",
    actions: ["quick_print", "add_to_print"],
    template: "product_list",
    limit: 200,
  },
  {
    label: "Affiche produit",
    provider: "product",
    type: "single",
    actions: ["button"],
    template: "product_poster",
    limit: 1,
  },
  {
    label: "Affiches produits",
    provider: "products",
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "product_poster",
    limit: 100,
  },
  {
    label: "Affiches produits",
    provider: "products_queryset",
    type: "queryset",
    actions: ["quick_print", "add_to_print"],
    template: "product_poster",
    limit: 100,
  },
];
