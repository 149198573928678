
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { CalibreService} from "./calibre.service";
import { Calibre } from "./calibre";

@Injectable({
  providedIn: 'root'
})
export class CalibreResolver  {
  constructor(private _rs: CalibreService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Calibre> {
    return this._rs.fetch(+route.params["calibreId"])
  }
}
