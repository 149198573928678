
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Eamapping } from "./eamapping";

@Injectable({
  providedIn: 'root'
})
export class EamappingService extends Collection<Eamapping> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/eamappings", Eamapping);
  }
}
