import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Brand } from '../brand';
import { DispeditComponent } from '@solidev/data';
import { ProviderService } from '../../../../structures/provider/provider.service';
import { ProducerService } from '../../../../structures/producer/producer.service';

@Component({
  selector: 'lvadg-brand-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './brand-manage.component.pug',
  styleUrls: ['./brand-manage.component.sass'],
})
export class BrandManageComponent implements OnInit {
  @Input() public brand!: Brand;

  constructor(
    public providers$: ProviderService,
    public producers$: ProducerService
  ) {}

  ngOnInit(): void {}
}
