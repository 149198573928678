import { charField } from '@solidev/data';
import { ArticleMetaBase } from '../meta.base';

export enum PACKAGING_TYPE {
  UCI = 'UCI',
  COLISAGE = 'COL',
  UCI_COLISAGE = 'UCO',
}

export class PackagingBase extends ArticleMetaBase {
  static override readonly __name: string = 'PackagingBase';

  @charField({
    name: 'type',
    description: 'type',
    defaultValue: PACKAGING_TYPE.UCI_COLISAGE,
    required: true,
    maxLength: 3,
    choices: [
      { value: PACKAGING_TYPE.UCI, desc: 'UCI' },
      { value: PACKAGING_TYPE.COLISAGE, desc: 'Colisage' },
      {
        value: PACKAGING_TYPE.UCI_COLISAGE,
        desc: 'UCI + Colisage',
      },
    ],
  })
  public override type: PACKAGING_TYPE = PACKAGING_TYPE.UCI_COLISAGE;

  public override _display(): string {
    if (this.name && this.name !== this.code) {
      return `[${this.code}] ${this.name}`;
    }
    return `${this.code}`;
  }
}
