
<ng-template #ofcAddTextSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Ajouter un texte</h4>
    <button class="btn-close" type="button" (click)="offcanvas.dismiss()" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="addForm" (submit)="addTextAction()">
      <div class="form-group">
        <label for="title">Titre</label>
        <input class="form-control" id="title" name="title" formControlName="title">
      </div>
      <div class="form-group">
        <label for="text">Texte</label>
        <data-richedit id="text" [fc]="addForm.controls.text" [hideButton]="true"></data-richedit>
      </div>
      <div class="form-group">
        <button class="btn btn-primary w-100" type="submit">
          <lvadg-icon ri="add">Ajouter</lvadg-icon>
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #ofcEditTextSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Modifier un texte</h4>
    <button class="btn-close" type="button" (click)="offcanvas.dismiss()" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="editForm" (submit)="updateTextAction()">
      <div class="form-group">
        <label for="etitle">Titre</label>
        <input class="form-control" id="etitle" name="title" formControlName="title">
      </div>
      <div class="form-group">
        <label for="etext">Texte</label>
        <data-richedit id="etext" [fc]="editForm.controls.text" [hideButton]="true"></data-richedit>
      </div>
      <div class="form-group">
        <button class="btn btn-primary w-100" type="submit">
          <lvadg-icon ri="add">Enregistrer</lvadg-icon>
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #ofcMoveTextSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Déplacer un texte</h4>
    <button class="btn-close" type="button" (click)="offcanvas.dismiss()" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <p class="text-info">Déplacer le texte vers une autre position. Si la position est déjà occupée, le texte sera échangé avec le texte déjà présent.</p>
    <div class="row g-3" *ngIf="current(); let c">
      <div class="col-6" *ngFor="let p of text_positions">
        <button class="btn btn-outline-primary w-100 p-3" (click)="moveTextAction(c, p)" [class.btn-outline-primary]="p!=c.position" [class.btn-secondary]="p==c.position" [disabled]="p==c.position">Position {{p+1}}</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ofcSelectVariantSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Choisir un texte</h4>
    <button class="btn-close" type="button" (click)="offcanvas.dismiss()" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <p class="text-info">Choisir un texte prédéfini.</p>
    <div class="row g-3" *ngIf="current(); let c">
      <pre>CUR {{c.texttype}}</pre>
      <div class="col-12" *ngFor="let t of available_texts()[c.texttype]||[]">
        <div class="card">
          <div class="card-body">
            <dl>
              <dt>Titre</dt>
              <dd>{{t.title}}</dd>
              <dt>Date de début</dt>
              <dd>{{t.date_start|date:'shortDate'}}</dd>
              <dt>Date de fin</dt>
              <dd>{{t.date_end|date:'shortDate'}}</dd>
              <dt>Texte (extrait)</dt>
              <dd>{{t.text|stripTags|shorten:300}}...</dd>
            </dl>
            <button class="btn btn-sm btn-outline-primary w-100 p-3" (click)="selectedVariantAction(c, t)">
              <lvadg-icon ri="add">Choisir ce texte</lvadg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ofcSelectPredefinedSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Choisir un texte prédéfini</h4>
    <button class="btn-close" type="button" (click)="offcanvas.dismiss()" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <p class="text-info">Choisir un texte prédéfini pour cette position.</p>
    <div class="row g-3">
      <ng-container *ngFor="let tt of available_texttypes()">
        <ng-container *ngIf="available_texts()[tt.id]?.length">
          <div class="col-12" *ngFor="let t of available_texts()[tt.id]||[]">
            <div class="card">
              <div class="card-body">
                <dl>
                  <dt>Type</dt>
                  <dd>{{t.title}}</dd>
                  <dt>Titre</dt>
                  <dd>{{t.title}}</dd>
                  <dt>Date de début</dt>
                  <dd>{{t.date_start|date:'shortDate'}}</dd>
                  <dt>Date de fin</dt>
                  <dd>{{t.date_end|date:'shortDate'}}</dd>
                  <dt>Texte (extrait)</dt>
                  <dd>{{t.text|stripTags|shorten:300}}...</dd>
                </dl>
                <button class="btn btn-sm btn-outline-primary w-100 p-3" (click)="selectedPredefinedTextAction(current_position()!, t)">
                  <lvadg-icon ri="add">Choisir ce texte</lvadg-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="data$|async; let data">
  <ng-template #ofcImportTextSlot let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Importer les textes d'un autre offre</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-offer-list [name]="data.route.name+'-import'" [keep]="false" [filter]="forOfferFilter$" [actions]="['texts_import']" [default_fields]="texts_import_default_fields" [default_filters]="['search', 'clients', 'storages']" [sorter]="['-dateend']" (action)="processImportTexts($event)" displayMode="ml-top"></lvadg-offer-list>
    </div>
  </ng-template>
  <lvadg-offer-nav [routes]="data.o_menu" [offer]="data.offer" *ngIf="data.o_menu" [offertype]="data.offertype" [offertypestorage]="data.offertypestorage"></lvadg-offer-nav>
  <lvadg-header-action [title]="data.offer.title" level="h1" subtitle="Textes de l'offre" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-warning btn-sm" (click)="syncTextsAction()">
        <lvadg-icon ri="sync">Réinitialiser</lvadg-icon>
      </button>
      <button class="btn btn-outline-info btn-sm" (click)="openImportTexts()">
        <lvadg-icon ri="add">Importer</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="container">
    <div class="text-info fs-5 fw-bold mt-4 mb-2">
      La disposition des textes est identique à celle de l'impression.
      Vous pouvez déplacer les textes en cliquant sur le bouton "Déplacer"
      et en choisissant une nouvelle position.
      Lorsque un texte est seul sur sa ligne, sa largeur à l'impression est de 100%.
      
    </div>
    <div class="row g-4">
      <div class="col-6" *ngFor="let p of text_positions">
        <div class="card h-100">
          <ng-container *ngIf="ots()[p]; let ot">
            <ng-container *ngIf="ot.texttype_details; let tt">
              <ng-container *ngIf="ot.texttype_text_details; let ttd">
                <div class="card-header">
                  <div class="d-flex flex-row justify-content-between"><span>{{tt.title}}</span>
                    <div class="badge bg-primary">{{tt.mandatory}} - {{p+1}}</div>
                  </div>
                </div>
                <div class="card-body d-flex flex-column justify-content-between">
                  <dl>
                    <dt>Titre</dt>
                    <dd>{{ttd.title}}</dd>
                    <dt>Texte</dt>
                    <dd class="message-content" [innerHTML]="ttd.text"></dd>
                  </dl>
                  <div class="actions d-flex flex-row justify-content-between gap-3">
                    <button class="btn btn-outline-primary btn-sm flex-grow-1" (click)="moveText(ot)">
                      <lvadg-icon ri="edit">Déplacer</lvadg-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ot.texttype_text_details">
                <div class="card-header bg-danger">
                  <div class="d-flex flex-row justify-content-between"><span>{{tt.title}}</span>
                    <div class="badge bg-primary">{{tt.mandatory}} - {{p+1}}</div>
                  </div>
                </div>
                <div class="card-body d-flex flex-column justify-content-between">
                  <p class="text-danger p-4 m-2">Pas de texte</p>
                  <div class="actions d-flex flex-row justify-content-between gap-3">
                    <button class="btn btn-outline-warning btn-sm flex-grow-1" (click)="selectVariant(ot)" *ngIf="available_texts()[tt.id]?.length">
                      <lvadg-icon ri="edit">Choisir</lvadg-icon>
                    </button>
                    <button class="btn btn-outline-primary btn-sm flex-grow-1" (click)="moveText(ot)">
                      <lvadg-icon ri="edit">Déplacer</lvadg-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!ot.texttype_details">
              <div class="card-header">
                <div class="d-flex flex-row justify-content-between"><span>Texte libre</span>
                  <div class="badge bg-primary">{{p+1}}</div>
                </div>
              </div>
              <div class="card-body d-flex flex-column justify-content-between">
                <dl>
                  <dt>Titre</dt>
                  <dd>{{ot.title}}</dd>
                  <dt>Texte</dt>
                  <dd class="message-content" [innerHTML]="ot.text"></dd>
                </dl>
                <div class="actions d-flex flex-row justify-content-between gap-3">
                  <button class="btn btn-outline-primary btn-sm flex-grow-1" (click)="updateText(ot)">
                    <lvadg-icon ri="edit">Modifier</lvadg-icon>
                  </button>
                  <button class="btn btn-outline-danger btn-sm flex-grow-1" (click)="deleteTextAction(ot)">
                    <lvadg-icon ri="delete">Supprimer</lvadg-icon>
                  </button>
                  <button class="btn btn-outline-primary btn-sm flex-grow-1" (click)="moveText(ot)">
                    <lvadg-icon ri="edit">Déplacer</lvadg-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!ots()[p]">
            <div class="card-header">
              <div class="d-flex flex-row justify-content-between"><span>Aucun texte</span>
                <div class="badge bg-primary">{{p+1}}</div>
              </div>
            </div>
            <div class="card-body d-flex flex-column justify-content-between">
              <p class="text-danger p-4 m-2">Pas de texte</p>
              <div class="actions d-flex flex-row justify-content-between gap-3">
                <button class="btn btn-outline-primary btn-sm flex-grow-1" (click)="addText(p)">
                  <lvadg-icon ri="add">Ajouter un texte libre</lvadg-icon>
                </button>
                <button class="btn btn-outline-primary btn-sm flex-grow-1" (click)="selectPredefinedText(p)" *ngIf="available_texttypes()?.length">
                  <lvadg-icon ri="add">Choisir un texte prédéfini</lvadg-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>