import { Component, computed, input } from "@angular/core";
import { Product } from "../product";
import { FamilyDisplayComponent } from "../../family/family-display/family-display.component";
import { CommonModule } from "@angular/common";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../label/label-display/label-display.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { VideoDisplayComponent } from "../../../documents/image/video-display/video-display.component";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { ProducerDisplayComponent } from "../../../structures/producer/producer-display/producer-display.component";
import { IMAGE_USAGE } from "../../../documents/image/image.base";
import { Producer } from "../../../structures/producer/producer";
import { Link } from "@solidev/data";
import { CDATAKEY_DEST } from "../../../cdata/cdata-key/cdata-key.base";
import { CdatasDisplayComponent } from "../../../cdata/cdata/cdatas-display/cdatas-display.component";
import { RelationListmapComponent } from "../../../structures/relation/relation-listmap/relation-listmap.component";
import { ProducerDocumentDisplayComponent } from "../../../structures/producer-document/producer-document-display/producer-document-display.component";

@Component({
  selector: "lvadg-product-details",
  standalone: true,
  imports: [
    CommonModule,
    FamilyDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    IconComponent,
    TodoComponent,
    SeasonsManageComponent,
    VideoDisplayComponent,
    NgbCarouselModule,
    ProducerDisplayComponent,
    CdatasDisplayComponent,
    RelationListmapComponent,
    ProducerDocumentDisplayComponent,
  ],
  templateUrl: "./product-details.component.pug",
  styleUrl: "./product-details.component.sass",
})
export class ProductDetailsComponent {
  public product = input.required<Product>();
  public producer = input<Producer>();
  public display_producer = input<boolean>(true);
  public producer_detail_route = input<Link<Producer>>();
  public images = computed(() => {
    const p = this.product();
    return (p.images_details || []).concat(p.cimages_details || []);
  });
  public haveDocuments = computed(() => {
    const p = this.product();
    return (
      p.documents_details &&
      p.documents_details.filter((d) => d.is_valid && !d.label).length > 0
    );
  });
  public haveVideos = computed(() => {
    const images = this.images();
    return images && images.filter((d) => !!d.video).length > 0;
  });
  public carouselImages = computed(() => {
    const images = this.images();
    return (
      images &&
      images.filter((d) => !d.video && d.have_usages(IMAGE_USAGE.GDISP))
    );
  });
  public havePhotos = computed(() => {
    const p = this.carouselImages();
    return p ? p.length > 0 : false;
  });
  protected readonly CDATAKEY_DEST = CDATAKEY_DEST;
}
