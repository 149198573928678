
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { RnmarticleService} from "./rnmarticle.service";
import { Rnmarticle } from "./rnmarticle";

@Injectable({
  providedIn: 'root'
})
export class RnmarticleResolver  {
  constructor(private _rs: RnmarticleService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Rnmarticle> {
    return this._rs.fetch(+route.params["rnmarticleId"])
  }
}
