
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs nav-justified" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Paramètres</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <h2>Paramètres de la liaison
            <lvadg-tariftype-display [tariftype]="data.tariftyperelation.src_details"></lvadg-tariftype-display><i class="bi bi-arrow-right mx-3"></i>
            <lvadg-tariftype-display [tariftype]="data.tariftyperelation.dest_details"></lvadg-tariftype-display>
          </h2>
          <lvadg-tariftype-relation-manage [tariftyperelation]="data.tariftyperelation"></lvadg-tariftype-relation-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="articles"><a ngbNavLink>Liaisons entre articles</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="clearfix">
            <button class="btn btn-sm btn-outline-primary float-end" *ngIf="data.tariftype" [disabled]="updatingArticles" (click)="updateArticles(data.tariftype, data.tariftyperelation)">
              <ng-container><i class="bi bi-arrow-clockwise me-2" *ngIf="!updatingArticles"></i><i class="spinner-border spinner-border-sm me-2" *ngIf="updatingArticles"></i>Mettre à jour les liaisons</ng-container>
            </button>
            <h2>Détails liaisons articles
              <lvadg-tariftype-display [tariftype]="data.tariftyperelation.src_details"></lvadg-tariftype-display><i class="bi bi-arrow-right mx-3"></i>
              <lvadg-tariftype-display [tariftype]="data.tariftyperelation.dest_details"></lvadg-tariftype-display>
            </h2>
          </div>
          <lvadg-article-tarif-template-relation-list [name]="data.route.name+'_relations'" [default_fields]="attrel_default_fields" [filter]="attrel_filter" [reload]="reload"></lvadg-article-tarif-template-relation-list>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>