import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LayoutData, LayoutTree } from "./layout";
import { map, Observable, of } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { PRINT_API_URL, PrintSettingsItem } from "../print-settings";
import { PrintBase } from "../public_api";
import { WsService } from "../../../components/live/ws.service";
import { PresetAction } from "../rstypes/PresetAction";
import { ProviderData } from "../rstypes/ProviderData";
import { UrlParamValue } from "../rstypes/UrlParamValue";

function stripNulls(obj: Partial<UrlParamValue>) {
  return Object.entries(obj).reduce(
    (a, [k, v]) =>
      v == null
        ? a
        : {
            ...a,
            [k]: v,
          },
    {},
  );
}

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  constructor(
    private _http: HttpClient,
    @Inject(PRINT_API_URL) private _printApiUrl: string,
    private _ws: WsService,
    private _domSanitizer: DomSanitizer,
  ) {}

  public load$(
    print?: PrintBase | null,
    params: Partial<UrlParamValue> = {},
  ): Observable<LayoutTree | null> {
    if (!print) {
      return of(null);
    }
    return this._http
      .get<LayoutData[]>(`${this._printApiUrl}/prints/${print.id}/structure`, {
        params: stripNulls(params),
      })
      .pipe(
        map((data) => {
          return new LayoutTree(
            print,
            params,
            data,
            this._http,
            this._printApiUrl,
            this._domSanitizer,
          );
        }),
      );
  }

  /**
   * Reset the layout tree, clearing layout server caches.
   * @param print print object
   * @param params url parameters
   */
  public reset$(
    print?: PrintBase | null,
    params: Partial<UrlParamValue> = {},
  ): Observable<LayoutTree | null> {
    if (!print) {
      return of(null);
    }
    params.reset_cache = true;
    return this._http
      .get<LayoutData[]>(`${this._printApiUrl}/prints/${print.id}/structure`, {
        params: stripNulls(params),
      })
      .pipe(
        map((data) => {
          return new LayoutTree(
            print,
            params,
            data,
            this._http,
            this._printApiUrl,
            this._domSanitizer,
          );
        }),
      );
  }

  /**
   * Call the layout template endpoint for a print (adding layout template to print)
   *
   * @param printId print id
   * @param settings settings
   * @param data template data
   * @param context context data
   * @returns observable of action results
   */
  public add$(
    printId: number,
    settings: PrintSettingsItem,
    data: ProviderData,
    context: ProviderData[] = [],
  ) {
    // FIXME: manage errors & results
    return this._http.post(`${this._printApiUrl}/prints/${printId}/template`, {
      data,
      settings,
      context,
    } as PresetAction);
  }

  /**
   * Get the PDF print for a print, and launch the download
   * @param printId print id
   * @paran sync if true, wait for the print to be ready
   * @returns promise true/false of success
   */
  public pdf$(printId: number, sync: boolean = false): Observable<boolean> {
    if (sync) {
      return this._http
        .get(`${this._printApiUrl}/prints/${printId}/pdf`, {
          responseType: "blob",
        })
        .pipe(
          map((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `print-${printId}.pdf`;
            document.body.appendChild(a);
            a.click();
            this._ws.unprogress(`print_feedback_${printId}`);
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            return true;
          }),
        );
    } else {
      return this._http
        .get(`${this._printApiUrl}/prints/${printId}/pdf_task`, {
          responseType: "blob",
        })
        .pipe(map(() => true));
    }
  }
}
