import { Injectable } from "@angular/core";
import { Tarif } from "./tarif";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";
import { TarifBase } from "./tarif.base";
import { ArticleTarif } from "../article-tarif/article-tarif";
import { ArticleTarifItem } from "../article-tarif-item/article-tarif-item";
import { TarifExportType } from "./tarif-export/tarif-export-type";

export interface TarifActionResult {
  tarif?: Tarif;
  at?: ArticleTarif;
  ati?: ArticleTarifItem;
  data?: any;
  message: string;
  success: boolean;
}

interface TarifActionStorageImportItemsParams {
  action: "storage_import_items";
  storage: number;
}

interface TarifActionStorageValidateItemsParams {
  action: "storage_validate_items";
  storage: number;
}

interface TarifActionStorageBaseParams {
  action: "storage_reimport_prices";
  confirm: boolean;
  upload_id: number | undefined;
}

interface TarifActionRegionImportItemsParams {
  action: "region_import_items";
  region: number;
}

interface TarifActionRegionValidateItemsParams {
  action: "region_validate_items";
  region: number;
}

interface TarifActionRegionBaseParams {
  action: "region_reimport_prices";
  confirm: boolean;
  upload_id: number | undefined;
}

interface TarifActionReadCustomArticlesParams {
  action: "read_custom_articles";
  confirm: boolean;
  mode: "price" | "storage" | "region";
  storage_id: number | undefined;
  region_id: number | undefined;
  upload_id: number | undefined;
  document_id: number | undefined;
  metadata_id: number | undefined;
}

interface TarifActionReadCustomPricesParams {
  action: "read_custom_prices";
  confirm: boolean;
  mode: "price" | "storage" | "region";
  storage_id: number | undefined;
  region_id: number | undefined;
  upload_id: number | undefined;
  document_id: number | undefined;
  metadata_id: number | undefined;
}

interface TarifActionWriteCustomPricesParams {
  action: "write_custom_prices";
  confirm: boolean;
  upload_id: number | undefined;
  document_id: number | undefined;
  metadata_id: number | undefined;
}

interface TarifActionToggleArticleParams {
  action: "toggle_article";
  articletarif: number;
}

interface TarifActionSetPriceParams {
  action: "set_price";
  price: number | string | null | undefined;
  quantity: number | null | undefined;
  type: "price" | "decondi" | "storage" | "region" | "rnm";
  mode: "price" | "dispo" | "dispoprice";
}

interface TarifActionRelationActionParams {
  action: "relation_action";
  relation: number;
}

interface TarifActionRelationAddParams {
  action: "relation_add";
  ttrelation: number;
  src: number;
  ops?: string;
}

interface TarifActionRelationRemoveParams {
  action: "relation_remove";
  relation: number;
}

interface TarifActionRepublishParams {
  action: "republish";
}

interface TarifActionSyncArticleParams {
  action: "sync_articles";
}

interface TarifActionEaExportParams {
  action: "ea_export";
  type: TarifExportType;
  format: "xlsx" | "json" | "csv";
  collection: number;
}

export type TarifActionParams =
  | TarifActionStorageImportItemsParams
  | TarifActionStorageValidateItemsParams
  | TarifActionStorageBaseParams
  | TarifActionRegionImportItemsParams
  | TarifActionRegionValidateItemsParams
  | TarifActionRegionBaseParams
  | TarifActionToggleArticleParams
  | TarifActionSetPriceParams
  | TarifActionRelationActionParams
  | TarifActionRelationAddParams
  | TarifActionRelationRemoveParams
  | TarifActionReadCustomArticlesParams
  | TarifActionReadCustomPricesParams
  | TarifActionWriteCustomPricesParams
  | TarifActionRepublishParams
  | TarifActionSyncArticleParams
  | TarifActionEaExportParams;

@Injectable({
  providedIn: "root",
})
export class TarifActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action<T extends TarifActionResult>(
    t: TarifBase,
    action: TarifActionParams,
    messages: Record<string, string> = {},
  ): Promise<T> {
    try {
      const res = await firstValueFrom(
        t.action<Tarif, TarifActionResult>("POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res as T;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { tarif: t, message: message, success: false } as T;
    }
  }
}
