
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferNotificationStatus } from '../offer-notification-status';
@Component({
  selector: 'lvadg-offer-notification-status-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-notification-status-manage.component.pug',
  styleUrls: ['./offer-notification-status-manage.component.sass']
})
export class OfferNotificationStatusManageComponent implements OnInit {
  @Input() public offernotificationstatus?: OfferNotificationStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
