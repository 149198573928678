import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Offertype } from "../../../../../models/offers/offertype/offertype";
import { Link } from "@solidev/data";
import { OFFERTYPE_ROUTES } from "../menu";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { OFFERTYPE_USER_LEVEL } from "../../../../../models/offers/offertype/offertype.base";

@Component({
  selector: "lvadg-offertype-nav",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: "./offertype-nav.component.pug",
  styleUrls: ["./offertype-nav.component.sass"],
})
export class OffertypeNavComponent implements OnInit {
  @Input({ required: true }) public routes!: OFFERTYPE_ROUTES;
  @Input() public offertype!: Offertype;
  public OTUL = OFFERTYPE_USER_LEVEL;

  public offertype_detail_route?: Link<Offertype>;
  public offertype_edit_route?: Link<Offertype>;
  public offertype_permissions_route?: Link<Offertype>;
  public offertype_storages_route?: Link<Offertype>;
  public offertype_offers_route?: Link<Offertype>;
  public offertype_destinations_route?: Link<Offertype>;
  public offertype_notifications_route?: Link<Offertype>;
  public offertype_notification_logs_route?: Link<Offertype>;
  public offertype_offers_logs_route?: Link<Offertype>;
  public offertype_offers_stats_route?: Link<Offertype>;
  public offertype_text_types_route?: Link<Offertype>;

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes) {
      throw new Error("Missing routes input");
    }
    if (this.routes.offertype_detail_route) {
      this.offertype_detail_route = new Link(
        this.routes.offertype_detail_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_edit_route) {
      this.offertype_edit_route = new Link(
        this.routes.offertype_edit_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_permissions_route) {
      this.offertype_permissions_route = new Link(
        this.routes.offertype_permissions_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_storages_route) {
      this.offertype_storages_route = new Link(
        this.routes.offertype_storages_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_offers_route) {
      this.offertype_offers_route = new Link(
        this.routes.offertype_offers_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_destinations_route) {
      this.offertype_destinations_route = new Link(
        this.routes.offertype_destinations_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_notifications_route) {
      this.offertype_notifications_route = new Link(
        this.routes.offertype_notifications_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_notification_logs_route) {
      this.offertype_notification_logs_route = new Link(
        this.routes.offertype_notification_logs_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_offers_logs_route) {
      this.offertype_offers_logs_route = new Link(
        this.routes.offertype_offers_logs_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_offers_stats_route) {
      this.offertype_offers_stats_route = new Link(
        this.routes.offertype_offers_stats_route,
        this,
        this._router,
      );
    }
    if (this.routes.offertype_text_types_route) {
      this.offertype_text_types_route = new Link(
        this.routes.offertype_text_types_route,
        this,
        this._router,
      );
    }
  }
}
