import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offertype } from '../offertype';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-offertype-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './offertype-display.component.pug',
  styleUrls: ['./offertype-display.component.sass'],
})
export class OffertypeDisplayComponent {
  @Input() public offertype?: Offertype;
  @Input() public mode: 'cline' | 'line' | 'id' = 'line';
  @Input() public detail_route?: Link<Offertype>;
}
