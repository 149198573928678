import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TariftypeMetadata } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata';
import { TariftypeMetadataManageComponent } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata-manage/tariftype-metadata-manage.component';
import { BaseRouteParams } from '@solidev/data';

export interface TariftypeMetadataDetailViewParams extends BaseRouteParams {}

export interface TariftypeMetadataDetailViewData extends TariftypeMetadataDetailViewParams {
  tariftypemetadata: TariftypeMetadata;
}

@Component({
  selector: 'lvadg-tariftype-metadata-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TariftypeMetadataManageComponent],
  templateUrl: './tariftype-metadata-detail-view.component.pug',
  styleUrls: ['./tariftype-metadata-detail-view.component.sass'],
})
export class TariftypeMetadataDetailViewComponent extends BaseDetailViewComponent<
  TariftypeMetadataDetailViewData,
  TariftypeMetadata
> {
  public override setRouteData(data: TariftypeMetadataDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
