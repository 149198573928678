import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { TarifNotificationManageComponent } from '../../../../models/tarifs/tarif-notification/tarif-notification-manage/tarif-notification-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TarifNotification } from '../../../../models/tarifs/tarif-notification/tarif-notification';
import { BaseRouteParams } from '@solidev/data';

export interface TarifNotificationDetailViewParams extends BaseRouteParams {}

export interface TarifNotificationDetailViewData extends TarifNotificationDetailViewParams {
  tarifnotification: TarifNotification;
}

@Component({
  selector: 'lvadg-tarif-notification-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TarifNotificationManageComponent],
  templateUrl: './tarif-notification-detail-view.component.pug',
  styleUrls: ['./tarif-notification-detail-view.component.sass'],
})
export class TarifNotificationDetailViewComponent extends BaseDetailViewComponent<
  TarifNotificationDetailViewData,
  TarifNotification
> {
  public override setRouteData(data: TarifNotificationDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
