
<ng-container *ngIf="data$|async; let data">
  <lvadg-offertype-storage-nav [routes]="data.ots_menu" [offertypestorage]="data.offertypestorage" *ngIf="data.ots_menu"></lvadg-offertype-storage-nav>
  <lvadg-header-action [title]="data.offertypestorage._display" level="h1" subtitle="Permissions du modèle dépôt" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-primary" (click)="create_permission=!create_permission">
        <lvadg-icon ri="plus">Ajouter une permission</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="create_permission">
    <div class="card-header">
      <lvadg-icon ri="plus">Ajouter une permission</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-offertype-storage-permission-create [offertypestorage]="data.offertypestorage" (created)="reload$.next(true); create_permission=false" (cancelled)="create_permission=false"></lvadg-offertype-storage-permission-create>
    </div>
  </div>
  <pre class="todo">- TODO: add filters (level)
- TODO: add colors depending on level</pre>
  <lvadg-offertype-storage-permission-list [name]="data.route.name" [filter]="offertypestorageFilter$" [reload]="reload$" [actions]="['delete']" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route"></lvadg-offertype-storage-permission-list>
</ng-container>