import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Tarif } from '../tarif';
import { TarifService } from '../tarif.service';
import {
  FiltersParams,
  Link,
  ModelListDateFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NgStringPipesModule } from 'ngx-pipes';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { TARIF_STATUS } from '../tarif.base';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { TARIFDOCUMENT_STATUSES, TARIFDOCUMENT_TYPES } from '../../tarif-document/tarif-document.base';
import { GenericregionDisplayComponent } from '../../../locations/genericregion/genericregion-display/genericregion-display.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-tarif-list',
  standalone: true,
  templateUrl: './tarif-list.component.pug',
  styleUrls: ['./tarif-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    StorageDisplayComponent,
    IconComponent,
    GenericregionDisplayComponent,
    NgStringPipesModule,
  ],
})
export class TarifListComponent extends ModellistComponent<Tarif> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  public TARIFDOCUMENT_STATUSES = TARIFDOCUMENT_STATUSES;
  public TARIFDOCUMENT_TYPES = TARIFDOCUMENT_TYPES;

  constructor(coll: TarifService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'status', 'ttype', 'tzone', 'validat'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Filtrer par état',
          model: Tarif,
          mfield: 'status',
        }),
        new ModelListSelectFilter({
          field: 'ttype',
          name: 'ttype',
          label: 'Filtrer par type de tarif',
          model: TariftypeBase,
          mfield: 'type',
        }),
        new ModelListSelectFilter({
          field: 'tzone',
          name: 'tzone',
          label: 'Filtrer par zone de tarif',
          model: TariftypeBase,
          mfield: 'zone',
        }),
        new ModelListSelectFilter({
          field: 'tlifecycle',
          name: 'tlifecycle',
          label: 'Filtrer par cycle de vie',
          model: TariftypeBase,
          mfield: 'lifecycle',
        }),
        new ModelListSelectFilter({
          field: 'tinterval',
          name: 'tinterval',
          label: 'Filtrer par périodicité de base',
          model: TariftypeBase,
          mfield: 'base_interval',
        }),

        new ModelListDateFilter({
          field: 'validat',
          name: 'validat',
          label: 'Rechercher par date de validité',
        }),
      ],
    };
  }

  public override getRowClasses(row: Tarif): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === TARIF_STATUS.PUBLISHED) {
      cls.push('table-success');
    }
    if (row.status === TARIF_STATUS.ARCHIVED) {
      cls.push('table-secondary');
    }
    if (row.status === TARIF_STATUS.TOVALIDATE || row.status === TARIF_STATUS.VALID) {
      cls.push('table-info');
    }
    if (row.status === TARIF_STATUS.PREPARATION) {
      cls.push('table-warning');
    }

    return cls;
  }

  public override detailRoute(item: Tarif): (string | number)[] | null {
    if (this.detail_route) {
      this.detail_route.context({ tariftype: { id: item.tariftype || item.tariftype_details?.id } });
    }
    return super.detailRoute(item);
  }
}
