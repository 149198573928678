import { BaseRouteParams, RouteConfigItem } from "@solidev/data";
import { Offertype } from "../../../../models/offers/offertype/offertype";

export interface OFFERTYPE_ROUTES {
  offertype_detail_route?: RouteConfigItem;
  offertype_edit_route?: RouteConfigItem;
  offertype_permissions_route?: RouteConfigItem;
  offertype_storages_route?: RouteConfigItem;
  offertype_offers_route?: RouteConfigItem;
  offertype_destinations_route?: RouteConfigItem;
  offertype_notifications_route?: RouteConfigItem;
  offertype_notification_logs_route?: RouteConfigItem;
  offertype_offers_logs_route?: RouteConfigItem;
  offertype_offers_stats_route?: RouteConfigItem;
  offertype_text_types_route?: RouteConfigItem;
  offertype_text_type_route?: RouteConfigItem;
  offertype_texts_route?: RouteConfigItem;
}

export interface OffertypeViewParams extends BaseRouteParams {
  ot_menu: OFFERTYPE_ROUTES;
}

export interface OffertypeViewData extends OffertypeViewParams {
  offertype: Offertype;
}
