
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarifLog } from '../article-tarif-log';
@Component({
  selector: 'lvadg-article-tarif-log-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-tarif-log-manage.component.pug',
  styleUrls: ['./article-tarif-log-manage.component.sass']
})
export class ArticleTarifLogManageComponent implements OnInit {
  @Input() public articletariflog?: ArticleTarifLog;

  constructor() { }

  ngOnInit(): void {
  }

}
