import { RestoBase } from "./resto.base";
import { detailsField, integerField } from "@solidev/data";
import { LocationBase } from "../../locations/location/location.base";
import { ClientBase } from "../client/client.base";
import { Image } from "../../documents/image/image";
import { Label } from "../../catalog/label/label";
import { Relation } from "../relation/relation";

export class Resto extends RestoBase {
  static override readonly __name: string = "Resto";
  @integerField({
    name: "near_distance",
    description: "Distance",
    readonly: true,
    priority: 300,
  })
  public near_distance?: number;

  @detailsField({
    name: "location_details",
    description: "Adresse",
    readonly: true,
    model: LocationBase,
    priority: 600,
  })
  public location_details?: LocationBase;

  @detailsField({
    name: "client_details",
    description: "Client",
    readonly: true,
    model: ClientBase,
    priority: 700,
  })
  public client_details?: ClientBase;

  @detailsField({
    name: "logo_details",
    description: "Logo",
    readonly: true,
    model: Image,
    priority: 200,
  })
  public logo_details?: Image;

  @detailsField({
    name: "images_details",
    description: "Images",
    readonly: true,
    model: Image,
    many: true,
    priority: 150,
  })
  public images_details?: Image[];

  @detailsField({
    name: "slabels_details",
    description: "Labels",
    readonly: true,
    model: Label,
    many: true,
  })
  public slabels_details: Label[] = [];

  @detailsField({
    name: "storage_relation_details",
    description: "Distance dépot-restaurant",
    readonly: true,
    model: Relation,
  })
  public storage_relation_details?: Relation;
}
