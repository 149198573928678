import { TariftypeBase } from './tariftype.base';
import { detailsField } from '@solidev/data';
import { ClientBase } from '../../structures/client/client.base';
import { UserBase } from '../../users/user/user.base';
import { GenericregionBase } from '../../locations/genericregion/genericregion.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { MemberBase } from '../../structures/member/member.base';

export class Tariftype extends TariftypeBase {
  static override readonly __name: string = 'Tariftype';

  @detailsField({
    name: 'client_details',
    description: 'Client',
    readonly: true,
    model: ClientBase,
    priority: 750,
  })
  public client_details?: ClientBase;

  @detailsField({
    name: 'client_parent_details',
    description: 'Client (groupe)',
    readonly: true,
    model: ClientBase,
    priority: 745,
  })
  public client_parent_details?: ClientBase;

  @detailsField({
    name: 'asscom_details',
    description: 'Ass. Comm.',
    readonly: true,
    model: UserBase,
    priority: 700,
  })
  public asscom_details?: UserBase;

  @detailsField({
    name: 'respcom_details',
    description: 'Resp. Comm.',
    readonly: true,
    model: UserBase,
    priority: 700,
  })
  public respcom_details?: UserBase;

  @detailsField({
    name: 'pilotes_details',
    description: 'Pilotes',
    readonly: true,
    model: UserBase,
    many: true,
    priority: 700,
  })
  public pilotes_details?: UserBase[];

  @detailsField({
    name: 'region_details',
    description: 'Région',
    readonly: true,
    model: GenericregionBase,
    priority: 650,
  })
  public region_details?: GenericregionBase;

  @detailsField({
    name: 'storage_details',
    description: 'Dépôt',
    readonly: true,
    model: StorageBase,
    priority: 650,
  })
  public storage_details?: StorageBase;

  @detailsField({
    name: 'member_details',
    description: 'Adhérent',
    readonly: true,
    model: MemberBase,
    priority: 650,
  })
  public member_details?: MemberBase;
}
