import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link, PChoicePipe } from '@solidev/data';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { TariftypeRegionPermissionBase } from '../tariftype-region-permission.base';
import { TariftypeRegionPermission } from '../tariftype-region-permission';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';

@Component({
  selector: 'lvadg-tariftype-region-permission-display',
  standalone: true,
  imports: [CommonModule, UserDisplayComponent, GroupDisplayComponent, PChoicePipe],
  templateUrl: './tariftype-region-permission-display.component.pug',
  styleUrls: ['./tariftype-region-permission-display.component.sass'],
})
export class TariftypeRegionPermissionDisplayComponent {
  @Input() public permission?: TariftypeRegionPermission;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<TariftypeRegionPermissionBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
}
