import { ArticleTarifTemplateBase } from "./article-tarif-template.base";
import { detailsField, integerField } from "@solidev/data";
import { Article } from "../../catalog/article/article";
import { TariftypeBase } from "../tariftype/tariftype.base";
import { Earticle } from "../../catalog/earticle/earticle";

export class ArticleTarifTemplate extends ArticleTarifTemplateBase {
  static override readonly __name: string = "ArticleTarifTemplate";

  @detailsField({
    model: Article,
    readonly: true,
    description: "Détails article",
  })
  public article_details?: Article;

  @detailsField({
    model: Earticle,
    readonly: true,
    description: "Article client",
  })
  public eaclient_details?: Earticle;

  @detailsField({
    model: TariftypeBase,
    readonly: true,
    description: "Modèle de tarif",
  })
  public tariftype_details?: TariftypeBase;

  @integerField({
    name: "in_op",
    readonly: true,
    description: "Dans l'offre",
    priority: -1,
  })
  public in_op?: number;

  public override get _display(): string {
    return `${this.tariftype_details?.code || ""} ${this.name} `;
  }
}
