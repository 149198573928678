import { inject, Injectable, InjectionToken, signal } from '@angular/core';

export type SITE_MODES = 'dev' | 'prod' | 'stg';

export enum SITE_SPECIAL_MODES {
  DEMO = 'demo',
}

export const LVADG_MODE = new InjectionToken<string>('sitemode');

@Injectable({
  providedIn: 'root',
})
export class SitemodeService {
  public dev = signal(false);
  public prod = signal(false);
  public demo = signal(false);

  constructor() {
    const mode = inject(LVADG_MODE) ?? 'dev';
    switch (mode) {
      case 'dev':
        this.dev.set(true);
        break;
      case 'prod':
        this.prod.set(true);
        break;
      case 'stg':
        this.prod.set(true);
        break;
    }
    if (localStorage.getItem('dev') === 'true') {
      this.dev.set(true);
    }
    if (localStorage.getItem('demo') === 'true') {
      this.demo.set(true);
    }
  }
}
