import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarifTemplateBase } from '../article-tarif-template.base';
import { ArticleTarifTemplate } from '../article-tarif-template';
import { Link } from '@solidev/data';
import { RouterLinkWithHref } from '@angular/router';
import { Tariftype } from '../../tariftype/tariftype';

@Component({
  selector: 'lvadg-article-tarif-template-display',
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref],
  templateUrl: './article-tarif-template-display.component.pug',
  styleUrls: ['./article-tarif-template-display.component.sass'],
})
export class ArticleTarifTemplateDisplayComponent implements OnInit {
  @Input() public articletariftemplate?: ArticleTarifTemplateBase | ArticleTarifTemplate;
  @Input() public mode: 'line' | 'ttline' = 'line';
  @Input() public detail_route?: Link<ArticleTarifTemplateBase>;
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  @Input() public classes: Record<string, boolean> = {};
  public full?: ArticleTarifTemplate;

  constructor() {}

  ngOnInit(): void {
    if (this.articletariftemplate && (this.articletariftemplate as any).article_details) {
      this.full = this.articletariftemplate as ArticleTarifTemplate;
    }
  }
}
