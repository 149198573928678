
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypePermission } from '../offertype-permission';
@Component({
  selector: 'lvadg-offertype-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offertype-permission-manage.component.pug',
  styleUrls: ['./offertype-permission-manage.component.sass']
})
export class OffertypePermissionManageComponent implements OnInit {
  @Input() public offertypepermission?: OffertypePermission;

  constructor() { }

  ngOnInit(): void {
  }

}
