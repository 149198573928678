import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { UserService } from '../../../users/user/user.service';
import { GroupService } from '../../../users/group/group.service';
import { TarifNotificationDestination } from '../tarif-notification-destination';
import { DispeditComponent } from '@solidev/data';
import { ContactService } from '../../../users/contact/contact.service';
import { AuthService } from '../../../users/auth.service';
import { User } from '../../../users/user/user';
import { Observable } from 'rxjs';
import { TARIFNOTIFICATION_TYPE } from '../tarif-notification-destination.base';
import { Contact } from '../../../users/contact/contact';

@Component({
  selector: 'lvadg-tarif-notification-destination-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tarif-notification-destination-create.component.pug',
  styleUrls: ['./tarif-notification-destination-create.component.sass'],
})
export class TarifNotificationDestinationCreateComponent implements OnInit {
  @Input() public tariftype?: Tariftype;
  @Output() public created = new EventEmitter<void>();
  @Output() public cancelled = new EventEmitter<void>();
  public currentUser$!: Observable<User | null>;
  public dest!: TarifNotificationDestination;
  public contact!: Contact;
  public TD = TARIFNOTIFICATION_TYPE;

  constructor(
    private _ttact: TariftypeActionService,
    private _auth: AuthService,
    public user$: UserService,
    public group$: GroupService,
    public contact$: ContactService
  ) {}

  ngOnInit(): void {
    this.dest = new TarifNotificationDestination();
    this.contact = new Contact();
    this.currentUser$ = this._auth.currentUser$;
    if (this.tariftype) {
      this.dest.tariftype = this.tariftype.id;
      this.dest.tariftype_details = this.tariftype;
    }
  }

  public async save(isStaff: boolean = false) {
    const tt = this.tariftype;
    if (!isStaff) {
      this.dest.notification = TARIFNOTIFICATION_TYPE.NEGO_ALL;
    }
    if (tt && tt.id) {
      const res = await this._ttact.action(tt, {
        action: 'add_destination',
        user: this.dest.user,
        group: this.dest.group,
        contact: this.dest.contact,
        contact_last_name: this.contact.last_name,
        contact_first_name: this.contact.first_name,
        contact_email: this.contact.email,
        notification: this.dest.notification,
      });
      if (res.success) {
        this.created.next();
      }
    }
  }
}
