import { TariftypeRelationBase } from './tariftype-relation.base';
import { detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { TarifRelation } from '../tarif-relation/tarif-relation';

export class TariftypeRelation extends TariftypeRelationBase {
  static override readonly __name: string = 'TariftypeRelation';

  @detailsField({
    model: TariftypeBase,
    description: 'Source',
    readonly: true,
  })
  public src_details?: TariftypeBase;

  @detailsField({
    model: TariftypeBase,
    description: 'Destination',
    readonly: true,
  })
  public dest_details?: TariftypeBase;

  @detailsField({
    model: TarifRelation,
    description: 'Tarif associé',
    readonly: true,
  })
  public src_current_tarifrelation?: TarifRelation;
}
