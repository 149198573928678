import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Resto } from '../../../../../models/structures/resto/resto';
import { RESTO_ROUTES } from '../menu';
import { Storage } from '../../../../../models/structures/storage/storage';
import { Client } from '../../../../../models/structures/client/client';
import { VivalyaBill } from '../../../../../models/sales/vivalya-bill/vivalya-bill';
import { RestoNavComponent } from '../_nav/resto-nav.component';
import { VivalyaBillListComponent } from '../../../../../models/sales/vivalya-bill/vivalya-bill-list/vivalya-bill-list.component';

export interface RestoSalesViewParams extends BaseRouteParams {
  r_menu: RESTO_ROUTES;
  storage_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
}

export interface RestoSalesViewData extends RestoSalesViewParams {
  resto: Resto;
}

@Component({
  selector: 'lvadg-resto-sales-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, RestoNavComponent, VivalyaBillListComponent, IconComponent],
  templateUrl: './resto-sales-view.component.pug',
  styleUrls: ['./resto-sales-view.component.sass'],
})
export class RestoSalesViewComponent extends BaseDetailViewComponent<RestoSalesViewData, Resto> {
  public restoFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public storage_detail_route?: Link<Storage>;
  public client_detail_route?: Link<Client>;
  public resto_detail_route?: Link<Resto>;
  public bill_detail_route?: Link<VivalyaBill>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: RestoSalesViewData) {
    super.setRouteData(data);
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link<Resto>(data.resto_detail_route, data, this._router);
    }
    if (data.bill_detail_route) {
      this.bill_detail_route = new Link<VivalyaBill>(data.bill_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.restoFilter$ = this.data$.pipe(map((d) => ({ resto: d.resto.id })));
  }
}
