import {
  charField,
  computedField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';
import { CdataKeyChoiceBase } from '../cdata-key-choice/cdata-key-choice.base';
import {
  CDATAKEY_TYPE,
  CDATAKEY_TYPE_CHOICES,
} from '../cdata-key/cdata-key.base';

export class CdataBase extends DataModel {
  static override readonly __name: string = 'CdataBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @integerField({
    name: 'value_int',
    description: 'Valeur entière',
    defaultValue: 0,
    priority: -1,
  })
  public value_int: number = 0;

  @detailsField({
    name: 'value_dec',
    description: 'Valeur décimale',
    defaultValue: 0,
    priority: -1,
  })
  public value_dec: any = 0;

  @charField({
    name: 'value_stx',
    description: 'Valeur texte court',
    defaultValue: '',
    maxLength: 250,
    priority: -1,
  })
  public value_stx: string = '';

  @textField({
    name: 'value_ltx',
    description: 'Valeur texte long',
    defaultValue: '',
    priority: -1,
  })
  public value_ltx: string = '';

  @textField({
    name: 'value_rtx',
    description: 'Valeur texte riche',
    defaultValue: '',
    priority: -1,
  })
  public value_rtx: string = '';

  @dateField({
    name: 'value_dte',
    description: 'Valeur date',
    priority: -1,
  })
  public value_dte!: Date;

  @datetimeField({
    name: 'value_dth',
    description: 'Valeur date et heure',
    priority: -1,
  })
  public value_dth!: Date;

  @detailsField({
    name: 'value_json',
    description: 'Valeur JSON',
    priority: -1,
  })
  public value_json!: any;

  @foreignKeyField({
    name: 'key',
    description: 'ID Clef',
    related: 'CDataKey',
    priority: -1,
  })
  public key!: number;

  @foreignKeyField({
    name: 'choice',
    description: 'ID Choix',
    related: 'CDataKeyChoice',
    priority: -1,
  })
  public choice!: number;

  @foreignKeyField({
    name: 'member',
    description: 'ID Adhérent',
    related: 'Member',
    priority: -1,
  })
  public member!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -1,
  })
  public storage!: number;

  @foreignKeyField({
    name: 'provider',
    description: 'ID Fournisseur',
    related: 'Provider',
    priority: -1,
  })
  public provider!: number;

  @foreignKeyField({
    name: 'producer',
    description: 'ID Producteur',
    related: 'Producer',
    priority: -1,
  })
  public producer!: number;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Resto',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @foreignKeyField({
    name: 'product',
    description: 'ID Produit',
    related: 'Product',
    priority: -1,
  })
  public product!: number;

  @foreignKeyField({
    name: 'family',
    description: 'ID Famille',
    related: 'Family',
    priority: -1,
  })
  public family!: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
    priority: -1,
  })
  public article!: number;

  @foreignKeyField({
    name: 'document',
    description: 'ID Document',
    related: 'Document',
    priority: -1,
  })
  public document!: number;

  @charField({
    name: 'file',
    description: 'Fichier',
    maxLength: 100,
    priority: -1,
  })
  public file!: string;

  @foreignKeyField({
    name: 'folder',
    description: 'ID Dossier',
    related: 'Folder',
    priority: -1,
  })
  public folder!: number;

  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;

  @detailsField({
    description: 'Choix',
    model: CdataKeyChoiceBase,
    readonly: true,
    priority: -1,
  })
  public choice_details?: CdataKeyChoiceBase;

  @charField({
    name: 'type',
    description: 'Type',
    readonly: true,
    choices: CDATAKEY_TYPE_CHOICES,
    priority: 700,
  })
  public type: CDATAKEY_TYPE = CDATAKEY_TYPE.SHORT_TEXT;
  @charField({
    name: 'display_value',
    description: 'Valeur affichée',
    readonly: true,
    priority: 600,
  })
  public display_value!: string;

  @computedField({
    name: 'value',
    description: 'Valeur',
    priority: -1,
  })
  public get value(): string {
    switch (this.type) {
      case CDATAKEY_TYPE.INTEGER:
        return this.value_int.toString();
      case CDATAKEY_TYPE.DECIMAL:
        return this.value_dec.toString();
      case CDATAKEY_TYPE.SHORT_TEXT:
        return this.value_stx;
      case CDATAKEY_TYPE.LONG_TEXT:
        return this.value_ltx;
      case CDATAKEY_TYPE.RICH_TEXT:
        return this.value_rtx;
      case CDATAKEY_TYPE.DATE:
        return this.value_dte.toString();
      case CDATAKEY_TYPE.DATETIME:
        return this.value_dth.toString();
      default:
        return '';
    }
  }
}
