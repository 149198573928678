
<form [formGroup]="form" (submit)="create()">
  <div class="row">
    <div class="col-6">
      <data-dispedit [model]="group" field="slug" [form]="form" mode="form">Code du groupe</data-dispedit>
    </div>
    <div class="col-6">
      <data-dispedit [model]="group" field="name" [form]="form" mode="form">Nom du groupe</data-dispedit>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-8">
      <button class="btn btn-primary w-100" [disabled]="form.invalid">Créer le groupe</button>
    </div>
    <div class="col-4">
      <button class="btn btn-secondary w-100" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>