
<ng-container *ngIf="step">
  <ng-container *ngIf="step.pstep!==MAIN_STEPS.PREPARATION">
    <h4 class="my-2">{{step.title$||'Édition du tarif et des infos du tarif'}}</h4>
    <p class="text-info" [innerHTML]="step.message$"></p>
    <ng-container *ngIf="step.pstep===MAIN_STEPS.PUBLICATION">
      <ng-container *ngFor="let tn of notifs$|async">
        <div class="text-warning fw-bold" *ngIf="tn.status===TNSTATUS.PREPARE || tn.status===TNSTATUS.PENDING">Notification {{tn.id}} (priorité {{tn.priority}}) pas encore envoyée
          <div class="small">{{tn.title}}</div>
          <button class="btn btn-outline-danger w-100 p-3 my-3" (click)="action({action: 'publish_cancel'})" *ngIf="canCancel(tn)">Annuler la publication</button>
        </div>
        <div class="text-primary fw-bold" *ngIf="tn.status===TNSTATUS.SENDING">Notification {{tn.id}} (priorité {{tn.priority}}) en cours d'envoi
          <div class="small">{{tn.title}}</div>
        </div>
        <div class="text-success fw-bold" *ngIf="tn.status===TNSTATUS.SENT">Notification {{tn.id}} (priorité {{tn.priority}})  envoyée
          <div class="small">{{tn.title}}</div>
          <button class="btn btn-outline-danger w-100 p-3 my-3" (click)="action({action: 'publish_cancel'})">Annuler la publication</button>
        </div>
      </ng-container>
    </ng-container>
    <button class="btn btn-outline-warning w-100 p-3 my-2" (click)="action({action: 'next'})"><i class="bi bi-check-lg me-2"></i>Créer un erratum</button>
  </ng-container>
  <ng-container *ngIf="step.pstep===MAIN_STEPS.PREPARATION">
    <h4 class="my-2">{{step.title$||'Valider et passer à la publication'}}</h4>
    <p class="text-info" [innerHTML]="step.message$"></p>
    <button class="btn btn-outline-warning w-100 p-3 my-2" (click)="action({action: 'next'})"><i class="bi bi-check-lg me-2"></i>Passer à la publication</button>
  </ng-container>
</ng-container>