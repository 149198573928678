
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Producer } from "./producer";

@Injectable({
  providedIn: 'root'
})
export class ProducerService extends Collection<Producer> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/producers", Producer);
  }
}
