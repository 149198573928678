
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ArticleTarifItem } from "./article-tarif-item";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifItemService extends Collection<ArticleTarifItem> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/articletarifitems", ArticleTarifItem);
  }
}
