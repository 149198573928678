
<ng-container *ngIf="orderprocessstorage">
  <dl class="row">
    <data-dispedit class="col" [model]="orderprocessstorage" field="storage" mode="dd" [collection]="storage$">Dépôt</data-dispedit>
    <data-dispedit class="col" [model]="orderprocessstorage" field="platform" mode="dd" [collection]="platform$">Plateforme</data-dispedit>
    <div class="col-12">
      <dt>Règles de récurrence (date d'enlèvement)
        <div><span class="text-info">Si aucune règle n'est définie, la règle par défaut est appliquée.</span><span class="ms-2 text-warning">Les jours fériés ne sont pas pris en compte</span></div>
      </dt>
      <dd>
        <lvadg-rrule-editor [rule]="orderprocessstorage.params.delivery_dates_rrule" [editable]="true" (save)="saveRrule(orderprocessstorage, $event)"></lvadg-rrule-editor>
      </dd>
    </div>
  </dl>
</ng-container>