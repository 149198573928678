import { Component } from '@angular/core';
import { OrderprocessPermissionDetailViewData } from './orderprocess-permission-detail-view.component.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { OrderprocessPermission } from '../../../../models/orders/orderprocess-permission/orderprocess-permission';
import {
  OrderprocessPermissionManageComponent
} from "../../../../models/orders/orderprocess-permission/orderprocess-permission-manage/orderprocess-permission-manage.component";

@Component({
  selector: 'lvadg-orderprocess-permission-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, OrderprocessPermissionManageComponent],
  templateUrl: './orderprocess-permission-detail-view.component.pug',
  styleUrls: ['./orderprocess-permission-detail-view.component.sass'],
})
export class OrderprocessPermissionDetailViewComponent extends BaseDetailViewComponent<
  OrderprocessPermissionDetailViewData,
  OrderprocessPermission
> {
  public override setRouteData(data: OrderprocessPermissionDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
