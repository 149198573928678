import {
  charField,
  DataModel,
  datetimeField,
  decimalField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum RnmtarifType {
  RAW = 'RW',
  CONT = 'CT',
  WEEK = 'W1',
}

export class RnmtarifBase extends DataModel {
  static override readonly __name: string = 'RnmtarifBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'rnmlib',
    description: 'ID Libellé',
    related: 'RnmLibelle',
  })
  public rnmlib!: number;

  @foreignKeyField({
    name: 'cotation',
    description: 'ID Cotation',
    related: 'RnmCotation',
  })
  public cotation!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: RnmtarifType.RAW,
    required: true,
    maxLength: 2,
    choices: [
      {
        value: RnmtarifType.RAW,
        desc: 'Donnée brute',
      },
      {
        value: RnmtarifType.CONT,
        desc: 'Donnée continue',
      },
      {
        value: RnmtarifType.WEEK,
        desc: 'Donnée agrégée 1 semaine',
      },
    ],
  })
  public type: RnmtarifType = RnmtarifType.RAW;

  @foreignKeyField({
    name: 'marche',
    description: 'ID Marché',
    related: 'RnmMarche',
  })
  public marche!: number;

  @datetimeField({
    name: 'start',
    description: 'Date de début',
  })
  public start!: Date;

  @datetimeField({
    name: 'end',
    description: 'Date de fin',
  })
  public end!: Date;

  @decimalField({
    name: 'price',
    description: 'Prix',
    factor: 10000,
  })
  public price!: number;

  @decimalField({
    name: 'minprice',
    description: 'Prix min',
    factor: 10000,
  })
  public minprice!: number;

  @decimalField({
    name: 'maxprice',
    description: 'Prix max',
    factor: 10000,
  })
  public maxprice!: number;

  @decimalField({
    name: 'variation',
    description: 'Variation',
    factor: 10000,
  })
  public variation!: number;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
  })
  public orig_id!: number;
}
