import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Georegion } from '../georegion';
import { GeoregionService } from '../georegion.service';
import { FiltersParams, Link, ModelListAutocompleteFilter, ModelListService, ModelListTextFilter } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GeodepartementBase } from '../../geodepartement/geodepartement.base';
import { GeodepartementDisplayComponent } from '../../geodepartement/geodepartement-display/geodepartement-display.component';
import { GeocountryDisplayComponent } from '../../geocountry/geocountry-display/geocountry-display.component';
import { GeocountryBase } from '../../geocountry/geocountry.base';
import { GeocountryService } from '../../geocountry/geocountry.service';

@Component({
  selector: 'lvadg-georegion-list',
  standalone: true,
  templateUrl: './georegion-list.component.pug',
  styleUrls: ['./georegion-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, GeodepartementDisplayComponent, GeocountryDisplayComponent],
})
export class GeoregionListComponent extends ModellistComponent<Georegion> {
  @Input() public geodepartement_detail_route?: Link<GeodepartementBase>;
  @Input() public geocountry_detail_route?: Link<GeocountryBase>;

  constructor(
    coll: GeoregionService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private geocountries$: GeocountryService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'country'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListAutocompleteFilter({
          field: 'country',
          name: 'country',
          label: 'Pays',
          collection: this.geocountries$,
        }),
      ],
    };
  }
}
