import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum DOCUMENT_STATUS {
  ONLINE = 'OK',
  OFFLINE_DL_OK = 'LN',
  ONLINE_STAFF_ONLY = 'ST',
  ONLINE_SU_ONLY = 'SU',
  OFFLINE = 'KO',
  DELETED = 'DE',
}

export class DocumentBase extends DataModel {
  static override readonly __name: string = 'DocumentBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 110,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'slug',
    description: 'Slug',
    maxLength: 200,
    priority: 800,
  })
  public slug!: string;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 200,
    priority: 900,
  })
  public title!: string;

  @charField({
    name: 'mimetype',
    description: 'Type de fichier',
    maxLength: 250,
    priority: 700,
  })
  public mimetype!: string;

  @charField({
    name: 'filename',
    description: 'Nom du fichier',
    maxLength: 255,
    priority: 650,
  })
  public filename!: string;

  @integerField({
    name: 'filesize',
    description: 'Taille du fichier',
    defaultValue: 0,
    priority: 600,
  })
  public filesize: number = 0;

  @charField({
    name: 'file',
    description: 'URL du fichier',
    maxLength: 200,
    priority: 550,
  })
  public file!: string;

  @foreignKeyField({
    name: 'image',
    description: 'ID image',
    related: 'Image',
    priority: -1,
  })
  public image!: number;

  @dateField({
    name: 'start',
    description: 'Date de début de validité',
    priority: 300,
  })
  public start!: Date;

  @dateField({
    name: 'end',
    description: 'Date de fin de validité',
    priority: 250,
  })
  public end!: Date;

  @detailsField({
    name: 'parameters',
    description: 'Paramètres',
    defaultValue: {},
    priority: -1,
  })
  public parameters: any = {};

  @detailsField({
    name: 'content',
    description: 'Contenu',
    defaultValue: {},
    priority: 190,
  })
  public content: any = {};

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -100,
  })
  public orig_id!: number;

  @charField({
    name: 'status',
    description: 'Status',
    maxLength: 4,
    defaultValue: DOCUMENT_STATUS.ONLINE,
    choices: [
      { value: DOCUMENT_STATUS.ONLINE, desc: 'En ligne' },
      {
        value: DOCUMENT_STATUS.OFFLINE_DL_OK,
        desc: 'Hors ligne mais téléchargement possible',
      },
      {
        value: DOCUMENT_STATUS.ONLINE_STAFF_ONLY,
        desc: 'En ligne pour les membres du staff',
      },
      {
        value: DOCUMENT_STATUS.ONLINE_SU_ONLY,
        desc: 'En ligne pour les super-utilisateurs',
      },
      { value: DOCUMENT_STATUS.OFFLINE, desc: 'Hors ligne' },
      { value: DOCUMENT_STATUS.DELETED, desc: 'Supprimé' },
    ],
    priority: 200,
  })
  public status: string = DOCUMENT_STATUS.ONLINE;

  public get _display(): string {
    // SEE: ajouter un code unique de document pour faciliter la recherche
    return this.title;
  }
}
