import { CdataBase } from './cdata.base';
import { detailsField } from '@solidev/data';
import { ProducerBase } from '../../structures/producer/producer.base';
import { ProductBase } from '../../catalog/product/product.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { CdataKeyBase } from '../cdata-key/cdata-key.base';

export class Cdata extends CdataBase {
  static override readonly __name: string = 'Cdata';

  @detailsField({
    description: 'Clef de donnée',
    model: CdataKeyBase,
    readonly: true,
    priority: 830,
  })
  public key_details?: CdataKeyBase;

  @detailsField({
    description: 'Producteur',
    model: ProducerBase,
    readonly: true,
    priority: 800,
  })
  public producer_details?: ProducerBase;

  @detailsField({
    description: 'Produit',
    model: ProductBase,
    readonly: true,
    priority: 801,
  })
  public product_details?: ProductBase;

  @detailsField({
    description: 'Dépôt',
    model: StorageBase,
    readonly: true,
    priority: 802,
  })
  public storage_details?: StorageBase;
}
