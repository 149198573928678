
<form [formGroup]="createForm" (submit)="save()">
  <div class="row">
    <data-dispedit class="col-4" [model]="news" field="title" mode="form" [form]="createForm">Titre de l'info</data-dispedit>
    <data-dispedit class="col-4" [model]="news" field="type" mode="form" [form]="createForm">Type d'info (INT/EXT)</data-dispedit>
    <data-dispedit class="col-4" [model]="news" field="icon" mode="form" [form]="createForm">Icône de contenu</data-dispedit>
    <data-dispedit class="col-4" [model]="news" field="datestart" mode="form" [form]="createForm">Date de début de publication</data-dispedit>
    <data-dispedit class="col-4" [model]="news" field="dateend" mode="form" [form]="createForm">Date de fin de publication</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>