
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { PresentationService} from "./presentation.service";
import { Presentation } from "./presentation";

@Injectable({
  providedIn: 'root'
})
export class PresentationResolver  {
  constructor(private _rs: PresentationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Presentation> {
    return this._rs.fetch(+route.params["presentationId"])
  }
}
