
<ng-container *ngIf="cdatakey">
  <dl class="row g-3">
    <data-dispedit class="col-3" [model]="cdatakey" field="parent" mode="dd" [collection]="keys$" [filter]="{parent: null}">Parent</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="type" mode="dd">Type</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="dest" mode="dd">Destination</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="status" mode="dd">État</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="name" mode="dd">Nom</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="description" mode="dd">Description</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="code" mode="dd">Code</data-dispedit>
    <data-dispedit class="col-3" [model]="cdatakey" field="unit" mode="dd">Unité</data-dispedit>
  </dl>
</ng-container>