import { BrandBase } from './brand.base';
import { detailsField } from '@solidev/data';
import { Producer } from '../../../structures/producer/producer';
import { Provider } from '../../../structures/provider/provider';

export class Brand extends BrandBase {
  static override readonly __name: string = 'Brand';

  @detailsField({
    model: Producer,
    description: 'Producteur',
    readonly: true,
    priority: 300,
  })
  public producer_details?: Producer;

  @detailsField({
    model: Provider,
    description: 'Fournisseur',
    readonly: true,
    priority: 300,
  })
  public provider_details?: Provider;
}
