
<ng-container *ngIf="calibre">
  <dl class="row">
    <data-dispedit class="col-6" [model]="calibre" field="id" [editable]="false" mode="dd">ID</data-dispedit>
    <data-dispedit class="col-6" [model]="calibre" field="vvid" [editable]="false" mode="dd">ID Vivalya (deprecated)</data-dispedit>
    <data-dispedit class="col-6" [model]="calibre" field="family" [editable]="true" mode="dd" [collection]="families$" [filter]="{min_level: 4}">Famille</data-dispedit>
    <data-dispedit class="col-3" [model]="calibre" field="code" [editable]="true" mode="dd">Code</data-dispedit>
    <data-dispedit class="col-3" [model]="calibre" field="type" [editable]="true" mode="dd">Type</data-dispedit>
    <data-dispedit class="col-6" [model]="calibre" field="name" [editable]="true" mode="dd">Nom / description</data-dispedit>
  </dl>
</ng-container>