import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Relation } from '../relation';
import { RelationService } from '../relation.service';
import {
  BanAdapter,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListGeodistanceFilter,
  ModelListNumberFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { ProducerDisplayComponent } from '../../producer/producer-display/producer-display.component';
import { StorageDisplayComponent } from '../../storage/storage-display/storage-display.component';
import { ClientDisplayComponent } from '../../client/client-display/client-display.component';
import { MemberDisplayComponent } from '../../member/member-display/member-display.component';
import { ProviderDisplayComponent } from '../../provider/provider-display/provider-display.component';
import { ProviderBase } from '../../provider/provider.base';
import { StorageBase } from '../../storage/storage.base';
import { ClientBase } from '../../client/client.base';
import { MemberBase } from '../../member/member.base';
import { ProducerBase } from '../../producer/producer.base';
import { MemberService } from '../../member/member.service';
import { StorageService } from '../../storage/storage.service';
import { ProducerService } from '../../producer/producer.service';
import { HttpClient } from '@angular/common/http';
import { DistanceCacheService } from '../../../locations/distance/distance-cache.service';
import { GeodepartementService } from '../../../locations/geodepartement/geodepartement.service';
import { GeoregionService } from '../../../locations/georegion/georegion.service';
import { GeocommuneService } from '../../../locations/geocommune/geocommune.service';
import { RestoDisplayComponent } from '../../resto/resto-display/resto-display.component';
import { RestoBase } from '../../resto/resto.base';
import { RestoService } from '../../resto/resto.service';
import { ClientService } from '../../client/client.service';

@Component({
  selector: 'lvadg-relation-list',
  standalone: true,
  templateUrl: './relation-list.component.pug',
  styleUrls: ['./relation-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FlagsDisplayComponent,
    SafeDeleteComponent,
    PChoicePipe,
    ProducerDisplayComponent,
    StorageDisplayComponent,
    ClientDisplayComponent,
    MemberDisplayComponent,
    ProviderDisplayComponent,
    RestoDisplayComponent,
  ],
})
export class RelationListComponent extends ModellistComponent<Relation> {
  @Input() public producer_detail_route?: Link<ProducerBase>;
  @Input() public provider_detail_route?: Link<ProviderBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public client_detail_route?: Link<ClientBase>;
  @Input() public member_detail_route?: Link<MemberBase>;
  @Input() public resto_detail_route?: Link<RestoBase>;
  public displayLocation: boolean = false;

  constructor(
    coll: RelationService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _members: MemberService,
    private _storages: StorageService,
    private _producers: ProducerService,
    private _restos: RestoService,
    private _clients: ClientService,
    private _http: HttpClient,
    private _distc: DistanceCacheService,
    private _departments: GeodepartementService,
    private _regions: GeoregionService,
    private _communes: GeocommuneService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    const adapter = new BanAdapter(this._http, 'https://gis.lavieadugout.fr/geocoder/search');
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'type', 'status'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Recherche par type',
          model: Relation,
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Recherche par état',
          model: Relation,
        }),
        // Recherche par producteurs
        new ModelListAutocompleteMultiFilter({
          field: 'producers',
          name: 'producers',
          label: 'Recherche par producteur(s)',
          collection: this._producers,
        }),
        // Recherche par dépôt
        new ModelListAutocompleteMultiFilter({
          field: 'storages',
          name: 'storages',
          label: 'Recherche par dépôt(s)',
          collection: this._storages,
        }),
        // Recherche par adhérent
        new ModelListAutocompleteMultiFilter({
          field: 'members',
          name: 'members',
          label: 'Recherche par adhérent(s)',
          collection: this._members,
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'restos',
          name: 'restos',
          label: 'Recherche par restaurant(s)',
          collection: this._restos,
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'clients',
          name: 'clients',
          label: 'Recherche par client(s)',
          collection: this._clients,
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'restoclients',
          name: 'restoclients',
          label: 'Recherche par client(s) - via les restaurants',
          collection: this._clients,
        }),

        // Recherche par producteur à proximité
        new ModelListGeodistanceFilter({
          name: 'producer_near',
          field: 'producer_near',
          label: "Recherche producteur à proximité de  (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: 'Ville ou adresse (en France)',
          geolocator: (pos: string) => adapter.search(pos),
        }),
        // Recherche par distance dépôt-producteur (au moins un)
        new ModelListNumberFilter({
          name: 'storage_producer_distance',
          field: 'storage_producer_distance',
          label: 'Recherche par distance dépôt-producteur',
          desc: 'Distance en km',
        }),

        // TODO: filtres ci-dessous à implémenter
        // Commune du producteur
        new ModelListAutocompleteMultiFilter({
          field: 'producer_communes',
          name: 'producer_communes',
          label: 'Commune(s) du producteur',
          collection: this._communes,
          filter: { country_code: 'FR' },
        }),
        // Département du producteur
        new ModelListAutocompleteMultiFilter({
          field: 'producer_departments',
          name: 'producer_departments',
          label: 'Département(s) du producteur',
          collection: this._departments,
          filter: { country_code: 'FR' },
        }),
        // Région du producteur
        new ModelListAutocompleteMultiFilter({
          field: 'producer_regions',
          name: 'producer_regions',
          label: 'Région(s) du producteur',
          collection: this._regions,
          filter: { country_code: 'FR' },
        }),
        // Commune du dépôt
        new ModelListAutocompleteMultiFilter({
          field: 'storage_communes',
          name: 'storage_communes',
          label: 'Commune(s) du dépôt',
          collection: this._communes,
          filter: { country_code: 'FR' },
        }),
        // Département du dépôt
        new ModelListAutocompleteMultiFilter({
          field: 'storage_departments',
          name: 'storage_departments',
          label: 'Département(s) du dépôt',
          collection: this._departments,
          filter: { country_code: 'FR' },
        }),
        // Région du dépôt
        new ModelListAutocompleteMultiFilter({
          field: 'storage_regions',
          name: 'storage_regions',
          label: 'Région(s) du dépôt',
          collection: this._regions,
          filter: { country_code: 'FR' },
        }),
        // Dépôt proche de
        new ModelListGeodistanceFilter({
          name: 'storage_near',
          field: 'storage_near',
          label: "Recherche dépôt à proximité de (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: 'Ville ou adresse (en France)',
          geolocator: (pos: string) => adapter.search(pos),
        }),
      ],
    };
  }
}
