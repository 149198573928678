import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '@solidev/data';
import { Orderprocessstorage } from '../orderprocessstorage';
import { OrderprocessstorageBase } from '../orderprocessstorage.base';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-orderprocessstorage-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './orderprocessstorage-display.component.pug',
  styleUrls: ['./orderprocessstorage-display.component.sass'],
})
export class OrderprocessstorageDisplayComponent implements OnInit {
  @Input() public orderprocessstorage?:
    | OrderprocessstorageBase
    | Orderprocessstorage;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<OrderprocessstorageBase>;

  constructor() {}

  ngOnInit(): void {}
}
