import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Origin } from '../origin';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-origin-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './origin-manage.component.pug',
  styleUrls: ['./origin-manage.component.sass'],
})
export class OriginManageComponent implements OnInit {
  @Input() public origin!: Origin;

  constructor() {}

  ngOnInit(): void {}
}
