import { Component, Input } from '@angular/core';
import { OrderDetailViewData } from './order-detail-view.component.params';

import { Link } from '@solidev/data';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { OrderManageComponent } from '../../../../models/orders/order/order-manage/order-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { OrdergroupBase } from '../../../../models/orders/ordergroup/ordergroup.base';
import { OrderEditComponent } from '../../../../models/orders/order/order-edit/order-edit.component';
import {Order} from "../../../../models/orders/order/order";

@Component({
  selector: 'lvadg-order-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, OrderManageComponent, OrderEditComponent],
  templateUrl: './order-detail-view.component.pug',
  styleUrls: ['./order-detail-view.component.sass'],
})
export class OrderDetailViewComponent extends BaseDetailViewComponent<OrderDetailViewData, Order> {
  @Input() public ordergroup_detail_route?: Link<OrdergroupBase>;

  public override setRouteData(data: OrderDetailViewData) {
    super.setRouteData(data);
    this.ordergroup_detail_route = new Link(data.ordergroup_detail_route, data);
  }
}
