import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Image } from "../image";
import { ImageDisplay } from "../image.base";

@Component({
  selector: "lvadg-image-display",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: "./image-display.component.pug",
  styleUrls: ["./image-display.component.sass"],
})
export class ImageDisplayComponent {
  @Input() public image?: Image | ImageDisplay;
  @Input() public mode:
    | "list"
    | "list-image"
    | "list-preview"
    | "icon"
    | "preview"
    | "medium"
    | "large"
    | "xlarge" = "list";
  @Input() public hover: boolean = true;
  @Input() public thumbnail: boolean = true;
  @Input() public classes: string[] | string = [];
  @Input() public imgClasses: string[] | string = [];
  @Output() public clicked = new EventEmitter<Image | ImageDisplay>();
}
