
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class RnmlibelleBase extends DataModel {
  static override readonly __name: string = "RnmlibelleBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @datetimeField({"name": "created", "description": "created", "readonly": true})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated", "readonly": true})
  public updated!: Date;

  @foreignKeyField({"name": "rnm", "description": "rnm", "related": "Rnm"})
  public rnm!: number;

  @charField({"name": "code", "description": "code", "maxLength": 50})
  public code!: string;

  @charField({"name": "libelle", "description": "libelle", "maxLength": 300})
  public libelle!: string;

  @charField({"name": "ulibelle", "description": "ulibelle", "maxLength": 100})
  public ulibelle!: string;

  @integerField({"name": "orig_id", "description": "orig id"})
  public orig_id!: number;


}
