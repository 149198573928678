import { Component } from '@angular/core';
import { RegionDetailViewData } from './region-detail-view.component.params';
import { FilterDefaults, Link } from '@solidev/data';
import { Region } from '../../../../../models/catalog/meta/region/region';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { META_ARTICLE_LIST_FIELDS } from '../../constants';
import { map, Observable } from 'rxjs';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';
import { RegionManageComponent } from '../../../../../models/catalog/meta/region/region-manage/region-manage.component';

@Component({
  selector: 'lvadg-region-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ArticleListComponent, RegionManageComponent],
  templateUrl: './region-detail-view.component.pug',
  styleUrls: ['./region-detail-view.component.sass'],
})
export class RegionDetailViewComponent extends BaseDetailViewComponent<RegionDetailViewData, Region> {
  public list_route!: Link<Region>;
  public article_detail_route!: Link<ArticleBase>;
  public article_list_fields = META_ARTICLE_LIST_FIELDS;

  public metaFilter$!: Observable<FilterDefaults>;

  public override setRouteData(data: RegionDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.metaFilter$ = this.data$.pipe(map((d) => ({ regions: d.region.id })));
  }

  deleted() {
    this.list_route.navigate();
  }
}
