import {Inject, Injectable, InjectionToken} from "@angular/core";
import {
  Collection,
  DataBackend,
  DataMessageService,
  RouteConfigItem,
} from "@solidev/data";
import {Print} from "./print";
import {
  first,
  firstValueFrom,
  map,
  Observable,
  ReplaySubject,
  tap,
} from "rxjs";
import {PrintSettingsItem} from "../print-settings";
import {PRINT_STATUS} from "./print.base";
import {LayoutService} from "../layout/layout.service";
import {ProgressAction, WsService} from "../../../components/live/ws.service";
import {ProviderData} from "../rstypes/ProviderData";

/**
 * Injectable PRINT_DETAIL_ROUTE
 */
export const PRINT_DETAIL_ROUTE = new InjectionToken<RouteConfigItem>(
  "print.detail.route",
);

/**
 * Manage prints.
 *
 * TODO: add user print context ?
 */
@Injectable({
  providedIn: "root",
})
export class PrintService extends Collection<Print> {
  public current: Print | null = null;
  private _available?: ReplaySubject<Print[]>;

  constructor(
    _backend: DataBackend,
    private _layout: LayoutService,
    private _ws: WsService,
    private _msgs: DataMessageService,
    @Inject(PRINT_DETAIL_ROUTE) public detailRoute: RouteConfigItem,
  ) {
    super(_backend, "/v3/prints", Print);
  }

  /**
   * Returns an observable of all available prints (PRP status).
   * This observable reemits on every fetchAvailable() call.
   */
  public get available$(): Observable<Print[]> {
    if (!this._available) {
      this._available = new ReplaySubject<Print[]>(1);
      this.fetchAvailable();
    }
    return this._available.asObservable();
  }

  /**
   * Returns the current print as an observable.
   * If there is no current print, the first available print is set as current.
   * If there are no available prints, null is returned.
   *
   * Current print id is stored in localStorage.
   *
   * @returns observable of current print or null
   */
  public get current$(): Observable<Print | null> {
    return this.available$.pipe(
      map((p) => {
        const curId = localStorage.getItem("currentPrintId");
        if (curId !== null) {
          const cur = p.find((p) => p.id === +curId);
          if (cur) {
            return cur;
          } else {
            localStorage.removeItem("currentPrintId");
          }
        }
        // no current print, set first available as current
        if (p.length > 0) {
          localStorage.setItem("currentPrintId", p[0].id.toString());
          return p[0];
        } else {
          return null;
        }
      }),
      tap((p) => (this.current = p)),
    );
  }

  /**
   * Fetches all available prints (PRP status) and emits them to available$.
   */
  public fetchAvailable() {
    this.list({
      status: "PRP",
      fields: ["id", "name", "status", "created", "updated"].join(","),
      sort: "-id",
      page_size: 20,
    })
      .pipe(first())
      .subscribe((p) => this._available?.next(p));
  }

  /**
   * Sets the current print.
   */
  public async setCurrent(p: Print) {
    if (p.status !== PRINT_STATUS.PRP) {
      p.status = PRINT_STATUS.PRP;
      await firstValueFrom(p.update(["status"]));
    }
    localStorage.setItem("currentPrintId", p.id.toString());
    this.fetchAvailable();
  }

  /**
   * Process print command.
   *
   * Print commands can take a long time, so live feedback should be provided.
   * Each print have its own channel (`print_feedback_${print.id}`) to send progress messages, from printer or from api.
   *
   * Quick print = create a print, add the selected items, and get the PDF, close the print
   * Add to print = get or create a print, add the selected items (and blink the print icon)
   */
  public async print(
    ps: PrintSettingsItem,
    data: ProviderData,
    context: ProviderData[],
    mode: "quick_print" | "add_to_print" | "button",
  ) {
    if (mode === "quick_print" || mode === "button") {
      this._quickPrint(ps, data, context);
    } else {
      this._addToPrint(ps, data, context);
    }
  }

  /**
   * Process quick print command.
   *
   * SEE: add progress indicator ?
   *
   * - create a print
   * - add the selected template
   * - get the PDF
   * - close the print
   *
   * @param ps print settings
   * @param data template data
   * @param context template data context items
   */
  private async _quickPrint(
    ps: PrintSettingsItem,
    data: ProviderData,
    context: ProviderData[],
  ): Promise<boolean> {
    // Create a print
    let prt: Print;
    try {
      prt = await firstValueFrom(
        this.create({
          status: PRINT_STATUS.PRP,
          name: ps.label,
        }).save(),
      );
      const detail_route = this.detailRoute?.route(prt);
      const actions: ProgressAction[] = [];
      if (detail_route) {
        actions.push({
          label: "Mise en page",
          icon: "print",
          route: detail_route,
        });
      }
      this._msgs.success(
        "Impression créée",
        "L'impression est en cours, merci de patienter",
      );
      this._ws.progress(
        `print_feedback_${prt.id}`,
        "global",
        100,
        `Impression : ${prt.name}`,
        actions,
      );
    } catch (e) {
      this._msgs.error(
        "Erreur lors de la création de l'impression",
        "Impossible de créer l'impression",
        e,
      );
      return false;
    }
    // FIXME: manage errors & results
    // Add the selected template
    console.log("Adding layout to print : ", prt.id, ps, data, context);
    await firstValueFrom(this._layout.add$(prt.id, ps, data, context));
    // Download the PDF
    await firstValueFrom(this._layout.pdf$(prt.id));
    // Close the print
    prt.status = PRINT_STATUS.ARC;
    await firstValueFrom(prt.save());
    // FIXME: let user unprogress ?
    // this._ws.unprogress(`print_feedback_${prt.id}`);
    this.fetchAvailable();
    return true;
  }

  /**
   * Add a print preset to current print.
   * If there is no current print, create one.
   *
   * @param ps Print preset settings
   * @param data Print preset data
   * @param context Print preset context
   * @private
   */
  private async _addToPrint(
    ps: PrintSettingsItem,
    data: ProviderData,
    context: ProviderData[],
  ) {
    // Get current print, create if none.
    let prt = await firstValueFrom(this.current$);
    if (prt === null) {
      // Create a print
      try {
        prt = await firstValueFrom(
          this.create({
            status: PRINT_STATUS.PRP,
            name: ps.label,
          }).save(),
        );
      } catch (e) {
        this._msgs.error(
          "Erreur lors de la création de l'impression",
          "Impossible de créer l'impression",
          e,
        );
        return;
      }
    }
    this._ws.progress(
      `print_feedback_${prt.id}`,
      "global",
      20,
      `Impression : ${prt.name}`,
    );
    // FIXME: manage errors & results
    await firstValueFrom(this._layout.add$(prt.id, ps, data, context));
    this._ws.unprogress(`print_feedback_${prt.id}`);
    this.fetchAvailable();
    this._msgs.success(
      "Impression mise à jour",
      "L'impression a été mise à jour avec succès",
    );
  }
}
