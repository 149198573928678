
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action [title]="data.page_title ||'Offres contractuelles'" level="h1" ri="offer"></lvadg-header-action>
  <div class="d-flex flex-column flex-md-row justify-content-start my-4 gap-2">
    <div class="me-md-2 flex-grow-1">
      <input class="form-control" placeholder="Filtrer par texte" [formControl]="searchForm">
    </div>
    <div class="me-md-2 flex-grow-1">
      <select class="form-select" [formControl]="storageForm">
        <option value="">Filtrer par dépot</option>
        <option *ngFor="let client of storages$.results|async" [value]="client.id">{{client.name}}</option>
      </select>
    </div>
    <div class="me-md-2 flex-grow-1">
      <select class="form-select" [formControl]="clientForm">
        <option value="">Filtrer par client</option>
        <option *ngFor="let client of clients$.results|async" [value]="client.id">{{client.name}}</option>
      </select>
    </div>
    <div class="me-md-2 flex-grow-1">
      <select class="form-select" [formControl]="statusForm">
        <option value="">Filtrer par statut</option>
        <option *ngFor="let status of statusList" [value]="status.id">{{status.name}}</option>
      </select>
    </div>
    <div class="me-md-2 flex-grow-1">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="only-mine" [formControl]="onlyMineForm">
        <label class="form-check-label" for="only-mine">Uniquement mes offres</label>
      </div>
    </div>
  </div>
  <lvadg-offertype-storage-shortlist display="buttons" [filter]="offerTypeFilter$" [ots_detail_routes]="ots_detail_routes"></lvadg-offertype-storage-shortlist>
</ng-container>