
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class DocumentFolderBase extends DataModel {
  static override readonly __name: string = "DocumentFolderBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @foreignKeyField({"name": "document", "description": "document", "related": "Document"})
  public document!: number;

  @foreignKeyField({"name": "folder", "description": "folder", "related": "Folder"})
  public folder!: number;


}
