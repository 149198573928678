import { Injectable } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';
import { debounceTime, filter, first, map, Observable, of, ReplaySubject, share, switchMap } from 'rxjs';
import { Folder } from './folder';
import { FolderService } from './folder.service';
import { Queryset } from '@solidev/data';

// TODO: create a generic class for this and cache
export class FolderTree {
  public control: NestedTreeControl<Folder, number>;
  public data: ArrayDataSource<Folder>;
  public expanded = new Map<number, boolean>();
  public highlighted = new Map<number, string>();
  public current?: number;
  public root: number | null = null;
  private _rootQs: Queryset<Folder>;

  constructor(
    private _ftc: FolderTreeCache,
    private _folder: FolderService,
    private name: string,
    root: Folder | number | null = null,
  ) {
    // Set root id or null
    if (root === null) {
      this.root = null;
    } else if (root instanceof Folder) {
      this.root = root.id;
    } else {
      this.root = root;
    }
    // Create root queryset
    this._rootQs = this._folder
      .queryset()
      .filter({
        parent: this.root ? this.root : 'null',
      })
      .setFields(['id', 'name', 'children', 'parent', 'slug', 'status', 'tree_path', 'user_level', 'flags'])
      .sort('name');
    // Create tree control, using the FolderTreeCache to get children
    this.control = new NestedTreeControl<Folder, number>((node) => this._ftc.getChildren$(node.id), {
      trackBy: (d) => d.id,
    });
    // Create data source, using the root queryset results
    this.data = new ArrayDataSource(this._rootQs.results);
    // Launch root queryset
    this._rootQs.get(true, true).pipe(first()).subscribe();
  }

  /**
   * Expand a node
   * @param node
   */
  public expand(node: Folder) {
    this.expanded.set(node.id, true);
    this.control.expand(node);
  }

  /**
   * Collapse a node
   * @param node
   */
  public collapse(node: Folder) {
    this.expanded.delete(node.id);
    this.control.collapse(node);
  }

  /**
   * Set the current node
   * @param node
   */
  public setCurrent(node: Folder) {
    this.current = node.id;
  }

  /**
   * Reload the tree, reopening the expanded nodes after root collapse
   * @param full
   */
  public reload(full: boolean = false) {
    if (full) {
      this._ftc.clear();
      this.control.collapseAll();
      this._rootQs.get(true, true).subscribe(() => {
        setTimeout(() => {
          this.control.expansionModel.select(...this.expanded.keys());
        }, 1000);
      });
    } else {
      this.control.expansionModel.select(...this.expanded.keys());
    }
  }

  /**
   * Search for a folder.
   * All expanded nodes are collapsed, and the search results are then expanded.
   * Search results are not used for the tree data source, only to expand nodes.
   * @param text$
   */
  public search(text$: Observable<string | null>) {
    text$
      .pipe(
        debounceTime(500),
        filter((t) => t !== null && t.length > 3),
        switchMap((text) => {
          this.control.expansionModel.clear(true);
          return this._folder.list({
            search: text,
            fields: 'id,name,slug,status,flags',
            page_size: 100,
          });
        }),
      )
      .subscribe((res) => {
        this.highlighted.clear();
        for (const r of res.slice(0, 30)) {
          this.highlighted.set(r.id, 'search');
          for (const p of r.tree_path) {
            if (r.id !== p.id) {
              this.control.expansionModel.select(p.id);
            }
          }
        }
      });
  }

  /**
   * Open an arbitrary node in the tree.
   * If the folder is not loaded, load its parents and recursively get and open
   * all parents
   * @param node node to open
   * @param force force collapse of all nodes before opening
   */
  public open(node: Folder, force = false) {
    if (node.id !== this.current || force) {
      if (force) {
        this.control.collapseAll();
      }
      for (const p of node.tree_path) {
        if (p.id !== node.id) {
          this.control.expansionModel.select(p.id);
        }
      }
      this.control.expansionModel.select(node.id);
      this.current = node.id;
    }
  }
}

/**
 * Cache for folder tree children.
 * Parent queries are batched every 500ms, and the results are cached.
 */
@Injectable({
  providedIn: 'root',
})
export class FolderTreeCache {
  /** List of missing parent ids */
  private _missing: (number | null)[] = [];
  /** Subject to trigger missing parent queries */
  private _missing$: ReplaySubject<void> = new ReplaySubject<void>(1);
  /** Observable of missing parent query results */
  private _missingResults$: Observable<(number | null)[]>;
  /** Map of parent id to children (cache) */
  private children = new Map<number, Folder[]>();

  /**
   * Create the cache and set up the missing parent query observable.
   * @param _folder
   */
  constructor(private _folder: FolderService) {
    this._missingResults$ = this._missing$.pipe(
      debounceTime(100),
      switchMap(() => this.getMissing()),
      share(),
    );
  }

  /**
   * Get the children of a parent folder.
   * @param id id of parent folder (null for root)
   */
  public getChildren$(id: number | null): Observable<Folder[]> {
    // Set cache id (for root, use -1)
    const cacheId = id === null ? -1 : id;
    // If the children are already cached, return them
    if (this.children.has(cacheId)) {
      return of(this.children.get(cacheId)!);
    }
    if (this._missing.indexOf(id) === -1) {
      this._missing.push(id);
      this._missing$.next();
    }
    return this._missingResults$.pipe(
      filter((res) => res.indexOf(id) !== -1),
      map(() => this.children.get(cacheId)!),
    );
  }

  /**
   * Clear the cache
   */
  public clear() {
    this.children = new Map<number, Folder[]>();
  }

  /**
   * Get the missing children, return an observable of the missing parent ids
   * @private
   */
  private getMissing(): Observable<(number | null)[]> {
    // If there are no missing parents, return an empty observable
    if (this._missing.length === 0) {
      return of([]);
    }
    // Clear _missing values, and query for the missing parents
    const missing = this._missing.map((id) => id);
    this._missing = [];
    return this._folder
      .list({
        parents: missing.map((id) => `${id}`).join(','),
        fields: 'id,name,children,parent,slug,status,tree_path,user_level,flags',
      })
      .pipe(
        map((res) => {
          const found: (number | null)[] = [];
          for (const r of res) {
            const pkey = r.parent ? r.parent : -1;
            if (!found.includes(r.parent)) {
              found.push(r.parent);
            }
            const existing = this.children.get(pkey) || [];
            existing.push(r);
            this.children.set(pkey, existing);
          }
          return found;
        }),
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class FolderTreeService {
  private _trees = new Map<string, FolderTree>();

  constructor(
    private _folder: FolderService,
    private _ftc: FolderTreeCache,
  ) {}

  public load(name: string, root: Folder | number | null = null): FolderTree {
    const zname = `${name}`;
    if (!this._trees.has(zname)) {
      this._trees.set(zname, new FolderTree(this._ftc, this._folder, zname, root));
    }
     
    const tree = this._trees.get(zname)!;
    // FIXME: this reload(true) is a hack to allow the tree to be reloaded
    tree.reload(true);
    return tree;
  }

  public reload(name: string) {
    const zname = `${name}`;
    if (this._trees.has(zname)) {
       
      this._trees.get(zname)!.reload(true);
    }
  }

  public open(name: string, node: Folder, force: boolean = false) {
    const zname = `${name}`;
    if (this._trees.has(zname)) {
       
      this._trees.get(zname)!.open(node, force);
    }
  }
}
