import { computed, Injectable, signal } from '@angular/core';
import { DataModel, ModelList } from '@solidev/data';
import { takeUntil } from 'rxjs';

export type selectableTypes = 'articles' | 'producers' | 'products';

export class Selection<T extends DataModel> {
  private _selected = signal(new Map<number, T>());
  public size = computed(() => this._selected().size);
  public display = computed((): { label: string; id: number }[] => {
    return Array.from(this._selected().values()).map((item) => {
      return {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        label: (item as any)._display
          ? (item as any)._display
          : (item as any).name || (item as any).title || `${item.id}`,
        id: item.id,
      };
    });
  });
  public ids = computed(() => Array.from(this._selected().keys()));
  public isEmpty = computed(() => this._selected().size === 0);
  private _current: T[] = [];
  private _list: ModelList<T>;

  constructor(
    name: selectableTypes,
    list: ModelList<T>,
    public kill: () => void,
  ) {
    this._name = name;
    this._list = list;
    this._list.unsubscribe.subscribe(() => {
      this.emptyAll();
      this.kill();
    });
    this._list.results.pipe(takeUntil(this._list.unsubscribe)).subscribe((results) => {
      this._current = results;
    });
  }

  private _name: selectableTypes;

  public get name() {
    return this._name;
  }

  public isSelected(item: T) {
    return this._selected().has(item.id);
  }

  public toggleCheck(item: T) {
    const selected = this._selected();
    if (selected.has(item.id)) {
      selected.delete(item.id);
    } else {
      selected.set(item.id, item);
    }
    this._selected.set(new Map(selected));
  }

  public invertSelection() {
    const selected = this._selected();
    this._current.forEach((item) => {
      if (selected.has(item.id)) {
        selected.delete(item.id);
      } else {
        selected.set(item.id, item);
      }
    });
    this._selected.set(new Map(selected));
  }

  public removeById(id: number) {
    const selected = this._selected();
    selected.delete(id);
    this._selected.set(new Map(selected));
  }

  public emptyAll() {
    this._selected.set(new Map());
  }

  public selectAll() {
    const selected = this._selected();
    this._current.forEach((item) => {
      selected.set(item.id, item);
    });
    this._selected.set(new Map(selected));
  }
}

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  private _selections = new Map<string, Selection<any>>();

  public getSelection<T extends DataModel>(name: selectableTypes, list: ModelList<T>): Selection<T> {
    if (this._selections.has(name)) {
      return this._selections.get(name) as Selection<T>;
    }
    const selection = new Selection<T>(name, list, () => {
      this._selections.delete(name);
    });
    this._selections.set(name, selection);
    return selection;
  }
}
