import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Label } from "../label";
import { LabelService } from "../label.service";
import { Observable } from "rxjs";

@Component({
  selector: "lvadg-label-display",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: "./label-display.component.pug",
  styleUrls: ["./label-display.component.sass"],
})
export class LabelDisplayComponent implements OnInit {
  @Input() public label?: Label;
  @Input() public label_id?: number;
  @Input() mode:
    | "large"
    | "medium"
    | "small"
    | "preview"
    | "list"
    | "list-medium"
    | "list-preview" = "medium";
  public label$?: Observable<Label | undefined>;
  @Output() public clicked = new EventEmitter<Label>();
  @Input() public hover: boolean = true;
  @Input() public thumbnail: boolean = false;

  constructor(public _labels: LabelService) {}

  ngOnInit(): void {
    if (this.label) {
      this.label$ = this._labels.get(this.label.id);
    } else if (this.label_id) {
      this.label$ = this._labels.get(this.label_id);
    }
  }
}
