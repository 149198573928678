import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferViewData, OfferViewParams } from '../menu';
import { RouteConfigItem } from '@solidev/data';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OfferNavComponent } from '../_nav/offer-nav.component';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Offer } from '../../../../../models/offers/offer/offer';

export interface OfferArticlesViewParams extends OfferViewParams {
  offerarticle_detail_route?: RouteConfigItem;
}

export interface OffertArticlesViewData extends OfferViewData, OfferArticlesViewParams {}

@Component({
  selector: 'lvadg-offer-articles-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OfferNavComponent],
  templateUrl: './offer-articles-view.component.pug',
  styleUrls: ['./offer-articles-view.component.sass'],
})
export class OfferArticlesViewComponent extends BaseDetailViewComponent<OffertArticlesViewData, Offer> {}
