import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ProducerDocument } from '../producer-document';
import { Link } from '@solidev/data';
import { LabelDisplayComponent } from '../../../catalog/label/label-display/label-display.component';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { AuthService } from '../../../users/auth.service';

@Component({
  selector: 'lvadg-producer-document-display',
  standalone: true,
  imports: [CommonModule, RouterLink, LabelDisplayComponent, ImageDisplayComponent],
  templateUrl: './producer-document-display.component.pug',
  styleUrls: ['./producer-document-display.component.sass'],
})
export class ProducerDocumentDisplayComponent {
  @Input() public producerdocument!: ProducerDocument;
  @Input() public mode: 'line' | 'details' = 'line';
  @Input() public detail_route?: Link<ProducerDocument>;
  @Input() public showCodes: boolean;

  constructor() {
    const user = inject(AuthService).currentUser;
    this.showCodes = user?.is_superuser || user?.is_staff || false;
  }

  // FIXME: use real lvadg.io download
  public download(doc: ProducerDocument) {
    window.open(doc.file, '_blank');
  }
}
