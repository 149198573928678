import { RData, RouteConfigItem, RoutesDefinition } from "@solidev/data";
import { PRODUCT_CONTEXT, PRODUCT_LINKS_ROUTES, PRODUCT_ROUTES } from "./menu";
import { RS } from "../../../../constants";
import {
  ProductArticlesViewComponent,
  ProductArticlesViewParams,
} from "./articles/product-articles-view.component";
import {
  ProductDocumentsViewComponent,
  ProductDocumentsViewParams,
} from "./documents/product-documents-view.component";
import {
  ProductOffersViewComponent,
  ProductOffersViewParams,
} from "./offers/product-offers-view.component";
import {
  ProductDisplayViewComponent,
  ProductDisplayViewParams,
} from "./display/product-display-view.component";
import {
  ProductEditViewComponent,
  ProductEditViewParams,
} from "./edit/product-edit-view.component";
import {
  ProductProducerViewComponent,
  ProductProducerViewData,
  ProductProducerViewParams,
} from "./producer/product-producer-view.component";

export const gen_product_detail_views = (
  routes: PRODUCT_ROUTES,
  link_routes: PRODUCT_LINKS_ROUTES,
  context: PRODUCT_CONTEXT,
  default_route: RouteConfigItem,
): RoutesDefinition => {
  const gen_routes: RoutesDefinition = [];
  if (routes.product_detail_route) {
    gen_routes.push({
      path: "",
      pathMatch: "full",
      data: RData<ProductDisplayViewParams>({
        skip: true,
        route: routes.product_detail_route || default_route,
        producer_detail_route: link_routes.producer_detail_route,
        p_menu: routes,
        local: context.local,
      }),
      component: ProductDisplayViewComponent,
    });
  }
  if (routes.product_articles_route) {
    gen_routes.push({
      path: RS.articles,
      data: RData<ProductArticlesViewParams>({
        title: "Articles",
        route: routes.product_articles_route || default_route,
        article_detail_route: link_routes.article_detail_route,
        p_menu: routes,
      }),
      component: ProductArticlesViewComponent,
    });
  }
  if (routes.product_documents_route) {
    gen_routes.push({
      path: RS.documents,
      data: RData<ProductDocumentsViewParams>({
        title: "Documents",
        route: routes.product_documents_route || default_route,
        p_menu: routes,
      }),
      component: ProductDocumentsViewComponent,
    });
  }
  if (routes.product_offers_route) {
    gen_routes.push({
      path: RS.offers,
      data: RData<ProductOffersViewParams>({
        title: "Offres",
        route: routes.product_offers_route || default_route,
        p_menu: routes,
        offer_detail_route: link_routes.offer_detail_route,
        resto_detail_route: link_routes.resto_detail_route,
        storage_detail_route: link_routes.storage_detail_route,
        user_detail_route: link_routes.user_detail_route,
        offertype_detail_route: link_routes.offertype_detail_route,
        offertypestorage_detail_route:
          link_routes.offertypestorage_detail_route,
        offertypestorage_offer_detail_route:
          link_routes.offertypestorage_offer_detail_route,
      }),
      component: ProductOffersViewComponent,
    });
  }
  if (routes.product_edit_route) {
    gen_routes.push({
      path: RS.edit,
      data: RData<ProductEditViewParams>({
        title: "Édition",
        route: routes.product_edit_route || default_route,
        family_detail_route: link_routes.family_detail_route,
        p_menu: routes,
      }),
      component: ProductEditViewComponent,
    });
  }
  if (routes.product_producer_route) {
    gen_routes.push({
      path: RS.producer,
      data: RData<ProductProducerViewParams>({
        title: (data: ProductProducerViewData) =>
          `${data.product.producer_details?.name}`,
        route: routes.product_producer_route || default_route,
        p_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        local: context.local,
        type: context.type,
      }),
      component: ProductProducerViewComponent,
    });
  }

  return gen_routes;
};
