import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TariftypeDocument } from '../tariftype-document';
import { TariftypeDocumentService } from '../tariftype-document.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NgMathPipesModule } from 'ngx-pipes';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { TARIFTYPEDOCUMENT_STATUSES } from '../tariftype-document.base';
import { TariftypeMetadata } from '../../tariftype-metadata/tariftype-metadata';
import { TariftypeBase } from '../../tariftype/tariftype.base';

@Component({
  selector: 'lvadg-tariftype-document-list',
  standalone: true,
  templateUrl: './tariftype-document-list.component.pug',
  styleUrls: ['./tariftype-document-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    TariftypeDisplayComponent,
    FlagsDisplayComponent,
    ImageDisplayComponent,
    NgMathPipesModule,
    SafeDeleteComponent,
    PChoicePipe,
  ],
})
export class TariftypeDocumentListComponent extends ModellistComponent<TariftypeDocument> {
  @Input() public tariftypemetadata_detail_route?: Link<TariftypeMetadata>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;

  constructor(coll: TariftypeDocumentService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'status', 'type'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'État',
          model: TariftypeDocument,
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Type',
          model: TariftypeDocument,
        }),
      ],
    };
  }

  public override getRowClasses(row: TariftypeDocument): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === TARIFTYPEDOCUMENT_STATUSES.VALID) {
      cls.push('text-success');
    }
    if (
      row.status === TARIFTYPEDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE ||
      row.status === TARIFTYPEDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE
    ) {
      cls.push('text-muted');
    }

    return cls;
  }
}
