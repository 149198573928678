import { RouteConfigItem } from "@solidev/data";

export interface PROVIDER_ROUTES {
  provider_detail_route: RouteConfigItem;
  provider_edit_route: RouteConfigItem;
  provider_documents_route: RouteConfigItem;
  provider_articles_route: RouteConfigItem;
}

export interface PROVIDER_LINKS_ROUTES {
  provider_list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}
