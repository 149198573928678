import { RouteConfigItem } from '@solidev/data';

export interface TARIF_ROUTES {
  tarif_detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  tarif_manage_route?: RouteConfigItem;
  tarif_lines_route?: RouteConfigItem;
  tarif_documents_route?: RouteConfigItem;
  tarif_notifications_route?: RouteConfigItem;
  tarif_notification_logs_route?: RouteConfigItem;
  tarif_relations_route?: RouteConfigItem;
  tarif_lifecycle_route?: RouteConfigItem;
}
