import { RoutesConfig } from '@solidev/data';
import {
  OrderBase,
  OrdergroupBase,
  OrderitemBase,
  OrderprocessarticleBase,
  OrderprocessBase,
  OrderprocessPermissionBase,
  OrderprocessstorageBase,
  OrderprocessstoragePermissionBase,
  RS,
} from '@vivalya/lvadg';

export const ADMIN_ORDERS_ROUTES_NAMES = [
  'orders',
  'orders_articles',
  'orders_articles_article',
  'orders_groups',
  'orders_groups_group',
  'orders_orderitems',
  'orders_orderitems_orderitem',
  'orders_orders',
  'orders_orders_order',
  'orders_permissions',
  'orders_permissions_permission',
  'orders_processes',
  'orders_processes_process',
  'orders_processes_process_articles',
  'orders_processes_process_articles_article',
  'orders_processes_process_edit',
  'orders_processes_process_ordergroups',
  'orders_processes_process_ordergroups_ordergroup',
  'orders_processes_process_orders',
  'orders_processes_process_orders_order',
  'orders_processes_process_permissions',
  'orders_processes_process_storages',
  'orders_processes_process_storages_storage',
  'orders_storagepermissions',
  'orders_storagepermissions_storagepermission',
  'orders_storages',
  'orders_storages_storage',
] as const;
export const ADMIN_ORDERS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_ORDERS_ROUTES_NAMES)[number]> = {
  orders: {
    params: [],
    route: () => ['/', RS.orders],
    name: 'orders',
  },
  orders_articles: {
    params: [],
    route: () => ['/', RS.orders, RS.articles],
    name: 'orders_articles',
  },
  orders_articles_article: {
    params: ['orderprocessarticle'],
    route: (opa: OrderprocessarticleBase) => ['/', RS.orders, RS.articles, opa.id],
    name: 'orders_articles_article',
  },
  orders_groups: {
    params: [],
    route: () => ['/', RS.orders, RS.groups],
    name: 'order_groups',
  },
  orders_groups_group: {
    params: ['ordergroup'],
    route: (og: OrdergroupBase) => ['/', RS.orders, RS.groups, og.id],
    name: 'order_groups_group',
  },

  orders_orderitems: {
    params: [],
    route: () => ['/', RS.orders, RS.orderitems],
    name: 'orders_orderitems',
  },
  orders_orderitems_orderitem: {
    params: ['orderitem'],
    route: (oi: OrderitemBase) => ['/', RS.orders, RS.orderitems, oi.id],
    name: 'orders_orderitems_orderitem',
  },

  orders_orders: {
    params: [],
    route: () => ['/', RS.orders, RS.orders],
    name: 'orders_orders',
  },

  orders_orders_order: {
    params: ['order'],
    route: (o: OrderBase) => ['/', RS.orders, RS.orders, o.id],
    name: 'orders_orders_order',
  },
  orders_permissions: {
    params: [],
    route: () => ['/', RS.orders, RS.permissions],
    name: 'orders_permissions',
  },
  orders_permissions_permission: {
    params: ['orderprocesspermission'],
    route: (op: OrderprocessPermissionBase) => ['/', RS.orders, RS.permissions, op.id],
    name: 'orders_permissions_permission',
  },
  orders_processes: {
    params: [],
    route: () => ['/', RS.orders, RS.processes],
    name: 'orders_processes',
  },
  orders_processes_process: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id],
    name: 'orders_processes_process',
  },
  orders_processes_process_articles: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id, RS.articles],
    name: 'orders_processes_process_articles',
  },
  orders_processes_process_articles_article: {
    params: ['orderprocess', 'orderprocessarticle'],
    route: (op: OrderprocessBase, opa: OrderprocessarticleBase) => [
      '/',
      RS.orders,
      RS.processes,
      op.id,
      RS.articles,
      opa.id,
    ],
    name: 'orders_processes_process_articles_article',
  },
  orders_processes_process_edit: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id, RS.edit],
    name: 'orders_processes_process_edit',
  },
  orders_processes_process_ordergroups: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id, RS.groups],
    name: 'orders_processes_process_ordergroups',
  },
  orders_processes_process_ordergroups_ordergroup: {
    params: ['orderprocess', 'ordergroup'],
    route: (op: OrderprocessBase, ordg: OrdergroupBase) => ['/', RS.orders, RS.processes, op.id, RS.groups, ordg.id],
    name: 'orders_processes_process_order',
  },
  // UPDATE NEEDED

  orders_processes_process_orders: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id, RS.orders],
    name: 'orders_processes_process_orders',
  },

  orders_processes_process_orders_order: {
    params: ['orderprocess', 'order'],
    route: (op: OrderprocessBase, ord: OrderBase) => ['/', RS.orders, RS.processes, op.id, RS.orders, ord.id],
    name: 'orders_processes_process_order',
  },
  orders_processes_process_permissions: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id, RS.permissions],
    name: 'orders_processes_process_permissions',
  },
  orders_processes_process_storages: {
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, RS.processes, op.id, RS.storages],
    name: 'orders_processes_process_storages',
  },
  orders_processes_process_storages_storage: {
    params: ['orderprocess', 'orderprocessstorage'],
    route: (op: OrderprocessBase, ops: OrderprocessstorageBase) => [
      '/',
      RS.orders,
      RS.processes,
      op.id,
      RS.storages,
      ops.id,
    ],
    name: 'orders_processes_process_storage',
  },
  orders_storagepermissions: {
    params: [],
    route: () => ['/', RS.orders, RS.storagepermissions],
    name: 'orders_storagepermissions',
  },
  orders_storagepermissions_storagepermission: {
    params: ['orderprocessstoragepermission'],
    route: (op: OrderprocessstoragePermissionBase) => ['/', RS.orders, RS.storagepermissions, op.id],
    name: 'orders_storagepermissions_storagepermission',
  },
  orders_storages: {
    params: [],
    route: () => ['/', RS.orders, RS.storages],
    name: 'orders_storages',
  },
  orders_storages_storage: {
    params: ['orderprocessstorage'],
    route: (ops: OrderprocessstorageBase) => ['/', RS.orders, RS.storages, ops.id],
    name: 'orders_storages_storage',
  },
};
