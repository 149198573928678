import { Injectable } from '@angular/core';
import { StructureBaseAction } from '../structure-base-action';
import { DataMessageService } from '@solidev/data';

@Injectable({
  providedIn: 'root',
})
export class ProviderActionService extends StructureBaseAction<any> {
  constructor(_msgs: DataMessageService) {
    super(_msgs);
  }
}
