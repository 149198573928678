import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  primaryField,
  textField,
} from '@solidev/data';
import { MAPPING_BASE_STYLES, MAPPING_STYLES } from '../mapping/constants';

export class UserMapBase extends DataModel {
  static override readonly __name: string = 'UserMapBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'code',
    description: 'Code carte',
    priority: 900,
    pattern: '^[a-zA-Z0-9-]{30}$',
  })
  public code!: string;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 50,
  })
  public updated!: Date;

  @charField({
    name: 'name',
    description: 'Nom de la carte',
    maxLength: 200,
    priority: 800,
  })
  public name!: string;

  @textField({ name: 'description', description: 'Description', priority: 600 })
  public description!: string;

  @foreignKeyField({ name: 'user', description: 'ID créateur', related: 'User', priority: -1 })
  public user!: number;

  @charField({
    name: 'style',
    description: 'Style de base',
    defaultValue: MAPPING_BASE_STYLES.OSM_LIBERTY_EU,
    maxLength: 200,
    priority: 800,
    choices: Array.from(MAPPING_STYLES.entries()).map(([name, style]) => ({ value: name, desc: style.description })),
  })
  public style: MAPPING_BASE_STYLES = MAPPING_BASE_STYLES.OSM_LIBERTY_EU;

  public get _display(): string {
    return `[${this.id}] ${this.name}`;
  }
}
