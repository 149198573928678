
<ng-container *ngIf="layout$|async as layout">
  <ul class="list-group my-2" *ngIf="layout.providers as providers">
    <li class="list-group-item" *ngFor="let p of providers"><i class="bi bi-database me-2"></i>{{p}}
      <div class="badge bg-warning ms-2">TODO</div>
    </li>
  </ul>
  <div class="card">
    <div class="card-header">Ajouter des données</div>
    <div class="card-body">
      <div class="row g-0">
        <div class="col-12">
          <select class="form-select" [ngModel]="add" (ngModelChange)="add=$event">
            <option value="member">Adhérent</option>
            <option value="storage">Dépôt</option>
            <option value="client">Client</option>
            <option value="resto">Restaurant</option>
            <option value="article">Article</option>
            <option value="product">Produit</option>
            <option value="producer">Producteur</option>
          </select>
        </div>
        <div class="col-12 mt-3" *ngIf="add">
          <ng-container [ngSwitch]="add">
            <ng-container *ngSwitchCase="'member'">
              <data-fkselect [collection]="members$" [filter]="{fields: ['id', 'cvva', 'name'].join(',')}" (selected)="addMember($event, layout)" label="Ajouter un adhérent"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchCase="'storage'">
              <data-fkselect [collection]="storages$" [filter]="{fields: ['id', 'cvva', 'name'].join(',')}" (selected)="addStorage($event, layout)" label="Ajouter un dépôt"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchCase="'client'">
              <data-fkselect [collection]="clients$" [filter]="{fields: ['id', 'cvva', 'name'].join(',')}" (selected)="addClient($event, layout)" label="Ajouter un client"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchCase="'resto'">
              <data-fkselect [collection]="restos$" [filter]="{fields: ['id', 'cvva', 'name'].join(',')}" (selected)="addResto($event, layout)" label="Ajouter un restaurant"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchCase="'article'">
              <data-fkselect [collection]="articles$" [filter]="{fields: ['id', 'code', 'libelle'].join(',')}" (selected)="addArticle($event, layout)" label="Ajouter un article"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchCase="'product'">
              <data-fkselect [collection]="products$" [filter]="{fields: ['id', 'code', 'libelle'].join(',')}" (selected)="addProduct($event, layout)" label="Ajouter un produit"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchCase="'producer'">
              <data-fkselect [collection]="producers$" [filter]="{fields: ['id', 'cvva', 'name'].join(',')}" (selected)="addProducer($event, layout)" label="Ajouter un producteur"></data-fkselect>
            </ng-container>
            <ng-container *ngSwitchDefault>Erreur, non implémenté : {{add}}</ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>