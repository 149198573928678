import { RoutesConfig } from '@solidev/data';
import { Resto, RS } from '@vivalya/lvadg';

export const FRONT_RESTOS_ROUTES_NAMES = ['restos', 'restos_restos', 'restos_restos_resto'] as const;

export const FRONT_RESTOS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_RESTOS_ROUTES_NAMES)[number]> = {
  restos: {
    name: 'restos',
    params: [],
    route: () => ['/', RS.restos],
  },
  restos_restos: {
    name: 'restos_restos',
    params: [],
    route: () => ['/', RS.restos, RS.restos],
  },
  restos_restos_resto: {
    name: 'restos_restos_resto',
    params: ['resto'],
    route: (s: Resto) => ['/', RS.restos, RS.restos, s.id],
  },
};
