
<ng-template #specialop let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">
      <ng-container *ngIf="running['earticleImport']">Importation articles client</ng-container>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <lvadg-tariftype-earticles-import [tariftype]="current" *ngIf="running['earticleImport'] &amp;&amp; current" (done)="reload$.next(true); running['earticleImport']=false; offcanvas.close()" (cancelled)="offcanvas.close()"></lvadg-tariftype-earticles-import>
  </div>
</ng-template>
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu"></lvadg-tariftype-detail-nav>
  <lvadg-header-action [title]="data.tariftype.name" level="h2" subtitle="Gestion des correspondances avec les articles" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-outline-warning btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'eamapping_to_tarif'})">Ciblés &rightarrow; tarif</button>
      <button class="btn btn-outline-primary btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'eamapping_update_statuses'})">MAJ états</button>
      <button class="btn btn-outline-primary btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'eamapping_get_from_bulk'})" *ngIf="data.tariftype.have_level(TTUL.sadmin)">Match fred</button>
      <button class="btn btn-outline-primary btn-sm" (click)="ofcOp('earticleImport')">Importer des articles</button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-tariftype-earticle-mappings [name]="data.route.name+'-'+data.tariftype.code" [tariftype]="data.tariftype" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" [tariftype_detail_route]="tariftype_detail_route" [reload]="reload$"></lvadg-tariftype-earticle-mappings>
</ng-container>