import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { TarifNotificationDestinationManageComponent } from '../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination-manage/tarif-notification-destination-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TarifNotificationDestination } from '../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination';
import { BaseRouteParams } from '@solidev/data';

export interface TarifNotificationDestinationDetailViewParams extends BaseRouteParams {}

export interface TarifNotificationDestinationDetailViewData extends TarifNotificationDestinationDetailViewParams {
  tarifnotificationdestination: TarifNotificationDestination;
}

@Component({
  selector: 'lvadg-tarif-notification-destination-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TarifNotificationDestinationManageComponent],
  templateUrl: './tarif-notification-destination-detail-view.component.pug',
  styleUrls: ['./tarif-notification-destination-detail-view.component.sass'],
})
export class TarifNotificationDestinationDetailViewComponent extends BaseDetailViewComponent<
  TarifNotificationDestinationDetailViewData,
  TarifNotificationDestination
> {
  public override setRouteData(data: TarifNotificationDestinationDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
