import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeStorage } from '../tariftype-storage';
import { TariftypeStoragePermissionListComponent } from '../../tariftype-storage-permission/tariftype-storage-permission-list/tariftype-storage-permission-list.component';
import { Subject } from 'rxjs';
import { FilterDefaults } from '@solidev/data';
import { TariftypeStoragePermissionCreateComponent } from '../../tariftype-storage-permission/tariftype-storage-permission-create/tariftype-storage-permission-create.component';

@Component({
  selector: 'lvadg-tariftype-storage-manage',
  standalone: true,
  imports: [CommonModule, TariftypeStoragePermissionCreateComponent, TariftypeStoragePermissionListComponent],
  templateUrl: './tariftype-storage-manage.component.pug',
  styleUrls: ['./tariftype-storage-manage.component.sass'],
})
export class TariftypeStorageManageComponent implements OnInit {
  @Input() public tariftypestorage?: TariftypeStorage;
  public reload$ = new Subject<void | boolean>();
  public permFilter: FilterDefaults = {};
  public perm_default_fields = ['user_details', 'group_details', 'permission'];
  public addPermission: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.tariftypestorage) {
      this.permFilter = { target: this.tariftypestorage.id };
    }
  }
}
