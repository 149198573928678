import { Component, Input, signal } from "@angular/core";
import { NgOptimizedImage } from "@angular/common";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import {
  FactorPipe,
  FieldsParams,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { ArticleStatsService } from "../article-stats.service";
import { ArticleStats } from "../article-stats";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { SeasonsManageComponent } from "../../../catalog/common/seasons-manage/seasons-manage.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { FamilyBase } from "../../../catalog/family/family.base";
import { TarifArticleStatsComponent } from "../tarif-article-stats/tarif-article-stats.component";
import { TarifIntervalStatDisplayComponent } from "../tarif-interval-stat-display/tarif-interval-stat-display.component";
import { LabelService } from "../../../catalog/label/label.service";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { Tariftype } from "../../tariftype/tariftype";
import {
  INTERVAL_GROUPER_CHOICES,
  PERIOD_CHOICES,
} from "../../../../components/utils/periods";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { ProviderDisplayComponent } from "../../../structures/provider/provider-display/provider-display.component";
import { ProviderBase } from "../../../structures/provider/provider.base";
import { SelectItemComponent } from "../../../../components/selection/select-item/select-item.component";
import { SelectHeaderComponent } from "../../../../components/selection/select-header/select-header.component";
import { PrintSettings } from "../../../layouts/print-settings";
import { QuickprintButtonComponent } from "../../../layouts/print/quickprint-button/quickprint-button.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { IocodeDisplayComponent } from "../../../lvadgio/iocode/iocode-display/iocode-display.component";
import { FamilyDisplayComponent } from "../../../catalog/family/family-display/family-display.component";
import {
  FamilyDisplayListHeaderComponent,
  FamilyDisplayParams,
} from "../../../catalog/family/family-display-list-header/family-display-list-header.component";
import { ArticleDocumentDisplayComponent } from "../../../catalog/article-document/article-document-display/article-document-display.component";
import { ProviderDocumentDisplayComponent } from "../../../structures/provider-document/provider-document-display/provider-document-display.component";

@Component({
  selector: "lvadg-tarif-articles-stats",
  standalone: true,
  imports: [
    ...MODELLIST_IMPORTS,
    NgOptimizedImage,
    SeasonsManageComponent,
    LabelDisplayComponent,
    TarifArticleStatsComponent,
    TarifIntervalStatDisplayComponent,
    FactorPipe,
    SafeDeleteComponent,
    ProviderDisplayComponent,
    ImageDisplayComponent,
    QuickprintButtonComponent,
    IocodeDisplayComponent,
    IconComponent,
    SelectItemComponent,
    SelectHeaderComponent,
    FamilyDisplayComponent,
    FamilyDisplayListHeaderComponent,
    PChoicePipe,
    ArticleDocumentDisplayComponent,
    ProviderDocumentDisplayComponent,
  ],
  templateUrl: "./tarif-articles-stats.component.pug",
  styleUrls: ["./tarif-articles-stats.component.sass"],
})
export class TarifArticlesStatsComponent extends ModellistComponent<ArticleStats> {
  public stats_fields: string[] = ["name", "id"];
  public stats_rows: string[] = [];
  @Input() public family_detail_route?: Link<FamilyBase>;
  @Input() public provider_detail_route?: Link<ProviderBase>;
  @Input() public printSettings?: PrintSettings;
  public print_filters_whitelist = signal<string[]>([]);
  public familyDisplay = signal<FamilyDisplayParams>({
    display: true,
    mode: "line",
    start: 0,
    end: 5,
  });

  constructor(
    coll: ArticleStatsService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    public _labels: LabelService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    this.print_filters_whitelist.set([]);
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "tarifs_stats_grouper",
          "tarifs_stats_period",
          "search",
          "egalims",
        ],
      filters: [
        new ModelListSelectFilter({
          field: "tarifs_stats_grouper",
          name: "tarifs_stats_grouper",
          label: "Intervalle de statistique",
          choices: INTERVAL_GROUPER_CHOICES,
        }),
        new ModelListSelectFilter({
          field: "tarifs_stats_period",
          name: "tarifs_stats_period",
          label: "Période de statistique",
          choices: PERIOD_CHOICES,
        }),
        new ModelListTextFilter({
          field: "libelle",
          name: "libelle",
          label: "Recherche par libelle",
        }),
        new ModelListAutocompleteMultiFilter({
          name: "egalims",
          desc: "Labels/certifications",
          label: "Filtrer par label/certification",
          collection: this._labels,
          filter: { type: LABEL_TYPE.FL },
          field: "egalims",
          display: (d) => `[${d.type}] ${d.code} (${d.name})`,
        }),
        new ModelListSelectFilter({
          field: "tariftype-segment",
          name: "tariftype-segment",
          label: "[Tarif] Filtrer par segment",
          model: Tariftype,
          mfield: "segment",
        }),
        new ModelListSelectFilter({
          field: "with_rnm",
          name: "with_rnm",
          label: "Article avec RNM",
          choices: [
            { value: "ALL", desc: "Toute correspondance" },
            { value: "AUTO", desc: "Toute correspondance auto" },
            { value: "MANUAL", desc: "Toute correspondance manuelle" },
            { value: "AUTO20", desc: "Auto = 20" },
          ],
        }),

        // new ModelListSelectFilter({
        //   field: 'article-udf_unit',
        //   name: 'article-udf_unit',
        //   label: '[Articles] Filtrer par uf',
        //   model: Article,
        //   mfield: 'udf_unit',
        // }),
        //
        new ModelListTextFilter({
          field: "tariftype-name",
          name: "tariftype-name",
          label: "[Tarif] Recherche par titre du tarif",
        }),
        // new ModelListNumberFilter({
        //   field: 'sdev',
        //   name: 'sdev',
        //   label: 'Ecart type',
        //   operations: ['gte', 'lte'],
        // }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const fields = super.getFields();
    return fields;
  }

  public override tapPreResults(res: ArticleStats[]) {
    const intervals = new Set<string>([]);
    for (const r of res) {
      if (r.interval_stats) {
        for (const i of r.interval_stats.intervals) {
          if (!intervals.has(i)) {
            intervals.add(i);
          }
        }
      }
    }
    this.stats_rows = Array.from(intervals.values()).sort();
    this.stats_fields = this.list.fields.enabled.concat(this.stats_rows);
  }
}
