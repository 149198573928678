import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarif } from '../article-tarif';
import { TarifBase } from '../../tarif/tarif.base';
import { ArticleTarifBase } from '../article-tarif.base';

interface ITarifRecord {
  tarif: TarifBase;
  price: ArticleTarifBase | null;
  current: number | null;
  percent: number | null;
}

@Component({
  selector: 'lvadg-article-tarif-price-history',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-tarif-price-history.component.pug',
  styleUrls: ['./article-tarif-price-history.component.sass'],
})
export class ArticleTarifPriceHistoryComponent implements OnChanges {
  @Input() public at!: ArticleTarif;
  @Input() public references?: TarifBase[];
  @Input() public prices?: ArticleTarifBase[];
  @Input() public price?: number | null;
  @Input() public display: 'price' | 'percent' | 'both' = 'percent';

  public data: ITarifRecord[] = [];

  @Input() public offset?: number;
  public max: number | null = null;
  public min: number | null = null;
  public count!: number;
  public abs = Math.abs;

  public ngOnChanges(): void {
    if (!this.references || !this.prices) {
      console.log('Missing', this.references, this.prices);
      return;
    }
    this.count = this.references.length;
    let min = null;
    let max = null;
    const tar: ITarifRecord[] = [];
    for (const r of this.references) {
      const t: ITarifRecord = {
        tarif: r,
        price: null,
        current: this.price || null,
        percent: null,
      };
      for (const p of this.prices) {
        if (p.tarif === r.id) {
          t.price = p;
          if (t.current !== null && p.price !== null && p.price !== 0) {
            t.percent = ((t.current - p.price) / p.price) * 100;
            if (min === null || min === undefined || t.percent < min) {
              min = t.percent / 10000;
            }
            if (max === null || max === undefined || t.percent > max) {
              max = t.percent / 10000;
            }
          }
          break;
        }
      }
      tar.push(t);
    }
    this.data = tar.reverse();
    this.min = min === undefined ? null : min!;
    this.max = max === undefined ? null : min!;
    if (this.max === this.min && this.min !== null) {
      this.max! += 1;
      this.min -= 1;
    }
  }
}
