import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TarifNotificationStatusService } from './tarif-notification-status.service';
import { TarifNotificationStatus } from './tarif-notification-status';

@Injectable({
  providedIn: 'root',
})
export class TarifNotificationStatusResolver
  
{
  constructor(private _rs: TarifNotificationStatusService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TarifNotificationStatus> {
    return this._rs.fetch(+route.params['tarifnotificationstatusId']);
  }
}
