
<dl class="row" *ngIf="label">
  <data-dispedit class="col-6" [model]="label" field="code" mode="dd">Code</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="name" mode="dd">Texte</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="type" mode="dd">Type</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="url" mode="dd">URL</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="egalim" mode="dd">Egalim ?</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="egalim_type" mode="dd">Type Egalim</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="egalim_eq" mode="dd">Equivalence Egalim ?</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="catalog_only" mode="dd">Catalogue uniquement ?</data-dispedit>
  <data-dispedit class="col-6" [model]="label" field="ordering" mode="dd">Ordre</data-dispedit>
</dl>
<p class="text-danger">Les labels sont mis en cache, les modifications effectuées ne sont prises en compte dans les vues de liste qu'après rechargement de la page dans les navigateurs.</p>
<div *ngIf="label">
  <h4>Logo du label</h4>
  <lvadg-logo-manage [model]="label"></lvadg-logo-manage>
</div>