
<form [formGroup]="form" (submit)="create()">
  <div class="row">
    <div class="col-6">
      <label>Type de lien</label>
      <select class="form-select" formControlName="type">
        <option [value]="null">Sélectionner</option>
        <option *ngFor="let iotype of iocode_types" [value]="iotype.value">{{ iotype.label }}</option>
      </select>
    </div>
    <div class="col-6">
      <label>Durée de vie</label>
      <select class="form-select" formControlName="ttl">
        <option [value]="null">Sélectionner</option>
        <option *ngFor="let ttl of ttl_values" [value]="ttl.value">{{ ttl.label }}</option>
      </select>
    </div>
    <div class="col-6">
      <label>URL</label>
      <input class="form-control" type="text" formControlName="url">
    </div>
    <div class="col-6">
      <label>Données</label>
      <textarea class="form-control" type="text" formControlName="data"></textarea>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button class="btn btn-outline-primary" [disabled]="form.invalid||form.pristine">Créer le lien lvadg.io</button>
  </div>
</form>