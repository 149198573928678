
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Orderprocessarticle } from "./orderprocessarticle";

@Injectable({
  providedIn: 'root'
})
export class OrderprocessarticleService extends Collection<Orderprocessarticle> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/orderprocessarticles", Orderprocessarticle);
  }
}
