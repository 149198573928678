
<div class="mb-3" *ngIf="step==0">
  <lvadg-media-upload [url]="uploadUrl" [enabled]="true" [model]="doc" [prepare]="uploadPrepare" (uploadDone)="onUploaded($event)"></lvadg-media-upload>
</div>
<div class="mb-3" *ngIf="step==1">
  <ng-container [ngSwitch]="action">
    <div *ngSwitchCase="'read_custom_articles'">
      <pre>{{result|json}}</pre>
    </div>
    <div *ngSwitchCase="'read_custom_prices'">
      <pre>{{result|json}}</pre>
    </div>
    <div *ngSwitchCase="'reimport_prices'">
      <p class="text-warning"><span *ngIf="result?.delta?.length">Nouveaux prix détectés, merci de vérifier leur validité avant de confirmer.</span><span *ngIf="!result?.delta?.length">Aucun changement de prix détecté.</span></p>
      <table class="table table-sm table-striped" *ngIf="result?.delta?.length">
        <thead>
          <tr>
            <th>Libellé</th>
            <th>Ancien prix</th>
            <th>Nouveau prix</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of result?.delta">
            <td>{{c.libelle}}</td>
            <th>
              <ng-container *ngIf="c.oldprice">{{c.oldprice/10000|currency:'EUR'}}</ng-container>
              <ng-container *ngIf="!c.oldprice">Absent</ng-container>
            </th>
            <th>
              <ng-container *ngIf="c.newprice">{{c.newprice/10000|currency:'EUR'}}</ng-container>
              <ng-container *ngIf="!c.newprice">Absent</ng-container>
            </th>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <button class="btn btn-outline-primary w-100" (click)="validateImport(true)"><i class="fa fa-check me-2"></i>Valider l'importation</button>
        </div>
        <div class="col">
          <button class="btn btn-outline-secondary w-100" (click)="validateImport(false)"><i class="fa fa-check me-2"></i>Annuler l'importation</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>