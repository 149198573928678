import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { BaseRouteParams } from "@solidev/data";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ArticleDetailComponent } from "../../../../../models/catalog/article/article-detail/article-detail.component";
import { Article } from "../../../../../models/catalog/article/article";
import { ArticleNavComponent } from "../_nav/article-nav.component";
import { QuickprintButtonComponent } from "../../../../../models/layouts/print/quickprint-button/quickprint-button.component";
import { IocodeButtonComponent } from "../../../../../models/lvadgio/iocode/iocode-button/iocode-button.component";
import { ARTICLE_PRINT_SETTINGS } from "../../../../../models/catalog/article/article-print-settings";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";

export interface ArticleDisplayViewParams
  extends ArticleViewParams,
    BaseRouteParams {}

export interface ArticleDisplayViewData
  extends ArticleDisplayViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-display-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ArticleNavComponent,
    ArticleDetailComponent,
    IconComponent,
    QuickprintButtonComponent,
    IocodeButtonComponent,
  ],
  templateUrl: "./article-display-view.component.pug",
  styleUrls: ["./article-display-view.component.sass"],
})
export class ArticleDisplayViewComponent extends BaseDetailViewComponent<
  ArticleDisplayViewData,
  Article
> {
  public print_settings = ARTICLE_PRINT_SETTINGS;
  public print_context = {};
}
