import { Component } from '@angular/core';
import { AllergenListViewData } from './allergen-list-view.component.params';

import { Subject } from 'rxjs';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { AllergenListComponent } from '../../../../../models/catalog/meta/allergen/allergen-list/allergen-list.component';
import { AllergenCreateComponent } from '../../../../../models/catalog/meta/allergen/allergen-create/allergen-create.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Allergen } from '../../../../../models/catalog/meta/allergen/allergen';

@Component({
  selector: 'lvadg-allergen-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    AllergenListComponent,
    AllergenCreateComponent,
    IconComponent,
    HeaderActionComponent,
  ],
  templateUrl: './allergen-list-view.component.pug',
  styleUrls: ['./allergen-list-view.component.sass'],
})
export class AllergenListViewComponent extends BaseListViewComponent<AllergenListViewData, Allergen> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
