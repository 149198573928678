
<lvadg-header-action title="Processus de commande" ri="order">
  <ng-container actions>
    <button class="btn btn-primary" (click)="create=!create"><i class="bi bi-plus-circle me-2"></i>Ajouter un processus</button>
  </ng-container>
</lvadg-header-action>
<div class="card my-3" *ngIf="create">
  <div class="card-header">Création de processus de commande</div>
  <div class="card-body">
    <lvadg-orderprocess-create (created)="reload$.next(); create=false" (cancelled)="create=false"></lvadg-orderprocess-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-orderprocess-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [tariftype_detail_route]="tariftype_detail_route" [reload]="reload$"></lvadg-orderprocess-list>
</ng-container>