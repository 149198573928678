
<form class="row g-3" [formGroup]="form" (submit)="save()">
  <data-dispedit class="col-12" [form]="form" [model]="item" field="title" mode="form">Titre</data-dispedit>
  <data-richedit class="col-12" [fc]="form.controls.text" [model]="item" field="text" mode="form" [hideButton]="true">Texte</data-richedit>
  <data-dispedit class="col-3" [form]="form" [model]="item" field="date_start" mode="form">Date de début</data-dispedit>
  <data-dispedit class="col-3" [form]="form" [model]="item" field="date_applicable" mode="form"><b>Date d'application</b></data-dispedit>
  <data-dispedit class="col-3" [form]="form" [model]="item" field="date_end" mode="form">Date de fin</data-dispedit>
  <data-dispedit class="col-3" [form]="form" [model]="item" field="status" mode="form">État</data-dispedit>
  <div class="col-12">
    <p class="text-info">Seule la date d'application est obligatoire. Les autres dates sont pour information uniquement. Un texte est appliqué à une offre si la date d'application est comprise entre la date de début et la date de fin de l'offre.</p>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="form.pristine||form.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>