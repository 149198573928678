import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyBase } from '../family.base';
import { Family } from '../family';
import { Link } from '@solidev/data';
import { RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'lvadg-family-display',
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref],
  templateUrl: './family-display.component.pug',
  styleUrls: ['./family-display.component.sass'],
})
export class FamilyDisplayComponent {
  @Input() public family?: FamilyBase | Family;
  @Input() public mode: 'line' | 'button' | 'path' = 'line';
  @Input() public detail_route?: Link<FamilyBase>;
  @Input() public start: number = 0;
  @Input() public end: number = 5;
}
