
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { GrouppermissionService} from "./grouppermission.service";
import { Grouppermission } from "./grouppermission";

@Injectable({
  providedIn: 'root'
})
export class GrouppermissionResolver  {
  constructor(private _rs: GrouppermissionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Grouppermission> {
    return this._rs.fetch(+route.params["grouppermissionId"])
  }
}
