import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { map, Observable, Subject } from "rxjs";
import { MemberBase } from "../../../../models/structures/member/member.base";
import { CdataKey } from "../../../../models/cdata/cdata-key/cdata-key";
import { StorageListComponent } from "../../../../models/structures/storage/storage-list/storage-list.component";
import { StorageCreateComponent } from "../../../../models/structures/storage/storage-create/storage-create.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { BaseListViewComponent } from "../../../../components/baseview/baselistview.component";
import { Storage } from "../../../../models/structures/storage/storage";
import { STORAGE_TYPES } from "../../../../models/structures/storage/storage.base";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { AuthService } from "../../../../models/users/auth.service";
import { ADMIN_GROUPS } from "../../../../constants";

export interface StorageListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  member_detail_route: RouteConfigItem;
  types?: STORAGE_TYPES[];
  context: string | null;
}

export interface StorageListViewData extends StorageListViewParams {
  cdatakeys: CdataKey[];
}

@Component({
  selector: "lvadg-storage-list-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    StorageListComponent,
    StorageCreateComponent,
    IconComponent,
  ],
  templateUrl: "./storage-list-view.component.pug",
  styleUrls: ["./storage-list-view.component.sass"],
})
export class StorageListViewComponent extends BaseListViewComponent<
  StorageListViewData,
  Storage
> {
  public member_detail_route!: Link<MemberBase>;
  public default_fields = [
    "id",
    "cvva",
    "name",
    "type",
    "member_details",
    "location_details",
    "updated",
    "status",
  ];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();
  public filter$!: Observable<FilterDefaults>;
  public user = inject(AuthService).currentUser;
  public readonly ADG = ADMIN_GROUPS;

  public override setRouteData(data: StorageListViewData) {
    super.setRouteData(data);
    this.member_detail_route = new Link<MemberBase>(
      data.member_detail_route,
      this,
      this._router,
    );
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.filter$ = this.data$.pipe(
      map((data) => {
        const f: FilterDefaults = {};
        if (data.context) {
          f["context"] = data.context;
        }
        if (data.types) {
          f["types"] = data.types.join(",");
        }
        return f;
      }),
    );
  }
}
