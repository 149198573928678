import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Allergen } from '../../../../../models/catalog/meta/allergen/allergen';

export interface AllergenDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface AllergenDetailViewData extends AllergenDetailViewParams {
  allergen: Allergen;
}
