import {
  Inject,
  Injectable,
  InjectionToken,
  PLATFORM_ID,
  TransferState,
} from "@angular/core";
import { Collection, DataBackend } from "@solidev/data";
import { SupportMessage } from "./support-message";
import { HttpClient } from "@angular/common/http";

export const SUPPORT_PROJECT_ID = new InjectionToken<number>(
  "support.project.id",
);

@Injectable({
  providedIn: "root",
})
export class SupportDataBackend extends DataBackend {
  constructor(
    _http: HttpClient,
    _transferState: TransferState,
    @Inject(PLATFORM_ID) _platform: string,
  ) {
    super(_http, _transferState, _platform, "https://pagstit.com/api", 0);
  }

  protected override headers(headers: Record<string, string>): Record<string, string> {
    if (localStorage.getItem("support")) {
      return Object.assign(
        {},
        { Authorization: `Bearer ${localStorage.getItem("support")}` },
        super.headers(headers),
      );
    }
    return super.headers(headers);
  }
}

@Injectable({
  providedIn: "root",
})
export class SupportMessageService extends Collection<SupportMessage> {
  constructor(_backend: SupportDataBackend) {
    super(_backend, "/v1/messages", SupportMessage);
  }
}
