
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="producer">
  <li class="nav-item" *ngIf="producer_detail_route"><a class="nav-link" [routerLink]="producer_detail_route.route({producer: producer})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Fiche producteur</a></li>
  <!-- Produits-->
  <li class="nav-item" *ngIf="producer_products_route"><a class="nav-link" [routerLink]="producer_products_route.route({producer: producer})" routerLinkActive="active">Liste des produits</a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="producer_edit_route &amp;&amp; canEdit()"><a class="nav-link" [routerLink]="producer_edit_route.route({producer: producer})" routerLinkActive="active">Édition</a></li>
  <!-- Relations-->
  <li class="nav-item" *ngIf="producer_relations_route &amp;&amp; canAdmin()"><a class="nav-link" [routerLink]="producer_relations_route.route({producer: producer})" routerLinkActive="active">Relations</a></li>
  <!-- Documents-->
  <li class="nav-item" *ngIf="producer_documents_route"><a class="nav-link" [routerLink]="producer_documents_route.route({producer: producer})" routerLinkActive="active">Documents</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="producer_offers_route &amp;&amp; canAdmin()"><a class="nav-link" [routerLink]="producer_offers_route.route({producer: producer})" routerLinkActive="active">Offres</a></li>
</ul>