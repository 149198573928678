import {
  booleanField,
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
} from "@solidev/data";

export enum LABEL_TYPE {
  FL = "FL",
  SEA = "MR",
  STRUCTURE = "ST",
  OTHER = "OT",
}

export enum LABEL_EGALIM_TYPE {
  EGALIM_BIO = "bio",
  EGALIM_CLIENT = "cli",
  EGALIM_HVE = "hve",
  EGALIM_RUP = "rup",
  EGALIM_VAL = "val",
  EGALIM_UNK = "unk",
  EGALIM_NA = "na",
}

export class LabelBase extends DataModel {
  static override readonly __name: string = "LabelBase";

  @primaryField({ name: "id", description: "ID" })
  public override id!: number;

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
  })
  public updated!: Date;

  @charField({
    name: "type",
    description: "Type de label",
    defaultValue: "OT",
    required: true,
    maxLength: 2,
    choices: [
      {
        value: LABEL_TYPE.FL,
        desc: "Label spécifique fruits/légumes",
      },
      {
        value: LABEL_TYPE.SEA,
        desc: "Label spécifique marée",
      },
      {
        value: LABEL_TYPE.STRUCTURE,
        desc: "Label de structure",
      },
      {
        value: LABEL_TYPE.OTHER,
        desc: "Autre type de label / certification",
      },
    ],
  })
  public type: LABEL_TYPE = LABEL_TYPE.OTHER;
  @charField({ name: "code", description: "Code du label", maxLength: 20 })
  public code!: string;
  @charField({ name: "name", description: "Nom", maxLength: 200 })
  public name!: string;
  @foreignKeyField({ name: "logo", description: "Logo", related: "Image" })
  public logo!: number;
  @charField({ name: "url", description: "URL de description", maxLength: 500 })
  public url!: string;
  @booleanField({ name: "egalim", description: "Egalim ?" })
  public egalim!: boolean;
  @booleanField({ name: "catalog_only", description: "Catalogue uniquement" })
  public catalog_only: boolean = false;
  @charField({
    name: "egalim_type",
    description: "Type Egalim",
    choices: [
      { value: LABEL_EGALIM_TYPE.EGALIM_BIO, desc: "Egalim Bio" },
      { value: LABEL_EGALIM_TYPE.EGALIM_CLIENT, desc: "Egalim Client" },
      { value: LABEL_EGALIM_TYPE.EGALIM_HVE, desc: "Egalim HVE" },
      { value: LABEL_EGALIM_TYPE.EGALIM_RUP, desc: "Egalim RUP" },
      { value: LABEL_EGALIM_TYPE.EGALIM_VAL, desc: "Egalim Valorisation" },
      { value: LABEL_EGALIM_TYPE.EGALIM_UNK, desc: "Non précisé" },
      { value: LABEL_EGALIM_TYPE.EGALIM_NA, desc: "Sans objet" },
    ],
  })
  public egalim_type: LABEL_EGALIM_TYPE = LABEL_EGALIM_TYPE.EGALIM_NA;
  @booleanField({ name: "egalim_eq", description: "Equivalence Egalim ?" })
  public egalim_eq!: boolean;
  @integerField({ name: "orig_id", description: "orig id" })
  public orig_id!: number;
  @integerField({ name: "ordering", description: "Ordre d'affichage" })
  public ordering: number = 0;

  public get type_short(): string {
    if (this.type === LABEL_TYPE.FL) {
      return "F&L";
    }
    if (this.type === LABEL_TYPE.SEA) {
      return "MARÉE";
    }
    if (this.type === LABEL_TYPE.STRUCTURE) {
      return "STRUCT";
    }
    return "AUTRE";
  }

  public get _display(): string {
    return `[${this.type_short}] ${this.name}`;
  }
}
