import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '../../../../../models/structures/storage/storage';
import { STORAGE_ROUTES } from '../menu';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { StorageNavComponent } from '../_nav/storage-nav.component';
import { StorageManageComponent } from '../../../../../models/structures/storage/storage-manage/storage-manage.component';

export interface StorageEditViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
}

export interface StorageEditViewData extends StorageEditViewParams {
  storage: Storage;
}

@Component({
  selector: 'lvadg-storage-edit-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, StorageNavComponent, StorageManageComponent],
  templateUrl: './storage-edit-view.component.pug',
  styleUrls: ['./storage-edit-view.component.sass'],
})
export class StorageEditViewComponent extends BaseDetailViewComponent<StorageEditViewData, Storage> {
  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageEditViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }
}
