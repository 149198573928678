
<ng-container *ngIf="obj">
  <dl class="row">
    <data-dispedit class="col-12" [model]="obj" mode="inline" field="src" [collection]="tariftypes$" *ngIf="!src">Source</data-dispedit>
    <data-dispedit class="col-12" [model]="obj" mode="inline" field="dest" [collection]="tariftypes$" *ngIf="!dest">Destination</data-dispedit>
    <data-dispedit class="col-6" [model]="obj" mode="inline" field="type">Type</data-dispedit>
    <data-dispedit class="col-6" [model]="obj" mode="inline" field="ops">Opérations</data-dispedit>
  </dl>
  <div class="row">
    <div class="col-8">
      <button class="btn btn-primary w-100" (click)="save()"><i class="bi bi-save me-2"></i>Créer la relation</button>
    </div>
    <div class="col-4">
      <button class="btn btn-outline-secondary" (click)="cancelled.emit()">Annuler</button>
    </div>
  </div>
</ng-container>