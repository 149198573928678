import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProviderDocument } from "../provider-document";
import { LabelService } from "../../../catalog/label/label.service";
import { ProviderService } from "../../provider/provider.service";
import { ArticleService } from "../../../catalog/article/article.service";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";

@Component({
  selector: "lvadg-provider-document-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    ImageDisplayComponent,
  ],
  templateUrl: "./provider-document-manage.component.pug",
  styleUrls: ["./provider-document-manage.component.sass"],
})
export class ProviderDocumentManageComponent {
  @Input() public providerdocument?: ProviderDocument;

  constructor(
    public labels$: LabelService,
    public providers$: ProviderService,
    public articles$: ArticleService,
  ) {}
}
