import { BaseRouteParams, DataModel, Link, RouteConfigItem } from '@solidev/data';
import { BaseViewComponent } from './baseview.component';
import { Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface BaseListRouteParams extends BaseRouteParams {
  detail_route?: RouteConfigItem;
}

@Directive()
export class BaseListViewComponent<RD extends BaseListRouteParams, T extends DataModel> extends BaseViewComponent<RD> {
  public detail_route!: Link<T>;

  constructor(_router: Router, _route: ActivatedRoute) {
    super(_router, _route);
  }

  public override setRouteData(data: RD) {
    super.setRouteData(data);
    if (data.detail_route) {
      this.detail_route = new Link<T>(data.detail_route, data, this._router);
    }
  }
}
