import { charField, foreignKeyField } from '@solidev/data';
import { ArticleMetaBase } from '../meta.base';

export enum REGION_TYPE {
  REGION_FRANCE = 'FRG',
  REGION_WORLD = 'MRG',
  CITY_FRANCE = 'FCT',
  DEPARTMENT_FRANCE = 'FDP',
  MISC = 'DVS',
}

export class RegionBase extends ArticleMetaBase {
  static override readonly __name: string = 'RegionBase';

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: REGION_TYPE.MISC,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: REGION_TYPE.REGION_FRANCE,
        desc: 'Région (France)',
      },
      {
        value: REGION_TYPE.REGION_WORLD,
        desc: 'Région (monde)',
      },
      {
        value: REGION_TYPE.CITY_FRANCE,
        desc: 'Ville (France)',
      },
      {
        value: REGION_TYPE.DEPARTMENT_FRANCE,
        desc: 'Département (France)',
      },
      {
        value: REGION_TYPE.MISC,
        desc: 'Divers',
      },
    ],
  })
  public override type: string = REGION_TYPE.MISC;

  @foreignKeyField({
    name: 'georegion',
    description: 'ID GeoRegion',
    related: 'GeoRegion',
    priority: -1,
  })
  public georegion!: number;
  @foreignKeyField({
    name: 'geodepartement',
    description: 'ID GeoDepartement',
    related: 'GeoDepartement',
    priority: -1,
  })
  public geodepartement!: number;
  @foreignKeyField({
    name: 'geocommune',
    description: 'ID GeoCommune',
    related: 'GeoCommune',
    priority: -1,
  })
  public geocommune!: number;

  public get type_sdesc(): string {
    return (
      {
        [REGION_TYPE.REGION_FRANCE]: 'Reg. FR',
        [REGION_TYPE.REGION_WORLD]: 'Reg. Monde',
        [REGION_TYPE.CITY_FRANCE]: 'Ville FR',
        [REGION_TYPE.DEPARTMENT_FRANCE]: 'Dpt. FR',
        [REGION_TYPE.MISC]: 'Divers',
      }[this.type] || '???'
    );
  }
}
