import { Component, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Cdata } from "../cdata";
import { CdataKey } from "../../cdata-key/cdata-key";

@Component({
  selector: "lvadg-cdata-display",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./cdata-display.component.pug",
  styleUrls: ["./cdata-display.component.sass"],
})
export class CdataDisplayComponent {
  public cdata = input.required<Cdata>();
  public cdatakey = input<CdataKey>();
  public mode = input<"list">("list");
}
