
<ng-container *ngIf="display==='card'">
  <div class="card-header">
    <lvadg-icon ri="producer"><span class="fw-bold">{{title}}</span></lvadg-icon>
  </div>
  <div class="max-height-200">
    <nav class="list-group list-group-flush">
      <ng-container *ngFor="let p of producers$|async"><a class="list-group-item list-group-item-action" [routerLink]="detail_route?.route({producer: p})">{{p.name}}<small class="ms-2">{{p.location_details?.postcode}} {{p.location_details?.city}}</small></a></ng-container>
    </nav>
  </div>
</ng-container>