import { TarifLifecycleStepBase } from './tarif-lifecycle-step.base';
import { detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';

export class TarifLifecycleStep extends TarifLifecycleStepBase {
  static override readonly __name: string = 'TarifLifecycleStep';

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: TarifLifecycleStepBase,
    many: true,
    description: 'Sous-étapes',
  })
  public substeps_details?: TarifLifecycleStepBase[];
}
