import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  DataMessageService,
  DataModel,
  FilterDefaults,
  ModelList,
} from "@solidev/data";
import {
  intoProviderData,
  PrintContext,
  PrintSettings,
  PrintSettingsItem,
  strictStringFilter,
} from "../../print-settings";
import { Selection } from "../../../../components/selection/selection.service";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { PrintService } from "../print.service";
import { ProviderData } from "../../rstypes/ProviderData";
import { firstValueFrom, Observable } from "rxjs";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { UserPrintContextService } from "../../user-print-context.service";

@Component({
  selector: "lvadg-modellist-print-button",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./modellist-print-button.component.pug",
  styleUrls: ["./modellist-print-button.component.sass"],
})
export class ModellistPrintButtonComponent<T extends DataModel> {
  @Input({ required: true }) public list!: ModelList<T>;
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults>;
  @Input({ required: true }) public print_settings?: PrintSettings;
  @Input() public print_context: PrintContext = {};
  @Input({ required: true }) public selection?: Selection<T>;
  @ViewChild("ofcslot", { static: true }) ofcTemplate!: ElementRef<HTMLElement>;
  public instance?: NgbOffcanvasRef;

  constructor(
    private _ofc: NgbOffcanvas,
    private _prt: PrintService,
    private _msgs: DataMessageService,
    private _upc: UserPrintContextService,
  ) {}

  public async print(
    ps: PrintSettingsItem,
    mode: "button" | "quick_print" | "add_to_print",
  ) {
    if (mode === "button") {
      return;
    }
    const filter = await firstValueFrom(this.list.filters.output);
    const sorter = await firstValueFrom(this.list.sorter.output);
    if (sorter.length > 0) {
      filter["ordering"] = sorter.join(",");
    }
    if (this.filter) {
      if (this.filter instanceof Observable) {
        const f = await firstValueFrom(this.filter);
        Object.assign(filter, f);
      } else {
        Object.assign(filter, this.filter);
      }
    }
    if (!this.list.count) {
      this._msgs.error(
        "Pas d'éléments pour cette impression",
        `Aucun élément à imprimer.`,
      );
      this.instance?.close();
      return;
    }
    if (this.list.count > ps.limit) {
      this._msgs.error(
        "Trop d'éléments pour cette impression",
        `Le maximum autorisé pour ce type d'impression est de ${ps.limit} éléments.`,
      );
      this.instance?.close();
      return;
    }
    const data: ProviderData = {
      type: ps.provider,
      queryset: {
        page: "1",
        page_size: `${ps.limit + 1}`,
        ...strictStringFilter(filter),
      },
      description: this.list.name,
    } as ProviderData;
    this._upc.update_context(this.print_context);
    this._prt.print(ps, data, intoProviderData(this.print_context), mode);
    this.instance?.close();
  }

  public clickIcon() {
    this.instance = this._ofc.open(this.ofcTemplate, { position: "end" });
  }
}
