
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VivalyaBillItem } from '../vivalya-bill-item';
@Component({
  selector: 'lvadg-vivalya-bill-item-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vivalya-bill-item-manage.component.pug',
  styleUrls: ['./vivalya-bill-item-manage.component.sass']
})
export class VivalyaBillItemManageComponent implements OnInit {
  @Input() public vivalyabillitem?: VivalyaBillItem;

  constructor() { }

  ngOnInit(): void {
  }

}
