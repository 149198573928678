import { charField, DataModel, datetimeField, primaryField, textField } from '@solidev/data';

export enum NEWS_CATEGORY_STATUSES {
  OK = 'OK',
  KO = 'KO',
}

export class NewsCategoryBase extends DataModel {
  static override readonly __name: string = 'NewsCategoryBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @charField({
    name: 'title',
    description: 'title',
    maxLength: 255,
  })
  public title!: string;

  @textField({
    name: 'description',
    description: 'Description categorie',
    maxLength: 2000,
  })
  public description!: string;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @charField({
    name: 'status',
    description: 'status',
    priority: 100,
    maxLength: 2,
    choices: [
      {
        value: NEWS_CATEGORY_STATUSES.KO,
        desc: 'Non publié',
      },
      {
        value: NEWS_CATEGORY_STATUSES.OK,
        desc: 'Publié',
      },
    ],
  })
  public status!: string;
}
