
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Relation } from '../relation';
@Component({
  selector: 'lvadg-relation-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './relation-manage.component.pug',
  styleUrls: ['./relation-manage.component.sass']
})
export class RelationManageComponent implements OnInit {
  @Input() public relation?: Relation;

  constructor() { }

  ngOnInit(): void {
  }

}
