import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifNotification } from '../tarif-notification';
import { TarifNotificationService } from '../tarif-notification.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { TarifDisplayComponent } from '../../tarif/tarif-display/tarif-display.component';
import { TarifBase } from '../../tarif/tarif.base';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import {
  TARIFNOTIFICATION_PRIORITY,
  TARIFNOTIFICATION_STATUS,
  TarifNotificationBase,
} from '../tarif-notification.base';

@Component({
  selector: 'lvadg-tarif-notification-list',
  standalone: true,
  templateUrl: './tarif-notification-list.component.pug',
  styleUrls: ['./tarif-notification-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    UserDisplayComponent,
    TariftypeDisplayComponent,
    TarifDisplayComponent,
    PChoicePipe,
  ],
})
export class TarifNotificationListComponent extends ModellistComponent<TarifNotification> {
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;

  constructor(
    coll: TarifNotificationService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'type', 'priority', 'status'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Recherche par type',
          model: TarifNotificationBase,
        }),
        new ModelListSelectFilter({
          field: 'priority',
          name: 'priority',
          label: 'Recherche par priorité',
          model: TarifNotificationBase,
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Recherche par état',
          model: TarifNotificationBase,
        }),
      ],
    };
  }

  public override getRowClasses(row: TarifNotification): string[] {
    const cls = super.getRowClasses(row);
    if (row.priority === TARIFNOTIFICATION_PRIORITY.P1) {
      cls.push('text-danger');
    }
    if (row.status !== TARIFNOTIFICATION_STATUS.SENT) {
      cls.push('text-warning');
    }
    if (row.priority === TARIFNOTIFICATION_PRIORITY.P3) {
      cls.push('text-muted');
    }

    return cls;
  }
}
