
<ng-template #attselector let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Sélection d'article - tarif source : {{attselectorSource?.name}}</h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <table class="table table-bordered mb-3">
      <tbody>
        <ng-container *ngIf="attselectorTarget">
          <tr>
            <th colspan="10">Correspondance recherchée pour :</th>
          </tr>
          <tr>
            <td>
              <lvadg-article-display [article]="attselectorTarget.article_details" mode="line" [detail_route]="article_detail_route"></lvadg-article-display>
            </td>
            <td><small>UDF={{attselectorTarget.article_details?.udf_unit}}</small></td>
            <td></td>
          </tr>
        </ng-container>
        <ng-container *ngIf="attselectorCurrent">
          <tr>
            <th colspan="10">Correspondance actuelle :</th>
          </tr>
          <tr>
            <td>
              <lvadg-article-display [article]="attselectorCurrent.article_details" mode="line" [detail_route]="article_detail_route"></lvadg-article-display>
            </td>
            <td><small>UDF={{attselectorCurrent.article_details?.udf_unit}}</small></td>
            <td>
              <button class="btn btn-warning btn-sm my-2 w-100" (click)="attRemove()"><i class="bi bi-trash me-2"></i>Supprimer l'association</button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <lvadg-article-tarif-template-list name="attselector_modal" [keep]="false" [default_fields]="attselector_default_fields" [actions]="['select']" (action)="attSelected($event)" [filter]="attselectorFilter" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route"></lvadg-article-tarif-template-list>
  </div>
</ng-template>
<ng-container *ngIf="articletariftemplaterelation; let attrel">
  <ng-container *ngIf="mode==='inline'">
    <ng-container *ngIf="field==='dest'">
      <ng-template #nodest>
        <button class="btn btn-outline-primary btn-sm me-2" (click)="openModal()"><i class="bi bi-plus me-2"></i>Associer un article destination</button>
      </ng-template>
      <ng-container *ngIf="attrel.dest_details; let att; else nodest">
        <button class="btn btn-outline-primary btn-sm me-2" (click)="openModal()"><i class="bi bi-pencil"></i></button>
        <lvadg-article-tarif-template-display [articletariftemplate]="att" mode="line" [detail_route]="articletariftemplate_detail_route"></lvadg-article-tarif-template-display>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="field==='src'">
      <ng-template #nosrc>
        <button class="btn btn-outline-primary btn-sm me-2" (click)="openModal()"><i class="bi bi-plus me-2"></i>Associer un article source</button>
      </ng-template>
      <ng-container *ngIf="attrel.src_details; let att; else nosrc">
        <button class="btn btn-outline-primary btn-sm me-2" (click)="openModal()"><i class="bi bi-pencil"></i></button>
        <lvadg-article-tarif-template-display [articletariftemplate]="att" mode="line" [detail_route]="articletariftemplate_detail_route"></lvadg-article-tarif-template-display>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="field==='ops'">
      <div class="d-flex flex-row">
        <button class="btn btn-outline-primary btn-sm me-2" (click)="editOps=!editOps"><i class="bi bi-pencil"></i></button><code *ngIf="articletariftemplaterelation.ops &amp;&amp; !editOps">{{articletariftemplaterelation.ops}}</code>
        <input class="form-control form-select-sm" [formControl]="opsFormControl" (keyup.enter)="updateOps()" (keyup.escape)="editOps=!editOps" *ngIf="editOps">
      </div>
    </ng-container>
  </ng-container>
</ng-container>