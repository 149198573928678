
<ng-container *ngIf="data$|async; let data">
  <lvadg-producer-nav [producer]="data.producer" [storage]="data.storage" [routes]="data.pr_menu"></lvadg-producer-nav>
  <div class="container">
    <lvadg-header-action [title]="page_title()||data.producer.name" level="h1" ri="producer" [subtitle]="data.producer.location_details?.cpcity">
      <ng-container actions>
        <lvadg-iocode-button class="me-3" [model]="data.producer">
          <lvadg-icon class="fs-3 px-1" role="button" ri="iocode" [icon_only]="true"></lvadg-icon>
        </lvadg-iocode-button>
        <lvadg-quickprint-button [model]="data.producer" [print_settings]="print_settings" [template]="'producer_poster'" [title]="'Impression affiche producteur'">
          <lvadg-icon class="fs-3 px-1" ri="poster" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
        <lvadg-quickprint-button [model]="data.producer" [print_settings]="print_settings" [template]="'producer_detail'" [title]="'Impression fiche producteur'">
          <lvadg-icon class="fs-3 px-1" ri="fiche" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
        <lvadg-quickprint-button [model]="data.producer" [print_settings]="print_settings" [template]="'producer_detail_products'" [title]="'Impression fiche producteur et produits'">
          <lvadg-icon class="fs-3 px-1" ri="fiches" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
      </ng-container>
    </lvadg-header-action>
    <lvadg-producer-details [producer]="data.producer"></lvadg-producer-details>
  </div>
</ng-container>