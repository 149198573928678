import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessPermissionService } from '../orderprocess-permission.service';
import { OrderprocessPermissionBase } from '../orderprocess-permission.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { GroupService } from '../../../users/group/group.service';
import { UserService } from '../../../users/user/user.service';
import { OrderprocessService } from '../../orderprocess/orderprocess.service';
import { OrderprocessBase } from '../../orderprocess/orderprocess.base';
import { OrderprocessPermission } from '../orderprocess-permission';

@Component({
  selector: 'lvadg-orderprocess-permission-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './orderprocess-permission-create.component.pug',
  styleUrls: ['./orderprocess-permission-create.component.sass'],
})
export class OrderprocessPermissionCreateComponent implements OnInit {
  public obj!: OrderprocessPermission;
  public createForm = new FormGroup({});
  @Input() public orderprocess?: OrderprocessBase;
  @Output() public created = new EventEmitter<OrderprocessPermissionBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _svc: OrderprocessPermissionService,
    public orderprocess$: OrderprocessService,
    public groups$: GroupService,
    public users$: UserService
  ) {}

  public ngOnInit(): void {
    this.obj = new OrderprocessPermission(this._svc);
    if (this.orderprocess) {
      this.obj.target_details = this.orderprocess;
      this.obj.target = this.orderprocess.id;
    }
  }

  public async save() {
    this.obj.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
