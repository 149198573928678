import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface IDocToc {
  doc: number;
  id: number;
  level: number;
  link: string;
  order: number;
  title: string;
}

export class DocToc implements IDocToc {
  public doc: number;
  public id: number;
  public level: number;
  public link: string;
  public order: number;
  public title: string;

  constructor(doctoc: IDocToc) {
    this.doc = doctoc.doc;
    this.id = doctoc.id;
    this.level = doctoc.level;
    this.link = doctoc.link;
    this.title = doctoc.title;
    this.order = doctoc.order;
  }
}

export interface IDoc {
  path: string;
  root: string;
  nrel: string;
  file: string;
  nfile: string;
  mtime: number;
  groups: string[];
  title: string;
  toc: {
    level: number;
    text: string;
    id: string;
  }[];
  links: {
    external: string[];
    internal: string[];
  }[];
  images: {
    external: string[];
    internal: string[];
  }[];
  html: string;
  doctoc_details: IDocToc[];
}

export class Doc implements IDoc {
  public file: string;
  public groups: string[];
  public html: string;
  public images: { external: string[]; internal: string[] }[];
  public links: { external: string[]; internal: string[] }[];
  public mtime: number;
  public nfile: string;
  public nrel: string;
  public path: string;
  public root: string;
  public title: string;
  public toc: { level: number; text: string; id: string }[];
  public content: SafeHtml;
  public doctoc_details: DocToc[] = [];

  constructor(doc: IDoc, san: DomSanitizer) {
    this.file = doc.file;
    this.groups = doc.groups;
    this.html = doc.html;
    this.images = doc.images;
    this.links = doc.links;
    this.mtime = doc.mtime;
    this.nfile = doc.nfile;
    this.nrel = doc.nrel;
    this.path = doc.path;
    this.root = doc.root;
    this.title = doc.title;
    this.toc = doc.toc;
    this.content = san.bypassSecurityTrustHtml(this.html);
    for (const dt of doc.doctoc_details) {
      this.doctoc_details.push(new DocToc(dt));
    }
  }
}
