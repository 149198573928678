import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  decimalField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class VivalyaBillBase extends DataModel {
  static override readonly __name: string = 'VivalyaBillBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 50,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 80,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @detailsField({
    name: 'hash',
    description: 'Hash',
    priority: -1,
  })
  public hash!: string;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -1,
  })
  public storage!: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Restaurant',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @dateField({
    name: 'billdate',
    description: 'Date de facturation',
    priority: 950,
  })
  public billdate!: Date;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 100,
    priority: 970,
  })
  public code!: string;

  @decimalField({
    name: 'brut_amount',
    description: 'Brut',
    priority: 504,
    factor: 10000,
  })
  public brut_amount!: number;

  @decimalField({
    name: 'net_amount',
    description: 'Net',
    priority: 503,
    factor: 10000,
  })
  public net_amount!: number;

  @decimalField({
    name: 'vat_amount',
    description: 'TVA',
    priority: 502,
    factor: 10000,
  })
  public vat_amount!: number;

  @decimalField({
    name: 'wvat_amount',
    description: 'TTC',
    priority: 501,
    factor: 10000,
  })
  public wvat_amount!: number;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1000,
  })
  public orig_id!: number;

  public get _display(): string {
    return `${this.code} - ${this.billdate.toLocaleDateString()}`;
  }
}
