import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Production } from '../production';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-production-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './production-manage.component.pug',
  styleUrls: ['./production-manage.component.sass'],
})
export class ProductionManageComponent {
  @Input() public production!: Production;

  constructor() {}
}
