import { enableProdMode, importProvidersFrom, LOCALE_ID } from "@angular/core";

import { environment } from "./environments/environment";
import { AdminAppComponent } from "./app/app.component";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideRouter, withRouterConfig } from "@angular/router";
import { ADMIN_ROUTES } from "./app/routing/main";
import {
  AuthInterceptor,
  DATA_API_URL,
  DATA_AUTH_PARAMS,
  DATA_AUTH_SERVICE,
  DATA_AUTH_URLS,
  DATA_AUTH_USER_SERVICE,
} from "@solidev/data";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  AuthService,
  LIVE_URL,
  LVADG_MODE,
  PRINT_API_URL,
  PRINT_DETAIL_ROUTE,
  SITE_DEFAULTS,
  SUPPORT_ZONE,
  UrlInterceptor,
  UserService,
} from "@vivalya/lvadg";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  registerLocaleData,
} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AuthParams } from "@solidev/data/lib/auth/auth.service";
import { ADMIN_ROUTES_DEF } from "./app/routing/router";

if (environment.production) {
  enableProdMode();
}
registerLocaleData(localeFr, "fr", localeFrExtra);

bootstrapApplication(AdminAppComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: "fr" },
    { provide: DATA_API_URL, useValue: environment.API_URL },
    { provide: PRINT_API_URL, useValue: environment.PRINT_API_URL },
    {
      provide: DATA_AUTH_PARAMS,
      useValue: {
        tokenUrl: environment.API_URL + "/v3/token",
        tokenRefreshUrl: environment.API_URL + "/v3/token/refresh",
        loginField: "username",
        passwordField: "password",
        tokenField: "token",
        loginRoute: ADMIN_ROUTES.login.route(),
        userFetchValiditySeconds: 600,
      } as AuthParams,
    },
    { provide: DATA_AUTH_URLS, useValue: [environment.PRINT_API_URL] },
    { provide: DATA_AUTH_USER_SERVICE, useExisting: UserService },
    { provide: DATA_AUTH_SERVICE, useExisting: AuthService },
    {
      provide: PRINT_DETAIL_ROUTE,
      useValue: ADMIN_ROUTES.layouts_prints_print,
    },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: LIVE_URL, useValue: environment.LIVE_URL },
    { provide: LVADG_MODE, useValue: environment.mode },
    { provide: SUPPORT_ZONE, useValue: "admin" },
    {
      provide: SITE_DEFAULTS,
      useValue: {
        MODELLIST_DEFAULT_POSITION: "ml-top",
        MODELLIST_SHOW_LISTNAME: environment.mode === "development",
      },
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        // Get image without extension
        const src = config.src.split(".").slice(0, -1).join(".");
        // Add width
        if (config.width) {
          if (config.loaderParams && config.loaderParams["contain"]) {
            return `${src}--contain_${config.width}.webp`;
          }
          if (config.loaderParams && config.loaderParams["cover"]) {
            return `${src}--cover_${config.width}.webp`;
          }
          return `${src}--resize_${config.width}.webp`;
        } else {
          return `${src}.webp`;
        }
      },
    },
    AuthService,
    provideAnimations(),
    provideRouter(
      ADMIN_ROUTES_DEF,
      withRouterConfig({
        paramsInheritanceStrategy: "always",
        onSameUrlNavigation: "reload",
      }),
    ),
    importProvidersFrom(
      ServiceWorkerModule.register("/ngsw-worker.js", {
        enabled: environment.production,
      }),
    ),
  ],
}).catch((err) => console.error(err));
