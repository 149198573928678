import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataMessageService, Link } from '@solidev/data';
import { RouterLink } from '@angular/router';
import { Product } from '../product';
import { Family } from '../../family/family';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { FamilyDisplayComponent } from '../../family/family-display/family-display.component';

@Component({
  selector: 'lvadg-product-display',
  standalone: true,
  imports: [CommonModule, RouterLink, ImageDisplayComponent, FamilyDisplayComponent],
  templateUrl: './product-display.component.pug',
  styleUrls: ['./product-display.component.sass'],
})
export class ProductDisplayComponent implements OnInit {
  @Input() public product!: Product;
  @Input() public mode: 'line' | 'rich' = 'line';
  @Input() public detail_route?: Link<Product>;
  @Input() public family_detail_route?: Link<Family>;

  constructor(private _msgs: DataMessageService) {}

  public async clip() {
    await navigator.clipboard.writeText(this.product?.name || '');
    this._msgs.success('Nom du produit copié dans le presse-papier');
  }

  ngOnInit(): void {}
}
