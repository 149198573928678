
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class UserMapLayerBase extends DataModel {
  static override readonly __name: string = "UserMapLayerBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @datetimeField({"name": "created", "description": "created", "readonly": true})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated", "readonly": true})
  public updated!: Date;

  @foreignKeyField({"name": "usermap", "description": "usermap", "related": "UserMap"})
  public usermap!: number;

  @charField({"name": "name", "description": "name", "maxLength": 200})
  public name!: string;

  @charField({"name": "type", "description": "type", "defaultValue": "CIRC", "maxLength": 4, "choices": [{"value": "CIRC", "desc": "Circle"}, {"value": "MARK", "desc": "Marker"}, {"value": "POLY", "desc": "Polygon"}, {"value": "CDAT", "desc": "Custom data"}, {"value": "STPS", "desc": "Structures positions"}, {"value": "STRL", "desc": "Relations"}]})
  public type: string = "CIRC";

  @detailsField({"name": "data", "description": "data"})
  public data!: any;

  @integerField({"name": "position", "description": "position"})
  public position!: number;


}
