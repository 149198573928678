import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Rnm } from '../rnm';
import { RnmService } from '../rnm.service';
import {
  FactorPipe,
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FamilyBase } from '../../../catalog/family/family.base';
import { LabelDisplayComponent } from '../../../catalog/label/label-display/label-display.component';

@Component({
  selector: 'lvadg-rnm-list',
  standalone: true,
  templateUrl: './rnm-list.component.pug',
  styleUrls: ['./rnm-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, LabelDisplayComponent, FactorPipe],
})
export class RnmListComponent extends ModellistComponent<Rnm> {
  public family_detail_route?: Link<FamilyBase>;

  constructor(coll: RnmService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
