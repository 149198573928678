import { BaseRouteParams, RData, RoutesDefinition } from '@solidev/data';
import { HomeComponent } from '../pages/home/home/home.component';
import { IsAdminGuard } from './guards/is-admin-guard.service';
import {
  AuthService,
  DocpageResolver,
  DocsDisplayViewComponent,
  GenericErrorViewComponent,
  LoginViewComponent,
  LogoutViewComponent,
  RI,
  RS,
} from '@vivalya/lvadg';
import { inject } from '@angular/core';
import { filter } from 'rxjs';
import { UrlSegment } from '@angular/router';
import { ADMIN_ROUTES } from './main';

export const ADMIN_ROUTES_DEF: RoutesDefinition = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    data: RData<BaseRouteParams>({
      title: 'Accueil',
      route: ADMIN_ROUTES.home,
    }),
    canMatch: [IsAdminGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    component: LoginViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Home non auth',
      route: ADMIN_ROUTES.home,
    }),
  },
  {
    path: RS.login,
    component: LoginViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Login',
      route: ADMIN_ROUTES.login,
    }),
  },
  {
    path: RS.logout,
    component: LogoutViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Logout',
      route: ADMIN_ROUTES.login,
    }),
  },
  {
    path: RS.access,
    loadChildren: () => import('../pages/access/access.routes').then((m) => m.ADMIN_ACCESS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      icon: RI.access,
      title: 'Accès',
      route: ADMIN_ROUTES.access,
    }),
  },
  {
    path: RS.structure,
    loadChildren: () => import('../pages/structures/structures.routes').then((m) => m.ADMIN_STRUCTURES_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Structures',
      route: ADMIN_ROUTES.structure,
    }),
  },
  {
    path: RS.locations,
    loadChildren: () => import('../pages/locations/locations.routes').then((m) => m.ADMIN_LOCATIONS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Localisations',
      icon: RI.location,
      route: ADMIN_ROUTES.locations,
    }),
  },
  {
    path: RS.catalog,
    loadChildren: () => import('../pages/catalog/catalog.routes').then((m) => m.ADMIN_CATALOG_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Catalogue',
      icon: RI.catalog,
      route: ADMIN_ROUTES.catalog,
    }),
  },
  {
    path: RS.tarifs,
    loadChildren: () => import('../pages/tarifs/tarifs.routes').then((m) => m.ADMIN_TARIFS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Tarifs',
      icon: RI.tarifs,
      route: ADMIN_ROUTES.tarifs,
    }),
    resolve: {
      user: () => {
        return inject(AuthService).currentUser$.pipe(filter((u) => !!u));
      },
    },
  },
  {
    path: RS.rnm,
    loadChildren: () => import('../pages/rnm/rnm.routes').then((m) => m.ADMIN_RNM_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Marchés',
      route: ADMIN_ROUTES.rnm,
    }),
  },
  {
    path: RS.orders,
    loadChildren: () => import('../pages/orders/orders.routes').then((m) => m.ADMIN_ORDERS_VIEW_ROUTES),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Commandes',
      route: ADMIN_ROUTES.orders,
    }),
  },
  {
    path: RS.documents,
    loadChildren: () => import('../pages/documents/documents.routes').then((m) => m.ADMIN_DOCUMENTS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Documents',
      route: ADMIN_ROUTES.documents,
      icon: RI.folder,
    }),
  },
  {
    path: RS.layouts,
    loadChildren: () => import('../pages/layouts/layouts.routes').then((m) => m.ADMIN_LAYOUTS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Impressions',
      route: ADMIN_ROUTES.layouts,
    }),
  },
  {
    path: RS.cdatas,
    loadChildren: () => import('../pages/cdatas/cdatas.routes').then((m) => m.ADMIN_CDATAS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Données personnalisées',
      route: ADMIN_ROUTES.cdatas,
    }),
  },
  {
    path: RS.utils,
    loadChildren: () => import('../pages/utils/utils.routes').then((m) => m.ADMIN_UTILS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Outils',
      route: ADMIN_ROUTES.utils,
      icon: RI.utils,
    }),
  },
  {
    path: RS.news,
    loadChildren: () => import('../pages/news/news.routes').then((m) => m.ADMIN_NEWS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Actualites',
      icon: RI.news,
      route: ADMIN_ROUTES.news,
    }),
  },

  {
    path: RS.offers,
    loadChildren: () => import('../pages/offers/offers.routes').then((m) => m.ADMIN_OFFERS_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Offres',
      icon: RI.offer,
      route: ADMIN_ROUTES.offers,
    }),
  },

  {
    path: RS.sales,
    loadChildren: () => import('../pages/sales/sales.routes').then((m) => m.ADMIN_SALES_ROUTES_DEF),
    canMatch: [IsAdminGuard],
    data: RData<BaseRouteParams>({
      title: 'Ventes',
      icon: RI.sales,
      route: ADMIN_ROUTES.sales,
    }),
  },

  {
    matcher: (url: UrlSegment[]) => {
      return url.length >= 1 && url[0].path === 'docs' ? { consumed: url } : null;
    },
    component: DocsDisplayViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Documentation',
      route: ADMIN_ROUTES.noop,
    }),
    resolve: { docpage: DocpageResolver },
  },
  {
    path: 'error',
    component: GenericErrorViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Erreur...',
      route: ADMIN_ROUTES.noop,
    }),
  },
  {
    path: '**',
    component: GenericErrorViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Erreur...',
      route: ADMIN_ROUTES.noop,
    }),
  },
];
