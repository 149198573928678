
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderitem } from '../orderitem';
@Component({
  selector: 'lvadg-orderitem-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './orderitem-manage.component.pug',
  styleUrls: ['./orderitem-manage.component.sass']
})
export class OrderitemManageComponent implements OnInit {
  @Input() public orderitem?: Orderitem;

  constructor() { }

  ngOnInit(): void {
  }

}
