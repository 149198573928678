
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-flags-display [item]="row"></lvadg-flags-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="hash">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="hash" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.hash}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="bill">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="bill" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.bill}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="article">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.article}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="adh_article">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="adh_article" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.adh_article}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="gc_article">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="gc_article" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.gc_article}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="family">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="family" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.family}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="producer">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.producer}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="weight">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="weight" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.weight|pfactor:row:"weight"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="packages">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="packages" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.packages|pfactor:row:"packages"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="pieces">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pieces" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.pieces|pfactor:row:"pieces"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="quantity">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="quantity" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.quantity|pfactor:row:"quantity"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="brut_amount">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="brut_amount" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.brut_amount|pfactorc:row:"brut_amount":"EUR"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="net_amount">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="net_amount" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.net_amount|pfactorc:row:"net_amount":"EUR"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="vat_amount">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="vat_amount" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.vat_amount|pfactorc:row:"vat_amount":"EUR"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="wvat_amount">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="wvat_amount" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.wvat_amount|pfactorc:row:"wvat_amount":"EUR"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uv">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uv" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uv}}</td>
        </ng-container>
        <ng-container cdkColumnDef="provider_country">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="provider_country" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.provider_country}}</td>
        </ng-container>
        <ng-container cdkColumnDef="provider_type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="provider_type" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.provider_type}}</td>
        </ng-container>
        <ng-container cdkColumnDef="provider_postcode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="provider_postcode" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.provider_postcode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="provider_name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="provider_name" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.provider_name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="vvfamily">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="vvfamily" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.vvfamily}}</td>
        </ng-container>
        <ng-container cdkColumnDef="adh_code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="adh_code" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.adh_code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="adh_libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="adh_libelle" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.adh_libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="gc_code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="gc_code" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.gc_code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="gc_libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="gc_libelle" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.gc_libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.orig_id}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="bill_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="bill_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-vivalya-bill-display [vivalyabill]="row.bill_details" mode="line" [detail_route]="bill_detail_route"></lvadg-vivalya-bill-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-display [article]="row.article_details" mode="line" [detail_route]="article_detail_route"></lvadg-article-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="adh_article_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="adh_article_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-earticle-display [earticle]="row.adh_article_details" mode="line" [detail_route]="earticle_detail_route"></lvadg-earticle-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="gc_article_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="gc_article_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-earticle-display [earticle]="row.gc_article_details" mode="line" [detail_route]="earticle_detail_route"></lvadg-earticle-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="producer_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-producer-display [producer]="row.producer_details" mode="line" [detail_route]="producer_detail_route"></lvadg-producer-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="storage_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storage_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-storage-display [storage]="row.storage_details" mode="line" [detail_route]="storage_detail_route"></lvadg-storage-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="client_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-client-display [client]="row.client_details" mode="line" [detail_route]="client_detail_route"></lvadg-client-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="member_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="member_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-member-display [member]="row.member_details" mode="line" [detail_route]="member_detail_route"></lvadg-member-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="resto_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="resto_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-resto-display [resto]="row.resto_details" mode="line" [detail_route]="resto_detail_route"></lvadg-resto-display>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>