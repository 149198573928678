import { Component } from '@angular/core';
import { BrandListViewData } from './brand-list-view.component.params';
import { Subject } from 'rxjs';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Brand } from '../../../../../models/catalog/meta/brand/brand';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { BrandListComponent } from '../../../../../models/catalog/meta/brand/brand-list/brand-list.component';
import { BrandCreateComponent } from '../../../../../models/catalog/meta/brand/brand-create/brand-create.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { Link } from '@solidev/data';
import { ProducerBase } from '../../../../../models/structures/producer/producer.base';
import { ProviderBase } from '../../../../../models/structures/provider/provider.base';

@Component({
  selector: 'lvadg-brand-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, BrandListComponent, HeaderActionComponent, IconComponent, BrandCreateComponent],
  templateUrl: './brand-list-view.component.pug',
  styleUrls: ['./brand-list-view.component.sass'],
})
export class BrandListViewComponent extends BaseListViewComponent<BrandListViewData, Brand> {
  public create = false;
  public reload$ = new Subject<void | boolean>();
  public producer_detail_route!: Link<ProducerBase>;
  public provider_detail_route!: Link<ProviderBase>;

  public default_fields = [
    'id',
    'type',
    'code',
    'name',
    'producer_details',
    'provider_details',
    'articles_count',
    'updated',
  ];

  public override setRouteData(data: BrandListViewData) {
    super.setRouteData(data);
    this.producer_detail_route = new Link<ProducerBase>(data.producer_detail_route, data, this._router);
    this.provider_detail_route = new Link<ProviderBase>(data.provider_detail_route, data, this._router);
  }
}
