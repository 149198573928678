
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Provider } from "./provider";

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends Collection<Provider> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/providers", Provider);
  }
}
