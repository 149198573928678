import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum OFFERPRODUCT_TYPE {
  LVADG_FL = 'LVF',
  LVADG_SEA = 'LVM',
  PROVIDER = 'PRV',
}

export class OfferProductBase extends DataModel {
  static override readonly __name: string = 'OfferProductBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'offer',
    description: 'ID Offre',
    related: 'Offer',
    priority: -1,
  })
  public offer!: number;

  @foreignKeyField({
    name: 'product',
    description: 'ID Produit',
    related: 'Product',
    priority: -1,
  })
  public product!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: OFFERPRODUCT_TYPE.LVADG_FL,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: OFFERPRODUCT_TYPE.LVADG_FL,
        desc: 'Produit lavieadugout - FL',
      },
      {
        value: OFFERPRODUCT_TYPE.LVADG_SEA,
        desc: 'Produit lavieadugout - Marée',
      },
      {
        value: OFFERPRODUCT_TYPE.PROVIDER,
        desc: 'Produit fournisseur',
      },
    ],
  })
  public type: OFFERPRODUCT_TYPE = OFFERPRODUCT_TYPE.LVADG_FL;

  @integerField({
    name: 'order',
    description: 'Ordre',
    defaultValue: 0,
    priority: 300,
  })
  public order: number = 0;

  @textField({
    name: 'comment',
    description: 'Commentaire',
    priority: 400,
  })
  public comment!: string;

  @integerField({ name: 'orig_id', description: 'orig id', priority: -400 })
  public orig_id!: number;
}
