import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Packaging } from '../packaging';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-packaging-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './packaging-manage.component.pug',
  styleUrls: ['./packaging-manage.component.sass'],
})
export class PackagingManageComponent implements OnInit {
  @Input() public packaging!: Packaging;

  constructor() {}

  ngOnInit(): void {}
}
