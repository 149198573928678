import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../../../../../models/structures/member/member';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { MemberManageComponent } from '../../../../../models/structures/member/member-manage/member-manage.component';
import { MEMBER_ROUTES } from '../menu';
import { MemberNavComponent } from '../_nav/member-nav.component';

export interface MemberEditViewParams extends BaseRouteParams {
  m_menu: MEMBER_ROUTES;
}

export interface MemberEditViewData extends MemberEditViewParams {
  member: Member;
}

@Component({
  selector: 'lvadg-member-edit-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, MemberManageComponent, MemberNavComponent],
  templateUrl: './member-edit-view.component.pug',
  styleUrls: ['./member-edit-view.component.sass'],
})
export class MemberEditViewComponent extends BaseDetailViewComponent<MemberEditViewData, Member> {
  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: MemberEditViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }
}
