import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Contact } from '../contact';
import { CONTACT_STATUSES, ContactBase } from '../contact.base';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-contact-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './contact-display.component.pug',
  styleUrls: ['./contact-display.component.sass'],
})
export class ContactDisplayComponent implements OnInit {
  public readonly CTS = CONTACT_STATUSES;
  @Input() public contact?: Contact | ContactBase;
  @Input() public mode: 'line' | 'button' = 'line';
  @Input() public detail_route?: Link<ContactBase>;
  @Input() public classes: string | string[] = '';

  constructor() {}

  ngOnInit(): void {}
}
