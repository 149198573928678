import { NewsBase } from './news.base';
import { detailsField } from '@solidev/data';
import { NewsCategory } from '../newscategory/newscategory';
import { Document } from '../../documents/document/document';
import { Folder } from '../../documents/folder/folder';
import { Image } from '../../documents/image/image';

export class News extends NewsBase {
  static override readonly __name: string = 'News';
  @detailsField({
    name: 'categories_details',
    description: 'Catégories',
    readonly: true,
    model: NewsCategory,
    many: true,
    priority: 750,
  })
  public categories_details: NewsCategory[] = [];

  @detailsField({
    name: 'document_details',
    description: 'Document lié (détails)',
    required: false,
    readonly: true,
    model: Document,
    priority: 500,
  })
  public document_details!: { id: number; title: string; slug: string; file: string; image_details: any };

  @detailsField({
    name: 'folder_details',
    description: 'Dossier lié (détails)',
    required: false,
    readonly: true,
    model: Folder,
    priority: 500,
  })
  public folder_details!: { id: number; title: string; slug: string; logo_img: any };

  @detailsField({
    description: 'Image',
    priority: 950,
    model: Image,
    readonly: true,
  })
  public logo_details?: Image;
}
