import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { TARIFTYPE_STATUS, TariftypeBase } from '../tariftype.base';
import { TariftypeService } from '../tariftype.service';

@Component({
  selector: 'lvadg-tariftype-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './tariftype-create.component.pug',
  styleUrls: ['./tariftype-create.component.sass'],
})
export class TariftypeCreateComponent implements OnInit {
  public tariftype!: TariftypeBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<TariftypeBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _tariftypes: TariftypeService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.tariftype = new TariftypeBase(this._tariftypes);
  }

  public async save() {
    this.tariftype.fromJson(
      {
        status: TARIFTYPE_STATUS.FUTUR,
        ...this.createForm.value,
      },
      { partial: true }
    );
    try {
      await firstValueFrom(this.tariftype.save({ updateModel: true }));
      this.created.emit(this.tariftype);
      this._msgs.success('Modèle de tarif créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
