import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestoBase } from '../resto.base';
import { Resto } from '../resto';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-resto-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './resto-display.component.pug',
  styleUrls: ['./resto-display.component.sass'],
})
export class RestoDisplayComponent implements OnInit {
  @Input() public resto?: RestoBase | Resto;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<RestoBase>;

  constructor() {}

  ngOnInit(): void {}
}
