import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';
import { TarifNotificationListComponent } from '../../../../../models/tarifs/tarif-notification/tarif-notification-list/tarif-notification-list.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeNotificationsViewParams extends BaseRouteParams {
  tarif_detail_route: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeNotificationsViewData extends TariftypeNotificationsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-notifications-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    TarifNotificationListComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-notifications-view.component.pug',
  styleUrls: ['./tariftype-notifications-view.component.sass'],
})
export class TariftypeNotificationsViewComponent extends BaseDetailViewComponent<
  TariftypeNotificationsViewData,
  Tariftype
> {
  public tn_default_fields = [
    'tariftype_details',
    'tarif_details',
    'created',
    'updated',
    'type',
    'priority',
    'title',
    'status',
  ];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public tarif_detail_route?: Link<TarifBase>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeNotificationsViewData) {
    super.setRouteData(data);
    if (this.tarif_detail_route) {
      this.tarif_detail_route = new Link(data.tarif_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }
}
