import { RoutesConfig } from '@solidev/data';
import { Contact, Group, RS, User } from '@vivalya/lvadg';

export const ADMIN_ACCESS_ROUTES_NAMES = [
  'access',
  'access_groups',
  'access_groups_group',
  'access_users',
  'access_users_user',
  'access_contacts',
  'access_contacts_contact',
] as const;

export const ADMIN_ACCESS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_ACCESS_ROUTES_NAMES)[number]> = {
  access: {
    params: [],
    route: () => ['/', RS.access],
    name: 'access',
  },
  access_groups: {
    params: [],
    route: () => ['/', RS.access, RS.groups],
    name: 'access_groups',
  },
  access_groups_group: {
    params: ['group'],
    route: (group: Group) => ['/', RS.access, RS.groups, group.id],
    name: 'access_groups_group',
  },
  access_users: {
    params: [],
    route: () => ['/', RS.access, RS.users],
    name: 'access_users',
  },
  access_users_user: {
    params: ['user'],
    route: (user: User) => ['/', RS.access, RS.users, user.id],
    name: 'access_users_user',
  },
  access_contacts: {
    params: [],
    route: () => ['/', RS.access, RS.contacts],
    name: 'access_contacts',
  },
  access_contacts_contact: {
    params: ['contact'],
    route: (contact: Contact) => ['/', RS.access, RS.contacts, contact.id],
    name: 'access_contacts_contact',
  },
};
