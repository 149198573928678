import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
} from '@solidev/data';

export enum AttStatus {
  ACTIVE = 'ACT',
  INACTIVE = 'NAC', // FIXME: to be deleted
  ARCHIVED = 'ARC',
  DELETED = 'DEL',
}

export class ArticleTarifTemplateBase extends DataModel {
  static override readonly __name: string = 'ArticleTarifTemplateBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
  })
  public tariftype!: number;

  @charField({
    name: 'name',
    description: 'Libellé',
    maxLength: 200,
  })
  public name!: string;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
  })
  public article!: number;

  @foreignKeyField({
    name: 'eaclient',
    description: 'ID Article externe',
    related: 'ExternalArticle',
  })
  public eaclient!: number;

  @dateField({
    name: 'datestart',
    description: 'Date de début',
  })
  public datestart!: Date;

  @dateField({
    name: 'dateend',
    description: 'Date de fin',
  })
  public dateend!: Date;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: AttStatus.ACTIVE,
    maxLength: 4,
    choices: [
      {
        value: AttStatus.ACTIVE,
        desc: 'Actif',
      },
      {
        value: AttStatus.INACTIVE,
        desc: 'Non actif - DEPRECATED',
      },
      {
        value: AttStatus.ARCHIVED,
        desc: 'Archivé',
      },
      {
        value: AttStatus.DELETED,
        desc: 'Supprimé',
      },
    ],
  })
  public status: AttStatus = AttStatus.ACTIVE;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
  })
  public orig_id!: number;

  @manyToManyField({
    name: 'eastorages',
    description: 'IDs Articles dépôts',
    defaultValue: [],
    related: 'ExternalArticle',
  })
  public eastorages: number[] = [];

  @manyToManyField({
    name: 'externals',
    description: 'IDs Articles externes',
    defaultValue: [],
    related: 'ExternalArticle',
  })
  public externals: number[] = [];

  public get _display(): string {
    return `${this.name} `;
  }
}
