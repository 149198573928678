import {
  booleanField,
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from "@solidev/data";
import { ContentType } from "../../structures/structure.base";
import { FAMILY_DISPLAY_MODE_CHOICES, FAMILY_DISPLAY_MODES } from "../common";

export enum FAMILY_TYPE {
  VIVALYA = "VVA",
  LVADG_FL = "LVF",
  LVADG_SEA = "LVM",
}

export enum FamilyStatus {
  OK = "OK",
  KO = "KO",
}

export class FamilyBase extends DataModel {
  static override readonly __name: string = "FamilyBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: "flags",
    description: "Flags",
    defaultValue: [],
    priority: 110,
  })
  public flags: string[] = [];

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: "parent",
    description: "ID Parent",
    related: "Family",
    priority: -1,
  })
  public parent!: number;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: FAMILY_TYPE.VIVALYA,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: FAMILY_TYPE.VIVALYA,
        desc: "Catalogue Vivalya",
      },
      {
        value: FAMILY_TYPE.LVADG_FL,
        desc: "Catalogue lvadg - F&L",
      },
      {
        value: FAMILY_TYPE.LVADG_SEA,
        desc: "Catalogue lvadg - Marée",
      },
    ],
    priority: 990,
  })
  public type: FAMILY_TYPE = FAMILY_TYPE.VIVALYA;

  @charField({
    name: "name",
    description: "Nom",
    maxLength: 200,
    priority: 980,
  })
  public name!: string;

  @charField({
    name: "cfullname",
    description: "Nom complet",
    maxLength: 200,
    priority: 970,
  })
  public cfullname!: string;

  @booleanField({
    name: "dispname",
    description: "Afficher ce niveau ?",
    defaultValue: true,
    required: true,
    priority: 500,
  })
  public dispname: boolean = true;

  @charField({
    name: "article_name",
    description: "Nom article par défaut",
    maxLength: 200,
    priority: 490,
  })
  public article_name!: string;

  @detailsField({
    name: "article_seasons",
    description: "Saisons par défaut",
    defaultValue: [],
    priority: 480,
  })
  public article_seasons: number[] = [];

  @textField({
    name: "article_description",
    description: "Description article",
    priority: 470,
  })
  public article_description!: string;

  @textField({
    name: "content_src",
    description: "Description (source)",
    required: false,
    priority: 500,
  })
  public content_src?: string;

  @textField({
    name: "content_html",
    description: "Description (html)",
    required: false,
    priority: -1,
  })
  public content_html?: string;

  @charField({
    name: "content_type",
    description: "Description (type)",
    required: false,
    choices: [
      { value: ContentType.TEXT, desc: "Texte brut" },
      { value: ContentType.MARKDOWN, desc: "Markdown" },
      { value: ContentType.HTML, desc: "HTML" },
    ],
    defaultValue: ContentType.MARKDOWN,
    priority: -1,
  })
  public content_type: ContentType = ContentType.MARKDOWN;

  @foreignKeyField({
    name: "logo",
    description: "ID Image",
    related: "Image",
    priority: -1,
  })
  public logo!: number;

  @charField({
    name: "status",
    description: "status",
    defaultValue: FamilyStatus.OK,
    required: true,
    maxLength: 4,
    choices: [
      {
        value: FamilyStatus.OK,
        desc: "OK, affichée",
      },
      {
        value: FamilyStatus.KO,
        desc: "Non affichée",
      },
    ],
    priority: 200,
  })
  public status: FamilyStatus = FamilyStatus.OK;

  @charField({
    name: "dmode",
    description: "Mode affichage",
    defaultValue: FAMILY_DISPLAY_MODES.UNKNOWN,
    maxLength: 5,
    choices: FAMILY_DISPLAY_MODE_CHOICES,
    priority: 600,
  })
  public dmode: FAMILY_DISPLAY_MODES = FAMILY_DISPLAY_MODES.UNKNOWN;

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -1,
  })
  public orig_id!: number;

  @manyToManyField({
    name: "images",
    description: "IDs images",
    defaultValue: [],
    related: "Image",
    priority: -1,
  })
  public images: number[] = [];

  @manyToManyField({
    name: "children",
    description: "IDS sous familles",
    defaultValue: [],
    related: "Family",
    priority: -1,
  })
  public children: number[] = [];

  @integerField({
    name: "tree_depth",
    description: "Niveau hiérarchie",
    readonly: true,
    priority: 520,
  })
  public tree_depth: number = 0;

  @detailsField({
    name: "tree_path",
    description: "Hiérarchie",
    readonly: true,
    priority: 800,
  })
  public tree_path: { name: string; id: number }[] = [];

  public get full_path(): string {
    return this.tree_path.map((p) => p.name).join(" : ");
  }

  public get _display(): string {
    return this.full_path;
  }

  public truncated_path(
    start: number = 0,
    end: number = 8,
    sep: string = " : ",
  ): string {
    let tp = this.tree_path
      .filter((v, i) => i >= start && i <= end)
      .map((p) => p.name);
    // Dedupe tp
    tp = tp.filter((v, i) => tp.indexOf(v) === i);
    return tp.join(sep);
  }
}
