import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Location } from '../location';
import { StructureBase } from '../../../structures/structure.base';
import { LocationService } from '../location.service';
import { combineLatest, first, firstValueFrom, Subject } from 'rxjs';
import { DataMessageService, DispeditComponent, FlagsComponent } from '@solidev/data';
import { MAPPING_BASE_STYLES, MAPPING_STYLES } from '../../mapping/constants';
import { MapContainer, MapService } from '../../mapping/services/map.service';
import { GeocommuneService } from '../../geocommune/geocommune.service';
import { GeoregionService } from '../../georegion/georegion.service';
import { GeodepartementService } from '../../geodepartement/geodepartement.service';
import { GeocountryService } from '../../geocountry/geocountry.service';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { StructureBaseAction } from '../../../structures/structure-base-action';
import { Marker } from 'maplibre-gl';

export interface StructureWithLocation extends StructureBase {
  location: number;
  location_details: Location;
}

@Component({
  selector: 'lvadg-location-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, FlagsComponent, IconComponent],
  templateUrl: './location-manage.component.pug',
  styleUrls: ['./location-manage.component.sass'],
})
export class LocationManageComponent<T extends StructureWithLocation> implements OnInit, OnDestroy {
  // One of those 3 infos MUST be given
  @Input() public location?: Location;
  @Input() public locationId?: number;
  @Input() public structure?: StructureWithLocation;
  @Input() public action?: StructureBaseAction<T>;

  // Current location subject
  public location$ = new Subject<Location>();

  // Map initial data
  public marker?: Marker;
  public markerPosition: [number, number] = [2.6390772508210603, 46.441826066304316];
  public markerMoved: boolean = false;
  public mapStyle: MAPPING_BASE_STYLES = MAPPING_BASE_STYLES.OSM_LIBERTY_EU;
  public mapZoom: number = 5.24;
  public mapHeight = 400;

  // Map graphic and logic container
  @ViewChild('map', { static: true })
  public mapContainer!: ElementRef<HTMLDivElement>;
  public map?: MapContainer;

  constructor(
    private _locs: LocationService,
    private _mps: MapService,
    private _msgs: DataMessageService,
    public communes$: GeocommuneService,
    public regions$: GeoregionService,
    public departements$: GeodepartementService,
    public countries$: GeocountryService,
  ) {}

  public async ngOnInit(): Promise<void> {
    // Get location from locationId / location or structure
    if (!this.location && this.structure?.location_details) {
      this.location = this.structure.location_details;
    }
    if (!this.locationId) {
      if (this.location) {
        this.locationId = this.location.id;
      } else if (this.structure?.location) {
        this.locationId = this.structure.location;
      }
    }
    // Create the map (unique)
    this.map = await this._mps.create(`locmanage-map-${this.locationId}`, this.mapContainer.nativeElement, {
      container: '',
      style: MAPPING_STYLES.get(this.mapStyle)
        ? MAPPING_STYLES.get(this.mapStyle)!.url
        : MAPPING_STYLES.get(MAPPING_BASE_STYLES.OSM_LIBERTY_EU)!.url, // stylesheet location
      center: this.markerPosition, // starting position [lng, lat]
      zoom: this.mapZoom, // starting zoom,
      minZoom: 3, // was 4.4
      attributionControl: false,
    });

    if (this.locationId) {
      this.refreshLocation();
    }
    combineLatest([this.map.map$, this.location$]).subscribe(async ([m, l]) => {
      console.log('Map, center', m, l);
      m.setCenter([l.lng, l.lat]);
      m.setZoom(11);
      m.resize();
      if (!this.marker) {
        this.marker = new Marker({ draggable: true }).setLngLat([l.lng, l.lat]).addTo(m);
        this.marker.on('dragend', () => {
          console.log('Dragget', this.marker!.getLngLat());
          this.markerMoved = true;
          this.markerPosition = this.marker!.getLngLat().toArray() as [number, number];
        });
      }
    });
  }

  public ngOnDestroy() {
    if (this.locationId) {
      this._mps.destroy(`locmanage-map-${this.locationId}`);
    }
  }

  public refreshLocation() {
    if (this.locationId) {
      this._locs
        .fetch(this.locationId)
        .pipe(first())
        .subscribe((l) => this.location$?.next(l));
    }
  }

  async updateGeolocation(loc: Location, force_ggmap: boolean = false) {
    if (this.action && this.structure) {
      // Normal mode
      const res = await this.action.action(this.structure as T, {
        action: 'fix_location',
        force_ggmap,
      });
      this.refreshLocation();
      this._msgs.success('Géolocalisation auto effectuée');
    } else {
      // Superuser - direct mode
      const res = await firstValueFrom(
        loc.action('POST', 'fix_position', {
          body: { force_ggmap },
        }),
      );
      this.refreshLocation();
      this._msgs.success('Géolocalisation auto effectuée');
    }
  }

  async geolocateMarker(loc: Location) {
    combineLatest([
      this.map!.map$,
      this._locs.action<{ rtype: string; result: any }>(null, 'POST', 'geolocate', {
        body: {
          address: loc.address,
          postcode: loc.postcode,
          city: loc.city,
          fuzzy: true,
          country: loc.country || 'FR',
        },
      }),
    ]).subscribe(([m, res]) => {
      if (res.result?.latitude && res.result?.longitude) {
        m.setCenter([res.result.longitude, res.result.latitude]);
        this.marker?.setLngLat([res.result.longitude, res.result.latitude]);
        this.markerPosition = [res.result.longitude, res.result.latitude];
        this.markerMoved = true;
      }
    });
  }

  async savePosition(loc: Location) {
    if (this.action && this.structure) {
      // Normal mode
      const res = await this.action.action(this.structure as T, {
        action: 'update_location_position',
        latitude: this.markerPosition[1],
        longitude: this.markerPosition[0],
      });
    } else {
      // Superuser / direct mode
      console.warn('This update mode is super-user only and should not be used');
      loc.lng = this.markerPosition[0];
      loc.lat = this.markerPosition[1];
      loc.position = `POINT(${loc.lng} ${loc.lat})`;
      await firstValueFrom(loc.update(['position']));
      this.markerMoved = false;
      this._msgs.success('Position mise à jour avec succès');
    }
  }
}
