import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offer } from '../offer';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../../users/auth.service';

@Component({
  selector: 'lvadg-offer-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './offer-display.component.pug',
  styleUrls: ['./offer-display.component.sass'],
})
export class OfferDisplayComponent {
  @Input() public offer?: Offer;
  @Input() public mode: 'line' | 'id' = 'line';
  @Input() public detail_route?: Link<Offer>;
  @Input() public classes: Record<string, boolean> = {};
  @Input() public showCodes: boolean;

  constructor() {
    const user = inject(AuthService).currentUser;
    this.showCodes = user?.is_superuser || user?.is_staff || false;
  }
}
