import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface OrderprocessarticleListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  orderprocess_detail_route: RouteConfigItem;
}

export interface OrderprocessarticleListViewData
  extends OrderprocessarticleListViewParams {}
