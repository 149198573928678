import {
  charField,
  detailsField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';
import { BaseFile } from '../basefile';

export class UploadBase extends BaseFile {
  static override readonly __name: string = 'UploadBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 20,
  })
  public code!: string;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 200,
  })
  public name!: string;

  @charField({
    name: 'type',
    description: 'Type',
    maxLength: 5,
  })
  public type!: string;

  @foreignKeyField({
    name: 'user',
    description: 'Utilisateur',
    related: 'User',
  })
  public user!: number;

  @detailsField({
    name: 'output',
    description: 'Sortie Json',
    defaultValue: {},
  })
  public output: any = {};
}
