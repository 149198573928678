
<div *ngIf="producer">
  <div class="card">
    <div class="card-header">
      <lvadg-icon ri="producer">Infos producteur</lvadg-icon>
    </div>
    <div class="card-body">
      <dl class="row border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="id" [editable]="false" mode="dd">ID</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="cvva" [editable]="true" mode="dd">Code Vivalya</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="name" [editable]="true" mode="dd">Nom</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="type" [editable]="true" mode="dd">Type</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="pubimage" [editable]="true" mode="dd">Droit à l'image ?</data-dispedit>
        <data-dispedit class="col-12" [model]="producer" field="content_src" [editable]="true" mode="dd">Description</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="status" [editable]="true" mode="dd">État</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="created" [editable]="false" mode="dd">Date de création</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="updated" [editable]="false" mode="dd">Date de MAJ</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="cvva_updated" [editable]="false" mode="dd">Date de MAJ vivalya</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="phone" [editable]="true" mode="dd">Téléphone</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="fax" [editable]="true" mode="dd">Fax</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="website" [editable]="true" mode="dd">Site internet</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="legal" [editable]="true" mode="dd">Type structure</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="capital" [editable]="true" mode="dd">Capital</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="siren" [editable]="true" mode="dd">SIREN</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="siret" [editable]="true" mode="dd">SIRET</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="tvaic" [editable]="true" mode="dd">TVA IC</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="producer" field="naf" [editable]="true" mode="dd">Code NAF</data-dispedit>
      </dl>
      <dl class="row mt-3">
        <div class="col-6">
          <dt class="d-flex flex-row justify-content-start"><span>Labels structure</span>
            <div class="form-check form-switch ms-3">
              <input class="form-check-input" id="allow_labels_edition" type="checkbox" #cbb (change)="allow_labels_edition.set(cbb.checked||false)">
              <label class="form-check-label" for="allow_labels_edition">Éditer les labels</label>
            </div>
          </dt>
          <dd>
            <lvadg-labels-manager class="col-12" [item]="producer" [type]="LBT.STRUCTURE" source="slabels" [save]="true" [catalog_only]="false" [mode]="allow_labels_edition()?'edit':'view'"></lvadg-labels-manager>
          </dd>
        </div>
        <div class="col-6">
          <dt>Labels produits (automatique)</dt>
          <dd>
            <lvadg-labels-manager class="col-12" [item]="producer" [type]="product_label_types" source="plabels" [catalog_only]="false" mode="view"></lvadg-labels-manager>
          </dd>
        </div>
        <data-flags class="col-6 col-lg-3" [model]="producer">Flags</data-flags>
      </dl>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header d-flex flex-row justify-content-start">
      <lvadg-icon ri="storage">Dépôts associés</lvadg-icon>
      <div class="form-check form-switch ms-3">
        <input class="form-check-input" id="allow_storages_edition" type="checkbox" #cbbs (change)="allow_storages_edition.set(cbbs.checked||false)">
        <label class="form-check-label" for="allow_storages_edition">Éditer les dépôts</label>
      </div>
    </div>
    <div class="card-body">
      <div class="text-danger" *ngIf="allow_storages_edition()">Édition activée</div>
      <lvadg-producer-storages-manage [producer]="producer" [editable]="allow_storages_edition()"></lvadg-producer-storages-manage>
    </div>
  </div>
  <div class="card mt-3" *ngIf="!producer.location_details">
    <div class="card-header">
      <lvadg-icon ri="location">Adresse et géolocalisation</lvadg-icon>
    </div>
    <div class="card-body">
      <ng-container>
        <div class="text-danger py-2">Aucune adresse renseignée, ajoutez une adresse pour pouvoir géolocaliser le producteur</div>
        <lvadg-location-create [structure]="producer" [action]="pact" [type]="LT.PRODUCER" (created)="producer.location_details = $event; producer.location = $event.id"></lvadg-location-create>
      </ng-container>
    </div>
  </div>
  <div class="card mt-3" *ngIf="producer.location_details">
    <div class="card-header">
      <lvadg-icon ri="location">Adresse et géolocalisation</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-location-manage [location]="producer.location_details" [structure]="$any(producer)" [action]="pact"></lvadg-location-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="image">Logo</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-logo-manage [model]="producer"></lvadg-logo-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="image">Images</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-gallery-manage [model]="producer"></lvadg-gallery-manage>
    </div>
  </div>
</div>