import { Component } from '@angular/core';

import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypePermission } from '../../../../models/tarifs/tariftype-permission/tariftype-permission';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { TariftypePermissionListComponent } from '../../../../models/tarifs/tariftype-permission/tariftype-permission-list/tariftype-permission-list.component';

export interface TariftypePermissionListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
}

export interface TariftypePermissionListViewData extends TariftypePermissionListViewParams {}

@Component({
  selector: 'lvadg-tariftype-permission-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TariftypePermissionListComponent],
  templateUrl: './tariftype-permission-list-view.component.pug',
  styleUrls: ['./tariftype-permission-list-view.component.sass'],
})
export class TariftypePermissionListViewComponent extends BaseListViewComponent<
  TariftypePermissionListViewData,
  TariftypePermission
> {
  public tariftype_detail_route!: Link<TariftypeBase>;
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public default_fields = [
    'id',
    'tariftype_details',
    'user_details',
    'group_details',
    'permission',
    'updated',
    'created',
  ];

  public override setRouteData(data: TariftypePermissionListViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, data);
    this.user_detail_route = new Link<UserBase>(data.user_detail_route, data);
    this.group_detail_route = new Link<GroupBase>(data.group_detail_route, data);
  }
}
