import { Component, inject, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProviderDocument } from "../provider-document";
import { Link } from "@solidev/data";
import { AuthService } from "../../../users/auth.service";
import { RouterLink } from "@angular/router";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";

@Component({
  selector: "lvadg-provider-document-display",
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    LabelDisplayComponent,
    ImageDisplayComponent,
  ],
  templateUrl: "./provider-document-display.component.pug",
  styleUrls: ["./provider-document-display.component.sass"],
})
export class ProviderDocumentDisplayComponent {
  @Input() public providerdocument!: ProviderDocument;
  @Input() public mode: "line" | "details" = "line";
  @Input() public detail_route?: Link<ProviderDocument>;
  @Input() public showCodes: boolean;

  constructor() {
    const user = inject(AuthService).currentUser;
    this.showCodes = user?.is_superuser || user?.is_staff || false;
  }

  // FIXME: use real lvadg.io download
  public download(doc: ProviderDocument) {
    window.open(doc.file, "_blank");
  }
}
