import { AtscacheBase } from './atscache.base';
import { detailsField } from '@solidev/data';
import { Tariftype } from '../../tariftype/tariftype';
import { ArticleBase } from '../../../catalog/article/article.base';

export class Atscache extends AtscacheBase {
  static override readonly __name: string = 'Atscache';

  @detailsField({
    model: Tariftype,
    readonly: true,
    description: 'Modèle de tarif',
  })
  public tariftype_details?: Tariftype;

  @detailsField({
    model: ArticleBase,
    readonly: true,
    description: 'Article',
  })
  public article_details?: ArticleBase;
}
