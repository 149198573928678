import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessBase } from '../process.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProcessService } from '../process.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-process-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './process-create.component.pug',
  styleUrls: ['./process-create.component.sass'],
})
export class ProcessCreateComponent implements OnInit {
  public process!: ProcessBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<ProcessBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _processes: ProcessService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.process = new ProcessBase(this._processes);
  }

  public async save() {
    this.process.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.process.save({ updateModel: true }));
      this.created.emit(this.process);
      this._msgs.success('Process créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
