import { Offertype } from '../../../../models/offers/offertype/offertype';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { OffertypeStorage } from '../../../../models/offers/offertype-storage/offertype-storage';

export interface OFFERTYPE_STORAGE_ROUTES {
  offertype_storage_detail_route?: RouteConfigItem;
  offertype_storage_edit_route?: RouteConfigItem;
  offertype_storage_permissions_route?: RouteConfigItem;
  offertype_storage_offers_route?: RouteConfigItem;
}

export interface OFFERTYPE_STORAGE_LINKS_ROUTES {
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
}

export interface OffertypeStorageViewParams extends BaseRouteParams {
  ots_menu: OFFERTYPE_STORAGE_ROUTES;
}

export interface OffertypeStorageViewData extends OffertypeStorageViewParams {
  offertypestorage: OffertypeStorage;
  offertype?: Offertype;
}
