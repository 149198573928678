import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { NewsCategory } from './newscategory';

@Injectable({
  providedIn: 'root',
})
export class NewscategoryService extends Collection<NewsCategory> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/newscategories', NewsCategory);
  }
}
