import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDefaults, Link } from '@solidev/data';
import { ReplaySubject, Subject } from 'rxjs';
import { TariftypeRelationListComponent } from '../../tariftype-relation/tariftype-relation-list/tariftype-relation-list.component';
import { TariftypeRelationCreateComponent } from '../../tariftype-relation/tariftype-relation-create/tariftype-relation-create.component';
import { Tariftype } from '../tariftype';
import { TariftypeRelationBase } from '../../tariftype-relation/tariftype-relation.base';

@Component({
  selector: 'lvadg-tariftype-links-manage',
  standalone: true,
  imports: [
    CommonModule,
    TariftypeRelationListComponent,
    TariftypeRelationCreateComponent,
  ],
  templateUrl: './tariftype-links-manage.component.pug',
  styleUrls: ['./tariftype-links-manage.component.sass'],
})
export class TariftypeLinksManageComponent implements OnInit, OnChanges {
  @Input() public tariftype?: Tariftype;
  @Input() public tariftyperelation_detail_route?: Link<TariftypeRelationBase>;
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  @Input() public mode: 'in' | 'out' | 'inout' = 'in';
  public srcFilter: ReplaySubject<FilterDefaults> =
    new ReplaySubject<FilterDefaults>(1);
  public destFilter: ReplaySubject<FilterDefaults> =
    new ReplaySubject<FilterDefaults>(1);
  public addInputRelation = false;
  public addOutputRelation = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();

  constructor() {}

  ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['tariftype']) {
      const tch = changes['tariftype'];
      if (tch.previousValue?.id !== tch.currentValue?.id) {
        this.srcFilter.next({ src: tch.currentValue.id });
        this.destFilter.next({ dest: tch.currentValue.id });
      }
    }
  }
}
