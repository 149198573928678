export enum OrigineGlob {
  FRANCE = 'FR',
  EUROPE = 'UE',
  IMPORT = 'IMP',
  NOTSET = '',
}

export const OriginGlobChoices = [
  {
    value: OrigineGlob.FRANCE,
    desc: 'France',
  },
  {
    value: OrigineGlob.EUROPE,
    desc: 'Europe',
  },
  {
    value: OrigineGlob.IMPORT,
    desc: 'Import',
  },
  {
    value: OrigineGlob.NOTSET,
    desc: 'Non précisé',
  },
];
