import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { TarifNotificationDestinationListComponent } from '../../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination-list/tarif-notification-destination-list.component';
import { ModelListAction } from '../../../../../includes/modellist/modellist.component';
import { TarifNotificationDestination } from '../../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination';
import { TariftypeActionService } from '../../../../../models/tarifs/tarif/tariftype-action.service';
import { UserBase } from '../../../../../models/users/user/user.base';
import { GroupBase } from '../../../../../models/users/group/group.base';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { TarifNotificationDestinationCreateComponent } from '../../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination-create/tarif-notification-destination-create.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeDestinationsViewParams extends BaseRouteParams {
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeDestinationsViewData extends TariftypeDestinationsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-destinations-view',
  standalone: true,
  imports: [
    CommonModule,
    TarifNotificationDestinationListComponent,
    HeaderActionComponent,
    IconComponent,
    TarifNotificationDestinationCreateComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-destinations-view.component.pug',
  styleUrls: ['./tariftype-destinations-view.component.sass'],
})
export class TariftypeDestinationsViewComponent extends BaseDetailViewComponent<
  TariftypeDestinationsViewData,
  Tariftype
> {
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public tndest_default_fields = ['id', 'notification', 'user_details', 'contact_details', 'group_details', 'actions'];
  public user_detail_route?: Link<UserBase>;
  public group_detail_route?: Link<GroupBase>;
  public createDestination = false;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    public ttAction: TariftypeActionService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeDestinationsViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }

  public async destinationAction(tt: Tariftype, act: ModelListAction<TarifNotificationDestination>) {
    if (act.action === 'remove' && act.model) {
      await this.ttAction.action(tt, {
        action: 'remove_destination',
        destination: act.model.id,
      });
      this.reload$.next(true);
    }
  }
}
