import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Frequency, Options, RRule, Weekday } from "rrule";
import { FRENCH_RRULE, GETTEXT_FRENCH_RRULE } from "./rrule-language";

interface DayOption {
  id: number;
  wd: Weekday;
  desc: string;
}

interface FormRrule {
  freq: FormControl<Frequency | null>;
  interval: FormControl<number | null>;
  byhour: FormControl<number[] | null>;
  byweekday: FormControl<DayOption[] | null>;
  bymonthday: FormControl<number[] | null>;
  dtstart: FormControl<string | null>;
  until: FormControl<string | null>;
  count: FormControl<number | null>;
}

interface FormRruleValue {
  freq: Frequency | null | undefined;
  interval: number | null | undefined;
  byhour: number[] | null | undefined;
  byweekday: DayOption[] | null | undefined;
  bymonthday: number[] | null | undefined;
  dtstart: string | null | undefined;
  until: string | null | undefined;
  count: number | null | undefined;
}

@Component({
  selector: "lvadg-rrule-editor",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: "./rrule-editor.component.pug",
  styleUrls: ["./rrule-editor.component.css"],
})
export class RruleEditorComponent implements OnInit {
  @Input() public rule?: string;
  @Input() public editable = true;
  @Output() public save: EventEmitter<string> = new EventEmitter<string>();

  public form?: FormGroup<FormRrule>;
  public FRENCH = FRENCH_RRULE;
  public computedDates!: Date[];
  public freqOptions = [
    { freq: RRule.DAILY, desc: "Jours(s)" },
    { freq: RRule.WEEKLY, desc: "Semaine(s)" },
    { freq: RRule.MONTHLY, desc: "Mois" },
    { freq: RRule.YEARLY, desc: "Année(s)" },
  ];
  public hourOptions = [
    { hour: 0, desc: "00:00" },
    { hour: 1, desc: "01:00" },
    { hour: 2, desc: "02:00" },
    { hour: 3, desc: "03:00" },
    { hour: 4, desc: "04:00" },
    { hour: 5, desc: "05:00" },
    { hour: 6, desc: "06:00" },
    { hour: 7, desc: "07:00" },
    { hour: 8, desc: "08:00" },
    { hour: 9, desc: "09:00" },
    { hour: 10, desc: "10:00" },
    { hour: 11, desc: "11:00" },
    { hour: 12, desc: "12:00" },
    { hour: 13, desc: "13:00" },
    { hour: 14, desc: "14:00" },
    { hour: 15, desc: "15:00" },
    { hour: 16, desc: "16:00" },
    { hour: 17, desc: "17:00" },
    { hour: 18, desc: "18:00" },
    { hour: 19, desc: "19:00" },
    { hour: 20, desc: "20:00" },
    { hour: 21, desc: "21:00" },
    { hour: 22, desc: "22:00" },
    { hour: 23, desc: "23:00" },
  ];
  public dayOptions: DayOption[] = [
    { id: 1, wd: RRule.MO, desc: "Les lundis" },
    { id: 2, wd: RRule.TU, desc: "Les mardis" },
    { id: 3, wd: RRule.WE, desc: "Les mercredis" },
    { id: 4, wd: RRule.TH, desc: "Les jeudis" },
    { id: 5, wd: RRule.FR, desc: "Les vendredis" },
    { id: 6, wd: RRule.SA, desc: "Les samedis" },
    { id: 7, wd: RRule.SU, desc: "Les dimanches" },

    { id: 11, wd: RRule.MO.nth(1), desc: "Le premier lundi" },
    { id: 12, wd: RRule.MO.nth(2), desc: "Le deuxième lundi" },
    { id: 13, wd: RRule.MO.nth(3), desc: "Le 3eme lundi" },
    { id: 14, wd: RRule.MO.nth(4), desc: "Le 4eme lundi" },
    { id: 15, wd: RRule.MO.nth(5), desc: "Le 5eme lundi" },
    { id: 16, wd: RRule.MO.nth(-1), desc: "Le dernier lundi" },
    { id: 17, wd: RRule.MO.nth(-2), desc: "L'avant dernier lundi" },

    { id: 21, wd: RRule.TU.nth(1), desc: "Le premier mardi" },
    { id: 22, wd: RRule.TU.nth(2), desc: "Le deuxième mardi" },
    { id: 23, wd: RRule.TU.nth(3), desc: "Le 3eme mardi" },
    { id: 24, wd: RRule.TU.nth(4), desc: "Le 4eme mardi" },
    { id: 25, wd: RRule.TU.nth(5), desc: "Le 5eme mardi" },
    { id: 26, wd: RRule.TU.nth(-1), desc: "Le dernier mardi" },
    { id: 27, wd: RRule.TU.nth(-2), desc: "L'avant dernier mardi" },

    { id: 31, wd: RRule.WE.nth(1), desc: "Le premier mercredi" },
    { id: 32, wd: RRule.WE.nth(2), desc: "Le deuxième mercredi" },
    { id: 33, wd: RRule.WE.nth(3), desc: "Le 3eme mercredi" },
    { id: 34, wd: RRule.WE.nth(4), desc: "Le 4eme mercredi" },
    { id: 35, wd: RRule.WE.nth(5), desc: "Le 5eme mercredi" },
    { id: 36, wd: RRule.WE.nth(-1), desc: "Le dernier mercredi" },
    { id: 37, wd: RRule.WE.nth(-2), desc: "L'avant dernier mercredi" },

    { id: 41, wd: RRule.TH.nth(1), desc: "Le premier jeudi" },
    { id: 42, wd: RRule.TH.nth(2), desc: "Le deuxième jeudi" },
    { id: 43, wd: RRule.TH.nth(3), desc: "Le 3eme jeudi" },
    { id: 44, wd: RRule.TH.nth(4), desc: "Le 4eme jeudi" },
    { id: 45, wd: RRule.TH.nth(5), desc: "Le 5eme jeudi" },
    { id: 46, wd: RRule.TH.nth(-1), desc: "Le dernier jeudi" },
    { id: 47, wd: RRule.TH.nth(-2), desc: "L'avant dernier jeudi" },

    { id: 51, wd: RRule.FR.nth(1), desc: "Le premier vendredi " },
    { id: 52, wd: RRule.FR.nth(2), desc: "Le deuxième vendredi" },
    { id: 53, wd: RRule.FR.nth(3), desc: "Le 3eme vendredi" },
    { id: 54, wd: RRule.FR.nth(4), desc: "Le 4eme vendredi" },
    { id: 55, wd: RRule.FR.nth(5), desc: "Le 5eme vendredi" },
    { id: 56, wd: RRule.FR.nth(-1), desc: "Le dernier vendredi" },
    { id: 57, wd: RRule.FR.nth(-2), desc: "L'avant dernier vendredi" },

    { id: 61, wd: RRule.SA.nth(1), desc: "Le premier vsamedi" },
    { id: 62, wd: RRule.SA.nth(2), desc: "Le deuxième samedi" },
    { id: 63, wd: RRule.SA.nth(3), desc: "Le 3eme samedi" },
    { id: 64, wd: RRule.SA.nth(4), desc: "Le 4eme samedi" },
    { id: 65, wd: RRule.SA.nth(5), desc: "Le 5eme samedi" },
    { id: 66, wd: RRule.SA.nth(-1), desc: "Le dernier samedi" },
    { id: 67, wd: RRule.SA.nth(-2), desc: "L'avant dernier samedi" },

    { id: 71, wd: RRule.SU.nth(1), desc: "Le premier dimanche" },
    { id: 72, wd: RRule.SU.nth(2), desc: "Le deuxième dimanche" },
    { id: 73, wd: RRule.SU.nth(3), desc: "Le 3eme dimanche" },
    { id: 74, wd: RRule.SU.nth(4), desc: "Le 4eme dimanche" },
    { id: 75, wd: RRule.SU.nth(5), desc: "Le 5eme dimanche" },
    { id: 76, wd: RRule.SU.nth(-1), desc: "Le dernier dimanche" },
    { id: 77, wd: RRule.SU.nth(-2), desc: "L'avant dernier dimanche" },
  ];
  public dateOptions: { day: number; desc: string }[] = [];
  public rrule!: RRule;
  public showDates = false;
  public edit: boolean = false;

  constructor() {}

  public defaultGetText = (id: string | number | Weekday): string => {
    const out = GETTEXT_FRENCH_RRULE[`${id}`];
    if (!out) {
      console.error("Missing translation for ", id);
      return id.toString();
    }
    return out;
  };

  ngOnInit(): void {
    if (this.rule) {
      this.rrule = RRule.fromString(this.rule);
    }
    this.form = new FormGroup({
      freq: new FormControl<Frequency | null>(null),
      interval: new FormControl<number | null>(null),
      byhour: new FormControl<number[] | null>(null),
      byweekday: new FormControl<DayOption[] | null>(null),
      bymonthday: new FormControl<number[] | null>(null),
      dtstart: new FormControl<string | null>(null),
      until: new FormControl<string | null>(null),
      count: new FormControl<number | null>(null),
    });
    this.dateOptions.push({ day: 0, desc: "Quelle que soit la date du jour" });
    for (let i = 1; i <= 31; i++) {
      this.dateOptions.push({ day: i, desc: `Uniquement le ${i} du mois` });
    }
    this.form.valueChanges.subscribe((value) => this.setRrule(value));
  }

  public setRrule(value: Partial<FormRruleValue>) {
    console.log("setRrule", value);
    const rropts: Partial<Options> = {};
    if (value.freq) {
      rropts.freq = value.freq;
    }
    if (value.interval) {
      rropts.interval = value.interval;
    }
    if (value.byhour && value.byhour.length > 0) {
      rropts.byhour = value.byhour;
    }
    if (value.dtstart) {
      rropts.dtstart = new Date(value.dtstart);
    }
    if (value.byweekday && value.byweekday.length > 0) {
      rropts.byweekday = value.byweekday.map((dayopt) => dayopt.wd);
    }
    if (value.bymonthday && value.bymonthday.length > 0) {
      if (value.bymonthday.every((day) => day !== 0)) {
        rropts.bymonthday = value.bymonthday as number[];
      } else {
        rropts.bymonthday = null;
      }
    }
    if (value.count && value.count > 0) {
      rropts.count = value.count;
    }
    if (value.until) {
      rropts.until = new Date(value.until);
    }

    this.rrule = new RRule(rropts, true);
    this.computedDates = this.rrule.all((date, i) => i < 100);
    for (const d of this.computedDates) {
      // SEE: check if this is needed
      const offset = d.getTimezoneOffset() * 60 * 1000;
      d.setTime(d.getTime() + offset);
    }
  }

  public showCalendar() {
    console.log("TODO");
  }

  public saveRule(): void {
    this.rule = this.rrule.toString();
    this.save.next(this.rule);
    this.edit = false;
  }

  public toggleEdit(): void {
    if (this.edit) {
      this.rrule = RRule.fromString(this.rule ?? "");
      this.edit = false;
    } else if (this.editable) {
      this.edit = true;
      if (this.rule) {
        this.rrule = RRule.fromString(this.rule);
      } else {
        this.rrule = RRule.fromString(
          "RRULE:FREQ=WEEKLY;INTERVAL=1;BYHOUR=0;BYDAY=MO,TU,WE,TH,FR;UNTIL=20251231T200000Z",
        );
      }

      let byweekday: DayOption[] = [];
      if (Array.isArray(this.rrule.origOptions.byweekday)) {
        byweekday = this.rrule.origOptions.byweekday
          .map((wd) => {
            if (wd instanceof Weekday) {
              const id = this.dayOptions.findIndex((d) => d.wd.equals(wd));
              return this.dayOptions[id];
            }
            return null;
          })
          .filter((wd) => wd !== null) as DayOption[];
      }
      this.form?.setValue({
        freq: this.rrule.options.freq,
        interval: this.rrule.options.interval,
        byhour: this.rrule.options.byhour,
        byweekday,
        bymonthday: this.rrule.options.bymonthday.length
          ? this.rrule.options.bymonthday
          : [0],
        dtstart: this.rrule.options.dtstart?.toISOString().slice(0, 16) || null,
        until: this.rrule.options.until?.toISOString().slice(0, 16) || null,
        count: this.rrule.options.count,
      });
    }
  }
}
