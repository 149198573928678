
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Genericregion } from "./genericregion";

@Injectable({
  providedIn: 'root'
})
export class GenericregionService extends Collection<Genericregion> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/genericregions", Genericregion);
  }
}
