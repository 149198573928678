import { Component, inject, model, signal } from "@angular/core";
import { Document } from "../../document/document";
import { DocumentActionService } from "../../document/document-action.service";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { FkselectComponent } from "@solidev/data";
import { FolderService } from "../../folder/folder.service";
import { DocumentService } from "../../document/document.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "lvadg-document-folder-listmanage",
  standalone: true,
  imports: [IconComponent, FkselectComponent],
  template: `
    <ul class="list-group list-group-flush">
      @for (df of document().documentfolders_details; track df.id) {
        <li class="list-group-item">
          <div class="d-flex justify-content-between align-content-center">
            <span>{{ df.folder_details.full_path }}</span>
            <span class="text-danger">
              <lvadg-icon
                ri="delete"
                role="button"
                [icon_only]="true"
                (click)="remove_folder(df.folder)"
              />
            </span>
          </div>
        </li>
      }
      <li
        class="list-group-item list-group-item-action list-group-item-primary"
        role="button"
        (click)="associate.set(true)"
      >
        <lvadg-icon ri="add">Ajouter à un dossier</lvadg-icon>
      </li>
      @if (associate()) {
        <li class="list-group-item">
          <data-fkselect
            [collection]="folders$"
            mode="autocomplete"
            label="Sélectionnez le dossier"
            (selected)="add_to_folder($event.id)"
          ></data-fkselect>
        </li>
      }
    </ul>
  `,
  styleUrl: "./document-folder-listmanage.component.sass",
})
export class DocumentFolderListmanageComponent {
  public document = model.required<Document>();
  public _action = inject(DocumentActionService);
  public folders$ = inject(FolderService);
  public document$ = inject(DocumentService);

  public associate = signal(false);

  public async add_to_folder(folder: number): Promise<void> {
    await this._action.action(this.document(), {
      action: "add_to_folder",
      folder_id: folder,
    });
    this.associate.set(false);
    const doc = await firstValueFrom(this.document$.fetch(this.document().id));
    this.document.set(doc);
  }

  public async remove_folder(folder: number): Promise<void> {
    await this._action.action(this.document(), {
      action: "remove_from_folder",
      folder_id: folder,
    });
    const doc = await firstValueFrom(this.document$.fetch(this.document().id));
    this.document.set(doc);
  }
}
