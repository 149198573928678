import { EamappingBase } from "./eamapping.base";
import { computedField, detailsField } from "@solidev/data";
import { Article } from "../article/article";
import { EarticleBase } from "../earticle/earticle.base";
import { Family } from "../family/family";
import { Label } from "../label/label";
import { TariftypeBase } from "../../tarifs/tariftype/tariftype.base";
import { Brand } from "../meta/brand/brand";

export class Eamapping extends EamappingBase {
  static override readonly __name: string = "Eamapping";

  @detailsField({
    model: TariftypeBase,
    description: "Modèle de tarif",
    readonly: true,
    priority: 900,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: Article,
    description: "Article catalogue",
    readonly: true,
    priority: 300,
  })
  public article_details?: Article;

  @detailsField({
    model: EarticleBase,
    description: "Article externe",
    readonly: true,
    priority: 600,
  })
  public earticle_details?: EarticleBase;

  @computedField({
    name: "article_family",
    description: "A. Famille",
    readonly: true,
    priority: 299,
  })
  public get article_family(): Family | undefined {
    return this.article_details?.family_details;
  }

  @computedField({
    name: "article_code",
    description: "A. Code",
    readonly: true,
    priority: 299,
  })
  public get article_code(): string | undefined {
    return this.article_details?.code;
  }

  @computedField({
    name: "article_calibre",
    description: "A. Calibre",
    readonly: true,
    priority: 299,
  })
  public get article_calibre(): string | undefined {
    return this.article_details?.calibre_details?.code;
  }

  @computedField({
    name: "article_categorie",
    description: "A. Catégorie",
    readonly: true,
    priority: 299,
  })
  public get article_categorie(): string | undefined {
    return this.article_details?.categorie_details?.code;
  }

  @computedField({
    name: "article_libelle",
    description: "A. Libellé",
    readonly: true,
    priority: 299,
  })
  public get article_libelle(): string | undefined {
    return this.article_details?.libelle;
  }

  @computedField({
    name: "article_uci",
    description: "A. UCI",
    readonly: true,
    priority: 299,
  })
  public get article_uci(): string | undefined {
    return this.article_details?.uci;
  }

  @computedField({
    name: "article_colisage",
    description: "A. Colisage",
    readonly: true,
    priority: 299,
  })
  public get article_colisage(): string | undefined {
    return this.article_details?.colisage;
  }

  @computedField({
    name: "article_uf",
    description: "A. UF",
    readonly: true,
    priority: 299,
  })
  public get article_uf(): string | undefined {
    return this.article_details?.udf_unit;
  }

  @computedField({
    name: "article_local",
    description: "A. Local",
    readonly: true,
    priority: 299,
  })
  public get article_local(): string | undefined {
    return this.article_details?.local;
  }

  @computedField({
    name: "article_labels",
    description: "A. Labels",
    readonly: true,
    priority: 299,
  })
  public get article_labels(): Label[] | undefined {
    return this.article_details?.egalim_details;
  }

  @computedField({
    name: "article_marque",
    description: "A. Marque",
    readonly: true,
    priority: 299,
  })
  public get article_marque(): Brand | undefined {
    return this.article_details?.marque_details;
  }

  @computedField({
    name: "article_origine",
    description: "A. Origine",
    readonly: true,
    priority: 299,
  })
  public get article_origine(): string | undefined {
    return this.article_details?.origine_details?.code;
  }

  @computedField({
    name: "article_decondi_mode",
    description: "A. Mode décondi",
    readonly: true,
    priority: 299,
  })
  public get article_decondi_mode(): string | undefined {
    return this.article_details?.decondi_mode;
  }

  @computedField({
    name: "earticle_code",
    description: "A.E. Code",
    readonly: true,
    priority: 500,
  })
  public get earticle_code(): string | undefined {
    return this.earticle_details?.code;
  }

  @computedField({
    name: "earticle_icode",
    description: "A.E. Code 2",
    readonly: true,
    priority: 500,
  })
  public get earticle_icode(): string | undefined {
    return this.earticle_details?.icode;
  }

  @computedField({
    name: "earticle_gamme",
    description: "A.E. Classification 1",
    readonly: true,
    priority: 500,
  })
  public get earticle_gamme(): string | undefined {
    return this.earticle_details?.gamme;
  }

  @computedField({
    name: "earticle_sgamme",
    description: "A.E. Classification 2",
    readonly: true,
    priority: 500,
  })
  public get earticle_sgamme(): string | undefined {
    return this.earticle_details?.sgamme;
  }

  @computedField({
    name: "earticle_libelle",
    description: "A.E. Libellé",
    readonly: true,
    priority: 500,
  })
  public get earticle_libelle(): string | undefined {
    return this.earticle_details?.libelle;
  }

  @computedField({
    name: "earticle_libelle2",
    description: "A.E. Libellé 2",
    readonly: true,
    priority: 500,
  })
  public get earticle_libelle2(): string | undefined {
    return this.earticle_details?.libelle2;
  }

  @computedField({
    name: "earticle_categorie",
    description: "A.E. Catégorie",
    readonly: true,
    priority: 500,
  })
  public get earticle_categorie(): string | undefined {
    return this.earticle_details?.categorie;
  }

  @computedField({
    name: "earticle_calibre",
    description: "A.E. Calibre",
    readonly: true,
    priority: 500,
  })
  public get earticle_calibre(): string | undefined {
    return this.earticle_details?.calibre;
  }

  @computedField({
    name: "earticle_origine",
    description: "A.E. Origine",
    readonly: true,
    priority: 500,
  })
  public get earticle_origine(): string | undefined {
    return this.earticle_details?.origine;
  }

  @computedField({
    name: "earticle_labels",
    description: "A.E. Labels",
    readonly: true,
    priority: 500,
  })
  public get earticle_labels(): string | undefined {
    return this.earticle_details?.labels;
  }

  @computedField({
    name: "earticle_uf",
    description: "A.E. UF",
    readonly: true,
    priority: 500,
  })
  public get earticle_uf(): string | undefined {
    return (
      (this.earticle_details?.uf ? `${this.earticle_details?.uf}` : "") +
      (this.earticle_details?.uf_unit
        ? ` U:${this.earticle_details?.uf_unit}`
        : "") +
      (this.earticle_details?.uf_value
        ? ` V:${this.earticle_details?.uf_value}`
        : "")
    );
  }

  @computedField({
    name: "earticle_uc",
    description: "A.E. U. Commande",
    readonly: true,
    priority: 500,
  })
  public get earticle_uc(): string | undefined {
    return (
      (this.earticle_details?.uc ? `${this.earticle_details?.uc}` : "") +
      (this.earticle_details?.uc_unit
        ? ` U:${this.earticle_details?.uc_unit}`
        : "") +
      (this.earticle_details?.uc_value
        ? ` V:${this.earticle_details?.uc_value}`
        : "")
    );
  }

  @computedField({
    name: "earticle_ucuf",
    description: "A.E. UCUF",
    readonly: true,
    priority: 500,
  })
  public get earticle_ucuf(): string | undefined {
    return this.earticle_details?.ucuf;
  }

  @computedField({
    name: "earticle_ucond",
    description: "A.E. U. Cond. (toutes)",
    readonly: true,
    priority: 500,
  })
  public get earticle_ucond(): string | undefined {
    return (
      (this.earticle_details?.ucond ? `${this.earticle_details?.ucond}` : "") +
      (this.earticle_details?.ucond_unit
        ? ` U:${this.earticle_details?.ucond_unit}`
        : "") +
      (this.earticle_details?.ucond_value
        ? ` V:${this.earticle_details?.ucond_value}`
        : "")
    );
  }

  @computedField({
    name: "earticle_ucond_string",
    description: "A.E. U. Cond.",
    readonly: true,
    priority: 500,
  })
  public get earticle_ucond_string(): string | undefined {
    return this.earticle_details?.ucond;
  }

  @computedField({
    name: "earticle_ucond_unit",
    description: "A.E. U. Cond. Unit",
    readonly: true,
    priority: 500,
  })
  public get earticle_ucond_unit(): string | undefined {
    return this.earticle_details?.ucond_unit;
  }

  @computedField({
    name: "earticle_ucond_value",
    description: "A.E. U. Cond. Value",
    readonly: true,
    priority: 500,
  })
  public get earticle_ucond_value(): string | undefined {
    return this.earticle_details?.ucond_value;
  }

  @computedField({
    name: "earticle_nego_unit",
    description: "A.E. U. Nego",
    readonly: true,
    priority: 500,
  })
  public get earticle_nego_unit(): string | undefined {
    return this.earticle_details?.nego_unit;
  }

  @computedField({
    name: "earticle_marque",
    description: "A.E. Marque",
    readonly: true,
    priority: 500,
  })
  public get earticle_marque(): string | undefined {
    return this.earticle_details?.marque;
  }
}
