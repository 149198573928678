import { Injectable } from "@angular/core";
import { MapData, MapLayerType } from "../constants";
import {
  type LayerSpecification,
  LngLatBounds,
  type SymbolLayerSpecification,
} from "maplibre-gl";
import { LocationBase } from "../../location/location.base";

interface GeoJsonBounded {
  bounds: LngLatBounds;
  geojson: {
    type: "geojson";
    data: GeoJSON.FeatureCollection;
  };
}

@Injectable({
  providedIn: "root",
})
export class MapLayerService {
  constructor() {}

  public makeGeoJson(data: MapData, type: MapLayerType): GeoJsonBounded {
    const geo: GeoJsonBounded = {
      bounds: new LngLatBounds(),
      geojson: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
    };
    if (data[type]) {
      for (const d of data[type]!) {
        let id: number = -1;
        let name: string = "";
        const location: LocationBase | undefined = d.location_details;
        id = d.id;
        name = d.name || "";
        if (!location || !location.lat || !location.lng) {
          console.log("No location for data", d);
          continue;
        }
        geo.bounds.extend([location.lng, location.lat]);
        geo.geojson.data.features.push({
          type: "Feature",
          properties: {
            map_type: type,
            id: id,
            name: name,
          },
          geometry: {
            type: "Point",
            coordinates: [location.lng, location.lat],
          },
        });
      }
    }
    return geo;
  }

  public getSymbolLayer(l: MapLayerType | string): LayerSpecification {
    return {
      id: l,
      source: l,
      type: "symbol",
      layout: {
        "icon-image": ["concat", "vvya-", ["get", "map_type"]],
        "icon-ignore-placement": true,
        "icon-allow-overlap": true,
        "text-ignore-placement": true,
        "text-allow-overlap": true,
        "icon-size": 1,
        "icon-anchor": "bottom",
        visibility: "visible", // this.layerDisplayStatus[l] !== false ? "visible" : "none"
      } as SymbolLayerSpecification["layout"],
      paint: {
        "text-color": "rgba(0,0,0,1)",
      } as SymbolLayerSpecification["paint"],
    };
  }

  public getSymbolTextLayer(l: MapLayerType | string): LayerSpecification {
    return {
      id: l + "-text",
      source: l,
      type: "symbol",
      minzoom: 5,
      layout: {
        "text-field": ["get", "name"],
        "text-anchor": "top",
        "text-size": 11,
        visibility: "visible", // this.displayNames && this.layerDisplayStatus[l] !== false ? "visible" : "none"
      } as SymbolLayerSpecification["layout"],
      paint: {
        "text-color": "rgba(0,0,0,1)",
      } as SymbolLayerSpecification["paint"],
    };
  }
}
