import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface OrderprocessstoragePermissionListViewParams
  extends BaseRouteParams {
  detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  orderprocess_detail_route: RouteConfigItem;
  orderprocessstorage_detail_route: RouteConfigItem;
}

export interface OrderprocessstoragePermissionListViewData
  extends OrderprocessstoragePermissionListViewParams {}
