import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { ArticleTarif } from "../article-tarif";
import { ArticleTarifService } from "../article-tarif.service";
import {
  FieldsParams,
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  PFactorcPipe,
  PFactorPipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";

import { NoteDispeditComponent } from "../../../../components/utils/note-dispedit/note-dispedit.component";
import { ArticleDisplayComponent } from "../../../catalog/article/article-display/article-display.component";
import { EarticleDisplayComponent } from "../../../catalog/earticle/earticle-display/earticle-display.component";
import { ArticleTarifPriceDispeditComponent } from "../article-tarif-price-dispedit/article-tarif-price-dispedit.component";
import { Tarif } from "../../tarif/tarif";
import { TarifBase } from "../../tarif/tarif.base";
import {
  Editor,
  EditorCollection,
} from "../article-tarif-price-dispedit/editor";
import { FamilyDisplayComponent } from "../../../catalog/family/family-display/family-display.component";
import { ArticleTarifPriceHistoryComponent } from "../article-tarif-price-history/article-tarif-price-history.component";
import { ArticleBase } from "../../../catalog/article/article.base";
import { FamilyBase } from "../../../catalog/family/family.base";
import { EarticleBase } from "../../../catalog/earticle/earticle.base";
import { ArticleTarifColumns } from "./article-tarif-columns";
import { ArticleTarifPriceRelatedComponent } from "../article-tarif-price-related/article-tarif-price-related.component";
import {
  TarifActionResult,
  TarifActionService,
} from "../../tarif/tarif-action.service";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { start } from "@popperjs/core";
import { ArticleTarifStorageColumnOperationsComponent } from "./article-tarif-storage-column-operations/article-tarif-storage-column-operations.component";
import {
  TARIFTYPE_PRICE_TYPE,
  TariftypeBase,
} from "../../tariftype/tariftype.base";
import { TariftypeDisplayComponent } from "../../tariftype/tariftype-display/tariftype-display.component";
import { TarifDisplayComponent } from "../../tarif/tarif-display/tarif-display.component";
import { ArticleTarifRegionColumnOperationsComponent } from "./article-tarif-region-column-operations/article-tarif-region-column-operations.component";

@Component({
  selector: "lvadg-article-tarif-list",
  standalone: true,
  templateUrl: "./article-tarif-list.component.pug",
  styleUrls: ["./article-tarif-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    ArticleDisplayComponent,
    FamilyDisplayComponent,
    EarticleDisplayComponent,
    ArticleTarifPriceDispeditComponent,
    ArticleTarifPriceHistoryComponent,
    ArticleTarifPriceRelatedComponent,
    ArticleTarifStorageColumnOperationsComponent,
    ArticleTarifRegionColumnOperationsComponent,
    TariftypeDisplayComponent,
    TarifDisplayComponent,
    LabelDisplayComponent,
    NoteDispeditComponent,
    PFactorcPipe,
    PFactorPipe,
    PFactorPipe,
    PChoicePipe,
  ],
})
export class ArticleTarifListComponent extends ModellistComponent<ArticleTarif> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public family_detail_route?: Link<FamilyBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;
  @Input() public tarif?: Tarif;
  @Input() public readonly?: boolean = false;

  public editPriceFn = this._editPrice.bind(this);
  public editors!: EditorCollection;
  public columns!: ArticleTarifColumns;

  public messageDisplay: "icon" | "text" = "icon";
  public priceHistoryDisplay: "price" | "percent" | "both" = "percent";
  public familyDispStart = 0;
  public familyDispEnd = 5;
  public showFamily: boolean = false;

  public PT = TARIFTYPE_PRICE_TYPE;

  constructor(
    coll: ArticleTarifService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _tas: TarifActionService,
  ) {
    super(coll, list, ofc);
  }

  public override preNgOnInit() {
    super.preNgOnInit();
    console.log("Creating columns", this.tarif);
    this.columns = new ArticleTarifColumns(this.tarif);
    this.editors = new EditorCollection();
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "displaystatus"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "displaystatus",
          name: "displaystatus",
          label: "Affichage",
          default: "active",
          choices: [
            {
              desc: "Tous (actifs avec/sans prix, inactifs)",
              value: "all",
            },
            {
              desc: "Actifs (avec/sans prix) uniquement",
              value: "active",
            },
            {
              desc: "Inactifs uniquement",
              value: "inactive",
            },
            {
              desc: "Actifs avec prix uniquement",
              value: "activewithprice",
            },
            {
              desc: "Actifs sans prix uniquement",
              value: "activewithoutprice",
            },
          ],
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const fields = super.getFields();
    //- Set common custom fields
    fields.custom?.push({
      name: "activation",
      label: "Activation",
      priority: 400,
    });
    fields.custom?.push({
      name: "price_history_1",
      label: "T1",
      priority: 250,
    });
    fields.custom?.push({
      name: "price_history_2",
      label: "T2",
      priority: 249,
    });
    fields.custom?.push({
      name: "price_history_3",
      label: "T3",
      priority: 248,
    });
    fields.custom?.push({
      name: "price_history_4",
      label: "T4",
      priority: 247,
    });
    fields.custom?.push(...this.columns.storage_columns);
    fields.custom?.push(...this.columns.region_columns);
    fields.custom?.push(...this.columns.reference_columns);
    fields.custom?.push(...this.columns.related_columns);
    fields.custom?.push(...this.columns.decondi_columns);

    //- Set additionnal default fields
    fields.defaults?.push(
      ...this.columns.related_columns
        .filter((c) => c.default)
        .map((c) => c.name),
    );
    fields.defaults?.push(
      ...this.columns.storage_columns
        .filter((c) => c.default)
        .map((c) => c.name),
    );
    fields.defaults?.push(
      ...this.columns.region_columns
        .filter((c) => c.default)
        .map((c) => c.name),
    );
    return fields;
  }

  public async toggleStatus(row: ArticleTarif) {
    if (!this.tarif) {
      return;
    }
    const res = await this._tas.action(this.tarif, {
      action: "toggle_article",
      articletarif: row.id,
    });
    if (res.success) {
      this.reload?.next(true);
    }
  }

  public tooglePriceHistory() {
    const vals: ("price" | "percent" | "both")[] = ["price", "percent", "both"];
    this.priceHistoryDisplay =
      vals[(vals.indexOf(this.priceHistoryDisplay) + 1) % 3];
  }

  public onSelect(row: ArticleTarif) {
    this.selected = row;
  }

  public override getRowClasses(row: ArticleTarif): string[] {
    const cls = super.getRowClasses(row);
    if (row.in_op === 1) {
      cls.push("table-success");
    }
    if (this.selected && row.id === this.selected.id) {
      cls.push("table-active");
    }
    return cls;
  }

  public override getTableClasses(): string[] {
    return ["table-sm"];
  }

  public familyDispUpdate(pos: "start" | "end", inc: number) {
    if (pos == start) {
      this.familyDispStart += inc;
      if (this.familyDispStart < 0) {
        this.familyDispStart = 0;
      }
      if (this.familyDispStart > this.familyDispEnd) {
        this.familyDispStart = this.familyDispEnd;
      }
    } else {
      this.familyDispEnd += inc;
      if (this.familyDispEnd > 5) {
        this.familyDispStart = 5;
      }
      if (this.familyDispStart > this.familyDispEnd) {
        this.familyDispEnd = this.familyDispStart;
      }
    }
  }

  private async _editPrice(
    editor: Editor,
    value: number | string | null | undefined,
    quantity: number | null | undefined,
  ): Promise<TarifActionResult> {
    if (!this.tarif) {
      return { message: "Aucun tarif", success: false };
    }
    const res = await this._tas.action(this.tarif, {
      action: "set_price",
      price: value,
      quantity: quantity,
      ...editor.action,
    });
    if (res.success) {
      this.reload?.next(true);
    }
    return res;
  }
}
