import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutData, LayoutTree } from '../../layout/layout';
import { TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from '../../layout/template';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lvadg-layout-template-provider-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './layout-template-provider-select.component.pug',
  styleUrls: ['./layout-template-provider-select.component.sass'],
})
export class LayoutTemplateProviderSelectComponent implements OnInit {
  @Input() public tree!: LayoutTree;
  @Input() public layout!: LayoutData;
  @Input() public fragment!: TemplateFragment;
  @Input() public param!: TemplateParam;
  @Input() public provider!: TemplateProvider;

  public fc = new FormControl<TemplateChoice | null>(null);
  public chid = Math.random().toString(36);

  constructor() {
    this.fc.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      if (!value) {
        console.error('No choice selected');
        return;
      }
      if (this.layout.template?.name) {
         

        this.tree.set_fragment_param({
          layout: this.layout,
          group: this.layout.group,
          fragment: this.fragment,
          param: this.param,
          provider: this.provider,
          choice: value,
        });
      }
    });
  }

  public ngOnInit() {
    // Check if a choice is selected
    if (this.provider) {
      for (const choice of this.provider.choices) {
        if (choice.current) {
          this.fc.setValue(choice, { emitEvent: false });
          return;
        }
      }
    }
  }
}
