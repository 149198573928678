import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { ArticleSimilarity } from './article-similarity';

@Injectable({
  providedIn: 'root',
})
export class ArticleSimilarityService extends Collection<ArticleSimilarity> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/articlesimilarities', ArticleSimilarity);
  }
}
