import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DATA_AUTH_SERVICE } from '@solidev/data';
import { AuthService } from '../../models/users/auth.service';

export enum ERROR_MODE {
  unknown = 'unknown',
  resnotfound = 'resnotfound',
  apinotavailable = 'apinotavailable',
  apierror = 'apierror',
  forbidden = 'forbidden',
}

@Component({
  selector: 'lvadg-generic-error-view',
  templateUrl: './generic-error-view.component.pug',
  styleUrls: ['./generic-error-view.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class GenericErrorViewComponent implements OnInit {
  public mode!: Observable<ERROR_MODE>;
  public url?: string;
  public ERROR_MODE = ERROR_MODE;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(DATA_AUTH_SERVICE) public auth: AuthService
  ) {}

  public ngOnInit() {
    this.url = this._router.url;
    this.mode = this._route.fragment.pipe(
      map((f) => {
        if (f === null) {
          return ERROR_MODE.unknown;
        }
        return f as ERROR_MODE;
      })
    );
  }
}
