import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent } from '@solidev/data';
import { FolderPermission } from '../folder-permission';
import { firstValueFrom } from 'rxjs';
import { FolderPermissionService } from '../folder-permission.service';
import { Folder } from '../../folder/folder';
import { UserService } from '../../../users/user/user.service';
import { GroupService } from '../../../users/group/group.service';

@Component({
  selector: 'lvadg-folder-permission-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './folder-permission-create.component.pug',
  styleUrls: ['./folder-permission-create.component.sass'],
})
export class FolderPermissionCreateComponent implements OnInit {
  @Input() public folder?: Folder;
  @Output() public created = new EventEmitter<FolderPermission>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: FolderPermission;

  constructor(
    private _items: FolderPermissionService,
    public user$: UserService,
    public group$: GroupService
  ) {}

  ngOnInit(): void {
    this.item = new FolderPermission(this._items);
  }

  public async create() {
    if (this.folder) {
      this.item.target = this.folder.id;
    }
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }
}
