
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="resto">
  <li class="nav-item" *ngIf="resto_display_route"><a class="nav-link" [routerLink]="resto_display_route.route({resto: resto})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Résumé</a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="resto_edit_route &amp;&amp; resto.have_level(RUL.admin)"><a class="nav-link" [routerLink]="resto_edit_route.route({resto: resto})" routerLinkActive="active">Édition</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="resto_offers_route &amp;&amp; resto.have_level(RUL.admin)"><a class="nav-link" [routerLink]="resto_offers_route.route({resto: resto})" routerLinkActive="active">Offres</a></li>
  <!-- Relations-->
  <li class="nav-item" *ngIf="resto_relations_route &amp;&amp; resto.have_level(RUL.admin)"><a class="nav-link" [routerLink]="resto_relations_route.route({resto: resto})" routerLinkActive="active">Relations</a></li>
  <!-- Sales-->
  <li class="nav-item" *ngIf="resto_sales_route &amp;&amp; resto.have_level(RUL.admin)"><a class="nav-link" [routerLink]="resto_sales_route.route({resto: resto})" routerLinkActive="active">Ventes</a>
    <!-- Users-->
  </li>
  <li class="nav-item" *ngIf="resto_users_route &amp;&amp; resto.have_level(RUL.admin)"><a class="nav-link" [routerLink]="resto_users_route.route({resto: resto})" routerLinkActive="active">Utilisateurs</a></li>
</ul>