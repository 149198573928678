import { detailsField } from '@solidev/data';
import { PrintBase } from './print.base';
import { UserBase } from '../../users/public_api';

export class Print extends PrintBase {
  static override readonly __name: string = 'Print';

  // Owner details
  @detailsField({
    name: 'owner_details',
    description: 'Détails du propriétaire',
    priority: 600,
  })
  public owner_details?: UserBase;
}
