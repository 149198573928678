
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OffertypeStorage } from "./offertype-storage";

@Injectable({
  providedIn: 'root'
})
export class OffertypeStorageService extends Collection<OffertypeStorage> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offertypestorages", OffertypeStorage);
  }
}
