import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Treatment } from '../../../../../models/catalog/meta/treatment/treatment';

export interface TreatmentDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface TreatmentDetailViewData extends TreatmentDetailViewParams {
  treatment: Treatment;
}
