
<ng-container *ngIf="display==='card'">
  <div class="card-header">
    <lvadg-icon ri="folder"><span class="fw-bold">{{title}}</span></lvadg-icon>
  </div>
  <div class="max-height-200">
    <nav class="list-group list-group-flush">
      <ng-container *ngFor="let f of folders$|async"><a class="list-group-item list-group-item-action" [routerLink]="detail_route?.route({folder: f})">
          <lvadg-icon ri="folder">{{f.truncated_path(1)}}</lvadg-icon></a></ng-container>
    </nav>
  </div>
</ng-container>