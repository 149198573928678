import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { Subject } from 'rxjs';
import { lifecycleNav, TarifLifecycleAction } from '../../../lifecycle-actions';
import { TarifLifecycleActionService } from '../../../tarif-lifecycle-action.service';
import {
  ComposerMessage,
  TarifMessageComposerComponent,
} from '../../messages/tarif-message-composer/tarif-message-composer.component';
import {
  TarifMessageContacts,
  TarifMessageContactsComponent,
} from '../../messages/tarif-message-contacts/tarif-message-contacts.component';
import { TARIF_ROUTES } from '../../../../../../../views/tarifs/tarifs/detail/menu';
import { Router } from '@angular/router';
import { Tariftype } from '../../../../../tariftype/tariftype';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-negosend',
  standalone: true,
  imports: [CommonModule, TarifMessageComposerComponent, TarifMessageContactsComponent],
  templateUrl: './tarif-lifecycle-step-negosend.component.pug',
  styleUrls: ['./tarif-lifecycle-step-negosend.component.sass'],
})
export class TarifLifecycleStepNegosendComponent {
  @Input() public tarif!: Tarif;
  @Input() public tariftype!: Tariftype;
  @Input() public step!: TarifLifecycleStep;
  @Input() public steps!: TarifLifecycleStep[];
  @Input() public menu?: TARIF_ROUTES;
  @Input() public reload$!: Subject<void | boolean>;
  public running: Record<string, boolean> = {};

  public messageData: ComposerMessage | null = null;
  public contactData: TarifMessageContacts | null = null;

  constructor(
    public taction: TarifLifecycleActionService,
    private router: Router,
  ) {}

  public async action(action: TarifLifecycleAction) {
    if (action.action === 'nav') {
      await lifecycleNav(action.dest, this.menu, this.router, this.tarif, this.tariftype);
    } else {
      this.running[action.action] = true;
      const res = await this.taction.action(this.tarif, action);
      this.reload$.next(true);
      this.running[action.action] = false;
    }
  }

  public setMessage($event: ComposerMessage | null) {
    this.messageData = $event;
  }

  public setContacts($event: TarifMessageContacts | null) {
    this.contactData = $event;
  }
}
