import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OfferDocument } from '../offer-document';
import { OfferDocumentService } from '../offer-document.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { NgMathPipesModule } from 'ngx-pipes';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { Resto } from '../../../structures/resto/resto';
import { Client } from '../../../structures/client/client';
import { Offer } from '../../offer/offer';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { OfferDisplayComponent } from '../../offer/offer-display/offer-display.component';
import { OFFERDOCUMENT_STATUSES } from '../offer-document.base';

@Component({
  selector: 'lvadg-offer-document-list',
  standalone: true,
  templateUrl: './offer-document-list.component.pug',
  styleUrls: ['./offer-document-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    NgMathPipesModule,
    PChoicePipe,
    ImageDisplayComponent,
    FlagsDisplayComponent,
    RestoDisplayComponent,
    OfferDisplayComponent,
  ],
})
export class OfferDocumentListComponent extends ModellistComponent<OfferDocument> {
  @Input() public resto_detail_route?: Link<Resto>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public offer_detail_route?: Link<Offer>;

  constructor(coll: OfferDocumentService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  public override getRowClasses(row: OfferDocument): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === OFFERDOCUMENT_STATUSES.FUTUR) {
      cls.push('table-warning');
    }
    if (row.status === OFFERDOCUMENT_STATUSES.PRINTING) {
      cls.push('table-info');
    }
    if (row.status === OFFERDOCUMENT_STATUSES.INVALID) {
      cls.push('table-danger');
    }
    if (row.status === OFFERDOCUMENT_STATUSES.VALID) {
      cls.push('text-success');
    }
    return cls;
  }
}
