import { Component } from '@angular/core';
import { Calibre } from '../calibre';
import { CalibreService } from '../calibre.service';
import { FiltersParams, ModelListService, ModelListTextFilter, ModelListTreeFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../../includes/modellist/modellist.imports';
import { ModellistComponent } from '../../../../../includes/modellist/modellist.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FamilyDisplayComponent } from '../../../family/family-display/family-display.component';
import { Family } from '../../../family/family';
import { FAMILY_TYPE } from '../../../family/family.base';
import { FamilyService } from '../../../family/family.service';

@Component({
  selector: 'lvadg-calibre-list',
  standalone: true,
  templateUrl: './calibre-list.component.pug',
  styleUrls: ['./calibre-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, FamilyDisplayComponent, PChoicePipe],
})
export class CalibreListComponent extends ModellistComponent<Calibre> {
  constructor(
    coll: CalibreService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _fs: FamilyService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'in_family'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListTreeFilter<Family>({
          field: 'in_family',
          name: 'in_family',
          label: 'Recherche par famille',
          collection: this._fs,
          help: '---------------------',
          filter: {
            fields: ['id', 'name', 'parent'].join(','),
            type: FAMILY_TYPE.VIVALYA,
          },
          display: (v) => `${v.name}`,
        }),
      ],
    };
  }
}
