import { News } from '../news';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '../../../documents/document/document.service';
import { FolderService } from '../../../documents/folder/folder.service';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { DispeditComponent, RicheditComponent } from '@solidev/data';
import { LogoManageComponent } from '../../../documents/image/logo-manage/logo-manage.component';
import { CommonModule } from '@angular/common';
import { NewsService } from '../news.service';
import { firstValueFrom } from 'rxjs';
import { NewscategoryService } from '../../newscategory/newscategory.service';

@Component({
  standalone: true,
  selector: 'lvadg-news-manage',
  templateUrl: './news-manage.component.pug',
  styleUrls: ['./news-manage.component.sass'],
  imports: [CommonModule, IconComponent, DispeditComponent, LogoManageComponent, RicheditComponent],
})
export class NewsManageComponent implements OnInit {
  @Input() public news!: News;
  public destinations = [
    { name: 'Adhérents', value: 'member' },
    { name: 'Clients', value: 'client' },
    { name: 'Restos', value: 'resto' },
  ];
  public edit = {};

  constructor(
    public documents$: DocumentService,
    public folders$: FolderService,
    public category$: NewscategoryService,
    public news$: NewsService,
  ) {}

  public haveDest(p: { name: string; value: string }): boolean {
    return this.news.dest.indexOf(p.value) !== -1;
  }

  public async setUnsetDest(p: { name: string; value: string }): Promise<void> {
    const pos = this.news.dest.indexOf(p.value);

    if (pos !== -1) {
      this.news.dest.splice(pos, 1);
    } else {
      this.news.dest.push(p.value);
    }
    await firstValueFrom(this.news.update(['dest']).pipe());
  }

  public ngOnInit(): void {}
}
