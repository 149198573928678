import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum RELATION_TYPE {
  STORAGE_CLIENT = 'SC',
  STORAGE_RESTO = 'SR',
  MEMBER_CLIENT = 'MC',
  MEMBER_RESTO = 'MR',
  PRODUCER_STORAGE = 'PS',
  PROVIDER_PRODUCER = 'WP',
  PRODUCER_RESTO = 'PR',
}

export enum RELATION_STATUS {
  ACTIVE = 'OK',
  INACTIVE = 'KO',
  ACTIVE_MANUAL = 'OM',
  INACTIVE_MANUAL = 'KM',
}

export class RelationBase extends DataModel {
  static override readonly __name: string = 'RelationBase';

  @primaryField({ name: 'id', description: 'ID', priority: 1000 })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 75,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Resto',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @foreignKeyField({
    name: 'producer',
    description: 'ID Producteur',
    related: 'Producer',
    priority: -1,
  })
  public producer!: number;

  @foreignKeyField({
    name: 'provider',
    description: 'ID Fournisseur',
    related: 'Provider',
    priority: -1,
  })
  public provider!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -1,
  })
  public storage!: number;

  @foreignKeyField({
    name: 'member',
    description: 'ID Adhérent',
    related: 'Member',
    priority: -1,
  })
  public member!: number;

  @charField({
    name: 'type',
    description: 'Type',
    maxLength: 4,
    choices: [
      { value: RELATION_TYPE.STORAGE_CLIENT, desc: 'Dépôt → Client' },
      { value: RELATION_TYPE.STORAGE_RESTO, desc: 'Dépôt → Resto' },
      { value: RELATION_TYPE.MEMBER_CLIENT, desc: 'Adhérent → Client' },
      { value: RELATION_TYPE.MEMBER_RESTO, desc: 'Adhérent → Resto' },
      { value: RELATION_TYPE.PRODUCER_STORAGE, desc: 'Producteur → Dépôt' },
      {
        value: RELATION_TYPE.PROVIDER_PRODUCER,
        desc: 'Fournisseur → Producteur',
      },
      { value: RELATION_TYPE.PRODUCER_RESTO, desc: 'Producteur → Resto' },
    ],
    priority: 900,
  })
  public type!: RELATION_TYPE;

  @foreignKeyField({
    name: 'distance',
    description: 'ID Distance',
    related: 'Distance',
    priority: -1,
  })
  public distance!: number;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: RELATION_STATUS.ACTIVE,
    maxLength: 4,
    choices: [
      { value: RELATION_STATUS.ACTIVE, desc: 'Actif' },
      { value: RELATION_STATUS.INACTIVE, desc: 'Inactif' },
      { value: RELATION_STATUS.ACTIVE_MANUAL, desc: 'Actif (manuel)' },
      { value: RELATION_STATUS.INACTIVE_MANUAL, desc: 'Inactif (manuel)' },
    ],
    priority: 100,
  })
  public status: RELATION_STATUS = RELATION_STATUS.ACTIVE;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;
}
