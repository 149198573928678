import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Category } from '../category';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-category-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './category-manage.component.pug',
  styleUrls: ['./category-manage.component.sass'],
})
export class CategoryManageComponent {
  @Input() public category!: Category;

  constructor() {}
}
