export class Toc {
  public id: number;
  public title: string;
  public path: string;
  public children: Toc[] = [];
  public parents: Toc[];
  private _cmap: Map<number, Toc[]>;

  constructor(data: any, cmap: Map<number, Toc[]>) {
    this.id = data.id;
    this._parent = data.parent;
    this.title = data.title;
    this.path = data.path;
    this._cmap = cmap;
    this.parents = [];
  }

  private _parent: number;

  public get parent(): number {
    return this._parent === null ? 0 : this._parent;
  }

  public get fullpath(): string {
    return this.parents
      .map((p) => p.path)
      .filter((p) => !!p)
      .join('/');
  }
}
