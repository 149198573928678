import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { PrintBase } from '../print.base';
import { LayoutService } from '../../layout/layout.service';
import { WsService } from '../../../../components/live/ws.service';
import { DataMessageService } from '@solidev/data';

@Component({
  selector: 'lvadg-launch-print-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './launch-print-button.component.pug',
  styleUrls: ['./launch-print-button.component.sass'],
})
export class LaunchPrintButtonComponent implements OnDestroy {
  @Input() public print!: PrintBase;
  @Input() public mode: 'header' | 'list' = 'header';
  @Input() public sync = false;
  public printing = false;
  public printed: string | null = null;
  private _unsubscribe = new Subject<void>();

  constructor(
    private _prints: LayoutService,
    private _ws: WsService,
    private _msgs: DataMessageService,
  ) {}

  public ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public async to_pdf() {
    this.printing = true;
    const channel = `print_feedback_${this.print.id}`;
    this._ws.progress(channel, 'global', 100, `Impression : ${this.print.name}`);
    if (this.sync) {
      try {
        await firstValueFrom(this._prints.pdf$(this.print.id, true));
        this._msgs.success('Impression terminée');
      } catch (e) {
        this._msgs.error("Erreur lors de l'impression", "Une erreur est survenue lors de l'impression", e);
      }

      this.printing = false;
      this._ws.unprogress(`print_feedback_${this.print.id}`);
    } else {
      await firstValueFrom(this._prints.pdf$(this.print.id, false));
      this._ws
        .progress$('global', channel)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((msgs) => {
          if (msgs.length === 0) {
            return false;
          }
          const msg = msgs[msgs.length - 1];
          console.log('Received message', msg);
          if (msg.done) {
            this.printing = false;
            if (msg.link) {
              this.printed = msg.link;
              this._ws.unprogress(channel);
              this._unsubscribe.next();
            }
          }
          return false;
        });
    }
  }

  public download_pdf() {
    if (this.printed) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = this.printed;
      a.download = `print-${this.print.id}.pdf`;
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }
    return true;
  }
}
