import {
  Component,
  ElementRef,
  EventEmitter,
  input,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DataMessageService,
  DataModel,
  FilterDefaults,
  ModelList,
} from "@solidev/data";
import { Observable } from "rxjs";
import { Selection } from "../../../../components/selection/selection.service";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";

@Component({
  selector: "lvadg-modellist-map-button",
  standalone: true,
  imports: [CommonModule, TodoComponent, IconComponent],
  templateUrl: "./modellist-map-button.component.pug",
  styleUrl: "./modellist-map-button.component.sass",
})
export class ModellistMapButtonComponent<T extends DataModel> {
  @Input({ required: true }) public list!: ModelList<T>;
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults>;
  @Input({ required: true }) public selection?: Selection<T>;
  public mode = input<"none" | "local" | "usermap">("local");
  public displayed = false;

  @Output() public localMapStatus = new EventEmitter<boolean>();

  @ViewChild("ofcslot", { static: true }) ofcTemplate!: ElementRef<HTMLElement>;
  public instance?: NgbOffcanvasRef;

  constructor(
    private _ofc: NgbOffcanvas,
    private _msgs: DataMessageService,
  ) {}

  public clickIcon() {
    if (this.mode() === "usermap") {
      this.instance = this._ofc.open(this.ofcTemplate, { position: "bottom" });
    } else {
      this.displayed = !this.displayed;
      this.localMapStatus.emit(this.displayed);
    }
  }
}
