import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ADMIN_GROUPS, AuthService, LVADG_GROUPS, META_GROUPS, SPECIAL_GROUPS } from '@vivalya/lvadg';
import { DataMessageService } from '@solidev/data';

export class IsInGroupGuard {
  static forGroups(...groups: (META_GROUPS | LVADG_GROUPS | SPECIAL_GROUPS | ADMIN_GROUPS)[]) {
    @Injectable({
      providedIn: 'root',
    })
    class IsInGroupGuardCheck {
      constructor(
        private _auth: AuthService,
        private _msgs: DataMessageService,
        private router: Router,
      ) {}

      canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
      }

      canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
      }

      canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
      }

      canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
      }

      private _check() {
        return this._auth.currentUser$.pipe(
          map((u) => {
            if (u === null) {
              return false;
            }
            if (groups.includes(SPECIAL_GROUPS.superuser) && u.is_superuser) {
              return true;
            }
            if (groups.includes(SPECIAL_GROUPS.staff) && u.is_staff) {
              return true;
            }
            if (u.current_groups_slugs) {
              if (groups.includes(SPECIAL_GROUPS.all)) {
                return u.current_groups_slugs.some((g) => g.startsWith('meta:') || g.startsWith('lvadg:'));
              }
              for (const g of groups) {
                if (u.current_groups_slugs.includes(`${g}`)) {
                  return true;
                }
              }
            }
            return false;
          }),
        );
      }
    }

    return IsInGroupGuardCheck;
  }
}
