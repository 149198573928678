import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LayoutService} from "../layout/layout.service";
import {LayoutTree} from "../layout/layout";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  tap,
} from "rxjs";
import {CdkTreeModule} from "@angular/cdk/tree";
import {
  LayoutTemplateEditorComponent
} from "../layout-template-editor/layout-template-editor.component";
import {
  LayoutProvidersEditorComponent
} from "../layout-providers-editor/layout-providers-editor.component";
import {
  LayoutRenderPreviewComponent
} from "../layout-render-preview/layout-render-preview.component";
import {PrintBase} from "../print/print.base";
import {WsService} from "../../../components/live/ws.service";
import {DataMessageService, DispeditComponent} from "@solidev/data";
import {PrintService} from "../print/print.service";
import {NgbNavModule, NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {UrlParamValue} from "../rstypes/UrlParamValue";
import {IconComponent} from "../../../components/utils/icon/icon.component";

/**
 * Layout tree editor.
 */
@Component({
  selector: "lvadg-layout-editor",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    CdkTreeModule,
    DispeditComponent,
    LayoutTemplateEditorComponent,
    LayoutProvidersEditorComponent,
    LayoutRenderPreviewComponent,
    NgbPopoverModule,
    NgbNavModule,
  ],
  templateUrl: "./layout-editor.component.pug",
  styleUrls: ["./layout-editor.component.sass"],
})
export class LayoutEditorComponent implements OnInit {
  @Input({ required: true }) public print$!: Observable<PrintBase>;
  @Input() public printParams$: Observable<Partial<UrlParamValue>> = of({});
  public print!: PrintBase;
  public tree$ = new ReplaySubject<LayoutTree | null>(1);
  public reload$ = new BehaviorSubject<boolean>(true);
  public current$!: Observable<boolean | null>;

  constructor(
    private _layouts: LayoutService,
    private _prints: PrintService,
    private _ws: WsService,
    private _msgs: DataMessageService,
  ) {}

  public ngOnInit(): void {
    this.current$ = combineLatest([this._prints.current$, this.print$]).pipe(
      map(([c, p]) => c && p && c.id === p.id),
    );
    combineLatest([this.print$, this.reload$, this.printParams$])
      .pipe(
        tap(() => this.tree$.next(null)),
        switchMap(([p, , params]) => {
          this.print = p;
          return this._layouts.load$(p, params);
        }),
        filter((f) => !!f),
      )
      .subscribe((print) => this.tree$.next(print!));
  }

  public reload(): void {
    this.reload$.next(true);
  }

  updatedPrint() {
    this._prints.fetchAvailable();
  }

  public async setCurrent(print: PrintBase): Promise<void> {
    await this._prints.setCurrent(print);
    this.reload();
  }

  refresh() {
    this._layouts
      .reset$(this.print)
      .subscribe((print) => this.tree$.next(print!));
  }
}
