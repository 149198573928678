
<ng-container *ngIf="data$|async; let data">
  <ng-container *ngIf="data.offertypestorage &amp;&amp; data.ots_menu">
    <lvadg-offertype-storage-nav [offertypestorage]="data.offertypestorage" [offertype]="data.offertype" [routes]="data.ots_menu"></lvadg-offertype-storage-nav>
    <lvadg-header-action [title]="data.offertypestorage._display" subtitle="Gestion des offres" level="h1" ri="offer">
      <ng-container actions>
        <button class="btn btn-outline-primary" (click)="create=!create" *ngIf="canCreate()">
          <lvadg-icon ri="plus">Créer une offre</lvadg-icon>
        </button>
      </ng-container>
    </lvadg-header-action>
  </ng-container>
  <ng-container *ngIf="data.offertype &amp;&amp; data.ot_menu &amp;&amp; !data.offertypestorage">
    <lvadg-offertype-nav [offertype]="data.offertype" [routes]="data.ot_menu"></lvadg-offertype-nav>
    <lvadg-header-action [title]="data.offertype._display" subtitle="Gestion des offres" level="h1" ri="offer">
      <ng-container actions>
        <button class="btn btn-outline-primary" (click)="create=!create" *ngIf="canCreate()">
          <lvadg-icon ri="plus">Créer une offre</lvadg-icon>
        </button>
      </ng-container>
    </lvadg-header-action>
  </ng-container>
  <ng-container *ngIf="!data.offertype &amp;&amp; !data.offertypestorage">
    <lvadg-header-action [title]="data.page_title || 'Gestion des offres'" level="h1" ri="offer" [subtitle]="data.page_subtitle || 'OFFRES LIBRES'">
      <ng-container actions>
        <button class="btn btn-outline-primary" (click)="create=!create" *ngIf="canCreate()">
          <lvadg-icon ri="plus">Créer une offre</lvadg-icon>
        </button>
      </ng-container>
    </lvadg-header-action>
  </ng-container>
  <div class="card" *ngIf="create">
    <div class="card-header">
      <lvadg-icon ri="plus">Créer une offre</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-offer-create [offertype]="data.offertypestorage?.offertype_details" [offertypestorage]="data.offertypestorage" [zone]="data.zone" [type]="data.type" (created)="detail_route.navigate({offer: $event}); create=false" (cancelled)="create=false"></lvadg-offer-create>
    </div>
  </div>
  <lvadg-offer-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [filter]="filter$" [user_detail_route]="user_detail_route" [storage_detail_route]="storage_detail_route" [client_detail_route]="client_detail_route" [resto_detail_route]="resto_detail_route" [offertype_detail_route]="offertype_detail_route" [offertypestorage_detail_route]="offertypestorage_detail_route" [offertypestorage_offer_detail_route]="offertypestorage_offer_detail_route"></lvadg-offer-list>
</ng-container>