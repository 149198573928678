import { Component } from '@angular/core';
import { GeocommuneListViewData } from './geocommune-list-view.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { GeocommuneListComponent } from '../../../../models/locations/geocommune/geocommune-list/geocommune-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Geocommune } from '../../../../models/locations/geocommune/geocommune';

@Component({
  selector: 'lvadg-geocommune-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, GeocommuneListComponent],
  templateUrl: './geocommune-list-view.component.pug',
  styleUrls: ['./geocommune-list-view.component.sass'],
})
export class GeocommuneListViewComponent extends BaseListViewComponent<GeocommuneListViewData, Geocommune> {
  public override setRouteData(data: GeocommuneListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
