
<ul class="nav-tabs nav-justified" ngbNav #nav="ngbNav" [(activeId)]="mode">
  <li [ngbNavItem]="'at'" *ngIf="enabledAtMode">
    <button ngbNavLink>Tarifs</button>
    <ng-template ngbNavContent>
      <h3>Associer une ligne des tarifs associés</h3>
      <lvadg-article-tarif-list [filter]="{offerproduct: offerproduct.id}" [default_fields]="article_tarif_fields" [readonly]="true" [displayMode]="'ml-top'" [reload]="reload$" [actions]="['selectOp']" (action)="onTarifSelect($event)"></lvadg-article-tarif-list>
    </ng-template>
  </li>
  <li [ngbNavItem]="'att'" *ngIf="enabledAttMode">
    <button ngbNavLink>Articles de tarif</button>
    <ng-template ngbNavContent>
      <h3>Associer un article des modèles de tarifs associés</h3>
      <lvadg-article-tarif-template-list [filter]="{offerproduct: offerproduct.id}" [default_fields]="article_tarif_template_fields" [displayMode]="'ml-top'" [reload]="reload$" [actions]="['selectOp']" (action)="onTarifTemplateSelect($event)"></lvadg-article-tarif-template-list>
    </ng-template>
  </li>
  <li [ngbNavItem]="'article'" *ngIf="enabledArticleMode">
    <button ngbNavLink>Articles catalogue</button>
    <ng-template ngbNavContent>
      <h3>Associer un article du catalogue</h3>
      <lvadg-article-list [filter]="{offerproduct: offerproduct.id}" [default_fields]="article_fields" [reload]="reload$" [displayMode]="'ml-top'" [actions]="['selectOp']" (action)="onArticleSelect($event)"></lvadg-article-list>
    </ng-template>
  </li>
</ul>
<div class="mt-2" [ngbNavOutlet]="nav"></div>