import { inject, Injectable } from "@angular/core";
import { ProductService } from "./product.service";
import { FilterDefaults } from "@solidev/data";
import { firstValueFrom } from "rxjs";

export enum PRODUCT_STAT_TYPES {
  COUNT = "count",
  BY_FAMILY = "by_family",
  BY_LABEL = "by_label",
}

export interface CountStats {
  count: number;
}

export interface ByFamilyStat {
  family: string;
  family_id: number;
  count: number;
}

export interface ByLabelStat {
  label: string;
  label_id: number;
  count: number;
}

export interface StatsResponse {
  count?: CountStats;
  by_family?: ByFamilyStat[];
  by_label?: ByLabelStat[];
}

@Injectable({
  providedIn: "root",
})
export class ProductStatsService {
  private _products = inject(ProductService);

  public get_stats(stats: PRODUCT_STAT_TYPES[], filter?: FilterDefaults) {
    return firstValueFrom(
      this._products.action<StatsResponse>(null, "GET", "stats", {
        params: {
          stats: stats.join(","),
          ...filter,
        },
      }),
    );
  }
}
