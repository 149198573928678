import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RRule, Weekday } from 'rrule';
import { FRENCH_RRULE, GETTEXT_FRENCH_RRULE } from '../rrule-editor/rrule-language';

@Component({
  selector: 'lvadg-rrule-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rrule-display.component.pug',
  styleUrls: ['./rrule-display.component.sass'],
})
export class RruleDisplayComponent implements OnInit {
  @Input() public rule?: string;
  public rrule?: RRule;
  public FRENCH = FRENCH_RRULE;

  public ngOnInit() {
    if (this.rule) {
      this.rrule = RRule.fromString(this.rule);
    }
  }

  public defaultGetText = (id: string | number | Weekday): string => {
    const out = GETTEXT_FRENCH_RRULE[`${id}`];
    if (!out) {
      console.error('Missing translation for ', id);
      return id.toString();
    }
    return out;
  };
}
