
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { OrderprocessarticleService} from "./orderprocessarticle.service";
import { Orderprocessarticle } from "./orderprocessarticle";

@Injectable({
  providedIn: 'root'
})
export class OrderprocessarticleResolver  {
  constructor(private _rs: OrderprocessarticleService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Orderprocessarticle> {
    return this._rs.fetch(+route.params["orderprocessarticleId"])
  }
}
