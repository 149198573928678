
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Rnmmarche } from "./rnmmarche";

@Injectable({
  providedIn: 'root'
})
export class RnmmarcheService extends Collection<Rnmmarche> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/rnmmarches", Rnmmarche);
  }
}
