import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum IOCODE_STATUSES {
  VALID = 'OK',
  OFFLINE = 'KO',
}

export enum IOCODE_TYPES {
  REDIRECT = 'RD',
  REVERSE_PROXY = 'RP',
  PUBLIC_URL = 'PU',
  DOWNLOAD = 'DL',
  DATA = 'DT',
}

export interface IocodeDetails {
  code: string;
  iourl: string;
  ioqr: string;
}

export class IocodeBase extends DataModel {
  static override readonly __name: string = 'IocodeBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 50,
  })
  public updated!: Date;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: IOCODE_TYPES.REDIRECT,
    maxLength: 2,
    priority: 900,
    choices: [
      { value: IOCODE_TYPES.REDIRECT, desc: 'Redirection' },
      { value: IOCODE_TYPES.REVERSE_PROXY, desc: 'Reverse proxy' },
      { value: IOCODE_TYPES.PUBLIC_URL, desc: 'Page publique' },
      {
        value: IOCODE_TYPES.DOWNLOAD,
        desc: 'Download',
      },
      { value: IOCODE_TYPES.DATA, desc: 'Data' },
    ],
  })
  public type: IOCODE_TYPES = IOCODE_TYPES.REDIRECT;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 10,
    priority: 800,
  })
  public code!: string;

  @charField({
    name: 'url',
    description: 'Url',
    maxLength: 2048,
    priority: 700,
  })
  public url!: string;

  @detailsField({
    name: 'data',
    description: 'Data',
    priority: 600,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public data!: any;

  @charField({
    name: 'mimetype',
    description: 'Type fichier',
    maxLength: 250,
    priority: -1,
  })
  public mimetype!: string;

  @charField({
    name: 'filename',
    description: 'Nom fichier',
    maxLength: 255,
    priority: -1,
  })
  public filename!: string;

  @integerField({
    name: 'filesize',
    description: 'Taille fichier',
    defaultValue: 0,
    priority: -1,
  })
  public filesize: number = 0;

  @datetimeField({
    name: 'expire',
    description: 'Expiration',
    priority: 500,
  })
  public expire!: Date;

  @integerField({
    name: 'ttl',
    description: 'TTL',
    defaultValue: 172800,
    priority: 400,
  })
  public ttl: number = 172800;

  @foreignKeyField({
    name: 'user',
    description: 'ID Créateur',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @integerField({
    name: 'count',
    description: 'Nb clics',
    defaultValue: 0,
    priority: 200,
  })
  public count: number = 0;

  @datetimeField({
    name: 'last_hit',
    description: 'Dernier acces',
    priority: 210,
  })
  public last_hit?: Date;

  @charField({
    name: 'status',
    description: 'Etat',
    defaultValue: IOCODE_STATUSES.VALID,
    maxLength: 4,
    choices: [
      { value: IOCODE_STATUSES.VALID, desc: 'En ligne' },
      { value: IOCODE_STATUSES.OFFLINE, desc: 'Hors ligne' },
    ],
    priority: 150,
  })
  public status: IOCODE_STATUSES = IOCODE_STATUSES.VALID;
}
