
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OrderprocessPermission } from "./orderprocess-permission";

@Injectable({
  providedIn: 'root'
})
export class OrderprocessPermissionService extends Collection<OrderprocessPermission> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/orderprocesspermissions", OrderprocessPermission);
  }
}
