
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-offertype-text-type-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offertype-text-type-display.component.pug',
  styleUrls: ['./offertype-text-type-display.component.sass']
})
export class OffertypeTextTypeDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
