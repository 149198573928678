
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ioqrcode } from '../ioqrcode';
@Component({
  selector: 'lvadg-ioqrcode-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ioqrcode-manage.component.pug',
  styleUrls: ['./ioqrcode-manage.component.sass']
})
export class IoqrcodeManageComponent implements OnInit {
  @Input() public ioqrcode?: Ioqrcode;

  constructor() { }

  ngOnInit(): void {
  }

}
