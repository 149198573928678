import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TarifBase } from '../../../../models/tarifs/tarif/tarif.base';
import { TarifDocument } from '../../../../models/tarifs/tarif-document/tarif-document';
import { TarifDocumentListComponent } from '../../../../models/tarifs/tarif-document/tarif-document-list/tarif-document-list.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';

export interface TarifDocumentListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
}

export interface TarifDocumentListViewData extends TarifDocumentListViewParams {}

@Component({
  selector: 'lvadg-tarif-document-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifDocumentListComponent],
  templateUrl: './tarif-document-list-view.component.pug',
  styleUrls: ['./tarif-document-list-view.component.sass'],
})
export class TarifDocumentListViewComponent extends BaseListViewComponent<TarifDocumentListViewData, TarifDocument> {
  public default_fields = [
    'id',
    'tarif_details',
    'image_details',
    'title',
    'filename',
    'filesize',
    'type',
    'status',
    'flags',
    'created',
    'updated',
  ];
  public tarif_detail_route!: Link<TarifBase>;

  public override setRouteData(data: TarifDocumentListViewData) {
    super.setRouteData(data);
    this.tarif_detail_route = new Link<TarifBase>(data.tarif_detail_route, data, this._router);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
