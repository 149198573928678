import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderService } from '../folder.service';
import { Link } from '@solidev/data';
import { Folder } from '../folder';
import { Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-folder-shortlist',
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent],
  templateUrl: './folder-shortlist.component.pug',
  styleUrls: ['./folder-shortlist.component.sass'],
})
export class FolderShortlistComponent implements OnInit {
  @Input() public flags: string[] = [];
  @HostBinding('class') public readonly class = 'card h-100';
  @Input() public display = 'card' as const;
  @Input() public title = 'Dossiers';
  @Input() public detail_route?: Link<Folder>;
  public folders$!: Observable<Folder[]>;

  constructor(private _folders: FolderService) {}

  public ngOnInit() {
    this.folders$ = this._folders.list({ flags: this.flags.join(','), fields: ['id', 'name', 'parents'].join(',') });
  }
}
