import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Georegion } from '../georegion';

@Component({
  selector: 'lvadg-georegion-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './georegion-display.component.pug',
  styleUrls: ['./georegion-display.component.sass'],
})
export class GeoregionDisplayComponent {
  @Input() public georegion!: Georegion;

  constructor() {}
}
