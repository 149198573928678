import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable } from 'rxjs';
import { Client } from '../../../../../models/structures/client/client';
import { CLIENT_ROUTES } from '../menu';
import { ClientNavComponent } from '../_nav/client-nav.component';

export interface ClientDisplayViewParams extends BaseRouteParams {
  c_menu: CLIENT_ROUTES;
}

export interface ClientDisplayViewData extends ClientDisplayViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-display-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, ClientNavComponent, IconComponent],
  templateUrl: './client-display-view.component.pug',
  styleUrls: ['./client-display-view.component.sass'],
})
export class ClientDisplayViewComponent extends BaseDetailViewComponent<ClientDisplayViewData, Client> {
  public clientFilter$!: Observable<FilterDefaults>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ClientDisplayViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.clientFilter$ = this.data$.pipe(map((d) => ({ client: d.client.id })));
  }
}
