import { RoutesConfig } from '@solidev/data';

export const FRONT_VIVALYA_ROUTES_NAMES = [
  'vivalya',
  'vivalya_members',
  'vivalya_members_member',
  'vivalya_members_member_storages',
  'vivalya_members_member_storages_storage',
  'vivalya_storages',
  'vivalya_storages_storage',
  'vivalya_stats',
] as const;

export const FRONT_VIVALYA_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_VIVALYA_ROUTES_NAMES)[number]> = {
  vivalya: { name: 'vivalya', params: [], route: () => [] },
  vivalya_members: { name: 'vivalya_members', params: [], route: () => [] },
  vivalya_members_member: {
    name: 'vivalya_members_member',
    params: [],
    route: () => [],
  },
  vivalya_members_member_storages: {
    name: 'vivalya_members_member_storages',
    params: [],
    route: () => [],
  },
  vivalya_members_member_storages_storage: {
    name: 'vivalya_members_member_storages_storage',
    params: [],
    route: () => [],
  },
  vivalya_storages: { name: 'vivalya_storages', params: [], route: () => [] },
  vivalya_storages_storage: {
    name: 'vivalya_storages_storage',
    params: [],
    route: () => [],
  },
  vivalya_stats: { name: 'vivalya_stats', params: [], route: () => [] },
};
