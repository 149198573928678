import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionBase } from '../production.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProductionService } from '../production.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-production-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './production-create.component.pug',
  styleUrls: ['./production-create.component.sass'],
})
export class ProductionCreateComponent implements OnInit {
  public production!: ProductionBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<ProductionBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _productions: ProductionService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.production = new ProductionBase(this._productions);
  }

  public async save() {
    this.production.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.production.save({ updateModel: true }));
      this.created.emit(this.production);
      this._msgs.success('Allergène créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
