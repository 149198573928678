
<div class="fpdialog">
  <div class="text-center">
    <h1 class="my-5">Connexion #lavieadugout
      <div class="small">Réactivation de compte</div>
    </h1>
  </div>
  <div class="card" [class.border-danger]="error!=''">
    <div class="card-header login" [class.bg-danger]="error!=''" [class.text-white]="error!=''"><i class="bi bi-box-arrow-in-right me-2"></i>Réactiver mon compte</div>
    <div class="card-body">
      <form [formGroup]="reactivateForm">
        <div class="mb-3">
          <label class="form-label" for="username">Identifiant ou adresse email</label>
          <input class="form-control" type="email" aria-describedby="usernamehelp" formControlName="username" autocomplete="username email" id="username">
          <div class="form-text" id="usernamehelp">Identifiant ou adresse email.</div>
        </div>
        <button class="btn btn-primary w-100" *ngIf="mode==='ask'" (click)="requestReactivate()">Envoyer un email de validation</button>
        <p class="text-info" *ngIf="mode==='waiting'">Un email a été envoyé à votre adresse {{reactivateForm.value.username}}. Vous pouvez cliquer sur le lien contenu dans ce mail ou bien coller le code ci-dessous.</p>
        <ng-container *ngIf="mode!=='ask'">
          <div class="mb-3">
            <label class="form-label" for="token">Jeton de réactivation</label>
            <input class="form-control" type="text" aria-describedby="usernamehelp" formControlName="token" id="token">
            <div class="form-text" id="tokenhelp">Jeton de réactivation, reçu par email</div>
          </div>
          <button class="btn btn-primary w-100" [disabled]="reactivateForm.invalid" (click)="processReactivate()">Réactiver mon compte</button>
        </ng-container>
      </form>
    </div>
  </div>
  <div class="text-center mt-5"><img src="/assets/images/logos/vivalya.png" width="128px"></div>
</div>