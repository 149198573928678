import { inject, Injectable } from "@angular/core";
import { Family } from "./family";
import { OpLog } from "../../../components/utils/oplog/oplog";
import { firstValueFrom } from "rxjs";
import { DataMessageService } from "@solidev/data";

interface RefreshFieldsFamilyAction {
  action: "refresh_computed_fields";
}

interface MoveFamilyAction {
  action: "move";
  dest: number;
  doit: boolean;
}

interface MergeFamilyAction {
  action: "merge";
  dest: number;
  doit: boolean;
}

interface MoveProductsFamilyAction {
  action: "move_products";
  dest: number;
  doit: boolean;
}

interface MoveArticlesFamilyAction {
  action: "move_articles";
  dest: number;
  doit: boolean;
}

interface FamilyActionResult<T> {
  success: boolean;
  message: string;
  data?: T;
  family?: Family;
  log?: OpLog;
}

interface FamilyActionStats {
  articles: number;
  products: number;
  subarticles: number;
  subfamilies: number;
  subproducts: number;
}

type FamilyAction =
  | RefreshFieldsFamilyAction
  | MoveFamilyAction
  | MoveProductsFamilyAction
  | MoveArticlesFamilyAction
  | MergeFamilyAction;

@Injectable({
  providedIn: "root",
})
export class FamilyActionService {
  private _msgs = inject(DataMessageService);

  constructor() {}

  public async action(
    family: Family,
    action: RefreshFieldsFamilyAction,
  ): Promise<FamilyActionResult<unknown>>;
  public async action(
    family: Family,
    action: MoveProductsFamilyAction,
  ): Promise<FamilyActionResult<FamilyActionStats>>;
  public async action(
    family: Family,
    action: MoveArticlesFamilyAction,
  ): Promise<FamilyActionResult<FamilyActionStats>>;

  public async action(
    family: Family,
    action: MoveFamilyAction,
  ): Promise<FamilyActionResult<FamilyActionStats>>;

  public async action(
    family: Family,
    action: MergeFamilyAction,
  ): Promise<FamilyActionResult<unknown>>;
  public async action<T>(
    family: Family,
    action: FamilyAction,
  ): Promise<FamilyActionResult<T>> {
    try {
      const res = await firstValueFrom(
        family.action<Family, FamilyActionResult<T>>("POST", "actions", {
          body: action,
        }),
      );
      if (res.success) {
        this._msgs.success(res.message);
        return res;
      } else {
        this._msgs.error(res.message);
        return res;
      }
    } catch (e: unknown) {
      console.error(e);
      const error = (
        e as {
          error: FamilyActionResult<T>;
        }
      ).error;
      if (error) {
        this._msgs.error(error.message);
        return error;
      } else {
        this._msgs.error("Erreur inconnue");
        return { success: false, message: "Erreur inconnue" };
      }
    }
  }
}
