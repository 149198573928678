
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TariftypeDocumentService} from "./tariftype-document.service";
import { TariftypeDocument } from "./tariftype-document";

@Injectable({
  providedIn: 'root'
})
export class TariftypeDocumentResolver  {
  constructor(private _rs: TariftypeDocumentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TariftypeDocument> {
    return this._rs.fetch(+route.params["tariftypedocumentId"])
  }
}
