import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypeStorage } from '../../offertype-storage/offertype-storage';
import { Subject } from 'rxjs';
import { FilterDefaults } from '@solidev/data';
import { OffertypeStoragePermissionCreateComponent } from '../offertype-storage-permission-create/offertype-storage-permission-create.component';
import { OffertypeStoragePermissionListComponent } from '../offertype-storage-permission-list/offertype-storage-permission-list.component';
import { Offertype } from '../../offertype/offertype';

@Component({
  selector: 'lvadg-offertype-storage-permission-listmanage',
  standalone: true,
  imports: [CommonModule, OffertypeStoragePermissionCreateComponent, OffertypeStoragePermissionListComponent],
  templateUrl: './offertype-storage-permission-listmanage.component.pug',
  styleUrls: ['./offertype-storage-permission-listmanage.component.sass'],
})
export class OffertypeStoragePermissionListmanageComponent implements OnInit {
  @Input() public offertypestorage?: OffertypeStorage;
  @Input() public offertype?: Offertype;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public permFilter: FilterDefaults = {};
  public perm_default_fields = ['user_details', 'group_details', 'permission', 'actions'];
  public addPermission: boolean = false;

  public ngOnInit() {
    if (this.offertypestorage) {
      this.permFilter = { target: this.offertypestorage.id };
    }
  }
}
