import { LOCATION_TYPE, LocationBase } from './location.base';
import { charField, detailsField } from '@solidev/data';
import { GeodepartementBase } from '../geodepartement/geodepartement.base';
import { GeoregionBase } from '../georegion/georegion.base';
import { GeocommuneBase } from '../geocommune/geocommune.base';
import { GeocountryBase } from '../geocountry/geocountry.base';

export class Location extends LocationBase {
  static override readonly __name: string = 'Location';

  @detailsField({
    name: 'geodepartement_details',
    model: GeodepartementBase,
    description: 'Département (géo)',
    readonly: true,
    priority: 250,
  })
  public geodepartement_details?: GeodepartementBase;

  @detailsField({
    name: 'georegion_details',
    model: GeoregionBase,
    description: 'Région (géo)',
    readonly: true,
    priority: 250,
  })
  public georegion_details?: GeoregionBase;

  @detailsField({
    name: 'geocommune_details',
    model: GeocommuneBase,
    description: 'Commune (géo)',
    readonly: true,
    priority: 250,
  })
  public geocommune_details?: GeocommuneBase;

  @detailsField({
    name: 'geocountry_details',
    description: 'Pays (géo)',
    model: GeocountryBase,
    readonly: true,
    priority: 250,
  })
  public geocountry_details?: GeocountryBase;

  @detailsField({
    name: 'related_details',
    description: 'Structure',
    readonly: true,
    priority: 850,
  })
  public related_details?: { id: number; name: number; type: LOCATION_TYPE };

  @charField({
    name: 'position',
    description: 'Position',
    priority: -1,
  })
  public position?: string;
}
