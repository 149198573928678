import { OrderprocessPermissionBase } from './orderprocess-permission.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { OrderprocessBase } from '../orderprocess/orderprocess.base';
import { GroupBase } from '../../users/group/group.base';

export class OrderprocessPermission extends OrderprocessPermissionBase {
  static override readonly __name: string = 'OrderprocessPermission';
  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: OrderprocessBase,
    description: 'Processus de commande',
    readonly: true,
  })
  public target_details?: OrderprocessBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;
}
