
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { ProviderDocumentService} from "./provider-document.service";
import { ProviderDocument } from "./provider-document";

@Injectable({
  providedIn: 'root'
})
export class ProviderDocumentResolver implements Resolve<ProviderDocument> {
  constructor(private _rs: ProviderDocumentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProviderDocument> {
    return this._rs.fetch(+route.params["providerdocumentId"])
  }
}
