
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { CdataService} from "./cdata.service";
import { Cdata } from "./cdata";

@Injectable({
  providedIn: 'root'
})
export class CdataResolver  {
  constructor(private _rs: CdataService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Cdata> {
    return this._rs.fetch(+route.params["cdataId"])
  }
}
