import { ProviderDocumentBase } from "./provider-document.base";
import { detailsField } from "@solidev/data";
import { Image } from "../../documents/image/image";
import { ImageDisplay } from "../../documents/image/image.base";
import { Label } from "../../catalog/label/label";
import { ProviderBase } from "../provider/provider.base";
import { ArticleBase } from "../../catalog/article/article.base";

export class ProviderDocument extends ProviderDocumentBase {
  static override readonly __name: string = "ProviderDocument";
  @detailsField({
    description: "Fournisseur",
    model: ProviderBase,
    readonly: true,
    priority: 880,
  })
  public provider_details?: ProviderBase;

  @detailsField({
    description: "Preview",
    model: Image,
    readonly: true,
    priority: 890,
  })
  public image_details?: ImageDisplay;

  @detailsField({
    description: "Label",
    model: Label,
    readonly: true,
    priority: 900,
  })
  public label_details?: Label;

  @detailsField({
    description: "Articles",
    model: ArticleBase,
    readonly: true,
    priority: 750,
    many: true,
  })
  public articles_details?: ArticleBase[];
}
