
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OffertypePermission } from "./offertype-permission";

@Injectable({
  providedIn: 'root'
})
export class OffertypePermissionService extends Collection<OffertypePermission> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offertypepermissions", OffertypePermission);
  }
}
