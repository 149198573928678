import { OffertypeBase } from './offertype.base';
import { detailsField } from '@solidev/data';
import { Tariftype } from '../../tarifs/tariftype/tariftype';
import { Client } from '../../structures/client/client';
import { User } from '../../users/user/user';

export class Offertype extends OffertypeBase {
  static override readonly __name: string = 'Offertype';
  /// Tariftypes details (Tariftype, many)
  @detailsField({
    name: 'tariftypes_details',
    description: 'Modèles de tarif',
    priority: 400,
    model: Tariftype,
    many: true,
    readonly: true,
  })
  public tariftypes_details?: Tariftype[];

  // Client details (Client)
  @detailsField({
    name: 'client_details',
    description: 'Client',
    priority: 450,
    model: Client,
    readonly: true,
  })
  public client_details?: Client;

  @detailsField({
    name: 'pilotes_details',
    description: 'Pilotes',
    priority: 500,
    model: User,
    readonly: true,
    many: true,
  })
  public pilotes_details?: User[];

  public override get _display(): string {
    return `[${this.code || this.id}] ${this.name || this.client_details?.name || ''}`;
  }
}
