import { charField, DataModel, datetimeField, foreignKeyField, primaryField } from '@solidev/data';

export enum OFFERTYPESTORAGE_PERMISSION {
  READ = 'READ',
  WRITE = 'WRITE',
}

export class OffertypeStoragePermissionBase extends DataModel {
  static override readonly __name: string = 'OffertypeStoragePermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({ name: 'updated', description: 'Date de MAJ', readonly: true, priority: 100 })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permissions',
    maxLength: 10,
    choices: [
      { value: OFFERTYPESTORAGE_PERMISSION.WRITE, desc: 'Saisie offres dépôt' },
      {
        value: OFFERTYPESTORAGE_PERMISSION.READ,
        desc: 'Consultation offres dépôt',
      },
    ],
    priority: 300,
  })
  public permission: OFFERTYPESTORAGE_PERMISSION = OFFERTYPESTORAGE_PERMISSION.READ;

  @foreignKeyField({
    name: 'target',
    description: 'ID Modèle offre dépôt',
    related: 'OfferTypeStorage',
    priority: -1,
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
    priority: -1,
  })
  public group!: number;
}
