import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from "../../../documents/upload/media-upload/media-upload.component";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DispeditComponent, UploadFile } from "@solidev/data";
import { Article } from "../../article/article";
import { ArticleDocument } from "../article-document";
import { ARTICLEDOCUMENT_TYPES } from "../article-document.base";
import { ArticleService } from "../../article/article.service";
import { ArticleDocumentService } from "../article-document.service";

@Component({
  selector: "lvadg-article-document-create",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MediaUploadComponent,
    DispeditComponent,
  ],
  templateUrl: "./article-document-create.component.pug",
  styleUrls: ["./article-document-create.component.sass"],
})
export class ArticleDocumentCreateComponent implements OnInit {
  @Input() public article?: Article;
  @Output() public uploaded = new EventEmitter<
    UploadResult<ArticleDocument, { data: ArticleDocument }>
  >();
  @Output() public created = new EventEmitter<ArticleDocument>();
  public createForm = new FormGroup({
    articles: new FormControl<number[] | null>(null),
    type: new FormControl<string | null>(ARTICLEDOCUMENT_TYPES.CERTIFICATION),
  });
  public doc!: ArticleDocument;
  public uploadPrepare: (
    model: ArticleDocument,
    file: UploadFile,
  ) => Promise<IPreparedUploadFile<ArticleDocument>> =
    this._uploadPrepare.bind(this);

  constructor(
    public articles$: ArticleService,
    public articledocument$: ArticleDocumentService,
  ) {}

  ngOnInit(): void {
    this.doc = new ArticleDocument(this.articledocument$);
    if (this.article) {
      this.doc.articles = [this.article.id];
      this.doc.articles_details = [this.article];
    }
    this.createForm = this.doc.FC(["articles", "type"]);
    this.uploaded.subscribe((result) => {
      if (result.response?.data) {
        this.created.emit(result.response.data);
      }
    });
  }

  private async _uploadPrepare(
    model: ArticleDocument,
    file: UploadFile,
  ): Promise<IPreparedUploadFile<ArticleDocument>> {
    let articles: number[] = [];
    if (this.article) {
      articles = [this.article.id];
    }
    if (!articles.length) {
      if (this.createForm.value.articles) {
        articles = this.createForm.value.articles;
      }
    }
    if (!articles.length) {
      throw new Error("No article id");
    }
    return {
      model,
      url: this.articles$.getUrl(null, { suffix: "action" }),
      data: {
        action: "add_document",
        articles,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        type: this.createForm.value.type,
      },
    };
  }
}
