
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { RnmtarifService} from "./rnmtarif.service";
import { Rnmtarif } from "./rnmtarif";

@Injectable({
  providedIn: 'root'
})
export class RnmtarifResolver  {
  constructor(private _rs: RnmtarifService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Rnmtarif> {
    return this._rs.fetch(+route.params["rnmtarifId"])
  }
}
