
<ng-container *ngIf="display==='card'">
  <div class="card-header">
    <lvadg-icon ri="fl"><span class="fw-bold">{{title}}</span></lvadg-icon>
  </div>
  <div class="max-height-200">
    <nav class="list-group list-group-flush">
      <ng-container *ngFor="let p of products$|async"><a class="list-group-item list-group-item-action d-flex justify-content-start align-items-center" [routerLink]="detail_route?.route({product: p})">
          <lvadg-image-display class="me-2" [image]="p.clogo_details" [mode]="'list'" [thumbnail]="false" [hover]="false"></lvadg-image-display>
          <div class="fw-bold">{{p.name}}</div><small class="ms-2">{{p.producer_details?.name}}</small></a></ng-container>
    </nav>
  </div>
</ng-container>