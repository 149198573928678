
<ng-container *ngIf="brand">
  <dl class="row">
    <data-dispedit class="col-6" [model]="brand" field="id" [editable]="false" mode="dd">ID</data-dispedit>
    <data-dispedit class="col-6" [model]="brand" field="vvid" [editable]="false" mode="dd">ID Vivalya (deprecated)</data-dispedit>
    <data-dispedit class="col-3" [model]="brand" field="code" [editable]="true" mode="dd">Code</data-dispedit>
    <data-dispedit class="col-3" [model]="brand" field="type" [editable]="true" mode="dd">Type</data-dispedit>
    <data-dispedit class="col-6" [model]="brand" field="name" [editable]="true" mode="dd">Nom / description</data-dispedit>
    <data-dispedit class="col-6" [model]="brand" field="producer" [editable]="true" mode="dd" [collection]="producers$">Producteur associé</data-dispedit>
    <data-dispedit class="col-6" [model]="brand" field="provider" [editable]="true" mode="dd" [collection]="providers$">Fournisseur associé</data-dispedit>
  </dl>
</ng-container>