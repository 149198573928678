
<div class="row g-3">
  <div class="card">
    <div class="card-header">Informations</div>
    <div class="card-body">
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="newscategory" field="id" [editable]="false" mode="dd">ID</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="newscategory" field="title" [editable]="true" mode="dd">Titre</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="newscategory" field="status" [editable]="true" mode="dd">Etat</data-dispedit>
      </dl>
      <dl class="row mt-3">
        <data-richedit class="col-12" [model]="newscategory" field="description" [editable]="true" mode="dd">description</data-richedit>
      </dl>
    </div>
  </div>
</div>