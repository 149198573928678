
<form class="row g-3" [formGroup]="form" (submit)="save()">
  <data-dispedit class="col-6" [form]="form" [model]="item" field="type" mode="form">Type</data-dispedit>
  <data-dispedit class="col-6" [form]="form" [model]="item" field="mandatory" mode="form">Obligatoire ?</data-dispedit>
  <data-dispedit class="col-6" [form]="form" [model]="item" field="title" mode="form">Titre</data-dispedit>
  <data-dispedit class="col-6" [form]="form" [model]="item" field="region" mode="form" [collection]="customRegion$">Spécifique région</data-dispedit>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="form.pristine||form.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>