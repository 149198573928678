import { CiqualAlimBase } from './ciqual-alim.base';
import { detailsField } from '@solidev/data';
import { CiqualValue } from '../ciqual-value/ciqual-value';

export class CiqualAlim extends CiqualAlimBase {
  static override readonly __name: string = 'CiqualAlim';

  @detailsField({
    model: CiqualValue,
    many: true,
    description: 'Valeurs',
    readonly: true,
    priority: 100,
  })
  public values_details?: CiqualValue[];
}
