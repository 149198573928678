import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Resto } from '../../../../models/structures/resto/resto';
import { RestoListComponent } from '../../../../models/structures/resto/resto-list/resto-list.component';
import { ClientBase } from '../../../../models/structures/client/client.base';

export interface RestoListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
}

export interface RestoListViewData extends RestoListViewParams {}

@Component({
  selector: 'lvadg-resto-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, RestoListComponent],
  templateUrl: './resto-list-view.component.pug',
  styleUrls: ['./resto-list-view.component.sass'],
})
export class RestoListViewComponent extends BaseListViewComponent<RestoListViewData, Resto> {
  public client_detail_route?: Link<ClientBase>;
  public default_fields = [
    'id',
    'cvva',
    'name',
    'cclient',
    'type',
    'client_details',
    'location_details',
    'updated',
    'datestart',
    'dateend',
    'status',
  ];

  public override setRouteData(data: RestoListViewData) {
    super.setRouteData(data);
    this.client_detail_route = new Link<ClientBase>(data.client_detail_route, this, this._router);
  }
}
