
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TarifNotification } from "./tarif-notification";

@Injectable({
  providedIn: 'root'
})
export class TarifNotificationService extends Collection<TarifNotification> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tarifnotifications", TarifNotification);
  }
}
