
<ng-template #countStat let-count="count">
  <ng-container *ngIf="(stats_display()[PST.COUNT]||'default') === 'default'">
    <dl>
      <dt>{{stats_titles()[PST.COUNT]||'Total'}}</dt>
      <dd>{{count.count}}</dd>
    </dl>
  </ng-container>
</ng-template>
<ng-template #perDistanceStat let-distances="per_distance">
  <ng-container *ngIf="(stats_display()[PST.PER_DISTANCE]||'default') === 'default'">
    <dl>
      <dt>{{stats_titles()[PST.PER_DISTANCE]||'Par distance'}}</dt>
      <dd>
        <div *ngFor="let label of distances"><span>< {{label.distance}} km :</span><span class="ms-2">{{label.count}}</span></div>
      </dd>
    </dl>
  </ng-container>
</ng-template>
<ng-template #avgDistanceStat let-distance="avg_distance">
  <ng-container *ngIf="(stats_display()[PST.AVG_DISTANCE]||'default') === 'default'">
    <dl>
      <dt>{{stats_titles()[PST.AVG_DISTANCE].local||'Distance moyenne (local)'}}</dt>
      <dd>{{distance.local_distance/1000|number:'1.0-0'}} km</dd>
      <dt>{{stats_titles()[PST.AVG_DISTANCE].global||'Distance moyenne (global)'}}</dt>
      <dd>{{distance.global_distance/1000|number:'1.0-0'}} km</dd>
    </dl>
  </ng-container>
</ng-template>
<ng-template #showStats>
  <ng-container *ngIf="stats(); let stat">
    <ng-container *ngIf="stat.count">
      <ng-container *ngTemplateOutlet="countStat; context: stat"></ng-container>
    </ng-container>
    <ng-container *ngIf="stat.per_distance">
      <ng-container *ngTemplateOutlet="perDistanceStat; context: stat"></ng-container>
    </ng-container>
    <ng-container *ngIf="stat.avg_distance">
      <ng-container *ngTemplateOutlet="avgDistanceStat; context: stat"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<ng-container *ngIf="display()==='card'">
  <div class="card-header">
    <lvadg-icon ri="stats"><span class="fw-bold">{{title()}}</span></lvadg-icon>
  </div>
  <div class="card-body">
    <div class="max-height-200">
      <ng-template *ngTemplateOutlet="showStats"></ng-template>
    </div>
  </div>
</ng-container>