import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { PROVIDER_ROUTES } from "../menu";
import { Provider } from "../../../../../models/structures/provider/provider";
import { AuthService } from "../../../../../models/users/auth.service";
import { Link } from "@solidev/data";
import { CommonModule } from "@angular/common";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { ADMIN_GROUPS } from "../../../../../constants";

@Component({
  selector: "lvadg-provider-nav",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: "./provider-nav.component.pug",
  styleUrl: "./provider-nav.component.sass",
})
export class ProviderNavComponent implements OnInit {
  @Input() public routes!: PROVIDER_ROUTES;
  @Input() public provider!: Provider;

  public provider_detail_route?: Link<Provider>;
  public provider_edit_route?: Link<Provider>;
  public provider_articles_route?: Link<Provider>;
  public provider_documents_route?: Link<Provider>;

  public readonly canEdit = signal(false);
  private _auth = inject(AuthService).currentUser$;
  private _router = inject(Router);

  public ngOnInit() {
    this._auth.subscribe((user) => {
      if (user) {
        if (
          user.is_superuser ||
          user.have_group(ADMIN_GROUPS.providers_editor)
        ) {
          this.canEdit.set(true);
        } else {
          this.canEdit.set(false);
        }
      }
    });
    if (!this.routes) {
      throw new Error("Missing routes input");
    }
    if (this.routes.provider_detail_route) {
      this.provider_detail_route = new Link(
        this.routes.provider_detail_route,
        this,
        this._router,
      );
    }
    if (this.routes.provider_edit_route) {
      this.provider_edit_route = new Link(
        this.routes.provider_edit_route,
        this,
        this._router,
      );
    }
    if (this.routes.provider_articles_route) {
      this.provider_articles_route = new Link(
        this.routes.provider_articles_route,
        this,
        this._router,
      );
    }
    if (this.routes.provider_documents_route) {
      this.provider_documents_route = new Link(
        this.routes.provider_documents_route,
        this,
        this._router,
      );
    }
  }
}
