
<ng-container *ngIf="tariftyperelation &amp;&amp; tarifrelation &amp;&amp; mode==='inline'">
  <div class="d-flex flex-row justify-content-between">
    <lvadg-tarif-display [tarif]="tarifrelation.src_details" mode="line" [detail_route]="tarif_detail_route"></lvadg-tarif-display>
    <div class="actions" *ngIf="dest_tarif">
      <ng-container [ngSwitch]="tariftyperelation.type">
        <button class="btn btn-outline-primary btn-sm" *ngSwitchCase="TT_RELATION_TYPE.IMPORT_PRICE" [class.disabled]="running || !dest_tarif.isEditable" (click)="process_action().then()" [title]="!dest_tarif.isEditable ? &quot;Le tarif doit être en préparation&quot;:&quot;Effectuer l'action&quot;"><span class="spinner-border spinner-border-sm me-2" *ngIf="running"></span>Importer les prix</button>
        <button class="btn btn-outline-primary btn-sm" *ngSwitchCase="TT_RELATION_TYPE.IMPORT_LOCAL_DISPO" [class.disabled]="running || !dest_tarif.isEditable" (click)="process_action().then()" [title]="!dest_tarif.isEditable ? &quot;Le tarif doit être en préparation&quot;:&quot;Effectuer l'action&quot;"><span class="spinner-border spinner-border-sm me-2" *ngIf="running"></span>Importer les disponibilités locales</button>
        <button class="btn btn-outline-primary btn-sm" *ngSwitchCase="TT_RELATION_TYPE.IMPORT_LOCAL_PRICE" [class.disabled]="running || !dest_tarif.isEditable" (click)="process_action().then()" [title]="!dest_tarif.isEditable ? &quot;Le tarif doit être en préparation&quot;:&quot;Effectuer l'action&quot;"><span class="spinner-border spinner-border-sm me-2" *ngIf="running"></span>Importer les prix locaux</button>
        <button class="btn btn-outline-primary btn-sm" *ngSwitchCase="TT_RELATION_TYPE.FULL_CLONE" [class.disabled]="running || !dest_tarif.isEditable" (click)="process_action().then()" [title]="!dest_tarif.isEditable ? &quot;Le tarif doit être en préparation&quot;:&quot;Effectuer l'action&quot;"><span class="spinner-border spinner-border-sm me-2" *ngIf="running"></span>Effectuer le clonage du tarif</button>
        <button class="btn btn-outline-primary btn-sm" *ngSwitchCase="TT_RELATION_TYPE.REGION_PRICES_CLONE" [class.disabled]="running || !dest_tarif.isEditable" (click)="process_action().then()" [title]="!dest_tarif.isEditable ? &quot;Le tarif doit être en préparation&quot;:&quot;Effectuer l'action&quot;"><span class="spinner-border spinner-border-sm me-2" *ngIf="running"></span>Effectuer l'import des prix région</button>
      </ng-container>
      <button class="btn btn-outline-danger btn-sm ms-2" [class.disabled]="dest_tarif.isEditable" (click)="delete()"><i class="bi bi-trash"></i></button>
    </div>
  </div>
</ng-container>