
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { CiqualAlim } from "./ciqual-alim";

@Injectable({
  providedIn: 'root'
})
export class CiqualAlimService extends Collection<CiqualAlim> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/ciqualalims", CiqualAlim);
  }
}
