import { Injectable } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';
import { debounceTime, filter, Observable, of, switchMap, tap } from 'rxjs';
import { Family } from './family';
import { FamilyService } from './family.service';
import { FamilyBase } from './family.base';

export class FamilyTree {
  public control: NestedTreeControl<Family, number>;
  public data: ArrayDataSource<Family>;
  public expanded = new Map<number, boolean>();
  public highlighted = new Map<number, string>();
  public current?: number;
  public root: number | null = null;

  constructor(
    private _ftc: FamilyTreeCache,
    private _family: FamilyService,
    name: string,
    root: FamilyBase | number | null = null
  ) {
    if (root === null) {
      this.root = null;
    } else if (root instanceof FamilyBase) {
      this.root = root.id;
    } else {
      this.root = root;
    }
    this.control = new NestedTreeControl<Family, number>(
      (node) => {
        if (this._ftc.children.has(node.id)) {
          return of(this._ftc.children.get(node.id)!);
        }
        return this._family
          .list({
            parent: node.id,
            order: 'name',
            fields: 'id,name,children,parent,nbsubproducts,nbsubarticles',
          })
          .pipe(
            tap((res) => {
              this._ftc.children.set(node.id, res);
            })
          );
      },
      { trackBy: (d) => d.id }
    );
    this.data = new ArrayDataSource(
      this._family.list({
        parent: this.root,
        order: 'name',
        fields: 'id,name,children,parent,nbsubproducts,nbsubarticles',
      })
    );
  }

  public expand(node: Family) {
    this.expanded.set(node.id, true);
    this.control.expand(node);
  }

  public collapse(node: Family) {
    this.expanded.delete(node.id);
    this.control.collapse(node);
  }

  public setCurrent(node: Family) {
    this.current = node.id;
  }

  public reload(full: boolean = false) {
    if (full) {
      this._ftc.children = new Map<number, Family[]>();
      this.control.collapseAll();
      setTimeout(() => {
        this.control.expansionModel.select(...this.expanded.keys());
      }, 1000);
    } else {
      this.control.expansionModel.select(...this.expanded.keys());
    }
  }

  public search(text$: Observable<string | null>) {
    text$
      .pipe(
        debounceTime(500),
        filter((t) => t !== null && t.length > 3),
        switchMap((text) => {
          this.control.expansionModel.clear(true);
          return this._family.list({
            search: text,
            fields: 'id,name,nbsubproducts,nbsubarticles',
            page_size: 100,
          });
        })
      )
      .subscribe((res) => {
        this.highlighted.clear();
        for (const r of res.slice(0, 30)) {
          this.highlighted.set(r.id, 'search');
          for (const p of r.tree_path) {
            if (r.id !== p.id) {
              this.control.expansionModel.select(p.id);
            }
          }
        }
      });
  }
}

@Injectable({
  providedIn: 'root',
})
export class FamilyTreeCache {
  public children = new Map<number, Family[]>();
}

@Injectable({
  providedIn: 'root',
})
export class FamilyTreeService {
  private _trees = new Map<string, FamilyTree>();

  constructor(private _family: FamilyService, private _ftc: FamilyTreeCache) {
  }

  public load(name: string, root: Family | number | null = null): FamilyTree {
    if (!this._trees.has(name)) {
      this._trees.set(
        name,
        new FamilyTree(this._ftc, this._family, name, root)
      );
    }
    const tree = this._trees.get(name)!;
    tree.reload();
    return tree;
  }
}
