import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderService } from '../order.service';
import { OrderBase } from '../order.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { OrderprocessService } from '../../orderprocess/orderprocess.service';
import { StorageService } from '../../../structures/storage/storage.service';

@Component({
  selector: 'lvadg-order-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './order-create.component.pug',
  styleUrls: ['./order-create.component.sass'],
})
export class OrderCreateComponent implements OnInit {
  public obj!: OrderBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<OrderBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _svc: OrderService,
    public process$: OrderprocessService,
    public storage$: StorageService
  ) {}

  public ngOnInit(): void {
    this.obj = new OrderBase(this._svc);
  }

  public async save() {
    this.obj.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
