import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
} from "@angular/core";
import { Cdata } from "../cdata";
import { CDATAKEY_DEST, CDATAKEY_TYPE } from "../../cdata-key/cdata-key.base";
import { CdataKeyService } from "../../cdata-key/cdata-key.service";
import { CdataKey } from "../../cdata-key/cdata-key";
import { firstValueFrom } from "rxjs";
import { Product } from "../../../catalog/product/product";
import { CdataBase } from "../cdata.base";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { CdataDispmanageComponent } from "../cdata-dispmanage/cdata-dispmanage.component";
import { Storage } from "../../../structures/storage/storage";
import { DataMessageService } from "@solidev/data";

type DataModelWithCData = Product | Storage;

interface CdataActionResult {
  instance: Cdata;
  success: boolean;
  message: string;
}

@Component({
  selector: "lvadg-cdata-listmanage",
  standalone: true,
  imports: [IconComponent, CdataDispmanageComponent],
  template: `
    @if (cdatas(); as cdatas) {
      @if (keys(); as keys) {
        <table class="table">
          <tbody>
            @for (k of keys.entries(); track k[0]) {
              @if (k[1].type !== CDATAKEY_TYPE.CATEGORY) {
                <tr>
                  <th style="width: 25%">{{ k[1].name }}</th>
                  <td>
                    @if (getData(k[0]); as cd) {
                      <div class="d-flex flex-row justify-content-between">
                        <div class="flex-grow-1">
                          <lvadg-cdata-dispmanage
                            [key]="k[1]"
                            [cdata]="cd"
                            (cdataChange)="updated($event)"
                          />
                        </div>
                        <div>
                          <lvadg-icon
                            ri="delete"
                            [icon_only]="true"
                            class="text-danger"
                            role="button"
                            (click)="delete(cd.id)"
                          />
                        </div>
                      </div>
                    } @else {
                      <lvadg-cdata-dispmanage
                        [key]="k[1]"
                        [cdata]="undefined"
                        (cdataChange)="updated($event)"
                      />
                    }
                  </td>
                </tr>
              }
            }
          </tbody>
        </table>
      }
    }
  `,
  styleUrl: "./cdata-listmanage.component.sass",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CdataListmanageComponent {
  public model = input.required<DataModelWithCData>();
  public current = signal<DataModelWithCData | undefined>(undefined);

  public cdatas = computed<CdataBase[]>(() => {
    const m = this.current();
    return m?.cdata_details || [];
  });
  public dest = input<CDATAKEY_DEST>();
  public keys = signal<Map<number, CdataKey>>(new Map());
  protected readonly CDATAKEY_TYPE = CDATAKEY_TYPE;
  private _keys = inject<CdataKeyService>(CdataKeyService);
  private _msgs = inject(DataMessageService);

  constructor() {
    effect(async () => {
      const d = this.dest();
      const m = this.model();
      if (d) {
        const k = new Map<number, CdataKey>(
          Array.from(await firstValueFrom(this._keys.byDest(d))).map((k) => [
            k.id,
            k,
          ]),
        );
        this.keys.set(k);
      }
      if (m) {
        await this.refreshModel(m);
      }
    });
  }

  public getData(k: number): Cdata | undefined {
    return (this.cdatas() || []).filter((c) => c.key === k)[0];
  }

  public async refreshModel(m: DataModelWithCData) {
    this.current.set(
      await firstValueFrom(
        m._collection.fetch(m.id, {
          params: {
            fields: "id,cdata_details",
          },
        }),
      ),
    );
  }

  public async updated(c: Cdata | undefined) {
    console.log("Updated", c);
    if (!c) {
      return;
    }
    try {
      const res = await firstValueFrom(
        this.model().action<DataModelWithCData, CdataActionResult>(
          "POST",
          "cdata_action",
          {
            body: {
              action: "set_cdata",
              data: c.toJson(),
            },
          },
        ),
      );
      await this.refreshModel(this.model());
      if (res.success) {
        this._msgs.success(res.message);
      } else {
        this._msgs.error(res.message);
      }
    } catch (e) {
      console.error("Error lors de la mise à jour", e);
    }
  }

  public async delete(k: number) {
    console.log("Delete", k);
    try {
      const res = await firstValueFrom(
        this.model().action<DataModelWithCData, CdataActionResult>(
          "POST",
          "cdata_action",
          {
            body: {
              action: "delete_cdata",
              cdata_id: k,
            },
          },
        ),
      );
      await this.refreshModel(this.model());
      if (res.success) {
        this._msgs.success(res.message);
      } else {
        this._msgs.error(res.message);
      }
    } catch (e) {
      console.error("Error lors de la suppression", e);
    }
  }
}
