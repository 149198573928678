import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../utils/icon/icon.component";
import { firstValueFrom, ReplaySubject } from "rxjs";
import { User } from "../../../models/users/user/user";
import {
  NotificationStatusActionParams,
  UserActionService,
} from "../../../models/users/user/user-action.service";
import { AuthService } from "../../../models/users/auth.service";
import { FormControl, ReactiveFormsModule } from "@angular/forms";

export interface UserTarifsNotificationsStatus {
  disabled: {
    status: boolean;
    until?: Date;
  };
  autoabo: boolean;
  count: {
    bells: number;
    stars: number;
  };
}

@Component({
  selector: "lvadg-user-tarifs-notifications-manage",
  standalone: true,
  imports: [CommonModule, IconComponent, ReactiveFormsModule],
  templateUrl: "./user-tarifs-notifications-manage.component.pug",
  styleUrls: ["./user-tarifs-notifications-manage.component.sass"],
})
export class UserTarifsNotificationsManageComponent implements OnInit {
  @Input() public user: User | null = null;
  public status$ = new ReplaySubject<UserTarifsNotificationsStatus>(1);
  public disableDate = new FormControl("");

  constructor(
    private _uas: UserActionService,
    private _auth: AuthService,
  ) {}

  public async ngOnInit() {
    if (!this.user) {
      this.user = await firstValueFrom(this._auth.currentUser$);
    }
    await this.getStatus();
  }

  public async getStatus() {
    if (this.user !== null) {
      const res = await this._uas.action<{
        tarifs: UserTarifsNotificationsStatus;
      }>(this.user, {
        action: "get_notifications_settings",
      });
      if (res.success && res.data) {
        this.status$.next(res.data.tarifs);
      }
    }
  }

  public async action(
    act:
      | "deactivate_tarifs_notifications"
      | "activate_tarifs_notifications"
      | "deactivate_tarifs_autoabo"
      | "activate_tarifs_autoabo"
      | "uncheck_tarifs_bells"
      | "uncheck_tarifs_stars",
  ) {
    if (!this.user) {
      return;
    }
    let params: NotificationStatusActionParams;
    if (act === "deactivate_tarifs_notifications") {
      params = {
        do: act,
        until: this.disableDate.value || undefined,
      };
    } else {
      params = { do: act };
    }
    const res = await this._uas.action<{
      tarifs: UserTarifsNotificationsStatus;
    }>(this.user, {
      action: "change_notifications_settings",
      ...params,
    });
    if (res.success && res.data) {
      this.status$.next(res.data.tarifs);
    }
  }
}
