import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Collection, DataModel, UploadFile } from '@solidev/data';
import { IMAGE_USAGE_CHOICES_DICT, ImageDisplay } from '../image.base';
import { IPreparedUploadFile, MediaUploadComponent } from '../../upload/media-upload/media-upload.component';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ImageDisplayComponent } from '../image-display/image-display.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { ImageManageComponent } from '../image-manage/image-manage.component';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-logo-manage',
  standalone: true,
  imports: [CommonModule, MediaUploadComponent, ImageDisplayComponent, ImageManageComponent, IconComponent],
  templateUrl: './logo-manage.component.pug',
  styleUrls: ['./logo-manage.component.sass'],
})
export class LogoManageComponent<T extends DataModel> implements OnInit, OnChanges {
  @Input() public model!: T;
  @Input() public collection!: Collection<T>;
  @Input() public endpoints: {
    set: string;
    get: string;
    update: string;
    unset: string;
  } = {
    set: 'set_logo',
    get: 'get_logo',
    update: 'update_logo',
    unset: 'unset_logo',
  };
  @Input() public mode: 'row' | 'column' = 'row';
  @Input() public logo_field: string = 'logo';
  @Input() public logo_details_field: string = 'logo_details';
  @Output() public updated = new EventEmitter<T>();
  @ViewChild('ofc', { static: false })
  public ofcSlot?: ElementRef<HTMLElement>;
  public ofcRef?: NgbOffcanvasRef;
  public logo$?: Observable<ImageDisplay | null>;
  public IMAGE_USAGE = IMAGE_USAGE_CHOICES_DICT;

  constructor(private _ofc: NgbOffcanvas) {}

  public ngOnInit() {
    if (!this.collection && this.model) {
      this.collection = this.model._collection;
    }
    this.reload();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes['model']?.isFirstChange()) {
      this.ngOnInit();
    }
  }

  public uploadLogo = async (model: T, file: UploadFile): Promise<IPreparedUploadFile<T>> => {
    return {
      model: model,
      url: this.collection!.getUrl(model.id, { suffix: `/${this.endpoints.set}` }),
      data: {
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        legend: '',
        credits: '',
        status: 'OK',
      },
      fieldName: 'file',
      method: 'POST',
    };
  };

  async reload() {
    this.logo$ = this.collection!.fetch(this.model.id, {
      params: {
        fields: ['id', this.logo_field, this.logo_details_field].join(','),
      },
      // eslint-disable-next-line
    }).pipe(map((model) => (model as any)[this.logo_details_field] as ImageDisplay) || null);
  }

  async removeLogo() {
    await firstValueFrom(this.model.action('POST', this.endpoints.unset));
    this.updated.emit(this.model);
    await this.reload();
  }

  public edit(logo: ImageDisplay) {
    this.ofcRef = this._ofc.open(this.ofcSlot, { position: 'bottom', panelClass: 'h-auto' });
  }
}
