import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../tarif';
import { DispeditComponent, RicheditComponent } from '@solidev/data';
import { Tariftype } from '../../tariftype/tariftype';
import { TARIFTYPE_USER_LEVEL } from '../../tariftype/tariftype.base';
import { TARIF_STATUS } from '../tarif.base';
import { TarifActionService } from '../tarif-action.service';
import { firstValueFrom } from 'rxjs';
import { TarifService } from '../tarif.service';

@Component({
  selector: 'lvadg-tarif-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, RicheditComponent],
  templateUrl: './tarif-manage.component.pug',
  styleUrls: ['./tarif-manage.component.sass'],
})
export class TarifManageComponent implements OnInit {
  @Input() public tarif?: Tarif;
  @Input() public tariftype?: Tariftype;
  public TTUL = TARIFTYPE_USER_LEVEL;
  public TS = TARIF_STATUS;
  public renewing = false;

  constructor(
    private _tactions: TarifActionService,
    private _tarifs: TarifService
  ) {}

  public get renewable(): boolean {
    return (
      (this.tariftype?.have_level(this.TTUL.admin) &&
        this.tarif?.status === this.TS.ARCHIVED &&
        this.tarif?.dateend > new Date()) ||
      false
    );
  }

  public async renew() {
    if (this.tarif) {
      const res = await this._tactions.action(
        this.tarif,
        { action: 'republish' },
        {
          success: 'Le tarif a été renouvelé avec succès',
          error: 'Une erreur est survenue lors du renouvellement du tarif',
        }
      );
      this.tarif = await firstValueFrom(this._tarifs.fetch(this.tarif.id));
    }
  }

  ngOnInit(): void {}
}
