import { OfferNotificationBase } from "./offer-notification.base";
import { detailsField } from "@solidev/data";

export class OfferNotification extends OfferNotificationBase {
  static override readonly __name: string = "OfferNotification";

  @detailsField({
    name: "statuses_stats",
    description: "Statistiques",
    readonly: true,
    priority: 500,
  })
  public statuses_stats?: {
    total?: number;
    clicked?: number;
    opened?: number;
    sent?: number;
    sending?: number;
    pending?: number;
    fatal?: number;
    temporary?: number;
  };
}
