import { Component, Input, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Storage } from "../storage";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LocationManageComponent } from "../../../locations/location/location-manage/location-manage.component";
import { LocationCreateComponent } from "../../../locations/location/location-create/location-create.component";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { LOCATION_TYPE } from "../../../locations/location/location.base";
import { StorageActionService } from "../storage-action.service";
import { MemberService } from "../../member/member.service";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { CDATAKEY_DEST } from "../../../cdata/cdata-key/cdata-key.base";
import { CdataListmanageComponent } from "../../../cdata/cdata/cdata-listmanage/cdata-listmanage.component";
import { LabelsManagerComponent } from "../../../catalog/label/labels-manager/labels-manager.component";

@Component({
  selector: "lvadg-storage-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    IconComponent,
    LogoManageComponent,
    GalleryManageComponent,
    LocationManageComponent,
    LocationCreateComponent,
    CdataListmanageComponent,
    LabelsManagerComponent,
  ],
  templateUrl: "./storage-manage.component.pug",
  styleUrls: ["./storage-manage.component.sass"],
})
export class StorageManageComponent implements OnInit {
  @Input() public storage!: Storage;
  public LBT = LABEL_TYPE;
  public LT = LOCATION_TYPE;
  protected readonly CDATAKEY_DEST = CDATAKEY_DEST;
  protected allow_labels_edition = signal(false);

  constructor(
    public members$: MemberService,
    public sact: StorageActionService,
  ) {}

  ngOnInit(): void {}
}
