
<ng-container *ngIf="offertypestorage">
  <div class="row g-2">
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Paramètres</div>
        <div class="card-body">
          <p class="text-info">Paramètres non modifiables.</p>
          <dl class="row g-1">
            <data-dispedit class="col-6" [model]="offertypestorage" field="offertype" [editable]="false" mode="dd">Modèle d'offre</data-dispedit>
            <data-dispedit class="col-6" [model]="offertypestorage" field="storage" [editable]="false" mode="dd">Dépôt associé</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Caractéristiques</div>
        <div class="card-body">
          <p class="text-info">Modèles de tarif spécifiques pour cette offre / dépôt.</p>
          <dl class="row g-1">
            <data-dispedit class="col-12" [model]="offertypestorage" field="tariftypes" [editable]="true" mode="dd" [collection]="tariftypes$">Modèles de tarif</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Flags</div>
        <div class="card-body">
          <p class="text-warning">Les flags d'alarme sont ajoutés automatiquement par le système.</p>
          <dl class="row g-1">
            <data-flags [model]="offertypestorage">Flags</data-flags>
          </dl>
        </div>
      </div>
    </div>
  </div>
</ng-container>