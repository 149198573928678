import { OfferTextBase } from "./offer-text.base";
import { detailsField } from "@solidev/data";
import { OffertypeTextType } from "../offertype-text-type/offertype-text-type";
import { OffertypeText } from "../offertype-text/offertype-text";

export class OfferText extends OfferTextBase {
  static override readonly __name: string = "OfferText";

  @detailsField({
    name: "texttype_details",
    description: "Type de texte prédéfini",
    model: OffertypeTextType,
    readonly: true,
  })
  public texttype_details?: OffertypeTextType;

  @detailsField({
    name: "texttype_text_details",
    description: "Texte prédéfini",
    model: OffertypeText,
    readonly: true,
  })
  public texttype_text_details?: OffertypeText;
}
