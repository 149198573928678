import { inject, Injectable, InjectionToken } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterState,
} from "@angular/router";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  share,
  switchMap,
  throttleTime,
} from "rxjs";
import { BaseRouteParams } from "@solidev/data";
import {
  AuthService,
  SupportMessage,
  SupportMessageService,
  User,
} from "projects/lvadg/src/public-api";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface CurrentRouteMessages {
  route: string;
  path: string;
  user: string | null;
  messages: SupportMessage[];
}

export const SUPPORT_ZONE = new InjectionToken<string>("support.zone");

@Injectable({
  providedIn: "root",
})
export class RouteSupportService {
  public messages$: Observable<CurrentRouteMessages>;
  private _router = inject(Router);
  private _auth = inject(AuthService);
  private _messages = inject(SupportMessageService);
  private _reload$ = new BehaviorSubject(true);
  private _zone = inject(SUPPORT_ZONE);

  constructor() {
    this.messages$ = combineLatest([
      this._reload$.asObservable(),
      this._router.events,
      this._auth.currentUser$,
    ]).pipe(
      takeUntilDestroyed(),
      filter(() => localStorage.getItem("support") !== null),
      filter(([, e, u]) => u !== null && e instanceof NavigationEnd),
      throttleTime(500),
      map(([, , u]) => {
        const stt = this._router.routerState;
        return this._getCurrentRoute(stt, u);
      }),
      switchMap((r) => {
        return this._messages
          .list(
            {
              zone: this._zone,
              route: r.route,
              current_path: r.path,
              current_user: r.user,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("support")}`,
              },
            },
          )
          .pipe(map((m) => ({ ...r, messages: m })));
      }),
      share(),
    );
    this.refresh();
  }

  public refresh() {
    this._reload$.next(true);
  }

  private _getCurrentRoute(
    stt: RouterState,
    user: User | null,
  ): CurrentRouteMessages {
    let p: ActivatedRouteSnapshot | null = stt.root.snapshot;
    while (p?.firstChild != null) {
      p = p.firstChild;
    }
    const d = p.data as BaseRouteParams;
    return {
      route: d.route.name,
      path: stt.snapshot.url,
      user: user?.username || null,
      messages: [],
    };
  }
}
