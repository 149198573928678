import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum TARIFTYPEDOCUMENT_TYPE {
  OTHER = 'OT', // Type non précisé
  EACLIENT = 'EAC', // Fichier de tarif client (modèle)
}

export enum TARIFTYPEDOCUMENT_STATUSES {
  VALID = 'OK',
  OK_NO_UPDATE_EXP_15D = 'PRE1',
  OK_NO_UPDATE_EXP_1M = 'PRE2',
  FUTUR = 'FUT',
  ARCHIVED_NO_UPDATE = 'ARCN',
  ARCHIVED_WITH_UPDATE = 'ARCM',
  INVALID = 'KO',
}

export class TariftypeDocumentBase extends DataModel {
  static override readonly __name: string = 'TariftypeDocumentBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 100,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 150,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @charField({
    name: 'slug',
    description: 'Slug',
    maxLength: 200,
    priority: -1,
  })
  public slug!: string;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 200,
    priority: -1,
  })
  public title!: string;

  @charField({
    name: 'mimetype',
    description: 'Type mime',
    maxLength: 250,
    priority: -1,
  })
  public mimetype!: string;

  @charField({
    name: 'filename',
    description: 'Nom du fichier',
    maxLength: 255,
    priority: 700,
  })
  public filename!: string;

  @integerField({
    name: 'filesize',
    description: 'Taille',
    defaultValue: 0,
    priority: 650,
  })
  public filesize: number = 0;

  @charField({
    name: 'file',
    description: 'URL',
    maxLength: 200,
    priority: 600,
  })
  public file!: string;

  @foreignKeyField({
    name: 'image',
    description: 'ID image',
    related: 'Image',
    priority: -1,
  })
  public image!: number;

  @dateField({
    name: 'start',
    description: 'Date de début de validité',
    priority: 500,
  })
  public start!: Date;

  @dateField({
    name: 'end',
    description: 'Date de fin de validité',
    priority: 500,
  })
  public end!: Date;

  @detailsField({
    name: 'parameters',
    description: 'Paramètres',
    defaultValue: {},
    priority: -1,
  })
  public parameters: any = {};

  @detailsField({
    name: 'content',
    description: 'Contenus',
    defaultValue: {},
    priority: -1,
  })
  public content: any = {};

  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
    priority: -1,
  })
  public tariftype!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: TARIFTYPEDOCUMENT_TYPE.OTHER,
    maxLength: 4,
    priority: 600,
    choices: [
      {
        value: TARIFTYPEDOCUMENT_TYPE.OTHER,
        desc: 'Type non précisé',
      },
      {
        value: TARIFTYPEDOCUMENT_TYPE.EACLIENT,
        desc: 'Fichier de tarif client (modèle)',
      },
    ],
  })
  public type: TARIFTYPEDOCUMENT_TYPE = TARIFTYPEDOCUMENT_TYPE.OTHER;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: TARIFTYPEDOCUMENT_STATUSES.VALID,
    maxLength: 4,
    choices: [
      {
        value: TARIFTYPEDOCUMENT_STATUSES.VALID,
        desc: 'Document valide',
      },
      {
        value: TARIFTYPEDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D,
        desc: 'Document sans MAJ expirant dans - de 15 jours',
      },
      {
        value: TARIFTYPEDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M,
        desc: 'Document sans MAJ expirant dans - de 1 mois',
      },
      {
        value: TARIFTYPEDOCUMENT_STATUSES.FUTUR,
        desc: 'Document futur',
      },
      {
        value: TARIFTYPEDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE,
        desc: 'Document archivé, sans MAJ',
      },
      {
        value: TARIFTYPEDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE,
        desc: 'Document archivé, avec MAJ',
      },
      {
        value: TARIFTYPEDOCUMENT_STATUSES.INVALID,
        desc: 'Document non valide',
      },
    ],
  })
  public status: TARIFTYPEDOCUMENT_STATUSES = TARIFTYPEDOCUMENT_STATUSES.VALID;
}
