import { charField, DataModel, foreignKeyField, integerField, primaryField } from '@solidev/data';

export enum TariftypeRelationType {
  IMPORT_LOCAL_DISPO = 'LOC',
  IMPORT_LOCAL_PRICE = 'LOP',
  IMPORT_PRICE = 'REF',
  REFERENCE = 'NFO',
  FULL_CLONE = 'CLN',
  REGION_PRICES_CLONE = 'SRP',
}

export class TariftypeRelationBase extends DataModel {
  static override readonly __name: string = 'TariftypeRelationBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'src',
    description: 'ID Source',
    related: 'TarifType',
  })
  public src!: number;

  @foreignKeyField({
    name: 'dest',
    description: 'ID Destination',
    related: 'TarifType',
  })
  public dest!: number;

  @charField({
    name: 'type',
    description: 'Type de relation',
    defaultValue: TariftypeRelationType.IMPORT_PRICE,
    maxLength: 3,
    choices: [
      {
        value: TariftypeRelationType.IMPORT_LOCAL_DISPO,
        desc: 'Dispo locale (importation)',
      },
      {
        value: TariftypeRelationType.IMPORT_LOCAL_PRICE,
        desc: 'Prix local (importation)',
      },
      {
        value: TariftypeRelationType.IMPORT_PRICE,
        desc: 'Prix global (importation)',
      },
      {
        value: TariftypeRelationType.REFERENCE,
        desc: 'Référence (information)',
      },
      {
        value: TariftypeRelationType.FULL_CLONE,
        desc: 'Clone complet',
      },
      {
        value: TariftypeRelationType.REGION_PRICES_CLONE,
        desc: 'Clone des prix par région',
      },
    ],
  })
  public type: TariftypeRelationType = TariftypeRelationType.IMPORT_PRICE;

  @charField({
    name: 'ops',
    description: 'Opérations',
    defaultValue: '',
    maxLength: 200,
  })
  public ops: string = '';

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
