
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogUsage } from '../log-usage';
@Component({
  selector: 'lvadg-log-usage-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './log-usage-manage.component.pug',
  styleUrls: ['./log-usage-manage.component.sass']
})
export class LogUsageManageComponent implements OnInit {
  @Input() public logusage?: LogUsage;

  constructor() { }

  ngOnInit(): void {
  }

}
