import { UserMapBase } from './user-map.base';
import { detailsField } from '@solidev/data';
import { User } from '../../users/user/user';
import { UserMapLayerBase } from '../user-map-layer/user-map-layer.base';

export class UserMap extends UserMapBase {
  static override readonly __name: string = 'UserMap';

  @detailsField({
    name: 'user_details',
    description: 'Créateur',
    model: User,
  })
  public user_details?: User;

  @detailsField({
    name: 'layers_details',
    description: 'Calques',
    model: UserMapLayerBase,
    many: true,
  })
  public layers_details?: UserMapLayerBase[];
}
