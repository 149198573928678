
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OffertypeStoragePermissionService} from "./offertype-storage-permission.service";
import { OffertypeStoragePermission } from "./offertype-storage-permission";

@Injectable({
  providedIn: 'root'
})
export class OffertypeStoragePermissionResolver implements Resolve<OffertypeStoragePermission> {
  constructor(private _rs: OffertypeStoragePermissionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OffertypeStoragePermission> {
    return this._rs.fetch(+route.params["offertypestoragepermissionId"])
  }
}
