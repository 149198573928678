
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Rnmarticle } from "./rnmarticle";

@Injectable({
  providedIn: 'root'
})
export class RnmarticleService extends Collection<Rnmarticle> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/rnmarticles", Rnmarticle);
  }
}
