import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Family } from '../../family/family';
import { Article } from '../article';
import { ArticleService } from '../article.service';
import { FamilyService } from '../../family/family.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-article-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './article-create.component.pug',
  styleUrls: ['./article-create.component.sass'],
})
export class ArticleCreateComponent implements OnInit {
  @Input() public family?: Family;
  @Output() public created = new EventEmitter<Article>();
  @Output() public cancelled = new EventEmitter<void>();
  public createForm = new FormGroup({});
  public article!: Article;

  constructor(
    private _articles: ArticleService,
    public families$: FamilyService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.article = this._articles.create({
      family: this.family?.id,
    });
  }

  public async save() {
    this.article.fromJson(
      {
        code: 'CREATE',
        ...this.createForm.value,
      },
      { partial: true }
    );
    try {
      await firstValueFrom(this.article.save({ updateModel: true }));
      this.created.emit(this.article);
      this._msgs.success('Article créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
