import { DataModel, datetimeField, detailsField, foreignKeyField, primaryField } from '@solidev/data';

export class TariftypeStorageBase extends DataModel {
  static override readonly __name: string = 'TariftypeStorageBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
  })
  public tariftype!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
  })
  public storage!: number;

  @detailsField({
    name: 'user_level',
    description: 'Permissions utilisateur',
    deserialize: (d) => d,
  })
  public user_level: string[] = [];

  public have_level(level: string): boolean {
    return this.user_level.indexOf(level) !== -1;
  }
}
