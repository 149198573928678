
<ng-container *ngIf="data$|async; let data">
  <lvadg-article-nav [routes]="data.a_menu" [article]="data.article"></lvadg-article-nav>
  <lvadg-header-action level="h1" ri="catalog" [title]="data.article.code + ' - ' + data.article.libelle" subtitle="Contrôle qualité">
    <ng-container actions>
      <button class="btn btn-outline-primary btn-sm" (click)="refreshQuality(data.article)"><i class="bi bi-arrow-clockwise me-2"></i>Rafraîchir les similaires</button>
    </ng-container>
  </lvadg-header-action>
  <dl>
    <dt>Mappings article courant</dt>
    <dd>
      <ng-container *ngFor="let mapping of articleMappings$|async">
        <ng-container [ngSwitch]="mapping.type"><span class="badge bg-warning me-1" *ngSwitchCase="EAT.CLIENT">{{mapping.type}}::{{mapping.libelle}}</span><span class="badge bg-secondary me-1" *ngSwitchCase="EAT.CLIENTBULK">{{mapping.type}}::{{mapping.libelle}}</span><span class="badge bg-info me-1" *ngSwitchDefault>{{mapping.type}}::{{mapping.libelle}}</span></ng-container>
      </ng-container>
    </dd>
    <dt>Tarifs article courant :</dt>
    <dd>
      <ng-container *ngFor="let tarif of articleTarifTypes$|async"><span class="badge bg-info me-1">{{tarif.name}}</span></ng-container>
    </dd>
  </dl>
  <lvadg-article-similarity-list [name]="data.route.name" [filter]="articleFilter$" [default_fields]="default_fields" [article_detail_route]="article_detail_route" [actions]="['merge']" [reload]="reload$" [keep]="false" (action)="similarityAction(data.article, $event)"></lvadg-article-similarity-list>
  <div class="mt-4">
    <div class="row mt-3">
      <div class="col-6">
        <button class="btn btn-lg btn-outline-danger w-100" (click)="clone(data.article)"><i class="bi bi-plus-circle-dotted me-2"></i>CLONER CET ARTICLE</button>
      </div>
      <div class="col-6">
        <data-safe-delete [model]="data.article" display="custom" (deleted)="article_list_route!.navigate()">
          <button class="btn btn-lg btn-outline-danger w-100"><i class="bi bi-trash me-2"></i>SUPPRIMER CET ARTICLE</button>
        </data-safe-delete>
      </div>
    </div>
  </div>
</ng-container>