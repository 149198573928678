import {
  charField,
  DataModel,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class ArticleTarifTemplateRelationBase extends DataModel {
  static override readonly __name: string = 'ArticleTarifTemplateRelationBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'ttrel',
    description: 'ID Relation',
    related: 'TarifTypeRelation',
  })
  public ttrel!: number;

  @foreignKeyField({
    name: 'src',
    description: 'ID Article tarif source',
    related: 'ArticleTarifTemplate',
  })
  public src!: number;

  @foreignKeyField({
    name: 'dest',
    description: 'ID Article tarif destination',
    related: 'ArticleTarifTemplate',
  })
  public dest!: number;

  @charField({
    name: 'ops',
    description: 'Opérations',
    defaultValue: '',
    maxLength: 200,
  })
  public ops: string = '';

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
