import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Label } from '../label';
import { LabelService } from '../label.service';
import {
  FiltersParams,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LabelManageComponent } from '../label-manage/label-manage.component';
import { LabelBase } from '../label.base';
import { NgStringPipesModule } from 'ngx-pipes';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';

@Component({
  selector: 'lvadg-label-list',
  standalone: true,
  templateUrl: './label-list.component.pug',
  styleUrls: ['./label-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    ImageDisplayComponent,
    LabelManageComponent,
    NgStringPipesModule,
  ],
})
export class LabelListComponent extends ModellistComponent<Label> {
  public override actions = ['ofc'];

  constructor(coll: LabelService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Recherche par type',
          model: LabelBase,
          mfield: 'type',
          help: 'Sélectionnez un type',
        }),
      ],
    };
  }
}
