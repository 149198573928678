import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OrderprocessPermission } from '../orderprocess-permission';
import { OrderprocessPermissionService } from '../orderprocess-permission.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserBase } from '../../../users/user/user.base';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { GroupBase } from '../../../users/group/group.base';
import { OrderprocessBase } from '../../orderprocess/orderprocess.base';
import { OrderprocessDisplayComponent } from '../../orderprocess/orderprocess-display/orderprocess-display.component';

@Component({
  selector: 'lvadg-orderprocess-permission-list',
  standalone: true,
  templateUrl: './orderprocess-permission-list.component.pug',
  styleUrls: ['./orderprocess-permission-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    UserDisplayComponent,
    GroupDisplayComponent,
    OrderprocessDisplayComponent,
    SafeDeleteComponent,
  ],
})
export class OrderprocessPermissionListComponent extends ModellistComponent<OrderprocessPermission> {
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input() public orderprocess_detail_route?: Link<OrderprocessBase>;

  constructor(
    coll: OrderprocessPermissionService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
