
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OffertypeStoragePermission } from "./offertype-storage-permission";

@Injectable({
  providedIn: 'root'
})
export class OffertypeStoragePermissionService extends Collection<OffertypeStoragePermission> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offertypestoragepermissions", OffertypeStoragePermission);
  }
}
