import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessPermission } from '../orderprocess-permission';
import { OrderprocessPermissionBase } from '../orderprocess-permission.base';
import { Link } from '@solidev/data';

@Component({
  selector: 'lvadg-orderprocess-permission-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './orderprocess-permission-display.component.pug',
  styleUrls: ['./orderprocess-permission-display.component.sass'],
})
export class OrderprocessPermissionDisplayComponent implements OnInit {
  @Input() public permission?: OrderprocessPermission;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<OrderprocessPermissionBase>;

  constructor() {}

  ngOnInit(): void {}
}
