import { ProducerBase } from './producer.base';
import { detailsField, integerField } from '@solidev/data';
import { Image } from '../../documents/image/image';
import { Label } from '../../catalog/label/label';
import { ProducerDocument } from '../producer-document/producer-document';
import { Location } from '../../locations/location/location';
import { Relation } from '../relation/relation';
import { IocodeDetails } from '../../lvadgio/iocode/iocode.base';

export class Producer extends ProducerBase {
  static override readonly __name: string = 'Producer';
  @detailsField({
    name: 'location_details',
    description: 'Adresse',
    readonly: true,
    model: Location,
    priority: 500,
  })
  public location_details?: Location;

  @integerField({
    name: 'nb_products',
    description: '# produits',
    readonly: true,
    priority: 450,
  })
  public nb_products?: number;

  @detailsField({
    name: 'logo_details',
    description: 'Logo',
    readonly: true,
    model: Image,
    priority: 730,
  })
  public logo_details?: Image;

  @detailsField({
    name: 'images_details',
    description: 'Images',
    readonly: true,
    model: Image,
    many: true,
    priority: 720,
  })
  public images_details?: Image[];

  @detailsField({
    name: 'slabels_details',
    description: 'Labels structure',
    readonly: true,
    model: Label,
    many: true,
    priority: 600,
  })
  public slabels_details: Label[] = [];

  @detailsField({
    name: 'plabels_details',
    description: 'Labels produits',
    readonly: true,
    model: Label,
    many: true,
    priority: 600,
  })
  public plabels_details: Label[] = [];

  @detailsField({
    name: 'documents_details',
    description: 'Documents',
    readonly: true,
    model: ProducerDocument,
    many: true,
    priority: 350,
  })
  public documents_details: ProducerDocument[] = [];

  @detailsField({
    description: 'Dépôts',
    model: Relation,
    many: true,
    priority: 600,
  })
  public storage_relations_details?: Relation[];

  @detailsField({
    description: 'Lien .io',
    name: 'iocode',
    priority: 800,
    readonly: true,
  })
  public iocode?: IocodeDetails;
}
