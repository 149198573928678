import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericregionService } from './genericregion.service';
import { Genericregion } from './genericregion';

@Injectable({
  providedIn: 'root',
})
export class GenericregionResolver {
  constructor(private _rs: GenericregionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Genericregion> {
    return this._rs.fetch(+route.params['genericregionId']);
  }
}
