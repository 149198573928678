import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface OrderprocessPermissionListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  orderprocess_detail_route: RouteConfigItem;
}

export interface OrderprocessPermissionListViewData
  extends OrderprocessPermissionListViewParams {}
