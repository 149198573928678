import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Geocountry } from './geocountry';

@Injectable({
  providedIn: 'root',
})
export class GeocountryService extends Collection<Geocountry> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/geocountries', Geocountry);
  }
}
