import {NewsService} from "../news.service";
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DataMessageService, DispeditComponent} from "@solidev/data";
import {firstValueFrom} from "rxjs";
import {getErrorContext} from "../../../../components/utils/errormessage";
import {News} from "../news";
import {CommonModule} from "@angular/common";

@Component({
    standalone: true,
    selector: "lvadg-news-create",
    templateUrl: "./news-create.component.pug",
    styleUrls: ["./news-create.component.sass"],
    imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
})
export class NewsCreateComponent implements OnInit {
    public news!: News;
    public createForm = new FormGroup({});
    @Output() public created = new EventEmitter<News>();
    @Output() public cancelled = new EventEmitter<void>();

    constructor(
        private _news: NewsService,
        private _msgs: DataMessageService
    ) {
    }

    ngOnInit(): void {
        this.news = new News(this._news);
    }

    public async save() {
        this.news.fromJson(
            {parent: null, ...this.createForm.value},
            {partial: true}
        );
        try {
            await firstValueFrom(this.news.save({updateModel: true}));
            this.created.emit(this.news);
            this._msgs.success('Actualité créé avec succès');
        } catch (e) {
            const ectx = getErrorContext(e, {});
            this._msgs.error(ectx.title, ectx.message, ectx.context);
        }
    }
}
