import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../../../../../models/structures/member/member';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { VivalyaBillListComponent } from '../../../../../models/sales/vivalya-bill/vivalya-bill-list/vivalya-bill-list.component';
import { MEMBER_ROUTES } from '../menu';
import { MemberNavComponent } from '../_nav/member-nav.component';
import { map, Observable, Subject } from 'rxjs';
import { Storage } from '../../../../../models/structures/storage/storage';
import { Client } from '../../../../../models/structures/client/client';
import { Resto } from '../../../../../models/structures/resto/resto';
import { VivalyaBill } from '../../../../../models/sales/vivalya-bill/vivalya-bill';

export interface MemberSalesViewParams extends BaseRouteParams {
  m_menu: MEMBER_ROUTES;
  storage_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
}

export interface MemberSalesViewData extends MemberSalesViewParams {
  member: Member;
}

@Component({
  selector: 'lvadg-member-sales-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, VivalyaBillListComponent, MemberNavComponent],
  templateUrl: './member-sales-view.component.pug',
  styleUrls: ['./member-sales-view.component.sass'],
})
export class MemberSalesViewComponent extends BaseDetailViewComponent<MemberSalesViewData, Member> {
  public memberFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public storage_detail_route?: Link<Storage>;
  public client_detail_route?: Link<Client>;
  public resto_detail_route?: Link<Resto>;
  public bill_detail_route?: Link<VivalyaBill>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: MemberSalesViewData) {
    super.setRouteData(data);
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link<Resto>(data.resto_detail_route, data, this._router);
    }
    if (data.bill_detail_route) {
      this.bill_detail_route = new Link<VivalyaBill>(data.bill_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.memberFilter$ = this.data$.pipe(map((data) => ({ member: data.member.id })));
  }
}
