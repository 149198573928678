import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessarticleService } from '../orderprocessarticle.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent, FilterDefaults } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { OrderprocessService } from '../../orderprocess/orderprocess.service';
import { ArticleService } from '../../../catalog/article/article.service';
import { Orderprocess } from '../../orderprocess/orderprocess';
import { Orderprocessarticle } from '../orderprocessarticle';
import { ArticleTarifTemplateService } from '../../../tarifs/article-tarif-template/article-tarif-template.service';

@Component({
  selector: 'lvadg-orderprocessarticle-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './orderprocessarticle-create.component.pug',
  styleUrls: ['./orderprocessarticle-create.component.sass'],
})
export class OrderprocessarticleCreateComponent implements OnInit {
  public obj!: Orderprocessarticle;
  public createForm = new FormGroup({});
  @Input() public orderprocess?: Orderprocess;
  @Output() public created = new EventEmitter<Orderprocessarticle>();
  @Output() public cancelled = new EventEmitter<void>();
  public attFilter!: FilterDefaults;

  constructor(
    private _svc: OrderprocessarticleService,
    public process$: OrderprocessService,
    public att$: ArticleTarifTemplateService,
    public article$: ArticleService
  ) {}

  public ngOnInit(): void {
    this.obj = new Orderprocessarticle(this._svc);
    if (this.orderprocess) {
      this.obj.process = this.orderprocess.id;
      this.obj.process_details = this.orderprocess;
    }
    this.attFilter = {
      fields: [
        'id',
        'name',
        'tariftype_details',
        'article',
        'article_details',
      ].join(','),
      tariftype: this.orderprocess?.tariftype,
    };
    this.createForm.valueChanges.subscribe((v) => {
      console.log(v, (v as any).att, this.obj);
      if (
        this.obj.att_details &&
        this.obj.article !== this.obj.att_details.article
      ) {
        this.obj.article = this.obj.att_details.article;
        this.obj.article_details = this.obj.att_details.article_details;
        this.createForm.patchValue({ article: this.obj.article });
        console.log(this.obj);
      }
    });
  }

  public async save() {
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
