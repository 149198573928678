
<lvadg-header-action title="Régions génériques" level="h1" ri="location" subtitle="Gestion des régions génériques">
  <ng-container actions>
    <button class="btn btn-outline-primary" type="button" (click)="create=!create">
      <lvadg-icon ri="add">Ajouter une région générique</lvadg-icon>
    </button>
  </ng-container>
</lvadg-header-action>
<div class="card my-3" *ngIf="create">
  <div class="card-header">
    <lvadg-icon ri="add">Ajouter une région générique</lvadg-icon>
  </div>
  <div class="card-body">
    <lvadg-genericregion-create (cancelled)="create=false" (created)="reload$.next(true); create=false; detail_route.navigate({genericregion: $event})"></lvadg-genericregion-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-genericregion-list [name]="data.route.name" [detail_route]="detail_route" [reload]="reload$" [client_detail_route]="client_detail_route" [geodepartement_detail_route]="geodepartement_detail_route"></lvadg-genericregion-list>
</ng-container>