
import {Component} from '@angular/core';
import {ModellistComponent} from "../../../../includes/modellist/modellist.component";
import { DocumentPermission } from "../document-permission";
import { DocumentPermissionService} from "../document-permission.service";
import {FiltersParams, ModelListService, ModelListTextFilter} from "@solidev/data";
import {MODELLIST_IMPORTS} from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-document-permission-list',
  standalone: true,
  templateUrl: './document-permission-list.component.pug',
  styleUrls: ['./document-permission-list.component.sass'],
  imports: [...MODELLIST_IMPORTS]
})
export class DocumentPermissionListComponent extends ModellistComponent<DocumentPermission> {
  constructor(coll: DocumentPermissionService,
              list: ModelListService,
              ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte"
        }),
      ]
    };
  }
}
