
<ng-container *ngIf="list">
  <div class="d-flex flex-row justify-content-between">
    <div class="mx-2">
      <button class="btn btn-sm btn-outline-primary" (click)="displayProviders.set(true)" *ngIf="!displayProviders()">Afficher les fournisseurs</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayProviders.set(false)" *ngIf="displayProviders()">Masquer les fournisseurs</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayProvidersLegend.set(true)" *ngIf="displayProviders() &amp;&amp; !displayProvidersLegend()">Afficher la légende</button>
      <button class="btn btn-sm btn-outline-primary" (click)="displayProvidersLegend.set(false)" *ngIf="displayProviders() &amp;&amp; displayProvidersLegend()">Masquer la légende</button>
    </div>
  </div>
</ng-container>
<div #map id="map"></div>