
<style>
  <div class="line-class-prix-moyen">{ stroke-width: 4px; }</div>
</style>
<div class="row">
  <!--.col-4-->
  <!--  #summarychart(#summarychart)-->
  <div class="col-12">
    <div id="chart" #chart></div>
  </div>
</div>