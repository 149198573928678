
<ng-container *ngIf="storage(); let p">
  <div class="row align-items-center">
    <div [class.col-8]="p.logo_details" [class.col-12]="!p.logo_details">
      <div class="description fs-5">
        <div [innerHTML]="p.content_html"></div>
      </div>
    </div>
    <div class="col-4 d-flex justify-content-center" *ngIf="p.logo_details">
      <div class="logo">
        <lvadg-image-display [image]="p.logo_details" mode="medium" [hover]="true" [imgClasses]="'rounded'"></lvadg-image-display>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-around">
    <!--div.plabels.my-3
    //ng-container(*ngFor="let label of p.plabels_details")
    //  lvadg-label-display([label]="label", mode="large", [hover]="false", [thumbnail]="false")
      //ng-container(*ngFor="let document of p.documents_details")
      //  ng-container(*ngIf="document.label===label.id")
      //    lvadg-producer-document-display([producerdocument]="document", mode="details", *ngIf="document.is_valid").mx-1
    
    -->
    <div class="slabels my-3">
      <ng-container *ngFor="let label of p.slabels_details">
        <lvadg-label-display [label]="label" mode="large" [hover]="false" [thumbnail]="false"></lvadg-label-display>
        <!--ng-container(*ngFor="let document of p.documents_details")-->
        <!--  ng-container(*ngIf="document.label===label.id")-->
        <!--    lvadg-producer-document-display([producerdocument]="document", mode="details", *ngIf="document.is_valid").mx-1-->
      </ng-container>
    </div>
    <!--div.documents(*ngIf="haveDocuments()").my-2-->
    <!--  ng-container(*ngFor="let document of p.documents_details")-->
    <!--    lvadg-producer-document-display([producerdocument]="document", mode="details",-->
    <!--      *ngIf="document.is_valid && !document.label").mx-1-->
  </div>
  <div class="d-flex flex-column justify-content-around align-items-center fs-5">
    <lvadg-cdatas-display class="mt-3" [cdatas]="p.cdata_details" [dest]="CDATAKEY_DEST.STORAGE"></lvadg-cdatas-display>
  </div>
  <!--div.address-->
  <!--  address {{ p.location_details?.address }} {{ p.location_details?.postcode }} {{ p.location_details?.city }}-->
  <!--div.relations.my-3-->
  <!--  ng-container(*ngIf="p.storage_relations_details")-->
  <!--    lvadg-relation-listmap([model]="p",-->
  <!--      [relations]="p.storage_relations_details")-->
  <!--div.products
  lvadg-product-list([p]="p")
  
  -->
  <div class="row g-3 mt-3">
    <div class="col-xl-4 col-md-4 col-sm-4 col-6">
      <lvadg-producer-stats title="Statistiques producteurs locaux" [filter]="{local: LOCAL_FILTERS.NO_FILTER, storage:p.id , distances:'25,50,100,200'}"></lvadg-producer-stats>
    </div>
    <div class="col-xl-4 col-md-4 col-sm-4 col-6">
      <lvadg-product-stats title="Statistiques produits locaux" [filter]="{local: LOCAL_FILTERS.LOCAL_CONTEXT, storage:p.id }"></lvadg-product-stats>
    </div>
    <div class="col-xl-4 col-md-4 col-sm-4 col-6">
      <lvadg-product-stats title="Statistique familles de produits locaux" [filter]="{local: LOCAL_FILTERS.LOCAL_FULL, storage:p.id, family_level:3 }"></lvadg-product-stats>
    </div>
  </div>
  <div class="videos my-2" *ngIf="haveVideos()">
    <ng-container *ngFor="let image of p.images_details">
      <div *ngIf="image.video">
        <lvadg-video-display [video]="image" [containerClass]="'ratio ratio-16x9'"></lvadg-video-display>
      </div>
    </ng-container>
  </div>
  <div class="photos mb-5" *ngIf="havePhotos()">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let image of carouselImages()"><a class="d-flex align-items-center justify-content-center bg-secondary-subtle" [href]="image.file" target="_blank">
          <lvadg-image-display class="mx-auto my-1" [image]="image" mode="large" [hover]="false" [thumbnail]="false"></lvadg-image-display></a>
        <!--.bg-primary-subtle.ratio.ratio-16x9-->
        <!--  img.object-fit-cover.rounded.img-thumbnail([src]="image.file", [alt]="image.title", [title]="image.title")-->
      </ng-template>
    </ngb-carousel>
  </div>
</ng-container>