import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { FolderPermission } from '../folder-permission';
import { FolderPermissionService } from '../folder-permission.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { User } from '../../../users/user/user';
import { Group } from '../../../users/group/group';

@Component({
  selector: 'lvadg-folder-permission-list',
  standalone: true,
  templateUrl: './folder-permission-list.component.pug',
  styleUrls: ['./folder-permission-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    UserDisplayComponent,
    GroupDisplayComponent,
    SafeDeleteComponent,
  ],
})
export class FolderPermissionListComponent extends ModellistComponent<FolderPermission> {
  @Input() public user_detail_route?: Link<User>;
  @Input() public group_detail_route?: Link<Group>;

  constructor(
    coll: FolderPermissionService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
