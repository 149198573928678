import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeMetadata } from '../tariftype-metadata';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-tariftype-metadata-raw-edit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './tariftype-metadata-raw-edit.component.pug',
  styleUrls: ['./tariftype-metadata-raw-edit.component.sass'],
})
export class TariftypeMetadataRawEditComponent implements OnInit {
  @Input() public tariftypemetadata!: TariftypeMetadata;
  @Output() public updated = new EventEmitter<TariftypeMetadata>();
  @Output() public cancelled = new EventEmitter<void>();
  public form = new FormControl<string>('', {
    validators: Validators.required,
  });

  public ngOnInit() {
    if (this.tariftypemetadata && this.tariftypemetadata.data) {
      this.form.setValue(
        JSON.stringify(this.tariftypemetadata.data as any, null, 2)
      );
    }
  }

  public async save() {
    this.tariftypemetadata.data = JSON.parse(this.form.value || '{}') as any;
    await firstValueFrom(
      this.tariftypemetadata.update(['data'], { updateModel: true })
    );
    this.updated.emit(this.tariftypemetadata);
  }
}
