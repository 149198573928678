import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { User } from '../user';
import { DataMessageService } from '@solidev/data';
import { UserService } from '../user.service';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-user-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './user-create.component.pug',
  styleUrls: ['./user-create.component.sass'],
})
export class UserCreateComponent implements OnInit {
  @Output() public created = new EventEmitter<User>();
  @Output() public cancelled = new EventEmitter<void>();
  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    orig_password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    first_name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    last_name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
  });

  constructor(private _us: UserService, private _msgs: DataMessageService) {}

  ngOnInit(): void {}

  public genPassword(): void {
    this.form.patchValue({
      orig_password: Array(10)
        .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join(''),
    });
  }

  public async create() {
    const user = this._us.create(this.form.value);
    user.username = 'CREATE';
    user.is_superuser = false;
    user.is_staff = false;
    user.is_active = false;
    try {
      await firstValueFrom(user.save({ updateModel: true }));
      this._msgs.success('Utilisateur créé avec succès');
      this.created.emit(user);
    } catch (e) {
      const ctx = getErrorContext(e, {});
      this._msgs.error(ctx.title, ctx.message, ctx.context);
    }
  }
}
