import { charField } from '@solidev/data';
import { ArticleMetaBase } from '../meta.base';

export enum ALLERGEN_TYPE {
  PRESENCE = 'PR',
  TRACES = 'TR',
}

export class AllergenBase extends ArticleMetaBase {
  static override readonly __name: string = 'AllergenBase';

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: ALLERGEN_TYPE.PRESENCE,
    required: true,
    maxLength: 2,
    choices: [
      { value: ALLERGEN_TYPE.PRESENCE, desc: 'Présence de' },
      { value: ALLERGEN_TYPE.TRACES, desc: 'Traces de' },
    ],
  })
  public override type: string = ALLERGEN_TYPE.PRESENCE;

  public get type_short(): string {
    return this.type === ALLERGEN_TYPE.PRESENCE ? 'présence' : 'traces';
  }

  public override _display(): string {
    if (this.name && this.code !== this.name) {
      return `[${this.code}] ${this.name} (${this.type_short})`;
    }
    return `${this.code} (${this.type_short})`;
  }
}
