
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnmcotation } from '../rnmcotation';
@Component({
  selector: 'lvadg-rnmcotation-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmcotation-manage.component.pug',
  styleUrls: ['./rnmcotation-manage.component.sass']
})
export class RnmcotationManageComponent implements OnInit {
  @Input() public rnmcotation?: Rnmcotation;

  constructor() { }

  ngOnInit(): void {
  }

}
