import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from "../../../documents/upload/media-upload/media-upload.component";
import { DispeditComponent, UploadFile } from "@solidev/data";
import { Provider } from "../../provider/provider";
import { ProviderDocument } from "../provider-document";
import { ProviderService } from "../../provider/provider.service";
import { ProviderDocumentService } from "../provider-document.service";
import { PROVIDERDOCUMENT_TYPES } from "../provider-document.base";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "lvadg-provider-document-create",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MediaUploadComponent,
    DispeditComponent,
  ],
  templateUrl: "./provider-document-create.component.pug",
  styleUrls: ["./provider-document-create.component.sass"],
})
export class ProviderDocumentCreateComponent implements OnInit {
  @Input() public provider?: Provider;
  @Output() public uploaded = new EventEmitter<
    UploadResult<ProviderDocument, { data: ProviderDocument }>
  >();
  @Output() public created = new EventEmitter<ProviderDocument>();
  public createForm = new FormGroup({
    provider: new FormControl<number | null>(null),
    type: new FormControl<string | null>(PROVIDERDOCUMENT_TYPES.CERTIFICATION),
  });
  public doc!: ProviderDocument;
  public uploadPrepare: (
    model: ProviderDocument,
    file: UploadFile,
  ) => Promise<IPreparedUploadFile<ProviderDocument>> =
    this._uploadPrepare.bind(this);

  constructor(
    public providers$: ProviderService,
    public providerdocument$: ProviderDocumentService,
  ) {}

  ngOnInit(): void {
    this.doc = new ProviderDocument(this.providerdocument$);
    if (this.provider) {
      this.doc.provider = this.provider.id;
      this.doc.provider_details = this.provider;
    }
    this.createForm = this.doc.FC(["provider", "type"]);
    this.uploaded.subscribe((result) => {
      if (result.response?.data) {
        this.created.emit(result.response.data);
      }
    });
  }

  private async _uploadPrepare(
    model: ProviderDocument,
    file: UploadFile,
  ): Promise<IPreparedUploadFile<ProviderDocument>> {
    let provider_id = this.provider?.id;
    if (!provider_id) {
      if (this.createForm.value.provider) {
        provider_id = this.createForm.value.provider;
      }
    }
    if (!provider_id) {
      throw new Error("No provider id");
    }
    return {
      model,
      url: this.providers$.getUrl(provider_id, { suffix: "/action" }),
      data: {
        action: "add_document",
        provider: provider_id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        type: this.createForm.value.type,
      },
    };
  }
}
