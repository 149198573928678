import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  emailField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum CONTACT_TYPES {
  OFFER = 'OC',
  TARIF = 'TR',
}

export enum CONTACT_STATUSES {
  ACTIVE = 'OK',
  INACTIVE = 'NOK',
  TO_CHECK = 'ARV',
}

export class ContactBase extends DataModel {
  static override readonly __name: string = 'ContactBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 50,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: CONTACT_TYPES.OFFER,
    maxLength: 2,
    choices: [
      { value: CONTACT_TYPES.OFFER, desc: 'Contact offre locale' },
      { value: CONTACT_TYPES.TARIF, desc: 'Contact tarif' },
    ],
    priority: 900,
  })
  public type: CONTACT_TYPES = CONTACT_TYPES.OFFER;

  @foreignKeyField({
    name: 'owner',
    description: 'ID Propriétaire',
    related: 'User',
    priority: -1,
  })
  public owner!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur lvadg associé',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @charField({
    name: 'first_name',
    description: 'Prénom',
    maxLength: 100,
    minLength: 2,
    priority: 850,
    defaultValue: '',
  })
  public first_name: string = '';

  @charField({
    name: 'last_name',
    description: 'Nom de famille',
    minLength: 2,
    maxLength: 100,
    priority: 851,
    defaultValue: '',
  })
  public last_name: string = '';

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 10,
    priority: 852,
  })
  public title!: string;

  @charField({
    name: 'jobname',
    description: 'Poste',
    maxLength: 200,
    priority: 848,
  })
  public jobname!: string;

  @emailField({
    name: 'email',
    description: 'Email',
    minLength: 2,
    maxLength: 100,
    priority: 849,
    required: true,
    defaultValue: '',
  })
  public email: string = '';

  @charField({
    name: 'phone',
    description: 'Numéro de téléphone',
    maxLength: 40,
    priority: 849,
  })
  public phone!: string;

  @charField({
    name: 'mobile',
    description: 'Tel portable',
    maxLength: 40,
    priority: 849,
  })
  public mobile!: string;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Resto',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @foreignKeyField({
    name: 'member',
    description: 'ID Adhérent',
    related: 'Member',
    priority: -1,
  })
  public member!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -1,
  })
  public storage!: number;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
    priority: -1,
  })
  public tariftype!: number;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: CONTACT_STATUSES.ACTIVE,
    maxLength: 4,
    choices: [
      { value: CONTACT_STATUSES.ACTIVE, desc: 'Actif' },
      { value: CONTACT_STATUSES.INACTIVE, desc: 'Désactivé' },
      {
        value: CONTACT_STATUSES.TO_CHECK,
        desc: 'A revoir',
      },
    ],
    priority: 300,
  })
  public status: CONTACT_STATUSES = CONTACT_STATUSES.ACTIVE;

  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;

  public _display(): string {
    return `${this.last_name} ${this.first_name} [${this.email}]`;
  }
}
