import { VivalyaBillBase } from './vivalya-bill.base';
import { detailsField } from '@solidev/data';
import { Storage } from '../../structures/storage/storage';
import { Client } from '../../structures/client/client';
import { Resto } from '../../structures/resto/resto';

export class VivalyaBill extends VivalyaBillBase {
  static override readonly __name: string = 'VivalyaBill';

  @detailsField({
    description: 'Dépôt',
    model: Storage,
    readonly: true,
    priority: 800,
  })
  public storage_details?: Storage;

  @detailsField({
    description: 'Client',
    model: Client,
    readonly: true,
    priority: 900,
  })
  public client_details?: Client;

  @detailsField({
    description: 'Restaurant',
    model: Resto,
    readonly: true,
    priority: 950,
  })
  public resto_details?: Resto;
}
