
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Rnmatcache } from "./rnmatcache";

@Injectable({
  providedIn: 'root'
})
export class RnmatcacheService extends Collection<Rnmatcache> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/rnmatcaches", Rnmatcache);
  }
}
