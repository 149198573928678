
<ng-container *ngIf="tariftypedocument">
  <dl class="row">
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="id" [editable]="false" mode="dd">ID document</data-dispedit>
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="created" [editable]="false" mode="dd">Date de création</data-dispedit>
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="updated" [editable]="false" mode="dd">Date de MAJ</data-dispedit>
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="filesize" [editable]="false" mode="dd">Taille du fichier</data-dispedit>
    <data-dispedit class="col-sm-12" [model]="tariftypedocument" field="mimetype" [editable]="false" mode="dd">Type de fichier</data-dispedit>
    <data-dispedit class="col-sm-12" [model]="tariftypedocument" field="file" [editable]="false" mode="dd">URL du fichier</data-dispedit>
  </dl>
  <dl class="row mt-4">
    <data-dispedit class="col-sm-12" [model]="tariftypedocument" field="tariftype" [editable]="true" mode="dd" [collection]="tariftypes$">Modèle de tarif associé</data-dispedit>
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="slug" [editable]="true" mode="dd">Slug</data-dispedit>
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="title" [editable]="true" mode="dd">Titre</data-dispedit>
    <data-dispedit class="col-sm-4" [model]="tariftypedocument" field="filename" [editable]="true" mode="dd">Nom de fichier</data-dispedit>
  </dl>
  <dl class="row mt-4">
    <data-dispedit class="col-sm-6" [model]="tariftypedocument" field="start" [editable]="true" mode="dd">Date de début de validité</data-dispedit>
    <data-dispedit class="col-sm-6" [model]="tariftypedocument" field="end" [editable]="true" mode="dd">Date de fin de validité</data-dispedit>
  </dl>
  <dl class="row mt-4">
    <data-dispedit class="col-sm-6" [model]="tariftypedocument" field="type" [editable]="true" mode="dd">Type de document</data-dispedit>
    <data-dispedit class="col-sm-6" [model]="tariftypedocument" field="status" [editable]="true" mode="dd">État du document</data-dispedit>
  </dl>
</ng-container>