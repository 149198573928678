
<ng-container *ngIf="fdata$|async; let fdata">
  <lvadg-offertype-storage-nav [offertype]="fdata.data.offertype" [offertypestorage]="fdata.data.offertypestorage" [routes]="fdata.data.ots_menu" *ngIf="fdata.data.ots_menu"></lvadg-offertype-storage-nav>
  <lvadg-header-action [title]="fdata.data.offertypestorage._display" level="h1" subtitle="Offre contractuelle" ri="offer"></lvadg-header-action>
  <ng-template #ofcCreateSlot let-offcanvas>
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">
        <lvadg-icon ri="add">Créer une offre</lvadg-icon>
      </h5>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-offer-create [offertypestorage]="fdata.data.offertypestorage" [offertype]="fdata.ot" (created)="onOfferCreated($event)" (cancelled)="offcanvas.dismiss()"></lvadg-offer-create>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-sm-4">
      <div class="card h-100">
        <div class="card-header">
          <lvadg-icon ri="offer">Infos générales</lvadg-icon>
        </div>
        <div class="card-body">
          <dl>
            <dt>Nom / code</dt>
            <dd>{{fdata.ot.name}} <code>{{fdata.ot.code}}</code></dd>
            <dt>Dépot source</dt>
            <dd>{{fdata.data.offertypestorage.storage_details?._display}}</dd>
            <dt>Destination</dt>
            <dd>{{fdata.ot.client_details?._display}}</dd>
            <dt>Type</dt>
            <dd>{{fdata.ot.mode|pchoice:fdata.ot:'mode'}}, {{fdata.ot.type|pchoice:fdata.ot:'type'}} [{{fdata.ot.zone|pchoice:fdata.ot:'zone'}}]</dd>
            <dt>Périodicité</dt>
            <dd>
              <div>{{fdata.ot.base_interval|pchoice:fdata.ot:'base_interval'}}</div>
              <lvadg-rrule-display [rule]="fdata.ot.rrule"></lvadg-rrule-display>
            </dd>
            <dt>État</dt>
            <dd>{{fdata.ot.status|pchoice:fdata.ot:'status'}}</dd>
            <dt>Responsable(s)</dt>
            <dd>
              <div *ngFor="let u of fdata.ot.pilotes_details">
                <lvadg-user-display [user]="u"></lvadg-user-display>
              </div>
            </dd>
          </dl>
          <dl class="mt-3" *ngIf="showDetails(fdata.user)">
            <dt>Template de nom</dt>
            <dd>{{fdata.ot.name_template}}</dd>
            <dt>Notes internes</dt>
            <dd>{{fdata.ot.internal_notes}}</dd>
            <dt>Delta de préparation</dt>
            <dd>{{fdata.ot.delta_prepared}}</dd>
            <dt>Delta de publication</dt>
            <dd>{{fdata.ot.delta_published}}</dd>
            <dt>Modèles de tarif</dt>
            <dd>
              <div *ngFor="let tt of fdata.ot.tariftypes_details">
                <lvadg-tariftype-display [tariftype]="tt"></lvadg-tariftype-display>
              </div>
              <div *ngFor="let tt of fdata.data.offertypestorage.tariftypes_details">
                <lvadg-tariftype-display [tariftype]="tt"></lvadg-tariftype-display>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <lvadg-offer-shortlist display="fh-card" title="Dernieres offres" [filter]="{offertypestorage: fdata.data.offertypestorage.id}" [limit]="10" [ots_offer_detail_routes]="offertypestorage_offer_detail_route"></lvadg-offer-shortlist>
    </div>
    <div class="col">
      <button class="btn btn-outline-primary btn-lg py-4 w-100" *ngIf="canCreate(fdata.user, fdata.ot, fdata.data.offertypestorage)" (click)="createOffer()">
        <lvadg-icon ri="add">Créer une offre</lvadg-icon>
      </button>
    </div>
  </div>
</ng-container>