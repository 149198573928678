
<ng-container *ngIf="data$|async; let data">
  <lvadg-tarif-detail-nav [routes]="data.menu" [tarif]="data.tarif" [tariftype]="data.tariftype" *ngIf="data.menu"></lvadg-tarif-detail-nav>
  <lvadg-header-action [title]="data.tarif.name" subtitle="Documents du tarif" level="h2" ri="tarifs">
    <ng-container actions><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a></ng-container>
  </lvadg-header-action>
  <div class="row mb-3" *ngIf="data.tariftype.have_level(TTUL.sadmin)">
    <div class="col-6">
      <h4>Ajout de document</h4>
      <lvadg-tarif-document-create [tarif]="data.tarif"></lvadg-tarif-document-create>
    </div>
    <div class="col-6">
      <h4>Exportations</h4>
      <lvadg-tarif-export [tarif]="data.tarif" (exported)="reloadTarif$.next(true)"></lvadg-tarif-export>
    </div>
  </div>
  <lvadg-tarif-document-list [name]="data.route.name+&quot;_documents&quot;" [default_fields]="documents_default_fields" [actions]="['delete']" [filter]="tarifFilter$" [reload]="reloadTarif$"></lvadg-tarif-document-list>
</ng-container>