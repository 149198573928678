
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu &amp;&amp; data.tariftype"></lvadg-tariftype-detail-nav>
  <lvadg-header-action *ngIf="data.tariftype" [title]="data.tariftype.name" subtitle="Documents et modèles du tarif" level="h2" ri="tarifs"></lvadg-header-action>
  <lvadg-header-action *ngIf="!data.tariftype" title="Documents et modèles de tarif" level="h1" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="createTarif=true">
        <lvadg-icon ri="add">Ajouter un document</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="data.tariftype || createTarif">
    <div class="card-header">Ajout de document</div>
    <div class="card-body">
      <lvadg-tariftype-document-create [tariftype]="data.tariftype" (uploaded)="reload$.next(true); createTarif=false"></lvadg-tariftype-document-create>
    </div>
  </div>
  <lvadg-tariftype-document-list [filter]="tarifTypeFilter$" [detail_route]="tariftypedocument_detail_route" [tariftypemetadata_detail_route]="tariftypemetadata_detail_route" [tariftype_detail_route]="tariftype_detail_route" [reload]="reload$" [actions]="['delete']"></lvadg-tariftype-document-list>
</ng-container>