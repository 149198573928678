import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { map, Observable, Subject } from 'rxjs';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { TarifNotificationListComponent } from '../../../../../models/tarifs/tarif-notification/tarif-notification-list/tarif-notification-list.component';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';
import { TARIF_ROUTES } from '../menu';

export interface TarifNotificationsViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifNotificationsViewData extends TarifNotificationsViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-notifications-view',
  standalone: true,
  imports: [CommonModule, RouterLink, HeaderActionComponent, TarifNotificationListComponent, TarifDetailNavComponent],
  templateUrl: './tarif-notifications-view.component.pug',
  styleUrls: ['./tarif-notifications-view.component.sass'],
})
export class TarifNotificationsViewComponent extends BaseDetailViewComponent<TarifNotificationsViewData, Tarif> {
  public tn_default_fields = ['created', 'updated', 'type', 'priority', 'title', 'status'];
  public tariftype_detail_route!: Link<TariftypeBase>;
  public tarifFilter$!: Observable<FilterDefaults>;
  public reloadTarif$: Subject<void | boolean> = new Subject<void | boolean>();

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifNotificationsViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifFilter$ = this.data$.pipe(
      map((d) => {
        return { tarif: d.tarif.id };
      }),
    );
  }
}
