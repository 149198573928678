import { Component, Input, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Producer } from "../producer";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LocationManageComponent } from "../../../locations/location/location-manage/location-manage.component";
import { LocationCreateComponent } from "../../../locations/location/location-create/location-create.component";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { LOCATION_TYPE } from "../../../locations/location/location.base";
import { ProducerActionService } from "../producer-action.service";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { ProducerStoragesManageComponent } from "../producer-storages-manage/producer-storages-manage.component";
import { LabelsManagerComponent } from "../../../catalog/label/labels-manager/labels-manager.component";
import { PRODUCER_TYPES } from "../producer.base";

@Component({
  selector: "lvadg-producer-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    IconComponent,
    LogoManageComponent,
    GalleryManageComponent,
    LocationManageComponent,
    ProducerStoragesManageComponent,
    LocationCreateComponent,
    LabelsManagerComponent,
  ],
  templateUrl: "./producer-manage.component.pug",
  styleUrls: ["./producer-manage.component.sass"],
})
export class ProducerManageComponent implements OnInit {
  @Input() public producer!: Producer;
  public allow_storages_edition = signal(false);
  public LBT = LABEL_TYPE;
  public LT = LOCATION_TYPE;
  public product_label_types: LABEL_TYPE[] = [];
  protected allow_labels_edition = signal(false);

  constructor(public pact: ProducerActionService) {}

  public ngOnInit() {
    this.product_label_types = [LABEL_TYPE.FL, LABEL_TYPE.SEA];
    if (this.producer) {
      if (this.producer.type === PRODUCER_TYPES.FL) {
        this.product_label_types = [LABEL_TYPE.FL];
      } else if (this.producer.type === PRODUCER_TYPES.MR) {
        this.product_label_types = [LABEL_TYPE.SEA];
      } else {
        this.product_label_types = [LABEL_TYPE.FL, LABEL_TYPE.SEA];
      }
    }
  }
}
