import { RouteConfigItem } from "@solidev/data";

export interface PRODUCER_ROUTES {
  producer_detail_route?: RouteConfigItem;
  producer_edit_route?: RouteConfigItem;
  producer_relations_route?: RouteConfigItem;
  producer_documents_route?: RouteConfigItem;
  producer_offers_route?: RouteConfigItem;
  producer_products_route?: RouteConfigItem;
}

export interface PRODUCER_LINKS_ROUTES {
  producer_list_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  product_detail_route?: RouteConfigItem;
  relation_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
}
