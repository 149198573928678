import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TariftypePermission } from '../../../../models/tarifs/tariftype-permission/tariftype-permission';
import { TariftypePermissionManageComponent } from '../../../../models/tarifs/tariftype-permission/tariftype-permission-manage/tariftype-permission-manage.component';
import { BaseRouteParams } from '@solidev/data';

export interface TariftypePermissionDetailViewParams extends BaseRouteParams {}

export interface TariftypePermissionDetailViewData extends TariftypePermissionDetailViewParams {
  tariftypepermission: TariftypePermission;
}

@Component({
  selector: 'lvadg-tariftype-permission-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TariftypePermissionManageComponent],
  templateUrl: './tariftype-permission-detail-view.component.pug',
  styleUrls: ['./tariftype-permission-detail-view.component.sass'],
})
export class TariftypePermissionDetailViewComponent extends BaseDetailViewComponent<
  TariftypePermissionDetailViewData,
  TariftypePermission
> {
  public override setRouteData(data: TariftypePermissionDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
