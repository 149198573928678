import { charField, DataModel, datetimeField, foreignKeyField, primaryField } from '@solidev/data';

export enum TARIFTYPESTORAGE_PERMISSION {
  WRITE = 'WRITE',
  READ = 'READ',
}

export class TariftypeStoragePermissionBase extends DataModel {
  static override readonly __name: string = 'TariftypeStoragePermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    choices: [
      {
        value: TARIFTYPESTORAGE_PERMISSION.WRITE,
        desc: 'Saisie tarif/dispo dépôt',
      },
      {
        value: TARIFTYPESTORAGE_PERMISSION.READ,
        desc: 'Consultation tarif/dispo dépôt',
      },
    ],
    priority: 300,
  })
  public permission!: TARIFTYPESTORAGE_PERMISSION;

  @foreignKeyField({
    name: 'target',
    description: 'ID Modèle de tarif',
    related: 'TarifTypeStorage',
    priority: -1,
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID User',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
    priority: -1,
  })
  public group!: number;
}
