import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Print } from '../print';
import { PrintService } from '../print.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserBase, UserDisplayComponent } from '../../../users/public_api';

@Component({
  selector: 'lvadg-print-list',
  standalone: true,
  templateUrl: './print-list.component.pug',
  styleUrls: ['./print-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, PChoicePipe, UserDisplayComponent],
})
export class PrintListComponent extends ModellistComponent<Print> {
  @Input() public user_detail_route?: Link<UserBase>;
  constructor(coll: PrintService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'status'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        // Filter by status
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Statut',
          model: Print,
        }),
      ],
    };
  }
}
