
<ng-container *ngIf="isEditable &amp;&amp; mode==='price'">
  <input class="form-control form-control-sm price-editor nobsvalid" #editor [formControl]="editCtrl" (focus)="onFocus($any($event))" (blur)="onBlur($any($event))" (click)="onFocus($any($event))" [class.changed]="changed" [class.pristine]="isPristine" [class.nopristine]="!isPristine" (keyup.enter)="save(true, false)" (keyup.shift.enter)="save(false, true)" [attr.aria-label]="'Edition prix '+(storage?.name || region?.name ||'')">
</ng-container>
<ng-container *ngIf="isEditable &amp;&amp; mode==='dispo'">
  <div class="w-100" (click)="onCheckCellFocus($any($event))" role="button">
    <div class="form-check">
      <input class="form-check-input mx-auto" #dispo type="checkbox" [formControl]="checkCtrl" (focus)="onFocus($any($event))" (blur)="onBlur($any($event))" (click)="toggleAvailable($event)" [class.changed]="changed" [class.pristine]="isPristine" [class.nopristine]="!isPristine" (keyup.enter)="save(true, false)" (keyup.shift.enter)="save(false, true)" [attr.aria-label]="'Edition dispo '+(storage?.name || region?.name ||'')">
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isEditable &amp;&amp; mode==='dispoprice'">
  <div class="w-100" (click)="onCheckCellFocus($any($event))" role="button">
    <div class="form-check">
      <input class="form-check-input mx-auto" #dispo type="checkbox" [formControl]="checkCtrl" (focus)="onFocus($any($event))" (blur)="onBlur($any($event))" (click)="toggleAvailable($event)" [class.changed]="changed" [class.pristine]="isPristine" [class.nopristine]="!isPristine" (keyup.enter)="save(true, false)" (keyup.shift.enter)="save(false, true)" [attr.aria-label]="'Edition dispo '+(storage?.name || region?.name ||'')">
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!isEditable &amp;&amp; mode==='price'">
  <div>{{priceDisp}}</div>
</ng-container>
<ng-container *ngIf="!isEditable &amp;&amp; mode==='dispo'"><i class="bi bi-check-lg text-success" *ngIf="available"></i><i class="bi bi-x text-muted" *ngIf="!available"></i></ng-container>
<ng-container *ngIf="!isEditable &amp;&amp; mode==='dispoprice'"><i class="bi bi-check-lg text-success" *ngIf="available"></i><i class="bi bi-x text-muted" *ngIf="!available"></i></ng-container>