import { DataModel, datetimeField, detailsField, foreignKeyField, primaryField } from '@solidev/data';

export class TariftypeRegionBase extends DataModel {
  static override readonly __name: string = 'TariftypeRegionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
    priority: -1,
  })
  public tariftype!: number;

  @foreignKeyField({
    name: 'region',
    description: 'ID Région générique',
    related: 'GenericRegion',
    priority: -1,
  })
  public region!: number;
  @detailsField({
    name: 'user_level',
    description: 'Permissions utilisateur',
    deserialize: (d) => d,
  })
  public user_level: string[] = [];

  public have_level(level: string): boolean {
    return this.user_level.indexOf(level) !== -1;
  }
}
