import { Component } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OfferNotification } from "../offer-notification";
import { OfferNotificationService } from "../offer-notification.service";
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import {
  NgbOffcanvas,
  NgbProgressbar,
  NgbProgressbarStacked,
} from "@ng-bootstrap/ng-bootstrap";
import { NgStringPipesModule } from "ngx-pipes";
import { OFFERNOTIFICATION_STATUSES } from "../offer-notification.base";
import { IconComponent } from "../../../../components/utils/icon/icon.component";

@Component({
  selector: "lvadg-offer-notification-list",
  standalone: true,
  templateUrl: "./offer-notification-list.component.pug",
  styleUrls: ["./offer-notification-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    NgStringPipesModule,
    IconComponent,
    NgbProgressbar,
    NgbProgressbarStacked,
  ],
})
export class OfferNotificationListComponent extends ModellistComponent<OfferNotification> {
  public readonly ONS = OFFERNOTIFICATION_STATUSES;

  constructor(
    coll: OfferNotificationService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getRowClasses(row: OfferNotification): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === OFFERNOTIFICATION_STATUSES.SENT) {
      cls.push("text-success");
    }
    return cls;
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
      ],
    };
  }
}
