import { Offer, OffertypeStorage, Producer, Product, RS } from "@vivalya/lvadg";
import { RoutesConfig } from "@solidev/data";

export const FRONT_FL_ROUTES_NAMES = [
  "fl",
  "fl_global",
  "fl_global_producers",
  "fl_global_producers_producer",
  "fl_global_producers_producer_products",
  "fl_global_producers_producer_products_product",
  "fl_global_producers_producer_products_product_edit",
  "fl_global_producers_producer_products_product_documents",
  "fl_global_producers_producer_products_product_offers",
  "fl_global_producers_producer_products_product_articles",
  "fl_global_producers_producer_relations",
  "fl_global_producers_producer_edit",
  "fl_global_producers_producer_documents",
  "fl_global_producers_producer_offers",
  "fl_global_products",
  "fl_global_products_product",
  "fl_global_products_product_articles",
  "fl_global_products_product_documents",
  "fl_global_products_product_edit",
  "fl_global_products_product_offers",
  "fl_global_products_product_producer",
  "fl_global_stats",
  "fl_global_offers",
  "fl_global_offers_offer",
  "fl_global_offers_offer_edit",
  "fl_global_offers_offer_documents",
  "fl_global_offers_offer_printpreview",
  "fl_global_offers_offer_notifications",
  "fl_global_offers_offer_destinations",
  "fl_global_offers_offer_notificationstatuses",
  "fl_global_offers_offer_products",
  "fl_global_offers_offer_articles",
  "fl_global_offers_offer_restos",
  "fl_global_offers_offer_texts",
  "fl_global_offertypestorages",
  "fl_global_offertypestorages_offertypestorage",
  "fl_global_offertypestorages_offertypestorage_edit",
  "fl_global_offertypestorages_offertypestorage_permissions",
  "fl_global_offertypestorages_offertypestorage_offers",
  "fl_global_offertypestorages_offertypestorage_offers_offer",
  "fl_global_offertypestorages_offertypestorage_offers_offer_edit",
  "fl_global_offertypestorages_offertypestorage_offers_offer_documents",
  "fl_global_offertypestorages_offertypestorage_offers_offer_printpreview",
  "fl_global_offertypestorages_offertypestorage_offers_offer_notifications",
  "fl_global_offertypestorages_offertypestorage_offers_offer_destinations",
  "fl_global_offertypestorages_offertypestorage_offers_offer_notificationstatuses",
  "fl_global_offertypestorages_offertypestorage_offers_offer_products",
  "fl_global_offertypestorages_offertypestorage_offers_offer_articles",
  "fl_global_offertypestorages_offertypestorage_offers_offer_restos",
  "fl_global_offertypestorages_offertypestorage_offers_offer_texts",
  "fl_local",
  "fl_local_producers",
  "fl_local_producers_producer",
  "fl_local_producers_producer_products",
  "fl_local_producers_producer_products_product",
  "fl_local_producers_producer_products_product_edit",
  "fl_local_producers_producer_products_product_documents",
  "fl_local_producers_producer_products_product_offers",
  "fl_local_producers_producer_products_product_articles",
  "fl_local_producers_producer_relations",
  "fl_local_producers_producer_edit",
  "fl_local_producers_producer_documents",
  "fl_local_producers_producer_offers",
  "fl_local_products",
  "fl_local_products_product",
  "fl_local_products_product_articles",
  "fl_local_products_product_documents",
  "fl_local_products_product_edit",
  "fl_local_products_product_offers",
  "fl_local_products_product_producer",
  "fl_local_stats",
  "fl_local_offers",
  "fl_local_offers_offer",
  "fl_local_offers_offer_edit",
  "fl_local_offers_offer_documents",
  "fl_local_offers_offer_printpreview",
  "fl_local_offers_offer_notifications",
  "fl_local_offers_offer_destinations",
  "fl_local_offers_offer_notificationstatuses",
  "fl_local_offers_offer_products",
  "fl_local_offers_offer_articles",
  "fl_local_offers_offer_restos",
  "fl_local_offers_offer_texts",
  "fl_local_offertypestorages",
  "fl_local_offertypestorages_offertypestorage",
  "fl_local_offertypestorages_offertypestorage_edit",
  "fl_local_offertypestorages_offertypestorage_permissions",
  "fl_local_offertypestorages_offertypestorage_offers",
  "fl_local_offertypestorages_offertypestorage_offers_offer",
  "fl_local_offertypestorages_offertypestorage_offers_offer_edit",
  "fl_local_offertypestorages_offertypestorage_offers_offer_documents",
  "fl_local_offertypestorages_offertypestorage_offers_offer_printpreview",
  "fl_local_offertypestorages_offertypestorage_offers_offer_notifications",
  "fl_local_offertypestorages_offertypestorage_offers_offer_destinations",
  "fl_local_offertypestorages_offertypestorage_offers_offer_notificationstatuses",
  "fl_local_offertypestorages_offertypestorage_offers_offer_products",
  "fl_local_offertypestorages_offertypestorage_offers_offer_articles",
  "fl_local_offertypestorages_offertypestorage_offers_offer_restos",
  "fl_local_offertypestorages_offertypestorage_offers_offer_texts",
  "fl_stats",
] as const;

export const FRONT_FL_ROUTES_CONFIG: RoutesConfig<
  (typeof FRONT_FL_ROUTES_NAMES)[number]
> = {
  fl: { name: "fl", params: [], route: () => [RS.fl] },
  fl_global: {
    name: "fl_global",
    params: [],
    route: () => ["/", RS.fl, RS.global],
  },
  fl_global_offers: {
    name: "fl_global_offers",
    params: [],
    route: () => ["/", RS.fl, RS.global, RS.offers],
  },
  fl_global_offers_offer: {
    name: "fl_global_offers_offer",
    params: ["offer"],
    route: (offer: Offer) => ["/", RS.fl, RS.global, RS.offers, offer.id],
  },
  fl_global_offers_offer_articles: {
    name: "fl_global_offers_offer_articles",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.articles,
    ],
  },
  fl_global_offers_offer_destinations: {
    name: "fl_global_offers_offer_destinations",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.offernotificationdestinations,
    ],
  },
  fl_global_offers_offer_documents: {
    name: "fl_global_offers_offer_documents",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.documents,
    ],
  },
  fl_global_offers_offer_edit: {
    name: "fl_global_offers_offer_edit",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.edit,
    ],
  },
  fl_global_offers_offer_notifications: {
    name: "fl_global_offers_offer_notifications",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.offernotifications,
    ],
  },
  fl_global_offers_offer_notificationstatuses: {
    name: "fl_global_offers_offer_notificationstatuses",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.offernotificationstatuses,
    ],
  },
  fl_global_offers_offer_printpreview: {
    name: "fl_global_offers_offer_printpreview",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.print,
    ],
  },
  fl_global_offers_offer_products: {
    name: "fl_global_offers_offer_products",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.products,
    ],
  },
  fl_global_offers_offer_restos: {
    name: "fl_global_offers_offer_restos",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.restos,
    ],
  },
  fl_global_offers_offer_texts: {
    name: "fl_global_offers_offer_texts",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offers,
      offer.id,
      RS.texts,
    ],
  },
  fl_global_offertypestorages: {
    name: "fl_global_offertypestorages",
    params: [],
    route: () => ["/", RS.fl, RS.global, RS.offertypestorages],
  },
  fl_global_offertypestorages_offertypestorage: {
    name: "fl_global_offertypestorages_offertypestorage",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
    ],
  },
  fl_global_offertypestorages_offertypestorage_edit: {
    name: "fl_global_offertypestorages_offertypestorage_edit",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.edit,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers: {
    name: "fl_global_offertypestorages_offertypestorage_offers",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer: {
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
    ],
    name: "fl_global_offertypestorages_offertypestorage_offers_offer",
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_articles: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_articles",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.articles,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_destinations: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_destinations",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.offernotificationdestinations,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_documents: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_documents",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.documents,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_edit: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_edit",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.edit,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_notifications: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_notifications",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.offernotifications,
    ],
  },

  fl_global_offertypestorages_offertypestorage_offers_offer_notificationstatuses:
    {
      name: "fl_global_offertypestorages_offertypestorage_offers_offer_notificationstatuses",
      params: ["offertypestorage", "offer"],
      route: (offertypestorage: OffertypeStorage, offer: Offer) => [
        "/",
        RS.fl,
        RS.global,
        RS.offertypestorages,
        offertypestorage.id,
        RS.offers,
        offer.id,
        RS.offernotificationstatuses,
      ],
    },
  fl_global_offertypestorages_offertypestorage_offers_offer_printpreview: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_printpreview",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.print,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_products: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_products",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.products,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_restos: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_restos",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.restos,
    ],
  },
  fl_global_offertypestorages_offertypestorage_offers_offer_texts: {
    name: "fl_global_offertypestorages_offertypestorage_offers_offer_texts",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.texts,
    ],
  },
  fl_global_offertypestorages_offertypestorage_permissions: {
    name: "fl_global_offertypestorages_offertypestorage_permissions",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.global,
      RS.offertypestorages,
      offertypestorage.id,
      RS.permissions,
    ],
  },
  fl_global_producers: {
    name: "fl_global_producers",
    params: [],
    route: () => ["/", RS.fl, RS.global, RS.producers],
  },
  fl_global_producers_producer: {
    name: "fl_global_producers_producer",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
    ],
  },
  fl_global_producers_producer_documents: {
    name: "fl_global_producers_producer_documents",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.documents,
    ],
  },
  fl_global_producers_producer_edit: {
    name: "fl_global_producers_producer_edit",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.edit,
    ],
  },
  fl_global_producers_producer_offers: {
    name: "fl_global_producers_producer_offers",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.offers,
    ],
  },
  fl_global_producers_producer_products: {
    name: "fl_global_producers_producer_products",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.products,
    ],
  },
  fl_global_producers_producer_products_product: {
    name: "fl_global_producers_producer_products_product",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
    ],
  },
  fl_global_producers_producer_products_product_articles: {
    name: "fl_global_producers_producer_products_product_articles",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.articles,
    ],
  },
  fl_global_producers_producer_products_product_documents: {
    name: "fl_global_producers_producer_products_product_documents",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.documents,
    ],
  },
  fl_global_producers_producer_products_product_edit: {
    name: "fl_global_producers_producer_products_product_edit",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.edit,
    ],
  },
  fl_global_producers_producer_products_product_offers: {
    name: "fl_global_producers_producer_products_product_offers",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.offers,
    ],
  },
  fl_global_producers_producer_relations: {
    name: "fl_global_producers_producer_relations",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.global,
      RS.producers,
      producer.id,
      RS.relations,
    ],
  },
  fl_global_products: {
    name: "fl_global_products",
    params: [],
    route: () => ["/", RS.fl, RS.global, RS.products],
  },
  fl_global_products_product: {
    name: "fl_global_products_product",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.products,
      product.id,
    ],
  },
  fl_global_products_product_articles: {
    name: "fl_global_products_product_articles",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.products,
      product.id,
      RS.articles,
    ],
  },
  fl_global_products_product_documents: {
    name: "fl_global_products_product_documents",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.products,
      product.id,
      RS.documents,
    ],
  },
  fl_global_products_product_edit: {
    name: "fl_global_products_product_edit",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.products,
      product.id,
      RS.edit,
    ],
  },
  fl_global_products_product_offers: {
    name: "fl_global_products_product_offers",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.products,
      product.id,
      RS.offers,
    ],
  },
  fl_global_products_product_producer: {
    name: "fl_global_products_product_producer",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.global,
      RS.products,
      product.id,
      RS.producer,
    ],
  },
  fl_global_stats: {
    name: "fl_global_stats",
    params: [],
    route: () => ["/", RS.fl, RS.global, RS.stats],
  },
  fl_local: {
    name: "fl_local",
    params: [],
    route: () => ["/", RS.fl, RS.local],
  },
  fl_local_offers: {
    name: "fl_local_offers",
    params: [],
    route: () => ["/", RS.fl, RS.local, RS.offers],
  },
  fl_local_offers_offer: {
    name: "fl_local_offers_offer",
    params: ["offer"],
    route: (offer: Offer) => ["/", RS.fl, RS.local, RS.offers, offer.id],
  },
  fl_local_offers_offer_articles: {
    name: "fl_local_offers_offer_articles",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.articles,
    ],
  },
  fl_local_offers_offer_destinations: {
    name: "fl_local_offers_offer_destinations",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.offernotificationdestinations,
    ],
  },
  fl_local_offers_offer_documents: {
    name: "fl_local_offers_offer_documents",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.documents,
    ],
  },
  fl_local_offers_offer_edit: {
    name: "fl_local_offers_offer_edit",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.edit,
    ],
  },
  fl_local_offers_offer_notifications: {
    name: "fl_local_offers_offer_notifications",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.offernotifications,
    ],
  },
  fl_local_offers_offer_notificationstatuses: {
    name: "fl_local_offers_offer_notificationstatuses",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.offernotificationstatuses,
    ],
  },
  fl_local_offers_offer_printpreview: {
    name: "fl_local_offers_offer_printpreview",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.print,
    ],
  },
  fl_local_offers_offer_products: {
    name: "fl_local_offers_offer_products",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.products,
    ],
  },
  fl_local_offers_offer_restos: {
    name: "fl_local_offers_offer_restos",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.restos,
    ],
  },
  fl_local_offers_offer_texts: {
    name: "fl_local_offers_offer_texts",
    params: ["offer"],
    route: (offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offers,
      offer.id,
      RS.texts,
    ],
  },
  fl_local_offertypestorages: {
    name: "fl_local_offertypestorages",
    params: [],
    route: () => ["/", RS.fl, RS.local, RS.offertypestorages],
  },
  fl_local_offertypestorages_offertypestorage: {
    name: "fl_local_offertypestorages_offertypestorage",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
    ],
  },
  fl_local_offertypestorages_offertypestorage_edit: {
    name: "fl_local_offertypestorages_offertypestorage_edit",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.edit,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers: {
    name: "fl_local_offertypestorages_offertypestorage_offers",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
    ],
  },

  fl_local_offertypestorages_offertypestorage_offers_offer: {
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
    ],
    name: "fl_local_offertypestorages_offertypestorage_offers_offer",
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_articles: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_articles",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.articles,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_destinations: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_destinations",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.offernotificationdestinations,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_documents: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_documents",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.documents,
    ],
  },

  fl_local_offertypestorages_offertypestorage_offers_offer_edit: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_edit",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.edit,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_notifications: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_notifications",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.offernotifications,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_notificationstatuses:
    {
      name: "fl_local_offertypestorages_offertypestorage_offers_offer_notificationstatuses",
      params: ["offertypestorage", "offer"],
      route: (offertypestorage: OffertypeStorage, offer: Offer) => [
        "/",
        RS.fl,
        RS.local,
        RS.offertypestorages,
        offertypestorage.id,
        RS.offers,
        offer.id,
        RS.offernotificationstatuses,
      ],
    },
  fl_local_offertypestorages_offertypestorage_offers_offer_printpreview: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_printpreview",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.print,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_products: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_products",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.products,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_restos: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_restos",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.restos,
    ],
  },
  fl_local_offertypestorages_offertypestorage_offers_offer_texts: {
    name: "fl_local_offertypestorages_offertypestorage_offers_offer_texts",
    params: ["offertypestorage", "offer"],
    route: (offertypestorage: OffertypeStorage, offer: Offer) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.offers,
      offer.id,
      RS.texts,
    ],
  },

  fl_local_offertypestorages_offertypestorage_permissions: {
    name: "fl_local_offertypestorages_offertypestorage_permissions",
    params: ["offertypestorage"],
    route: (offertypestorage: OffertypeStorage) => [
      "/",
      RS.fl,
      RS.local,
      RS.offertypestorages,
      offertypestorage.id,
      RS.permissions,
    ],
  },
  fl_local_producers: {
    name: "fl_local_producers",
    params: [],
    route: () => ["/", RS.fl, RS.local, RS.producers],
  },
  fl_local_producers_producer: {
    name: "fl_local_producers_producer",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
    ],
  },
  fl_local_producers_producer_documents: {
    name: "fl_local_producers_producer_documents",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.documents,
    ],
  },
  fl_local_producers_producer_edit: {
    name: "fl_local_producers_producer_edit",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.edit,
    ],
  },
  fl_local_producers_producer_offers: {
    name: "fl_local_producers_producer_offers",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.offers,
    ],
  },
  fl_local_producers_producer_products: {
    name: "fl_local_producers_producer_products",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.products,
    ],
  },
  fl_local_producers_producer_products_product: {
    name: "fl_local_producers_producer_products_product",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
    ],
  },
  fl_local_producers_producer_products_product_articles: {
    name: "fl_local_producers_producer_products_product_articles",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.articles,
    ],
  },
  fl_local_producers_producer_products_product_documents: {
    name: "fl_local_producers_producer_products_product_documents",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.documents,
    ],
  },
  fl_local_producers_producer_products_product_edit: {
    name: "fl_local_producers_producer_products_product_edit",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.edit,
    ],
  },
  fl_local_producers_producer_products_product_offers: {
    name: "fl_local_producers_producer_products_product_offers",
    params: ["producer", "product"],
    route: (producer: Producer, product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.products,
      product.id,
      RS.offers,
    ],
  },
  fl_local_producers_producer_relations: {
    name: "fl_local_producers_producer_relations",
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.fl,
      RS.local,
      RS.producers,
      producer.id,
      RS.relations,
    ],
  },
  fl_local_products: {
    name: "fl_local_products",
    params: [],
    route: () => ["/", RS.fl, RS.local, RS.products],
  },
  fl_local_products_product: {
    name: "fl_local_products_product",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.products,
      product.id,
    ],
  },
  fl_local_products_product_articles: {
    name: "fl_local_products_product_articles",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.products,
      product.id,
      RS.articles,
    ],
  },
  fl_local_products_product_documents: {
    name: "fl_local_products_product_documents",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.products,
      product.id,
      RS.documents,
    ],
  },
  fl_local_products_product_edit: {
    name: "fl_local_products_product_edit",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.products,
      product.id,
      RS.edit,
    ],
  },
  fl_local_products_product_offers: {
    name: "fl_local_products_product_offers",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.products,
      product.id,
      RS.offers,
    ],
  },
  fl_local_products_product_producer: {
    name: "fl_local_products_product_producer",
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.fl,
      RS.local,
      RS.products,
      product.id,
      RS.producer,
    ],
  },
  fl_local_stats: {
    name: "fl_local_stats",
    params: [],
    route: () => ["/", RS.fl, RS.local, RS.stats],
  },
  fl_stats: {
    name: "fl_stats",
    params: [],
    route: () => ["/", RS.fl, RS.stats],
  },
};
