
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderPermission } from '../folder-permission';
@Component({
  selector: 'lvadg-folder-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './folder-permission-manage.component.pug',
  styleUrls: ['./folder-permission-manage.component.sass']
})
export class FolderPermissionManageComponent implements OnInit {
  @Input() public folderpermission?: FolderPermission;

  constructor() { }

  ngOnInit(): void {
  }

}
