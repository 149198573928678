import { Folder, folderTreeRoutes, RS } from '@vivalya/lvadg';
import { RoutesConfig } from '@solidev/data';

export const FRONT_DOCUMENTS_ROUTES_NAMES = ['documents', 'documents_folder'] as const;

export const FRONT_DOCUMENTS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_DOCUMENTS_ROUTES_NAMES)[number]> = {
  documents: {
    params: [],
    route: () => ['/', RS.documents],
    name: 'front_documents',
  },
  documents_folder: {
    params: ['folder'],
    route: (df: Folder) => ['/', RS.documents].concat(folderTreeRoutes(df)),
    name: 'front_documents_folder',
  },
};
