import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FamilyStatsService } from '../family-stats.service';
import { FilterData } from '@solidev/data';
import bb, { areaSplineRange, candlestick, spline } from 'billboard.js';
import { CommonModule } from '@angular/common';
import { FamilyStats } from '../family-stats';
import { tap } from 'rxjs';
import { AtscacheService } from '../atscache/atscache.service';
import { Atscache } from '../atscache/atscache';
import { group, map as d3map, mean } from 'd3-array';

@Component({
  selector: 'lvadg-tarif-family-stats',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-family-stats.component.pug',
  styleUrls: ['./tarif-family-stats.component.sass'],
})
export class TarifFamilyStatsComponent implements OnInit {
  @Input() public family?: FamilyStats;
  @Input() public filter: FilterData | null = {};
  @ViewChild('chart', { static: true })
  public chartDiv!: ElementRef<HTMLDivElement>;
  @ViewChild('detailchart', { static: true })
  public detailChartDiv!: ElementRef<HTMLDivElement>;

  constructor(
    private _details: AtscacheService,
    private _stats: FamilyStatsService
  ) {}

  public ngOnInit() {
    if (this.family) {
      this._details
        .list({
          by_family: this.family.id,
          ...(this.filter ? this.filter : {}),
          page_size: 1000,
          fields: [
            'tarif_details',
            'tariftype_details',
            'article_details',
          ].join(','),
        })
        .pipe(tap((d) => this.setDetailChart(d)))
        .subscribe((d) => console.log(d));

      this._stats
        .fetch(this.family.id, {
          params: {
            fields: ['id', 'tree_path', 'name', 'interval_stats'].join(','),
            ...(this.filter ? this.filter : {}),
          },
        })
        .pipe(
          tap((r) => {
            this.setChart(r);
          })
        )
        .subscribe();
    }
  }

  public setDetailChart(ats: Atscache[]) {
    const data = group(ats, (d) => d.article_details!.libelle);
    const byinterval = group(
      ats,
      (d) => d.date,
      (d) => d.article_details!.libelle
    );
    const chart = bb.generate({
      data: {
        x: 'x',
        columns: [
          ['x', ...byinterval.keys()],
          ...d3map(data.keys(), (name) => [
            name,
            ...d3map(byinterval.entries(), ([date, values]) => {
              const v = values.get(name);
              if (v) {
                const m = mean(v, (i) => i.price);
                return m !== undefined ? Math.round(m / 100) / 100 : null;
              }
              return null;
            }),
          ]),
        ],
        type: spline(),
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d',
          },
        },
      },
      legend: {
        hide: true,
      },
      tooltip: {
        order: 'desc',
      },
      bindto: this.detailChartDiv.nativeElement,
    });
  }

  public setChart(fam: FamilyStats) {
    const stats = fam.interval_stats!;
    const chart = bb.generate({
      data: {
        x: 'x',
        columns: [
          ['x', ...stats.intervals.map((i) => stats.get(i)!.date)],
          [
            'data',
            ...stats.intervals.map((i) => {
              const v = stats.get(i)!;
              return {
                open: Math.round((v.avg - v.sdev) / 100) / 100,
                close: Math.round((v.avg + v.sdev) / 100) / 100,
                high: Math.round(v.max / 100) / 100,
                low: Math.round(v.min / 100) / 100,
                volume: v.articles,
              };
            }),
          ],
          [
            'datas',
            ...stats.intervals.map((i) => {
              const v = stats.get(i)!;
              return [
                Math.round(v.max / 100) / 100,
                Math.round(v.avg / 100) / 100,
                Math.round(v.min / 100) / 100,
              ];
            }),
          ],
          [
            'datass',
            ...stats.intervals.map((i) => {
              const v = stats.get(i)!;
              return [
                Math.round((v.avg + v.sdev) / 100) / 100,
                Math.round(v.avg / 100) / 100,
                Math.round((v.avg - v.sdev) / 100) / 100,
              ];
            }),
          ],
        ],
        types: {
          data: candlestick(),
          datas: areaSplineRange(),
          datass: areaSplineRange(),
        }, // for ESM specify as: area()
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d',
          },
        },
      },
      bindto: this.chartDiv.nativeElement,
    });

    console.log(chart);
  }
}
