
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action level="h2" ri="document">
    <ng-container title>{{data.providerdocument.provider_details?.name}}</ng-container>
    <ng-container subtitle>{{data.providerdocument.filename}}
      <div><small class="ms-2">{{data.providerdocument.status|pchoice:data.providerdocument:'status'}}</small><small class="ms-2" *ngIf="data.providerdocument.start || data.providerdocument.end">| du {{data.providerdocument.start|date:'shortDate'}} au {{data.providerdocument.end|date:'shortDate'}}</small></div>
    </ng-container>
    <ng-container actions>
      <data-safe-delete [model]="data.providerdocument" display="button" (deleted)="_router.navigate(['..'], {relativeTo: _route})"></data-safe-delete>
    </ng-container>
  </lvadg-header-action>
  <div class="mx-2">
    <lvadg-provider-document-manage [providerdocument]="data.providerdocument"></lvadg-provider-document-manage>
  </div>
</ng-container>