
<ng-container *ngIf="ciqualalim">
  <dl class="row">
    <data-dispedit class="col-12" [model]="ciqualalim" field="alim_code" [editable]="false" mode="dd">Code aliment</data-dispedit>
    <data-dispedit class="col-4" [model]="ciqualalim" field="alim_grp_code" [editable]="false" mode="dd">Code groupe</data-dispedit>
    <data-dispedit class="col-4" [model]="ciqualalim" field="alim_ssgrp_code" [editable]="false" mode="dd">Code sous-groupe</data-dispedit>
    <data-dispedit class="col-4" [model]="ciqualalim" field="alim_ssssgrp_code" [editable]="false" mode="dd">Code sous-sous groupe</data-dispedit>
    <data-dispedit class="col-4" [model]="ciqualalim" field="alim_grp_nom_fr" [editable]="false" mode="dd">Nom groupe</data-dispedit>
    <data-dispedit class="col-4" [model]="ciqualalim" field="alim_ssgrp_nom_fr" [editable]="false" mode="dd">Nom sous-groupe</data-dispedit>
    <data-dispedit class="col-4" [model]="ciqualalim" field="alim_ssssgrp_nom_fr" [editable]="false" mode="dd">Nom sous-sous groupe</data-dispedit>
  </dl>
  <dl class="row mt-4">
    <data-dispedit class="col-6" [model]="ciqualalim" field="alim_nom_fr" [editable]="true" mode="dd">Nom aliment</data-dispedit>
    <data-dispedit class="col-6" [model]="ciqualalim" field="alim_nom_sci" [editable]="true" mode="dd">Nom scientifique aliment</data-dispedit>
  </dl>
</ng-container>