import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutData, LayoutTree } from '../../layout/layout';
import { TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from '../../layout/template';

@Component({
  selector: 'lvadg-layout-template-provider-single',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './layout-template-provider-single.component.pug',
  styleUrls: ['./layout-template-provider-single.component.sass'],
})
export class LayoutTemplateProviderSingleComponent {
  @Input() public tree!: LayoutTree;
  @Input() public layout!: LayoutData;
  @Input() public fragment!: TemplateFragment;
  @Input() public param!: TemplateParam;
  @Input() public provider!: TemplateProvider;

  public select(choice: TemplateChoice) {
    this.tree.set_fragment_param({
      layout: this.layout,
      group: this.layout.group,
      fragment: this.fragment,
      param: this.param,
      provider: this.provider,
      choice,
    });
  }
}
