
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferNotification } from '../offer-notification';
@Component({
  selector: 'lvadg-offer-notification-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-notification-manage.component.pug',
  styleUrls: ['./offer-notification-manage.component.sass']
})
export class OfferNotificationManageComponent implements OnInit {
  @Input() public offernotification?: OfferNotification;

  constructor() { }

  ngOnInit(): void {
  }

}
