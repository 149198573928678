import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Product } from "../../../../../models/catalog/product/product";
import { Link } from "@solidev/data";
import { PRODUCT_ROUTES } from "../menu";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { Producer } from "../../../../../models/structures/producer/producer";
import { Storage } from "../../../../../models/structures/storage/storage";
import { PRODUCT_USER_LEVELS } from "../../../../../models/catalog/product/product.base";
import { ADMIN_GROUPS } from "../../../../../constants";
import { AuthService } from "../../../../../models/users/auth.service";

@Component({
  selector: "lvadg-product-nav",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: "./product-nav.component.pug",
  styleUrl: "./product-nav.component.sass",
})
export class ProductNavComponent implements OnInit {
  @Input() public routes!: PRODUCT_ROUTES;
  @Input() public product!: Product;
  @Input() public producer?: Producer;
  @Input() public storage?: Storage;

  public product_detail_route?: Link<Product>;
  public product_edit_route?: Link<Product>;
  public product_documents_route?: Link<Product>;
  public product_articles_route?: Link<Product>;
  public product_offers_route?: Link<Product>;
  public product_producer_route?: Link<Product>;

  public readonly OUL = PRODUCT_USER_LEVELS;
  public canEdit = signal(false);
  public user = inject(AuthService).currentUser;

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes) {
      throw new Error("Missing routes input");
    }
    if (this.routes.product_detail_route) {
      this.product_detail_route = new Link(
        this.routes.product_detail_route,
        this,
        this._router,
      );
    }
    if (this.routes.product_edit_route) {
      this.product_edit_route = new Link(
        this.routes.product_edit_route,
        this,
        this._router,
      );
    }
    if (this.routes.product_documents_route) {
      this.product_documents_route = new Link(
        this.routes.product_documents_route,
        this,
        this._router,
      );
    }
    if (this.routes.product_articles_route) {
      this.product_articles_route = new Link(
        this.routes.product_articles_route,
        this,
        this._router,
      );
    }
    if (this.routes.product_offers_route) {
      this.product_offers_route = new Link(
        this.routes.product_offers_route,
        this,
        this._router,
      );
    }
    if (this.routes.product_producer_route) {
      this.product_producer_route = new Link(
        this.routes.product_producer_route,
        this,
        this._router,
      );
    }

    this.canEdit.set(
      !!(
        this.product.have_level(PRODUCT_USER_LEVELS.WRITE) ||
        this.user?.have_group(
          ADMIN_GROUPS.producers_editor,
          ADMIN_GROUPS.products_editor,
        )
      ),
    );
  }
}
