import {
  booleanField,
  charField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  integerField,
  primaryField,
} from '@solidev/data';

export class UserBase extends DataModel {
  static override readonly __name: string = 'UserBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'last_login',
    description: 'Dernière connexion',
    priority: 100,
  })
  public last_login!: Date;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 140,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 70,
  })
  public updated!: Date;

  @charField({
    name: 'username',
    description: "Nom d'utilisateur",
    help: 'Requis. 150 caractères maximum. Uniquement des lettres, nombres et les caractères @ + - _ .',
    maxLength: 150,
    priority: 900,
  })
  public username!: string;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 10,
    priority: 880,
  })
  public title!: string;

  @charField({
    name: 'first_name',
    description: 'Prénom',
    maxLength: 150,
    priority: 870,
  })
  public first_name!: string;

  @charField({
    name: 'last_name',
    description: 'Nom',
    maxLength: 150,
    priority: 860,
  })
  public last_name!: string;

  @charField({
    name: 'email',
    description: 'Email',
    maxLength: 254,
    priority: 850,
  })
  public email!: string;

  @charField({
    name: 'mobile',
    description: 'Portable',
    maxLength: 40,
    priority: 840,
  })
  public mobile!: string;

  @charField({
    name: 'phone',
    description: 'Téléphone',
    maxLength: 40,
    priority: 830,
  })
  public phone!: string;

  @booleanField({
    name: 'is_staff',
    description: 'Statut équipe',
    help: 'Accès admin ?',
    priority: 790,
  })
  public is_staff?: boolean;

  @booleanField({
    name: 'is_active',
    description: 'Actif',
    help: 'Compte actif ou non',
    priority: 780,
  })
  public is_active?: boolean;

  @datetimeField({
    name: 'date_joined',
    description: 'Date inscription',
    readonly: true,
    priority: 200,
  })
  public date_joined!: Date;

  @booleanField({
    name: 'is_superuser',
    description: 'Statut super-utilisateur',
    help: 'Super-utilisateur ?',
    defaultValue: false,
    required: true,
    priority: 770,
  })
  public is_superuser: boolean = false;

  @dateField({
    name: 'birth_date',
    description: 'Date de naissance',
    priority: 845,
  })
  public birth_date!: Date;

  @charField({
    name: 'orig_password',
    description: 'Mot de passe temporaire',
    maxLength: 50,
    priority: 750,
  })
  public orig_password!: string;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;

  public get _display(): string {
    return `${this.last_name} ${this.first_name} <${this.email}>`;
  }
}
