<a class="btn btn-link btn-sm w-100" *ngIf="account_route" [routerLink]="account_route.route()" fragment="tarifnotif">gestion des notifications</a>
<div class="btn-group">
  <button class="btn btn-sm" (click)="updateFilter('unread')" [class.btn-secondary]="notifstatus=='unread'" [class.btn-outline-secondary]="notifstatus!=='unread'"><i class="bi bi-eye-slash me-2"></i>Affichage des notifications non lues uniquement</button>
  <button class="btn btn-sm" (click)="updateFilter('all')" [class.btn-outline-secondary]="notifstatus!=='all'" [class.btn-secondary]="notifstatus=='all'"><i class="bi bi-eye me-2"></i>Affichage des notifications lues et non lues</button>
</div>
<ul class="list-group mt-2 scroll">
  <ng-container *ngFor="let n of notifications$|async">
    <ng-container *ngIf="n.tarif_details &amp;&amp; n.tariftype">
      <li class="list-group-item small d-flex flex-row align-content-between" [class.text-muted]="n.user_status===TNSTATUS.CLICKED" [class.text-danger]="n.priority==TNPRIO.P1 &amp;&amp; n.user_status!==TNSTATUS.CLICKED" [class.text-dark]="n.priority==TNPRIO.P2 &amp;&amp; n.user_status!==TNSTATUS.CLICKED" [class.text-secondary]="n.priority==TNPRIO.P3 &amp;&amp; n.user_status!==TNSTATUS.CLICKED">
        <div class="flex-grow-1"><a class="inlink" [routerLink]="tariftype_detail_route?.route({tariftype: $any({id: n.tariftype})})" (click)="toggleRead(n, TNSTATUS.CLICKED)" *ngIf="dest=='type'"><i class="bi bi-bell me-2"></i><span>{{n.tarif_details.name}}</span></a><a class="inlink" [routerLink]="tarif_detail_route?.route({tarif: n.tarif_details})" (click)="toggleRead(n, TNSTATUS.CLICKED)" *ngIf="dest=='tarif'"><i class="bi bi-bell me-2"></i><span>{{n.tarif_details.name}}</span></a>
          <div>{{n.title}} - {{n.created|date:'short'}}</div>
          <div *ngIf="n.priority==TNPRIO.P1 || n.priority==TNPRIO.P2">
            <div [innerHTML]="n.message"></div>
          </div>
        </div>
        <div class="ms-1"><i class="bi bi-eye-slash fs-3 pointer" (click)="toggleRead(n)" *ngIf="n.user_status!==TNSTATUS.CLICKED" title="Marquer comme lu"></i><i class="bi bi-eye fs-3 pointer" (click)="toggleRead(n)" *ngIf="n.user_status==TNSTATUS.CLICKED" title="Marquer comme non lu"></i></div>
      </li>
    </ng-container>
  </ng-container>
</ul>
<div class="d-flex justify-content-around mt-3">
  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [directionLinks]="true" [maxSize]="4" (pageChange)="pageChange($event)" [collectionSize]="(notifqs.meta|async)?.nav?.count || 0"></ngb-pagination>
</div>