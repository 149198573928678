import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams } from '@solidev/data';
import { TariftypeRegion } from '../../../../models/tarifs/tariftype-region/tariftype-region';

export interface TariftypeRegionDetailViewParams extends BaseRouteParams {}

export interface TariftypeRegionDetailViewData extends TariftypeRegionDetailViewParams {
  tariftyperegion: TariftypeRegion;
}

@Component({
  selector: 'lvadg-tariftype-region-detail-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-region-detail-view.component.pug',
  styleUrls: ['./tariftype-region-detail-view.component.sass'],
})
export class TariftypeRegionDetailViewComponent {}
