import { OffertypeTextTypeBase } from "./offertype-text-type.base";
import { detailsField } from "@solidev/data";
import { Offertype } from "../offertype/offertype";
import { Genericregion } from "../../locations/genericregion/genericregion";

export class OffertypeTextType extends OffertypeTextTypeBase {
  static override readonly __name: string = "OffertypeTextType";

  @detailsField({
    name: "offertype_details",
    description: "Modèle d'offre",
    readonly: true,
    model: Offertype,
    priority: 900,
  })
  public offertype_details?: Offertype;

  @detailsField({
    name: "region_details",
    description: "Region",
    readonly: true,
    model: Genericregion,
    priority: 800,
  })
  public region_details?: Genericregion;
}
