import {
  charField,
  DataModel,
  datetimeField,
  decimalField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from "@solidev/data";
import { Units, UnitsChoices } from "../common/units";
import { OrigineGlob, OriginGlobChoices } from "../common/origins";
import {
  EGALIM_STATUS,
  FAMILY_DISPLAY_MODE_CHOICES,
  FAMILY_DISPLAY_MODES,
} from "../common";

export enum ArticleDlcUnit {
  DAYS = "J",
  MONTHS = "M",
}

export enum ArticleDdmUnit {
  DAYS = "J",
  MONTHS = "M",
}

export enum ArticleLocal {
  LOCAL = "L",
  ULTRALOCAL = "U",
  NONE = "-",
}

export enum ARTICLE_TYPES {
  FL = "FL",
  SEA = "SEA",
  OG = "OG",
}

export enum ARTICLE_DECONDI_MODES {
  NO_DECONDI = "NO",
  DECONDI_KG = "DK",
  DECONDI_PCE = "DP",
}

export class ArticleBase extends DataModel {
  static override readonly __name: string = "ArticleBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: "flags",
    description: "Flags",
    defaultValue: [],
    priority: 110,
  })
  public flags: string[] = [];

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @detailsField({
    name: "seasons",
    description: "Saisonnalité",
    defaultValue: [],
    priority: 690,
  })
  public seasons: number[] = [];

  @foreignKeyField({
    name: "logo",
    description: "ID Logo",
    related: "Image",
    priority: -1,
  })
  public logo!: number;

  @foreignKeyField({
    name: "clogo",
    description: "ID Logo hérité",
    related: "Image",
    priority: -1,
  })
  public clogo!: number;

  @integerField({
    name: "vvid",
    description: "ID Vivalya",
    priority: -1,
  })
  public vvid!: number;

  @foreignKeyField({
    name: "family",
    description: "ID Famille",
    related: "Family",
    priority: -1,
  })
  public family!: number;

  @charField({
    name: "code",
    description: "Code",
    maxLength: 50,
    priority: 980,
  })
  public code!: string;

  @charField({
    name: "name",
    description: "Nom article",
    maxLength: 255,
    priority: 950,
  })
  public name!: string;

  @charField({
    name: "libelle",
    description: "Libellé",
    maxLength: 255,
    priority: 970,
  })
  public libelle!: string;

  @charField({
    name: "libelle_auto",
    description: "Libellé auto",
    maxLength: 255,
    priority: 960,
  })
  public libelle_auto!: string;

  @charField({
    name: "scname",
    description: "Nom scientifique",
    maxLength: 255,
    priority: 940,
  })
  public scname!: string;

  @foreignKeyField({
    name: "production",
    description: "ID Production",
    related: "Production",
    priority: -1,
  })
  public production!: number;

  @foreignKeyField({
    name: "calibre",
    description: "ID Calibre",
    related: "Calibre",
    priority: -1,
  })
  public calibre!: number;

  @foreignKeyField({
    name: "categorie",
    description: "ID Catégorie",
    related: "Category",
    priority: -1,
  })
  public categorie!: number;

  @integerField({
    name: "ciqual",
    description: "ID Ciqual",
    priority: -1,
  })
  public ciqual!: number;

  @foreignKeyField({
    name: "ciqual_alim",
    description: "ID Ciqual Alim",
    priority: -1,
  })
  public ciqual_alim?: number;

  @foreignKeyField({
    name: "colisage_cond",
    description: "ID Conditionnement colisage",
    related: "Packaging",
    priority: -1,
  })
  public colisage_cond!: number;

  @decimalField({
    name: "colisage_nombre",
    description: "Nombre par colisage",
    factor: 10000,
    priority: 800,
  })
  public colisage_nombre!: number;

  @decimalField({
    name: "colisage_poids",
    description: "Poids colisage",
    factor: 10000,
    priority: 780,
  })
  public colisage_poids!: number;

  @charField({
    name: "colisage_unit",
    description: "Unité colisage",
    maxLength: 2,
    choices: UnitsChoices,
    priority: 770,
  })
  public colisage_unit!: Units;

  @charField({
    name: "colisage",
    description: "Colisage",
    readonly: true,
    priority: 810,
  })
  public colisage!: string;

  @charField({
    name: "decondi_mode",
    description: "Mode deconditionnement",
    maxLength: 2,
    choices: [
      {
        value: ARTICLE_DECONDI_MODES.NO_DECONDI,
        desc: "Aucun déconditionnement",
      },
      {
        value: ARTICLE_DECONDI_MODES.DECONDI_KG,
        desc: "Déconditionnement au kg",
      },
      {
        value: ARTICLE_DECONDI_MODES.DECONDI_PCE,
        desc: "Déconditionnement à la pièce",
      },
    ],
    priority: 750,
  })
  public decondi_mode?: ARTICLE_DECONDI_MODES;

  @integerField({
    name: "dlc_value",
    description: "Valeur DLC",
    priority: 680,
  })
  public dlc_value!: number;

  @charField({
    name: "dlc_unit",
    description: "Unité DLC",
    maxLength: 1,
    choices: [
      {
        value: ArticleDlcUnit.DAYS,
        desc: "Jours",
      },
      {
        value: ArticleDlcUnit.MONTHS,
        desc: "Mois",
      },
    ],
    priority: 670,
  })
  public dlc_unit!: ArticleDlcUnit;

  @integerField({
    name: "ddm_value",
    description: "Valeur DDM",
    priority: 660,
  })
  public ddm_value!: number;

  @charField({
    name: "ddm_unit",
    description: "Unité DDM",
    maxLength: 1,
    choices: [
      {
        value: ArticleDdmUnit.DAYS,
        desc: "Jours",
      },
      {
        value: ArticleDdmUnit.MONTHS,
        desc: "Mois",
      },
    ],
    priority: 650,
  })
  public ddm_unit!: ArticleDdmUnit;

  @foreignKeyField({
    name: "fournisseur",
    description: "ID Fournisseur",
    related: "Provider",
    priority: -1,
  })
  public fournisseur!: number;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: ARTICLE_TYPES.FL,
    maxLength: 3,
    choices: [
      {
        value: ARTICLE_TYPES.FL,
        desc: "F&L",
      },
      {
        value: ARTICLE_TYPES.SEA,
        desc: "Marée",
      },
      {
        value: ARTICLE_TYPES.OG,
        desc: "4-5e gamme",
      },
    ],
    readonly: true,
    priority: 900,
  })
  public type: ARTICLE_TYPES = ARTICLE_TYPES.FL;

  @charField({
    name: "local",
    description: "Local ?",
    defaultValue: ArticleLocal.NONE,
    maxLength: 1,
    choices: [
      {
        value: ArticleLocal.LOCAL,
        desc: "Local",
      },
      {
        value: ArticleLocal.ULTRALOCAL,
        desc: "Ultra Local",
      },
      {
        value: ArticleLocal.NONE,
        desc: "Non local / non précisé",
      },
    ],
    priority: 860,
  })
  public local: ArticleLocal = ArticleLocal.NONE;

  @foreignKeyField({
    name: "marque",
    description: "ID Marque",
    related: "Brand",
    priority: -1,
  })
  public marque!: number;

  @foreignKeyField({
    name: "origine",
    description: "ID Origine",
    related: "Origin",
    priority: -1,
  })
  public origine!: number;

  @charField({
    name: "origine_glob",
    description: "Origine globale",
    defaultValue: OrigineGlob.NOTSET,
    maxLength: 3,
    choices: OriginGlobChoices,
    priority: 870,
  })
  public origine_glob: OrigineGlob = OrigineGlob.NOTSET;

  @charField({
    name: "egalim_status",
    description: "État Égalim",
    defaultValue: EGALIM_STATUS.NON_EGALIM,
    maxLength: 2,
    choices: [
      { value: EGALIM_STATUS.NON_EGALIM, desc: "Non égalim" },
      { value: EGALIM_STATUS.EQ_EGALIM, desc: "Eq. égalim" },
      { value: EGALIM_STATUS.EGALIM, desc: "Egalim" },
    ],
    priority: 840,
  })
  public egalim_status: EGALIM_STATUS = EGALIM_STATUS.NON_EGALIM;

  @foreignKeyField({
    name: "particularite",
    description: "ID Particularité",
    related: "Particularity",
    priority: -1,
  })
  public particularite!: number;

  @decimalField({
    name: "poids_min",
    description: "Poids min (kg)",
    factor: 10000,
    priority: 640,
  })
  public poids_min!: number;

  @decimalField({
    name: "poids_max",
    description: "Poids max (kg)",
    factor: 10000,
    priority: 630,
  })
  public poids_max!: number;

  @decimalField({
    name: "poids_moyen",
    description: "Poids moyen (kg)",
    factor: 10000,
    priority: 635,
  })
  public poids_moyen!: number;

  @foreignKeyField({
    name: "presentation",
    description: "ID Présentation",
    related: "Presentation",
    priority: -1,
  })
  public presentation!: number;

  @foreignKeyField({
    name: "process",
    description: "ID Process",
    related: "Process",
    priority: -1,
  })
  public process!: number;

  @textField({
    name: "recette",
    description: "Recette",
    priority: 620,
  })
  public recette!: string;

  @foreignKeyField({
    name: "region",
    description: "ID Région",
    related: "Region",
    priority: -1,
  })
  public region!: number;

  @integerField({
    name: "rnm_code",
    description: "ID RNM",
    priority: -1,
  })
  public rnm_code!: number;

  @charField({
    name: "temperature",
    description: "Température",
    maxLength: 50,
    priority: 600,
  })
  public temperature!: string;

  @foreignKeyField({
    name: "traitement",
    description: "ID Traitement",
    related: "Treatment",
    priority: -1,
  })
  public traitement!: number;

  @foreignKeyField({
    name: "uci_cond",
    description: "ID Conditionnement UCI",
    related: "Packaging",
    priority: -1,
  })
  public uci_cond!: number;

  @decimalField({
    name: "uci_value",
    description: "Valeur UCI",
    factor: 100000,
    priority: 740,
  })
  public uci_value!: number;

  @charField({
    name: "uci_unit",
    description: "Unité UCI",
    maxLength: 2,
    choices: UnitsChoices,
    priority: 730,
  })
  public uci_unit!: Units;

  @charField({
    name: "uci",
    description: "UCI",
    readonly: true,
    priority: 760,
  })
  public uci!: string;

  @charField({
    name: "udf_unit",
    description: "Unité de facturation",
    maxLength: 2,
    choices: UnitsChoices,
    priority: 920,
  })
  public udf_unit!: Units;

  @charField({
    name: "url",
    description: "URL",
    maxLength: 255,
    priority: 570,
  })
  public url!: string;

  @decimalField({
    name: "udv_value",
    description: "Valeur UDV",
    factor: 100000,
    priority: 900,
  })
  public udv_value!: number;

  @charField({
    name: "udv_unit",
    description: "Unité de vente",
    maxLength: 2,
    choices: UnitsChoices,
    priority: 910,
  })
  public udv_unit!: Units;

  @foreignKeyField({
    name: "usage",
    description: "ID Usage",
    related: "Usage",
    priority: -1,
  })
  public usage!: number;

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -1000,
  })
  public orig_id!: number;

  @manyToManyField({
    name: "images",
    description: "IDs images",
    defaultValue: [],
    related: "Image",
    priority: -1,
  })
  public images: number[] = [];

  @manyToManyField({
    name: "allergenes",
    description: "IDs Allergènes",
    defaultValue: [],
    related: "Allergen",
    priority: -1,
  })
  public allergenes: number[] = [];

  @manyToManyField({
    name: "egalim",
    description: "IDs Egalims",
    defaultValue: [],
    related: "Label",
    priority: -1,
  })
  public egalim: number[] = [];

  @charField({
    name: "dmode",
    readonly: true,
    description: "Mode affichage",
    priority: 700,
    choices: FAMILY_DISPLAY_MODE_CHOICES,
  })
  public dmode: FAMILY_DISPLAY_MODES = FAMILY_DISPLAY_MODES.UNKNOWN;

  public get gamme(): string | undefined {
    return "";
  }

  public get _display(): string {
    return `[${this.code}] ${this.libelle}`;
  }
}
