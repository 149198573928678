import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Eamapping } from '../eamapping';
import { EamappingService } from '../eamapping.service';
import {
  DispeditComponent,
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ArticleBase } from '../../article/article.base';
import { EarticleBase } from '../../earticle/earticle.base';
import { TariftypeBase } from '../../../tarifs/tariftype/tariftype.base';
import { ArticleDisplayComponent } from '../../article/article-display/article-display.component';
import { EarticleDisplayComponent } from '../../earticle/earticle-display/earticle-display.component';
import { FamilyDisplayComponent } from '../../family/family-display/family-display.component';
import { LabelDisplayComponent } from '../../label/label-display/label-display.component';
import { EAMAPPING_STATUS } from '../eamapping.base';
import { TariftypeActionService } from '../../../tarifs/tarif/tariftype-action.service';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';

@Component({
  selector: 'lvadg-eamapping-list',
  standalone: true,
  templateUrl: './eamapping-list.component.pug',
  styleUrls: ['./eamapping-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
    DispeditComponent,
    FamilyDisplayComponent,
    TariftypeDisplayComponent,
    LabelDisplayComponent,
    SafeDeleteComponent,
    PChoicePipe,
  ],
})
export class EamappingListComponent extends ModellistComponent<Eamapping> {
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public tarifType?: TariftypeBase;

  public STT = EAMAPPING_STATUS;

  constructor(coll: EamappingService, list: ModelListService, ofc: NgbOffcanvas, private _tta: TariftypeActionService) {
    super(coll, list, ofc);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.maxPages = 1000;
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'statuses'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectMultiFilter({
          field: 'statuses',
          name: 'statuses',
          model: Eamapping,
          mfield: 'status',
          label: 'Recherche par état(s)',
        }),
        new ModelListSelectFilter({
          field: 'multiples',
          name: 'multiples',
          label: 'Recherche multiple(s)',
          choices: [
            {
              value: 'article',
              desc: 'Même article catalogue - plusieurs articles client',
            },
            {
              value: 'eaclient',
              desc: 'Même article client - plusieurs article catalogue',
            },
          ],
        }),
      ],
    };
  }

  public override getRowClasses(row: Eamapping): string[] {
    const cls = super.getRowClasses(row);
    switch (row.status) {
      case EAMAPPING_STATUS.IN_TARIF:
        cls.push('text-success');
        break;
      case EAMAPPING_STATUS.INSIGNIFIANT:
        cls.push('text-muted');
        break;
      case EAMAPPING_STATUS.NOT_PROCESSED:
        cls.push('text-warning');
        break;
      case EAMAPPING_STATUS.PROBLEM:
        cls.push('text-danger');
        break;
      case EAMAPPING_STATUS.TARGETTED:
        cls.push('text-primary');
        break;
    }
    return cls;
  }

  public async setStatusAction(item: Eamapping | undefined, status: EAMAPPING_STATUS) {
    console.log('Set eamapping status', item, status);
    if (this.tarifType && item) {
      await this._tta.action(this.tarifType, {
        action: 'eamapping_change_status',
        eamapping: item.id,
        status: status,
      });
    }
    this.ofcInstance?.close();
  }
}
