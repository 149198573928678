import { ArticleDocumentBase } from "./article-document.base";
import { detailsField } from "@solidev/data";
import { Image } from "../../documents/image/image";
import { ImageDisplay } from "../../documents/image/image.base";
import { Label } from "../label/label";
import { ArticleBase } from "../article/article.base";

export class ArticleDocument extends ArticleDocumentBase {
  static override readonly __name: string = "ArticleDocument";
  @detailsField({
    description: "Preview",
    model: Image,
    readonly: true,
    priority: 890,
  })
  public image_details?: ImageDisplay;

  @detailsField({
    description: "Label",
    model: Label,
    readonly: true,
    priority: 900,
  })
  public label_details?: Label;

  @detailsField({
    description: "Articles",
    model: ArticleBase,
    readonly: true,
    priority: 750,
    many: true,
  })
  public articles_details?: ArticleBase[];
}
