
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeRegionPermission } from '../tariftype-region-permission';
@Component({
  selector: 'lvadg-tariftype-region-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-region-permission-manage.component.pug',
  styleUrls: ['./tariftype-region-permission-manage.component.sass']
})
export class TariftypeRegionPermissionManageComponent implements OnInit {
  @Input() public tariftyperegionpermission?: TariftypeRegionPermission;

  constructor() { }

  ngOnInit(): void {
  }

}
