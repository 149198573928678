import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TariftypeRelationService } from './tariftype-relation.service';
import { TariftypeRelation } from './tariftype-relation';

@Injectable({
  providedIn: 'root',
})
export class TariftypeRelationResolver  {
  constructor(private _rs: TariftypeRelationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TariftypeRelation> {
    return this._rs.fetch(+route.params['tariftyperelationId']);
  }
}
