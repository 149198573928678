import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderprocess } from '../orderprocess';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OrderprocessService } from '../orderprocess.service';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-orderprocess-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './orderprocess-create.component.pug',
  styleUrls: ['./orderprocess-create.component.sass'],
})
export class OrderprocessCreateComponent implements OnInit {
  public ops!: Orderprocess;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<Orderprocess>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(private _ops: OrderprocessService) {}

  ngOnInit(): void {
    this.ops = new Orderprocess(this._ops);
  }

  async save() {
    this.ops.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.ops.save({ updateModel: true }));
    this.created.emit(this.ops);
  }
}
