import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifRelation } from '../tarif-relation';
import { TariftypeRelation } from '../../tariftype-relation/tariftype-relation';
import { TariftypeRelationType } from '../../tariftype-relation/tariftype-relation.base';
import { TarifDisplayComponent } from '../../tarif/tarif-display/tarif-display.component';
import { TARIF_STATUS, TarifBase } from '../../tarif/tarif.base';
import { DataMessageService, Link } from '@solidev/data';
import { TarifRelationService } from '../tarif-relation.service';
import { TarifActionService } from '../../tarif/tarif-action.service';
import { Tarif } from '../../tarif/tarif';

@Component({
  selector: 'lvadg-tarif-relation-manage',
  standalone: true,
  imports: [CommonModule, TarifDisplayComponent],
  templateUrl: './tarif-relation-manage.component.pug',
  styleUrls: ['./tarif-relation-manage.component.sass'],
})
export class TarifRelationManageComponent {
  @Input() public dest_tarif?: Tarif;
  @Input() public tariftyperelation?: TariftypeRelation;
  @Input() public tarifrelation?: TarifRelation;
  @Input() public mode: 'inline' = 'inline';
  @Input() public tarif_detail_route?: Link<TarifBase>;
  public running = false;

  @Output() public action = new EventEmitter<void>();

  public TT_RELATION_TYPE = TariftypeRelationType;
  public TARIF_STATUS = TARIF_STATUS;

  constructor(
    private _msgs: DataMessageService,
    private _trs: TarifRelationService,
    private _tas: TarifActionService,
  ) {}

  public get canAct(): boolean {
    if (!this.dest_tarif || !this.tarifrelation) {
      return false;
    }
    if (!this.dest_tarif.isEditable) {
      console.log(this.dest_tarif);
      this._msgs.error('Le tarif doit être en mode préparation pour pouvoir effectuer une action');
      return false;
    }
    return true;
  }

  public async process_action() {
    if (!this.canAct || this.running) {
      return;
    }
    this.running = true;
    await this._tas.action(this.dest_tarif!, {
      action: 'relation_action',
      relation: this.tarifrelation!.id,
    });
    this.action.emit();
    this.running = false;
  }

  public async delete() {
    if (this.canAct && !this.running) {
      this.running = true;
      await this._tas.action(this.dest_tarif!, {
        action: 'relation_remove',
        relation: this.tarifrelation!.id,
      });
      this.action.emit();
      this.running = false;
    }
  }
}
