import {
  charField,
  DataModel,
  datetimeField,
  primaryField,
} from '@solidev/data';

export class MeteoBase extends DataModel {
  static override readonly __name: string = 'MeteoBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @charField({
    name: 'type',
    description: 'type',
    maxLength: 3,
  })
  public type!: string;

  @charField({
    name: 'location',
    description: 'location',
    maxLength: 100,
  })
  public location!: string;

  @datetimeField({
    name: 'date',
    description: 'date',
    readonly: true,
  })
  public date!: Date;

  @charField({
    name: 'data',
    description: 'data',
    maxLength: 100,
  })
  public data!: JSON;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

}
