
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class EacollectionBase extends DataModel {
  static override readonly __name: string = "EacollectionBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @detailsField({"name": "flags", "description": "flags", "defaultValue": []})
  public flags: string[] = [];

  @datetimeField({"name": "created", "description": "created", "readonly": true})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated", "readonly": true})
  public updated!: Date;

  @charField({"name": "name", "description": "name", "maxLength": 100})
  public name!: string;

  @charField({"name": "type", "description": "type", "defaultValue": "STO", "maxLength": 3, "choices": [{"value": "STO", "desc": "Groupe de d\u00e9p\u00f4ts"}]})
  public type: string = "STO";

  @integerField({"name": "orig_id", "description": "orig id"})
  public orig_id!: number;

  @manyToManyField({"name": "storages", "description": "storages", "defaultValue": [], "related": "Storage"})
  public storages: number[] = [];


}
