
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-orderprocess-permission-manage [orderprocesspermission]="data.orderprocesspermission"></lvadg-orderprocess-permission-manage>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>