import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Document } from '../document';
import { DocumentService } from '../document.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { MimetypeIconComponent } from '../../../../components/utils/mimetype-icon/mimetype-icon.component';
import { ImageDisplayComponent } from '../../image/image-display/image-display.component';

@Component({
  selector: 'lvadg-document-list',
  standalone: true,
  templateUrl: './document-list.component.pug',
  styleUrls: ['./document-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FlagsDisplayComponent,
    MimetypeIconComponent,
    ImageDisplayComponent,
    PChoicePipe,
  ],
})
export class DocumentListComponent extends ModellistComponent<Document> {
  constructor(
    coll: DocumentService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
