import { Inject, Injectable } from "@angular/core";
import { AuthService } from "../users/auth.service";
import { map, of, switchMap } from "rxjs";
import { Storage } from "../structures/storage/storage";
import { PrintContext } from "./print-settings";
import { Member } from "../structures/member/member";
import { Client } from "../structures/client/client";
import { Resto } from "../structures/resto/resto";
import { DATA_AUTH_SERVICE } from "@solidev/data";

export interface UserContext {
  storage?: Storage;
  member?: Member;
  client?: Client;
  resto?: Resto;
}

@Injectable({
  providedIn: "root",
})
export class UserPrintContextService {
  private _context: UserContext = {};

  constructor(@Inject(DATA_AUTH_SERVICE) private _auth: AuthService) {
    this._auth.currentUser$
      .pipe(
        switchMap((user) => {
          if (user && user.id) {
            return user.action<unknown, UserContext>("GET", "context");
          } else {
            return of({} as UserContext);
          }
        }),
        map((context: UserContext) => {
          console.log("Setting print context", context);
          if (context.storage) {
            const storage = new Storage();
            storage.fromJson(context.storage);
            context.storage = storage;
          }
          if (context.member) {
            const member = new Member();
            member.fromJson(context.member);
            context.member = member;
          }
          if (context.client) {
            const client = new Client();
            client.fromJson(context.client);
            context.client = client;
          }
          if (context.resto) {
            const resto = new Resto();
            resto.fromJson(context.resto);
            context.resto = resto;
          }
          return context;
        }),
      )
      .subscribe((context) => {
        this._context = context;
      });
  }

  public update_context(ctx: PrintContext) {
    if (this._context.storage && !ctx.storage) {
      ctx.storage = this._context.storage;
    }
    if (this._context.member && !ctx.member) {
      ctx.member = this._context.member;
    }
    if (this._context.client && !ctx.client) {
      ctx.client = this._context.client;
    }
    if (this._context.resto && !ctx.resto) {
      ctx.resto = this._context.resto;
    }
  }
}
