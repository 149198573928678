import { InjectionToken } from "@angular/core";

export const RS = {
  access: "acces",
  account: "account",
  admin: "admin",
  aliment: "aliment",
  aliments: "aliments",
  allergen: "allergen",
  allergens: "allergens",
  article: "article",
  articles: "articles",
  articledocument: "articledocument",
  articledocuments: "articledocuments",
  articletariflogs: "articletariflogs",
  articletariftemplate: "att",
  articletariftemplaterelation: "attr",
  articletariftemplaterelations: "attrs",
  articletariftemplates: "atts",
  atcache: "atcache",
  atcaches: "atcaches",
  att: "att",
  atts: "atts",
  bill: "facture",
  billitem: "lignefacture",
  billitems: "lignesfacture",
  bills: "factures",
  brand: "brand",
  brands: "brands",
  calibre: "calibre",
  calibres: "calibres",
  catalog: "catalogue",
  categories: "categories",
  category: "category",
  cdata: "custom_data",
  cdatakey: "key",
  cdatakeychoice: "choice",
  cdatakeychoices: "chocies",
  cdatakeys: "keys",
  cdatas: "custom_datas",
  ciqual: "ciqual",
  client: "client",
  clients: "clients",
  column: "colonne",
  columns: "colonnes",
  comment: "commentaire",
  comments: "commentaires",
  contact: "contact",
  contacts: "contacts",
  cotation: "cotation",
  cotations: "cotations",
  distance: "distance",
  distances: "distances",
  document: "document",
  documents: "documents",
  eacollection: "eacollection",
  eacollections: "eacollections",
  earticle: "earticle",
  earticles: "earticles",
  edit: "edition",
  families: "familles",
  family: "famille",
  fl: "fl",
  folder: "dossier",
  folders: "dossiers",
  free: "free",
  genericregion: "gregion",
  genericregions: "gregions",
  geocommune: "commune",
  geocommunes: "communes",
  geocountries: "pays",
  geocountry: "pays",
  geodepartement: "departement",
  geodepartements: "departements",
  georegion: "region",
  georegions: "regions",
  global: "national",
  group: "groupe",
  groups: "groupes",
  iocode: "iocode",
  iocodes: "iocodes",
  label: "label",
  labels: "labels",
  layout: "layout",
  layouts: "layouts",
  libelle: "libelle",
  libelles: "libelles",
  lifecycle: "lifecycle",
  lifecycles: "lifecycles",
  lifecyclestep: "lifecyclestep",
  lifecyclesteps: "lifecyclesteps",
  local: "local",
  location: "localisation",
  locations: "localisations",
  login: "login",
  logout: "logout",
  logusages: "logusages",
  mappings: "mappings",
  marche: "marche",
  marches: "marches",
  match: "match",
  members: "adherents",
  meta: "meta",
  meteo: "meteo",
  news: "actualites",
  newscategories: "actucategories",
  newscategory: "actucategorie",
  offer: "offer",
  offernotification: "onotification",
  offernotificationdestination: "ondest",
  offernotificationdestinations: "ondests",
  offernotifications: "onotifications",
  offernotificationstatus: "onstatus",
  offernotificationstatuses: "onstatuses",
  offerproduct: "oproduct",
  offerproducts: "oproducts",
  offers: "offers",
  offertype: "offertype",
  offertypes: "offertypes",
  offertypestorage: "otstorage",
  offertypestorages: "otstorages",
  order: "order",
  orderitem: "orderitem",
  orderitems: "orderitems",
  orders: "orders",
  origin: "origin",
  origins: "origins",
  packaging: "packaging",
  packagings: "packagings",
  particularities: "particularities",
  particularity: "particularity",
  permission: "permission",
  permissions: "permissions",
  pilote: "pilote",
  platform: "platform",
  platforms: "platforms",
  presentation: "presentation",
  presentations: "presentations",
  price: "price",
  prices: "prices",
  print: "print",
  prints: "prints",
  process: "process",
  processes: "processes",
  producer: "producteur",
  producerdocument: "docproducteur",
  producerdocuments: "docsproducteur",
  providerdocument: "docfournisseur",
  providerdocuments: "docsfournisseur",
  producers: "producteurs",
  product: "produit",
  production: "production",
  productions: "productions",
  products: "produits",
  provider: "fournisseur",
  providers: "fournisseurs",
  pwreset: "pwreset",
  quality: "qualite",
  reactivate: "reactivate",
  region: "region",
  regionpermission: "regionpermission",
  regionpermissions: "regionpermissions",
  regions: "regions",
  relation: "relation",
  relations: "relations",
  resto: "resto",
  restos: "restos",
  rnm: "rnm",
  rnms: "rnms",
  rnmtarifs: "rnmtarifs",
  sales: "ventes",
  sea: "maree",
  stats: "stats",
  storage: "depot",
  storagepermission: "storagepermission",
  storagepermissions: "storagepermissions",
  storages: "depots",
  structure: "structure",
  supportmessage: "supportmessage",
  supportmessages: "supportmessages",
  tarif: "tarif",
  tarifdocument: "tdoc",
  tarifdocuments: "tdocs",
  tariflogs: "tariflogs",
  tarifnotification: "tn",
  tarifnotificationdestination: "tndest",
  tarifnotificationdestinations: "tndests",
  tarifnotifications: "tns",
  tarifnotificationstatus: "tnstatus",
  tarifnotificationstatuses: "tnstatuses",
  tarifrelation: "trel",
  tarifrelations: "trels",
  tarifs: "tarifs",
  tariftype: "tariftype",
  tariftypedocument: "ttdoc",
  tariftypedocuments: "ttdocs",
  tariftypemetadata: "ttmeta",
  tariftypemetadatas: "ttmetas",
  tariftypepermission: "ttperm",
  tariftypepermissions: "ttperms",
  tariftyperelation: "ttrelation",
  tariftyperelations: "ttrelations",
  tariftypes: "tariftypes",
  text: "text",
  texts: "texts",
  texttype: "texttype",
  texttypes: "texttypes",
  treatment: "treatment",
  treatments: "treatments",
  usage: "usage",
  usages: "usages",
  user: "utilisateur",
  usermap: "umap",
  usermaplayer: "maplayer",
  usermaplayers: "maplayers",
  usermaps: "umaps",
  users: "utilisateurs",
  utils: "utils",
  value: "valeur",
  values: "valeurs",
  zone: "zone",
  zones: "zones",
};

export const RI = {
  access: "bi bi-key",
  add: "bi bi-plus-circle",
  allergen: "bi bi-hospital",
  archive: "bi bi-archive",
  bell: "bi bi-bell",
  catalog: "bi bi-stack",
  cdata: "bi bi-spreadsheet",
  cdatakey: "bi bi-spreadsheet",
  cdatakeychoice: "bi bi-spreadsheet",
  check: "bi bi-gear",
  client: "bi bi-briefcase",
  close: "bi bi-x-circle",
  collapse: "bi bi-chevron-double-up",
  contact: "bi bi-person-vcard",
  danger: "bi bi-exclamation-triangle",
  delete: "bi bi-trash",
  distance: "bi bi-rulers",
  document: "bi bi-archive",
  download: "bi bi-download",
  edit: "bi bi-pencil-square",
  excel: "bi bi-file-excel",
  expand: "bi bi-chevron-double-right",
  families: "icon icon-folder-tree",
  fiche: "icon icon-journal-richtext",
  fiches: "bi bi-journals",
  fl: "bi bi-basket2",
  flproducer: "bi bi-flower1",
  folder: "bi bi-folder",
  global: "bi bi-globe",
  group: "bi bi-people",
  home: "bi bi-house",
  help: "bi bi-question-circle",
  image: "bi bi-image",
  info: "bi bi-info-circle",
  iocode: "bi bi-qr-code",
  label: "bi bi-tags",
  list: "bi bi-list",
  location: "bi bi-geo-alt",
  login: "bi bi-box-arrow-left",
  logout: "bi bi-box-arrow-right",
  map: "bi bi-map",
  member: "bi bi-bank",
  message: "bi bi-chat-left",
  meteo: "bi bi-sun",
  national: "bi bi-globe",
  news: "bi bi-newspaper",
  offer: "bi bi-file-spreadsheet",
  order: "bi bi-cart-plus",
  orderprocess: "bi bi-cart-plus",
  pilote: "bi bi-list-columns",
  play: "bi bi-play-circle",
  plus: "bi bi-plus-circle",
  poster: "icon icon-file-earmark-image",
  print: "bi bi-printer",
  producer: "bi bi-gear",
  product: "bi bi-box",
  provider: "bi bi-truck",
  publish: "bi bi-cloud-upload",
  relation: "bi bi-arrow-left-right",
  reload: "bi bi-bootstrap-reboot",
  resto: "bi bi-shop",
  rnm: "bi bi-bar-chart",
  sales: "bi bi-receipt",
  save: "bi bi-save",
  sea: "icon icon-boat",
  seaproducer: "bi bi-water",
  search: "bi bi-search",
  send: "bi bi-send",
  stats: "bi bi-bar-chart-line",
  storage: "bi bi-truck",
  sync: "bi bi-arrow-clockwise",
  tarifs: "bi bi-table",
  tariftype: "bi bi-table",
  text: "bi bi-card-text",
  texts: "bi bi-card-text",
  user: "bi bi-person",
  utils: "bi bi-tools",
  vivalya: "bi bi-bank",
  merge: "bi bi-sign-merge-left",
  move: "bi bi-arrow-right",
} as const;

export const PRICE_FACTOR = 10000;
export const QUANTITY_FACTOR = 100000;

/**
 * Those special groups are FAKE groups, they are not stored in the database.
 */
export enum SPECIAL_GROUPS {
  /** Super utilisateur */
  superuser = "lvadg:staff:superuser",
  /** Staff (utilisateurs Vivalya) */
  staff = "lvadg:staff:staff",
  /** All users */
  all = "__all__",
}

export enum META_GROUPS {
  /**  Accés zone documents */
  documents = "meta:documents",
  /**  Accès zone produits */
  products = "meta:products",
  /**  Accés zone dépôts */
  storages = "meta:storages",
  /**  Accès zone catalogue */
  catalog = "meta:catalog",
  /**  Accès zone tarifs */
  tarifs = "meta:tarifs",
  /**  Accès zone pilote */
  pilote = "meta:pilote",
  /**  Accès zone RNM */
  rnm = "meta:rnm",
  /**  Accès zone clients */
  clients = "meta:clients",
  /**  Accès zone restos */
  restos = "meta:restos",
  /**  Accès zone fournisseurs */
  providers = "meta:providers",
  /**  Accès zone commandes */
  orders = "meta:orders",
  /**  Accès zone météo */
  meteo = "meta:meteo",
  /**  Accès zone actualités */
  news = "meta:news",
}

export enum LVADG_GROUPS {
  /** Adhérents */
  members = "lvadg:users:members",
  /** Clients */
  clients = "lvadg:users:clients",
  /** Restaurants */
  restos = "lvadg:users:restos",
}

export enum ADMIN_GROUPS {
  /** Editeur catalogue */
  catalog_editor = "admin:catalog:editor",
  asscom = "pilote:ttcli:asscom",
  respcom = "pilote:ttcli:respcom",
  beta_products = "users:beta:products",
  beta_providers = "users:beta:providers",
  beta_documents = "users:beta:documents",
  /** Éditeur documents */
  documents_editor = "admin:documents:editor",
  /** Éditeur produits */
  products_editor = "admin:products:editor",
  /** Éditeur producteurs */
  producers_editor = "admin:producers:editor",
  /** Éditeur fournisseurs */
  providers_editor = "admin:providers:editor",
}

export const DB_CHECKS = {
  "layouts.LayoutParam": "Paramètre(s) d'impression",
  "layouts.Layout": "Élément(s) d'impression",
  "layouts.Print": "Impression(s)",
  "catalog.Product_labels": "Associations produit / label",
  "structures.Producer_plabels": "Association producteur / label",
  "structures.Producer_iocodes": "QR Code producteur",
  "structures.Relation": "Relation(s)",
  "structures.ProducerDocument": "Document(s) producteur",
  "catalog.Product": "Produit(s)",
  "structures.Producer": "Producteur(s)",
  "structures.Producer_images": "Image(s) producteur",
  "offers.OfferProduct": "Produit(s) offre",
  "catalog.Product_iocodes": "QR Code produit",
  "structures.Provider_slabels": "Association fournisseur / label",
  "structures.ProviderDocument": "Document(s) fournisseur",
  "structures.Provider": "Fournisseur(s)",
};

export interface SITE_DEFAULT_PARAMS {
  MODELLIST_DEFAULT_POSITION?:
    | "ml-hidden"
    | "ml-over"
    | "ml-over-end"
    | "ml-pinned"
    | "ml-top";
  MODELLIST_SHOW_LISTNAME?: boolean;
}

export const SITE_DEFAULTS = new InjectionToken<SITE_DEFAULT_PARAMS>(
  "site.defaults",
);
