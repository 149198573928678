import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OffertypeStorage } from "../offertype-storage";
import { TariftypeService } from "../../../tarifs/tariftype/tariftype.service";
import { DispeditComponent, FlagsComponent } from "@solidev/data";

@Component({
  selector: "lvadg-offertype-storage-manage",
  standalone: true,
  imports: [CommonModule, DispeditComponent, FlagsComponent],
  templateUrl: "./offertype-storage-manage.component.pug",
  styleUrls: ["./offertype-storage-manage.component.sass"],
})
export class OffertypeStorageManageComponent {
  @Input() public offertypestorage?: OffertypeStorage;

  constructor(public tariftypes$: TariftypeService) {}
}
