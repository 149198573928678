import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeRegion } from '../../../../models/tarifs/tariftype-region/tariftype-region';
import { GenericregionBase } from '../../../../models/locations/genericregion/genericregion.base';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { TariftypeRegionListComponent } from '../../../../models/tarifs/tariftype-region/tariftype-region-list/tariftype-region-list.component';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { TariftypeRegionPermission } from '../../../../models/tarifs/tariftype-region-permission/tariftype-region-permission';
import { TARIFTYPE_ROUTES } from '../../tariftypes/detail/menu';
import { map, Observable, Subject } from 'rxjs';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { TariftypeDetailNavComponent } from '../../tariftypes/detail/nav/tariftype-detail-nav.component';
import { TariftypeRegionCreateComponent } from '../../../../models/tarifs/tariftype-region/tariftype-region-create/tariftype-region-create.component';

export interface TariftypeRegionListViewParams extends BaseRouteParams {
  detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  region_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  permission_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeRegionListViewData extends TariftypeRegionListViewParams {
  tariftype?: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-region-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    IconComponent,
    HeaderActionComponent,
    TariftypeRegionCreateComponent,
    TariftypeRegionListComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-region-list-view.component.pug',
  styleUrls: ['./tariftype-region-list-view.component.sass'],
})
export class TariftypeRegionListViewComponent extends BaseListViewComponent<
  TariftypeRegionListViewData,
  TariftypeRegion
> {
  public createRegion = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifTypeFilter$!: Observable<FilterDefaults>;

  public tariftype_detail_route?: Link<TariftypeBase>;
  public region_detail_route?: Link<GenericregionBase>;
  public user_detail_route?: Link<UserBase>;
  public group_detail_route?: Link<GroupBase>;
  public permission_detail_route?: Link<TariftypeRegionPermission>;

  public default_fields = [
    'id',
    'tariftype_details',
    'region_details',
    'perms_details',
    'created',
    'updated',
    'actions',
  ];

  public override setRouteData(data: TariftypeRegionListViewData) {
    super.setRouteData(data);
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
    }
    if (data.region_detail_route) {
      this.region_detail_route = new Link(data.region_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
    if (data.permission_detail_route) {
      this.permission_detail_route = new Link(data.permission_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return d.tariftype ? { tariftype: d.tariftype.id } : {};
      }),
    );
  }
}
