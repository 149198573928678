import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RI } from '../../../constants';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'lvadg-header-action',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './header-action.component.pug',
  styleUrls: ['./header-action.component.sass'],
})
export class HeaderActionComponent {
  @Input() level: 'h1' | 'h2' | 'h3' = 'h1';
  @Input() public ri?: keyof typeof RI;
  @Input() public icon?: string | string[];
  @Input() public title?: string;
  @Input() public subtitle?: string;
}
