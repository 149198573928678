import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Tarif } from "../tarif";
import { Observable } from "rxjs";
import { TarifExportType } from "./tarif-export-type";
import { EacollectionService } from "../../../catalog/eacollection/eacollection.service";
import { Eacollection } from "../../../catalog/eacollection/eacollection";
import { TarifActionService } from "../tarif-action.service";
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownModule,
  NgbDropdownToggle,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-tarif-export",
  standalone: true,
  imports: [
    CommonModule,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbDropdownItem,
  ],
  templateUrl: "./tarif-export.component.pug",
  styleUrls: ["./tarif-export.component.sass"],
})
export class TarifExportComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Output() public exported = new EventEmitter<void>();
  public coll$!: Observable<Eacollection[]>;
  public running: Record<number, boolean> = {};

  constructor(
    public _coll: EacollectionService,
    private _tac: TarifActionService,
  ) {}

  ngOnInit(): void {
    this.coll$ = this._coll.list({ fields: "id,name" });
  }

  public async exportEaCollection(
    c: Eacollection,
    format: "xlsx" | "csv" | "json",
  ) {
    this.running[c.id] = true;
    try {
      const res = await this._tac.action(this.tarif, {
        action: "ea_export",
        type: TarifExportType.EACOLLECTION,
        format,
        collection: c.id,
      });
      this.exported.emit();
    } finally {
      this.running[c.id] = false;
    }
  }
}
