
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content mx-3">
          <lvadg-orderprocessstorage-manage [orderprocessstorage]="data.orderprocessstorage"></lvadg-orderprocessstorage-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="permissions"><a ngbNavLink>Permissions</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <button class="btn btn-primary float-end" (click)="permissionCreate=!permissionCreate"><i class="bi bi-plus-circle me-2"></i>Ajouter une permission</button>
          <h2>Permissions de dépôt</h2>
          <div class="card my-3" *ngIf="permissionCreate">
            <div class="card-header">Ajouter une permission</div>
            <div class="card-body">
              <lvadg-orderprocessstorage-permission-create [orderprocessstorage]="data.orderprocessstorage" (created)="reload$.next(); permissionCreate=false" (cancelled)="permissionCreate=false"></lvadg-orderprocessstorage-permission-create>
            </div>
          </div>
          <ng-container *ngIf="data$|async; let data">
            <lvadg-orderprocessstorage-permission-list [name]="data.route.name" [reload]="reload$" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [default_fields]="default_fields" [filter]="orderprocessstorageFilter$" [orderprocess_detail_route]="orderprocess_detail_route" [orderprocessstorage_detail_route]="orderprocessstorage_detail_route"></lvadg-orderprocessstorage-permission-list>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>