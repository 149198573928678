
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ProducerDocument } from "./producer-document";

@Injectable({
  providedIn: 'root'
})
export class ProducerDocumentService extends Collection<ProducerDocument> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/producerdocuments", ProducerDocument);
  }
}
