
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftypedocument.filename}}<small>Détails</small></h2>
          </div>
          <lvadg-tariftype-document-manage [tariftypedocument]="data.tariftypedocument"></lvadg-tariftype-document-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="metadata"><a ngbNavLink>Paramètres associés</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftypedocument.filename}}<small>Paramétrages associés</small></h2>
            <div class="actions">
              <button class="btn btn-outline-primary btn-sm" (click)="createMetadata=!createMetadata"><i class="bi bi-plus-circle me-2"></i>Ajouter un paramétrage</button>
            </div>
          </div>
          <div class="card my-3" *ngIf="createMetadata">
            <div class="card-header"><i class="bi bi-plus-circle me-2"></i>Ajouter un paramétrage</div>
            <div class="card-body">
              <lvadg-tariftype-metadata-create [tariftypedocument]="data.tariftypedocument" (created)="createMetadata=false; reload$.next(true); tariftypemetadata_detail_route?.navigate({tariftypemetadata: $event})" (cancelled)="createMetadata=false"></lvadg-tariftype-metadata-create>
            </div>
          </div>
          <lvadg-tariftype-metadata-list [name]="data.route.name+&quot;_ttparams_&quot;+data.tariftypedocument.id" [filter]="tariftypeDocumentFilter$" [detail_route]="tariftypemetadata_detail_route" [reload]="reload$" [actions]="[&quot;rawedit&quot;, &quot;sdelete&quot;]"></lvadg-tariftype-metadata-list>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>