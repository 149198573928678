
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Rnmatt } from "./rnmatt";

@Injectable({
  providedIn: 'root'
})
export class RnmattService extends Collection<Rnmatt> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/rnmatts", Rnmatt);
  }
}
