
<input class="form-control form-control-sm" [formControl]="search" placeholder="Recherche dossier - 3 car. min., 30 premiers résultats">
<div class="overflow-auto vh-75 px-3 py-2">
  <cdk-tree [dataSource]="tree.data" [treeControl]="tree.control">
    <cdk-nested-tree-node class="d-block" *cdkTreeNodeDef="let node"><span class="pe-1" cdkTreeNodeToggle aria-label="toggle tree node" role="button" [cdkTreeNodeToggleRecursive]="false" *ngIf="node.children.length"><i class="bi bi-folder-minus" *ngIf="tree.control.isExpanded(node)"></i><i class="bi bi-folder-plus" *ngIf="!tree.control.isExpanded(node)"></i></span><span class="pe-1" *ngIf="!node.children.length"><i class="bi bi-folder"></i></span><a class="text-decoration-none text-uppercase user-select-none" [routerLink]="detail_route?.route({folder: node})" role="button" (click)="clickNode(node)" [class.text-success]="tree.highlighted.has(node.id)" [class.text-warning]="node.status!==FS.ONLINE" [class.fw-bold]="tree.current===node.id">{{node.name}}</a>
      <div class="ms-4" *ngIf="tree.control.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>