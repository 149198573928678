import { Component } from "@angular/core";

import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";
import { BASE_LIST_VIEW_IMPORTS } from "../../../../components/baseview/baselistview.imports";
import { ProductListComponent } from "../../../../models/catalog/product/product-list/product-list.component";
import { BaseListViewComponent } from "../../../../components/baseview/baselistview.component";
import { Product } from "../../../../models/catalog/product/product";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
} from "../../../../models/catalog/product/product.base";
import { Storage } from "../../../../models/structures/storage/storage";
import { ProducerBase } from "../../../../models/structures/producer/producer.base";
import { StorageBase } from "../../../../models/structures/storage/storage.base";
import {
  PrintContext,
  PrintSettings,
} from "../../../../models/layouts/print-settings";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { PRODUCT_PRINT_SETTINGS } from "../../../../models/catalog/product/product.print";
import { CdataKey } from "../../../../models/cdata/cdata-key/cdata-key";

export interface ProductListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  producer_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  local: LOCAL_FILTERS;
  type?: PRODUCT_TYPES;
  default_fields?: string[];
  default_filters?: string[];
}

export interface ProductListViewData extends ProductListViewParams {
  storage?: Storage;
  products_cdatakeys: CdataKey[];
}

@Component({
  selector: "lvadg-product-list-view",
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    ProductListComponent,
    HeaderActionComponent,
  ],
  templateUrl: "./product-list-view.component.pug",
  styleUrls: ["./product-list-view.component.sass"],
})
export class ProductListViewComponent extends BaseListViewComponent<
  ProductListViewData,
  Product
> {
  public producer_detail_route!: Link<ProducerBase>;
  public storage_detail_route!: Link<StorageBase>;
  public default_fields: string[] = [
    "name",
    "producer_details",
    "labels_details",
    "seasons",
    "storage_relations_details",
    "actions",
  ];
  public default_filters: string[] = [
    "search",
    "producers",
    "parent_families",
    "storages",
    "producer_near",
  ];
  public printSettings: PrintSettings = PRODUCT_PRINT_SETTINGS;
  public print_context: PrintContext = {};

  public readonly TP = PRODUCT_TYPES;

  public override setRouteData(data: ProductListViewData) {
    super.setRouteData(data);
    this.producer_detail_route = new Link(
      data.producer_detail_route,
      data,
      this._router,
    );
    this.storage_detail_route = new Link(
      data.storage_detail_route,
      data,
      this._router,
    );
    this.default_fields = data.default_fields || this.default_fields || [];
    this.default_filters = data.default_filters || this.default_filters || [];
    if (data.storage) {
      this.print_context.storage = data.storage;
    }
  }
}
