import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BaseListViewComponent } from '../../../components/baseview/baselistview.component';
import { Contact } from '../../../models/users/contact/contact';
import { ContactListComponent } from '../../../models/users/contact/contact-list/contact-list.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../components/baseview/baselistview.imports';
import { User } from '../../../models/users/user/user';
import { Client } from '../../../models/structures/client/client';
import { Member } from '../../../models/structures/member/member';
import { Storage } from '../../../models/structures/storage/storage';
import { Resto } from '../../../models/structures/resto/resto';
import { HeaderActionComponent } from '../../../components/utils/header-action/header-action.component';
import { Tariftype } from '../../../models/tarifs/tariftype/tariftype';

export interface ContactListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  resto_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  member_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
}

export interface ContactListViewData extends ContactListViewParams {}

@Component({
  selector: 'lvadg-contact-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, ContactListComponent, HeaderActionComponent],
  templateUrl: './contact-list-view.component.pug',
  styleUrls: ['./contact-list-view.component.sass'],
})
export class ContactListViewComponent extends BaseListViewComponent<ContactListViewData, Contact> {
  public user_detail_route?: Link<User>;
  public resto_detail_route?: Link<Resto>;
  public storage_detail_route?: Link<Storage>;
  public member_detail_route?: Link<Member>;
  public client_detail_route?: Link<Client>;
  public tariftype_detail_route?: Link<Tariftype>;

  public override setRouteData(data: ContactListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
    this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    this.resto_detail_route = new Link<Resto>(data.resto_detail_route, data, this._router);
    this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
    this.member_detail_route = new Link<Member>(data.member_detail_route, data, this._router);
    this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    this.tariftype_detail_route = new Link<Tariftype>(data.tariftype_detail_route, data, this._router);
  }
}
