import {
  booleanField,
  charField,
  dateField,
  primaryField,
} from "@solidev/data";
import { StructureBase } from "../structure.base";

export enum PROVIDER_STATUSES {
  VALID = "OK",
  TERMINATED = "RS",
  INVALID = "KO",
}

export enum PROVIDER_TYPES {
  FOURN_REF = "FREF",
}

export class ProviderBase extends StructureBase {
  static override readonly __name: string = "ProviderBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: PROVIDER_TYPES.FOURN_REF,
    maxLength: 4,
    choices: [
      { value: PROVIDER_TYPES.FOURN_REF, desc: "Fournisseur référencé" },
    ],
    priority: 900,
  })
  public type: PROVIDER_TYPES = PROVIDER_TYPES.FOURN_REF;

  @charField({
    name: "status",
    description: "Etat",
    defaultValue: PROVIDER_STATUSES.VALID,
    required: true,
    maxLength: 4,
    choices: [
      { value: PROVIDER_STATUSES.VALID, desc: "Valide" },
      { value: PROVIDER_STATUSES.TERMINATED, desc: "Résilié" },
      {
        value: PROVIDER_STATUSES.INVALID,
        desc: "Non valide",
      },
    ],
    priority: 800,
  })
  public status: PROVIDER_STATUSES = PROVIDER_STATUSES.VALID;

  @dateField({
    name: "open_date",
    description: "Date début référencement",
    priority: 410,
  })
  public open_date!: Date;

  @dateField({
    name: "close_date",
    description: "Date fin référencement",
    priority: 409,
  })
  public close_date!: Date;

  @booleanField({
    name: "ref_1g",
    description: "Référencé 1G",
    defaultValue: false,
    required: true,
    priority: 804,
  })
  public ref_1g: boolean = false;

  @booleanField({
    name: "ref_4g",
    description: "Référencé 4G",
    defaultValue: false,
    required: true,
    priority: 803,
  })
  public ref_4g: boolean = false;

  @booleanField({
    name: "ref_5g",
    description: "Référencé 5G",
    defaultValue: false,
    required: true,
    priority: 802,
  })
  public ref_5g: boolean = false;
}
