
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu"></lvadg-tariftype-detail-nav>
  <lvadg-header-action [title]="data.tariftype.name" level="h2" subtitle="Liste des tarifs" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-primary" (click)="createTarif=!createTarif" *ngIf="data.tariftype.have_level(TTUL.pilote)">
        <lvadg-icon ri="plus">Créer un tarif</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createTarif">
    <div class="card-header">Créer un tarif</div>
    <div class="card-body">
      <lvadg-tarif-create [tariftype]="data.tariftype" (cancelled)="createTarif=false" (created)="reload$.next(true); createTarif=false; tarif_detail_route?.navigate({tarif: $event})"></lvadg-tarif-create>
    </div>
  </div>
  <lvadg-tarif-list [name]="data.route.name+'-'+data.tariftype.code" [filter]="tarifTypeFilter$" [reload]="reload$" [default_fields]="tarifs_default_fields" [default_filters]="['search', 'status', 'validat']" [detail_route]="tarif_detail_route"></lvadg-tarif-list>
</ng-container>