import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../../user/user';
import { Group } from '../../group/group';
import { GroupService } from '../../group/group.service';
import { UserService } from '../../user/user.service';
import { Usergrouplink } from '../usergrouplink';
import { DispeditComponent } from '@solidev/data';
import { UserActionService } from '../../user/user-action.service';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { GroupActionService } from '../../group/group-action.service';

@Component({
  selector: 'lvadg-usergrouplink-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, IconComponent],
  templateUrl: './usergrouplink-create.component.pug',
  styleUrls: ['./usergrouplink-create.component.sass'],
})
export class UsergrouplinkCreateComponent implements OnInit {
  @Output() public created = new EventEmitter<Usergrouplink>();
  @Output() public cancelled = new EventEmitter<void>();
  @Input() public user?: User;
  @Input() public group?: Group;
  public groupLink = new Usergrouplink();

  constructor(
    public groups$: GroupService,
    private _uss: UserActionService,
    private _gss: GroupActionService,
    public users$: UserService
  ) {}

  ngOnInit(): void {}

  public async addUserToGroup() {
    const group = this.groupLink.group;
    const res = await this._uss.action(this.user!, {
      action: 'add_to_group',
      group,
    });
    if (res.success) {
      this.created.emit(this.groupLink);
    }
  }

  public async addGroupToUser() {
    const user = this.groupLink.user;
    const res = await this._gss.action(this.group!, 'add_user', { user });
    if (res.success) {
      this.created.emit(this.groupLink);
    }
  }
}
