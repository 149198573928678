import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessstoragePermissionService } from '../orderprocessstorage-permission.service';
import { OrderprocessstoragePermissionBase } from '../orderprocessstorage-permission.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { GroupService } from '../../../users/group/group.service';
import { UserService } from '../../../users/user/user.service';
import { OrderprocessBase } from '../../orderprocess/orderprocess.base';
import { OrderprocessstorageBase } from '../../orderprocessstorage/orderprocessstorage.base';
import { OrderprocessstoragePermission } from '../orderprocessstorage-permission';
import { OrderprocessstorageService } from '../../orderprocessstorage/orderprocessstorage.service';

@Component({
  selector: 'lvadg-orderprocessstorage-permission-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './orderprocessstorage-permission-create.component.pug',
  styleUrls: ['./orderprocessstorage-permission-create.component.sass'],
})
export class OrderprocessstoragePermissionCreateComponent implements OnInit {
  public obj!: OrderprocessstoragePermission;
  public createForm = new FormGroup({});
  @Input() public orderprocess?: OrderprocessBase;
  @Input() public orderprocessstorage?: OrderprocessstorageBase;

  @Output() public created =
    new EventEmitter<OrderprocessstoragePermissionBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _svc: OrderprocessstoragePermissionService,
    public orderprocessstorage$: OrderprocessstorageService,
    public groups$: GroupService,
    public users$: UserService
  ) {}

  public ngOnInit(): void {
    this.obj = new OrderprocessstoragePermission(this._svc);
    if (this.orderprocessstorage) {
      this.obj.target_details = this.orderprocessstorage;
      this.obj.target = this.orderprocessstorage.id;
    }
  }

  public async save() {
    this.obj.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
