import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum OFFERDOCUMENT_TYPE {
  CLIENT_PRINT = 'CP',
  RESTO_PRINT = 'RP',
  CLIENT_ATTACHMENT = 'CA',
  RESTO_ATTACHMENT = 'RA',
}

export enum OFFERDOCUMENT_STATUSES {
  VALID = 'OK',
  FUTUR = 'FUT',
  PRINTING = 'PRN',
  INVALID = 'KO',
}

export class OfferDocumentBase extends DataModel {
  static override readonly __name: string = 'OfferDocumentBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 100,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 150,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @charField({
    name: 'slug',
    description: 'Slug',
    maxLength: 200,
    priority: -1,
  })
  public slug!: string;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 200,
    priority: -1,
  })
  public title!: string;

  @charField({
    name: 'mimetype',
    description: 'Type de fichier',
    maxLength: 250,
    priority: -1,
  })
  public mimetype!: string;

  @charField({
    name: 'filename',
    description: 'Nom du fichier',
    maxLength: 255,
    priority: 700,
  })
  public filename!: string;

  @integerField({
    name: 'filesize',
    description: 'Taille du fichier',
    defaultValue: 0,
    priority: 650,
  })
  public filesize: number = 0;

  @charField({
    name: 'file',
    description: 'URL fichier',
    maxLength: 200,
    priority: 600,
  })
  public file!: string;

  @foreignKeyField({
    name: 'image',
    description: 'ID Image',
    related: 'Image',
    priority: -1,
  })
  public image!: number;

  @dateField({
    name: 'start',
    description: 'Date de début de validité',
    priority: 500,
  })
  public start!: Date;

  @dateField({
    name: 'end',
    description: 'Date de fin de validité',
    priority: 500,
  })
  public end!: Date;

  @detailsField({
    name: 'parameters',
    description: 'Paramètres',
    defaultValue: {},
    priority: -1,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public parameters: any = {};

  @detailsField({
    name: 'content',
    description: 'Contenu',
    defaultValue: {},
    priority: -1,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public content: any = {};

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;

  @foreignKeyField({
    name: 'offer',
    description: 'ID Offre',
    related: 'Offer',
    priority: -1,
  })
  public offer!: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Restaurant',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @charField({
    name: 'type',
    description: 'Type de document',
    defaultValue: OFFERDOCUMENT_TYPE.CLIENT_PRINT,
    maxLength: 4,
    choices: [
      { value: OFFERDOCUMENT_TYPE.CLIENT_PRINT, desc: 'Impression client' },
      {
        value: OFFERDOCUMENT_TYPE.RESTO_PRINT,
        desc: 'Impression restaurant',
      },
      { value: OFFERDOCUMENT_TYPE.CLIENT_ATTACHMENT, desc: 'Pièce jointe client' },
      { value: OFFERDOCUMENT_TYPE.RESTO_ATTACHMENT, desc: 'Pièce jointe resto' },
    ],
    priority: 800,
  })
  public type: OFFERDOCUMENT_TYPE = OFFERDOCUMENT_TYPE.CLIENT_PRINT;

  @foreignKeyField({
    name: 'layout',
    description: 'ID Layout',
    related: 'Print',
    priority: -1,
  })
  public layout!: number;

  @charField({
    name: 'layout_task',
    description: 'ID tâche impression',
    maxLength: 64,
    priority: -1,
  })
  public layout_task!: string;

  @charField({
    name: 'callback_token',
    description: 'Callback Token',
    maxLength: 64,
    priority: -1,
  })
  public callback_token!: string;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: OFFERDOCUMENT_STATUSES.VALID,
    maxLength: 4,
    choices: [
      { value: OFFERDOCUMENT_STATUSES.VALID, desc: 'Document valide' },
      { value: OFFERDOCUMENT_STATUSES.FUTUR, desc: 'Document futur' },
      { value: OFFERDOCUMENT_STATUSES.PRINTING, desc: "Document en cours d'impression" },
      {
        value: OFFERDOCUMENT_STATUSES.INVALID,
        desc: 'Document non valide',
      },
    ],
    priority: 200,
  })
  public status: OFFERDOCUMENT_STATUSES = OFFERDOCUMENT_STATUSES.VALID;
}
