import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Usage } from '../../../../../models/catalog/meta/usage/usage';

export interface UsageDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface UsageDetailViewData extends UsageDetailViewParams {
  usage: Usage;
}
