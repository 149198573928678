import { Component } from "@angular/core";

import { firstValueFrom } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
  TabMemoryService,
} from "@solidev/data";
import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { BASE_DETAIL_VIEW_IMPORTS } from "../../../../../components/baseview/basedetailview.imports";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { RI } from "../../../../../constants";
import { Article } from "../../../../../models/catalog/article/article";
import { Family } from "../../../../../models/catalog/family/family";
import { ArticleActionService } from "../../../../../models/catalog/article/article-action.service";
import { ArticleManageComponent } from "../../../../../models/catalog/article/article-manage/article-manage.component";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";

export interface ArticleManageViewParams extends ArticleViewParams {
  articles_list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  family_detail_route: RouteConfigItem;
}

export interface ArticleManageViewData
  extends ArticleManageViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-detail-view",
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    SafeDeleteComponent,
    ArticleManageComponent,
    ArticleNavComponent,
    HeaderActionComponent,
  ],
  templateUrl: "./article-manage-view.component.pug",
  styleUrls: ["./article-manage-view.component.sass"],
})
export class ArticleManageViewComponent extends BaseDetailViewComponent<
  ArticleManageViewData,
  Article
> {
  public RI = RI;
  public articles_list_route!: Link<unknown>;
  public article_detail_route!: Link<Article>;
  public family_detail_route!: Link<Family>;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _aas: ArticleActionService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ArticleManageViewData) {
    super.setRouteData(data);
    console.log("Route data", data.article.libelle);
    this.articles_list_route = new Link(
      data.articles_list_route,
      this,
      this._router,
    );
    this.article_detail_route = new Link(
      data.article_detail_route,
      this,
      this._router,
    );
    this.family_detail_route = new Link(
      data.family_detail_route,
      data,
      this._router,
    );
  }

  public override preNgOnInit() {
    super.preNgOnInit();
  }

  public async print(a: Article) {
    await firstValueFrom(
      a.action("POST", "print", {
        body: { title: "yolo" },
      }),
    );
  }

  public async clone(article: Article) {
    const res = await this._aas.action(article, { action: "clone_article" });
    if (res.success) {
      this._router.navigate(
        this.article_detail_route.route({ article: res.article }),
      );
    }
  }
}
