
<form [formGroup]="createForm" (submit)="save()">
  <div class="row">
    <data-dispedit class="col-4" [model]="packaging" field="code" mode="form" [form]="createForm">Code</data-dispedit>
    <data-dispedit class="col-4" [model]="packaging" field="type" mode="form" [form]="createForm">Type</data-dispedit>
    <data-dispedit class="col-4" [model]="packaging" field="name" mode="form" [form]="createForm">Nom</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>