
<form *ngIf="createForm" (submit)="$event.preventDefault(); create()">
  <div class="row mb-3 g-2">
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="type" mode="form" [form]="createForm">Type de relation</data-dispedit>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="producer" mode="form" [form]="createForm" *ngIf="isNeeded('producer') &amp;&amp; !producer" [collection]="producers$" [filter]="{fields: ['id', 'name', 'type'].join(',')}">Producteur</data-dispedit>
    <div class="col-xl-3 col-md-6 col-12" *ngIf="isNeeded('producer') &amp;&amp; producer">
      <label>Producteur</label>
      <input class="form-control" [disabled]="true" [value]="producer.name">
    </div>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="storage" mode="form" [form]="createForm" *ngIf="isNeeded('storage') &amp;&amp; !storage" [collection]="producers$" [filter]="{fields: ['id', 'name', 'type', 'cvva'].join(',')}">Dépôt</data-dispedit>
    <div class="col-xl-3 col-md-6 col-12" *ngIf="isNeeded('storage') &amp;&amp; storage">
      <label>Dépôt</label>
      <input class="form-control" [disabled]="true" [value]="storage.name">
    </div>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="member" mode="form" [form]="createForm" *ngIf="isNeeded('member')" [collection]="members$" [filter]="{fields: ['id', 'name', 'type'].join(',')}">Membre</data-dispedit>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="provider" mode="form" [form]="createForm" *ngIf="isNeeded('provider')" [collection]="providers$" [filter]="{fields: ['id', 'name', 'type', 'cvva'].join(',')}">Fournisseur</data-dispedit>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="client" mode="form" [form]="createForm" *ngIf="isNeeded('client')" [collection]="clients$" [filter]="{fields: ['id', 'name', 'type', 'cvva'].join(',')}">Client</data-dispedit>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="resto" mode="form" [form]="createForm" *ngIf="isNeeded('resto')" [collection]="restos$" [filter]="{fields: ['id', 'name', 'type', 'cvva'].join(',')}">Resto</data-dispedit>
    <data-dispedit class="col-xl-3 col-md-6 col-12" [model]="item" field="status" mode="form" [form]="createForm">État</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>