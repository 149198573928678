
<label class="text-danger">Edition brute des paramètres</label>
<textarea class="form-control form-control-plaintext" [formControl]="form" rows="50"></textarea>
<div class="row mt-3">
  <div class="col">
    <button class="btn btn-primary w-100" (click)="save()"><i class="bi bi-save me-2"></i>Enregistrer</button>
  </div>
  <div class="col">
    <button class="btn btn-outline-secondary w-100" (click)="cancelled.emit()">Annuler</button>
  </div>
</div>