
<button class="btn btn-primary float-end" (click)="create=!create"><i class="bi bi-plus-circle me-2"></i>Ajouter un article</button>
<h1 class="page-title">Articles</h1>
<div class="card my-3" *ngIf="create">
  <div class="card-header">Ajouter un article</div>
  <div class="card-body">
    <lvadg-orderprocessarticle-create (created)="reload$.next(); create=false" (cancelled)="create=false"></lvadg-orderprocessarticle-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-orderprocessarticle-list [name]="data.route.name" [detail_route]="detail_route" [article_detail_route]="article_detail_route" [tariftype_detail_route]="tariftype_detail_route" [orderprocess_detail_route]="orderprocess_detail_route" [default_fields]="default_fields" [reload]="reload$"></lvadg-orderprocessarticle-list>
</ng-container>