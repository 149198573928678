import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams } from '@solidev/data';
import { TariftypeRegionPermission } from '../../../../models/tarifs/tariftype-region-permission/tariftype-region-permission';

export interface TariftypeRegionPermissionDetailViewParams extends BaseRouteParams {}

export interface TariftypeRegionPermissionDetailViewData extends TariftypeRegionPermissionDetailViewParams {
  tariftyperegionpermission: TariftypeRegionPermission;
}

@Component({
  selector: 'lvadg-tariftype-region-permission-detail-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-region-permission-detail-view.component.pug',
  styleUrls: ['./tariftype-region-permission-detail-view.component.sass'],
})
export class TariftypeRegionPermissionDetailViewComponent {}
