import {
  DataModel,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class TarifRelationBase extends DataModel {
  static override readonly __name: string = 'TarifRelationBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'relation',
    description: 'relation',
    related: 'TarifTypeRelation',
  })
  public relation!: number;

  @foreignKeyField({ name: 'src', description: 'src', related: 'Tarif' })
  public src!: number;

  @foreignKeyField({ name: 'dest', description: 'dest', related: 'Tarif' })
  public dest!: number;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
