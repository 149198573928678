
<ng-container *ngIf="meteo">
  <div class="table-responsive">
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th>
            <select class="form-select form-select-sm" (change)="citySelected($event)">
              <option *ngFor="let c of cities" [value]="c">{{c}}</option>
            </select>
          </th>
          <th *ngFor="let m of meteo['Lyon']?.properties?.daily_forecast">{{m.time|date:'d'}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of selcities">
          <td>{{c}}</td>
          <td *ngFor="let m of meteo[c]?.properties?.daily_forecast">
            <div class="day"><img [src]="'/assets/img/meteoicons/'+m.daily_weather_icon+'.svg'">
              <div class="tmin">{{m.T_min}}</div>
              <div class="tmax">{{m.T_max}}</div>
              <div class="rain" [style.width]="(m.total_precipitation_24h/20*30)+'px'"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>