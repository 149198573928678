import { GenericregionBase } from './genericregion.base';
import { detailsField } from '@solidev/data';
import { Client } from '../../structures/client/client';
import { Geodepartement } from '../geodepartement/geodepartement';

export class Genericregion extends GenericregionBase {
  static override readonly __name: string = 'Genericregion';

  @detailsField({
    model: Client,
    readonly: true,
    description: 'Client',
    priority: 500,
  })
  public client_details?: Client;

  @detailsField({
    model: Geodepartement,
    readonly: true,
    description: 'Départements',
    priority: 400,
    many: true,
  })
  public geodepartements_details?: Geodepartement[];
}
