
<!-- Form for Producer creation, fields : name, type, cvva-->
<form *ngIf="createForm" [formGroup]="createForm" (submit)="create()">
  <div class="row mb-3 g-3">
    <data-dispedit class="col-4" [model]="item" field="key" mode="form" [form]="createForm" [collection]="cdatakeys$" [filter]="keyFilter">Clef</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_int" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.INTEGER">Valeur entière</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_dec" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.CURRENCY">Valeur (monétaire)</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_stx" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.SHORT_TEXT">Valeur (texte court)</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_ltx" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.LONG_TEXT">Valeur (texte long)</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_rtx" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.RICH_TEXT">Valeur (markdown)</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_dte" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.DATE">Valeur (date)</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="value_dth" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.DATETIME">Valeur (date et heure)</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="choice" mode="form" [form]="createForm" *ngIf="item.key_details?.type===KT.SINGLE_CHOICE" [collection]="cdatakeychoices$" [filter]="{key: item.key}">Valeur (choix)</data-dispedit>
  </div>
  <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
  <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
</form>