import { Component, inject } from "@angular/core";
import {
  BaseRouteParams,
  DataMessageService,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
} from "@solidev/data";
import { PRODUCT_ROUTES } from "../menu";
import { Product } from "../../../../../models/catalog/product/product";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProductNavComponent } from "../_nav/product-nav.component";
import { ProductManageComponent } from "../../../../../models/catalog/product/product-manage/product-manage.component";
import { CommonModule } from "@angular/common";
import { Producer } from "../../../../../models/structures/producer/producer";
import { Storage } from "../../../../../models/structures/storage/storage";
import { DB_CHECKS } from "../../../../../constants";
import { FamilyBase } from "../../../../../models/catalog/family/family.base";

export interface ProductEditViewParams extends BaseRouteParams {
  p_menu: PRODUCT_ROUTES;
  product_list_route?: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
}

export interface ProductEditViewData extends ProductEditViewParams {
  product: Product;
  producer?: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-product-edit-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProductNavComponent,
    ProductManageComponent,
    SafeDeleteComponent,
  ],
  templateUrl: "./product-edit-view.component.pug",
  styleUrl: "./product-edit-view.component.sass",
})
export class ProductEditViewComponent extends BaseDetailViewComponent<
  ProductEditViewData,
  Product
> {
  public product_list_route?: Link<never>;
  public family_detail_route!: Link<FamilyBase>;
  protected readonly DB_CHECKS = DB_CHECKS;
  private _msgs = inject(DataMessageService);

  public deleted() {
    this._msgs.success("Le producteur a été supprimé avec succès.");
    if (this.product_list_route) {
      this.product_list_route.navigate();
    }
    this._router.navigate(["../.."], { relativeTo: this._route });
  }

  public override setRouteData(data: ProductEditViewData) {
    super.setRouteData(data);
    if (data.product_list_route) {
      this.product_list_route = new Link(
        data.product_list_route,
        data,
        this._router,
      );
    }
    if (data.family_detail_route) {
      this.family_detail_route = new Link(
        data.family_detail_route,
        data,
        this._router,
      );
    }
  }
}
