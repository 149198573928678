import {
  charField,
  DataModel,
  datetimeField,
  decimalField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';
import { TARIF_STATUS, TarifStatusChoices } from '../tarif/tarif.base';

export class ArticleTarifLogBase extends DataModel {
  static override readonly __name: string = 'ArticleTarifLogBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'articletarif',
    description: 'ID Article Tarif',
    related: 'ArticleTarif',
  })
  public articletarif!: number;

  @foreignKeyField({
    name: 'articletarifitem',
    description: 'ID Sub-Article tarif',
    related: 'ArticleTarifItem',
  })
  public articletarifitem!: number;

  @decimalField({
    name: 'price',
    description: 'Prix après',
    factor: 10000,
  })
  public price!: number;

  @decimalField({
    name: 'pprice',
    description: 'Prix avant',
    factor: 10000,
  })
  public pprice!: number;

  @charField({
    name: 'status',
    description: 'État après',
    defaultValue: TARIF_STATUS.TOVALIDATE,
    maxLength: 2,
    choices: TarifStatusChoices,
  })
  public status: TARIF_STATUS = TARIF_STATUS.TOVALIDATE;

  @charField({
    name: 'pstatus',
    description: 'État avant',
    maxLength: 2,
    choices: TarifStatusChoices,
  })
  public pstatus?: TARIF_STATUS;

  @integerField({
    name: 'available',
    description: 'Disponibilité après',
    defaultValue: 0,
  })
  public available: number = 0;

  @integerField({
    name: 'pavailable',
    description: 'Disponibilité avant',
    defaultValue: 0,
  })
  public pavailable: number = 0;

  @foreignKeyField({
    name: 'author',
    description: 'ID Auteur',
    related: 'User',
  })
  public author!: number;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
