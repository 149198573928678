import {Component, OnInit} from "@angular/core";
import {News} from "../../news";
import {NewsService} from "../../news.service";
import {Queryset} from "@solidev/data";
import {Observable} from "rxjs";
import {CommonModule} from "@angular/common";
import {NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {RouterLink} from "@angular/router";
import {FRONT_ROUTES} from "../../../../../../../../front/src/app/routing/main";
import {LogoManageComponent} from "../../../../documents/image/logo-manage/logo-manage.component";
import {ImageDisplayComponent} from "../../../../documents/image/image-display/image-display.component";
import {NgMathPipesModule, NgStringPipesModule} from "ngx-pipes";


@Component({
    standalone: true,
    selector: "lvadgf-news-list",
    templateUrl: "./news-list.component.pug",
    styleUrls: ["./news-list.component.sass"],
    imports: [
        CommonModule,
        RouterLink,
        NgbPagination,
        LogoManageComponent,
        ImageDisplayComponent,
        NgMathPipesModule,
        NgStringPipesModule
    ],
})
export class NewsListGalleryComponent implements OnInit {
    public newsqs!: Queryset<News>
    public fnews!: Observable<News[]>;

    constructor(private _ns: NewsService) {
    }

    public async ngOnInit(): Promise<void> {
        this.newsqs = this._ns.queryset();
        this.newsqs.paginateBy(10)
            .filter({type: "INT"})
            .sort('-created');
        this.fnews = this.newsqs.get();
    }

    protected readonly FRONT_ROUTES = FRONT_ROUTES;
}
