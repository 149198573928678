import { RnmlibelleBase } from './rnmlibelle.base';
import { detailsField } from '@solidev/data';
import { Rnm } from '../rnm/rnm';

export class Rnmlibelle extends RnmlibelleBase {
  static override readonly __name: string = 'Rnmlibelle';

  @detailsField({
    description: 'Pivot RNM',
    model: Rnm,
    readonly: true,
  })
  public rnm_details?: Rnm;
}
