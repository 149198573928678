import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IocodeService } from './iocode.service';
import { Iocode } from './iocode';

@Injectable({
  providedIn: 'root',
})
export class IocodeResolver {
  constructor(private _rs: IocodeService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Iocode> {
    return this._rs.fetch(+route.params['iocodeId']);
  }
}
