
<ng-container *ngIf="orderprocess">
  <dl class="row">
    <data-dispedit class="col-4" [model]="orderprocess" field="code" mode="dd" help="Format : PCxxx">Code du process</data-dispedit>
    <data-dispedit class="col-4" [model]="orderprocess" field="name" mode="dd">Nom du process</data-dispedit>
    <data-dispedit class="col-4" [model]="orderprocess" field="tariftype" mode="dd" [collection]="tariftypes$">Modèle de tarif</data-dispedit>
    <div class="col-12">
      <dt>Règles de récurrence (date d'enlèvement)
        <div><span class="text-info">Les règles par dépôt sont prioritaires si définies.</span><span class="ms-2 text-warning">Les jours fériés ne sont pas pris en compte</span></div>
      </dt>
      <dd>
        <lvadg-rrule-editor [rule]="orderprocess.delivery_dates_rrule" [editable]="true" (save)="saveRrule(orderprocess, $event)"></lvadg-rrule-editor>
      </dd>
    </div>
  </dl>
</ng-container>