
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TarifNotificationDestination } from "./tarif-notification-destination";

@Injectable({
  providedIn: 'root'
})
export class TarifNotificationDestinationService extends Collection<TarifNotificationDestination> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tarifnotificationdestinations", TarifNotificationDestination);
  }
}
