import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { TariftypeFullDetailViewData } from '../../../full-tariftype-detail-view/tariftype-detail-view.component.params';
import { TARIFTYPE_USER_LEVEL } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { TarifListComponent } from '../../../../../models/tarifs/tarif/tarif-list/tarif-list.component';
import { TarifCreateComponent } from '../../../../../models/tarifs/tarif/tarif-create/tarif-create.component';
import { CommonModule } from '@angular/common';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';
import { TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';

export interface TariftypeTarifsViewParams extends BaseRouteParams {
  tarif_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeTarifsViewData extends TariftypeTarifsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-tarifs-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    TarifListComponent,
    TarifCreateComponent,
    IconComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-tarifs-view.component.pug',
  styleUrls: ['./tariftype-tarifs-view.component.sass'],
})
export class TariftypeTarifsViewComponent extends BaseDetailViewComponent<TariftypeTarifsViewData, Tariftype> {
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public TTUL = TARIFTYPE_USER_LEVEL;
  public createTarif: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarif_detail_route?: Link<TarifBase>;
  public tarifs_default_fields = [
    'id',
    'name',
    'datestart',
    'dateend',
    'status',
    'documents_details',
    'created',
    'updated',
  ];

  public override setRouteData(data: TariftypeFullDetailViewData) {
    super.setRouteData(data);
    if (data.tarif_detail_route) {
      this.tarif_detail_route = new Link(data.tarif_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }
}
