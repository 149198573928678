import { Component, signal } from "@angular/core";
import { OFFERTYPE_ROUTES } from "../../offertypes/detail/menu";
import { FilterDefaults } from "@solidev/data";
import { Offertype } from "../../../../models/offers/offertype/offertype";
import {
  BaseListRouteParams,
  BaseListViewComponent,
} from "../../../../components/baseview/baselistview.component";
import { OffertypeTextType } from "../../../../models/offers/offertype-text-type/offertype-text-type";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { OffertypeNavComponent } from "../../offertypes/detail/_nav/offertype-nav.component";
import { CommonModule } from "@angular/common";
import { map, Observable, Subject } from "rxjs";
import { OffertypeTextTypeListComponent } from "../../../../models/offers/offertype-text-type/offertype-text-type-list/offertype-text-type-list.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { OffertypeTextTypeCreateComponent } from "../../../../models/offers/offertype-text-type/offertype-text-type-create/offertype-text-type-create.component";

export interface OffertypeTextTypesViewParams extends BaseListRouteParams {
  ot_menu?: OFFERTYPE_ROUTES;
}

export interface OffertypeTextTypesViewData
  extends OffertypeTextTypesViewParams {
  offertype?: Offertype;
}

@Component({
  selector: "lvadg-offertype-text-types-view",
  standalone: true,
  imports: [
    HeaderActionComponent,
    OffertypeNavComponent,
    CommonModule,
    IconComponent,
    OffertypeTextTypeCreateComponent,
    OffertypeTextTypeListComponent,
  ],
  templateUrl: "./offertype-text-types-view.component.pug",
  styleUrl: "./offertype-text-types-view.component.sass",
})
export class OffertypeTextTypesViewComponent extends BaseListViewComponent<
  OffertypeTextTypesViewData,
  OffertypeTextType
> {
  public offertypeTextTypeFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public default_fields: string[] = [];
  public add = signal(false);

  public override setRouteData(data: OffertypeTextTypesViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offertypeTextTypeFilter$ = this.data$.pipe(
      map((data) => {
        return { offertype: data.offertype?.id };
      }),
    );
  }
}
