
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeStoragePermission } from '../tariftype-storage-permission';
@Component({
  selector: 'lvadg-tariftype-storage-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-storage-permission-manage.component.pug',
  styleUrls: ['./tariftype-storage-permission-manage.component.sass']
})
export class TariftypeStoragePermissionManageComponent implements OnInit {
  @Input() public tariftypestoragepermission?: TariftypeStoragePermission;

  constructor() { }

  ngOnInit(): void {
  }

}
