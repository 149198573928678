import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { CdataKeyChoice } from './cdata-key-choice';

@Injectable({
  providedIn: 'root',
})
export class CdataKeyChoiceService extends Collection<CdataKeyChoice> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/cdatakeychoices', CdataKeyChoice);
  }
}
