import { Component } from '@angular/core';
import { Category } from '../category';
import { CategoryService } from '../category.service';
import { FiltersParams, ModelListService, ModelListTextFilter } from '@solidev/data';
import { ModellistComponent } from '../../../../../includes/modellist/modellist.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MODELLIST_IMPORTS } from '../../../../../includes/modellist/modellist.imports';

@Component({
  selector: 'lvadg-category-list',
  standalone: true,
  templateUrl: './category-list.component.pug',
  styleUrls: ['./category-list.component.sass'],
  imports: [...MODELLIST_IMPORTS],
})
export class CategoryListComponent extends ModellistComponent<Category> {
  constructor(coll: CategoryService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
