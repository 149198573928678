import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodoComponent } from '../../../../components/utils/todo/todo.component';

@Component({
  selector: 'lvadg-news-shortlist',
  standalone: true,
  imports: [CommonModule, TodoComponent],
  templateUrl: './news-shortlist.component.pug',
  styleUrls: ['./news-shortlist.component.sass'],
})
export class NewsShortlistComponent {}
