import { Component, Input, signal } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Offer } from "../offer";
import { OfferService } from "../offer.service";
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { StorageDisplayComponent } from "../../../structures/storage/storage-display/storage-display.component";
import { OfferDisplayComponent } from "../offer-display/offer-display.component";
import { ClientDisplayComponent } from "../../../structures/client/client-display/client-display.component";
import { RestoDisplayComponent } from "../../../structures/resto/resto-display/resto-display.component";
import { UserDisplayComponent } from "../../../users/user/user-display/user-display.component";
import { Resto } from "../../../structures/resto/resto";
import { Client } from "../../../structures/client/client";
import { Storage } from "../../../structures/storage/storage";
import { User } from "../../../users/user/user";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { ClientService } from "../../../structures/client/client.service";
import { StorageService } from "../../../structures/storage/storage.service";
import { RestoService } from "../../../structures/resto/resto.service";
import { UserService } from "../../../users/user/user.service";
import { Offertype } from "../../offertype/offertype";
import { OffertypeDisplayComponent } from "../../offertype/offertype-display/offertype-display.component";
import { OffertypeStorageDisplayComponent } from "../../offertype-storage/offertype-storage-display/offertype-storage-display.component";
import { OffertypeStorage } from "../../offertype-storage/offertype-storage";
import { OFFER_STATUSES, OFFER_USER_LEVEL } from "../offer.base";
import { Tarif } from "../../../tarifs/tarif/tarif";
import { TarifDisplayComponent } from "../../../tarifs/tarif/tarif-display/tarif-display.component";

@Component({
  selector: "lvadg-offer-list",
  standalone: true,
  templateUrl: "./offer-list.component.pug",
  styleUrls: ["./offer-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    StorageDisplayComponent,
    UserDisplayComponent,
    OfferDisplayComponent,
    ClientDisplayComponent,
    RestoDisplayComponent,
    OffertypeDisplayComponent,
    OffertypeStorageDisplayComponent,
    TarifDisplayComponent,
    PChoicePipe,
    IconComponent,
  ],
})
export class OfferListComponent extends ModellistComponent<Offer> {
  @Input() public resto_detail_route?: Link<Resto>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public user_detail_route?: Link<User>;
  @Input() public tarif_detail_route?: Link<Tarif>;
  @Input() public offertype_detail_route?: Link<Offertype>;
  @Input() public offertypestorage_detail_route?: Link<OffertypeStorage>;
  @Input() public offertypestorage_offer_detail_route?: Link<Offer>;
  public details_restos = false;
  public importing_products = signal<number | null>(null);
  public importing_texts = signal<number | null>(null);
  public readonly OUL = OFFER_USER_LEVEL;

  constructor(
    coll: OfferService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _clients: ClientService,
    private _storages: StorageService,
    private _restos: RestoService,
    private _users: UserService,
  ) {
    super(coll, list, ofc);
  }

  public override detailRoute(item: Offer): (string | number)[] | null {
    if (item.offertypestorage || item.offertypestorage_details) {
      if (this.offertypestorage_offer_detail_route) {
         
        return this.offertypestorage_offer_detail_route.route({
          offer: item,
          offertypestorage: item.offertypestorage_details
            ? item.offertypestorage_details
            : ({ id: item.offertypestorage } as any),
        });
      }
    }
    return super.detailRoute(item);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "storages",
          "clients",
          "users",
          "restos",
          "statuses",
        ],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectMultiFilter({
          field: "types",
          name: "types",
          label: "Filtrer par type(s)",
          model: Offer,
          mfield: "type",
        }),
        new ModelListSelectMultiFilter({
          field: "statuses",
          name: "statuses",
          label: "Filtrer par état(s)",
          model: Offer,
          mfield: "status",
        }),
        new ModelListAutocompleteMultiFilter({
          field: "restos",
          name: "restos",
          label: "Filtrer par resto(s)",
          collection: this._restos,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "clients",
          name: "clients",
          label: "Filtrer par client(s)",
          collection: this._clients,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "storages",
          name: "storages",
          label: "Filtrer par dépôt(s)",
          collection: this._storages,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "users",
          name: "users",
          label: "Filtrer par auteur(s)",
          collection: this._users,
        }),
        new ModelListSelectFilter({
          field: "with_links",
          name: "with_links",
          label: "Filtrer par lien(s)",
          choices: [
            { value: "article", desc: "Avec lien catalogue" },
            { value: "earticle", desc: "Avec lien catalogue client" },
            { value: "att", desc: "Avec lien modèle tarif" },
            { value: "at", desc: "Avec ligne de tarif" },
            { value: "any", desc: "Tout lien disponible" },
            { value: "none", desc: "Sans aucun lien" },
          ],
        }),
      ],
    };
  }

  public override getRowClasses(row: Offer): string[] {
    const cls = super.getRowClasses(row);
    if (
      row.status === OFFER_STATUSES.PUBLISHED ||
      row.status === OFFER_STATUSES.PUBLISHED_SENT
    ) {
      cls.push("table-success");
    }
    if (
      row.status === OFFER_STATUSES.ARCHIVED ||
      row.status === OFFER_STATUSES.ARCHIVED_NOT_PUBLISHED
    ) {
      cls.push("table-secondary");
    }
    if (row.status === OFFER_STATUSES.PREPARATION) {
      cls.push("table-warning");
    }

    return cls;
  }
}
