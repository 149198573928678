import { Injectable } from "@angular/core";
import { Collection, DataBackend } from "@solidev/data";
import { CdataKey } from "./cdata-key";
import { map, Observable, ReplaySubject } from "rxjs";
import { CDATAKEY_DEST } from "./cdata-key.base";

@Injectable({
  providedIn: "root",
})
export class CdataKeyService extends Collection<CdataKey> {
  private _cdatakeys?: ReplaySubject<Map<number, CdataKey>>;

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/cdatakeys", CdataKey);
  }

  /** Get cdatakey by id, caching all cdatakeys values on first call */
  public get(id: number): Observable<CdataKey | undefined> {
    // FIXME: check order of pipe / need for asObservable ?
    return this.prefetch()
      .asObservable()
      .pipe(map((v) => v.get(id)));
  }

  /** Get cdatakeys by dest, caching all cdatakeys values on first call */
  public byDest(dest: CDATAKEY_DEST): Observable<CdataKey[]> {
    return this.prefetch()
      .asObservable()
      .pipe(map((v) => [...v.values()].filter((l) => l.dest === dest)));
  }

  private prefetch(): ReplaySubject<Map<number, CdataKey>> {
    if (!this._cdatakeys) {
      this._cdatakeys = new ReplaySubject<Map<number, CdataKey>>(1);
      this.list({
        fields: [
          "id",
          "name",
          "code",
          "type",
          "unit",
          "dest",
          "position",
          "choices_details",
          "parent",
        ].join(","),
      }).subscribe((res) => {
        this._cdatakeys!.next(
          new Map<number, CdataKey>(res.map((v) => [v.id, v])),
        );
      });
    }
    return this._cdatakeys;
  }
}
