
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="member">
  <li class="nav-item" *ngIf="member_display_route"><a class="nav-link" [routerLink]="member_display_route.route({member: member})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Résumé</a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="member_edit_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_edit_route.route({member: member})" routerLinkActive="active">Édition</a></li>
  <!-- Dépôts-->
  <li class="nav-item" *ngIf="member_storages_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_storages_route.route({member: member})" routerLinkActive="active">Dépôts</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="member_offers_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_offers_route.route({member: member})" routerLinkActive="active">Offres</a></li>
  <!-- Relations-->
  <li class="nav-item" *ngIf="member_relations_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_relations_route.route({member: member})" routerLinkActive="active">Relations</a></li>
  <!-- Producers-->
  <li class="nav-item" *ngIf="member_producers_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_producers_route.route({member: member})" routerLinkActive="active">Producteurs</a></li>
  <!-- Produits-->
  <li class="nav-item" *ngIf="member_products_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_products_route.route({member: member})" routerLinkActive="active">Produits</a></li>
  <!-- Users-->
  <li class="nav-item" *ngIf="member_users_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_users_route.route({member: member})" routerLinkActive="active">Utilisateurs</a></li>
  <!-- Sales-->
  <li class="nav-item" *ngIf="member_sales_route &amp;&amp; member.have_level(MUL.admin)"><a class="nav-link" [routerLink]="member_sales_route.route({member: member})" routerLinkActive="active">Ventes</a></li>
</ul>