import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TariftypeRelation } from '../tariftype-relation';
import { TariftypeRelationService } from '../tariftype-relation.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeRelationManageComponent } from '../tariftype-relation-manage/tariftype-relation-manage.component';
import { Tarif } from '../../tarif/tarif';
import { TarifRelationCreateComponent } from '../../tarif-relation/tarif-relation-create/tarif-relation-create.component';
import { TarifRelationManageComponent } from '../../tarif-relation/tarif-relation-manage/tarif-relation-manage.component';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { TarifBase } from '../../tarif/tarif.base';

@Component({
  selector: 'lvadg-tariftype-relation-list',
  standalone: true,
  templateUrl: './tariftype-relation-list.component.pug',
  styleUrls: ['./tariftype-relation-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    SafeDeleteComponent,
    TariftypeRelationManageComponent,
    TarifRelationManageComponent,
    TarifRelationCreateComponent,
    TariftypeDisplayComponent,
  ],
})
export class TariftypeRelationListComponent extends ModellistComponent<TariftypeRelation> {
  @Input() public dest_tarif?: Tarif;
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  @Input() public tarif_detail_route?: Link<TarifBase>;

  constructor(
    coll: TariftypeRelationService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          model: TariftypeRelation,
          mfield: 'type',
          label: 'Recherche par type',
        }),
      ],
    };
  }
}
