import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OfferNavComponent } from '../_nav/offer-nav.component';
import { OfferViewData, OfferViewParams } from '../menu';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Offer } from '../../../../../models/offers/offer/offer';
import { OfferManageComponent } from '../../../../../models/offers/offer/offer-manage/offer-manage.component';
import { SafeDeleteComponent, TabMemoryService } from '@solidev/data';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../models/users/auth.service';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { OFFER_USER_LEVEL } from '../../../../../models/offers/offer/offer.base';
import { User } from '../../../../../models/users/user/user';

export interface OfferEditViewParams extends OfferViewParams {}

export interface OfferEditViewData extends OfferViewData, OfferEditViewParams {}

@Component({
  selector: 'lvadg-offer-edit-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OfferNavComponent, OfferManageComponent, SafeDeleteComponent],
  templateUrl: './offer-edit-view.component.pug',
  styleUrls: ['./offer-edit-view.component.sass'],
})
export class OfferEditViewComponent extends BaseDetailViewComponent<OfferEditViewData, Offer> {
  public canDelete = signal(false);
  public fdata$!: Observable<{ data: OfferEditViewData; user: User | null }>;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _auth: AuthService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.fdata$ = combineLatest([this.data$, this._auth.currentUser$]).pipe(
      tap(([data, user]) => {
        this.canDelete.set(
          user?.is_superuser || user?.is_staff || data.offer.have_level(OFFER_USER_LEVEL.o_write) || false,
        );
      }),
      map(([data, user]) => ({ data, user })),
    );
  }

  public override setRouteData(data: OfferEditViewData) {
    super.setRouteData(data);
  }

  public onDeleted(data: OfferEditViewData, offer: Offer): Promise<boolean> {
    return this._router.navigate(['../..'], { relativeTo: this._route });
  }
}
