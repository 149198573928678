import { Injectable } from '@angular/core';
import { Tariftype } from '../tariftype/tariftype';
import { Tarif } from './tarif';
import { DataMessageService } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../components/utils/errormessage';
import { TARIFTYPESTORAGE_PERMISSION } from '../tariftype-storage-permission/tariftype-storage-permission.base';
import { TARIFTYPE_PERMISSION } from '../tariftype-permission/tariftype-permission.base';
import { TARIFNOTIFICATION_TYPE } from '../tarif-notification-destination/tarif-notification-destination.base';
import { OpLog } from '../../../components/utils/oplog/oplog';
import { EAMAPPING_STATUS } from '../../catalog/eamapping/eamapping.base';
import { TARIFTYPEREGION_PERMISSION } from '../tariftype-region-permission/tariftype-region-permission.base';

interface TarifTypeArticleAddActionParams {
  action: 'article_add';
  article: number; // pk de l'article à ajouter
}

interface TarifTypeCreateTarifsActionParams {
  action: 'create_tarif';
  datestart: string | Date;
  dateend: string | Date;
  last?: number; // pk du tarif source
  name?: string; // nom_du_nouveau_tarif
}

interface TarifTypeSyncRelationActionParams {
  action: 'sync_relation';
  relation: number; // pk de la relation (TarifTypeRelation)
}

interface TarifTypeAddStorageActionParams {
  action: 'add_storage';
  storage: number; // pk du dépôt
}

interface TarifTypeAddStoragePermissionActionParams {
  action: 'add_storage_permission';
  ttstorage: number; // pk de la liaison modèle / dépôt (TarifTypeStorage)
  user: number | null; // pk de l'utilisateur
  group: number | null; // pk du groupe
  permission: TARIFTYPESTORAGE_PERMISSION; // permission, défaut = WRITE
}

interface TarifTypeRemoveStorageActionParams {
  action: 'remove_storage';
  ttstorage: number; // pk de la liaison modèle / dépôt (TarifTypeStorage)
}

interface TarifTypeAddRegionActionParams {
  action: 'add_region';
  region: number; // pk du dépôt
}

interface TarifTypeAddRegionPermissionActionParams {
  action: 'add_region_permission';
  ttregion: number; // pk de la liaison modèle / dépôt (TarifTypeRegion)
  user: number | null; // pk de l'utilisateur
  group: number | null; // pk du groupe
  permission: TARIFTYPEREGION_PERMISSION; // permission, défaut = WRITE
}

interface TarifTypeRemoveRegionActionParams {
  action: 'remove_region';
  ttregion: number; // pk de la liaison modèle / dépôt (TarifTypeRegion)
}

interface TarifTypeAddPermissionActionParams {
  action: 'add_permission';
  user: number | null; // pk de l'utilisateur
  group: number | null; // pk du groupe
  permission: TARIFTYPE_PERMISSION; // permission, défaut = WRITE
}

interface TarifTypeAddDestinationActionParams {
  action: 'add_destination';
  user?: number; // pk de l'utilisateur
  group?: number; // pk du groupe
  contact?: number; // pk du contact
  contact_last_name?: string; // création de contact : nom de famille
  contact_first_name?: string; // création de contact : prénom
  contact_email?: string; // créationde contact : email
  notification?: TARIFNOTIFICATION_TYPE;
  // type de notification, defaut = NOTIF_PUBLICATION_ALL
}

interface TarifTypeRemoveDestinationActionParams {
  action: 'remove_destination';
  destination: number; // pk de la destination
}

interface TarifTypeToggleStarBellActionParams {
  action: 'toggle_starbell';
  mode?: 'star' | 'bell'; // mode = star/bell, defaut = "bell"
}

interface TarifTypeGenLifecycleStepsActionParams {
  action: 'gen_lifecycle_steps';
}

interface TarifTypeImportClientEarticlesActionParams {
  action: 'import_client_earticles';
  document_id: number | undefined;
  upload_id: number | undefined;
  metadata_id: number;
  confirm: boolean;
}

interface TarifTypeEamappingAddArticleActionParams {
  action: 'eamapping_add_article';
  eamapping: number;
  article: number;
}

interface TarifTypeEamappingRemoveArticleActionParams {
  action: 'eamapping_remove_article';
  eamapping: number;
}

interface TarifTypeEamappingChangeStatusActionParams {
  action: 'eamapping_change_status';
  eamapping: number;
  status: EAMAPPING_STATUS;
}

interface TarifTypeEamappingChangeStatusActionParams {
  action: 'eamapping_change_status';
  eamapping: number;
  status: EAMAPPING_STATUS;
}

interface TarifTypeEamappingToTarifActionParams {
  action: 'eamapping_to_tarif';
}

interface TarifTypeEamappingUpdateStatusesActionParams {
  action: 'eamapping_update_statuses';
}

interface TarifTypeEamappingGetFromBulkActionParams {
  action: 'eamapping_get_from_bulk';
}

interface TarifTypeAddMetadataActionParams {
  action: 'add_metadata';
  tariftype: number;
  document?: number;
  type: string;
  name: string;
}

export type TariftypeActionParams =
  | TarifTypeArticleAddActionParams
  | TarifTypeCreateTarifsActionParams
  | TarifTypeSyncRelationActionParams
  | TarifTypeAddStorageActionParams
  | TarifTypeAddStoragePermissionActionParams
  | TarifTypeRemoveStorageActionParams
  | TarifTypeAddRegionActionParams
  | TarifTypeAddRegionPermissionActionParams
  | TarifTypeRemoveRegionActionParams
  | TarifTypeAddPermissionActionParams
  | TarifTypeAddDestinationActionParams
  | TarifTypeRemoveDestinationActionParams
  | TarifTypeToggleStarBellActionParams
  | TarifTypeGenLifecycleStepsActionParams
  | TarifTypeImportClientEarticlesActionParams
  | TarifTypeEamappingAddArticleActionParams
  | TarifTypeEamappingRemoveArticleActionParams
  | TarifTypeEamappingChangeStatusActionParams
  | TarifTypeEamappingToTarifActionParams
  | TarifTypeEamappingUpdateStatusesActionParams
  | TarifTypeEamappingGetFromBulkActionParams
  | TarifTypeAddMetadataActionParams;

export interface TariftypeActionResult {
  tariftype: Tariftype;
  tarif?: Tarif;
  log?: OpLog;
  data?: any;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TariftypeActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action<T extends TariftypeActionResult>(
    tt: Tariftype,
    action: TariftypeActionParams,
    messages: Record<string, string> = {},
  ): Promise<T> {
    try {
      const res = await firstValueFrom(
        tt.action<Tariftype, TariftypeActionResult>('POST', 'action', {
          body: {
            ...action,
          },
        }),
      );
      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res as T;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { tariftype: tt, message: message, success: false } as T;
    }
  }
}
