import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArticleTarifTemplateRelation } from "../article-tarif-template-relation";
import {
  NgbOffcanvas,
  NgbOffcanvasModule,
  NgbOffcanvasRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ArticleTarifTemplateListComponent } from "../../article-tarif-template/article-tarif-template-list/article-tarif-template-list.component";
import { ArticleTarifTemplateDisplayComponent } from "../../article-tarif-template/article-tarif-template-display/article-tarif-template-display.component";
import { ModelListAction } from "../../../../includes/modellist/modellist.component";
import { ArticleTarifTemplate } from "../../article-tarif-template/article-tarif-template";
import { DataMessageService, FilterDefaults, Link } from "@solidev/data";
import { TariftypeBase } from "../../tariftype/tariftype.base";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../../components/utils/errormessage";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { ArticleBase } from "../../../catalog/article/article.base";
import { ArticleTarifTemplateBase } from "../../article-tarif-template/article-tarif-template.base";
import { EarticleBase } from "../../../catalog/earticle/earticle.base";
import { ArticleDisplayComponent } from "../../../catalog/article/article-display/article-display.component";

@Component({
  selector: "lvadg-article-tarif-template-relation-manage",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbOffcanvasModule,
    ArticleTarifTemplateDisplayComponent,
    ArticleTarifTemplateListComponent,
    ArticleDisplayComponent,
  ],
  templateUrl: "./article-tarif-template-relation-manage.component.pug",
  styleUrls: ["./article-tarif-template-relation-manage.component.sass"],
})
export class ArticleTarifTemplateRelationManageComponent implements OnInit {
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;

  @Input()
  public articletariftemplate_detail_route?: Link<ArticleTarifTemplateBase>;
  @Input() public articletariftemplaterelation?: ArticleTarifTemplateRelation;
  @Input() public mode = "inline" as const;
  @Input() public field?: "dest" | "src" | "ops";
  @Output() public updated = new EventEmitter<ArticleTarifTemplateRelation>();
  public attselector_default_fields = [
    "id",
    "article_details",
    "article__uf",
    "article__colisage",
    "eaclient_details",
    "status",
    "actions",
  ];
  @ViewChild("attselector") public attselectDiv!: ElementRef<HTMLDivElement>;
  public attselectorFilter: FilterDefaults = {};
  public attselectorTarget?: ArticleTarifTemplate;
  public attselectorSource?: TariftypeBase;
  public attselectorCurrent?: ArticleTarifTemplate;
  public editOps: boolean = false;
  public opsFormControl = new FormControl<string>("");
  private attselectorOffCanvas?: NgbOffcanvasRef;

  constructor(
    private _ofc: NgbOffcanvas,
    private _msgs: DataMessageService,
  ) {}

  public ngOnInit(): void {
    this.opsFormControl.setValue(this.articletariftemplaterelation?.ops || "");
  }

  public openModal() {
    if (!this.articletariftemplaterelation) {
      return;
    }
    const attrel = this.articletariftemplaterelation;
    if (this.field === "src") {
      this.attselectorSource = attrel.ttrel_src_details;
      this.attselectorTarget = attrel.dest_details;
      this.attselectorFilter = { tariftype: attrel.ttrel_src_details?.id };
      this.attselectorCurrent = attrel.src_details;
    } else if (this.field === "dest") {
      this.attselectorSource = attrel.ttrel_dest_details;
      this.attselectorTarget = attrel.src_details;
      this.attselectorFilter = { tariftype: attrel.ttrel_dest_details?.id };
      this.attselectorCurrent = attrel.dest_details;
    }
    this.attselectorOffCanvas = this._ofc.open(this.attselectDiv, {
      position: "end",
      panelClass: "w-75",
    });
  }

  public async attSelected(actData: ModelListAction<ArticleTarifTemplate>) {
    if (actData.action === "select") {
      const target = actData.model;
      if (!target || !this.articletariftemplaterelation) {
        return;
      }
      const attrel = this.articletariftemplaterelation;
      try {
        const res = await firstValueFrom(
          attrel.action("POST", "manage", {
            body: {
              action: `set_${this.field}_att`,
              att: target.id,
            },
          }),
        );
        this._msgs.success("Association créée avec succès");
        this.updated.emit(attrel);
        this.attselectorOffCanvas?.close();
      } catch (e) {
        const { title, message, context } = getErrorContext(e, {});
        this._msgs.error(title, message, context);
        this.attselectorOffCanvas?.close();
      }
    }
  }

  public async attRemove() {
    if (!this.articletariftemplaterelation) {
      return;
    }
    const attrel = this.articletariftemplaterelation;
    try {
      const res = await firstValueFrom(
        attrel.action("POST", "manage", {
          body: {
            action: `remove_${this.field}_att`,
          },
        }),
      );
      this._msgs.success("Association supprimée avec succès");
      this.updated.emit(attrel);
      this.attselectorOffCanvas?.close();
    } catch (e) {
      const { title, message, context } = getErrorContext(e, {});
      this._msgs.error(title, message, context);
      this.attselectorOffCanvas?.close();
    }
  }

  public async updateOps() {
    if (!this.articletariftemplaterelation) {
      return;
    }
    const attrel = this.articletariftemplaterelation;
    try {
      const res = await firstValueFrom(
        attrel.action("POST", "manage", {
          body: {
            action: `update_ops`,
            ops: this.opsFormControl.value || "",
          },
        }),
      );
      this._msgs.success("Opération modifiée");
      this.editOps = false;
      this.updated.emit(attrel);
    } catch (e) {
      const { title, message, context } = getErrorContext(e, {});
      this.editOps = false;
      this._msgs.error(title, message, context);
    }
  }
}
