import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Region } from '../region';
import { DispeditComponent } from '@solidev/data';
import { GeoregionService } from '../../../../locations/georegion/georegion.service';
import { GeodepartementService } from '../../../../locations/geodepartement/geodepartement.service';
import { GeocommuneService } from '../../../../locations/geocommune/geocommune.service';

@Component({
  selector: 'lvadg-region-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './region-manage.component.pug',
  styleUrls: ['./region-manage.component.sass'],
})
export class RegionManageComponent {
  @Input() public region!: Region;

  constructor(
    public georegions$: GeoregionService,
    public geodepartements$: GeodepartementService,
    public geocommunes$: GeocommuneService,
  ) {}
}
