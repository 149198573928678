
<ng-container *ngIf="product(); let p">
  <div class="text-uppercase fs-5">
    <lvadg-family-display [family]="p.family_details" mode="line"></lvadg-family-display>
  </div>
  <div class="producer text-uppercase fs-3">Producteur :
    <lvadg-producer-display [producer]="p.producer_details" mode="1linewcpville" [detail_route]="producer_detail_route()"></lvadg-producer-display>
  </div>
  <div class="description fs-5" [innerHTML]="p.content_html || p.family_details?.article_description || ''"></div>
  <div class="d-flex flex-row justify-content-around">
    <div class="plabels my-3">
      <ng-container *ngFor="let label of p.labels_details">
        <div class="d-flex flex-row justify-content-around">
          <lvadg-label-display [label]="label" mode="large" [hover]="false" [thumbnail]="false"></lvadg-label-display>
          <ng-container *ngFor="let pd of p.documents_details">
            <lvadg-producer-document-display class="mx-1" [producerdocument]="pd" mode="details" *ngIf="pd.is_valid &amp;&amp; pd.label &amp;&amp; pd.label == label.id"></lvadg-producer-document-display>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="documents my-2" *ngIf="haveDocuments()">
      <ng-container *ngFor="let document of p.documents_details">
        <lvadg-producer-document-display class="mx-1" [producerdocument]="document" mode="details" *ngIf="document.is_valid &amp;&amp; !document.label"></lvadg-producer-document-display>
      </ng-container>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-around align-items-center fs-5">
    <lvadg-cdatas-display class="mt-3" [cdatas]="p.cdata_details" [dest]="CDATAKEY_DEST.PRODUCT"></lvadg-cdatas-display>
  </div>
  <div class="d-flex flex-column justify-content-around align-items-center">
    <lvadg-image-display *ngIf="p.clogo_details" [image]="p.clogo_details" mode="large"></lvadg-image-display>
  </div>
  <div class="videos my-2" *ngIf="haveVideos()">
    <ng-container *ngFor="let image of p.images_details">
      <div *ngIf="image.video">
        <lvadg-video-display [video]="image" [containerClass]="'ratio ratio-16x9'"></lvadg-video-display>
      </div>
    </ng-container>
  </div>
  <div class="photos mb-5" *ngIf="havePhotos()">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let image of carouselImages()"><a class="d-flex align-items-center justify-content-center bg-secondary-subtle" [href]="image.file" target="_blank">
          <lvadg-image-display class="mx-auto my-3" [image]="image" mode="large" [hover]="false" [thumbnail]="false"></lvadg-image-display></a>
        <!--.bg-primary-subtle.ratio.ratio-16x9-->
        <!--  img.object-fit-cover.rounded.img-thumbnail([src]="image.file", [alt]="image.title", [title]="image.title")-->
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="d-flex flex-column justify-content-around align-items-center">
    <lvadg-seasons-manage class="mt-3" [seasons]="p.seasons" [editable]="false" size="large"></lvadg-seasons-manage>
  </div>
  <ng-container *ngIf="display_producer()">
    <ng-container *ngIf="producer(); let pr">
      <div class="relations my-5">
        <ng-container *ngIf="pr.storage_relations_details">
          <lvadg-relation-listmap [model]="pr" [relations]="pr.storage_relations_details"></lvadg-relation-listmap>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <div class="mt-5" *ngIf="p.ciqual_alim">
    <h2>Informations nutritionnelles</h2>
    <p class="text-info"><a [href]="'https://ciqual.anses.fr/#/aliments/'+p.ciqual_alim_details?.alim_code+'/data'" target="_blank"><i class="fa fa-external-link me-2"></i>Source: CIQUAL</a></p>
    <div class="maxh">
      <table class="table table-sm table-borderless table-striped" *ngIf="p.ciqual_alim">
        <tbody>
          <tr *ngFor="let c of p.ciqual_alim_details?.values_details">
            <td>{{c.column_name}}</td>
            <td>
              <ng-container *ngIf="c.value!=null">{{c.less?'< ': ''}} {{c.value/100000}} {{c.column_unit}}</ng-container>
              <ng-container *ngIf="c.value==null">{{c.traces?'traces':''}}</ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>