import { RnmBase } from './rnm.base';
import { detailsField } from '@solidev/data';
import { FamilyBase } from '../../catalog/family/family.base';
import { Label } from '../../catalog/label/label';
import { CalibreBase } from '../../catalog/meta/calibre/calibre.base';
import { CategoryBase } from '../../catalog/meta/category/category.base';
import { OriginBase } from '../../catalog/meta/origin/origin.base';
import { ProcessBase } from '../../catalog/meta/process/process.base';
import { TreatmentBase } from '../../catalog/meta/treatment/treatment.base';
import { RegionBase } from '../../catalog/meta/region/region.base';
import { PresentationBase } from '../../catalog/meta/presentation/presentation.base';
import { ParticularityBase } from '../../catalog/meta/particularity/particularity.base';
import { ProductionBase } from '../../catalog/meta/production/production.base';

export class Rnm extends RnmBase {
  static override readonly __name: string = 'Rnm';
  @detailsField({
    model: FamilyBase,
    readonly: true,
    description: 'Famille',
  })
  public family_details?: FamilyBase;

  @detailsField({
    model: Label,
    readonly: true,
    description: 'Labels et certifications',
    many: true,
  })
  public egalim_details?: Label[];

  @detailsField({
    model: CalibreBase,
    readonly: true,
    description: 'Calibre',
  })
  public calibre_details?: CalibreBase;

  @detailsField({
    model: CategoryBase,
    readonly: true,
    description: 'Catégorie',
  })
  public categorie_details?: CategoryBase;

  @detailsField({
    model: OriginBase,
    readonly: true,
    description: 'Origine',
  })
  public origine_details?: OriginBase;

  @detailsField({
    model: ProcessBase,
    readonly: true,
    description: 'Process',
  })
  public process_details?: ProcessBase;

  @detailsField({
    model: TreatmentBase,
    readonly: true,
    description: 'Traitement',
  })
  public traitement_details?: TreatmentBase;

  @detailsField({
    model: RegionBase,
    readonly: true,
    description: 'Région',
  })
  public region_details?: RegionBase;

  @detailsField({
    model: PresentationBase,
    readonly: true,
    description: 'Présentation',
  })
  public presentation_details?: PresentationBase;

  @detailsField({
    model: ParticularityBase,
    readonly: true,
    description: 'Particularité',
  })
  public particularite_details?: ParticularityBase;

  @detailsField({
    model: ProductionBase,
    readonly: true,
    description: 'Production',
  })
  public production_details?: ProductionBase;
}
