import { TariftypeRegionBase } from './tariftype-region.base';
import { detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { Genericregion } from '../../locations/genericregion/genericregion';
import { TariftypeRegionPermission } from '../tariftype-region-permission/tariftype-region-permission';

export class TariftypeRegion extends TariftypeRegionBase {
  static override readonly __name: string = 'TariftypeRegion';

  @detailsField({
    model: TariftypeBase,
    readonly: true,
    description: 'Modèle de tarif',
    priority: 800,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: Genericregion,
    readonly: true,
    description: 'Région',
    priority: 700,
  })
  public region_details?: Genericregion;

  @detailsField({
    model: TariftypeRegionPermission,
    description: 'Permissions',
    readonly: true,
    many: true,
    priority: 600,
  })
  public perms_details?: TariftypeRegionPermission[];
}
