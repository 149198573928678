import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferProduct } from '../../../models/offers/offer-product/offer-product';
import { OfferProductManageComponent } from '../../../models/offers/offer-product/offer-product-manage/offer-product-manage.component';
import { FilterDefaults, Link } from '@solidev/data';
import { Observable, of, Subject } from 'rxjs';
import { OfferProductLinkListComponent } from '../../../models/offers/offer-product-link/offer-product-link-list/offer-product-link-list.component';
import { OfferProductLinkCreateComponent } from '../../../models/offers/offer-product-link/offer-product-link-create/offer-product-link-create.component';
import { IconComponent } from '../../utils/icon/icon.component';
import { ProductDisplayComponent } from '../../../models/catalog/product/product-display/product-display.component';
import { Product } from '../../../models/catalog/product/product';
import { Article } from '../../../models/catalog/article/article';
import { Earticle } from '../../../models/catalog/earticle/earticle';
import { ArticleTarifTemplate } from '../../../models/tarifs/article-tarif-template/article-tarif-template';
import { Tarif } from '../../../models/tarifs/tarif/tarif';
import { LabelDisplayComponent } from '../../../models/catalog/label/label-display/label-display.component';
import { ProducerDisplayComponent } from '../../../models/structures/producer/producer-display/producer-display.component';
import { SeasonsManageComponent } from '../../../models/catalog/common/seasons-manage/seasons-manage.component';
import { Family } from '../../../models/catalog/family/family';
import { Producer } from '../../../models/structures/producer/producer';
import { ModelListAction } from '../../../includes/modellist/modellist.component';
import { OfferProductLink } from '../../../models/offers/offer-product-link/offer-product-link';
import { OfferActionService } from '../../../models/offers/offer/offer-action.service';
import { Offer } from '../../../models/offers/offer/offer';

@Component({
  selector: 'lvadg-offer-product-edit',
  standalone: true,
  imports: [
    CommonModule,
    ProductDisplayComponent,
    OfferProductManageComponent,
    OfferProductLinkListComponent,
    OfferProductLinkCreateComponent,
    LabelDisplayComponent,
    ProducerDisplayComponent,
    SeasonsManageComponent,
    IconComponent,
  ],
  templateUrl: './offer-product-edit.component.pug',
  styleUrls: ['./offer-product-edit.component.sass'],
})
export class OfferProductEditComponent implements OnInit {
  @Input({ required: true }) public offer!: Offer;
  @Input({ required: true }) public offerproduct!: OfferProduct;
  @Input() public product_detail_route?: Link<Product>;
  @Input() public producer_detail_route?: Link<Producer>;
  @Input() public family_detail_route?: Link<Family>;
  @Input() public article_detail_route?: Link<Article>;
  @Input() public earticle_detail_route?: Link<Earticle>;
  @Input() public att_detail_route?: Link<ArticleTarifTemplate>;
  @Input() public tarif_detail_route?: Link<Tarif>;
  @Input() public reload?: Subject<void | boolean> = new Subject<void | boolean>();
  @Output() public link_action = new EventEmitter<ModelListAction<OfferProductLink>>();
  public offerproductFilter$!: Observable<FilterDefaults>;
  public links_default_fields = ['article_details', 'earticle_details', 'att_details', 'at_details', 'actions'];
  public add_link = false;

  constructor(private _oa: OfferActionService) {}

  public ngOnInit(): void {
    this.offerproductFilter$ = of({ offerproduct: this.offerproduct.id });
  }

  public async linkDelete(act: ModelListAction<OfferProductLink>): Promise<void> {
    this.link_action.emit(act);
  }
}
