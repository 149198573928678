import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_VIEW_IMPORTS } from '../../../../components/baseview/baseview.imports';
import { TarifFamiliesStatsComponent } from '../../../../models/tarifs/stats/tarif-families-stats/tarif-families-stats.component';
import { BaseViewComponent } from '../../../../components/baseview/baseview.component';

export interface TarifStatsFamiliesViewParams extends BaseRouteParams {
  family_detail_route: RouteConfigItem;
}

export interface TarifStatsFamiliesViewData extends TarifStatsFamiliesViewParams {}

@Component({
  selector: 'lvadg-tarif-stats-families-view',
  standalone: true,
  imports: [...BASE_VIEW_IMPORTS, TarifFamiliesStatsComponent],
  templateUrl: './tarif-stats-families-view.component.pug',
  styleUrls: ['./tarif-stats-families-view.component.sass'],
})
export class TarifStatsFamiliesViewComponent extends BaseViewComponent<TarifStatsFamiliesViewData> {
  public families_default_fields = ['id', 'name', 'period_stats_price', 'period_stats_sdev', 'interval_stats'];
  public family_detail_route?: Link<any>;

  public override setRouteData(data: TarifStatsFamiliesViewData) {
    super.setRouteData(data);
    this.family_detail_route = new Link(data.family_detail_route, this);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
