import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Production } from '../../../../../models/catalog/meta/production/production';

export interface ProductionDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface ProductionDetailViewData extends ProductionDetailViewParams {
  production: Production;
}
