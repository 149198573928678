import { Component, ElementRef, signal, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferViewData, OfferViewParams } from "../menu";
import {
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { OfferProductListComponent } from "../../../../../models/offers/offer-product/offer-product-list/offer-product-list.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Offer } from "../../../../../models/offers/offer/offer";
import { combineLatest, map, Observable, Subject, tap } from "rxjs";
import { OfferProduct } from "../../../../../models/offers/offer-product/offer-product";
import { Article } from "../../../../../models/catalog/article/article";
import { Earticle } from "../../../../../models/catalog/earticle/earticle";
import { ArticleTarifTemplate } from "../../../../../models/tarifs/article-tarif-template/article-tarif-template";
import { Tarif } from "../../../../../models/tarifs/tarif/tarif";
import { Product } from "../../../../../models/catalog/product/product";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OfferActionService } from "../../../../../models/offers/offer/offer-action.service";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { ProductListComponent } from "../../../../../models/catalog/product/product-list/product-list.component";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../../../../models/users/auth.service";
import { User } from "../../../../../models/users/user/user";
import { OFFER_USER_LEVEL } from "../../../../../models/offers/offer/offer.base";
import { OfferListComponent } from "../../../../../models/offers/offer/offer-list/offer-list.component";
import { Family } from "../../../../../models/catalog/family/family";
import { Producer } from "../../../../../models/structures/producer/producer";
import { OfferProductEditComponent } from "../../../../../components/offers/offer-product-edit/offer-product-edit.component";
import { OfferProductLink } from "../../../../../models/offers/offer-product-link/offer-product-link";

export interface OfferProductsViewParams extends OfferViewParams {
  offerproduct_detail_route?: RouteConfigItem;
  product_detail_route?: RouteConfigItem;
  article_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
  att_detail_route?: RouteConfigItem;
  tarif_detail_route?: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
  producer_detail_route?: RouteConfigItem;
}

export interface OffertProductsViewData
  extends OfferViewData,
    OfferProductsViewParams {}

@Component({
  selector: "lvadg-offer-products-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OfferNavComponent,
    OfferProductListComponent,
    OfferListComponent,
    ProductListComponent,
    OfferProductEditComponent,
    IconComponent,
  ],
  templateUrl: "./offer-products-view.component.pug",
  styleUrls: ["./offer-products-view.component.sass"],
})
export class OfferProductsViewComponent extends BaseDetailViewComponent<
  OffertProductsViewData,
  Offer
> {
  public offerFilter$!: Observable<FilterDefaults>;
  public forOfferFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public offerproduct_detail_route?: Link<OfferProduct>;
  public product_detail_route?: Link<Product>;
  public article_detail_route?: Link<Article>;
  public earticle_detail_route?: Link<Earticle>;
  public att_detail_route?: Link<ArticleTarifTemplate>;
  public tarif_detail_route?: Link<Tarif>;
  public family_detail_route?: Link<Family>;
  public producer_detail_route?: Link<Producer>;
  public offer!: Offer;
  public add_product_default_fields: string[] = [
    "name",
    "labels_details",
    "seasons",
    "producer_details",
    "storage_relations_details",
    "in_offer",
    "actions",
  ];
  @ViewChild("ofcslot_add", { static: false })
  public ofcSlotAdd!: ElementRef<HTMLDivElement>;
  public ofcInstanceAdd?: NgbOffcanvasRef;
  @ViewChild("ofcslot_remove", { static: false })
  public ofcSlotRemove!: ElementRef<HTMLDivElement>;
  public ofcInstanceRemove?: NgbOffcanvasRef;
  @ViewChild("ofcslot_import", { static: false })
  public ofcSlotImport!: ElementRef<HTMLDivElement>;
  public ofcInstanceImport?: NgbOffcanvasRef;
  @ViewChild("ofcslot_edit", { static: false })
  public ofcSlotEdit!: ElementRef<HTMLDivElement>;
  public ofcInstanceEdit?: NgbOffcanvasRef;

  public canEdit = signal(false);
  public fdata$!: Observable<{
    data: OffertProductsViewData;
    user: User | null;
  }>;
  public products_import_default_fields: string[] = [
    "title",
    "client_details",
    "storage_details",
    "datestart",
    "dateend",
    "status",
    "products_count",
    "storages_details",
    "actions",
  ];
  public default_fields = [
    "product_details",
    "product_labels_details",
    "producer_details",
    "producer_storage_relation_details",
    "product_client_articles",
    "comment",
  ];
  public currentProduct?: OfferProduct;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _oa: OfferActionService,
    private _ofc: NgbOffcanvas,
    private _auth: AuthService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offerFilter$ = this.data$.pipe(
      map((data) => ({ offer: data.offer.id })),
    );
    this.forOfferFilter$ = this.data$.pipe(
      map((data) => ({
        storage: this.offer.storage,
        for_offer: data.offer.id,
        in_offer: data.offer.id,
      })),
    );
    this.fdata$ = combineLatest([this.data$, this._auth.currentUser$]).pipe(
      tap(([d, user]) => {
        this.canEdit.set(
          user?.is_superuser ||
            d.offer.have_level(OFFER_USER_LEVEL.o_write) ||
            false,
        );
        if (this.canEdit() && this.default_fields.indexOf("order") === -1) {
          this.default_fields.push("order");
        }
        if (this.canEdit() && this.default_fields.indexOf("actions") === -1) {
          this.default_fields.push("actions");
        }
      }),
      map(([d, user]) => ({ data: d, user })),
    );
  }

  public override setRouteData(data: OffertProductsViewData) {
    super.setRouteData(data);
    if (data.offerproduct_detail_route) {
      this.offerproduct_detail_route = new Link(
        data.offerproduct_detail_route,
        data,
        this._router,
      );
    }
    if (data.product_detail_route) {
      this.product_detail_route = new Link(
        data.product_detail_route,
        data,
        this._router,
      );
    }
    if (data.article_detail_route) {
      this.article_detail_route = new Link(
        data.article_detail_route,
        data,
        this._router,
      );
    }
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(
        data.earticle_detail_route,
        data,
        this._router,
      );
    }
    if (data.att_detail_route) {
      this.att_detail_route = new Link(
        data.att_detail_route,
        data,
        this._router,
      );
    }
    if (data.tarif_detail_route) {
      this.tarif_detail_route = new Link(
        data.tarif_detail_route,
        data,
        this._router,
      );
    }
    if (data.family_detail_route) {
      this.family_detail_route = new Link(
        data.family_detail_route,
        data,
        this._router,
      );
    }
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(
        data.producer_detail_route,
        data,
        this._router,
      );
    }
    this.offer = data.offer;
  }

  public async openAddProduct() {
    this.ofcInstanceAdd = this._ofc.open(this.ofcSlotAdd, {
      panelClass: "w-75",
      position: "end",
    });
  }

  public async openRemoveAll() {
    this.ofcInstanceRemove = this._ofc.open(this.ofcSlotRemove, {
      position: "end",
    });
  }

  public async processRemoveAll() {
    await this._oa.action(this.offer, {
      action: "remove_all_products",
    });
    this.reload$?.next(true);
    this.ofcInstanceRemove?.dismiss();
  }

  public async openImportProducts() {
    this.ofcInstanceImport = this._ofc.open(this.ofcSlotImport, {
      panelClass: "w-75",
      position: "end",
    });
  }

  public async addProduct(event: ModelListAction<Product>) {
    if (event.model?.id) {
      await this._oa.action(this.offer, {
        action: "add_product",
        product: event.model.id,
      });
    }
    this.reload$?.next(true);
  }

  public async processImportProducts($event: ModelListAction<Offer>) {
    if (!$event.model?.id) {
      return;
    }
    await this._oa.action(this.offer, {
      action: "import_products",
      source: $event.model.id,
    });
    this.reload$?.next(true);
    this.ofcInstanceImport?.dismiss();
  }

  public onProductAction($event: ModelListAction<OfferProduct>) {
    if ($event.model) {
      if ($event.action === "edit") {
        this.currentProduct = $event.model;
        this.ofcInstanceEdit = this._ofc.open(this.ofcSlotEdit, {
          panelClass: "w-75",
          position: "end",
        });
        this.ofcInstanceEdit.result.finally(() => {
          this.reload$?.next(true);
        });
      }
    }
  }

  public async onLinkAction($event: ModelListAction<OfferProductLink>) {
    if ($event.model) {
      if ($event.action === "delete") {
        await this._oa.action(this.offer, {
          action: "remove_product_link",
          link: $event.model.id,
        });
      }
    }
    this.reload$?.next(true);
  }
}
