import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
} from '@solidev/data';
import { ZONES, ZONES_LABELS, ZONES_LIST } from '../zones';

export enum FOLDER_TYPE {
  DEFAULT = 'default',
  FILE = 'file',
  VIDEO = 'video',
}

export enum FOLDER_STATUS {
  ONLINE = 'OK',
  ONLINE_STAFF_ONLY = 'ST',
  ONLINE_SU_ONLY = 'SU',
  OFFLINE = 'KO',
  DELETED = 'DE',
}

export enum FOLDER_USER_LEVEL {
  sadmin = 'sadmin',
  owner = 'owner',
  read = 'read',
  anon = 'anon',
}

export class FolderBase extends DataModel {
  static override readonly __name: string = 'FolderBase';

  @primaryField({ name: 'id', description: 'ID', priority: 1000 })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 110,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'parent',
    description: 'ID Parent',
    related: 'Folder',
    priority: -1,
  })
  public parent!: number;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 200,
    priority: 900,
  })
  public name!: string;

  @charField({
    name: 'slug',
    description: 'Slug',
    maxLength: 200,
    priority: 890,
  })
  public slug!: string;

  @charField({
    name: 'zone',
    description: 'zone',
    defaultValue: ZONES.FLD,
    maxLength: 6,
    choices: ZONES_LIST.map((label) => ({
      value: label,
      desc: ZONES_LABELS[label],
    })),
    priority: 800,
  })
  public zone: ZONES = ZONES.FLD;

  @charField({
    name: 'type',
    description: 'type',
    defaultValue: FOLDER_TYPE.DEFAULT,
    maxLength: 10,
    choices: [
      { value: FOLDER_TYPE.DEFAULT, desc: 'Affichage par défaut' },
      { value: FOLDER_TYPE.FILE, desc: 'Dossier de fichiers' },
      { value: FOLDER_TYPE.VIDEO, desc: 'Dossier de vidéos' },
    ],
    priority: 700,
  })
  public type: FOLDER_TYPE = FOLDER_TYPE.DEFAULT;

  @foreignKeyField({
    name: 'image',
    description: 'ID Image',
    related: 'Image',
    priority: -1,
  })
  public image!: number;

  @charField({
    name: 'status',
    description: 'État',
    maxLength: 4,
    defaultValue: FOLDER_STATUS.ONLINE,
    choices: [
      { value: FOLDER_STATUS.ONLINE, desc: 'En ligne' },
      {
        value: FOLDER_STATUS.ONLINE_STAFF_ONLY,
        desc: 'En ligne - staff uniquement',
      },
      {
        value: FOLDER_STATUS.ONLINE_SU_ONLY,
        desc: 'En ligne - superadmin uniquement',
      },
      { value: FOLDER_STATUS.OFFLINE, desc: 'Hors ligne' },
      { value: FOLDER_STATUS.DELETED, desc: 'Hors ligne, supprimé' },
    ],
    priority: 300,
  })
  public status: FOLDER_STATUS = FOLDER_STATUS.ONLINE;

  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;

  @manyToManyField({
    name: 'children',
    description: 'IDS sous familles',
    defaultValue: [],
    related: 'Family',
    priority: -1,
  })
  public children: number[] = [];

  @integerField({
    name: 'tree_depth',
    description: 'Niveau hiérarchie',
    readonly: true,
    priority: 520,
  })
  public tree_depth: number = 0;

  @detailsField({
    name: 'tree_path',
    description: 'Hiérarchie',
    readonly: true,
    priority: 800,
  })
  public tree_path: { name: string; id: number }[] = [];
  @detailsField({
    name: 'user_level',
    description: 'Permissions utilisateur',
    deserialize: (d) => d,
    priority: -1,
  })
  public user_level: FOLDER_USER_LEVEL[] = [];

  public get full_path(): string {
    if (this.tree_path) {
      return this.tree_path.map((p) => p.name).join(' : ');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((this as any).parents_details) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (this as any).parents_details.map((p: any) => p.name).join(' : ');
    }
    return this.name;
  }

  public get _display(): string {
    return this.full_path;
  }

  public truncated_path(start: number = 0, end: number = 8, sep: string = ' : '): string {
    if (!this.tree_path) {
      return this.name;
    }
    return this.tree_path
      .filter((v, i) => i >= start && i <= end)
      .map((p) => p.name)
      .join(sep);
  }

  public have_level(level: FOLDER_USER_LEVEL): boolean {
    return this.user_level?.includes(level);
  }
}
