
<ng-container *ngIf="data$|async; let data">
  <ng-template #createOfcSlot let-offcanvas>
    <div class="offcanvas-header">
      <lvadg-icon ri="add">Ajouter un producteur</lvadg-icon>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body">
      <div class="container">
        <lvadg-producer-user-create [type]="(data.type &amp;&amp; data.type[0])||PT.FL" (cancelled)="create()" (created)="reload$.next(true); create(); detail_route.navigate({producer: $event})"></lvadg-producer-user-create>
      </div>
    </div>
  </ng-template>
  <lvadg-header-action [title]="data.page_title||'Producteurs'" level="h1" [ri]="data.page_icon||'producer'">
    <ng-container actions>
      <button class="btn btn-primary btn-sm" (click)="create()" *ngIf="canCreate()">
        <lvadg-icon ri="add">Ajouter un producteur</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-producer-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [storage_detail_route]="storage_detail_route" [local]="data.local" [type]="data.type" [storage]="data.storage" [reload]="reload$" [print_settings]="printSettings" [print_context]="{storage: data.storage}" [cdatakeys]="data.producers_cdatakeys" [dispMap]="'local'" [actions]="['print_affiche', 'print_fiche', 'print_fiche_products']"></lvadg-producer-list>
</ng-container>