import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LabelService } from './label.service';
import { Label } from './label';

@Injectable({
  providedIn: 'root',
})
export class LabelResolver  {
  constructor(private _rs: LabelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Label> {
    return this._rs.fetch(+route.params['labelId'], {
      params: {
        fields: [
          'id',
          'type',
          'code',
          'name',
          'logo',
          'url',
          'egalim',
          'egalim_type',
          'egalim_eq',
          'orig_id',
          'created',
          'updated',
          'status',
          'egalim_orig_id',
          'logo_details',
        ].join(','),
      },
    });
  }
}
