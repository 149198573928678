import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Geocommune } from '../geocommune';

@Component({
  selector: 'lvadg-geocommune-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './geocommune-manage.component.pug',
  styleUrls: ['./geocommune-manage.component.sass'],
})
export class GeocommuneManageComponent implements OnInit {
  @Input() public geocommune!: Geocommune;

  constructor() {}

  ngOnInit(): void {}
}
