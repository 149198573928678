import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageBase } from '../storage.base';
import { Storage } from '../storage';
import { DataMessageService, Link } from '@solidev/data';
import { RouterLink } from '@angular/router';
import { LocationDisplayComponent } from '../../../locations/location/location-display/location-display.component';
import { AuthService } from '../../../users/auth.service';

@Component({
  selector: 'lvadg-storage-display',
  standalone: true,
  imports: [CommonModule, RouterLink, LocationDisplayComponent],
  templateUrl: './storage-display.component.pug',
  styleUrls: ['./storage-display.component.sass'],
})
export class StorageDisplayComponent {
  @Input() public storage?: Storage;
  @Input() public mode: 'line' | 'linewloc' = 'line';
  @Input() public show_location = false;
  @Input() public detail_route?: Link<StorageBase>;

  @Input() public showCodes: boolean;
  private _msgs: DataMessageService;

  constructor() {
    const user = inject(AuthService).currentUser;
    this.showCodes = user?.is_superuser || user?.is_staff || false;
    this._msgs = inject(DataMessageService);
  }

  public async clip() {
    await navigator.clipboard.writeText(this.storage?.cvva || '');
    this._msgs.success('Code dépot copié dans le presse-papier');
  }
}
