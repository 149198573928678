
<ng-template #specialop let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">Opérations sur fichier client</h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <lvadg-tarif-import-custom-articles [tarif]="tarif" *ngIf="running['cliartimport']" (done)="reload$.next(true);offcanvas.close();" (cancelled)="offcanvas.close()"></lvadg-tarif-import-custom-articles>
    <lvadg-tarif-import-custom-prices [tarif]="tarif" *ngIf="running['clipriceimport']" (done)="reload$.next(true);offcanvas.close();" (cancelled)="offcanvas.close()"></lvadg-tarif-import-custom-prices>
    <lvadg-tarif-export-custom-prices [tarif]="tarif" *ngIf="running['clipriceexport']" (done)="reload$.next(true);offcanvas.close();" (cancelled)="offcanvas.close()"></lvadg-tarif-export-custom-prices>
  </div>
</ng-template>
<ng-container *ngIf="step">
  <h4 class="my-2">{{step.title$||'Édition du tarif et des infos du tarif'}}</h4>
  <p class="text-info" [innerHTML]="step.message$"></p>
  <div class="row">
    <div class="col-6">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'nav', dest: 'prices'})"><i class="bi bi-pencil me-2"></i>Éditer le tarif</button>
    </div>
    <div class="col-6">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'nav', dest: 'details'})"><i class="bi bi-pencil me-2"></i>Éditer les infos du tarif</button>
    </div>
  </div>
  <div class="row" *ngIf="tariftype.export_template || tariftype.client_data_link">
    <div class="col" *ngIf="tariftype.client_data_link">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="ofcOp('cliartimport')"><i class="bi bi-file-spreadsheet me-2"></i>Import fichier client<br>[articles]</button>
    </div>
    <div class="col" *ngIf="tariftype.client_data_link">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="ofcOp('clipriceimport')"><i class="bi bi-file-spreadsheet me-2"></i>Import fichier client<br>[prix]</button>
    </div>
    <div class="col" *ngIf="tariftype.client_data_link">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="ofcOp('clipriceexport')"><i class="bi bi-file-spreadsheet me-2"></i>Export dans fichier client<br>[prix/dispos]</button>
    </div>
    <div class="col" *ngIf="tariftype.export_template">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'export[custom]'})" *ngIf="!running['export[custom]']"><i class="bi bi-file-spreadsheet me-2"></i>Export excel [custom]</button>
      <button class="btn btn-outline-primary w-100 p-3 my-2" *ngIf="running['export[custom]']" [disabled]="true"><i class="spinner-border spinner-border-sm me-2"></i>Export excel [custom]</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="!with_nego">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'export[default]'})" *ngIf="!running['export[default]']"><i class="bi bi-file-spreadsheet me-2"></i>Export excel</button>
      <button class="btn btn-outline-primary w-100 p-3 my-2" *ngIf="running['export[default]']" [disabled]="true"><i class="spinner-border spinner-border-sm me-2"></i>Export excel</button>
    </div>
    <ng-container *ngIf="with_nego">
      <div class="col-6">
        <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'export[default]'})" *ngIf="!running['export[default]']"><i class="bi bi-file-spreadsheet me-2"></i>Export excel</button>
        <button class="btn btn-outline-primary w-100 p-3 my-2" *ngIf="running['export[default]']" [disabled]="true"><i class="spinner-border spinner-border-sm me-2"></i>Export excel</button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'export[nego]'})" *ngIf="!running['export[nego]']"><i class="bi bi-file-spreadsheet me-2"></i>Export excel [nego]</button>
        <button class="btn btn-outline-primary w-100 p-3 my-2" *ngIf="running['export[nego]']" [disabled]="true"><i class="spinner-border spinner-border-sm me-2"></i>Export excel [nego]</button>
      </div>
    </ng-container>
  </div>
  <button class="btn btn-outline-success w-100 p-3 my-2" (click)="action({action: 'next'})"><i class="bi bi-check-lg me-2"></i>Passer à l'étape suivante</button>
</ng-container>