
<ng-template #loading>
  <div class="text-center mt-5">
    <h1 class="mt-5">Chargement de l'impression en cours...</h1>
    <p class="text-info">Merci de patienter</p>
    <div class="spinner-border text-info d-block mx-auto mt-5" style="width: 3rem; height: 3rem;"><span class="visually-hidden">Chargement...</span></div>
  </div>
</ng-template>
<ng-template #doc>
  <div><i class="bi bi-folder-minus mx-2"></i><i class="bi bi-folder-plus mx-2"></i>Container (o/f)</div>
  <div><i class="bi bi-diagram-3 mx-2"></i><i class="bi bi-diagram-3-fill mx-2"></i>Groupe de mise en page (o/f)</div>
  <div><i class="bi bi-folder-minus mx-2 text-info"></i><i class="bi bi-folder-plus mx-2 text-info"></i>Instance de groupe (o/f)</div>
  <div><i class="bi bi-file-earmark-text mx-2"></i>Fragment de mise en page</div>
  <hr>
  <div class="small">Cliquez sur l'icône pour ouvrir / fermer l'arborescence</div>
  <div class="small">Cliquez sur le nom pour sélectionner un élément et l'éditer / l'afficher dans la prévisualisation, recliquez pour l'ouvrir ou le fermer.</div>
</ng-template>
<ng-template #docpreview>
  <div>La prévisualisation de la mise en page (sauts de page, coupure des lignes) est approximative au niveau de la pagination (et même désactivée sous Firefox). L'impression PDF n'est pas totalement fidèle à l'impression.</div>
</ng-template>
<ng-container *ngIf="tree$|async; let tree; else loading">
  <div class="row editor g-1">
    <div class="col-3">
      <div class="card h-100">
        <div class="card-header"><span>Structure de l'impression<i class="bi bi-question-circle mx-2" [ngbPopover]="doc" triggers="mouseenter:mouseleave"></i></span></div>
        <div class="card-body">
          <dl class="row g-1">
            <!-- FIXME: this does not update when print is updated-->
            <data-dispedit class="col-7" [model]="print" field="name" mode="dd" (changed)="updatedPrint()">Titre de l'impression</data-dispedit>
            <data-dispedit class="col-4" [model]="print" field="status" mode="dd" (changed)="updatedPrint()">État</data-dispedit>
            <div class="col-1"><i class="bi bi-bullseye text-success fs-2" title="Cette impression est l'impression courante" *ngIf="current$|async"><span class="visually-hidden">Impression courante</span></i><i class="bi bi-bullseye text-secondary fs-2" role="button" title="Sélectionner comme impression courante" *ngIf="!(current$|async)" (click)="setCurrent(print)"><span class="visually-hidden">Sélectionner comme impression courante</span></i></div>
          </dl>
          <div class="overflow-auto vh-75">
            <div class="fw-bold">Structure de l'impression</div>
            <cdk-tree [dataSource]="tree.data" [treeControl]="tree.control">
              <cdk-nested-tree-node class="d-block" *cdkTreeNodeDef="let node"><span class="pe-1" cdkTreeNodeToggle aria-label="toggle tree node" role="button" [cdkTreeNodeToggleRecursive]="false" *ngIf="node.children.length">
                  <ng-container *ngIf="node.layout_type=='group'"><i class="bi bi-diagram-3" *ngIf="tree.control.isExpanded(node)"></i><i class="bi bi-diagram-3-fill" *ngIf="!tree.control.isExpanded(node)"></i></ng-container>
                  <ng-container *ngIf="node.layout_type=='group_instance'"><i class="bi bi-folder-minus text-info" *ngIf="tree.control.isExpanded(node)"></i><i class="bi bi-folder-plus text-info" *ngIf="!tree.control.isExpanded(node)"></i></ng-container>
                  <ng-container *ngIf="node.layout_type=='container'"><i class="bi bi-folder-minus" *ngIf="tree.control.isExpanded(node)"></i><i class="bi bi-folder-plus" *ngIf="!tree.control.isExpanded(node)"></i></ng-container></span><span class="pe-1" *ngIf="!node.children.length"><i class="bi bi-file-earmark-text"></i></span><a class="text-decoration-none text-uppercase user-select-none" role="button" (click)="tree.clickNode(node)" [class.text-success]="tree.highlighted.has(node.tree_id)" [class.fw-bold]="tree.current===node.tree_id">{{node.name}}<i class="bi bi-database text-muted ms-1" *ngIf="node.providers?.length"></i></a>
                <div class="ms-4" *ngIf="tree.control.isExpanded(node)">
                  <ng-container cdkTreeNodeOutlet></ng-container>
                </div>
              </cdk-nested-tree-node>
            </cdk-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="card h-100">
        <div class="card-header">Paramètres</div>
        <div class="card-body">
          <ng-template #noLayout>
            <div class="alert alert-info my-4 fs-5">Sélectionnez un calque pour le prévisualiser et éditer ses paramètres</div>
          </ng-template>
          <ng-container *ngIf="tree.currentLayout$|async; let current; else noLayout">
            <ul class="nav-tabs nav-fill" #nav="ngbNav" ngbNav>
              <li ngbNavItem>
                <button ngbNavLink role="button">Mise en page</button>
                <ng-template ngbNavContent>
                  <ng-container *ngIf="current.layout_type==='template'">
                    <lvadg-layout-template-editor [tree]="tree" [layout$]="tree.currentLayout$"></lvadg-layout-template-editor>
                  </ng-container>
                  <ng-container *ngIf="current.layout_type!=='template'">
                    <div class="alert alert-info my-4 fs-5">Sélectionnez un calque "Fragment de mise en page" (<i class="bi bi-file-earmark-text mx-2"></i>) pour éditer ses paramètres</div>
                  </ng-container>
                </ng-template>
              </li>
              <li ngbNavItem>
                <button ngbNavLink role="button">Données associées</button>
                <ng-template ngbNavContent>
                  <lvadg-layout-providers-editor [tree]="tree" [layout$]="tree.currentLayout$"></lvadg-layout-providers-editor>
                </ng-template>
              </li>
              <!--li(ngbNavItem)-->
              <!--  button(ngbNavLink, role="button") JSON (dev mode)-->
              <!--  ng-template(ngbNavContent)-->
              <!--    pre.small {{current|json}}-->
            </ul>
            <div [ngbNavOutlet]="nav"></div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card h-100">
        <div class="card-header">Prévisualisation<i class="bi bi-question-circle mx-2" [ngbPopover]="docpreview" triggers="mouseenter:mouseleave"></i></div>
        <div class="card-body">
          <lvadg-layout-render-preview [tree]="tree" [layout$]="tree.currentLayout$"></lvadg-layout-render-preview>
        </div>
      </div>
    </div>
  </div>
</ng-container>