
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { VivalyaBill } from "./vivalya-bill";

@Injectable({
  providedIn: 'root'
})
export class VivalyaBillService extends Collection<VivalyaBill> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/vivalyabills", VivalyaBill);
  }
}
