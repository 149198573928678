
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Rnmlibelle } from "./rnmlibelle";

@Injectable({
  providedIn: 'root'
})
export class RnmlibelleService extends Collection<Rnmlibelle> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/rnmlibelles", Rnmlibelle);
  }
}
