
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu"></lvadg-tariftype-detail-nav>
  <lvadg-header-action [title]="data.tariftype.name" subtitle="Permissions du tarif" level="h2" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-primary btn-sm" (click)="createPermission=!createPermission">
        <lvadg-icon ri="plus">Ajouter une permission</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createPermission">
    <div class="card-header">Ajouter une permission</div>
    <div class="card-body">
      <lvadg-tariftype-permission-create [tariftype]="data.tariftype" (cancelled)="createPermission=false" (created)="reload$.next(true); createPermission=false"></lvadg-tariftype-permission-create>
    </div>
  </div>
  <lvadg-tariftype-permission-list [name]="data.route.name+'-'+data.tariftype.code" [filter]="tarifTypeFilter$" [default_fields]="tperms_default_fields" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [actions]="['delete']" [reload]="reload$"></lvadg-tariftype-permission-list>
</ng-container>