import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { TarifLifecycleStepListComponent } from '../../../../../models/tarifs/tarif-lifecycle-step/tarif-lifecycle-step-list/tarif-lifecycle-step-list.component';
import { map, Observable, Subject } from 'rxjs';
import {
  TariftypeActionParams,
  TariftypeActionService,
} from '../../../../../models/tarifs/tarif/tariftype-action.service';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeLifecycleViewParams extends BaseRouteParams {
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeLifecycleViewData extends TariftypeLifecycleViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-lifecycle-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    TarifLifecycleStepListComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-lifecycle-view.component.pug',
  styleUrls: ['./tariftype-lifecycle-view.component.sass'],
})
export class TariftypeLifecycleViewComponent extends BaseDetailViewComponent<TariftypeLifecycleViewData, Tariftype> {
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public lifecyclesteps_default_fields = ['id', 'step', 'stitle$', 'title$', 'message$', 'params$', 'order'];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    public ttAction: TariftypeActionService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeLifecycleViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }

  public async ttActionProcess(tt: Tariftype, action: TariftypeActionParams) {
    await this.ttAction.action(tt, action);
    this.reload$.next(true);
  }
}
