import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { map, Observable } from "rxjs";
import { DataMessageService } from "@solidev/data";
import { AuthService } from "@vivalya/lvadg";

@Injectable({
  providedIn: "root",
})
export class IsAdminGuard {
  constructor(
    private _auth: AuthService,
    private _msgs: DataMessageService,
    private router: Router,
  ) {}

  canMatch(
    route: Route,
    state: UrlSegment[],
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._auth.isAnonymous) {
      this._msgs.warning("Merci de vous identifier");
      return this.router.parseUrl("/login");
    }
    return this._auth.currentUser$.pipe(
      map((u) => {
        if (u === null) {
          this._msgs.warning("Merci de vous identifier");
          return this.router.parseUrl("/login");
        }
        if (!u.is_superuser && !u.is_staff) {
          this._msgs.warning("Merci de vous identifier avec un compte admin");
          return this.router.parseUrl("/login");
        }
        return true;
      }),
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._auth.isAnonymous) {
      this._msgs.warning("Merci de vous identifier");
      return this.router.parseUrl("/login");
    }
    return this._auth.currentUser$.pipe(
      map((u) => {
        if (u === null) {
          this._msgs.warning("Merci de vous identifier");
          return this.router.parseUrl("/login");
        }
        if (!u.is_superuser && !u.is_staff) {
          this._msgs.warning("Merci de vous identifier avec un compte admin");
          return this.router.parseUrl("/login");
        }
        return true;
      }),
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._auth.isAnonymous) {
      this._msgs.warning("Merci de vous identifier");
      return this.router.parseUrl("/login");
    }
    return this._auth.currentUser$.pipe(
      map((u) => {
        if (u === null) {
          this._msgs.warning("Merci de vous identifier");
          return this.router.parseUrl("/login");
        }
        if (!u.is_superuser && !u.is_staff) {
          this._msgs.warning("Merci de vous identifier avec un compte admin");
          return this.router.parseUrl("/login");
        }
        return true;
      }),
    );
  }
}
