import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { Observable, Subject, switchMap, timer } from 'rxjs';
import { TarifLifecycleActionService } from '../../../tarif-lifecycle-action.service';
import { LIFECYCLE_MAIN_STEP } from '../../../lifecycle-steps';
import { lifecycleNav, TarifLifecycleAction } from '../../../lifecycle-actions';
import { TarifNotificationService } from '../../../../../tarif-notification/tarif-notification.service';
import { TarifNotification } from '../../../../../tarif-notification/tarif-notification';
import { TARIFNOTIFICATION_STATUS } from '../../../../../tarif-notification/tarif-notification.base';
import { TARIFTYPE_USER_LEVEL } from '../../../../../tariftype/tariftype.base';
import { Tariftype } from '../../../../../tariftype/tariftype';
import { TARIF_ROUTES } from '../../../../../../../views/tarifs/tarifs/detail/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-done',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-lifecycle-step-done.component.pug',
  styleUrls: ['./tarif-lifecycle-step-done.component.sass'],
})
export class TarifLifecycleStepDoneComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public tariftype!: Tariftype;
  @Input() public step!: TarifLifecycleStep;
  @Input() public steps!: TarifLifecycleStep[];
  @Input() public menu?: TARIF_ROUTES;
  @Input() public reload$!: Subject<void | boolean>;
  public notifs$!: Observable<TarifNotification[]>;
  public MAIN_STEPS = LIFECYCLE_MAIN_STEP;
  public TNSTATUS = TARIFNOTIFICATION_STATUS;
  public running: Record<string, boolean> = {};
  public with_nego: boolean = false;
  private _subscriptions = new Subject<void>();

  constructor(
    public taction: TarifLifecycleActionService,
    private _notifs: TarifNotificationService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.with_nego = (this.steps || []).some((s) => s.step === LIFECYCLE_MAIN_STEP.NEGOCIATION);
    this.notifs$ = timer(0, 20000).pipe(
      switchMap(() => {
        return this._notifs.list({ tarif: this.tarif.id });
      }),
    );
  }

  public async action(action: TarifLifecycleAction) {
    if (action.action === 'nav') {
      await lifecycleNav(action.dest, this.menu, this.router, this.tarif, this.tariftype);
    } else {
      this.running[action.action] = true;
      const res = await this.taction.action(this.tarif, action);
      this.reload$.next(true);
      this.running[action.action] = false;
    }
  }

  canCancel(tn: TarifNotification): boolean {
    if (this.tarif.tariftype_details?.have_level(TARIFTYPE_USER_LEVEL.sadmin)) {
      return true;
    }
    const delta = Math.abs(Date.now() - tn.created.getTime()) / 1000;
    return delta < 5 * 60;
  }
}
