import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class BaseFile extends DataModel {
  static override readonly __name: string = 'BaseFile';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 130,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'slug',
    description: 'Slug',
    maxLength: 200,
    priority: 800,
  })
  public slug!: string;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 200,
    priority: 850,
  })
  public title!: string;

  @charField({
    name: 'mimetype',
    description: 'Mimetype',
    maxLength: 250,
    readonly: true,
    priority: 700,
  })
  public mimetype!: string;

  @charField({
    name: 'filename',
    description: 'Nom du fichier',
    maxLength: 255,
    readonly: true,
    priority: 650,
  })
  public filename!: string;

  @integerField({
    name: 'filesize',
    description: 'Taille du fichier',
    defaultValue: 0,
    readonly: true,
    priority: 600,
  })
  public filesize: number = 0;

  @charField({
    name: 'file',
    description: 'URL du fichier',
    maxLength: 200,
    priority: 550,
  })
  public file!: string;

  @foreignKeyField({
    name: 'image',
    description: 'ID Image',
    related: 'Image',
    readonly: true,
    priority: -1,
  })
  public image!: number;

  @dateField({
    name: 'start',
    description: 'Début de validité',
    required: false,
    priority: 300,
  })
  public start?: Date;

  @dateField({
    name: 'end',
    description: 'Fin de validité',
    required: false,
    priority: 250,
  })
  public end?: Date;

  @detailsField({
    name: 'parameters',
    description: 'Paramètres (interne)',
    defaultValue: {},
    priority: -1,
  })
  public parameters: any = {};

  @detailsField({
    name: 'content',
    description: 'Contenu (json)',
    defaultValue: {},
    priority: -1,
  })
  public content: any = {};

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;
}
