import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Brand } from '../../../../../models/catalog/meta/brand/brand';

export interface BrandDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface BrandDetailViewData extends BrandDetailViewParams {
  brand: Brand;
}
