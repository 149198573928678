
<ng-container *ngIf="providerdocument">
  <dl class="row">
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="id" mode="dd" [editable]="false">ID</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="provider" mode="dd" [editable]="true" [collection]="providers$">Fournisseur</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="type" mode="dd" [editable]="true">Type de document</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="title" mode="dd" [editable]="true">Titre</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="slug" mode="dd" [editable]="true">Slug</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="label" mode="dd" [editable]="true" [collection]="labels$">Label</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="start" mode="dd" [editable]="true">Date de début de validité</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="end" mode="dd" [editable]="true">Date de fin de validité</data-dispedit>
    <data-dispedit class="col-md-6 col-12" [model]="providerdocument" field="status" mode="dd" [editable]="true">État</data-dispedit>
    <div class="col-md-6 col-12">
      <data-flags [model]="providerdocument" field="flags" mode="dd" [editable]="true">Flags</data-flags>
    </div>
  </dl>
  <dl class="row mt-3">
    <div class="col-12 text-info">Articles associés au document.</div>
    <data-dispedit class="col-12" [model]="providerdocument" field="articles" mode="dd" [editable]="true" [collection]="articles$" [filter]="{provider: providerdocument.provider, fields: ['id', 'libelle'].join(',')}">Articles associés</data-dispedit>
  </dl>
  <dl class="row mt-3">
    <div class="col-12 text-info">Ã part le nom de fichier, les champs ci-dessous sont automatiquement remplis lors de l'upload du fichier et ne sont pas modifiables.</div>
    <data-dispedit class="col-4" [model]="providerdocument" field="filename" mode="dd" [editable]="true">Nom du fichier</data-dispedit>
    <data-dispedit class="col-4" [model]="providerdocument" field="mimetype" mode="dd" [editable]="false">Type de fichier</data-dispedit>
    <data-dispedit class="col-4" [model]="providerdocument" field="filesize" mode="dd" [editable]="false">Taille du fichier</data-dispedit>
    <data-dispedit class="col-12" [model]="providerdocument" field="file" mode="dd" [editable]="false">URL du fichier</data-dispedit>
    <div class="col-12 d-flex justify-content-center"><a [href]="providerdocument.file" target="_blank">
        <lvadg-image-display [image]="providerdocument.image_details" mode="preview" [hover]="false"></lvadg-image-display>
        <div class="mt-3"><i class="bi bi-download me-2"></i>Télécharger le fichier</div></a></div>
  </dl>
</ng-container>