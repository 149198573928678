import { charField, DataModel, datetimeField, foreignKeyField, primaryField } from '@solidev/data';

export enum OFFERTYPE_PERMISSION {
  ADMIN = 'ADMIN',
  SPILOTE = 'SPILOTE',
  READ = 'READ',
}

export class OffertypePermissionBase extends DataModel {
  static override readonly __name: string = 'OffertypePermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    choices: [
      {
        value: OFFERTYPE_PERMISSION.ADMIN,
        desc: 'Administrateur offre',
      },
      {
        value: OFFERTYPE_PERMISSION.SPILOTE,
        desc: 'Pilotage offre dépôt',
      },
      {
        value: OFFERTYPE_PERMISSION.READ,
        desc: 'Lecture',
      },
    ],
    priority: 850,
  })
  public permission: OFFERTYPE_PERMISSION = OFFERTYPE_PERMISSION.READ;

  @foreignKeyField({
    name: 'target',
    description: 'ID Modèle offre',
    related: 'OfferType',
    priority: -1,
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
    priority: -1,
  })
  public group!: number;
}
