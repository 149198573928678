import {
  Component,
  computed,
  EventEmitter,
  input,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { PRODUCT_TYPES } from "../../product/product.base";
import { ARTICLE_TYPES } from "../../article/article.base";

import { FAMILY_DISPLAY_MODES } from "../../common";

@Component({
  selector: "lvadg-seasons-manage",
  templateUrl: "./seasons-manage.component.pug",
  styleUrls: ["./seasons-manage.component.sass"],
  standalone: true,
  imports: [CommonModule],
})
export class SeasonsManageComponent implements OnChanges {
  @Input() public seasons!: number[];
  @Input() public seasons_stats!: number[];
  @Input() public editable = false;
  public zone = input<PRODUCT_TYPES | ARTICLE_TYPES | FAMILY_DISPLAY_MODES>(
    PRODUCT_TYPES.LVADG_FL,
  );
  @Input() per_line = 12;
  @Input() public size: "small" | "medium" | "large" = "small";
  public cseasons!: {
    number: number;
    status: boolean;
    intensity: number;
    letter: string;
  }[];
  public cseasons_letters = [
    "J",
    "F",
    "M",
    "A",
    "M",
    "J",
    "J",
    "A",
    "S",
    "O",
    "N",
    "D",
  ];
  @Output() public changed: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  public dzone = computed(() => {
    const z = this.zone();
    if (
      [
        PRODUCT_TYPES.LVADG_FL,
        ARTICLE_TYPES.FL,
        FAMILY_DISPLAY_MODES.FL_5G,
        FAMILY_DISPLAY_MODES.FL_4G,
        FAMILY_DISPLAY_MODES.FL_1G,
        FAMILY_DISPLAY_MODES.UNKNOWN,
      ].includes(z)
    ) {
      return "FL";
    }
    if (
      [
        PRODUCT_TYPES.LVADG_SEA,
        ARTICLE_TYPES.SEA,
        FAMILY_DISPLAY_MODES.MR,
      ].includes(z)
    ) {
      return "SEA";
    }
    return "FL";
  });

  constructor() {}

  public ngOnChanges() {
    this.cseasons = [];
    if (this.seasons) {
      for (let i = 1; i <= 12; i++) {
        this.cseasons.push({
          number: i,
          status: false,
          intensity: 0,
          letter: this.cseasons_letters[i - 1],
        });
      }
      for (const s of this.seasons) {
        this.cseasons[s - 1].status = true;
        this.cseasons[s - 1].intensity = 5;
      }
    } else if (this.seasons_stats) {
      let maxi = 0;
      for (let i = 1; i <= 12; i++) {
        const val = this.seasons_stats[i - 1] || 0;
        if (val > maxi) {
          maxi = val;
        }
      }
      for (let i = 1; i <= 12; i++) {
        const val = this.seasons_stats[i - 1] || 0;
        this.cseasons.push({
          number: i,
          status: val > 0,
          intensity: Math.round((val / maxi) * 5),
          letter: this.cseasons_letters[i - 1],
        });
      }
    }
  }

  public updateSeason(num: number): void {
    const sout = [];
    for (const s of this.cseasons) {
      if (num === s.number) {
        s.status = !s.status;
      }
      if (s.status) {
        sout.push(s.number);
      }
    }
    this.changed.emit(sout);
  }

  public getClass(s: {
    number: number;
    status: boolean;
    intensity: number;
    letter: string;
  }): string[] {
    const cl: string[] = [];
    if (s.status) {
      cl.push("a");
    } else {
      cl.push("n");
    }
    cl.push(`i-${s.intensity}`);
    return cl;
  }
}
