
<ng-template #loading>
  <div class="alert alert-info text-center p-3 m-5">Chargement de l'aperçu...</div>
</ng-template>
<ng-container *ngIf="preview$|async as p; else loading">
  <div class="d-flex flex-row justify-content-evenly"><span *ngFor="let z of zooms" role="button" [class.active]="z===zoom" (click)="setZoom(z)">{{z}}%</span></div>
  <ng-container *ngIf="p.empty">
    <div class="alert alert-info text-center p-3 m-5">Aucun aperçu à afficher, veuillez sélectionner un élément dans l'arborescence.</div>
  </ng-container>
  <div class="icontainer" [class.loading]="p.loading" [class.loaded]="!p.loading"><span class="spinner-border text-info" *ngIf="p.loading" role="status"><span class="visually-hidden">Chargement...</span></span>
    <iframe class="border-0 zoom" sandbox="allow-scripts" [srcdoc]="p.html" [ngClass]="'zoom-'+zoom"></iframe>
  </div>
</ng-container>