import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Geocommune } from '../geocommune';

@Component({
  selector: 'lvadg-geocommune-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './geocommune-display.component.pug',
  styleUrls: ['./geocommune-display.component.sass'],
})
export class GeocommuneDisplayComponent {
  @Input() public geocommune?: Geocommune;

  constructor() {}
}
