import { Component, Input, OnInit } from "@angular/core";
import {
  DataMessageService,
  DataModel,
  FilterData,
  FilterDefaults,
  ModelList,
} from "@solidev/data";
import {
  intoProviderData,
  PrintContext,
  PrintSettings,
  PrintSettingsItem,
} from "../../print-settings";
import { firstValueFrom, Observable } from "rxjs";
import { PrintService } from "../print.service";
import { PresetTemplate } from "../../rstypes/PresetTemplate";
import { ProviderData } from "../../rstypes/ProviderData";
import { UserPrintContextService } from "../../user-print-context.service";

@Component({
  selector: "lvadg-quickprint-button",
  standalone: true,
  imports: [],
  templateUrl: "./quickprint-button.component.pug",
  styleUrl: "./quickprint-button.component.sass",
})
export class QuickprintButtonComponent<T extends DataModel> implements OnInit {
  @Input({ required: true }) public model!: T;
  @Input({ required: true }) public template!: PresetTemplate;
  @Input({ required: true }) public print_settings?: PrintSettings;
  @Input() public print_context: PrintContext = {};
  @Input() public list?: ModelList<T>;
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults>;

  public print_setting?: PrintSettingsItem;

  constructor(
    private _prt: PrintService,
    private _msgs: DataMessageService,
    private _upc: UserPrintContextService,
  ) {}

  public ngOnInit() {
    this.print_setting = this.print_settings?.find(
      (ps) => ps.template === this.template && ps.actions.includes("button"),
    );
  }

  public async print() {
    if (!this.print_setting) {
      this._msgs.error(
        "Impossible d'imprimer",
        `Le modèle d'impression ${this.template} n'est pas disponible pour cette action.`,
      );
      return;
    }
    const filter: FilterData = {};
    if (this.list) {
      Object.assign(filter, await firstValueFrom(this.list.filters.output));
      const sorter = await firstValueFrom(this.list.sorter.output);
      if (sorter.length > 0) {
        filter["ordering"] = sorter.join(",");
      }
    }
    if (this.filter) {
      if (this.filter instanceof Observable) {
        const f = await firstValueFrom(this.filter);
        Object.assign(filter, f);
      } else {
        Object.assign(filter, this.filter);
      }
    }
    if (this.print_context.local) {
      Object.assign(filter, { local: this.print_context.local });
    }
    // Cleanup filter
    for (const k in filter) {
      if (filter[k] === undefined || filter[k] === null) {
        delete filter[k];
      } else {
        filter[k] = `${filter[k]}`;
      }
    }
    const data: ProviderData = {
      type: this.print_setting.provider,
      id: this.model.id,
      queryset: { page: "1", page_size: "1", ...filter },
      description: this.print_setting.label,
    } as ProviderData;
    this._upc.update_context(this.print_context);
    await this._prt.print(
      this.print_setting,
      data,
      intoProviderData(this.print_context),
      "button",
    );
  }
}
