
<div class="card-body">
  <div class="row g-3" [formGroup]="orderForm" (submit)="createOrder()">
    <div class="col-xl-6">
      <label>1. Type de commande</label>
      <select class="form-select" formControlName="curprocess">
        <option [ngValue]="null">-- Sélectionnez le type de commande --</option>
        <option *ngFor="let p of processes$|async" [ngValue]="p">[TP{{p.id|number:'3.0-0'}}] {{p.name}}</option>
      </select>
    </div>
    <div class="col-xl-6">
      <label>2. Choix du dépôt</label>
      <select class="form-select" formControlName="curstorage">
        <option [ngValue]="null">-- Sélectionnez le dépôt --</option>
        <option *ngFor="let s of storages$|async" [ngValue]="s">{{s.storage_details?.name}}</option>
      </select>
    </div>
    <div class="col-xl-6">
      <label>3. Date de livraison désirée</label>
      <select class="form-select" formControlName="curdelivery">
        <option [ngValue]="null">-- Sélectionnez la date de livraison désirée --</option>
        <option *ngFor="let d of deliverydates$|async" [ngValue]="d">{{d|date:'fullDate'}}</option>
      </select>
    </div>
    <div class="col-xl-6">
      <label>&nbsp;</label>
      <button class="btn btn-primary w-100" (click)="createOrder()" [disabled]="orderForm.invalid||orderForm.pristine">Créer la commande</button>
    </div>
  </div>
</div>