
<div class="row align-items-center">
  <div class="col-md-6">
    <div class="ratio ratio-4x3">
      <lvadg-simple-map [data$]="mapData"></lvadg-simple-map>
    </div>
  </div>
  <div class="col-md-6 text-center">
    <h3>Distributeurs</h3>
    <ng-container *ngFor="let r of relations">
      <div class="my-1" *ngIf="r.storage_details; let storage">
        <div class="fw-bold"><a [routerLink]="storage_detail_route()?.route({storage:storage})">{{storage.name}}</a></div>
        <div *ngIf="r.distance_details?.road; let d">{{d/1000 | number:'1.0-1'}} km</div>
      </div>
    </ng-container>
  </div>
</div>