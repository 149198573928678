import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageDisplay } from "../image.base";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "lvadg-video-display",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./video-display.component.pug",
  styleUrls: ["./video-display.component.sass"],
})
export class VideoDisplayComponent implements OnInit {
  @Input() video?: ImageDisplay;
  @Input() playerClass?: string | string[];
  @Input() containerClass?: string | string[];
  @Input() public autoplay = false;
  public vimeoUrl!: SafeResourceUrl;
  public youtubeUrl!: SafeResourceUrl;

  constructor(private _sanitizer: DomSanitizer) {}

  public ngOnInit() {
    if (this.video && this.video.video) {
      if (this.video.video.indexOf("vimeo") !== -1) {
        const regex =
          /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/g;
        const res = regex.exec(this.video.video);
        if (res && res[4]) {
          this.vimeoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
            `https://player.vimeo.com/video/${res[4]}?autoplay=${this.autoplay ? 1 : 0}&loop=1&autopause=1&autopause=0`,
          );
        }
      } else if (this.video.video.indexOf("youtube") !== -1) {
        const regex =
          /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
        const res = regex.exec(this.video.video);
        if (res && res[1]) {
          this.youtubeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
            `https://www.youtube.com/embed/${res[1]}`,
          );
        }
      }
    }
  }
}
