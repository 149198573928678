import { TARIF_STATUS, TarifBase } from './tarif.base';
import { detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { TariftypeStorage } from '../tariftype-storage/tariftype-storage';
import { TarifRelation } from '../tarif-relation/tarif-relation';
import { TarifDocumentBase } from '../tarif-document/tarif-document.base';
import { TarifStorageStatusBase } from '../tarif-storage-status/tarif-storage-status.base';
import { TarifLifecycleStep } from '../tarif-lifecycle-step/tarif-lifecycle-step';
import { TariftypeRegion } from '../tariftype-region/tariftype-region';
import { GenericregionBase } from '../../locations/genericregion/genericregion.base';
import { TarifRegionStatusBase } from '../tarif-region-status/tarif-region-status.base';
import { LIFECYCLE_SUB_STEP } from './lifecycle/lifecycle-steps';

export class Tarif extends TarifBase {
  static override readonly __name: string = 'Tarif';

  @detailsField({
    model: TariftypeBase,
    readonly: true,
    description: 'Modèle de tarif',
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: StorageBase,
    readonly: true,
    description: 'Dépôts',
    many: true,
  })
  public storages_details?: StorageBase[];

  @detailsField({
    model: TariftypeStorage,
    readonly: true,
    description: 'Association dépôts',
    many: true,
  })
  public tstorages_details?: TariftypeStorage[];

  @detailsField({
    model: GenericregionBase,
    readonly: true,
    description: 'Dépôts',
    many: true,
  })
  public regions_details?: GenericregionBase[];

  @detailsField({
    model: TariftypeRegion,
    readonly: true,
    description: 'Association régions',
    many: true,
  })
  public tregions_details?: TariftypeRegion[];

  @detailsField({
    model: TarifRelation,
    readonly: true,
    description: 'Tarifs liés',
    many: true,
  })
  public destrelations_details?: TarifRelation[];

  @detailsField({
    model: TarifDocumentBase,
    readonly: true,
    description: 'Documents',
    many: true,
  })
  public documents_details?: TarifDocumentBase[];

  @detailsField({
    model: TarifStorageStatusBase,
    readonly: true,
    description: 'État dépôts',
    many: true,
  })
  public tarifstoragestatus_details?: TarifStorageStatusBase[];

  @detailsField({
    model: TarifRegionStatusBase,
    readonly: true,
    description: 'État dépôts',
    many: true,
  })
  public tarifregionstatus_details?: TarifRegionStatusBase[];

  @detailsField({
    model: TarifLifecycleStep,
    readonly: true,
    description: 'Étape du cycle de vie',
  })
  public tstep_details?: TarifLifecycleStep;

  public override get isEditable(): boolean {
    return this.status === TARIF_STATUS.PREPARATION || this.tstep_details?.step === LIFECYCLE_SUB_STEP.EDITION;
  }
}
