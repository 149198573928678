import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {tap} from "rxjs";
import bb, {line, zoom} from "billboard.js";
import {FilterData} from "@solidev/data";
import {Rnmtarif} from "../rnmtarif";
import {RnmtarifService} from "../rnmtarif.service";
import {group, map as d3map, mean} from "d3-array";

@Component({
  selector: 'lvadg-rnmtarif-graphe',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmtarif-graphe.component.pug',
  styleUrls: ['./rnmtarif-graphe.component.sass'],
})
export class RnmtarifGrapheComponent implements OnInit {
  @Input() public rnmTarif?: Rnmtarif;
  @Input() public filter: FilterData | null = {};
  @ViewChild('chart', {static: true})
  public chartDiv!: ElementRef<HTMLDivElement>;

  constructor(
    private _statsRnmTarif: RnmtarifService
  ) {
  }

  ngOnInit() {
    if (this.rnmTarif) {
      this._statsRnmTarif
        .list({
          rnmlib: this.rnmTarif.rnmlib,
          ...(this.filter ? this.filter : {}),
          page_size: 10000,
          fields: [
            'rnmlib',
            'cotation',
            'marche',
            'rnmlib_details',
            'marche_details',
            'cotation_details',
            'price',
            'minprice',
            'maxprice',
            'created',
            'updated',
          ].join(','),
        })
        .pipe(tap((d) => this.setChart(d)))
        .subscribe();

    }

  }

  public setChart(rnmts: Rnmtarif[]) {
    const data = group(rnmts, (d) =>
      (d.marche_details)
        ? d.marche_details!.lname
        : d.cotation_details!.marche_details!.lname
    );
    const byinterval = group(
      rnmts,
      (d) => d.created,
      (d) =>
        (d.marche_details)
          ? d.marche_details!.lname
          : d.cotation_details!.marche_details!.lname
    );
    const chart = bb.generate({
      data: {
        x: 'x',
        columns: [
          ['x', ...byinterval.keys()],
          ...d3map(data.keys(), (name) => [
            name,
            ...d3map(byinterval.entries(), ([date, values]) => {
              const v = values.get(name);
              if (v) {
                const m = mean(v, (i) => i.price);
                return m !== undefined ? (Math.round(m / 100) / 100) : 0;
              }
              return null;
            }),
          ]),
        ],
        type: line(),
      },

      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d',
          },
        },
        y: {
          tick: {
            format: function(x: number) {
              return x+' €';
            }
          }
        },
      },
      tooltip: {
        format: {
           value: function(value: number, ratio: number | undefined, id: string, index: number): string {
             return value.toFixed(2)+' €';
           }
        }
      },
      bindto: this.chartDiv.nativeElement,
      zoom: {
        enabled: zoom(),
      }
    });

  }
}
