import {
  Component,
  inject,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { PRODUCER_ROUTES } from "../menu";
import { Producer } from "../../../../../models/structures/producer/producer";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProducerNavComponent } from "../_nav/producer-nav.component";
import { ProducerDocumentListComponent } from "../../../../../models/structures/producer-document/producer-document-list/producer-document-list.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { ProducerDocumentCreateComponent } from "../../../../../models/structures/producer-document/producer-document-create/producer-document-create.component";
import { map, Observable, Subject } from "rxjs";
import { Product } from "../../../../../models/catalog/product/product";
import { Storage } from "../../../../../models/structures/storage/storage";
import { ProducerDocument } from "../../../../../models/structures/producer-document/producer-document";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { ProducerDocumentManageComponent } from "../../../../../models/structures/producer-document/producer-document-manage/producer-document-manage.component";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { AuthService } from "../../../../../models/users/auth.service";
import { ADMIN_GROUPS } from "../../../../../constants";
import { PRODUCER_USER_LEVELS } from "../../../../../models/structures/producer/producer.base";

export interface ProducerDocumentsViewParams extends BaseRouteParams {
  pr_menu: PRODUCER_ROUTES;
  product_detail_route?: RouteConfigItem;
}

export interface ProducerDocumentsViewData extends ProducerDocumentsViewParams {
  producer: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-producer-documents-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProducerNavComponent,
    ProducerDocumentListComponent,
    ProducerDocumentManageComponent,
    IconComponent,
    ProducerDocumentCreateComponent,
  ],
  templateUrl: "./producer-documents-view.component.pug",
  styleUrl: "./producer-documents-view.component.sass",
})
export class ProducerDocumentsViewComponent extends BaseDetailViewComponent<
  ProducerDocumentsViewData,
  Producer
> {
  public producerFilter$!: Observable<FilterDefaults>;
  public product_detail_route?: Link<Product>;
  public createDocument = false;
  public currentDocument = signal<ProducerDocument | null>(null);
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  @ViewChild("docEditSlot", { static: false })
  public docEditSlot?: TemplateRef<HTMLDivElement>;
  public canCreate = signal(false);
  public user = inject(AuthService).currentUser;
  public default_fields = [
    "id",
    "type",
    "label_details",
    "filename",
    "products_details",
    "status",
    "start",
    "end",
    "actions",
  ];
  private _ofc = inject(NgbOffcanvas);
  private _docEditInstance?: NgbOffcanvasRef;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.producerFilter$ = this.data$.pipe(
      map((data) => ({ producer: data.producer.id })),
    );
  }

  public override setRouteData(data: ProducerDocumentsViewData) {
    super.setRouteData(data);
    if (data.product_detail_route) {
      this.product_detail_route = new Link(
        data.product_detail_route,
        data,
        this._router,
      );
    }
    if (
      (this.user &&
        (this.user.is_superuser ||
          this.user.have_group(ADMIN_GROUPS.producers_editor))) ||
      data.producer.have_level(PRODUCER_USER_LEVELS.WRITE)
    ) {
      this.canCreate.set(true);
    }
  }

  public onAction(
    action: ModelListAction<ProducerDocument>,
    data: ProducerDocumentsViewData,
  ) {
    if (action.action === "edit" && action.model) {
      this.currentDocument.set(action.model!);
      if (this.docEditSlot) {
        this._docEditInstance = this._ofc.open(this.docEditSlot, {
          position: "end",
          panelClass: "w-75",
        });
        this._docEditInstance.closed.subscribe(() => {
          this.currentDocument.set(null);
          this.reload$.next(true);
        });
      }
    }
  }
}
