import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  Collection,
  DATA_AUTH_SERVICE,
  DataMessageService,
  DataModel,
  humanizeBytes,
  NgxUploaderModule,
  UploaderOptions,
  UploadFile,
  UploadInput,
  UploadOutput,
  UploadStatus,
} from '@solidev/data';
import { CommonModule } from '@angular/common';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../users/auth.service';

export interface IPreparedUploadFile<T> {
  model: T;
  data: any;
  fieldName?: string;
  url?: string;
  method?: string;
  headers?: Record<string, string>;
}

export interface UploadResult<T, R> {
  model: T;
  response: R | null;
  status: number;
}

@Component({
  selector: 'lvadg-media-upload',
  templateUrl: './media-upload.component.pug',
  styleUrls: ['./media-upload.component.sass'],
  standalone: true,
  imports: [CommonModule, NgbProgressbarModule, NgxUploaderModule],
})
export class MediaUploadComponent<T extends DataModel, R> implements OnInit {
  public headers!: Record<string, string>;
  public method!: string;
  public files!: UploadFile[];
  public uploadInput: EventEmitter<UploadInput> =
    new EventEmitter<UploadInput>();
  public humanizeBytes = humanizeBytes;
  public dragOver = false;
  public options!: UploaderOptions;

  /**
   * Collection used for media upload.
   */
  @Input() public service!: Collection<T>;
  /**
   * Model (used for update)
   */
  @Input() public model?: T;
  /**
   * Upload url (computed from model or service if not given)
   */
  @Input() public url!: string;

  /**
   * Upload enabled (default : true)
   */
  @Input() public enabled = true;
  /**
   * Upload prepare function.
   * Should return a promise of IPreparedUploadFile, using model and file parameters.
   * If not provided prepareDefault is used.
   */
  @Input() public prepare!: (
    model: T,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<T>>;
  /**
   * Max file size
   */
  @Input() public maxFileSize = 200 * 1024 * 1024;
  /**
   * Allowed content types
   */
  @Input() public allowedContentTypes?: string[];
  /**
   * Max number of uploads for this component.
   * Default : unlimited, added sequentially.
   */
  @Input() public maxUploads: number = Infinity;
  /**
   * Upload done event.
   */
  @Output() public uploadDone = new EventEmitter<UploadResult<T, R>>();

  constructor(
    @Inject(DATA_AUTH_SERVICE) private _auth: AuthService,
    private _msgs: DataMessageService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.headers = { accept: 'application/json' };
    if (!this.url) {
      if (!this.model || !this.model.id) {
        this.url = this.service.getUrl(null);
        this.model = new this.service.model(this.service);
        this.method = 'POST';
      } else {
        this.url = this.service.getUrl(this.model.id);
        this.method = 'PATCH';
      }
    }
    this.options = {
      concurrency: 1,
      maxFileSize: this.maxFileSize,
      allowedContentTypes: this.allowedContentTypes,
      maxUploads: this.maxUploads,
    };
    this.files = [];
  }

  public async onUploadOutput(evoutput: UploadOutput): Promise<void> {
    const output = evoutput as UploadOutput;
    if (output.type === 'allAddedToQueue' && this.files.length > 0) {
      const prpFn = this.prepare ? this.prepare : this.prepareDefault;
      const prep = await prpFn(this.model!, this.files[0]);
      this.model = prep.model;
      if (!this._auth.isAnonymous) {
        await firstValueFrom(this._auth.refresh());
        this.headers['authorization'] = `Bearer ${this._auth.accessToken!}`;
      }
      const event: UploadInput = {
        type: 'uploadAll',
        url: prep.url ? prep.url : this.url,
        method: prep.method ? prep.method : this.method,
        headers: prep.headers ? prep.headers : this.headers, //
        fieldName: prep.fieldName ? prep.fieldName : 'file',
        data: prep.data,
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && output.file !== undefined) {
      this.files.push(output.file);
    } else if (output.type === 'uploading' && output.file !== undefined) {
      const index = this.files.findIndex(
        (file) => output.file !== undefined && file.id === output.file.id
      );
      this.files[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected' && output.file !== undefined) {
      this._msgs.error(
        output.file.name + ' rejeté (vérifiez le type du fichier)'
      );
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'done') {
      if (output.file !== undefined) {
        this.uploadDone.next({
          model: this.model!,
          response: output.file.response,
          status: output.file.responseStatus || 500,
        });
      } else {
        this.uploadDone.next({
          model: this.model!,
          response: null,
          status: 500,
        });
      }
    }
    this.files = this.files.filter(
      (file) => file.progress.status !== UploadStatus.Done
    );
  }

  public cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id });
  }

  private prepareDefault = async (
    model: T,
    file: UploadFile
  ): Promise<IPreparedUploadFile<T>> => {
    if (this.method === 'PATCH') {
      return { model, data: {} };
    } else {
      return { model, data: model.toJson() };
    }
  };
}
