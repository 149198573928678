import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tariftype } from '../tariftype';
import { DispeditComponent, FilterDefaults, FlagsComponent, Link, RicheditComponent } from '@solidev/data';
import { UserService } from '../../../users/user/user.service';
import { RruleEditorComponent } from '../../../../components/utils/rrule-editor/rrule-editor.component';
import { firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { ClientService } from '../../../structures/client/client.service';
import { TARIFTYPE_USER_LEVEL } from '../tariftype.base';
import { GenericregionService } from '../../../locations/genericregion/genericregion.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { MemberService } from '../../../structures/member/member.service';

@Component({
  selector: 'lvadg-tariftype-manage',
  standalone: true,
  imports: [CommonModule, FlagsComponent, DispeditComponent, RicheditComponent, RruleEditorComponent],
  templateUrl: './tariftype-manage.component.pug',
  styleUrls: ['./tariftype-manage.component.sass'],
})
export class TariftypeManageComponent implements OnChanges {
  @Input() public tariftype?: Tariftype;
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  public adminEditable = true;
  public srcFilter: ReplaySubject<FilterDefaults> = new ReplaySubject<FilterDefaults>(1);
  public destFilter: ReplaySubject<FilterDefaults> = new ReplaySubject<FilterDefaults>(1);
  public reload$: Subject<void> = new Subject<void>();
  private TTUL = TARIFTYPE_USER_LEVEL;

  constructor(
    public users$: UserService,
    public clients$: ClientService,
    public regions$: GenericregionService,
    public storages$: StorageService,
    public members$: MemberService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['tariftype']) {
      const tch = changes['tariftype'];
      if (tch.previousValue?.id !== tch.currentValue?.id) {
        this.srcFilter.next({ src: tch.currentValue.id });
        this.destFilter.next({ dest: tch.currentValue.id });
      }
    }
    this.adminEditable = this.tariftype?.have_level(this.TTUL.admin) || false;
  }

  public async onRRuleSave($event: string): Promise<void> {
    if (this.tariftype) {
      this.tariftype.rrule = $event;
      await firstValueFrom(this.tariftype.update(['rrule']));
    }
  }
}
