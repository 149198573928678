
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails fournisseur</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-provider-manage [provider]="data.provider"></lvadg-provider-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="articles"><a ngbNavLink>Articles</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-article-list [name]="data.route.name+'-articles'" [detail_route]="article_detail_route" [family_detail_route]="family_detail_route" [default_fields]="articles_default_fields" [filter]="fournisseur$" [keep]="false"></lvadg-article-list>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>