
<lva-header></lva-header>
<div class="container-fluid mt-2 mb-5">
  <data-breadcrumb></data-breadcrumb>
  <router-outlet></router-outlet>
</div>
<lva-footer></lva-footer>
<data-update></data-update>
<data-message-zone></data-message-zone>
<lvadg-live-message></lvadg-live-message>
<lvadg-support-message-zone></lvadg-support-message-zone>