
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="ready|async">
  <li class="nav-item" *ngIf="offer_detail_route"><a class="nav-link" [routerLink]="offer_detail_route.route({offer: offer})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Résumé</a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="offer_edit_route"><a class="nav-link" [routerLink]="offer_edit_route.route({offer: offer})" routerLinkActive="active">Édition</a></li>
  <!-- Produits-->
  <li class="nav-item" *ngIf="offer_products_route"><a class="nav-link" [routerLink]="offer_products_route.route({offer: offer})" routerLinkActive="active">Sélection produits</a></li>
  <!-- Articles-->
  <li class="nav-item" *ngIf="offer_articles_route"><a class="nav-link" [routerLink]="offer_articles_route.route({offer: offer})" routerLinkActive="active">Sélections articles</a></li>
  <!-- Restos-->
  <li class="nav-item" *ngIf="offer_restos_route"><a class="nav-link" [routerLink]="offer_restos_route.route({offer: offer})" routerLinkActive="active">Restaurants</a></li>
  <!-- Textes-->
  <li class="nav-item" *ngIf="offer_texts_route"><a class="nav-link" [routerLink]="offer_texts_route.route({offer: offer})" routerLinkActive="active">Textes</a></li>
  <!-- Print preview-->
  <li class="nav-item" *ngIf="offer_print_preview_route"><a class="nav-link" [routerLink]="offer_print_preview_route.route({offer: offer})" routerLinkActive="active">Mise en page</a></li>
  <!-- Documents-->
  <li class="nav-item" *ngIf="offer_documents_route"><a class="nav-link" [routerLink]="offer_documents_route.route({offer: offer})" routerLinkActive="active">Documents</a></li>
  <!-- Destinations-->
  <li class="nav-item" *ngIf="offer_destinations_route"><a class="nav-link" [routerLink]="offer_destinations_route.route({offer: offer})" routerLinkActive="active">Destinataires</a></li>
  <!-- Notifications-->
  <li class="nav-item" *ngIf="offer_notifications_route"><a class="nav-link" [routerLink]="offer_notifications_route.route({offer: offer})" routerLinkActive="active">Publication</a></li>
  <!-- Notifications statuses-->
  <li class="nav-item" *ngIf="offer_notification_statuses_route"><a class="nav-link" [routerLink]="offer_notification_statuses_route.route({offer: offer})" routerLinkActive="active">Suivi des envois</a></li>
</ul>