
<div class="drop-container w-100 bg-light border border-dark text-center p-2" dataFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" *ngIf="enabled">
  <div class="my-3">Glissez déposez des fichiers ici, ou</div>
  <input type="file" dataFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
  <div class="mt-2" *ngFor="let f of files; let i = index">
    <div class="filename">{{f.name}}</div>
    <div class="cancel" (click)="cancelUpload(f.id)"></div>
    <ngb-progressbar [value]="(f.progress.data?.percentage || 0)" [type]="'info'" [striped]="(f.progress.data?.percentage || 0)&lt;100"></ngb-progressbar>
    <div class="progress-text">{{f.progress.data?.percentage}}% {{f.progress.data?.speedHuman}} {{f.progress.data?.etaHuman}}</div>
  </div>
</div>