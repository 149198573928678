import { charField, DataModel, foreignKeyField, integerField, primaryField } from '@solidev/data';

export enum OFFERNOTIFICATIONDESTINATION_STATUSES {
  ACTIVE = 'ACT',
  INACTIVE = 'INA',
  ERRORS = 'ERR',
  EMPTY = 'EMP',
  REMOVED = 'DEL',
}

export enum OFFERNOTIFICATIONDESTINATION_TYPES {
  PUBLISH = 'PUB',
  ALARM = 'ALM',
}

export enum OFFERNOTIFICATIONDESTINATION_TARGET {
  RESTO = 'RES', // Offre pour un resto
  GENERIC = 'GEN', // Offre générique
}

export class OfferNotificationDestinationBase extends DataModel {
  static override readonly __name: string = 'OfferNotificationDestinationBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @foreignKeyField({
    name: 'offertype',
    description: 'ID offre contractuelle',
    related: 'OfferType',
    priority: -1,
  })
  public offertype?: number;

  @foreignKeyField({
    name: 'offertypestorage',
    description: 'ID offre contractuelle dépôt',
    related: 'OfferTypeStorage',
    priority: -1,
  })
  public offertypestorage?: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -1,
  })
  public storage?: number;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client?: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Restaurant',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
    priority: -1,
  })
  public group!: number;

  @foreignKeyField({
    name: 'contact',
    description: 'ID Contact',
    related: 'Contact',
    priority: -1,
  })
  public contact!: number;

  @charField({
    name: 'single',
    description: 'Email',
    maxLength: 255,
    priority: 830,
  })
  public single!: string;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;

  @charField({
    name: 'type',
    description: 'Type',
    choices: [
      { value: OFFERNOTIFICATIONDESTINATION_TYPES.PUBLISH, desc: 'Publication' },
      { value: OFFERNOTIFICATIONDESTINATION_TYPES.ALARM, desc: 'Alarme' },
    ],
    maxLength: 3,
    priority: 900,
  })
  public type!: OFFERNOTIFICATIONDESTINATION_TYPES;

  @charField({
    name: 'target',
    description: 'Cible',
    choices: [
      { value: OFFERNOTIFICATIONDESTINATION_TARGET.RESTO, desc: 'Restaurant' },
      { value: OFFERNOTIFICATIONDESTINATION_TARGET.GENERIC, desc: 'Générique' },
    ],
    maxLength: 3,
    priority: 950,
  })
  public target!: OFFERNOTIFICATIONDESTINATION_TARGET;

  @charField({
    name: 'status',
    description: 'Statut',
    choices: [
      { value: OFFERNOTIFICATIONDESTINATION_STATUSES.ACTIVE, desc: 'Actif' },
      { value: OFFERNOTIFICATIONDESTINATION_STATUSES.INACTIVE, desc: 'Inactif' },
      { value: OFFERNOTIFICATIONDESTINATION_STATUSES.ERRORS, desc: 'Erreurs' },
      { value: OFFERNOTIFICATIONDESTINATION_STATUSES.EMPTY, desc: 'Vide' },
      { value: OFFERNOTIFICATIONDESTINATION_STATUSES.REMOVED, desc: 'Supprimé' },
    ],
    maxLength: 3,
    priority: 180,
  })
  public status!: OFFERNOTIFICATIONDESTINATION_STATUSES;
}
