import { RoutesConfig } from "@solidev/data";
import { Iocode, RS, SupportMessage } from "@vivalya/lvadg";

export const ADMIN_UTILS_ROUTES_NAMES = [
  "utils",
  "utils_iocodes",
  "utils_iocodes_iocode",
  "utils_supportmessages",
  "utils_supportmessages_supportmessage",
  "utils_logusages",
] as const;
export const ADMIN_UTILS_ROUTES_CONFIG: RoutesConfig<
  (typeof ADMIN_UTILS_ROUTES_NAMES)[number]
> = {
  utils: {
    params: [],
    route: () => ["/", RS.utils],
    name: "utils",
  },
  utils_iocodes: {
    params: [],
    route: () => ["/", RS.utils, RS.iocodes],
    name: "utils_iocodes",
  },

  utils_iocodes_iocode: {
    params: ["iocode"],
    route: (ioc: Iocode) => ["/", RS.utils, RS.iocodes, ioc.id],
    name: "utils_iocodes_iocode",
  },

  utils_supportmessages: {
    params: [],
    route: () => ["/", RS.utils, RS.supportmessages],
    name: "utils_supportmessages",
  },
  utils_supportmessages_supportmessage: {
    params: ["supportmessage"],
    route: (sm: SupportMessage) => ["/", RS.utils, RS.supportmessages, sm.id],
    name: "utils_supportmessages_supportmessage",
  },
  utils_logusages: {
    params: [],
    route: () => ["/", RS.utils, RS.logusages],
    name: "utils_logusages",
  },
};
