
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferResto } from '../offer-resto';
@Component({
  selector: 'lvadg-offer-resto-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-resto-manage.component.pug',
  styleUrls: ['./offer-resto-manage.component.sass']
})
export class OfferRestoManageComponent implements OnInit {
  @Input() public offerresto?: OfferResto;

  constructor() { }

  ngOnInit(): void {
  }

}
