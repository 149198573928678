import { Component } from '@angular/core';

import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { TarifRelationListComponent } from '../../../../models/tarifs/tarif-relation/tarif-relation-list/tarif-relation-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TarifRelation } from '../../../../models/tarifs/tarif-relation/tarif-relation';
import { TarifBase } from '../../../../models/tarifs/tarif/tarif.base';
import { TariftypeRelationBase } from '../../../../models/tarifs/tariftype-relation/tariftype-relation.base';

export interface TarifRelationListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
  tariftyperelation_detail_route: RouteConfigItem;
}

export interface TarifRelationListViewData extends TarifRelationListViewParams {}

@Component({
  selector: 'lvadg-tarif-relation-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifRelationListComponent],
  templateUrl: './tarif-relation-list-view.component.pug',
  styleUrls: ['./tarif-relation-list-view.component.sass'],
})
export class TarifRelationListViewComponent extends BaseListViewComponent<TarifRelationListViewData, TarifRelation> {
  public tarif_detail_route?: Link<TarifBase>;
  public tariftyperelation_detail_route?: Link<TariftypeRelationBase>;

  public default_fields = ['id', 'src_details', 'dest_details', 'relation_details'];

  public override setRouteData(data: TarifRelationListViewData) {
    super.setRouteData(data);
    this.tarif_detail_route = new Link(data.tarif_detail_route, data, this._router);
    this.tariftyperelation_detail_route = new Link(data.tariftyperelation_detail_route, data, this._router);
  }
}
