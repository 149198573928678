import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitemodeService } from '../sitemode.service';

@Component({
  selector: 'lvadg-todo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './todo.component.pug',
  styleUrls: ['./todo.component.sass'],
})
export class TodoComponent {
  @Input() public issue?: string;

  constructor(public mode: SitemodeService) {}
}
