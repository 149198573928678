
<div *ngIf="client">
  <div class="card">
    <div class="card-header">
      <lvadg-icon ri="client">Détails client</lvadg-icon>
    </div>
    <div class="card-body">
      <dl class="row border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="id" [editable]="false" mode="dd">ID</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="cvva" [editable]="true" mode="dd">Code Vivalya</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="name" [editable]="true" mode="dd">Nom</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="parent" [editable]="false" mode="dd" [collection]="client$">Structure parente</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="type" [editable]="true" mode="dd">Type</data-dispedit>
        <data-dispedit class="col-6 col-lg-9" [model]="client" field="content_src" [editable]="true" mode="dd">Description</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="status" [editable]="true" mode="dd">État</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="created" [editable]="false" mode="dd">Date de création</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="updated" [editable]="false" mode="dd">Date de MAJ</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="cvva_updated" [editable]="false" mode="dd">Date de MAJ vivalya</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="phone" [editable]="true" mode="dd">Téléphone</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="fax" [editable]="true" mode="dd">Fax</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="website" [editable]="true" mode="dd">Site internet</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="legal" [editable]="true" mode="dd">Type structure</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="capital" [editable]="true" mode="dd">Capital</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="siren" [editable]="true" mode="dd">SIREN</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="siret" [editable]="true" mode="dd">SIRET</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="tvaic" [editable]="true" mode="dd">TVA IC</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="client" field="naf" [editable]="true" mode="dd">Code NAF</data-dispedit>
      </dl>
      <dl class="row mt-3">
        <div class="col-6">
          <dt class="d-flex flex-row justify-content-start"><span>Labels structure</span>
            <div class="form-check form-switch ms-3">
              <input class="form-check-input" id="allow_labels_edition" type="checkbox" #cbb (change)="allow_labels_edition.set(cbb.checked||false)">
              <label class="form-check-label" for="allow_labels_edition">Éditer les labels</label>
            </div>
          </dt>
          <dd>
            <lvadg-labels-manager class="col-12" [item]="client" [type]="LBT.STRUCTURE" source="slabels" [save]="true" [catalog_only]="false" [mode]="allow_labels_edition()?'edit':'view'"></lvadg-labels-manager>
          </dd>
        </div>
        <data-flags class="col-6 col-lg-3" [model]="client">Flags</data-flags>
      </dl>
    </div>
  </div>
  <div class="card mt-3" *ngIf="!client.location_details">
    <div class="card-header">
      <lvadg-icon ri="location">Adresse et géolocalisation</lvadg-icon>
    </div>
    <div class="card-body">
      <ng-container>
        <div class="text-danger py-2">Aucune adresse renseignée, ajoutez une adresse pour pouvoir géolocaliser le client</div>
        <lvadg-location-create [structure]="client" [action]="cact" [type]="LT.CLIENT" (created)="client.location_details = $event; client.location = $event.id"></lvadg-location-create>
      </ng-container>
    </div>
  </div>
  <div class="card mt-3" *ngIf="client.location_details">
    <div class="card-header">
      <lvadg-icon ri="location">Adresse et géolocalisation</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-location-manage [location]="client.location_details" [structure]="$any(client)" [action]="cact"></lvadg-location-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="image">Logo</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-logo-manage [model]="client"></lvadg-logo-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="image">Images</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-gallery-manage [model]="client"></lvadg-gallery-manage>
    </div>
  </div>
</div>