import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { DataMessageService, Link } from '@solidev/data';
import { ClientBase } from '../client.base';
import { Client } from '../client';
import { AuthService } from '../../../users/auth.service';

@Component({
  selector: 'lvadg-client-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './client-display.component.pug',
  styleUrls: ['./client-display.component.sass'],
})
export class ClientDisplayComponent {
  @Input() public client?: ClientBase | Client;
  @Input() public mode: 'line' = 'line' as const;
  @Input() public detail_route?: Link<ClientBase>;
  @Input() public showCodes: boolean;
  @Input() public xlsx: boolean = false;
  private _msgs: DataMessageService;

  constructor() {
    const user = inject(AuthService).currentUser;
    this.showCodes = user?.is_superuser || user?.is_staff || false;
    this._msgs = inject(DataMessageService);
  }

  public async clip() {
    await navigator.clipboard.writeText(this.client?.cvva || '');
    this._msgs.success('Code client copié dans le presse-papier');
  }
}
