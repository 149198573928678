import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdataKey } from '../cdata-key';
import { CdataKeyService } from '../cdata-key.service';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-cdata-key-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './cdata-key-manage.component.pug',
  styleUrls: ['./cdata-key-manage.component.sass'],
})
export class CdataKeyManageComponent implements OnInit {
  @Input() public cdatakey?: CdataKey;

  constructor(public keys$: CdataKeyService) {}

  ngOnInit(): void {}
}
