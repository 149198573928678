import { detailsField, integerField } from '@solidev/data';
import { Family } from '../../catalog/family/family';

export interface FamilyIntervalStat {
  interval: string;
  date: Date;
  avg: number;
  min: number;
  max: number;
  sdev: number;
  articles: number;
  tariftypes: number;
  tarifs: number;
}

export class FamilyIntervalStats {
  private _data = new Map<string, FamilyIntervalStat>();

  constructor(data: FamilyIntervalStat[]) {
    this._data = new Map(data.map((p) => [p.interval, p]));
  }

  public get intervals(): string[] {
    return Array.from(this._data.keys());
  }

  public get(key: string): FamilyIntervalStat | undefined {
    return this._data.get(key);
  }

  public has(key: string): boolean {
    return this._data.has(key);
  }
}

export class FamilyStats extends Family {
  static override readonly __name: string = 'FamilyStats';

  @integerField({
    description: 'Prix moyen',
    readonly: true,
  })
  public period_stats_price?: number;

  @integerField({
    description: 'Max sdev',
    readonly: true,
  })
  public period_stats_sdev?: number;

  @detailsField({
    name: 'interval_stats',
    many: true,
    deserialize: (data) => {
      return new FamilyIntervalStats(data);
    },
    readonly: true,
    description: 'Stats par intervalle',
  })
  public interval_stats?: FamilyIntervalStats;
}
