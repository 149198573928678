import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BASE_VIEW_IMPORTS } from './baseview.imports';
import { SafeDeleteComponent } from '@solidev/data';
import { HeaderActionComponent } from '../utils/header-action/header-action.component';

export const BASE_DETAIL_VIEW_IMPORTS = [
  ...BASE_VIEW_IMPORTS,
  NgbNavModule,
  HeaderActionComponent,
  SafeDeleteComponent,
];
