import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Storage } from "../storage";
import { StorageService } from "../storage.service";
import {
  FieldsParams,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgStringPipesModule } from "ngx-pipes";
import { MemberBase } from "../../member/member.base";
import { STORAGE_STATUS } from "../storage.base";
import { MemberService } from "../../member/member.service";
import { LocationFiltersService } from "../../../locations/common/location-filters.service";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { CdataKey } from "../../../cdata/cdata-key/cdata-key";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { IocodeDisplayComponent } from "../../../lvadgio/iocode/iocode-display/iocode-display.component";
import { CdataDisplayComponent } from "../../../cdata/cdata/cdata-display/cdata-display.component";

@Component({
  selector: "lvadg-storage-list",
  standalone: true,
  templateUrl: "./storage-list.component.pug",
  styleUrls: ["./storage-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule,
    LocationDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    IocodeDisplayComponent,
    CdataDisplayComponent,
  ],
})
export class StorageListComponent extends ModellistComponent<Storage> {
  @Input() member_detail_route?: Link<MemberBase>;
  /** Custom data allowed keys */
  @Input() public cdatakeys: CdataKey[] = [];

  constructor(
    private _members: MemberService,
    private _locFilters: LocationFiltersService,
    coll: StorageService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filtrer par état",
          model: Storage,
        }),
        new ModelListSelectMultiFilter({
          field: "type",
          name: "type",
          label: "Filtrer par type(s)",
          model: Storage,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "members",
          name: "members",
          label: "Recherche par adhérent(s)",
          display: "name",
          collection: this._members,
        }),
        ...this._locFilters.getFilters(),
      ],
    };
  }

  /**
   * Get fields to display in the list.
   * Adds custom data fields to the list, at position 500+.
   *
   * @returns FieldsParams
   */
  public override getFields(): FieldsParams {
    const flds = super.getFields();
    // Add custom data fields
    let i = 0;
    for (const k of this.cdatakeys) {
      flds.custom!.push({
        name: `cdata_${k.id}`,
        label: k.name,
        priority: 500 + i,
      });
      i++;
    }
    return flds;
  }

  public override getRowClasses(row: Storage): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === STORAGE_STATUS.INACTIVE) {
      cls.push("table-secondary");
      cls.push("text-muted");
    }
    if (row.status === STORAGE_STATUS.ACTIVE_NEW) {
      cls.push("table-warning");
    }
    return cls;
  }
}
