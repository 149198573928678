import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterDefaults, Link } from "@solidev/data";
import { Observable } from "rxjs";
import { Offer } from "../offer";
import { OfferService } from "../offer.service";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { RouterLink } from "@angular/router";
import { OFFER_TYPES } from "../offer.base";

@Component({
  selector: "lvadg-offer-shortlist",
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent],
  templateUrl: "./offer-shortlist.component.pug",
  styleUrls: ["./offer-shortlist.component.sass"],
})
export class OfferShortlistComponent implements OnInit {
  @HostBinding("class") public readonly class = "card h-100";
  @Input() public display: "card" | "fh-card" = "card";
  @Input() public title = "Offres";
  @Input() public offer_detail_routes: {
    [key in OFFER_TYPES]?: Link<Offer>;
  } = {};
  @Input() public ots_offer_detail_routes: {
    [key in OFFER_TYPES]?: Link<Offer>;
  } = {};
  @Input() public filter?: FilterDefaults = {};
  @Input() public limit: number = 25;
  public offers$!: Observable<Offer[]>;
  protected readonly OFFER_TYPES = OFFER_TYPES;

  constructor(private _offers: OfferService) {}

  public ngOnInit(): void {
    this.offers$ = this._offers.list({
      page_size: this.limit,
      fields: [
        "id",
        "type",
        "offertypestorage",
        "client_details",
        "datestart",
        "dateend",
        "storage_details",
        "title",
      ].join(","),
      ordering: "-created",
      ...this.filter,
    });
  }
}
