import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  floatField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum LOCATION_TYPE {
  MEMBER = 'member',
  STORAGE = 'storage',
  CLIENT = 'client',
  RESTO = 'resto',
  PRODUCER = 'producer',
  PROVIDER = 'provider',
  UNKNOWN = 'unknown',
}

export enum LocationGeoProviderType {
  MAPS = 'MAPS',
  BAN = 'BAN',
  MAN = 'MAN',
  UNKNOWN = '',
}

export enum GeoLocationStatus {
  VALID = 'OK',
  NO_GEOLOC = 'NG',
  AUTO_GEOLOC_OK = 'AY',
  AUTO_GEOLOC_FAIL = 'AN',
  INVALID_GEOLOC = 'KO',
  DO_NOT_GEOLOCATE = 'NA',
}

export enum LocationGeoTypes {
  HOUSE = 'housenumber',
  STREET = 'street',
  LOCALITY = 'locality',
  MUNICIPALITY = 'municipality',
  UNKNOWN = '',
}

export class LocationBase extends DataModel {
  static override readonly __name: string = 'LocationBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 100,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  // FIXME: this field is not used anymore, should be removed
  @charField({
    name: 'name',
    description: 'Alias',
    maxLength: 100,
    priority: -1,
  })
  public name!: string;

  @charField({
    name: 'phone',
    description: 'Téléphone',
    defaultValue: '',
    maxLength: 200,
    priority: 700,
  })
  public phone: string = '';

  @charField({
    name: 'fax',
    description: 'Fax',
    defaultValue: '',
    maxLength: 200,
    priority: 700,
  })
  public fax: string = '';

  @charField({
    name: 'website',
    description: 'Site web',
    defaultValue: '',
    maxLength: 300,
    priority: 700,
  })
  public website: string = '';

  @charField({
    name: 'locname',
    description: "Nom de l'emplacement",
    maxLength: 300,
    priority: 800,
  })
  public locname!: string;

  @textField({
    name: 'address',
    description: 'Adresse',
    maxLength: 300,
    defaultValue: '',
    required: true,
    priority: 790,
  })
  public address!: string;

  @charField({
    name: 'caddress',
    description: "Complément d'adresse",
    maxLength: 300,
    priority: 780,
  })
  public caddress!: string;

  @charField({
    name: 'city',
    description: 'Ville',
    defaultValue: '',
    maxLength: 100,
    required: true,
    priority: 770,
  })
  public city!: string;

  @charField({
    name: 'postcode',
    description: 'Code Postal',
    defaultValue: '',
    maxLength: 10,
    required: true,
    priority: 760,
  })
  public postcode!: string;

  @charField({
    name: 'cedex',
    description: 'CEDEX',
    maxLength: 20,
    priority: 750,
  })
  public cedex!: string;

  @charField({
    name: 'region',
    description: 'Région',
    maxLength: 50,
    priority: 740,
  })
  public region!: string;

  @charField({
    name: 'country',
    description: 'Code Pays',
    defaultValue: 'FR',
    required: true,
    maxLength: 50,
    priority: 730,
  })
  public country: string = 'FR';

  @floatField({
    name: 'lat',
    description: 'Latitude',
    priority: 500,
  })
  public lat!: number;

  @floatField({
    name: 'lng',
    description: 'Longitude',
    priority: 500,
  })
  public lng!: number;

  @foreignKeyField({
    name: 'geodepartement',
    description: 'ID Département',
    related: 'GeoDepartement',
    priority: -1,
  })
  public geodepartement!: number;

  @foreignKeyField({
    name: 'geocommune',
    description: 'ID Commune',
    related: 'GeoCommune',
    priority: -1,
  })
  public geocommune!: number;

  @foreignKeyField({
    name: 'georegion',
    description: 'ID Région',
    related: 'GeoRegion',
    priority: -1,
  })
  public georegion!: number;

  @foreignKeyField({
    name: 'geocountry',
    description: 'ID Pays',
    related: 'GeoCountry',
    priority: -1,
  })
  public geocountry!: number;

  @charField({
    name: 'loctype',
    description: 'Type de localisation',
    defaultValue: LOCATION_TYPE.UNKNOWN,
    required: true,
    maxLength: 12,
    priority: 950,
    choices: [
      {
        value: LOCATION_TYPE.UNKNOWN,
        desc: 'Type inconnu',
      },
      {
        value: LOCATION_TYPE.MEMBER,
        desc: 'Adhérent',
      },
      {
        value: LOCATION_TYPE.STORAGE,
        desc: 'Dépôt',
      },
      {
        value: LOCATION_TYPE.PRODUCER,
        desc: 'Producteur',
      },
      {
        value: LOCATION_TYPE.CLIENT,
        desc: 'Client',
      },
      {
        value: LOCATION_TYPE.RESTO,
        desc: 'Restaurant',
      },
      {
        value: LOCATION_TYPE.PROVIDER,
        desc: 'Fournisseur',
      },
    ],
  })
  public loctype: LOCATION_TYPE = LOCATION_TYPE.UNKNOWN;

  @charField({
    name: 'geotype',
    description: 'Type géoloc.',
    choices: [
      {
        value: LocationGeoTypes.HOUSE,
        desc: 'Bâtiment',
      },
      {
        value: LocationGeoTypes.STREET,
        desc: 'Rue',
      },
      {
        value: LocationGeoTypes.LOCALITY,
        desc: 'Localité',
      },
      {
        value: LocationGeoTypes.MUNICIPALITY,
        desc: 'Municipalité',
      },
      {
        value: LocationGeoTypes.UNKNOWN,
        desc: 'Non renseigné',
      },
    ],
    defaultValue: LocationGeoTypes.UNKNOWN,
    maxLength: 40,
    priority: 510,
  })
  public geotype: LocationGeoTypes = LocationGeoTypes.UNKNOWN;

  @charField({
    name: 'geoprovider',
    description: 'Source Géoloc.',
    maxLength: 4,
    choices: [
      {
        value: LocationGeoProviderType.MAPS,
        desc: 'Google Maps',
      },
      {
        value: LocationGeoProviderType.BAN,
        desc: 'Base Adresses Nationales',
      },
      {
        value: LocationGeoProviderType.MAN,
        desc: 'Manuel',
      },
      {
        value: LocationGeoProviderType.UNKNOWN,
        desc: 'Inconnu',
      },
    ],
    priority: 505,
  })
  public geoprovider!: LocationGeoProviderType;

  @charField({
    name: 'geolabel',
    description: 'Adresse formatée (géo)',
    maxLength: 300,
    priority: 450,
  })
  public geolabel!: string;

  // SEE: values here are not very coherent depending on geoprovider
  @charField({
    name: 'geoname',
    description: 'Nom adresse xx (géo)',
    maxLength: 300,
    priority: 440,
  })
  public geoname!: string;

  @charField({
    name: 'geopostcode',
    description: 'Code postal (géo)',
    maxLength: 20,
    priority: 430,
  })
  public geopostcode!: string;

  @charField({
    name: 'geocity',
    description: 'Ville  (géo)',
    maxLength: 200,
    priority: 420,
  })
  public geocity!: string;

  @charField({
    name: 'geohousenumber',
    description: 'Numéro (géo)',
    maxLength: 20,
    priority: 425,
  })
  public geohousenumber!: string;

  @charField({
    name: 'geostreet',
    description: 'Rue (géo)',
    maxLength: 300,
    priority: 422,
  })
  public geostreet!: string;

  @charField({
    name: 'geocontext',
    description: 'Contexte (géo)',
    maxLength: 300,
    priority: 410,
  })
  public geocontext!: string;

  @charField({
    name: 'geocitycode',
    description: 'Code ville (géo)',
    maxLength: 20,
    priority: 400,
  })
  public geocitycode!: string;

  @datetimeField({
    name: 'cvva_updated',
    description: 'Date MAJ Vivalya',
    priority: 100,
  })
  public cvva_updated!: Date;

  @charField({
    name: 'status',
    description: 'État',
    maxLength: 4,
    choices: [
      {
        value: GeoLocationStatus.VALID,
        desc: 'Géoloc. valide',
      },
      {
        value: GeoLocationStatus.NO_GEOLOC,
        desc: 'Sans géoloc.',
      },
      {
        value: GeoLocationStatus.AUTO_GEOLOC_OK,
        desc: 'Géoloc. auto OK',
      },
      {
        value: GeoLocationStatus.AUTO_GEOLOC_FAIL,
        desc: 'Échec géoloc. auto',
      },
      {
        value: GeoLocationStatus.INVALID_GEOLOC,
        desc: 'Géoloc. non valide',
      },
      {
        value: GeoLocationStatus.DO_NOT_GEOLOCATE,
        desc: 'Ne pas géolocaliser',
      },
    ],
    priority: 100,
  })
  public status!: GeoLocationStatus;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;

  public get _display(): string {
    return `${this.address} ${this.postcode} ${this.city} ${this.country == 'FR' ? '' : this.country}`;
  }

  public get cpcity(): string {
    return `${this.postcode} ${this.city} ${this.country == 'FR' ? '' : this.country}`;
  }
}
