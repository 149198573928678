import {
  Component,
  inject,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";
import { BASE_LIST_VIEW_IMPORTS } from "../../../../components/baseview/baselistview.imports";
import { ProducerListComponent } from "../../../../models/structures/producer/producer-list/producer-list.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { ProducerCreateComponent } from "../../../../models/structures/producer/producer-create/producer-create.component";
import { BaseListViewComponent } from "../../../../components/baseview/baselistview.component";
import { Producer } from "../../../../models/structures/producer/producer";
import { LOCAL_FILTERS } from "../../../../models/catalog/product/product.base";
import { PRODUCER_TYPES } from "../../../../models/structures/producer/producer.base";
import { Storage } from "../../../../models/structures/storage/storage";
import { PrintSettings } from "../../../../models/layouts/print-settings";
import { ProducerUserCreateComponent } from "../../../../models/structures/producer/producer-user-create/producer-user-create.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../../models/users/auth.service";
import {
  NgbModalModule,
  NgbOffcanvas,
  NgbOffcanvasRef,
} from "@ng-bootstrap/ng-bootstrap";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { RI } from "../../../../constants";
import { PRODUCER_PRINT_SETTINGS } from "../../../../models/structures/producer/producer.print";
import { CdataKey } from "../../../../models/cdata/cdata-key/cdata-key";

export interface ProducerListViewParams extends BaseRouteParams {
  page_title?: string;
  page_subtitle?: string;
  page_icon?: keyof typeof RI;
  detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  storage?: Storage;
  local: LOCAL_FILTERS;
  type?: PRODUCER_TYPES[];
}

export interface ProducerListViewData extends ProducerListViewParams {
  producers_cdatakeys: CdataKey[];
}

@Component({
  selector: "lvadg-producer-list-view",
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    ProducerListComponent,
    IconComponent,
    ProducerCreateComponent,
    ProducerUserCreateComponent,
    NgbModalModule,
    TodoComponent,
  ],
  templateUrl: "./producer-list-view.component.pug",
  styleUrls: ["./producer-list-view.component.sass"],
})
export class ProducerListViewComponent extends BaseListViewComponent<
  ProducerListViewData,
  Producer
> {
  public storage_detail_route?: Link<Storage>;
  public default_fields = [
    // 'id',
    "name",
    // 'logo_details',
    // 'slabels_details',
    "plabels_details",
    "location_details",
    "storage_relations_details",
    // 'location_postcode',
    // 'location_city',
    // 'activity', // TODO: activity stats
    "nb_products",
    // 'documents_details', // TODO: documents links
    // 'status',
    // 'flags',
    "actions",
  ];
  public printSettings: PrintSettings = PRODUCER_PRINT_SETTINGS;
  public readonly PT = PRODUCER_TYPES;
  public reload$ = new Subject<void | boolean>();
  public canCreate = signal<boolean>(false);
  public ofc$ = inject(NgbOffcanvas);
  public createOfcRef?: NgbOffcanvasRef;
  @ViewChild("createOfcSlot", { read: TemplateRef })
  createOfcSlot!: TemplateRef<unknown>;

  constructor(_router: Router, _route: ActivatedRoute, _auth: AuthService) {
    super(_router, _route);
    _auth.currentUser$.subscribe((user) => {
      this.canCreate.set(user?.have_group(/members:[0-9]+:admins/) || false);
    });
  }

  public override setRouteData(data: ProducerListViewData) {
    super.setRouteData(data);
    this.storage_detail_route = new Link(
      data.storage_detail_route,
      data,
      this._router,
    );
  }

  public create() {
    if (this.createOfcRef) {
      this.createOfcRef.close();
      delete this.createOfcRef;
    } else {
      this.createOfcRef = this.ofc$.open(this.createOfcSlot, {
        position: "bottom",
        panelClass: "h-auto",
      });
    }
  }
}
