//  allergen
export * from './allergen/allergen';
export * from './allergen/allergen.base';
export * from './allergen/allergen.resolver';
export * from './allergen/allergen.service';
export * from './allergen/allergen-list/allergen-list.component';
export * from './allergen/allergen-manage/allergen-manage.component';
export * from './allergen/allergen-create/allergen-create.component';
export * from './allergen/allergen-display/allergen-display.component';

//  brand
export * from './brand/brand';
export * from './brand/brand.base';
export * from './brand/brand.resolver';
export * from './brand/brand.service';
export * from './brand/brand-list/brand-list.component';
export * from './brand/brand-manage/brand-manage.component';
export * from './brand/brand-create/brand-create.component';
export * from './brand/brand-display/brand-display.component';

//  calibre
export * from './calibre/calibre';
export * from './calibre/calibre.base';
export * from './calibre/calibre.resolver';
export * from './calibre/calibre.service';
export * from './calibre/calibre-list/calibre-list.component';
export * from './calibre/calibre-manage/calibre-manage.component';
export * from './calibre/calibre-create/calibre-create.component';
export * from './calibre/calibre-display/calibre-display.component';

//  category
export * from './category/category';
export * from './category/category.base';
export * from './category/category.resolver';
export * from './category/category.service';
export * from './category/category-list/category-list.component';
export * from './category/category-manage/category-manage.component';
export * from './category/category-create/category-create.component';
export * from './category/category-display/category-display.component';

//  origin
export * from './origin/origin';
export * from './origin/origin.base';
export * from './origin/origin.resolver';
export * from './origin/origin.service';
export * from './origin/origin-list/origin-list.component';
export * from './origin/origin-manage/origin-manage.component';
export * from './origin/origin-create/origin-create.component';
export * from './origin/origin-display/origin-display.component';

//  particularity
export * from './particularity/particularity';
export * from './particularity/particularity.base';
export * from './particularity/particularity.resolver';
export * from './particularity/particularity.service';
export * from './particularity/particularity-list/particularity-list.component';
export * from './particularity/particularity-manage/particularity-manage.component';
export * from './particularity/particularity-create/particularity-create.component';
export * from './particularity/particularity-display/particularity-display.component';

//  packaging
export * from './packaging/packaging';
export * from './packaging/packaging.base';
export * from './packaging/packaging.resolver';
export * from './packaging/packaging.service';
export * from './packaging/packaging-list/packaging-list.component';
export * from './packaging/packaging-manage/packaging-manage.component';
export * from './packaging/packaging-create/packaging-create.component';
export * from './packaging/packaging-display/packaging-display.component';

//  presentation
export * from './presentation/presentation';
export * from './presentation/presentation.base';
export * from './presentation/presentation.resolver';
export * from './presentation/presentation.service';
export * from './presentation/presentation-list/presentation-list.component';
export * from './presentation/presentation-manage/presentation-manage.component';
export * from './presentation/presentation-create/presentation-create.component';
export * from './presentation/presentation-display/presentation-display.component';

//  process
export * from './process/process';
export * from './process/process.base';
export * from './process/process.resolver';
export * from './process/process.service';
export * from './process/process-list/process-list.component';
export * from './process/process-manage/process-manage.component';
export * from './process/process-create/process-create.component';
export * from './process/process-display/process-display.component';

//  production
export * from './production/production';
export * from './production/production.base';
export * from './production/production.resolver';
export * from './production/production.service';
export * from './production/production-list/production-list.component';
export * from './production/production-manage/production-manage.component';
export * from './production/production-create/production-create.component';
export * from './production/production-display/production-display.component';

//  region
export * from './region/region';
export * from './region/region.base';
export * from './region/region.resolver';
export * from './region/region.service';
export * from './region/region-list/region-list.component';
export * from './region/region-manage/region-manage.component';
export * from './region/region-create/region-create.component';
export * from './region/region-display/region-display.component';

//  treatment
export * from './treatment/treatment';
export * from './treatment/treatment.base';
export * from './treatment/treatment.resolver';
export * from './treatment/treatment.service';
export * from './treatment/treatment-list/treatment-list.component';
export * from './treatment/treatment-manage/treatment-manage.component';
export * from './treatment/treatment-create/treatment-create.component';
export * from './treatment/treatment-display/treatment-display.component';

//  usage
export * from './usage/usage';
export * from './usage/usage.base';
export * from './usage/usage.resolver';
export * from './usage/usage.service';
export * from './usage/usage-list/usage-list.component';
export * from './usage/usage-manage/usage-manage.component';
export * from './usage/usage-create/usage-create.component';
export * from './usage/usage-display/usage-display.component';
