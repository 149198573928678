
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-article-tarif-template-manage [articletariftemplate]="data.articletariftemplate" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route"></lvadg-article-tarif-template-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="tarifs"><a class="text-warning" ngbNavLink>Détails tarifs</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <pre class="todo">Liste et évolution des prix dans le tarif</pre>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="rnm"><a class="text-warning" ngbNavLink>RNM</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <pre class="todo">Correspondances RNM et tarifs RNM</pre>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="links"><a class="text-warning" ngbNavLink>Correspondances tarifs liés</a>
      <ng-template ngbNavContent>
        <div class="tab-content"></div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="stats"><a class="text-warning" ngbNavLink>Statistiques</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <pre class="todo">Statistiques du tarif / évolution RNM / comparaison autres tarifs ?</pre>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>