
<form [formGroup]="createForm" (submit)="save()">
  <div class="row mb-3 g-3">
    <data-dispedit class="col-6" [model]="storage" field="cvva" mode="form" [form]="createForm">Code Vivalya</data-dispedit>
    <data-dispedit class="col-6" [model]="storage" field="member" mode="form" [form]="createForm" [collection]="members$">Adhérent</data-dispedit>
    <data-dispedit class="col-6" [model]="storage" field="name" mode="form" [form]="createForm">Nom</data-dispedit>
    <data-dispedit class="col-6" [model]="storage" field="type" mode="form" [form]="createForm">Type</data-dispedit>
  </div>
  <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
  <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
</form>