import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface LocationListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  member_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
  resto_detail_route: RouteConfigItem;
  producer_detail_route: RouteConfigItem;
  provider_detail_route: RouteConfigItem;
}

export interface LocationListViewData extends LocationListViewParams {}
