import { Component, EventEmitter, inject, input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OffertypeText } from "../offertype-text";
import { OffertypeTextType } from "../../offertype-text-type/offertype-text-type";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DispeditComponent, RicheditComponent } from "@solidev/data";
import { OffertypeTextService } from "../offertype-text.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "lvadg-offertype-text-create",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DispeditComponent,
    RicheditComponent,
  ],
  templateUrl: "./offertype-text-create.component.pug",
  styleUrls: ["./offertype-text-create.component.sass"],
})
export class OffertypeTextCreateComponent {
  public offertype_text_type = input<OffertypeTextType>();
  @Output() public created = new EventEmitter<OffertypeText>();
  @Output() public cancelled = new EventEmitter<void>();
  public form = new FormGroup({
    text: new FormControl<string>(""),
  });
  private ott = inject(OffertypeTextService);
  public item = this.ott.create();

  public async save() {
    const tt = this.offertype_text_type();
    if (this.form.valid && tt) {
      this.item.fromJson(this.form.value);
      this.item.texttype = tt.id;
      await firstValueFrom(this.item.save({ updateModel: true }));
      this.created.emit(this.item);
    }
  }
}
