import { Injectable } from '@angular/core';
import { filter, interval, Observable, ReplaySubject } from 'rxjs';
import { DistanceService } from './distance.service';

const RESOLVE_INTERVAL = 1000;
const RESOLVE_MAX_LENGTH = 50;
const RESOLVE_BATCH = 100;

interface DistanceCacheEntry {
  pos1: [number, number];
  pos2: [number, number];
  d: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class DistanceCacheService {
  public results$ = new ReplaySubject<boolean>(1);
  public tick$?: Observable<number>;
  private cache = new Map<string, DistanceCacheEntry>();
  private unresolved: string[] = [];

  constructor(public _distance: DistanceService) {}

  public startTick() {
    this.tick$ = interval(RESOLVE_INTERVAL).pipe(
      filter(() => this.unresolved.length > 0)
    );
    this.tick$.subscribe(() => this.resolve());
  }

  public getDistance(
    pos1: [number, number],
    pos2: [number, number]
  ): number | null {
    if (!this.tick$) {
      this.startTick();
    }
    if (!pos1 || !pos2) {
      return null;
    }
    const key1 = this.pos2key(pos1);
    const key2 = this.pos2key(pos2);
    const key = key1 + '_' + key2;
    if (this.cache.has(key)) {
      return this.cache.get(key)!.d;
    } else {
      this.cache.set(key, { pos1, pos2, d: null });
      this.unresolved.push(key);
      if (this.unresolved.length >= RESOLVE_MAX_LENGTH) {
        this.resolve();
      }
      return null;
    }
  }

  private pos2key(pos: [number, number]): string {
    return (
      `${Math.ceil(pos[0] * 10000)}`.padStart(8, '0') +
      ',' +
      `${Math.ceil(pos[1] + 10000)}`.padStart(8, '0')
    );
  }

  private resolve() {
    const i = 0;
    const payload: Record<string, DistanceCacheEntry> = {};
    const resolving: string[] = [];
    for (let i = 0; i < RESOLVE_BATCH; i++) {
      if (this.unresolved.length === 0) {
        break;
      }
      const key = this.unresolved.shift()!;
      const pc = this.cache.get(key)!;
      payload[key] = pc;
    }
    this._distance
      .action<Record<string, DistanceCacheEntry>>(
        null,
        'POST',
        'get_distances',
        { body: payload }
      )
      .subscribe((res) => {
        for (const key of Object.keys(res)) {
          this.cache.set(key, res[key]);
        }
        this.results$.next(true);
      });
  }
}
