
<ng-container *ngIf="stept$|async; let stept">
  <ng-container *ngFor="let s of stept; let main_index=index">
    <div class="card my-2">
      <div class="card-header mainstep" [class.text-bg-success]="isMainDone(tarif, stept, s)">{{main_index+1}}. {{s.title$}}</div>
      <!-- TODO: can open/close + collapse-->
      <div class="row" *ngIf="mainStep &amp;&amp; mainStep.order &gt;= s.order">
        <div class="col-md-4 col-xxl-3">
          <ol class="list-group list-group-flush">
            <li class="list-group-item substep" *ngFor="let v of s.substeps_details; let sub_index=index" [class.list-group-item-success]="v.last_log_item &amp;&amp; v.last_log_item.operation == 'done'" [class.list-group-item-primary]="subStep &amp;&amp; subStep.id === v.id">
              <div class="d-flex flex-row justify-content-between"><span class="title" role="button" (click)="action({action: 'step', dest: v.id})"><span class="me-2">{{sub_index+1}}.</span><span>{{v.stitle$}}</span></span>
                <div class="substeplog" *ngIf="v.last_log_item; let last_log" [class.text-success]="last_log.operation=='done'">
                  <lvadg-user-display [user]="last_log.author_details" mode="line"></lvadg-user-display><span class="ms-2">le {{last_log.created|date:'short'}}</span><i class="bi bi-check-lg text-success ms-1" *ngIf="last_log.operation=='done'"></i><i class="bi bi-check-lg text-muted ms-1" *ngIf="last_log.operation!='done'"></i>
                </div>
              </div>
            </li>
          </ol>
        </div>
        <div class="col-md-8 col-xxl-9">
          <ng-container *ngIf="isMainDone(tarif, stept, s)">
            <div class="d-flex flex-column justify-content-around h-100">
              <div class="text-center text-success">Étape terminée</div>
            </div>
          </ng-container>
          <ng-container *ngFor="let v of s.substeps_details">
            <ng-container *ngIf="subStep &amp;&amp; subStep.id ===v.id">
              <ng-container [ngSwitch]="v.step">
                <div class="step" *ngSwitchCase="LifecycleSubSteps.EDITION">
                  <lvadg-tarif-lifecycle-step-edit [tarif]="tarif" [tariftype]="tariftype" [steps]="stept" [step]="v" [reload$]="reload" [menu]="menu"></lvadg-tarif-lifecycle-step-edit>
                </div>
                <div class="step step-lg" *ngSwitchCase="LifecycleSubSteps.NEGOSEND">
                  <lvadg-tarif-lifecycle-step-negosend [tarif]="tarif" [tariftype]="tariftype" [steps]="stept" [step]="v" [reload$]="reload" [menu]="menu"></lvadg-tarif-lifecycle-step-negosend>
                </div>
                <div class="step" *ngSwitchCase="LifecycleSubSteps.NEGOUPD">
                  <lvadg-tarif-lifecycle-step-negoupdate [tarif]="tarif" [tariftype]="tariftype" [steps]="stept" [step]="v" [reload$]="reload" [menu]="menu"></lvadg-tarif-lifecycle-step-negoupdate>
                </div>
                <div class="step step-lg" *ngSwitchCase="LifecycleSubSteps.PUBLISH">
                  <lvadg-tarif-lifecycle-step-publish [tarif]="tarif" [tariftype]="tariftype" [steps]="stept" [step]="v" [reload$]="reload" [menu]="menu"></lvadg-tarif-lifecycle-step-publish>
                </div>
                <div class="step" *ngSwitchCase="LifecycleSubSteps.DONE">
                  <lvadg-tarif-lifecycle-step-done [tarif]="tarif" [tariftype]="tariftype" [steps]="stept" [step]="v" [reload$]="reload" [menu]="menu"></lvadg-tarif-lifecycle-step-done>
                </div>
                <div class="step" *ngSwitchCase="LifecycleSubSteps.UPLOAD">
                  <lvadg-tarif-lifecycle-step-upload [tarif]="tarif" [tariftype]="tariftype" [step]="v" [reload$]="reload" [menu]="menu" (next)="action({action: 'next'})"></lvadg-tarif-lifecycle-step-upload>
                </div>
                <div class="step" *ngSwitchCase="LifecycleSubSteps.ARTICLES">
                  <lvadg-tarif-lifecycle-step-articles [tarif]="tarif" [step]="v" (next)="action({action: 'next'})"></lvadg-tarif-lifecycle-step-articles>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>