import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Packaging } from '../../../../../models/catalog/meta/packaging/packaging';

export interface PackagingDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface PackagingDetailViewData extends PackagingDetailViewParams {
  packaging: Packaging;
}
