
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypeStoragePermission } from '../offertype-storage-permission';
@Component({
  selector: 'lvadg-offertype-storage-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offertype-storage-permission-manage.component.pug',
  styleUrls: ['./offertype-storage-permission-manage.component.sass']
})
export class OffertypeStoragePermissionManageComponent implements OnInit {
  @Input() public offertypestoragepermission?: OffertypeStoragePermission;

  constructor() { }

  ngOnInit(): void {
  }

}
