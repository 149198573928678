import { Component } from "@angular/core";

import {
  BaseRouteParams,
  FilterDefaults,
  TabMemoryService,
} from "@solidev/data";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { BASE_DETAIL_VIEW_IMPORTS } from "../../../../components/baseview/basedetailview.imports";
import { TariftypeRelationManageComponent } from "../../../../models/tarifs/tariftype-relation/tariftype-relation-manage/tariftype-relation-manage.component";
import { TariftypeDisplayComponent } from "../../../../models/tarifs/tariftype/tariftype-display/tariftype-display.component";
import { ArticleTarifTemplateRelationListComponent } from "../../../../models/tarifs/article-tarif-template-relation/article-tarif-template-relation-list/article-tarif-template-relation-list.component";
import { BaseDetailViewComponent } from "../../../../components/baseview/basedetailview.component";
import { TariftypeRelation } from "../../../../models/tarifs/tariftype-relation/tariftype-relation";
import { TariftypeActionService } from "../../../../models/tarifs/tarif/tariftype-action.service";
import { Tariftype } from "../../../../models/tarifs/tariftype/tariftype";

export interface TariftypeRelationDetailViewParams extends BaseRouteParams {}

export interface TariftypeRelationDetailViewData
  extends TariftypeRelationDetailViewParams {
  tariftyperelation: TariftypeRelation;
  tariftype?: Tariftype;
}

@Component({
  selector: "lvadg-tariftype-relation-detail-view",
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    TariftypeRelationManageComponent,
    TariftypeDisplayComponent,
    ArticleTarifTemplateRelationListComponent,
  ],
  templateUrl: "./tariftype-relation-detail-view.component.pug",
  styleUrls: ["./tariftype-relation-detail-view.component.sass"],
})
export class TariftypeRelationDetailViewComponent extends BaseDetailViewComponent<
  TariftypeRelationDetailViewData,
  TariftypeRelation
> {
  public attrel_default_fields: string[] = [
    "ttrel_details",
    "ttrel_src_details",
    "src_details",
    "src_details_uf",
    "ttrel_dest_details",
    "dest_details",
    "dest_details_uf",
    "ops",
  ];
  public attrel_filter: FilterDefaults = {};
  public updatingArticles = false;
  public reload = new Subject<void | boolean>();

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _ttact: TariftypeActionService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeRelationDetailViewData) {
    super.setRouteData(data);
    if (data.tariftype) {
      if (data.tariftype.id === data.tariftyperelation.src) {
        // Display source
        this.attrel_default_fields = [
          "ttrel_src_details",
          "src_details",
          "src_details_uf",
          "dest_details",
          "dest_details_uf",
          "ops",
        ];
        this.attrel_filter = { ttrel: data.tariftyperelation.id };
      } else {
        // Display dest
        this.attrel_default_fields = [
          "ttrel_src_details",
          "src_details",
          "src_details_uf",
          "dest_details",
          "dest_details_uf",
          "ops",
        ];
        this.attrel_filter = { ttrel: data.tariftyperelation.id };
      }
    }
    // this.some_route = new Link<any>(data.some_route, this);
  }

  public async updateArticles(tt: Tariftype, rel: TariftypeRelation) {
    this.updatingArticles = true;
    await this._ttact.action(
      tt,
      {
        action: "sync_relation",
        relation: rel.id,
      },
      {
        error: "Erreur lors de la synchronisation des articles",
      },
    );
    this.updatingArticles = false;
    this.reload.next(true);
  }
}
