
<ng-container *ngIf="articletariftemplate">
  <div class="row g-3">
    <div class="col">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos article</div>
        <div class="card-body">
          <p class="text-info">Seuls l'état et les dates de début/fin sont modifiables</p>
          <dl class="row">
            <data-dispedit class="col-6" [model]="articletariftemplate" field="id" mode="dd" [editable]="false">ID</data-dispedit>
            <data-dispedit class="col-6" [model]="articletariftemplate" field="status" mode="dd" [editable]="true">Etat</data-dispedit>
            <data-dispedit class="col-6" [model]="articletariftemplate" field="name" mode="dd" [editable]="true">Nom</data-dispedit>
          </dl>
          <dl class="row">
            <data-dispedit class="col-6" [model]="articletariftemplate" field="datestart" mode="dd" [editable]="true">Date de début</data-dispedit>
            <data-dispedit class="col-6" [model]="articletariftemplate" field="dateend" mode="dd" [editable]="true">Date de fin</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-info-circle me-2"></i>Liens articles catalogue</div>
        <div class="card-body">
          <dl class="row">
            <data-dispedit class="col-8" [model]="articletariftemplate" field="article" mode="dd" [editable]="true" [collection]="articles$">Article catalogue</data-dispedit>
            <div class="col-4">
              <lvadg-article-display [article]="articletariftemplate.article_details" mode="button" [detail_route]="article_detail_route"></lvadg-article-display>
            </div>
          </dl>
          <dl class="row">
            <data-dispedit class="col-8" [model]="articletariftemplate" field="eaclient" mode="dd" [editable]="true" [collection]="earticles$">Article client</data-dispedit>
            <div class="col-4">
              <lvadg-earticle-display [earticle]="articletariftemplate.eaclient_details" mode="button" [detail_route]="earticle_detail_route"></lvadg-earticle-display>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</ng-container>