
<form [formGroup]="form">
  <div class="row g-2">
    <div class="col-6">
      <label for="type">Type de notification</label>
      <select class="form-select" id="type" formControlName="type">
        <option [ngValue]="OTN.PUBLICATION">Publication initiale</option>
        <option [ngValue]="OTN.UPDATE">Mise à jour / erratum</option>
      </select>
    </div>
    <div class="col-6">
      <label for="title">Titre de la notification</label>
      <input class="form-control" id="title" type="text" formControlName="title">
    </div>
    <div class="col-12">
      <label for="content">Contenu de la notification</label>
      <data-richedit id="content" [fc]="form.controls.message" [hideButton]="true"></data-richedit>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-8">
      <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid" (click)="createNotification()">Créer la notification</button>
    </div>
    <div class="col-4">
      <button class="btn btn-outline-secondary w-100" type="cancel" (click)="cancelled.emit()">Annuler</button>
    </div>
  </div>
</form>