
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { RnmmarcheService} from "./rnmmarche.service";
import { Rnmmarche } from "./rnmmarche";

@Injectable({
  providedIn: 'root'
})
export class RnmmarcheResolver  {
  constructor(private _rs: RnmmarcheService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Rnmmarche> {
    return this._rs.fetch(+route.params["rnmmarcheId"])
  }
}
