
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Sélectionnez un tarif pour {{dest?.name}}</h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <button class="btn btn-outline-secondary w-100 my-1" (click)="togglePreparation()">{{preparation ? 'Afficher les tarifs publiés' : 'Afficher les tarifs en préparation'}}</button>
    <ul class="list-group">
      <li class="list-group-item list-group-item-action" *ngFor="let t of tarifs|async" role="button" (click)="selectTarif(t)" [class.list-group-item-success]="t.status===TarifStatus.PUBLISHED">{{t.name}}<span class="badge bg-primary ms-1">{{t.status|pchoice:t:'status'}}</span></li>
    </ul>
  </div>
  <div class="offcanvas-bottom">
    <p>Bottom</p>
  </div>
</ng-template>
<ng-container *ngIf="mode===&quot;inline&quot; &amp;&amp; relation &amp;&amp; dest">
  <button class="btn btn-outline-primary btn-sm" (click)="open()">Sélectionner un tarif source</button>
</ng-container>