import { Component, ElementRef, inject, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  Link,
  PChoicePipe,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { OffertypeStorage } from "../../../../../models/offers/offertype-storage/offertype-storage";
import { AuthService } from "../../../../../models/users/auth.service";
import { OffertypeStorageViewData, OffertypeStorageViewParams } from "../menu";
import { OffertypeStorageNavComponent } from "../_nav/offertype-storage-nav.component";
import { OfferCreateComponent } from "../../../../../models/offers/offer/offer-create/offer-create.component";
import { filter, map, Observable, switchMap, tap } from "rxjs";
import { Offertype } from "../../../../../models/offers/offertype/offertype";
import { OffertypeService } from "../../../../../models/offers/offertype/offertype.service";
import { User } from "../../../../../models/users/user/user";
import { LVADG_GROUPS } from "../../../../../constants";
import { UserDisplayComponent } from "../../../../../models/users/user/user-display/user-display.component";
import { RruleDisplayComponent } from "../../../../../components/utils/rrule-display/rrule-display.component";
import { TariftypeDisplayComponent } from "../../../../../models/tarifs/tariftype/tariftype-display/tariftype-display.component";
import { OfferShortlistComponent } from "../../../../../models/offers/offer/offer-shortlist/offer-shortlist.component";
import { OFFERTYPE_STORAGE_USER_LEVEL } from "../../../../../models/offers/offertype-storage/offertype-storage.base";
import { Offer } from "../../../../../models/offers/offer/offer";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { OFFER_TYPES } from "../../../../../models/offers/offer/offer.base";

export interface OffertypeStorageDisplayViewParams
  extends OffertypeStorageViewParams {
  offertypestorage_offer_detail_route: {
    [key in OFFER_TYPES]?: RouteConfigItem;
  };
}

export interface OffertypeStorageDisplayViewData
  extends OffertypeStorageDisplayViewParams,
    OffertypeStorageViewData {}

@Component({
  selector: "lvadg-offertype-storage-display-view",
  standalone: true,
  imports: [
    CommonModule,
    OffertypeStorageNavComponent,
    OfferCreateComponent,
    HeaderActionComponent,
    IconComponent,
    PChoicePipe,
    UserDisplayComponent,
    RruleDisplayComponent,
    TariftypeDisplayComponent,
    OfferShortlistComponent,
  ],
  templateUrl: "./offertype-storage-display-view.component.pug",
  styleUrls: ["./offertype-storage-display-view.component.sass"],
})
export class OffertypeStorageDisplayViewComponent extends BaseDetailViewComponent<
  OffertypeStorageDisplayViewData,
  OffertypeStorage
> {
  public fdata$!: Observable<{
    data: OffertypeStorageDisplayViewData;
    ot: Offertype;
    user: User | null;
  }>;
  public offertypestorage_offer_detail_route: {
    [key in OFFER_TYPES]?: Link<Offer>;
  } = {};
  @ViewChild("ofcCreateSlot", { static: false })
  private _createOfferSlot!: ElementRef<HTMLDivElement>;
  private _createOfferInstance?: NgbOffcanvasRef;
  private _ofc = inject(NgbOffcanvas);

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _auth: AuthService,
    private _ots: OffertypeService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: OffertypeStorageDisplayViewData) {
    super.setRouteData(data);
    for (const key in data.offertypestorage_offer_detail_route) {
      const tkey = key as OFFER_TYPES;
      if (data.offertypestorage_offer_detail_route[tkey]) {
        this.offertypestorage_offer_detail_route![tkey] = new Link(
          data.offertypestorage_offer_detail_route[tkey]!,
          data,
          this._router,
        );
      }
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.fdata$ = this.data$.pipe(
      filter((data) => !!data.offertypestorage.offertype),
      switchMap((data) =>
        this._auth.currentUser$.pipe(
          map((user) => ({
            data,
            user,
          })),
        ),
      ),
      switchMap((data) =>
        this._ots.fetch(data.data.offertypestorage.offertype!).pipe(
          tap((ot) => {
            data.data.offertype = ot;
          }),
          map((ot) => ({
            data: data.data,
            ot,
            user: data.user,
          })),
        ),
      ),
    );
  }

  public showDetails(user: User | null): boolean {
    if (user?.is_superuser || user?.is_staff) {
      return true;
    }
    return !!user?.have_group(LVADG_GROUPS.members);
  }

  public canCreate(
    user: User | null,
    ot: Offertype,
    ots: OffertypeStorage,
  ): boolean {
    if (user?.is_superuser) {
      return true;
    }
    return ots.have_level(
      OFFERTYPE_STORAGE_USER_LEVEL.write,
      OFFERTYPE_STORAGE_USER_LEVEL.write,
      OFFERTYPE_STORAGE_USER_LEVEL.admin,
    );
  }

  public async onOfferCreated($event: Offer) {
    await this.offertypestorage_offer_detail_route[$event.type]?.navigate({
      offer: $event,
    });
    this._createOfferInstance?.close();
  }

  public createOffer() {
    if (this._createOfferInstance) {
      this._createOfferInstance.dismiss();
      return;
    }
    this._createOfferInstance = this._ofc.open(this._createOfferSlot, {
      position: "bottom",
      panelClass: "h-auto",
    });
    this._createOfferInstance.result.finally(() => {
      this._createOfferInstance = undefined;
    });
  }
}
