
<lvadg-header-action title="Modes de production" level="h1" ri="catalog" subtitle="Gestion des métadonnées catalogue">
  <ng-container actions>
    <button class="btn btn-outline-primary" type="button" (click)="create=!create">
      <lvadg-icon ri="add">Ajouter un mode de production</lvadg-icon>
    </button>
  </ng-container>
</lvadg-header-action>
<div class="card my-3" *ngIf="create">
  <div class="card-header">
    <lvadg-icon ri="add">Ajouter un mode de production</lvadg-icon>
  </div>
  <div class="card-body">
    <lvadg-production-create (cancelled)="create=false" (created)="reload$.next(true); create=false; detail_route.navigate({production: $event})"></lvadg-production-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-production-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [reload]="reload$"></lvadg-production-list>
</ng-container>