import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';

export enum TARIFTYPE_PERMISSION {
  ADMIN = 'ADMIN',
  PILOTE = 'PILOTE',
  WRITE = 'WRITE',
  STORAGE = 'STORAGE',
  READ = 'READ',
}

export class TariftypePermissionBase extends DataModel {
  static override readonly __name: string = 'TariftypePermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    choices: [
      {
        value: TARIFTYPE_PERMISSION.ADMIN,
        desc: 'Administrateur tarif',
      },
      {
        value: TARIFTYPE_PERMISSION.PILOTE,
        desc: 'Pilote tarif',
      },
      {
        value: TARIFTYPE_PERMISSION.WRITE,
        desc: 'Saisie de prix/dispos',
      },
      {
        value: TARIFTYPE_PERMISSION.STORAGE,
        desc: 'Saisie de prix/dispo par dépôt',
      },
      {
        value: TARIFTYPE_PERMISSION.READ,
        desc: 'Consultation du tarif',
      },
    ],
  })
  public permission!: TARIFTYPE_PERMISSION;

  @foreignKeyField({
    name: 'target',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID User',
    related: 'User',
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
  })
  public group!: number;
}
