import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  decimalField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from "@solidev/data";

export enum SUPPORT_MESSAGE_KIND {
  BUG = "BUG",
  ENHANCEMENT = "ENH",
  FEATURE = "FEA",
  DOCUMENTATION = "DOC",
  HELP = "HEL",
  META = "MET",
  DISCUSSION = "DIS",
}

export const SUPPORT_MESSAGE_TYPE_CHOICES = [
  { value: SUPPORT_MESSAGE_KIND.HELP, desc: "HELP" },
  {
    value: SUPPORT_MESSAGE_KIND.ENHANCEMENT,
    desc: "AMÉLIORATION",
  },
  { value: SUPPORT_MESSAGE_KIND.FEATURE, desc: "FONCTIONNALITÉ" },
  {
    value: SUPPORT_MESSAGE_KIND.BUG,
    desc: "BUG !",
  },
  { value: SUPPORT_MESSAGE_KIND.DOCUMENTATION, desc: "DOCUMENTATION" },
  {
    value: SUPPORT_MESSAGE_KIND.META,
    desc: "MÉTA",
  },
  { value: SUPPORT_MESSAGE_KIND.DISCUSSION, desc: "DISCUSSION" },
];

export enum SUPPORT_MESSAGE_STATUS {
  OPEN = "OPEN",
  WIP = "WIP",
  TESTING = "TESTING",
  DONE = "DONE",
  ARCHIVE = "ARCHIVE",
}

export enum SUPPORT_MESSAGE_PRIORITY {
  LOW = "P4_LOW",
  MEDIUM = "P3_NORMAL",
  HIGH = "P2_URGENT",
  CRITICAL = "P1_CRITICAL",
}

export class SupportMessageBase extends DataModel {
  static override readonly __name: string = "SupportMessageBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @foreignKeyField({
    name: "author_id",
    description: "ID Auteur",
    related: "User",
    priority: -1,
  })
  public author_id!: number;

  @foreignKeyField({
    name: "project_id",
    description: "ID Projet",
    related: "Project",
    priority: -1,
  })
  public project_id!: number;

  @charField({
    name: "zone",
    description: "Zone",
    maxLength: 20,
    required: true,
    priority: 910,
    defaultValue: "front",
  })
  public zone: string = "front";

  @charField({
    name: "route",
    description: "Route",
    maxLength: 500,
    priority: 900,
  })
  public route!: string;

  @charField({ name: "url", description: "Url", maxLength: 500, priority: -1 })
  public url!: string;

  @charField({
    name: "title",
    description: "Titre",
    maxLength: 400,
    priority: 800,
  })
  public title!: string;

  @textField({ name: "text", description: "Texte", priority: 700 })
  public text!: string;

  @textField({
    name: "html",
    description: "Texte (html)",
    priority: -1,
  })
  public html!: string;

  @charField({
    name: "kind",
    description: "Type",
    priority: 980,
    defaultValue: SUPPORT_MESSAGE_KIND.ENHANCEMENT,
    required: true,
    maxLength: 10,
    choices: SUPPORT_MESSAGE_TYPE_CHOICES,
  })
  public kind: SUPPORT_MESSAGE_KIND = SUPPORT_MESSAGE_KIND.ENHANCEMENT;

  @charField({
    name: "status",
    description: "État",
    priority: 960,
    defaultValue: SUPPORT_MESSAGE_STATUS.OPEN,
    required: true,
    maxLength: 10,
    choices: [
      { value: SUPPORT_MESSAGE_STATUS.OPEN, desc: "Ouvert" },
      {
        value: SUPPORT_MESSAGE_STATUS.WIP,
        desc: "W.I.P.",
      },
      { value: SUPPORT_MESSAGE_STATUS.TESTING, desc: "Testing" },
      {
        value: SUPPORT_MESSAGE_STATUS.DONE,
        desc: "Fait",
      },
      { value: SUPPORT_MESSAGE_STATUS.ARCHIVE, desc: "Archivé" },
    ],
  })
  public status: SUPPORT_MESSAGE_STATUS = SUPPORT_MESSAGE_STATUS.OPEN;

  @charField({
    name: "priority",
    description: "Priorité",
    priority: 940,
    defaultValue: SUPPORT_MESSAGE_PRIORITY.MEDIUM,
    required: true,
    maxLength: 10,
    choices: [
      { value: SUPPORT_MESSAGE_PRIORITY.LOW, desc: "Basse" },
      {
        value: SUPPORT_MESSAGE_PRIORITY.MEDIUM,
        desc: "Normale",
      },
      { value: SUPPORT_MESSAGE_PRIORITY.HIGH, desc: "Haute" },
      {
        value: SUPPORT_MESSAGE_PRIORITY.CRITICAL,
        desc: "Critique",
      },
    ],
  })
  public priority: SUPPORT_MESSAGE_PRIORITY = SUPPORT_MESSAGE_PRIORITY.LOW;

  @integerField({
    name: "gitlab_issue_id",
    description: "ID Issue Gitlab",
    required: false,
  })
  public gitlab_issue_id?: number;

  @decimalField({
    name: "estimate",
    description: "Estimation (heures)",
    priority: 300,
    required: false,
    factor: 100,
  })
  public estimate!: number;

  @dateField({
    name: "deadline",
    description: "Date limite",
    priority: 200,
    required: false,
  })
  public deadline!: Date;

  @datetimeField({
    name: "date_created",
    description: "Date de création",
    priority: 50,
  })
  public date_created!: Date;

  @datetimeField({
    name: "date_updated",
    description: "Date de MAJ",
    priority: 100,
  })
  public date_updated!: Date;
}
