import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '@solidev/data';
import { CiqualAlimBase } from '../ciqual-alim.base';
import { CiqualAlim } from '../ciqual-alim';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-ciqual-alim-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './ciqual-alim-display.component.pug',
  styleUrls: ['./ciqual-alim-display.component.sass'],
})
export class CiqualAlimDisplayComponent implements OnInit {
  @Input() public ciqualalim?: CiqualAlimBase | CiqualAlim;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<CiqualAlimBase | CiqualAlim>;

  constructor() {}

  ngOnInit(): void {}
}
