
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-lifecycle-step-create.component.pug',
  styleUrls: ['./tarif-lifecycle-step-create.component.sass']
})
export class TarifLifecycleStepCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
