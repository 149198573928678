
<form [formGroup]="createForm" (submit)="save()">
  <div class="row my-2">
    <data-dispedit class="col" [model]="obj" field="permission" mode="form" [form]="createForm">Permission</data-dispedit>
    <ng-container *ngIf="!orderprocess">
      <data-dispedit class="col" [model]="obj" field="target" mode="form" [form]="createForm" [collection]="orderprocessstorage$" *ngIf="!orderprocessstorage">Lien process/depot/plateforme</data-dispedit>
    </ng-container>
    <data-dispedit class="col" [model]="obj" field="user" mode="form" [form]="createForm" [collection]="users$">Utilisateur</data-dispedit>
    <data-dispedit class="col" [model]="obj" field="group" mode="form" [form]="createForm" [collection]="groups$">Groupe</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>