import { ArticleBase } from "./article.base";
import {
  booleanField,
  computedField,
  detailsField,
  integerField,
} from "@solidev/data";
import { ImageDisplay } from "../../documents/image/image.base";
import { CalibreBase } from "../meta/calibre/calibre.base";
import { ProductionBase } from "../meta/production/production.base";
import { AllergenBase } from "../meta/allergen/allergen.base";
import { CategoryBase } from "../meta/category/category.base";
import { FamilyBase } from "../family/family.base";
import { Label } from "../label/label";
import {
  BrandBase,
  OriginBase,
  PackagingBase,
  ParticularityBase,
  PresentationBase,
  ProcessBase,
  RegionBase,
  TreatmentBase,
  UsageBase,
} from "../meta/public_api";
import { ProviderBase } from "../../structures/provider/provider.base";
import { CiqualAlim } from "../ciqual-alim/ciqual-alim";
import { IocodeDetails } from "../../lvadgio/iocode/iocode.base";
import { ProviderDocument } from "../../structures/provider-document/provider-document";
import { ArticleDocument } from "../article-document/article-document";

export class Article extends ArticleBase {
  static override readonly __name: string = "Article";
  @detailsField({
    model: ImageDisplay,
    readonly: true,
    description: "Image article (spécifique)",
    priority: 990,
  })
  public logo_details?: ImageDisplay;

  @detailsField({
    model: ImageDisplay,
    readonly: true,
    description: "Image article",
    priority: 995,
  })
  public clogo_details?: ImageDisplay;

  @detailsField({
    model: CalibreBase,
    readonly: true,
    description: "Calibre",
    priority: 830,
  })
  public calibre_details?: CalibreBase;

  @detailsField({
    model: ProductionBase,
    readonly: true,
    description: "Production",
    priority: 560,
  })
  public production_details?: ProductionBase;

  @detailsField({
    model: AllergenBase,
    readonly: true,
    description: "Allergènes",
    many: true,
    priority: 510,
  })
  public allergenes_details?: AllergenBase[];

  @detailsField({
    model: CategoryBase,
    readonly: true,
    description: "Catégorie",
    priority: 820,
  })
  public categorie_details?: CategoryBase;

  @detailsField({
    model: FamilyBase,
    readonly: true,
    description: "Famille",
    priority: 930,
  })
  public family_details?: FamilyBase;
  @detailsField({
    model: Label,
    readonly: true,
    description: "Labels et certifications",
    many: true,
    priority: 890,
  })
  public egalim_details?: Label[];
  @detailsField({
    model: BrandBase,
    readonly: true,
    description: "Marque",
    priority: 710,
  })
  public marque_details?: BrandBase;
  @detailsField({
    model: OriginBase,
    readonly: true,
    description: "Origine",
    priority: 880,
  })
  public origine_details?: OriginBase;
  @detailsField({
    model: ParticularityBase,
    readonly: true,
    description: "Particularité",
    priority: 550,
  })
  public particularite_details?: ParticularityBase;
  @detailsField({
    model: PresentationBase,
    readonly: true,
    description: "Présentation",
    priority: 540,
  })
  public presentation_details?: PresentationBase;
  @detailsField({
    model: ProviderBase,
    readonly: true,
    description: "Fournisseur",
    priority: 720,
  })
  public fournisseur_details?: ProviderBase;
  @detailsField({
    model: ProcessBase,
    readonly: true,
    description: "Process",
    priority: 530,
  })
  public process_details?: ProcessBase;
  @detailsField({
    model: TreatmentBase,
    readonly: true,
    description: "Traitement",
    priority: 520,
  })
  public traitement_details?: TreatmentBase;
  @detailsField({
    model: RegionBase,
    readonly: true,
    description: "Région",
    priority: 850,
  })
  public region_details?: RegionBase;
  @detailsField({
    model: PackagingBase,
    readonly: true,
    description: "Conditionnement colisage",
    priority: 790,
  })
  public colisage_cond_details?: PackagingBase;
  @detailsField({
    model: PackagingBase,
    readonly: true,
    description: "Conditionnement uci",
    priority: 750,
  })
  public uci_cond_details?: PackagingBase;

  @detailsField({
    description: "Lien .io",
    name: "iocode",
    priority: 800,
    readonly: true,
  })
  public iocode?: IocodeDetails;

  @detailsField({
    model: UsageBase,
    readonly: true,
    description: "Usage",
    priority: 610,
  })
  public usage_details?: UsageBase;
  @booleanField({
    name: "in_tariftype",
    readonly: true,
    required: false,
    description: "Dans tarif ?",
    priority: 220,
  })
  public in_tariftype?: boolean;
  @booleanField({
    name: "in_eamapping",
    readonly: true,
    required: false,
    description: "Dans mapping tarif ?",
    priority: 210,
  })
  public in_eamapping?: boolean;
  @detailsField({
    name: "ciqual_alim_details",
    model: CiqualAlim,
    readonly: true,
    priority: 500,
  })
  public ciqual_alim_details?: CiqualAlim;

  @integerField({
    name: "similar_count",
    readonly: true,
    description: "Nombre d'articles similaires",
    priority: 200,
  })
  public similar_count?: number;

  @integerField({
    name: "score",
    readonly: true,
    description: "Match score",
    priority: 200,
  })
  public score?: number;

  @integerField({
    name: "in_op",
    readonly: true,
    description: "Dans l'offre",
    priority: -1,
  })
  public in_op?: number;

  @detailsField({
    name: "providerdocuments_details",
    readonly: true,
    many: true,
    model: ProviderDocument,
    description: "Documents fournisseur",
  })
  public providerdocuments_details?: ProviderDocument[];

  @detailsField({
    name: "articledocuments_details",
    readonly: true,
    many: true,
    model: ArticleDocument,
    description: "Documents article",
  })
  public articledocuments_details?: ArticleDocument[];

  @computedField({
    name: "gamme",
    description: "Gamme",
  })
  public override get gamme(): string | undefined {
    if (this.family_details && this.family_details.tree_path.length > 0) {
      return this.family_details.tree_path[0].name;
    }
    return "?";
  }
}
