import { Component, signal } from "@angular/core";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { Offertype } from "../../../../models/offers/offertype/offertype";
import { OffertypeTextType } from "../../../../models/offers/offertype-text-type/offertype-text-type";
import { OFFERTYPE_ROUTES } from "../../offertypes/detail/menu";
import { BaseDetailViewComponent } from "../../../../components/baseview/basedetailview.component";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { OffertypeNavComponent } from "../../offertypes/detail/_nav/offertype-nav.component";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { OffertypeTextCreateComponent } from "../../../../models/offers/offertype-text/offertype-text-create/offertype-text-create.component";
import { OffertypeTextListComponent } from "../../../../models/offers/offertype-text/offertype-text-list/offertype-text-list.component";
import { map, Observable, Subject } from "rxjs";
import { OffertypeText } from "../../../../models/offers/offertype-text/offertype-text";
import { RouterLink } from "@angular/router";
import { OffertypeTextTypeManageComponent } from "../../../../models/offers/offertype-text-type/offertype-text-type-manage/offertype-text-type-manage.component";

export interface OffertypeTextTypeViewParams extends BaseRouteParams {
  ot_menu?: OFFERTYPE_ROUTES;
  offertype_text_types_list_route?: RouteConfigItem;
  offertype_text_detail_route?: RouteConfigItem;
}

export interface OffertypeTextTypeViewData extends OffertypeTextTypeViewParams {
  offertype?: Offertype;
  offertypetexttype: OffertypeTextType;
}

@Component({
  selector: "lvadg-offertype-text-type-view",
  standalone: true,
  imports: [
    HeaderActionComponent,
    OffertypeNavComponent,
    CommonModule,
    RouterLink,
    IconComponent,
    OffertypeTextCreateComponent,
    OffertypeTextListComponent,
    OffertypeTextTypeManageComponent,
  ],
  templateUrl: "./offertype-text-type-view.component.pug",
  styleUrl: "./offertype-text-type-view.component.sass",
})
export class OffertypeTextTypeViewComponent extends BaseDetailViewComponent<
  OffertypeTextTypeViewData,
  OffertypeTextType
> {
  public add = signal(false);
  public reload$ = new Subject<void | boolean>();
  public offertypeTextFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = [];
  public offertype_text_detail_route?: Link<OffertypeText>;
  public offertype_text_types_list_route?: Link<Offertype>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offertypeTextFilter$ = this.data$.pipe(
      map((data) => {
        return { offertype_text_type: data.offertypetexttype?.id };
      }),
    );
  }

  public override setRouteData(data: OffertypeTextTypeViewData) {
    super.setRouteData(data);
    if (data.offertype_text_detail_route) {
      this.offertype_text_detail_route = new Link(
        data.offertype_text_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertype_text_types_list_route) {
      this.offertype_text_types_list_route = new Link(
        data.offertype_text_types_list_route,
        data,
        this._router,
      );
    }
  }
}
