
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action title="Modes de production" level="h1" ri="catalog" [subtitle]="data.production.code">
    <ng-container actions>
      <data-safe-delete [model]="data.production" (deleted)="deleted()" display="button"><span class="ms-2">Supprimer le mode de production</span></data-safe-delete>
    </ng-container>
  </lvadg-header-action>
  <div class="card">
    <div class="card-header">Édition du mode de production</div>
    <div class="card-body">
      <lvadg-production-manage [production]="data.production"></lvadg-production-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">Articles associés</div>
    <lvadg-article-list [name]="data.route.name+'-articles'" [detail_route]="article_detail_route" [filter]="metaFilter$" [default_fields]="article_list_fields" [select]="false"></lvadg-article-list>
  </div>
</ng-container>