
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { UsergrouplinkService} from "./usergrouplink.service";
import { Usergrouplink } from "./usergrouplink";

@Injectable({
  providedIn: 'root'
})
export class UsergrouplinkResolver  {
  constructor(private _rs: UsergrouplinkService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Usergrouplink> {
    return this._rs.fetch(+route.params["usergrouplinkId"])
  }
}
