import { RoutesConfig } from '@solidev/data';
import { Folder, folderTreeRoutes, RS } from '@vivalya/lvadg';

export const ADMIN_DOCUMENTS_ROUTES_NAMES = ['documents', 'documents_folders', 'documents_folders_folder'] as const;
export const ADMIN_DOCUMENTS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_DOCUMENTS_ROUTES_NAMES)[number]> = {
  documents: {
    params: [],
    route: () => ['/', RS.documents],
    name: 'documents',
  },
  documents_folders: {
    params: [],
    route: () => ['/', RS.documents, RS.folders],
    name: 'documents_folders',
  },
  documents_folders_folder: {
    params: ['folder'],
    route: (df: Folder) => ['/', RS.documents, RS.folders].concat(folderTreeRoutes(df)),
    name: 'documents_folders_folder',
  },
};
