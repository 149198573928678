
<ng-container *ngIf="data$|async; let data">
  <lvadg-storage-nav [storage]="data.storage" [routes]="data.s_menu"></lvadg-storage-nav>
  <lvadg-header-action [title]="data.storage._display" level="h1" ri="storage" subtitle="Relations">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="createRelation=!createRelation">
        <lvadg-icon ri="add">Ajouter une relation</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createRelation">
    <div class="card-header">
      <lvadg-icon ri="relation">Nouvelle relation</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-relation-create [storage]="data.storage" (created)="createRelation=false; reload$.next(true)" (cancelled)="createRelation=false"></lvadg-relation-create>
    </div>
  </div>
  <lvadg-relation-list [name]="data.route.name" [keep]="false" [default_fields]="default_fields" [detail_route]="relation_detail_route" [producer_detail_route]="producer_detail_route" [storage_detail_route]="storage_detail_route" [client_detail_route]="client_detail_route" [provider_detail_route]="provider_detail_route" [resto_detail_route]="resto_detail_route" [reload]="reload$" [actions]="['delete']" [filter]="storageFilter$"></lvadg-relation-list>
</ng-container>