import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { TariftypeStoragePermissionManageComponent } from '../../../../models/tarifs/tariftype-storage-permission/tariftype-storage-permission-manage/tariftype-storage-permission-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TariftypeStoragePermission } from '../../../../models/tarifs/tariftype-storage-permission/tariftype-storage-permission';
import { BaseRouteParams } from '@solidev/data';

export interface TariftypeStoragePermissionDetailViewParams extends BaseRouteParams {}

export interface TariftypeStoragePermissionDetailViewData extends TariftypeStoragePermissionDetailViewParams {
  tariftypestoragepermission: TariftypeStoragePermission;
}

@Component({
  selector: 'lvadg-tariftype-storage-permission-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TariftypeStoragePermissionManageComponent],
  templateUrl: './tariftype-storage-permission-detail-view.component.pug',
  styleUrls: ['./tariftype-storage-permission-detail-view.component.sass'],
})
export class TariftypeStoragePermissionDetailViewComponent extends BaseDetailViewComponent<
  TariftypeStoragePermissionDetailViewData,
  TariftypeStoragePermission
> {
  public override setRouteData(data: TariftypeStoragePermissionDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
