import { Component } from "@angular/core";
import {
  BaseRouteParams,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import {
  ArticleDocument
} from "../../../../../models/catalog/article-document/article-document";
import {
  ArticleDocumentManageComponent
} from "../../../../../models/catalog/article-document/article-document-manage/article-document-manage.component";

export type ArticleDocumentDetailViewParams = BaseRouteParams;

export interface ArticleDocumentDetailViewData
  extends ArticleDocumentDetailViewParams {
  articledocument: ArticleDocument;
}

@Component({
  selector: "lvadg-article-document-detail-view",
  standalone: true,
  imports: [
    ArticleDocumentManageComponent,
    CommonModule,
    HeaderActionComponent,
    SafeDeleteComponent,
    IconComponent,
    PChoicePipe,
  ],
  templateUrl: "./article-document-detail-view.component.pug",
  styleUrl: "./article-document-detail-view.component.sass",
})
export class ArticleDocumentDetailViewComponent extends BaseDetailViewComponent<
  ArticleDocumentDetailViewData,
  ArticleDocument
> {
  public override setRouteData(data: ArticleDocumentDetailViewData) {
    super.setRouteData(data);
  }
}
