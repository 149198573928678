import {
  charField,
  foreignKeyField,
  manyToManyField,
  primaryField,
} from "@solidev/data";
import { BaseFile } from "../../documents/basefile";

export enum ARTICLEDOCUMENT_TYPES {
  DROIT_IMAGE = "DIM",
  FICHE_TECHNIQUE = "FTC",
  LABEL_ARTICLE = "LPR",
  CERTIFICATION = "CER",
  LABEL_STRUCTURE = "LST",
  INCONNU = "UNK",
}

export enum ARTICLEDOCUMENT_STATUSES {
  VALID = "OK",
  OK_NO_UPDATE_EXP_15D = "PRE1",
  OK_NO_UPDATE_EXP_1M = "PRE2",
  FUTUR = "FUT",
  ARCHIVED_NO_UPDATE = "ARCN",
  ARCHIVED_WITH_UPDATE = "ARCM",
  INVALID = "KO",
}

export class ArticleDocumentBase extends BaseFile {
  static override readonly __name: string = "ArticleDocumentBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @foreignKeyField({
    name: "label",
    description: "ID Label",
    related: "Label",
    priority: -1,
  })
  public label!: number;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: ARTICLEDOCUMENT_TYPES.DROIT_IMAGE,
    maxLength: 4,
    choices: [
      { value: ARTICLEDOCUMENT_TYPES.DROIT_IMAGE, desc: "Droit image" },
      {
        value: ARTICLEDOCUMENT_TYPES.FICHE_TECHNIQUE,
        desc: "Fiche technique",
      },
      { value: ARTICLEDOCUMENT_TYPES.LABEL_ARTICLE, desc: "Label article" },
      { value: ARTICLEDOCUMENT_TYPES.CERTIFICATION, desc: "Certification" },
      {
        value: ARTICLEDOCUMENT_TYPES.LABEL_STRUCTURE,
        desc: "Label structure",
      },
      { value: ARTICLEDOCUMENT_TYPES.INCONNU, desc: "Inconnu" },
    ],
    priority: 900,
  })
  public type: ARTICLEDOCUMENT_TYPES = ARTICLEDOCUMENT_TYPES.DROIT_IMAGE;

  @charField({
    name: "status",
    description: "État",
    defaultValue: ARTICLEDOCUMENT_STATUSES.VALID,
    maxLength: 4,
    choices: [
      { value: ARTICLEDOCUMENT_STATUSES.VALID, desc: "Valide" },
      { value: ARTICLEDOCUMENT_STATUSES.INVALID, desc: "Invalide" },
      { value: ARTICLEDOCUMENT_STATUSES.FUTUR, desc: "Futur" },
      {
        value: ARTICLEDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE,
        desc: "Archivé sans mise à jour",
      },
      {
        value: ARTICLEDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE,
        desc: "Archivé avec mise à jour",
      },
      {
        value: ARTICLEDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D,
        desc: "Valide sans mise à jour, expire dans -15 jours",
      },
      {
        value: ARTICLEDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M,
        desc: "Valide sans mise à jour expire dans -1 mois",
      },
    ],
    priority: 150,
  })
  public status: ARTICLEDOCUMENT_STATUSES = ARTICLEDOCUMENT_STATUSES.VALID;

  @manyToManyField({
    name: "articles",
    description: "ID Articles",
    defaultValue: [],
    related: "Article",
    priority: -1,
  })
  public articles: number[] = [];

  public get is_valid(): boolean {
    return (
      this.status === ARTICLEDOCUMENT_STATUSES.VALID ||
      this.status === ARTICLEDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D ||
      this.status === ARTICLEDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M
    );
  }
}
