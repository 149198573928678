
<button class="btn btn-outline-primary w-100" (click)="saveParams()"><i class="bi bi-save me-2"></i>Enregistrer le modèle de correspondance</button>
<div class="text-info">
  <ng-content select=".mapping_help"></ng-content>
</div>
<nav class="nav nav-tabs nav-fill nav-justified mb-2">
  <li class="nav-item" *ngFor="let t of xlsxData; let ti=index"><a class="nav-link text-uppercase" [class.active]="ti===xlsxSheetNumber" (click)="setSheet(t)" role="button">Feuille : {{t.name}}</a></li>
</nav>
<form [formGroup]="form" *ngIf="form">
  <div class="table-responsive">
    <table class="table table-sm table-hover table-striped-columns table-bordered table-minw" *ngIf="xlsxData[xlsxSheetNumber]; let sheet">
      <tbody>
        <ng-container *ngFor="let l of sheet.lines; let i=index">
          <tr class="table-success" *ngIf="i===xlsxHeaderPosition">
            <td class="ops"></td>
            <td *ngFor="let c of l; let cn=index">{{params.columns?.[cn]}}</td>
          </tr>
          <tr class="table-dark" *ngIf="i===xlsxHeaderPosition &amp;&amp; desc_mandatory">
            <th>{{desc_mandatory.title}}</th>
            <td *ngFor="let c of l; let cn=index">
              <div class="form-check form-switch nobsvalid">
                <input class="form-check-input nobsvalid" type="checkbox" [formControl]="form.controls['cols'].at(cn)!.controls['mandatory']" [attr.aria-label]="'Obligatoire colonne '+(cn+1)">
                <label class="form-check-label">{{desc_mandatory.title}}</label>
              </div>
            </td>
          </tr>
          <tr class="table-dark" *ngIf="i===xlsxHeaderPosition &amp;&amp; desc_keep.enabled">
            <th>{{desc_keep.title}}</th>
            <td *ngFor="let c of l; let cn=index">
              <div class="form-check form-switch nobsvalid">
                <input class="form-check-input nobsvalid" type="checkbox" [formControl]="form.controls['cols'].at(cn)!.controls['keep']" [attr.aria-label]="'Conservation colonne '+(cn+1)">
                <label class="form-check-label">{{desc_keep.title}}</label>
              </div>
            </td>
          </tr>
          <tr class="table-dark" *ngIf="i===xlsxHeaderPosition &amp;&amp; desc_match.enabled">
            <th>{{desc_match.title}}</th>
            <td *ngFor="let c of l; let cn=index">
              <select class="form-select form-select-sm nobsvalid" [formControl]="form.controls['cols'].at(cn)!.controls['match']" [attr.aria-label]="'Match colonne '+(cn+1)">
                <option [ngValue]="null">-------</option>
                <option *ngFor="let m of dest_match_columns" [value]="m.field">{{m.desc}}</option>
              </select>
            </td>
          </tr>
          <tr class="table-dark" *ngIf="i===xlsxHeaderPosition &amp;&amp; desc_update.enabled">
            <th>{{desc_update.title}}</th>
            <td *ngFor="let c of l; let cn=index">
              <select class="form-select form-select-sm nobsvalid" [formControl]="form.controls['cols'].at(cn)!.controls['update']" [attr.aria-label]="'Update colonne '+(cn+1)">
                <option [ngValue]="null">-------</option>
                <option *ngFor="let m of dest_update_columns" [value]="m.field">{{m.desc}}</option>
              </select>
            </td>
          </tr>
          <tr class="table-dark" *ngIf="i===xlsxHeaderPosition &amp;&amp; desc_create.enabled">
            <th>{{desc_create.title}}</th>
            <td *ngFor="let c of l; let cn=index">
              <select class="form-select form-select-sm nobsvalid" [formControl]="form.controls['cols'].at(cn)!.controls['create']" [attr.aria-label]="'Create colonne '+(cn+1)">
                <option [ngValue]="null">-------</option>
                <option *ngFor="let m of dest_create_columns" [value]="m.field">{{m.desc}}</option>
              </select>
            </td>
          </tr>
          <tr class="table-secondary" *ngIf="i===xlsxHeaderPosition">
            <td colspan="9999"></td>
          </tr>
          <tr [class.table-success]="i===xlsxHeaderPosition" [class.table-secondary]="i&lt;xlsxHeaderPosition">
            <td class="ops"><i class="bi bi-bullseye px-2" role="button" [attr.aria-label]="'En-tête ligne '+(i+1)" (click)="setHeader(i)"></i></td>
            <td *ngFor="let c of l">{{c}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</form>