export * from "./article/detail/article-view.params";
export * from "./article/detail/manage/article-manage-view.component";
export * from "./article/detail/eamappings/article-eamappings-view.component";
export * from "./article/detail/quality/article-quality-view.component";
export * from "./article/detail/display/article-display-view.component";
export * from "./article/detail/prices/article-prices-view.component";
export * from "./article/detail/tarifs/article-tarifs-view.component";
export * from "./article/detail/provider/article-provider-view.component";
export * from "./article/detail/providerdocuments/article-providerdocuments-view.component";
export * from "./article/detail/articledocuments/article-articledocuments-view.component";
export * from "./article/documents/list/article-document-list-view.component";
export * from "./article/documents/detail/article-document-detail-view.component";

export * from "./article/list/article-list-view.component";
export * from "./article/detail/menu";
export * from "./article/detail/article.routes";
// ---------------------------------------------------------------------------------
// META VIEWS
// particularity
export * from "./meta/particularity/list/particularity-list-view.component.params";
export * from "./meta/particularity/list/particularity-list-view.component";
export * from "./meta/particularity/detail/particularity-detail-view.component.params";
export * from "./meta/particularity/detail/particularity-detail-view.component";
// allergen
export * from "./meta/allergen/list/allergen-list-view.component.params";
export * from "./meta/allergen/list/allergen-list-view.component";
export * from "./meta/allergen/detail/allergen-detail-view.component.params";
export * from "./meta/allergen/detail/allergen-detail-view.component";
// brand
export * from "./meta/brand/list/brand-list-view.component.params";
export * from "./meta/brand/list/brand-list-view.component";
export * from "./meta/brand/detail/brand-detail-view.component.params";
export * from "./meta/brand/detail/brand-detail-view.component";
// calibre
export * from "./meta/calibre/list/calibre-list-view.component.params";
export * from "./meta/calibre/list/calibre-list-view.component";
export * from "./meta/calibre/detail/calibre-detail-view.component.params";
export * from "./meta/calibre/detail/calibre-detail-view.component";
// category
export * from "./meta/category/list/category-list-view.component.params";
export * from "./meta/category/list/category-list-view.component";
export * from "./meta/category/detail/category-detail-view.component.params";
export * from "./meta/category/detail/category-detail-view.component";
// origin
export * from "./meta/origin/list/origin-list-view.component.params";
export * from "./meta/origin/list/origin-list-view.component";
export * from "./meta/origin/detail/origin-detail-view.component.params";
export * from "./meta/origin/detail/origin-detail-view.component";
// packaging
export * from "./meta/packaging/list/packaging-list-view.component.params";
export * from "./meta/packaging/list/packaging-list-view.component";
export * from "./meta/packaging/detail/packaging-detail-view.component.params";
export * from "./meta/packaging/detail/packaging-detail-view.component";
// presentation
export * from "./meta/presentation/list/presentation-list-view.component.params";
export * from "./meta/presentation/list/presentation-list-view.component";
export * from "./meta/presentation/detail/presentation-detail-view.component.params";
export * from "./meta/presentation/detail/presentation-detail-view.component";
// process
export * from "./meta/process/list/process-list-view.component.params";
export * from "./meta/process/list/process-list-view.component";
export * from "./meta/process/detail/process-detail-view.component.params";
export * from "./meta/process/detail/process-detail-view.component";
// production
export * from "./meta/production/list/production-list-view.component.params";
export * from "./meta/production/list/production-list-view.component";
export * from "./meta/production/detail/production-detail-view.component.params";
export * from "./meta/production/detail/production-detail-view.component";
// region
export * from "./meta/region/list/region-list-view.component.params";
export * from "./meta/region/list/region-list-view.component";
export * from "./meta/region/detail/region-detail-view.component.params";
export * from "./meta/region/detail/region-detail-view.component";
// treatment
export * from "./meta/treatment/list/treatment-list-view.component.params";
export * from "./meta/treatment/list/treatment-list-view.component";
export * from "./meta/treatment/detail/treatment-detail-view.component.params";
export * from "./meta/treatment/detail/treatment-detail-view.component";
// usage
export * from "./meta/usage/list/usage-list-view.component.params";
export * from "./meta/usage/list/usage-list-view.component";
export * from "./meta/usage/detail/usage-detail-view.component.params";
export * from "./meta/usage/detail/usage-detail-view.component";

// ---------------------------------------------------------------------------------
// PRODUCT VIEWS
// product
export * from "./product/list/product-list-view.component";
export * from "./product/detail/menu";
export * from "./product/detail/product.routes";
export * from "./product/detail/display/product-display-view.component";
export * from "./product/detail/articles/product-articles-view.component";
export * from "./product/detail/documents/product-documents-view.component";
export * from "./product/detail/offers/product-offers-view.component";
