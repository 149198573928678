import { inject, Injectable } from "@angular/core";
import { Document } from "./document";
import { OpLog } from "../../../components/utils/oplog/oplog";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";

export interface DocumentActionResult<D> {
  instance: Document;
  data?: D;
  log?: OpLog;
  message?: string;
  success: boolean;
}

interface DocumentAddToFolderActionParams {
  action: "add_to_folder";
  folder_id: number;
}

interface DocumentRemoveFromFolderActionParams {
  action: "remove_from_folder";
  folder_id: number;
}

export type DocumentActionParams =
  | DocumentAddToFolderActionParams
  | DocumentRemoveFromFolderActionParams;

@Injectable({
  providedIn: "root",
})
export class DocumentActionService {
  private _msgs = inject(DataMessageService);

  public async action(
    document: Document,
    action: DocumentAddToFolderActionParams,
    messages?: Record<string, string>,
  ): Promise<DocumentActionResult<unknown>>;
  public async action(
    document: Document,
    action: DocumentRemoveFromFolderActionParams,
    messages?: Record<string, string>,
  ): Promise<DocumentActionResult<unknown>>;
  public async action<R>(
    document: Document,
    action: DocumentActionParams,
    messages: Record<string, string> = {},
  ): Promise<DocumentActionResult<R>> {
    try {
      const res = await firstValueFrom(
        document.action<Document, DocumentActionResult<R>>("POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res as DocumentActionResult<R>;
    } catch (error) {
      const { title, message, context } = getErrorContext(error, messages);
      this._msgs.error(title, message, context);
      return {
        instance: document,
        message: message,
        success: false,
      } as DocumentActionResult<R>;
    }
  }
}
