
<div class="row g-3" *ngIf="tariftype">
  <div class="col-xl-4">
    <div class="card h-100">
      <div class="card-header">Paramétrage du tarif</div>
      <div class="card-body">
        <dl class="row">
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="false" field="id">ID modèle</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="code">Code</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="status">Etat</data-dispedit>
        </dl>
        <dl class="row mt-1">
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="type">Type de modèle</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" field="client" mode="dd" [collection]="clients$" [editable]="adminEditable">Client</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" field="region" mode="dd" [collection]="regions$" [editable]="adminEditable">Région</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" field="member" mode="dd" [collection]="members$" [editable]="adminEditable">Adhérent</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" field="storage" mode="dd" [collection]="storages$" [editable]="adminEditable">Dépôt</data-dispedit>
        </dl>
        <dl class="row mt-1">
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="zone">Zone</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="segment">Segment</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="lifecycle">Cycle de vie</data-dispedit>
        </dl>
        <dl class="row mt-1">
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="storages_mode">Mode dépôts</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" [editable]="adminEditable" field="regions_mode">Mode régions</data-dispedit>
        </dl>
        <dl class="row mt-1">
          <data-dispedit class="col-12" [model]="tariftype" mode="dd" field="name" [editable]="adminEditable">Nom du modèle</data-dispedit>
          <data-dispedit class="col-12" [model]="tariftype" mode="dd" field="name_template" [editable]="adminEditable">Template de nom</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" field="asscom" [collection]="users$" [editable]="adminEditable">Ass. Comm.</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" field="respcom" [collection]="users$" [editable]="adminEditable">Resp. Comm.</data-dispedit>
          <data-dispedit class="col-4" [model]="tariftype" mode="dd" field="pilotes" [collection]="users$" [editable]="adminEditable">Pilote(s) tarifaire</data-dispedit>
        </dl>
        <dl class="row mt-3" *ngIf="adminEditable">
          <data-flags class="col-12" [model]="tariftype">Gestion des flags</data-flags>
        </dl>
        <dl class="row mt-3" *ngIf="adminEditable">
          <data-dispedit class="col-12" [model]="tariftype" mode="dd" field="internal_notes" [editable]="adminEditable">Notes internes</data-dispedit>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-xl-4">
    <div class="card h-100">
      <div class="card-header">Calendrier du tarif / exports</div>
      <div class="card-body">
        <data-dispedit class="col-12" [model]="tariftype" field="base_interval" mode="dd" [editable]="adminEditable">Intervalle de base</data-dispedit>
        <h4>Règles de récurrence</h4>
        <lvadg-rrule-editor (save)="onRRuleSave($event)" [rule]="tariftype.rrule" [editable]="adminEditable"></lvadg-rrule-editor>
        <h4>Dates de contrôle</h4>
        <p class="text-info">En heures avant le début de la date de validité du tarif</p>
        <dl class="row">
          <div class="col">
            <data-dispedit [model]="tariftype" field="delta_prepared" mode="dd" [editable]="adminEditable">Limite de préparation</data-dispedit>
          </div>
          <div class="col">
            <data-dispedit [model]="tariftype" field="delta_validated" mode="dd" [editable]="adminEditable">Limite de validation</data-dispedit>
          </div>
          <div class="col">
            <data-dispedit [model]="tariftype" field="delta_published" mode="dd" [editable]="adminEditable">Limite de publication</data-dispedit>
          </div>
        </dl>
        <h4>Paramètres export</h4>
        <dl class="row">
          <data-dispedit class="col-6" [model]="tariftype" field="export_template" mode="dd" [editable]="adminEditable">Template export spécifique</data-dispedit>
          <data-dispedit class="col-6" [model]="tariftype" field="client_data_link" mode="dd" [editable]="adminEditable">Import / export des XLSX client ?</data-dispedit>
          <data-dispedit class="col-6" [model]="tariftype" field="price_precision" mode="dd" [editable]="adminEditable">Précision des prix (de 0 à 4 décimales)</data-dispedit>
          <data-dispedit class="col-6" [model]="tariftype" field="export_inactives" mode="dd" [editable]="adminEditable">Exporter les articles inactifs ?</data-dispedit>
        </dl>
        <h4>Colonnes personnalisées</h4>
        <dl class="row">
          <data-dispedit class="col-6" [model]="tariftype" field="custom_column_1_title" mode="dd" [editable]="adminEditable">Titre colonne personnalisée 1</data-dispedit>
          <data-dispedit class="col-6" [model]="tariftype" field="custom_column_1_op" mode="dd" [editable]="adminEditable">Opération colonne personnalisée 1</data-dispedit>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-xl-4">
    <div class="card h-100">
      <div class="card-header">Paramètres</div>
      <div class="card-body">
        <ng-container *ngIf="tariftype">
          <dl class="row">
            <data-dispedit class="col-4" [model]="tariftype" field="decondi_status" mode="dd" [editable]="adminEditable">Déconditionnement</data-dispedit>
            <data-dispedit class="col-4" [model]="tariftype" field="decondi_kg" mode="dd" *ngIf="tariftype.decondi_status=='CTR'" [editable]="adminEditable">Tarif décondi €/kg</data-dispedit>
            <data-dispedit class="col-4" [model]="tariftype" field="decondi_pce" mode="dd" *ngIf="tariftype.decondi_status=='CTR'" [editable]="adminEditable">Tarif décondi €/pce</data-dispedit>
          </dl>
          <dl class="row">
            <data-dispedit class="col-6" [model]="tariftype" field="price_type" mode="dd" [editable]="adminEditable">Type de prix</data-dispedit>
            <data-dispedit class="col-6" [model]="tariftype" field="ref_price_op" mode="dd" [editable]="adminEditable">Opération prix de référence</data-dispedit>
            <data-dispedit class="col-6" [model]="tariftype" field="brut_to_net_op" mode="dd" [editable]="adminEditable">Opération brut vers net</data-dispedit>
            <data-dispedit class="col-6" [model]="tariftype" field="brut_to_net2_op" mode="dd" [editable]="adminEditable">Opération brut vers net2</data-dispedit>
          </dl>
          <dl class="row">
            <data-richedit class="col-12" [model]="tariftype" field="message" mode="dd" [editable]="adminEditable">Message du modèle de tarif</data-richedit>
            <data-richedit class="col-12" [model]="tariftype" field="contract_message" mode="dd" [editable]="adminEditable">Message de conditions de livraison</data-richedit>
          </dl>
        </ng-container>
      </div>
    </div>
  </div>
</div>