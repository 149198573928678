import { Component } from '@angular/core';
import { GeodepartementDetailViewData } from './geodepartement-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import {
  GeodepartementManageComponent
} from "../../../../models/locations/geodepartement/geodepartement-manage/geodepartement-manage.component";
import {BaseDetailViewComponent} from "../../../../components/baseview/basedetailview.component";
import {Geodepartement} from "../../../../models/locations/geodepartement/geodepartement";

@Component({
  selector: 'lvadg-geodepartement-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, GeodepartementManageComponent],
  templateUrl: './geodepartement-detail-view.component.pug',
  styleUrls: ['./geodepartement-detail-view.component.sass'],
})
export class GeodepartementDetailViewComponent extends BaseDetailViewComponent<
  GeodepartementDetailViewData,
  Geodepartement
> {
  public override setRouteData(data: GeodepartementDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
