
<ng-container *ngIf="provider(); let p">
  <div class="row align-items-center">
    <div [class.col-8]="p.logo_details" [class.col-12]="!p.logo_details">
      <div class="description fs-5">
        <div [innerHTML]="p.content_html"></div>
      </div>
    </div>
    <div class="col-4 d-flex justify-content-center" *ngIf="p.logo_details">
      <div class="logo">
        <lvadg-image-display [image]="p.logo_details" mode="medium" [hover]="true" [imgClasses]="'rounded'"></lvadg-image-display>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-around flex-wrap">
    <div class="slabels my-3">
      <ng-container *ngFor="let label of p.slabels_details">
        <lvadg-label-display [label]="label" mode="large" [hover]="false" [thumbnail]="false"></lvadg-label-display>
        <ng-container *ngFor="let document of p.documents_details">
          <ng-container *ngIf="document.label===label.id">
            <lvadg-provider-document-display class="mx-1" [providerdocument]="document" mode="details" *ngIf="document.is_valid &amp;&amp; !document.articles"></lvadg-provider-document-display>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="documents my-2" *ngIf="haveDocuments()">
      <ng-container *ngFor="let document of p.documents_details">
        <lvadg-provider-document-display class="mx-1" [providerdocument]="document" mode="details" *ngIf="document.is_valid &amp;&amp; !document.label &amp;&amp; !document.articles"></lvadg-provider-document-display>
      </ng-container>
    </div>
  </div>
  <!--div.address-->
  <!--  address {{ p.location_details?.address }} {{ p.location_details?.postcode }} {{ p.location_details?.city }}-->
  <!--div.relations.my-3-->
  <!--  ng-container(*ngIf="p.storage_relations_details")-->
  <!--    lvadg-relation-listmap([model]="p",-->
  <!--      [relations]="p.storage_relations_details")-->
  <!--div.productslvadg-product-list([p]="p")
  -->
  <div class="videos my-2" *ngIf="haveVideos()">
    <ng-container *ngFor="let image of p.images_details">
      <div *ngIf="image.video">
        <lvadg-video-display [video]="image" [containerClass]="'ratio ratio-16x9'"></lvadg-video-display>
      </div>
    </ng-container>
  </div>
  <div class="photos mb-5" *ngIf="havePhotos()">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let image of carouselImages()"><a class="d-flex align-items-center justify-content-center bg-secondary-subtle" [href]="image.file" target="_blank">
          <lvadg-image-display class="mx-auto my-3" [image]="image" mode="large" [hover]="false" [thumbnail]="false"></lvadg-image-display></a>
        <!--.bg-primary-subtle.ratio.ratio-16x9-->
        <!--  img.object-fit-cover.rounded.img-thumbnail([src]="image.file", [alt]="image.title", [title]="image.title")-->
      </ng-template>
    </ngb-carousel>
  </div>
</ng-container>