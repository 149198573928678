
<div class="text-muted">
  <div class="d-flex justify-content-between flex-row"><span>Sous-dossiers</span>
    <ng-container *ngIf="folder$|async; let currentFolder"><a *ngIf="folder_detail_route" [routerLink]="['..']"><i class="bi bi-arrow-up-circle me-1"></i>Dossier parent</a></ng-container>
  </div>
</div>
<div class="folders">
  <div class="folder" *ngFor="let f of subfolders$|async" [class.text-muted]="f.status !== FS.ONLINE"><a class="display" [routerLink]="folder_detail_route?.route({folder: f})" role="button"><i class="bi bi-folder" *ngIf="!f.image"></i>
      <lvadg-image-display *ngIf="f.image" [image]="f.image_details" mode="icon" [hover]="false"></lvadg-image-display><span><i class="bi bi-eye-slash me-1" *ngIf="f.status !== FS.ONLINE"></i>{{f.name}}</span></a>
    <div class="edit" *ngIf="editable &amp;&amp; f.have_level(FUL.owner)" role="button" (click)="folder_click.emit(f)" (dblclick)="folder_dblclick.emit(f)"><i class="bi bi-pencil-square ms-2"></i> Modifier</div>
  </div>
</div>
<div class="text-muted mt-3">Documents</div>
<div class="documents" *ngIf="folder$|async; let currentFolder">
  <div class="document" *ngFor="let d of documents$|async" role="button" [class.text-muted]="d.status !== DS.ONLINE"><a class="display" [href]="d.file" target="_blank" *ngIf="currentFolder.have_level(FUL.sadmin)"><i class="bi bi-file" *ngIf="!d.image"></i>
      <lvadg-image-display *ngIf="d.image" [image]="d.image_details" mode="icon" [hover]="true"></lvadg-image-display><span><i class="bi bi-eye-slash me-1" *ngIf="d.status !== DS.ONLINE"></i>{{d.title}}</span></a><a class="display" role="button" (click)="download(d)" *ngIf="!currentFolder.have_level(FUL.sadmin)"><i class="bi bi-file" *ngIf="!d.image"></i>
      <lvadg-image-display *ngIf="d.image" [image]="d.image_details" mode="icon" [hover]="true"></lvadg-image-display><span><i class="bi bi-eye-slash me-1" *ngIf="d.status !== DS.ONLINE"></i>{{d.title}}</span></a>
    <div class="edit" *ngIf="editable &amp;&amp; currentFolder.have_level(FUL.owner)" (click)="document_click.emit(d)" (dblclick)="document_dblclick.emit(d)"><i class="bi bi-pencil-square ms-2"></i> Modifier</div>
  </div>
</div>