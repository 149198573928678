import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Earticle } from '../earticle';
import { EarticleService } from '../earticle.service';
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { Client } from '../../../structures/client/client';
import { Storage } from '../../../structures/storage/storage';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { MemberDisplayComponent } from '../../../structures/member/member-display/member-display.component';
import { Member } from '../../../structures/member/member';
import { EacollectionDisplayComponent } from '../../eacollection/eacollection-display/eacollection-display.component';
import { Provider } from '../../../structures/provider/provider';
import { Eacollection } from '../../eacollection/eacollection';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { ClientService } from '../../../structures/client/client.service';

@Component({
  selector: 'lvadg-earticle-list',
  standalone: true,
  templateUrl: './earticle-list.component.pug',
  styleUrls: ['./earticle-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    ClientDisplayComponent,
    StorageDisplayComponent,
    MemberDisplayComponent,
    EacollectionDisplayComponent,
    FlagsDisplayComponent,
    PChoicePipe,
  ],
})
export class EarticleListComponent extends ModellistComponent<Earticle> {
  @Input() public client_detail_route?: Link<Client>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public member_detail_route?: Link<Member>;
  @Input() public provider_detail_route?: Link<Provider>;
  @Input() public collection_detail_route?: Link<Eacollection>;

  constructor(coll: EarticleService, list: ModelListService, ofc: NgbOffcanvas, private clients$: ClientService) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'type', 'clients'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Filtrer par type',
          model: Earticle,
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'clients',
          name: 'clients',
          label: 'Recherche par client(s)',
          collection: this.clients$,
          filter: { fields: ['id', 'cvva', 'name'].join(',') },
        }),
        new ModelListTextFilter({
          field: 'labels',
          name: 'labels',
          label: 'Labels (texte)',
        }),
        new ModelListTextFilter({
          field: 'origine',
          name: 'origine',
          label: 'Origine (texte)',
        }),
      ],
    };
  }
}
