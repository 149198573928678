import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { CdataKey } from '../cdata-key';
import { CdataKeyService } from '../cdata-key.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';

@Component({
  selector: 'lvadg-cdata-key-list',
  standalone: true,
  templateUrl: './cdata-key-list.component.pug',
  styleUrls: ['./cdata-key-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FlagsDisplayComponent,
    PChoicePipe,
    SafeDeleteComponent,
  ],
})
export class CdataKeyListComponent extends ModellistComponent<CdataKey> {
  constructor(
    coll: CdataKeyService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
