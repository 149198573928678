import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  inject,
  input,
  signal,
} from "@angular/core";
import { FilterDefaults } from "@solidev/data";
import {
  PRODUCT_STAT_TYPES,
  ProductStatsService,
  StatsResponse,
} from "../product-stats.service";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../../../components/utils/icon/icon.component";

@Component({
  selector: "lvadg-product-stats",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./product-stats.component.pug",
  styleUrl: "./product-stats.component.sass",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductStatsComponent {
  @HostBinding("class") public readonly class = "card h-100";
  public display = input("card");
  public title = input("Statistiques produits");
  public filter = input<FilterDefaults>({});
  public types = input<PRODUCT_STAT_TYPES[]>([
    PRODUCT_STAT_TYPES.BY_FAMILY,
    PRODUCT_STAT_TYPES.BY_LABEL,
    PRODUCT_STAT_TYPES.COUNT,
  ]);
  public stats_titles = input({
    [PRODUCT_STAT_TYPES.BY_FAMILY]: "Par famille",
    [PRODUCT_STAT_TYPES.BY_LABEL]: "Par label",
    [PRODUCT_STAT_TYPES.COUNT]: "Total",
  });
  public stats_display = input({
    [PRODUCT_STAT_TYPES.BY_FAMILY]: "default",
    [PRODUCT_STAT_TYPES.BY_LABEL]: "default",
    [PRODUCT_STAT_TYPES.COUNT]: "default",
  });

  public readonly PST = PRODUCT_STAT_TYPES;

  public stats = signal<StatsResponse>({} as StatsResponse);
  private _stats = inject(ProductStatsService);

  constructor() {
    effect(async () => {
      const stats = await this._stats.get_stats(this.types(), this.filter());
      console.log(stats);
      this.stats.set(stats);
    });
  }
}
