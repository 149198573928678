
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OfferNotificationDestination } from "./offer-notification-destination";

@Injectable({
  providedIn: 'root'
})
export class OfferNotificationDestinationService extends Collection<OfferNotificationDestination> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offernotificationdestinations", OfferNotificationDestination);
  }
}
