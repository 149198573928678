import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum TARIFNOTIFICATION_PRIORITY {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
}

export enum TARIFNOTIFICATION_STATUS {
  PREPARE = 'PRP',
  PENDING = 'PEN',
  SENDING = 'SND',
  SENT = 'SNT',
}

export enum TN_TYPE {
  NEGOCIATION = 'NEG',
  PUBLICATION = 'PUB',
  UPDATE = 'UPD',
  ALARM = 'ALA',
}

export class TarifNotificationBase extends DataModel {
  static override readonly __name: string = 'TarifNotificationBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 400,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 350,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'author',
    description: 'ID Auteur',
    related: 'User',
    priority: -1,
  })
  public author!: number;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
    priority: -1,
  })
  public tariftype!: number;

  @foreignKeyField({
    name: 'tarif',
    description: 'ID Tarif',
    related: 'Tarif',
    priority: -1,
  })
  public tarif!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: TN_TYPE.PUBLICATION,
    maxLength: 3,
    choices: [
      {
        value: TN_TYPE.NEGOCIATION,
        desc: 'Negociation',
      },
      {
        value: TN_TYPE.PUBLICATION,
        desc: 'Publication initiale',
      },
      {
        value: TN_TYPE.UPDATE,
        desc: 'Mise à jour',
      },
      {
        value: TN_TYPE.ALARM,
        desc: 'Alarme',
      },
    ],
    priority: 250,
  })
  public type: TN_TYPE = TN_TYPE.PUBLICATION;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 300,
    priority: 150,
  })
  public title!: string;

  @textField({
    name: 'message',
    description: 'Message',
    maxLength: 30000,
    priority: 140,
  })
  public message!: string;
  @charField({
    name: 'priority',
    description: 'Priorité',
    defaultValue: TARIFNOTIFICATION_PRIORITY.P2,
    maxLength: 2,
    choices: [
      { value: TARIFNOTIFICATION_PRIORITY.P1, desc: 'Urgent' },
      { value: TARIFNOTIFICATION_PRIORITY.P2, desc: 'Normal' },
      { value: TARIFNOTIFICATION_PRIORITY.P3, desc: 'Basse' },
    ],
  })
  public priority: TARIFNOTIFICATION_PRIORITY = TARIFNOTIFICATION_PRIORITY.P2;
  @charField({
    name: 'status',
    description: 'État',
    defaultValue: TARIFNOTIFICATION_STATUS.PENDING,
    maxLength: 3,
    choices: [
      { value: TARIFNOTIFICATION_STATUS.PREPARE, desc: 'En préparation' },
      {
        value: TARIFNOTIFICATION_STATUS.PENDING,
        desc: "En attente d'envoi",
      },
      { value: TARIFNOTIFICATION_STATUS.SENDING, desc: "En cours d'envoi" },
      { value: TARIFNOTIFICATION_STATUS.SENT, desc: 'Envoyée' },
    ],
    priority: 200,
  })
  public status: TARIFNOTIFICATION_STATUS = TARIFNOTIFICATION_STATUS.PENDING;
  @charField({
    name: 'file',
    description: 'Fichier',
    maxLength: 200,
    priority: 100,
  })
  public file!: string;
  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;

  public get message_txt(): string {
    const parser = new DOMParser();
    const floatingElement = parser.parseFromString(
      `<div id="content">${this.message}</div>`,
      'text/html'
    );
    return floatingElement.getElementById('content')!.innerText || '---';
  }
}
