import { Component, computed, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { VideoDisplayComponent } from "../../../documents/image/video-display/video-display.component";
import { ProducerDocumentDisplayComponent } from "../../producer-document/producer-document-display/producer-document-display.component";
import { RelationListmapComponent } from "../../relation/relation-listmap/relation-listmap.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { Producer } from "../../producer/producer";
import { Link } from "@solidev/data";
import { Storage } from "../storage";
import { IMAGE_USAGE } from "../../../documents/image/image.base";
import { CDATAKEY_DEST } from "../../../cdata/cdata-key/cdata-key.base";
import { CdatasDisplayComponent } from "../../../cdata/cdata/cdatas-display/cdatas-display.component";
import {
  ProductStatsComponent
} from "../../../catalog/product/product-stats/product-stats.component";
import {
  ProducerStatsComponent
} from "../../producer/producer-stats/producer-stats.component";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES
} from "../../../catalog/product/product.base";

@Component({
  selector: "lvadg-storage-details",
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    IconComponent,
    ImageDisplayComponent,
    VideoDisplayComponent,
    ProducerDocumentDisplayComponent,
    RelationListmapComponent,
    LabelDisplayComponent,
    NgbCarouselModule,
    CdatasDisplayComponent,
    TodoComponent,
    ProductStatsComponent,
    ProducerStatsComponent
  ],
  templateUrl: "./storage-details.component.pug",
  styleUrl: "./storage-details.component.sass",
})
export class StorageDetailsComponent {
  public storage = input.required<Storage>();
  public producer_detail_route = input<Link<Producer>>();
  public storage_detail_route = input<Link<Storage>>();
  protected readonly LOCAL_FILTERS = LOCAL_FILTERS;
  protected readonly PRODUCT_TYPES = PRODUCT_TYPES;
  // public haveDocuments = computed(() => {
  //   const p = this.storage();
  //   return (
  //     p.documents_details &&
  //     p.documents_details.filter((d) => d.is_valid).length > 0
  //   );
  // });
  public haveVideos = computed(() => {
    const p = this.storage();
    return (
      p.images_details && p.images_details.filter((d) => !!d.video).length > 0
    );
  });
  public carouselImages = computed(() => {
    const p = this.storage();
    return (
      p.images_details &&
      p.images_details.filter(
        (d) => !d.video && d.have_usages(IMAGE_USAGE.GDISP),
      )
    );
  });
  public havePhotos = computed(() => {
    const p = this.carouselImages();
    return p ? p.length > 0 : false;
  });
  protected readonly CDATAKEY_DEST = CDATAKEY_DEST;
}
