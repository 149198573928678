// georegion
export * from './georegion/detail/georegion-detail-view.component';
export * from './georegion/detail/georegion-detail-view.params';
export * from './georegion/list/georegion-list-view.component';
export * from './georegion/list/georegion-list-view.params';

// genericregion
export * from './genericregion/list/genericregion-list-view.component';
export * from './genericregion/list/genericregion-list-view.params';
export * from './genericregion/detail/genericregion-detail-view.component';
export * from './genericregion/detail/genericregion-detail-view.params';

// geocommune
export * from './geocommune/detail/geocommune-detail-view.component';
export * from './geocommune/detail/geocommune-detail-view.params';
export * from './geocommune/list/geocommune-list-view.component';
export * from './geocommune/list/geocommune-list-view.params';

// geocountry
export * from './geocountry/detail/geocountry-detail-view.component';
export * from './geocountry/detail/geocountry-detail-view.params';
export * from './geocountry/list/geocountry-list-view.component';
export * from './geocountry/list/geocountry-list-view.params';

// geodepartement
export * from './geodepartement/detail/geodepartement-detail-view.component';
export * from './geodepartement/detail/geodepartement-detail-view.params';
export * from './geodepartement/list/geodepartement-list-view.component';
export * from './geodepartement/list/geodepartement-list-view.params';

// location
export * from './location/detail/location-detail-view.component';
export * from './location/detail/location-detail-view.params';
export * from './location/list/location-list-view.component';
export * from './location/list/location-list-view.params';

// distance
export * from './distance/detail/distance-detail-view.component';
export * from './distance/detail/distance-detail-view.params';
export * from './distance/list/distance-list-view.component';
export * from './distance/list/distance-list-view.params';
