import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../../../../../models/structures/member/member';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { MEMBER_ROUTES } from '../menu';
import { MemberNavComponent } from '../_nav/member-nav.component';

export interface MemberDisplayViewParams extends BaseRouteParams {
  m_menu: MEMBER_ROUTES;
}

export interface MemberDisplayViewData extends MemberDisplayViewParams {
  member: Member;
}

@Component({
  selector: 'lvadg-member-display-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, MemberNavComponent],
  templateUrl: './member-display-view.component.pug',
  styleUrls: ['./member-display-view.component.sass'],
})
export class MemberDisplayViewComponent extends BaseDetailViewComponent<MemberDisplayViewData, Member> {
  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: MemberDisplayViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }
}
