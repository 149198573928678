import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { TarifRelationManageComponent } from '../../../../models/tarifs/tarif-relation/tarif-relation-manage/tarif-relation-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TarifRelation } from '../../../../models/tarifs/tarif-relation/tarif-relation';
import { BaseRouteParams } from '@solidev/data';

export interface TarifRelationDetailViewParams extends BaseRouteParams {}

export interface TarifRelationDetailViewData extends TarifRelationDetailViewParams {
  tarifrelation: TarifRelation;
}

@Component({
  selector: 'lvadg-tarif-relation-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TarifRelationManageComponent],
  templateUrl: './tarif-relation-detail-view.component.pug',
  styleUrls: ['./tarif-relation-detail-view.component.sass'],
})
export class TarifRelationDetailViewComponent extends BaseDetailViewComponent<
  TarifRelationDetailViewData,
  TarifRelation
> {
  public override setRouteData(data: TarifRelationDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
