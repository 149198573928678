import { charField, DataModel, datetimeField, detailsField, foreignKeyField, primaryField } from '@solidev/data';
import { XlsxParamFieldDesc } from '../../../components/utils/xlsx-params/xlsx-params';

export enum TARIFTYPEMETADATA_TYPE {
  EACLIENT_IMPORT_PARAMS = 'EAI', // "Paramètres d'importation articles client"
  EACLIENT_READ_PARAMS = 'EAR', // "Paramètres de lecture articles client"
  EAPRICE_READ_PARAMS = 'EAP', // "Paramètres de lectures prix client"
  EAPRICE_WRITE_PARAMS = 'EAW', // "Paramètres d'écriture prix/dispo client"
}

export interface Metadata_EAI {
  sheet: string;
  skip: number;
  columns: string[];
  keep_columns: string[];
  mandatory_columns: string[];
  mapping_match: Record<string, string>;
  mapping_create: Record<string, string>;
  mapping_update: Record<string, string>;
}

export interface MetadataDesc {
  desc_mandatory: XlsxParamFieldDesc;
  desc_keep: XlsxParamFieldDesc;
  desc_match: XlsxParamFieldDesc;
  desc_update: XlsxParamFieldDesc;
  desc_create: XlsxParamFieldDesc;
  dest_match_columns?: { field: string; desc: string }[];
  dest_update_columns?: { field: string; desc: string }[];
  dest_create_columns?: { field: string; desc: string }[];
}

export interface Metadata_EAR {
  sheet: string;
  skip: number;
  columns: string[];
  mandatory_columns: string[];
  mapping_match: Record<string, string>;
  mapping_read: Record<string, string>;
}

export interface Metadata_EAP {
  sheet: string;
  skip: number;
  columns: string[];
  mandatory_columns: string[];
  mapping_match: Record<string, string>;
  mapping_read: Record<string, string>;
}

export interface Metadata_EAW {
  sheet: string;
  skip: number;
  columns: string[];
  mandatory_columns: string[];
  mapping_match: Record<string, string>;
  mapping_update: Record<string, string>;
}

export class TariftypeMetadataBase extends DataModel {
  static override readonly __name: string = 'TariftypeMetadataBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
    priority: -1,
  })
  public tariftype!: number;

  @foreignKeyField({
    name: 'document',
    description: 'ID Document',
    related: 'TarifTypeDocument',
    priority: -1,
  })
  public document!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: TARIFTYPEMETADATA_TYPE.EACLIENT_IMPORT_PARAMS,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: TARIFTYPEMETADATA_TYPE.EACLIENT_IMPORT_PARAMS,
        desc: "Paramètres d'importation articles client",
      },
      {
        value: TARIFTYPEMETADATA_TYPE.EACLIENT_READ_PARAMS,
        desc: 'Paramètres de lecture articles client',
      },
      {
        value: TARIFTYPEMETADATA_TYPE.EAPRICE_READ_PARAMS,
        desc: 'Paramètres de lectures prix client',
      },
      {
        value: TARIFTYPEMETADATA_TYPE.EAPRICE_WRITE_PARAMS,
        desc: "Paramètres d'écriture prix/dispo client",
      },
    ],
    priority: 500,
  })
  public type: TARIFTYPEMETADATA_TYPE = TARIFTYPEMETADATA_TYPE.EACLIENT_IMPORT_PARAMS;

  @charField({
    name: 'name',
    description: 'Nom / description',
    maxLength: 200,
    priority: 600,
  })
  public name!: string;

  @detailsField({
    name: 'data',
    description: 'Données',
    defaultValue: {},
    priority: 300,
  })
  public data?: Metadata_EAI | Metadata_EAR | Metadata_EAP | Metadata_EAW;
}
