import { charField, detailsField, foreignKeyField, integerField, manyToManyField, primaryField } from '@solidev/data';
import { StructureBase } from '../structure.base';

export enum ClientStatus {
  ACTIVE = 'OK',
  ACTIVE_NEW = 'NEW',
  INACTIVE = 'KO',
}

export enum ClientType {
  GRAND_COMPTE = 'GC',
  COMMERCE_DETAIL = 'CD',
  COMMERCE_GROS = 'CG',
  SUPERMARCHE = 'HS',
  METIER_BOUCHE = 'MB',
  RESTO_COLLECTIVE = 'RC',
  NO_CATEGORY = 'OT',
}

export class ClientBase extends StructureBase {
  static override readonly __name: string = 'ClientBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: ClientStatus.ACTIVE,
    required: true,
    maxLength: 4,
    choices: [
      {
        value: ClientStatus.ACTIVE,
        desc: 'Actif',
      },
      {
        value: ClientStatus.ACTIVE_NEW,
        desc: 'Actif - nouveau',
      },
      {
        value: ClientStatus.INACTIVE,
        desc: 'Non actif',
      },
    ],
  })
  public status: ClientStatus = ClientStatus.ACTIVE;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: ClientType.GRAND_COMPTE,
    maxLength: 4,
    choices: [
      {
        value: ClientType.GRAND_COMPTE,
        desc: 'Grand compte',
      },
      {
        value: ClientType.COMMERCE_DETAIL,
        desc: 'Commerce de détail',
      },
      {
        value: ClientType.COMMERCE_GROS,
        desc: 'Commerce de gros',
      },
      {
        value: ClientType.SUPERMARCHE,
        desc: 'Hyper/supermarché',
      },
      {
        value: ClientType.METIER_BOUCHE,
        desc: 'Métiers de bouche',
      },
      {
        value: ClientType.RESTO_COLLECTIVE,
        desc: 'Restauration collective',
      },
      {
        value: ClientType.NO_CATEGORY,
        desc: 'Non catégorisé',
      },
    ],
  })
  public type: ClientType = ClientType.GRAND_COMPTE;

  @foreignKeyField({ name: 'parent', description: 'parent', related: 'Client' })
  public parent!: number;

  @manyToManyField({
    name: 'children',
    description: 'IDS sous familles',
    defaultValue: [],
    related: 'Family',
  })
  public children: number[] = [];

  @integerField({
    name: 'tree_depth',
    description: 'Niveau hiérarchie',
    readonly: true,
  })
  public tree_depth: number = 0;

  @detailsField({
    name: 'tree_path',
    description: 'Hiérarchie',
    readonly: true,
  })
  public tree_path: { name: string; id: number }[] = [];

  public get full_path(): string {
    return this.tree_path.map((p) => p.name).join(' : ');
  }

  public get _display(): string {
    return `[${this.cvva}] ${this.name}`;
  }

  public have_level(level: string): boolean {
    return true;
  }
}
