
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessstoragePermission } from '../orderprocessstorage-permission';
@Component({
  selector: 'lvadg-orderprocessstorage-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './orderprocessstorage-permission-manage.component.pug',
  styleUrls: ['./orderprocessstorage-permission-manage.component.sass']
})
export class OrderprocessstoragePermissionManageComponent implements OnInit {
  @Input() public orderprocessstoragepermission?: OrderprocessstoragePermission;

  constructor() { }

  ngOnInit(): void {
  }

}
