import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { RouterLink } from '@angular/router';
import { FilterDefaults, Link } from '@solidev/data';
import { Observable } from 'rxjs';
import { Producer } from '../producer';
import { ProducerService } from '../producer.service';

@Component({
  selector: 'lvadg-producer-shortlist',
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent],
  templateUrl: './producer-shortlist.component.pug',
  styleUrls: ['./producer-shortlist.component.sass'],
})
export class ProducerShortlistComponent implements OnInit {
  @HostBinding('class') public readonly class = 'card h-100';
  @Input() public display = 'card' as const;
  @Input() public title = 'Producteurs';
  @Input() public detail_route?: Link<Producer>;
  @Input() public filter?: FilterDefaults = {};
  @Input() public order: string = '-created';
  @Input() public limit: number = 20;
  public producers$!: Observable<Producer[]>;

  constructor(private _producers: ProducerService) {}

  public ngOnInit(): void {
    this.producers$ = this._producers.list({
      page_size: this.limit,
      ordering: this.order,
      fields: ['id', 'name', 'created', 'location_details'].join(','),
      ...this.filter,
    });
  }
}
