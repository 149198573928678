
<ng-container *ngIf="step">
  <h4 class="my-2">{{step.title$||'Négociation client'}}</h4>
  <p class="text-info" [innerHTML]="step.message$"></p>
  <button class="btn btn-sm btn-outline-primary w-100 p-2 my-2" (click)="action({action: 'export[nego]'})" *ngIf="!running['export[nego]']"><i class="bi bi-file-spreadsheet me-2"></i>Export excel négo (envoi manuel)</button>
  <button class="btn btn-sm btn-outline-primary w-100 p-2 my-2" *ngIf="running['export[nego]']" [disabled]="true"><i class="spinner-border spinner-border-sm me-2"></i>Export excel négo (envoi manuel)</button>
  <lvadg-tarif-message-contacts [tarif]="tarif" mode="nego" [step]="step" (contacts)="setContacts($event)"></lvadg-tarif-message-contacts>
  <lvadg-tarif-message-composer [tarif]="tarif" mode="nego" [step]="step" (message)="setMessage($event)"></lvadg-tarif-message-composer>
  <button class="btn btn-outline-success w-100 p-3 my-2" (click)="action({action: 'negosend', message: messageData!, contacts: contactData! })" [disabled]="!messageData || !contactData" *ngIf="!running['negosend']"><i class="bi bi-envelope me-2"></i>Envoyer le message et le fichier de négociation</button>
  <button class="btn btn-outline-success w-100 p-3 my-2" [disabled]="true" *ngIf="running['negosend']"><span class="spinner-border spinner-border-sm"></span>Envoi en cours, merci de patienter</button>
  <button class="btn btn-outline-warning w-100 p-1 my-2" (click)="action({action: 'next'})"><i class="bi bi-check-lg me-2"></i>Message déjà envoyé ou pas de message, passer à l'étape suivante</button>
</ng-container>