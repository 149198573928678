
<ng-container *ngIf="data$|async; let data">
  <lvadg-offertype-nav [routes]="data.ot_menu" [offertype]="data.offertype" *ngIf="data.ot_menu"></lvadg-offertype-nav>
  <lvadg-header-action [title]="data.offertype._display" level="h1" subtitle="Permissions du modèle" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-primary" (click)="create_permission=!create_permission">
        <lvadg-icon ri="plus">Ajouter une permission</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="create_permission">
    <div class="card-header">
      <lvadg-icon ri="plus">Ajouter une permission</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-offertype-permission-create [offertype]="data.offertype" (created)="reload$.next(true); create_permission=false" (cancelled)="create_permission=false"></lvadg-offertype-permission-create>
    </div>
  </div>
  <lvadg-offertype-permission-list [name]="data.route.name" [filter]="offertypeFilter$" [reload]="reload$" [actions]="['delete']" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route"></lvadg-offertype-permission-list>
</ng-container>