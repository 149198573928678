
<ng-container *ngIf="storage">
  <ng-container *ngIf="mode==='line'">
    <ng-container *ngIf="storage.cvva &amp;&amp; showCodes"><code class="clip" (click)="clip()">{{storage.cvva}}</code>&nbsp;</ng-container><a class="text-decoration-none" [routerLink]="detail_route?.route({storage})">
      <ng-container *ngIf="detail_route"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container>{{storage.name}}</a>
    <div class="small" *ngIf="show_location">
      <lvadg-location-display [location]="storage.location_details" mode="short"></lvadg-location-display>
    </div>
  </ng-container>
  <ng-container *ngIf="mode==='linewloc'"><code class="me-2" *ngIf="storage.cvva &amp;&amp; showCodes">{{storage.cvva}}</code><a class="text-decoration-none" [routerLink]="detail_route?.route({storage})">
      <ng-container *ngIf="detail_route"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container>{{storage.name}}</a>
    <div class="small">
      <lvadg-location-display [location]="storage.location_details" mode="short"></lvadg-location-display>
    </div>
  </ng-container>
</ng-container>