import { Component } from '@angular/core';

import { FilterDefaults, Link } from '@solidev/data';
import { Presentation } from '../../../../../models/catalog/meta/presentation/presentation';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { META_ARTICLE_LIST_FIELDS } from '../../constants';
import { map, Observable } from 'rxjs';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { PresentationDetailViewData } from './presentation-detail-view.component.params';
import { PresentationManageComponent } from '../../../../../models/catalog/meta/presentation/presentation-manage/presentation-manage.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';

@Component({
  selector: 'lvadg-presentation-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, PresentationManageComponent, ArticleListComponent],
  templateUrl: './presentation-detail-view.component.pug',
  styleUrls: ['./presentation-detail-view.component.sass'],
})
export class PresentationDetailViewComponent extends BaseDetailViewComponent<PresentationDetailViewData, Presentation> {
  public list_route!: Link<Presentation>;
  public article_detail_route!: Link<ArticleBase>;
  public article_list_fields = META_ARTICLE_LIST_FIELDS;

  public metaFilter$!: Observable<FilterDefaults>;

  public override setRouteData(data: PresentationDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.metaFilter$ = this.data$.pipe(map((d) => ({ presentations: d.presentation.id })));
  }

  deleted() {
    this.list_route.navigate();
  }
}
