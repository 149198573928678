import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  XlsxDescription,
  XlsxParamFieldDesc,
  XlsxParams,
  XlsxSheetDescription,
} from './xlsx-params';
import { DataMessageService } from '@solidev/data';

@Component({
  selector: 'lvadg-xlsx-params',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './xlsx-params.component.pug',
  styleUrls: ['./xlsx-params.component.sass'],
})
export class XlsxParamsComponent implements OnInit {
  @Input() public xlsxData!: XlsxDescription;
  @Input() public params!: XlsxParams;
  @Input() public desc_match: XlsxParamFieldDesc = {
    enabled: true,
    title: 'Mapping match',
    description: 'Champs de correspondance',
  };
  @Input() public desc_mandatory: XlsxParamFieldDesc = {
    enabled: true,
    title: 'Obligatoire ?',
    description: 'Obligatoire ?',
  };
  @Input() public desc_keep: XlsxParamFieldDesc = {
    enabled: true,
    title: 'Conserver ?',
    description: 'Conserver ?',
  };

  @Input() public desc_update: XlsxParamFieldDesc = {
    enabled: true,
    title: 'Mapping update',
    description: 'Champs de mise à jour',
  };
  @Input() public desc_create: XlsxParamFieldDesc = {
    enabled: true,
    title: 'Mapping create',
    description: 'Champs de création',
  };
  @Input() public dest_match_columns?: { field: string; desc: string }[];
  @Input() public dest_update_columns?: { field: string; desc: string }[];
  @Input() public dest_create_columns?: { field: string; desc: string }[];
  @Output() public save = new EventEmitter<XlsxParams>();
  public form?: FormGroup<{
    cols: FormArray<
      FormGroup<{
        mandatory: FormControl<boolean>;
        keep: FormControl<boolean>;
        match: FormControl<string | null>;
        update: FormControl<string | null>;
        create: FormControl<string | null>;
      }>
    >;
  }>;
  public xlsxSheetNumber = 0;
  public xlsxHeaderPosition = 0;

  constructor(private _msgs: DataMessageService) {}

  public ngOnInit() {
    if (!this.params) {
      this.params = {};
    }
    if (this.params.sheet) {
      const sheet = this.getSheet(this.params.sheet);
      if (sheet) {
        this.xlsxSheetNumber = this.xlsxData!.indexOf(sheet);
        this.params.sheet = sheet.name;
      } else {
        this.xlsxSheetNumber = 0;
        this.params.sheet = this.xlsxData[0].name;
      }
    } else {
      this.xlsxSheetNumber = 0;
      this.params.sheet = this.xlsxData[0].name;
    }

    if (this.params.skip !== undefined) {
      this.xlsxHeaderPosition = this.params.skip;
    } else {
      this.params.skip = 0;
    }

    this.setHeader(this.xlsxHeaderPosition);
    this.toForm(this.params);
    this.fromForm(this.form!.value);
  }

  setSheet(t: XlsxSheetDescription) {
    this.xlsxSheetNumber = this.xlsxData!.indexOf(t);
    this.params.sheet = t.name;
    this.setHeader(0);
  }

  public getSheet(name?: string): XlsxSheetDescription | undefined {
    if (name) {
      return this.xlsxData?.find((d) => d.name === name);
    }
    return this.xlsxData[0];
  }

  public setHeader(i: number) {
    this.xlsxHeaderPosition = i;
    this.params.columns = this.xlsxData![this.xlsxSheetNumber].lines[
      this.xlsxHeaderPosition
    ].map((v, i) => (v ? `${v}` : `COL@@${i}`));
    const oldv = this.form?.value;
    this.form = new FormGroup({
      cols: new FormArray(
        this.params.columns.map((c, i) => {
          return new FormGroup({
            mandatory: new FormControl<boolean>(false, { nonNullable: true }),
            keep: new FormControl<boolean>(false, { nonNullable: true }),
            match: new FormControl<string | null>(null),
            update: new FormControl<string | null>(null),
            create: new FormControl<string | null>(null),
          });
        })
      ),
    });
    this.form.valueChanges.subscribe((v) => {
      this.fromForm(v);
    });
  }

  public fromForm(
    v: Partial<{
      cols: Partial<{
        mandatory: boolean;
        keep: boolean;
        match: string | null;
        update: string | null;
        create: string | null;
      }>[];
    }>
  ) {
    if (v.cols) {
      this.params.skip = this.xlsxHeaderPosition;
      this.params.sheet = this.xlsxData![this.xlsxSheetNumber].name;
      this.params.mandatory_columns = v.cols
        .map((fg, cn): [boolean | undefined, number] => [fg.mandatory, cn])
        .filter((m) => !!m[0])
        .map(([m, cv]) => this.params.columns![cv]);
      this.params.keep_columns = v.cols
        .map((fg, cn): [boolean | undefined, number] => [fg.keep, cn])
        .filter((m) => !!m[0])
        .map(([m, cv]) => this.params.columns![cv]);
      this.params.mapping_match = Object.fromEntries(
        v.cols
          .map((fg, cn): [string | null | undefined, number] => [fg.match, cn])
          .filter((m) => !!m[0])
          .map(([m, cv]) => [
            this.params.columns![cv] !== 'null'
              ? this.params.columns![cv]
              : `COL@@${cv}`,
            m!,
          ])
      );
      this.params.mapping_update = Object.fromEntries(
        v.cols
          .map((fg, cn): [string | null | undefined, number] => [fg.update, cn])
          .filter((m) => !!m[0])
          .map(([m, cv]) => [
            this.params.columns![cv] !== 'null'
              ? this.params.columns![cv]
              : `COL@@${cv}`,
            m!,
          ])
      );
      this.params.mapping_create = Object.fromEntries(
        v.cols
          .map((fg, cn): [string | null | undefined, number] => [fg.create, cn])
          .filter((m) => !!m[0])
          .map(([m, cv]) => [
            this.params.columns![cv] !== 'null'
              ? this.params.columns![cv]
              : `COL@@${cv}`,
            m!,
          ])
      );
    }
  }

  public toForm(params: XlsxParams) {
    if (this.form) {
      const sheet = this.getSheet(params.sheet);
      if (params.sheet && !sheet) {
        this._msgs.error(`La feuille ${params.sheet} n'est pas présente`);
        return;
      }
      if (!sheet) {
        return;
      }
      this.setSheet(sheet);
      this.setHeader(params.skip || 0);
      let i = 0;
      for (const c of this.form.controls['cols'].controls) {
        const col = params.columns?.[i] || '';
        c.patchValue(
          {
            mandatory: params.mandatory_columns?.includes(col),
            keep: params.keep_columns?.includes(col),
            match: params.mapping_match?.[col] || null,
            update: params.mapping_update?.[col] || null,
            create: params.mapping_create?.[col] || null,
          },
          { emitEvent: false }
        );
        i += 1;
      }
    }
  }

  public saveParams() {
    if (this.form) {
      this.fromForm(this.form.value);
      this.save.emit(this.params);
    }
  }
}
