import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum GroupStatus {
  OK = 'OK',
  ARCHIVED = 'AR',
}

export class GroupBase extends DataModel {
  static override readonly __name: string = 'GroupBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @charField({ name: 'name', description: 'Nom du groupe', maxLength: 200 })
  public name!: string;

  @charField({
    name: 'slug',
    description: 'Code groupe',
    maxLength: 200,
    pattern: /^[a-z0-9:]+$/,
  })
  public slug!: string;

  @datetimeField({ name: 'datestart', description: 'Date de début' })
  public datestart!: Date;

  @datetimeField({ name: 'dateend', description: 'Date de fin' })
  public dateend!: Date;

  @foreignKeyField({
    name: 'member',
    description: 'ID Adhérent',
    related: 'structure.Member',
  })
  public member?: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'structures.Storage',
  })
  public storage?: number;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'structures.Client',
  })
  public client?: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Resto',
    related: 'structures.Resto',
  })
  public resto?: number;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Tarif',
    related: 'tarifs.Tariftype',
  })
  public tariftype?: number;

  @foreignKeyField({
    name: 'orderprocess',
    description: 'ID Process commande',
    related: 'orders.Orderprocess',
  })
  public orderprocess?: number;

  @charField({
    name: 'status',
    description: 'status',
    defaultValue: GroupStatus.OK,
    maxLength: 2,
    choices: [
      { value: GroupStatus.OK, desc: 'Actif' },
      { value: GroupStatus.ARCHIVED, desc: 'Archivé' },
    ],
  })
  public status: GroupStatus = GroupStatus.OK;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;

  public get _display(): string {
    return `[${this.slug}] ${this.name}`;
  }
}
