import { charField, datetimeField, foreignKeyField, primaryField } from '@solidev/data';
import { StructureBase } from '../structure.base';

export enum RestoType {
  RINC = 'RINC',
  RCOH = 'RCOH',
  RCOS = 'RCOS',
  RCOE = 'RCOE',
  RCOR = 'RCOR',
  RCOC = 'RCOC',
  RCMR = 'RCMR',
  RCMT = 'RCMT',
  RCMF = 'RCMF',
  RCMC = 'RCMC',
  RCMB = 'RCMB',
  UNKNOWN = '',
}

export enum RestoStatus {
  ACTIVE = 'OK',
  ACTIVE_NEW = 'NEW',
  INACTIVE = 'KO',
}

export class RestoBase extends StructureBase {
  static override readonly __name: string = 'RestoBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'status',
    description: 'Ètat',
    defaultValue: RestoStatus.ACTIVE,
    required: true,
    maxLength: 4,
    choices: [
      {
        value: RestoStatus.ACTIVE,
        desc: 'Actif',
      },
      {
        value: RestoStatus.ACTIVE_NEW,
        desc: 'Actif - nouveau',
      },
      {
        value: RestoStatus.INACTIVE,
        desc: 'Non actif',
      },
    ],
    priority: 50,
  })
  public status: RestoStatus = RestoStatus.ACTIVE;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: RestoType.RINC,
    maxLength: 4,
    choices: [
      {
        value: RestoType.RINC,
        desc: 'Type non connu',
      },
      {
        value: RestoType.RCOH,
        desc: 'Rest. collective (hospitalier)',
      },
      {
        value: RestoType.RCOS,
        desc: 'Rest. collective (scolaire)',
      },
      {
        value: RestoType.RCOE,
        desc: 'Rest. collective (entreprise)',
      },
      {
        value: RestoType.RCOR,
        desc: 'Rest. collective (maison de retraite)',
      },
      {
        value: RestoType.RCOC,
        desc: 'Rest. collective (cuisine centrale)',
      },
      {
        value: RestoType.RCMR,
        desc: 'Rest. commerciale (restaurant)',
      },
      {
        value: RestoType.RCMT,
        desc: 'Rest. commerciale (traiteur)',
      },
      {
        value: RestoType.RCMF,
        desc: 'Rest. commerciale (restauration rapide à emporter)',
      },
      {
        value: RestoType.RCMC,
        desc: 'Rest. commerciale (cafétérias)',
      },
      {
        value: RestoType.RCMB,
        desc: 'Rest. commerciale (boulangerie)',
      },
      {
        value: RestoType.UNKNOWN,
        desc: 'Non renseigné',
      },
    ],
    priority: 850,
  })
  public type: RestoType = RestoType.RINC;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @charField({
    name: 'cclient',
    description: 'Code Client',
    maxLength: 50,
    priority: 850,
  })
  public cclient!: string;

  @datetimeField({
    name: 'datestart',
    description: 'Date de début',
    priority: 500,
  })
  public datestart!: Date;

  @datetimeField({
    name: 'dateend',
    description: 'Date de fin',
    priority: 500,
  })
  public dateend!: Date;

  public get _display(): string {
    return `[${this.cvva}] ${this.name}`;
  }

  public have_level(level: string): boolean {
    return true;
  }
}
