import { Component } from '@angular/core';
import { BaseRouteParams } from '@solidev/data';
import { BASE_VIEW_IMPORTS } from '../../../../components/baseview/baseview.imports';
import { BaseViewComponent } from '../../../../components/baseview/baseview.component';

export interface TarifStatsArticleViewParams extends BaseRouteParams {}

export interface TarifStatsArticleViewData extends TarifStatsArticleViewParams {}

@Component({
  selector: 'lvadg-tarif-stats-article-view',
  standalone: true,
  imports: [...BASE_VIEW_IMPORTS],
  templateUrl: './tarif-stats-article-view.component.pug',
  styleUrls: ['./tarif-stats-article-view.component.sass'],
})
export class TarifStatsArticleViewComponent extends BaseViewComponent<TarifStatsArticleViewData> {
  public override setRouteData(data: TarifStatsArticleViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
