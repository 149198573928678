import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Relation } from '../relation';
import { firstValueFrom } from 'rxjs';
import { RelationService } from '../relation.service';
import { Producer } from '../../producer/producer';
import { ProducerService } from '../../producer/producer.service';
import { StorageService } from '../../storage/storage.service';
import { ClientService } from '../../client/client.service';
import { RestoService } from '../../resto/resto.service';
import { ProviderService } from '../../provider/provider.service';
import { RELATION_TYPE } from '../relation.base';
import { MemberService } from '../../member/member.service';
import { Storage } from '../../storage/storage';

const RELATION_NEEDS = {
  [RELATION_TYPE.PRODUCER_STORAGE]: ['producer', 'storage'],
  [RELATION_TYPE.PRODUCER_RESTO]: ['producer', 'resto'],
  [RELATION_TYPE.MEMBER_CLIENT]: ['member', 'client'],
  [RELATION_TYPE.MEMBER_RESTO]: ['member', 'resto'],
  [RELATION_TYPE.STORAGE_CLIENT]: ['storage', 'client'],
  [RELATION_TYPE.STORAGE_RESTO]: ['storage', 'resto'],
  [RELATION_TYPE.PROVIDER_PRODUCER]: ['provider', 'producer'],
};

@Component({
  selector: 'lvadg-relation-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './relation-create.component.pug',
  styleUrls: ['./relation-create.component.sass'],
})
export class RelationCreateComponent implements OnInit {
  public createForm!: FormGroup<{
    type: FormControl<RELATION_TYPE>;
    producer: FormControl<string | undefined>;
    storage: FormControl<string | undefined>;
    member: FormControl<string | undefined>;
    client: FormControl<string | undefined>;
    resto: FormControl<string | undefined>;
    provider: FormControl<string | undefined>;
  }>;
  @Input() public producer?: Producer;
  @Input() public storage?: Storage;
  @Input() public type?: RELATION_TYPE;
  @Output() public created = new EventEmitter<Relation>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: Relation;

  constructor(
    private _items: RelationService,
    public producers$: ProducerService,
    public members$: MemberService,
    public storages$: StorageService,
    public clients$: ClientService,
    public restos$: RestoService,
    public providers$: ProviderService
  ) {}

  ngOnInit(): void {
    this.item = new Relation(this._items);
    if (this.producer) {
      this.item.producer = this.producer.id;
      this.item.producer_details = this.producer;
      this.item.type = RELATION_TYPE.PRODUCER_STORAGE;
    }
    if (this.storage) {
      this.item.storage = this.storage.id;
      this.item.storage_details = this.storage;
      this.item.type = RELATION_TYPE.PRODUCER_STORAGE;
    }
    this.createForm = this.item.FC([
      'type',
      'producer',
      'storage',
      'member',
      'client',
      'resto',
      'provider',
    ]);
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }

  public isNeeded(need: string) {
    return RELATION_NEEDS[this.item.type]?.includes(need);
  }
}
