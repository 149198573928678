import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';
import { TarifNotificationBase } from '../../../../../models/tarifs/tarif-notification/tarif-notification.base';
import { UserBase } from '../../../../../models/users/user/user.base';
import { TarifNotificationStatusListComponent } from '../../../../../models/tarifs/tarif-notification-status/tarif-notification-status-list/tarif-notification-status-list.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeNotificationsLogsViewParams extends BaseRouteParams {
  tarif_detail_route: RouteConfigItem;
  tarifnotification_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeNotificationsLogsViewData extends TariftypeNotificationsLogsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-notifications-logs-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    TarifNotificationStatusListComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-notifications-logs-view.component.pug',
  styleUrls: ['./tariftype-notifications-logs-view.component.sass'],
})
export class TariftypeNotificationsLogsViewComponent extends BaseDetailViewComponent<
  TariftypeNotificationsLogsViewData,
  Tariftype
> {
  public tns_default_fields = [
    'id',
    'notification_details',
    'notification__type',
    'notification__tarif',
    'notification__priority',
    'user_details',
    'destination',
    'status',
    'updated',
    'created',
  ];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public tarif_detail_route?: Link<TarifBase>;
  public user_detail_route?: Link<UserBase>;
  public tarifnotification_detail_route?: Link<TarifNotificationBase>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeNotificationsLogsViewData) {
    super.setRouteData(data);
    if (this.tarif_detail_route) {
      this.tarif_detail_route = new Link(data.tarif_detail_route, data, this._router);
    }
    if (data.tarifnotification_detail_route) {
      this.tarifnotification_detail_route = new Link(data.tarifnotification_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }
}
