import {
  AfterViewInit,
  Component,
  ElementRef,
  input,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapData, MAPPING_BASE_STYLES, MAPPING_STYLES } from "../constants";
import { filter, Observable } from "rxjs";
import { MapContainer, MapService } from "../services/map.service";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { ImageDisplay } from "../../../documents/image/image.base";
import { LngLatBounds } from "maplibre-gl";

interface MapPopupData {
  name: string;
  logo_details: ImageDisplay;
}

/**
 * Display a simple map, with some data layers.
 */
@Component({
  selector: "lvadg-simple-map",
  standalone: true,
  imports: [CommonModule, ImageDisplayComponent],
  templateUrl: "./simple-map.component.pug",
  styleUrls: ["./simple-map.component.sass"],
})
export class SimpleMapComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Map style */
  public mapStyle = input<MAPPING_BASE_STYLES>(
    MAPPING_BASE_STYLES.OSM_LIBERTY_EU,
  );
  /** Map center */
  public position = input<[number, number]>([
    2.6390772508210603, 46.441826066304316,
  ]);
  /** Map zoom */
  public zoom = input<number>(5.24);
  /** Enable autozoom on first load ? */
  public autoZoom = input<boolean>(true);

  /** Map data (observable of MapData) */
  @Input() public data$!: Observable<MapData>;
  /** Map popup data (observable of MapPopupData) */
  public infos$?: Observable<MapPopupData>;

  @ViewChild("popup", { static: true })
  private popupContainer!: ElementRef;
  @ViewChild("map", { static: true })
  private mapContainer!: ElementRef<HTMLDivElement>;
  private _map?: MapContainer;

  constructor(private _mps: MapService) {}

  public async ngOnInit(): Promise<void> {
    // this.mapContainer.nativeElement.style.height = `${this.height}px`;
    this._map = await this._mps.create(
      "simplemap",
      this.mapContainer.nativeElement,
      {
        container: "",
        style: MAPPING_STYLES.get(this.mapStyle())
          ? MAPPING_STYLES.get(this.mapStyle())!.url
          : MAPPING_STYLES.get(MAPPING_BASE_STYLES.OSM_LIBERTY_EU)!.url, // stylesheet location
        center: this.position(), // starting position [lng, lat]
        zoom: this.zoom(), // starting zoom,
        minZoom: 3, // was 4.4
        attributionControl: false,
      },
    );
    if (this.data$) {
      this._map.setDataLayers(this.data$);
      this._map.setDataLayersPopup((data) => {
        this.infos$ = data.data;
        data.popup.setDOMContent(this.popupContainer.nativeElement);
      });
    }
    this._map.bounds$
      .pipe(filter(() => this.autoZoom()))
      .subscribe((bounds) => {
        const mb = new LngLatBounds();
        for (const b of Object.values(bounds)) {
          mb.extend(b);
        }
        this._map!.fitBounds(mb);
      });
  }

  public ngOnDestroy() {
    this._mps.destroy("simplemap");
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this._map!.checkSize();
    }, 500);
  }
}
