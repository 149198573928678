import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';

export interface OrderprocessDetailViewParams extends BaseRouteParams {
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  platform_detail_route: RouteConfigItem;
  orderprocessstorage_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  order_detail_route: RouteConfigItem;
  ordergroup_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
}

export interface OrderprocessDetailViewData extends OrderprocessDetailViewParams {
  orderprocess: Orderprocess;
}
