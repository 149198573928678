
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { FolderPermissionService} from "./folder-permission.service";
import { FolderPermission } from "./folder-permission";

@Injectable({
  providedIn: 'root'
})
export class FolderPermissionResolver  {
  constructor(private _rs: FolderPermissionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FolderPermission> {
    return this._rs.fetch(+route.params["folderpermissionId"])
  }
}
