import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';
import { CDATA_VALUE_TYPE } from '../cdata-values';

export class CdataKeyChoiceBase extends DataModel {
  static override readonly __name: string = 'CdataKeyChoiceBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @integerField({
    name: 'value_int',
    description: 'Valeur entière',
    defaultValue: 0,
    priority: -1,
  })
  public value_int: number = 0;

  @detailsField({
    name: 'value_dec',
    description: 'Valeur décimale',
    defaultValue: 0,
    priority: -1,
  })
  public value_dec: any = 0;

  @charField({
    name: 'value_stx',
    description: 'Valeur texte court',
    defaultValue: '',
    maxLength: 250,
    priority: -1,
  })
  public value_stx: string = '';

  @textField({
    name: 'value_ltx',
    description: 'Valeur texte long',
    defaultValue: '',
    priority: -1,
  })
  public value_ltx: string = '';

  @textField({
    name: 'value_rtx',
    description: 'Valeur texte riche',
    defaultValue: '',
    priority: -1,
  })
  public value_rtx: string = '';

  @dateField({
    name: 'value_dte',
    description: 'Valeur date',
    priority: -1,
  })
  public value_dte!: Date;

  @datetimeField({
    name: 'value_dth',
    description: 'Valeur date et heure',
    priority: -1,
  })
  public value_dth!: Date;

  @detailsField({
    name: 'value_json',
    description: 'Valeur JSON',
    priority: -1,
  })
  public value_json!: any;

  @foreignKeyField({
    name: 'key',
    description: 'ID Clef',
    related: 'CDataKey',
    priority: -1,
  })
  public key!: number;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 250,
    priority: 900,
  })
  public code!: string;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 250,
    priority: 800,
  })
  public name!: string;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: CDATA_VALUE_TYPE.SHORT_TEXT,
    required: true,
    maxLength: 3,
    choices: [
      { value: CDATA_VALUE_TYPE.INTEGER, desc: 'Entier' },
      { value: CDATA_VALUE_TYPE.DECIMAL, desc: 'Décimal' },
      { value: CDATA_VALUE_TYPE.SHORT_TEXT, desc: 'Texte court' },
      { value: CDATA_VALUE_TYPE.LONG_TEXT, desc: 'Texte long' },
      { value: CDATA_VALUE_TYPE.RICH_TEXT, desc: 'Texte riche' },
      { value: CDATA_VALUE_TYPE.CURRENCY, desc: 'Monétaire' },
      { value: CDATA_VALUE_TYPE.DATE, desc: 'Date' },
      { value: CDATA_VALUE_TYPE.DATETIME, desc: 'Date et heure' },
      { value: CDATA_VALUE_TYPE.JSON, desc: 'JSON' },
    ],
    priority: 700,
  })
  public type: CDATA_VALUE_TYPE = CDATA_VALUE_TYPE.SHORT_TEXT;
  @charField({
    name: 'unit',
    description: 'Unité',
    defaultValue: '',
    maxLength: 250,
    priority: 650,
  })
  public unit: string = '';

  @textField({
    name: 'description',
    description: 'Description',
    defaultValue: '',
    priority: 600,
  })
  public description: string = '';

  @integerField({
    name: 'position',
    description: 'Position',
    defaultValue: 0,
    required: true,
    priority: 500,
  })
  public position: number = 0;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;
}
