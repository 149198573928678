export const getErrorContext = (
  er: any,
  messages: Record<string, string>
): { title: string; message: string; context: any } => {
  console.error(er);
  let message: string = 'Erreur inconnue';
  if (er.error && er.error.message) {
    message = er.error.message;
  } else if (er.message) {
    message = er.message;
  }
  return {
    title: messages['error'] || "Erreur lors de l'action",
    message,
    context: er,
  };
};
