import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Collection, DataModel, UploadFile } from '@solidev/data';
import { IMAGE_USAGE_CHOICES_DICT, ImageDisplay } from '../image.base';
import { IPreparedUploadFile, MediaUploadComponent } from '../../upload/media-upload/media-upload.component';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, Observable, tap } from 'rxjs';
import { ImageDisplayComponent } from '../image-display/image-display.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { NgbModal, NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageManageComponent } from '../image-manage/image-manage.component';
import { VideoDisplayComponent } from '../video-display/video-display.component';

interface GalleryModel extends DataModel {
  images: number[];
  images_details?: ImageDisplay[];
}

@Component({
  selector: 'lvadg-gallery-manage',
  standalone: true,
  imports: [
    CommonModule,
    MediaUploadComponent,
    ImageDisplayComponent,
    ImageManageComponent,
    IconComponent,
    VideoDisplayComponent,
  ],
  templateUrl: './gallery-manage.component.pug',
  styleUrls: ['./gallery-manage.component.sass'],
})
export class GalleryManageComponent<T extends GalleryModel> implements OnInit {
  @Input() public model!: T;
  @Input() public collection!: Collection<T>;
  @Input() public endpoints: {
    set: string;
    get: string;
    update: string;
    unset: string;
  } = {
    set: 'add_image',
    get: 'get_image',
    update: 'update_image',
    unset: 'remove_image',
  };
  @ViewChild('ofc', { static: false })
  public ofcSlot?: ElementRef<HTMLElement>;
  @ViewChild('video', { static: false })
  public videoSlot?: ElementRef<HTMLElement>;
  public ofcRef?: NgbOffcanvasRef;

  public IMAGE_USAGE = IMAGE_USAGE_CHOICES_DICT;
  public images$?: Observable<ImageDisplay[] | null>;
  public image$?: Observable<ImageDisplay | null>;
  public currentImage$ = new BehaviorSubject<number | null>(null);
  @Input() public images_field: string = 'images';
  @Input() public images_details_field: string = 'images_details';
  @Output() public updated = new EventEmitter<T>();

  constructor(
    private _ofc: NgbOffcanvas,
    private _modal: NgbModal,
  ) {}

  public ngOnInit() {
    if (!this.collection && this.model) {
      this.collection = this.model._collection;
    }
    this.reload();
  }

  public uploadImage = async (model: T, file: UploadFile): Promise<IPreparedUploadFile<T>> => {
    return {
      model: model,
      url: this.collection!.getUrl(model.id, { suffix: `/${this.endpoints.set}` }),
      data: {
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        legend: '',
        credits: '',
        status: 'OK',
      },
      fieldName: 'file',
      method: 'POST',
    };
  };

  async reload() {
    this.images$ = this.collection!.fetch(this.model.id, {
      params: {
        fields: ['id', this.images_field, this.images_details_field].join(','),
      },
      // eslint-disable-next-line
    }).pipe(map((model) => (model as any)[this.images_details_field] as ImageDisplay[]) || null);
    this.image$ = combineLatest([this.images$, this.currentImage$]).pipe(
      filter(([images, current]) => !!images && !!current),
      map(([images, current]) => images!.find((i) => i.id === current) || null),
      tap((i) => {
        console.log(i);
      }),
    );
  }

  async removeImage(image: ImageDisplay) {
    await firstValueFrom(
      this.model.action('POST', 'remove_image', {
        body: {
          pk: image.id,
        },
      }),
    );
    await this.reload();
  }

  public edit(image: ImageDisplay) {
    this.currentImage$.next(image.id);
    this.ofcRef = this._ofc.open(this.ofcSlot, { position: 'bottom', panelClass: 'h-auto' });
  }

  public playVideo(image: ImageDisplay) {
    this.currentImage$.next(image.id);
    this._modal.open(this.videoSlot, { size: 'xl' });
  }
}
