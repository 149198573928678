import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Usage } from '../usage';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-usage-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './usage-manage.component.pug',
  styleUrls: ['./usage-manage.component.sass'],
})
export class UsageManageComponent {
  @Input() public usage!: Usage;

  constructor() {}
}
