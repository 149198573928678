import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Resto } from '../../../structures/resto/resto';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Contact } from '../contact';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { CONTACT_TYPES } from '../contact.base';
import { ContactService } from '../contact.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-contact-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './contact-create.component.pug',
  styleUrls: ['./contact-create.component.sass'],
})
export class ContactCreateComponent implements OnInit {
  @Input() public resto?: Resto | null;
  @Input() public type?: CONTACT_TYPES;
  @Output() public created = new EventEmitter<Contact>();
  @Output() public cancelled = new EventEmitter<void>();
  @Input() public save: boolean = true;
  public contact!: Contact;
  public formGroup = new FormGroup({
    title: new FormControl('', []),
    first_name: new FormControl('', []),
    last_name: new FormControl('', []),
    email: new FormControl('', [Validators.required, Validators.email]),
    jobname: new FormControl('', []),
    phone: new FormControl('', []),
    mobile: new FormControl('', []),
  });

  constructor(
    private _cts: ContactService,
    private _msgs: DataMessageService,
  ) {}

  public ngOnInit(): void {
    this.contact = new Contact();
  }

  public async create() {
    if (this.formGroup.invalid) {
      this._msgs.error('Le formulaire est invalide');
      return;
    }
    this.contact.fromJson(this.formGroup.value, { partial: true, check: true });
    if (this.type) {
      this.contact.type = this.type;
    }
    if (this.resto) {
      this.contact.resto = this.resto.id;
    }
    if (this.save) {
      this.contact._collection = this._cts;
      await firstValueFrom(this.contact.save());
    }

    this.created.emit(this.contact);
  }
}
