import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ProcessCreateComponent } from '../../../../../models/catalog/meta/process/process-create/process-create.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { ProcessListComponent } from '../../../../../models/catalog/meta/process/process-list/process-list.component';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Process } from '../../../../../models/catalog/meta/process/process';
import {ProcessListViewData} from "./process-list-view.component.params";

@Component({
  selector: 'lvadg-process-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    ProcessCreateComponent,
    IconComponent,
    HeaderActionComponent,
    ProcessListComponent,
  ],
  templateUrl: './process-list-view.component.pug',
  styleUrls: ['./process-list-view.component.sass'],
})
export class ProcessListViewComponent extends BaseListViewComponent<ProcessListViewData, Process> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: ProcessListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
