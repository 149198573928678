import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseRouteParams,
  DataMessageService,
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { Tariftype } from "../../../../../models/tarifs/tariftype/tariftype";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ArticleTarifTemplateBase } from "../../../../../models/tarifs/article-tarif-template/article-tarif-template.base";
import { ArticleBase } from "../../../../../models/catalog/article/article.base";
import { EarticleBase } from "../../../../../models/catalog/earticle/earticle.base";
import { firstValueFrom, map, Observable, Subject } from "rxjs";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { ArticleTarifTemplate } from "../../../../../models/tarifs/article-tarif-template/article-tarif-template";
import { ActivatedRoute, Router } from "@angular/router";
import { TARIFTYPE_USER_LEVEL } from "../../../../../models/tarifs/tariftype/tariftype.base";
import { ArticleTarifTemplateListComponent } from "../../../../../models/tarifs/article-tarif-template/article-tarif-template-list/article-tarif-template-list.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { TARIFTYPE_ROUTES } from "../menu";
import { TariftypeDetailNavComponent } from "../nav/tariftype-detail-nav.component";

export interface TariftypeArticlesViewParams extends BaseRouteParams {
  article_detail_route: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
  att_detail_route: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeArticlesViewData extends TariftypeArticlesViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: "lvadg-tariftype-articles-view",
  standalone: true,
  imports: [
    CommonModule,
    ArticleTarifTemplateListComponent,
    IconComponent,
    HeaderActionComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: "./tariftype-articles-view.component.pug",
  styleUrls: ["./tariftype-articles-view.component.sass"],
})
export class TariftypeArticlesViewComponent extends BaseDetailViewComponent<
  TariftypeArticlesViewData,
  Tariftype
> {
  public att_detail_route!: Link<ArticleTarifTemplateBase>;
  public article_detail_route?: Link<ArticleBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public TTUL = TARIFTYPE_USER_LEVEL;
  public atts_default_fields = [
    "id",
    "eaclient_details",
    "article_details",
    "article__family",
    "article__calibre",
    "article__categorie",
    "article__origine",
    "article__local",
    "article__egalim",
    "article__colisage",
    "article__uci",
    "article__udv_value",
    "article__uf",
    "article__decondi_mode",
    "status",
    "actions",
  ];

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeArticlesViewData) {
    super.setRouteData(data);
    this.att_detail_route = new Link(data.att_detail_route, data, this._router);
    if (data.article_detail_route) {
      this.article_detail_route = new Link(
        data.article_detail_route,
        data,
        this._router,
      );
    }
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(
        data.earticle_detail_route,
        data,
        this._router,
      );
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }

  public async attAction(
    tt: Tariftype,
    action: ModelListAction<ArticleTarifTemplate>,
  ) {
    if (action.model) {
      try {
        const res = await firstValueFrom(
          action.model.action<
            ArticleTarifTemplate,
            {
              message: string;
              att: ArticleTarifTemplate;
            }
          >("POST", "action", {
            body: {
              action: action.action,
            },
          }),
        );
        this.reload$.next();
        this._msgs.success(res.message);
      } catch (e) {
        console.error(e);
        this._msgs.error(
          "Erreur lors de l'action",
          `${(e as any).error?.message || (e as any).message || ""}`,
          e,
        );
      }
    }
  }
}
