import { ArticleTarifTemplateRelationBase } from "./article-tarif-template-relation.base";
import { computedField, detailsField } from "@solidev/data";
import { ArticleTarifTemplate } from "../article-tarif-template/article-tarif-template";
import { TariftypeRelation } from "../tariftype-relation/tariftype-relation";
import { TariftypeBase } from "../tariftype/tariftype.base";

export class ArticleTarifTemplateRelation extends ArticleTarifTemplateRelationBase {
  static override readonly __name: string = "ArticleTarifTemplateRelation";

  @detailsField({
    model: TariftypeRelation,
    readonly: true,
    description: "Relation",
  })
  public ttrel_details?: TariftypeRelation;
  @detailsField({
    model: ArticleTarifTemplate,
    readonly: true,
    description: "Article source",
  })
  public src_details?: ArticleTarifTemplate;
  @detailsField({
    model: ArticleTarifTemplate,
    readonly: true,
    description: "Article cible",
  })
  public dest_details?: ArticleTarifTemplate;

  @computedField({
    readonly: true,
    description: "Modèle de tarif source",
  })
  public get ttrel_src_details(): TariftypeBase | undefined {
    return this.ttrel_details?.src_details;
  }

  @computedField({
    readonly: true,
    description: "Modèle de tarif cible",
  })
  public get ttrel_dest_details(): TariftypeBase | undefined {
    return this.ttrel_details?.dest_details;
  }

  @computedField({
    readonly: true,
    description: "UF source",
  })
  public get src_details_uf(): string | undefined {
    return this.src_details?.article_details?.udf_unit;
  }

  @computedField({
    readonly: true,
    description: "UF cible",
  })
  public get dest_details_uf(): string | undefined {
    return this.dest_details?.article_details?.udf_unit;
  }
}
