import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ArticleTarifTemplateRelationService } from './article-tarif-template-relation.service';
import { ArticleTarifTemplateRelation } from './article-tarif-template-relation';

@Injectable({
  providedIn: 'root',
})
export class ArticleTarifTemplateRelationResolver {
  constructor(private _rs: ArticleTarifTemplateRelationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleTarifTemplateRelation> {
    return this._rs.fetch(+route.params['articletariftemplaterelationId']);
  }
}
