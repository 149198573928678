import { inject, Injectable } from "@angular/core";
import { FilterDefaults } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { ProducerService } from "./producer.service";

export enum PRODUCER_STAT_TYPES {
  COUNT = "count",
  PER_DISTANCE = "per_distance",
  AVG_DISTANCE = "avg_distance",
}

export interface CountStats {
  count: number;
}

export interface PerDistanceStat {
  distance: number;
  count: number;
}

export interface AvgDistanceStat {
  local_distance: number;
  global_distance: number;
}

export interface StatsResponse {
  count?: CountStats;
  per_distance?: PerDistanceStat[];
  avg_distance?: AvgDistanceStat;
}

@Injectable({
  providedIn: "root",
})
export class ProducerStatsService {
  private _producers = inject(ProducerService);

  public get_stats(stats: PRODUCER_STAT_TYPES[], filter?: FilterDefaults) {
    return firstValueFrom(
      this._producers.action<StatsResponse>(null, "GET", "stats", {
        params: {
          stats: stats.join(","),
          ...filter,
        },
      }),
    );
  }
}
