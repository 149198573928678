import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { STORAGE_ROUTES } from "../menu";
import { Storage } from "../../../../../models/structures/storage/storage";
import { Member } from "../../../../../models/structures/member/member";
import { Link } from "@solidev/data";
import {
  STORAGE_TYPES,
  STORAGE_USER_LEVELS,
} from "../../../../../models/structures/storage/storage.base";

@Component({
  selector: "lvadg-storage-nav",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: "./storage-nav.component.pug",
  styleUrl: "./storage-nav.component.sass",
})
export class StorageNavComponent implements OnInit {
  @Input() public routes!: STORAGE_ROUTES;
  @Input() public storage!: Storage;
  @Input() public member?: Member;

  public storage_display_route?: Link<Storage>;
  public storage_edit_route?: Link<Storage>;
  public storage_cdatas_route?: Link<Storage>;
  public storage_relations_route?: Link<Storage>;
  public storage_global_producers_route?: Link<Storage>;
  public storage_global_products_route?: Link<Storage>;
  public storage_local_producers_route?: Link<Storage>;
  public storage_local_products_route?: Link<Storage>;
  public storage_sea_producers_route?: Link<Storage>;
  public storage_sea_products_route?: Link<Storage>;

  public storage_offers_route?: Link<Storage>;
  public storage_stats_route?: Link<Storage>;
  public storage_sales_route?: Link<Storage>;
  public storage_users_route?: Link<Storage>;
  public readonly SUL = STORAGE_USER_LEVELS;

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes) {
      throw new Error("Missing routes input");
    }
    if (this.routes.storage_display_route) {
      this.storage_display_route = new Link(
        this.routes.storage_display_route,
        this,
        this._router,
      );
    }
    if (this.routes.storage_cdatas_route) {
      this.storage_cdatas_route = new Link(
        this.routes.storage_cdatas_route,
        this,
        this._router,
      );
    }
    if (this.routes.storage_edit_route) {
      this.storage_edit_route = new Link(
        this.routes.storage_edit_route,
        this,
        this._router,
      );
    }
    if (this.routes.storage_relations_route) {
      this.storage_relations_route = new Link(
        this.routes.storage_relations_route,
        this,
        this._router,
      );
    }
    if (
      this.routes.storage_global_producers_route &&
      this.storage.type != STORAGE_TYPES.SEA
    ) {
      this.storage_global_producers_route = new Link(
        this.routes.storage_global_producers_route,
        this,
        this._router,
      );
    }
    if (
      this.routes.storage_global_products_route &&
      this.storage.type != STORAGE_TYPES.SEA
    ) {
      this.storage_global_products_route = new Link(
        this.routes.storage_global_products_route,
        this,
        this._router,
      );
    }
    if (
      this.routes.storage_local_producers_route &&
      this.storage.type != STORAGE_TYPES.SEA
    ) {
      this.storage_local_producers_route = new Link(
        this.routes.storage_local_producers_route,
        this,
        this._router,
      );
    }
    if (
      this.routes.storage_local_products_route &&
      this.storage.type != STORAGE_TYPES.SEA
    ) {
      this.storage_local_products_route = new Link(
        this.routes.storage_local_products_route,
        this,
        this._router,
      );
    }
    if (
      this.routes.storage_sea_producers_route &&
      this.storage.type != STORAGE_TYPES.FL
    ) {
      this.storage_sea_producers_route = new Link(
        this.routes.storage_sea_producers_route,
        this,
        this._router,
      );
    }
    if (
      this.routes.storage_sea_products_route &&
      this.storage.type != STORAGE_TYPES.FL
    ) {
      this.storage_sea_products_route = new Link(
        this.routes.storage_sea_products_route,
        this,
        this._router,
      );
    }

    if (this.routes.storage_offers_route) {
      this.storage_offers_route = new Link(
        this.routes.storage_offers_route,
        this,
        this._router,
      );
    }
    if (this.routes.storage_stats_route) {
      this.storage_stats_route = new Link(
        this.routes.storage_stats_route,
        this,
        this._router,
      );
    }
    if (this.routes.storage_sales_route) {
      this.storage_sales_route = new Link(
        this.routes.storage_sales_route,
        this,
        this._router,
      );
    }
    if (this.routes.storage_users_route) {
      this.storage_users_route = new Link(
        this.routes.storage_users_route,
        this,
        this._router,
      );
    }
  }
}
