/////////////////////////////////////////////////////////////////////////////////////////////////////////
// MODÈLES DE TARIF
// menu
export * from './tariftypes/detail/menu';
// nav
export * from './tariftypes/detail/nav/tariftype-detail-nav.component';

// list
export * from './tariftypes/list/tariftype-list-view.component';
// detail
export * from './tariftypes/detail/tariftype-detail-view.component';
// display
export * from './tariftypes/detail/display/tariftype-display-view.component';
// manage
export * from './tariftypes/detail/manage/tariftype-manage-view.component';
// tarifs
export * from './tariftypes/detail/tarifs/tariftype-tarifs-view.component';
// articles
export * from './tariftypes/detail/articles/tariftype-articles-view.component';
// relations
export * from './tariftypes/detail/relations/tariftype-relations-view.component';
// catalog
export * from './tariftypes/detail/catalog/tariftype-catalog-view.component';
// destinations
export * from './tariftypes/detail/destinations/tariftype-destinations-view.component';
// lifecycle
export * from './tariftypes/detail/lifecycle/tariftype-lifecycle-view.component';
// mappings
export * from './tariftypes/detail/mappings/tariftype-mappings-view.component';
// notifications
export * from './tariftypes/detail/notifications/tariftype-notifications-view.component';
// notifications_logs
export * from './tariftypes/detail/notifications_logs/tariftype-notifications-logs-view.component';
// permissions
export * from './tariftypes/detail/permissions/tariftype-permissions-view.component';
// prices_logs
export * from './tariftypes/detail/prices_logs/tariftype-prices-logs-view.component';
// tarifs_logs
export * from './tariftypes/detail/tarifs_logs/tariftype-tarifs-logs-view.component';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// TARIFS
export * from './tarifs/detail/menu';
// nav
export * from './tarifs/detail/nav/tarif-detail-nav.component';
// list
export * from './tarifs/list/tarif-list-view.component';
// display / redirect
export * from './tarifs/detail/display/tarif-display-view.component';
// manage
export * from './tarifs/detail/manage/tarif-manage-view.component';
// lines
export * from './tarifs/detail/lines/tarif-lines-view.component';
// documents
export * from './tarifs/detail/documents/tarif-documents-view.component';
// notifications
export * from './tarifs/detail/notifications/tarif-notifications-view.component';
// notifications_logs
export * from './tarifs/detail/notifications_logs/tarif-notifications-logs-view.component';
// relations
export * from './tarifs/detail/relations/tarif-relations-view.component';
// lifecycle
export * from './tarifs/detail/lifecycle/tarif-lifecycle-view.component';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// Non-main models
// tariftype-relation
export * from './tariftype-relation/list/tariftype-relation-list-view.component';
export * from './tariftype-relation/detail/tariftype-relation-detail-view.component';
// tarif-relation
export * from './tarif-relation/list/tarif-relation-list-view.component';
export * from './tarif-relation/detail/tarif-relation-detail-view.component';
// tariftype-permission
export * from './tariftype-permission/list/tariftype-permission-list-view.component';
export * from './tariftype-permission/detail/tariftype-permission-detail-view.component';
// tariftype-storage
export * from './tariftype-storage/list/tariftype-storage-list-view.component';
export * from './tariftype-storage/detail/tariftype-storage-detail-view.component';
// tariftype-storage-permission
export * from './tariftype-storage-permission/list/tariftype-storage-permission-list-view.component';
export * from './tariftype-storage-permission/detail/tariftype-storage-permission-detail-view.component';
// tariftype-region
export * from './tariftype-region/list/tariftype-region-list-view.component';
export * from './tariftype-region/detail/tariftype-region-detail-view.component';
// tariftype-region-permission
export * from './tariftype-region-permission/list/tariftype-region-permission-list-view.component';
export * from './tariftype-region-permission/detail/tariftype-region-permission-detail-view.component';
// article-tarif-template
export * from './article-tarif-template/list/article-tarif-template-list-view.component';
export * from './article-tarif-template/detail/article-tarif-template-detail-view.component';
// article-tarif-template-relation
export * from './article-tarif-template-relation/list/article-tarif-template-relation-list-view.component';
export * from './article-tarif-template-relation/detail/article-tarif-template-relation-detail-view.component';
// tarif-notification
export * from './tarif-notification/list/tarif-notification-list-view.component';
export * from './tarif-notification/detail/tarif-notification-detail-view.component';
// tarif-notification-status
export * from './tarif-notification-status/list/tarif-notification-status-list-view.component';
export * from './tarif-notification-status/detail/tarif-notification-status-detail-view.component';
// tarif-notification-destination
export * from './tarif-notification-destination/list/tarif-notification-destination-list-view.component';
export * from './tarif-notification-destination/detail/tarif-notification-destination-detail-view.component';
// tarif-document
export * from './tarif-document/list/tarif-document-list-view.component';
export * from './tarif-document/detail/tarif-document-detail-view.component';
// tarif-lifecycle-step
export * from './tarif-lifecycle-step/list/tarif-lifecycle-step-list-view.component';
export * from './tarif-lifecycle-step/detail/tarif-lifecycle-step-detail-view.component';
// tariftype-metadata
export * from './tariftype-metadata/list/tariftype-metadata-list-view.component';
export * from './tariftype-metadata/detail/tariftype-metadata-detail-view.component';
// tariftype-document
export * from './tariftype-document/list/tariftype-document-list-view.component';
export * from './tariftype-document/detail/tariftype-document-detail-view.component';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// Stats
// article
export * from './stats/article/tarif-stats-article-view.component';
// articles
export * from './stats/articles/tarif-stats-articles-view.component';
// families
export * from './stats/families/tarif-stats-families-view.component';
// family
export * from './stats/family/tarif-stats-family-view.component';
// home
export * from './stats/home/tarif-stats-view.component';
