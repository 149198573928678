import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
} from '../../../../documents/upload/media-upload/media-upload.component';
import { Tarif } from '../../tarif';
import { Upload } from '../../../../documents/upload/upload';
import { DataMessageService, UploadFile } from '@solidev/data';
import { TarifService } from '../../tarif.service';
import { TarifActionService } from '../../tarif-action.service';
import { OplogDisplayComponent } from '../../../../../components/utils/oplog/oplog-display/oplog-display.component';
import { OpLog } from '../../../../../components/utils/oplog/oplog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TariftypeMetadata } from '../../../tariftype-metadata/tariftype-metadata';
import { TarifDocument } from '../../../tarif-document/tarif-document';
import { Observable } from 'rxjs';
import { TariftypeMetadataService } from '../../../tariftype-metadata/tariftype-metadata.service';
import { TarifDocumentService } from '../../../tarif-document/tarif-document.service';
import { TARIFDOCUMENT_TYPES } from '../../../tarif-document/tarif-document.base';
import { TARIFTYPEMETADATA_TYPE } from '../../../tariftype-metadata/tariftype-metadata.base';
import { GenericregionBase } from '../../../../locations/genericregion/genericregion.base';
import { StorageBase } from '../../../../structures/storage/storage.base';

interface ImportCustomArticleResult {
  tocreate: { ccode: string; clibelle: string; price: number | string | null }[];
  missing: { ccode: string; clibelle: string; price: number | string | null; mappings: string[] }[];
  inactive: { ccode: string; clibelle: string; vcode: string; vlibelle: string; price: number | string | null }[];
  enabled: { ccode: string; clibelle: string; vcode: string; vlibelle: string; price: number | string | null }[];
  disabled: { ccode: string; clibelle: string; vcode: string; vlibelle: string; price: number | string | null }[];
}

interface ActionResponseData {
  data: {
    result: ImportCustomArticleResult;
  };
  log: OpLog;
  message: string;
  success: boolean;
}

interface ActionUploadData {
  data: {
    tarifdocument_id?: number;
    upload_id?: number;
  };
  message: string;
}

@Component({
  selector: 'lvadg-tarif-import-custom-articles',
  standalone: true,
  imports: [CommonModule, MediaUploadComponent, ReactiveFormsModule, OplogDisplayComponent],
  templateUrl: './tarif-import-custom-articles.component.pug',
  styleUrls: ['./tarif-import-custom-articles.component.sass'],
})
export class TarifImportCustomArticlesComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public mode: 'price' | 'region' | 'storage' = 'price';
  @Input() public region?: GenericregionBase;
  @Input() public storage?: StorageBase;

  @Output() public cancelled = new EventEmitter<void>();
  @Output() public done = new EventEmitter<void>();
  public doc!: Upload;
  public uploadPrepare: (model: Upload, file: UploadFile) => Promise<IPreparedUploadFile<Upload>> =
    this._uploadPrepare.bind(this);
  public uploadUrl!: string;
  public step = 0;
  public result?: ActionResponseData;
  public form = new FormGroup({
    metadata: new FormControl<TariftypeMetadata | null>(null, [Validators.required]),
    nokeep: new FormControl<boolean>(false),
    document: new FormControl<TarifDocument | null>(null),
  });
  public docs$!: Observable<TarifDocument[]>;
  public metas$!: Observable<TariftypeMetadata[]>;
  public upload_id?: number;
  public tarifdocument_id?: number;
  public processing: boolean = false;

  constructor(
    private _tarifs: TarifService,
    private _ttmeta: TariftypeMetadataService,
    private _ttdocs: TarifDocumentService,
    private _tact: TarifActionService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    if (this.mode === 'region' && !this.region) {
      throw new Error('Missing region');
    }
    if (this.mode === 'storage' && !this.storage) {
      throw new Error('Missing storage');
    }
    this.docs$ = this._ttdocs.list({
      tarif: this.tarif.id,
      type: TARIFDOCUMENT_TYPES.IMPORT_TARIF,
    });
    this.metas$ = this._ttmeta.list({
      tariftype: this.tarif.tariftype,
      type: TARIFTYPEMETADATA_TYPE.EACLIENT_READ_PARAMS,
    });
    this.doc = new Upload();
    if (this.tarif) {
      this.uploadUrl = this._tarifs.getUrl(this.tarif.id, {
        suffix: '/action',
      });
    }
  }

  public onUploaded($event: { response: ActionUploadData; status: number }) {
    if ($event.response.data) {
      if ($event.response.data.upload_id) {
        this.upload_id = $event.response.data.upload_id;
      } else if ($event.response.data.tarifdocument_id) {
        this.tarifdocument_id = $event.response.data.tarifdocument_id;
      }
      this._msgs.success($event.response.message);
    }
  }

  public async launch(confirm: boolean = false) {
    if (this.form.value.metadata) {
      this.step = confirm ? 2 : 1;
      this.processing = true;
      delete this.result;
      try {
        this.result = await this._tact.action<ActionResponseData>(this.tarif, {
          action: 'read_custom_articles',
          document_id: this.tarifdocument_id || this.form.value.document?.id,
          upload_id: this.upload_id,
          storage_id: this.storage?.id,
          region_id: this.region?.id,
          mode: this.mode,
          metadata_id: this.form.value.metadata.id,
          confirm,
        });
        if (!this.result.success) {
          this.cancelled.emit();
        }
      } catch (e) {
        console.error('Untrapped error', e);
        this.cancelled.emit();
      }
      this.processing = false;
    }
  }

  public xlsxExport(result: ActionResponseData) {
    const res = result.data.result;
    if (!res) {
      return;
    }
    setTimeout(async () => {
      await import('xlsx').then(({ utils, writeFileXLSX }) => {
        const wb = utils.book_new();
        if (res.tocreate) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.tocreate.map((v) => [v.ccode, v.clibelle, v.price])),
            '01-Manquants',
          );
        }
        if (res.missing) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.missing.map((v) => [v.ccode, v.clibelle, v.price, v.mappings.join(' / ')])),
            '02-Mappings',
          );
        }
        if (res.inactive) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.inactive.map((v) => [v.ccode, v.clibelle, v.vcode, v.vlibelle, v.price])),
            '03-A_désarchiver',
          );
        }
        if (res.enabled) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.enabled.map((v) => [v.ccode, v.clibelle, v.vcode, v.vlibelle, v.price])),
            '04-Réactivés',
          );
        }
        if (res.disabled) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.disabled.map((v) => [v.ccode, v.clibelle, v.vcode, v.vlibelle, v.price])),
            '05-Désactivés',
          );
        }
        if (result.log) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(result.log.map((v) => [v.line, v.level, v.message, v.data])),
            '99-Log',
          );
        }
        writeFileXLSX(wb, 'résultat_importation.xlsx');
      });
    });
  }

  private async _uploadPrepare(model: Upload, file: UploadFile): Promise<IPreparedUploadFile<Upload>> {
    return {
      model,
      url: this.uploadUrl,
      data: {
        action: 'read_custom_articles',
        keep_file: !this.form.value.nokeep,
        tarif: this.tarif.id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
      },
    };
  }
}
