
<h1>Associate RNM</h1>
<ng-container *ngIf="curAtt$|async; let catt">
  <pre>{{catt.article_details?.libelle}}</pre>
  <div class="row">
    <div class="col-4">
      <h2>Existants</h2>
      <ul>
        <li *ngFor="let a of current$|async">{{a.rnm_details?.name}}</li>
      </ul>
    </div>
    <div class="col-4">
      <h2>Candidats</h2>
      <ul>
        <li *ngFor="let a of rnmCandidates$|async" (click)="selectRnm(a)">{{a.name}}</li>
      </ul>
    </div>
  </div>
</ng-container>