import { Component } from '@angular/core';
import { GeoregionListViewData } from './georegion-list-view.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { GeoregionListComponent } from '../../../../models/locations/georegion/georegion-list/georegion-list.component';
import { Georegion } from '../../../../models/locations/georegion/georegion';
import { GeodepartementBase } from '../../../../models/locations/geodepartement/geodepartement.base';
import { Link } from '@solidev/data';

@Component({
  selector: 'lvadg-georegion-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, GeoregionListComponent],
  templateUrl: './georegion-list-view.component.pug',
  styleUrls: ['./georegion-list-view.component.sass'],
})
export class GeoregionListViewComponent extends BaseListViewComponent<GeoregionListViewData, Georegion> {
  public geodepartement_detail_route?: Link<GeodepartementBase>;
  public geocountry_detail_route?: Link<any>;

  public override setRouteData(data: GeoregionListViewData) {
    super.setRouteData(data);
    this.geodepartement_detail_route = new Link<GeodepartementBase>(
      data.geodepartement_detail_route,
      data,
      this._router,
    );
    this.geocountry_detail_route = new Link<any>(data.geocountry_detail_route, data, this._router);
  }
}
