
<ng-container *ngIf="data$|async; let data">
  <ng-template #ofcslot_remove let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Supprimer tous les restaurants de l'offre</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <p class="text-center text-danger">Voulez-vous vraiment supprimer tous les restaurants de l'offre ?</p>
      <div class="p-5 text-center">
        <button class="btn btn-outline-danger btn-lg w-100 my-5 py-2" (click)="processRemoveAll()">
          <lvadg-icon ri="delete">Supprimer tous les restaurants</lvadg-icon>
        </button>
        <button class="btn btn-outline-secondary w-100" (click)="offcanvas.close()">
          <lvadg-icon ri="close">Annuler</lvadg-icon>
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #ofcslot_add let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Ajout d'un restaurant</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-resto-list name="offer-restos-view-add" [keep]="false" [default_fields]="resto_add_default_fields" [reload]="reload$" [filter]="restoAddFilter$" [actions]="['offer_add']" (action)="onRestoAddAction($event)" displayMode="ml-top"></lvadg-resto-list>
    </div>
  </ng-template>
  <lvadg-offer-nav [routes]="data.o_menu" [offer]="data.offer" *ngIf="data.o_menu" [offertype]="data.offertype" [offertypestorage]="data.offertypestorage"></lvadg-offer-nav>
  <lvadg-header-action [title]="data.offer.title" level="h1" subtitle="Restaurants de l'offre" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-info btn-sm" (click)="syncRestos()" *ngIf="data.offer.have_level(OUL.o_write)">
        <lvadg-icon ri="sync">Mettre a jour</lvadg-icon>
      </button>
      <button class="btn btn-outline-danger btn-sm" (click)="openRemoveAll()" *ngIf="data.offer.have_level(OUL.o_write)">
        <lvadg-icon ri="delete">Supprimer tout</lvadg-icon>
      </button>
      <button class="btn btn-outline-primary btn-sm" (click)="addResto()" *ngIf="data.offer.have_level(OUL.o_write)">
        <lvadg-icon ri="plus">Ajouter</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-todo class="my-3">
    <div class="item">TODO: filtres ? actif/non actif, par client ? par ???</div>
  </lvadg-todo>
  <lvadg-offer-resto-list [name]="data.route.name" [reload]="reload$" [default_fields]="default_fields" [filter]="restoFilter$" [actions]="data.offer.have_level(OUL.o_write)?['toggle_status']:[]" (action)="onOfferRestoAction(data.offer, $event)"></lvadg-offer-resto-list>
</ng-container>