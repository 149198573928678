import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IntervalGrouper, Period } from './periods';
import { map, Observable } from 'rxjs';
import { DATA_API_URL } from '@solidev/data';

@Injectable({
  providedIn: 'root',
})
export class IntervalService {
  constructor(
    @Inject(DATA_API_URL) private apiUrl: string,
    private _http: HttpClient
  ) {}

  public get(grouper: IntervalGrouper, period: Period): Observable<Date[]> {
    return this._http
      .get<Date[]>(`${this.apiUrl}/v3/intervals`, {
        params: {
          grouper: grouper,
          period: period,
        },
      })
      .pipe(map((r) => r.map((d) => new Date(d))));
  }
}
