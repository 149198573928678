import { TarifDocumentBase } from './tarif-document.base';
import { detailsField } from '@solidev/data';
import { Tarif } from '../tarif/tarif';
import { Image } from '../../documents/image/image';

export class TarifDocument extends TarifDocumentBase {
  static override readonly __name: string = 'TarifDocument';

  @detailsField({
    model: Tarif,
    readonly: true,
    description: 'Tarif',
  })
  public tarif_details?: Tarif;

  // SEE: check if we should/can move this into document base
  @detailsField({
    model: Image,
    readonly: true,
    description: 'Image',
  })
  public image_details?: Image;
}
