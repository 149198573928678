export enum EGALIM_STATUS {
  NON_EGALIM = "NO",
  EGALIM = "EG",
  EQ_EGALIM = "EQ",
}

export enum FAMILY_DISPLAY_MODES {
  FL_1G = "FL-1G",
  FL_4G = "FL-4G",
  FL_5G = "FL-5G",
  MR = "MR",
  UNKNOWN = "UNK",
}

export const FAMILY_DISPLAY_MODE_CHOICES = [
  {
    value: FAMILY_DISPLAY_MODES.FL_1G,
    desc: "Fruits & légumes, 1ère gamme",
  },
  {
    value: FAMILY_DISPLAY_MODES.FL_4G,
    desc: "Fruits & légumes, 4ème gamme",
  },
  {
    value: FAMILY_DISPLAY_MODES.FL_5G,
    desc: "Fruits & légumes, 5ème gamme",
  },
  {
    value: FAMILY_DISPLAY_MODES.MR,
    desc: "Marée",
  },
  {
    value: FAMILY_DISPLAY_MODES.UNKNOWN,
    desc: "Mode affichage non précisé",
  },
];
