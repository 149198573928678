import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { VivalyaBillItem } from '../vivalya-bill-item';
import { VivalyaBillItemService } from '../vivalya-bill-item.service';
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListService,
  ModelListTextFilter,
  ModelListTreeFilter,
  PFactorcPipe,
  PFactorPipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { ProducerDisplayComponent } from '../../../structures/producer/producer-display/producer-display.component';
import { ArticleDisplayComponent } from '../../../catalog/article/article-display/article-display.component';
import { EarticleDisplayComponent } from '../../../catalog/earticle/earticle-display/earticle-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { MemberDisplayComponent } from '../../../structures/member/member-display/member-display.component';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { VivalyaBillDisplayComponent } from '../../vivalya-bill/vivalya-bill-display/vivalya-bill-display.component';
import { Producer } from '../../../structures/producer/producer';
import { Storage } from '../../../structures/storage/storage';
import { Article } from '../../../catalog/article/article';
import { Earticle } from '../../../catalog/earticle/earticle';
import { Client } from '../../../structures/client/client';
import { Member } from '../../../structures/member/member';
import { Resto } from '../../../structures/resto/resto';
import { VivalyaBill } from '../../vivalya-bill/vivalya-bill';
import { MemberService } from '../../../structures/member/member.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { ClientService } from '../../../structures/client/client.service';
import { RestoService } from '../../../structures/resto/resto.service';
import { ArticleService } from '../../../catalog/article/article.service';
import { EarticleService } from '../../../catalog/earticle/earticle.service';
import { FamilyService } from '../../../catalog/family/family.service';
import { Family } from '../../../catalog/family/family';
import { FAMILY_TYPE } from '../../../catalog/family/family.base';

@Component({
  selector: 'lvadg-vivalya-bill-item-list',
  standalone: true,
  templateUrl: './vivalya-bill-item-list.component.pug',
  styleUrls: ['./vivalya-bill-item-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FlagsDisplayComponent,
    PFactorcPipe,
    PFactorPipe,
    ProducerDisplayComponent,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
    StorageDisplayComponent,
    ClientDisplayComponent,
    MemberDisplayComponent,
    RestoDisplayComponent,
    VivalyaBillDisplayComponent,
  ],
})
export class VivalyaBillItemListComponent extends ModellistComponent<VivalyaBillItem> {
  @Input() public producer_detail_route?: Link<Producer>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public article_detail_route?: Link<Article>;
  @Input() public earticle_detail_route?: Link<Earticle>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public member_detail_route?: Link<Member>;
  @Input() public resto_detail_route?: Link<Resto>;
  @Input() public bill_detail_route?: Link<VivalyaBill>;

  constructor(
    coll: VivalyaBillItemService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _members: MemberService,
    private _storages: StorageService,
    private _clients: ClientService,
    private _restos: RestoService,
    private _articles: ArticleService,
    private _earcticles: EarticleService,
    private _families: FamilyService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'members',
          name: 'members',
          label: 'Recherche par adhérent',
          collection: this._members,
          filter: {
            fields: ['id', 'name'].join(','),
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'storages',
          name: 'storages',
          label: 'Recherche par dépôt',
          collection: this._storages,
          filter: {
            fields: ['id', 'cvva', 'name'].join(','),
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'clients',
          name: 'clients',
          label: 'Recherche par client',
          collection: this._clients,
          filter: {
            fields: ['id', 'cvva', 'name'].join(','),
            have_billing: true,
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'restos',
          name: 'restos',
          label: 'Recherche par restaurant',
          collection: this._restos,
          filter: {
            fields: ['id', 'cvva', 'name'].join(','),
            have_billing: true,
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'articles',
          name: 'articles',
          label: 'Recherche par article',
          collection: this._articles,
          searchparam: 'libellecode',
          filter: {
            fields: ['id', 'libelle', 'code'].join(','),
            have_billing: true,
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'articles_tarifs',
          name: 'articles_tarifs',
          label: 'Recherche par article avec tarif (lent!)',
          collection: this._articles,
          searchparam: 'libellecode',
          filter: {
            fields: ['id', 'libelle', 'code'].join(','),
            with_tarif: 'yes',
            have_billing: true,
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'gc_articles',
          name: 'gc_articles',
          label: 'Recherche par article grand compte',
          collection: this._earcticles,
          filter: {
            fields: ['id', 'libelle', 'code', 'type'].join(','),
            have_gc_billing: true,
            order: 'name',
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'adh_articles',
          name: 'adh_articles',
          label: 'Recherche par article adhérent',
          collection: this._earcticles,
          filter: {
            fields: ['id', 'libelle', 'code', 'type'].join(','),
            have_adh_billing: true,
            order: 'name',
          },
        }),
        new ModelListTreeFilter<Family>({
          field: 'in_family',
          name: 'in_family',
          label: 'Recherche par famille',
          collection: this._families,
          help: '---------------------',
          filter: {
            fields: ['id', 'name', 'parent'].join(','),
            type: FAMILY_TYPE.VIVALYA,
          },
          display: (v) => `${v.name}`,
        }),
      ],
    };
  }
}
