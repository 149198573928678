import { Inject, Injectable } from '@angular/core';
import { type Map, type MapLayerMouseEvent, type Popup } from 'maplibre-gl';

import { Collection } from '@solidev/data';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { MemberService } from '../../../structures/member/member.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { ClientService } from '../../../structures/client/client.service';
import { RestoService } from '../../../structures/resto/resto.service';
import { ProducerService } from '../../../structures/producer/producer.service';
import { ProviderService } from '../../../structures/provider/provider.service';
import { MapLayerType } from '../constants';

export interface PopupData {
  popup: Popup;
  data: Observable<any>;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class PopupDataService {
  constructor(
    private _members: MemberService,
    private _storages: StorageService,
    private _clients: ClientService,
    private _restos: RestoService,
    private _producers: ProducerService,
    private _providers: ProviderService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public async clickPopup(map: Map, layer: MapLayerType, e: MapLayerMouseEvent): Promise<PopupData[]> {
    const out: PopupData[] = [];
    if (e.features) {
      for (const f of e.features) {
        if ('coordinates' in f.geometry && f.geometry.coordinates) {
          const coordinates = f.geometry.coordinates.slice() as [number, number];
          if (coordinates.length !== 2) {
            continue;
          }

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          const pd = await this.getPopup(map, coordinates, e.features[0].properties);
          if (pd !== null) {
            out.push(pd);
          }
        }
      }
    }
    return out;
  }

  public async getPopup(map: Map, coordinates: [number, number], properties: any): Promise<PopupData | null> {
    const services: { [key in MapLayerType]: Collection<any> } = {
      storages: this._storages,
      seastorages: this._storages,
      members: this._members,
      restos: this._restos,
      clients: this._clients,
      producers: this._producers,
      seaproducers: this._producers,
      providers: this._providers,
    };
    if (properties.id && properties.type && properties.type in services) {
      const { Popup } = await import('maplibre-gl');
      const popum = new Popup().setLngLat(coordinates).addTo(map);
      const infos = services[properties.type as MapLayerType].fetch(properties.id, {
        params: {},
      });
      return { popup: popum, data: infos, type: properties.type };
    }
    return null;
  }
}
