
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu &amp;&amp; data.tariftype"></lvadg-tariftype-detail-nav>
  <lvadg-header-action [title]="data.tariftype.name" level="h2" *ngIf="data.tariftype" subtitle="Gestion des régions" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-sm btn-primary" (click)="createRegion=!createRegion">
        <lvadg-icon ri="add">Ajouter une région</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-header-action title="Gestion des associations tarifs/régions" level="h1" *ngIf="!data.tariftype" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-sm btn-primary" (click)="createRegion=!createRegion">
        <lvadg-icon ri="add">Ajouter une association tarif/région</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createRegion">
    <div class="card-header">Ajouter une association tarif/région</div>
    <div class="card-body">
      <lvadg-tariftype-region-create [tariftype]="data.tariftype" (cancelled)="createRegion=false" (created)="reload$.next(true); createRegion=false"></lvadg-tariftype-region-create>
    </div>
  </div>
  <lvadg-tariftype-region-list [name]="data.route.name" [filter]="tarifTypeFilter$" [reload]="reload$" [default_fields]="default_fields" [detail_route]="detail_route" [tariftype_detail_route]="tariftype_detail_route" [region_detail_route]="region_detail_route" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [permission_detail_route]="permission_detail_route" [actions]="['delete', 'permissions']"></lvadg-tariftype-region-list>
</ng-container>