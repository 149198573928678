import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Progress, WsService } from '../ws.service';
import { NgbAlertModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../../utils/icon/icon.component';

@Component({
  selector: 'lvadg-live-progress',
  standalone: true,
  imports: [CommonModule, NgbProgressbarModule, NgbAlertModule, RouterLink, IconComponent],
  templateUrl: './live-progress.component.pug',
  styleUrls: ['./live-progress.component.sass'],
})
export class LiveProgressComponent implements OnInit {
  @Input() public slot: 'global' | string = 'global';
  public progress$!: Observable<Progress[]>;

  constructor(private _ws: WsService) {}

  public ngOnInit() {
    this.progress$ = this._ws.progress$(this.slot);
  }

  close(p: Progress) {
    p.closed = true;
    this._ws.unprogress(p.channel);
  }
}
