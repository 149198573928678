import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { TarifNotificationStatusManageComponent } from '../../../../models/tarifs/tarif-notification-status/tarif-notification-status-manage/tarif-notification-status-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TarifNotificationStatus } from '../../../../models/tarifs/tarif-notification-status/tarif-notification-status';
import { BaseRouteParams } from '@solidev/data';

export interface TarifNotificationStatusDetailViewParams extends BaseRouteParams {}

export interface TarifNotificationStatusDetailViewData extends TarifNotificationStatusDetailViewParams {
  tarifnotificationstatus: TarifNotificationStatus;
}

@Component({
  selector: 'lvadg-tarif-notification-status-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TarifNotificationStatusManageComponent],
  templateUrl: './tarif-notification-status-detail-view.component.pug',
  styleUrls: ['./tarif-notification-status-detail-view.component.sass'],
})
export class TarifNotificationStatusDetailViewComponent extends BaseDetailViewComponent<
  TarifNotificationStatusDetailViewData,
  TarifNotificationStatus
> {
  public override setRouteData(data: TarifNotificationStatusDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
