import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OffertypeText } from "../offertype-text";
import { DispeditComponent, RicheditComponent } from "@solidev/data";

@Component({
  selector: "lvadg-offertype-text-manage",
  standalone: true,
  imports: [CommonModule, DispeditComponent, RicheditComponent],
  templateUrl: "./offertype-text-manage.component.pug",
  styleUrls: ["./offertype-text-manage.component.sass"],
})
export class OffertypeTextManageComponent {
  @Input() public offertypetext?: OffertypeText;
}
