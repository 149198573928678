import { Component, input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SupportMessage } from "../support-message";
import { DispeditComponent, PChoicePipe } from "@solidev/data";
import { IconComponent } from "../../../../components/utils/icon/icon.component";

@Component({
  selector: "lvadg-support-message-manage",
  standalone: true,
  imports: [CommonModule, DispeditComponent, IconComponent, PChoicePipe],
  templateUrl: "./support-message-manage.component.pug",
  styleUrls: ["./support-message-manage.component.sass"],
})
export class SupportMessageManageComponent {
  public supportmessage = input.required<SupportMessage>();
  public mode = input<"small" | "normal">("small");
  public edit = signal(false);
}
