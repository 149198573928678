import { DataModel, datetimeField, foreignKeyField, integerField, primaryField, textField } from '@solidev/data';

export class ArticleSimilarityBase extends DataModel {
  static override readonly __name: string = 'ArticleSimilarityBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
    priority: -1,
  })
  public article!: number;

  @foreignKeyField({
    name: 'similar',
    description: 'ID Similar article',
    related: 'Article',
    priority: -1,
  })
  public similar!: number;

  @integerField({
    name: 'differences',
    description: 'Nb différences',
    defaultValue: 0,
    required: true,
    priority: 500,
  })
  public differences: number = 0;

  @integerField({
    name: 'similar_tarifs_count',
    description: 'Nb tarifs',
    defaultValue: 0,
    required: false,
    priority: 300,
  })
  public similar_tarifs_count?: number;

  @integerField({
    name: 'similar_mappings_count',
    description: 'Nb mappings',
    defaultValue: 0,
    required: false,
    priority: 300,
  })
  public similar_mappings_count?: number;

  @textField({
    name: 'description',
    description: 'Description',
    priority: 400,
  })
  public description!: string;
}
