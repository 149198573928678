import { Client } from "../../structures/client/client";
import { Member } from "../../structures/member/member";
import { Producer } from "../../structures/producer/producer";
import { Storage } from "../../structures/storage/storage";
import { Provider } from "../../structures/provider/provider";
import { Resto } from "../../structures/resto/resto";

export enum MAPPING_BASE_STYLES {
  OSM_LIBERTY_FR = "osmlibertyfr",
  OSM_LIBERTY_EU = "osmlibertyeu",
  TONER_VIVALYA_DARK_FR = "tnvivalyadarkfr",
  TONER_VIVALYA_LIGHT_FR = "tnvivalyalightfr",
  TONER_VIVALYA_DARK_EU = "tnvivalyadarkeu",
  TONER_VIVALYA_LIGHT_EU = "tnvivalyalighteu",
  OSM_BRIGHT_FR = "osmbrightfr",
  OSM_BRIGHT_EU = "osmbrighteu",
  BASIC_FR = "basicfr",
  BASIC_EU = "basiceu",
  TONER_FR = "tonerfr",
  TONER_EU = "tonereu",
  FR_BOUNDARIES = "frboundaries",
  REGIONS = "regions",
  DEPARTEMENTS = "departements",
  COMMUNES = "communes",
  REGIONS_NONAME = "regions-noname",
  DEPARTEMENTS_NONAME = "departements-noname",
  DEPARTEMENTS_BORDERS_NONAME = "departements-borders-noname",
  COMMUNES_NONAME = "communes-noname",
  EMPTY = "empty",
}

export const MAPPING_STYLES = new Map([
  [
    MAPPING_BASE_STYLES.OSM_LIBERTY_FR,
    {
      name: "osmlibertyfr",
      url: "https://maptiles.gis.lavieadugout.fr/styles/osmliberty-fr/style.json",
      description: "OSM Liberty (FR)",
    },
  ],
  [
    MAPPING_BASE_STYLES.OSM_LIBERTY_EU,
    {
      name: "osmlibertyeu",
      url: "https://maptiles.gis.lavieadugout.fr/styles/osmliberty-eu/style.json",
      description: "OSM Liberty (EU)",
    },
  ],
  [
    MAPPING_BASE_STYLES.TONER_VIVALYA_DARK_FR,
    {
      name: "tnvivalyadark",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner_vivalya_dark/style.json",
      description: "Vivalya (dark, FR)",
    },
  ],
  [
    MAPPING_BASE_STYLES.TONER_VIVALYA_LIGHT_FR,
    {
      name: "tnvivalyalight",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner_vivalya_light/style.json",
      description: "Vivalya (light, FR)",
    },
  ],
  [
    MAPPING_BASE_STYLES.TONER_VIVALYA_DARK_EU,
    {
      name: "tnvivalyadark",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner_eu_vivalya_dark/style.json",
      description: "Vivalya (dark, EU)",
    },
  ],
  [
    MAPPING_BASE_STYLES.TONER_VIVALYA_LIGHT_EU,
    {
      name: "tnvivalyalight",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner_eu_vivalya_light/style.json",
      description: "Vivalya (light, EU)",
    },
  ],
  [
    MAPPING_BASE_STYLES.OSM_BRIGHT_FR,
    {
      name: "osmbrightfr",
      url: "https://maptiles.gis.lavieadugout.fr/styles/osmbright-fr/style.json",
      description: "OSM Bright (FR)",
    },
  ],
  [
    MAPPING_BASE_STYLES.OSM_BRIGHT_EU,
    {
      name: "osmbrighteu",
      url: "https://maptiles.gis.lavieadugout.fr/styles/osmbright-eu/style.json",
      description: "OSM Bright (EU)",
    },
  ],
  [
    MAPPING_BASE_STYLES.BASIC_FR,
    {
      name: "basicfr",
      url: "https://maptiles.gis.lavieadugout.fr/styles/basic-fr/style.json",
      description: "Basic (FR)",
    },
  ],
  [
    MAPPING_BASE_STYLES.BASIC_EU,
    {
      name: "basiceu",
      url: "https://maptiles.gis.lavieadugout.fr/styles/basic-eu/style.json",
      description: "Basic (EU)",
    },
  ],
  [
    MAPPING_BASE_STYLES.TONER_FR,
    {
      name: "tonerfr",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner-fr/style.json",
      description: "Toner (FR)",
    },
  ],
  [
    MAPPING_BASE_STYLES.TONER_EU,
    {
      name: "tonereu",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner-eu/style.json",
      description: "Toner (EU)",
    },
  ],
  [
    MAPPING_BASE_STYLES.FR_BOUNDARIES,
    {
      name: "frboundaries",
      url: "https://maptiles.gis.lavieadugout.fr/styles/frboundaries/style.json",
      description: "France simple",
    },
  ],
  [
    MAPPING_BASE_STYLES.REGIONS,
    {
      name: "regions",
      url: "https://maptiles.gis.lavieadugout.fr/styles/regions/style.json",
      description: "Régions",
    },
  ],
  [
    MAPPING_BASE_STYLES.DEPARTEMENTS,
    {
      name: "departements",
      url: "https://maptiles.gis.lavieadugout.fr/styles/departements/style.json",
      description: "Départements",
    },
  ],
  [
    MAPPING_BASE_STYLES.COMMUNES,
    {
      name: "communes",
      url: "https://maptiles.gis.lavieadugout.fr/styles/communes/style.json",
      description: "Communes",
    },
  ],
  [
    MAPPING_BASE_STYLES.REGIONS_NONAME,
    {
      name: "regions-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/regions-noname/style.json",
      description: "Régions (sans texte)",
    },
  ],
  [
    MAPPING_BASE_STYLES.DEPARTEMENTS_NONAME,
    {
      name: "departements-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/departements-noname/style.json",
      description: "Départements (sans texte)",
    },
  ],
  [
    MAPPING_BASE_STYLES.DEPARTEMENTS_BORDERS_NONAME,
    {
      name: "departements-borders-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/departements-borders-noname/style.json",
      description: "Départements - limites (sans texte)",
    },
  ],

  [
    MAPPING_BASE_STYLES.COMMUNES_NONAME,
    {
      name: "communes-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/communes-noname/style.json",
      description: "Communes (sans texte)",
    },
  ],
  [
    MAPPING_BASE_STYLES.EMPTY,
    {
      name: "empty",
      url: "https://maptiles.gis.lavieadugout.fr/styles/empty/style.json",
      description: "Vide",
    },
  ],
]);

export type MapLayerType =
  | "members"
  | "producers"
  | "seaproducers"
  | "providers"
  | "storages"
  | "seastorages"
  | "clients"
  | "restos";

export const MapLayers: MapLayerType[] = [
  "members",
  "producers",
  "seaproducers",
  "providers",
  "storages",
  "seastorages",
  "clients",
  "restos",
];

/**
 * Map concrete data from structures.
 */
export interface MapData {
  members?: Member[];
  producers?: Producer[];
  seaproducers?: Producer[];
  providers?: Provider[];
  storages?: Storage[];
  seastorages?: Storage[];
  clients?: Client[];
  restos?: Resto[];
}
