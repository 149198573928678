import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TariftypeRegionService } from '../tariftype-region.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter, SafeDeleteComponent } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { TariftypeRegionPermissionBase } from '../../tariftype-region-permission/tariftype-region-permission.base';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { GenericregionBase } from '../../../locations/genericregion/genericregion.base';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { TariftypeRegionPermissionDisplayComponent } from '../../tariftype-region-permission/tariftype-region-permission-display/tariftype-region-permission-display.component';
import { TariftypeRegionManageComponent } from '../tariftype-region-manage/tariftype-region-manage.component';
import { GenericregionDisplayComponent } from '../../../locations/genericregion/genericregion-display/genericregion-display.component';
import {TariftypeRegion} from "../tariftype-region";

@Component({
  selector: 'lvadg-tariftype-region-list',
  standalone: true,
  templateUrl: './tariftype-region-list.component.pug',
  styleUrls: ['./tariftype-region-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    GenericregionDisplayComponent,
    SafeDeleteComponent,
    TariftypeDisplayComponent,
    TariftypeRegionPermissionDisplayComponent,
    TariftypeRegionManageComponent,
  ],
})
export class TariftypeRegionListComponent extends ModellistComponent<TariftypeRegion> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public region_detail_route?: Link<GenericregionBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input()
  public permission_detail_route?: Link<TariftypeRegionPermissionBase>;
  public deleteFn = this._deleteFn.bind(this);

  constructor(
    coll: TariftypeRegionService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _tts: TariftypeService,
    private _ttact: TariftypeActionService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  private async _deleteFn(model: TariftypeRegion): Promise<{ success: boolean }> {
    if (!model.tariftype_details) {
      return { success: false };
    }
    model.tariftype_details._collection = this._tts;
    const res = await this._ttact.action(model.tariftype_details, {
      action: 'remove_region',
      ttregion: model.id,
    });
    return res;
  }
}
