
<ul class="nav nav-tabs nav-fill mb-2">
  <!-- Résumé (à faire)-->
  <!--li.nav-item(*ngIf="tarif_detail_route")-->
  <!--  a.nav-link([routerLink]="tarif_detail_route.route({tarif: tarif})",-->
  <!--    [routerLinkActiveOptions]="{exact: true}",-->
  <!--    routerLinkActive="active") Résumé-->
  <!-- Lignes-->
  <li class="nav-item" *ngIf="tarif_lines_route &amp;&amp; tariftype.have_level(TTUL.storage) &amp;&amp; tariftype.lifecycle!==TTLC.UPLOAD"><a class="nav-link" [routerLink]="tarif_lines_route.route({tarif: tarif})" routerLinkActive="active">Lignes de tarif</a></li>
  <!-- Tarifs liés-->
  <li class="nav-item" *ngIf="tarif_relations_route &amp;&amp; tariftype.have_level(TTUL.pilote) &amp;&amp; tariftype.lifecycle!==TTLC.UPLOAD"><a class="nav-link" [routerLink]="tarif_relations_route.route({tarif: tarif})" routerLinkActive="active">Tarifs liés</a></li>
  <!-- Édition du tarif-->
  <li class="nav-item" *ngIf="tarif_manage_route &amp;&amp; tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tarif_manage_route.route({tarif: tarif})" routerLinkActive="active">Édition paramètres</a></li>
  <!-- Documents-->
  <li class="nav-item" *ngIf="tarif_documents_route &amp;&amp; tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tarif_documents_route.route({tarif: tarif})" routerLinkActive="active">Documents</a></li>
  <!-- Notifications-->
  <li class="nav-item" *ngIf="tarif_notifications_route &amp;&amp; tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tarif_notifications_route.route({tarif: tarif})" routerLinkActive="active">Notifications</a></li>
  <!-- Suivi des envois-->
  <li class="nav-item" *ngIf="tarif_notification_logs_route &amp;&amp; tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tarif_notification_logs_route.route({tarif: tarif})" routerLinkActive="active">Suivi des envois</a></li>
  <!-- Cycle de vie-->
  <li class="nav-item" *ngIf="tarif_lifecycle_route &amp;&amp; tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tarif_lifecycle_route.route({tarif: tarif})" routerLinkActive="active">Cycle de vie</a></li>
</ul>