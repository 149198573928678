import { Component, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferNotificationDestination } from "../offer-notification-destination";
import { Link } from "@solidev/data";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lvadg-offer-notification-destination-display",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./offer-notification-destination-display.component.pug",
  styleUrls: ["./offer-notification-destination-display.component.sass"],
})
export class OfferNotificationDestinationDisplayComponent {
  public destination = input<OfferNotificationDestination>();
  public mode = input<"line">("line");
  public detail_route = input<Link<OfferNotificationDestination>>();
}
