import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TarifDocument } from '../../../../models/tarifs/tarif-document/tarif-document';
import { TarifDocumentManageComponent } from '../../../../models/tarifs/tarif-document/tarif-document-manage/tarif-document-manage.component';
import { BaseRouteParams } from '@solidev/data';

export interface TarifDocumentDetailViewParams extends BaseRouteParams {}

export interface TarifDocumentDetailViewData extends TarifDocumentDetailViewParams {
  tarifdocument: TarifDocument;
}

@Component({
  selector: 'lvadg-tarif-document-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TarifDocumentManageComponent],
  templateUrl: './tarif-document-detail-view.component.pug',
  styleUrls: ['./tarif-document-detail-view.component.sass'],
})
export class TarifDocumentDetailViewComponent extends BaseDetailViewComponent<
  TarifDocumentDetailViewData,
  TarifDocument
> {
  public override setRouteData(data: TarifDocumentDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
