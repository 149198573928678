import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { PRODUCER_ROUTES } from '../menu';
import { Producer } from '../../../../../models/structures/producer/producer';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { CommonModule } from '@angular/common';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { ProducerNavComponent } from '../_nav/producer-nav.component';
import { RelationListComponent } from '../../../../../models/structures/relation/relation-list/relation-list.component';
import { RelationCreateComponent } from '../../../../../models/structures/relation/relation-create/relation-create.component';
import { map, Observable, Subject } from 'rxjs';
import { Relation } from '../../../../../models/structures/relation/relation';
import { Storage } from '../../../../../models/structures/storage/storage';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';

export interface ProducerRelationsViewParams extends BaseRouteParams {
  pr_menu: PRODUCER_ROUTES;
  relation_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
}

export interface ProducerRelationsViewData extends ProducerRelationsViewParams {
  producer: Producer;
  storage?: Storage;
}

@Component({
  selector: 'lvadg-producer-relations-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    ProducerNavComponent,
    RelationListComponent,
    RelationCreateComponent,
  ],
  templateUrl: './producer-relations-view.component.pug',
  styleUrl: './producer-relations-view.component.sass',
})
export class ProducerRelationsViewComponent extends BaseDetailViewComponent<ProducerRelationsViewData, Producer> {
  public producerFilter$!: Observable<FilterDefaults>;
  public createRelation = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public relation_detail_route?: Link<Relation>;
  public storage_detail_route?: Link<Storage>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.producerFilter$ = this.data$.pipe(map((data) => ({ producer: data.producer.id })));
  }

  public override setRouteData(data: ProducerRelationsViewData) {
    super.setRouteData(data);
    if (data.relation_detail_route) {
      this.relation_detail_route = new Link(data.relation_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(data.storage_detail_route, data, this._router);
    }
  }
}
