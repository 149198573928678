import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Particularity } from './particularity';

@Injectable({
  providedIn: 'root',
})
export class ParticularityService extends Collection<Particularity> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/particularities', Particularity);
  }
}
