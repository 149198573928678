
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Impression</h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngIf="print_settings">
      <h3 class="text-info">Impression des résultats courants</h3>
      <ul class="list-group">
        <ng-container *ngFor="let ps of print_settings">
          <ng-container *ngIf="ps.type==='queryset'">
            <ng-container *ngFor="let a of ps.actions">
              <ng-container *ngIf="a==='quick_print'">
                <li class="list-group-item list-group-item-action" role="button" (click)="print(ps, a)"><i class="bi bi-printer me-2"></i><span>{{ps.label}}</span><span class="ms-2" *ngIf="a==='quick_print'">(impression rapide)</span></li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <!--ul.list-group.mt-1-->
    <!--    li.list-group-item.list-group-item-warning.list-group-item-action(role="button",-->
    <!--        (click)="deselectAll()")-->
    <!--        i.bi.bi-trash-->
    <!--        |  Vider la sélection-->
    <!--    li.list-group-item.list-group-item-action(role="button",-->
    <!--        (click)="selectAll()")-->
    <!--        i.bi.bi-check2-square-->
    <!--        |  Sélectionner tous les éléments visibles-->
    <!--    li.list-group-item.list-group-item-action(role="button",-->
    <!--        (click)="invertSelection()")-->
    <!--        i.bi.bi-check2-square-->
    <!--        |  Inverser la sélection-->
    <!---->
    <!--ul.list-group.mt-2(*ngIf="!selection.isEmpty()")-->
    <!--    ng-container(*ngIf="print_settings")-->
    <!--        ng-container(*ngFor="let ps of print_settings")-->
    <!--            ng-container(*ngIf="ps.type==='selection'")-->
    <!--                ng-container(*ngFor="let a of ps.actions")-->
    <!--                    li.list-group-item.list-group-item-action(role="button",-->
    <!--                        (click)="print(ps, a)")-->
    <!--                        i.bi.bi-printer.me-2-->
    <!--                        span {{ps.label}}-->
    <!--                        span.ms-2(*ngIf="a==='quick_print'") (impression rapide)-->
    <!--                        span.ms-2(*ngIf="a==='add_to_print'") (ajouter à l'impression courante)-->
    <!---->
    <!---->
    <!--h4.mt-3 Éléments sélectionnés-->
    <!--table.table-sm(*ngIf="!selection.isEmpty()")-->
    <!--    thead-->
    <!--        tr-->
    <!--            th-->
    <!--            th-->
    <!--    tbody-->
    <!--        tr(*ngFor="let item of selection.display")-->
    <!--            td-->
    <!--                button.btn.btn-sm.btn-outline-warning(type="button",-->
    <!--                    (click)="selection.removeById(item.id)")-->
    <!--                    i.bi.bi-trash-->
    <!--            td-->
    <!--                | {{ item.label }}-->
    <!--p.text-warning.text-center.my-4(*ngIf="selection.isEmpty()")-->
    <!--    i.bi.bi-exclamation-triangle.me-2-->
    <!--    |  Aucun élément sélectionné-->
  </div>
</ng-template>
<lvadg-icon class="fs-5" ri="print" (click)="clickIcon()" [icon_only]="true"></lvadg-icon>
<!--ng-container(*ngIf="!selection.isEmpty()")-->
<!--    i.bi.bi-check-square((click)="clickIcon()")-->
<!--    span.count {{ selection.size }}-->