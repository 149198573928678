
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-flags-display [item]="row"></lvadg-flags-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="phone">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="phone" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a *ngIf="row.phone" [href]="'tel:'+row.phone">{{row.phone}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="fax">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="fax" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.fax}}</td>
        </ng-container>
        <ng-container cdkColumnDef="website">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="website" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a *ngIf="row.website" [href]="row.website" target="_blank">{{row.website}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="locname">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="locname" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.locname}}</td>
        </ng-container>
        <ng-container cdkColumnDef="address">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="address" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.address}}</td>
        </ng-container>
        <ng-container cdkColumnDef="caddress">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="caddress" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.caddress}}</td>
        </ng-container>
        <ng-container cdkColumnDef="city">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="city" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.city}}</td>
        </ng-container>
        <ng-container cdkColumnDef="postcode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="postcode" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.postcode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="cedex">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cedex" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.cedex}}</td>
        </ng-container>
        <ng-container cdkColumnDef="region">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="region" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.region}}</td>
        </ng-container>
        <ng-container cdkColumnDef="country">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="country" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.country}}</td>
        </ng-container>
        <ng-container cdkColumnDef="position">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="position" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.position}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geodepartement">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geodepartement" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geodepartement}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocommune">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocommune" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocommune}}</td>
        </ng-container>
        <ng-container cdkColumnDef="georegion">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="georegion" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.georegion}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocountry">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocountry" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocountry}}</td>
        </ng-container>
        <ng-container cdkColumnDef="loctype">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="loctype" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:"loctype"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geotype">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geotype" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'geotype'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geoprovider">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geoprovider" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'geoprovider'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geolabel">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geolabel" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geolabel}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geoname">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geoname" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geoname}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geopostcode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geopostcode" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geopostcode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocity">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocity" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocity}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geohousenumber">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geohousenumber" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geohousenumber}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geostreet">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geostreet" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geostreet}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocontext">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocontext" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocontext}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocitycode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocitycode" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocitycode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="cvva_updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cvva_updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.cvva_updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="lat">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="lat"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.lat}}</td>
        </ng-container>
        <ng-container cdkColumnDef="lng">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="lng"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.lng}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geodepartement_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geodepartement_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geodepartement_details?.name}} ({{row.geodepartement_details?.code}})</td>
        </ng-container>
        <ng-container cdkColumnDef="georegion_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="georegion_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.georegion_details?.name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocommune_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocommune_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocommune_details?.postcode}} {{row.geocommune_details?.name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="geocountry_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="geocountry_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.geocountry_details?.name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="related_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="related_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container [ngSwitch]="row.related_details.type" *ngIf="row.related_details"><a *ngSwitchCase="'member'" [routerLink]="member_detail_route?.route({member: row.related_details})"><small>[adhérent]</small> {{row.related_details?.name}}</a><a *ngSwitchCase="'storage'" [routerLink]="storage_detail_route?.route({storage: row.related_details})"><small>[dépôt]</small> {{row.related_details?.name}}</a><a *ngSwitchCase="'client'" [routerLink]="client_detail_route?.route({client: row.related_details})"><small>[client]</small> {{row.related_details?.name}}</a><a *ngSwitchCase="'resto'" [routerLink]="resto_detail_route?.route({resto: row.related_details})"><small>[resto]</small> {{row.related_details?.name}}</a><a *ngSwitchCase="'producer'" [routerLink]="producer_detail_route?.route({producer: row.related_details})"><small>[producteur]</small> {{row.related_details?.name}}</a><a *ngSwitchCase="'provider'" [routerLink]="provider_detail_route?.route({provider: row.related_details})"><small>[fournisseur]</small> {{row.related_details?.name}}</a></ng-container>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>