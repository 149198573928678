
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { EacollectionService} from "./eacollection.service";
import { Eacollection } from "./eacollection";

@Injectable({
  providedIn: 'root'
})
export class EacollectionResolver  {
  constructor(private _rs: EacollectionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Eacollection> {
    return this._rs.fetch(+route.params["eacollectionId"])
  }
}
