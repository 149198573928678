
<ng-container *ngIf="region">
  <dl class="row">
    <data-dispedit class="col-6" [model]="region" field="id" [editable]="false" mode="dd">ID</data-dispedit>
    <data-dispedit class="col-6" [model]="region" field="vvid" [editable]="false" mode="dd">ID Vivalya (deprecated)</data-dispedit>
    <data-dispedit class="col-3" [model]="region" field="code" [editable]="true" mode="dd">Code (utilisé pour le libellé)</data-dispedit>
    <data-dispedit class="col-3" [model]="region" field="type" [editable]="true" mode="dd">Type</data-dispedit>
    <data-dispedit class="col-6" [model]="region" field="name" [editable]="true" mode="dd">Nom / description</data-dispedit>
    <data-dispedit class="col-4" [model]="region" field="georegion" [editable]="true" mode="dd" [collection]="georegions$">Région géographique</data-dispedit>
    <data-dispedit class="col-4" [model]="region" field="geodepartement" [editable]="true" mode="dd" [collection]="geodepartements$">Département géographique</data-dispedit>
    <data-dispedit class="col-4" [model]="region" field="geocommune" [editable]="true" mode="dd" [collection]="geocommunes$">Commune géographique</data-dispedit>
  </dl>
</ng-container>