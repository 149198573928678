import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthService, SPECIAL_GROUPS } from "@vivalya/lvadg";
import { map, Observable } from "rxjs";
import { ADMIN_MENU, IMenuItem } from "../../../routing/menu";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lva-home",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./home.component.pug",
  styleUrls: ["./home.component.sass"],
})
export class HomeComponent implements OnInit {
  public menu$!: Observable<IMenuItem[]>;

  constructor(public auth: AuthService) {}

  ngOnInit(): void {
    this.menu$ = this.auth.currentUser$.pipe(
      map((u) => {
        return ADMIN_MENU.filter((i) => {
          if (i.onlyMenu) {
            return false;
          }
          for (const g of i.groups || [SPECIAL_GROUPS.superuser]) {
            if (g === SPECIAL_GROUPS.all) {
              return true;
            }
            if (g === SPECIAL_GROUPS.superuser && u?.is_superuser) {
              return true;
            }
            if (g === SPECIAL_GROUPS.staff && u?.is_staff) {
              return true;
            }
            if (
              u?.current_groups_slugs &&
              u.current_groups_slugs.indexOf(`${g}`) !== -1
            ) {
              return true;
            }
          }
          return false;
        });
      }),
    );
  }
}
