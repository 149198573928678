import { Component } from '@angular/core';

import { RegionListViewData } from './region-list-view.component.params';
import { Subject } from 'rxjs';
import { AllergenListViewData } from '../../allergen/list/allergen-list-view.component.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { RegionListComponent } from '../../../../../models/catalog/meta/region/region-list/region-list.component';
import { RegionCreateComponent } from '../../../../../models/catalog/meta/region/region-create/region-create.component';
import { Region } from '../../../../../models/catalog/meta/region/region';

@Component({
  selector: 'lvadg-region-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, RegionListComponent, RegionCreateComponent],
  templateUrl: './region-list-view.component.pug',
  styleUrls: ['./region-list-view.component.sass'],
})
export class RegionListViewComponent extends BaseListViewComponent<RegionListViewData, Region> {
  public default_fields = [
    'id',
    'type',
    'code',
    'name',
    'articles_count',
    'georegion_details',
    'geodepartement_details',
    'geocommune_details',
    'updated',
  ];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
