
<ng-template #type>
  <lvadg-todo>
    .item TODO: ADMIN MODE: CHOIX TYPE
    
  </lvadg-todo>
</ng-template>
<ng-template #destinataire>
  <lvadg-todo>
    <div class="item">TODO: ADMIN MODE: CHOIX USER / GROUP</div>
  </lvadg-todo>
</ng-template>
<ng-template #contactCreate>
  <lvadg-contact-create [save]="false" [resto]="dest_resto()" [type]="CT.OFFER" (created)="createContact($event)"></lvadg-contact-create>
</ng-template>
<ng-template #contact>
  <form class="row align-items-end g-3" [formGroup]="contactForm" (submit)="searchContact()">
    <div class="col-8">
      <label>Rechercher un contact existant</label>
      <input class="form-control form-control-lg" type="text" placeholder="Nom, prenom ou email" formControlName="search">
    </div>
    <div class="col-4">
      <button class="btn btn-outline-primary btn-lg w-100">
        <lvadg-icon ri="search">Rechercher</lvadg-icon>
      </button>
    </div>
  </form>
  <div class="mt-3" *ngIf="contactSearchResult(); let cts">
    <div class="mb-3" *ngIf="cts.length===0">
      <h4 class="text-warning">Aucun résultat, création d'un nouveau contact</h4>
      <ng-container *ngTemplateOutlet="contactCreate"></ng-container>
    </div>
    <div class="mb-3" *ngIf="cts.length&gt;0">
      <h4 class="text-success">Contact(s) trouvé(s), sélectionnez celui que vous voulez ajouter, ou créez-en un nouveau</h4>
      <div class="my-3 d-flex flex-row flex-wrap justify-content-start gap-3">
        <lvadg-contact-display *ngFor="let c of cts" classes="h-100 w-100" [contact]="c" mode="button" (click)="selectContact(c)"></lvadg-contact-display>
      </div>
      <h4 class="text-info">Créer un nouveau contact</h4>
      <ng-container *ngTemplateOutlet="contactCreate"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #selectResto>
  <lvadg-resto-list name="offer-destination-resto-select" [keep]="false" [default_fields]="resto_add_default_fields" [filter]="restoAddFilter" [actions]="['select']" (action)="onRestoAddAction($event)" displayMode="ml-top"></lvadg-resto-list>
</ng-template>
<ng-template #target>
  <ng-container *ngIf="dest_target()===null">
    <h2 class="step">Choix du type de destinataire</h2>
    <div class="row">
      <div class="col-6">
        <button class="btn btn-outline-primary btn-lg w-100 h-100" (click)="dest_target.set(TGT.GENERIC)">
          <lvadg-icon ri="user">Générique</lvadg-icon>
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-primary btn-lg w-100 h-100" (click)="dest_target.set(TGT.RESTO)">
          <lvadg-icon ri="resto">Restaurant</lvadg-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="dest_target()===TGT.GENERIC">
    <ng-container *ngIf="is_admin()">
      <h2 class="step">Destinataire de l'offre générique</h2>
      <ng-container *ngTemplateOutlet="destinataire"></ng-container>
      <ng-container *ngTemplateOutlet="contact"></ng-container>
    </ng-container>
    <ng-container *ngIf="!is_admin()">
      <ng-container *ngTemplateOutlet="contact"></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="dest_target()===TGT.RESTO">
    <ng-container *ngIf="dest_resto()===null">
      <h2 class="step">Sélectionnez le restaurant</h2>
      <ng-container *ngTemplateOutlet="selectResto"></ng-container>
    </ng-container>
    <ng-container *ngIf="dest_resto()!==null">
      <h2 class="step">Destinataire de l'offre restaurant {{dest_resto()!.name}}</h2>
      <ng-container *ngIf="is_admin()">
        <ng-container *ngTemplateOutlet="destinataire"></ng-container>
        <ng-container *ngTemplateOutlet="contact"></ng-container>
      </ng-container>
      <ng-container *ngIf="!is_admin()">
        <ng-container *ngTemplateOutlet="contact"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<div class="dialog container">
  <ng-container *ngIf="dest_type()===null">
    <ng-container *ngTemplateOutlet="target"></ng-container>
  </ng-container>
  <ng-container *ngIf="dest_type()===ONT.PUBLISH">
    <ng-container *ngTemplateOutlet="target"></ng-container>
  </ng-container>
  <ng-container *ngIf="dest_type()===ONT.ALARM">
    <ng-container *ngTemplateOutlet="contact"></ng-container>
  </ng-container>
</div>