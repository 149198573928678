
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Geodepartement } from "./geodepartement";

@Injectable({
  providedIn: 'root'
})
export class GeodepartementService extends Collection<Geodepartement> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/geodepartements", Geodepartement);
  }
}
