
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Region } from "./region";

@Injectable({
  providedIn: 'root'
})
export class RegionService extends Collection<Region> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/regions", Region);
  }
}
