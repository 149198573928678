import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CLIENT_ROUTES } from '../menu';
import { Client } from '../../../../../models/structures/client/client';
import { Link } from '@solidev/data';

@Component({
  selector: 'lvadg-client-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './client-nav.component.pug',
  styleUrl: './client-nav.component.sass',
})
export class ClientNavComponent implements OnInit {
  @Input() public routes!: CLIENT_ROUTES;
  @Input() public client!: Client;

  public client_display_route?: Link<Client>;
  public client_edit_route?: Link<Client>;
  public client_restos_route?: Link<Client>;
  public client_offers_route?: Link<Client>;
  public client_relations_route?: Link<Client>;
  public client_sales_route?: Link<Client>;
  public client_stats_route?: Link<Client>;
  public client_tariftypes_route?: Link<Client>;
  public client_earticles_route?: Link<Client>;
  public client_atts_route?: Link<Client>;
  public client_users_route?: Link<Client>;

  public CUL = {
    admin: 'admin',
  };

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes) {
      throw new Error('Missing routes input');
    }
    if (this.routes.client_display_route) {
      this.client_display_route = new Link(this.routes.client_display_route, this, this._router);
    }
    if (this.routes.client_edit_route) {
      this.client_edit_route = new Link(this.routes.client_edit_route, this, this._router);
    }
    if (this.routes.client_restos_route) {
      this.client_restos_route = new Link(this.routes.client_restos_route, this, this._router);
    }
    if (this.routes.client_offers_route) {
      this.client_offers_route = new Link(this.routes.client_offers_route, this, this._router);
    }
    if (this.routes.client_relations_route) {
      this.client_relations_route = new Link(this.routes.client_relations_route, this, this._router);
    }
    if (this.routes.client_sales_route) {
      this.client_sales_route = new Link(this.routes.client_sales_route, this, this._router);
    }
    if (this.routes.client_stats_route) {
      this.client_stats_route = new Link(this.routes.client_stats_route, this, this._router);
    }
    if (this.routes.client_tariftypes_route) {
      this.client_tariftypes_route = new Link(this.routes.client_tariftypes_route, this, this._router);
    }
    if (this.routes.client_earticles_route) {
      this.client_earticles_route = new Link(this.routes.client_earticles_route, this, this._router);
    }
    if (this.routes.client_atts_route) {
      this.client_atts_route = new Link(this.routes.client_atts_route, this, this._router);
    }
    if (this.routes.client_users_route) {
      this.client_users_route = new Link(this.routes.client_users_route, this, this._router);
    }
  }
}
