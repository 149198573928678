
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ProviderDocument } from "./provider-document";

@Injectable({
  providedIn: 'root'
})
export class ProviderDocumentService extends Collection<ProviderDocument> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/providerdocuments", ProviderDocument);
  }
}
