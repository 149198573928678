
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ArticleTarifTemplate } from "./article-tarif-template";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifTemplateService extends Collection<ArticleTarifTemplate> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/articletariftemplates", ArticleTarifTemplate);
  }
}
