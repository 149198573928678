import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offertype } from '../../../../models/offers/offertype/offertype';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { OffertypeNavComponent } from './_nav/offertype-nav.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { OffertypeViewParams } from './menu';
import { Link, PChoicePipe, RouteConfigItem } from '@solidev/data';
import { Client } from '../../../../models/structures/client/client';
import { ClientDisplayComponent } from '../../../../models/structures/client/client-display/client-display.component';
import { TariftypeDisplayComponent } from '../../../../models/tarifs/tariftype/tariftype-display/tariftype-display.component';
import { RruleDisplayComponent } from '../../../../components/utils/rrule-display/rrule-display.component';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { UserDisplayComponent } from '../../../../models/users/user/user-display/user-display.component';
import { User } from '../../../../models/users/user/user';

export interface OffertypeDetailViewParams extends OffertypeViewParams {
  client_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
}

export interface OffertypeDetailViewData extends OffertypeDetailViewParams {
  offertype: Offertype;
}

/**
 * Vue résumé d'un modèle d'offfre.
 * TODO: ajouter des stats et les infos intéressantes ?
 */
@Component({
  selector: 'lvadg-offertype-detail-view',
  standalone: true,
  imports: [
    CommonModule,
    OffertypeNavComponent,
    HeaderActionComponent,
    PChoicePipe,
    ClientDisplayComponent,
    UserDisplayComponent,
    TariftypeDisplayComponent,
    RruleDisplayComponent,
  ],
  templateUrl: './offertype-detail-view.component.pug',
  styleUrls: ['./offertype-detail-view.component.sass'],
})
export class OffertypeDetailViewComponent extends BaseDetailViewComponent<OffertypeDetailViewData, Offertype> {
  public client_detail_route?: Link<Client>;
  public tariftype_detail_route?: Link<Tariftype>;
  public user_detail_route?: Link<User>;

  public override setRouteData(data: OffertypeDetailViewData) {
    super.setRouteData(data);
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link<Tariftype>(data.tariftype_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
  }
}
