import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Rnmlibelle } from '../rnmlibelle';
import { RnmlibelleService } from '../rnmlibelle.service';
import {
  FieldsParams,
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LabelDisplayComponent } from '../../../catalog/label/label-display/label-display.component';
import { RnmBase } from '../../rnm/rnm.base';

@Component({
  selector: 'lvadg-rnmlibelle-list',
  standalone: true,
  templateUrl: './rnmlibelle-list.component.pug',
  styleUrls: ['./rnmlibelle-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, LabelDisplayComponent],
})
export class RnmlibelleListComponent extends ModellistComponent<Rnmlibelle> {
  @Input() public rnm_detail_route?: Link<RnmBase>;

  constructor(
    coll: RnmlibelleService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'without_rnm'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'without_rnm',
          name: 'without_rnm',
          label: 'Filtrer par association',
          choices: [
            { value: 'false', desc: 'Associé' },
            { value: 'true', desc: 'Non associé' },
          ],
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const fields = super.getFields();
    fields.custom?.push(
      {
        name: 'rnm_details__egalim',
        label: 'Labels et certifications',
      },
      {
        name: 'rnm_details__cotation_unit',
        label: 'Unité de cotation',
      },
      {
        name: 'rnm_details__cotation_value',
        label: 'Valeur de cotation',
      },
      {
        name: 'rnm_details__calibre_details',
        label: 'Calibre',
      },
      {
        name: 'rnm_details__origine_details',
        label: 'Origine',
      },
      {
        name: 'rnm_details__categorie_details',
        label: 'Categorie',
      }
    );
    return fields;
  }
}
