import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum EAMAPPING_STATUS {
  IN_TARIF = 'INT',
  OUT_TARIF = 'OUT',
  PROBLEM = 'PBM',
  TARGETTED = 'TGT',
  NOT_PROCESSED = 'NTT',
  INSIGNIFIANT = 'NSG',
  OTHER = 'OTH',
}

export class EamappingBase extends DataModel {
  static override readonly __name: string = 'EamappingBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 10000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 100,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'external',
    description: 'ID EA',
    related: 'ExternalArticle',
    priority: -1,
  })
  public external!: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
    priority: -2,
  })
  public article!: number;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Tariftype',
    related: 'TarifType',
    priority: -1,
  })
  public tariftype!: number;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: EAMAPPING_STATUS.NOT_PROCESSED,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: EAMAPPING_STATUS.IN_TARIF,
        desc: 'Dans tarif',
      },
      {
        value: EAMAPPING_STATUS.OUT_TARIF,
        desc: 'Hors tarif',
      },
      {
        value: EAMAPPING_STATUS.PROBLEM,
        desc: 'Problème à régler',
      },
      {
        value: EAMAPPING_STATUS.TARGETTED,
        desc: 'Ciblé pour tarif',
      },
      {
        value: EAMAPPING_STATUS.INSIGNIFIANT,
        desc: 'Non significatif',
      },
      {
        value: EAMAPPING_STATUS.NOT_PROCESSED,
        desc: 'Non traité',
      },
      {
        value: EAMAPPING_STATUS.OTHER,
        desc: 'Autre',
      },
    ],
    priority: 100,
  })
  public status: EAMAPPING_STATUS = EAMAPPING_STATUS.NOT_PROCESSED;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -20,
  })
  public orig_id!: number;
}
