import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Particularity } from '../particularity';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-particularity-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './particularity-manage.component.pug',
  styleUrls: ['./particularity-manage.component.sass'],
})
export class ParticularityManageComponent {
  @Input() public particularity!: Particularity;

  constructor() {}
}
