import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../../includes/modellist/modellist.component';
import { Region } from '../region';
import { RegionService } from '../region.service';
import { FiltersParams, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GeocommuneDisplayComponent } from '../../../../locations/geocommune/geocommune-display/geocommune-display.component';
import { GeoregionDisplayComponent } from '../../../../locations/georegion/georegion-display/georegion-display.component';
import { GeodepartementDisplayComponent } from '../../../../locations/geodepartement/geodepartement-display/geodepartement-display.component';

@Component({
  selector: 'lvadg-region-list',
  standalone: true,
  templateUrl: './region-list.component.pug',
  styleUrls: ['./region-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    GeocommuneDisplayComponent,
    GeoregionDisplayComponent,
    GeodepartementDisplayComponent,
  ],
})
export class RegionListComponent extends ModellistComponent<Region> {
  constructor(coll: RegionService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
