import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeRelation } from '../tariftype-relation';
import { DispeditComponent } from '@solidev/data';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { TariftypeRelationService } from '../tariftype-relation.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-tariftype-relation-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tariftype-relation-manage.component.pug',
  styleUrls: ['./tariftype-relation-manage.component.sass'],
})
export class TariftypeRelationManageComponent implements OnInit {
  @Input() public tariftyperelation?: TariftypeRelation;

  constructor(
    public tariftyperelations$: TariftypeRelationService,
    public tariftypes$: TariftypeService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.tariftyperelation) {
      this.tariftyperelation = await firstValueFrom(
        this.tariftyperelations$.fetch(this.tariftyperelation.id)
      );
    }
  }
}
