import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { map, Observable, Subject } from 'rxjs';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TarifNotificationStatusListComponent } from '../../../../../models/tarifs/tarif-notification-status/tarif-notification-status-list/tarif-notification-status-list.component';
import { TARIF_ROUTES } from '../menu';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';

export interface TarifNotificationsLogsViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifNotificationsLogsViewData extends TarifNotificationsLogsViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-notifications-logs-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    HeaderActionComponent,
    IconComponent,
    TarifNotificationStatusListComponent,
    TarifDetailNavComponent,
  ],
  templateUrl: './tarif-notifications-logs-view.component.pug',
  styleUrls: ['./tarif-notifications-logs-view.component.sass'],
})
export class TarifNotificationsLogsViewComponent extends BaseDetailViewComponent<
  TarifNotificationsLogsViewData,
  Tarif
> {
  public tns_default_fields = [
    'id',
    'notification_details',
    'notification__type',
    'notification__tarif',
    'notification__priority',
    'user_details',
    'destination',
    'status',
    'updated',
    'created',
  ];
  public tariftype_detail_route!: Link<TariftypeBase>;
  public tarifFilter$!: Observable<FilterDefaults>;
  public reloadTarif$: Subject<void | boolean> = new Subject<void | boolean>();

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifNotificationsLogsViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifFilter$ = this.data$.pipe(
      map((d) => {
        return { tarif: d.tarif.id };
      }),
    );
  }
}
