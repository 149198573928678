import { NewscategoryService } from '../newscategory.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { NewsCategory } from '../newscategory';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'lvadg-newscategorie-create',
  templateUrl: './newscategory-create.component.pug',
  styleUrls: ['./newscategory-create.component.sass'],
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
})
export class NewscategoryCreateComponent implements OnInit {
  public category!: NewsCategory;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<NewsCategory>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _category: NewscategoryService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.category = new NewsCategory(this._category);
  }

  public async save() {
    this.category.fromJson({ parent: null, ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.category.save({ updateModel: true }));
      this.created.emit(this.category);
      this._msgs.success('Actualité créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
