
<div class="d-flex flex-column h-100">
  <input class="form-control form-control-sm mt-1" [formControl]="search" placeholder="Recherche par marché">
  <div *ngIf="mlist$|async; let markets; else loading">
    <div class="flex-grow-1" *ngFor="let market of markets">
      <div class="d-block text-decoration-none text-black text-uppercase" role="button" (click)="rnmSelect(market)" [class.fw-bold]="current===market.id"><span class="me-1"><i class="bi bi-house-door"></i></span>
        <ng-container *ngIf="market.code"><span>{{market.lname}}</span></ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="text-center mt-5">
    <div class="fs-1 spinner-grow text-primary"></div>
    <div class="text-primary">Chargement en cours...</div>
  </div>
</ng-template>