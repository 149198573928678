import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { Client } from '../../../../../models/structures/client/client';
import { map, Observable, Subject } from 'rxjs';
import { CLIENT_ROUTES } from '../menu';
import { User } from '../../../../../models/users/user/user';
import { Group } from '../../../../../models/users/group/group';
import { UserListComponent } from '../../../../../models/users/user/user-list/user-list.component';
import { ClientNavComponent } from '../_nav/client-nav.component';

export interface ClientUsersViewParams extends BaseRouteParams {
  c_menu: CLIENT_ROUTES;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
}

export interface ClientUsersViewData extends ClientUsersViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-users-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, UserListComponent, ClientNavComponent, IconComponent],
  templateUrl: './client-users-view.component.pug',
  styleUrls: ['./client-users-view.component.sass'],
})
export class ClientUsersViewComponent extends BaseDetailViewComponent<ClientUsersViewData, Client> {
  public clientFilter$!: Observable<FilterDefaults>;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public reload$ = new Subject<void | boolean>();

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ClientUsersViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link<Group>(data.group_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.clientFilter$ = this.data$.pipe(map((d) => ({ client: d.client.id })));
  }
}
