import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-articles',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-lifecycle-step-articles.component.pug',
  styleUrls: ['./tarif-lifecycle-step-articles.component.sass'],
})
export class TarifLifecycleStepArticlesComponent {
  @Input() public tarif!: Tarif;
  @Input() public step!: TarifLifecycleStep;
  @Output() public next = new EventEmitter<void>();
}
