import {
  Component,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from "@angular/core";
import { OfferViewData, OfferViewParams } from "../menu";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { OfferNotificationDestinationListComponent } from "../../../../../models/offers/offer-notification-destination/offer-notification-destination-list/offer-notification-destination-list.component";
import { firstValueFrom, map, Observable, Subject } from "rxjs";
import {
  DataMessageService,
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { ActivatedRoute, Router } from "@angular/router";
import { OfferActionService } from "../../../../../models/offers/offer/offer-action.service";
import { Offer } from "../../../../../models/offers/offer/offer";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { OfferNotificationDestinationCreateComponent } from "../../../../../models/offers/offer-notification-destination/offer-notification-destination-create/offer-notification-destination-create.component";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { OfferNotificationDestination } from "../../../../../models/offers/offer-notification-destination/offer-notification-destination";
import { Contact } from "../../../../../models/users/contact/contact";
import { Resto } from "../../../../../models/structures/resto/resto";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { ContactManageComponent } from "../../../../../models/users/contact/contact-manage/contact-manage.component";
import { ContactService } from "../../../../../models/users/contact/contact.service";
import { User } from "../../../../../models/users/user/user";
import { Group } from "../../../../../models/users/group/group";
import { Client } from "../../../../../models/structures/client/client";
import { Storage } from "../../../../../models/structures/storage/storage";
import { OFFER_USER_LEVEL } from "../../../../../models/offers/offer/offer.base";

export interface OfferNotificationDestinationsViewParams
  extends OfferViewParams {
  resto_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  contact_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
}

export interface OffertNotificationDestinationsViewData
  extends OfferViewData,
    OfferNotificationDestinationsViewParams {}

// Action types
type AddContactAction = ModelListAction<OfferNotificationDestination> & {
  action: "addContact";
};
type AddOtherContactAction = ModelListAction<OfferNotificationDestination> & {
  action: "addOtherContact";
};
type EditContactAction = ModelListAction<
  OfferNotificationDestination,
  Contact
> & { action: "editContact" };
type DeleteDestination = ModelListAction<OfferNotificationDestination> & {
  action: "deleteDestination";
};
type ToggleDestination = ModelListAction<OfferNotificationDestination> & {
  action: "toggleDestination";
};

@Component({
  selector: "lvadg-offer-destinations-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    OfferNavComponent,
    OfferNotificationDestinationCreateComponent,
    OfferNotificationDestinationListComponent,
    ContactManageComponent,
    TodoComponent,
  ],
  templateUrl: "./offer-destinations-view.component.pug",
  styleUrl: "./offer-destinations-view.component.sass",
})
export class OfferDestinationsViewComponent extends BaseDetailViewComponent<
  OffertNotificationDestinationsViewData,
  Offer
> {
  // Optional link routes
  public resto_detail_route?: Link<Resto>;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public contact_detail_route?: Link<Contact>;
  public client_detail_route?: Link<Client>;
  public storage_detail_route?: Link<Storage>;

  // Lilst fields
  public reload$ = new Subject<void | boolean>();
  public offerFilter$!: Observable<FilterDefaults>;
  public default_fields = [
    "target",
    "type",
    "resto_details",
    "email",
    "contact_details",
    "user_details",
    "group_details",
    "status",
    "actions",
  ];
  public readonly OUL = OFFER_USER_LEVEL;

  // Current items signals
  public currentOffer = signal<Offer | null>(null);
  public currentResto = signal<Resto | null>(null);
  public currentDest = signal<OfferNotificationDestination | null>(null);
  public currentContact = signal<Contact | null>(null);

  // Offcanvas management
  @ViewChild("ofcSlot", { static: false })
  public ofcSlot!: ElementRef<HTMLDivElement>;
  public ofcInstance?: NgbOffcanvasRef;
  @ViewChild("ofcContactSlot", { static: false })
  public ofcContactSlot!: ElementRef<HTMLDivElement>;
  public ofcContactInstance?: NgbOffcanvasRef;
  @ViewChild("ofcRemoveSlot", { static: false })
  public ofcRemoveSlot!: ElementRef<HTMLDivElement>;
  public ofcRemoveInstance?: NgbOffcanvasRef;

  // Services
  private _ofc = inject(NgbOffcanvas);
  private _cts = inject(ContactService);
  private _msgs = inject(DataMessageService);

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _oa: OfferActionService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    // Set filer for this offer
    this.offerFilter$ = this.data$.pipe(
      map((data) => ({ offer: data.offer.id })),
    );
  }

  /**
   * Set route date, current offer signal, and optional links routes.
   * @param data
   */
  public override setRouteData(data: OffertNotificationDestinationsViewData) {
    super.setRouteData(data);
    this.currentOffer.set(data.offer);
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link(
        data.resto_detail_route,
        data,
        this._router,
      );
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link(
        data.user_detail_route,
        data,
        this._router,
      );
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(
        data.group_detail_route,
        data,
        this._router,
      );
    }
    if (data.contact_detail_route) {
      this.contact_detail_route = new Link(
        data.contact_detail_route,
        data,
        this._router,
      );
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link(
        data.client_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(
        data.storage_detail_route,
        data,
        this._router,
      );
    }
  }

  // ACTIONS
  /**
   * Update destinations for this offer, and reload list.
   * Update checks if all enabled restos have an entry, and disables the ones no more in offerrestos.
   * @param offer
   */
  public async updateDestinations(offer: Offer) {
    const res = await this._oa.action(offer, {
      action: "update_notification_destinations",
    });
    if (res.success) {
      this.reload$.next(true);
    }
  }

  /**
   * List actions dispatcher.
   * - Reset current resto and destination.
   * - Open offcanvas or do action directly.
   *
   * @param action : list action
   */
  public async onListAction(
    action:
      | AddContactAction
      | AddOtherContactAction
      | EditContactAction
      | DeleteDestination
      | ToggleDestination,
  ) {
    this.currentResto.set(null);
    this.currentDest.set(null);
    switch (action.action) {
      case "addContact":
        return this.addContact(action);
      case "addOtherContact":
        return this.addContact(action);
      case "editContact":
        return this.editContact(action);
      case "deleteDestination":
        return this.deleteDestination(action);
      case "toggleDestination":
        return this.toggleDestination(action);
    }
  }

  /**
   * Open the offcanvas to add a destination.
   */
  public addDestination() {
    if (this.ofcInstance) {
      this.ofcInstance.dismiss();
    } else {
      this.ofcInstance = this._ofc.open(this.ofcSlot, {
        position: "bottom",
        panelClass: "h-auto",
      });
      this.ofcInstance.result.finally(() => {
        this.currentResto.set(null);
        this.currentDest.set(null);
        this.reload$.next(true);
        this.ofcInstance = undefined;
      });
    }
  }

  /**
   * Close the offcanvas once a destination is created.
   */
  public onCreatedDestination() {
    this.ofcInstance?.close();
  }

  public addContact(action: AddContactAction | AddOtherContactAction) {
    if (action.model) {
      this.currentDest.set(action.model);
      if (action.model.resto_details) {
        this.currentResto.set(action.model.resto_details);
      }
      this.addDestination();
    }
  }

  /**
   * Open the offcanvas to edit a contact.
   * @param action
   */
  public async editContact(action: EditContactAction) {
    if (action.model && action.model.contact_details) {
      let contact: Contact | null = null;
      try {
        contact = await firstValueFrom(
          this._cts.fetch(action.model.contact_details.id),
        );
      } catch (error) {
        this._msgs.error("Vous n'avez pas les droits pour modifier ce contact");
        return;
      }
      this.currentContact.set(contact);
      if (this.ofcContactInstance) {
        this.ofcContactInstance.dismiss();
      } else {
        this.ofcContactInstance = this._ofc.open(this.ofcContactSlot, {
          position: "bottom",
          panelClass: "h-auto",
        });
        this.ofcContactInstance.result.finally(() => {
          this.reload$.next(true);
          this.currentContact.set(null);
          this.ofcContactInstance = undefined;
        });
      }
    }
  }

  /**
   * Open the offcanvas to delete a destination.
   * @param action
   */
  public deleteDestination(action: DeleteDestination) {
    if (action.model) {
      this.currentDest.set(action.model);
      if (this.ofcRemoveInstance) {
        this.ofcRemoveInstance.dismiss();
        this.currentDest.set(null);
      } else {
        this.ofcRemoveInstance = this._ofc.open(this.ofcRemoveSlot, {
          position: "end",
          panelClass: "",
        });
        this.ofcRemoveInstance.result.finally(() => {
          this.reload$.next(true);
          this.currentDest.set(null);
          this.ofcRemoveInstance = undefined;
        });
      }
    }
  }

  /**
   * Process the remove destination action.
   * @param full if true, remove the destination from db, else just disable it (removed status)
   */
  async processRemove(full: boolean) {
    if (this.currentDest() && this.currentOffer()) {
      const res = await this._oa.action(this.currentOffer()!, {
        action: "remove_offer_destination",
        destination_id: this.currentDest()!.id,
        full: `${full}`,
      });
      if (res.success) {
        this.reload$.next(true);
        this.ofcRemoveInstance?.close();
      }
    }
  }

  /**
   * Toggle the destination status.
   * @param action
   */
  public async toggleDestination(action: ToggleDestination) {
    if (action.model && this.currentOffer()) {
      const res = await this._oa.action(this.currentOffer()!, {
        action: "toggle_offer_destination",
        destination_id: action.model.id,
      });
      if (res.success) {
        this.reload$.next(true);
      }
    }
  }
}
