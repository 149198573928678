import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams } from '@solidev/data';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { VivalyaBillItem } from '../../../../models/sales/vivalya-bill-item/vivalya-bill-item';

export interface VivalyaBillItemDetailViewParams extends BaseRouteParams {}

export interface VivalyaBillItemDetailViewData extends VivalyaBillItemDetailViewParams {
  vivalyabillitem: VivalyaBillItem;
}

@Component({
  selector: 'lvadg-vivalya-bill-item-detail-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent],
  templateUrl: './vivalya-bill-item-detail-view.component.pug',
  styleUrls: ['./vivalya-bill-item-detail-view.component.sass'],
})
export class VivalyaBillItemDetailViewComponent extends BaseDetailViewComponent<
  VivalyaBillItemDetailViewData,
  VivalyaBillItem
> {}
