import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifDocument } from '../tarif-document';
import { TarifDocumentService } from '../tarif-document.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NgMathPipesModule } from 'ngx-pipes';
import { TarifBase } from '../../tarif/tarif.base';
import { TarifDisplayComponent } from '../../tarif/tarif-display/tarif-display.component';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { TARIFDOCUMENT_STATUSES } from '../tarif-document.base';

@Component({
  selector: 'lvadg-tarif-document-list',
  standalone: true,
  templateUrl: './tarif-document-list.component.pug',
  styleUrls: ['./tarif-document-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    NgMathPipesModule,
    PChoicePipe,
    SafeDeleteComponent,
    TarifDisplayComponent,
    ImageDisplayComponent,
  ],
})
export class TarifDocumentListComponent extends ModellistComponent<TarifDocument> {
  @Input() public tarif_detail_route?: Link<TarifBase>;

  constructor(coll: TarifDocumentService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  public override getRowClasses(row: TarifDocument): string[] {
    // FIXME: use text-xxx utilities when https://github.com/twbs/bootstrap/pull/38826 is merged
    const cls = super.getRowClasses(row);
    if (row.status === TARIFDOCUMENT_STATUSES.VALID) {
      cls.push('table-success');
    }
    if (
      row.status === TARIFDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE ||
      row.status === TARIFDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE
    ) {
      cls.push('table-secondary');
    }

    return cls;
  }
}
