import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  primaryField,
} from "@solidev/data";

export enum IOQRCODE_SIZES {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  EXTRA_LARGE = "xl",
}

export const IOQRCODE_SIZES_CHOICES = [
  { value: IOQRCODE_SIZES.EXTRA_SMALL, desc: "Extra small" },
  { value: IOQRCODE_SIZES.SMALL, desc: "Small" },
  { value: IOQRCODE_SIZES.MEDIUM, desc: "Medium" },
  { value: IOQRCODE_SIZES.LARGE, desc: "Large" },
  { value: IOQRCODE_SIZES.EXTRA_LARGE, desc: "Extra large" },
];

export enum IOQRCODE_COLOR {
  BLACK = "bk",
  GREEN = "gr",
  BLUE = "bl",
}

export const IOQRCODE_COLOR_CHOICES = [
  { value: IOQRCODE_COLOR.BLACK, desc: "Noir" },
  { value: IOQRCODE_COLOR.GREEN, desc: "Vert" },
  { value: IOQRCODE_COLOR.BLUE, desc: "Bleu" },
];

export enum IOQRCODE_LOGO {
  NONE = "nn",
  LVADG_SEA = "ls",
  LVADG_FL = "lf",
}

export const IOQRCODE_LOGO_CHOICES = [
  { value: IOQRCODE_LOGO.NONE, desc: "Aucun logo" },
  { value: IOQRCODE_LOGO.LVADG_SEA, desc: "Lavieadugout marée" },
  { value: IOQRCODE_LOGO.LVADG_FL, desc: "Lavieadugout fruits et légumes" },
];

export class IoqrcodeBase extends DataModel {
  static override readonly __name: string = "IoqrcodeBase";

  @primaryField({ name: "id", description: "ID" })
  public override id!: number;

  @datetimeField({
    name: "updated",
    description: "updated",
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({ name: "code", description: "code", related: "IoCode" })
  public code!: number;

  @charField({
    name: "size",
    description: "Size",
    defaultValue: "md",
    maxLength: 2,
    choices: [
      { value: "xs", desc: "Extra small" },
      {
        value: "sm",
        desc: "Small",
      },
      { value: "md", desc: "Medium" },
      {
        value: "lg",
        desc: "Large",
      },
      { value: "xl", desc: "Extra large" },
    ],
  })
  public size: string = "md";

  @charField({
    name: "color",
    description: "Color",
    defaultValue: "bk",
    maxLength: 2,
    choices: [
      { value: "bk", desc: "Black" },
      {
        value: "gr",
        desc: "Green",
      },
      { value: "bl", desc: "Blue" },
    ],
  })
  public color: string = "bk";

  @charField({
    name: "logo",
    description: "Logo",
    defaultValue: "nn",
    maxLength: 2,
    choices: [
      { value: "nn", desc: "None" },
      {
        value: "ls",
        desc: "Lavieadugout mar\u00e9e",
      },
      { value: "lf", desc: "Lavieadugout fruits et l\u00e9gumes" },
    ],
  })
  public logo: string = "nn";

  @datetimeField({ name: "created", description: "created" })
  public created!: Date;

  @charField({ name: "image", description: "image", maxLength: 100 })
  public image!: string;
}
