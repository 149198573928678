
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Ordergroup } from "./ordergroup";

@Injectable({
  providedIn: 'root'
})
export class OrdergroupService extends Collection<Ordergroup> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/ordergroups", Ordergroup);
  }
}
