import { Component, OnInit, signal } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { AuthService } from "../../../models/users/auth.service";
import { BaseRouteParams, DataMessageService } from "@solidev/data";
import { CommonModule } from "@angular/common";

export interface LoginViewParams extends BaseRouteParams {}

export interface LoginViewData extends LoginViewParams {}

@Component({
  selector: "lvadg-login-view",
  templateUrl: "./login-view.component.pug",
  styleUrls: ["./login-view.component.sass"],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
})
export class LoginViewComponent implements OnInit {
  public error: string = "";
  public reactivate: boolean = false;
  public loginForm = new FormGroup({
    username: new FormControl<string>("", [Validators.required]),
    password: new FormControl<string>("", [Validators.required]),
  });
  public pwResetRoute?: string[];
  public reactivateRoute?: string[];
  public canLoginByIp = signal(false);

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private router: Router,
    private _msgs: DataMessageService,
  ) {}

  public ngOnInit() {
    this.pwResetRoute = this._auth.pwresetRoute;
    this.reactivateRoute = this._auth.reactivateRoute;
    this._auth.login("loginbyip", "loginbyip").subscribe((res) => {
      if (res === "success") {
        this.canLoginByIp.set(true);
      }
    });
  }

  public async submit(autologin: boolean = false) {
    this.error = "";
    this.reactivate = false;
    const username = autologin ? "loginbyip" : this.loginForm.value.username;
    const password = autologin ? "loginbyip" : this.loginForm.value.password;
    const res = await firstValueFrom(
      this._auth.login(username as string, password as string),
    );
    console.log("Login result", res);
    if (res === "success") {
      this._msgs.success("Connexion effectuée avec succès. Bienvenue.");
      const next = this._route.snapshot.queryParams["next"] || "/";
      await this.router.navigateByUrl(next);
    } else if (res === "inactive_account") {
      this._msgs.warning(
        "Echec de la connexion..",
        "Compte désactivé",
        null,
        1000,
      );
      this.error = "Votre compte a été désactivé.";
      this.reactivate = true;
    } else {
      this._msgs.warning("Echec de la connexion..", "", null, 1000);
      this.error =
        "Aucun compte actif n'a été trouvé avec les identifiants fournis.";
    }
  }
}
