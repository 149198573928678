import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { Offer } from "../../../../../models/offers/offer/offer";
import {
  OFFER_TYPES,
  OFFER_USER_LEVEL,
} from "../../../../../models/offers/offer/offer.base";
import { Link } from "@solidev/data";
import { OFFER_ROUTES } from "../menu";
import { Offertype } from "../../../../../models/offers/offertype/offertype";
import { OffertypeStorage } from "../../../../../models/offers/offertype-storage/offertype-storage";
import { AuthService } from "../../../../../models/users/auth.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "lvadg-offer-nav",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: "./offer-nav.component.pug",
  styleUrls: ["./offer-nav.component.sass"],
})
export class OfferNavComponent implements OnInit {
  @Input() public routes!: OFFER_ROUTES;
  @Input() public offer!: Offer;
  @Input() public offertype?: Offertype;
  @Input() public offertypestorage?: OffertypeStorage;

  public offer_detail_route?: Link<Offer>;
  public offer_edit_route?: Link<Offer>;
  public offer_documents_route?: Link<Offer>;
  public offer_print_preview_route?: Link<Offer>;
  public offer_notifications_route?: Link<Offer>;
  public offer_destinations_route?: Link<Offer>;
  public offer_notification_statuses_route?: Link<Offer>;
  public offer_products_route?: Link<Offer>;
  public offer_articles_route?: Link<Offer>;
  public offer_restos_route?: Link<Offer>;
  public offer_texts_route?: Link<Offer>;

  public ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _router: Router,
    private _user: AuthService,
  ) {}

  public ngOnInit() {
    this._user.currentUser$.subscribe((user) => {
      if (!this.routes) {
        throw new Error("Missing routes input");
      }
      if (
        this.routes.offer_detail_route &&
        this.offer.have_level(OFFER_USER_LEVEL.o_read)
      ) {
        this.offer_detail_route = new Link(
          this.routes.offer_detail_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_edit_route &&
        this.offer.have_level(OFFER_USER_LEVEL.o_write)
      ) {
        this.offer_edit_route = new Link(
          this.routes.offer_edit_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_documents_route &&
        (this.offer.have_level(OFFER_USER_LEVEL.o_read) || user?.is_staff)
      ) {
        this.offer_documents_route = new Link(
          this.routes.offer_documents_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_notifications_route &&
        this.offer.have_level(OFFER_USER_LEVEL.o_write)
      ) {
        this.offer_notifications_route = new Link(
          this.routes.offer_notifications_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_notification_statuses_route &&
        (this.offer.have_level(OFFER_USER_LEVEL.o_read) || user?.is_staff)
      ) {
        this.offer_notification_statuses_route = new Link(
          this.routes.offer_notification_statuses_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_products_route &&
        (this.offer.type === OFFER_TYPES.PRODUCT_GLOBAL ||
          this.offer.type === OFFER_TYPES.PRODUCT_LOCAL) &&
        this.offer.have_level(OFFER_USER_LEVEL.o_read)
      ) {
        this.offer_products_route = new Link(
          this.routes.offer_products_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_texts_route &&
        this.offer.have_level(OFFER_USER_LEVEL.o_write)
      ) {
        this.offer_texts_route = new Link(
          this.routes.offer_texts_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_print_preview_route &&
        this.offer.have_level(OFFER_USER_LEVEL.o_write)
      ) {
        this.offer_print_preview_route = new Link(
          this.routes.offer_print_preview_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_articles_route &&
        this.offer.have_level(OFFER_USER_LEVEL.o_read) &&
        !(
          this.offer.type === OFFER_TYPES.PRODUCT_GLOBAL ||
          this.offer.type === OFFER_TYPES.PRODUCT_LOCAL
        )
      ) {
        this.offer_articles_route = new Link(
          this.routes.offer_articles_route,
          this,
          this._router,
        );
      }
      if (
        this.routes.offer_restos_route &&
        (this.offer.have_level(OFFER_USER_LEVEL.o_write) || user?.is_staff)
      ) {
        this.offer_restos_route = new Link(
          this.routes.offer_restos_route,
          this,
          this._router,
        );
      }
      if (this.routes.offer_destinations_route) {
        this.offer_destinations_route = new Link(
          this.routes.offer_destinations_route,
          this,
          this._router,
        );
      }
      this.ready.next(true);
    });
  }
}
