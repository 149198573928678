
<ng-template #storageChoice>
  <h1>Choix du dépôt</h1>
</ng-template>
<ng-template #producerChoice>
  <form class="row" (submit)="saveProducer()" [formGroup]="producerForm">
    <div class="col-12 fs-4">
      <data-dispedit [inputClasses]="'form-control-lg form-control'" [model]="item" field="producer" mode="form" [form]="producerForm" [collection]="producers$">Choix du producteur</data-dispedit>
    </div>
    <div class="col-12 mt-3">
      <button class="w-100 p-2 btn btn-outline-primary" type="submit">Valider le producteur</button>
    </div>
  </form>
</ng-template>
<ng-template #selectedProducer>
  <div class="card h-100">
    <div class="card-header fs-4">Producteur sélectionné</div>
    <div class="card-body">
      <div class="fs-6" *ngIf="producerDetails(); let producer">
        <dl>
          <dt>Nom</dt>
          <dd class="ps-3">{{ producer.name }}</dd>
        </dl>
        <ng-container *ngIf="producer.location_details; let ld">
          <dt>Adresse</dt>
          <dd class="ps-3">{{ld.address}} {{ld.city}} {{ld.postcode}}</dd>
        </ng-container>
        <dt>Dépôts associés</dt>
        <dd class="ps-3">
          <div *ngFor="let rs of producer.storage_relations_details"> {{ rs.storage_details?.name}}</div>
        </dd>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #familyChoice>
  <form class="row" (submit)="saveFamily()" [formGroup]="familyForm">
    <div class="col-12 fs-4">
      <data-dispedit [model]="item" field="family" mode="form" [form]="familyForm" [collection]="families$" [filter]="{type: FAMILY_TYPE.VIVALYA, level_gte: 4}">Choix de la famille</data-dispedit>
    </div>
    <div class="col-12 mt-4">
      <button class="w-100 p-2 btn btn-outline-primary" type="submit">Valider la famille</button>
    </div>
  </form>
</ng-template>
<ng-template #familyInfos>
  <div class="card h-100">
    <div class="card-header fs-4">Famille sélectionnée</div>
    <div class="card-body">
      <div class="fs-6" *ngIf="familyDetails(); let family">
        <dl>
          <dt>Famille</dt>
          <dd>{{ family.truncated_path() }}</dd>
          <dt>Image par défaut</dt>
          <dd>
            <lvadg-image-display [image]="family.clogo_details" mode="preview" [hover]="false"></lvadg-image-display>
          </dd>
          <dt>Nom article par défaut</dt>
          <dd>{{ family.article_name}}</dd>
          <dt>Description produit par défaut</dt>
          <dd>{{ family.article_description || '-'}}</dd>
        </dl>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #productCreation>
  <h3>Création du produit</h3>
  <p class="text-info">Seules les principales informations sont à renseigner ici, vous pourrez compléter la fiche produit plus tard.</p>
  <form [formGroup]="createForm" (submit)="saveProduct()">
    <data-dispedit [model]="item" field="name" mode="form" [form]="createForm">Nom du produit</data-dispedit>
    <data-dispedit [model]="item" field="content_src" mode="form" [form]="createForm">Description</data-dispedit>
    <div class="mt-3">
      <lvadg-seasons-manage [seasons]="item.seasons" [editable]="true" size="medium" (changed)="seasonsChanged($event)">Saisons</lvadg-seasons-manage>
    </div>
    <div class="mt-3">
      <button class="w-100 p-3 btn btn-outline-primary" type="submit" [disabled]="!createForm.value.name">Créer le produit</button>
      <div class="text-warning" *ngIf="!createForm.value.name">Le nom du produit est obligatoire</div>
    </div>
  </form>
</ng-template>
<ng-template #editProduct>
  <ng-container *ngIf="product(); let p">
    <h3>Compléter la fiche produit</h3>
    <p class="text-info">Vous pouvez maintenant compléter la fiche produit.</p>
    <div class="mt-3">
      <button class="w-100 p-3 btn btn-outline-primary" (click)="created.emit(p)">Édition terminée</button>
    </div>
    <lvadg-product-manage [product]="p"></lvadg-product-manage>
    <div class="mt-3">
      <button class="w-100 p-3 btn btn-outline-primary" (click)="created.emit(p)">Édition terminée</button>
    </div>
  </ng-container>
</ng-template>
<div class="dheader">
  <nav class="nav nav-pills"><span class="w-25 nav-link active" role="button" (click)="familyDetails.set(null); producerDetails.set(null)" [class.active]="!producerDetails()">1. Choix du producteur</span><span class="w-25 nav-link" role="button" (click)="familyDetails.set(null)" [class.active]="producerDetails() &amp;&amp; !familyDetails()">2. Choix de la famille</span><span class="w-25 nav-link" role="button" [class.active]="producerDetails() &amp;&amp; familyDetails() &amp;&amp; !product()">3. Création du produit</span><span class="w-25 nav-link" role="button" [class.active]="!!product()">4. Compléter la fiche produit</span></nav>
</div>
<div class="dcontent">
  <ng-container *ngIf="!producerDetails()">
    <div class="row">
      <div class="col-8">
        <ng-container *ngTemplateOutlet="producerChoice"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="producerDetails() &amp;&amp; !product()">
    <div class="row">
      <div class="col-4">
        <ng-container *ngTemplateOutlet="selectedProducer"></ng-container>
      </div>
      <ng-container *ngIf="!familyDetails()">
        <div class="col-8">
          <ng-container *ngTemplateOutlet="familyChoice"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="familyDetails()">
        <div class="col-4">
          <ng-container *ngTemplateOutlet="familyInfos"></ng-container>
        </div>
        <div class="col-4">
          <ng-container *ngTemplateOutlet="productCreation"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="product()">
    <div class="row">
      <div class="col-12">
        <ng-container *ngTemplateOutlet="editProduct"></ng-container>
      </div>
    </div>
  </ng-container>
</div>