import {
  OFFERTYPE_STORAGE_LINKS_ROUTES,
  OFFERTYPE_STORAGE_ROUTES,
} from "./menu";
import { RData, RouteConfigItem, RoutesDefinition } from "@solidev/data";
import { RS } from "../../../../constants";
import {
  OffertypeStorageEditViewComponent,
  OffertypeStorageEditViewParams,
} from "./edit/offertype-storage-edit-view.component";
import {
  OffertypeStoragePermissionsViewComponent,
  OffertypeStoragePermissionsViewParams,
} from "./permissions/offertype-storage-permissions-view.component";
import {
  OffertypeStorageOffersViewComponent,
  OffertypeStorageOffersViewParams,
} from "./offers/offertype-storage-offers-view.component";
import {
  gen_offer_detail_routes,
  OFFER_LINKS_ROUTES,
  OFFER_ROUTES,
} from "../../offers/detail/menu";
import { OfferResolver } from "../../../../models/offers/offer/offer.resolver";
import {
  OffertypeStorageDisplayViewComponent,
  OffertypeStorageDisplayViewParams,
} from "./display/offertype-storage-display-view.component";
import {
  OfferDisplayViewData,
  OfferDisplayViewParams,
} from "../../offers/detail/display/offer-display-view.component";
import { OFFER_TYPES } from "../../../../models/offers/offer/offer.base";

export const gen_offertypestorage_detail_routes = (
  routes: OFFERTYPE_STORAGE_ROUTES,
  links: OFFERTYPE_STORAGE_LINKS_ROUTES,
  offer_routes?: OFFER_ROUTES,
  offer_links_routes?: OFFER_LINKS_ROUTES,
  default_route?: RouteConfigItem,
): RoutesDefinition => {
  const routes_def: RoutesDefinition = [];

  if (routes.offertype_storage_detail_route) {
    routes_def.push({
      path: "",
      pathMatch: "full",
      component: OffertypeStorageDisplayViewComponent,
      data: RData<OffertypeStorageDisplayViewParams>({
        route: routes.offertype_storage_detail_route,
        ots_menu: routes,
        offertypestorage_offer_detail_route: {
          [OFFER_TYPES.PRODUCT_LOCAL]: offer_routes?.offer_detail_route,
          [OFFER_TYPES.PRODUCT_GLOBAL]: offer_routes?.offer_detail_route,
        },
        skip: true,
      }),
    });
  }
  if (routes.offertype_storage_edit_route) {
    routes_def.push({
      path: RS.edit,
      component: OffertypeStorageEditViewComponent,
      data: RData<OffertypeStorageEditViewParams>({
        route: routes.offertype_storage_edit_route,
        ots_menu: routes,
        title: "Édition",
      }),
    });
  }
  if (routes.offertype_storage_permissions_route) {
    routes_def.push({
      path: RS.permissions,
      component: OffertypeStoragePermissionsViewComponent,
      data: RData<OffertypeStoragePermissionsViewParams>({
        route: routes.offertype_storage_permissions_route,
        ots_menu: routes,
        group_detail_route: links.group_detail_route,
        user_detail_route: links.user_detail_route,
        title: "Permissions",
      }),
    });
  }
  if (
    routes.offertype_storage_offers_route &&
    (!offer_routes || !offer_links_routes || !offer_routes.offer_detail_route)
  ) {
    routes_def.push({
      path: RS.offers,
      component: OffertypeStorageOffersViewComponent,
      data: RData<OffertypeStorageOffersViewParams>({
        route: routes.offertype_storage_offers_route,
        ots_menu: routes,
        offer_detail_route: links.offer_detail_route,
        offertype_detail_route: links.offertype_detail_route,
        offertypestorage_detail_route: links.offertypestorage_detail_route,
        user_detail_route: links.user_detail_route,
        client_detail_route: links.client_detail_route,
        resto_detail_route: links.resto_detail_route,
        storage_detail_route: links.storage_detail_route,
        title: "Liste des offres",
      }),
    });
  }
  if (
    routes.offertype_storage_offers_route &&
    offer_routes &&
    offer_links_routes &&
    offer_routes.offer_detail_route &&
    default_route
  ) {
    routes_def.push({
      path: RS.offers,
      data: RData<OffertypeStorageOffersViewParams>({
        route: routes.offertype_storage_offers_route,
        ots_menu: routes,
        offer_detail_route: links.offer_detail_route,
        offertype_detail_route: links.offertype_detail_route,
        offertypestorage_detail_route: links.offertypestorage_detail_route,
        user_detail_route: links.user_detail_route,
        client_detail_route: links.client_detail_route,
        resto_detail_route: links.resto_detail_route,
        storage_detail_route: links.storage_detail_route,
        title: "Liste des offres",
      }),
      children: [
        {
          path: "",
          pathMatch: "full",
          component: OffertypeStorageOffersViewComponent,
          data: RData<OffertypeStorageOffersViewParams>({
            route: routes.offertype_storage_offers_route,
            ots_menu: routes,
            offer_detail_route: links.offer_detail_route,
            offertype_detail_route: links.offertype_detail_route,
            offertypestorage_detail_route: links.offertypestorage_detail_route,
            user_detail_route: links.user_detail_route,
            client_detail_route: links.client_detail_route,
            resto_detail_route: links.resto_detail_route,
            storage_detail_route: links.storage_detail_route,
            title: "Liste des offres",
          }),
        },
        {
          path: ":offerId",
          resolve: {
            offer: OfferResolver,
          },
          data: RData<OfferDisplayViewParams>({
            route: offer_routes.offer_detail_route,
            o_menu: offer_routes,
            title: (d: OfferDisplayViewData) => d.offer._display,
          }),
          children: gen_offer_detail_routes(
            offer_routes,
            offer_links_routes,
            default_route,
          ),
        },
      ],
    });
  }

  return routes_def;
};
