import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarif } from '../article-tarif';
import { Link, PFactorcPipe } from '@solidev/data';
import { Tarif } from '../../tarif/tarif';

@Component({
  selector: 'lvadg-article-tarif-display',
  standalone: true,
  imports: [CommonModule, PFactorcPipe],
  templateUrl: './article-tarif-display.component.pug',
  styleUrls: ['./article-tarif-display.component.sass'],
})
export class ArticleTarifDisplayComponent {
  @Input() public articletarif?: ArticleTarif;
  @Input() public mode: 'tprice' | 'price' = 'tprice';
  @Input() public tarif_detail_route?: Link<Tarif>;

  constructor() {}
}
