import { charField, DataModel, datetimeField, foreignKeyField, primaryField } from '@solidev/data';

export enum OFFERRESTO_STATUSES {
  ACTIVE = 'OK',
  INACTIVE = 'KO',
}

export class OfferRestoBase extends DataModel {
  static override readonly __name: string = 'OfferRestoBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'status',
    description: 'Statut',
    defaultValue: OFFERRESTO_STATUSES.ACTIVE,
    choices: [
      { value: OFFERRESTO_STATUSES.ACTIVE, desc: 'Actif' },
      { value: OFFERRESTO_STATUSES.INACTIVE, desc: 'Désactivé' },
    ],
    maxLength: 2,
    priority: 300,
  })
  public status: OFFERRESTO_STATUSES = OFFERRESTO_STATUSES.ACTIVE;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'offer',
    description: 'ID Offre',
    related: 'Offer',
    priority: -1,
  })
  public offer!: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Resto',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;
}
