import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataModel, ModelList } from '@solidev/data';
import { CdkTable } from '@angular/cdk/table';

@Component({
  selector: 'lvadg-modellist-xlsx-export',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modellist-xlsx-export.component.pug',
  styleUrls: ['./modellist-xlsx-export.component.sass'],
})
export class ModellistXlsxExportComponent<T extends DataModel> {
  @Input({ required: true }) list!: ModelList<T>;
  @Input({ required: true }) dataTableSlot!: () => CdkTable<never> | undefined;
  @Input({ required: true }) name!: string;
  @Output() public xlsxExporting = new EventEmitter<boolean>();
  public exporting = false;

  // FIXME: this is waaay too basic
  // Maybe we could export the whole table to python and do the export there ?

  public async xlsxExport() {
    const slot = this.dataTableSlot();
    this.list.messages$.next({ dest: 'header', sort: 'hide', menu: 'hide' });
    this.xlsxExporting.emit(true);
    this.exporting = true;
    setTimeout(async () => {
      await import('xlsx').then(({ utils, writeFileXLSX }) => {
        if (slot) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const elt = (slot as any)._elementRef.nativeElement;
          const wb = utils.table_to_book(elt);
          writeFileXLSX(wb, (this.name || 'export') + '.xlsx');
          this.list.messages$.next({ dest: 'header' });
          this.exporting = false;
          this.xlsxExporting.emit(false);
        }
      });
    }, 1000);
  }
}
