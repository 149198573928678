import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { ArticleTarifLog } from '../article-tarif-log';
import { ArticleTarifLogService } from '../article-tarif-log.service';
import {
  FactorcPipe,
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { TarifDisplayComponent } from '../../tarif/tarif-display/tarif-display.component';
import { TarifBase } from '../../tarif/tarif.base';
import { UserBase } from '../../../users/user/user.base';

@Component({
  selector: 'lvadg-article-tarif-log-list',
  standalone: true,
  templateUrl: './article-tarif-log-list.component.pug',
  styleUrls: ['./article-tarif-log-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FactorcPipe,
    FactorcPipe,
    UserDisplayComponent,
    TarifDisplayComponent,
  ],
})
export class ArticleTarifLogListComponent extends ModellistComponent<ArticleTarifLog> {
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public user_detail_route?: Link<UserBase>;

  constructor(
    coll: ArticleTarifLogService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
