import { News } from '../news';
import { Component } from '@angular/core';
import { FiltersParams, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { NewsService } from '../news.service';
import { DatePipe } from '@angular/common';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';

@Component({
  standalone: true,
  selector: 'lvadg-news-list',
  templateUrl: './news-list.component.pug',
  imports: [...MODELLIST_IMPORTS, DatePipe, PChoicePipe, ImageDisplayComponent],
  styleUrls: ['./news-list.component.sass'],
})
export class NewsListComponent extends ModellistComponent<News> {
  public choices = {
    destinations: [
      { desc: 'Adhérents', value: 'member' },
      { desc: 'Clients', value: 'client' },
      { desc: 'Restos', value: 'resto' },
    ],
    pubstatus: [
      { desc: 'En cours de publication', value: 'cur' },
      { desc: 'Publication terminée', value: 'arc' },
      { desc: 'Publication future', value: 'fut' },
      { desc: 'Non publié', value: 'nok' },
    ],
  };

  constructor(coll: NewsService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
