import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '@solidev/data';
import { Family } from '../family';
import { CdkTreeModule } from '@angular/cdk/tree';
import { Router, RouterModule } from '@angular/router';
import { FamilyTree, FamilyTreeService } from '../family-tree.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FamilyBase } from '../family.base';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lvadg-family-tree',
  standalone: true,
  imports: [CommonModule, CdkTreeModule, RouterModule, ReactiveFormsModule],
  templateUrl: './family-tree.component.pug',
  styleUrls: ['./family-tree.component.sass'],
})
export class FamilyTreeComponent implements OnInit, OnDestroy {
  @Input() public name!: string;
  @Input() public root?: FamilyBase | number | null = null;
  @Input() public detail_route?: Link<FamilyBase>;
  @Input() public reload?: Subject<void | boolean>;
  @Output() public selected = new EventEmitter<FamilyBase>();
  public tree!: FamilyTree;
  public search = new FormControl<string>('');
  private _subscriptions = new Subject<void>();

  constructor(private _fts: FamilyTreeService, private _router: Router) {}

  ngOnInit(): void {
    this.tree = this._fts.load(this.name, this.root);
    this.tree.search(this.search.valueChanges);
    if (this.reload) {
      this.reload.pipe(takeUntil(this._subscriptions)).subscribe(() => {
        console.log('Family tree reload');
        this.tree.reload(true);
      });
    }
  }

  public ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public clickNode(node: Family) {
    if (this.tree.current === node.id) {
      if (this.tree.control.isExpanded(node)) {
        this.tree.collapse(node);
      } else {
        this.tree.expand(node);
      }
    }
    this.tree.setCurrent(node);
    this.selected.next(node);
  }
}
