
<ng-container *ngIf="data$|async; let data">
  <div class="tab-content">
    <lvadg-header-action level="h1" title="Articles catalogue" ri="catalog">
      <ng-container actions>
        <button class="btn btn-primary" (click)="create=!create" *ngIf="canCreate()">
          <lvadg-icon ri="add">Ajouter un article</lvadg-icon>
        </button>
      </ng-container>
    </lvadg-header-action>
  </div>
  <div class="card my-3" *ngIf="create">
    <div class="card-header">
      <lvadg-icon ri="add">Ajouter un article</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-article-create (created)="reload$.next(true); create=false; detail_route.navigate({article: $event})" (cancelled)="create=false"></lvadg-article-create>
    </div>
  </div>
  <lvadg-article-list [name]="data.route.name" [detail_route]="detail_route" [family_detail_route]="family_detail_route" [provider_detail_route]="provider_detail_route" [default_fields]="default_fields" [reload]="reload$" [print_settings]="print_settings" [keep]="true" [actions]="['print_fiche']"></lvadg-article-list>
</ng-container>