import { ADMIN_ROUTES } from "./main";
import {
  ADMIN_GROUPS,
  LVADG_GROUPS,
  META_GROUPS,
  SPECIAL_GROUPS,
} from "@vivalya/lvadg";
import { ADMIN_TARIFS_MENU } from "../pages/tarifs/menu";

interface ISubMenuItem {
  title: string;
  icon?: string;
  route: (string | number)[];
}

export interface IMenuItem {
  title: string;
  altTitle?: string;
  icon?: string;
  route: (string | number)[];
  children?: ISubMenuItem[];
  groups?: (META_GROUPS | SPECIAL_GROUPS | LVADG_GROUPS | ADMIN_GROUPS)[];
  exact?: boolean;
  onlyMenu?: boolean;
  onlyHome?: boolean;
}

export const ADMIN_MENU: IMenuItem[] = [
  {
    title: "Accueil",
    route: ADMIN_ROUTES.home.route(),
    children: [],
    groups: [SPECIAL_GROUPS.all],
    exact: true,
    onlyMenu: true,
  },

  {
    title: "Structure",
    altTitle: "Gestion de la structure",
    route: ADMIN_ROUTES.structure.route(),
    groups: [SPECIAL_GROUPS.staff, SPECIAL_GROUPS.superuser],
    exact: false,
    children: [
      { title: "Adhérents", route: ADMIN_ROUTES.structure_members.route() },
      { title: "Dépôts", route: ADMIN_ROUTES.structure_storages.route() },
      { title: "Clients", route: ADMIN_ROUTES.structure_clients.route() },
      { title: "Restaurants", route: ADMIN_ROUTES.structure_restos.route() },
      { title: "Producteurs", route: ADMIN_ROUTES.structure_producers.route() },
      {
        title: "Documents producteurs",
        route: ADMIN_ROUTES.structure_producers_documents.route(),
      },
      {
        title: "Fournisseurs",
        route: ADMIN_ROUTES.structure_providers.route(),
      },
      {
        title: "Documents fournisseurs",
        route: ADMIN_ROUTES.structure_providers_documents.route(),
      },
      {
        title: "Plateformes",
        route: ADMIN_ROUTES.structure_platforms.route(),
      },
      {
        title: "Relations",
        route: ADMIN_ROUTES.structure_relations.route(),
      },
    ],
  },
  {
    title: "Localisation",
    altTitle: "Gestion des données géographiques",
    route: ADMIN_ROUTES.locations.route(),
    children: [
      {
        title: "Localisations",
        route: ADMIN_ROUTES.locations_locations.route(),
      },
      {
        title: "Régions génériques",
        route: ADMIN_ROUTES.locations_genericregions.route(),
      },
      {
        title: "Distances",
        route: ADMIN_ROUTES.locations_distances.route(),
      },
      { title: "Pays", route: ADMIN_ROUTES.locations_geocountries.route() },
      { title: "Régions", route: ADMIN_ROUTES.locations_georegions.route() },
      {
        title: "Départements",
        route: ADMIN_ROUTES.locations_geodepartements.route(),
      },
      { title: "Communes", route: ADMIN_ROUTES.locations_geocommunes.route() },
      {
        title: "Cartes utilisteurs",
        route: ADMIN_ROUTES.locations_usermaps.route(),
      },
    ],
  },
  {
    title: "Utilisateurs",
    altTitle: "Gestion des utilisateurs",
    route: ADMIN_ROUTES.access.route(),
    children: [
      { title: "Utilisateurs", route: ADMIN_ROUTES.access_users.route() },
      { title: "Groupes", route: ADMIN_ROUTES.access_groups.route() },
      { title: "Contacts", route: ADMIN_ROUTES.access_contacts.route() },
    ],
  },
  {
    title: "Catalogue",
    altTitle: "Gestion du catalogue",
    route: ADMIN_ROUTES.catalog.route(),
    children: [
      { title: "Labels", route: ADMIN_ROUTES.catalog_labels.route() },
      { title: "Familles", route: ADMIN_ROUTES.catalog_families.route() },
      {
        title: "Match familles FL",
        route: ADMIN_ROUTES.catalog_families_match.route("FL"),
      },
      {
        title: "Match familles Marée",
        route: ADMIN_ROUTES.catalog_families_match.route("SEA"),
      },
      { title: "Produits", route: ADMIN_ROUTES.catalog_products.route() },
      {
        title: "Articles",
        route: ADMIN_ROUTES.catalog_articles.route(),
      },
      {
        title: "Documents articles",
        route: ADMIN_ROUTES.catalog_articles_documents.route(),
      },
      {
        title: "Articles externes",
        route: ADMIN_ROUTES.catalog_earticles.route(),
      },
      {
        title: "Collections externes",
        route: ADMIN_ROUTES.catalog_eacollections.route(),
      },
      { title: "Métadonnées", route: ADMIN_ROUTES.catalog_meta.route() },
      {
        title: "Colonnes Ciqual",
        route: ADMIN_ROUTES.catalog_ciqual_columns.route(),
      },
      {
        title: "Aliments Ciqual",
        route: ADMIN_ROUTES.catalog_ciqual_alims.route(),
      },
    ],
  },
  ...ADMIN_TARIFS_MENU,
  {
    title: "Marchés",
    altTitle: "Gestion des donnés marchés",
    route: ADMIN_ROUTES.rnm.route(),
    children: [
      { title: "Marchés", route: ADMIN_ROUTES.rnm_marches.route() },
      { title: "Libellés", route: ADMIN_ROUTES.rnm_libelles.route() },
      { title: "Regroupement", route: ADMIN_ROUTES.rnm_rnms.route() },
      { title: "Cotations", route: ADMIN_ROUTES.rnm_cotations.route() },
      { title: "Prix cotations", route: ADMIN_ROUTES.rnm_tarifs.route() },

      { title: "Assoc. articles", route: ADMIN_ROUTES.rnm_articles.route() },
      { title: "Assoc. tarifs", route: ADMIN_ROUTES.rnm_atts.route() },
      { title: "Prix consolidés", route: ADMIN_ROUTES.rnm_atcaches.route() },
    ],
  },
  {
    title: "Offres",
    altTitle: "Gestion des offres",
    route: ADMIN_ROUTES.offers.route(),
    children: [
      {
        title: "Offres contractuelles",
        route: ADMIN_ROUTES.offers_offertypes.route(),
      },
      {
        title: "Offres contractuelles / dépôt",
        route: ADMIN_ROUTES.offers_offertypestorages.route(),
      },
      { title: "Toutes les offres", route: ADMIN_ROUTES.offers_offers.route() },
      {
        title: "Produits des offres",
        route: ADMIN_ROUTES.offers_offerproducts.route(),
      },
    ],
  },
  {
    title: "Ventes",
    altTitle: "Gestion des ventes",
    route: ADMIN_ROUTES.sales.route(),
    children: [
      { title: "Factures", route: ADMIN_ROUTES.sales_bills.route() },
      {
        title: "Lignes de factures",
        route: ADMIN_ROUTES.sales_billitems.route(),
      },
    ],
  },
  {
    title: "Commandes",
    altTitle: "Gestion des commandes",
    route: ADMIN_ROUTES.orders.route(),
    children: [
      {
        title: "Processus de commande",
        route: ADMIN_ROUTES.orders_processes.route(),
      },
      {
        title: "Permissions processus de commande",
        route: ADMIN_ROUTES.orders_permissions.route(),
      },
      {
        title: "Dépôts / plateformes",
        route: ADMIN_ROUTES.orders_storages.route(),
      },
      {
        title: "Permissions dépôts / plateformes",
        route: ADMIN_ROUTES.orders_storagepermissions.route(),
      },
      {
        title: "Articles",
        route: ADMIN_ROUTES.orders_articles.route(),
      },
      {
        title: "Commandes",
        route: ADMIN_ROUTES.orders_orders.route(),
      },
      {
        title: "Lignes de commandes",
        route: ADMIN_ROUTES.orders_orderitems.route(),
      },
      {
        title: "Commandes consolidées",
        route: ADMIN_ROUTES.orders_groups.route(),
      },
    ],
  },
  {
    title: "Documents",
    altTitle: "Gestion des dossiers et documents",
    route: ADMIN_ROUTES.documents.route(),
    children: [],
  },
  {
    title: "Impressions",
    altTitle: "Gestion des mises en page",
    route: ADMIN_ROUTES.layouts.route(),
    children: [],
  },
  {
    title: "Données",
    altTitle: "Gestion des données personnalisées",
    route: ADMIN_ROUTES.cdatas.route(),
  },
  {
    title: "Outils",
    altTitle: "Outils divers",
    route: ADMIN_ROUTES.utils.route(),
  },
  {
    title: "Actualités",
    altTitle: "Actualité vivaliya",
    route: ADMIN_ROUTES.news.route(),
    children: [
      {
        title: "Categories",
        route: ADMIN_ROUTES.news_newscategories.route(),
      },
      {
        title: "Actualités",
        route: ADMIN_ROUTES.news_news.route(),
      },
      {
        title: "Commentaires d'actualités",
        route: ADMIN_ROUTES.news_comments.route(),
      },
    ],
  },
];
