import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RnmmarcheService} from '../rnmmarche.service';
import {
  debounceTime,
  Observable, switchMap,
} from 'rxjs';
import {Rnmmarche} from '../rnmmarche';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {NgbCollapse} from '@ng-bootstrap/ng-bootstrap';
import {IconComponent} from '../../../../components/utils/icon/icon.component';
import {Queryset} from "@solidev/data";

@Component({
  selector: 'lvadg-rnmmarche-search',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    NgbCollapse,
    IconComponent,
  ],
  templateUrl: './rnmmarche-search.component.pug',
  styleUrls: ['./rnmmarche-search.component.sass'],
})
export class RnmmarcheSearchComponent implements OnInit {
  public mlist$!: Observable<Rnmmarche[]>;
  public mqs!: Queryset<Rnmmarche>;
  public current?: number;
  public search = new FormControl('');
  @Input() public current$?: Observable<number | undefined>;
  @Output() public rnmSelected = new EventEmitter<Rnmmarche>();

  constructor(private _tts: RnmmarcheService) {
  }

  public ngOnInit() {
    this.mqs = this._tts.queryset();
    this.mqs
      .filter()
      .setFields([
        'id',
        'code',
        'facode',
        'type',
        'sname',
        'lname'
      ])
    this.mlist$ = this.mqs.get();
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((v) => this.mqs.filter({search: v}, true).get())
      )
      .subscribe((v) => {
      });
  }

  public rnmSelect(rnmm: Rnmmarche) {
    if (this.current == rnmm.id) {
      this.current = 0;
      // @ts-ignore
      this.rnmSelected.emit(null);
    } else {
      this.current = rnmm.id;
      this.rnmSelected.emit(rnmm);
    }
  }
}
