
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="storage">
  <li class="nav-item" *ngIf="storage_display_route"><a class="nav-link" [routerLink]="storage_display_route.route({storage: storage})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Fiche dépôt</a></li>
  <!-- Local producers-->
  <li class="nav-item" *ngIf="storage_local_producers_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_local_producers_route.route({storage: storage})" routerLinkActive="active">Producteurs<span class="badge bg-success ms-1">LOCAL</span></a></li>
  <!-- Local products-->
  <li class="nav-item" *ngIf="storage_local_products_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_local_products_route.route({storage: storage})" routerLinkActive="active">Produits<span class="badge bg-success ms-1">LOCAL</span></a></li>
  <!-- Global producers-->
  <li class="nav-item" *ngIf="storage_global_producers_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_global_producers_route.route({storage: storage})" routerLinkActive="active">Producteurs<span class="badge bg-primary ms-1">NATIONAL</span></a></li>
  <!-- Global products-->
  <li class="nav-item" *ngIf="storage_global_products_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_global_products_route.route({storage: storage})" routerLinkActive="active">Produits<span class="badge bg-primary ms-1">NATIONAL</span></a></li>
  <!-- Sea producers-->
  <li class="nav-item" *ngIf="storage_sea_producers_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_sea_producers_route.route({storage: storage})" routerLinkActive="active">Producteurs<span class="badge bg-info ms-1">MARÉE</span></a></li>
  <!-- Sea products-->
  <li class="nav-item" *ngIf="storage_sea_products_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_sea_products_route.route({storage: storage})" routerLinkActive="active">Produits<span class="badge bg-info ms-1">MARÉE</span></a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="storage_edit_route &amp;&amp; storage.have_level(SUL.ADMIN)"><a class="nav-link" [routerLink]="storage_edit_route.route({storage: storage})" routerLinkActive="active">Édition</a>
    <!-- Cdatas-->
  </li>
  <li class="nav-item" *ngIf="storage_cdatas_route &amp;&amp; storage.have_level(SUL.SADMIN)"><a class="nav-link" [routerLink]="storage_cdatas_route.route({storage: storage})" routerLinkActive="active">Données associées</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="storage_offers_route &amp;&amp; storage.have_level(SUL.DETAIL)"><a class="nav-link" [routerLink]="storage_offers_route.route({storage: storage})" routerLinkActive="active">Offres</a></li>
  <!-- Relations-->
  <li class="nav-item" *ngIf="storage_relations_route &amp;&amp; storage.have_level(SUL.SADMIN)"><a class="nav-link" [routerLink]="storage_relations_route.route({storage: storage})" routerLinkActive="active">Relations</a></li>
  <!-- Sales-->
  <li class="nav-item" *ngIf="storage_sales_route &amp;&amp; storage.have_level(SUL.SADMIN)"><a class="nav-link" [routerLink]="storage_sales_route.route({storage: storage})" routerLinkActive="active">Ventes</a></li>
  <!-- Users-->
  <li class="nav-item" *ngIf="storage_users_route &amp;&amp; storage.have_level(SUL.SADMIN)"><a class="nav-link" [routerLink]="storage_users_route.route({storage: storage})" routerLinkActive="active">Utilisateurs</a></li>
  <!-- Stats-->
  <li class="nav-item" *ngIf="storage_stats_route &amp;&amp; storage.have_level(SUL.SADMIN)"><a class="nav-link" [routerLink]="storage_stats_route.route({storage: storage})" routerLinkActive="active">Statistiques</a></li>
</ul>