import { charField, DataModel, datetimeField, detailsField, integerField, primaryField } from '@solidev/data';

export enum IMAGE_USAGE {
  PGMAP = 'PGMAP', //  "Pointeur de carte"
  HDISP = 'HDISP', //  "Header (site)"
  DPRNT = 'DPRNT', //  "Header (impression)"
  LDISP = 'LDISP', //  "Logo/icône (site)"
  LPRNT = 'LPRNT', //  "Logo/icône (impression)"
  FDISP = 'FDISP', //  "Arrière plan (site)"
  FPRNT = 'FPRNT', //  "Arrière plan (impression)"
  LOWQT = 'LOWQT', //  "Basse qualité"
  MEDQT = 'MEDQT', //  "Qualité moyenne"
  HIGQT = 'HIGQT', //  "Haute qualité"
  GDISP = 'GDISP', //  "Galerie (site)"
  GPRNT = 'GPRNT', //  "Galerie (impression)"
  WPERS = 'WPERS', //  "Photo de personne(s)"
  AFFIP = 'AFFIP', //  "Affiche portrait"
  AFFIL = 'AFFIL', //  "Affiche paysage"
  VIDEO = 'VIDEO', //  "Vidéo thumbnail"
}

export const IMAGE_USAGE_CHOICES = [
  { value: IMAGE_USAGE.PGMAP, desc: 'Pointeur de carte' },
  { value: IMAGE_USAGE.HDISP, desc: 'Header (site)' },
  { value: IMAGE_USAGE.DPRNT, desc: 'Header (impression)' },
  { value: IMAGE_USAGE.LDISP, desc: 'Logo/icône (site)' },
  { value: IMAGE_USAGE.LPRNT, desc: 'Logo/icône (impression)' },
  { value: IMAGE_USAGE.FDISP, desc: 'Arrière plan (site)' },
  { value: IMAGE_USAGE.FPRNT, desc: 'Arrière plan (impression)' },
  { value: IMAGE_USAGE.LOWQT, desc: 'Basse qualité' },
  { value: IMAGE_USAGE.MEDQT, desc: 'Qualité moyenne' },
  { value: IMAGE_USAGE.HIGQT, desc: 'Haute qualité' },
  { value: IMAGE_USAGE.GDISP, desc: 'Galerie (site)' },
  { value: IMAGE_USAGE.GPRNT, desc: 'Galerie (impression)' },
  { value: IMAGE_USAGE.WPERS, desc: 'Photo de personne(s)' },
  { value: IMAGE_USAGE.AFFIP, desc: 'Affiche portrait' },
  { value: IMAGE_USAGE.AFFIL, desc: 'Affiche paysage' },
  { value: IMAGE_USAGE.VIDEO, desc: 'Vidéo thumbnail' },
];

export const IMAGE_USAGE_CHOICES_DICT = {
  [IMAGE_USAGE.PGMAP]: 'Pointeur de carte',
  [IMAGE_USAGE.HDISP]: 'Header (site)',
  [IMAGE_USAGE.DPRNT]: 'Header (impression)',
  [IMAGE_USAGE.LDISP]: 'Logo/icône (site)',
  [IMAGE_USAGE.LPRNT]: 'Logo/icône (impression)',
  [IMAGE_USAGE.FDISP]: 'Arrière plan (site)',
  [IMAGE_USAGE.FPRNT]: 'Arrière plan (impression)',
  [IMAGE_USAGE.LOWQT]: 'Basse qualité',
  [IMAGE_USAGE.MEDQT]: 'Qualité moyenne',
  [IMAGE_USAGE.HIGQT]: 'Haute qualité',
  [IMAGE_USAGE.GDISP]: 'Galerie (site)',
  [IMAGE_USAGE.GPRNT]: 'Galerie (impression)',
  [IMAGE_USAGE.WPERS]: 'Photo de personne(s)',
  [IMAGE_USAGE.AFFIP]: 'Affiche portrait',
  [IMAGE_USAGE.AFFIL]: 'Affiche paysage',
  [IMAGE_USAGE.VIDEO]: 'Vidéo thumbnail',
};

export class ImageDisplay extends DataModel {
  static override readonly __name: string = 'ImageDisplay';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @charField({ name: 'file', description: 'file', maxLength: 500 })
  public file!: string;

  @charField({ name: 'mimetype', description: 'mimetype', maxLength: 255 })
  public mimetype!: string;

  @charField({ name: 'title', description: 'title', maxLength: 200 })
  public title!: string;

  @charField({ name: 'credits', description: 'credits', maxLength: 500 })
  public credits!: string;

  @integerField({ name: 'width', description: 'width', defaultValue: 0 })
  public width: number = 0;

  @integerField({ name: 'height', description: 'height', defaultValue: 0 })
  public height: number = 0;

  @detailsField({ name: 'usages', description: 'usages', defaultValue: [] })
  public usages: IMAGE_USAGE[] = [];

  @charField({ name: 'video', description: 'video', maxLength: 255 })
  public video!: string;

  public have_usages(...usages: IMAGE_USAGE[]) {
    return usages.some((u) => this.usages.includes(u));
  }
}

export class ImageBase extends ImageDisplay {
  static override readonly __name: string = 'ImageBase';

  @detailsField({ name: 'flags', description: 'flags', defaultValue: [] })
  public flags: string[] = [];

  @datetimeField({ name: 'created', description: 'created', readonly: true })
  public created!: Date;

  @datetimeField({ name: 'updated', description: 'updated', readonly: true })
  public updated!: Date;

  @integerField({ name: 'filesize', description: 'filesize' })
  public filesize!: number;

  @charField({ name: 'filename', description: 'filename', maxLength: 255 })
  public filename!: string;

  @charField({ name: 'legend', description: 'legend', maxLength: 500 })
  public legend!: string;

  @charField({ name: 'slug', description: 'slug', maxLength: 200 })
  public slug!: string;

  @integerField({ name: 'order', description: 'order', defaultValue: 0 })
  public order: number = 0;

  @charField({ name: 'type', description: 'type', maxLength: 30 })
  public type!: string;

  @charField({
    name: 'status',
    description: 'status',
    defaultValue: 'OK',
    maxLength: 4,
    choices: [
      { value: 'OK', desc: 'Affichage OK' },
      { value: 'KO', desc: 'Ne pas afficher' },
    ],
  })
  public status: string = 'OK';

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
