import { RoutesConfig } from "@solidev/data";
import { ArticleBase, RS } from "@vivalya/lvadg";

export const FRONT_CATALOG_ROUTES_NAMES = [
  "catalog",
  "catalog_offers",
  "catalog_offers_offer",
  "catalog_articles",
  "catalog_articles_article",
  "catalog_articles_article_articledocuments",
  "catalog_articles_article_edit",
  "catalog_articles_article_eamappings",
  "catalog_articles_article_prices",
  "catalog_articles_article_provider",
  "catalog_articles_article_providerdocuments",
  "catalog_articles_article_tarifs",
  "catalog_articles_article_quality",
  "catalog_families",
  "catalog_families_family",
  "catalog_families_family_article",
] as const;

export const FRONT_CATALOG_ROUTES_CONFIG: RoutesConfig<
  (typeof FRONT_CATALOG_ROUTES_NAMES)[number]
> = {
  catalog: { name: "catalog", params: [], route: () => [RS.catalog] },
  catalog_articles: {
    name: "catalog_article",
    params: [],
    route: () => ["/", RS.catalog, RS.articles],
  },
  catalog_articles_article: {
    name: "catalog_articles_article",
    params: ["article"],
    route: (article: ArticleBase) => ["/", RS.catalog, RS.articles, article.id],
  },
  catalog_articles_article_articledocuments: {
    name: "catalog_articles_article_articledocuments",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.articledocuments,
    ],
  },
  catalog_articles_article_eamappings: {
    name: "catalog_articles_article_eamappings",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.earticles,
    ],
  },
  catalog_articles_article_edit: {
    name: "catalog_articles_article_edit",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.edit,
    ],
  },
  catalog_articles_article_prices: {
    name: "catalog_articles_article_prices",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.prices,
    ],
  },
  catalog_articles_article_provider: {
    name: "catalog_articles_article_provider",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.provider,
    ],
  },

  catalog_articles_article_providerdocuments: {
    name: "catalog_articles_article_providerdocuments",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.providerdocuments,
    ],
  },
  catalog_articles_article_quality: {
    name: "catalog_articles_article_quality",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.quality,
    ],
  },
  catalog_articles_article_tarifs: {
    name: "catalog_articles_article_tarifs",
    params: ["article"],
    route: (article: ArticleBase) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.tarifs,
    ],
  },
  catalog_families: { name: "catalog_families", params: [], route: () => [] },
  catalog_families_family: {
    name: "catalog_families_family",
    params: [],
    route: () => [],
  },
  catalog_families_family_article: {
    name: "catalog_families_family_article",
    params: [],
    route: () => [],
  },
  catalog_offers: { name: "catalog_offers", params: [], route: () => [] },
  catalog_offers_offer: {
    name: "catalog_offers_offer",
    params: [],
    route: () => [],
  },
};
