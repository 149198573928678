
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { PackagingService} from "./packaging.service";
import { Packaging } from "./packaging";

@Injectable({
  providedIn: 'root'
})
export class PackagingResolver  {
  constructor(private _rs: PackagingService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Packaging> {
    return this._rs.fetch(+route.params["packagingId"])
  }
}
