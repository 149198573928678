
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ArticleTarifTemplateRelation } from "./article-tarif-template-relation";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifTemplateRelationService extends Collection<ArticleTarifTemplateRelation> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/articletariftemplaterelations", ArticleTarifTemplateRelation);
  }
}
