import { RoutesConfig } from "@solidev/data";
import {Provider, ProviderDocument, RS} from "@vivalya/lvadg";

export const FRONT_PROVIDERS_ROUTES_NAMES = [
  "providers",
  "providers_providers",
  "providers_providers_provider",
  "providers_providers_provider_articles",
  "providers_providers_provider_documents",
  "providers_providers_provider_edit",
  "providers_documents",
  "providers_documents_document",
] as const;

export const FRONT_PROVIDERS_ROUTES_CONFIG: RoutesConfig<
  (typeof FRONT_PROVIDERS_ROUTES_NAMES)[number]
> = {
  providers: {
    name: "providers",
    params: [],
    route: () => ["/", RS.providers],
  },
  providers_providers: {
    name: "providers_providers",
    params: [],
    route: () => ["/", RS.providers, RS.providers],
  },
  providers_providers_provider: {
    name: "providers_providers_provider",
    params: ["provider"],
    route: (s: Provider) => ["/", RS.providers, RS.providers, s.id],
  },
  providers_providers_provider_articles: {
    name: "providers_providers_provider_articles",
    params: ["provider"],
    route: (s: Provider) => [
      "/",
      RS.providers,
      RS.providers,
      s.id,
      RS.articles,
    ],
  },
  providers_providers_provider_documents: {
    name: "providers_providers_provider_documents",
    params: ["provider"],
    route: (s: Provider) => [
      "/",
      RS.providers,
      RS.providers,
      s.id,
      RS.documents,
    ],
  },
  providers_providers_provider_edit: {
    name: "providers_providers_provider_edit",
    params: ["provider"],
    route: (s: Provider) => ["/", RS.providers, RS.providers, s.id, RS.edit],
  },
  providers_documents: {
    params: [],
    route: () => ["/", RS.providers, RS.documents],
    name: "providers_documents",
  },
  providers_documents_document: {
    params: ["providerdocument"],
    route: (providerdocument: ProviderDocument) => [
      "/",
      RS.providers,
      RS.documents,
      providerdocument.id,
    ],
    name: "providers_documents_document",
  },
};
