import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IOCODE_TYPES } from '../iocode.base';
import { Iocode } from '../iocode';
import { IocodeService } from '../iocode.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-iocode-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './iocode-create.component.pug',
  styleUrls: ['./iocode-create.component.sass'],
})
export class IocodeCreateComponent {
  @Output() public created = new EventEmitter<Iocode>();
  @Output() public cancelled = new EventEmitter<void>();
  public form = new FormGroup({
    type: new FormControl<IOCODE_TYPES>(IOCODE_TYPES.REDIRECT, Validators.required),
    url: new FormControl<string | null>(null),
    data: new FormControl<string | null>(null),
    ttl: new FormControl<number>(24 * 3600 * 30, Validators.required),
  });
  public ttl_values = [
    { label: '1 heure', value: 3600 },
    { label: '2 heures', value: 3600 * 2 },
    { label: '6 heures', value: 3600 * 6 },
    { label: '12 heures', value: 3600 * 12 },
    { label: '1 jour', value: 3600 * 24 },
    { label: '2 jours', value: 3600 * 24 * 2 },
    { label: '3 jours', value: 3600 * 24 * 3 },
    { label: '4 jours', value: 3600 * 24 * 4 },
    { label: '1 mois', value: 3600 * 24 * 30 },
    { label: '2 mois', value: 3600 * 24 * 30 * 2 },
    { label: '3 mois', value: 3600 * 24 * 30 * 3 },
    { label: '6 mois', value: 3600 * 24 * 30 * 6 },
    { label: '1 an', value: 3600 * 24 * 30 * 12 },
    { label: '2 ans', value: 3600 * 24 * 30 * 12 * 2 },
    { label: '3 ans', value: 3600 * 24 * 30 * 12 * 3 },
  ];
  public iocode_types = [
    { label: 'Redirection', value: IOCODE_TYPES.REDIRECT },
    { label: 'Données', value: IOCODE_TYPES.DATA },
    { label: 'Proxy', value: IOCODE_TYPES.REVERSE_PROXY },
    { label: 'Téléchargement', value: IOCODE_TYPES.DOWNLOAD },
  ];

  constructor(private _iocs: IocodeService) {}

  public async create() {
    if (this.form.valid) {
      const iocode = this._iocs.create({
        type: this.form.value.type || IOCODE_TYPES.REDIRECT,
        url: this.form.value.url || undefined,
        data: this.form.value.data || undefined,
        ttl: this.form.value.ttl || 24 * 3600 * 30,
      });
      await firstValueFrom(iocode.save());
      this.created.emit(iocode);
    }
  }
}
