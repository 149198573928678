import { Component, inject, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Contact } from '../contact';
import { ContactService } from '../contact.service';
import {
  FiltersParams,
  Link,
  ModelListAutocompleteFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../user/user-display/user-display.component';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { MemberDisplayComponent } from '../../../structures/member/member-display/member-display.component';
import { User } from '../../user/user';
import { Resto } from '../../../structures/resto/resto';
import { Storage } from '../../../structures/storage/storage';
import { Member } from '../../../structures/member/member';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { Client } from '../../../structures/client/client';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { Tariftype } from '../../../tarifs/tariftype/tariftype';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';
import { RestoService } from '../../../structures/resto/resto.service';
import { ClientService } from '../../../structures/client/client.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { MemberService } from '../../../structures/member/member.service';
import { TariftypeService } from '../../../tarifs/tariftype/tariftype.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lvadg-contact-list',
  standalone: true,
  templateUrl: './contact-list.component.pug',
  styleUrls: ['./contact-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    UserDisplayComponent,
    RestoDisplayComponent,
    ClientDisplayComponent,
    StorageDisplayComponent,
    MemberDisplayComponent,
    FlagsDisplayComponent,
    TariftypeDisplayComponent,
    PChoicePipe,
  ],
})
export class ContactListComponent extends ModellistComponent<Contact> {
  @Input() public user_detail_route?: Link<User>;
  @Input() public resto_detail_route?: Link<Resto>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public member_detail_route?: Link<Member>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public tariftype_detail_route?: Link<Tariftype>;

  private resto$ = inject(RestoService);
  private client$ = inject(ClientService);
  private storage$ = inject(StorageService);
  private member$ = inject(MemberService);
  private tariftype$ = inject(TariftypeService);
  private user$ = inject(UserService);

  constructor(coll: ContactService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          'search',
          'type',
          'status',
          'resto',
          'client',
          'storage',
          'member',
          'user',
          'tariftype',
        ],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Filtrer par type de contact',
          model: Contact,
          mfield: 'type',
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Filtrer par statut',
          model: Contact,
          mfield: 'status',
        }),
        // Member
        new ModelListAutocompleteFilter({
          field: 'member',
          name: 'member',
          label: 'Filtrer par adhérent',
          collection: this.member$,
          filter: { fields: ['id', 'name'].join(','), ordering: 'name' },
        }),
        // Storage
        new ModelListAutocompleteFilter({
          field: 'storage',
          name: 'storage',
          label: 'Filtrer par dépôt',
          collection: this.storage$,
          filter: { fields: ['id', 'cvva', 'name'].join(','), ordering: 'name' },
        }),
        // User
        new ModelListAutocompleteFilter({
          field: 'user',
          name: 'user',
          label: 'Filtrer par propriétaire',
          collection: this.user$,
          filter: { fields: ['id', 'last_name', 'first_name', 'email'].join(','), ordering: 'name' },
        }),
        // Client
        new ModelListAutocompleteFilter({
          field: 'client',
          name: 'client',
          label: 'Filtrer par client',
          collection: this.client$,
          filter: { fields: ['id', 'cvva', 'name'].join(','), ordering: 'name' },
        }),

        // Resto
        new ModelListAutocompleteFilter({
          field: 'resto',
          name: 'resto',
          label: 'Filtrer par restaurant',
          collection: this.resto$,
          filter: { fields: ['id', 'cvva', 'name'].join(','), ordering: 'name' },
        }),
        // Tariftype
        new ModelListAutocompleteFilter({
          field: 'tariftype',
          name: 'tariftype',
          label: 'Filtrer par modèle de tarif',
          collection: this.tariftype$,
          filter: { fields: ['id', 'code', 'name'].join(','), ordering: 'name' },
        }),
      ],
    };
  }
}
