import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Geodepartement } from '../geodepartement';
import { Link } from '@solidev/data';
import { GeodepartementBase } from '../geodepartement.base';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-geodepartement-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './geodepartement-display.component.pug',
  styleUrls: ['./geodepartement-display.component.sass'],
})
export class GeodepartementDisplayComponent {
  @Input() public geodepartement?: Geodepartement;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<GeodepartementBase>;

  constructor() {}
}
