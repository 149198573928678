import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Member } from '../../../../../models/structures/member/member';
import { Link } from '@solidev/data';
import { MEMBER_ROUTES } from '../menu';

@Component({
  selector: 'lvadg-member-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './member-nav.component.pug',
  styleUrl: './member-nav.component.sass',
})
export class MemberNavComponent implements OnInit {
  @Input() public routes!: MEMBER_ROUTES;
  @Input() public member!: Member;

  public member_display_route?: Link<Member>;
  public member_edit_route?: Link<Member>;
  public member_relations_route?: Link<Member>;
  public member_products_route?: Link<Member>;
  public member_offers_route?: Link<Member>;
  public member_storages_route?: Link<Member>;
  public member_sales_route?: Link<Member>;
  public member_producers_route?: Link<Member>;
  public member_users_route?: Link<Member>;

  public MUL = {
    admin: 'admin',
  };

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes) {
      throw new Error('Missing routes input');
    }
    if (this.routes.member_display_route) {
      this.member_display_route = new Link(this.routes.member_display_route, this, this._router);
    }
    if (this.routes.member_edit_route) {
      this.member_edit_route = new Link(this.routes.member_edit_route, this, this._router);
    }
    if (this.routes.member_relations_route) {
      this.member_relations_route = new Link(this.routes.member_relations_route, this, this._router);
    }
    if (this.routes.member_products_route) {
      this.member_products_route = new Link(this.routes.member_products_route, this, this._router);
    }
    if (this.routes.member_offers_route) {
      this.member_offers_route = new Link(this.routes.member_offers_route, this, this._router);
    }
    if (this.routes.member_storages_route) {
      this.member_storages_route = new Link(this.routes.member_storages_route, this, this._router);
    }
    if (this.routes.member_sales_route) {
      this.member_sales_route = new Link(this.routes.member_sales_route, this, this._router);
    }
    if (this.routes.member_producers_route) {
      this.member_producers_route = new Link(this.routes.member_producers_route, this, this._router);
    }
    if (this.routes.member_users_route) {
      this.member_users_route = new Link(this.routes.member_users_route, this, this._router);
    }
  }
}
