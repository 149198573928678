
<ng-container>
  <form [formGroup]="contactForm">
    <table class="table table-sm text-start">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of contacts$|async" [class.table-secondary]="!isEnabled(c)" [class.text-muted]="!isEnabled(c)">
          <td>{{c.last_name || '?'}}</td>
          <td>{{c.first_name || '?'}}</td>
          <td><a [href]="'mailto:'+c.email+'?subject='+tarif.name">{{c.email || '?'}}</a></td>
          <td><i class="bi bi-envelope px-3" [class.text-success]="isEnabled(c)" (click)="toggleContact(c)" role="button"></i></td>
        </tr>
        <tr *ngFor="let c of temporary" [class.table-secondary]="!isEnabled(c)" [class.text-muted]="!isEnabled(c)">
          <td>{{c.last_name || '?'}}</td>
          <td>{{c.first_name || '?'}}</td>
          <td><a [href]="'mailto:'+c.email+'?subject='+tarif.name">{{c.email || '?'}}</a></td>
          <td><i class="bi bi-envelope px-3" [class.text-success]="isEnabled(c)" (click)="toggleContact(c)" role="button"></i></td>
        </tr>
        <tr *ngIf="create">
          <td>
            <input class="form-control form-control-sn" type="text" placeholder="Nom de famille" formControlName="last_name">
          </td>
          <td>
            <input class="form-control form-control-sn" type="text" placeholder="Prénom" formControlName="first_name">
          </td>
          <td>
            <input class="form-control form-control-sn" type="email" placeholder="Email" formControlName="email">
          </td>
          <td>
            <button class="btn btn-sm btn-primary w-100" (click)="saveTemporary()" [disabled]="!contactForm.valid"><i class="bi bi-save"></i></button>
          </td>
        </tr>
        <tr>
          <td class="text-primary fw-bold text-center" colspan="20" (click)="create=!create">Ajouter un destinataire temporaire {{create}}</td>
        </tr>
      </tbody>
    </table>
  </form>
</ng-container>