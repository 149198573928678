import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericregionBase } from '../genericregion.base';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-genericregion-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './genericregion-display.component.pug',
  styleUrls: ['./genericregion-display.component.sass'],
})
export class GenericregionDisplayComponent {
  @Input() public genericregion?: GenericregionBase;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<GenericregionBase>;

  constructor() {}
}
