import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TARIF_ROUTES } from '../menu';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Link } from '@solidev/data';
import { TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';
import { TARIFTYPE_LIFECYCLE, TARIFTYPE_USER_LEVEL } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'lvadg-tarif-detail-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './tarif-detail-nav.component.pug',
  styleUrls: ['./tarif-detail-nav.component.sass'],
})
export class TarifDetailNavComponent implements OnInit {
  @Input({ required: true }) public tarif!: Tarif;
  @Input({ required: true }) public tariftype!: Tariftype;
  @Input({ required: true }) public routes!: TARIF_ROUTES;

  public tarif_detail_route?: Link<TarifBase>;
  public tariftype_detail_route?: Link<TarifBase>;
  public tarif_manage_route?: Link<TarifBase>;
  public tarif_lines_route?: Link<TarifBase>;
  public tarif_documents_route?: Link<TarifBase>;
  public tarif_notifications_route?: Link<TarifBase>;
  public tarif_notification_logs_route?: Link<TarifBase>;
  public tarif_relations_route?: Link<TarifBase>;
  public tarif_lifecycle_route?: Link<TarifBase>;
  public TTUL = TARIFTYPE_USER_LEVEL;
  public TTLC = TARIFTYPE_LIFECYCLE;

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (this.routes.tarif_detail_route) {
      this.tarif_detail_route = new Link<TarifBase>(this.routes.tarif_detail_route, this, this._router);
    }
    if (this.routes.tariftype_detail_route) {
      this.tariftype_detail_route = new Link<TarifBase>(this.routes.tariftype_detail_route, this, this._router);
    }
    if (this.routes.tarif_manage_route) {
      this.tarif_manage_route = new Link<TarifBase>(this.routes.tarif_manage_route, this, this._router);
    }
    if (this.routes.tarif_lines_route) {
      this.tarif_lines_route = new Link<TarifBase>(this.routes.tarif_lines_route, this, this._router);
    }
    if (this.routes.tarif_documents_route) {
      this.tarif_documents_route = new Link<TarifBase>(this.routes.tarif_documents_route, this, this._router);
    }
    if (this.routes.tarif_notifications_route) {
      this.tarif_notifications_route = new Link<TarifBase>(this.routes.tarif_notifications_route, this, this._router);
    }
    if (this.routes.tarif_notification_logs_route) {
      this.tarif_notification_logs_route = new Link<TarifBase>(
        this.routes.tarif_notification_logs_route,
        this,
        this._router,
      );
    }
    if (this.routes.tarif_relations_route) {
      this.tarif_relations_route = new Link<TarifBase>(this.routes.tarif_relations_route, this, this._router);
    }
    if (this.routes.tarif_lifecycle_route) {
      this.tarif_lifecycle_route = new Link<TarifBase>(this.routes.tarif_lifecycle_route, this, this._router);
    }
  }
}
