import { Component } from "@angular/core";

import { Subject } from "rxjs";
import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";
import { BASE_LIST_VIEW_IMPORTS } from "../../../../components/baseview/baselistview.imports";
import { ArticleTarifTemplateRelationListComponent } from "../../../../models/tarifs/article-tarif-template-relation/article-tarif-template-relation-list/article-tarif-template-relation-list.component";
import { BaseListViewComponent } from "../../../../components/baseview/baselistview.component";
import { ArticleTarifTemplateRelation } from "../../../../models/tarifs/article-tarif-template-relation/article-tarif-template-relation";
import { TariftypeBase } from "../../../../models/tarifs/tariftype/tariftype.base";
import { TariftypeRelationBase } from "../../../../models/tarifs/tariftype-relation/tariftype-relation.base";
import { ArticleTarifTemplateBase } from "../../../../models/tarifs/article-tarif-template/article-tarif-template.base";
import { ArticleBase } from "../../../../models/catalog/article/article.base";
import { EarticleBase } from "../../../../models/catalog/earticle/earticle.base";

export interface ArticleTarifTemplateRelationListViewParams
  extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  tariftyperelation_detail_route: RouteConfigItem;
  articletariftemplate_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  earticle_detail_route: RouteConfigItem;
}

export interface ArticleTarifTemplateRelationListViewData
  extends ArticleTarifTemplateRelationListViewParams {}

@Component({
  selector: "lvadg-article-tarif-template-relation-list-view",
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    ArticleTarifTemplateRelationListComponent,
  ],
  templateUrl: "./article-tarif-template-relation-list-view.component.pug",
  styleUrls: ["./article-tarif-template-relation-list-view.component.sass"],
})
export class ArticleTarifTemplateRelationListViewComponent extends BaseListViewComponent<
  ArticleTarifTemplateRelationListViewData,
  ArticleTarifTemplateRelation
> {
  public tariftype_detail_route?: Link<TariftypeBase>;
  public tariftyperelation_detail_route?: Link<TariftypeRelationBase>;
  public articletariftemplate_detail_route?: Link<ArticleTarifTemplateBase>;
  public article_detail_route?: Link<ArticleBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public default_fields = [
    "id",
    "ttrel_details",
    "ttrel_src_details",
    "src_details",
    "src_details_uf",
    "ttrel_dest_details",
    "dest_details",
    "dest_details_uf",
    "ops",
  ];
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: ArticleTarifTemplateRelationListViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(
      data.tariftype_detail_route,
      data,
      this._router,
    );
    this.tariftyperelation_detail_route = new Link<TariftypeRelationBase>(
      data.tariftyperelation_detail_route,
      data,
      this._router,
    );
    this.articletariftemplate_detail_route = new Link<ArticleTarifTemplateBase>(
      data.articletariftemplate_detail_route,
      data,
      this._router,
    );
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      data,
      this._router,
    );
    this.earticle_detail_route = new Link<EarticleBase>(
      data.earticle_detail_route,
      data,
      this._router,
    );
  }
}
