import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { ClientListComponent } from '../../../../models/structures/client/client-list/client-list.component';
import { Client } from '../../../../models/structures/client/client';
import { ClientCreateComponent } from '../../../../models/structures/client/client-create/client-create.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

export interface ClientListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface ClientListViewData extends ClientListViewParams {}

@Component({
  selector: 'lvadg-client-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, ClientListComponent, ClientCreateComponent, IconComponent],
  templateUrl: './client-list-view.component.pug',
  styleUrls: ['./client-list-view.component.sass'],
})
export class ClientListViewComponent extends BaseListViewComponent<ClientListViewData, Client> {
  public create = false;
  public reload$ = new Subject<void | boolean>();

  public default_fields = ['id', 'cvva', 'tree_path', 'name', 'type', 'location_details', 'updated', 'status'];

  public override setRouteData(data: ClientListViewData) {
    super.setRouteData(data);
  }
}
