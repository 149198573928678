import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataMessageService, FilterDefaults, Link, TabMemoryService } from '@solidev/data';
import { firstValueFrom, map, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { TariftypeFullDetailViewData } from './tariftype-detail-view.component.params';
import { TariftypeManageComponent } from '../../../models/tarifs/tariftype/tariftype-manage/tariftype-manage.component';
import { TariftypeLinksManageComponent } from '../../../models/tarifs/tariftype/tariftype-links-manage/tariftype-links-manage.component';
import { ArticleTarifTemplateListComponent } from '../../../models/tarifs/article-tarif-template/article-tarif-template-list/article-tarif-template-list.component';
import { TarifLogListComponent } from '../../../models/tarifs/tarif-log/tarif-log-list/tarif-log-list.component';
import { ArticleTarifLogListComponent } from '../../../models/tarifs/article-tarif-log/article-tarif-log-list/article-tarif-log-list.component';
import { TarifNotificationStatusListComponent } from '../../../models/tarifs/tarif-notification-status/tarif-notification-status-list/tarif-notification-status-list.component';
import { TarifLifecycleComponent } from '../../../models/tarifs/tarif/lifecycle/tarif-lifecycle/tarif-lifecycle.component';
import { TarifCreateComponent } from '../../../models/tarifs/tarif/tarif-create/tarif-create.component';
import { TarifLifecycleStepListComponent } from '../../../models/tarifs/tarif-lifecycle-step/tarif-lifecycle-step-list/tarif-lifecycle-step-list.component';
import { TariftypePermissionListComponent } from '../../../models/tarifs/tariftype-permission/tariftype-permission-list/tariftype-permission-list.component';
import { TariftypePermissionCreateComponent } from '../../../models/tarifs/tariftype-permission/tariftype-permission-create/tariftype-permission-create.component';
import { TariftypeStorageListComponent } from '../../../models/tarifs/tariftype-storage/tariftype-storage-list/tariftype-storage-list.component';
import { TariftypeStorageCreateComponent } from '../../../models/tarifs/tariftype-storage/tariftype-storage-create/tariftype-storage-create.component';
import { TariftypeStoragePermissionListComponent } from '../../../models/tarifs/tariftype-storage-permission/tariftype-storage-permission-list/tariftype-storage-permission-list.component';
import { TarifListComponent } from '../../../models/tarifs/tarif/tarif-list/tarif-list.component';
import { ArticleListComponent } from '../../../models/catalog/article/article-list/article-list.component';
import { Tariftype } from '../../../models/tarifs/tariftype/tariftype';
import { ArticleBase } from '../../../models/catalog/article/article.base';
import { EarticleBase } from '../../../models/catalog/earticle/earticle.base';
import { Tarif } from '../../../models/tarifs/tarif/tarif';
import { TarifNotificationBase } from '../../../models/tarifs/tarif-notification/tarif-notification.base';
import { TariftypeRelationBase } from '../../../models/tarifs/tariftype-relation/tariftype-relation.base';
import { UserBase } from '../../../models/users/user/user.base';
import { GroupBase } from '../../../models/users/group/group.base';
import { ArticleTarifTemplateBase } from '../../../models/tarifs/article-tarif-template/article-tarif-template.base';
import { StorageBase } from '../../../models/structures/storage/storage.base';
import {
  TARIFTYPE_LIFECYCLE,
  TARIFTYPE_STORAGE_MODE,
  TARIFTYPE_USER_LEVEL,
} from '../../../models/tarifs/tariftype/tariftype.base';
import { ArticleTarifTemplate } from '../../../models/tarifs/article-tarif-template/article-tarif-template';
import { Article } from '../../../models/catalog/article/article';
import { ModelListAction } from '../../../includes/modellist/modellist.component';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../components/baseview/basedetailview.component';
import { RI } from '../../../constants';
import { TarifNotificationDestinationListComponent } from '../../../models/tarifs/tarif-notification-destination/tarif-notification-destination-list/tarif-notification-destination-list.component';
import { TarifNotificationDestinationCreateComponent } from '../../../models/tarifs/tarif-notification-destination/tarif-notification-destination-create/tarif-notification-destination-create.component';
import { TariftypeActionParams, TariftypeActionService } from '../../../models/tarifs/tarif/tariftype-action.service';
import { TariftypeEarticleMappingsComponent } from '../../../models/tarifs/tariftype/tariftype-earticle-mappings/tariftype-earticle-mappings.component';
import { TariftypeEarticlesImportComponent } from '../../../models/tarifs/tariftype/tariftype-earticles-import/tariftype-earticles-import.component';
import { TarifNotificationListComponent } from '../../../models/tarifs/tarif-notification/tarif-notification-list/tarif-notification-list.component';
import { TarifNotificationDestination } from '../../../models/tarifs/tarif-notification-destination/tarif-notification-destination';
import { TariftypeDocumentListComponent } from '../../../models/tarifs/tariftype-document/tariftype-document-list/tariftype-document-list.component';
import { TariftypeDocumentCreateComponent } from '../../../models/tarifs/tariftype-document/tariftype-document-create/tariftype-document-create.component';
import { TariftypeDocumentBase } from '../../../models/tarifs/tariftype-document/tariftype-document.base';
import { TariftypeMetadataBase } from '../../../models/tarifs/tariftype-metadata/tariftype-metadata.base';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-tariftype-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    TariftypeManageComponent,
    TariftypeLinksManageComponent,
    ArticleTarifTemplateListComponent,
    TarifCreateComponent,
    TarifLogListComponent,
    ArticleTarifLogListComponent,
    TarifNotificationDestinationListComponent,
    TarifNotificationDestinationCreateComponent,
    TarifNotificationStatusListComponent,
    TarifNotificationListComponent,
    TarifLifecycleComponent,
    TarifLifecycleStepListComponent,
    TariftypePermissionListComponent,
    TariftypePermissionCreateComponent,
    TariftypeStorageListComponent,
    TariftypeStorageCreateComponent,
    TariftypeStoragePermissionListComponent,
    TarifListComponent,
    ArticleListComponent,
    TariftypeEarticleMappingsComponent,
    TariftypeEarticlesImportComponent,
    TariftypeDocumentListComponent,
    TariftypeDocumentCreateComponent,
  ],
  templateUrl: './tariftype-detail-view.component.pug',
  styleUrls: ['./tariftype-detail-view.component.sass'],
})
export class TariftypeFullDetailViewComponent extends BaseDetailViewComponent<TariftypeFullDetailViewData, Tariftype> {
  @ViewChild('specialop', { static: true })
  public ofcSlot!: ElementRef<HTMLDivElement>;

  public article_detail_route?: Link<ArticleBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public tariftype_detail_route?: Link<Tariftype>;
  public tarif_detail_route?: Link<Tarif>;
  public tarifnotification_detail_route?: Link<TarifNotificationBase>;
  public tariftyperelation_detail_route?: Link<TariftypeRelationBase>;
  public tariftypedocument_detail_route?: Link<TariftypeDocumentBase>;
  public tariftypemetadata_detail_route?: Link<TariftypeMetadataBase>;
  public user_detail_route?: Link<UserBase>;
  public group_detail_route?: Link<GroupBase>;
  public att_detail_route!: Link<ArticleTarifTemplateBase>;
  public storage_detail_route!: Link<StorageBase>;
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public catalogTarifTypeFilter$!: Observable<FilterDefaults>;
  public running: Record<string, boolean> = {};
  public current?: Tariftype;

  public tarifs_default_fields = [
    'id',
    'name',
    'datestart',
    'dateend',
    'status',
    'documents_details',
    'created',
    'updated',
  ];
  public atts_default_fields = [
    'id',
    'eaclient_details',
    'article_details',
    'article__family',
    'article__calibre',
    'article__categorie',
    'article__origine',
    'article__local',
    'article__egalim',
    'article__colisage',
    'article__uci',
    'article__udv_value',
    'article__uf',
    'status',
    'actions',
  ];

  public tn_default_fields = [
    'tariftype_details',
    'tarif_details',
    'created',
    'updated',
    'type',
    'priority',
    'title',
    'status',
  ];

  public tns_default_fields = [
    'id',
    'notification_details',
    'notification__type',
    'notification__tarif',
    'notification__priority',
    'user_details',
    'destination',
    'status',
    'updated',
    'created',
  ];
  public tlog_default_fields = [
    'id',
    'created',
    'author_details',
    'tarif_details',
    'user_details',
    'operation',
    'step',
    'ptstep_details',
    'tstep_details',
    'data',
  ];
  public storages_default_fields = ['id', 'storage_details', 'perms_details', 'created', 'updated', 'actions'];
  public storagepermissions_default_fields = [
    'id',
    'tariftype_details',
    'storage_details',
    'user_details',
    'group_details',
    'permission',
    'created',
    'updated',
  ];
  public atilog_default_fields = [
    'id',
    'created',
    'author',
    'tarif_details',
    'article_details',
    'articletarifitem_details',
    'pprice',
    'price',
    'pstatus',
    'status',
    'pavailable',
    'available',
  ];
  public catalog_default_fields = [
    'code',
    'libelle',
    'family_details',
    'udf_unit',
    'egalim_details',
    'local',
    'egalim_status',
    'calibre_details',
    'categorie_details',
    'origine_details',
    'colisage',
    'uci',
    'in_tariftype',
    'actions',
  ];
  public tperms_default_fields = [
    'id',
    'permission',
    'tariftype_details',
    'user_details',
    'group_details',
    'updated',
    'created',
    'actions',
  ];
  public tndest_default_fields = ['id', 'notification', 'user_details', 'contact_details', 'group_details', 'actions'];
  public lifecyclesteps_default_fields = ['id', 'step', 'stitle$', 'title$', 'message$', 'params$', 'order'];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public createTarif: boolean = false;
  public createStorage: boolean = false;
  public createPermission: boolean = false;
  public createDestination: boolean = false;

  public TTUL = TARIFTYPE_USER_LEVEL;
  public TTSM = TARIFTYPE_STORAGE_MODE;
  public TTLC = TARIFTYPE_LIFECYCLE;
  public RI = RI;

  public earticleImport: boolean = false;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private ofc: NgbOffcanvas,
    public ttAction: TariftypeActionService,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeFullDetailViewData) {
    super.setRouteData(data);
    this.current = data.tariftype;
    this.tariftype_detail_route = data.tariftype_detail_route ? new Link(data.tariftype_detail_route, data) : undefined;
    this.article_detail_route = data.article_detail_route ? new Link(data.article_detail_route, data) : undefined;
    this.earticle_detail_route = data.earticle_detail_route ? new Link(data.earticle_detail_route, data) : undefined;
    this.tarif_detail_route = data.tarif_detail_route
      ? new Link(data.tarif_detail_route, data, this._router)
      : undefined;
    this.tarifnotification_detail_route = data.tarifnotification_detail_route
      ? new Link(data.tarifnotification_detail_route, data)
      : undefined;
    this.user_detail_route = new Link(data.user_detail_route, data);
    this.att_detail_route = new Link(data.att_detail_route, data);
    this.group_detail_route = new Link(data.group_detail_route, data);
    this.storage_detail_route = new Link(data.storage_detail_route, data);
    this.tariftyperelation_detail_route = new Link(data.tariftyperelation_detail_route, data);
    this.tariftypedocument_detail_route = new Link(data.tariftypedocument_detail_route, data);
    this.tariftypemetadata_detail_route = new Link(data.tariftypemetadata_detail_route, data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
    this.catalogTarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { in_tariftype: d.tariftype.id };
      }),
    );
  }

  public async catalogAction(tt: Tariftype, action: ModelListAction<Article>) {
    if (action.action == 'tariftype_add' && action.model) {
      try {
        await firstValueFrom(
          tt.action('POST', 'action', {
            body: {
              action: 'article_add',
              article: action.model.id,
            },
          }),
        );
        this.reload$.next();
        this._msgs.success('Article ajouté au tarif');
      } catch (e) {
        console.error(e);
        this._msgs.error(
          "Erreur lors de l'ajout de l'article",
          `${(e as any).error?.message || (e as any).message || ''}`,
          e,
        );
      }
    }
  }

  public async attAction(tt: Tariftype, action: ModelListAction<ArticleTarifTemplate>) {
    if (action.model) {
      try {
        const res = await firstValueFrom(
          action.model.action<ArticleTarifTemplate, { message: string; att: ArticleTarifTemplate }>('POST', 'action', {
            body: {
              action: action.action,
            },
          }),
        );
        this.reload$.next();
        this._msgs.success(res.message);
      } catch (e) {
        console.error(e);
        this._msgs.error("Erreur lors de l'action", `${(e as any).error?.message || (e as any).message || ''}`, e);
      }
    }
  }

  public async destinationAction(tt: Tariftype, act: ModelListAction<TarifNotificationDestination>) {
    if (act.action === 'remove' && act.model) {
      await this.ttAction.action(tt, {
        action: 'remove_destination',
        destination: act.model.id,
      });
      this.reload$.next(true);
    }
  }

  public ofcOp(operation: string) {
    this.running[operation] = true;
    this.ofc.open(this.ofcSlot, { position: 'end', panelClass: 'w-50' }).result.then(
      () => {
        this.running[operation] = false;
      },
      () => {
        this.running[operation] = false;
      },
    );
  }

  public async ttActionProcess(tt: Tariftype, action: TariftypeActionParams) {
    await this.ttAction.action(tt, action);
    this.reload$.next(true);
  }
}

export { TariftypeFullDetailViewParams } from './tariftype-detail-view.component.params';
