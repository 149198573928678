
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { CdataKeyChoiceService} from "./cdata-key-choice.service";
import { CdataKeyChoice } from "./cdata-key-choice";

@Injectable({
  providedIn: 'root'
})
export class CdataKeyChoiceResolver  {
  constructor(private _rs: CdataKeyChoiceService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CdataKeyChoice> {
    return this._rs.fetch(+route.params["cdatakeychoiceId"])
  }
}
