import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OffertypePermission } from '../offertype-permission';
import { OffertypePermissionService } from '../offertype-permission.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Offertype } from '../../offertype/offertype';
import { User } from '../../../users/user/user';
import { Group } from '../../../users/group/group';
import { OffertypeDisplayComponent } from '../../offertype/offertype-display/offertype-display.component';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { OFFERTYPE_PERMISSION, OffertypePermissionBase } from '../offertype-permission.base';

@Component({
  selector: 'lvadg-offertype-permission-list',
  standalone: true,
  templateUrl: './offertype-permission-list.component.pug',
  styleUrls: ['./offertype-permission-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    SafeDeleteComponent,
    OffertypeDisplayComponent,
    UserDisplayComponent,
    GroupDisplayComponent,
    PChoicePipe,
  ],
})
export class OffertypePermissionListComponent extends ModellistComponent<OffertypePermission> {
  @Input() public offertype_detail_route?: Link<Offertype>;
  @Input() public user_detail_route?: Link<User>;
  @Input() public group_detail_route?: Link<Group>;

  constructor(coll: OffertypePermissionService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'permission'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'permission',
          name: 'permission',
          label: 'Permission',
          model: OffertypePermissionBase,
          mfield: 'permission',
        }),
      ],
    };
  }

  public override getRowClasses(row: OffertypePermission): string[] {
    const cls = super.getRowClasses(row);
    if (row.permission === OFFERTYPE_PERMISSION.ADMIN) {
      cls.push('table-warning');
    }
    return cls;
  }
}
