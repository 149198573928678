import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { VideoDisplayComponent } from "../../../documents/image/video-display/video-display.component";
import { ProviderDocumentDisplayComponent } from "../../provider-document/provider-document-display/provider-document-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { Link } from "@solidev/data";
import { Storage } from "../../storage/storage";
import { IMAGE_USAGE } from "../../../documents/image/image.base";
import { Provider } from "../provider";

@Component({
  selector: "lvadg-provider-details",
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ImageDisplayComponent,
    VideoDisplayComponent,
    ProviderDocumentDisplayComponent,
    LabelDisplayComponent,
    NgbCarouselModule,
  ],
  templateUrl: "./provider-details.component.pug",
  styleUrl: "./provider-details.component.sass",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderDetailsComponent {
  public provider = input.required<Provider>();
  public provider_detail_route = input<Link<Provider>>();
  public storage_detail_route = input<Link<Storage>>();
  public haveDocuments = computed(() => {
    const p = this.provider();
    return (
      p.documents_details &&
      p.documents_details.filter((d) => d.is_valid).length > 0
    );
  });
  public haveVideos = computed(() => {
    const p = this.provider();
    return (
      p.images_details && p.images_details.filter((d) => !!d.video).length > 0
    );
  });
  public carouselImages = computed(() => {
    const p = this.provider();
    return (
      p.images_details &&
      p.images_details.filter(
        (d) => !d.video && d.have_usages(IMAGE_USAGE.GDISP),
      )
    );
  });
  public havePhotos = computed(() => {
    const p = this.carouselImages();
    return p ? p.length > 0 : false;
  });
}
