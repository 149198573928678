import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeService } from '../tariftype.service';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { Tariftype } from '../tariftype';
import { ClientBase } from '../../../structures/client/client.base';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';
import { TARIFTYPE_STATUS, TariftypeBase } from '../tariftype.base';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

export interface TarifTypeTree {
  ids: Map<number, Tariftype>;
  id2cs: Map<number, number | undefined>;
  clients: { c: ClientBase; p: ClientBase }[];
  generics: Tariftype[];
  tree: Map<
    number,
    {
      c: ClientBase | undefined;
      p: ClientBase | undefined;
      tarifs: Tariftype[];
    }
  >;
}

@Component({
  selector: 'lvadg-tariftype-client-tree',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    NgbCollapse,
    IconComponent,
  ],
  templateUrl: './tariftype-client-tree.component.pug',
  styleUrls: ['./tariftype-client-tree.component.sass'],
})
export class TariftypeClientTreeComponent implements OnInit {
  public tlist$!: Observable<TarifTypeTree>;
  public opened: Record<number, boolean> = {};
  public current?: number;
  public search = new FormControl('');
  public filter$: BehaviorSubject<string> = new BehaviorSubject<string>('all');

  @Input() public current$?: Observable<number | undefined>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Output() public selected = new EventEmitter<Tariftype>();
  public curFilter: 'all' | 'starred' | 'notified' | 'pilote' = 'all';

  constructor(private _tts: TariftypeService) {}

  public ngOnInit() {
    const qs = this._tts.queryset();
    qs.setFields([
      'id',
      'client_details',
      'client_parent_details',
      'name',
      'type',
      'user_level',
      'user_starred',
      'user_notified',
    ]);
    qs.filter();
    this.tlist$ = combineLatest([
      merge(of(''), this.search.valueChanges.pipe(debounceTime(400))),
      this.filter$,
    ]).pipe(
      switchMap(([r, f]) =>
        qs
          .filter({
            search: r,
            mode: f,
            status: TARIFTYPE_STATUS.CURRENT,
          })
          .get()
      ),
      map((tts) => {
        const cids = new Map(
          tts.map((t) => [
            t.client_details?.id || -1,
            {
              c: t.client_details,
              p: t.client_parent_details,
            },
          ])
        );

        const tree: TarifTypeTree = {
          ids: new Map(tts.map((t) => [t.id, t])),
          id2cs: new Map(tts.map((t) => [t.id, t.client_details?.id])),
          clients: Array.from(cids.values())
            .filter((v) => v.c)
            .map((v) => {
              return { c: v.c!, p: v.p! };
            })
            .sort((a, b) => {
              return (a.p?.name || a.c!.name).localeCompare(
                b.p?.name || b.c.name!
              );
            }),
          generics: tts.filter((t) => !t.client_details),
          tree: new Map(
            Array.from(cids.entries()).map(([t, td]) => [
              t,
              {
                c: td.c,
                p: td.p,
                tarifs: tts.filter((tt) => tt.client_details?.id == t),
              },
            ])
          ),
        };
        return tree;
      }),
      shareReplay()
    );

    combineLatest([this.tlist$, this.current$ || of(undefined)]).subscribe(
      ([l, cur]) => {
        this.current = cur;
        if (cur && l.id2cs.get(cur)) {
          this.opened[l.id2cs.get(cur) || -1] = true;
        }
      }
    );
  }

  select(id: number) {
    console.log('Opened client', id);
    this.opened[id] = !this.opened[id];
  }

  ttSelect(tt: Tariftype) {
    console.log('Selected tarif', tt);
    this.current = tt.id;
    this.selected.emit(tt);
  }

  setFilter(c: 'all' | 'starred' | 'notified' | 'pilote') {
    this.curFilter = c;
    this.filter$.next(c);
  }
}
