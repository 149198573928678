import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../users/user/user.service';
import { UserMapService } from '../user-map.service';
import { UserMapBase } from '../user-map.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-user-map-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './user-map-create.component.pug',
  styleUrls: ['./user-map-create.component.sass'],
})
export class UserMapCreateComponent implements OnInit {
  public usermap!: UserMapBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<UserMapBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    public users$: UserService,
    private _msgs: DataMessageService,
    private _umaps: UserMapService,
  ) {}

  ngOnInit(): void {
    this.usermap = new UserMapBase(this._umaps);
  }

  public async save() {
    this.usermap.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.usermap.save({ updateModel: true }));
      this.created.emit(this.usermap);
      this._msgs.success('Carte utilisateur créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
