import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarif } from '../article-tarif';
import { Tarif } from '../../tarif/tarif';
import { ArticleTarifBase } from '../article-tarif.base';

@Component({
  selector: 'lvadg-article-tarif-price-related',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-tarif-price-related.component.pug',
  styleUrls: ['./article-tarif-price-related.component.sass'],
})
export class ArticleTarifPriceRelatedComponent implements OnChanges {
  @Input() public at!: ArticleTarif;
  @Input() public tarif!: Tarif;
  @Input() public current!: number | null;
  @Input() public ops: 'price' | 'opsprice' = 'opsprice';
  @Input() public display: 'price' | 'percent' | 'both' = 'price';

  public rat: ArticleTarifBase | undefined;
  public rprice: number | null = null;
  public cprice: number | null = null;
  public percent: number | null = null;
  public title: string = '';

  public ngOnChanges(changes: SimpleChanges) {
    this.rat = this.at.bytarif_related_details.get(this.tarif.id);
    if (!this.rat) {
      this.title = 'Non disponible';
    } else {
      this.rprice =
        (this.ops === 'opsprice' ? this.rat?.ops_price : this.rat?.price) ||
        null;
      if (!this.current) {
        this.title = 'Pas comparaison';
        this.percent = null;
      }
      if (this.rprice && this.current) {
        this.title += `Opérations : ${this.rat.ops.join(' ')}\n`;
        this.percent = ((this.current - this.rprice) / this.rprice) * 100;
      }
    }
  }
}
