
<ng-container *ngIf="ciqualcolumn">
  <data-dispedit [model]="ciqualcolumn" field="code" mode="dd">Code</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="unit" mode="dd">Unité</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="order" mode="dd">Ordre (décroissant)</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="group" mode="dd">Groupe</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="ajr" mode="dd">AJR (même unité)</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="maxjr" mode="dd">Max AJR (même unité)</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="desc" mode="dd">Description</data-dispedit>
  <data-dispedit [model]="ciqualcolumn" field="display" mode="dd">Mode affichage</data-dispedit>
</ng-container>