import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../models/users/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataMessageService } from '@solidev/data';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-reactivate-view',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './reactivate-view.component.pug',
  styleUrls: ['./reactivate-view.component.sass'],
})
export class ReactivateViewComponent implements OnInit {
  public token?: string;
  public email?: string;
  public error: string = '';
  public mode?: 'ask' | 'waiting';
  public reactivateForm = new FormGroup({
    username: new FormControl('', [Validators.email, Validators.required]),
    token: new FormControl('', [Validators.required]),
  });

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private router: Router,
    private _msgs: DataMessageService
  ) {}

  public ngOnInit() {
    this.token = this._route.snapshot.queryParams['token'];
    this.email = this._route.snapshot.queryParams['email'];
    this.mode = this._route.snapshot.queryParams['mode'];
    this.reactivateForm.patchValue({ username: this.email, token: this.token });
  }

  async processReactivate() {
    if (this.reactivateForm.value.username && this.reactivateForm.value.token) {
      const res = await firstValueFrom(
        this._auth.processReactivate(
          this.reactivateForm.value.username,
          this.reactivateForm.value.token
        )
      );
      if (res) {
        this._msgs.success(
          'Compte réactivé avec succès, vous pouvez désormais vous connecter'
        );
        this.router.navigate(this._auth.loginRoute);
      } else {
        this._msgs.error(
          'Erreur lors de la réactivation du compte, merci de contacter le support (support@lavieadugout.fr)'
        );
      }
    }
  }

  public async requestReactivate() {
    const res = await firstValueFrom(
      this._auth.requestReactivate(this.reactivateForm.value.username as string)
    );
    if (res) {
      this._msgs.success('Email de réactivation envoyé avec succès');
      this.mode = 'waiting';
      this.error = '';
    }
  }
}
