
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TarifLogService} from "./tarif-log.service";
import { TarifLog } from "./tarif-log";

@Injectable({
  providedIn: 'root'
})
export class TarifLogResolver  {
  constructor(private _rs: TarifLogService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TarifLog> {
    return this._rs.fetch(+route.params["tarif-logId"])
  }
}
