import {Component} from "@angular/core";
import {
    FiltersParams,
    ModelListService,
    ModelListTextFilter, PChoicePipe
} from "@solidev/data";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {ModellistComponent} from "../../../../includes/modellist/modellist.component";
import {DatePipe} from "@angular/common";
import {MODELLIST_IMPORTS} from "../../../../includes/modellist/modellist.imports";
import {CommentService} from "../comment.service";
import {Comment} from "../comment";
import {UserDisplayComponent} from "../../../users/user/user-display/user-display.component";

@Component({
    standalone: true,
    selector: "lvadg-comment-list",
    templateUrl: "./comment-list.component.pug",
    imports: [
        ...MODELLIST_IMPORTS,
        DatePipe,
        PChoicePipe,
        UserDisplayComponent
    ],
    styleUrls: ["./comment-list.component.sass"]
})
export class CommentListComponent extends ModellistComponent<Comment> {
    constructor(
        coll: CommentService,
        list: ModelListService,
        ofc: NgbOffcanvas,
    ) {
        super(coll, list, ofc);
    }


    public override getFilters(): FiltersParams {
        return {
            defaults: this.default_filters || this.filters?.defaults || ['search'],
            filters: [
                new ModelListTextFilter({
                    field: 'search',
                    name: 'search',
                    label: 'Recherche par texte',
                }),
            ],
        };
    }
}
