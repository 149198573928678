import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { VivalyaBill } from '../vivalya-bill';
import { VivalyaBillService } from '../vivalya-bill.service';
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListDateFilter,
  ModelListService,
  ModelListTextFilter,
  PFactorcPipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { Storage } from '../../../structures/storage/storage';
import { Client } from '../../../structures/client/client';
import { Resto } from '../../../structures/resto/resto';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { RestoService } from '../../../structures/resto/resto.service';
import { ClientService } from '../../../structures/client/client.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { MemberService } from '../../../structures/member/member.service';
import { FamilyService } from '../../../catalog/family/family.service';
import { ArticleService } from '../../../catalog/article/article.service';

@Component({
  selector: 'lvadg-vivalya-bill-list',
  standalone: true,
  templateUrl: './vivalya-bill-list.component.pug',
  styleUrls: ['./vivalya-bill-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    StorageDisplayComponent,
    FlagsDisplayComponent,
    ClientDisplayComponent,
    RestoDisplayComponent,
    PFactorcPipe,
  ],
})
export class VivalyaBillListComponent extends ModellistComponent<VivalyaBill> {
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public resto_detail_route?: Link<Resto>;

  constructor(
    coll: VivalyaBillService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _restos: RestoService,
    private _clients: ClientService,
    private _storages: StorageService,
    private _members: MemberService,
    private _families: FamilyService,
    private _articles: ArticleService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'restos',
          name: 'restos',
          label: 'Recherche par restaurant',
          collection: this._restos,
          filter: {
            fields: ['id', 'cvva', 'name'].join(','),
            order: 'name',
            have_billing: true,
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'clients',
          name: 'clients',
          label: 'Recherche par client',
          collection: this._clients,
          filter: {
            fields: 'id,cvva,name',
            order: 'name',
            have_billing: true,
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'storages',
          name: 'storages',
          label: 'Recherche par dépôt',
          collection: this._storages,
          filter: {
            fields: 'id,cvva,name',
            order: 'name',
            have_billing: true,
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'members',
          name: 'members',
          label: 'Recherche par adhérent',
          collection: this._members,
          filter: {
            fields: 'id,cvva,name',
            order: 'name',
            have_billing: true,
          },
        }),
        new ModelListDateFilter({
          field: 'datestart',
          name: 'datestart',
          label: 'Recherche par date (après)',
        }),
        new ModelListDateFilter({
          field: 'dateend',
          name: 'dateend',
          label: 'Recherche par date (avant)',
        }),
      ],
    };
  }
}
