
<ng-container *ngIf="currentUser$|async; let user">
  <ng-container *ngIf="user">
    <ng-container *ngIf="dest &amp;&amp; user.is_staff">
      <div class="row g-2">
        <data-dispedit class="col" [model]="dest" field="notification">Notification</data-dispedit>
        <data-dispedit class="col" [model]="dest" field="user" [collection]="user$">Utilisateur</data-dispedit>
        <data-dispedit class="col" [model]="dest" field="group" [collection]="group$">Groupe</data-dispedit>
      </div>
      <div class="row g-2 mt-3" *ngIf="dest.notification===TD.NEGO_ALL">
        <div class="col-12 text-info">Contact négociation</div>
        <div class="col-md-6">
          <div class="text-info fw-bold">Utiliser un contact existant</div>
          <data-dispedit class="col" [model]="dest" field="contact" [collection]="contact$">Contact</data-dispedit>
        </div>
        <div class="col-md-6">
          <div class="text-info fw-bold">Créer un nouveau contact</div>
          <div class="row g-2">
            <data-dispedit class="col-6" [model]="contact" field="last_name">Nom de famille</data-dispedit>
            <data-dispedit class="col-6" [model]="contact" field="first_name">Prénom</data-dispedit>
            <data-dispedit class="col-12" [model]="contact" field="email">Email</data-dispedit>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row g-2" *ngIf="dest &amp;&amp; !user.is_staff">
      <div class="text-info fw-bold">Ajouter un contact de négociation</div>
      <div class="row g-2">
        <data-dispedit class="col-6" [model]="contact" field="last_name">Nom de famille</data-dispedit>
        <data-dispedit class="col-6" [model]="contact" field="first_name">Prénom</data-dispedit>
        <data-dispedit class="col-12" [model]="contact" field="email">Email</data-dispedit>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-8">
        <button class="btn btn-primary w-100" (click)="save(user.is_staff)"><i class="bi bi-save me-2"></i>Enregistrer</button>
      </div>
      <div class="col-4">
        <button class="btn btn-secondary w-100" (click)="cancelled.emit()">Annuler</button>
      </div>
    </div>
  </ng-container>
</ng-container>