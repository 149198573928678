
<div class="content">
  <div class="row">
    <ng-container *ngFor="let news of fnews|async ">
      <ul class="post-grid col-md-3">
        <div [routerLink]="FRONT_ROUTES.actualites_actualite.route(news)">
          <li class="card h-100">
            <div class="row">
              <div class="col">
                <lvadg-image-display [image]="news.logo_details" mode="large"></lvadg-image-display>
              </div>
              <div class="col-6">
                <h3 class="card-title">{{news.title}}</h3>
                <p class="card-text">{{news.content | shorten:100 }}</p>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </ng-container>
  </div>
</div>