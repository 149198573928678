import { Component } from '@angular/core';
import { OrderprocessDetailViewData } from './orderprocess-detail-view.component.params';
import { FilterDefaults, Link, SafeDeleteComponent, TabMemoryService } from '@solidev/data';
import { map, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { OrderprocessManageComponent } from '../../../../models/orders/orderprocess/orderprocess-manage/orderprocess-manage.component';
import { OrderprocessPermissionListComponent } from '../../../../models/orders/orderprocess-permission/orderprocess-permission-list/orderprocess-permission-list.component';
import { OrderprocessPermissionCreateComponent } from '../../../../models/orders/orderprocess-permission/orderprocess-permission-create/orderprocess-permission-create.component';
import { OrderprocessstorageListComponent } from '../../../../models/orders/orderprocessstorage/orderprocessstorage-list/orderprocessstorage-list.component';
import { OrderprocessstorageCreateComponent } from '../../../../models/orders/orderprocessstorage/orderprocessstorage-create/orderprocessstorage-create.component';
import { OrderprocessarticleListComponent } from '../../../../models/orders/orderprocessarticle/orderprocessarticle-list/orderprocessarticle-list.component';
import { OrderprocessarticleCreateComponent } from '../../../../models/orders/orderprocessarticle/orderprocessarticle-create/orderprocessarticle-create.component';
import { OrderListComponent } from '../../../../models/orders/order/order-list/order-list.component';
import { OrderCreateComponent } from '../../../../models/orders/order/order-create/order-create.component';
import { OrdergroupCreateComponent } from '../../../../models/orders/ordergroup/ordergroup-create/ordergroup-create.component';
import { OrdergroupListComponent } from '../../../../models/orders/ordergroup/ordergroup-list/ordergroup-list.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { StorageBase } from '../../../../models/structures/storage/storage.base';
import { PlatformBase } from '../../../../models/structures/platform/platform.base';
import { OrderprocessstorageBase } from '../../../../models/orders/orderprocessstorage/orderprocessstorage.base';
import { ArticleBase } from '../../../../models/catalog/article/article.base';
import { OrderBase } from '../../../../models/orders/order/order.base';
import { OrdergroupBase } from '../../../../models/orders/ordergroup/ordergroup.base';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { OrderprocessService } from '../../../../models/orders/orderprocess/orderprocess.service';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';

@Component({
  selector: 'lvadg-orderprocess-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    OrderprocessManageComponent,
    OrderprocessPermissionListComponent,
    OrderprocessPermissionCreateComponent,
    OrderprocessstorageListComponent,
    OrderprocessstorageCreateComponent,
    OrderprocessarticleListComponent,
    OrderprocessarticleCreateComponent,
    OrderListComponent,
    OrderCreateComponent,
    OrdergroupCreateComponent,
    OrdergroupListComponent,
    SafeDeleteComponent,
    HeaderActionComponent,
  ],
  templateUrl: './orderprocess-detail-view.component.pug',
  styleUrls: ['./orderprocess-detail-view.component.sass'],
})
export class OrderprocessDetailViewComponent extends BaseDetailViewComponent<OrderprocessDetailViewData, Orderprocess> {
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public storage_detail_route!: Link<StorageBase>;
  public platform_detail_route!: Link<PlatformBase>;
  public orderprocessstorage_detail_route!: Link<OrderprocessstorageBase>;
  public article_detail_route?: Link<ArticleBase>;
  public order_detail_route?: Link<OrderBase>;
  public ordergroup_detail_route?: Link<OrdergroupBase>;
  public tariftype_detail_route?: Link<TariftypeBase>;

  public permissionProcessFilter$!: Observable<FilterDefaults>;
  public orderprocessFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public create: Record<string, boolean> = {};
  public permissions_default_fields = [
    'id',
    'permission',
    'user_details',
    'group_details',
    'created',
    'updated',
    'actions',
  ];
  public storages_default_fields = [
    'id',
    'storage_details',
    'platform_details',
    'perms_details',
    'user_level',
    'created',
    'updated',
    'actions',
  ];
  public articles_default_fields = [
    'id',
    'tariftype_details',
    'att_details',
    'article_details',
    'unit_weight',
    'colisage',
    'udv_unit',
    'udv_to_udf_ratio',
    'unit_price',
    'udf_unit',
    'actions',
  ];
  public orders_default_fields = [
    'id',
    'code',
    'date',
    'delivery_date',
    'storage_details',
    'total_price',
    'total_weight',
    'total_quantity',
    'status',
    'group_details',
    'flags',
    'actions',
  ];

  public ordergroup_default_fields = ['id', 'code', 'delivery_date', 'date', 'status', 'created', 'updated', 'actions'];

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    public orderprocess$: OrderprocessService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: OrderprocessDetailViewData) {
    super.setRouteData(data);
    this.user_detail_route = new Link(data.user_detail_route, data);
    this.group_detail_route = new Link(data.group_detail_route, data);
    this.storage_detail_route = new Link(data.storage_detail_route, data);
    this.platform_detail_route = new Link(data.platform_detail_route, data);
    this.article_detail_route = new Link(data.article_detail_route, data);
    this.order_detail_route = new Link(data.order_detail_route, data);
    this.ordergroup_detail_route = new Link(data.ordergroup_detail_route, data);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data);
    this.orderprocessstorage_detail_route = new Link(data.orderprocessstorage_detail_route, data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.permissionProcessFilter$ = this.data$.pipe(
      map((t) => {
        return { target: t.orderprocess.id };
      }),
    );
    this.orderprocessFilter$ = this.data$.pipe(
      map((t) => {
        return { process: t.orderprocess.id };
      }),
    );
  }
}
