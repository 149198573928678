
<ng-template #t let-date="date" let-focused="focused"><span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" [class.used]="haveTarif(date)" (mouseenter)="hoveredDate=date" (mouseleave)="hoveredDate=null">{{ date.day }}</span></ng-template>
<ng-container *ngIf="lasts$|async; let lasts">
  <div class="text-info">Les dates des tarifs ne peuvent pas se chevaucher. Pour corriger un tarif, utilisez la fonctionnalité de mise à jour dans le cycle de vie.</div>
  <div class="row">
    <div class="col-lg-4">
      <div class="text-info">Sélectionnez la source des prix ou disponibilités pour la création<i class="bi bi-lock ms-3" *ngIf="lockedLast" title="Débloquer la sélection de la source" (click)="lockedLast=false" role="button"></i><i class="bi bi-unlock ms-3" *ngIf="!lockedLast" title="Débloquer la sélection de la source" (click)="lockedLast=true" role="button"></i></div>
      <ul>
        <li *ngFor="let t of lasts">
          <lvadg-tarif-display [tarif]="t" mode="line" [classes]="{&quot;text-success&quot;: t.id === last?.id}" (click)="selectLast(t)" role="button"></lvadg-tarif-display>
        </li>
        <li class="text-warning" role="button" (click)="selectLast(undefined)" [class.fw-bold]="!last">Créer un tarif vierge</li>
      </ul>
      <form class="row" [formGroup]="createForm">
        <div class="col-6">
          <label id="startdate">Date de début</label>
          <input class="form-control" type="datetime-local" formControlName="datestart" aria-labelledby="startdate">
        </div>
        <div class="col-6">
          <label id="enddate">Date de fin</label>
          <input class="form-control" type="datetime-local" formControlName="dateend" aria-labelledby="enddate">
        </div>
        <div class="col-12 my-2">
          <label id="tarifname">Nom du tarif</label>
          <div class="input-group"><i class="bi bi-lock input-group-text" *ngIf="lockedName" (click)="toggleNameLock()" role="button"></i><i class="bi bi-unlock input-group-text" *ngIf="!lockedName" (click)="toggleNameLock()" role="button"></i>
            <input class="form-control" type="text" formControlName="name" aria-labelledby="tarifname">
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-8">
      <div class="text-center my-3">
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [startDate]="startDate" [displayMonths]="3" [dayTemplate]="t" outsideDays="hidden" [showWeekNumbers]="true" [markDisabled]="checkDisabled" (navigate)="onNavigation($event, dp)"></ngb-datepicker>
      </div>
    </div>
    <div class="col-8">
      <button class="btn btn-primary w-100" (click)="create()" [disabled]="creating || createForm.invalid">
        <ng-container *ngIf="!creating"><i class="bi bi-save me-2"></i>Créer le nouveau tarif</ng-container>
        <ng-container *ngIf="creating"><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Création en cours, merci de patienter...</ng-container>
      </button>
    </div>
    <div class="col-4">
      <button class="btn btn-outline-secondary w-100" (click)="cancelled.emit()">Annuler</button>
    </div>
  </div>
</ng-container>