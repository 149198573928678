import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from '@solidev/data';

export enum OFFERTYPE_TYPES {
  PRODUCT_LOCAL = 'LP',
  PRODUCT_GLOBAL = 'GP',
  ARTICLE_PROVIDER = 'AP',
  ARTICLE_CATALOG = 'AC',
  ARTICLE_TARIF = 'AT',
}

export enum OFFERTYPE_INTERVALS {
  W1 = 'W1',
  W2 = 'W2',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M6 = 'M6',
  M10 = 'M10',
  M12 = 'M12',
  CS = 'CS',
}

export enum OFFERTYPE_ZONES {
  FL = 'FL',
  SEA = 'SEA',
  '45G' = '45G',
}

export enum OFFERTYPE_STATUS {
  FUTUR = 'PRP',
  CURRENT = 'CUR',
  ARCHIVED = 'ARC',
}

export enum OFFERTYPE_USER_LEVEL {
  sadmin = 'sadmin',
  admin = 'admin',
  spilote = 'spilote',
  read = 'read',
  anon = 'anon',
}

export enum OFFERTYPE_MODE {
  CONTRACT = 'CON',
  FREE_TARIF = 'FRT',
  FREE = 'FRE',
}

export const OFFERTYPE_FLAGS_HUMANIZED = {
  alarm: { desc: 'Toute alarme', class: 'warning' },
  date: { desc: 'Toute alarme de date', class: 'warning' },
  'alarm:nocurrent': { desc: 'Aucune offre en cours', class: 'danger' },
  'alarm:date_publication': { desc: 'Date de publication', class: 'warning' },
  'alarm:date_validation': { desc: 'Date de publication', class: 'info' },
  'alarm:date_preparation': { desc: 'Date de publication', class: 'warning' },
  'alarm:no_rrule': { desc: 'Pas de récurrence', class: 'danger' },
};

export class OffertypeBase extends DataModel {
  static override readonly __name: string = 'OffertypeBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 255,
    priority: 950,
  })
  public name!: string;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 50,
    priority: 975,
  })
  public code!: string;

  @charField({
    name: 'name_template',
    description: 'Template de nom',
    maxLength: 400,
    priority: 200,
  })
  public name_template!: string;

  @textField({
    name: 'internal_notes',
    description: 'Notes internes',
    priority: 200,
  })
  public internal_notes!: string;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: OFFERTYPE_TYPES.PRODUCT_LOCAL,
    maxLength: 2,
    choices: [
      { value: OFFERTYPE_TYPES.PRODUCT_LOCAL, desc: 'Produits locaux' },
      { value: OFFERTYPE_TYPES.PRODUCT_GLOBAL, desc: 'Produits nationaux' },
      {
        value: OFFERTYPE_TYPES.ARTICLE_PROVIDER,
        desc: 'Articles fournisseurs',
      },
      { value: OFFERTYPE_TYPES.ARTICLE_CATALOG, desc: 'Articles catalogue' },
      { value: OFFERTYPE_TYPES.ARTICLE_TARIF, desc: 'Articles tarif' },
    ],
    priority: 900,
  })
  public type: OFFERTYPE_TYPES = OFFERTYPE_TYPES.PRODUCT_LOCAL;

  @charField({
    name: 'mode',
    description: 'Mode',
    defaultValue: OFFERTYPE_MODE.CONTRACT,
    maxLength: 3,
    choices: [
      { value: OFFERTYPE_MODE.CONTRACT, desc: 'Offre contractuelle' },
      { value: OFFERTYPE_MODE.FREE_TARIF, desc: 'Offre libre avec tarif' },
      {
        value: OFFERTYPE_MODE.FREE,
        desc: 'Offre libre',
      },
    ],
    priority: 899,
  })
  public mode: OFFERTYPE_MODE = OFFERTYPE_MODE.CONTRACT;

  @manyToManyField({
    name: 'pilotes',
    description: 'IDs Pilotes',
    defaultValue: [],
    related: 'User',
    priority: -1,
  })
  public pilotes: number[] = [];

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @charField({
    name: 'zone',
    description: 'Zone',
    defaultValue: OFFERTYPE_ZONES.FL,
    maxLength: 3,
    choices: [
      { value: OFFERTYPE_ZONES.FL, desc: 'F&L' },
      {
        value: OFFERTYPE_ZONES.SEA,
        desc: 'Marée',
      },
      { value: OFFERTYPE_ZONES['45G'], desc: '4/5eme gamme' },
    ],
    priority: 700,
  })
  public zone: OFFERTYPE_ZONES = OFFERTYPE_ZONES.FL;

  @charField({
    name: 'base_interval',
    description: 'Intervalle de base',
    defaultValue: OFFERTYPE_INTERVALS.CS,
    maxLength: 3,
    choices: [
      { value: OFFERTYPE_INTERVALS.W1, desc: '1 semaine' },
      { value: OFFERTYPE_INTERVALS.W2, desc: '2 semaines' },
      {
        value: OFFERTYPE_INTERVALS.M1,
        desc: '1 mois',
      },
      { value: OFFERTYPE_INTERVALS.M2, desc: '2 mois' },
      { value: OFFERTYPE_INTERVALS.M3, desc: '3 mois' },
      {
        value: OFFERTYPE_INTERVALS.M6,
        desc: '6 mois',
      },
      { value: OFFERTYPE_INTERVALS.M10, desc: '10 mois' },
      { value: OFFERTYPE_INTERVALS.M12, desc: '12 mois' },
      {
        value: OFFERTYPE_INTERVALS.CS,
        desc: 'Non définie / custom',
      },
    ],
    priority: 300,
  })
  public base_interval: OFFERTYPE_INTERVALS = OFFERTYPE_INTERVALS.CS;

  @textField({
    name: 'rrule',
    description: 'Récurrence',
    defaultValue: '',
    priority: 280,
  })
  public rrule: string = '';

  @integerField({
    name: 'delta_prepared',
    description: 'Delta préparation',
    defaultValue: 84,
    priority: 270,
  })
  public delta_prepared: number = 84;

  @integerField({
    name: 'delta_published',
    description: 'Delta publication',
    defaultValue: 36,
    priority: 260,
  })
  public delta_published: number = 36;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: OFFERTYPE_STATUS.CURRENT,
    maxLength: 4,
    choices: [
      { value: OFFERTYPE_STATUS.FUTUR, desc: 'Futur' },
      { value: OFFERTYPE_STATUS.CURRENT, desc: 'En cours' },
      {
        value: OFFERTYPE_STATUS.ARCHIVED,
        desc: 'Archivée',
      },
    ],
    priority: 180,
  })
  public status: OFFERTYPE_STATUS = OFFERTYPE_STATUS.CURRENT;

  @manyToManyField({
    name: 'tariftypes',
    description: 'ID Modèles de tarifs',
    defaultValue: [],
    related: 'TarifType',
    priority: -1,
  })
  public tariftypes: number[] = [];

  @detailsField({
    name: 'user_level',
    description: 'Permissions utilisateur',
    deserialize: (d) => d,
    priority: -1,
  })
  public user_level: OFFERTYPE_USER_LEVEL[] = [];

  public get _display(): string {
    return `[${this.code || this.id}] ${this.name}`;
  }

  public have_level(level: OFFERTYPE_USER_LEVEL): boolean {
    return this.user_level?.includes(level);
  }
}
