import { RoutesConfig } from '@solidev/data';
import { RS, VivalyaBill, VivalyaBillItem, VivalyaBillItemBase } from '@vivalya/lvadg';

export const ADMIN_SALES_ROUTES_NAMES = [
  'sales',
  'sales_billitems',
  'sales_billitems_billitem',
  'sales_bills',
  'sales_bills_bill',
  'sales_bills_bill_billitem',
] as const;

export const ADMIN_SALES_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_SALES_ROUTES_NAMES)[number]> = {
  sales: {
    params: [],
    route: () => ['/', RS.sales],
    name: 'sales',
  },
  sales_billitems: {
    params: [],
    route: () => ['/', RS.sales, RS.billitems],
    name: 'sales_billitems',
  },
  sales_billitems_billitem: {
    params: ['vivalyabillitem'],
    route: (bi: VivalyaBillItemBase) => ['/', RS.sales, RS.billitems, bi.id],
    name: 'sales_billitems_billitem',
  },
  sales_bills: {
    params: [],
    route: () => ['/', RS.sales, RS.bills],
    name: 'sales_bills',
  },
  sales_bills_bill: {
    params: ['vivalyabill'],
    route: (b: VivalyaBill) => ['/', RS.sales, RS.bills, b.id],
    name: 'sales_bills_bill',
  },
  sales_bills_bill_billitem: {
    params: ['vivalyabill', 'vivalyabillitem'],
    route: (b: VivalyaBill, i: VivalyaBillItem) => ['/', RS.sales, RS.bills, b.id, RS.billitems, i.id],
    name: 'sales_bills_bill_items_item',
  },
};
