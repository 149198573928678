import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { Earticle } from '../earticle';
import { DispeditComponent, FlagsComponent } from '@solidev/data';
import { ClientService } from '../../../structures/client/client.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { MemberService } from '../../../structures/member/member.service';
import { ProviderService } from '../../../structures/provider/provider.service';
import { EacollectionService } from '../../eacollection/eacollection.service';
import { EARTICLE_TYPE } from '../earticle.base';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-earticle-manage',
  standalone: true,
  imports: [NgIf, DispeditComponent, FlagsComponent, IconComponent],
  templateUrl: './earticle-manage.component.pug',
  styleUrls: ['./earticle-manage.component.sass'],
})
export class EarticleManageComponent {
   
  @Input({ required: true }) public earticle!: Earticle;
  public EAT = EARTICLE_TYPE;

  constructor(
    public clients$: ClientService,
    public storages$: StorageService,
    public members$: MemberService,
    public providers$: ProviderService,
    public collections$: EacollectionService
  ) {}
}
