
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { OrderprocessService} from "./orderprocess.service";
import { Orderprocess } from "./orderprocess";

@Injectable({
  providedIn: 'root'
})
export class OrderprocessResolver  {
  constructor(private _rs: OrderprocessService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Orderprocess> {
    return this._rs.fetch(+route.params["orderprocessId"])
  }
}
