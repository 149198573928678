
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportMessageComment } from '../support-message-comment';
@Component({
  selector: 'lvadg-support-message-comment-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './support-message-comment-manage.component.pug',
  styleUrls: ['./support-message-comment-manage.component.sass']
})
export class SupportMessageCommentManageComponent implements OnInit {
  @Input() public supportmessagecomment?: SupportMessageComment;

  constructor() { }

  ngOnInit(): void {
  }

}
