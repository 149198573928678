import { Component, HostBinding, input, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";
import { RI } from "../../../constants";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "lvadg-button-block",
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent],
  templateUrl: "./button-block.component.pug",
  styleUrls: ["./button-block.component.sass"],
})
export class ButtonBlockComponent {
  @Input() public ri?: keyof typeof RI;
  @Input() icon?: string | string[];
  @Input() public mode: "todo" | "dev" | "beta" | "prod" = "prod";
  @Input() public route: (string | number)[] | undefined | null = null;
  @Input() public title: string = "";
  public blank = input<boolean | undefined>(false);
  @Input() public display: string | "r3" | "r4" | "r6" = "r3";

  @HostBinding("class") get klass() {
    if (this.display === "r4") {
      return "col-xl-4 col-md-4 col-sm-4 col-6";
    }
    if (this.display === "r3") {
      return "col-xl-3 col-md-4 col-sm-4 col-6";
    }
    if (this.display === "r6") {
      return "col-xl-6 col-md-6 col-sm-6 col-6";
    }
    return this.display;
  }

  getCls(i: "todo" | "dev" | "beta" | "prod"): string | string[] {
    switch (i) {
      case "todo":
        return ["text-bg-danger"];
      case "dev":
        return ["text-bg-warning"];
      case "beta":
        return ["text-bg-secondary"];
      case "prod":
        return ["text-bg-primary"];
    }
    return [];
  }
}
