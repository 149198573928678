import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Geodepartement } from '../geodepartement';

@Component({
  selector: 'lvadg-geodepartement-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './geodepartement-manage.component.pug',
  styleUrls: ['./geodepartement-manage.component.sass'],
})
export class GeodepartementManageComponent implements OnInit {
  @Input() public geodepartement!: Geodepartement;

  constructor() {}

  ngOnInit(): void {}
}
