import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { Subject } from 'rxjs';
import {
  ComposerMessage,
  TarifMessageComposerComponent,
} from '../../messages/tarif-message-composer/tarif-message-composer.component';
import { TarifLifecycleActionService } from '../../../tarif-lifecycle-action.service';
import { lifecycleNav, TarifLifecycleAction } from '../../../lifecycle-actions';
import { LIFECYCLE_MAIN_STEP } from '../../../lifecycle-steps';
import { TARIF_ROUTES } from '../../../../../../../views/tarifs/tarifs/detail/menu';
import { Router } from '@angular/router';
import { Tariftype } from '../../../../../tariftype/tariftype';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-publish',
  standalone: true,
  imports: [CommonModule, TarifMessageComposerComponent],
  templateUrl: './tarif-lifecycle-step-publish.component.pug',
  styleUrls: ['./tarif-lifecycle-step-publish.component.sass'],
})
export class TarifLifecycleStepPublishComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public tariftype!: Tariftype;
  @Input() public step!: TarifLifecycleStep;
  @Input() public steps!: TarifLifecycleStep[];
  @Input() public menu?: TARIF_ROUTES;
  @Input() public reload$!: Subject<void | boolean>;
  public mode: 'publish' | 'erratum' = 'publish';
  public running: Record<string, boolean> = {};

  public messageData: ComposerMessage | null = null;

  constructor(
    public taction: TarifLifecycleActionService,
    private router: Router,
  ) {}

  public ngOnInit() {
    if (this.step) {
      if (this.step.pstep === LIFECYCLE_MAIN_STEP.PUBLICATION) {
        this.mode = 'publish';
      } else {
        this.mode = 'erratum';
      }
    }
  }

  public async action(action: TarifLifecycleAction) {
    if (action.action === 'nav') {
      await lifecycleNav(action.dest, this.menu, this.router, this.tarif, this.tariftype);
    } else {
      this.running[action.action] = true;
      const res = await this.taction.action(this.tarif, action);
      if (res.success) {
        this.reload$.next(true);
      }
      this.running[action.action] = false;
    }
  }

  public setMessage($event: ComposerMessage | null) {
    this.messageData = $event;
  }
}
