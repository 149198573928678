import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FolderService } from './folder.service';
import { Folder } from './folder';

@Injectable({
  providedIn: 'root',
})
export class FolderResolver {
  constructor(private _rs: FolderService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Folder> {
    if (route.params['folderId']) {
      return this._rs.fetch(+route.params['folderId']);
    } else if (route.params['folderSlugId']) {
      const slugid = route.params['folderSlugId'].split('-');
      return this._rs.fetch(+slugid[slugid.length - 1]);
    }
    throw Error('Folder id not found');
  }
}
