
<div class="fpdialog">
  <div class="text-center">
    <h1 class="my-5">Déconnexion #lavieadugout</h1>
  </div>
  <div class="card">
    <div class="card-header logout"><i class="bi bi-box-arrow-right me-2"></i>Déconnexion</div>
    <div class="card-body" *ngIf="!auth.isAnonymous">
      <div class="mt-3">
        <p class="fs-5 text-center">Vous allez vous déconnecter.<br/>Merci de votre visite et à bientôt.</p>
      </div>
      <div class="mt-5">
        <button class="btn btn-outline-warning btn-lg w-100" (click)="logout()">Me déconnecter</button>
        <button class="btn btn-outline-secondary btn-sm w-100 mt-1" [routerLink]="['/']">J'ai changé d'avis !</button>
      </div>
    </div>
    <div class="card-body" *ngIf="auth.isAnonymous">
      <div class="mt-3">
        <p class="fs-5 text-center">Vous avez été déconnecté avec succès.</p>
      </div>
      <div class="mt-5">
        <button class="btn btn-outline-warning btn-lg w-100" [routerLink]="['/']">Me reconnecter</button>
      </div>
    </div>
  </div>
  <div class="text-center mt-5"><img src="/assets/images/logos/vivalya.png" width="128px"></div>
</div>