import { Component } from '@angular/core';
import { GeoregionDetailViewData } from './georegion-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { GeoregionManageComponent } from '../../../../models/locations/georegion/georegion-manage/georegion-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Georegion } from '../../../../models/locations/georegion/georegion';
import { Link } from '@solidev/data';

@Component({
  selector: 'lvadg-georegion-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, GeoregionManageComponent],
  templateUrl: './georegion-detail-view.component.pug',
  styleUrls: ['./georegion-detail-view.component.sass'],
})
export class GeoregionDetailViewComponent extends BaseDetailViewComponent<GeoregionDetailViewData, Georegion> {
  public list_route!: Link<Georegion>;

  public override setRouteData(data: GeoregionDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
  }

  deleted() {
    this.list_route.navigate();
  }
}
