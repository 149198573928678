
<div class="card" *ngIf="offerproduct.product_details">
  <div class="card-header">Infos produit</div>
  <div class="card-body">
    <table class="table table-sm">
      <tr>
        <th>Produit</th>
        <th>Labels</th>
        <th>Saisons</th>
        <th>Producteur</th>
      </tr>
      <tr>
        <td>
          <lvadg-product-display [product]="offerproduct.product_details" mode="rich" [detail_route]="product_detail_route" [family_detail_route]="family_detail_route"></lvadg-product-display>
        </td>
        <td>@for(l of offerproduct.product_details.labels_details; track l.id) {
          <lvadg-label-display [label]="l"></lvadg-label-display>} @empty {<span class="badge badge-secondary">Pas de label</span>}
        </td>
        <td>
          <lvadg-seasons-manage [seasons]="offerproduct.product_details.seasons || []" [per_line]="4"></lvadg-seasons-manage>
        </td>
        <td>
          <lvadg-producer-display [producer]="offerproduct.product_details.producer_details" mode="linewcpville" [show_location]="true" [detail_route]="producer_detail_route"></lvadg-producer-display>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="card my-2">
  <div class="card-header">Édition produit offre locale</div>
  <div class="card-body">
    <lvadg-offer-product-manage [offerproduct]="offerproduct"></lvadg-offer-product-manage>
  </div>
</div>
<div class="card my-2">
  <div class="card-header">Associations</div>
  <div class="card-body">
    <button class="btn btn-outline-primary w-100 my-2" (click)="add_link=!add_link" *ngIf="!add_link">
      <lvadg-icon ri="plus">Ajouter une association</lvadg-icon>
    </button>
    <button class="btn btn-outline-primary w-100 my-2" (click)="add_link=!add_link" *ngIf="add_link">
      <lvadg-icon ri="plus">Fermer le mode liaison</lvadg-icon>
    </button>
  </div>
  <div class="card-body" *ngIf="add_link">
    <lvadg-offer-product-link-create [offerproduct]="offerproduct" (created)="reload?.next(true)" (canceled)="add_link=false"></lvadg-offer-product-link-create>
  </div>
  <div class="card-body" *ngIf="!add_link">
    <lvadg-offer-product-link-list [name]="'offerproduct_links_listmanage'" [reload]="reload" [filter]="offerproductFilter$" [default_fields]="links_default_fields" [dispFilters]="false" [dispFieldsSelector]="false" [dispXlsxExport]="false" [displayMode]="'ml-hidden'" [keep]="false" [actions]="[&quot;delete&quot;]" (action)="linkDelete($event)"></lvadg-offer-product-link-list>
  </div>
</div>