import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from "@solidev/data";

export class LogUsageBase extends DataModel {
  static override readonly __name: string = "LogUsageBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @charField({
    name: "method",
    description: "Méthode",
    maxLength: 10,
    priority: 950,
  })
  public method!: string;

  @charField({
    name: "act",
    description: "Action",
    maxLength: 50,
    priority: 900,
  })
  public act!: string;

  @charField({
    name: "view",
    description: "Vue",
    maxLength: 255,
    priority: 800,
  })
  public view!: string;

  @charField({
    name: "url",
    description: "URL",
    maxLength: 4096,
    priority: 700,
  })
  public url!: string;

  @detailsField({
    name: "params",
    description: "Query",
    defaultValue: {},
    priority: 500,
  })
  public params: Record<string, number | string> = {};

  @textField({ name: "user_agent", description: "User agent", priority: 400 })
  public user_agent!: string;

  @charField({
    name: "referrer",
    description: "Referrer",
    maxLength: 1024,
    priority: 300,
  })
  public referrer!: string;

  @datetimeField({ name: "timestamp", description: "Timestamp", priority: 100 })
  public timestamp!: Date;

  @foreignKeyField({
    name: "user",
    description: "Uuser id",
    related: "User",
    priority: -1,
  })
  public user!: number;

  @detailsField({ name: "ips", description: "ips", priority: 200 })
  public ips!: string[];

  @detailsField({
    name: "data",
    description: "Data",
    defaultValue: {},
    priority: 450,
  })
  public data: unknown = {};

  @integerField({
    name: "duration",
    description: "Durée",
    priority: 50,
  })
  public duration!: number;
}
