import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../models/users/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataMessageService } from '@solidev/data';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-pwreset-view',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './pwreset-view.component.pug',
  styleUrls: ['./pwreset-view.component.sass'],
})
export class PwresetViewComponent implements OnInit {
  public token?: string;
  public email?: string;
  public password?: string;
  public error: string = '';
  public mode?: 'ask' | 'waiting';

  public pwresetForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    token: new FormControl('', [Validators.required]),
  });

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private router: Router,
    private _msgs: DataMessageService
  ) {}

  public ngOnInit() {
    this.token = this._route.snapshot.queryParams['token'];
    this.email = this._route.snapshot.queryParams['email'];
    this.pwresetForm.patchValue({ username: this.email, token: this.token });
    this.mode = this._route.snapshot.queryParams['mode'];
  }

  async processPwReset() {
    if (
      this.pwresetForm.value.username &&
      this.pwresetForm.value.token &&
      this.pwresetForm.value.password
    ) {
      const res = await firstValueFrom(
        this._auth.processPwReset(
          this.pwresetForm.value.username,
          this.pwresetForm.value.password,
          this.pwresetForm.value.token
        )
      );
      if (res) {
        this._msgs.success(
          'Mot de passe modifié avec succès, vous pouvez désormais vous connecter'
        );
        this.router.navigate(this._auth.loginRoute);
      } else {
        this._msgs.error(
          'Erreur lors de la modification du mot de passe, merci de contacter le support (support@lavieadugout.fr).'
        );
      }
    }
  }

  public async requestPwReset() {
    const res = await firstValueFrom(
      this._auth.requestPwReset(this.pwresetForm.value.username as string)
    );
    if (res) {
      this._msgs.success("Demande d'envoi de validation traitée avec succès");
      this.mode = 'waiting';
    }
  }
}
