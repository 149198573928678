
<ng-container *ngIf="permission">
  <ng-container *ngIf="mode==='line'"><span class="badge bg-primary">{{permission.permission|pchoice:permission:'permission'}}</span>&nbsp;
    <ng-container *ngIf="permission.user_details">
      <lvadg-user-display [user]="permission.user_details" mode="line" [detail_route]="user_detail_route"></lvadg-user-display>
    </ng-container>
    <ng-container *ngIf="permission.group_details">
      <lvadg-group-display [group]="permission.group_details" mode="line" [detail_route]="group_detail_route"></lvadg-group-display>
    </ng-container>
  </ng-container>
</ng-container>