
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Iocode } from '../iocode';
@Component({
  selector: 'lvadg-iocode-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './iocode-manage.component.pug',
  styleUrls: ['./iocode-manage.component.sass']
})
export class IocodeManageComponent implements OnInit {
  @Input() public iocode?: Iocode;

  constructor() { }

  ngOnInit(): void {
  }

}
