import {Component, inject, signal, TemplateRef, ViewChild} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProviderDocumentListComponent } from "../../../../../models/structures/provider-document/provider-document-list/provider-document-list.component";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { BaseListViewComponent } from "../../../../../components/baseview/baselistview.component";
import { Subject } from "rxjs";
import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";
import { ProviderDocument } from "../../../../../models/structures/provider-document/provider-document";
import { ArticleBase } from "../../../../../models/catalog/article/article.base";
import { ProviderBase } from "../../../../../models/structures/provider/provider.base";
import { ProviderDocumentCreateComponent } from "../../../../../models/structures/provider-document/provider-document-create/provider-document-create.component";
import { AuthService } from "../../../../../models/users/auth.service";
import { ADMIN_GROUPS } from "../../../../../constants";
import {
  ModelListAction
} from "../../../../../includes/modellist/modellist.component";
import {NgbOffcanvas, NgbOffcanvasRef} from "@ng-bootstrap/ng-bootstrap";
import {Provider} from "../../../../../models/structures/provider/provider";
import {
  ProviderDocumentManageComponent
} from "../../../../../models/structures/provider-document/provider-document-manage/provider-document-manage.component";

export interface ProviderDocumentListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  provider_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface ProviderDocumentListViewData extends ProviderDocumentListViewParams {
    provider: Provider;
  }

@Component({
  selector: "lvadg-provider-document-list-view",
  standalone: true,
  imports: [
    CommonModule,
    ProviderDocumentListComponent,
    ProviderDocumentCreateComponent,
    HeaderActionComponent,
    ProviderDocumentManageComponent,
  ],
  templateUrl: "./provider-document-list-view.component.pug",
  styleUrl: "./provider-document-list-view.component.sass",
})
export class ProviderDocumentListViewComponent extends BaseListViewComponent<
  ProviderDocumentListViewData,
  ProviderDocument
> {
  public create = signal<boolean>(false);
  public reload$ = new Subject<void | boolean>();
  public provider_detail_route?: Link<ProviderBase>;
  public article_detail_route?: Link<ArticleBase>;
  public canEdit = signal(false);
  public currentUser = inject(AuthService).currentUser;
  public currentDocument = signal<ProviderDocument | null>(null);
  @ViewChild("docEditSlot", { static: false })
  public docEditSlot?: TemplateRef<HTMLDivElement>;
  private _ofc = inject(NgbOffcanvas);
  private _docEditInstance?: NgbOffcanvasRef;
  public default_fields = [
    "provider_details",
    "type",
    "label_details",
    "filename",
    "articles_details",
    "status",
    "start",
    "end",
    "status",
  ];

  public override setRouteData(data: ProviderDocumentListViewData) {
    super.setRouteData(data);
    this.canEdit.set(
      !!(
        this.currentUser &&
        (this.currentUser.is_superuser ||
          this.currentUser.have_group(ADMIN_GROUPS.providers_editor))
      ),
    );
    if(this.canEdit())
    {
    this.default_fields = [
      "id",
      "provider_details",
      "type",
      "label_details",
      "filename",
      "articles_details",
      "status",
      "start",
      "end",
      "status",
      "actions",
    ]
    }
    this.provider_detail_route = new Link<ProviderBase>(
      data.provider_detail_route,
      data,
      this._router,
    );
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      data,
      this._router,
    );
  }

  public created(pd: ProviderDocument) {
    this.create.set(false);
    this.reload$.next(true);
    console.log("created", pd);
    this.detail_route.navigate({ providerdocument: pd });
  }

  public onAction(
    action: ModelListAction<ProviderDocument>,
    data: ProviderDocumentListViewData,
  ) {
    if (action.action === "edit" && action.model) {
      this.currentDocument.set(action.model!);
      if (this.docEditSlot) {
        this._docEditInstance = this._ofc.open(this.docEditSlot, {
          position: "end",
          panelClass: "w-75",
        });
        this._docEditInstance.closed.subscribe(() => {
          this.currentDocument.set(null);
          this.reload$.next(true);
        });
      }
    }
  }
}
