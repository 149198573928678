import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OffertypeStoragePermission } from '../offertype-storage-permission';
import { OffertypeStoragePermissionService } from '../offertype-storage-permission.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  PFactorPipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { OffertypeActionService } from '../../offertype/offertype-action.service';
import { OffertypeService } from '../../offertype/offertype.service';
import { Offertype } from '../../offertype/offertype';

@Component({
  selector: 'lvadg-offertype-storage-permission-list',
  standalone: true,
  templateUrl: './offertype-storage-permission-list.component.pug',
  styleUrls: ['./offertype-storage-permission-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    UserDisplayComponent,
    GroupDisplayComponent,
    PFactorPipe,
    PChoicePipe,
    SafeDeleteComponent,
  ],
})
export class OffertypeStoragePermissionListComponent extends ModellistComponent<OffertypeStoragePermission> {
  @Input() public offertype?: Offertype;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  public deleteFn = this._deleteFn.bind(this);

  constructor(
    coll: OffertypeStoragePermissionService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _otact: OffertypeActionService,
    private _ots: OffertypeService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  private async _deleteFn(model: OffertypeStoragePermission): Promise<{ success: boolean }> {
    if (!this.offertype || !model.target) {
      return { success: false };
    }
    this.offertype._collection = this._ots;
    const res = await this._otact.action(this.offertype, {
      action: 'remove_storage_permission',
      otstorage: model.target,
      permission: model.id,
    });
    return res;
  }
}
