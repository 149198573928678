import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Client } from '../../../../../models/structures/client/client';
import { CLIENT_ROUTES } from '../menu';
import { TariftypeListComponent } from '../../../../../models/tarifs/tariftype/tariftype-list/tariftype-list.component';
import { ClientNavComponent } from '../_nav/client-nav.component';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { GenericregionBase } from '../../../../../models/locations/genericregion/genericregion.base';
import { Group } from '../../../../../models/users/group/group';
import { User } from '../../../../../models/users/user/user';
import { Member } from '../../../../../models/structures/member/member';
import { Storage } from '../../../../../models/structures/storage/storage';

export interface ClientTariftypesViewParams extends BaseRouteParams {
  c_menu: CLIENT_ROUTES;
  tariftype_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  member_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  genericregion_detail_route?: RouteConfigItem;
}

export interface ClientTariftypesViewData extends ClientTariftypesViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-tariftypes-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, TariftypeListComponent, ClientNavComponent, IconComponent],
  templateUrl: './client-tariftypes-view.component.pug',
  styleUrls: ['./client-tariftypes-view.component.sass'],
})
export class ClientTariftypesViewComponent extends BaseDetailViewComponent<ClientTariftypesViewData, Client> {
  public clientFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = [];
  public reload$ = new Subject<void | boolean>();
  public tariftype_detail_route?: Link<Tariftype>;
  public client_detail_route?: Link<Client>;
  public storage_detail_route?: Link<Storage>;
  public member_detail_route?: Link<Member>;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public genericregion_detail_route?: Link<GenericregionBase>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ClientTariftypesViewData) {
    super.setRouteData(data);
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link<Tariftype>(data.tariftype_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
    }
    if (data.member_detail_route) {
      this.member_detail_route = new Link<Member>(data.member_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link<Group>(data.group_detail_route, data, this._router);
    }
    if (data.genericregion_detail_route) {
      this.genericregion_detail_route = new Link<GenericregionBase>(
        data.genericregion_detail_route,
        data,
        this._router,
      );
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.clientFilter$ = this.data$.pipe(map((d) => ({ client: d.client.id })));
  }
}
