import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { ArticleSimilarity } from '../article-similarity';
import { ArticleSimilarityService } from '../article-similarity.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ArticleBase } from '../../article/article.base';
import { ArticleDisplayComponent } from '../../article/article-display/article-display.component';

@Component({
  selector: 'lvadg-article-similarity-list',
  standalone: true,
  templateUrl: './article-similarity-list.component.pug',
  styleUrls: ['./article-similarity-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, ArticleDisplayComponent],
})
export class ArticleSimilarityListComponent extends ModellistComponent<ArticleSimilarity> {
  @Input() public article_detail_route?: Link<ArticleBase>;

  constructor(coll: ArticleSimilarityService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  public override getRowClasses(row: ArticleSimilarity): string[] {
    const cls = super.getRowClasses(row);
    if (row.differences === 0) {
      cls.push('table-danger');
    }
    if (row.differences === 1) {
      cls.push('table-warning');
    }
    if (row.differences === 2) {
      cls.push('table-secondary');
    }
    return cls;
  }
}
