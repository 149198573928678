import { OfferDocumentBase } from './offer-document.base';
import { detailsField } from '@solidev/data';
import { Resto } from '../../structures/resto/resto';
import { ImageDisplay } from '../../documents/image/image.base';
import { OfferBase } from '../offer/offer.base';

export class OfferDocument extends OfferDocumentBase {
  static override readonly __name: string = 'OfferDocument';

  @detailsField({
    name: 'resto_details',
    description: 'Restaurant',
    readonly: true,
    model: Resto,
    priority: 800,
  })
  public resto_details?: Resto;

  @detailsField({
    name: 'image_details',
    description: 'Aperçu',
    readonly: true,
    model: ImageDisplay,
    priority: 750,
  })
  public image_details?: ImageDisplay;

  @detailsField({
    name: 'offer_details',
    description: 'Offre',
    readonly: true,
    model: OfferBase,
    priority: 900,
  })
  public offer_details?: OfferBase;
}
