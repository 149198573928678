
<ng-container *ngIf="display==='card' || display==='fh-card'">
  <div class="card-header">
    <lvadg-icon ri="offer"><span class="fw-bold">{{title}}</span></lvadg-icon>
  </div>
  <div [class.max-height-200]="display!=='fh-card'" [class.max-height-full]="display==='fh-card'">
    <nav class="list-group list-group-flush">
      <ng-container *ngFor="let o of offers$|async">
        <ng-container *ngIf="!o.offertypestorage"><a class="list-group-item list-group-item-action" [routerLink]="offer_detail_routes[o.type]?.route({offer: o})">
            <div><span class="badge bg-info me-1" *ngIf="o.type===OFFER_TYPES.PRODUCT_LOCAL">L</span>{{o.title}}</div>
            <div><span class="badge bg-primary">{{o.storage_details?.name || '??'}} &rightarrow; {{o.client_details?.name || '??'}}</span><span class="badge bg-info ms-2">{{o.datestart | date:'shortDate'}} &rightarrow; {{o.dateend | date:'shortDate'}}</span></div></a></ng-container>
        <ng-container *ngIf="o.offertypestorage"><a class="list-group-item list-group-item-action" [routerLink]="ots_offer_detail_routes[o.type]?.route({offertypestorage: $any({id: o.offertypestorage}), offer: o})">
            <div><span class="badge bg-info me-1" *ngIf="o.type===OFFER_TYPES.PRODUCT_LOCAL">L</span><span class="badge bg-info me-1">C</span>{{o.title}}</div>
            <div><span class="badge bg-primary">{{o.storage_details?.name || '??'}} &rightarrow; {{o.client_details?.name || '??'}}</span><span class="badge bg-info ms-2">{{o.datestart | date:'shortDate'}} &rightarrow; {{o.dateend | date:'shortDate'}}</span></div></a></ng-container>
      </ng-container>
    </nav>
  </div>
</ng-container>