import { CdataKeyBase } from "./cdata-key.base";
import { detailsField } from "@solidev/data";
import { CdataKeyChoice } from "../cdata-key-choice/cdata-key-choice";

export class CdataKey extends CdataKeyBase {
  static override readonly __name: string = "CdataKey";

  @detailsField({
    description: "Parent",
    model: CdataKeyBase,
    readonly: true,
    priority: -1,
  })
  public parent_details?: CdataKeyBase;

  @detailsField({
    description: "Choices",
    model: CdataKeyChoice,
    many: true,
    priority: 100,
  })
  public choices_details?: CdataKeyChoice[];
}
