import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../../../../../models/structures/member/member';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OfferListComponent } from '../../../../../models/offers/offer/offer-list/offer-list.component';
import { MEMBER_ROUTES } from '../menu';
import { MemberNavComponent } from '../_nav/member-nav.component';
import { map, Observable, Subject } from 'rxjs';
import { Offer } from '../../../../../models/offers/offer/offer';
import { Client } from '../../../../../models/structures/client/client';
import { Storage } from '../../../../../models/structures/storage/storage';
import { Resto } from '../../../../../models/structures/resto/resto';
import { User } from '../../../../../models/users/user/user';
import { Offertype } from '../../../../../models/offers/offertype/offertype';
import { OffertypeStorage } from '../../../../../models/offers/offertype-storage/offertype-storage';

export interface MemberOffersViewParams extends BaseRouteParams {
  m_menu: MEMBER_ROUTES;
  offer_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
}

export interface MemberOffersViewData extends MemberOffersViewParams {
  member: Member;
}

@Component({
  selector: 'lvadg-member-offers-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OfferListComponent, MemberNavComponent],
  templateUrl: './member-offers-view.component.pug',
  styleUrls: ['./member-offers-view.component.sass'],
})
export class MemberOffersViewComponent extends BaseDetailViewComponent<MemberOffersViewData, Member> {
  public memberFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public default_fields: string[] = [];
  public offer_detail_route?: Link<Offer>;
  public client_detail_route?: Link<Client>;
  public storage_detail_route?: Link<Storage>;
  public resto_detail_route?: Link<Resto>;
  public user_detail_route?: Link<User>;
  public offertype_detail_route?: Link<Offertype>;
  public offertypestorage_detail_route?: Link<OffertypeStorage>;
  public offertypestorage_offer_detail_route?: Link<Offer>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: MemberOffersViewData) {
    super.setRouteData(data);
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    }
    if (data.offer_detail_route) {
      this.offer_detail_route = new Link<Offer>(data.offer_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link<Resto>(data.resto_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
    if (data.offertype_detail_route) {
      this.offertype_detail_route = new Link<Offertype>(data.offertype_detail_route, data, this._router);
    }
    if (data.offertypestorage_detail_route) {
      this.offertypestorage_detail_route = new Link<OffertypeStorage>(
        data.offertypestorage_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertypestorage_offer_detail_route) {
      this.offertypestorage_offer_detail_route = new Link<Offer>(
        data.offertypestorage_offer_detail_route,
        data,
        this._router,
      );
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.memberFilter$ = this.data$.pipe(map((data) => ({ member: data.member.id })));
  }
}
