import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferViewData, OfferViewParams } from '../menu';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OfferNavComponent } from '../_nav/offer-nav.component';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Offer } from '../../../../../models/offers/offer/offer';
import { OfferNotificationStatusListComponent } from '../../../../../models/offers/offer-notification-status/offer-notification-status-list/offer-notification-status-list.component';
import { map, Observable } from 'rxjs';
import { FilterDefaults } from '@solidev/data';

export interface OfferNotificationsStatusesViewParams extends OfferViewParams {}

export interface OffertNotificationsStatusesViewData extends OfferViewData, OfferNotificationsStatusesViewParams {}

@Component({
  selector: 'lvadg-offer-notifications-statuses-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OfferNotificationStatusListComponent, OfferNavComponent],
  templateUrl: './offer-notifications-statuses-view.component.pug',
  styleUrls: ['./offer-notifications-statuses-view.component.sass'],
})
export class OfferNotificationsStatusesViewComponent extends BaseDetailViewComponent<
  OffertNotificationsStatusesViewData,
  Offer
> {
  public offerFilter$!: Observable<FilterDefaults>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offerFilter$ = this.data$.pipe(map((data) => ({ offer: data.offer.id })));
  }
}
