import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Offertype } from '../../../../../models/offers/offertype/offertype';
import { OffertypeManageComponent } from '../../../../../models/offers/offertype/offertype-manage/offertype-manage.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OffertypeNavComponent } from '../_nav/offertype-nav.component';
import { OffertypeViewParams } from '../menu';

export interface OffertypeEditViewParams extends OffertypeViewParams {}

export interface OffertypeEditViewData extends OffertypeEditViewParams {
  offertype: Offertype;
}

@Component({
  selector: 'lvadg-offertype-edit-view',
  standalone: true,
  imports: [CommonModule, OffertypeManageComponent, HeaderActionComponent, OffertypeNavComponent],
  templateUrl: './offertype-edit-view.component.pug',
  styleUrls: ['./offertype-edit-view.component.sass'],
})
export class OffertypeEditViewComponent extends BaseDetailViewComponent<OffertypeEditViewData, Offertype> {}
