
<ng-container *ngIf="order$|async; let order">
  <h1><code class="mx-2">{{order.code}}</code>{{order.process_details?.name}} / {{order.storage_details?.name}}<span class="fs-4 ms-2">enlèvement le {{order.delivery_date|date:'fullDate'}} - {{order.status|pchoice:order:'status'}}</span></h1>
  <div class="row my-3 g-2">
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row">
            <dl>
              <dt>Date de commande</dt>
              <dd>{{order.date|date:'fullDate'}}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100" [class.bg-warning]="order.flags.indexOf('alert:deliverydate')!==-1">
        <div class="card-body">
          <dl>
            <dt>Date d'enlèvement / livraison</dt>
            <dd>{{order.delivery_date|date:'fullDate'}}</dd>
          </dl>
          <ng-container *ngIf="isEditable(order)">
            <div class="text-info my-1">Modifier la date d'enlèvement : dates d'enlèvement possibles.</div>
            <div class="my-2"><span class="me-2 badge bg-light" *ngFor="let d of dates$|async" [class.bg-primary]="false" (click)="action(order, 'set_delivery_date', {date: d})" role="button">{{d|date:'shortDate'}} {{d|date:'EE'}}</span></div><span class="ms-2" (click)="customEditDate=true" role="button">Définir une date d'enlèvement exceptionnelle</span>
            <input class="form-control" *ngIf="customEditDate" [formControl]="deliveryDateControl" type="date" (keyup.enter)="action(order, 'set_delivery_date', {date: deliveryDateControl.value}); customEditDate=false">
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-body">
          <dl class="row">
            <div class="col-6">
              <dt>État</dt>
              <dd>{{order.status|pchoice:order:'status'}}</dd>
            </div>
            <div class="col-6" *ngIf="order.have_process_level(OrderProcessUserLevel.WRITE)">
              <dt>Consolidation</dt>
              <dd>
                <lvadg-ordergroup-display [ordergroup]="order.group_details" mode="line" [detail_route]="ordergroup_detail_route"></lvadg-ordergroup-display>
              </dd>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <dt>Poids total</dt>
                  <dd>
                    <ng-container *ngIf="order.total_weight">{{order.total_weight|pfactor:order:'total_weight'}} Kg</ng-container>
                    <ng-container *ngIf="!order.total_weight">Commande vide</ng-container>
                  </dd>
                </div>
                <div class="col-6">
                  <dt>Prix HT total</dt>
                  <dd>
                    <ng-container *ngIf="order.total_price">{{order.total_price|pfactorc:order:'total_price':'EUR'}}</ng-container>
                    <ng-container *ngIf="!order.total_price">Commande vide</ng-container>
                  </dd>
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">Articles commande</div>
        <div class="card-body">
          <lvadg-orderitem-list [reload]="reload$" [default_fields]="orderitems_default_fields" [filter]="$any(orderFilter$)" [dispLoading]="false" [quantityEditor]="true" [dispFilters]="false" [dispPagination]="false" [dispFieldsSelector]="false" [dispFooters]="true"></lvadg-orderitem-list>
          <button class="btn btn-primary float-end" (click)="action(order, 'validate')" *ngIf="order.status===OrderStatus.PREPARATION">Valider la commande</button>
          <button class="btn btn-warning float-end" (click)="action(order, 'reprepare')" *ngIf="order.status===OrderStatus.VALIDATED">Remettre la commande en préparation</button>
          <ng-container *ngIf="order.have_process_level(OrderProcessUserLevel.OWNER)">
            <button class="btn btn-danger float-end" (click)="action(order, 'consolidate')" *ngIf="order.status===OrderStatus.VALIDATED">Consolider</button>
            <button class="btn btn-warning float-end" (click)="action(order, 'reprepare')" *ngIf="order.status===OrderStatus.CONSOLIDATED">Remettre la commande en préparation</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>