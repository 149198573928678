import { Component, Input, signal } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Producer } from "../producer";
import { ProducerService } from "../producer.service";
import {
  BanAdapter,
  FieldsParams,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListFilterGroup,
  ModelListGeodistanceFilter,
  ModelListNumberFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  ModelListTreeFilter,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgStringPipesModule } from "ngx-pipes";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { ProducerDocumentDisplayComponent } from "../../producer-document/producer-document-display/producer-document-display.component";
import { PRODUCER_TYPES, ProducerBase, ProducerStatus } from "../producer.base";
import { LabelService } from "../../../catalog/label/label.service";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { delay, filter, map, mergeMap, Observable, of } from "rxjs";
import { LOCAL_FILTERS } from "../../../catalog/product/product.base";
import { StorageBase } from "../../storage/storage.base";
import { IMAGE_USAGE_CHOICES } from "../../../documents/image/image.base";
import { HttpClient } from "@angular/common/http";
import { GeodepartementService } from "../../../locations/geodepartement/geodepartement.service";
import { GeoregionService } from "../../../locations/georegion/georegion.service";
import { GeocommuneService } from "../../../locations/geocommune/geocommune.service";
import { StorageService } from "../../storage/storage.service";
import { FAMILY_TYPE } from "../../../catalog/family/family.base";
import { FamilyService } from "../../../catalog/family/family.service";
import { RelationDisplayComponent } from "../../relation/relation-display/relation-display.component";
import { SelectItemComponent } from "../../../../components/selection/select-item/select-item.component";
import { SelectHeaderComponent } from "../../../../components/selection/select-header/select-header.component";
import { SelectionService } from "../../../../components/selection/selection.service";
import { DistanceCacheDisplayComponent } from "../../../locations/distance/distance-cache-display/distance-cache-display.component";
import { IocodeDisplayComponent } from "../../../lvadgio/iocode/iocode-display/iocode-display.component";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { QuickprintButtonComponent } from "../../../layouts/print/quickprint-button/quickprint-button.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { ProducersMapComponent } from "../../../locations/mapping/producers-map/producers-map.component";
import { CdataKey } from "../../../cdata/cdata-key/cdata-key";
import { CdataDisplayComponent } from "../../../cdata/cdata/cdata-display/cdata-display.component";

@Component({
  selector: "lvadg-producer-list",
  standalone: true,
  templateUrl: "./producer-list.component.pug",
  styleUrls: ["./producer-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule,
    LocationDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    ProducerDocumentDisplayComponent,
    RelationDisplayComponent,
    SelectItemComponent,
    SelectHeaderComponent,
    DistanceCacheDisplayComponent,
    IocodeDisplayComponent,
    TodoComponent,
    IconComponent,
    QuickprintButtonComponent,
    ProducersMapComponent,
    CdataDisplayComponent,
  ],
})
export class ProducerListComponent extends ModellistComponent<Producer> {
  @Input() public local?: LOCAL_FILTERS;
  @Input() public type?: PRODUCER_TYPES[];
  @Input() public storage?: StorageBase;
  /** Storage detail route */
  @Input() public storage_detail_route?: Link<StorageBase>;
  /** Selection enabled ? */
  @Input() public select = true;
  /** Custom data allowed keys */
  @Input() public cdatakeys: CdataKey[] = [];
  public print_filters_whitelist = signal<string[]>([]);

  public producerDistanceRef$!: Observable<{
    pos: [number, number];
    label: string;
  }>;
  public storageDistanceRef$!: Observable<{
    pos: [number, number];
    label: string;
  }>;
  public xlsxMode: boolean = false;

  constructor(
    coll: ProducerService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _http: HttpClient,
    private _labels: LabelService,
    private _departments: GeodepartementService,
    private _regions: GeoregionService,
    private _communes: GeocommuneService,
    private _storages: StorageService,
    private _families: FamilyService,
    private _select: SelectionService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    this.print_filters_whitelist.set([
      "local",
      "type",
      "storage",
      "storages",
      "storage_producer_distance",
      "storage_near",
    ]);
    const adapter = new BanAdapter(
      this._http,
      "https://gis.lavieadugout.fr/geocoder/search",
    );
    const producer_filter = new ModelListFilterGroup({
      name: "product_filter",
      label: "Recherche générale",
      desc: "Filtres sur les informations producteurs",
      position: 1,
      filters: [
        // Global filters
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectMultiFilter({
          field: "statuses",
          name: "statuses",
          label: "Recherche par état(s)",
          model: ProducerBase,
          mfield: "status",
          default: [
            {
              value: ProducerStatus.OK,
              desc: "En activité, valide",
            },
          ],
        }),
        new ModelListSelectFilter({
          field: "type",
          name: "type",
          label: "Recherche par type",
          model: ProducerBase,
        }),
        new ModelListSelectMultiFilter({
          field: "pubimages",
          name: "pubimages",
          label: "Recherche par droit à l'image",
          model: ProducerBase,
          mfield: "pubimage",
        }),
        new ModelListSelectMultiFilter({
          field: "medias",
          name: "medias",
          label: "Recherche par médias disponibles (un)",
          choices: IMAGE_USAGE_CHOICES,
        }),
        new ModelListSelectMultiFilter({
          field: "medias_all",
          name: "medias_all",
          label: "Recherche par médias disponibles (tous)",
          choices: IMAGE_USAGE_CHOICES,
        }),
        new ModelListNumberFilter({
          name: "nb_products",
          label: "Recherche par nombre de produits",
          field: "nb_products",
        }),
      ],
    });
    const storage_filter = new ModelListFilterGroup({
      name: "storage_filter",
      label: "Recherche par dépot",
      desc: "Filtres sur les informations dépot",
      position: 2,
      filters: [
        // Storage filter
        new ModelListAutocompleteMultiFilter({
          field: "storages",
          name: "storages",
          label: "Recherche par dépôt(s)",
          collection: this._storages,
        }),

        new ModelListNumberFilter({
          name: "storage_producer_distance",
          field: "storage_producer_distance",
          label: "Recherche par distance dépôt-producteur (au moins un)",
          desc: "Distance en km",
        }),
        // Commune du dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storage_communes",
          name: "storage_communes",
          label: "Commune(s) du dépôt",
          collection: this._communes,
          filter: { country_code: "FR" },
        }),
        // Département du dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storage_departments",
          name: "storage_departments",
          label: "Département(s) du dépôt",
          collection: this._departments,
          filter: { country_code: "FR" },
        }),
        // Région du dépôt
        new ModelListAutocompleteMultiFilter({
          field: "storage_regions",
          name: "storage_regions",
          label: "Région(s) du dépôt",
          collection: this._regions,
          filter: { country_code: "FR" },
        }),
        new ModelListGeodistanceFilter({
          name: "storage_near",
          field: "storage_near",
          label: "Recherche dépôt à proximité de  (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: "Ville ou adresse (en France)",
          geolocator: (pos: string) => adapter.search(pos),
        }),
      ],
    });
    const geoproducer_filter = new ModelListFilterGroup({
      name: "geoproducer_filter",
      label: "Recherche par géographique producteur",
      desc: "Filtres sur les informations géographiques producteur",
      position: 3,
      filters: [
        // Producer filter
        new ModelListGeodistanceFilter({
          name: "producer_near",
          field: "producer_near",
          label: "Recherche producteur à proximité de  (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: "Ville ou adresse (en France)",
          geolocator: (pos: string) => adapter.search(pos),
        }),
        // Commune du producteur
        new ModelListAutocompleteMultiFilter({
          field: "producer_communes",
          name: "producer_communes",
          label: "Commune(s) du producteur",
          collection: this._communes,
          filter: { country_code: "FR" },
        }),
        // Département du producteur
        new ModelListAutocompleteMultiFilter({
          field: "producer_departments",
          name: "producer_departments",
          label: "Département(s) du producteur",
          collection: this._departments,
          filter: { country_code: "FR" },
        }),
        // Région du producteur
        new ModelListAutocompleteMultiFilter({
          field: "producer_regions",
          name: "producer_regions",
          label: "Région(s) du producteur",
          collection: this._regions,
          filter: { country_code: "FR" },
        }),
      ],
    });
    const products_filter = new ModelListFilterGroup({
      name: "products_filter",
      label: "Recherche par caractéristiques produit",
      desc: "Filtres sur les caractéristiques produit",
      position: 4,
      filters: [
        // Product filter
        new ModelListSelectMultiFilter({
          field: "plabels",
          name: "plabels",
          label: "Recherche par label(s) produit(s)",
          choices: this._labels.byType(LABEL_TYPE.FL).pipe(
            map((labels) =>
              labels.map((l) => ({
                value: l.id,
                desc: `${l.type} : ${l.name}`,
              })),
            ),
          ),
        }),
        // Saisonnalité
        new ModelListSelectMultiFilter({
          field: "seasons",
          name: "seasons",
          label: "Saisonnalité(s) (une)",
          choices: [
            { desc: "Janvier", value: "1" },
            { desc: "Février", value: "2" },
            { desc: "Mars", value: "3" },
            { desc: "Avril", value: "4" },
            { desc: "Mai", value: "5" },
            { desc: "Juin", value: "6" },
            { desc: "Juillet", value: "7" },
            { desc: "Août", value: "8" },
            { desc: "Septembre", value: "9" },
            { desc: "Octobre", value: "10" },
            { desc: "Novembre", value: "11" },
            { desc: "Décembre", value: "12" },
          ],
        }),
        // Saisonnalité
        new ModelListSelectMultiFilter({
          field: "seasons_all",
          name: "seasons_all",
          label: "Saisonnalité(s) (toutes)",
          choices: [
            { desc: "Janvier", value: "1" },
            { desc: "Février", value: "2" },
            { desc: "Mars", value: "3" },
            { desc: "Avril", value: "4" },
            { desc: "Mai", value: "5" },
            { desc: "Juin", value: "6" },
            { desc: "Juillet", value: "7" },
            { desc: "Août", value: "8" },
            { desc: "Septembre", value: "9" },
            { desc: "Octobre", value: "10" },
            { desc: "Novembre", value: "11" },
            { desc: "Décembre", value: "12" },
          ],
        }),
        // Famille (exacte)
        new ModelListTreeFilter({
          field: "exact_family",
          name: "exact_family",
          label: "Famille (exacte) - sélection",
          help: "---------------------",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          display: (v) => `${v.name}`,
          collection: this._families,
        }),
        // Famille et sous-familles
        new ModelListTreeFilter({
          field: "parent_family",
          name: "parent_family",
          label: "Famille et sous-famille - sélection",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          help: "---------------------",
          display: (v) => `${v.name}`,
          collection: this._families,
        }),
        // Recherche par famille(s) exacte(s)
        new ModelListAutocompleteMultiFilter({
          field: "exact_families",
          name: "exact_families",
          label: "Recherche par famille(s) exacte(s)",

          collection: this._families,
        }),
        // Recherche par famille(s) contenant
        new ModelListAutocompleteMultiFilter({
          field: "parent_families",
          name: "parent_families",
          label: "Recherche par famille(s) et sous-familles",
          collection: this._families,
        }),
      ],
    });

    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "statuses",
          "producer_near",
          "storages",
          "plabels",
        ],
      filters: [
        producer_filter,
        storage_filter,
        geoproducer_filter,
        products_filter,
      ],
    };
  }

  /**
   * Get fields, adding custom fields and cdata fields.
   *
   * - location_city
   * - location_postcode
   * - search_producer_near_distances
   * - search_storage_near_distances
   * - select
   * - cdata keys
   */
  public override getFields(): FieldsParams {
    const fields = super.getFields();
    fields.custom!.push({
      name: "location_city",
      label: "Ville producteur",
      priority: 500,
    });
    fields.custom!.push({
      name: "location_postcode",
      label: "CP producteur",
      priority: 499,
    });
    fields.custom!.push({
      name: "search_producer_near_distances",
      label: "Proximité producteur",
      priority: 140,
    });
    fields.custom!.push({
      name: "search_storage_near_distances",
      label: "Proximité dépôt",
      priority: 140,
    });
    if (this.select) {
      fields.custom?.push({
        name: "select",
        label: "Sélection",
        priority: 2000,
      });
      if (!fields.defaults) {
        fields.defaults = [];
      }
      fields.defaults.unshift("select");
    }
    let i = 0;
    for (const k of this.cdatakeys) {
      fields.custom!.push({
        name: `cdata_${k.id}`,
        label: k.name,
        priority: 500 + i,
      });
      i++;
    }
    return fields;
  }

  public override preNgOnInit() {
    super.preNgOnInit();
    if (!this.filter) {
      this.filter = of({
        local: this.local,
        types: this.type?.join(","),
        storage: this.storage?.id,
      });
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    const producerDistance = this.list.filters.enabled.pipe(
      mergeMap((v) => v),
      filter((v) => v.name === "producer_near"),
      filter((v) => !!(v.get() && v.get()!.value)),
    );
    this.producerDistanceRef$ = producerDistance.pipe(
      delay(1000),
      map((v) => {
        const val = v.get()!.value;
        const label = v.get()!.desc;
        return {
          label,
          pos: [+val.split(",")[2], +val.split(",")[3]],
        };
      }),
    );
    const storageDistance = this.list.filters.enabled.pipe(
      mergeMap((v) => v),
      filter((v) => v.name === "storage_near"),
      filter((v) => !!(v.get() && v.get()!.value)),
    );
    this.storageDistanceRef$ = storageDistance.pipe(
      delay(1000),
      map((v) => {
        const val = v.get()!.value;
        const label = v.get()!.desc;
        return {
          label,
          pos: [+val.split(",")[2], +val.split(",")[3]],
        };
      }),
    );
    this.dispPrint = true;
    if (this.select) {
      this.selection = this._select.getSelection("producers", this.list);
    }
  }
}
