
<ng-container *ngIf="!rat"><span [title]="title">?</span></ng-container>
<ng-container *ngIf="rat">
  <ng-container *ngIf="display==='percent'">
    <ng-container *ngIf="percent!==null"><span [class.text-danger]="percent&gt;0" [class.text-success]="percent&lt;0" [title]="title">{{percent|number:'0.0-2'}} %</span></ng-container>
    <ng-container *ngIf="percent===null"><span [title]="title">---</span></ng-container>
  </ng-container>
  <ng-container *ngIf="display==='price'">
    <ng-container *ngIf="percent!==null &amp;&amp; rprice"><span [class.text-danger]="percent&gt;0" [class.text-success]="percent&lt;0" [title]="title">{{rprice/10000|currency:'EUR'}}</span></ng-container>
    <ng-container *ngIf="percent===null &amp;&amp; rprice"><span [title]="title">{{rprice/10000|currency:'EUR'}}</span></ng-container>
    <ng-container *ngIf="percent===null &amp;&amp; rprice===null"><span [title]="title">{{rat.status}}</span></ng-container>
  </ng-container>
  <ng-container *ngIf="display==='both'">
    <ng-container *ngIf="percent!==null &amp;&amp; rprice"><span [class.text-danger]="percent&gt;0" [class.text-success]="percent&lt;0" [title]="title">{{rprice/10000|currency:'EUR'}}<small class="ms-1">{{percent|number:'0.0-2'}} %</small></span></ng-container>
    <ng-container *ngIf="percent===null &amp;&amp; rprice"><span [title]="title">{{rprice/10000|currency:'EUR'}}<small class="ms-1">-- %</small></span></ng-container>
    <ng-container *ngIf="percent===null &amp;&amp; rprice===null"><span [title]="title">{{rat.status}}</span></ng-container>
  </ng-container>
</ng-container>