
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-flags-display [item]="row" [humanize]="TARIFTYPE_FLAGS_HUMANIZED" [exclude]="['legacy:import', 'rrule:auto']"></lvadg-flags-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">{{row.name}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="code" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="name_template">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name_template" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.name_template}}</td>
        </ng-container>
        <ng-container cdkColumnDef="message">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="message" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.message"> {{row.message|stripTags|shorten:150:'...'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="contract_message">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="contract_message" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.contract_message">{{row.contract_message|stripTags|shorten:150:'...'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="internal_notes">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="internal_notes" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.internal_notes"> {{row.internal_notes|shorten:150:'...'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="type" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="segment">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="segment"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'segment'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="lifecycle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="lifecycle" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'lifecycle'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="zone">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="zone" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'zone'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="client">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.client}}</td>
        </ng-container>
        <ng-container cdkColumnDef="member">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="member" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.member}}</td>
        </ng-container>
        <ng-container cdkColumnDef="member_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="member_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-member-display [member]="row.member_details" mode="line" [detail_route]="member_detail_route"></lvadg-member-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="storage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storage" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.storage}}</td>
        </ng-container>
        <ng-container cdkColumnDef="storage_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storage_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-storage-display [storage]="row.storage_details" mode="line" [detail_route]="storage_detail_route"></lvadg-storage-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="storages_mode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storages_mode" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'storages_mode'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="region">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="region" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.region}}</td>
        </ng-container>
        <ng-container cdkColumnDef="regions_mode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="regions_mode" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'regions_mode'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="base_interval">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="base_interval" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'base_interval'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="rrule">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="rrule" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.rrule}}</td>
        </ng-container>
        <ng-container cdkColumnDef="delta_prepared">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="delta_prepared" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.delta_prepared}}</td>
        </ng-container>
        <ng-container cdkColumnDef="delta_validated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="delta_validated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.delta_validated}}</td>
        </ng-container>
        <ng-container cdkColumnDef="delta_published">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="delta_published" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.delta_published}}</td>
        </ng-container>
        <ng-container cdkColumnDef="asscom">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="asscom" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.asscom}}</td>
        </ng-container>
        <ng-container cdkColumnDef="respcom">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="respcom" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.respcom}}</td>
        </ng-container>
        <ng-container cdkColumnDef="decondi_status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="decondi_status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'decondi_status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="decondi_pce">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="decondi_pce" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.decondi_pce">{{row.decondi_pce/10000|currency:"EUR"}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="decondi_kg">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="decondi_kg" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.decondi_kg">{{row.decondi_kg/10000|currency:"EUR"}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="parameters">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="parameters" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.parameters}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_name" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="pilotes">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pilotes" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.pilotes}}</td>
        </ng-container>
        <ng-container cdkColumnDef="starred">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="starred" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.starred}}</td>
        </ng-container>
        <ng-container cdkColumnDef="notified">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="notified" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.notified}}</td>
        </ng-container>
        <ng-container cdkColumnDef="client_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.client_details">
              <lvadg-client-display [client]="row.client_details" mode="line" [detail_route]="client_detail_route" [xlsx]="xlsxExporting"></lvadg-client-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="client_parent_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client_parent_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.client_parent_details"><a class="text-decoration-none" [routerLink]="client_detail_route?.route({client: row.client_parent_details})">{{row.client_parent_details?.name}}</a></ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="asscom_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="asscom_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-user-display [user]="row.asscom_details" mode="line" [detail_route]="user_detail_route"></lvadg-user-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="respcom_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="respcom_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-user-display [user]="row.respcom_details" mode="line" [detail_route]="user_detail_route"></lvadg-user-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="pilotes_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pilotes_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div *ngFor="let p of row.pilotes_details">
              <lvadg-user-display [user]="p" mode="line" [detail_route]="user_detail_route"></lvadg-user-display>
            </div>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="user_level">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="user_level"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><span class="me-2 bg-primary badge" *ngFor="let l of row.user_level">{{l}}</span></td>
        </ng-container>
        <ng-container cdkColumnDef="user_starred">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="user_starred"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><i class="bi bi-star-fill" *ngIf="row.user_starred"></i></td>
        </ng-container>
        <ng-container cdkColumnDef="user_notified">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="user_notified"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><i class="bi bi-bell-fill" *ngIf="row.user_notified"></i></td>
        </ng-container>
        <ng-container cdkColumnDef="export_template">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="export_template"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.export_template}}</td>
        </ng-container>
        <ng-container cdkColumnDef="client_data_link">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client_data_link"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.client_data_link?'oui':'non'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="price_type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="price_type"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.price_type|pchoice:row:'price_type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ref_price_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ref_price_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ref_price_op}}</td>
        </ng-container>
        <ng-container cdkColumnDef="brut_to_net_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="brut_to_net_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.brut_to_net_op}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="brut_to_net2_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="brut_to_net2_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.brut_to_net2_op}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="custom_column_1_title">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="custom_column_1_title"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.custom_column_1_title}}</td>
        </ng-container>
        <ng-container cdkColumnDef="custom_column_1_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="custom_column_1_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.custom_column_1_op}}</td>
        </ng-container>
        <ng-container cdkColumnDef="price_precision">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="price_precision"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.price_precision}}</td>
        </ng-container>
        <ng-container cdkColumnDef="export_inactives">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="export_inactives"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.export_inactives|pchoice:row:'export_inactives'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="region_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="region_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-genericregion-display [genericregion]="row.region_details" mode="line" [detail_route]="genericregion_detail_route"></lvadg-genericregion-display>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>