
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OfferNotification } from "./offer-notification";

@Injectable({
  providedIn: 'root'
})
export class OfferNotificationService extends Collection<OfferNotification> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offernotifications", OfferNotification);
  }
}
