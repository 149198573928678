
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Orderprocessstorage } from "./orderprocessstorage";

@Injectable({
  providedIn: 'root'
})
export class OrderprocessstorageService extends Collection<Orderprocessstorage> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/orderprocessstorages", Orderprocessstorage);
  }
}
