import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Category } from '../../../../../models/catalog/meta/category/category';

export interface CategoryDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface CategoryDetailViewData extends CategoryDetailViewParams {
  category: Category;
}
