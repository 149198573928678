
<ng-container *ngIf="data$|async; let data">
  <lvadg-offertype-nav [offertype]="data.offertype" [routes]="data.ot_menu"></lvadg-offertype-nav>
  <lvadg-header-action [title]="data.offertype._display" level="h1" subtitle="Résumé du modèle d'offre" ri="offer"></lvadg-header-action>
  <div class="row g-2" *ngIf="data.offertype; let ot">
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Caractéristiques</div>
        <div class="card-body">
          <dl class="row g-1">
            <div class="col-6">
              <dt>Nom</dt>
              <dd>{{ot.name||'-'}}</dd>
            </div>
            <div class="col-6">
              <dt>Code</dt>
              <dd><code>{{ot.code}}</code></dd>
            </div>
            <div class="col-6">
              <dt>Mode</dt>
              <dd>{{ot.mode|pchoice:ot:"mode"}}</dd>
            </div>
            <div class="col-6">
              <dt>Type</dt>
              <dd>{{ot.type|pchoice:ot:"type"}}</dd>
            </div>
            <div class="col-6">
              <dt>Zone</dt>
              <dd>{{ot.zone|pchoice:ot:"zone"}}</dd>
            </div>
            <div class="col-6">
              <dt>État</dt>
              <dd>{{ot.status|pchoice:ot:"status"}}</dd>
            </div>
            <div class="col-12">
              <dt>Modèle de nom</dt>
              <dd><span class="font-monospace">{{ot.name_template||'-'}}</span></dd>
            </div>
            <div class="col-12">
              <dt>Notes internes</dt>
              <dd>
                <pre>{{ot.internal_notes||'-'}}</pre>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Client cible et modèles de tarifs</div>
        <div class="card-body">
          <dl class="row g-1">
            <div class="col-6">
              <dt>Client</dt>
              <dd>
                <lvadg-client-display [client]="ot.client_details"></lvadg-client-display>
              </dd>
            </div>
            <div class="col-12">
              <dt>Modèles de tarifs</dt>
              <dd *ngIf="ot.tariftypes_details?.length">
                <ul class="list-unstyled">
                  <li *ngFor="let tt of ot.tariftypes_details">
                    <lvadg-tariftype-display [tariftype]="tt" mode="line" [detail_route]="tariftype_detail_route"></lvadg-tariftype-display>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Contrôle et périodicité</div>
        <div class="card-body">
          <dl class="row g-1">
            <div class="col-12">
              <dt>Responsables</dt>
              <dd>
                <ul>
                  <li *ngFor="let r of ot.pilotes_details">
                    <lvadg-user-display [user]="r" mode="line" [detail_route]="user_detail_route"></lvadg-user-display>
                  </li>
                </ul>
              </dd>
            </div>
            <div class="col-6">
              <dt>Périodicité de base</dt>
              <dd>{{ot.base_interval|pchoice:ot:"base_interval"}}</dd>
            </div>
            <div class="col-12">
              <dt>Règles de récurrence</dt>
              <dd>
                <lvadg-rrule-display [rule]="ot.rrule"></lvadg-rrule-display>
              </dd>
            </div>
            <div class="col-6">
              <dt>Contrôle de préparation</dt>
              <dd>{{ot.delta_prepared||'-'}} heures</dd>
            </div>
            <div class="col-6">
              <dt>Contrôle de publication</dt>
              <dd>{{ot.delta_published||'-'}} heures</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-2 g-2">
    <div class="card">
      <div class="card-header">Statistiques et contrôle</div>
      <div class="card-body">
        <pre class="todo">TODO: A FAIRE / A VOIR CE QUI EST NÉCESSAIRE</pre>
      </div>
    </div>
  </div>
</ng-container>