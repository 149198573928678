
<ng-container *ngIf="region">
  <h1>Opérations région</h1>
  <h2>{{region.name}}</h2>
  <hr>
  <button class="btn btn-primary my-2 w-100" (click)="checkArticles=true" *ngIf="!checkArticles &amp;&amp; !importRegionPrices &amp;&amp; !validatingRegion">Vérifier les articles d'un tarif XLSX</button>
  <lvadg-tarif-import-custom-articles [tarif]="tarif" mode="region" [region]="region" *ngIf="checkArticles" (cancelled)="checkArticles = false" (done)="reload?.next(true); checkArticles = false"></lvadg-tarif-import-custom-articles>
  <button class="btn btn-primary my-2 w-100" (click)="importRegionPrices=true" *ngIf="!checkArticles &amp;&amp; !importRegionPrices &amp;&amp; !validatingRegion">Charger les prix d'un tarif XLSX</button>
  <lvadg-tarif-import-custom-prices [tarif]="tarif" mode="region" [region]="region" *ngIf="importRegionPrices" (cancelled)="importRegionPrices = false" (done)="reload?.next(true); importRegionPrices = false"></lvadg-tarif-import-custom-prices>
  <button class="btn btn-primary my-2 w-100" (click)="validateRegion()" *ngIf="!checkArticles &amp;&amp; !importRegionPrices &amp;&amp; !validatingRegion">Valider l'ensemble des prix/disponibilités</button>
  <button class="btn btn-primary my-2 w-100" *ngIf="validatingRegion" [disabled]="true"><span class="spinner-grow spinner-grow-sm me-2"></span>Validation en cours, merci de patienter...</button>
</ng-container>