import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeRelationBase } from '../../tariftype-relation/tariftype-relation.base';
import { TARIF_STATUS, TarifBase } from '../../tarif/tarif.base';
import { TarifRelation } from '../tarif-relation';
import { TarifRelationService } from '../tarif-relation.service';
import { TarifService } from '../../tarif/tarif.service';
import { DispeditComponent, PChoicePipe } from '@solidev/data';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { Tarif } from '../../tarif/tarif';
import { firstValueFrom, Observable } from 'rxjs';
import { TarifActionService } from '../../tarif/tarif-action.service';

@Component({
  selector: 'lvadg-tarif-relation-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, PChoicePipe],
  templateUrl: './tarif-relation-create.component.pug',
  styleUrls: ['./tarif-relation-create.component.sass'],
})
export class TarifRelationCreateComponent implements OnInit {
  @Input() public mode: 'inline' = 'inline';
  @Input() public relation?: TariftypeRelationBase;
  @Input() public src?: TarifBase;
  @Input() public dest?: TarifBase;
  @Output() public created = new EventEmitter<void>();
  public model!: TarifRelation;
  @ViewChild('ofcslot', { static: true })
  public ofcSlot?: ElementRef<HTMLElement>;
  public tarifs?: Observable<Tarif[]>;
  public TarifStatus = TARIF_STATUS;
  public preparation: boolean = false;
  private ofcInstance?: NgbOffcanvasRef;

  constructor(
    private _rel: TarifRelationService,
    public tarifs$: TarifService,
    private _tas: TarifActionService,
    private ofc$: NgbOffcanvas,
  ) {}

  ngOnInit(): void {
    this.model = new TarifRelation(this._rel);
    if (this.relation) {
      this.model.relation = this.relation.id;
      this.model.relation_details = this.relation;

      if (this.src) {
        this.model.src = this.src.id;
        this.model.src_details = this.src;
      }
      if (this.dest) {
        this.model.dest = this.dest.id;
        this.model.dest_details = this.dest;
        this.tarifs = this.tarifs$.list({
          fields: ['id', 'name', 'status'].join(','),
          src_relation_candidate: `${this.relation.id}T${this.dest.id}T${this.preparation ? 'PRP' : 'VAL'}`,
        });
      }
    }
  }

  public open() {
    this.ofcInstance = this.ofc$.open(this.ofcSlot, { position: 'end' });
  }

  public togglePreparation() {
    if (this.relation) {
      if (this.dest) {
        this.preparation = !this.preparation;
        this.tarifs = this.tarifs$.list({
          fields: ['id', 'name', 'status'].join(','),
          src_relation_candidate: `${this.relation.id}T${this.dest.id}T${this.preparation ? 'PRP' : 'VAL'}`,
        });
      }
    }
  }

  public async selectTarif(t: Tarif) {
    if (this.src) {
      this.model.dest = t.id;
      this.model.dest_details = t;
      const res = await firstValueFrom(this.model.save({ updateModel: true }));
      this.ofcInstance?.close();
      this.created.emit();
    } else if (this.dest) {
      this.model.src = t.id;
      this.model.src_details = t;
      const res = await this._tas.action(this.dest, {
        action: 'relation_add',
        ttrelation: this.relation!.id,
        src: t.id,
      });
      this.ofcInstance?.close();
      this.created.emit();
    }
  }
}
