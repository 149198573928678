import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Geocountry } from '../geocountry';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-geocountry-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './geocountry-manage.component.pug',
  styleUrls: ['./geocountry-manage.component.sass'],
})
export class GeocountryManageComponent {
  @Input() public geocountry!: Geocountry;

  constructor() {}
}
