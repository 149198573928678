import { RoutesConfig } from '@solidev/data';
import { Client, RS } from '@vivalya/lvadg';

export const FRONT_CLIENTS_ROUTES_NAMES = [
  'clients',
  'clients_clients',
  'clients_clients_client',
  'clients_clients_client_offers',
  'clients_clients_client_offers_local',
  'clients_clients_client_offers_global',
  'clients_clients_client_offers_sea',
  'clients_clients_client_offers_catalog',
  'clients_clients_client_stats',
  'clients_clients_client_tarifs',
  'clients_clients_client_restos',
  'clients_clients_client_restos_resto',
  'clients_clients_client_restos_resto_offers',
  'clients_clients_client_restos_resto_offers_local',
  'clients_clients_client_restos_resto_offers_global',
  'clients_clients_client_restos_resto_offers_sea',
  'clients_clients_client_restos_resto_offers_catalog',
] as const;

export const FRONT_CLIENTS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_CLIENTS_ROUTES_NAMES)[number]> = {
  clients: { name: 'clients', params: [], route: () => [RS.clients] },
  clients_clients: { name: 'clients_clients', params: [], route: () => ['/', RS.clients, RS.clients] },
  clients_clients_client: {
    params: ['client'],
    route: (client: Client) => ['/', RS.clients, RS.clients, client.id],
    name: 'clients_clients_client',
  },
  clients_clients_client_offers: {
    name: 'clients_clients_client_offers',
    params: [],
    route: () => [],
  },
  clients_clients_client_offers_local: {
    name: 'clients_clients_client_offers_local',
    params: [],
    route: () => [],
  },
  clients_clients_client_offers_global: {
    name: 'clients_clients_client_offers_global',
    params: [],
    route: () => [],
  },
  clients_clients_client_offers_sea: {
    name: 'clients_clients_client_offers_sea',
    params: [],
    route: () => [],
  },
  clients_clients_client_offers_catalog: {
    name: 'clients_clients_client_offers_catalog',
    params: [],
    route: () => [],
  },
  clients_clients_client_stats: {
    name: 'clients_clients_client_stats',
    params: [],
    route: () => [],
  },
  clients_clients_client_tarifs: {
    name: 'clients_clients_client_tarifs',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos: {
    name: 'clients_clients_client_restos',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos_resto: {
    name: 'clients_clients_client_restos_resto',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos_resto_offers: {
    name: 'clients_clients_client_restos_resto_offers',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos_resto_offers_local: {
    name: 'clients_clients_client_restos_resto_offers_local',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos_resto_offers_global: {
    name: 'clients_clients_client_restos_resto_offers_global',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos_resto_offers_sea: {
    name: 'clients_clients_client_restos_resto_offers_sea',
    params: [],
    route: () => [],
  },
  clients_clients_client_restos_resto_offers_catalog: {
    name: 'clients_clients_client_restos_resto_offers_catalog',
    params: [],
    route: () => [],
  },
};
