
<form [formGroup]="form" (submit)="create()">
  <div class="row g-2">
    <div class="col-sm-6">
      <label>Email</label>
      <input class="form-control" type="email" formControlName="email">
      <div class="form-text">Adresse email</div>
      <div class="invalid-feedback" *ngIf="form.controls['email'].invalid">Adresse email valide obligatoire</div>
    </div>
    <div class="col-sm-6">
      <label>Mot de passe<span class="ms-2" (click)="genPassword()" role="button"><i class="bi bi-key me-2" role="img" aria-hidden="true"></i>Gen pw</span></label>
      <input class="form-control" type="password" formControlName="orig_password">
      <div class="form-text">Mot de passe, 8 caractères minimum</div>
      <div class="invalid-feedback" *ngIf="form.controls['orig_password'].invalid">Mot de passe invalide</div>
    </div>
    <div class="col-sm-6">
      <label>Prénom</label>
      <input class="form-control" type="text" formControlName="first_name">
      <div class="invalid-feedback" *ngIf="form.controls['first_name'].invalid">2 caractères minimum</div>
    </div>
    <div class="col-sm-6">
      <label>Nom de famille</label>
      <input class="form-control" type="text" formControlName="last_name">
      <div class="invalid-feedback" *ngIf="form.controls['first_name'].invalid">2 caractères minimum</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-8">
      <button class="btn btn-primary btn-block w-100" type="submit" [disabled]="!form.valid"><i class="fa fa-save me-2"></i>Enregistrer</button>
    </div>
    <div class="col-4">
      <button class="btn btn-secondary btn-block w-100" (click)="cancelled.emit()"><i class="fa fa-times me-2"></i>Cancel</button>
    </div>
  </div>
</form>