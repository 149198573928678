import {Component, ElementRef, inject, Input, ViewChild, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserMap} from "../user-map";
import {UserMapService} from "../user-map.service";
import maplibregl from "maplibre-gl";
import {MAPPING_BASE_STYLES} from "../../mapping/constants";
import {firstValueFrom} from "rxjs";
import {DATA_API_URL} from "@solidev/data";

/**
 * Displays a user map in a maplibre-gl map.
 *
 * Map can be updated, adding layers and changing the style.
 */
@Component({
  selector: "lvadg-user-map-showmap",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./user-map-showmap.component.pug",
  styleUrls: ["./user-map-showmap.component.sass"],
})
export class UserMapShowmapComponent implements OnInit {
  /** User map instance */
  @Input() public map!: UserMap;
  /** Map container reference */
  @ViewChild("map", { static: true })
  public mapContainer!: ElementRef<HTMLDivElement>;
  /** Map center position */
  @Input()
  public position: [number, number] = [2.6390772508210603, 46.441826066304316];
  /** Maplibre instance */
  public glmap!: maplibregl.Map;
  private _url = inject(DATA_API_URL);

  constructor(private _maps: UserMapService) {}

  public async ngOnInit(): Promise<void> {
    if (!this.map.id) {
      await firstValueFrom(this._maps.save(this.map, { updateModel: true }));
    }
    this.glmap = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: `${this._url}/v3/usermaps/${this.map.id}/style?map_token=${
        this.map.code
      }&refresh=${Date.now()}`,
      center: this.position,
      zoom: 5.24,
      minZoom: 3, // was 4.4
      attributionControl: false,
    });
  }

  public async update() {
    if (this.map.style === MAPPING_BASE_STYLES.OSM_LIBERTY_EU) {
      this.map.style = MAPPING_BASE_STYLES.TONER_EU;
    } else {
      this.map.style = MAPPING_BASE_STYLES.OSM_LIBERTY_EU;
    }
    await firstValueFrom(this._maps.update(this.map));
    this.glmap.setStyle(
      `${this._url}/v3/usermaps/${this.map.id}/style?map_token=${
        this.map.code
      }&refresh=${Date.now()}`,
    );
  }
}
