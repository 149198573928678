import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Cdata } from './cdata';

@Injectable({
  providedIn: 'root',
})
export class CdataService extends Collection<Cdata> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/cdatas', Cdata);
  }
}
