import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Map, SymbolLayerSpecification } from 'maplibre-gl';
import { MeteoFull } from '../meteo-full';
import { MeteoService } from '../meteo.service';
import { first } from 'rxjs/operators';
import { combineLatest, fromEvent } from 'rxjs';
import { CommonModule, DatePipe } from '@angular/common';

const MAP_PARAMS: Record<string, { center: [number, number]; zoom: number }> = {
  france: {
    center: [2.6390772508210603, 46.441826066304316],
    zoom: 2.8,
  },
  europe: {
    center: [2.6390772508210603, 46.441826066304316],
    zoom: 1.1,
  },
};

@Component({
  standalone: true,
  selector: 'lvadg-meteo-map',
  templateUrl: './meteo-map.component.pug',
  imports: [DatePipe, CommonModule],
  styleUrls: ['./meteo-map.component.sass'],
})
export class MeteoMapComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) public mapEl!: ElementRef;
  @Input() public geo: 'france' | 'europe' = 'france';
  @Input() public day = 0;
  public meteo!: MeteoFull;
  private map!: Map;

  constructor(
    private _zone: NgZone,
    public mt: MeteoService,
  ) {}

  async ngOnInit(): Promise<void> {
    this._zone.runOutsideAngular(() => {
      this.map = new Map({
        container: this.mapEl.nativeElement,
        style: 'https://maptiles.gis.lavieadugout.fr/styles/toner-vivalya-light/style.json',
        center: MAP_PARAMS[this.geo].center,
        zoom: MAP_PARAMS[this.geo].zoom,
        antialias: true,
        interactive: false,
        minZoom: 1, // was 4.4
        attributionControl: false,
        preserveDrawingBuffer: false,
      });
      combineLatest([fromEvent(this.map, 'load'), this.mt.full])
        .pipe(first())
        .subscribe(([, meteo]) => {
          this.meteo = meteo;
          this.map.addSource('meteo', { type: 'geojson', data: this.meteo as any });
          this.map.addLayer({
            id: 'meteoMarkers',
            source: 'meteo',
            type: 'symbol',
            layout: {
              'icon-image': ['get', 'daily_weather_icon', ['at', this.day, ['get', 'daily_forecast']]],
              'icon-ignore-placement': true,
              'icon-allow-overlap': false,
              'icon-size': 1,
              'icon-anchor': 'center',
            } as SymbolLayerSpecification['layout'],
          });
        });
    });
  }

  public ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
}
