import { NewsCategory } from '../newscategory';
import { Component, Input } from '@angular/core';
import { DispeditComponent, RicheditComponent } from '@solidev/data';
import { CommonModule } from '@angular/common';
import { NewscategoryService } from '../newscategory.service';

@Component({
  standalone: true,
  selector: 'lvadg-newscategorie-manage',
  templateUrl: './newscategory-manage.component.pug',
  styleUrls: ['./newscategory-manage.component.sass'],
  imports: [CommonModule, DispeditComponent, RicheditComponent],
})
export class NewscategoryManageComponent {
  @Input() public newscategory!: NewsCategory;
  public edit = {};

  constructor(public category$: NewscategoryService) {}
}
