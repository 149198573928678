
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Storage } from "./storage";

@Injectable({
  providedIn: 'root'
})
export class StorageService extends Collection<Storage> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/storages", Storage);
  }
}
