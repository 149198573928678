import { ArticleTarif } from '../article-tarif';
import { StorageBase } from '../../../structures/storage/storage.base';
import { RnmmarcheBase } from '../../../rnm/rnmmarche/rnmmarche.base';
import { ReplaySubject } from 'rxjs';
import { GenericregionBase } from '../../../locations/genericregion/genericregion.base';

export interface IEditorData {
  type: 'price' | 'decondi' | 'storage' | 'region' | 'rnm';
  mode: 'price' | 'dispo' | 'dispoprice';
  index: number;
  at: ArticleTarif;
  storage?: StorageBase;
  region?: GenericregionBase;
  rnm?: RnmmarcheBase;
}

export interface EditorAction {
  type: 'price' | 'decondi' | 'storage' | 'region' | 'rnm';
  mode: 'price' | 'dispo' | 'dispoprice';
  at: number;
  storage: number | undefined;
  region: number | undefined;
}

export class Editor {
  constructor(private _data: IEditorData) {}

  public get id(): number {
    const d = this._data;
    return d.index;
  }

  public get type(): string {
    const d = this._data;
    return `${d.type}/${d.storage?.id || d.rnm?.id || d.region?.id || '-'}`;
  }

  public get action(): EditorAction {
    return {
      type: this._data.type,
      mode: this._data.mode,
      at: this._data.at.id,
      storage: this._data.storage?.id,
      region: this._data.region?.id,
    };
  }
}

export class EditorCollection {
  private _editors: Record<string, Map<number, Editor>> = {};
  private _focus$ = new ReplaySubject<Editor>(1);
  public focus$ = this._focus$.asObservable();

  public register(editor: Editor) {
    const edts = this.getMap(editor);
    edts.set(editor.id, editor);
  }

  public unregister(editor: Editor) {
    const edts = this.getMap(editor);
    if (edts.has(editor.id)) {
      edts.delete(editor.id);
    }
  }

  public next(editor: Editor, pos: number = 1): Editor | null {
    const edts = this.getMap(editor);
    const available = Array.from(edts.keys()).sort((a, b) => a - b);
    const next = available[available.indexOf(editor.id) + pos];
    if (next !== undefined && edts.has(next)) {
      this._focus$.next(edts.get(next)!);
      return edts.get(next) || null;
    }
    return null;
  }

  public clear() {
    this._editors = {};
  }

  private getMap(editor: Editor): Map<number, Editor> {
    if (!this._editors[editor.type]) {
      this._editors[editor.type] = new Map();
    }
    return this._editors[editor.type];
  }
}
