import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  DataMessageService,
  DataModel,
  FilterData,
  FilterDefaults,
  ModelList,
} from "@solidev/data";
import { Selection } from "../selection.service";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import {
  intoProviderData,
  PrintContext,
  PrintSettings,
  PrintSettingsItem,
} from "../../../models/layouts/print-settings";
import { PrintService } from "../../../models/layouts/public_api";
import { ProviderData } from "../../../models/layouts/rstypes/ProviderData";
import { firstValueFrom, Observable } from "rxjs";
import { IconComponent } from "../../utils/icon/icon.component";
import { UserPrintContextService } from "../../../models/layouts/user-print-context.service";

// FIXME: use filter and filter_filter to reset selection if a filtered_filter changes

@Component({
  selector: "lvadg-select-header",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./select-header.component.pug",
  styleUrls: ["./select-header.component.sass"],
})
export class SelectHeaderComponent<T extends DataModel> {
  @Input({ required: true }) public selection?: Selection<T>;
  @Input({ required: true }) public list?: ModelList<T>;
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults>;
  /** Filter whitelist, if provided, only the keys in this array will be kept in the filter */
  @Input() public filter_filter: string[] | null = null;
  @Input() public print_settings?: PrintSettings;
  @Input() public print_context: PrintContext = {};
  @ViewChild("ofcslot", { static: true }) ofcTemplate!: ElementRef<HTMLElement>;
  public instance?: NgbOffcanvasRef;

  constructor(
    private _ofc: NgbOffcanvas,
    private _prt: PrintService,
    private _msgs: DataMessageService,
    private _upc: UserPrintContextService,
  ) {}

  public clickIcon() {
    this.instance = this._ofc.open(this.ofcTemplate, { position: "end" });
  }

  public selectAll() {
    this.selection?.selectAll();
  }

  public deselectAll() {
    this.selection?.emptyAll();
  }

  invertSelection() {
    this.selection?.invertSelection();
  }

  public async print(
    ps: PrintSettingsItem,
    mode: "button" | "quick_print" | "add_to_print",
  ) {
    if (mode === "button") {
      return;
    }
    if (this.selection) {
      if (this.selection.size() > ps.limit) {
        this._msgs.error(
          "Trop d'éléments pour cette impression",
          `Le maximum autorisé pour ce type d'impression est de ${ps.limit} éléments.`,
        );
        this.instance?.close();
        return;
      }
      const filter: FilterData = {};
      if (this.list) {
        Object.assign(filter, await firstValueFrom(this.list.filters.output));
        const sorter = await firstValueFrom(this.list.sorter.output);
        if (sorter.length > 0) {
          filter["ordering"] = sorter.join(",");
        }
      }
      if (this.filter) {
        if (this.filter instanceof Observable) {
          const f = await firstValueFrom(this.filter);
          Object.assign(filter, f);
        } else {
          Object.assign(filter, this.filter);
        }
      }
      // Remove keys that are not in the filter_filter array (if provided)
      if (this.filter_filter !== null) {
        const filters = this.filter_filter;
        // Only keep the filter keys that are in the filter_filter array
        Object.keys(filter).forEach((key) => {
          if (!filters.includes(key)) {
            delete filter[key];
          }
        });
      }
      // Cleanup filter
      for (const k in filter) {
        if (filter[k] === undefined || filter[k] === null) {
          delete filter[k];
        } else {
          filter[k] = `${filter[k]}`;
        }
      }
      if (this.print_context.local) {
        Object.assign(filter, { local: this.print_context.local });
      }
      const data: ProviderData = {
        type: ps.provider,
        ids: Array.from(this.selection.ids()),
        queryset: { page: "1", page_size: ps.limit.toString(), ...filter },
        description: this.selection.name,
      } as ProviderData;
      this._upc.update_context(this.print_context);
      this._prt.print(ps, data, intoProviderData(this.print_context), mode);
      this.instance?.close();
    }
  }
}
