
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { RnmlibelleService} from "./rnmlibelle.service";
import { Rnmlibelle } from "./rnmlibelle";

@Injectable({
  providedIn: 'root'
})
export class RnmlibelleResolver  {
  constructor(private _rs: RnmlibelleService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Rnmlibelle> {
    return this._rs.fetch(+route.params["rnmlibelleId"])
  }
}
