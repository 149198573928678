import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../tarif';
import { Upload } from '../../../../documents/upload/upload';
import { DataMessageService, UploadFile } from '@solidev/data';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
} from '../../../../documents/upload/media-upload/media-upload.component';
import { TarifService } from '../../tarif.service';
import { TarifActionService } from '../../tarif-action.service';
import { OpLog } from '../../../../../components/utils/oplog/oplog';
import { OplogDisplayComponent } from '../../../../../components/utils/oplog/oplog-display/oplog-display.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TariftypeMetadata } from '../../../tariftype-metadata/tariftype-metadata';
import { TarifDocument } from '../../../tarif-document/tarif-document';
import { Observable } from 'rxjs';
import { TariftypeMetadataService } from '../../../tariftype-metadata/tariftype-metadata.service';
import { TarifDocumentService } from '../../../tarif-document/tarif-document.service';
import { TARIFDOCUMENT_TYPES } from '../../../tarif-document/tarif-document.base';
import { TARIFTYPEMETADATA_TYPE } from '../../../tariftype-metadata/tariftype-metadata.base';

interface ImportCustomArticleResult {
  prices: {
    ccode: string;
    code: string;
    libelle: string;
    clibelle: string;
    old_price: number | null;
    new_price: number | null;
    dispos: number;
    availables: number;
    changed: boolean;
  }[];
  url: string;
}

interface ActionUploadData {
  data: {
    tarifdocument_id?: number;
    upload_id?: number;
  };
  message: string;
}

interface ActionResponseData {
  data: {
    result: ImportCustomArticleResult;
    upload: number;
  };
  log: OpLog;
  message: string;
  success: boolean;
}

@Component({
  selector: 'lvadg-tarif-export-custom-prices',
  standalone: true,
  imports: [
    CommonModule,
    MediaUploadComponent,
    ReactiveFormsModule,
    OplogDisplayComponent,
  ],
  templateUrl: './tarif-export-custom-prices.component.pug',
  styleUrls: ['./tarif-export-custom-prices.component.sass'],
})
export class TarifExportCustomPricesComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Output() public cancelled = new EventEmitter<void>();
  @Output() public done = new EventEmitter<void>();
  public form = new FormGroup({
    metadata: new FormControl<TariftypeMetadata | null>(null, [
      Validators.required,
    ]),
    nokeep: new FormControl<boolean>(false),
    document: new FormControl<TarifDocument | null>(null),
  });
  public doc!: Upload;
  public uploadPrepare: (
    model: Upload,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<Upload>> = this._uploadPrepare.bind(this);
  public uploadUrl!: string;
  public step = 0;
  public result?: ActionResponseData;
  public docs$!: Observable<TarifDocument[]>;
  public metas$!: Observable<TariftypeMetadata[]>;

  public upload_id?: number;
  public tarifdocument_id?: number;
  public processing: boolean = false;

  constructor(
    private _tarifs: TarifService,
    private _ttmeta: TariftypeMetadataService,
    private _ttdocs: TarifDocumentService,
    private _tact: TarifActionService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.docs$ = this._ttdocs.list({
      tarif: this.tarif.id,
      type: TARIFDOCUMENT_TYPES.IMPORT_TARIF,
    });
    this.metas$ = this._ttmeta.list({
      tariftype: this.tarif.tariftype,
      type: TARIFTYPEMETADATA_TYPE.EAPRICE_WRITE_PARAMS,
    });
    this.doc = new Upload();
    if (this.tarif) {
      this.uploadUrl = this._tarifs.getUrl(this.tarif.id, {
        suffix: '/action',
      });
    }
  }

  public onUploaded($event: { response: ActionUploadData; status: number }) {
    if ($event.response.data) {
      if ($event.response.data.upload_id) {
        this.upload_id = $event.response.data.upload_id;
      } else if ($event.response.data.tarifdocument_id) {
        this.tarifdocument_id = $event.response.data.tarifdocument_id;
      }
      this._msgs.success($event.response.message);
    }
  }

  public async launch(confirm: boolean = false) {
    if (this.form.value.metadata) {
      this.step = confirm ? 2 : 1;
      this.processing = true;
      delete this.result;
      this.result = await this._tact.action<ActionResponseData>(this.tarif, {
        action: 'write_custom_prices',
        document_id: this.tarifdocument_id || this.form.value.document?.id,
        upload_id: this.upload_id,
        metadata_id: this.form.value.metadata.id,
        confirm,
      });
      this.processing = false;
    }
  }

  public xlsxExport(result: ActionResponseData) {
    const res = result.data.result;
    if (!res) {
      return;
    }
    setTimeout(async () => {
      await import('xlsx').then(({ utils, writeFileXLSX }) => {
        const wb = utils.book_new();
        if (res.prices) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet([
              [
                'Code article',
                'Libellé',
                'Code client',
                'Libellé client',
                'Ancien prix',
                'Nouveau prix',
                'Dispo',
                'Depots',
                'Changé ?',
              ],
              ...res.prices.map((v) => [
                v.code,
                v.libelle,
                v.ccode,
                v.clibelle,
                v.old_price,
                v.new_price,
                v.availables,
                v.dispos,
                v.changed,
              ]),
            ]),
            '01-Lignes'
          );
        }
        if (result.log) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(
              result.log.map((v) => [v.line, v.level, v.message, v.data])
            ),
            '99-Log'
          );
        }
        writeFileXLSX(wb, 'résultat_exportation.xlsx');
      });
    });
  }

  private async _uploadPrepare(
    model: Upload,
    file: UploadFile
  ): Promise<IPreparedUploadFile<Upload>> {
    return {
      model,
      url: this.uploadUrl,
      data: {
        action: 'write_custom_prices',
        keep_file: !this.form.value.nokeep,
        tarif: this.tarif.id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
      },
    };
  }
}
