
import { RnmattBase} from "./rnmatt.base";
import {detailsField} from "@solidev/data";
import {RnmBase} from "../rnm/rnm.base";
import {ArticleTarifTemplate} from "../../tarifs/article-tarif-template/article-tarif-template";
import {ArticleTarifTemplateBase} from "../../tarifs/article-tarif-template/article-tarif-template.base";

export class Rnmatt extends RnmattBase {
  static override readonly __name: string = "Rnmatt"

  @detailsField({
    description: "Pivot RNM",
    model: RnmBase,
    readonly: true
  })
  public rnm_details?: RnmBase;

  @detailsField({
    description: "Article tarif",
    model: ArticleTarifTemplateBase,
    readonly: true
  })
  public att_details?: ArticleTarifTemplateBase;


}
