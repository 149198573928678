import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderprocess } from '../orderprocess';
import { DispeditComponent } from '@solidev/data';
import { RruleEditorComponent } from '../../../../components/utils/rrule-editor/rrule-editor.component';
import { firstValueFrom } from 'rxjs';
import { TariftypeService } from '../../../tarifs/tariftype/tariftype.service';

@Component({
  selector: 'lvadg-orderprocess-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, RruleEditorComponent],
  templateUrl: './orderprocess-manage.component.pug',
  styleUrls: ['./orderprocess-manage.component.sass'],
})
export class OrderprocessManageComponent {
  @Input() public orderprocess?: Orderprocess;

  constructor(public tariftypes$: TariftypeService) {}

  public async saveRrule(orderprocess: Orderprocess, rrule: string) {
    orderprocess.delivery_dates_rrule = rrule;
    await firstValueFrom(orderprocess.save({ updateModel: true }));
  }
}
