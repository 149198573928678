import { RData, RoutesDefinition } from "@solidev/data";
import { ARTICLE_LINKS_ROUTES, ARTICLE_ROUTES } from "./menu";
import {
  ArticleDisplayViewComponent,
  ArticleDisplayViewParams,
} from "./display/article-display-view.component";
import { FRONT_ROUTES } from "../../../../../../../front/src/app/routing/main";
import { ADMIN_GROUPS, RS, SPECIAL_GROUPS } from "../../../../constants";
import {
  ArticleEamappingsViewComponent,
  ArticleEamappingsViewParams,
} from "./eamappings/article-eamappings-view.component";
import { IsInGroupGuard } from "../../../../../../../front/src/app/routing/guards/is-in-group.guard";
import {
  ArticleManageViewComponent,
  ArticleManageViewParams,
} from "./manage/article-manage-view.component";
import {
  ArticlePricesViewComponent,
  ArticlePricesViewParams,
} from "./prices/article-prices-view.component";
import {
  ArticleQualityViewComponent,
  ArticleQualityViewParams,
} from "./quality/article-quality-view.component";
import {
  ArticleTarifsViewComponent,
  ArticleTarifsViewParams,
} from "./tarifs/article-tarifs-view.component";
import {
  ArticleProviderViewComponent,
  ArticleProviderViewParams,
} from "./provider/article-provider-view.component";
import {
  ArticleArticledocumentsViewComponent,
  ArticleArticledocumentsViewParams,
} from "./articledocuments/article-articledocuments-view.component";
import {
  ArticleProviderdocumentsViewComponent,
  ArticleProviderDocumentsViewParams,
} from "./providerdocuments/article-providerdocuments-view.component";

export const gen_article_detail_views = (
  routes: ARTICLE_ROUTES,
  links: ARTICLE_LINKS_ROUTES,
): RoutesDefinition => {
  const gen_routes: RoutesDefinition = [];

  if (routes.article_detail_route) {
    gen_routes.push({
      path: "",
      pathMatch: "full",
      component: ArticleDisplayViewComponent,
      data: RData<ArticleDisplayViewParams>({
        route: FRONT_ROUTES.catalog_articles_article,
        a_menu: routes,
        title: "",
        skip: true,
      }),
    });
  }

  if (routes.article_eamappings_route) {
    gen_routes.push({
      path: RS.earticles,
      component: ArticleEamappingsViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      data: RData<ArticleEamappingsViewParams>({
        route: routes.article_eamappings_route,
        title: "Articles externes",
        a_menu: routes,
        article_detail_route: routes.article_detail_route,
        tariftype_detail_route: links.tariftype_detail_route,
      }),
    });
  }

  if (routes.article_edit_route) {
    gen_routes.push({
      path: RS.edit,
      component: ArticleManageViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      runGuardsAndResolvers: "always",
      data: RData<ArticleManageViewParams>({
        route: routes.article_edit_route,
        title: "Edition article",
        a_menu: routes,
        article_detail_route: routes.article_detail_route,
        family_detail_route: links.family_detail_route,
        articles_list_route: links.article_list_route,
      }),
    });
  }

  if (routes.article_provider_route) {
    gen_routes.push({
      path: RS.provider,
      component: ArticleProviderViewComponent,
      data: RData<ArticleProviderViewParams>({
        route: routes.article_provider_route,
        title: "Fournisseur",
        a_menu: routes,
      }),
    });
  }

  if (routes.article_articledocuments_route) {
    gen_routes.push({
      path: RS.articledocuments,
      component: ArticleArticledocumentsViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      data: RData<ArticleArticledocumentsViewParams>({
        route: routes.article_articledocuments_route,
        title: "Documents article",
        a_menu: routes,
      }),
    });
  }

  if (routes.article_providerdocuments_route) {
    gen_routes.push({
      path: RS.providerdocuments,
      component: ArticleProviderdocumentsViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      data: RData<ArticleProviderDocumentsViewParams>({
        route: routes.article_providerdocuments_route,
        provider_detail_route: links.provider_detail_route,
        title: "Documents fournisseur",
        a_menu: routes,
      }),
    });
  }

  if (routes.article_prices_route) {
    gen_routes.push({
      path: RS.prices,
      component: ArticlePricesViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      data: RData<ArticlePricesViewParams>({
        route: routes.article_prices_route,
        title: "Prix tarifs",
        a_menu: routes,
        article_detail_route: routes.article_detail_route,
        family_detail_route: links.family_detail_route,
        tarif_detail_route: links.tarif_detail_route,
        tariftype_detail_route: links.tariftype_detail_route,
      }),
    });
  }

  if (routes.article_quality_route) {
    gen_routes.push({
      path: RS.quality,
      component: ArticleQualityViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      data: RData<ArticleQualityViewParams>({
        route: routes.article_quality_route,
        title: "Contrôle article",
        a_menu: routes,
        article_detail_route: routes.article_detail_route,
        article_edit_route: routes.article_edit_route,
        article_list_route: links.article_list_route,
        family_detail_route: links.family_detail_route,
      }),
    });
  }

  if (routes.article_tarifs_route) {
    gen_routes.push({
      path: RS.tarifs,
      component: ArticleTarifsViewComponent,
      canMatch: [
        IsInGroupGuard.forGroups(
          SPECIAL_GROUPS.superuser,
          ADMIN_GROUPS.catalog_editor,
        ),
      ],
      data: RData<ArticleTarifsViewParams>({
        route: routes.article_tarifs_route,
        title: "Tarifs",
        a_menu: routes,
        tariftype_detail_route: links.tariftype_detail_route,
        group_detail_route: links.group_detail_route,
        user_detail_route: links.user_detail_route,
        client_detail_route: links.client_detail_route,
      }),
    });
  }
  return gen_routes;
};
