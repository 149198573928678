import {
  charField,
  DataModel,
  decimalField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class VivalyaBillItemBase extends DataModel {
  static override readonly __name: string = 'VivalyaBillItemBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 50,
  })
  public flags: string[] = [];

  @detailsField({
    name: 'hash',
    description: 'hash',
    priority: -1,
  })
  public hash!: string;

  @foreignKeyField({
    name: 'bill',
    description: 'ID Facture',
    related: 'VivalyaBill',
    priority: -1,
  })
  public bill!: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
    priority: -1,
  })
  public article!: number;

  @foreignKeyField({
    name: 'adh_article',
    description: 'ID Article adhérent',
    related: 'ExternalArticle',
    priority: -1,
  })
  public adh_article!: number;

  @foreignKeyField({
    name: 'gc_article',
    description: 'ID Article grand compte',
    related: 'ExternalArticle',
    priority: -1,
  })
  public gc_article!: number;

  @foreignKeyField({
    name: 'family',
    description: 'ID Famille',
    related: 'Family',
    priority: -1,
  })
  public family!: number;

  @foreignKeyField({
    name: 'producer',
    description: 'ID Producteur',
    related: 'Producer',
    priority: -1,
  })
  public producer!: number;

  @decimalField({
    name: 'weight',
    description: 'Poids (kg)',
    factor: 10000,
    priority: 500,
  })
  public weight!: number;

  @decimalField({
    name: 'packages',
    description: 'Colis',
    factor: 10000,
    priority: 500,
  })
  public packages!: number;

  @decimalField({
    name: 'pieces',
    description: 'Pièces',
    factor: 10000,
    priority: 500,
  })
  public pieces!: number;

  @decimalField({
    name: 'quantity',
    description: 'Quantité',
    factor: 10000,
    priority: 500,
  })
  public quantity!: number;

  @decimalField({
    name: 'brut_amount',
    description: 'Montant brut',
    factor: 10000,
    priority: 400,
  })
  public brut_amount!: number;

  @decimalField({
    name: 'net_amount',
    description: 'Montant net',
    factor: 10000,
    priority: 400,
  })
  public net_amount!: number;

  @decimalField({
    name: 'vat_amount',
    description: 'TVA',
    factor: 10000,
    priority: 400,
  })
  public vat_amount!: number;

  @decimalField({
    name: 'wvat_amount',
    description: 'TTC',
    factor: 10000,
    priority: 400,
  })
  public wvat_amount!: number;

  @charField({
    name: 'uv',
    description: 'UV',
    maxLength: 10,
    priority: 450,
  })
  public uv!: string;

  @charField({
    name: 'provider_country',
    description: 'Pays fournisseur',
    maxLength: 30,
    priority: 300,
  })
  public provider_country!: string;

  @charField({
    name: 'provider_type',
    description: 'Type fournisseur',
    maxLength: 30,
    priority: 300,
  })
  public provider_type!: string;

  @charField({
    name: 'provider_postcode',
    description: 'Code postal fournisseur',
    maxLength: 30,
    priority: 300,
  })
  public provider_postcode!: string;

  @charField({
    name: 'provider_name',
    description: 'Nom du fournisseur',
    maxLength: 250,
    priority: 300,
  })
  public provider_name!: string;

  @charField({
    name: 'vvfamily',
    description: 'Famille facturation',
    maxLength: 500,
    priority: 300,
  })
  public vvfamily!: string;

  @charField({
    name: 'adh_code',
    description: 'Code adhérent',
    maxLength: 30,
    priority: 800,
  })
  public adh_code!: string;

  @charField({
    name: 'adh_libelle',
    description: 'Libellé adherent',
    maxLength: 250,
    priority: 800,
  })
  public adh_libelle!: string;

  @charField({
    name: 'gc_code',
    description: 'Code GC',
    maxLength: 30,
    priority: 780,
  })
  public gc_code!: string;

  @charField({
    name: 'gc_libelle',
    description: 'Libellé GC',
    maxLength: 250,
    priority: 780,
  })
  public gc_libelle!: string;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;
}
