import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import {Comment} from "./comment";

@Injectable({
    providedIn: 'root'
})
export class CommentService extends Collection<Comment> {

    constructor(_backend: DataBackend) {
        super(_backend, "/v3/newscomments", Comment);
    }
}
