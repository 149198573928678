import { ProducerDocumentBase } from './producer-document.base';
import { detailsField } from '@solidev/data';
import { ProducerBase } from '../producer/producer.base';
import { ImageDisplay } from '../../documents/image/image.base';
import { Image } from '../../documents/image/image';
import { Label } from '../../catalog/label/label';
import { ProductBase } from '../../catalog/product/product.base';

export class ProducerDocument extends ProducerDocumentBase {
  static override readonly __name: string = 'ProducerDocument';

  @detailsField({
    description: 'Producteur',
    model: ProducerBase,
    readonly: true,
    priority: 880,
  })
  public producer_details?: ProducerBase;

  @detailsField({
    description: 'Preview',
    model: Image,
    readonly: true,
    priority: 890,
  })
  public image_details?: ImageDisplay;

  @detailsField({
    description: 'Label',
    model: Label,
    readonly: true,
    priority: 900,
  })
  public label_details?: Label;

  @detailsField({
    description: 'Produits',
    model: ProductBase,
    readonly: true,
    priority: 750,
    many: true,
  })
  public products_details?: ProductBase[];
}
