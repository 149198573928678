
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Order } from "./order";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends Collection<Order> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/orders", Order);
  }
}
