import { OrderitemBase } from './orderitem.base';
import { detailsField } from '@solidev/data';
import { Order } from '../order/order';
import { Orderprocessarticle } from '../orderprocessarticle/orderprocessarticle';
import { StorageBase } from '../../structures/storage/storage.base';
import { PlatformBase } from '../../structures/platform/platform.base';
import { TarifBase } from '../../tarifs/tarif/tarif.base';

export class Orderitem extends OrderitemBase {
  static override readonly __name: string = 'Orderitem';
  @detailsField({
    model: Order,
    description: 'Commande',
    readonly: true,
  })
  public order_details?: Order;

  @detailsField({
    model: Orderprocessarticle,
    description: 'Article',
    readonly: true,
  })
  public item_details?: Orderprocessarticle;

  @detailsField({
    description: 'Dépôt',
    model: StorageBase,
    readonly: true,
  })
  public storage_details?: StorageBase;

  @detailsField({
    description: 'Plateforme',
    model: PlatformBase,
    readonly: true,
  })
  public platform_details?: PlatformBase;

  @detailsField({
    description: 'Tarif',
    model: TarifBase,
    readonly: true,
  })
  public tarif_details?: TarifBase;
}
