import { formatNumber } from '@angular/common';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const getWeek = (date: Date): number => {
  const dt = new Date(date.getTime());
  dt.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(dt.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((dt.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};

export const m02 = (v: number) => formatNumber(v, 'fr-fr', '2.0-0');

// Returns the four-digit year corresponding to the ISO week of the date.
export const getWeekYear = (date: Date): number => {
  const dt = new Date(date.getTime());
  dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
  return dt.getFullYear();
};

export const ngbd2s = (d: NgbDateStruct) => `${d.year}-${m02(d.month)}-${m02(d.day)}`;

export const toDateString = (date: Date | NgbDate | string, start: boolean = true): string => {
  if (date instanceof Date) {
    return (
      date.getFullYear().toString() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      (start ? '00:00:00' : '23:59:59')
    );
  } else if (date instanceof NgbDate) {
    return ngbd2s(date) + 'T' + (start ? '00:00:00' : '23:59:59');
  } else {
    return toDateString(new Date(date));
  }
};

export const titleTemplate = (tpl: string, datestart: Date, dateend: Date): string => {
  if (!tpl) {
    tpl = 'du (DJJJ) (DJ) (DMM) (DY) (S(DW)) au (FJJJ) (FJ) (FMM) (FY)';
  }
  const TPLTAGS: Record<string, number | string> = {
    DA: datestart.getFullYear(),
    DY: datestart.getFullYear(),
    DS: m02(getWeek(datestart)),
    DW: m02(getWeek(datestart)),
    DM: m02(datestart.getMonth() + 1),
    DMM: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aou', 'sep', 'oct', 'nov', 'dec'][datestart.getMonth()],
    DMMM: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ][datestart.getMonth()],
    DJ: m02(datestart.getDate()),
    DJJ: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'][datestart.getUTCDay()],
    DJJJ: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'][datestart.getUTCDay()],
    DD: m02(datestart.getDate()),
    DDD: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'][datestart.getUTCDay()],
    DDDD: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'][datestart.getUTCDay()],
    FA: dateend.getFullYear(),
    FY: dateend.getFullYear(),
    FM: m02(dateend.getMonth() + 1),
    FS: m02(getWeek(dateend)),
    FW: m02(getWeek(dateend)),
    FMM: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aou', 'sep', 'oct', 'nov', 'dec'][dateend.getMonth()],
    FMMM: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ][dateend.getMonth()],
    FJ: m02(dateend.getDate()),
    FJJ: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'][dateend.getUTCDay()],
    FJJJ: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'][dateend.getUTCDay()],
    FD: m02(dateend.getDate()),
    FDD: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'][dateend.getUTCDay()],
    FDDD: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'][dateend.getUTCDay()],
  };

  for (const tp of Object.keys(TPLTAGS)) {
    tpl = tpl.replace('(' + tp + ')', `${TPLTAGS[tp]}`);
  }
  return tpl;
};
