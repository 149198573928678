
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Order } from '../order';
@Component({
  selector: 'lvadg-order-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-manage.component.pug',
  styleUrls: ['./order-manage.component.sass']
})
export class OrderManageComponent implements OnInit {
  @Input() public order?: Order;

  constructor() { }

  ngOnInit(): void {
  }

}
