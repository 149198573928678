import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  signal,
} from "@angular/core";
import { Cdata } from "../cdata";
import { CDATAKEY_DEST } from "../../cdata-key/cdata-key.base";
import { CdataKeyService } from "../../cdata-key/cdata-key.service";
import { CdataKey } from "../../cdata-key/cdata-key";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "lvadg-cdatas-display",
  standalone: true,
  imports: [],
  template: `
    @if (cdatas(); as cdatas) {
      @if (keys(); as keys) {
        <table class="table">
          <tbody>
            @for (cdata of cdatas; track cdata.id) {
              <tr>
                <th>{{ keys.get(cdata.key)?.name }}</th>
                <td>{{ cdata.display_value }}</td>
              </tr>
            }
          </tbody>
        </table>
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CdatasDisplayComponent {
  public cdatas = input.required<Cdata[] | undefined>();
  public dest = input<CDATAKEY_DEST>();
  public keys = signal<Map<number, CdataKey>>(new Map());
  private _keys = inject<CdataKeyService>(CdataKeyService);

  constructor() {
    effect(async () => {
      const d = this.dest();
      if (!d) {
        return;
      }
      const k = new Map<number, CdataKey>(
        Array.from(await firstValueFrom(this._keys.byDest(d))).map((k) => [
          k.id,
          k,
        ]),
      );
      this.keys.set(k);
    });
  }
}
