
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Product } from "./product";

@Injectable({
  providedIn: 'root'
})
export class ProductService extends Collection<Product> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/products", Product);
  }
}
