import { Component, computed, inject, input } from "@angular/core";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { NgIf } from "@angular/common";
import { ARTICLE_ROUTES } from "../menu";
import { Article } from "../../../../../models/catalog/article/article";
import { Link } from "@solidev/data";
import { AuthService } from "../../../../../models/users/auth.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { ADMIN_GROUPS } from "../../../../../constants";

@Component({
  selector: "lvadg-article-nav",
  standalone: true,
  imports: [NgIf, RouterLink, RouterLinkActive],
  templateUrl: "./article-nav.component.pug",
  styleUrl: "./article-nav.component.sass",
})
export class ArticleNavComponent {
  public routes = input.required<ARTICLE_ROUTES>();
  public article = input.required<Article>();
  private _router = inject(Router);
  public article_detail_route = computed(() => {
    return new Link(
      this.routes().article_detail_route,
      { article: this.article() },
      this._router,
    );
  });
  public article_provider_route = computed(() => {
    if (!this.article().fournisseur) {
      return null;
    }
    return new Link(
      this.routes().article_provider_route,
      { article: this.article() },
      this._router,
    );
  });
  private _user = toSignal(inject(AuthService).currentUser$);
  public article_tarifs_route = computed(() => {
    const user = this._user();
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_tarifs_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });
  public article_eamappings_route = computed(() => {
    const user = this._user();
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_eamappings_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });
  public article_prices_route = computed(() => {
    const user = this._user();
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_prices_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });
  public article_quality_route = computed(() => {
    const user = this._user();
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_quality_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });
  public article_edit_route = computed(() => {
    const user = this._user();
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_edit_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });
  public article_providerdocuments_route = computed(() => {
    const user = this._user();
    if (!this.article().fournisseur) {
      return null;
    }
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_providerdocuments_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });

  public article_articledocuments_route = computed(() => {
    const user = this._user();
    if (
      user &&
      (user.is_superuser || user.have_group(ADMIN_GROUPS.catalog_editor))
    ) {
      return new Link(
        this.routes().article_articledocuments_route,
        { article: this.article() },
        this._router,
      );
    } else {
      return null;
    }
  });
}
