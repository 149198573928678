import { Component } from '@angular/core';

@Component({
  selector: 'lvadg-product-user-create',
  standalone: true,
  imports: [],
  templateUrl: './product-user-create.component.pug',
  styleUrl: './product-user-create.component.sass',
})
export class ProductUserCreateComponent {}
