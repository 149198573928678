import { UsergrouplinkBase } from './usergrouplink.base';
import { detailsField } from '@solidev/data';
import { GroupBase } from '../group/group.base';

export class Usergrouplink extends UsergrouplinkBase {
  static override readonly __name: string = 'Usergrouplink';

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;
}
