import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferProduct } from '../offer-product';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-offer-product-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './offer-product-manage.component.pug',
  styleUrls: ['./offer-product-manage.component.sass'],
})
export class OfferProductManageComponent {
  @Input() public offerproduct?: OfferProduct;
}
