
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypePermission } from '../tariftype-permission';
@Component({
  selector: 'lvadg-tariftype-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-permission-manage.component.pug',
  styleUrls: ['./tariftype-permission-manage.component.sass']
})
export class TariftypePermissionManageComponent implements OnInit {
  @Input() public tariftypepermission?: TariftypePermission;

  constructor() { }

  ngOnInit(): void {
  }

}
