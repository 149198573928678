
<dl class="row">
  <data-dispedit class="col-md-6" [model]="tts" field="tariftype" [collection]="tariftype$" mode="inline" [editable]="!tariftype" [filter]="tariftypeFilter">Modèle de tarif à associer (uniquement mode détails par région)</data-dispedit>
  <data-dispedit class="col-md-6" [model]="tts" field="region" [collection]="regions$" mode="inline" [filter]="{fields: ['id', 'code', 'type','name', 'client_details'].join(',')}">Région à associer</data-dispedit>
</dl>
<div class="row mt-3">
  <div class="col-8">
    <button class="btn btn-primary w-100" (click)="save()"><i class="bi bi-save me-2"></i>Enregistrer</button>
  </div>
  <div class="col-4">
    <button class="btn btn-secondary w-100" (click)="cancelled.emit()">Annuler</button>
  </div>
</div>