import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Category } from '../../../../../models/catalog/meta/category/category';
import { CategoryManageComponent } from '../../../../../models/catalog/meta/category/category-manage/category-manage.component';
import { CategoryCreateComponent } from '../../../../../models/catalog/meta/category/category-create/category-create.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { map, Observable } from 'rxjs';
import { FilterDefaults, Link } from '@solidev/data';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { META_ARTICLE_LIST_FIELDS } from '../../constants';
import { CategoryDetailViewData } from './category-detail-view.component.params';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';

@Component({
  selector: 'lvadg-category-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    CategoryManageComponent,
    CategoryCreateComponent,
    ArticleListComponent,
    HeaderActionComponent,
  ],
  templateUrl: './category-detail-view.component.pug',
  styleUrls: ['./category-detail-view.component.sass'],
})
export class CategoryDetailViewComponent extends BaseDetailViewComponent<CategoryDetailViewData, Category> {
  public list_route!: Link<Category>;
  public article_detail_route!: Link<ArticleBase>;
  public article_list_fields = META_ARTICLE_LIST_FIELDS;

  public metaFilter$!: Observable<FilterDefaults>;

  public override setRouteData(data: CategoryDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.metaFilter$ = this.data$.pipe(map((d) => ({ categories: d.category.id })));
  }

  deleted() {
    this.list_route.navigate();
  }
}
