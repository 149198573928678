
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TariftypeStorage } from "./tariftype-storage";

@Injectable({
  providedIn: 'root'
})
export class TariftypeStorageService extends Collection<TariftypeStorage> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tariftypestorages", TariftypeStorage);
  }
}
