import { Component, ElementRef, ViewChild } from '@angular/core';

import { combineLatest, firstValueFrom, map, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  BaseRouteParams,
  DataMessageService,
  FilterDefaults,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
  TabMemoryService,
} from '@solidev/data';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../components/baseview/basedetailview.imports';
import { TarifManageComponent } from '../../../models/tarifs/tarif/tarif-manage/tarif-manage.component';
import { TarifExportComponent } from '../../../models/tarifs/tarif/tarif-export/tarif-export.component';
import { TarifLifecycleComponent } from '../../../models/tarifs/tarif/lifecycle/tarif-lifecycle/tarif-lifecycle.component';
import { ArticleTarifListComponent } from '../../../models/tarifs/article-tarif/article-tarif-list/article-tarif-list.component';
import { TarifDocumentListComponent } from '../../../models/tarifs/tarif-document/tarif-document-list/tarif-document-list.component';
import { TarifDocumentCreateComponent } from '../../../models/tarifs/tarif-document/tarif-document-create/tarif-document-create.component';
import { TariftypeRelationListComponent } from '../../../models/tarifs/tariftype-relation/tariftype-relation-list/tariftype-relation-list.component';
import { TarifNotificationListComponent } from '../../../models/tarifs/tarif-notification/tarif-notification-list/tarif-notification-list.component';
import { TarifNotificationStatusListComponent } from '../../../models/tarifs/tarif-notification-status/tarif-notification-status-list/tarif-notification-status-list.component';
import { BaseDetailViewComponent } from '../../../components/baseview/basedetailview.component';
import { Tarif } from '../../../models/tarifs/tarif/tarif';
import { TARIF_STATUS, TarifBase } from '../../../models/tarifs/tarif/tarif.base';
import { ArticleBase } from '../../../models/catalog/article/article.base';
import { FamilyBase } from '../../../models/catalog/family/family.base';
import { EarticleBase } from '../../../models/catalog/earticle/earticle.base';
import {
  TARIFTYPE_LIFECYCLE,
  TARIFTYPE_USER_LEVEL,
  TariftypeBase,
} from '../../../models/tarifs/tariftype/tariftype.base';
import { Tariftype } from '../../../models/tarifs/tariftype/tariftype';
import { ModelListAction } from '../../../includes/modellist/modellist.component';
import { Article } from '../../../models/catalog/article/article';
import { TariftypeActionService } from '../../../models/tarifs/tarif/tariftype-action.service';
import { ArticleListComponent } from '../../../models/catalog/article/article-list/article-list.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HeaderActionComponent } from '../../../components/utils/header-action/header-action.component';
import { EDITION_ALLOWED_STEPS, LIFECYCLE_SUB_STEP } from '../../../models/tarifs/tarif/lifecycle/lifecycle-steps';
import { TarifActionService } from '../../../models/tarifs/tarif/tarif-action.service';

export interface TarifFullDetailViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  family_detail_route: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
}

export interface TarifFullDetailViewData extends TarifFullDetailViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-full-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    RouterLink,
    TarifManageComponent,
    TarifExportComponent,
    TarifLifecycleComponent,
    ArticleTarifListComponent,
    TarifDocumentListComponent,
    TarifDocumentCreateComponent,
    TariftypeRelationListComponent,
    TarifNotificationListComponent,
    TarifNotificationStatusListComponent,
    ArticleListComponent,
    HeaderActionComponent,
    SafeDeleteComponent,
  ],
  templateUrl: './tarif-detail-view.component.pug',
  styleUrls: ['./tarif-detail-view.component.sass'],
})
export class TarifFullDetailViewComponent extends BaseDetailViewComponent<TarifFullDetailViewData, Tarif> {
  @ViewChild('catalogSlot') public catalogSlot!: ElementRef<HTMLDivElement>;

  public tarif_detail_route?: Link<TarifBase>;
  public article_detail_route?: Link<ArticleBase>;
  public family_detail_route?: Link<FamilyBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public tariftype_detail_route?: Link<TariftypeBase>;
  public tarif$ = new ReplaySubject<Tarif>(1);
  public tarifFilter$!: Observable<FilterDefaults>;
  public catalogTarifTypeFilter$!: Observable<FilterDefaults>;
  public tdata$!: Observable<TarifFullDetailViewData>;

  public reloadTarif$: Subject<void | boolean> = new Subject<void | boolean>();

  public articletarif_default_fields = [
    'article_details',
    'message',
    'article_family',
    'article_labels',
    'activation',
    'price',
    'article_uf',
    'price_history_1',
  ];
  public relations_default_fields = ['id', 'src_details', 'src_current_tarifrelation', 'type', 'ops'];
  public documents_default_fields = [
    'id',
    'image_details',
    'title',
    'filename',
    'filesize',
    'type',
    'status',
    'flags',
    'created',
    'updated',
    'actions',
  ];
  public catalog_default_fields = [
    'code',
    'libelle',
    'family_details',
    'udf_unit',
    'egalim_details',
    'local',
    'egalim_status',
    'calibre_details',
    'categorie_details',
    'origine_details',
    'colisage',
    'uci',
    'in_tariftype',
    'actions',
  ];
  public tn_default_fields = ['created', 'updated', 'type', 'priority', 'title', 'status'];
  public tns_default_fields = [
    'id',
    'notification_details',
    'notification__type',
    'notification__tarif',
    'notification__priority',
    'user_details',
    'destination',
    'status',
    'updated',
    'created',
  ];

  public TTUL = TARIFTYPE_USER_LEVEL;
  public TTLC = TARIFTYPE_LIFECYCLE;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    public ttAction: TariftypeActionService,
    public tAction: TarifActionService,
    private _ofc: NgbOffcanvas,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifFullDetailViewData) {
    if (!data.tariftype) {
      if (data.tarif.tariftype_details) {
        data.tariftype = data.tarif.tariftype_details!;
      } else {
        throw new Error('Missing tariftype data');
      }
    }
    if (data.tariftype.lifecycle === this.TTLC.UPLOAD) {
      this.defaultNavId = 'details';
    } else {
      this.defaultNavId = 'prices';
    }
    super.setRouteData(data);

    this.tarif_detail_route = new Link(data.tarif_detail_route, data);
    this.article_detail_route = new Link(data.article_detail_route, data);
    this.family_detail_route = new Link(data.family_detail_route, data);
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(data.earticle_detail_route, data);
    }
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);

    this.tarif$.next(data.tarif);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tdata$ = combineLatest([this.data$, this.tarif$]).pipe(
      map(([data, tarif]) => {
        data.tarif = tarif;
        return data;
      }),
    );
    this.tarifFilter$ = this.tdata$.pipe(
      map((d) => {
        return { tarif: d.tarif.id };
      }),
    );
    this.catalogTarifTypeFilter$ = this.tdata$.pipe(
      map((d) => {
        return { in_tariftype: d.tariftype.id };
      }),
    );
  }

  public canEdit(data: TarifFullDetailViewData, level: TARIFTYPE_USER_LEVEL) {
    if (data.tariftype.have_level(TARIFTYPE_USER_LEVEL.sadmin)) {
      return true;
    }
    return (
      data.tariftype.have_level(level) &&
      (data.tarif.status === TARIF_STATUS.PREPARATION ||
        (EDITION_ALLOWED_STEPS as any).includes(data.tarif.tstep_details?.pstep || -1) ||
        data.tarif.tstep_details?.step === LIFECYCLE_SUB_STEP.EDITION)
    );
  }

  public canDelete(data: TarifFullDetailViewData) {
    if (data.tariftype.have_level(TARIFTYPE_USER_LEVEL.sadmin)) {
      return true;
    }
    return data.tariftype.have_level(TARIFTYPE_USER_LEVEL.pilote) && data.tarif.status === TARIF_STATUS.PREPARATION;
  }

  public async catalogAction(tt: Tariftype, action: ModelListAction<Article>) {
    if (action.action == 'tariftype_add' && action.model) {
      try {
        await firstValueFrom(
          tt.action('POST', 'action', {
            body: {
              action: 'article_add',
              article: action.model.id,
            },
          }),
        );
        this.reloadTarif$.next();
        this._msgs.success('Article ajouté au tarif');
      } catch (e) {
        console.error(e);
        this._msgs.error(
          "Erreur lors de l'ajout de l'article",
          `${(e as any).error?.message || (e as any).message || ''}`,
          e,
        );
      }
    }
  }

  openCatalog() {
    this._ofc.open(this.catalogSlot, {
      position: 'bottom',
      panelClass: 'h-auto',
    });
  }

  updateTarif(tarif: Tarif) {
    this.tarif$.next(tarif);
  }

  public async updateArticles(data: TarifFullDetailViewData) {
    await this.tAction.action(data.tarif, { action: 'sync_articles' });
    this.reloadTarif$.next();
  }
}
