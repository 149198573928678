import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Rnmtarif } from '../rnmtarif';
import { RnmtarifService } from '../rnmtarif.service';
import {
  FactorcPipe,
  FactorPipe,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter, ModelListDateFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { RnmmarcheService } from '../../rnmmarche/rnmmarche.service';
import { RnmmarcheBase } from '../../rnmmarche/rnmmarche.base';
import { RnmcotationBase } from '../../rnmcotation/rnmcotation.base';
import { RnmBase } from '../../rnm/rnm.base';
import { RnmlibelleBase } from '../../rnmlibelle/rnmlibelle.base';
import { ArticleService } from '../../../catalog/article/article.service';

@Component({
  selector: 'lvadg-rnmtarif-list',
  standalone: true,
  templateUrl: './rnmtarif-list.component.pug',
  styleUrls: ['./rnmtarif-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, FactorPipe, FactorcPipe],
})
export class RnmtarifListComponent extends ModellistComponent<Rnmtarif> {
  @Input() public rnmmarche_detail_route?: Link<RnmmarcheBase>;
  @Input() public rnmcotation_detail_route?: Link<RnmcotationBase>;
  @Input() public rnm_detail_route?: Link<RnmBase>;
  @Input() public rnmlib_detail_route?: Link<RnmlibelleBase>;

  constructor(
    coll: RnmtarifService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _marches: RnmmarcheService,
    private _articles: ArticleService
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'start'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListDateFilter({
          field: 'start',
          name: 'start',
          label: 'Recherche par date',
        }),
      ],
    };
  }

  // public override getRowClasses(row: Rnmtarif): string[] {
  //   const cls = super.getRowClasses(row);
  //   if (!row.rnmlib_details?.rnm) {
  //     cls.push('table-warning');
  //   }
  //   return cls;
  // }
}
