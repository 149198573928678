
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { IoqrcodeService} from "./ioqrcode.service";
import { Ioqrcode } from "./ioqrcode";

@Injectable({
  providedIn: 'root'
})
export class IoqrcodeResolver implements Resolve<Ioqrcode> {
  constructor(private _rs: IoqrcodeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Ioqrcode> {
    return this._rs.fetch(+route.params["ioqrcodeId"])
  }
}
