import { Component, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferNotification } from "../offer-notification";
import { Link } from "@solidev/data";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lvadg-offer-notification-display",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./offer-notification-display.component.pug",
  styleUrls: ["./offer-notification-display.component.sass"],
})
export class OfferNotificationDisplayComponent {
  public notification = input<OfferNotification>();
  public mode = input<"line">("line");
  public detail_route = input<Link<OfferNotification>>();
}
