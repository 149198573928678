import { BaseRouteParams } from "@solidev/data";
import { Article } from "../../../../models/catalog/article/article";
import { ARTICLE_ROUTES } from "./menu";

export interface ArticleViewParams extends BaseRouteParams {
  a_menu: ARTICLE_ROUTES;
}

export interface ArticleViewData extends ArticleViewParams {
  article: Article;
}
