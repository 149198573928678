import { RData, RouteConfigItem, RoutesDefinition } from "@solidev/data";
import { PRODUCER_LINKS_ROUTES, PRODUCER_ROUTES } from "./menu";
import {
  ProducerEditViewComponent,
  ProducerEditViewParams,
} from "./edit/producer-edit-view.component";
import {
  ProducerRelationsViewComponent,
  ProducerRelationsViewParams,
} from "./relations/producer-relations-view.component";
import {
  ProducerOffersViewComponent,
  ProducerOffersViewParams,
} from "./offers/producer-offers-view.component";
import { RS } from "../../../../constants";
import {
  ProducerDocumentsViewComponent,
  ProducerDocumentsViewParams,
} from "./documents/producer-documents-view.component";
import {
  ProducerProductsViewComponent,
  ProducerProductsViewParams,
} from "./products/producer-products-view.component";
import {
  PRODUCT_CONTEXT,
  PRODUCT_LINKS_ROUTES,
  PRODUCT_ROUTES,
} from "../../../catalog/product/detail/menu";
import { gen_product_detail_views } from "../../../catalog/product/detail/product.routes";
import { ProductResolver } from "../../../../models/catalog/product/product.resolver";
import {
  ProductDisplayViewData,
  ProductDisplayViewParams,
} from "../../../catalog/product/detail/display/product-display-view.component";
import {
  ProducerDisplayViewComponent,
  ProducerDisplayViewParams,
} from "./display/producer-display-view.component";

export const gen_producer_detail_views = (
  routes: PRODUCER_ROUTES,
  link_routes: PRODUCER_LINKS_ROUTES,
  context: PRODUCT_CONTEXT,
  default_route: RouteConfigItem,
  product_routes?: PRODUCT_ROUTES,
  product_link_routes?: PRODUCT_LINKS_ROUTES,
): RoutesDefinition => {
  const gen_routes: RoutesDefinition = [];
  if (routes.producer_detail_route) {
    gen_routes.push({
      path: "",
      pathMatch: "full",
      data: RData<ProducerDisplayViewParams>({
        skip: true,
        route: routes.producer_detail_route || default_route,
        local: context.local,
        pr_menu: routes,
      }),
      component: ProducerDisplayViewComponent,
    });
  }
  // edit
  if (routes.producer_edit_route) {
    gen_routes.push({
      path: RS.edit,
      data: RData<ProducerEditViewParams>({
        title: "Édition",
        route: routes.producer_edit_route || default_route,
        producer_list_route: link_routes.producer_list_route,
        pr_menu: routes,
      }),
      component: ProducerEditViewComponent,
    });
  }
  // relations
  if (routes.producer_relations_route) {
    gen_routes.push({
      path: RS.relations,
      data: RData<ProducerRelationsViewParams>({
        title: "Relations",
        route: routes.producer_relations_route || default_route,
        pr_menu: routes,
      }),
      component: ProducerRelationsViewComponent,
    });
  }
  // offers
  if (routes.producer_offers_route) {
    gen_routes.push({
      path: RS.offers,
      data: RData<ProducerOffersViewParams>({
        title: "Offres",
        route: routes.producer_offers_route || default_route,
        offer_detail_route: link_routes.offer_detail_route,
        offertypestorage_offer_detail_route:
          link_routes.offertypestorage_offer_detail_route,
        pr_menu: routes,
      }),
      component: ProducerOffersViewComponent,
    });
  }
  // documents
  if (routes.producer_documents_route) {
    gen_routes.push({
      path: RS.documents,
      data: RData<ProducerDocumentsViewParams>({
        title: "Documents",
        route: routes.producer_documents_route || default_route,
        pr_menu: routes,
      }),
      component: ProducerDocumentsViewComponent,
    });
  }
  // products simple
  if (
    routes.producer_products_route &&
    (!product_routes || !product_link_routes)
  ) {
    gen_routes.push({
      path: RS.products,
      data: RData<ProducerProductsViewParams>({
        title: "Produits",
        route: routes.producer_products_route || default_route,
        product_detail_route: link_routes.product_detail_route,
        pr_menu: routes,
        local: context.local,
      }),
      component: ProducerProductsViewComponent,
    });
  }
  // products with product routes
  else if (
    routes.producer_products_route &&
    product_routes &&
    product_link_routes
  ) {
    gen_routes.push({
      path: RS.products,
      data: RData<ProducerProductsViewParams>({
        title: "Produits",
        route: routes.producer_products_route || default_route,
        product_detail_route:
          product_routes.product_detail_route ||
          link_routes.product_detail_route,
        pr_menu: routes,
        local: context.local,
      }),
      children: [
        {
          path: "",
          pathMatch: "full",
          data: RData<ProducerProductsViewParams>({
            skip: true,
            route: routes.producer_products_route || default_route,
            product_detail_route:
              product_routes.product_detail_route ||
              link_routes.product_detail_route,
            pr_menu: routes,
            local: context.local,
          }),
          component: ProducerProductsViewComponent,
        },
        {
          path: ":productId",
          data: RData<ProductDisplayViewParams>({
            title: (d: ProductDisplayViewData) => d.product?.name || "Produit",
            route: routes.producer_products_route || default_route,
            producer_detail_route: routes.producer_detail_route,
            p_menu: product_routes,
            local: context.local,
          }),
          resolve: {
            product: ProductResolver,
          },
          children: [
            ...gen_product_detail_views(
              product_routes,
              product_link_routes,
              context,
              default_route,
            ),
          ],
        },
      ],
    });
  } else if (routes.producer_products_route) {
    console.error("Missing product routes");
  }

  return gen_routes;
};
