
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OffertypeStorageService} from "./offertype-storage.service";
import { OffertypeStorage } from "./offertype-storage";

@Injectable({
  providedIn: 'root'
})
export class OffertypeStorageResolver implements Resolve<OffertypeStorage> {
  constructor(private _rs: OffertypeStorageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OffertypeStorage> {
    return this._rs.fetch(+route.params["offertypestorageId"])
  }
}
