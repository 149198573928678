import { Component, inject } from "@angular/core";
import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { BaseRouteParams, FilterDefaults } from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { map, Observable, Subject } from "rxjs";
import { ProviderService } from "../../../../../models/structures/provider/provider.service";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";
import { ArticleDocumentListComponent } from "../../../../../models/catalog/article-document/article-document-list/article-document-list.component";

export interface ArticleArticledocumentsViewParams
  extends ArticleViewParams,
    BaseRouteParams {}

export interface ArticleArticleDocumentsViewData
  extends ArticleArticledocumentsViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-articledocuments-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ArticleNavComponent,
    ArticleDocumentListComponent,
  ],
  templateUrl: "./article-articledocuments-view.component.pug",
  styleUrl: "./article-articledocuments-view.component.sass",
})
export class ArticleArticledocumentsViewComponent extends BaseDetailViewComponent<
  ArticleArticleDocumentsViewData,
  Article
> {
  public articledocumentsFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = [];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  private _providers = inject(ProviderService);

  public override postNgOnInit() {
    super.postNgOnInit();
    this.articledocumentsFilter$ = this.data$.pipe(
      map((data) => {
        return { article: data.article.id };
      }),
    );
  }
}
