
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { StorageService} from "./storage.service";
import { Storage } from "./storage";

@Injectable({
  providedIn: 'root'
})
export class StorageResolver  {
  constructor(private _rs: StorageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Storage> {
    return this._rs.fetch(+route.params["storageId"])
  }
}
