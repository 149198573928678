import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Tariftype } from "../tariftype";
import { TariftypeService } from "../tariftype.service";
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { GroupBase } from "../../../users/group/group.base";
import { GroupDisplayComponent } from "../../../users/group/group-display/group-display.component";
import { ClientBase } from "../../../structures/client/client.base";
import { NgStringPipesModule } from "ngx-pipes";
import { UserDisplayComponent } from "../../../users/user/user-display/user-display.component";
import { UserBase } from "../../../users/user/user.base";
import {
  TARIFTYPE_ALARM,
  TARIFTYPE_FLAGS_HUMANIZED,
  TARIFTYPE_STATUS,
} from "../tariftype.base";
import { GenericregionDisplayComponent } from "../../../locations/genericregion/genericregion-display/genericregion-display.component";
import { GenericregionBase } from "../../../locations/genericregion/genericregion.base";
import { MemberBase } from "../../../structures/member/member.base";
import { StorageBase } from "../../../structures/storage/storage.base";
import { MemberDisplayComponent } from "../../../structures/member/member-display/member-display.component";
import { StorageDisplayComponent } from "../../../structures/storage/storage-display/storage-display.component";
import { ClientDisplayComponent } from "../../../structures/client/client-display/client-display.component";
import { UserService } from "../../../users/user/user.service";

@Component({
  selector: "lvadg-tariftype-list",
  standalone: true,
  templateUrl: "./tariftype-list.component.pug",
  styleUrls: ["./tariftype-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    GroupDisplayComponent,
    ClientDisplayComponent,
    UserDisplayComponent,
    GenericregionDisplayComponent,
    MemberDisplayComponent,
    StorageDisplayComponent,
    NgStringPipesModule,
    PChoicePipe,
  ],
})
export class TariftypeListComponent extends ModellistComponent<Tariftype> {
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public client_detail_route?: Link<ClientBase>;
  @Input() public genericregion_detail_route?: Link<GenericregionBase>;
  @Input() public member_detail_route?: Link<MemberBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public default_status?: TARIFTYPE_STATUS;
  public TARIFTYPE_FLAGS_HUMANIZED = TARIFTYPE_FLAGS_HUMANIZED;

  constructor(
    coll: TariftypeService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _users: UserService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "type", "alarm"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "type",
          name: "type",
          label: "Filtrer par type",
          model: Tariftype,
          mfield: "type",
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filtrer par état",
          model: Tariftype,
          mfield: "status",
          default: this.default_status || undefined,
        }),
        new ModelListSelectFilter({
          field: "segment",
          name: "segment",
          label: "Filtrer par segment",
          model: Tariftype,
          mfield: "segment",
        }),
        new ModelListSelectFilter({
          field: "zone",
          name: "zone",
          label: "Filtrer par zone",
          model: Tariftype,
          mfield: "zone",
        }),
        new ModelListSelectFilter({
          field: "lifecycle",
          name: "lifecycle",
          label: "Filtrer par cycle de vie",
          model: Tariftype,
          mfield: "lifecycle",
        }),
        new ModelListSelectFilter({
          field: "client_data_link",
          name: "client_data_link",
          label: "Filtrer par liaison trame client",
          choices: [
            { value: "true", desc: "Oui" },
            { value: "false", desc: "Non" },
          ],
        }),

        new ModelListSelectFilter({
          field: "alarm",
          name: "alarm",
          label: "Filtrer par alarme",
          choices: Object.entries(TARIFTYPE_FLAGS_HUMANIZED).map(([n, v]) => ({
            value: n,
            desc: v.desc,
          })),
        }),

        new ModelListAutocompleteMultiFilter({
          field: "pilotes",
          name: "pilotes",
          label: "Filtrer par pilotes",
          collection: this._users,
          filter: { is_pilote: true },
        }),
        new ModelListSelectMultiFilter({
          field: "storages_modes",
          name: "storages_modes",
          mfield: "storages_mode",
          label: "Filtrer par détails par dépôt",
          model: Tariftype,
        }),
        new ModelListSelectMultiFilter({
          field: "regions_modes",
          name: "regions_modes",
          mfield: "regions_mode",
          label: "Filtrer par détails par région",
          model: Tariftype,
        }),
      ],
    };
  }

  override getRowClasses(row: Tariftype): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === TARIFTYPE_STATUS.ARCHIVED) {
      cls.push("table-secondary");
    }
    if (row.flags.includes(TARIFTYPE_ALARM.NO_CURRENT)) {
      cls.push("table-danger");
      return cls;
    }
    if (row.flags.includes(TARIFTYPE_ALARM.DATE_PUBLICATION)) {
      cls.push("table-warning");
      return cls;
    }
    if (row.flags.includes(TARIFTYPE_ALARM.DATE_VALIDATION)) {
      cls.push("text-warning");
      return cls;
    }
    if (row.flags.includes(TARIFTYPE_ALARM.DATE_PREPARATION)) {
      cls.push("text-warning");
      return cls;
    }

    return cls;
  }
}
