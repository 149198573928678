
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TarifService} from "./tarif.service";
import { Tarif } from "./tarif";

@Injectable({
  providedIn: 'root'
})
export class TarifResolver  {
  constructor(private _rs: TarifService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tarif> {
    return this._rs.fetch(+route.params["tarifId"])
  }
}
