import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferNotification } from "../offer-notification";
import { Offer } from "../../offer/offer";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { OFFERNOTIFICATION_TYPES } from "../offer-notification.base";
import { RicheditComponent } from "@solidev/data";
import { OfferActionService } from "../../offer/offer-action.service";

@Component({
  selector: "lvadg-offer-notification-create",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RicheditComponent],
  templateUrl: "./offer-notification-create.component.pug",
  styleUrls: ["./offer-notification-create.component.sass"],
})
export class OfferNotificationCreateComponent implements OnInit {
  @Output() public created = new EventEmitter<OfferNotification>();
  @Output() public cancelled = new EventEmitter<void>();
  @Input() public offer?: Offer;
  public readonly OTN = OFFERNOTIFICATION_TYPES;
  public form = new FormGroup({
    type: new FormControl(OFFERNOTIFICATION_TYPES.PUBLICATION, [
      Validators.required,
    ]),
    title: new FormControl("", [Validators.required]),
    message: new FormControl("", [Validators.required]),
  });

  constructor(private _oa: OfferActionService) {}

  public ngOnInit() {
    this.form.patchValue({
      title: `${this.offer?.title} - ${this.offer?.storage_details?.name}`,
      message: `<p>Bonjour,</p>
      <p>Vous trouverez ci-dessous les informations concernant notre offre</p>
      <p>Cordialement</p>
      <p>${this.offer?.author_details?.last_name} ${this.offer?.author_details?.first_name}</p>`,
    });
  }

  public async createNotification() {
    if (!this.offer) return;

    const res = await this._oa.action<OfferNotification>(this.offer, {
      action: "create_notification",
      type: this.form.value.type || OFFERNOTIFICATION_TYPES.PUBLICATION,
      title: this.form.value.title || this.offer.title,
      message: this.form.value.message || "",
    });
    if (res.success) {
      this.created.emit(res.data);
    }
  }
}
