
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-tariftype-metadata-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-metadata-display.component.pug',
  styleUrls: ['./tariftype-metadata-display.component.sass']
})
export class TariftypeMetadataDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
