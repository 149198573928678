import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { combineLatest, debounceTime, Observable } from 'rxjs';
import { Doc } from '../doc';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-doctoc-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './doctoc-display.component.pug',
  styleUrls: ['./doctoc-display.component.sass'],
})
export class DoctocDisplayComponent implements OnInit {
  @Input() public doc$!: Observable<Doc>;
  public current: string | null = null;

  constructor(
    private _route: ActivatedRoute,
    private _scroll: ViewportScroller
  ) {}

  public ngOnInit() {
    combineLatest([this.doc$, this._route.fragment])
      .pipe(debounceTime(250))
      .subscribe(([doc, f]) => {
        this.current = f;
        if (f) {
          this._scroll.scrollToAnchor(f);
        } else {
          this._scroll.scrollToPosition([0, 0]);
        }
      });
  }
}
