
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngIf="ofcMode==='status'">
      <h3>Changement d'état</h3>
      <ng-container *ngIf="selected?.status !== STT.IN_TARIF">
        <p class="text-danger" *ngIf="!(selected?.article_details)">Pour cibler l'article, vous devez lui associer un article catalogue</p>
        <ul class="list-group">
          <li class="list-group-item list-group-item-danger" *ngIf="!(selected?.article_details)">Ciblé pour tarif</li>
          <li class="list-group-item list-group-item-action" role="button" *ngIf="selected?.article_details" (click)="setStatusAction(selected, STT.TARGETTED)" [class.list-group-item-success]="selected?.status===STT.TARGETTED">Ciblé pour tarif</li>
          <li class="list-group-item list-group-item-action" role="button" (click)="setStatusAction(selected, STT.PROBLEM)" [class.list-group-item-success]="selected?.status===STT.PROBLEM">Problème à régler</li>
          <li class="list-group-item list-group-item-action" role="button" (click)="setStatusAction(selected, STT.INSIGNIFIANT)" [class.list-group-item-success]="selected?.status===STT.INSIGNIFIANT">Non significatif</li>
          <li class="list-group-item list-group-item-action" role="button" (click)="setStatusAction(selected, STT.NOT_PROCESSED)" [class.list-group-item-success]="selected?.status===STT.NOT_PROCESSED">Non traité</li>
          <li class="list-group-item list-group-item-action" role="button" (click)="setStatusAction(selected, STT.OTHER)" [class.list-group-item-success]="selected?.status===STT.OTHER">Autre</li>
        </ul>
      </ng-container>
      <p class="text-danger" *ngIf="selected?.status === STT.IN_TARIF">L'article est dans le tarif et son état ne peut plus être changé</p>
    </ng-container>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.flags}}</td>
        </ng-container>
        <ng-container cdkColumnDef="external">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="external" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.external}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tariftype">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tariftype" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.tariftype}}</td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><span role="button" (click)="ofc(row, 'status', {reload: true})">{{row.status|pchoice:row:'status'}}</span></td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.article_details">
              <lvadg-article-display [article]="row.article_details" mode="line" [detail_route]="article_detail_route"></lvadg-article-display>
            </ng-container>
            <ng-container *ngIf="!row.article_details &amp;&amp; haveAction('article_add')">
              <button class="btn btn-outline-primary btn-sm" (click)="action.emit({action: 'article_add', model: row})"><i class="bi bi-plus-circle me-2"></i>Article</button>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_family">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_family"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-family-display [family]="row.article_family" mode="line"></lvadg-family-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_code" [sort]="true" sortField="article__code"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_calibre">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_calibre"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_calibre}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_categorie">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_categorie"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_categorie}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_origine">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_origine"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_origine}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_libelle" [sort]="true" sortField="article__libelle"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_marque">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_marque"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_marque?.code || row.article_marque?.name || ''}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_uf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_uf" [sort]="true" sortField="article__uf"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_uf}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_uci">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_uci"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_uci}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_colisage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_colisage"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_colisage}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_labels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_labels"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let l of row.article_labels">
              <lvadg-label-display [label]="l" mode="small"></lvadg-label-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_local">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_local"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_local}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_decondi_mode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_decondi_mode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_decondi_mode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-earticle-display [earticle]="row.earticle_details" mode="line" [detail_route]="earticle_detail_route"></lvadg-earticle-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_code" [sort]="true" sortField="external__code"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.earticle_code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_icode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_icode" [sort]="true" sortField="external__icode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.earticle_icode}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_gamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_gamme"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_gamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_sgamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_sgamme"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_sgamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_libelle" [sort]="true" sortField="external__libelle"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_libelle2">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_libelle2" [sort]="true" sortField="external__libelle2"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_libelle2}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_categorie">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_categorie"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_categorie}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_calibre">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_calibre"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_calibre}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_origine">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_origine" [sort]="true" sortField="external__origine"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_origine}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_labels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_labels"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_labels}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_uf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_uf" [sort]="true" sortField="external__uf"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_uf}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_uc">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_uc"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_uc}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_ucond">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_ucond"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_ucond}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_ucuf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_ucuf"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_ucuf}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_nego_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_nego_unit"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_nego_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="actions"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="!row.article_details &amp;&amp; haveAction('article_add')">
              <button class="btn btn-outline-primary btn-sm me-1" (click)="action.emit({action: 'article_add', model: row})"><i class="bi bi-plus-circle me-2"></i>Article</button>
            </ng-container>
            <ng-container *ngIf="row.article_details &amp;&amp; haveAction('article_add')">
              <button class="btn btn-outline-warning btn-sm me-1" (click)="action.emit({action: 'article_add', model: row})"><i class="bi bi-plus-circle me-2"></i>Article</button>
            </ng-container>
            <ng-container *ngIf="row.article_details &amp;&amp; haveAction('article_remove')">
              <button class="btn btn-outline-danger btn-sm me-1" (click)="action.emit({action: 'article_remove', model: row})"><i class="bi bi-trash me-2"></i>Article</button>
            </ng-container>
            <ng-container *ngIf="!row.article_details &amp;&amp; haveAction('sdelete')">
              <data-safe-delete class="me-1" [model]="row" (deleted)="reload?.next(true)" display="button"></data-safe-delete>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_ucond_string">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_ucond_string"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_ucond_string}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_ucond_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_ucond_unit"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_ucond_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_ucond_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_ucond_value"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_ucond_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="earticle_marque">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="earticle_marque"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.earticle_marque}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tariftype_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tariftype_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-tariftype-display [tariftype]="row.tariftype_details" mode="line" [detail_route]="tariftype_detail_route"></lvadg-tariftype-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'short'}}</td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>