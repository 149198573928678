import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterDefaults, Link } from "@solidev/data";
import { map, Observable } from "rxjs";
import { RouterLink } from "@angular/router";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { OffertypeStorageService } from "../offertype-storage.service";
import { OffertypeStorage } from "../offertype-storage";
import { FlagsDisplayComponent } from "../../../../components/utils/flags-display/flags-display.component";
import {
  OFFERTYPE_FLAGS_HUMANIZED,
  OFFERTYPE_TYPES,
} from "../../offertype/offertype.base";
import { AuthService } from "../../../users/auth.service";
import { User } from "../../../users/user/user";
import { LVADG_GROUPS } from "../../../../constants";
import { OFFERTYPE_STORAGE_USER_LEVEL } from "../offertype-storage.base";

@Component({
  selector: "lvadg-offertype-storage-shortlist",
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent, FlagsDisplayComponent],
  templateUrl: "./offertype-storage-shortlist.component.pug",
  styleUrls: ["./offertype-storage-shortlist.component.sass"],
})
export class OffertypeStorageShortlistComponent implements OnInit {
  @HostBinding("class") public class = "card h-100";
  @Input() public display: "card" | "buttons" = "card";
  @Input() public title = "Offres par dépôt";
  @Input() public ots_detail_routes: {
    [key in OFFERTYPE_TYPES]?: Link<OffertypeStorage>;
  } = {};
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults> = {};
  public readonly OFFERTYPE_FLAGS_HUMANIZED = OFFERTYPE_FLAGS_HUMANIZED;
  public readonly OTS_PERMS = OFFERTYPE_STORAGE_USER_LEVEL;
  public ots$!: Observable<OffertypeStorage[]>;
  public dispFlags$!: Observable<boolean>;
  protected readonly OFFERTYPE_TYPES = OFFERTYPE_TYPES;

  constructor(
    private _ots: OffertypeStorageService,
    private _auth: AuthService,
  ) {}

  public ngOnInit(): void {
    this.dispFlags$ = this._auth.currentUser$.pipe(
      map(
        (user: User | null) =>
          user?.is_superuser ||
          user?.is_staff ||
          user?.have_group(LVADG_GROUPS.members) ||
          false,
      ),
    );
    if (this.display === "buttons") {
      this.class = "";
    }
    if (this.filter instanceof Observable) {
      this.filter.subscribe((filter) => {
        this.ots$ = this._ots.list({
          page_size: 100,
          fields: [
            "id",
            "offertype_details",
            "storage_details",
            "flags",
            "user_level",
          ].join(","),
          ordering: "-created",
          ...filter,
        });
      });
    } else {
      this.ots$ = this._ots.list({
        page_size: 100,
        fields: ["id", "offertype_details", "storage_details", "flags"].join(
          ",",
        ),
        ordering: "-created",
        ...this.filter,
      });
    }
  }
}
