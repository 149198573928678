import { RouteConfigItem } from '@solidev/data';

export interface TARIFTYPE_ROUTES {
  tarifs_detail_route?: RouteConfigItem;
  tariftype_documents_route?: RouteConfigItem;
  tariftype_catalog_route?: RouteConfigItem;
  tariftype_manage_route?: RouteConfigItem;
  tariftype_notification_logs_route?: RouteConfigItem;
  tariftype_mappings_route?: RouteConfigItem;
  tariftype_storages_route?: RouteConfigItem;
  tariftype_regions_route?: RouteConfigItem;
  tariftype_notifications_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  tariftype_permissions_route?: RouteConfigItem;
  tariftype_atts_route?: RouteConfigItem;
  tariftype_relations_route?: RouteConfigItem;
  tariftype_destinations_route?: RouteConfigItem;
  tariftype_prices_logs_route?: RouteConfigItem;
  tariftype_tarifs_logs_route?: RouteConfigItem;
  tariftype_lifecycle_route?: RouteConfigItem;
}
