
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-flags-display [item]="row"></lvadg-flags-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'short'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'short'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="seasons">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="seasons" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-seasons-manage [seasons]="row.seasons" [per_line]="6" [zone]="row.type"></lvadg-seasons-manage>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="logo">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="logo" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.logo}}</td>
        </ng-container>
        <ng-container cdkColumnDef="clogo">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="clogo" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.clogo}}</td>
        </ng-container>
        <ng-container cdkColumnDef="vvid">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="vvid" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.vvid}}</td>
        </ng-container>
        <ng-container cdkColumnDef="family">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="family" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.family}}</td>
        </ng-container>
        <ng-container cdkColumnDef="code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="code" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="type"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.type|pchoice:row:'type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold text-decoration-none" [routerLink]="detailRoute(row)">{{row.name}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="libelle" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold text-decoration-none" [routerLink]="detailRoute(row)">{{row.libelle}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="libelle_auto">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="libelle_auto" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.libelle_auto}}</td>
        </ng-container>
        <ng-container cdkColumnDef="scname">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="scname" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.scname}}</td>
        </ng-container>
        <ng-container cdkColumnDef="production">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="production" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.production}}</td>
        </ng-container>
        <ng-container cdkColumnDef="calibre">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="calibre" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.calibre}}</td>
        </ng-container>
        <ng-container cdkColumnDef="categorie">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="categorie" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.categorie}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ciqual">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ciqual" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ciqual}}</td>
        </ng-container>
        <ng-container cdkColumnDef="colisage_cond">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="colisage_cond" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.colisage_cond}}</td>
        </ng-container>
        <ng-container cdkColumnDef="colisage_nombre">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="colisage_nombre" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:'colisage_nombre'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="colisage_poids">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="colisage_poids" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:'colisage_poids'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="colisage_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="colisage_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:"colisage_unit"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="dlc_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="dlc_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.dlc_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="dlc_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="dlc_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.dlc_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ddm_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ddm_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ddm_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ddm_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ddm_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ddm_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="fournisseur">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="fournisseur" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.fournisseur}}</td>
        </ng-container>
        <ng-container cdkColumnDef="local">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="local" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.local}}</td>
        </ng-container>
        <ng-container cdkColumnDef="marque">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="marque" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.marque}}</td>
        </ng-container>
        <ng-container cdkColumnDef="origine">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="origine" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.origine}}</td>
        </ng-container>
        <ng-container cdkColumnDef="origine_glob">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="origine_glob" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.origine_glob}}</td>
        </ng-container>
        <ng-container cdkColumnDef="egalim_status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="egalim_status"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'egalim_status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="particularite">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="particularite" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.particularite}}</td>
        </ng-container>
        <ng-container cdkColumnDef="poids_min">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="poids_min" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:"poids_min"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="poids_moyen">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="poids_moyen" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:"poids_moyen"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="poids_max">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="poids_max" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:"poids_max"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="presentation">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="presentation" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.presentation}}</td>
        </ng-container>
        <ng-container cdkColumnDef="process">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="process" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.process}}</td>
        </ng-container>
        <ng-container cdkColumnDef="recette">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="recette" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.recette}}</td>
        </ng-container>
        <ng-container cdkColumnDef="region">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="region" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.region}}</td>
        </ng-container>
        <ng-container cdkColumnDef="rnm_code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="rnm_code" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.rnm_code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="temperature">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="temperature" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.temperature}}</td>
        </ng-container>
        <ng-container cdkColumnDef="traitement">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="traitement" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.traitement}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uci_cond">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uci_cond" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uci_cond}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uci_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uci_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:'uci_value'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uci_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uci_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uci_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="udf_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="udf_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'udf_unit'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="url">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="url" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.url}}</td>
        </ng-container>
        <ng-container cdkColumnDef="udv_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="udv_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|factor:'udv_value'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="udv_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="udv_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'udv_unit'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="usage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="usage" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.usage}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="images">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="images" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.images}}</td>
        </ng-container>
        <ng-container cdkColumnDef="allergenes">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="allergenes" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.allergenes}}</td>
        </ng-container>
        <ng-container cdkColumnDef="egalim">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="egalim" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.egalim}}</td>
        </ng-container>
        <ng-container cdkColumnDef="logo_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="logo_details" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-image-display *ngIf="row.logo_details" [image]="row.logo_details" mode="list"></lvadg-image-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="clogo_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="clogo_details" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-image-display *ngIf="row.clogo_details" [image]="row.clogo_details" mode="list-image" [thumbnail]="false"></lvadg-image-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="calibre_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="calibre_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.calibre_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="production_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="production_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.production_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="allergenes_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="allergenes_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let a of row.allergenes_details"><span class="me-2">{{a|choice:"type"}} {{a.code}}</span></ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="categorie_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="categorie_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.categorie_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="family_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="family_details">
              <lvadg-family-display-list-header [display]="familyDisplay()" (displayChange)="familyDisplay.set($event)"></lvadg-family-display-list-header>
            </data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-family-display [family]="row.family_details" mode="line" [detail_route]="family_detail_route" [start]="familyDisplay().start" [end]="familyDisplay().end"></lvadg-family-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="egalim_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="egalim_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-label-display [label]="e" *ngFor="let e of row.egalim_details" mode="list-medium" [thumbnail]="false"></lvadg-label-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="marque_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="marque_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.marque_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="origine_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="origine_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.origine_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="particularite_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="particularite_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.particularite_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="presentation_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="presentation_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.presentation_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="fournisseur_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="fournisseur_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-provider-display [provider]="row.fournisseur_details" mode="line" [detail_route]="provider_detail_route"></lvadg-provider-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="process_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="process_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.process_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="traitement_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="traitement_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.traitement_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="region_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="region_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.region_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="colisage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="colisage"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.colisage}}</td>
        </ng-container>
        <ng-container cdkColumnDef="colisage_cond_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="colisage_cond_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.colisage_cond_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uci_cond_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uci_cond_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uci_cond_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="decondi_mode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="decondi_mode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.decondi_mode|pchoice:row:'decondi_mode'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="usage_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="usage_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.usage_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uci">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uci"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uci}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="actions"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <data-safe-delete *ngIf="haveAction('delete')" [model]="row" [service]="coll" display="icon" title="Supprimer ?" (deleted)="reload!.next(true)"></data-safe-delete>
            <button class="btn btn-outline-primary btn-sm mx-2" *ngIf="haveAction('tariftype_add') &amp;&amp; row.in_tariftype!=true" (click)="action.next({model: row, action: 'tariftype_add'})"><i class="bi bi-plus-circle me-2"></i>tarif</button>
            <button class="btn btn-outline-primary btn-sm mx-2" *ngIf="haveAction('earticle_add')" (click)="action.next({model: row, action: 'earticle_add'})"><i class="bi bi-plus-circle me-2"></i>article</button>
            <button class="btn btn-outline-primary btn-sm mx-2" *ngIf="haveAction('select')" (click)="action.next({model: row, action: 'select'})">Sélectionner</button>
            <button class="btn btn-outline-primary btn-sm mx-2" *ngIf="haveAction('selectOp') &amp;&amp; !row.in_op" (click)="action.next({model: row, action: 'selectOp'})">Sélectionner</button>
            <lvadg-quickprint-button *ngIf="haveAction('print_fiche')" [model]="row" [print_settings]="print_settings" [print_context]="print_context" [list]="list" [filter]="filter" [template]="'article_detail'" [title]="'Impression fiche article'">
              <lvadg-icon class="px-2" ri="fiche" [icon_only]="true"></lvadg-icon>
            </lvadg-quickprint-button>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="in_tariftype">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="in_tariftype"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><i class="bi bi-check-lg me-2" *ngIf="row.in_tariftype===true"></i></td>
        </ng-container>
        <ng-container cdkColumnDef="in_eamapping">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="in_eamapping"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><i class="bi bi-check-lg me-2" *ngIf="row.in_eamapping===true"></i></td>
        </ng-container>
        <ng-container cdkColumnDef="in_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="in_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.in_op? 'Oui' : 'Non'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ciqual_alim">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ciqual_alim"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.ciqual_alim}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="ciqual_alim_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ciqual_alim_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <p class="text-info">Voir détail article</p>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="gamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="gamme"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.gamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="dmode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="dmode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.dmode|pchoice:row:'dmode'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="select">
          <th cdk-header-cell *cdkHeaderCellDef>
            <lvadg-select-header [selection]="selection" [list]="list" [filter]="filter" [filter_filter]="print_filters_whitelist()" [print_context]="print_context" [print_settings]="print_settings"></lvadg-select-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-select-item [selection]="selection" [item]="row"></lvadg-select-item>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="similar_count">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="similar_count"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.similar_count}}</td>
        </ng-container>
        <ng-container cdkColumnDef="score">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="score" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.score}}</td>
        </ng-container>
        <ng-container cdkColumnDef="iocode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="iocode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-iocode-display [iocode]="row.iocode" mode="link"></lvadg-iocode-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="providerdocuments_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="providerdocuments_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div *ngFor="let sl of row.providerdocuments_details">
              <lvadg-provider-document-display class="mx-1" [providerdocument]="sl" mode="line"></lvadg-provider-document-display>
            </div>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="articledocuments_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="articledocuments_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div *ngFor="let sl of row.articledocuments_details">
              <lvadg-article-document-display class="mx-1" [articledocument]="sl" mode="line"></lvadg-article-document-display>
            </div>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>