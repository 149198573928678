import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { PrintDocument } from './print-document';

@Injectable({
  providedIn: 'root',
})
export class PrintDocumentService extends Collection<PrintDocument> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/tarifdocuments', PrintDocument);
  }
}
