import { Component } from '@angular/core';

import { CategoryListViewData } from './category-list-view.component.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Category } from '../../../../../models/catalog/meta/category/category';
import { CategoryListComponent } from '../../../../../models/catalog/meta/category/category-list/category-list.component';
import { Subject } from 'rxjs';
import { AllergenListViewData } from '../../allergen/list/allergen-list-view.component.params';
import { CategoryCreateComponent } from '../../../../../models/catalog/meta/category/category-create/category-create.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';

@Component({
  selector: 'lvadg-category-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    CategoryListComponent,
    CategoryCreateComponent,
    IconComponent,
    HeaderActionComponent,
  ],
  templateUrl: './category-list-view.component.pug',
  styleUrls: ['./category-list-view.component.sass'],
})
export class CategoryListViewComponent extends BaseListViewComponent<CategoryListViewData, Category> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
