import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnmmarche } from '../rnmmarche';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-rnmmarche-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './rnmmarche-manage.component.pug',
  styleUrls: ['./rnmmarche-manage.component.sass'],
})
export class RnmmarcheManageComponent implements OnInit {
  @Input() public rnmmarche?: Rnmmarche;

  constructor() {}

  ngOnInit(): void {}
}
