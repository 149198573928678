import { charField, DataModel, datetimeField, detailsField, foreignKeyField, primaryField } from '@solidev/data';

export enum OrderProcessUserLevel {
  ANONYMOUS = 'anon',
  SUPERADMIN = 'sadmin',
  OWNER = 'owner',
  WRITE = 'write',
  READ = 'read',
}

export class OrderprocessBase extends DataModel {
  static override readonly __name: string = 'OrderprocessBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 300,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 200,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'name',
    description: 'Nom du process',
    maxLength: 200,
    priority: 900,
  })
  public name!: string;

  @charField({
    name: 'code',
    description: 'Code du process',
    maxLength: 10,
    pattern: /PC[0-9]{3}/,
    priority: 950,
  })
  public code?: string;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'Tariftype',
    required: false,
    priority: -100,
  })
  public tariftype?: number;

  @detailsField({
    name: 'params',
    description: 'Paramètres du process',
    defaultValue: {},
    priority: -1,
  })
  public params: any = {};

  @charField({
    name: 'delivery_dates_rrule',
    description: 'Règles de récurrence des dates de livraison',
    maxLength: 200,
    required: false,
    priority: 450,
  })
  public delivery_dates_rrule?: string;

  @detailsField({
    name: 'user_level',
    description: 'Mes permissions',
    deserialize: (d) => d,
    priority: -2,
  })
  public user_level: OrderProcessUserLevel[] = [];

  public get _display(): string {
    return `[${this.code}] ${this.name}`;
  }

  public have_level(level: OrderProcessUserLevel): boolean {
    return this.user_level.indexOf(level) !== -1;
  }
}
