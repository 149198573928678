import {
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
} from "@angular/core";
import { LabelService } from "../label.service";
import { LABEL_TYPE } from "../label.base";
import { firstValueFrom } from "rxjs";
import { Label } from "../label";
import { LabelDisplayComponent } from "../label-display/label-display.component";
import { PRODUCT_TYPES } from "../../product/product.base";
import { DataMessageService, DataModel } from "@solidev/data";

interface WithLabels extends DataModel {
  labels?: number[];
  egalim?: number[];
  slabels?: number[];
  plabels?: number[];
}

type LabelSource = "labels" | "egalim" | "slabels" | "plabels";

interface LabelStatus {
  label: Label;
  status: boolean;
}

/**
 * Display and manage labels for a given item.
 */
@Component({
  selector: "lvadg-labels-manager",
  standalone: true,
  imports: [LabelDisplayComponent],
  template: `
    <div
      class="d-flex flex-row align-content-center justify-content-start flex-wrap"
    >
      @for (status of label_statuses(); track status.label.id) {
        @if (status.status) {
          <button
            class="btn btn-primary m-1 label-btn"
            [title]="status.label.name"
            (click)="clicked(status)"
            [disabled]="mode() === 'view'"
          >
            <lvadg-label-display
              mode="small"
              [label]="status.label"
              [hover]="false"
              class="me-1"
            />
            {{ status.label.code }}
          </button>
        } @else {
          <button
            class="btn btn-secondary m-1 label-btn"
            [title]="status.label.name"
            (click)="clicked(status)"
            [disabled]="mode() === 'view'"
          >
            <lvadg-label-display
              mode="small"
              [label]="status.label"
              [hover]="false"
              class="me-1"
            />
            {{ status.label.code }}
          </button>
        }
      }
    </div>
  `,
  styleUrl: "./labels-manager.component.sass",
})
export class LabelsManagerComponent {
  /** Item to manage labels for. Required. */
  public item = input.required<WithLabels>();
  /** Mode of the component : view or edit */
  public mode = input<"edit" | "view">("edit");
  /** Type of labels to manage. Eventually inferred from existing labels */
  public type = input<
    LABEL_TYPE[] | LABEL_TYPE | PRODUCT_TYPES | PRODUCT_TYPES[] | null
  >(null);
  public catalog_only = input(false);
  /** Source of labels to manage. Eventually inferred from existing field if unique */
  public source = input<LabelSource | null>(null);
  public save = input(false);
  /** List of labels for the item */
  public labels = computed(() => {
    // Get the label source.
    const source = this.source();
    const item = this.item();
    if (source !== null) {
      return item[source] || [];
    } else {
      for (const field of ["labels", "egalims", "slabels", "plabels"]) {
        if (
          item[field as LabelSource] &&
          item[field as LabelSource]!.length > 0
        ) {
          return item[field as LabelSource] || [];
        }
      }
    }
    console.error("No label source found for item", item);
    return [];
  });

  /** List of label statuses */
  public label_statuses = signal<LabelStatus[]>([]);

  /** Ids of the current labels */
  private current_labels = signal<number[]>([]);

  /** Current label types */
  private _curtypes = computed(() => {
    const in_type = this.type();
    const out_types: LABEL_TYPE[] = [];
    let types: (LABEL_TYPE | PRODUCT_TYPES)[] = [];

    if (in_type !== null) {
      if (!Array.isArray(in_type)) {
        types = [in_type];
      } else {
        types = in_type;
      }
      for (const t of types) {
        if (Object.values(LABEL_TYPE).includes(t as LABEL_TYPE)) {
          out_types.push(t as LABEL_TYPE);
        } else {
          console.log(
            "Product type",
            t,
            "not found",
            t in LABEL_TYPE,
            t in PRODUCT_TYPES,
          );
          switch (t) {
            case PRODUCT_TYPES.LVADG_FL:
              out_types.push(LABEL_TYPE.FL);
              break;
            case PRODUCT_TYPES.LVADG_SEA:
              out_types.push(LABEL_TYPE.SEA);
              break;
          }
        }
      }
      console.log("Out types", out_types, in_type, types);
      return out_types;
    }
    return null;
  });

  private _labels = inject(LabelService);
  private _msgs = inject(DataMessageService);

  constructor() {
    // Update the label statuses when the item or the labels change.
    effect(
      async () => {
        // Get the label types.
        let types = this._curtypes();
        const labels = this.labels();
        if (types === null) {
          const labels = this.labels();
          if (labels.length > 0) {
            const label = await firstValueFrom(this._labels.get(labels[0]));
            if (label) {
              types = [label.type];
            }
          }
        }
        if (types === null) {
          console.error("No label type found for item", this.item());
          this.label_statuses.set([] as LabelStatus[]);
          return;
        }
        // Get the labels of the given type.
        const available = await firstValueFrom(this._labels.byTypes(...types));
        const out: LabelStatus[] = [];
        for (const label of available) {
          // Filter out catalog only labels if needed.
          if (!this.catalog_only() && label.catalog_only) {
            continue;
          }
          out.push({
            label,
            status: labels.includes(label.id),
          });
        }
        this.current_labels.set([]);
        this.label_statuses.set(out);
      },
      { allowSignalWrites: true },
    );
  }

  public async clicked(status: LabelStatus) {
    if (this.mode() == "view") {
      this._msgs.warning("Édition désactivée");
      return;
    }
    if (this.save() && this.source()) {
      const labels = this.labels();
      const idx = labels.indexOf(status.label.id);
      if (idx === -1) {
        labels.push(status.label.id);
      } else {
        labels.splice(idx, 1);
      }
      this.item().setFV(this.source()!, labels);
      const out: LabelStatus[] = [];
      for (const s of this.label_statuses()) {
        out.push({
          label: s.label,
          status: labels.includes(s.label.id),
        });
      }
      this.label_statuses.set(out);
      await firstValueFrom(
        this.item().update([this.source()!], { updateModel: true }),
      );
      this._msgs.success("Labels mis à jour");
    }
  }
}
