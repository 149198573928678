import { Component } from '@angular/core';

import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { TarifLifecycleStepListComponent } from '../../../../models/tarifs/tarif-lifecycle-step/tarif-lifecycle-step-list/tarif-lifecycle-step-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TarifLifecycleStep } from '../../../../models/tarifs/tarif-lifecycle-step/tarif-lifecycle-step';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface TarifLifecycleStepListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface TarifLifecycleStepListViewData extends TarifLifecycleStepListViewParams {}

@Component({
  selector: 'lvadg-tarif-lifecycle-step-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifLifecycleStepListComponent],
  templateUrl: './tarif-lifecycle-step-list-view.component.pug',
  styleUrls: ['./tarif-lifecycle-step-list-view.component.sass'],
})
export class TarifLifecycleStepListViewComponent extends BaseListViewComponent<
  TarifLifecycleStepListViewData,
  TarifLifecycleStep
> {
  public override setRouteData(data: TarifLifecycleStepListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
