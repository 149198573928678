
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { FolderPermission } from "./folder-permission";

@Injectable({
  providedIn: 'root'
})
export class FolderPermissionService extends Collection<FolderPermission> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/folderpermissions", FolderPermission);
  }
}
