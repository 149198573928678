import { TariftypeStorageBase } from './tariftype-storage.base';
import { detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { Storage } from '../../structures/storage/storage';
import { TariftypeStoragePermission } from '../tariftype-storage-permission/tariftype-storage-permission';

export class TariftypeStorage extends TariftypeStorageBase {
  static override readonly __name: string = 'TariftypeStorage';

  @detailsField({
    model: TariftypeBase,
    readonly: true,
    description: 'Modèle de tarif',
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: Storage,
    readonly: true,
    description: 'Dépôt',
  })
  public storage_details?: Storage;

  @detailsField({
    model: TariftypeStoragePermission,
    description: 'Permissions',
    readonly: true,
    many: true,
  })
  public perms_details?: TariftypeStoragePermission[];
}
