import { Component } from '@angular/core';

import { OriginListViewData } from './origin-list-view.component.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { OriginListComponent } from '../../../../../models/catalog/meta/origin/origin-list/origin-list.component';
import { OriginCreateComponent } from '../../../../../models/catalog/meta/origin/origin-create/origin-create.component';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Origin } from '../../../../../models/catalog/meta/origin/origin';
import { Subject } from 'rxjs';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-origin-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    OriginListComponent,
    OriginCreateComponent,
    HeaderActionComponent,
    IconComponent,
  ],
  templateUrl: './origin-list-view.component.pug',
  styleUrls: ['./origin-list-view.component.sass'],
})
export class OriginListViewComponent extends BaseListViewComponent<OriginListViewData, Origin> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: OriginListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
