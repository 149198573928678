
<ng-container *ngIf="data$|async; let data">
  <ng-template #folderedit let-offcanvas>
    <div class="offcanvas-header" *ngIf="editedFolder">
      <h4 class="modal-title">{{editedFolder._display}}</h4>
      <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body" *ngIf="editedFolder">
      <div class="card my-2">
        <div class="card-header">Propriétés du dossier</div>
        <div class="card-body">
          <lvadg-folder-manage [folder]="editedFolder" (statusChanged)="editedFolderChanges.status=true" (detailsChanged)="editedFolderChanges.details=true" (folderMoved)="editedFolderChanges.moved=true"></lvadg-folder-manage>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-header">Permissions du dossier</div>
        <div class="card-body">
          <button class="btn btn-primary btn-sm w-100 my-2" (click)="createFolderPermission=!createFolderPermission">
            <lvadg-icon ri="add">Ajouter une permission</lvadg-icon>
          </button>
          <div class="card my-2" *ngIf="createFolderPermission">
            <div class="card-body">
              <lvadg-folder-permission-create (created)="editedFolderChanges.details=true; createFolderPermission=false; editedFolderReload$.next(true)" (cancelled)="createFolderPermission=false" [folder]="editedFolder"></lvadg-folder-permission-create>
            </div>
          </div>
          <lvadg-folder-permission-list [name]="data.route.name+'-edit-permissions'" [keep]="false" [filter]="{folder: editedFolder.id}" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [default_fields]="['user_details', 'group_details', 'permission', 'actions']" [actions]="['delete']" [reload]="editedFolderReload$" [dispFilters]="false" [dispHeaders]="false" [dispFieldsSelector]="false" [dispXlsxExport]="false" [dispPagination]="false"></lvadg-folder-permission-list>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-header">Logo du dossier</div>
        <div class="card-body">
          <lvadg-logo-manage [model]="editedFolder" mode="row" logo_field="image" logo_details_field="image_details" (updated)="editedFolderChanges.details=true"></lvadg-logo-manage>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <button class="btn btn-primary w-100 m-2" (click)="offcanvas.close()">Fermer</button>
    </div>
  </ng-template>
  <ng-template #documentedit let-offcanvas>
    <div class="offcanvas-header" *ngIf="editedDocument">
      <h4 class="modal-title">{{editedDocument.title}}</h4>
      <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body" *ngIf="editedDocument">
      <div class="card my-2">
        <div class="card-header">
          <data-safe-delete [model]="editedDocument" display="fbutton" (deleted)="editedDocumentChanges.deleted=true; offcanvas.close(); deletedDocument($event, data)"></data-safe-delete><span>Propriétés du document</span>
        </div>
        <div class="card-body">
          <lvadg-document-manage [document]="editedDocument"></lvadg-document-manage>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-header">Emplacements du document</div>
        <lvadg-document-folder-listmanage [document]="editedDocument"></lvadg-document-folder-listmanage>
      </div>
      <div class="card my-2">
        <div class="card-header">
          <button class="btn btn-sm btn-primary float-end" (click)="updatePreview(editedDocument)">
            <lvadg-icon ri="reload">Régénérer un aperçu</lvadg-icon>
          </button><span>Aperçu du document</span>
        </div>
        <div class="card-body" *ngIf="!updatingPreview">
          <lvadg-logo-manage [model]="editedDocument" mode="row" logo_field="image" logo_details_field="image_details" (updated)="editedDocumentChanges.details=true"></lvadg-logo-manage>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-header">Lien de partage</div>
        <div class="card-body">
          <pre class="todo">Lien de partage</pre>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <button class="btn btn-primary w-100 m-2" (click)="offcanvas.close()">Fermer</button>
    </div>
  </ng-template>
  <lvadg-header-action level="h2" ri="folder">
    <ng-container title>{{data.folder?._display||'racine'}}</ng-container>
    <ng-container subtitle>Détails
      <ng-container *ngIf="(user$|async)?.is_superuser">
        <lvadg-flags-display class="ms-2" [item]="data.folder" *ngIf="data.folder"></lvadg-flags-display>
      </ng-container>
    </ng-container>
    <ng-container actions>
      <data-safe-delete *ngIf="data.folder &amp;&amp; data.folder.have_level(FUL.sadmin)" [model]="data.folder" display="button" (deleted)="deletedFolder($event, data)"><span class="ms-1">Supprimer le dossier</span></data-safe-delete>
      <button class="btn btn-primary btn-sm" (click)="createSubFolder=!createSubFolder" *ngIf="data.folder &amp;&amp; data.folder.have_level(FUL.sadmin)">
        <lvadg-icon ri="add">Ajouter un sous-dossier</lvadg-icon>
      </button>
      <button class="btn btn-primary btn-sm" *ngIf="data.folder &amp;&amp; data.folder.have_level(FUL.sadmin)" (click)="clickedFolder(data.folder, data)">
        <lvadg-icon ri="edit">Modifier le dossier</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-todo>
    <div class="item">[ ] TODO: mode admin, ajouter résumé des droits sur dossier et parents</div>
    <div class="item">[ ] TODO: mode admin, ajouter vérification droits parents</div>
    <div class="item">[ ] TODO: mode admin, simplifier les statuts (online/offline uniquement)</div>
    <div class="item">[ ] TODO: mode non-admin + propriétaire : voir droits et boutons édition</div>
    <div class="item">[ ] TODO: tous modes, navigation via clics parents dans titre</div>
    <div class="item">[ ] TODO: tous modes, recherche tous documents</div>
    <div class="item">[ ] TODO: tous modes, bouton partage document (lien lvadg.io personnalisable)</div>
  </lvadg-todo>
  <div class="card my-3" *ngIf="createSubFolder">
    <div class="card-header">
      <lvadg-icon ri="add">Ajouter un sous-dossier</lvadg-icon>
    </div>
    <div class="card-body">
      <ng-container *ngIf="data$|async; let data">
        <lvadg-folder-create (created)="createdSubFolder($event)" (cancelled)="createSubFolder=false" [parent]="data.folder"></lvadg-folder-create>
      </ng-container>
    </div>
  </div>
  <lvadg-folder-content [folder$]="folder$" [reload]="reload$" [folder_detail_route]="folder_detail_route" (folder_click)="clickedFolder($event, data)" (document_click)="clickedDocument($event, data)"></lvadg-folder-content>
  <lvadg-document-create [folder]="data.folder" (uploaded)="createdDocument($event)" *ngIf="data.folder &amp;&amp; data.folder.have_level(FUL.owner)"></lvadg-document-create>
  <!--
  nav(ngbNav, #nav="ngbNav", [(activeId)] = "navId.current").nav-tabs
    ng-container(ngbNavItem="show")
      a(ngbNavLink) Détails
      ng-template(ngbNavContent)
        .tab-content
          lvadg-header-action([title]="data.folder?._display||'racine'",
            subtitle="Détails", level="h2",
            ri="folder")
            ng-container(actions)
              data-safe-delete(*ngIf="data.folder", [model]="data.folder",
                display="button", (deleted)="deletedFolder($event, data)") Supprimer le dossier
              button.btn.btn-primary.btn-sm((click)="createSubFolder=!createSubFolder")
                lvadg-icon(ri="add") Ajouter un sous-dossier
          .card.my-3(*ngIf="createSubFolder")
            .card-header
              lvadg-icon(ri="add") Ajouter un sous-dossier
            .card-body
              ng-container(*ngIf="data$|async; let data")
                lvadg-folder-create((created)="createdSubFolder($event)", (cancelled)="createSubFolder=false",
                  [parent]="data.folder")
          .row(*ngIf="data.folder")
            .col-6
              .card.h-100
                .card-body
                  lvadg-folder-manage([folder]="data.folder",
                    (statusChanged)="changedStatus($event, data)",
                    (detailsChanged)="changedDetails($event, data)",
                    (folderMoved)="movedFolder($event, data)")
            .col-6
              .card.h-100
                .card-body
                  lvadg-logo-manage([model]="data.folder",
                    mode="row",
                    logo_field="image", logo_details_field="image_details",
                    (updated)="changedDetails($event, data)")
          .card.mt-3
            .card-body
              lvadg-folder-content([folder$]="folder$",
                [reload]="reload$",
                [folder_detail_route]="folder_detail_route",
                (folder_click)="clickedFolder($event, data)",
                (document_click)="clickedDocument($event, data)",
              )
              lvadg-document-create([folder]="data.folder", (uploaded)="createdDocument($event)", *ngIf="data.folder")
  
    ng-container(ngbNavItem="permissions", *ngIf="data.folder")
      a(ngbNavLink) Permissions
      ng-template(ngbNavContent)
        .tab-content
          lvadg-header-action([title]="data.folder._display",
            subtitle="Permissions", level="h2",
            ri="folder")
          lvadg-folder-permission-list(
            [name]="data.route.name+'-permissions'",
            [reload]="reload$",
            [filter]="folderFilter$",
            [user_detail_route]="user_detail_route",
            [group_detail_route]="group_detail_route",
          )
  
    ng-container(ngbNavItem="documents", *ngIf="data.folder")
      a(ngbNavLink) Documents
      ng-template(ngbNavContent)
        .tab-content
          lvadg-header-action([title]="data.folder._display",
            subtitle="Documents", level="h2",
            ri="folder")
          lvadg-document-list(
            [name]="data.route.name",
            [reload]="reload$",
            [filter]="folderFilter$",
          )
    ng-container(ngbNavItem="subfolders")
      a(ngbNavLink) Sous-dossiers
      ng-template(ngbNavContent)
        .tab-content
          lvadg-header-action([title]="data.folder?._display||'racine'",
            subtitle="Sous-dossiers", level="h2",
            ri="folder")
          lvadg-folder-list(
            [name]="data.route.name+'-subfolders'",
            [reload]="reload$",
            [filter]="parentFilter$",
            [detail_route]="folder_detail_route",
          )
  
  div([ngbNavOutlet]="nav")
  -->
</ng-container>