
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OffertypeTextTypeService} from "./offertype-text-type.service";
import { OffertypeTextType } from "./offertype-text-type";

@Injectable({
  providedIn: 'root'
})
export class OffertypeTextTypeResolver implements Resolve<OffertypeTextType> {
  constructor(private _rs: OffertypeTextTypeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OffertypeTextType> {
    return this._rs.fetch(+route.params["offertypetexttypeId"])
  }
}
