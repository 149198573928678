
<ng-container *ngIf="folder">
  <dl class="row">
    <data-dispedit class="col-12 col-sm-6" [model]="folder" field="id" [editable]="false" mode="dd">ID Dossier</data-dispedit>
    <data-dispedit class="col-12 col-sm-6" [model]="folder" field="name" [editable]="true" mode="dd" (changed)="detailsChanged.emit(folder)">Nom</data-dispedit>
    <data-dispedit class="col-12 col-sm-6" [model]="folder" field="slug" [editable]="true" mode="dd" (changed)="detailsChanged.emit(folder)">Slug</data-dispedit>
    <data-dispedit class="col-12 col-sm-6" [model]="folder" field="zone" [editable]="false" mode="dd">Zone</data-dispedit>
    <data-dispedit class="col-12 col-sm-6" [model]="folder" field="type" [editable]="true" mode="dd" (changed)="detailsChanged.emit(folder)">Type</data-dispedit>
    <data-dispedit class="col-12 col-sm-6" [model]="folder" field="status" [editable]="true" mode="dd" (changed)="statusChanged.emit(folder)">Statut</data-dispedit>
    <div class="col-6">
      <dt>Chemin complet du dossier</dt>
      <dd>{{folder.full_path}}</dd>
    </div>
    <div class="col-6">
      <data-dispedit class="col-12" [model]="folder" field="parent" [editable]="true" mode="dd" [collection]="folders$" (changed)="refresh(); folderMoved.emit(folder)">Édition du parent</data-dispedit>
    </div>
    <data-flags class="col-12" [model]="folder">Flags</data-flags>
  </dl>
</ng-container>