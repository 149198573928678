
<ng-template #titleTpl>
  <ng-content select="[title]"></ng-content>
</ng-template>
<ng-template #subtitleTpl>
  <ng-content select="[subtitle]"></ng-content>
</ng-template>
<div class="header-action">
  <div class="title">
    <h1 *ngIf="level==='h1'">
      <lvadg-icon [ri]="ri" *ngIf="ri"></lvadg-icon>
      <lvadg-icon [icon]="icon" *ngIf="icon"></lvadg-icon>
      <ng-container *ngIf="title; else titleTpl">{{title}}</ng-container><small>
        <ng-container *ngIf="subtitle; else subtitleTpl">{{subtitle}}</ng-container></small>
    </h1>
    <h2 *ngIf="level==='h2'">
      <lvadg-icon [ri]="ri" *ngIf="ri"></lvadg-icon>
      <lvadg-icon [icon]="icon" *ngIf="icon"></lvadg-icon>
      <ng-container *ngIf="title; else titleTpl">{{title}}</ng-container><small>
        <ng-container *ngIf="subtitle; else subtitleTpl">{{subtitle}}</ng-container></small>
    </h2>
    <h3 *ngIf="level==='h3'">
      <lvadg-icon [ri]="ri" *ngIf="ri"></lvadg-icon>
      <lvadg-icon [icon]="icon" *ngIf="icon"></lvadg-icon>
      <ng-container *ngIf="title; else titleTpl">{{title}}</ng-container><small>
        <ng-container *ngIf="subtitle; else subtitleTpl">{{subtitle}}</ng-container></small>
    </h3>
  </div>
  <div class="actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>