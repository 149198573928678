
<ng-container *ngIf="data$|async; let data">
  <lvadg-product-nav [routes]="data.p_menu" [product]="data.product" [producer]="data.producer" [storage]="data.storage" *ngIf="data.p_menu"></lvadg-product-nav>
  <div class="container" *ngIf="producer$|async; let producer">
    <lvadg-header-action level="h1" ri="producer">
      <ng-container title><span>{{page_title()||data.product.producer_details?.name}}</span></ng-container>
      <ng-container subtitle><span>Fiche producteur</span></ng-container>
      <ng-container actions><a class="btn btn-outline- me-3" [routerLink]="producer_detail_route.route({producer: data.product.producer_details!})">Détails producteur</a>
        <lvadg-iocode-button class="me-3" [model]="producer">
          <lvadg-icon class="fs-3 px-1" role="button" ri="iocode" [icon_only]="true"></lvadg-icon>
        </lvadg-iocode-button>
        <lvadg-quickprint-button [model]="producer" [print_settings]="print_settings" [template]="'producer_poster'" [title]="'Impression affiche producteur'">
          <lvadg-icon class="fs-3 px-1" ri="poster" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
        <lvadg-quickprint-button [model]="producer" [print_settings]="print_settings" [template]="'producer_detail'" [title]="'Impression fiche producteur'">
          <lvadg-icon class="fs-3 px-1" ri="fiche" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
        <lvadg-quickprint-button [model]="producer" [print_settings]="print_settings" [template]="'producer_detail_products'" [title]="'Impression fiche producteur et produits'">
          <lvadg-icon class="fs-3 px-1" ri="fiches" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
      </ng-container>
    </lvadg-header-action>
    <lvadg-producer-details [producer]="producer" [producer_detail_route]="producer_detail_route" [storage_detail_route]="storage_detail_route"></lvadg-producer-details>
  </div>
</ng-container>