
<ng-container>
  <div class="row g-1 my-3">
    <div class="col-12 text-start">Sélectionner la priorité de l'envoi</div>
    <div class="col">
      <button class="btn btn-sm w-100" [class.btn-outline-primary]="messageForm.value.priority!==priorities.P1" [class.btn-primary]="messageForm.value.priority===priorities.P1" (click)="setPriority(priorities.P1)">Priorité 1</button>
    </div>
    <div class="col">
      <button class="btn btn-sm w-100" [class.btn-outline-primary]="messageForm.value.priority!==priorities.P2" [class.btn-primary]="messageForm.value.priority===priorities.P2" (click)="setPriority(priorities.P2)">Priorité 2</button>
    </div>
    <div class="col">
      <button class="btn btn-sm w-100" [class.btn-outline-primary]="messageForm.value.priority!==priorities.P3" [class.btn-primary]="messageForm.value.priority===priorities.P3" (click)="setPriority(priorities.P3)">Priorité 3</button>
    </div>
  </div>
</ng-container>
<!--ng-container-->
<!--  .row.g-1.my-1-->
<!--    .col-12.text-start Contenu automatique / enregistré-->
<!--    .col-->
<!--      button.btn.btn-sm.btn-outline-primary.w-100 Insérer les changements-->
<!--    .col-->
<!--      button.btn.btn-sm.btn-outline-primary.w-100((click)="useModel()") Utiliser mon modèle-->
<!--    .col-->
<!--      button.btn.btn-sm.btn-outline-primary.w-100 Enregistrer comme modèle-->
<ng-container>
  <div class="row">
    <div class="col-12 text-start">Titre du message</div>
    <div class="col-12">
      <input class="form-control" type="text" [formControl]="messageForm.controls.title">
    </div>
    <div class="col-12 text-start mt-2">Texte du message</div>
    <div class="col-12 text-start">
      <data-richedit [fc]="messageForm.controls.message" mode="inline" toolbar="light" [hideLabel]="true" [hideButton]="true"></data-richedit>
    </div>
  </div>
</ng-container>