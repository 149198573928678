import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarticleBase } from '../earticle.base';
import { Earticle } from '../earticle';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-earticle-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './earticle-display.component.pug',
  styleUrls: ['./earticle-display.component.sass'],
})
export class EarticleDisplayComponent implements OnInit {
  @Input() public earticle?: EarticleBase | Earticle;
  @Input() public mode: 'line' | 'button' = 'line';
  @Input() public detail_route?: Link<EarticleBase>;

  constructor() {}

  ngOnInit(): void {}
}
