import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { ArticleTarifTemplateRelation } from '../../../../models/tarifs/article-tarif-template-relation/article-tarif-template-relation';
import { ArticleTarifTemplateRelationManageComponent } from '../../../../models/tarifs/article-tarif-template-relation/article-tarif-template-relation-manage/article-tarif-template-relation-manage.component';
import { BaseRouteParams } from '@solidev/data';

export interface ArticleTarifTemplateRelationDetailViewParams extends BaseRouteParams {}

export interface ArticleTarifTemplateRelationDetailViewData extends ArticleTarifTemplateRelationDetailViewParams {
  articletariftemplaterelation: ArticleTarifTemplateRelation;
}

@Component({
  selector: 'lvadg-article-tarif-template-relation-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ArticleTarifTemplateRelationManageComponent],
  templateUrl: './article-tarif-template-relation-detail-view.component.pug',
  styleUrls: ['./article-tarif-template-relation-detail-view.component.sass'],
})
export class ArticleTarifTemplateRelationDetailViewComponent extends BaseDetailViewComponent<
  ArticleTarifTemplateRelationDetailViewData,
  ArticleTarifTemplateRelation
> {
  public override setRouteData(data: ArticleTarifTemplateRelationDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
