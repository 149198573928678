import { Component } from '@angular/core';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeMetadata } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata';
import { TariftypeMetadataListComponent } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata-list/tariftype-metadata-list.component';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface TariftypeMetadataListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface TariftypeMetadataListViewData extends TariftypeMetadataListViewParams {}

@Component({
  selector: 'lvadg-tariftype-metadata-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TariftypeMetadataListComponent],
  templateUrl: './tariftype-metadata-list-view.component.pug',
  styleUrls: ['./tariftype-metadata-list-view.component.sass'],
})
export class TariftypeMetadataListViewComponent extends BaseListViewComponent<
  TariftypeMetadataListViewData,
  TariftypeMetadata
> {
  public override setRouteData(data: TariftypeMetadataListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
