import { charField, DataModel, datetimeField, foreignKeyField, integerField, primaryField } from '@solidev/data';
import { ArticleTarifStatus, ArticleTarifStatusChoices } from '../article-tarif/article-tarif.base';

export class ArticleTarifItemBase extends DataModel {
  static override readonly __name: string = 'ArticleTarifItemBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({ name: 'created', description: 'created', readonly: true })
  public created!: Date;

  @datetimeField({ name: 'updated', description: 'updated', readonly: true })
  public updated!: Date;

  @foreignKeyField({
    name: 'articletarif',
    description: 'articletarif',
    related: 'ArticleTarif',
  })
  public articletarif!: number;

  @integerField({ name: 'price', description: 'price' })
  public price!: number;

  @integerField({
    name: 'available',
    description: 'available',
    defaultValue: 0,
  })
  public available: number = 0;

  @foreignKeyField({
    name: 'storage',
    description: 'storage',
    related: 'Storage',
  })
  public storage!: number;

  @foreignKeyField({
    name: 'region',
    description: 'region',
    related: 'Region',
  })
  public region!: number;

  @charField({
    name: 'status',
    description: 'status',
    defaultValue: 'TV',
    maxLength: 2,
    choices: ArticleTarifStatusChoices,
  })
  public status: ArticleTarifStatus = ArticleTarifStatus.TOVALIDATE;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
