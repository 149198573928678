import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import {
  TariftypeActionParams,
  TariftypeActionService,
} from '../../../../../models/tarifs/tarif/tariftype-action.service';
import { Subject } from 'rxjs';
import { NgbOffcanvas, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { TariftypeEarticleMappingsComponent } from '../../../../../models/tarifs/tariftype/tariftype-earticle-mappings/tariftype-earticle-mappings.component';
import { TARIFTYPE_USER_LEVEL, TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { TariftypeEarticlesImportComponent } from '../../../../../models/tarifs/tariftype/tariftype-earticles-import/tariftype-earticles-import.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { EarticleBase } from '../../../../../models/catalog/earticle/earticle.base';

export interface TariftypeMappingsViewParams extends BaseRouteParams {
  menu?: TARIFTYPE_ROUTES;
  article_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
}

export interface TariftypeMappingsViewData extends TariftypeMappingsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-mappings-view',
  standalone: true,
  imports: [
    CommonModule,
    NgbOffcanvasModule,
    HeaderActionComponent,
    IconComponent,
    TariftypeEarticleMappingsComponent,
    TariftypeEarticlesImportComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-mappings-view.component.pug',
  styleUrls: ['./tariftype-mappings-view.component.sass'],
})
export class TariftypeMappingsViewComponent extends BaseDetailViewComponent<TariftypeMappingsViewData, Tariftype> {
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;

  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  @ViewChild('specialop', { static: true })
  public ofcSlot!: ElementRef<HTMLDivElement>;
  public running: Record<string, boolean> = {};
  public current?: Tariftype;
  public TTUL = TARIFTYPE_USER_LEVEL;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private ofc: NgbOffcanvas,
    private ttAction: TariftypeActionService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeMappingsViewData) {
    super.setRouteData(data);
    this.current = data.tariftype;
    if (data.article_detail_route) {
      this.article_detail_route = new Link(data.article_detail_route, data, this._router);
    }
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(data.earticle_detail_route, data, this._router);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }

  public async ttActionProcess(tt: Tariftype, action: TariftypeActionParams) {
    await this.ttAction.action(tt, action);
    this.reload$.next(true);
  }

  public ofcOp(operation: string) {
    this.running[operation] = true;
    this.ofc.open(this.ofcSlot, { position: 'end', panelClass: 'w-50' }).result.then(
      () => {
        this.running[operation] = false;
      },
      () => {
        this.running[operation] = false;
      },
    );
  }
}
