import { Injectable } from "@angular/core";
import { Offertype } from "./offertype";
import { Offer } from "../offer/offer";
import { OpLog } from "../../../components/utils/oplog/oplog";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";
import { OFFERTYPE_PERMISSION } from "../offertype-permission/offertype-permission.base";
import { OFFERTYPESTORAGE_PERMISSION } from "../offertype-storage-permission/offertype-storage-permission.base";
import { OFFER_TYPES, OFFER_ZONES } from "../offer/offer.base";

interface OffertypeCreateOfferActionParams {
  action: "create_offer";
  datestart: string | Date;
  dateend: string | Date;
  storage: number; // pk du dépôt
  name: string; // nom de la nouvelle offre
  zone?: OFFER_ZONES; // zone de l'offre
  type?: OFFER_TYPES; // type de l'offre
  last?: number; // pk de l'offre source (pour copie)
}

interface OfferTypeAddPermissionActionParams {
  action: "add_permission";
  user: number | null; // pk de l'utilisateur
  group: number | null; // pk du groupe
  permission: OFFERTYPE_PERMISSION; // permission, défaut = WRITE
}

interface OfferTypeAddStorageActionParams {
  action: "add_storage";
  storage: number; // pk du dépôt
}

interface OfferTypeAddStoragePermissionActionParams {
  action: "add_storage_permission";
  otstorage: number; // pk de la liaison modèle / dépôt (OfferTypeStorage)
  user: number | null; // pk de l'utilisateur
  group: number | null; // pk du groupe
  permission: OFFERTYPESTORAGE_PERMISSION; // permission, défaut = WRITE
}

interface OfferTypeRemoveStoragePermissionActionParams {
  action: "remove_storage_permission";
  otstorage: number; // pk de la liaison modèle / dépôt (OfferTypeStorage)
  permission: number; // permission id
}

interface OfferTypeRemoveStorageActionParams {
  action: "remove_storage";
  otstorage: number; // pk de la liaison modèle / dépôt (OfferTypeStorage)
}

export type OffertypeActionParams =
  | OffertypeCreateOfferActionParams
  | OfferTypeAddPermissionActionParams
  | OfferTypeAddStorageActionParams
  | OfferTypeAddStoragePermissionActionParams
  | OfferTypeRemoveStoragePermissionActionParams
  | OfferTypeRemoveStorageActionParams;

export interface OffertypeActionResult<T> {
  offertype?: Offertype;
  offer?: Offer;
  log?: OpLog;
  data?: T;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: "root",
})
export class OffertypeActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action<T>(
    ot: Offertype,
    action: OffertypeActionParams,
    messages: Record<string, string> = {},
  ): Promise<OffertypeActionResult<T>> {
    try {
      const res = await firstValueFrom(
        ot.action<Offertype, OffertypeActionResult<T>>("POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { offertype: ot, message: message, success: false };
    }
  }
}
