import { inject, Injectable } from "@angular/core";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";
import { OpLog } from "../../../components/utils/oplog/oplog";
import { Producer } from "./producer";
import { ProducerService } from "./producer.service";
import { PRODUCER_TYPES } from "./producer.base";
import { StructureBaseAction } from "../structure-base-action";
import { Product } from "../../catalog/product/product";

interface ProducerCreateProductParams {
  action: "create_product";
  name: string;
  family: number;
  content_src: string;
  seasons: number[];
}

interface ProducerAddStorageParams {
  action: "add_storage";
  storage: number;
}

interface ProducerRemoveStorageParams {
  action: "remove_storage";
  storage: number;
}

// FIXME: find a way to extend StructureBaseAction

export type ProducerSpecificActionParams =
  | ProducerCreateProductParams
  | ProducerAddStorageParams
  | ProducerRemoveStorageParams;

interface ProducerSpecificActionResult<D> {
  data?: D;
  log: OpLog;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ProducerSpecificActionService {
  private _msgs = inject(DataMessageService);
  private _producers: ProducerService = inject(ProducerService);

  public async action(
    model: Producer,
    action: ProducerCreateProductParams,
    messages?: Record<string, string>,
  ): Promise<ProducerSpecificActionResult<Product>>;
  public async action(
    model: Producer,
    action: ProducerAddStorageParams,
    messages?: Record<string, string>,
  ): Promise<ProducerSpecificActionResult<Product>>;
  public async action(
    model: Producer,
    action: ProducerRemoveStorageParams,
    messages?: Record<string, string>,
  ): Promise<ProducerSpecificActionResult<Product>>;
  public async action<R extends ProducerSpecificActionResult<unknown>>(
    model: Producer,
    action: ProducerSpecificActionParams,
    messages: Record<string, string> = {},
  ): Promise<R> {
    try {
      const res = await firstValueFrom(
        this._producers.action<R>(model, "POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res as R;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { message: message, success: false } as R;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class ProducerActionService extends StructureBaseAction<never> {
  constructor(_msgs: DataMessageService) {
    super(_msgs);
  }
}

export interface ProducerListActionResult<D> {
  data?: D;
  log: OpLog;
  message: string;
  success: boolean;
}

export interface ProducerListCreateFromExistingParams {
  action: "create_existing";
  siren: string;
  producer_id: number;
  storages: number[];
}

export interface ProducerListCreateNewParams {
  action: "create_new";
  name: string;
  description: string;
  siren: string;
  address: string;
  postcode: string;
  city: string;
  type: PRODUCER_TYPES;
  storages: number[];
}

interface ProducerListSearchSirenParams {
  action: "search_siren";
  siren: string;
}

export type ProducerListActionParams =
  | ProducerListSearchSirenParams
  | ProducerListCreateFromExistingParams
  | ProducerListCreateNewParams;

@Injectable({
  providedIn: "root",
})
export class ProducerListActionService {
  private _msgs = inject(DataMessageService);
  private _producers: ProducerService = inject(ProducerService);

  public async action(
    action: ProducerListCreateNewParams,
    messages?: Record<string, string>,
  ): Promise<ProducerListActionResult<Producer>>;
  public async action(
    action: ProducerListCreateFromExistingParams,
    messages?: Record<string, string>,
  ): Promise<ProducerListActionResult<Producer>>;
  public async action(
    action: ProducerListSearchSirenParams,
    messages?: Record<string, string>,
  ): Promise<ProducerListActionResult<Producer[]>>;
  public async action<R extends ProducerListActionResult<unknown>>(
    action: ProducerListActionParams,
    messages: Record<string, string> = {},
  ): Promise<R> {
    try {
      const res = await firstValueFrom(
        this._producers.action<R>(null, "POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res as R;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { message: message, success: false } as R;
    }
  }
}
