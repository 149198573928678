import {
  charField,
  computedField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';
import {
  LIFECYCLE_MAIN_STEP,
  LIFECYCLE_SUB_STEP,
  LifeCycleDefault,
  LifeCycleDefaults,
  LifeCycleMainDefaults,
} from '../tarif/lifecycle/lifecycle-steps';
import { TarifLog } from '../tarif-log/tarif-log';

export class TarifLifecycleStepBase extends DataModel {
  static override readonly __name: string = 'TarifLifecycleStepBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'parent',
    description: 'ID Parent',
    related: 'self',
  })
  public parent!: number | null;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle de tarif',
    related: 'TarifType',
  })
  public tariftype!: number;

  @charField({
    name: 'step',
    description: 'Étape',
    maxLength: 4,
    choices: [
      {
        value: LIFECYCLE_MAIN_STEP.PREPARATION,
        desc: 'Préparation',
      },
      {
        value: LIFECYCLE_MAIN_STEP.NEGOCIATION,
        desc: 'Négociation',
      },
      {
        value: LIFECYCLE_MAIN_STEP.PUBLICATION,
        desc: 'Publication',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_1,
        desc: 'Erratum 1',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_2,
        desc: 'Erratum 2',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_3,
        desc: 'Erratum 3',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_4,
        desc: 'Erratum 4',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_5,
        desc: 'Erratum 5',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_6,
        desc: 'Erratum 6',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_7,
        desc: 'Erratum 7',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_8,
        desc: 'Erratum 8',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_9,
        desc: 'Erratum 9',
      },
      {
        value: LIFECYCLE_SUB_STEP.EDITION,
        desc: 'Edition',
      },
      {
        value: LIFECYCLE_SUB_STEP.UPLOAD,
        desc: 'Upload',
      },
      {
        value: LIFECYCLE_SUB_STEP.ARTICLES,
        desc: 'MAJ articles',
      },
      {
        value: LIFECYCLE_SUB_STEP.NEGOSEND,
        desc: 'Envoi pour négo',
      },
      {
        value: LIFECYCLE_SUB_STEP.NEGOUPD,
        desc: 'Retour négo',
      },
      {
        value: LIFECYCLE_SUB_STEP.PUBLISH,
        desc: 'Publication',
      },
      {
        value: LIFECYCLE_SUB_STEP.DONE,
        desc: 'Étape terminée',
      },
    ],
  })
  public step!: LIFECYCLE_MAIN_STEP | LIFECYCLE_SUB_STEP;

  @charField({
    name: 'pstep',
    description: 'Étape parente',
    maxLength: 4,
    choices: [
      {
        value: LIFECYCLE_MAIN_STEP.PREPARATION,
        desc: 'Préparation',
      },
      {
        value: LIFECYCLE_MAIN_STEP.NEGOCIATION,
        desc: 'Négociation',
      },
      {
        value: LIFECYCLE_MAIN_STEP.PUBLICATION,
        desc: 'Publication',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_1,
        desc: 'Erratum 1',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_2,
        desc: 'Erratum 2',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_3,
        desc: 'Erratum 3',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_4,
        desc: 'Erratum 4',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_5,
        desc: 'Erratum 5',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_6,
        desc: 'Erratum 6',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_7,
        desc: 'Erratum 7',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_8,
        desc: 'Erratum 8',
      },
      {
        value: LIFECYCLE_MAIN_STEP.UPDATE_9,
        desc: 'Erratum 9',
      },
    ],
  })
  public pstep?: LIFECYCLE_MAIN_STEP;

  @integerField({
    name: 'order',
    description: 'Ordre',
    defaultValue: 0,
  })
  public order: number = 0;

  @textField({
    name: 'message',
    description: 'Message',
  })
  public message!: string;

  @charField({
    name: 'stitle',
    description: 'Titre court',
    maxLength: 100,
  })
  public stitle!: string;

  @charField({
    name: 'title',
    description: 'Titre long',
    maxLength: 500,
  })
  public title!: string;

  @detailsField({
    name: 'params',
    description: 'Paramètres',
  })
  public params!: any;
  public log_items: TarifLog[] = [];

  public get last_log_item(): TarifLog | null {
    if (!this.log_items) {
      return null;
    }
    return this.log_items[this.log_items.length - 1];
  }

  @computedField({
    name: 'title$',
    description: 'Titre',
    readonly: true,
  })
  public get title$(): string | null {
    return this.title || this._defaults().title || null;
  }

  @computedField({
    name: 'stitle$',
    description: 'Sous-titre',
    readonly: true,
  })
  public get stitle$(): string | null {
    return this.stitle || this._defaults().stitle || null;
  }

  @computedField({
    name: 'message$',
    description: 'Message',
    readonly: true,
  })
  public get message$(): string | null {
    return this.message || this._defaults().message || null;
  }

  @computedField({
    name: 'params$',
    description: 'Paramètres',
    readonly: true,
  })
  public get params$(): any {
    return Object.assign({}, this.params || {}, this._defaults()?.params || {});
  }

  private _defaults(): LifeCycleDefault {
    if (this.pstep) {
      if (
        LifeCycleDefaults[this.pstep] &&
        LifeCycleDefaults[this.pstep]![this.step as LIFECYCLE_SUB_STEP]
      ) {
        return LifeCycleDefaults[this.pstep]![this.step as LIFECYCLE_SUB_STEP]!;
      } else {
        return {};
      }
    }
    return LifeCycleMainDefaults[this.step as LIFECYCLE_MAIN_STEP] || {};
  }
}
