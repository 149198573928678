
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="client">
  <li class="nav-item" *ngIf="client_display_route"><a class="nav-link" [routerLink]="client_display_route.route({client: client})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Résumé</a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="client_edit_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_edit_route.route({client: client})" routerLinkActive="active">Édition</a></li>
  <!-- Restos-->
  <li class="nav-item" *ngIf="client_restos_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_restos_route.route({client: client})" routerLinkActive="active">Restos</a></li>
  <!-- Relations-->
  <li class="nav-item" *ngIf="client_relations_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_relations_route.route({client: client})" routerLinkActive="active">Relations</a></li>
  <!-- Offers-->
  <li class="nav-item" *ngIf="client_offers_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_offers_route.route({client: client})" routerLinkActive="active">Offres</a></li>
  <!-- Sales-->
  <li class="nav-item" *ngIf="client_sales_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_sales_route.route({client: client})" routerLinkActive="active">Ventes</a></li>
  <!-- Tariftypes-->
  <li class="nav-item" *ngIf="client_tariftypes_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_tariftypes_route.route({client: client})" routerLinkActive="active">Tarifs</a></li>
  <!-- Earticles-->
  <li class="nav-item" *ngIf="client_earticles_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_earticles_route.route({client: client})" routerLinkActive="active">Articles</a></li>
  <!-- Atts-->
  <li class="nav-item" *ngIf="client_atts_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_atts_route.route({client: client})" routerLinkActive="active">Articles de tarif</a></li>
  <!-- users-->
  <li class="nav-item" *ngIf="client_users_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_users_route.route({client: client})" routerLinkActive="active">Utilisateurs</a></li>
  <!-- Stats-->
  <li class="nav-item" *ngIf="client_stats_route &amp;&amp; client.have_level(CUL.admin)"><a class="nav-link" [routerLink]="client_stats_route.route({client: client})" routerLinkActive="active">Statistiques</a></li>
</ul>