
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { GeoregionService} from "./georegion.service";
import { Georegion } from "./georegion";

@Injectable({
  providedIn: 'root'
})
export class GeoregionResolver  {
  constructor(private _rs: GeoregionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Georegion> {
    return this._rs.fetch(+route.params["georegionId"])
  }
}
