import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class RnmarticleBase extends DataModel {
  static override readonly __name: string = 'RnmarticleBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'rnm',
    description: 'ID Pivot RNM',
    related: 'Rnm',
  })
  public rnm!: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
  })
  public article!: number;

  @charField({
    name: 'ops',
    description: 'Opérations',
    maxLength: 200,
  })
  public ops!: string;

  @integerField({
    name: 'score_manual',
    description: 'Score manuel',
    defaultValue: 0,
  })
  public score_manual: number = 0;

  @integerField({
    name: 'score_auto',
    description: 'Score auto',
    defaultValue: 0,
  })
  public score_auto: number = 0;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;
}
