
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Georegion } from "./georegion";

@Injectable({
  providedIn: 'root'
})
export class GeoregionService extends Collection<Georegion> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/georegions", Georegion);
  }
}
