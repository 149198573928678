import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../users/user/user.service';
import { GroupService } from '../../../users/group/group.service';
import { OffertypeStorage } from '../../offertype-storage/offertype-storage';
import { OffertypeStoragePermission } from '../offertype-storage-permission';
import { OffertypeActionService } from '../../offertype/offertype-action.service';
import { OffertypeService } from '../../offertype/offertype.service';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-offertype-storage-permission-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './offertype-storage-permission-create.component.pug',
  styleUrls: ['./offertype-storage-permission-create.component.sass'],
})
export class OffertypeStoragePermissionCreateComponent implements OnInit {
  @Input() public offertypestorage?: OffertypeStorage;
  @Output() public created = new EventEmitter<void>();
  @Output() public cancelled = new EventEmitter<void>();
  public perm!: OffertypeStoragePermission;

  constructor(
    private _otact: OffertypeActionService,
    private _otcoll: OffertypeService,
    public user$: UserService,
    public group$: GroupService,
  ) {}

  ngOnInit(): void {
    this.perm = new OffertypeStoragePermission();
    if (this.offertypestorage && this.offertypestorage.offertype_details?.id) {
      this.perm.target = this.offertypestorage.id;
    } else {
      console.error('No tariftype available');
    }
  }

  public async save() {
    const tt = this.offertypestorage?.offertype_details;
    if (tt && tt.id && this.offertypestorage?.id) {
      tt._collection = this._otcoll;
      const res = await this._otact.action(tt, {
        action: 'add_storage_permission',
        otstorage: this.offertypestorage.id,
        user: this.perm.user,
        group: this.perm.group,
        permission: this.perm.permission,
      });
      if (res.success) {
        this.created.next();
      }
    }
  }
}
