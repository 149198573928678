import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferProduct } from "../../offer-product/offer-product";
import { OfferProductLink } from "../offer-product-link";
import { Article } from "../../../catalog/article/article";
import { Earticle } from "../../../catalog/earticle/earticle";
import { ArticleTarifTemplate } from "../../../tarifs/article-tarif-template/article-tarif-template";
import { ArticleTarif } from "../../../tarifs/article-tarif/article-tarif";
import { firstValueFrom, Subject } from "rxjs";
import { ArticleTarifDisplayComponent } from "../../../tarifs/article-tarif/article-tarif-display/article-tarif-display.component";
import { ArticleTarifTemplateDisplayComponent } from "../../../tarifs/article-tarif-template/article-tarif-template-display/article-tarif-template-display.component";
import { ArticleDisplayComponent } from "../../../catalog/article/article-display/article-display.component";
import { EarticleDisplayComponent } from "../../../catalog/earticle/earticle-display/earticle-display.component";
import { Link } from "@solidev/data";
import { Tarif } from "../../../tarifs/tarif/tarif";
import { ArticleTarifListComponent } from "../../../tarifs/article-tarif/article-tarif-list/article-tarif-list.component";
import { TarifService } from "../../../tarifs/tarif/tarif.service";
import { ModelListAction } from "../../../../includes/modellist/modellist.component";
import { OfferService } from "../../offer/offer.service";
import { Offer } from "../../offer/offer";
import { OfferActionService } from "../../offer/offer-action.service";
import { ArticleTarifTemplateListComponent } from "../../../tarifs/article-tarif-template/article-tarif-template-list/article-tarif-template-list.component";
import { ArticleListComponent } from "../../../catalog/article/article-list/article-list.component";
import { OffertypeService } from "../../offertype/offertype.service";
import { OffertypeStorageService } from "../../offertype-storage/offertype-storage.service";
import { Offertype } from "../../offertype/offertype";
import { OffertypeStorage } from "../../offertype-storage/offertype-storage";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";

export interface LinksSuggestion {
  articles: { article: Article; score: number }[];
  earticles: { earticle: Earticle; score: number }[];
  atts: { att: ArticleTarifTemplate; score: number }[];
  ats: { at: ArticleTarif; score: number }[];
}

@Component({
  selector: "lvadg-offer-product-link-create",
  standalone: true,
  imports: [
    CommonModule,
    ArticleTarifDisplayComponent,
    ArticleTarifTemplateDisplayComponent,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
    ArticleTarifListComponent,
    ArticleTarifTemplateListComponent,
    ArticleListComponent,
    NgbNavModule,
  ],
  templateUrl: "./offer-product-link-create.component.pug",
  styleUrls: ["./offer-product-link-create.component.sass"],
})
export class OfferProductLinkCreateComponent implements OnInit {
  @Input({ required: true }) public offerproduct!: OfferProduct;
  @Input() public offer?: Offer;
  @Output() public created = new EventEmitter<OfferProductLink>();
  @Output() public canceled = new EventEmitter<void>();
  @Input() public article_detail_route?: Link<Article>;
  @Input() public earticle_detail_route?: Link<Earticle>;
  @Input() public att_detail_route?: Link<ArticleTarifTemplate>;
  @Input() public tarif_detail_route?: Link<Tarif>;
  public offertype: Offertype | undefined;
  public offertypestorage: OffertypeStorage | undefined;
  public article_tarif_fields = [
    "tarif_details",
    "article_details",
    "eaclient_details",
    "article_labels",
    "price",
    "status",
    "in_op",
    "actions",
  ];
  public article_tarif_template_fields = [
    "tariftype_details",
    "article_details",
    "eaclient_details",
    "article__egalim",
    "in_op",
    "actions",
  ];
  public article_fields = [
    "code",
    "libelle",
    "clogo_details",
    "name",
    "egalim_details",
    "local",
    "in_op",
    "actions",
  ];
  public tarif?: Tarif;
  public mode: "at" | "att" | "article" = "at";
  public enabledAtMode = false;
  public enabledAttMode = false;
  public enabledArticleMode = false;
  public reload$ = new Subject<void | boolean>();

  constructor(
    private _ts: TarifService,
    private _ots: OffertypeService,
    private _otss: OffertypeStorageService,
    private _oa: OfferActionService,
    private _os: OfferService,
  ) {}

  public async ngOnInit(): Promise<void> {
    // Ensure offer is loaded
    if (!this.offer) {
      if (!this.offerproduct.offer) {
        console.error("Current offerproduct", this.offerproduct);
        throw new Error("OfferProduct has no offer field");
      }
      this.offer = await firstValueFrom(
        this._os.fetch(this.offerproduct.offer),
      );
    }
    // If offer has offertype, load it
    if (this.offer.offertype) {
      this.offertype = await firstValueFrom(
        this._ots.fetch(this.offer.offertype),
      );
    }
    // If offer has offertypestorage, load it
    if (this.offer.offertypestorage) {
      this.offertypestorage = await firstValueFrom(
        this._otss.fetch(this.offer.offertypestorage),
      );
    }
    const haveTts = !!(
      (this.offertype?.tariftypes_details &&
        this.offertype.tariftypes_details.length) ||
      (this.offertypestorage?.tariftypes_details &&
        this.offertypestorage.tariftypes_details.length)
    );
    if (this.offer.tarifs_details && this.offer.tarifs_details.length > 0) {
      this.mode = "at";
      this.enabledArticleMode = true;
      this.enabledAtMode = true;
      if (haveTts) {
        this.enabledAttMode = true;
      }
    } else if (haveTts) {
      this.mode = "att";
      this.enabledArticleMode = true;
      this.enabledAttMode = true;
      this.enabledAtMode = false;
    } else {
      this.mode = "article";
      this.enabledArticleMode = true;
      this.enabledAttMode = false;
      this.enabledAtMode = false;
    }
  }

  onTarifSelect(event: ModelListAction<ArticleTarif>) {
    if (!this.offer) {
      throw new Error("Offer is undefined");
    }
    if (!event.model?.id) {
      throw new Error("ArticleTarif is undefined");
    }
    console.log(this.offer);
    this._oa
      .action<OfferProductLink>(this.offer, {
        action: "add_product_link",
        offerproduct: this.offerproduct.id,
        at: event.model.id,
      })
      .then((res) => {
        if (res.success) {
          this.created.emit(res.data);
          this.reload$.next(true);
        }
      });
  }

  onTarifTemplateSelect(event: ModelListAction<ArticleTarifTemplate>) {
    if (!this.offer) {
      throw new Error("Offer is undefined");
    }
    if (!event.model?.id) {
      throw new Error("ArticleTarifTemplate is undefined");
    }
    this._oa
      .action<OfferProductLink>(this.offer, {
        action: "add_product_link",
        offerproduct: this.offerproduct.id,
        att: event.model.id,
      })
      .then((res) => {
        if (res.success) {
          this.created.emit(res.data);
          this.reload$.next(true);
        }
      });
  }

  onArticleSelect(event: ModelListAction<Article>) {
    if (!this.offer) {
      throw new Error("Offer is undefined");
    }
    if (!event.model?.id) {
      throw new Error("Article is undefined");
    }
    this._oa
      .action<OfferProductLink>(this.offer, {
        action: "add_product_link",
        offerproduct: this.offerproduct.id,
        article: event.model.id,
      })
      .then((res) => {
        if (res.success) {
          this.created.emit(res.data);
          this.reload$.next(true);
        }
      });
  }
}
