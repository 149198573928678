import { Component } from '@angular/core';
import { OrderprocessPermissionListViewData } from './orderprocess-permission-list-view.component.params';
import { Subject } from 'rxjs';
import { Link } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { OrderprocessPermission } from '../../../../models/orders/orderprocess-permission/orderprocess-permission';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { OrderprocessBase } from '../../../../models/orders/orderprocess/orderprocess.base';
import { OrderprocessPermissionListComponent } from '../../../../models/orders/orderprocess-permission/orderprocess-permission-list/orderprocess-permission-list.component';
import { OrderprocessPermissionCreateComponent } from '../../../../models/orders/orderprocess-permission/orderprocess-permission-create/orderprocess-permission-create.component';

@Component({
  selector: 'lvadg-orderprocess-permission-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, OrderprocessPermissionListComponent, OrderprocessPermissionCreateComponent],
  templateUrl: './orderprocess-permission-list-view.component.pug',
  styleUrls: ['./orderprocess-permission-list-view.component.sass'],
})
export class OrderprocessPermissionListViewComponent extends BaseListViewComponent<
  OrderprocessPermissionListViewData,
  OrderprocessPermission
> {
  public create: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public orderprocess_detail_route!: Link<OrderprocessBase>;
  public default_fields = ['id', 'permission', 'target_details', 'user_details', 'group_details', 'created', 'updated'];

  public override setRouteData(data: OrderprocessPermissionListViewData) {
    super.setRouteData(data);
    this.user_detail_route = new Link(data.user_detail_route, this);
    this.group_detail_route = new Link(data.group_detail_route, this);
    this.orderprocess_detail_route = new Link(data.orderprocess_detail_route, this);
  }
}
