
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { RelationService} from "./relation.service";
import { Relation } from "./relation";

@Injectable({
  providedIn: 'root'
})
export class RelationResolver  {
  constructor(private _rs: RelationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Relation> {
    return this._rs.fetch(+route.params["relationId"])
  }
}
