import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Tariftype } from "../tariftype";
import { Subject } from "rxjs";
import { EamappingListComponent } from "../../../catalog/eamapping/eamapping-list/eamapping-list.component";
import { ArticleBase } from "../../../catalog/article/article.base";
import { Link } from "@solidev/data";
import { EarticleBase } from "../../../catalog/earticle/earticle.base";
import { TARIFTYPE_USER_LEVEL, TariftypeBase } from "../tariftype.base";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { ModelListAction } from "../../../../includes/modellist/modellist.component";
import { Eamapping } from "../../../catalog/eamapping/eamapping";
import { ArticleListComponent } from "../../../catalog/article/article-list/article-list.component";
import { Article } from "../../../catalog/article/article";
import { TariftypeActionService } from "../../tarif/tariftype-action.service";

@Component({
  selector: "lvadg-tariftype-earticle-mappings",
  standalone: true,
  imports: [CommonModule, EamappingListComponent, ArticleListComponent],
  templateUrl: "./tariftype-earticle-mappings.component.pug",
  styleUrls: ["./tariftype-earticle-mappings.component.sass"],
})
export class TariftypeEarticleMappingsComponent {
  @ViewChild("catalogSlot") public catalogSlot!: ElementRef<HTMLDivElement>;
  @Input() public name: string = "";
  @Input() public tariftype!: Tariftype;
  @Input() public reload?: Subject<void | boolean>;
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  public TARIFTYPE_USER_LEVEL = TARIFTYPE_USER_LEVEL;
  public catalog_default_fields = [
    "code",
    "libelle",
    "family_details",
    "udf_unit",
    "egalim_details",
    "local",
    "egalim_status",
    "calibre_details",
    "categorie_details",
    "origine_details",
    "colisage",
    "uci",
    "in_eamapping",
    "in_tariftype",
    "actions",
  ];

  public eamapping_default_fields = [
    "id",
    "article_details",
    "earticle_details",
    "earticle_code",
    "earticle_libelle",
    "earticle_libelle2",
    "earticle_origine",
    "earticle_uf",
    "article_code",
    "article_libelle",
    "article_decondi_mode",
    "article_uf",
    "article_labels",
    "actions",
    "status",
  ];

  public curMapping?: Eamapping;
  public mapping_actions: string[] = ["article_add", "article_remove"];
  public mapping_sactions: string[] = [
    "article_add",
    "article_remove",
    "sdelete",
  ];

  constructor(
    private _ofc: NgbOffcanvas,
    private _ttact: TariftypeActionService,
  ) {}

  public async catalogAction(action: ModelListAction<Article>) {
    if (action.action == "earticle_add" && action.model) {
      await this._ttact.action(this.tariftype, {
        action: "eamapping_add_article",
        eamapping: this.curMapping!.id,
        article: action.model.id,
      });
      this.reload?.next(true);
    }
  }

  public async eamappingAction(action: ModelListAction<Eamapping>) {
    if (action.action === "article_add" && action.model) {
      this.curMapping = action.model;
      this.openCatalog();
    } else if (action.action == "article_remove" && action.model) {
      this.curMapping = action.model;
      await this._ttact.action(this.tariftype, {
        action: "eamapping_remove_article",
        eamapping: this.curMapping!.id,
      });
      this.reload?.next(true);
      delete this.curMapping;
    }
  }

  openCatalog() {
    this._ofc.open(this.catalogSlot, {
      position: "end",
      panelClass: "w-75",
    });
  }
}
