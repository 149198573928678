
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Brand } from "./brand";

@Injectable({
  providedIn: 'root'
})
export class BrandService extends Collection<Brand> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/brands", Brand);
  }
}
