import { Component } from '@angular/core';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { BASE_VIEW_IMPORTS } from '../../../../components/baseview/baseview.imports';
import { BaseViewComponent } from '../../../../components/baseview/baseview.component';
import { TarifArticlesStatsComponent } from '../../../../models/tarifs/stats/tarif-articles-stats/tarif-articles-stats.component';

export interface TarifStatsArticlesViewParams extends BaseRouteParams {
  article_detail_route: RouteConfigItem;
}

export interface TarifStatsArticlesViewData extends TarifStatsArticlesViewParams {}

@Component({
  selector: 'lvadg-tarif-stats-articles-view',
  standalone: true,
  imports: [...BASE_VIEW_IMPORTS, TarifArticlesStatsComponent],
  templateUrl: './tarif-stats-articles-view.component.pug',
  styleUrls: ['./tarif-stats-articles-view.component.sass'],
})
export class TarifStatsArticlesViewComponent extends BaseViewComponent<TarifStatsArticlesViewData> {
  public articles_default_fields = [
    'id',
    'code',
    'family_details',
    'libelle',
    'udf_unit',
    'egalim_details',
    'categorie_details',
    'calibre_details',
    'period_stats_price',
    'period_stats_sdev',
    'interval_stats',
  ];

  public override setRouteData(data: TarifStatsArticlesViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
