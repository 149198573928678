import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { CiqualValue } from '../ciqual-value';
import { CiqualValueService } from '../ciqual-value.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PFactorPipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CiqualColumn } from '../../ciqual-column/ciqual-column';
import { CiqualColumnDisplayComponent } from '../../ciqual-column/ciqual-column-display/ciqual-column-display.component';
import { CiqualAlimBase } from '../../ciqual-alim/ciqual-alim.base';
import { CiqualAlim } from '../../ciqual-alim/ciqual-alim';
import { CiqualAlimDisplayComponent } from '../../ciqual-alim/ciqual-alim-display/ciqual-alim-display.component';

@Component({
  selector: 'lvadg-ciqual-value-list',
  standalone: true,
  templateUrl: './ciqual-value-list.component.pug',
  styleUrls: ['./ciqual-value-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PFactorPipe,
    CiqualColumnDisplayComponent,
    CiqualAlimDisplayComponent,
  ],
})
export class CiqualValueListComponent extends ModellistComponent<CiqualValue> {
  @Input() public ciqualcolumn_detail_route?: Link<CiqualColumn>;
  @Input() ciqualalim_detail_route?: Link<CiqualAlim | CiqualAlimBase>;

  constructor(
    coll: CiqualValueService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
