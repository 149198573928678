import { Component, ElementRef, ViewChild } from "@angular/core";

import { firstValueFrom, map, Observable, Subject } from "rxjs";
import {
  BaseRouteParams,
  DataMessageService,
  FakeDeleteResult,
  FilterDefaults,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
  TabMemoryService,
} from "@solidev/data";
import { UploadResult } from "../../../models/documents/upload/media-upload/media-upload.component";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { Folder } from "../../../models/documents/folder/folder";
import { BASE_DETAIL_VIEW_IMPORTS } from "../../../components/baseview/basedetailview.imports";
import { DocumentListComponent } from "../../../models/documents/document/document-list/document-list.component";
import { FolderPermissionListComponent } from "../../../models/documents/folder-permission/folder-permission-list/folder-permission-list.component";
import { FolderPermissionCreateComponent } from "../../../models/documents/folder-permission/folder-permission-create/folder-permission-create.component";
import { FolderManageComponent } from "../../../models/documents/folder/folder-manage/folder-manage.component";
import { FolderListComponent } from "../../../models/documents/folder/folder-list/folder-list.component";
import { FolderContentComponent } from "../../../models/documents/folder/folder-content/folder-content.component";
import { FolderCreateComponent } from "../../../models/documents/folder/folder-create/folder-create.component";
import { IconComponent } from "../../../components/utils/icon/icon.component";
import {
  DocumentCreateComponent,
  DocumentUploadResponse,
} from "../../../models/documents/document/document-create/document-create.component";
import { Document } from "../../../models/documents/document/document";
import { DocumentManageComponent } from "../../../models/documents/document/document-manage/document-manage.component";
import { LogoManageComponent } from "../../../models/documents/image/logo-manage/logo-manage.component";
import { BaseDetailViewComponent } from "../../../components/baseview/basedetailview.component";
import { User } from "../../../models/users/user/user";
import { Group } from "../../../models/users/group/group";
import { FolderService } from "../../../models/documents/folder/folder.service";
import { DocumentService } from "../../../models/documents/document/document.service";
import { FolderTreeService } from "../../../models/documents/folder/folder-tree.service";
import { FOLDER_USER_LEVEL } from "../../../models/documents/folder/folder.base";
import { AuthService } from "../../../models/users/auth.service";
import { FlagsDisplayComponent } from "../../../components/utils/flags-display/flags-display.component";
import { TodoComponent } from "../../../components/utils/todo/todo.component";
import { DocumentFolderListmanageComponent } from "../../../models/documents/document-folder/document-folder-listmanage/document-folder-listmanage.component";

export interface FolderDetailViewParams extends BaseRouteParams {
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  folder_detail_route: RouteConfigItem;
  treeName: string;
}

export interface FolderDetailViewData extends FolderDetailViewParams {
  folder: Folder | null;
}

@Component({
  selector: "lvadg-folder-detail-view",
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    DocumentListComponent,
    FolderPermissionListComponent,
    FolderPermissionCreateComponent,
    FolderManageComponent,
    FolderListComponent,
    FolderContentComponent,
    FolderCreateComponent,
    IconComponent,
    FlagsDisplayComponent,
    DocumentCreateComponent,
    DocumentManageComponent,
    DocumentFolderListmanageComponent,
    SafeDeleteComponent,
    LogoManageComponent,
    TodoComponent,
  ],
  templateUrl: "./folder-detail-view.component.pug",
  styleUrls: ["./folder-detail-view.component.sass"],
})
export class FolderDetailViewComponent extends BaseDetailViewComponent<
  FolderDetailViewData,
  Folder
> {
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public folderFilter$!: Observable<FilterDefaults>;
  public parentFilter$!: Observable<FilterDefaults>;
  public folder$!: Observable<Folder | null>;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public folder_detail_route?: Link<Folder>;
  public createSubFolder = false;
  @ViewChild("folderedit", { static: false })
  public ofcFolderEditSlot?: ElementRef<HTMLDivElement>;
  public editedFolder?: Folder;
  public editedFolderChanges = {
    status: false,
    details: false,
    moved: false,
    deleted: false,
  };
  public editedFolderReload$ = new Subject<void | boolean>();
  @ViewChild("documentedit", { static: false })
  public ofcDocumentEditSlot?: ElementRef<HTMLDivElement>;
  public editedDocument?: Document;
  public editedDocumentChanges = {
    details: false,
    deleted: false,
  };
  public createFolderPermission: boolean = false;
  public FUL = FOLDER_USER_LEVEL;
  public updatingPreview: boolean = false;
  public user$!: Observable<User | null>;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    public msgs: DataMessageService,
    private _folders: FolderService,
    private _documents: DocumentService,
    private _fts: FolderTreeService,
    private _ofc: NgbOffcanvas,
    private _auth: AuthService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: FolderDetailViewData) {
    super.setRouteData(data);
    this.user_detail_route = new Link<User>(
      data.user_detail_route,
      data,
      this._router,
    );
    this.group_detail_route = new Link<Group>(
      data.group_detail_route,
      data,
      this._router,
    );
    this.folder_detail_route = new Link<Folder>(
      data.folder_detail_route,
      data,
      this._router,
    );
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.folder$ = this.data$.pipe(map((data) => data.folder));
    this.folderFilter$ = this.data$.pipe(
      map((data) => ({ folder: data.folder ? data.folder.id : "null" })),
    );
    this.parentFilter$ = this.data$.pipe(
      map((data) => ({ parent: data.folder ? data.folder.id : "null" })),
    );
    this.user$ = this._auth.currentUser$;
  }

  public createdDocument(
    result: UploadResult<Document, DocumentUploadResponse>,
  ) {
    this.msgs.success(result.response?.message || "Document créé avec succès");
    this.reload$.next(true);
  }

  public createdSubFolder($event: Folder) {
    this.reload$.next(true);
    this.createSubFolder = false;
    this.folder_detail_route?.navigate({ folder: $event });
  }

  public deletedFolder($event: FakeDeleteResult, data: FolderDetailViewData) {
    this.msgs.success("Dossier supprimé avec succès");
    this._fts.reload(data.treeName);
    this.reload$.next(true);
    if (data.folder?.parent) {
      this._router.navigate(["../"], { relativeTo: this._route });
    }
  }

  public async changedStatus($event: Folder, data: FolderDetailViewData) {
    this.msgs.success("Visibilité du dossier modifiée avec succès");
    this.reloadFolder($event, data);
  }

  public async changedDetails($event: Folder, data: FolderDetailViewData) {
    this.msgs.success("Dossier modifiée avec succès");
    this.reloadFolder($event, data);
  }

  public async movedFolder($event: Folder, data: FolderDetailViewData) {
    this.msgs.success("Dossier déplacé avec succès");
    this.reloadFolder($event, data);
  }

  public async reloadFolder(folder: Folder, data: FolderDetailViewData) {
    folder = await firstValueFrom(this._folders.fetch(folder.id));
    this._fts.reload(data.treeName);
    this._fts.open(data.treeName, folder, true);
    this.reload$.next(true);
    this.folder_detail_route?.navigate({ folder: folder });
  }

  public async clickedFolder(folder: Folder, data: FolderDetailViewData) {
    this.editedFolder = await firstValueFrom(this._folders.fetch(folder.id));
    this.editedFolderChanges = {
      status: false,
      details: false,
      moved: false,
      deleted: false,
    };
    if (!this.ofcFolderEditSlot) {
      console.error("No slot for folder's offcanvas");
    }
    const close = () => {
      this.editedFolder = undefined;
      if (
        !this.editedFolderChanges.deleted &&
        (this.editedFolderChanges.status ||
          this.editedFolderChanges.details ||
          this.editedFolderChanges.moved)
      ) {
        this.editedFolderChanges = {
          status: false,
          details: false,
          moved: false,
          deleted: false,
        };
        this.createFolderPermission = false;
        if (folder.parent_details) {
          this.reloadFolder(folder.parent_details, data);
        } else {
          this.reloadFolder(folder, data);
        }
      }
    };
    this._ofc
      .open(this.ofcFolderEditSlot, {
        position: "end",
        panelClass: "w-50",
      })
      .result.then(close, close);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async clickedDocument(document: Document, data: FolderDetailViewData) {
    this.editedDocument = await firstValueFrom(
      this._documents.fetch(document.id),
    );
    this.editedDocumentChanges = { details: false, deleted: false };
    if (!this.ofcDocumentEditSlot) {
      console.error("No slot for document's offcanvas");
    }
    const close = () => {
      this.editedDocument = undefined;
      this.editedDocumentChanges = { details: false, deleted: false };
      this.reload$.next(true);
    };
    this._ofc
      .open(this.ofcDocumentEditSlot, {
        position: "end",
        panelClass: "w-50",
      })
      .result.then(close, close);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deletedDocument($event: FakeDeleteResult, data: FolderDetailViewData) {
    this.msgs.success("Document supprimé avec succès");
    this.reload$.next(true);
  }

  public async updatePreview(doc: Document) {
    this.updatingPreview = true;
    try {
      await firstValueFrom(doc.action("POST", "update_preview"));
      doc.fromJson(await firstValueFrom(this._documents.fetch(doc.id)));
      this.msgs.success("Aperçu mis à jour avec succès");
    } catch (e) {
      this.msgs.error("Erreur lors de la mise à jour de l'aperçu");
    }
    this.updatingPreview = false;
  }
}
