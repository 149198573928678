import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifNotificationService } from '../tarif-notification.service';
import { firstValueFrom, Observable } from 'rxjs';
import { TarifNotification } from '../tarif-notification';
import { TarifBase } from '../../tarif/tarif.base';
import { Link, Queryset } from '@solidev/data';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { RouterLink } from '@angular/router';
import { TarifNotificationStatusStatus } from '../../tarif-notification-status/tarif-notification-status.base';
import { TARIFNOTIFICATION_PRIORITY } from '../tarif-notification.base';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-tarif-notification-notifylist',
  standalone: true,
  imports: [CommonModule, RouterLink, NgbPagination],
  templateUrl: './tarif-notification-notifylist.component.pug',
  styleUrls: ['./tarif-notification-notifylist.component.sass'],
})
export class TarifNotificationNotifylistComponent implements OnInit {
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public account_route?: Link<any>;
  @Input() public notifstatus: 'read' | 'unread' | 'all' = 'unread';
  @Input() public dest: 'tarif' | 'type' = 'tarif';

  public notifqs!: Queryset<TarifNotification>;
  public pageSize = 20;
  public page = 1;
  public count = 0;
  public notifications$!: Observable<TarifNotification[]>;
  public TNSTATUS = TarifNotificationStatusStatus;
  public TNPRIO = TARIFNOTIFICATION_PRIORITY;

  constructor(private _tns: TarifNotificationService) {}

  public ngOnInit() {
    this.notifqs = this._tns.queryset();
    this.notifqs
      .filter({ own: this.notifstatus })
      .sort('-created')
      .setFields([
        'id',
        'tariftype',
        'tarif',
        'tarif_details',
        'tariftype_details',
        'type',
        'title',
        'message',
        'priority',
        'status',
        'user_status',
      ])
      .paginateBy(this.pageSize);
    this.notifications$ = this.notifqs.get();
  }

  public async updateFilter(status: 'read' | 'unread' | 'all') {
    this.notifstatus = status;
    await firstValueFrom(
      this.notifqs.filter({ own: this.notifstatus }).get(true, true)
    );
  }

  public async toggleRead(
    n: TarifNotification,
    status: TarifNotificationStatusStatus = TarifNotificationStatusStatus.CLICKED
  ) {
    await firstValueFrom(
      n.action('POST', 'toggle_status', { body: { status } })
    );
    await this.updateFilter(this.notifstatus);
  }

  public async pageChange($event: number) {
    await firstValueFrom(this.notifqs.setPage($event).get());
  }
}
