
<ng-container *ngIf="data$|async; let data">
  <lvadg-offer-nav [routes]="data.o_menu" [offer]="data.offer" *ngIf="data.o_menu" [offertype]="data.offertype" [offertypestorage]="data.offertypestorage"></lvadg-offer-nav>
  <lvadg-header-action [title]="data.offer.title" level="h1" subtitle="Résumé de l'offre" ri="offer"></lvadg-header-action>
  <div class="row">
    <div class="col-xl-4 col-sm-6">
      <div class="card h-100">
        <div class="card-header">Infos offre</div>
        <div class="card-body">
          <dl>
            <dt>Titre</dt>
            <dd>{{data.offer.title}}</dd>
            <dt>Type</dt>
            <dd>{{data.offer.type|pchoice:data.offer:'type'}} {{data.offer.zone|pchoice:data.offer:'zone'}}</dd>
            <ng-container *ngIf="data.offer.offertype_details || data.offer.offertypestorage_details">
              <dt>Modèle</dt>
              <dd>{{data.offer.offertype_details?.name}}</dd>
              <dt>Modèle / dépôt</dt>
              <dd>{{data.offer.offertypestorage_details?.storage_details?.name}}</dd>
            </ng-container>
            <ng-container *ngIf="!data.offer.offertype_details &amp;&amp; !data.offer.offertypestorage_details">
              <dt>Modèle</dt>
              <dd>Offre libre</dd>
            </ng-container>
            <dt>Date de début</dt>
            <dd>{{data.offer.datestart|date:'shortDate'}}</dd>
            <dt>Date de fin</dt>
            <dd>{{data.offer.dateend|date:'shortDate'}}</dd>
            <dt>Auteur</dt>
            <dd>{{data.offer.author_details?.email}}</dd>
            <dt>Date de création</dt>
            <dd>{{data.offer.created|date:'short'}}</dd>
            <dt>Date de dernière modification</dt>
            <dd>{{data.offer.updated|date:'short'}}</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-6">
      <div class="card h-100">
        <div class="card-header">Destination</div>
        <div class="card-body">
          <dl>
            <dt>Client</dt>
            <dd>{{data.offer.client_details?.name}}</dd>
            <dt>Restaurant(s)</dt>
            <dd>{{data.offer.offerrestos_details?.length}}
              <div class="restos overflow-auto">
                <ul *ngIf="data.offer.offerrestos_details?.length">
                  <li *ngFor="let r of data.offer.offerrestos_details">{{r.resto_details?.name}}</li>
                </ul>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-12">
      <div class="card h-100">
        <div class="card-header">Contenu de l'offre</div>
        <div class="card-body">
          <div class="products overflow-auto">
            <ul>
              <li *ngFor="let p of products$|async">{{p.product_details?.name}} ({{p.producer_details?.name}})</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<lvadg-todo>
  <div class="item">TODO: état de préparation (???) => besoin de truc genre "lifecycle"</div>
  <div class="item">TODO: stats impression / envoi</div>
</lvadg-todo>