import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent, FilterDefaults } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Cdata } from '../cdata';
import { firstValueFrom } from 'rxjs';
import { CdataService } from '../cdata.service';
import { StorageBase } from '../../../structures/storage/storage.base';
import { CdataKeyService } from '../../cdata-key/cdata-key.service';
import { CDATAKEY_DEST, CDATAKEY_TYPE } from '../../cdata-key/cdata-key.base';
import { CdataKeyChoiceService } from '../../cdata-key-choice/cdata-key-choice.service';

@Component({
  selector: 'lvadg-cdata-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './cdata-create.component.pug',
  styleUrls: ['./cdata-create.component.sass'],
})
export class CdataCreateComponent implements OnInit {
  public createForm!: FormGroup<{ name?: FormControl<string> }>;
  @Input() public storage?: StorageBase;
  @Output() public created = new EventEmitter<Cdata>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: Cdata;
  public keyFilter: FilterDefaults = {};
  public KT = CDATAKEY_TYPE;

  constructor(
    private _items: CdataService,
    public cdatakeys$: CdataKeyService,
    public cdatakeychoices$: CdataKeyChoiceService
  ) {}

  ngOnInit(): void {
    this.item = new Cdata(this._items);
    this.createForm = this.item.FC(['key']);
    if (this.storage) {
      this.keyFilter = { dest: CDATAKEY_DEST.STORAGE };
    }
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    if (this.storage) {
      this.item.storage = this.storage.id;
    }
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }
}
