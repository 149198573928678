
<form [formGroup]="createForm" (submit)="save()">
  <div class="row mb-2">
    <data-dispedit class="col-6" [model]="article" field="family" mode="form" [form]="createForm" [collection]="families$">Famille</data-dispedit>
    <data-dispedit class="col-6" [model]="article" field="libelle" mode="form" [form]="createForm">Libellé</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>