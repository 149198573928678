import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderitemService } from '../orderitem.service';
import { OrderitemBase } from '../orderitem.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { OrderService } from '../../order/order.service';
import { OrderprocessarticleService } from '../../orderprocessarticle/orderprocessarticle.service';

@Component({
  selector: 'lvadg-orderitem-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './orderitem-create.component.pug',
  styleUrls: ['./orderitem-create.component.sass'],
})
export class OrderitemCreateComponent implements OnInit {
  public obj!: OrderitemBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<OrderitemBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _svc: OrderitemService,
    public order$: OrderService,
    public orderprocessarticle$: OrderprocessarticleService
  ) {}

  public ngOnInit(): void {
    this.obj = new OrderitemBase(this._svc);
  }

  public async save() {
    this.obj.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
