import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TariftypeRegionPermission } from '../tariftype-region-permission';
import { TariftypeRegionPermissionService } from '../tariftype-region-permission.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { GenericregionBase } from '../../../locations/genericregion/genericregion.base';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { GenericregionDisplayComponent } from '../../../locations/genericregion/genericregion-display/genericregion-display.component';

@Component({
  selector: 'lvadg-tariftype-region-permission-list',
  standalone: true,
  templateUrl: './tariftype-region-permission-list.component.pug',
  styleUrls: ['./tariftype-region-permission-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    UserDisplayComponent,
    GroupDisplayComponent,
    TariftypeDisplayComponent,
    GenericregionDisplayComponent,
  ],
})
export class TariftypeRegionPermissionListComponent extends ModellistComponent<TariftypeRegionPermission> {
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public region_detail_route?: Link<GenericregionBase>;

  constructor(coll: TariftypeRegionPermissionService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
