import { RouteConfigItem } from '@solidev/data';

export interface MEMBER_ROUTES {
  member_display_route?: RouteConfigItem;
  member_edit_route?: RouteConfigItem;
  member_relations_route?: RouteConfigItem;
  member_products_route?: RouteConfigItem;
  member_offers_route?: RouteConfigItem;
  member_storages_route?: RouteConfigItem;
  member_sales_route?: RouteConfigItem;
  member_producers_route?: RouteConfigItem;
  member_users_route?: RouteConfigItem;
}

export interface MEMBER_LINKS_ROUTES {
  storage_detail_route: RouteConfigItem;
  product_detail_route?: RouteConfigItem;
  producer_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
  relation_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
}
