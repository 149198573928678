
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ArticleTarifLog } from "./article-tarif-log";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifLogService extends Collection<ArticleTarifLog> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/articletariflogs", ArticleTarifLog);
  }
}
