
<ng-container *ngIf="data$|async; let data">
  <lvadg-storage-nav [storage]="data.storage" [routes]="data.s_menu"></lvadg-storage-nav>
  <lvadg-header-action [title]="data.storage._display" level="h1" ri="storage" subtitle="Données associées">
    <ng-container actions>
      <button class="btn btn-outline-primary btn-sm" (click)="createCData=!createCData">
        <lvadg-icon ri="add">Ajouter une donnée</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createCData">
    <div class="card-header">
      <lvadg-icon ri="cdata">Nouvelle donnée</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-cdata-create [storage]="data.storage" (created)="reload$.next(true); createCData=false" (cancelled)="createCData=false"></lvadg-cdata-create>
    </div>
  </div>
  <lvadg-cdata-list [name]="data.route.name" [keep]="false" [filter]="storageFilter$" [reload]="reload$" [actions]="['delete']"></lvadg-cdata-list>
</ng-container>