
<ng-container *ngIf="genericregion">
  <dl class="row">
    <data-dispedit class="col-6" [model]="genericregion" field="code" mode="dd">Code</data-dispedit>
    <data-dispedit class="col-6" [model]="genericregion" field="name" mode="dd">Nom</data-dispedit>
    <data-dispedit class="col-6" [model]="genericregion" field="description" mode="dd">Description</data-dispedit>
    <data-dispedit class="col-6" [model]="genericregion" field="type" mode="dd">Type</data-dispedit>
    <data-dispedit class="col-6" *ngIf="genericregion.type==GENERICREGION_TYPE.CLIENT" [model]="genericregion" field="client" mode="dd" [collection]="clients$" [filter]="{fields: ['id', 'cvva', 'name'].join(',')}">Client</data-dispedit>
    <data-dispedit class="col-6" [model]="genericregion" field="geodepartements" mode="dd" [collection]="geodepartements$" [filter]="{fields: ['id', 'code', 'name'].join(',')}">Départements</data-dispedit>
  </dl>
</ng-container>