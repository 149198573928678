
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OfferService} from "./offer.service";
import { Offer } from "./offer";

@Injectable({
  providedIn: 'root'
})
export class OfferResolver implements Resolve<Offer> {
  constructor(private _rs: OfferService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Offer> {
    return this._rs.fetch(+route.params["offerId"])
  }
}
