
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Relation } from "./relation";

@Injectable({
  providedIn: 'root'
})
export class RelationService extends Collection<Relation> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/relations", Relation);
  }
}
