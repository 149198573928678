import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  textField,
} from '@solidev/data';

export enum GENERICREGION_TYPE {
  CLIENT = 'CLI1', // Région client (CLI1)',
  VIVALYA = 'VVYA', // Région Vivalya',
}

export class GenericregionBase extends DataModel {
  static override readonly __name: string = 'GenericregionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 200,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 150,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 250,
  })
  public updated!: Date;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: GENERICREGION_TYPE.VIVALYA,
    maxLength: 4,
    priority: 950,
    choices: [
      { value: GENERICREGION_TYPE.CLIENT, desc: 'Région client' },
      { value: GENERICREGION_TYPE.VIVALYA, desc: 'Region Vivalya' },
    ],
  })
  public type: GENERICREGION_TYPE = GENERICREGION_TYPE.VIVALYA;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 200,
    priority: 900,
  })
  public name!: string;

  @textField({
    name: 'description',
    description: 'Description',
    priority: 500,
  })
  public description!: string;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 20,
    priority: 800,
  })
  public code!: string;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @manyToManyField({
    name: 'geodepartements',
    description: 'IDs départements',
    defaultValue: [],
    related: 'GeoDepartement',
    priority: -2,
  })
  public geodepartements: number[] = [];
}
