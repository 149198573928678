
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { UserMapService} from "./user-map.service";
import { UserMap } from "./user-map";

@Injectable({
  providedIn: 'root'
})
export class UserMapResolver implements Resolve<UserMap> {
  constructor(private _rs: UserMapService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserMap> {
    return this._rs.fetch(+route.params["usermapId"])
  }
}
