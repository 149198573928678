import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Family } from './family';

@Injectable({
  providedIn: 'root',
})
export class FamilyService extends Collection<Family> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/families', Family);
  }
}
