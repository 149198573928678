import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from "@solidev/data";

export class OfferTextBase extends DataModel {
  static override readonly __name: string = "OfferTextBase";

  @primaryField({ name: "id", description: "ID" })
  public override id!: number;

  @datetimeField({
    name: "created",
    description: "created",
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "updated",
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: "offer",
    description: "offer",
    related: "Offer",
  })
  public offer!: number;

  @charField({ name: "title", description: "title", maxLength: 255 })
  public title!: string;

  @textField({ name: "text", description: "text" })
  public text!: string;

  @integerField({
    name: "position",
    description: "position",
    defaultValue: 0,
  })
  public position: number = 0;

  @foreignKeyField({
    name: "texttype",
    description: "offertype text type",
    related: "OffertypeTextType",
  })
  public texttype!: number;

  @foreignKeyField({
    name: "texttype_text",
    description: "offertype text",
    related: "OffertypeText",
  })
  public texttype_text!: number;
}
