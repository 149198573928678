import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'lva-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.pug',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent {
  public env = environment;
}
