import { Component } from '@angular/core';
import { GenericregionListViewData } from './genericregion-list-view.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { GenericregionListComponent } from '../../../../models/locations/genericregion/genericregion-list/genericregion-list.component';
import { Genericregion } from '../../../../models/locations/genericregion/genericregion';
import { GenericregionCreateComponent } from '../../../../models/locations/genericregion/genericregion-create/genericregion-create.component';
import { Subject } from 'rxjs';
import { ClientBase } from '../../../../models/structures/client/client.base';
import { Link } from '@solidev/data';
import { GeodepartementBase } from '../../../../models/locations/geodepartement/geodepartement.base';

@Component({
  selector: 'lvadg-genericregion-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, GenericregionListComponent, GenericregionCreateComponent],
  templateUrl: './genericregion-list-view.component.pug',
  styleUrls: ['./genericregion-list-view.component.sass'],
})
export class GenericregionListViewComponent extends BaseListViewComponent<GenericregionListViewData, Genericregion> {
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();
  public client_detail_route?: Link<ClientBase>;
  public geodepartement_detail_route?: Link<GeodepartementBase>;

  public override setRouteData(data: GenericregionListViewData) {
    super.setRouteData(data);
    this.client_detail_route = new Link<ClientBase>(data.client_detail_route, data, this._router);
    if (data.geodepartement_detail_route) {
      this.geodepartement_detail_route = new Link<GeodepartementBase>(
        data.geodepartement_detail_route,
        data,
        this._router,
      );
    }
  }
}
