
<ng-template #ofc let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Propriétés de l'image</h4>
  </div>
  <div class="offcanvas-body" *ngIf="logo$">
    <lvadg-image-manage [image]="logo$" mode="offcanvas" [model]="model" [endpoints]="endpoints" (updated)="updated.emit(model); reload()" (closed)="offcanvas.dismiss()"></lvadg-image-manage>
  </div>
</ng-template>
<ng-template #noLogo>
  <div class="mt-4 text-center text-danger">Aucun logo</div>
</ng-template>
<ng-container>
  <div class="row g-3">
    <div [class.col]="mode==='row'" [class.col-12]="mode==='column'">
      <ng-container *ngIf="logo$ | async as logo; else noLogo">
        <div class="text-center">
          <lvadg-image-display [image]="logo" mode="preview"></lvadg-image-display>
          <div class="mx-auto mt-1">
            <div *ngIf="logo.title || logo.credits">
              <div *ngIf="logo.title"><span class="badge bg-secondary me-1">Titre</span>{{logo.title}}</div>
              <div class="small" *ngIf="logo.credits"><span class="badge bg-secondary me-1">Crédits</span>{{logo.credits}}</div>
            </div>
            <div class="mb-2 w-50 mx-auto"><span class="badge bg-primary me-1" *ngFor="let usage of logo.usages">{{IMAGE_USAGE[usage]}}</span></div>
            <button class="btn-sm btn btn-outline-primary me-2" (click)="edit(logo)">
              <lvadg-icon ri="edit">Propriétés</lvadg-icon>
            </button>
            <button class="btn-sm btn btn-outline-danger" (click)="removeLogo()">
              <lvadg-icon ri="delete">Supprimer</lvadg-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div [class.col]="mode==='row'" [class.col-12]="mode==='column'">
      <lvadg-media-upload [model]="model" [prepare]="uploadLogo" [service]="collection" (uploadDone)="updated.emit(model); reload()"></lvadg-media-upload>
    </div>
  </div>
</ng-container>