
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { UsageService} from "./usage.service";
import { Usage } from "./usage";

@Injectable({
  providedIn: 'root'
})
export class UsageResolver  {
  constructor(private _rs: UsageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Usage> {
    return this._rs.fetch(+route.params["usageId"])
  }
}
