import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { TariftypeLinksManageComponent } from '../../../../../models/tarifs/tariftype/tariftype-links-manage/tariftype-links-manage.component';
import { TariftypeRelationBase } from '../../../../../models/tarifs/tariftype-relation/tariftype-relation.base';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeRelationsViewParams extends BaseRouteParams {
  tariftyperelation_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeRelationsViewData extends TariftypeRelationsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-relations-view',
  standalone: true,
  imports: [
    CommonModule,
    TariftypeLinksManageComponent,
    HeaderActionComponent,
    IconComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-relations-view.component.pug',
  styleUrls: ['./tariftype-relations-view.component.sass'],
})
export class TariftypeRelationsViewComponent extends BaseDetailViewComponent<TariftypeRelationsViewData, Tariftype> {
  public tariftyperelation_detail_route!: Link<TariftypeRelationBase>;
  public tariftype_detail_route!: Link<Tariftype>;

  public override setRouteData(data: TariftypeRelationsViewData) {
    super.setRouteData(data);
    this.tariftyperelation_detail_route = new Link(data.tariftyperelation_detail_route, data, this._router);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
  }
}
