import { Inject, Injectable } from '@angular/core';
import {
  AuthParams,
  AuthServiceBase,
  DATA_AUTH_PARAMS,
  DATA_AUTH_USER_SERVICE,
  DataMessageService,
} from '@solidev/data';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user/user.service';
import { User } from './user/user';
import { Router } from '@angular/router';

interface UserJwtData {
  token_type: 'access' | 'refresh';
  user_id: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthServiceBase<User, UserService, UserJwtData> {
  constructor(
    @Inject(DATA_AUTH_USER_SERVICE) _userService: UserService,
    @Inject(DATA_AUTH_PARAMS) _params: AuthParams,
    _http: HttpClient,
    _router: Router,
    _msgs: DataMessageService
  ) {
    super(_userService, _params, _router, _http, _msgs);
  }
}
