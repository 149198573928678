import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom, Observable } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { CommonModule } from '@angular/common';
import { Comment } from '../comment';
import { CommentService } from '../comment.service';
import { User } from '../../../users/user/user';
import { AuthService } from '../../../users/auth.service';
import { News } from '../../news/news';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Component({
  standalone: true,
  selector: 'lvadg-comment-create',
  templateUrl: './comment-create.component.pug',
  styleUrls: ['./comment-create.component.sass'],
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent, PickerModule],
})
export class CommentCreateComponent implements OnInit {
  @ViewChild('description', { read: ElementRef }) descriptionElement!: ElementRef;
  @Input() public description: string = '';
  public createForm = new FormGroup({
    description: new FormControl<string>('', { nonNullable: true }),
  });
  public comment!: Comment;
  @Output() public created = new EventEmitter<Comment>();
  @Output() public cancelled = new EventEmitter<void>();
  public user!: User | null;
  @Input() public news!: News;
  public fcomment!: Observable<Comment[]>;
  showEmojiPicker = false;
  sets = ['native', 'google', 'twitter', 'facebook', 'emojione', 'apple', 'messenger'];
  set = 'twitter';

  constructor(
    private _comment: CommentService,
    private _msgs: DataMessageService,
    private _auth: AuthService,
  ) {}

  public async ngOnInit() {
    this.createForm.reset();
    if (!this.user) {
      this.user = await firstValueFrom(this._auth.currentUser$);
    }
    this.comment = new Comment(this._comment);
    // Get last comments
    this.fcomment = this._comment.list({
      news: this.news.id,
      page_size: 5,
      sort: '-id',
    });
  }

  public async save() {
    this.comment.fromJson(
      {
        user: this.user?.id,
        news: this.news?.id,
        description: this.createForm.value.description!,
      },
      { partial: true },
    );

    try {
      await firstValueFrom(this.comment.save({ updateModel: true }));
      this.created.emit(this.comment);
      this._msgs.success('Commentaire envoyé avec succès');
      await this.ngOnInit();
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }

  public async toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  public addEmoji(event: EmojiEvent) {
    this.descriptionElement.nativeElement.value = `${this.descriptionElement.nativeElement.value}${event.emoji.native}`;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
}
