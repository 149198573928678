
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { AtscacheService} from "./atscache.service";
import { Atscache } from "./atscache";

@Injectable({
  providedIn: 'root'
})
export class AtscacheResolver  {
  constructor(private _rs: AtscacheService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Atscache> {
    return this._rs.fetch(+route.params["atscacheId"])
  }
}
