
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifDocument } from '../tarif-document';
@Component({
  selector: 'lvadg-tarif-document-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-document-manage.component.pug',
  styleUrls: ['./tarif-document-manage.component.sass']
})
export class TarifDocumentManageComponent implements OnInit {
  @Input() public tarifdocument?: TarifDocument;

  constructor() { }

  ngOnInit(): void {
  }

}
