import { Component } from '@angular/core';

import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TarifNotificationStatus } from '../../../../models/tarifs/tarif-notification-status/tarif-notification-status';
import { TarifNotificationStatusListComponent } from '../../../../models/tarifs/tarif-notification-status/tarif-notification-status-list/tarif-notification-status-list.component';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface TarifNotificationStatusListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface TarifNotificationStatusListViewData extends TarifNotificationStatusListViewParams {}

@Component({
  selector: 'lvadg-tarif-notification-status-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifNotificationStatusListComponent],
  templateUrl: './tarif-notification-status-list-view.component.pug',
  styleUrls: ['./tarif-notification-status-list-view.component.sass'],
})
export class TarifNotificationStatusListViewComponent extends BaseListViewComponent<
  TarifNotificationStatusListViewData,
  TarifNotificationStatus
> {
  public override setRouteData(data: TarifNotificationStatusListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
