import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults } from '@solidev/data';
import { map, Observable } from 'rxjs';
import { Family } from '../../../../models/catalog/family/family';
import { BASE_VIEW_IMPORTS } from '../../../../components/baseview/baseview.imports';
import { BaseViewComponent } from '../../../../components/baseview/baseview.component';
import {
  TarifArticlesStatsComponent
} from "../../../../models/tarifs/stats/tarif-articles-stats/tarif-articles-stats.component";

export interface TarifStatsFamilyViewParams extends BaseRouteParams {}

export interface TarifStatsFamilyViewData extends TarifStatsFamilyViewParams {
  family: Family;
}

@Component({
  selector: 'lvadg-tarif-stats-family-view',
  standalone: true,
  imports: [...BASE_VIEW_IMPORTS, TarifArticlesStatsComponent],
  templateUrl: './tarif-stats-family-view.component.pug',
  styleUrls: ['./tarif-stats-family-view.component.sass'],
})
export class TarifStatsFamilyViewComponent extends BaseViewComponent<TarifStatsFamilyViewData> {
  public articles_default_fields = [
    'id',
    'family_details',
    'libelle',
    'udf_unit',
    'egalim_details',
    'categorie_details',
    'calibre_details',
    'period_stats_price',
    'period_stats_sdev',
    'interval_stats',
  ];
  public filter!: Observable<FilterDefaults>;

  public override setRouteData(data: TarifStatsFamilyViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.filter = this.data$.pipe(
      map((d) => {
        return { with_stat: 1, in_family: d.family.id };
      }),
    );
  }
}
