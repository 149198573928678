import { PlatformBase } from './platform.base';
import { detailsField } from '@solidev/data';
import { ProviderBase } from '../provider/provider.base';

export class Platform extends PlatformBase {
  static override readonly __name: string = 'Platform';
  @detailsField({
    name: 'provider_details',
    description: 'Fournisseur',
    model: ProviderBase,
    readonly: true,
  })
  public provider_details?: ProviderBase;
}
