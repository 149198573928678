
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarifItem } from '../article-tarif-item';
@Component({
  selector: 'lvadg-article-tarif-item-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-tarif-item-manage.component.pug',
  styleUrls: ['./article-tarif-item-manage.component.sass']
})
export class ArticleTarifItemManageComponent implements OnInit {
  @Input() public articletarifitem?: ArticleTarifItem;

  constructor() { }

  ngOnInit(): void {
  }

}
