import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from '../../../documents/upload/media-upload/media-upload.component';
import { DispeditComponent, UploadFile } from '@solidev/data';
import { Producer } from '../../producer/producer';
import { ProducerDocument } from '../producer-document';
import { ProducerService } from '../../producer/producer.service';
import { ProducerDocumentService } from '../producer-document.service';
import { PRODUCERDOCUMENT_TYPES } from '../producer-document.base';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lvadg-producer-document-create',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MediaUploadComponent,
    DispeditComponent,
  ],
  templateUrl: './producer-document-create.component.pug',
  styleUrls: ['./producer-document-create.component.sass'],
})
export class ProducerDocumentCreateComponent implements OnInit {
  @Input() public producer?: Producer;
  @Output() public uploaded = new EventEmitter<
    UploadResult<ProducerDocument, { data: ProducerDocument }>
  >();
  @Output() public created = new EventEmitter<ProducerDocument>();
  public createForm = new FormGroup({
    producer: new FormControl<number | null>(null),
    type: new FormControl<string | null>(PRODUCERDOCUMENT_TYPES.CERTIFICATION),
  });
  public doc!: ProducerDocument;
  public uploadPrepare: (
    model: ProducerDocument,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<ProducerDocument>> =
    this._uploadPrepare.bind(this);

  constructor(
    public producers$: ProducerService,
    public producerdocument$: ProducerDocumentService
  ) {}

  ngOnInit(): void {
    this.doc = new ProducerDocument(this.producerdocument$);
    if (this.producer) {
      this.doc.producer = this.producer.id;
      this.doc.producer_details = this.producer;
    }
    this.createForm = this.doc.FC(['producer', 'type']);
    this.uploaded.subscribe((result) => {
      if (result.response?.data) {
        this.created.emit(result.response.data);
      }
    });
  }

  private async _uploadPrepare(
    model: ProducerDocument,
    file: UploadFile
  ): Promise<IPreparedUploadFile<ProducerDocument>> {
    let producer_id = this.producer?.id;
    if (!producer_id) {
      if (this.createForm.value.producer) {
        producer_id = this.createForm.value.producer;
      }
    }
    if (!producer_id) {
      throw new Error('No producer id');
    }
    return {
      model,
      url: this.producers$.getUrl(producer_id, { suffix: '/action' }),
      data: {
        action: 'add_document',
        producer: producer_id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        type: this.createForm.value.type,
      },
    };
  }
}
