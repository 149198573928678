import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offertype } from '../offertype';
import { DispeditComponent } from '@solidev/data';
import { ClientService } from '../../../structures/client/client.service';
import { TariftypeService } from '../../../tarifs/tariftype/tariftype.service';
import { RruleEditorComponent } from '../../../../components/utils/rrule-editor/rrule-editor.component';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../../users/user/user.service';

@Component({
  selector: 'lvadg-offertype-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, RruleEditorComponent],
  templateUrl: './offertype-manage.component.pug',
  styleUrls: ['./offertype-manage.component.sass'],
})
export class OffertypeManageComponent {
  @Input() public offertype?: Offertype;

  constructor(
    public clients$: ClientService,
    public users$: UserService,
    public tariftypes$: TariftypeService,
  ) {}

  public async saveRrule(offertype: Offertype, $event: string) {
    offertype.rrule = $event;
    await firstValueFrom(offertype.save());
  }
}
