export const META_ARTICLE_LIST_FIELDS = [
  'id',
  'code',
  'libelle',
  'udf_unit',
  'udv',
  'egalim_details',
  'origine_details',
  'local',
  'egalim_status',
  'calibre_details',
  'categorie_details',
  'colisage',
  'uci',
  'flags',
  'updated',
];
