
<ng-container *ngIf="data$|async; let data">
  <lvadg-offer-nav [routes]="data.o_menu" [offer]="data.offer" [offertypestorage]="data.offertypestorage" *ngIf="data.o_menu"></lvadg-offer-nav>
  <div class="container">
    <lvadg-header-action [title]="data.offer.title" level="h1" subtitle="Publication de l'offre" ri="offer"></lvadg-header-action>
    <div class="row g-3 mt-3 mb-3">
      <div class="col-12">
        <div class="fs-4 p-2 fw-bold text-center text-uppercase">État de l'offre : {{data.offer.status | pchoice:data.offer:'status'}}</div>
      </div>
      <div class="col-6">
        <button class="btn btn-lg btn-outline-primary p-3 w-100" (click)="publishOffer(data.offer).then()" *ngIf="data.offer.status == OFFER_STATUSES.PREPARATION">
          <lvadg-icon ri="publish">Publier l'offre</lvadg-icon>
        </button>
        <button class="btn btn-lg btn-outline-warning p-3 w-100" (click)="unPublishOffer(data.offer).then()" *ngIf="data.offer.status == OFFER_STATUSES.PUBLISHED">
          <lvadg-icon ri="publish">Dépublier l'offre</lvadg-icon>
        </button>
        <button class="btn btn-lg btn-success p-3 w-100" *ngIf="data.offer.status == OFFER_STATUSES.PUBLISHED_SENT" [disabled]="true">
          <lvadg-icon ri="publish">Offre publiée et envoyée</lvadg-icon>
        </button>
        <button class="btn btn-lg btn-secondary p-3 w-100" *ngIf="data.offer.status == OFFER_STATUSES.ARCHIVED" [disabled]="true">
          <lvadg-icon ri="archive">Offre archivée (publiée)</lvadg-icon>
        </button>
        <button class="btn btn-lg btn-outline-secondary p-3 w-100" *ngIf="data.offer.status == OFFER_STATUSES.ARCHIVED_NOT_PUBLISHED" [disabled]="true">
          <lvadg-icon ri="archive">Offre archivée (non publiée)</lvadg-icon>
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-lg btn-outline-primary p-3 w-100" (click)="createNotification=!createNotification">
          <lvadg-icon ri="add">Créer un envoi</lvadg-icon>
        </button>
      </div>
    </div>
    <div class="card my-2" *ngIf="createNotification">
      <div class="card-header">
        <lvadg-icon ri="add">Créer un envoi</lvadg-icon>
      </div>
      <div class="card-body">
        <lvadg-offer-notification-create [offer]="data.offer" (created)="createNotification=false; reload$.next(true)" (cancelled)="createNotification=false"></lvadg-offer-notification-create>
      </div>
    </div>
    <h2 class="mt-3">
      <lvadg-icon ri="send">Historique des envois
        <lvadg-icon class="float-end" ri="reload" (click)="refresh(data.offer)" role="button" [icon_only]="true"></lvadg-icon>
      </lvadg-icon>
    </h2>
    <lvadg-offer-notification-list [name]="data.route.name" [filter]="offerFilter$" [default_filters]="[]" [detail_route]="offer_notification_statuses_route" [actions]="['send', 'cancel']" (action)="onNotificationAction(data.offer, $event)" [reload]="reload$" [displayMode]="'ml-hidden'" [dispLoading]="false"></lvadg-offer-notification-list>
  </div>
</ng-container>