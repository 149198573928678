import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutData, LayoutTree } from '../../layout/layout';
import { TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from '../../layout/template';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from '../../../documents/upload/media-upload/media-upload.component';
import { PrintDocument } from '../../print-document/print-document';
import { UploadFile } from '@solidev/data';
import { PrintService } from '../../print/print.service';
import { PrintDocumentService } from '../../print-document/print-document.service';
import { OpLog } from '../../../../components/utils/oplog/oplog';

export interface PrintDocumentUploadResponse {
  log: OpLog;
  message: string;
  data: { file: string };
}

@Component({
  selector: 'lvadg-layout-template-provider-upload-image',
  standalone: true,
  imports: [CommonModule, MediaUploadComponent],
  templateUrl: './layout-template-provider-upload-image.component.pug',
  styleUrls: ['./layout-template-provider-upload-image.component.sass'],
})
export class LayoutTemplateProviderUploadImageComponent {
  @Input() public tree!: LayoutTree;
  @Input() public layout!: LayoutData;
  @Input() public fragment!: TemplateFragment;
  @Input() public param!: TemplateParam;
  @Input() public provider!: TemplateProvider;
  public disp_custom = false;
  public uploadPrepare: (model: PrintDocument, file: UploadFile) => Promise<IPreparedUploadFile<PrintDocument>> =
    this._uploadPrepare.bind(this);

  constructor(
    public prints$: PrintService,
    public pdocs$: PrintDocumentService,
  ) {}

  public select(choice: TemplateChoice) {
    this.tree.set_fragment_param({
      layout: this.layout,
      group: this.layout.group,
      fragment: this.fragment,
      param: this.param,
      provider: this.provider,
      choice,
      value: choice.name,
    });
  }

  uploadDone($event: UploadResult<PrintDocument, PrintDocumentUploadResponse>) {
    if (!$event.response) return;
    this.tree.set_fragment_param({
      layout: this.layout,
      group: this.layout.group,
      fragment: this.fragment,
      param: this.param,
      provider: this.provider,
      choice: this.provider.choices[0]!,
      value: $event.response.data.file,
    });
  }

  public custom(c: TemplateChoice) {
    console.log('custom', c);
    this.disp_custom = !this.disp_custom;
  }

  private async _uploadPrepare(model: PrintDocument, file: UploadFile): Promise<IPreparedUploadFile<PrintDocument>> {
    return {
      model,
      url: this.prints$.getUrl(this.tree.print.id, { suffix: '/actions' }),
      data: {
        action: 'add_document',
        print: this.tree.print.id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
      },
    };
  }
}
