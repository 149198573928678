
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { RegionService} from "./region.service";
import { Region } from "./region";

@Injectable({
  providedIn: 'root'
})
export class RegionResolver  {
  constructor(private _rs: RegionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Region> {
    return this._rs.fetch(+route.params["regionId"])
  }
}
