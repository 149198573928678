import {
  charField,
  DataModel,
  datetimeField,
  decimalField,
  detailsField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';
import { Units, UnitsChoices } from '../../catalog/common/units';
import { OrderProcessUserLevel } from '../orderprocess/orderprocess.base';

export enum OrderItemStatus {
  PREPARATION = 'PRP',
  VALIDATED = 'VAL',
  CONSOLIDATED = 'CNS',
  SENT = 'SNT',
  CLOSED = 'CLS',
  CANCELLED = 'CAN',
  EMPTY = 'EMP',
}

export class OrderitemBase extends DataModel {
  static override readonly __name: string = 'OrderitemBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'order',
    description: 'ID Commande',
    related: 'Order',
  })
  public order!: number;

  @foreignKeyField({
    name: 'item',
    description: 'ID Article',
    related: 'OrderProcessArticle',
  })
  public item!: number;

  @decimalField({
    name: 'quantity',
    description: 'Quantité',
    factor: 100000,
    defaultValue: 0,
  })
  public quantity: number = 0;

  @decimalField({
    name: 'total_price',
    description: 'Total HT',
    required: false,
    factor: 10000,
  })
  public total_price?: number;

  @decimalField({
    name: 'total_weight',
    description: 'Poids total',
    required: false,
    factor: 100000,
  })
  public total_weight?: number;

  // Article infos mixin
  @decimalField({
    name: 'unit_price',
    description: 'Prix HT / UDF',
    required: false,
    factor: 10000,
  })
  public unit_price?: number;

  @decimalField({
    name: 'unit_weight',
    description: 'Poids / UDV (kg)',
    required: false,
    factor: 100000,
  })
  public unit_weight?: number;

  @charField({
    name: 'colisage',
    description: 'Colisage',
    required: false,
  })
  public colisage?: string;

  @charField({
    name: 'udv_unit',
    description: 'Unité de vente',
    maxLength: 2,
    choices: UnitsChoices,
  })
  public udv_unit!: Units;

  @charField({
    name: 'udf_unit',
    description: 'Unité de facturation',
    maxLength: 2,
    choices: UnitsChoices,
  })
  public udf_unit!: Units;

  @decimalField({
    name: 'udv_to_udf_ratio',
    description: 'Ratio udv - udf',
    required: false,
    factor: 100000,
  })
  public udv_to_udf_ratio?: number;

  @decimalField({
    name: 'delivered_total_price',
    description: 'Total HT (livraison)',
    required: false,
    factor: 10000,
  })
  public delivered_total_price?: number;

  @decimalField({
    name: 'delivered_total_weight',
    description: 'Poids total (livraison)',
    required: false,
    factor: 100000,
  })
  public delivered_total_weight?: number;

  @decimalField({
    name: 'delivered_quantity',
    description: 'Total UDV (livraison)',
    required: false,
    factor: 100000,
  })
  public delivered_quantity?: number;

  @charField({
    name: 'status',
    description: 'État',
    maxLength: 3,
    defaultValue: OrderItemStatus.PREPARATION,
    choices: [
      { value: OrderItemStatus.PREPARATION, desc: 'En préparation' },
      { value: OrderItemStatus.VALIDATED, desc: 'Validée' },
      { value: OrderItemStatus.CONSOLIDATED, desc: 'Consolidée' },
      { value: OrderItemStatus.SENT, desc: 'Envoyée' },
      { value: OrderItemStatus.CLOSED, desc: 'Fermée' },
      { value: OrderItemStatus.CANCELLED, desc: 'Annulée' },
      { value: OrderItemStatus.EMPTY, desc: 'Aucune quantité' },
    ],
  })
  public status: OrderItemStatus = OrderItemStatus.EMPTY;

  @detailsField({
    name: 'process_user_level',
    description: 'Permissions utilisateur (processus)',
    deserialize: (d) => d,
  })
  public process_user_level: OrderProcessUserLevel[] = [];

  @detailsField({
    name: 'pstorage_user_level',
    description: 'Permissions utilisateur (dépôt)',
    deserialize: (d) => d,
  })
  public pstorage_user_level: OrderProcessUserLevel[] = [];

  public have_process_level(level: OrderProcessUserLevel): boolean {
    return this.process_user_level.indexOf(level) !== -1;
  }

  public have_pstorage_level(level: OrderProcessUserLevel): boolean {
    return this.pstorage_user_level.indexOf(level) !== -1;
  }
}
