import { Component } from '@angular/core';
import { LocationDetailViewData } from './location-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { LocationManageComponent } from '../../../../models/locations/location/location-manage/location-manage.component';
import { Location } from '../../../../models/locations/location/location';

@Component({
  selector: 'lvadg-location-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, LocationManageComponent],
  templateUrl: './location-detail-view.component.pug',
  styleUrls: ['./location-detail-view.component.sass'],
})
export class LocationDetailViewComponent extends BaseDetailViewComponent<LocationDetailViewData, Location> {
  public override setRouteData(data: LocationDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
