import { RouteConfigItem } from '@solidev/data';

export interface CLIENT_ROUTES {
  client_display_route?: RouteConfigItem;
  client_edit_route?: RouteConfigItem;
  client_restos_route?: RouteConfigItem;
  client_offers_route?: RouteConfigItem;
  client_relations_route?: RouteConfigItem;
  client_sales_route?: RouteConfigItem;
  client_stats_route?: RouteConfigItem;
  client_tariftypes_route?: RouteConfigItem;
  client_earticles_route?: RouteConfigItem;
  client_atts_route?: RouteConfigItem;
  client_users_route?: RouteConfigItem;
}

export interface CLIENT_LINKS_ROUTES {
  tariftype_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  att_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  collection_detail_route?: RouteConfigItem;
  member_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  producer_detail_route?: RouteConfigItem;
  relation_detail_route?: RouteConfigItem;
  article_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
}
