import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferViewData, OfferViewParams } from '../menu';
import { FilterDefaults, RouteConfigItem } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Offer } from '../../../../../models/offers/offer/offer';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OfferNavComponent } from '../_nav/offer-nav.component';
import { map, Observable, Subject } from 'rxjs';
import { OfferDocumentListComponent } from '../../../../../models/offers/offer-document/offer-document-list/offer-document-list.component';

export interface OfferDocumentsViewParams extends OfferViewParams {
  offerdocument_detail_route?: RouteConfigItem;
}

export interface OffertDocumentsViewData extends OfferViewData, OfferDocumentsViewParams {}

@Component({
  selector: 'lvadg-offer-documents-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OfferDocumentListComponent, OfferNavComponent],
  templateUrl: './offer-documents-view.component.pug',
  styleUrls: ['./offer-documents-view.component.sass'],
})
export class OfferDocumentsViewComponent extends BaseDetailViewComponent<OffertDocumentsViewData, Offer> {
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public offerFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = ['type', 'resto_details', 'image_details', 'file', 'status', 'created'];

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offerFilter$ = this.data$.pipe(map((data) => ({ offer: data.offer.id })));
  }
}
