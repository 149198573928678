import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ordergroup } from '../ordergroup';
import { map, Observable } from 'rxjs';
import { DispeditComponent } from '@solidev/data';
import { OrderprocessService } from '../../orderprocess/orderprocess.service';

@Component({
  selector: 'lvadg-ordergroup-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './ordergroup-manage.component.pug',
  styleUrls: ['./ordergroup-manage.component.sass'],
})
export class OrdergroupManageComponent implements OnInit {
  @Input() public data$!: Observable<{ ordergroup: Ordergroup }>;
  public ordergroup$!: Observable<Ordergroup>;

  constructor(public orderprocesses$: OrderprocessService) {}

  ngOnInit(): void {
    this.ordergroup$ = this.data$.pipe(map((d) => d.ordergroup));
  }
}
