import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
} from '@solidev/data';
import { Units, UnitsChoices } from '../../catalog/common/units';
import { OrigineGlob, OriginGlobChoices } from '../../catalog/common/origins';

export class RnmBase extends DataModel {
  static override readonly __name: string = 'RnmBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @integerField({
    name: 'cvva',
    description: 'Code Vivalya',
  })
  public cvva!: number;

  @charField({
    name: 'name',
    description: 'Libellé',
    maxLength: 300,
  })
  public name!: string;

  @charField({
    name: 'cotation_unit',
    description: 'Unité de cotation',
    defaultValue: Units.NONE,
    maxLength: 1,
    choices: UnitsChoices,
  })
  public cotation_unit: Units = Units.NONE;

  @integerField({
    name: 'cotation_value',
    description: 'Cotation par nombre',
  })
  public cotation_value!: number;

  @foreignKeyField({
    name: 'family',
    description: 'ID Famille',
    related: 'Family',
  })
  public family!: number;

  @foreignKeyField({
    name: 'calibre',
    description: 'ID Calibre',
    related: 'Calibre',
  })
  public calibre!: number;

  @foreignKeyField({
    name: 'categorie',
    description: 'ID Catégorie',
    related: 'Category',
  })
  public categorie!: number;

  @foreignKeyField({
    name: 'origine',
    description: 'ID Origine',
    related: 'Origin',
  })
  public origine!: number;

  @charField({
    name: 'origine_glob',
    description: 'Origine globale',
    defaultValue: OrigineGlob.NOTSET,
    maxLength: 3,
    choices: OriginGlobChoices,
  })
  public origine_glob: OrigineGlob = OrigineGlob.NOTSET;

  @foreignKeyField({
    name: 'process',
    description: 'ID Process',
    related: 'Process',
  })
  public process!: number;

  @foreignKeyField({
    name: 'traitement',
    description: 'ID Traitement',
    related: 'Treatment',
  })
  public traitement!: number;

  @foreignKeyField({
    name: 'region',
    description: 'ID Région',
    related: 'Region',
  })
  public region!: number;

  @foreignKeyField({
    name: 'presentation',
    description: 'ID Présentation',
    related: 'Presentation',
  })
  public presentation!: number;

  @foreignKeyField({
    name: 'particularite',
    description: 'ID Particularité',
    related: 'Particularity',
  })
  public particularite!: number;

  @foreignKeyField({
    name: 'production',
    description: 'ID Production',
    related: 'Production',
  })
  public production!: number;

  @integerField({
    name: 'uci_value',
    description: 'Valeur UCI',
  })
  public uci_value!: number;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;

  @manyToManyField({
    name: 'egalim',
    description: 'IDs labels et certifications',
    defaultValue: [],
    related: 'Label',
  })
  public egalim: number[] = [];
}
