
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-geocountry-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './geocountry-create.component.pug',
  styleUrls: ['./geocountry-create.component.sass']
})
export class GeocountryCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
