import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../user';
import { DispeditComponent, FlagsComponent } from '@solidev/data';
import { AuthService } from '../../auth.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'lvadg-user-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, FlagsComponent],
  templateUrl: './user-manage.component.pug',
  styleUrls: ['./user-manage.component.sass'],
})
export class UserManageComponent implements OnInit {
  @Input() public user!: User;
  public isSuperUser?: Observable<boolean>;

  constructor(private _auth: AuthService) {}

  ngOnInit(): void {
    this.isSuperUser = this._auth.currentUser$.pipe(
      map((u) => !!u?.is_superuser)
    );
  }
}
