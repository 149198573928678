import { charField, foreignKeyField } from '@solidev/data';
import { ArticleMetaBase, META_BASE_TYPE } from '../meta.base';

export enum BRAND_TYPE {
  UNKNOWN = 'MS',
}

export class BrandBase extends ArticleMetaBase {
  static override readonly __name: string = 'BrandBase';

  @foreignKeyField({
    name: 'producer',
    description: 'ID Producteur',
    related: 'Producer',
    priority: -1,
  })
  public producer!: number;

  @foreignKeyField({
    name: 'provider',
    description: 'ID Fournisseur',
    related: 'Provider',
    priority: -1,
  })
  public provider!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: BRAND_TYPE.UNKNOWN,
    required: true,
    maxLength: 2,
    choices: [
      {
        value: META_BASE_TYPE.UNKNOWN,
        desc: 'Non précisé',
      },
    ],
    priority: 800,
  })
  public override type: string = BRAND_TYPE.UNKNOWN;
}
