
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { ProviderService} from "./provider.service";
import { Provider } from "./provider";

@Injectable({
  providedIn: 'root'
})
export class ProviderResolver  {
  constructor(private _rs: ProviderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Provider> {
    return this._rs.fetch(+route.params["providerId"])
  }
}
