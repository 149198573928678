
<input class="form-control form-control-sm" [formControl]="search" placeholder="Recherche - 3 caracteres min, 30 premiers résultats">
<div class="overflow-auto vh-75 mt-3 p-3">
  <cdk-tree [dataSource]="tree.data" [treeControl]="tree.control">
    <cdk-nested-tree-node class="d-block" *cdkTreeNodeDef="let node"><span class="pe-1" cdkTreeNodeToggle aria-label="toggle tree node" role="button" [cdkTreeNodeToggleRecursive]="false" *ngIf="node.children.length"><i class="bi bi-chevron-down" *ngIf="tree.control.isExpanded(node)"></i><i class="bi bi-chevron-right" *ngIf="!tree.control.isExpanded(node)"></i></span><a class="text-decoration-none text-uppercase user-select-none" [routerLink]="detail_route?.route({family: node})" role="button" (click)="clickNode(node)" [class.text-success]="tree.highlighted.has(node.id)" [class.fw-bold]="tree.current===node.id">{{node.name}}  [{{node.nbsubarticles}}/{{node.nbsubproducts}}]</a>
      <div class="ms-3" *ngIf="tree.control.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>