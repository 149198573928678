import { Component } from '@angular/core';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeDocument } from '../../../../models/tarifs/tariftype-document/tariftype-document';
import { TariftypeDocumentListComponent } from '../../../../models/tarifs/tariftype-document/tariftype-document-list/tariftype-document-list.component';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { TARIFTYPE_ROUTES } from '../../tariftypes/detail/menu';
import { CommonModule } from '@angular/common';
import { TariftypeDocumentCreateComponent } from '../../../../models/tarifs/tariftype-document/tariftype-document-create/tariftype-document-create.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { TariftypeDetailNavComponent } from '../../tariftypes/detail/nav/tariftype-detail-nav.component';
import { map, Observable, Subject } from 'rxjs';
import { TariftypeDocumentBase } from '../../../../models/tarifs/tariftype-document/tariftype-document.base';
import { TariftypeMetadataBase } from '../../../../models/tarifs/tariftype-metadata/tariftype-metadata.base';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';

export interface TariftypeDocumentListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tariftypedocument_detail_route: RouteConfigItem;
  tariftypemetadata_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeDocumentListViewData extends TariftypeDocumentListViewParams {
  tariftype?: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-document-list-view',
  standalone: true,
  imports: [
    CommonModule,
    TariftypeDocumentCreateComponent,
    TariftypeDocumentListComponent,
    HeaderActionComponent,
    IconComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-document-list-view.component.pug',
  styleUrls: ['./tariftype-document-list-view.component.sass'],
})
export class TariftypeDocumentListViewComponent extends BaseListViewComponent<
  TariftypeDocumentListViewData,
  TariftypeDocument
> {
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public tariftypedocument_detail_route?: Link<TariftypeDocumentBase>;
  public tariftypemetadata_detail_route?: Link<TariftypeMetadataBase>;
  public tariftype_detail_route?: Link<Tariftype>;
  public createTarif = false;

  public override setRouteData(data: TariftypeDocumentListViewData) {
    super.setRouteData(data);
    this.tariftypedocument_detail_route = new Link(data.tariftypedocument_detail_route, data, this._router);
    this.tariftypemetadata_detail_route = new Link(data.tariftypemetadata_detail_route, data, this._router);
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return d.tariftype ? { tariftype: d.tariftype.id } : {};
      }),
    );
  }
}
