import { Component } from '@angular/core';

import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { ArticleTarifTemplate } from '../../../../models/tarifs/article-tarif-template/article-tarif-template';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { ArticleBase } from '../../../../models/catalog/article/article.base';
import { EarticleBase } from '../../../../models/catalog/earticle/earticle.base';
import { ArticleTarifTemplateListComponent } from '../../../../models/tarifs/article-tarif-template/article-tarif-template-list/article-tarif-template-list.component';

export interface ArticleTarifTemplateListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  earticle_detail_route: RouteConfigItem;
}

export interface ArticleTarifTemplateListViewData extends ArticleTarifTemplateListViewParams {}

@Component({
  selector: 'lvadg-article-tarif-template-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, ArticleTarifTemplateListComponent],
  templateUrl: './article-tarif-template-list-view.component.pug',
  styleUrls: ['./article-tarif-template-list-view.component.sass'],
})
export class ArticleTarifTemplateListViewComponent extends BaseListViewComponent<
  ArticleTarifTemplateListViewData,
  ArticleTarifTemplate
> {
  public default_fields = [
    'id',
    'tariftype_details',
    'name',
    'article__code',
    'article_details',
    'article__uf',
    'article__family',
  ];
  public tariftype_detail_route?: Link<TariftypeBase>;
  public article_detail_route?: Link<ArticleBase>;
  public earticle_detail_route?: Link<EarticleBase>;

  public override setRouteData(data: ArticleTarifTemplateListViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, this);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, this);
    this.earticle_detail_route = new Link<EarticleBase>(data.earticle_detail_route, this);
  }
}
