
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu"></lvadg-tariftype-detail-nav>
  <lvadg-header-action [title]="data.tariftype.name" subtitle="Destinataires des notifications" level="h2" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-primary btn-sm" (click)="createDestination=!createDestination">
        <lvadg-icon ri="plus">Ajouter un destinataire</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createDestination">
    <div class="card-header">Ajouter un contact</div>
    <div class="card-body">
      <lvadg-tarif-notification-destination-create [tariftype]="data.tariftype" (cancelled)="createDestination=false" (created)="reload$.next(true); createDestination=false"></lvadg-tarif-notification-destination-create>
    </div>
  </div>
  <lvadg-tarif-notification-destination-list [name]="data.route.name+'-'+data.tariftype.code" [filter]="tarifTypeFilter$" [default_fields]="tndest_default_fields" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [actions]="['remove']" (action)="destinationAction(data.tariftype, $event).then()" [reload]="reload$"></lvadg-tarif-notification-destination-list>
</ng-container>