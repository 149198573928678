import {
  booleanField,
  DataModel,
  decimalField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';

export class CiqualValueBase extends DataModel {
  static override readonly __name: string = 'CiqualValueBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: -1,
  })
  public override id!: number;

  @foreignKeyField({
    name: 'alim',
    description: 'Code aliment',
    related: 'CiqualAlim',
    priority: 1000,
  })
  public alim!: number;

  @foreignKeyField({
    name: 'column',
    description: 'ID Colonne',
    related: 'CiqualColumn',
    priority: -1,
  })
  public column!: number;

  @booleanField({
    name: 'less',
    description: 'Inférieur à ?',
    defaultValue: false,
    required: true,
    priority: 900,
  })
  public less: boolean = false;

  @booleanField({
    name: 'traces',
    description: 'Traces ?',
    defaultValue: false,
    required: true,
    priority: 800,
  })
  public traces: boolean = false;

  @decimalField({
    name: 'value',
    description: 'Valeur',
    defaultValue: 0,
    factor: 100000,
    priority: 500,
  })
  public value: number = 0;
}
