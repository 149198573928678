import { Component } from "@angular/core";
import {
  BaseRouteParams,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { Product } from "../../../../../models/catalog/product/product";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProductNavComponent } from "../_nav/product-nav.component";
import { CommonModule } from "@angular/common";
import { PRODUCT_ROUTES } from "../menu";
import { Producer } from "../../../../../models/structures/producer/producer";
import { Storage } from "../../../../../models/structures/storage/storage";
import { filter, Observable, switchMap } from "rxjs";
import { ProducerService } from "../../../../../models/structures/producer/producer.service";
import { ProducerDetailsComponent } from "../../../../../models/structures/producer/producer-details/producer-details.component";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { QuickprintButtonComponent } from "../../../../../models/layouts/print/quickprint-button/quickprint-button.component";
import { IocodeButtonComponent } from "../../../../../models/lvadgio/iocode/iocode-button/iocode-button.component";
import { PrintSettings } from "../../../../../models/layouts/print-settings";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
} from "../../../../../models/catalog/product/product.base";
import { ProducerBase } from "../../../../../models/structures/producer/producer.base";
import { StorageBase } from "../../../../../models/structures/storage/storage.base";

export interface ProductProducerViewParams extends BaseRouteParams {
  p_menu: PRODUCT_ROUTES;
  producer_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  local: LOCAL_FILTERS;
  type?: PRODUCT_TYPES;
}

export interface ProductProducerViewData extends ProductProducerViewParams {
  product: Product;
  producer?: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-product-producer-view",
  standalone: true,
  imports: [
    RouterLink,
    HeaderActionComponent,
    ProductNavComponent,
    ProducerDetailsComponent,
    QuickprintButtonComponent,
    IocodeButtonComponent,
    IconComponent,
    CommonModule,
  ],
  templateUrl: "./product-producer-view.component.pug",
  styleUrl: "./product-producer-view.component.sass",
})
export class ProductProducerViewComponent extends BaseDetailViewComponent<
  ProductProducerViewData,
  Product
> {
  public producer_detail_route!: Link<ProducerBase>;
  public storage_detail_route!: Link<StorageBase>;

  public producer$!: Observable<Producer>;
  public print_settings: PrintSettings = [
    // Affiches producteurs
    {
      label: "Affiche producteur",
      provider: "producer",
      type: "single",
      actions: ["button"],
      template: "producer_poster",
      limit: 100,
    },
    // Fiches producteurs
    {
      label: "Fiche Producteur",
      provider: "producer",
      type: "single",
      actions: ["button"],
      template: "producer_detail",
      limit: 100,
    },
    // Fiches et produits producteurs
    {
      label: "Fiche et produits producteur",
      provider: "producer_with_products",
      type: "single",
      actions: ["button"],
      template: "producer_detail_products",
      limit: 100,
    },
  ];

  // FIXME: add print context

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _producers: ProducerService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.producer$ = this.data$.pipe(
      filter((data) => !!data.product.producer),
      switchMap((data) => {
        return this._producers.fetch(data.product.producer!, {
          params: { local: data.local },
        });
      }),
    );
  }

  public override setRouteData(data: ProductProducerViewData) {
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(
        data.producer_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(
        data.storage_detail_route,
        data,
        this._router,
      );
    }
  }
}
