import { Component } from '@angular/core';
import { OrderprocessstorageDetailViewData } from './orderprocessstorage-detail-view.component.params';
import { map, Observable, Subject } from 'rxjs';
import { FilterDefaults, Link } from '@solidev/data';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { OrderprocessstorageManageComponent } from '../../../../models/orders/orderprocessstorage/orderprocessstorage-manage/orderprocessstorage-manage.component';
import { OrderprocessstoragePermissionListComponent } from '../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission-list/orderprocessstorage-permission-list.component';
import { OrderprocessstoragePermissionCreateComponent } from '../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission-create/orderprocessstorage-permission-create.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Orderprocessstorage } from '../../../../models/orders/orderprocessstorage/orderprocessstorage';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { OrderprocessBase } from '../../../../models/orders/orderprocess/orderprocess.base';
import { OrderprocessstorageBase } from '../../../../models/orders/orderprocessstorage/orderprocessstorage.base';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lvadg-orderprocessstorage-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    OrderprocessstorageManageComponent,
    OrderprocessstoragePermissionListComponent,
    OrderprocessstoragePermissionCreateComponent,
  ],
  templateUrl: './orderprocessstorage-detail-view.component.pug',
  styleUrls: ['./orderprocessstorage-detail-view.component.sass'],
})
export class OrderprocessstorageDetailViewComponent extends BaseDetailViewComponent<
  OrderprocessstorageDetailViewData,
  Orderprocessstorage
> {
  public permissionCreate: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public orderprocess_detail_route!: Link<OrderprocessBase>;
  public orderprocessstorage_detail_route!: Link<OrderprocessstorageBase>;
  public orderprocessstorageFilter$!: Observable<FilterDefaults>;
  public default_fields = [
    'id',
    'process_details',
    'storage_details',
    'platform_details',
    'permission',
    'user_details',
    'group_details',
    'created',
    'updated',
  ];

  public override setRouteData(data: OrderprocessstorageDetailViewData) {
    super.setRouteData(data);
    this.user_detail_route = new Link(data.user_detail_route, data);
    this.group_detail_route = new Link(data.group_detail_route, data);
    this.orderprocess_detail_route = new Link(data.orderprocess_detail_route, data);
    this.orderprocessstorage_detail_route = new Link(data.orderprocessstorage_detail_route, data);
    if (data.orderprocess) {
      this.default_fields = ['id', 'permission', 'user_details', 'group_details', 'created', 'updated'];
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.orderprocessstorageFilter$ = this.data$.pipe(
      map((d) => {
        return { target: d.orderprocessstorage.id };
      }),
      takeUntilDestroyed(this._destroyRef),
    );
  }
}
