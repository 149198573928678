import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifRelation } from '../tarif-relation';
import { TarifRelationService } from '../tarif-relation.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TariftypeRelationDisplayComponent } from '../../tariftype-relation/tariftype-relation-display/tariftype-relation-display.component';
import { TarifDisplayComponent } from '../../tarif/tarif-display/tarif-display.component';
import { TarifBase } from '../../tarif/tarif.base';
import { TariftypeRelationBase } from '../../tariftype-relation/tariftype-relation.base';

@Component({
  selector: 'lvadg-tarif-relation-list',
  standalone: true,
  templateUrl: './tarif-relation-list.component.pug',
  styleUrls: ['./tarif-relation-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    TariftypeRelationDisplayComponent,
    TarifDisplayComponent,
  ],
})
export class TarifRelationListComponent extends ModellistComponent<TarifRelation> {
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public tariftyperelation_detail_route?: Link<TariftypeRelationBase>;

  constructor(
    coll: TarifRelationService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
