import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TARIFTYPE_ROUTES } from '../menu';
import { Link } from '@solidev/data';
import {
  TARIFTYPE_LIFECYCLE,
  TARIFTYPE_REGION_MODE,
  TARIFTYPE_STORAGE_MODE,
  TARIFTYPE_USER_LEVEL,
  TariftypeBase,
} from '../../../../../models/tarifs/tariftype/tariftype.base';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'lvadg-tariftype-detail-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './tariftype-detail-nav.component.pug',
  styleUrls: ['./tariftype-detail-nav.component.sass'],
})
export class TariftypeDetailNavComponent implements OnInit {
  @Input({ required: true }) public routes!: TARIFTYPE_ROUTES;
  @Input({ required: true }) public tariftype!: Tariftype;
  public TTSM = TARIFTYPE_STORAGE_MODE;
  public TTRG = TARIFTYPE_REGION_MODE;
  public TTUL = TARIFTYPE_USER_LEVEL;
  public TTLC = TARIFTYPE_LIFECYCLE;

  public tarifs_detail_route?: Link<TariftypeBase>;
  public tariftype_detail_route?: Link<TariftypeBase>;
  public tariftype_manage_route?: Link<TariftypeBase>;
  public tariftype_documents_route?: Link<TariftypeBase>;
  public tariftype_atts_route?: Link<TariftypeBase>;
  public tariftype_relations_route?: Link<TariftypeBase>;
  public tariftype_storages_route?: Link<TariftypeBase>;
  public tariftype_regions_route?: Link<TariftypeBase>;
  public tariftype_catalog_route?: Link<TariftypeBase>;
  public tariftype_destinations_route?: Link<TariftypeBase>;
  public tariftype_notifications_route?: Link<TariftypeBase>;
  public tariftype_notification_logs_route?: Link<TariftypeBase>;
  public tariftype_permissions_route?: Link<TariftypeBase>;
  public tariftype_tarifs_logs_route?: Link<TariftypeBase>;
  public tariftype_prices_logs_route?: Link<TariftypeBase>;
  public tariftype_lifecycle_route?: Link<TariftypeBase>;
  public tariftype_mappings_route?: Link<TariftypeBase>;

  constructor(private _router: Router) {}

  public ngOnInit() {
    console.log(this.routes);
    if (this.routes.tarifs_detail_route) {
      this.tarifs_detail_route = new Link(this.routes.tarifs_detail_route, this, this._router);
    }
    if (this.routes.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(this.routes.tariftype_detail_route, this, this._router);
    }
    if (this.routes.tariftype_manage_route) {
      this.tariftype_manage_route = new Link(this.routes.tariftype_manage_route, this, this._router);
    }
    if (this.routes.tariftype_documents_route) {
      this.tariftype_documents_route = new Link(this.routes.tariftype_documents_route, this, this._router);
    }
    if (this.routes.tariftype_atts_route) {
      this.tariftype_atts_route = new Link(this.routes.tariftype_atts_route, this, this._router);
    }
    if (this.routes.tariftype_relations_route) {
      this.tariftype_relations_route = new Link(this.routes.tariftype_relations_route, this, this._router);
    }
    if (this.routes.tariftype_storages_route) {
      this.tariftype_storages_route = new Link(this.routes.tariftype_storages_route, this, this._router);
    }
    if (this.routes.tariftype_regions_route) {
      this.tariftype_regions_route = new Link(this.routes.tariftype_regions_route, this, this._router);
    }
    if (this.routes.tariftype_catalog_route) {
      this.tariftype_catalog_route = new Link(this.routes.tariftype_catalog_route, this, this._router);
    }
    if (this.routes.tariftype_destinations_route) {
      this.tariftype_destinations_route = new Link(this.routes.tariftype_destinations_route, this, this._router);
    }
    if (this.routes.tariftype_notifications_route) {
      this.tariftype_notifications_route = new Link(this.routes.tariftype_notifications_route, this, this._router);
    }
    if (this.routes.tariftype_notification_logs_route) {
      this.tariftype_notification_logs_route = new Link(
        this.routes.tariftype_notification_logs_route,
        this,
        this._router,
      );
    }
    if (this.routes.tariftype_permissions_route) {
      this.tariftype_permissions_route = new Link(this.routes.tariftype_permissions_route, this, this._router);
    }
    if (this.routes.tariftype_tarifs_logs_route) {
      this.tariftype_tarifs_logs_route = new Link(this.routes.tariftype_tarifs_logs_route, this, this._router);
    }
    if (this.routes.tariftype_prices_logs_route) {
      this.tariftype_prices_logs_route = new Link(this.routes.tariftype_prices_logs_route, this, this._router);
    }
    if (this.routes.tariftype_lifecycle_route) {
      this.tariftype_lifecycle_route = new Link(this.routes.tariftype_lifecycle_route, this, this._router);
    }
    if (this.routes.tariftype_mappings_route) {
      this.tariftype_mappings_route = new Link(this.routes.tariftype_mappings_route, this, this._router);
    }
  }
}
