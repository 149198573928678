import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { TariftypeManageComponent } from '../../../../../models/tarifs/tariftype/tariftype-manage/tariftype-manage.component';
import { CommonModule } from '@angular/common';
import { TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeManageViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeManageViewData extends TariftypeManageViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-manage-view',
  standalone: true,
  imports: [CommonModule, TariftypeManageComponent, HeaderActionComponent, IconComponent, TariftypeDetailNavComponent],
  templateUrl: './tariftype-manage-view.component.pug',
  styleUrls: ['./tariftype-manage-view.component.sass'],
})
export class TariftypeManageViewComponent extends BaseDetailViewComponent<TariftypeManageViewData, Tariftype> {
  public tariftype_detail_route!: Link<TariftypeBase>;

  public override setRouteData(data: TariftypeManageViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
  }
}
