import { RouteConfigItem } from "@solidev/data";

export interface ARTICLE_ROUTES {
  article_detail_route: RouteConfigItem;
  article_edit_route: RouteConfigItem;
  article_prices_route: RouteConfigItem;
  article_eamappings_route: RouteConfigItem;
  article_quality_route: RouteConfigItem;
  article_tarifs_route: RouteConfigItem;
  article_provider_route: RouteConfigItem;
  article_providerdocuments_route: RouteConfigItem;
  article_articledocuments_route: RouteConfigItem;
}

export interface ARTICLE_LINKS_ROUTES {
  article_list_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  family_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
  provider_detail_route: RouteConfigItem;
}
