import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseRouteParams, TabMemoryService } from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Storage } from "../../../../../models/structures/storage/storage";
import { STORAGE_ROUTES } from "../menu";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { StorageNavComponent } from "../_nav/storage-nav.component";
import { StorageDetailsComponent } from "../../../../../models/structures/storage/storage-details/storage-details.component";
import { QuickprintButtonComponent } from "../../../../../models/layouts/print/quickprint-button/quickprint-button.component";
import { IocodeButtonComponent } from "../../../../../models/lvadgio/iocode/iocode-button/iocode-button.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";
import { PrintSettings } from "../../../../../models/layouts/print-settings";

export interface StorageDisplayViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
}

export interface StorageDisplayViewData extends StorageDisplayViewParams {
  storage: Storage;
}

@Component({
  selector: "lvadg-storage-display-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    StorageDetailsComponent,
    StorageNavComponent,
    QuickprintButtonComponent,
    IocodeButtonComponent,
    IconComponent,
    TodoComponent,
  ],
  templateUrl: "./storage-display-view.component.pug",
  styleUrls: ["./storage-display-view.component.sass"],
})
export class StorageDisplayViewComponent extends BaseDetailViewComponent<
  StorageDisplayViewData,
  Storage
> {
  public print_settings: PrintSettings = [
    // Fiche dépôt
  ];

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageDisplayViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }
}
