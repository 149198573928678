
<ng-container *ngIf="offset">
  <ng-container *ngIf="data[count-offset]; let o">
    <ng-container *ngIf="display==='percent'"><span *ngIf="o.percent !== null" [title]="o.tarif.name" [class.text-danger]="o.percent &lt; -0.5" [class.text-success]="o.percent &gt; 0.5">{{o.percent|number:'0.0-1'}}%</span></ng-container>
    <ng-container *ngIf="display==='price'"><span *ngIf="o.price?.price &amp;&amp; o.percent!== null" [title]="o.tarif.name" [class.text-danger]="o.percent &lt; -0.5" [class.text-success]="o.percent &gt; 0.5">{{o.price?.price!/10000|currency:'EUR'}}</span></ng-container>
    <ng-container *ngIf="display==='both'"><span *ngIf="o.price?.price&amp;&amp; o.percent!== null" [title]="o.tarif.name" [class.text-danger]="o.percent &lt; -0.5" [class.text-success]="o.percent &gt; 0.5">{{o.price?.price!/10000|currency:'EUR'}}<small class="ms-1">{{o.percent|number:'0.0-1'}}%</small></span></ng-container>
  </ng-container>
</ng-container>
<div class="minigraph" *ngIf="!offset">
  <svg *ngIf="min!==null &amp;&amp; max!==null" [attr.viewBox]="'0 '+min+' '+ (count*10) + ' '+max" height="13px">
    <ng-container *ngFor="let o of data; let index=index">
      <ng-container *ngIf="o.percent!==null">
        <rect *ngIf="o.percent!&gt;0" [attr.x]="index*10" [attr.y]="abs(max+min)/2 - (o.percent||0)" [attr.width]="9" [attr.height]="o.percent||0" fill="green">
          <title>{{o.tarif.name}} : {{o.price!.price/10000|currency:'EUR'}} € / {{o.percent|number:'0.0-2'}}%</title>
        </rect>
        <rect *ngIf="o.percent!&lt;0" [attr.x]="index*10" [attr.y]="abs(max+min)/2" [attr.width]="9" [attr.height]="-(o.percent||0)" fill="red">
          <title>{{o.tarif.name}} : {{o.price!.price/10000|currency:'EUR'}} € / {{o.percent|number:'0.0-2'}}%</title>
        </rect>
      </ng-container>
      <ng-container *ngIf="o.percent===null">
        <rect [attr.x]="index*10" [attr.y]="(max+min)/2-abs(max-min)/10" [attr.width]="9" [attr.height]="abs(max-min)/5" fill="black">
          <title>{{o.tarif.name}} : {{o.price?.status}}</title>
        </rect>
      </ng-container>
    </ng-container>
    <line [attr.x1]="0" [attr.x2]="count*10" [attr.y1]="(max+min)/2" [attr.y2]="(max+min)/2" stroke="lightgrey" [attr.stroke-width]="(max-min)/10"></line>
  </svg>
</div>