import { ProductBase } from "./product.base";
import { computedField, detailsField } from "@solidev/data";
import { LabelBase } from "../label/label.base";
import { Relation } from "../../structures/relation/relation";
import { FamilyBase } from "../family/family.base";
import { Producer } from "../../structures/producer/producer";
import { Location } from "../../locations/location/location";
import { ImageDisplay } from "../../documents/image/image.base";
import { Image } from "../../documents/image/image";
import { ProducerDocument } from "../../structures/producer-document/producer-document";
import { ProducerDocumentBase } from "../../structures/producer-document/producer-document.base";
import { IocodeDetails } from "../../lvadgio/iocode/iocode.base";
import { CiqualAlim } from "../ciqual-alim/ciqual-alim";
import { CdataBase } from "../../cdata/cdata/cdata.base";

export class Product extends ProductBase {
  static override readonly __name: string = "Product";

  @detailsField({
    name: "labels_details",
    readonly: true,
    description: "Labels",
    model: LabelBase,
    priority: 600,
    many: true,
  })
  public labels_details?: LabelBase[];

  @detailsField({
    name: "producer_details",
    readonly: true,
    description: "Producteur",
    model: Producer,
    priority: 730,
  })
  public producer_details?: Producer;

  @detailsField({
    name: "producer_location_details",
    readonly: true,
    description: "Localisation du producteur",
    model: Location,
    priority: 720,
  })
  public producer_location_details?: Location;

  @detailsField({
    description: "Dépôts",
    model: Relation,
    many: true,
    priority: 600,
  })
  public storage_relations_details?: Relation[];

  @detailsField({
    model: FamilyBase,
    readonly: true,
    description: "Famille",
    priority: 850,
  })
  public family_details?: FamilyBase;

  @detailsField({
    model: ImageDisplay,
    readonly: true,
    description: "Image produit (spécifique)",
    priority: 990,
  })
  public logo_details?: ImageDisplay;

  @detailsField({
    model: ImageDisplay,
    readonly: true,
    description: "Image produit",
    priority: 995,
  })
  public clogo_details?: ImageDisplay;

  @detailsField({
    name: "images_details",
    description: "Images",
    readonly: true,
    model: Image,
    many: true,
  })
  public images_details?: Image[];

  @detailsField({
    name: "cimages_details",
    description: "Images héritées",
    readonly: true,
    model: Image,
    many: true,
    priority: -1,
  })
  public cimages_details?: Image[];

  @detailsField({
    name: "documents_details",
    description: "Documents",
    readonly: true,
    model: ProducerDocument,
    many: true,
  })
  public documents_details?: ProducerDocumentBase[];

  @detailsField({
    description: "Lien .io",
    name: "iocode",
    priority: 800,
    readonly: true,
  })
  public iocode?: IocodeDetails;

  @detailsField({
    description: "Données spécifiques",
    model: CdataBase,
    many: true,
    readonly: true,
  })
  public cdata_details: CdataBase[] = [];

  @detailsField({
    name: "ciqual_alim_details",
    model: CiqualAlim,
    readonly: true,
    priority: 500,
  })
  public ciqual_alim_details?: CiqualAlim;

  @computedField({
    name: "producer_postcode",
    description: "Code postal producteur",
    readonly: true,
  })
  public get producer_postcode(): string {
    return this.producer_location_details?.postcode ?? "";
  }

  @computedField({
    name: "producer_city",
    description: "Ville du producteur",
    readonly: true,
  })
  public get producer_city(): string {
    return this.producer_location_details?.city ?? "";
  }

  @computedField({
    name: "producer_dptcode",
    description: "Code département du producteur",
    readonly: true,
  })
  public get producer_dptcode(): string {
    return this.producer_location_details?.geodepartement_details?.code ?? "";
  }

  @computedField({
    name: "producer_city",
    description: "Ville du producteur",
    readonly: true,
  })
  public get producer_dptname(): string {
    return this.producer_location_details?.geodepartement_details?.name ?? "";
  }
}
