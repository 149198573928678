import { OrderprocessBase } from './orderprocess.base';
import { detailsField } from '@solidev/data';
import { OrderprocessPermission } from '../orderprocess-permission/orderprocess-permission';
import { TariftypeBase } from '../../tarifs/tariftype/tariftype.base';

export class Orderprocess extends OrderprocessBase {
  static override readonly __name: string = 'Orderprocess';

  @detailsField({
    model: OrderprocessPermission,
    description: 'Permissions',
    readonly: true,
    many: true,
    priority: 360,
  })
  public perms_details?: OrderprocessPermission[];

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
    priority: 480,
  })
  public tariftype_details?: TariftypeBase;
}
