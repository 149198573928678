import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERROR_MODE } from '../../views/generic-error-view/generic-error-view.component';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResolverErrorService {
  public catch = this._catch.bind(this);
  public nav = this._nav.bind(this);

  constructor(private _router: Router) {}

  private _catch(e: any) {
    console.error('Error', e);
    const stt: number | undefined = (e as any).status;
    if (stt === 404) {
      this._nav(ERROR_MODE.resnotfound);
    } else if (stt === 0 || stt === 503) {
      this._nav(ERROR_MODE.apinotavailable);
    } else if (stt === 500) {
      this._nav(ERROR_MODE.apierror);
    } else {
      this._nav(ERROR_MODE.unknown);
    }
    return EMPTY;
  }

  private _nav(mode: ERROR_MODE) {
    this._router.navigate(['/error'], {
      fragment: mode,
      skipLocationChange: true,
    });
  }
}
