import { Component } from '@angular/core';
import { PackagingListComponent } from '../../../../../models/catalog/meta/packaging/packaging-list/packaging-list.component';

import { PackagingListViewData } from './packaging-list-view.component.params';
import { Subject } from 'rxjs';
import { AllergenListViewData } from '../../allergen/list/allergen-list-view.component.params';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { PackagingCreateComponent } from '../../../../../models/catalog/meta/packaging/packaging-create/packaging-create.component';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Packaging } from '../../../../../models/catalog/meta/packaging/packaging';

@Component({
  selector: 'lvadg-packaging-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    PackagingListComponent,
    PackagingCreateComponent,
    HeaderActionComponent,
    IconComponent,
  ],
  templateUrl: './packaging-list-view.component.pug',
  styleUrls: ['./packaging-list-view.component.sass'],
})
export class PackagingListViewComponent extends BaseListViewComponent<PackagingListViewData, Packaging> {
  public default_fields = [
    'id',
    'type',
    'code',
    'name',
    'articles_count',
    'articles_count_colisage',
    'articles_count_uci',
    'updated',
  ];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
