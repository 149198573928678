import { Component } from '@angular/core';
import { PackagingDetailViewData } from './packaging-detail-view.component.params';

import { FilterDefaults, Link } from '@solidev/data';
import { Packaging } from '../../../../../models/catalog/meta/packaging/packaging';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { META_ARTICLE_LIST_FIELDS } from '../../constants';
import { map, Observable } from 'rxjs';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { PackagingManageComponent } from '../../../../../models/catalog/meta/packaging/packaging-manage/packaging-manage.component';

@Component({
  selector: 'lvadg-packaging-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ArticleListComponent, HeaderActionComponent, PackagingManageComponent],
  templateUrl: './packaging-detail-view.component.pug',
  styleUrls: ['./packaging-detail-view.component.sass'],
})
export class PackagingDetailViewComponent extends BaseDetailViewComponent<PackagingDetailViewData, Packaging> {
  public list_route!: Link<Packaging>;
  public article_detail_route!: Link<ArticleBase>;
  public article_list_fields = META_ARTICLE_LIST_FIELDS;

  public metaFilter$!: Observable<FilterDefaults>;

  public override setRouteData(data: PackagingDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.metaFilter$ = this.data$.pipe(map((d) => ({ packagings: d.packaging.id })));
  }

  deleted() {
    this.list_route.navigate();
  }
}
