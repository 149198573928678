import { RoutesConfig } from '@solidev/data';
import { OrderBase, OrderprocessBase, RS } from '@vivalya/lvadg';

export const FRONT_ORDERS_ROUTES_NAMES = ['orders', 'orders_process', 'orders_process_order'] as const;

export const FRONT_ORDERS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_ORDERS_ROUTES_NAMES)[number]> = {
  orders: {
    name: 'orders',
    params: [],
    route: () => ['/', RS.orders],
  },
  orders_process: {
    name: 'orders_process',
    params: ['orderprocess'],
    route: (op: OrderprocessBase) => ['/', RS.orders, op.id],
  },
  orders_process_order: {
    name: 'orders_process_order',
    params: ['orderprocess', 'order'],
    route: (op: OrderprocessBase, order: OrderBase) => ['/', RS.orders, op.id, RS.order, order.id],
  },
};
