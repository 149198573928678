
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action level="h2" ri="document">
    <ng-container title>{{data.articledocument.filename}}
      <div><small class="ms-2">{{data.articledocument.status|pchoice:data.articledocument:'status'}}</small><small class="ms-2" *ngIf="data.articledocument.start || data.articledocument.end">| du {{data.articledocument.start|date:'shortDate'}} au {{data.articledocument.end|date:'shortDate'}}</small></div>
    </ng-container>
    <ng-container actions>
      <data-safe-delete [model]="data.articledocument" display="button" (deleted)="_router.navigate(['..'], {relativeTo: _route})"></data-safe-delete>
    </ng-container>
  </lvadg-header-action>
  <div class="mx-2">
    <lvadg-article-document-manage [articledocument]="data.articledocument"></lvadg-article-document-manage>
  </div>
</ng-container>