import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Document } from "../document";
import { DispeditComponent } from "@solidev/data";

@Component({
  selector: "lvadg-document-manage",
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  template: ` @if (document) {
    <!-- Édition des propriétés du document -->
    <div class="row g-3">
      <data-dispedit class="col-6" [model]="document" field="title" mode="dd">
        Titre
      </data-dispedit>
      <data-dispedit class="col-6" [model]="document" field="slug" mode="dd">
        Slug
      </data-dispedit>
      <data-dispedit
        class="col-12"
        [model]="document"
        field="filename"
        mode="dd"
        >Nom du fichier
      </data-dispedit>
      <data-dispedit class="col-6" [model]="document" field="start" mode="dd">
        Date de début
      </data-dispedit>
      <data-dispedit class="col-6" [model]="document" field="end" mode="dd">
        Date de fin
      </data-dispedit>
      <data-dispedit class="col-12" [model]="document" field="status" mode="dd"
        >État
      </data-dispedit>
    </div>
  }`,
  styleUrls: ["./document-manage.component.sass"],
})
export class DocumentManageComponent {
  @Input() public document?: Document;
}
