import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { ArticleTarifTemplateManageComponent } from '../../../../models/tarifs/article-tarif-template/article-tarif-template-manage/article-tarif-template-manage.component';
import { RnmattCreateComponent } from '../../../../models/rnm/rnmatt/rnmatt-create/rnmatt-create.component';
import { RnmtarifListComponent } from '../../../../models/rnm/rnmtarif/rnmtarif-list/rnmtarif-list.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { ArticleTarifTemplate } from '../../../../models/tarifs/article-tarif-template/article-tarif-template';
import { Earticle } from '../../../../models/catalog/earticle/earticle';
import { Article } from '../../../../models/catalog/article/article';

export interface ArticleTarifTemplateDetailViewParams extends BaseRouteParams {
  earticle_detail_route?: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface ArticleTarifTemplateDetailViewData extends ArticleTarifTemplateDetailViewParams {
  articletariftemplate: ArticleTarifTemplate;
}

@Component({
  selector: 'lvadg-article-tarif-template-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    ArticleTarifTemplateManageComponent,
    RnmattCreateComponent,
    RnmtarifListComponent,
  ],
  templateUrl: './article-tarif-template-detail-view.component.pug',
  styleUrls: ['./article-tarif-template-detail-view.component.sass'],
})
export class ArticleTarifTemplateDetailViewComponent extends BaseDetailViewComponent<
  ArticleTarifTemplateDetailViewData,
  ArticleTarifTemplate
> {
  public earticle_detail_route?: Link<Earticle>;
  public article_detail_route!: Link<Article>;

  public override setRouteData(data: ArticleTarifTemplateDetailViewData) {
    super.setRouteData(data);
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(data.earticle_detail_route, data);
    }
    this.article_detail_route = new Link(data.article_detail_route, data);
  }
}
