import { charField, foreignKeyField } from '@solidev/data';
import { BaseFile } from '../../documents/basefile';

export enum TARIFDOCUMENT_TYPES {
  ARCHIVE_TARIF = 'AT',
  EXPORT_TARIF = 'ET',
  IMPORT_TARIF = 'IT',
  CEXPORT_TARIF = 'EC',
  MEXPORT_TARIF = 'EM',
}

export enum TARIFDOCUMENT_STATUSES {
  VALID = 'OK',
  OK_NO_UPDATE_EXP_15D = 'PRE1',
  OK_NO_UPDATE_EXP_1M = 'PRE2',
  FUTUR = 'FUT',
  ARCHIVED_NO_UPDATE = 'ARCN',
  ARCHIVED_WITH_UPDATE = 'ARCM',
  INVALID = 'KO',
}

export class TarifDocumentBase extends BaseFile {
  static override readonly __name: string = 'TarifDocumentBase';

  @foreignKeyField({ name: 'tarif', description: 'ID Tarif', related: 'Tarif' })
  public tarif!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: TARIFDOCUMENT_TYPES.EXPORT_TARIF,
    maxLength: 4,
    choices: [
      {
        value: TARIFDOCUMENT_TYPES.ARCHIVE_TARIF,
        desc: 'Archive tarif',
      },
      {
        value: TARIFDOCUMENT_TYPES.EXPORT_TARIF,
        desc: 'Export tarif',
      },
      {
        value: TARIFDOCUMENT_TYPES.IMPORT_TARIF,
        desc: 'Import tarif',
      },
      {
        value: TARIFDOCUMENT_TYPES.CEXPORT_TARIF,
        desc: 'Export tarif [client]',
      },
      {
        value: TARIFDOCUMENT_TYPES.MEXPORT_TARIF,
        desc: 'Export tarif [adhérent]',
      },
    ],
  })
  public type: TARIFDOCUMENT_TYPES = TARIFDOCUMENT_TYPES.EXPORT_TARIF;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: TARIFDOCUMENT_STATUSES.VALID,
    maxLength: 4,
    choices: [
      {
        value: TARIFDOCUMENT_STATUSES.VALID,
        desc: 'Document valide',
      },
      {
        value: TARIFDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D,
        desc: 'Document sans MAJ expirant dans - de 15 jours',
      },
      {
        value: TARIFDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M,
        desc: 'Document sans MAJ expirant dans - de 1 mois',
      },
      {
        value: TARIFDOCUMENT_STATUSES.FUTUR,
        desc: 'Document futur',
      },
      {
        value: TARIFDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE,
        desc: 'Document archivé, sans MAJ',
      },
      {
        value: TARIFDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE,
        desc: 'Document archivé, avec MAJ',
      },
      { value: TARIFDOCUMENT_STATUSES.INVALID, desc: 'Document non valide' },
    ],
  })
  public status: TARIFDOCUMENT_STATUSES = TARIFDOCUMENT_STATUSES.VALID;
}
