import { GeoregionBase } from './georegion.base';
import { detailsField } from '@solidev/data';
import { Geodepartement } from '../geodepartement/geodepartement';
import { Geocountry } from '../geocountry/geocountry';

export class Georegion extends GeoregionBase {
  static override readonly __name: string = 'Georegion';
  @detailsField({
    model: Geodepartement,
    readonly: true,
    description: 'Départements',
    priority: 400,
    many: true,
  })
  public departements_details?: Geodepartement[];

  @detailsField({
    model: Geocountry,
    readonly: true,
    description: 'Pays',
    priority: 500,
  })
  public country_details?: Geocountry;
}
