import { Component } from '@angular/core';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TarifNotification } from '../../../../models/tarifs/tarif-notification/tarif-notification';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { TarifNotificationListComponent } from '../../../../models/tarifs/tarif-notification/tarif-notification-list/tarif-notification-list.component';

export interface TarifNotificationListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface TarifNotificationListViewData extends TarifNotificationListViewParams {}

@Component({
  selector: 'lvadg-tarif-notification-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifNotificationListComponent],
  templateUrl: './tarif-notification-list-view.component.pug',
  styleUrls: ['./tarif-notification-list-view.component.sass'],
})
export class TarifNotificationListViewComponent extends BaseListViewComponent<
  TarifNotificationListViewData,
  TarifNotification
> {
  public override setRouteData(data: TarifNotificationListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
