import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Orderprocess } from './orderprocess';

@Injectable({
  providedIn: 'root',
})
export class OrderprocessService extends Collection<Orderprocess> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/orderprocesses', Orderprocess);
  }
}
