import {
    charField,
    DataModel,
    datetimeField, detailsField, foreignKeyField,
    primaryField,
} from '@solidev/data';
import {User} from "../../users/user/user";

export class CommentBase extends DataModel {
    static override readonly __name: string = 'CommentBase';

    @primaryField({
        name: 'id',
        description: 'ID',
    })
    public override id!: number;

    @charField({
        name: 'description',
        description: 'description',
        maxLength: 255,
    })
    public description!: string;

    @datetimeField({
        name: 'created',
        description: 'Date de création',
        readonly: true,
        priority: -1
    })
    public created!: Date;

    @datetimeField({
        name: 'updated',
        description: 'Date de MAJ',
        readonly: true,
    })
    public updated!: Date;

    @foreignKeyField({
        name: "user",
        description: "Utilisateur",
        related: "User",
        priority: -90
    })
    public user!: number;

    @foreignKeyField({
        name: "news",
        description: "Actualité",
        related: "News",
    })
    public news!: number;

    @detailsField({
        name: "user_details",
        description: "Utilisateur lié (détails)",
        model: User,
    })
    public user_details!: { id: number; username: string; email: string, last_name: string, first_name: string };
}
