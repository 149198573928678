import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  decimalField,
  detailsField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';
import { OrderProcessUserLevel } from '../orderprocess/orderprocess.base';

export enum OrderStatus {
  PREPARATION = 'PRP',
  VALIDATED = 'VAL',
  CONSOLIDATED = 'CNS',
  SENT = 'SNT',
  CLOSED = 'CLS',
  CANCELLED = 'CAN',
}

export class OrderBase extends DataModel {
  static override readonly __name: string = 'OrderBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'process',
    description: 'ID Processus de commande',
    related: 'OrderProcess',
  })
  public process!: number;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 20,
  })
  public code!: string;

  @charField({
    name: 'status',
    description: 'État',
    maxLength: 3,
    defaultValue: OrderStatus.PREPARATION,
    choices: [
      { value: OrderStatus.PREPARATION, desc: 'En préparation' },
      { value: OrderStatus.VALIDATED, desc: 'Validée' },
      { value: OrderStatus.CONSOLIDATED, desc: 'Consolidée' },
      { value: OrderStatus.SENT, desc: 'Envoyée' },
      { value: OrderStatus.CLOSED, desc: 'Fermée' },
      { value: OrderStatus.CANCELLED, desc: 'Annulée' },
    ],
  })
  public status: OrderStatus = OrderStatus.PREPARATION;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
  })
  public storage!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Ordergroup',
    required: false,
  })
  public group?: number;

  @decimalField({
    name: 'total_price',
    description: 'Total HT',
    required: false,
    factor: 10000,
  })
  public total_price?: number;

  @decimalField({
    name: 'total_weight',
    description: 'Poids total',
    required: false,
    factor: 100000,
  })
  public total_weight?: number;

  @decimalField({
    name: 'total_quantity',
    description: 'Total UDV',
    required: false,
    factor: 100000,
  })
  public total_quantity?: number;

  @dateField({
    name: 'date',
    description: 'Date de commande',
    required: false,
  })
  public date!: Date;

  @dateField({
    name: 'delivery_date',
    description: 'Date de livraison/enlèvement prévue',
    required: false,
  })
  public delivery_date!: Date;

  @decimalField({
    name: 'delivered_total_price',
    description: 'Total HT (livraison)',
    required: false,
    factor: 10000,
  })
  public delivered_total_price?: number;

  @decimalField({
    name: 'delivered_total_weight',
    description: 'Poids total (livraison)',
    required: false,
    factor: 100000,
  })
  public delivered_total_weight?: number;

  @decimalField({
    name: 'delivered_total_quantity',
    description: 'Total UDV (livraison)',
    required: false,
    factor: 100000,
  })
  public delivered_total_quantity?: number;

  @dateField({
    name: 'date',
    description: 'Date de livraison effective',
    required: false,
  })
  public delivered_date!: Date;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
  })
  public flags: string[] = [];

  @detailsField({
    name: 'process_user_level',
    description: 'Permissions utilisateur (processus)',
    deserialize: (d) => d,
  })
  public process_user_level: OrderProcessUserLevel[] = [];

  @detailsField({
    name: 'pstorage_user_level',
    description: 'Permissions utilisateur (dépôt)',
    deserialize: (d) => d,
  })
  public pstorage_user_level: OrderProcessUserLevel[] = [];

  public have_process_level(level: OrderProcessUserLevel): boolean {
    return this.process_user_level.indexOf(level) !== -1;
  }

  public have_pstorage_level(level: OrderProcessUserLevel): boolean {
    return this.pstorage_user_level.indexOf(level) !== -1;
  }
}
