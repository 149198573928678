import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Link, RouteConfigItem } from '@solidev/data';
import { BaseListRouteParams, BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { Client } from '../../../../models/structures/client/client';
import { OffertypeListComponent } from '../../../../models/offers/offertype/offertype-list/offertype-list.component';
import { Offertype } from '../../../../models/offers/offertype/offertype';
import { Subject } from 'rxjs';
import { OffertypeCreateComponent } from '../../../../models/offers/offertype/offertype-create/offertype-create.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { User } from '../../../../models/users/user/user';

export interface OffertypeListViewParams extends BaseListRouteParams {
  tariftype_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
}

export interface OffertypeListViewData extends OffertypeListViewParams {}

@Component({
  selector: 'lvadg-offertype-list-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OffertypeListComponent, OffertypeCreateComponent, IconComponent],
  templateUrl: './offertype-list-view.component.pug',
  styleUrls: ['./offertype-list-view.component.sass'],
})
export class OffertypeListViewComponent extends BaseListViewComponent<OffertypeListViewData, Offertype> {
  public tariftype_detail_route!: Link<Tariftype>;
  public client_detail_route!: Link<Client>;
  public user_detail_route!: Link<User>;
  public create = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields = [
    'id',
    'code',
    'name',
    'type',
    'mode',
    'zone',
    'pilotes_details',
    'client_details',
    'tariftypes_details',
    'base_interval',
    'status',
    'updated_at',
    'flags',
  ];

  public override setRouteData(data: OffertypeListViewData) {
    super.setRouteData(data);
    this.client_detail_route = new Link(data.client_detail_route, data, this._router);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
    this.user_detail_route = new Link(data.user_detail_route, data, this._router);
  }
}
