
<div class="d-flex flex-column h-100">
  <div class="btn-group w-100 mt-1">
    <button class="btn w-25" (click)="setFilter('all')" [class.btn-primary]="curFilter=='all'" [class.btn-outline-secondary]="curFilter!=='all'"><span aria-label="all">TOUS</span></button>
    <button class="btn w-25" (click)="setFilter('starred')" [class.btn-primary]="curFilter=='starred'" [class.btn-outline-secondary]="curFilter!=='starred'"><i class="bi bi-star" aria-label="star"></i></button>
    <button class="btn w-25" (click)="setFilter('notified')" [class.btn-primary]="curFilter=='notified'" [class.btn-outline-secondary]="curFilter!=='notified'"><i class="bi bi-bell" aria-label="bell"></i></button>
    <button class="btn w-25" (click)="setFilter('pilote')" [class.btn-primary]="curFilter=='pilote'" [class.btn-outline-secondary]="curFilter!=='pilote'"><i class="bi bi-pencil" aria-label="pilote"></i></button>
  </div>
  <input class="form-control form-control-sm mt-1" [formControl]="search" placeholder="Recherche par texte">
  <div class="dlist" *ngIf="tlist$|async; let tree; else loading">
    <div *ngIf="tree.generics &amp;&amp; tree.generics.length">
      <div class="text-uppercase" role="button" (click)="select(-1)"><span class="me-1"><i class="bi bi-folder" *ngIf="!opened[-1]" role="img" aria-hidden="true"></i><i class="bi bi-folder-fill" *ngIf="opened[-1]" role="img" aria-hidden="true"></i></span><strong>Tarifs génériques</strong></div>
      <div class="ms-4" [ngbCollapse]="!opened[-1]"><a class="d-block text-decoration-none text-black text-uppercase" *ngFor="let t of tree.generics" role="button" (click)="ttSelect(t)" [routerLink]="tariftype_detail_route?.route({tariftype: t})" [class.fw-bold]="current===t.id">
          <lvadg-icon ri="tarifs">{{t.name}}</lvadg-icon><span class="ms-2"><i class="bi bi-star text-muted" *ngIf="!t.user_starred" role="img" aria-hidden="true"></i><i class="bi bi-star-fill text-success" *ngIf="t.user_starred" role="img" aria-hidden="true"></i><i class="bi bi-bell text-muted" *ngIf="!t.user_notified" role="img" aria-hidden="true"></i><i class="bi bi-bell-fill text-success" *ngIf="t.user_notified" role="img" aria-hidden="true"></i></span></a></div>
    </div>
    <div class="flex-grow-1" *ngFor="let c of tree.clients">
      <div class="text-uppercase" role="button" (click)="select(c.c.id)"><span class="me-1"><i class="bi bi-folder" *ngIf="!opened[c.c.id]"></i><i class="bi bi-folder-fill" *ngIf="opened[c.c.id]"></i></span>
        <ng-container *ngIf="c.p"><strong>{{c.p.name}}</strong><small><span class="mx-1">/</span><span>{{c.c.name}}</span></small></ng-container>
        <ng-container *ngIf="!c.p"><strong>{{c.c.name}}</strong></ng-container>
      </div>
      <div class="ms-4" [ngbCollapse]="!opened[c.c.id]"><a class="d-block text-decoration-none text-black text-uppercase" *ngFor="let t of tree.tree.get(c.c.id)?.tarifs" role="button" (click)="ttSelect(t)" [routerLink]="tariftype_detail_route?.route({tariftype: t})" [class.fw-bold]="current===t.id">
          <lvadg-icon ri="tarifs">{{t.name}}</lvadg-icon><span class="ms-2 small"><i class="bi bi-star-fill" *ngIf="t.user_starred"></i><i class="bi bi-bell-fill" *ngIf="t.user_notified"></i></span></a></div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="text-center mt-5">
    <div class="fs-1 spinner-grow text-primary"></div>
    <div class="text-primary">Chargement en cours...</div>
  </div>
</ng-template>