import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Location } from '../location';
import { LocationService } from '../location.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { LocationBase } from '../location.base';
import { MemberBase } from '../../../structures/member/member.base';
import { ProviderBase } from '../../../structures/provider/provider.base';
import { ProducerBase } from '../../../structures/producer/producer.base';
import { RestoBase } from '../../../structures/resto/resto.base';
import { ClientBase } from '../../../structures/client/client.base';
import { StorageBase } from '../../../structures/storage/storage.base';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-location-list',
  standalone: true,
  templateUrl: './location-list.component.pug',
  styleUrls: ['./location-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, FlagsDisplayComponent],
})
export class LocationListComponent extends ModellistComponent<Location> {
  @Input() public member_detail_route?: Link<MemberBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public client_detail_route?: Link<ClientBase>;
  @Input() public resto_detail_route?: Link<RestoBase>;
  @Input() public producer_detail_route?: Link<ProducerBase>;
  @Input() public provider_detail_route?: Link<ProviderBase>;

  constructor(
    coll: LocationService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          name: 'loctype',
          desc: 'Type de localisation',
          model: LocationBase,
          field: 'loctype',
          mfield: 'loctype',
          label: 'Recherche par type',
          help: 'Sélectionnez un type de localisation',
        }),
        new ModelListSelectFilter({
          name: 'status',
          desc: 'État',
          model: LocationBase,
          field: 'status',
          mfield: 'status',
          label: 'Recherche par état',
          help: 'Sélectionnez un état',
        }),
        new ModelListSelectFilter({
          name: 'geotype',
          desc: 'Précision géoloc.',
          model: LocationBase,
          field: 'geotype',
          mfield: 'geotype',
          label: 'Recherche par précision de géoloc.',
          help: 'Sélectionnez une précision',
        }),
        new ModelListSelectFilter({
          name: 'geoprovider',
          desc: 'Source géoloc.',
          model: LocationBase,
          field: 'geoprovider',
          mfield: 'geoprovider',
          label: 'Recherche par source de géoloc.',
          help: 'Sélectionnez une source de géoloc.',
        }),
      ],
    };
  }
}
