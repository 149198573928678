
<ng-container *ngIf="offertype">
  <div class="row g-2">
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Caractéristiques</div>
        <div class="card-body">
          <dl class="row g-1">
            <data-dispedit class="col-4" [model]="offertype" field="name" [editable]="true" mode="dd">Nom</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="code" [editable]="true" mode="dd">Code</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="mode" [editable]="true" mode="dd">Mode</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="type" [editable]="true" mode="dd">Type</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="zone" [editable]="true" mode="dd">Zone</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="status" [editable]="true" mode="dd">État</data-dispedit>
            <data-dispedit class="col-12" [model]="offertype" field="name_template" [editable]="true" mode="dd">Template de nom</data-dispedit>
            <data-dispedit class="col-12" [model]="offertype" field="internal_notes" [editable]="true" mode="dd">Notes internes</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Caractéristiques</div>
        <div class="card-body">
          <dl class="row g-1">
            <data-dispedit class="col-6" [model]="offertype" field="client" [editable]="true" mode="dd" [collection]="clients$">Client</data-dispedit>
            <data-dispedit class="col-12" [model]="offertype" field="tariftypes" [editable]="true" mode="dd" [collection]="tariftypes$">Modèles de tarif</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Contrôle et périodicité</div>
        <div class="card-body">
          <dl class="row g-1">
            <data-dispedit class="col-12" [model]="offertype" field="pilotes" [editable]="true" mode="dd" [collection]="users$">Responsables</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="base_interval" [editable]="true" mode="dd">Intervalle de base</data-dispedit>
            <div class="col-12">
              <dt>Règle de récurrence</dt>
              <dd>
                <lvadg-rrule-editor [rule]="offertype.rrule" [editable]="true" (save)="saveRrule(offertype, $event).then()">Règle de récurrence</lvadg-rrule-editor>
              </dd>
            </div>
            <data-dispedit class="col-6" [model]="offertype" field="delta_prepared" [editable]="true" mode="dd">Delta de préparation</data-dispedit>
            <data-dispedit class="col-6" [model]="offertype" field="delta_published" [editable]="true" mode="dd">Delta de publication</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
  </div>
</ng-container>