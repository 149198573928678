
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { PlatformService} from "./platform.service";
import { Platform } from "./platform";

@Injectable({
  providedIn: 'root'
})
export class PlatformResolver  {
  constructor(private _rs: PlatformService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Platform> {
    return this._rs.fetch(+route.params["platformId"])
  }
}
