import {
  charField,
  detailsField,
  foreignKeyField,
  primaryField,
} from "@solidev/data";
import { StructureBase } from "../structure.base";

export enum STORAGE_STATUS {
  ACTIVE = "OK",
  ACTIVE_NEW = "NEW",
  INACTIVE = "KO",
}

export enum STORAGE_TYPES {
  FL = "FL",
  SEA = "MR",
  FLSEA = "FLMR",
}

export enum STORAGE_USER_LEVELS {
  SADMIN = "sadmin",
  ADMIN = "admin",
  DETAIL = "detail",
  READ = "read",
}

export class StorageBase extends StructureBase {
  static override readonly __name: string = "StorageBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @charField({
    name: "status",
    description: "État",
    defaultValue: STORAGE_STATUS.ACTIVE,
    required: true,
    maxLength: 4,
    choices: [
      {
        value: STORAGE_STATUS.ACTIVE,
        desc: "Actif",
      },
      {
        value: STORAGE_STATUS.ACTIVE_NEW,
        desc: "Actif - nouveau",
      },
      {
        value: STORAGE_STATUS.INACTIVE,
        desc: "Non actif",
      },
    ],
    priority: 180,
  })
  public status: STORAGE_STATUS = STORAGE_STATUS.ACTIVE;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: STORAGE_TYPES.FLSEA,
    maxLength: 4,
    choices: [
      {
        value: STORAGE_TYPES.FL,
        desc: "Fruits / légumes",
      },
      {
        value: STORAGE_TYPES.SEA,
        desc: "Marée",
      },
      {
        value: STORAGE_TYPES.FLSEA,
        desc: "Fruits / légumes / marée",
      },
    ],
    priority: 999,
  })
  public type: STORAGE_TYPES = STORAGE_TYPES.FLSEA;

  @foreignKeyField({
    name: "member",
    description: "ID Adhérent",
    related: "Member",
    priority: -1,
  })
  public member!: number;

  @detailsField({
    name: "user_level",
    description: "Droits utilisateur",
    readonly: true,
  })
  public user_level: STORAGE_USER_LEVELS[] = [];

  public get _display(): string {
    return `${this.name}`;
  }

  public have_level(level: STORAGE_USER_LEVELS): boolean {
    return this.user_level.includes(level);
  }
}
