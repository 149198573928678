
<ng-container *ngIf="user">
  <h3>Sélectionnez un groupe</h3>
  <data-dispedit [model]="groupLink" field="group" [collection]="groups$" mode="inline">Groupe</data-dispedit>
  <button class="btn btn-primary mt-3" (click)="addUserToGroup()" [disabled]="!groupLink.group">
    <lvadg-icon ri="add">Ajouter au groupe</lvadg-icon>
  </button>
</ng-container>
<ng-container *ngIf="group">
  <h3>Sélectionnez un utilisateur</h3>
  <data-dispedit [model]="groupLink" field="user" [collection]="users$" mode="inline">Utilisateur</data-dispedit>
  <button class="btn btn-primary mt-3" (click)="addGroupToUser()" [disabled]="!groupLink.user">
    <lvadg-icon ri="add">Ajouter l'utilisateur</lvadg-icon>
  </button>
</ng-container>