import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterDefaults, PChoicePipe, TabMemoryService } from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { map, Observable, switchMap } from "rxjs";
import { Offer } from "../../../../../models/offers/offer/offer";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { OfferViewData, OfferViewParams } from "../menu";
import { OfferProduct } from "../../../../../models/offers/offer-product/offer-product";
import { OfferProductService } from "../../../../../models/offers/offer-product/offer-product.service";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";

export interface OfferDisplayViewParams extends OfferViewParams {}

export interface OfferDisplayViewData
  extends OfferViewData,
    OfferDisplayViewParams {}

@Component({
  selector: "lvadg-offer-display-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OfferNavComponent,
    TodoComponent,
    PChoicePipe,
  ],
  templateUrl: "./offer-display-view.component.pug",
  styleUrls: ["./offer-display-view.component.sass"],
})
export class OfferDisplayViewComponent extends BaseDetailViewComponent<
  OfferDisplayViewData,
  Offer
> {
  public offerFilter$!: Observable<FilterDefaults>;
  public products$!: Observable<OfferProduct[]>;
  private _products = inject(OfferProductService);

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: OfferDisplayViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offerFilter$ = this.data$.pipe(map((d) => ({ offer: d.offer.id })));
    this.products$ = this.data$.pipe(
      switchMap((d) => {
        return this._products.list({
          offer: d.offer.id,
          page_size: 100,
          ordering: "-order,product__name",
          fields: ["product_details", "producer_details"].join(","),
        });
      }),
    );
  }
}
