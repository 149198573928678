import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Distance } from '../distance';
import { DistanceService } from '../distance.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LocationDisplayComponent } from '../../location/location-display/location-display.component';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';

@Component({
  selector: 'lvadg-distance-list',
  standalone: true,
  templateUrl: './distance-list.component.pug',
  styleUrls: ['./distance-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    FlagsDisplayComponent,
    LocationDisplayComponent,
  ],
})
export class DistanceListComponent extends ModellistComponent<Distance> {
  constructor(
    coll: DistanceService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
