
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action title="Gestion des offres contractuelles" level="h1" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-primary" (click)="create=!create">
        <lvadg-icon ri="add">Nouvelle offre contractuelle</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="create">
    <div class="card-header">Nouvelle offre contractuelle</div>
    <div class="card-body">
      <lvadg-offertype-create (created)="create=false; reload$.next(true); detail_route.navigate({offertype: $event}).then()"></lvadg-offertype-create>
    </div>
  </div>
  <lvadg-offertype-list [name]="data.route.name" [detail_route]="detail_route" [client_detail_route]="client_detail_route" [tariftype_detail_route]="tariftype_detail_route" [user_detail_route]="user_detail_route" [default_fields]="default_fields" [reload]="reload$"></lvadg-offertype-list>
</ng-container>