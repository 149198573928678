
<ng-container *ngIf="label$|async; let label">
  <div class="hoverable" *ngIf="label.logo_details &amp;&amp; (mode==='small' || mode === 'list')" (click)="clicked.emit(label)" role="button"><img ngSrc="{{label.logo_details.file}}" [width]="24" [height]="24" [loaderParams]="{contain: true, width: 24, height: 24}" priority [class.img-thumbnail]="thumbnail"><img class="hovered-img hovered-128 img-thumbnail" *ngIf="hover" ngSrc="{{label.logo_details.file}}" [width]="128" [height]="128" [loaderParams]="{contain: true, width: 128, height: 128}" loading="auto"></div>
  <div class="hoverable" *ngIf="label.logo_details &amp;&amp; mode==='list-medium'" (click)="clicked.emit(label)" role="button"><img ngSrc="{{label.logo_details.file}}" [width]="36" [height]="36" [loaderParams]="{contain: true, width: 36, height: 36}" priority [class.img-thumbnail]="thumbnail"><img class="hovered-img hovered-192 img-thumbnail" *ngIf="hover" ngSrc="{{label.logo_details.file}}" [width]="192" [height]="192" [loaderParams]="{contain: true, width: 192, height: 192}" loading="auto"></div>
  <div class="hoverable" *ngIf="label.logo_details &amp;&amp; mode==='medium'" (click)="clicked.emit(label)" role="button"><img ngSrc="{{label.logo_details.file}}" [width]="48" [height]="48" [loaderParams]="{contain: true, width: 48, height: 48}" priority [class.img-thumbnail]="thumbnail"><img class="hovered-img hovered-256 img-thumbnail" *ngIf="hover" ngSrc="{{label.logo_details.file}}" [width]="256" [height]="256" [loaderParams]="{contain: true, width: 256, height: 256}" loading="auto"></div>
  <div class="hoverable" *ngIf="label.logo_details &amp;&amp; mode==='list-preview'" (click)="clicked.emit(label)" role="button"><img ngSrc="{{label.logo_details.file}}" [width]="64" [height]="64" [loaderParams]="{contain: true, width: 64, height: 64}" priority [class.img-thumbnail]="thumbnail"><img class="hovered-img hovered-256 img-thumbnail" *ngIf="hover" ngSrc="{{label.logo_details.file}}" [width]="256" [height]="256" [loaderParams]="{contain: true, width: 256, height: 256}" loading="auto"></div>
  <div class="hoverable" *ngIf="label.logo_details &amp;&amp; (mode==='large' || mode === 'preview')" (click)="clicked.emit(label)" role="button"><img ngSrc="{{label.logo_details.file}}" [width]="128" [height]="128" [loaderParams]="{contain: true, width: 128, height: 128}" priority [class.img-thumbnail]="thumbnail"><img class="hovered-img hovered-512 img-thumbnail" *ngIf="hover" ngSrc="{{label.logo_details.file}}" [width]="512" [height]="512" [loaderParams]="{contain: true, width: 512, height: 512}" loading="auto"></div>
  <!---->
  <!--img(*ngIf="label.logo_details && mode=='medium'", ngSrc="{{label.logo_details.file}}", [width]="48", [height]="48",-->
  <!--  [loaderParams]="{contain: true, width: 48, height: 48}",-->
  <!--  priority, [title]="label.code")-->
  <!--img(*ngIf="label.logo_details && mode=='small'", ngSrc="{{label.logo_details.file}}", [width]="24", [height]="24",-->
  <!--  [loaderParams]="{contain: true, width: 24, height: 24}",-->
  <!--  priority, [title]="label.code")-->
  <!--img(*ngIf="label.logo_details && mode=='list'", ngSrc="{{label.logo_details.file}}", [width]="24", [height]="24",-->
  <!--  [loaderParams]="{contain: true, width: 24, height: 24}",-->
  <!--  priority, [title]="label.code")-->
  <!--img(*ngIf="label.logo_details && mode=='list-medium'", ngSrc="{{label.logo_details.file}}", [width]="36", [height]="36",-->
  <!--  [loaderParams]="{contain: true, width: 36, height: 36}",-->
  <!--  priority, [title]="label.code")--><span class="visually-hidden">{{label.code}}&nbsp;</span>
</ng-container>