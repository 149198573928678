import { DestroyRef, Directive, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseRouteParams } from '@solidev/data';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive()
export class BaseViewComponent<RD extends BaseRouteParams> implements OnInit, OnDestroy {
  public data$!: Observable<RD>;
  public page_title = signal('');
  protected _destroyRef = inject(DestroyRef);

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
  ) {}

  protected _name!: string;

  public get name(): string {
    return this._name;
  }

  public ngOnInit(): void {
    this.preNgOnInit();
    this.data$ = this._route.data.pipe(
      takeUntilDestroyed(this._destroyRef),
      map((d) => d as RD),
      // Set _name as route name
      tap((d: RD) => (this._name = d.route.name)),
      tap((d: RD) => {
        // If d.title is a string, set page_title to it
        if (typeof d.title === 'string') {
          this.page_title.set(d.title);
        }
        // If it is a function, call it with d as argument
        else if (typeof d.title === 'function') {
          this.page_title.set(d.title(d));
        } else {
          this.page_title.set(this._name);
        }
      }),
      tap((d: RD) => this.setRouteData(d)),
    );
    this.postNgOnInit();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public setRouteData(data: RD) {}

  public ngOnDestroy(): void {
    this.preNgOnDestroy();
    this.postNgOnDestroy();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public preNgOnInit(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public postNgOnInit(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public preNgOnDestroy(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public postNgOnDestroy(): void {}
}
