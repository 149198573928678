import { RoutesConfig } from '@solidev/data';
import { Print, RS } from '@vivalya/lvadg';

export const ADMIN_LAYOUTS_ROUTES_NAMES = ['layouts', 'layouts_prints', 'layouts_prints_print'] as const;
export const ADMIN_LAYOUTS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_LAYOUTS_ROUTES_NAMES)[number]> = {
  layouts: {
    params: [],
    route: () => ['/', RS.layouts],
    name: 'layouts',
  },
  layouts_prints: {
    params: [],
    route: () => ['/', RS.layouts, RS.prints],
    name: 'layouts_prints',
  },
  layouts_prints_print: {
    params: ['print'],
    route: (p: Print) => ['/', RS.layouts, RS.prints, p.id],
    name: 'layouts_prints_print',
  },
};
