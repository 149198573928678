import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticularityService } from '../particularity.service';
import { Particularity } from '../particularity';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lvadg-particularity-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './particularity-create.component.pug',
  styleUrls: ['./particularity-create.component.sass'],
})
export class ParticularityCreateComponent implements OnInit {
  public particularity!: Particularity;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<Particularity>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _parts: ParticularityService,
    private _msgs: DataMessageService,
  ) {}

  public ngOnInit(): void {
    this.particularity = new Particularity(this._parts);
  }

  public async save() {
    this.particularity.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.particularity.save({ updateModel: true }));
      this.created.emit(this.particularity);
      this._msgs.success('Particularité créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
