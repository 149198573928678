import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeStoragePermission } from '../../../../models/tarifs/tariftype-storage-permission/tariftype-storage-permission';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { StorageBase } from '../../../../models/structures/storage/storage.base';
import { TariftypeStoragePermissionListComponent } from '../../../../models/tarifs/tariftype-storage-permission/tariftype-storage-permission-list/tariftype-storage-permission-list.component';

export interface TariftypeStoragePermissionListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
}

export interface TariftypeStoragePermissionListViewData extends TariftypeStoragePermissionListViewParams {}

@Component({
  selector: 'lvadg-tariftype-storage-permission-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TariftypeStoragePermissionListComponent],
  templateUrl: './tariftype-storage-permission-list-view.component.pug',
  styleUrls: ['./tariftype-storage-permission-list-view.component.sass'],
})
export class TariftypeStoragePermissionListViewComponent extends BaseListViewComponent<
  TariftypeStoragePermissionListViewData,
  TariftypeStoragePermission
> {
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public tariftype_detail_route!: Link<TariftypeBase>;
  public storage_detail_route!: Link<StorageBase>;
  public default_fields = [
    'id',
    'tariftype_details',
    'storage_details',
    'user_details',
    'group_details',
    'permission',
    'created',
    'updated',
  ];

  public override setRouteData(data: TariftypeStoragePermissionListViewData) {
    super.setRouteData(data);
    this.user_detail_route = new Link(data.user_detail_route, data);
    this.group_detail_route = new Link(data.group_detail_route, data);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data);
    this.storage_detail_route = new Link(data.storage_detail_route, data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
