
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { UserMapLayer } from "./user-map-layer";

@Injectable({
  providedIn: 'root'
})
export class UserMapLayerService extends Collection<UserMapLayer> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/usermaplayers", UserMapLayer);
  }
}
