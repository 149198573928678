
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Platform } from "./platform";

@Injectable({
  providedIn: 'root'
})
export class PlatformService extends Collection<Platform> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/platforms", Platform);
  }
}
