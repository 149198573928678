import { charField, DataModel, datetimeField, foreignKeyField, primaryField } from '@solidev/data';

export enum TARIFTYPEREGION_PERMISSION {
  WRITE = 'WRITE',
  READ = 'READ',
}

export class TariftypeRegionPermissionBase extends DataModel {
  static override readonly __name: string = 'TariftypeRegionPermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    choices: [
      {
        value: TARIFTYPEREGION_PERMISSION.WRITE,
        desc: 'Saisie tarif/dispo région',
      },
      {
        value: TARIFTYPEREGION_PERMISSION.READ,
        desc: 'Consultation tarif/dispo région',
      },
    ],
    priority: 300,
  })
  public permission!: TARIFTYPEREGION_PERMISSION;

  @foreignKeyField({
    name: 'target',
    description: 'ID modèle de tarif',
    related: 'TarifTypeRegion',
    priority: -1,
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
    priority: -1,
  })
  public group!: number;
}
