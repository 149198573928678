import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserBase } from '../user.base';
import { User } from '../user';
import { DataMessageService, Link } from '@solidev/data';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'lvadg-user-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './user-display.component.pug',
  styleUrls: ['./user-display.component.sass'],
})
export class UserDisplayComponent {
  @Input() public user?: UserBase | User;
  @Input() public mode: 'line' = 'line' as const;
  @Input() public detail_route?: Link<UserBase>;
  @Input() public showCodes: boolean;
  @Input() public xlsx: boolean = false;
  private _msgs: DataMessageService;

  constructor() {
    const user = inject(AuthService).currentUser;
    this.showCodes = user?.is_superuser || user?.is_staff || false;
    this._msgs = inject(DataMessageService);
  }

  public async clip() {
    await navigator.clipboard.writeText(this.user?.email || '');
    this._msgs.success('Email utilisateur copié dans le presse-papier');
  }
}
