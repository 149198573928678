import { LogUsageBase } from "./log-usage.base";
import { detailsField } from "@solidev/data";
import { UserBase } from "../../users/user/user.base";

export class LogUsage extends LogUsageBase {
  static override readonly __name: string = "LogUsage";

  @detailsField({
    name: "user_details",
    model: UserBase,
    description: "Utilisateur",
    priority: 600,
  })
  public user_details?: UserBase;
}
