
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderprocessarticle } from '../orderprocessarticle';
@Component({
  selector: 'lvadg-orderprocessarticle-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './orderprocessarticle-manage.component.pug',
  styleUrls: ['./orderprocessarticle-manage.component.sass']
})
export class OrderprocessarticleManageComponent implements OnInit {
  @Input() public orderprocessarticle?: Orderprocessarticle;

  constructor() { }

  ngOnInit(): void {
  }

}
