import { Injectable } from '@angular/core';
import { DataMessageService } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../components/utils/errormessage';
import { User } from './user';

export interface UserActionResult<T> {
  user: User;
  message: string;
  data?: T;
  success: boolean;
}

interface UserActionSyncMetaGroups {
  action: 'sync_meta_groups';
}

interface UserActionRemoveFromGroup {
  action: 'remove_from_group';
  group: number;
}

interface UserActionAddToGroup {
  action: 'add_to_group';
  group: number;
}

interface UserActionGetNotificationsSettings {
  action: 'get_notifications_settings';
}

export interface NotificationStatusActionParams {
  do:
    | 'deactivate_tarifs_notifications'
    | 'activate_tarifs_notifications'
    | 'deactivate_tarifs_autoabo'
    | 'activate_tarifs_autoabo'
    | 'uncheck_tarifs_bells'
    | 'uncheck_tarifs_stars';
  until?: string;
}

interface UserActionChangeNotificationsSettings
  extends NotificationStatusActionParams {
  action: 'change_notifications_settings';
}

export type UserAction =
  | UserActionSyncMetaGroups
  | UserActionRemoveFromGroup
  | UserActionAddToGroup
  | UserActionGetNotificationsSettings
  | UserActionChangeNotificationsSettings;

@Injectable({
  providedIn: 'root',
})
export class UserActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action<T>(
    user: User,
    action: UserAction,
    messages: Record<string, string> = {}
  ): Promise<UserActionResult<T>> {
    try {
      const res = await firstValueFrom(
        user.action<User, UserActionResult<T>>('POST', 'action', {
          body: {
            ...action,
          },
        })
      );
      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { user: user, message: message, success: false };
    }
  }
}
