import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { TarifNotificationStatus } from './tarif-notification-status';

@Injectable({
  providedIn: 'root',
})
export class TarifNotificationStatusService extends Collection<TarifNotificationStatus> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/tarifnotificationstatuses', TarifNotificationStatus);
  }
}
