import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Client } from '../../../../../models/structures/client/client';
import { CLIENT_ROUTES } from '../menu';
import { ClientNavComponent } from '../_nav/client-nav.component';
import { EarticleListComponent } from '../../../../../models/catalog/earticle/earticle-list/earticle-list.component';
import { Member } from '../../../../../models/structures/member/member';
import { Eacollection } from '../../../../../models/catalog/eacollection/eacollection';
import { Provider } from '../../../../../models/structures/provider/provider';
import { Storage } from '../../../../../models/structures/storage/storage';
import { Earticle } from '../../../../../models/catalog/earticle/earticle';

export interface ClientEarticlesViewParams extends BaseRouteParams {
  c_menu: CLIENT_ROUTES;
  client_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  member_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
  collection_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
}

export interface ClientEarticlesViewData extends ClientEarticlesViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-earticles-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, EarticleListComponent, ClientNavComponent, IconComponent],
  templateUrl: './client-earticles-view.component.pug',
  styleUrls: ['./client-earticles-view.component.sass'],
})
export class ClientEarticlesViewComponent extends BaseDetailViewComponent<ClientEarticlesViewData, Client> {
  public clientFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = [];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public earticle_detail_route?: Link<Earticle>;
  public client_detail_route?: Link<Client>;
  public storage_detail_route?: Link<Storage>;
  public member_detail_route?: Link<Member>;
  public provider_detail_route?: Link<Provider>;
  public collection_detail_route?: Link<Eacollection>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ClientEarticlesViewData) {
    super.setRouteData(data);
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link(data.earticle_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link(data.client_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(data.storage_detail_route, data, this._router);
    }
    if (data.member_detail_route) {
      this.member_detail_route = new Link(data.member_detail_route, data, this._router);
    }
    if (data.provider_detail_route) {
      this.provider_detail_route = new Link(data.provider_detail_route, data, this._router);
    }
    if (data.collection_detail_route) {
      this.collection_detail_route = new Link(data.collection_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.clientFilter$ = this.data$.pipe(map((d) => ({ client: d.client.id })));
  }
}
