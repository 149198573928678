import { FolderBase } from "./folder.base";
import { computedField, detailsField } from "@solidev/data";
import { Image } from "../image/image";

export class Folder extends FolderBase {
  static override readonly __name: string = "Folder";
  @detailsField({
    description: "Parents",
    many: true,
    model: FolderBase,
    readonly: true,
  })
  public parents_details?: Folder[];

  @detailsField({
    description: "Image",
    model: Image,
    readonly: true,
  })
  public image_details?: Image;

  @computedField({
    description: "Parent",
    name: "parent_details",
    readonly: true,
  })
  public get parent_details(): Folder | undefined {
    return this.parents_details?.[this.parents_details.length - 1];
  }

  public set parent_details(value: Folder | undefined) {
    if (value) {
      this.parents_details = [value];
    } else {
      this.parents_details = [];
    }
  }
}
