import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { ArticleStats } from './article-stats';

@Injectable({
  providedIn: 'root',
})
export class ArticleStatsService extends Collection<ArticleStats> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/articles', ArticleStats);
  }
}
