import { RnmtarifBase } from './rnmtarif.base';
import { detailsField } from '@solidev/data';
import { RnmlibelleBase } from '../rnmlibelle/rnmlibelle.base';
import { RnmmarcheBase } from '../rnmmarche/rnmmarche.base';
import { Rnmcotation } from '../public_api';

export class Rnmtarif extends RnmtarifBase {
  static override readonly __name: string = 'Rnmtarif';
  @detailsField({
    model: RnmlibelleBase,
    readonly: true,
    description: 'Libellé',
  })
  public rnmlib_details?: RnmlibelleBase;

  @detailsField({
    model: Rnmcotation,
    readonly: true,
    description: 'Cotation',
  })
  public cotation_details?: Rnmcotation;

  @detailsField({
    model: RnmmarcheBase,
    readonly: true,
    description: 'Marché',
  })
  public marche_details?: RnmmarcheBase;
}
