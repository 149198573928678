
<ng-container *ngIf="offertypetext">
  <dl class="row">
    <data-dispedit class="col-12" [model]="offertypetext" field="title" mode="dd">Titre</data-dispedit>
    <data-richedit class="col-12" [model]="offertypetext" field="text">Texte</data-richedit>
    <data-dispedit class="col-3" [model]="offertypetext" field="date_start" mode="dd">Date de début</data-dispedit>
    <data-dispedit class="col-3" [model]="offertypetext" field="date_applicable" mode="dd">Date d'application</data-dispedit>
    <data-dispedit class="col-3" [model]="offertypetext" field="date_end" mode="dd">Date de fin</data-dispedit>
    <data-dispedit class="col-3" [model]="offertypetext" field="status" mode="dd">État</data-dispedit>
  </dl>
</ng-container>