
<ng-container *ngIf="tariftypemetadata">
  <dl class="row">
    <data-dispedit [model]="tariftypemetadata" mode="dd" field="name">Nom / description</data-dispedit>
  </dl>
  <ng-container *ngIf="mparams$.TYPES|async; let params">
    <ng-container *ngIf="tariftypedocument">
      <ng-container [ngSwitch]="tariftypemetadata.type">
        <div *ngSwitchCase="TTMT.EACLIENT_IMPORT_PARAMS">
          <h3>Importation des références client</h3>
          <ng-container *ngIf="xlsxData">
            <lvadg-xlsx-params [xlsxData]="xlsxData" [params]="tariftypemetadata.data!" [desc_mandatory]="params[TTMT.EACLIENT_IMPORT_PARAMS].desc_mandatory" [desc_keep]="params[TTMT.EACLIENT_IMPORT_PARAMS].desc_keep" [desc_match]="params[TTMT.EACLIENT_IMPORT_PARAMS].desc_match" [desc_update]="params[TTMT.EACLIENT_IMPORT_PARAMS].desc_update" [desc_create]="params[TTMT.EACLIENT_IMPORT_PARAMS].desc_create" [dest_match_columns]="params[TTMT.EACLIENT_IMPORT_PARAMS].dest_match_columns" [dest_update_columns]="params[TTMT.EACLIENT_IMPORT_PARAMS].dest_update_columns" [dest_create_columns]="params[TTMT.EACLIENT_IMPORT_PARAMS].dest_create_columns" (save)="saveParams($event)"></lvadg-xlsx-params>
          </ng-container>
        </div>
        <div *ngSwitchCase="TTMT.EAPRICE_READ_PARAMS">
          <h3>Lecture des prix dans xlsx client</h3>
          <ng-container *ngIf="xlsxData">
            <lvadg-xlsx-params [xlsxData]="xlsxData" [params]="tariftypemetadata.data!" [desc_mandatory]="params[TTMT.EAPRICE_READ_PARAMS].desc_mandatory" [desc_keep]="params[TTMT.EAPRICE_READ_PARAMS].desc_keep" [desc_match]="params[TTMT.EAPRICE_READ_PARAMS].desc_match" [desc_update]="params[TTMT.EAPRICE_READ_PARAMS].desc_update" [desc_create]="params[TTMT.EAPRICE_READ_PARAMS].desc_create" [dest_match_columns]="params[TTMT.EAPRICE_READ_PARAMS].dest_match_columns" [dest_update_columns]="params[TTMT.EAPRICE_READ_PARAMS].dest_update_columns" [dest_create_columns]="params[TTMT.EAPRICE_READ_PARAMS].dest_create_columns" (save)="saveParams($event)"></lvadg-xlsx-params>
          </ng-container>
        </div>
        <div *ngSwitchCase="TTMT.EACLIENT_READ_PARAMS">Read articles params
          <ng-container *ngIf="xlsxData">
            <lvadg-xlsx-params [xlsxData]="xlsxData" [params]="tariftypemetadata.data!" [desc_mandatory]="params[TTMT.EACLIENT_READ_PARAMS].desc_mandatory" [desc_keep]="params[TTMT.EACLIENT_READ_PARAMS].desc_keep" [desc_match]="params[TTMT.EACLIENT_READ_PARAMS].desc_match" [desc_update]="params[TTMT.EACLIENT_READ_PARAMS].desc_update" [desc_create]="params[TTMT.EACLIENT_READ_PARAMS].desc_create" [dest_match_columns]="params[TTMT.EACLIENT_READ_PARAMS].dest_match_columns" [dest_update_columns]="params[TTMT.EACLIENT_READ_PARAMS].dest_update_columns" [dest_create_columns]="params[TTMT.EACLIENT_READ_PARAMS].dest_create_columns" (save)="saveParams($event)"></lvadg-xlsx-params>
          </ng-container>
        </div>
        <div *ngSwitchCase="TTMT.EAPRICE_WRITE_PARAMS">Write prices params
          <ng-container *ngIf="xlsxData">
            <lvadg-xlsx-params [xlsxData]="xlsxData" [params]="tariftypemetadata.data!" [desc_mandatory]="params[TTMT.EAPRICE_WRITE_PARAMS].desc_mandatory" [desc_keep]="params[TTMT.EAPRICE_WRITE_PARAMS].desc_keep" [desc_match]="params[TTMT.EAPRICE_WRITE_PARAMS].desc_match" [desc_update]="params[TTMT.EAPRICE_WRITE_PARAMS].desc_update" [desc_create]="params[TTMT.EAPRICE_WRITE_PARAMS].desc_create" [dest_match_columns]="params[TTMT.EAPRICE_WRITE_PARAMS].dest_match_columns" [dest_update_columns]="params[TTMT.EAPRICE_WRITE_PARAMS].dest_update_columns" [dest_create_columns]="params[TTMT.EAPRICE_WRITE_PARAMS].dest_create_columns" (save)="saveParams($event)"></lvadg-xlsx-params>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>