
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TarifRelation } from "./tarif-relation";

@Injectable({
  providedIn: 'root'
})
export class TarifRelationService extends Collection<TarifRelation> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tarifrelations", TarifRelation);
  }
}
