import { RegionBase } from './region.base';
import { detailsField } from '@solidev/data';
import { Georegion } from '../../../locations/georegion/georegion';
import { Geodepartement } from '../../../locations/geodepartement/geodepartement';
import { Geocommune } from '../../../locations/geocommune/geocommune';

export class Region extends RegionBase {
  static override readonly __name: string = 'Region';

  /** Geo region */
  @detailsField({
    name: 'georegion_details',
    description: 'Région (géo)',
    readonly: true,
    priority: 300,
  })
  public georegion_details?: Georegion;

  /** Geo department */
  @detailsField({
    name: 'geodepartement_details',
    description: 'Département (géo)',
    readonly: true,
    priority: 300,
  })
  public geodepartement_details?: Geodepartement;

  /** Geo commune */
  @detailsField({
    name: 'geocommune_details',
    description: 'Commune (géo)',
    readonly: true,
    priority: 300,
  })
  public geocommune_details?: Geocommune;
}
