import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Producer } from "../producer";
import { Link } from "@solidev/data";
import { ProducerBase } from "../producer.base";
import { RouterLink } from "@angular/router";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";

@Component({
  selector: "lvadg-producer-display",
  standalone: true,
  imports: [CommonModule, RouterLink, LocationDisplayComponent],
  templateUrl: "./producer-display.component.pug",
  styleUrls: ["./producer-display.component.sass"],
})
export class ProducerDisplayComponent {
  @Input() public producer?: Producer;
  @Input() public mode: "line" | "linewloc" | "linewcpville" | "1linewcpville" =
    "line";
  @Input() public show_location: boolean = false;
  @Input() public detail_route?: Link<ProducerBase | Producer>;
}
