import { Component } from '@angular/core';

import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeStorage } from '../../../../models/tarifs/tariftype-storage/tariftype-storage';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { StorageBase } from '../../../../models/structures/storage/storage.base';
import { TariftypeStorageListComponent } from '../../../../models/tarifs/tariftype-storage/tariftype-storage-list/tariftype-storage-list.component';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { TARIFTYPE_ROUTES } from '../../tariftypes/detail/menu';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { TariftypeStorageCreateComponent } from '../../../../models/tarifs/tariftype-storage/tariftype-storage-create/tariftype-storage-create.component';
import { TariftypeDetailNavComponent } from '../../tariftypes/detail/nav/tariftype-detail-nav.component';

export interface TariftypeStorageListViewParams extends BaseRouteParams {
  detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeStorageListViewData extends TariftypeStorageListViewParams {
  tariftype?: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-storage-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    IconComponent,
    HeaderActionComponent,
    TariftypeStorageCreateComponent,
    TariftypeStorageListComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-storage-list-view.component.pug',
  styleUrls: ['./tariftype-storage-list-view.component.sass'],
})
export class TariftypeStorageListViewComponent extends BaseListViewComponent<
  TariftypeStorageListViewData,
  TariftypeStorage
> {
  public createStorage = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifTypeFilter$!: Observable<FilterDefaults>;

  public default_fields = [
    'id',
    'tariftype_details',
    'storage_details',
    'perms_details',
    'created',
    'updated',
    'actions',
  ];
  public tariftype_detail_route?: Link<TariftypeBase>;
  public storage_detail_route?: Link<StorageBase>;
  public user_detail_route?: Link<UserBase>;
  public group_detail_route?: Link<GroupBase>;

  public override setRouteData(data: TariftypeStorageListViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(data.storage_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return d.tariftype ? { tariftype: d.tariftype.id } : {};
      }),
    );
  }
}
