
<ng-template #placeholder>
  <div class="text-center"><i class="spinner-border" style="margin: 3cm 0; width: 3rem; height: 3rem;"></i></div>
</ng-template>
<ng-template #notif>
  <div class="row" *ngIf="status$|async; let stt; else placeholder">
    <div class="col-md-6 d-flex flex-column justify-content-between notification-toggle">
      <dl>
        <dt>État des notifications</dt>
        <dd>
          <ng-container *ngIf="!stt.disabled.status">Activées</ng-container>
          <ng-container *ngIf="stt.disabled.status">
            <ng-container *ngIf="stt.disabled.until">Désactivées jusqu'au {{stt.disabled.until|date:'longDate'}}</ng-container>
            <ng-container *ngIf="!stt.disabled.until">Désactivées (sans date de fin)</ng-container>
          </ng-container>
        </dd>
      </dl>
      <div class="action">
        <ng-container *ngIf="!stt.disabled.status">
          <div class="row mb-2">
            <div class="col-8">
              <label id="deactivatelabel">Désactiver jusqu'au</label>
              <input class="form-control" type="date" [formControl]="disableDate" aria-labelledby="deactivatelabel">
            </div>
            <div class="col-4">
              <button class="btn btn-outline-secondary w-100 h-100" (click)="disableDate.reset(null)">Pas de date</button>
            </div>
          </div>
          <button class="btn btn-outline-warning w-100" (click)="action('deactivate_tarifs_notifications')">Désactiver les notifications de publication de tarif</button>
        </ng-container>
        <ng-container *ngIf="stt.disabled.status">
          <button class="btn btn-outline-primary w-100" (click)="action('activate_tarifs_notifications')">Réactiver les notifications de publication de tarif</button>
        </ng-container>
      </div>
    </div>
    <div class="col-md-6 d-flex flex-column justify-content-between autoabo-toggle">
      <dl>
        <dt>Abonnement aux nouveaux tarifs</dt>
        <dd>
          <ng-container *ngIf="stt.autoabo">Activé
            <div class="small text-info ms-2">Lorsqu'un nouveau tarif est créé, vous y serez automatiquement abonné</div>
          </ng-container>
          <ng-container *ngIf="!stt.autoabo">Désactivé
            <div class="small text-warning">Lorsqu'un nouveau tarif est créé, vous ne serez PAS automatiquement abonné</div>
          </ng-container>
        </dd>
      </dl>
      <div class="action">
        <ng-container *ngIf="stt.autoabo">
          <button class="btn btn-outline-warning w-100" (click)="action('deactivate_tarifs_autoabo')">Désactiver l'abonnement aux nouveaux tarifs</button>
        </ng-container>
        <ng-container *ngIf="!stt.autoabo">
          <button class="btn btn-outline-primary w-100" (click)="action('activate_tarifs_autoabo')">Réactiver l'abonnement aux nouveaux tarifs</button>
        </ng-container>
      </div>
    </div>
    <ng-container>
      <div class="col-12 mt-3 mb-1">
        <div class="fw-bold">Opérations groupées</div>
      </div>
      <div class="col-md-6">
        <button class="btn btn-outline-danger w-100" (click)="action('uncheck_tarifs_bells')">Décocher tous mes tarifs avec notification de publication
          <div class="small">{{stt.count.bells}} tarif(s)</div>
        </button>
        <div class="small">Pour réactiver les notifications, vous devrez sélectionner (icône cloche) les tarifs pour lesquels vous désirez recevoir des notifications.</div>
      </div>
      <div class="col-md-6">
        <button class="btn btn-outline-danger w-100" (click)="action('uncheck_tarifs_stars')">Décocher tous mes tarifs préférés
          <div class="small">{{stt.count.stars}} tarif(s)</div>
        </button>
        <div class="small">Pour rajouter à nouveau des tarifs dans votre portfolio de tarifs préférés, vous devrez sélectionner (icône étoile) les tarifs désirés pour les rajouter. Cette opération désactive aussi toutes les notifications.</div>
      </div>
    </ng-container>
  </div>
</ng-template>
<div class="card">
  <div class="card-header">
    <lvadg-icon ri="bell">Préférences de notification de tarifs</lvadg-icon>
  </div>
  <div class="card-body">
    <ng-container *ngTemplateOutlet="notif"></ng-container>
  </div>
</div>