
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TariftypePermissionService} from "./tariftype-permission.service";
import { TariftypePermission } from "./tariftype-permission";

@Injectable({
  providedIn: 'root'
})
export class TariftypePermissionResolver  {
  constructor(private _rs: TariftypePermissionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TariftypePermission> {
    return this._rs.fetch(+route.params["tariftypepermissionId"])
  }
}
