
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TariftypeRelation } from "./tariftype-relation";

@Injectable({
  providedIn: 'root'
})
export class TariftypeRelationService extends Collection<TariftypeRelation> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tariftyperelations", TariftypeRelation);
  }
}
