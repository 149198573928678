
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { ArticleTarifService} from "./article-tarif.service";
import { ArticleTarif } from "./article-tarif";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifResolver  {
  constructor(private _rs: ArticleTarifService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleTarif> {
    return this._rs.fetch(+route.params["article-tarifId"])
  }
}
