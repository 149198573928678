import { Component, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { BaseListViewComponent } from "../../../../../components/baseview/baselistview.component";
import { Subject } from "rxjs";
import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";
import { ArticleBase } from "../../../../../models/catalog/article/article.base";
import { ArticleDocument } from "../../../../../models/catalog/article-document/article-document";
import { ArticleDocumentCreateComponent } from "../../../../../models/catalog/article-document/article-document-create/article-document-create.component";
import { ArticleDocumentListComponent } from "../../../../../models/catalog/article-document/article-document-list/article-document-list.component";

export interface ArticleDocumentListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export type ArticleDocumentListViewData = ArticleDocumentListViewParams;

@Component({
  selector: "lvadg-article-document-list-view",
  standalone: true,
  imports: [
    CommonModule,
    ArticleDocumentListComponent,
    ArticleDocumentCreateComponent,
    HeaderActionComponent,
  ],
  templateUrl: "./article-document-list-view.component.pug",
  styleUrl: "./article-document-list-view.component.sass",
})
export class ArticleDocumentListViewComponent extends BaseListViewComponent<
  ArticleDocumentListViewData,
  ArticleDocument
> {
  public create = signal<boolean>(false);
  public reload$ = new Subject<void | boolean>();
  public article_detail_route?: Link<ArticleBase>;

  public override setRouteData(data: ArticleDocumentListViewData) {
    super.setRouteData(data);
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      data,
      this._router,
    );
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      data,
      this._router,
    );
  }

  public created(pd: ArticleDocument) {
    this.create.set(false);
    this.reload$.next(true);
    console.log("created", pd);
    this.detail_route.navigate({ articledocument: pd });
  }
}
