import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  model,
  signal,
} from "@angular/core";
import { CdataKey } from "../../cdata-key/cdata-key";
import { Cdata } from "../cdata";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { CDATAKEY_TYPE } from "../../cdata-key/cdata-key.base";
import { FormsModule } from "@angular/forms";
import { NgTemplateOutlet } from "@angular/common";

@Component({
  selector: "lvadg-cdata-dispmanage",
  standalone: true,
  imports: [IconComponent, FormsModule, NgTemplateOutlet],
  template: `
    <ng-template #editor>
      @switch (key().type) {
        @case (CDATAKEY_TYPE.SHORT_TEXT) {
          <input
            type="text"
            class="form-control form-control-sm"
            [ngModel]="cdata()?.value_stx || ''"
            (ngModelChange)="setValue('value_stx', $event)"
            (keyup.escape)="edit.set(false)"
            (keyup.enter)="save()"
          />
        }
        @case (CDATAKEY_TYPE.SINGLE_CHOICE) {
          <select
            class="form-select form-select-sm"
            [ngModel]="cdata()?.choice || ''"
            (ngModelChange)="setValue('choice', $event, true)"
            (keyup.escape)="edit.set(false)"
          >
            @for (choice of key().choices_details; track choice.id) {
              <option [ngValue]="choice.id">
                {{ choice.value_stx || choice.name }}
              </option>
            }
          </select>
        }
        @case (CDATAKEY_TYPE.INTEGER) {
          <input
            class="form-control form-control-sm"
            type="number"
            [ngModel]="cdata()?.value_int || ''"
            (ngModelChange)="setValue('value_int', $event, false)"
            (keyup.escape)="edit.set(false)"
            (keyup.enter)="save()"
          />
        }
        @case (CDATAKEY_TYPE.CURRENCY) {
          <input
            type="number"
            class="form-control form-control-sm"
            [ngModel]="cdata()?.value_dec || 0"
            (ngModelChange)="setValue('value_dec', $event, false)"
            (keyup.escape)="edit.set(false)"
            (keyup.enter)="save()"
          />
        }
      }
    </ng-template>

    @if (edit(); as e) {
      <div class="d-flex flex-row justify-content-start">
        <lvadg-icon
          ri="save"
          [icon_only]="true"
          class="me-4 text-success"
          role="button"
          (click)="save()"
        />
        <ng-container *ngTemplateOutlet="editor"></ng-container>
      </div>
    } @else {
      @if (cdata(); as c) {
        <lvadg-icon
          ri="edit"
          [icon_only]="true"
          class="me-4"
          role="button"
          (click)="edit.set(!edit())"
        />
        {{ c.display_value }}
      } @else {
        <lvadg-icon
          ri="add"
          [icon_only]="true"
          class="me-4"
          role="button"
          (click)="edit.set(!edit())"
        />
        <span class="text-muted">N/A</span>
      }
    }
  `,
  styleUrl: "./cdata-dispmanage.component.sass",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CdataDispmanageComponent {
  public edit = signal<boolean>(false);
  public key = input.required<CdataKey>();
  public cdata = model.required<Cdata | undefined>();
  public current = new Cdata();
  protected readonly CDATAKEY_TYPE = CDATAKEY_TYPE;

  constructor() {
    effect(() => {
      this.current = new Cdata();
      const key = this.key();
      const cur = this.cdata();
      this.current.key = key.id;
      if (cur) {
        this.current.fromJson(cur);
      }
    });
  }

  public save() {
    const v = new Cdata();
    v.fromJson(this.current);
    this.current = v;
    this.cdata.set(v);
    this.edit.set(false);
  }

  public setValue(
    field: string,
    value: number | string,
    save: boolean = false,
  ) {
    this.current.setFV(field, value);
    if (save) {
      this.save();
    }
  }
}
