import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutData, LayoutTree } from '../../layout/layout';
import { TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from '../../layout/template';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lvadg-layout-template-provider-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './layout-template-provider-checkbox.component.pug',
  styleUrls: ['./layout-template-provider-checkbox.component.sass'],
})
export class LayoutTemplateProviderCheckboxComponent implements OnInit {
  @Input() public tree!: LayoutTree;
  @Input() public layout!: LayoutData;
  @Input() public fragment!: TemplateFragment;
  @Input() public param!: TemplateParam;
  @Input() public provider!: TemplateProvider;

  public fc = new FormControl<boolean>(false, { nonNullable: true });
  public chid = Math.random().toString(36);

  constructor() {
    this.fc.valueChanges.pipe(takeUntilDestroyed()).subscribe(async (value) => {
      let selected: TemplateChoice | null = null;
      for (const choice of this.provider.choices) {
        if (choice.choice_bool() === true && value) {
          selected = choice;
          break;
        } else if (choice.choice_bool() === false && !value) {
          selected = choice;
          break;
        }
      }
      console.log(
        'valueChanges',
        selected,
        this.provider.choices,
        this.layout.layout_id,
        this.fragment.name,
        this.param.name,
        this.provider.name,
        selected?.name,
      );
      if (selected && this.layout.template?.name) {
        this.tree.set_fragment_param({
          layout: this.layout,
          group: this.layout.group,
          fragment: this.fragment,
          param: this.param,
          provider: this.provider,
          choice: selected,
        });
      }
    });
  }

  public ngOnInit() {
    // Check if a choice is selected
    if (this.provider) {
      for (const choice of this.provider.choices) {
        if (choice.choice_bool() && choice.current) {
          this.fc.setValue(true, { emitEvent: false });
          return;
        }
      }
      this.fc.setValue(false, { emitEvent: false });
    }
  }
}
