import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Group } from '../group';
import { GroupService } from '../group.service';
import {
  FieldsParams,
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { MemberDisplayComponent } from '../../../structures/member/member-display/member-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';
import { OrderprocessDisplayComponent } from '../../../orders/orderprocess/orderprocess-display/orderprocess-display.component';
import { MemberBase } from '../../../structures/member/member.base';
import { StorageBase } from '../../../structures/storage/storage.base';
import { ClientBase } from '../../../structures/client/client.base';
import { RestoBase } from '../../../structures/resto/resto.base';
import { TariftypeBase } from '../../../tarifs/tariftype/tariftype.base';
import { OrderprocessBase } from '../../../orders/orderprocess/orderprocess.base';

@Component({
  selector: 'lvadg-group-list',
  standalone: true,
  templateUrl: './group-list.component.pug',
  styleUrls: ['./group-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    IconComponent,
    MemberDisplayComponent,
    StorageDisplayComponent,
    ClientDisplayComponent,
    RestoDisplayComponent,
    TariftypeDisplayComponent,
    OrderprocessDisplayComponent,
  ],
})
export class GroupListComponent extends ModellistComponent<Group> {
  @Input() public member_detail_route?: Link<MemberBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public client_detail_route?: Link<ClientBase>;
  @Input() public resto_detail_route?: Link<RestoBase>;
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public orderprocess_detail_route?: Link<OrderprocessBase>;

  constructor(coll: GroupService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const flds = super.getFields();
    flds.custom?.push({ label: 'Liens', name: 'links_details' });
    return flds;
  }
}
