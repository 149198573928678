export * from "./offertypes/detail/menu";
export * from "./offertypes/detail/offertype-detail-view.component";
export * from "./offertypes/detail/edit/offertype-edit-view.component";
export * from "./offertypes/detail/permissions/offertype-permissions-view.component";
export * from "./offertypes/detail/_nav/offertype-nav.component";
export * from "./offertypes/list/offertype-list-view.component";
export * from "./offertype-text-types/list/offertype-text-types-view.component";
export * from "./offertype-text-types/detail/offertype-text-type-view.component";
export * from "./offertype-texts/detail/offertype-text-view.component";
export * from "./offers/list/offer-list-view.component";
export * from "./offers/detail/menu";
export * from "./offers/detail/display/offer-display-view.component";
export * from "./offers/detail/documents/offer-documents-view.component";
export * from "./offers/detail/articles/offer-articles-view.component";
export * from "./offers/detail/edit/offer-edit-view.component";
export * from "./offers/detail/products/offer-products-view.component";
export * from "./offers/detail/print-preview/offer-print-preview-view.component";
export * from "./offers/detail/_nav/offer-nav.component";
export * from "./offers/detail/notifications/offer-notifications-view.component";
export * from "./offers/detail/destinations/offer-destinations-view.component";
export * from "./offers/detail/notifications-statuses/offer-notifications-statuses-view.component";
export * from "./offers/detail/restos/offer-restos-view.component";
// Offertype storages
export * from "./offertype-storages/list/offertype-storage-list-view.component";
export * from "./offertype-storages/summary/offertype-storage-summary-view.component";
export * from "./offertype-storages/detail/menu";
export * from "./offertype-storages/detail/offertypestorage.routes";
export * from "./offertype-storages/detail/permissions/offertype-storage-permissions-view.component";
export * from "./offertype-storages/detail/edit/offertype-storage-edit-view.component";
export * from "./offertype-storages/detail/_nav/offertype-storage-nav.component";
export * from "./offertype-storages/detail/offers/offertype-storage-offers-view.component";
export * from "./offertype-storages/detail/display/offertype-storage-display-view.component";
