import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { TarifLifecycleStep } from './tarif-lifecycle-step';

@Injectable({
  providedIn: 'root',
})
export class TarifLifecycleStepService extends Collection<TarifLifecycleStep> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/tariflifecyclesteps', TarifLifecycleStep);
  }
}
