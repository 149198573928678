import { PrintDocumentBase } from './print-document.base';
import { detailsField } from '@solidev/data';
import { Image } from '../../documents/image/image';

export class PrintDocument extends PrintDocumentBase {
  static override readonly __name: string = 'PrintDocument';

  // SEE: check if we should/can move this into document base
  @detailsField({
    model: Image,
    readonly: true,
    description: 'Image',
  })
  public image_details?: Image;
}
