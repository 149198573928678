import { Component } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Provider } from "../provider";
import { ProviderService } from "../provider.service";
import {
  FiltersParams,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgStringPipesModule } from "ngx-pipes";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { ProviderDocumentDisplayComponent } from "../../provider-document/provider-document-display/provider-document-display.component";
import { ProvidersMapComponent } from "../../../locations/mapping/providers-map/providers-map.component";

@Component({
  selector: "lvadg-provider-list",
  standalone: true,
  templateUrl: "./provider-list.component.pug",
  styleUrls: ["./provider-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule,
    LocationDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    ProviderDocumentDisplayComponent,
    PChoicePipe,
    ProvidersMapComponent,
  ],
})
export class ProviderListComponent extends ModellistComponent<Provider> {
  constructor(
    coll: ProviderService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "with_articles"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "with_articles",
          name: "with_articles",
          label: "Recherche avec/sans articles",
          choices: [
            { value: "yes", desc: "Avec un article" },
            { value: "no", desc: "Sans article" },
          ],
        }),
      ],
    };
  }
}
