export enum Units {
  KG = 'K',
  GRAM = 'G',
  LITRE = 'L',
  PIECE = 'P',
  COLIS = 'C',
  PALETTE = 'T',
  BARQUETTE = 'B',
  NONE = '',
}

export const UnitsChoices = [
  { value: Units.KG, desc: 'Kg' },
  { value: Units.GRAM, desc: 'g' },
  { value: Units.LITRE, desc: 'L' },
  { value: Units.PIECE, desc: 'Pce' },
  { value: Units.COLIS, desc: 'Colis' },
  { value: Units.PALETTE, desc: 'Palette' },
  { value: Units.BARQUETTE, desc: 'Barquette' },
  { value: Units.NONE, desc: '-' },
];

export const unitConvert = (
  value: number,
  from: Units,
  to: Units
): number | null => {
  if (from === to) {
    return value;
  }
  if (from === Units.KG && to === Units.GRAM) {
    return value * 1000;
  }
  if (from === Units.GRAM && to === Units.KG) {
    return value / 1000;
  }
  console.log('Impossible conversion', from, to);
  return null;
};
