import { OrderprocessstoragePermissionBase } from './orderprocessstorage-permission.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';
import { OrderprocessstorageBase } from '../orderprocessstorage/orderprocessstorage.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { OrderprocessBase } from '../orderprocess/orderprocess.base';
import { PlatformBase } from '../../structures/platform/platform.base';

export class OrderprocessstoragePermission extends OrderprocessstoragePermissionBase {
  static override readonly __name: string = 'OrderprocessstoragePermission';
  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: OrderprocessstorageBase,
    description: 'Dépôt / plateforme',
    readonly: true,
  })
  public target_details?: OrderprocessstorageBase;

  @detailsField({
    model: StorageBase,
    description: 'Dépôt',
    readonly: true,
  })
  public storage_details?: StorageBase;

  @detailsField({
    model: PlatformBase,
    description: 'Plateforme',
    readonly: true,
  })
  public platform_details?: PlatformBase;

  @detailsField({
    model: OrderprocessBase,
    description: 'Process de commande',
    readonly: true,
  })
  public orderprocess_details?: OrderprocessBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;
}
