
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Iocode } from "./iocode";

@Injectable({
  providedIn: 'root'
})
export class IocodeService extends Collection<Iocode> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/iocodes", Iocode);
  }
}
