import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from '../../../documents/upload/media-upload/media-upload.component';
import { DispeditComponent, UploadFile } from '@solidev/data';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeDocument } from '../tariftype-document';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { TariftypeDocumentService } from '../tariftype-document.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lvadg-tariftype-document-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule, MediaUploadComponent],
  templateUrl: './tariftype-document-create.component.pug',
  styleUrls: ['./tariftype-document-create.component.sass'],
})
export class TariftypeDocumentCreateComponent implements OnInit {
  @Input() public tariftype?: Tariftype;
  public tariftypeFilter = {
    fields: ['id', 'code', 'name'].join(','),
  };
  @Output() public uploaded = new EventEmitter<
    UploadResult<TariftypeDocument, any> // FIXME: remove this any, add proper type
  >();
  public doc!: TariftypeDocument;
  public uploadPrepare: (
    model: TariftypeDocument,
    file: UploadFile,
  ) => Promise<IPreparedUploadFile<TariftypeDocument>> = this._uploadPrepare.bind(this);

  public form = new FormGroup<{ tariftype: FormControl<number | null> }>({
    tariftype: new FormControl<number | null>(null),
  });

  constructor(
    public tariftypes$: TariftypeService,
    public tariftypedocument$: TariftypeDocumentService,
  ) {}

  ngOnInit(): void {
    this.doc = new TariftypeDocument(this.tariftypedocument$);
    if (this.tariftype) {
      this.doc.tariftype = this.tariftype.id;
      this.doc.tariftype_details = this.tariftype;
    }
  }

  private async _uploadPrepare(
    model: TariftypeDocument,
    file: UploadFile,
  ): Promise<IPreparedUploadFile<TariftypeDocument>> {
    const tt = this.tariftype?.id || this.form.value.tariftype;
    if (!tt) {
      throw new Error('No tariftype set');
    }
    return {
      model,
      url: this.tariftypes$.getUrl(tt, { suffix: '/action' }),
      data: {
        action: 'add_document',
        tarif: tt,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        type: 'OT',
      },
    };
  }
}
