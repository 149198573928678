import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';
import { TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TariftypeRelationListComponent } from '../../../../../models/tarifs/tariftype-relation/tariftype-relation-list/tariftype-relation-list.component';
import { TARIF_ROUTES } from '../menu';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';

export interface TarifRelationsViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifRelationsViewData extends TarifRelationsViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-relations-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    HeaderActionComponent,
    IconComponent,
    TariftypeRelationListComponent,
    TarifDetailNavComponent,
  ],
  templateUrl: './tarif-relations-view.component.pug',
  styleUrls: ['./tarif-relations-view.component.sass'],
})
export class TarifRelationsViewComponent extends BaseDetailViewComponent<TarifRelationsViewData, Tarif> {
  public reloadTarif$: Subject<void | boolean> = new Subject<void | boolean>();
  public tarifFilter$!: Observable<FilterDefaults>;
  public tariftype_detail_route!: Link<TariftypeBase>;
  public tarif_detail_route!: Link<TarifBase>;
  public relations_default_fields = ['id', 'src_details', 'src_current_tarifrelation', 'type', 'ops'];

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifRelationsViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, data, this._router);
    this.tarif_detail_route = new Link<TarifBase>(data.tarif_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifFilter$ = this.data$.pipe(
      map((d) => {
        return { tarif: d.tarif.id };
      }),
    );
  }
}
