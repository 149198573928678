
<ng-container *ngIf="data$|async; let data">
  <lvadg-offertype-nav *ngIf="data.ot_menu &amp;&amp; data.offertype" [routes]="data.ot_menu" [offertype]="data.offertype"></lvadg-offertype-nav>
  <lvadg-header-action [title]="data.offertype?._display" subtitle="Types de textes prédéfinis" ri="texts" level="h1">
    <ng-container actions>
      <button class="btn btn-outline-primary btn-sm" (click)="add.set(!add())">
        <lvadg-icon ri="plus">Ajouter un type de texte</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="add()">
    <div class="card-header">
      <lvadg-icon ri="plus">Ajouter un type de texte</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-offertype-text-type-create [offertype]="data.offertype" (created)="reload$.next(true); add.set(false)" (cancelled)="add.set(false)"></lvadg-offertype-text-type-create>
    </div>
  </div>
  <lvadg-offertype-text-type-list [name]="data.route.name" [reload]="reload$" [default_fields]="default_fields" [filter]="offertypeTextTypeFilter$" [detail_route]="detail_route"></lvadg-offertype-text-type-list>
</ng-container>