import { RoutesConfig } from "@solidev/data";
import { Producer, Product, RS, Storage } from "@vivalya/lvadg";

export const FRONT_STORAGES_ROUTES_NAMES = [
  "storages",
  "storages_storages",
  "storages_storages_storage",
  "storages_storages_storage_edit",
  "storages_storages_storage_cdatas",
  "storages_storages_storage_relations",
  "storages_storages_storage_offers",
  "storages_storages_storage_stats",
  "storages_storages_storage_sales",
  "storages_storages_storage_users",

  "storages_storages_storage_fl_local_producers",
  "storages_storages_storage_fl_local_producers_producer",
  "storages_storages_storage_fl_local_producers_producer_edit",
  "storages_storages_storage_fl_local_producers_producer_relations",
  "storages_storages_storage_fl_local_producers_producer_documents",
  "storages_storages_storage_fl_local_producers_producer_offers",
  "storages_storages_storage_fl_local_producers_producer_products",
  "storages_storages_storage_fl_local_producers_producer_products_product",
  "storages_storages_storage_fl_local_producers_producer_products_product_edit",
  "storages_storages_storage_fl_local_producers_producer_products_product_documents",
  "storages_storages_storage_fl_local_producers_producer_products_product_offers",
  "storages_storages_storage_fl_local_producers_producer_products_product_articles",
  "storages_storages_storage_fl_local_products",
  "storages_storages_storage_fl_local_products_product",
  "storages_storages_storage_fl_local_products_product_edit",
  "storages_storages_storage_fl_local_products_product_documents",
  "storages_storages_storage_fl_local_products_product_offers",
  "storages_storages_storage_fl_local_products_product_articles",
  "storages_storages_storage_fl_local_products_product_producer",

  "storages_storages_storage_fl_global_producers",
  "storages_storages_storage_fl_global_producers_producer",
  "storages_storages_storage_fl_global_producers_producer_edit",
  "storages_storages_storage_fl_global_producers_producer_relations",
  "storages_storages_storage_fl_global_producers_producer_documents",
  "storages_storages_storage_fl_global_producers_producer_offers",
  "storages_storages_storage_fl_global_producers_producer_products",
  "storages_storages_storage_fl_global_producers_producer_products_product",
  "storages_storages_storage_fl_global_producers_producer_products_product_edit",
  "storages_storages_storage_fl_global_producers_producer_products_product_documents",
  "storages_storages_storage_fl_global_producers_producer_products_product_offers",
  "storages_storages_storage_fl_global_producers_producer_products_product_articles",
  "storages_storages_storage_fl_global_products",
  "storages_storages_storage_fl_global_products_product",
  "storages_storages_storage_fl_global_products_product_edit",
  "storages_storages_storage_fl_global_products_product_documents",
  "storages_storages_storage_fl_global_products_product_offers",
  "storages_storages_storage_fl_global_products_product_articles",
  "storages_storages_storage_fl_global_products_product_producer",

  "storages_storages_storage_sea_producers",
  "storages_storages_storage_sea_producers_producer",
  "storages_storages_storage_sea_producers_producer_edit",
  "storages_storages_storage_sea_producers_producer_relations",
  "storages_storages_storage_sea_producers_producer_documents",
  "storages_storages_storage_sea_producers_producer_offers",
  "storages_storages_storage_sea_producers_producer_products",
  "storages_storages_storage_sea_producers_producer_products_product",
  "storages_storages_storage_sea_producers_producer_products_product_edit",
  "storages_storages_storage_sea_producers_producer_products_product_documents",
  "storages_storages_storage_sea_producers_producer_products_product_offers",
  "storages_storages_storage_sea_producers_producer_products_product_articles",
  "storages_storages_storage_sea_products",
  "storages_storages_storage_sea_products_product",
  "storages_storages_storage_sea_products_product_edit",
  "storages_storages_storage_sea_products_product_documents",
  "storages_storages_storage_sea_products_product_offers",
  "storages_storages_storage_sea_products_product_articles",
  "storages_storages_storage_sea_products_product_producer",
] as const;

export const FRONT_STORAGES_ROUTES_CONFIG: RoutesConfig<
  (typeof FRONT_STORAGES_ROUTES_NAMES)[number]
> = {
  storages: {
    name: "storages",
    params: [],
    route: () => ["/", RS.storages],
  },
  storages_storages: {
    name: "storages_storages",
    params: [],
    route: () => ["/", RS.storages, RS.storages],
  },
  storages_storages_storage: {
    name: "storages_storages_storage",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id],
  },
  storages_storages_storage_cdatas: {
    name: "storages_storages_storage_cdatas",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.cdatas],
  },
  storages_storages_storage_edit: {
    name: "storages_storages_storage_edit",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.edit],
  },
  storages_storages_storage_offers: {
    name: "storages_storages_storage_offers",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.offers],
  },

  // LOCAL
  storages_storages_storage_fl_local_producers: {
    name: "storages_storages_storage_fl_local_producers",
    params: ["storage"],
    route: (s: Storage) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
    ],
  },
  storages_storages_storage_fl_local_producers_producer: {
    name: "storages_storages_storage_fl_local_producers_producer",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
    ],
  },
  storages_storages_storage_fl_local_producers_producer_documents: {
    name: "storages_storages_storage_fl_local_producers_producer_documents",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.documents,
    ],
  },
  storages_storages_storage_fl_local_producers_producer_edit: {
    name: "storages_storages_storage_fl_local_producers_producer_edit",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.edit,
    ],
  },

  storages_storages_storage_fl_local_producers_producer_offers: {
    name: "storages_storages_storage_fl_local_producers_producer_offers",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.offers,
    ],
  },
  storages_storages_storage_fl_local_producers_producer_products: {
    name: "storages_storages_storage_fl_local_producers_producer_products",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.products,
    ],
  },
  storages_storages_storage_fl_local_producers_producer_products_product: {
    name: "storages_storages_storage_fl_local_producers_producer_products_product",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
    ],
  },
  storages_storages_storage_fl_local_producers_producer_products_product_articles:
    {
      name: "storages_storages_storage_fl_local_producers_producer_products_product_articles",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.local,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.articles,
      ],
    },
  storages_storages_storage_fl_local_producers_producer_products_product_documents:
    {
      name: "storages_storages_storage_fl_local_producers_producer_products_product_documents",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.local,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.documents,
      ],
    },
  storages_storages_storage_fl_local_producers_producer_products_product_edit: {
    name: "storages_storages_storage_fl_local_producers_producer_products_product_edit",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.edit,
    ],
  },

  storages_storages_storage_fl_local_producers_producer_products_product_offers:
    {
      name: "storages_storages_storage_fl_local_producers_producer_products_product_offers",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.local,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.offers,
      ],
    },
  storages_storages_storage_fl_local_producers_producer_relations: {
    name: "storages_storages_storage_fl_local_producers_producer_relations",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.producers,
      p.id,
      RS.relations,
    ],
  },
  storages_storages_storage_fl_local_products: {
    name: "storages_storages_storage_fl_local_products",
    params: ["storage"],
    route: (s: Storage) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
    ],
  },
  storages_storages_storage_fl_local_products_product: {
    name: "storages_storages_storage_fl_local_products_product",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
      pr.id,
    ],
  },
  storages_storages_storage_fl_local_products_product_articles: {
    name: "storages_storages_storage_fl_local_products_product_articles",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
      pr.id,
      RS.articles,
    ],
  },
  storages_storages_storage_fl_local_products_product_documents: {
    name: "storages_storages_storage_fl_local_products_product_documents",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
      pr.id,
      RS.documents,
    ],
  },

  storages_storages_storage_fl_local_products_product_edit: {
    name: "storages_storages_storage_fl_local_products_product_edit",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
      pr.id,
      RS.edit,
    ],
  },
  storages_storages_storage_fl_local_products_product_offers: {
    name: "storages_storages_storage_fl_local_products_product_offers",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
      pr.id,
      RS.offers,
    ],
  },
  storages_storages_storage_fl_local_products_product_producer: {
    name: "storages_storages_storage_fl_local_products_product_producer",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.local,
      RS.products,
      pr.id,
      RS.producer,
    ],
  },

  // Global

  storages_storages_storage_fl_global_producers: {
    name: "storages_storages_storage_fl_global_producers",
    params: ["storage"],
    route: (s: Storage) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
    ],
  },
  storages_storages_storage_fl_global_producers_producer: {
    name: "storages_storages_storage_fl_global_producers_producer",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
    ],
  },
  storages_storages_storage_fl_global_producers_producer_documents: {
    name: "storages_storages_storage_fl_global_producers_producer_documents",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
      RS.documents,
    ],
  },
  storages_storages_storage_fl_global_producers_producer_edit: {
    name: "storages_storages_storage_fl_global_producers_producer_edit",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
      RS.edit,
    ],
  },

  storages_storages_storage_fl_global_producers_producer_offers: {
    name: "storages_storages_storage_fl_global_producers_producer_offers",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
      RS.offers,
    ],
  },
  storages_storages_storage_fl_global_producers_producer_products: {
    name: "storages_storages_storage_fl_global_producers_producer_products",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
      RS.products,
    ],
  },
  storages_storages_storage_fl_global_producers_producer_products_product: {
    name: "storages_storages_storage_fl_global_producers_producer_products_product",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
    ],
  },
  storages_storages_storage_fl_global_producers_producer_products_product_articles:
    {
      name: "storages_storages_storage_fl_global_producers_producer_products_product_articles",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.global,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.articles,
      ],
    },
  storages_storages_storage_fl_global_producers_producer_products_product_documents:
    {
      name: "storages_storages_storage_fl_global_producers_producer_products_product_documents",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.global,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.documents,
      ],
    },
  storages_storages_storage_fl_global_producers_producer_products_product_edit:
    {
      name: "storages_storages_storage_fl_global_producers_producer_products_product_edit",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.global,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.edit,
      ],
    },

  storages_storages_storage_fl_global_producers_producer_products_product_offers:
    {
      name: "storages_storages_storage_fl_global_producers_producer_products_product_offers",
      params: ["storage", "producer", "product"],
      route: (s: Storage, p: Producer, pr: Product) => [
        "/",
        RS.storages,
        RS.storages,
        s.id,
        RS.fl,
        RS.global,
        RS.producers,
        p.id,
        RS.products,
        pr.id,
        RS.offers,
      ],
    },
  storages_storages_storage_fl_global_producers_producer_relations: {
    name: "storages_storages_storage_fl_global_producers_producer_relations",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.producers,
      p.id,
      RS.relations,
    ],
  },
  storages_storages_storage_fl_global_products: {
    name: "storages_storages_storage_fl_global_products",
    params: ["storage"],
    route: (s: Storage) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
    ],
  },
  storages_storages_storage_fl_global_products_product: {
    name: "storages_storages_storage_fl_global_products_product",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
      pr.id,
    ],
  },
  storages_storages_storage_fl_global_products_product_articles: {
    name: "storages_storages_storage_fl_global_products_product_articles",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
      pr.id,
      RS.articles,
    ],
  },
  storages_storages_storage_fl_global_products_product_documents: {
    name: "storages_storages_storage_fl_global_products_product_documents",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
      pr.id,
      RS.documents,
    ],
  },

  storages_storages_storage_fl_global_products_product_edit: {
    name: "storages_storages_storage_fl_global_products_product_edit",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
      pr.id,
      RS.edit,
    ],
  },
  storages_storages_storage_fl_global_products_product_offers: {
    name: "storages_storages_storage_fl_global_products_product_offers",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
      pr.id,
      RS.offers,
    ],
  },
  storages_storages_storage_fl_global_products_product_producer: {
    name: "storages_storages_storage_fl_global_products_product_producer",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.fl,
      RS.global,
      RS.products,
      pr.id,
      RS.producer,
    ],
  },
  // Sea
  storages_storages_storage_sea_producers: {
    name: "storages_storages_storage_sea_producers",
    params: ["storage"],
    route: (s: Storage) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
    ],
  },
  storages_storages_storage_sea_producers_producer: {
    name: "storages_storages_storage_sea_producers_producer",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
    ],
  },
  storages_storages_storage_sea_producers_producer_documents: {
    name: "storages_storages_storage_sea_producers_producer_documents",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.documents,
    ],
  },
  storages_storages_storage_sea_producers_producer_edit: {
    name: "storages_storages_storage_sea_producers_producer_edit",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.edit,
    ],
  },

  storages_storages_storage_sea_producers_producer_offers: {
    name: "storages_storages_storage_sea_producers_producer_offers",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.offers,
    ],
  },
  storages_storages_storage_sea_producers_producer_products: {
    name: "storages_storages_storage_sea_producers_producer_products",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
    ],
  },
  storages_storages_storage_sea_producers_producer_products_product: {
    name: "storages_storages_storage_sea_producers_producer_products_product",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
    ],
  },
  storages_storages_storage_sea_producers_producer_products_product_articles: {
    name: "storages_storages_storage_sea_producers_producer_products_product_articles",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.articles,
    ],
  },
  storages_storages_storage_sea_producers_producer_products_product_documents: {
    name: "storages_storages_storage_sea_producers_producer_products_product_documents",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.documents,
    ],
  },
  storages_storages_storage_sea_producers_producer_products_product_edit: {
    name: "storages_storages_storage_sea_producers_producer_products_product_edit",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.edit,
    ],
  },

  storages_storages_storage_sea_producers_producer_products_product_offers: {
    name: "storages_storages_storage_sea_producers_producer_products_product_offers",
    params: ["storage", "producer", "product"],
    route: (s: Storage, p: Producer, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.offers,
    ],
  },
  storages_storages_storage_sea_producers_producer_relations: {
    name: "storages_storages_storage_sea_producers_producer_relations",
    params: ["storage", "producer"],
    route: (s: Storage, p: Producer) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.producers,
      p.id,
      RS.relations,
    ],
  },
  storages_storages_storage_sea_products: {
    name: "storages_storages_storage_sea_products",
    params: ["storage"],
    route: (s: Storage) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
    ],
  },
  storages_storages_storage_sea_products_product: {
    name: "storages_storages_storage_sea_products_product",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
      pr.id,
    ],
  },
  storages_storages_storage_sea_products_product_articles: {
    name: "storages_storages_storage_sea_products_product_articles",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
      pr.id,

      RS.articles,
    ],
  },
  storages_storages_storage_sea_products_product_documents: {
    name: "storages_storages_storage_sea_products_product_documents",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
      pr.id,
      RS.documents,
    ],
  },

  storages_storages_storage_sea_products_product_edit: {
    name: "storages_storages_storage_sea_products_product_edit",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
      pr.id,
      RS.edit,
    ],
  },
  storages_storages_storage_sea_products_product_offers: {
    name: "storages_storages_storage_sea_products_product_offers",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
      pr.id,
      RS.offers,
    ],
  },
  storages_storages_storage_sea_products_product_producer: {
    name: "storages_storages_storage_sea_products_product_producer",
    params: ["storage", "product"],
    route: (s: Storage, pr: Product) => [
      "/",
      RS.storages,
      RS.storages,
      s.id,
      RS.sea,
      RS.products,
      pr.id,
      RS.producer,
    ],
  },

  storages_storages_storage_relations: {
    name: "storages_storages_storage_relations",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.relations],
  },
  storages_storages_storage_sales: {
    name: "storages_storages_storage_sales",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.sales],
  },
  storages_storages_storage_stats: {
    name: "storages_storages_storage_stats",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.stats],
  },
  storages_storages_storage_users: {
    name: "storages_storages_storage_users",
    params: ["storage"],
    route: (s: Storage) => ["/", RS.storages, RS.storages, s.id, RS.users],
  },
};
