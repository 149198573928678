import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Ordergroup } from '../../../../models/orders/ordergroup/ordergroup';

export interface OrdergroupDetailViewParams extends BaseRouteParams {
  storage_detail_route: RouteConfigItem;
  platform_detail_route: RouteConfigItem;
  order_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
}

export interface OrdergroupDetailViewData extends OrdergroupDetailViewParams {
  ordergroup: Ordergroup;
}
