
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { OrderitemService} from "./orderitem.service";
import { Orderitem } from "./orderitem";

@Injectable({
  providedIn: 'root'
})
export class OrderitemResolver  {
  constructor(private _rs: OrderitemService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Orderitem> {
    return this._rs.fetch(+route.params["orderitemId"])
  }
}
