import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../user';
import { Tariftype } from '../../../tarifs/tariftype/tariftype';
import { Member } from '../../../structures/member/member';
import { Storage } from '../../../structures/storage/storage';
import { Client } from '../../../structures/client/client';
import { Resto } from '../../../structures/resto/resto';
import { Observable } from 'rxjs';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { MemberDisplayComponent } from '../../../structures/member/member-display/member-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';
import { Link } from '@solidev/data';

interface UserPropertiesData {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  structures: {
    member?: Member;
    storage?: Storage;
    client?: Client;
    resto?: Resto;
    is_admin: boolean;
  }[];
  tarifs_allowed: boolean;
  notifications_all: boolean;
  notifications_none: boolean;
  notifications_tarifs: Tariftype[];
  tarifs_pilote: Tariftype[];
  last_login: Date;
  flags: string[];
}

@Component({
  selector: 'lvadg-user-properties',
  standalone: true,
  imports: [
    CommonModule,
    MemberDisplayComponent,
    StorageDisplayComponent,
    ClientDisplayComponent,
    RestoDisplayComponent,
    TariftypeDisplayComponent,
  ],
  templateUrl: './user-properties.component.pug',
  styleUrls: ['./user-properties.component.sass'],
})
export class UserPropertiesComponent implements OnInit {
  @Input() public user!: User;
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  @Input() public member_detail_route?: Link<Member>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public resto_detail_route?: Link<Resto>;

  public data$!: Observable<UserPropertiesData>;

  constructor() {}

  public async ngOnInit() {
    this.data$ = this.user.action<User, UserPropertiesData>('GET', 'properties');
  }
}
