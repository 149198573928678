
<ng-template #countStat let-count="count">
  <ng-container *ngIf="(stats_display()[PST.COUNT]||'default') === 'default'">
    <dl>
      <dt>{{stats_titles()[PST.COUNT]||'Total'}}</dt>
      <dd>{{count.count}}</dd>
    </dl>
  </ng-container>
</ng-template>
<ng-template #bylabelStat let-labels="by_label">
  <ng-container *ngIf="(stats_display()[PST.BY_LABEL]||'default') === 'default'">
    <dl>
      <dt>{{stats_titles()[PST.BY_LABEL]||'Par label'}}</dt>
      <dd>
        <div *ngFor="let label of labels"><span>{{label.label}} :</span><span class="ms-2">{{label.count}}</span></div>
      </dd>
    </dl>
  </ng-container>
</ng-template>
<ng-template #byfamilyStat let-families="by_family">
  <ng-container *ngIf="(stats_display()[PST.BY_FAMILY]||'default') === 'default'">
    <dl>
      <dt>{{stats_titles()[PST.BY_FAMILY]||'Par famille'}}</dt>
      <dd>
        <div *ngFor="let family of families"><span>{{family.family}} :</span><span class="ms-2">{{family.count}}</span></div>
      </dd>
    </dl>
  </ng-container>
</ng-template>
<ng-template #showStats>
  <ng-container *ngIf="stats(); let stat">
    <ng-container *ngIf="stat.count">
      <ng-container *ngTemplateOutlet="countStat; context: stat"></ng-container>
    </ng-container>
    <ng-container *ngIf="stat.by_label">
      <ng-container *ngTemplateOutlet="bylabelStat; context: stat"></ng-container>
    </ng-container>
    <ng-container *ngIf="stat.by_family">
      <ng-container *ngTemplateOutlet="byfamilyStat; context: stat"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<ng-container *ngIf="display()==='card'">
  <div class="card-header">
    <lvadg-icon ri="stats"><span class="fw-bold">{{title()}}</span></lvadg-icon>
  </div>
  <div class="card-body">
    <div class="max-height-200">
      <ng-template *ngTemplateOutlet="showStats"></ng-template>
    </div>
  </div>
</ng-container>