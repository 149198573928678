import { charField } from '@solidev/data';
import { ArticleMetaBase } from '../meta.base';

export enum ORIGIN_TYPE {
  COUNTRY = 'CN',
  CONTINENT = 'CT',
  FISHING_ZONE = 'ZP',
  MISC = 'DV',
}

export class OriginBase extends ArticleMetaBase {
  static override readonly __name: string = 'OriginBase';

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: ORIGIN_TYPE.MISC,
    required: true,
    maxLength: 2,
    choices: [
      { value: ORIGIN_TYPE.COUNTRY, desc: 'Pays' },
      { value: ORIGIN_TYPE.CONTINENT, desc: 'Continent' },
      {
        value: ORIGIN_TYPE.FISHING_ZONE,
        desc: 'Zone de pêche',
      },
      { value: ORIGIN_TYPE.MISC, desc: 'Divers / non précisé' },
    ],
    priority: 800,
  })
  public override type: string = ORIGIN_TYPE.MISC;
}
