import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { ArticleTarifTemplateRelation } from '../article-tarif-template-relation';
import { ArticleTarifTemplateRelationService } from '../article-tarif-template-relation.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ArticleTarifTemplateDisplayComponent } from '../../article-tarif-template/article-tarif-template-display/article-tarif-template-display.component';
import { TariftypeRelationDisplayComponent } from '../../tariftype-relation/tariftype-relation-display/tariftype-relation-display.component';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { ArticleTarifTemplateRelationManageComponent } from '../article-tarif-template-relation-manage/article-tarif-template-relation-manage.component';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { ArticleTarifTemplateBase } from '../../article-tarif-template/article-tarif-template.base';
import { ArticleBase } from '../../../catalog/article/article.base';
import { TariftypeRelationBase } from '../../tariftype-relation/tariftype-relation.base';
import { EarticleBase } from '../../../catalog/earticle/earticle.base';

@Component({
  selector: 'lvadg-article-tarif-template-relation-list',
  standalone: true,
  templateUrl: './article-tarif-template-relation-list.component.pug',
  styleUrls: ['./article-tarif-template-relation-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    TariftypeRelationDisplayComponent,
    TariftypeDisplayComponent,
    ArticleTarifTemplateDisplayComponent,
    ArticleTarifTemplateRelationManageComponent,
  ],
})
export class ArticleTarifTemplateRelationListComponent extends ModellistComponent<ArticleTarifTemplateRelation> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public tariftyperelation_detail_route?: Link<TariftypeRelationBase>;
  @Input()
  public articletariftemplate_detail_route?: Link<ArticleTarifTemplateBase>;
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;

  constructor(
    coll: ArticleTarifTemplateRelationService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'src_assoc'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'src_assoc',
          name: 'src_assoc',
          label: 'Filtrer par association (source)',
          choices: [
            { value: true, desc: 'Associé' },
            { value: false, desc: 'Non associé' },
          ],
        }),
      ],
    };
  }
}
