
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="product">
  <li class="nav-item" *ngIf="product_detail_route"><a class="nav-link" [routerLink]="product_detail_route.route({product: product})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Fiche produit</a></li>
  <!-- Producteur-->
  <li class="nav-item" *ngIf="product_producer_route"><a class="nav-link" [routerLink]="product_producer_route.route({product: product})" routerLinkActive="active">Fiche producteur</a></li>
  <!-- Édition-->
  <li class="nav-item" *ngIf="product_edit_route &amp;&amp; canEdit()"><a class="nav-link" [routerLink]="product_edit_route.route({product: product})" routerLinkActive="active">Édition</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="product_offers_route &amp;&amp; canEdit()"><a class="nav-link" [routerLink]="product_offers_route.route({product: product})" routerLinkActive="active">Offres</a></li>
  <!-- Documents-->
  <li class="nav-item" *ngIf="product_documents_route &amp;&amp; canEdit()"><a class="nav-link" [routerLink]="product_documents_route.route({product: product})" routerLinkActive="active">Documents</a></li>
  <!-- Articles-->
  <li class="nav-item" *ngIf="product_articles_route &amp;&amp; canEdit()"><a class="nav-link" [routerLink]="product_articles_route.route({product: product})" routerLinkActive="active">Articles</a></li>
</ul>