import { OfferBase } from "./offer.base";
import { detailsField, integerField } from "@solidev/data";
import { Storage } from "../../structures/storage/storage";
import { User } from "../../users/user/user";
import { Client } from "../../structures/client/client";
import { Offertype } from "../offertype/offertype";
import { OffertypeStorage } from "../offertype-storage/offertype-storage";
import { OfferResto } from "../offer-resto/offer-resto";
import { Tarif } from "../../tarifs/tarif/tarif";

export class Offer extends OfferBase {
  static override readonly __name: string = "Offer";

  /// Storage details (Storage)
  @detailsField({
    name: "storage_details",
    description: "Dépôt",
    model: Storage,
    readonly: true,
    priority: 750,
  })
  public storage_details?: Storage;

  /// Author details (User)
  @detailsField({
    name: "author_details",
    description: "Auteur",
    model: User,
    readonly: true,
    priority: 550,
  })
  public author_details?: User;

  /// Parent details (OfferBase)
  @detailsField({
    name: "parent_details",
    description: "Parent",
    model: OfferBase,
    readonly: true,
    priority: 250,
  })
  public parent_details?: OfferBase;

  /// Client details (Client)
  @detailsField({
    name: "client_details",
    description: "Client",
    model: Client,
    readonly: true,
    priority: 700,
  })
  public client_details?: Client;

  /// Restos details (Resto)
  @detailsField({
    name: "offerrestos_details",
    description: "Restos",
    model: OfferResto,
    many: true,
    readonly: true,
    priority: 350,
  })
  public offerrestos_details?: OfferResto[];

  /// Offertype details (Offertype)
  @detailsField({
    name: "offertype_details",
    description: "Modèle offre",
    model: Offertype,
    readonly: true,
    priority: 850,
  })
  public offertype_details?: Offertype;

  /// OffertypeStorage details (OffertypeStorage)
  @detailsField({
    name: "offertypestorage_details",
    description: "Modèle dépôt",
    model: OffertypeStorage,
    readonly: true,
    priority: 840,
  })
  public offertypestorage_details?: OffertypeStorage;

  /// Tarifs details (Tarif)
  @detailsField({
    name: "tarifs_details",
    description: "Tarifs",
    model: Tarif,
    readonly: true,
    priority: 740,
    many: true,
  })
  public tarifs_details?: Tarif[];

  // Stats
  @integerField({
    name: "products_count",
    description: "Nombre de produits",
    readonly: true,
    priority: 370,
  })
  public products_count?: number;

  @integerField({
    name: "productlinks_count",
    description: "Nombre de liens produits",
    readonly: true,
    priority: 369,
  })
  public productlinks_count?: number;

  @integerField({
    name: "articles_count",
    description: "Nombre d'articles",
    readonly: true,
    priority: 368,
  })
  public articles_count?: number;

  @integerField({
    name: "articlelinks_count",
    description: "Nombre de liens articles",
    readonly: true,
    priority: 367,
  })
  public articlelinks_count?: number;

  @detailsField({
    name: "full_distances",
    description: "Toutes distances",
    priority: -1,
    readonly: true,
    deserialize: (
      data: [number, number, number][] | Map<[number, number], number>,
    ) => {
      if (!data) {
        return new Map();
      }
      if (data instanceof Map) {
        return data;
      }
      return new Map(
        data.map((d) => {
          return [[d[0], d[1]], d[2]];
        }),
      );
    },
  })
  public full_distances = new Map<[number, number], number>();
}
