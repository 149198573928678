import { Component } from '@angular/core';

import { OrderListViewData } from './order-list-view.component.params';
import { Subject } from 'rxjs';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { OrderListComponent } from '../../../../models/orders/order/order-list/order-list.component';
import { OrderCreateComponent } from '../../../../models/orders/order/order-create/order-create.component';
import { Order } from '../../../../models/orders/order/order';
import { OrdergroupCreateComponent } from '../../../../models/orders/ordergroup/ordergroup-create/ordergroup-create.component';

@Component({
  selector: 'lvadg-order-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, OrderListComponent, OrderCreateComponent, OrdergroupCreateComponent],
  templateUrl: './order-list-view.component.pug',
  styleUrls: ['./order-list-view.component.sass'],
})
export class OrderListViewComponent extends BaseListViewComponent<OrderListViewData, Order> {
  public create: boolean = false;
  public groupCreate: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields = [
    'id',
    'code',
    'date',
    'delivery_date',
    'process_details',
    'storage_details',
    'total_price',
    'total_weight',
    'total_quantity',
    'group_details',
    'status',
    'flags',
    'created',
    'updated',
  ];

  public override setRouteData(data: OrderListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
