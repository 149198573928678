import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Process } from './process';

@Injectable({
  providedIn: 'root',
})
export class ProcessService extends Collection<Process> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/processes', Process);
  }
}
