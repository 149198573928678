import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Rnmarticle } from '../rnmarticle';
import { RnmarticleService } from '../rnmarticle.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ArticleBase } from '../../../catalog/article/article.base';
import { RnmBase } from '../../rnm/rnm.base';

@Component({
  selector: 'lvadg-rnmarticle-list',
  standalone: true,
  templateUrl: './rnmarticle-list.component.pug',
  styleUrls: ['./rnmarticle-list.component.sass'],
  imports: [...MODELLIST_IMPORTS],
})
export class RnmarticleListComponent extends ModellistComponent<Rnmarticle> {
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public rnm_detail_route?: Link<RnmBase>;

  constructor(
    coll: RnmarticleService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
