
<div class="content">
  <div class="container">
    <div class="row post ng-scope">
      <h1 class="title">{{news.title}}</h1>
      <lvadg-image-display [image]="news.logo_details" mode="large"></lvadg-image-display>
      <div class="spam date">{{news.created | date}}</div>
      <div class="post-body">
        <p class="description" [innerHTML]="news.content"></p>
      </div>
    </div>
  </div>
</div>