
<form [formGroup]="createForm" (submit)="save()">
  <dl>
    <data-dispedit [model]="ops" field="name" mode="form" [form]="createForm">Nom</data-dispedit>
  </dl>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" [disabled]="createForm.pristine||createForm.invalid" type="submit">Enregistrer</button>
      <button class="btn btn-secondary ms-2" (click)="cancelled.emit(); $event.preventDefault()" type="cancel">Annuler</button>
    </div>
  </div>
</form>