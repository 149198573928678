import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '@solidev/data';
import { Folder } from '../folder';
import { CdkTreeModule } from '@angular/cdk/tree';
import { Router, RouterModule } from '@angular/router';
import { FolderTree, FolderTreeService } from '../folder-tree.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FOLDER_STATUS } from '../folder.base';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lvadg-folder-tree',
  standalone: true,
  imports: [CommonModule, CdkTreeModule, RouterModule, ReactiveFormsModule],
  templateUrl: './folder-tree.component.pug',
  styleUrls: ['./folder-tree.component.sass'],
})
export class FolderTreeComponent implements OnInit, OnDestroy {
  @Input() public name!: string;
  @Input() public root?: Folder | number | null = null;
  @Input() public detail_route?: Link<Folder>;
  @Input() public reload?: Subject<void | boolean>;
  @Input() public open?: Observable<Folder>;
  @Output() public selected = new EventEmitter<Folder>();
  public FS = FOLDER_STATUS;
  public tree!: FolderTree;
  public search = new FormControl<string>('');
  private _subscriptions = new Subject<void>();

  constructor(
    private _fts: FolderTreeService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.tree = this._fts.load(this.name, this.root);
    console.log('folder tree init', this.tree);
    this.tree.search(this.search.valueChanges);
    if (this.reload) {
      this.reload.pipe(takeUntil(this._subscriptions)).subscribe(() => {
        this.tree.reload(true);
      });
    }
    if (this.open && this.open.subscribe) {
      this.open.subscribe((folder) => {
        this.tree.open(folder);
      });
    }
  }

  public ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public clickNode(node: Folder) {
    if (this.tree.current === node.id) {
      if (this.tree.control.isExpanded(node)) {
        this.tree.collapse(node);
      } else {
        this.tree.expand(node);
      }
    }
    this.tree.setCurrent(node);
    this.selected.next(node);
  }
}
