import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CiqualAlim } from '../ciqual-alim';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-ciqual-alim-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './ciqual-alim-manage.component.pug',
  styleUrls: ['./ciqual-alim-manage.component.sass'],
})
export class CiqualAlimManageComponent implements OnInit {
  @Input() public ciqualalim?: CiqualAlim;

  constructor() {}

  ngOnInit(): void {}
}
