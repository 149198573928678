import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { TarifBase } from '../tarif.base';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TarifService } from '../tarif.service';
import { firstValueFrom, map, Observable, tap } from 'rxjs';
import { Tarif } from '../tarif';
import { TarifDisplayComponent } from '../tarif-display/tarif-display.component';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepicker,
  NgbDatepickerModule,
  NgbDatepickerNavigateEvent,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { ngbd2s, titleTemplate, toDateString } from '../tarif-utils';
import { DataMessageService } from '@solidev/data';
import { TariftypeActionService } from '../tariftype-action.service';

@Component({
  selector: 'lvadg-tarif-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TarifDisplayComponent, NgbDatepickerModule],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  templateUrl: './tarif-create.component.pug',
  styleUrls: ['./tarif-create.component.sass'],
})
export class TarifCreateComponent implements OnInit {
  /** Tarif type - mandatory */
  @Input() public tariftype!: TariftypeBase;
  /** Created event, emits new tarif */
  @Output() public created = new EventEmitter<TarifBase>();
  /** Cancelled event */
  @Output() public cancelled = new EventEmitter<void>();
  /** Date string to tarif(s) map */
  public date_to_tarifs = new Map<string, Tarif[]>();
  /** Ultimate create form, contains data to be sent to api */
  public createForm = new FormGroup({
    datestart: new FormControl<string>(toDateString(new Date(), true)),
    dateend: new FormControl<string>(toDateString(new Date(), false)),
    name: new FormControl<string>('', { nonNullable: true }),
  });

  /** Observable for last 5 tarifs */
  public lasts$!: Observable<Tarif[]>;
  /** Last tarif */
  public last?: Tarif;

  // Calendar utils
  public startDate!: NgbDate;
  /** Hovered date */
  public hoveredDate: NgbDate | null = null;
  /** Interval start date */
  public fromDate: NgbDate | null = null;
  /** Interval end date */
  public toDate: NgbDate | null = null;
  /** Tarif name edition locked / unlocked ? */
  public lockedName: boolean = true;
  /** Last tarif is locked */
  public lockedLast: boolean = true;
  /** Are we currently creating the tarif */
  public creating: boolean = false;
  /** Tarif ruleset */
  private rst?: RRule | RRuleSet;
  // FIXME: remove this, with 1+ year of tarifs, it is not useful
  private havedispo: boolean = false;

  constructor(
    private _ts: TarifService,
    private _dad: NgbDateAdapter<Date>,
    private calendar: NgbCalendar,
    private _msgs: DataMessageService,
    private _ttact: TariftypeActionService,
  ) {}

  /** Check if date is disabled (e.g. already have a tarif) */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public checkDisabled = (date: NgbDateStruct, current?: { year: number; month: number }): boolean => {
    return this.haveTarif(date);
  };

  /**
   * Init:
   * - set calendar boundaries
   * - get tarifs within calendar boundaries
   * - compute defaults
   */
  ngOnInit(): void {
    // Compute ruleset; if not set, no auto computation will be possible
    // TODO: add a default ruleset for every tarif_interval value
    if (!this.tariftype) {
      return;
    }
    if (this.tariftype.rrule) {
      this.rst = rrulestr(this.tariftype.rrule);
    }
    // Disable name edition by default
    this.toggleNameLock(true);
    // Create form changes subscriptions
    this.createForm.valueChanges.subscribe((v) => {
      this.updateFromForm(v);
    });

    // Get last tarifs
    this.lasts$ = this._ts
      .list({
        tariftype: this.tariftype.id,
        page_size: 52,
        fields: ['id', 'name', 'datestart', 'dateend', 'status'].join(','),
        sort: '-dateend',
      })
      .pipe(
        tap((lasts) => {
          // Init view from last tarifs
          this.populateTarifs(lasts);
          this.initFromLastTarif(lasts[0]);
        }),
        map((lasts) => lasts.slice(0, 6)),
      );
  }

  /** Calendar date selection action : update interval */
  public onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.createForm.patchValue({ datestart: toDateString(date, true) });
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      if (this.checkInterval(this.fromDate, date)) {
        this.toDate = date;
        this.createForm.patchValue({ dateend: toDateString(date, false) });
      } else {
        this.fromDate = null;
        this.createForm.patchValue({ datestart: null });
      }
    } else {
      this.toDate = null;
      this.createForm.patchValue({ dateend: null });
      this.fromDate = date;
      this.createForm.patchValue({ datestart: toDateString(date, true) });
    }
  }

  /** Is hovered */
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  /** Is inside interval check */
  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /** Check if date is in range */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  /** Check if date have a tarif */
  public haveTarif(date: NgbDateStruct) {
    return !!(this.date_to_tarifs.get(ngbd2s(date)) || []).length;
  }

  public async onNavigation(event: NgbDatepickerNavigateEvent, dp: NgbDatepicker) {
    if (!this.havedispo) {
      event.preventDefault();
      await firstValueFrom(
        this._ts.list({
          tariftype: this.tariftype.id,
          page_size: 5,
          sort: '-dateend',
        }),
      );
      this.havedispo = true;
      dp.navigateTo(event.next);
    }
  }

  public toggleNameLock(force?: boolean) {
    if (force !== undefined) {
      this.lockedName = force;
    } else {
      this.lockedName = !this.lockedName;
    }
    if (this.lockedName) {
      this.createForm.controls.name.disable({ emitEvent: false });
    } else {
      this.createForm.controls.name.enable({ emitEvent: false });
    }
  }

  /**
   * Do tarif creating using data from createForm and last.
   */
  public async create() {
    this.creating = true;
    const res = await this._ttact.action(
      this.tariftype,
      {
        action: 'create_tarif',
        last: this.last?.id,
        name: this.createForm.getRawValue().name!, // use raw value to ensure value even if control is disabled
        datestart: this.createForm.value.datestart!,
        dateend: this.createForm.value.dateend!,
      },
      {
        success: 'Tarif créé avec succès',
        error: 'Erreur lors de la création du tarif',
      },
    );
    this.creating = false;
    if (res.success) {
      this.created.emit(res.tarif);
    }
  }

  public selectLast(t: Tarif | undefined) {
    if (this.lockedLast) {
      this._msgs.warning(
        'Changement de source verrouillé',
        'Si vous souhaitez réellement changer la source des prix/disponibilités, utilisez le cadenas pour déverrouiller la fonctionnalité',
      );
    } else {
      this.last = t;
    }
  }

  /**
   * Set next primary interval for tarif, using last tarifs and rrule
   * @param last last tarif
   * @private
   */
  private initFromLastTarif(last: Tarif | undefined) {
    // By default, use this month for calendar start
    this.startDate = this.calendar.getToday();
    if (!last) {
      return;
    }
    // Compute start date from rrule
    const start = this.rst?.after(last.dateend);
    let end: Date | undefined;
    if (start) {
      // We have a start date, update calendar and compute end
      this.startDate = NgbDate.from(this._dad.fromModel(new Date(start)))!;
      end = this.rst?.after(start) || undefined;
    }
    // Display previous month if we are less than 15 of month
    if (this.startDate.day <= 15) {
      this.startDate = this.calendar.getPrev(this.startDate, 'm', 1);
    }

    // We have a full interval, set form values
    if (start && end) {
      end.setDate(end.getDate() - 1);
      this.createForm.patchValue(
        {
          datestart: toDateString(start, true),
          dateend: toDateString(end, false),
        },
        { emitEvent: true },
      );
    }
    // Set last
    this.last = last;
  }

  /**
   * Fill tarifs map with provided tarifs dates
   * @param tarifs list of tarifs
   * @private
   */
  private populateTarifs(tarifs: Tarif[]) {
    const checkAdd = (date: NgbDate, t: Tarif) => {
      const dt = ngbd2s(date);
      const cur = this.date_to_tarifs.get(dt) || [];
      if (!cur.find((tr) => t.id == tr.id)) {
        cur.push(t);
        this.date_to_tarifs.set(dt, cur);
      }
    };
    for (const t of tarifs) {
      const start = NgbDate.from(this._dad.fromModel(t.datestart))!;
      const end = NgbDate.from(this._dad.fromModel(t.dateend))!;
      let date = start;
      if (end.after(start)) {
        while (date.before(end)) {
          checkAdd(date, t);
          date = this.calendar.getNext(date, 'd', 1);
        }
        checkAdd(date, t);
      }
    }
  }

  /**
   * Update calendar from form
   * @param v
   * @private
   */
  private updateFromForm(
    v: Partial<{
      datestart: string | null | undefined;
      name: string | null | undefined;
      dateend: string | null | undefined;
    }>,
  ) {
    // On form change, set from / to dates in calendar
    if (v.datestart) {
      this.fromDate = NgbDate.from(
        // use datestart
        this._dad.fromModel(new Date(v.datestart)),
      );
    }
    if (v.dateend) {
      this.toDate = NgbDate.from(
        // use dateend
        this._dad.fromModel(new Date(v.dateend)),
      );
    }
    // If both dates are OK, write name from template if name is locked
    if (v.datestart && v.dateend && this.lockedName) {
      this.createForm.patchValue(
        {
          name: titleTemplate(this.tariftype.name_template, new Date(v.datestart), new Date(v.dateend)),
        },
        { emitEvent: false },
      );
    }
  }

  /** Check if interval already contains tarifs */
  private checkInterval(fromDate: NgbDate, toDate: NgbDate): boolean {
    if (fromDate.after(toDate)) {
      return false;
    }
    let date = fromDate;
    while (date.before(toDate) || date.equals(toDate)) {
      const dt = ngbd2s(date);
      const tl = this.date_to_tarifs.get(dt) || [];
      if (tl.length) {
        return false;
      }
      date = this.calendar.getNext(date, 'd', 1);
    }
    return true;
  }
}
