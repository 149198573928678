import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TariftypeService } from './tariftype.service';
import { Tariftype } from './tariftype';
import { TARIFTYPE_USER_LEVEL } from './tariftype.base';
import { catchError, Observable, tap } from 'rxjs';
import { ERROR_MODE } from '../../../views/generic-error-view/generic-error-view.component';
import { ResolverErrorService } from '../../../components/utils/resolver-error.service';

@Injectable({
  providedIn: 'root',
})
export class TariftypeResolver {
  constructor(
    private _rs: TariftypeService,
    private _re: ResolverErrorService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tariftype> {
    return this._rs.fetch(+route.params['tariftypeId']).pipe(catchError(this._re.catch));
  }
}

export class TariftypePermResolver {
  static forPerms(...perms: TARIFTYPE_USER_LEVEL[]) {
    @Injectable({
      providedIn: 'root',
    })
    class TariftypeWithPermResolver {
      constructor(
        public _rs: TariftypeService,
        public _re: ResolverErrorService,
      ) {}

      resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tariftype> {
        return this._rs.fetch(+route.params['tariftypeId']).pipe(
          catchError(this._re.catch),
          tap((t) => {
            for (const p of perms) {
              if (t.have_level(p)) {
                return;
              }
            }
            this._re.nav(ERROR_MODE.forbidden);
          }),
        );
      }
    }

    return TariftypeWithPermResolver;
  }
}
