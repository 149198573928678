
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Folder } from "./folder";

@Injectable({
  providedIn: 'root'
})
export class FolderService extends Collection<Folder> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/folders", Folder);
  }
}
