import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
} from "@angular/core";
import {Producer} from "../producer";
import {AsyncPipe, CommonModule, DecimalPipe} from "@angular/common";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {IconComponent} from "../../../../components/utils/icon/icon.component";
import {
  ProducerListActionResult,
  ProducerListActionService,
} from "../producer-action.service";
import {StorageService} from "../../storage/storage.service";
import {Storage} from "../../storage/storage";
import {Location} from "../../../locations/location/location";
import {PRODUCER_TYPES} from "../producer.base";
import {BanAdapter, GeoSearchResult} from "@solidev/data";
import { HttpClient } from "@angular/common/http";
import {debounceTime, distinctUntilChanged, map, of, switchMap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {
  DistanceCacheDisplayComponent
} from "../../../locations/distance/distance-cache-display/distance-cache-display.component";

/** Validate a SIREN number
 * @param control The form control to validate
 */
function sirenValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value && value.length === 9) {
    const sum = value
      .split("")
      .map((c: string, i: number) =>
        i % 2 === 0 ? parseInt(c, 10) : parseInt(c, 10) * 2,
      )
      .map((n: number) => (n > 9 ? n - 9 : n))
      .reduce((acc: number, n: number) => acc + n, 0);
    if (sum % 10 === 0) {
      return null;
    }
  }
  return { siren: true };
}

/** Producer creation wizard steps */
enum STEPS {
  SIREN,
  FOUND_SIREN_CONFIRM,
  ASSOCIATE_STORAGES,
  CREATE_PRODUCER,
  SUBMIT,
}

@Component({
  selector: "lvadg-producer-user-create",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    DecimalPipe,
    AsyncPipe,
    DistanceCacheDisplayComponent,
  ],
  templateUrl: "./producer-user-create.component.pug",
  styleUrl: "./producer-user-create.component.sass",
})
/**
 * Producer creation wizard.
 * - Step 1: Search SIREN number
 * - Step 2: Confirm SIREN number or create new producer
 * - Step 3: Associate storages
 * - Step 4: Check data and submit
 */
export class ProducerUserCreateComponent {
  public readonly STEPS = STEPS;
  /** Producer type input signal, defaults to FL */
  public readonly type = input<PRODUCER_TYPES>(PRODUCER_TYPES.FL);
  /** Current step signal */
  public readonly step = signal(STEPS.SIREN);
  /** Current producer signal */
  public readonly producer = signal<Producer | null>(null);
  /** List of storages associations signal */
  public readonly associations = signal<
    {
      storage: Storage;
      association: boolean;
      readonly: boolean;
    }[]
  >([]);
  /** Is there any association computed signal */
  public readonly associated = computed(() => {
    const assoc = !!this.associations().filter((a) => a.association).length;
    console.log("Computing associations", assoc, this.associations());
    return assoc;
  });
  /** Geolocation result signal */
  public readonly geoLocation = signal<GeoSearchResult | null>(null);

  /** SIREN form, with validation */
  public readonly sirenForm = new FormGroup({
    siren: new FormControl("", {
      validators: [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
        sirenValidator,
      ],
    }),
  });

  /** Producer form, with validation */
  public readonly producerForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    description: new FormControl("", [
      Validators.required,
      Validators.minLength(100),
      Validators.maxLength(5000),
    ]),
    address: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(500),
    ]),
    postcode: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    city: new FormControl("", [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(255),
    ]),
  });

  /** Output emitter for producer creation */
  @Output() public created = new EventEmitter<Producer>();
  /** Output emitter for producer creation cancellation */
  @Output() public cancelled = new EventEmitter<void>();

  /** Action service injection */
  private readonly _action: ProducerListActionService = inject(
    ProducerListActionService,
  );
  /** Storage service injection */
  private readonly _storages: StorageService = inject(StorageService);
  /** Generic http client injection */
  private readonly _http: HttpClient = inject(HttpClient);
  /** Geocoder adapter, using BanAdapter for France */
  private readonly _geocoder = new BanAdapter(
    this._http,
    "https://gis.lavieadugout.fr/geocoder/search",
  );

  // noinspection JSUnusedLocalSymbols
  /** Geosearch subscription.
   * When the producer form changes, we debounce the changes and search for a location, taking the first (best) result
   * to feed the geolocation signal.
   */
  private readonly _geosearch$ = this.producerForm.valueChanges
    .pipe(
      debounceTime(300),
      map((v) => {
        if (!v.address && !v.postcode && !v.city) {
          return "";
        }
        const address = v.address || "";
        const postcode = v.postcode || "";
        const city = v.city || "";
        return `${address}, ${postcode} ${city}`;
      }),
      distinctUntilChanged(),
      switchMap((v) => {
        if (v === "") {
          return of([]);
        }
        return this._geocoder.search(v);
      }),
      takeUntilDestroyed(),
    )
    .subscribe((v) => this.geoLocation.set(v.length > 0 ? v[0] : null));

  /**
   * Search producer by SIREN number, and proceed to next step.
   * - If the SIREN number is not found, proceed to producer creation step.
   * - If the SIREN number is found, proceed to confirmation step.
   *
   * Checks if the SIREN number is valid before searching.
   */
  public async searchSiren() {
    const siren = this.sirenForm.value.siren;
    if (!siren || this.sirenForm.invalid) {
      return;
    }
    const res = await this._action.action({ action: "search_siren", siren });
    if (!res.success) {
      return;
    }
    if (res.data && res.data.length === 1) {
      this.producer.set(res.data[0]);
      this.associations.set([]);
      this.step.set(STEPS.FOUND_SIREN_CONFIRM);
    } else {
      this.producer.set(null);
      this.step.set(STEPS.CREATE_PRODUCER);
    }
  }

  /**
   * Process "producer found" confirmation step.
   *
   * If the producer is found, we set the geolocation signal to the producer's location,
   * then load the list of storages and set the associations signal.
   */
  public async foundSirenConfirm() {
    const loc = this.producer()?.location_details;
    if (loc) {
      this.geoLocation.set({
        label: `${loc.address}, ${loc.postcode} ${loc.city}`,
        lat: loc.lat,
        lng: loc.lng,
        score: 1,
      });
    } else {
      this.geoLocation.set(null);
    }
    this._storages.list({ is_admin: "true" }).subscribe((storages) => {
      this.associations.set(
        storages.map((storage) => {
          for (const s of this.producer()?.storage_relations_details || []) {
            if (s.storage_details?.id === storage.id) {
              return { storage, association: true, readonly: true };
            }
          }
          return { storage, association: false, readonly: false };
        }),
      );
      this.step.set(STEPS.ASSOCIATE_STORAGES);
    });
  }

  /**
   * Process "associate storages" step.
   *
   * We simply set the next step to "submit". No data is processed here.
   */
  public associateStorages() {
    this.step.set(STEPS.SUBMIT);
  }

  /**
   * Process "create producer" step.
   *
   * - Check if the producer form is valid
   * - Set the producer signal with the form data (producer an location)
   * - Load the list of storages and set the associations signal
   */
  public createProducer() {
    // TODO: eventually check if geolocation is valid.
    const producer = new Producer();
    const location = new Location();
    producer.fromJson(this.producerForm.value, { partial: true, check: true });
    location.fromJson(this.producerForm.value, { partial: true, check: true });
    producer.location_details = location;
    this.producer.set(producer);
    this._storages.list({ is_admin: "true" }).subscribe((storages) => {
      this.associations.set(
        storages.map((storage) => {
          return { storage, association: false, readonly: false };
        }),
      );
      this.step.set(STEPS.ASSOCIATE_STORAGES);
    });
  }

  /**
   * Submit the producer creation.
   * - If the producer already exists, create a new producer from the existing one, the SIREN number and the storages
   *   by calling the action service with the "create_existing" action.
   *   The action service will return the new producer data, which we emit in the "created" event.
   *   - If the producer does not exist, create a new producer from the form data, the SIREN number and the storages
   *   by calling the action service with the "create_new" action.
   *   The action service will return the new producer data, which we emit in the "created" event.
   *
   *   If the action fails, nothing is emitted, but the error is displayed in console and user messages.
   */
  public async submit() {
    const producer = this.producer();
    if (!producer) {
      return;
    }
    let action: Promise<ProducerListActionResult<Producer>>;
    const storages = this.associations()
      .filter((a) => a.association && !a.readonly)
      .map((a) => a.storage.id);
    const siren = this.sirenForm.value.siren as string;
    if (producer.id) {
      /// Already existing producer, create from id, siren and storages
      action = this._action.action({
        action: "create_existing",
        producer_id: producer.id,
        siren,
        storages,
      });
    } else {
      /// New producer, create from data
      const data = this.producerForm.value;
      action = this._action.action({
        action: "create_new",
        name: data.name as string,
        description: data.description as string,
        address: data.address as string,
        postcode: data.postcode as string,
        city: data.city as string,
        type: this.type(),
        siren,
        storages,
      });
    }
    const res = await action;
    if (res.success && res.data) {
      const p = new Producer();
      p.fromJson(res.data);
      this.created.emit(res.data);
    }
  }

  /**
   * Storage association toggle.
   *
   * @param storage
   */
  public associateStorage(storage: Storage) {
    const associations = this.associations();
    for (const a of associations) {
      if (a.storage === storage) {
        a.association = !a.association;
      }
    }
    this.associations.set(associations.map((a) => a));
  }
}
