
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Presentation } from "./presentation";

@Injectable({
  providedIn: 'root'
})
export class PresentationService extends Collection<Presentation> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/presentations", Presentation);
  }
}
