
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Associer un tarif à l'offre</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body">
    <lvadg-tarif-list [name]="'offer-manage-tarif-add'" [keep]="false" [filter]="tarifFilter$" [default_fields]="tariflist_default_fields" [actions]="['offer_add']" (action)="addTarif($event)" displayMode="ml-top"></lvadg-tarif-list>
  </div>
</ng-template>
<ng-container *ngIf="offer$|async; let o">
  <div class="row">
    <div class="col-sm-6">
      <div class="card h-100">
        <div class="card-header">Caractéristiques de l'offre</div>
        <div class="card-body">
          <div class="text-warning" *ngIf="o.have_level(OUL.o_write) &amp;&amp; !o.have_level(OUL.sadmin)">Édition limitée aux administrateurs lavieadugout</div>
          <dl class="row">
            <data-dispedit class="col-12" [model]="o" field="offertype" [editable]="o.have_level(OUL.sadmin)" [collection]="offertype$" mode="dd">Offre contractuelle</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="offertypestorage" [editable]="o.have_level(OUL.sadmin)" [collection]="offertypestorage$" mode="dd">Dépôt offre contractuelle</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="client" [collection]="clients$" [editable]="o.have_level(OUL.sadmin)" mode="dd">Client</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="storage" [collection]="storages$" [editable]="o.have_level(OUL.sadmin)" mode="dd">Dépôt</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="type" mode="dd" [editable]="o.have_level(OUL.sadmin)">Type</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="zone" mode="dd" [editable]="o.have_level(OUL.sadmin)">Zone</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="author" [collection]="users$" [editable]="o.have_level(OUL.sadmin)" mode="dd">Auteur</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="datevalidation" mode="dd" [editable]="o.have_level(OUL.sadmin)">Date de validation</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card h-100">
        <div class="card-header">Édition de l'offre</div>
        <div class="card-body">
          <dl class="row">
            <data-dispedit class="col-12" [model]="o" field="title" mode="dd" [editable]="o.have_level(OUL.o_write)">Titre</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="datestart" mode="dd" [editable]="o.have_level(OUL.o_write)">Date de début</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="dateend" mode="dd" [editable]="o.have_level(OUL.o_write)">Date de fin</data-dispedit>
            <data-dispedit class="col-12" [model]="o" field="status" mode="dd" [editable]="o.have_level(OUL.o_write)">État courant</data-dispedit>
          </dl>
          <hr>
          <dl>
            <dt>Tarifs associés</dt>
            <dd>
              <ul class="list-group list-group-flush mb-2">
                <li class="list-group-item d-flex justify-content-between" *ngFor="let t of o.tarifs_details">
                  <lvadg-tarif-display [tarif]="t" mode="line"></lvadg-tarif-display>
                  <button class="btn btn-outline-danger btn-sm" (click)="removeTarif(t)" *ngIf="o.have_level(OUL.o_write)">
                    <lvadg-icon ri="delete"></lvadg-icon>
                  </button>
                </li>
              </ul>
              <button class="btn btn-outline-primary btn-sm w-100" (click)="openAddTarif()" *ngIf="o.have_level(OUL.o_write)">
                <lvadg-icon ri="add">Associer un tarif</lvadg-icon>
              </button>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</ng-container>