import { RnmatcacheBase } from './rnmatcache.base';
import { detailsField } from '@solidev/data';
import { RnmmarcheBase } from '../rnmmarche/rnmmarche.base';
import { Article } from '../../catalog/article/article';
import { RnmlibelleBase } from '../rnmlibelle/rnmlibelle.base';

export class Rnmatcache extends RnmatcacheBase {
  static override readonly __name: string = 'Rnmatcache';

  @detailsField({
    model: RnmmarcheBase,
    description: 'Marché',
    readonly: true,
  })
  public marche_details?: RnmmarcheBase;

  @detailsField({
    model: Article,
    description: 'Article',
    readonly: true,
  })
  public article_details?: Article;

  @detailsField({
    model: RnmlibelleBase,
    description: 'Libellé RNM',
    readonly: true,
  })
  public libelle_details?: RnmlibelleBase;
}
