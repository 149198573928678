
<ng-container *ngIf="display==='card'">
  <div class="card-header">
    <lvadg-icon ri="offer"><span class="fw-bold">{{title}}</span></lvadg-icon>
  </div>
  <div class="max-height-200">
    <nav class="list-group list-group-flush">
      <ng-container *ngFor="let o of ots$|async"><a class="list-group-item list-group-item-action" [routerLink]="ots_detail_routes[o.offertype_details!.type]?.route({offertypestorage: o})">
          <div class="flex flex-row justify-content-between">
            <div></div><span class="badge bg-info me-1" *ngIf="o.offertype_details.type===OFFERTYPE_TYPES.PRODUCT_LOCAL">L</span>{{o.storage_details?.name || '??'}} &rightarrow; {{o.offertype_details.client_details?.name || '??'}}
            <lvadg-flags-display [item]="o" [humanize]="OFFERTYPE_FLAGS_HUMANIZED" *ngIf="dispFlags$|async"></lvadg-flags-display>
          </div></a></ng-container>
    </nav>
  </div>
</ng-container>
<ng-container *ngIf="display==='buttons'">
  <ng-container *ngIf="ots$|async; let ots">
    <div class="row g-3" *ngIf="ots.length">
      <div class="col-6 col-md-4 col-xl-3 col-xxl-2" *ngFor="let o of ots">
        <button class="btn btn-outline-primary w-100 ofc" [routerLink]="ots_detail_routes[o.offertype_details!.type]?.route({offertypestorage: o})">
          <div class="flex flex-row justify-content-between">
            <div><span class="badge bg-info me-1" *ngIf="o.offertype_details.type===OFFERTYPE_TYPES.PRODUCT_LOCAL">L</span>{{o.storage_details?.name || '??'}} &rightarrow; {{o.offertype_details.client_details?.name || '??'}}<span class="ms-2"><i class="bi bi-pencil-square" *ngIf="o.have_level(OTS_PERMS.admin, OTS_PERMS.admin, OTS_PERMS.sadmin, OTS_PERMS.write)"></i></span></div>
            <lvadg-flags-display [item]="o" [humanize]="OFFERTYPE_FLAGS_HUMANIZED" *ngIf="dispFlags$|async"></lvadg-flags-display>
          </div>
        </button>
      </div>
    </div>
    <div class="row g-3" *ngIf="!ots.length">
      <div class="col-12 col-md-3"></div>
      <div class="col-12 col-md-6">
        <div class="alert alert-info text-center fs-4 p-5 my-5"><i class="bi bi-info-circle me-2"></i>Aucune offre contractuelle</div>
      </div>
    </div>
  </ng-container>
</ng-container>