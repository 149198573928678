
<ng-container *ngIf="layout$|async as layout">
  <ng-container *ngIf="layout.template as tpl">
    <div class="d-flex flex-row justify-content-between">
      <h1>{{tpl.description}}</h1>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="groupMode" (change)="toggleGroup(layout)" [checked]="!tree.get_group_mode(layout)">
        <label class="form-check-label" for="groupMode">Paramétrage global</label>
      </div>
    </div>
    <div class="row">
      <div ngbAccordion (shown)="onShow($event)" (hidden)="onHide($event)">
        <ng-container *ngFor="let frag of tpl.fragments">
          <div *ngIf="frag.enabled" [ngbAccordionItem]="frag.name" [collapsed]="!openedFragments.includes(frag.name)">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>{{frag.description}}</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="fragment" [ngClass]="'fragment_'+frag.display">
                    <ng-container *ngFor="let param of frag.params">
                      <div class="param mb-3" *ngIf="param.enabled" [ngClass]="'param_'+param.display">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <h3>{{param.description}}</h3><span class="text-warning px-2 me-3" role="button" title="Réinitialiser" (click)="resetParam(layout, frag, param)"><i class="bi bi-arrow-counterclockwise"></i></span>
                        </div>
                        <div class="providers">
                          <div class="provider" *ngFor="let prov of param.providers">
                            <ng-container [ngSwitch]="prov.mode">
                              <ng-container *ngSwitchCase="'checkbox'">
                                <lvadg-layout-template-provider-checkbox [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov"></lvadg-layout-template-provider-checkbox>
                              </ng-container>
                              <ng-container *ngSwitchCase="'select'">
                                <lvadg-layout-template-provider-select [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov"></lvadg-layout-template-provider-select>
                              </ng-container>
                              <ng-container *ngSwitchCase="'select_logo_image'">
                                <lvadg-layout-template-provider-select-image [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov" display="logo"></lvadg-layout-template-provider-select-image>
                              </ng-container>
                              <ng-container *ngSwitchCase="'select_header_background_image'">
                                <lvadg-layout-template-provider-select-image [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov" display="header"></lvadg-layout-template-provider-select-image>
                              </ng-container>
                              <ng-container *ngSwitchCase="'select_portrait_background_image'">
                                <lvadg-layout-template-provider-select-image [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov" display="header"></lvadg-layout-template-provider-select-image>
                              </ng-container>
                              <ng-container *ngSwitchCase="'upload_image'">
                                <lvadg-layout-template-provider-upload-image [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov"></lvadg-layout-template-provider-upload-image>
                              </ng-container>
                              <ng-container *ngSwitchCase="'single'">
                                <lvadg-layout-template-provider-single [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov"></lvadg-layout-template-provider-single>
                              </ng-container>
                              <ng-container *ngSwitchCase="'custom_text'">
                                <lvadg-layout-template-provider-custom-text [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov"></lvadg-layout-template-provider-custom-text>
                              </ng-container>
                              <ng-container *ngSwitchCase="'custom_formatted_text'">
                                <lvadg-layout-template-provider-custom-formatted-text [tree]="tree" [layout]="layout" [fragment]="frag" [param]="param" [provider]="prov"></lvadg-layout-template-provider-custom-formatted-text>
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                <pre class="todo">UNKWNOWN PROVIDER MODE: {{prov.mode}}</pre>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>