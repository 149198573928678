import { ArticleTarifBase } from "./article-tarif.base";
import { computedField, detailsField, integerField } from "@solidev/data";
import { Article } from "../../catalog/article/article";
import { EarticleBase } from "../../catalog/earticle/earticle.base";
import { Family } from "../../catalog/family/family";
import { TarifBase } from "../tarif/tarif.base";
import { ArticleTarifItemBase } from "../article-tarif-item/article-tarif-item.base";
import { ValidationErrors } from "@angular/forms";
import { Label } from "../../catalog/label/label";
import { TariftypeBase } from "../tariftype/tariftype.base";

export class ArticleTarif extends ArticleTarifBase {
  static override readonly __name: string = "ArticleTarif";
  @detailsField({
    model: Article,
    description: "Article catalogue",
    readonly: true,
    priority: 900,
  })
  public article_details?: Article;
  @detailsField({
    model: EarticleBase,
    description: "Article client",
    readonly: true,
    priority: 800,
  })
  public eaclient_details?: EarticleBase;
  @detailsField({
    name: "price_history",
    description: "Historique prix récents",
    readonly: true,
    priority: 400,
  })
  public price_history?: ArticleTarifBase[];
  @detailsField({
    name: "reference_tarifs",
    description: "Tarifs de référence",
    readonly: true,
    priority: 350,
  })
  public reference_tarifs?: TarifBase[];
  @detailsField({
    name: "items_details",
    model: ArticleTarifItemBase,
    many: true,
    readonly: true,
    priority: -1,
  })
  public items_details?: ArticleTarifItemBase[];
  public bystorage_items_details = new Map<number, ArticleTarifItemBase>();
  public byregion_items_details = new Map<number, ArticleTarifItemBase>();
  @detailsField({
    name: "related_details",
    model: ArticleTarifBase,
    many: true,
    readonly: true,
    priority: -1,
  })
  public related_details?: ArticleTarifBase[];
  public bytarif_related_details = new Map<number, ArticleTarifBase>();

  @detailsField({
    name: "tarif_details",
    model: TarifBase,
    description: "Tarif",
  })
  public tarif_details?: TarifBase;

  @detailsField({
    name: "tariftype_details",
    model: TariftypeBase,
    description: "Modèle de tarif",
  })
  public tariftype_details?: TariftypeBase;

  @integerField({
    name: "in_op",
    readonly: true,
    description: "Dans l'offre",
    priority: -1,
  })
  public in_op?: number;

  @computedField({
    name: "eaclient_libelle",
    description: "Libellé client",
    readonly: true,
    priority: 750,
  })
  public get eaclient_libelle(): string | undefined {
    return this.eaclient_details?.libelle;
  }

  @computedField({
    name: "eaclient_code",
    description: "Code client",
    readonly: true,
    priority: 750,
  })
  public get eaclient_code(): string | undefined {
    return this.eaclient_details?.code;
  }

  @computedField({
    name: "eaclient_origin",
    description: "Origine client",
    readonly: true,
    priority: 750,
  })
  public get eaclient_origin(): string | undefined {
    return this.eaclient_details?.origine;
  }

  @computedField({
    name: "article_family",
    description: "Famille",
    readonly: true,
    priority: 600,
  })
  public get article_family(): Family | undefined {
    return this.article_details?.family_details;
  }

  @computedField({
    name: "article_uf",
    description: "UF",
    readonly: true,
    priority: 299,
  })
  public get article_uf(): string | undefined {
    return this.article_details?.udf_unit;
  }

  @computedField({
    name: "article_labels",
    description: "Labels",
    readonly: true,
    priority: 500,
  })
  public get article_labels(): Label[] | undefined {
    return this.article_details?.egalim_details;
  }

  public get _ariaLabel(): string {
    return `${this.article_details?.code || this.id}`;
  }

  public override fromJson(
    data: Record<string, any>,
    { partial, check }: { partial?: boolean; check?: boolean } = {
      partial: false,
      check: false,
    },
  ): ValidationErrors | null {
    const res = super.fromJson(data, { partial, check });
    if (res === null) {
      // Create map for bystorage_items_details
      this.bystorage_items_details.clear();
      for (const sti of this.items_details || []) {
        if (sti.storage) {
          this.bystorage_items_details.set(sti.storage, sti);
        }
      }
      // Create map for byregion_items_details
      this.byregion_items_details.clear();
      for (const sti of this.items_details || []) {
        if (sti.region) {
          this.byregion_items_details.set(sti.region, sti);
        }
      }
      // Create map for bytarif_related_details
      this.bytarif_related_details.clear();
      for (const rel of this.related_details || []) {
        if (rel.tarif) {
          this.bytarif_related_details.set(rel.tarif, rel);
        }
      }
    }
    return res;
  }
}
