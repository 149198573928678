import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { CdataKeyService } from "./cdata-key.service";
import { CdataKey } from "./cdata-key";
import { CDATAKEY_DEST } from "./cdata-key.base";

@Injectable({
  providedIn: "root",
})
export class CdataKeyResolver {
  constructor(private _rs: CdataKeyService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<CdataKey> {
    return this._rs.fetch(+route.params["cdatakeyId"]);
  }
}

export const CdataKeysResolver = (
  dest: CDATAKEY_DEST,
): ResolveFn<CdataKey[]> => {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<CdataKey[]> => {
    return inject(CdataKeyService).byDest(dest);
  };
};
