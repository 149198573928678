import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { PRODUCT_ROUTES } from '../menu';
import { Product } from '../../../../../models/catalog/product/product';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ProductNavComponent } from '../_nav/product-nav.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { CommonModule } from '@angular/common';
import { map, Observable, Subject } from 'rxjs';
import { OfferListComponent } from '../../../../../models/offers/offer/offer-list/offer-list.component';
import { Producer } from '../../../../../models/structures/producer/producer';
import { Storage } from '../../../../../models/structures/storage/storage';
import { Resto } from '../../../../../models/structures/resto/resto';
import { Offer } from '../../../../../models/offers/offer/offer';
import { User } from '../../../../../models/users/user/user';
import { Offertype } from '../../../../../models/offers/offertype/offertype';
import { OffertypeStorage } from '../../../../../models/offers/offertype-storage/offertype-storage';

export interface ProductOffersViewParams extends BaseRouteParams {
  p_menu: PRODUCT_ROUTES;
  resto_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
}

export interface ProductOffersViewData extends ProductOffersViewParams {
  product: Product;
  producer?: Producer;
  storage?: Storage;
}

@Component({
  selector: 'lvadg-product-offers-view',
  standalone: true,
  imports: [CommonModule, ProductNavComponent, OfferListComponent, HeaderActionComponent],
  templateUrl: './product-offers-view.component.pug',
  styleUrl: './product-offers-view.component.sass',
})
export class ProductOffersViewComponent extends BaseDetailViewComponent<ProductOffersViewData, Product> {
  public productFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields: string[] = [
    'id',
    'title',
    'storage_details',
    'client_details',
    'datestart',
    'dateend',
    'author_details',
    'status',
  ];
  public resto_detail_route?: Link<Resto>;
  public storage_detail_route?: Link<Storage>;
  public offer_detail_route?: Link<Offer>;
  public offertypestorage_offer_detail_route?: Link<Offer>;
  public user_detail_route?: Link<User>;
  public offertype_detail_route?: Link<Offertype>;
  public offertypestorage_detail_route?: Link<OffertypeStorage>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.productFilter$ = this.data$.pipe(
      map((data) => {
        return {
          product: data.product.id,
        };
      }),
    );
  }

  public override setRouteData(data: ProductOffersViewData) {
    super.setRouteData(data);
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link(data.resto_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(data.storage_detail_route, data, this._router);
    }
    if (data.offer_detail_route) {
      this.offer_detail_route = new Link(data.offer_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.offertype_detail_route) {
      this.offertype_detail_route = new Link(data.offertype_detail_route, data, this._router);
    }
    if (data.offertypestorage_detail_route) {
      this.offertypestorage_detail_route = new Link(data.offertypestorage_detail_route, data, this._router);
    }
    if (data.offertypestorage_offer_detail_route) {
      this.offertypestorage_offer_detail_route = new Link(data.offertypestorage_offer_detail_route, data, this._router);
    }
  }
}
