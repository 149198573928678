// noinspection AngularMissingOrInvalidDeclarationInModule

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import {
  Collection,
  DataModel,
  FieldsParams,
  FilterDefaults,
  FiltersParams,
  IQueryNav,
  Link,
  ModelList,
  ModelListService,
  ModelListTextFilter,
  SorterDefaults,
} from "@solidev/data";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { CdkTable } from "@angular/cdk/table";
import {
  PrintContext,
  PrintSettings,
} from "../../models/layouts/print-settings";
import { Selection } from "../../components/selection/selection.service";
import { SITE_DEFAULT_PARAMS, SITE_DEFAULTS } from "../../constants";
// FIXME: use https://stackoverflow.com/questions/58902736/how-to-declare-an-optional-generic-type-in-an-interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ModelListAction<T extends DataModel, D = any> {
  model?: T;
  models?: T[];
  action: "edit" | "delete" | string;
  data?: D;
}

@Directive()
export class ModellistComponent<T extends DataModel>
  implements OnInit, OnDestroy
{
  @Input() public detail_route?: Link<T>;
  @Input() public ctype?: string;
  @Input() public name!: string;
  @Input() public fields!: FieldsParams;
  @Input() public filters?: FiltersParams;
  @Input() public sorter!: SorterDefaults;
  @Input() public filter!: FilterDefaults | Observable<FilterDefaults>;
  @Input() public paginator!: IQueryNav;
  @Input() public keep: boolean = true;
  @Input() public reload?: Subject<void | boolean>;
  @Input() public default_fields?: string[];
  @Input() public default_filters?: string[];
  @Input() public actions?: string[];
  @Input() public print_settings?: PrintSettings;
  @Input() public print_context: PrintContext = {};

  @Input() public dispLoading = true;
  @Input() public dispFilters = true;
  @Input() public dispFieldsSelector = true;
  @Input() public dispHeaders = true;
  @Input() public dispFooters = false;
  @Input() public dispPagination = true;
  @Input() public dispXlsxExport = true;
  @Input() public dispPrint = false;
  @Input() public maxPages = 200;
  @Input() public displayMode!:
    | "ml-hidden"
    | "ml-over"
    | "ml-over-end"
    | "ml-pinned"
    | "ml-top";

  // Mapping setup
  public dispMap = input<"none" | "local" | "usermap">("none");
  public localMapStatus = signal(false);

  @Output() public action = new EventEmitter<ModelListAction<T>>();
  @ViewChild("ofcslot", { static: false })
  public ofcSlot?: ElementRef<HTMLElement>;
  public dataTableSlot?: CdkTable<never>;
  public selected?: T;
  public selection?: Selection<T>;
  public xlsxExporting = false;
  public ofcMode?: string;
  public ofcValue?: any;
  public ofcInstance?: NgbOffcanvasRef;
  public list!: ModelList<T>;
  public ldata!: { results: Observable<T[]>; loading: Observable<boolean> };
  public site_defaults: SITE_DEFAULT_PARAMS;
  @ViewChild("datatable", { static: false })
  private _dataTableSlot?: CdkTable<never>;
  private _subscriptions$ = new Subject<void>();

  constructor(
    public coll: Collection<T>,
    protected _list: ModelListService,
    protected _ofc: NgbOffcanvas,
  ) {
    this.site_defaults = inject(SITE_DEFAULTS, { optional: true }) || {
      MODELLIST_DEFAULT_POSITION: "ml-pinned",
      MODELLIST_SHOW_LISTNAME: false,
    };
  }

  @HostListener("document:keyup.escape", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.displayMode === "ml-over" || this.displayMode === "ml-over-end") {
      this.displayMode = "ml-hidden";
      event.preventDefault();
    }
  }

  @HostListener("document:keyup.shift.f", ["$event"])
  handleShowFilters(event: KeyboardEvent) {
    if (this.displayMode === "ml-hidden") {
      this.displayMode = "ml-over-end";
      event.preventDefault();
    }
  }

  public ngOnDestroy() {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public getTableSlot = (): CdkTable<never> | undefined => {
    return this._dataTableSlot;
  };

  public async print() {
    console.log("Not implemented");
  }

  public getFilters(): FiltersParams {
    return {
      defaults: ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
      ],
    };
  }

  public getFields(): FieldsParams {
    const flds: FieldsParams = { excluded: [], custom: [] };
    if (this.default_fields && this.default_fields.length) {
      flds.defaults = this.default_fields;
    }
    // If actions is true, add actions custom field
    if (this.actions) {
      flds.custom!.push({
        label: "Actions",
        name: "actions",
      });
    }
    // If actions is true, ensure we have a reload subject
    if (this.actions) {
      if (!this.reload) {
        this.reload = new Subject();
      }
    }
    return flds;
  }

  public ngOnInit(): void {
    this.preNgOnInit();
    this.displayMode =
      this.displayMode ||
      this.site_defaults.MODELLIST_DEFAULT_POSITION ||
      "ml-pinned";

    if (!this.name && this.detail_route) {
      this.name = this.detail_route?.name + "-list";
    }
    if (!this.filters) {
      this.filters = this.getFilters();
    }
    if (!this.fields) {
      this.fields = this.getFields();
    }
    this.list = this._list.get<T>(this.name, this.coll, {
      fields: this.fields,
      filters: this.filters,
      sorter: this.sorter,
      paginator: this.paginator,
      keep: this.keep,
      reload: this.reload,
      filter: this.filter,
      unsubscribe: this._subscriptions$,
    });
    this.ldata = {
      results: this.list.results.pipe(
        tap(this.tapPreResults.bind(this)),
        takeUntil(this._subscriptions$),
      ),
      loading: this.list.loading.pipe(takeUntil(this._subscriptions$)),
    };
    this.postNgOnInit();
  }

  public preNgOnInit() {}

  public postNgOnInit() {}

  public tapPreResults(res: T[]) {}

  public detailRoute(item: T): (string | number)[] | null {
    if (this.detail_route) {
      const params: Record<string, any> = {};
      // SEE: check if this fix is ok
      let type = this.ctype || (this.coll.model as any).__name?.toLowerCase();
      if (!type) {
        console.error(
          "Undefined model type, using relative link",
          this.coll.model,
        );
        return null;
      } else {
        if (type.indexOf("base") === type.length - 4) {
          type = type.replace("base", "");
        }
      }
      params[type] = item;
      return this.detail_route.route(params);
    } else {
      // console.warn('No detail_route provided, no link');
      return null;
    }
  }

  public getRowClasses(row: T): string[] {
    return [];
  }

  public getTableClasses(): string[] {
    return ["table-hover"];
  }

  public ofc(
    item: T | undefined,
    mode?: string,
    value?: any,
    options?: {
      position?: "start" | "end" | "top" | "bottom";
      reload?: boolean;
      panelClass?: string;
    },
  ) {
    if (this.ofcSlot) {
      this.selected = item;
      this.ofcMode = mode;
      this.ofcValue = value;
      this.ofcInstance = this._ofc.open(this.ofcSlot, {
        position: options?.position ? options?.position : "end",
        panelClass: options?.panelClass,
      });
      this.ofcInstance.result.then(
        () => {
          if (options?.reload !== false) {
            this.reload?.next(true);
          }
        },
        () => {
          if (options?.reload !== false) {
            this.reload?.next(true);
          }
        },
      );
    } else {
      console.error("No ofc slot available");
    }
  }

  public haveAction(act: string) {
    if (!this.actions) {
      return false;
    }
    return this.actions.indexOf(act) !== -1;
  }

  public eltId(index: number, model: T): number | string {
    return model.id as number;
  }

  public xlsxExportMode($event: boolean) {
    this.xlsxExporting = $event;
  }

  public toggleDisplayMode(
    position?: "ml-hidden" | "ml-over" | "ml-over-end" | "ml-pinned" | "ml-top",
  ) {
    if (this.displayMode === "ml-hidden") {
      this.displayMode =
        position ||
        this.site_defaults.MODELLIST_DEFAULT_POSITION ||
        "ml-pinned";
    } else {
      this.displayMode = "ml-hidden";
    }
  }
}
