
<ng-container *ngIf="display==='card'">
  <div class="card-header">
    <lvadg-icon ri="storage"><span class="fw-bold">{{title}}</span></lvadg-icon>
  </div>
  <div class="max-height-200">
    <nav class="list-group list-group-flush">
      <ng-container *ngFor="let s of storages$|async"><a class="list-group-item list-group-item-action" [routerLink]="detail_route?.route({storage: s})">{{s.name}}</a></ng-container>
    </nav>
  </div>
</ng-container>