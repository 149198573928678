import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Allergen } from '../allergen';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-allergen-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './allergen-manage.component.pug',
  styleUrls: ['./allergen-manage.component.sass'],
})
export class AllergenManageComponent {
  @Input() public allergen?: Allergen;

  constructor() {}
}
