import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Orderitem } from '../orderitem';
import { OrderitemService } from '../orderitem.service';
import {
  FactorcPipe,
  FactorPipe,
  FieldsParams,
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  PFactorcPipe,
  PFactorPipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { OrderitemQuantityComponent } from '../orderitem-quantity/orderitem-quantity.component';
import { OrderService } from '../../order/order.service';
import { firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';
import { Order } from '../../order/order';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { PlatformDisplayComponent } from '../../../structures/platform/platform-display/platform-display.component';
import { OrderDisplayComponent } from '../../order/order-display/order-display.component';
import { StorageBase } from '../../../structures/storage/storage.base';
import { PlatformBase } from '../../../structures/platform/platform.base';
import { OrderBase } from '../../order/order.base';
import { ArticleBase } from '../../../catalog/article/article.base';
import { TarifBase } from '../../../tarifs/tarif/tarif.base';
import { ArticleDisplayComponent } from '../../../catalog/article/article-display/article-display.component';
import { TarifDisplayComponent } from '../../../tarifs/tarif/tarif-display/tarif-display.component';

@Component({
  selector: 'lvadg-orderitem-list',
  standalone: true,
  templateUrl: './orderitem-list.component.pug',
  styleUrls: ['./orderitem-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FactorPipe,
    FactorcPipe,
    OrderitemQuantityComponent,
    PFactorcPipe,
    PFactorPipe,
    PChoicePipe,
    StorageDisplayComponent,
    PlatformDisplayComponent,
    OrderDisplayComponent,
    ArticleDisplayComponent,
    TarifDisplayComponent,
  ],
})
export class OrderitemListComponent extends ModellistComponent<Orderitem> {
  @Input() public quantityEditor = false;
  @Input() public dispStats = true;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public platform_detail_route?: Link<PlatformBase>;
  @Input() public order_detail_route?: Link<OrderBase>;
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public tarif_detail_route?: Link<TarifBase>;

  public focus$?: ReplaySubject<number>;
  public stats$!: Observable<{
    total_price: number;
    total_weight: number;
    total_quantity: number;
  }>;

  constructor(
    coll: OrderitemService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _order: OrderService
  ) {
    super(coll, list, ofc);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    if (this.quantityEditor) {
      this.focus$ = new ReplaySubject<number>(1);
    }
    this.stats$ = this.list.results.pipe(
      map((lines) => {
        const stats = { total_price: 0, total_weight: 0, total_quantity: 0 };
        for (const l of lines) {
          stats.total_price += l.total_price || 0;
          stats.total_weight += l.total_weight || 0;
          stats.total_quantity += l.quantity || 0;
        }
        stats.total_quantity /= 100000;
        stats.total_weight /= 100000;
        stats.total_price /= 10000;
        return stats;
      })
    );
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const fields = super.getFields();
    if (this.quantityEditor) {
      fields.custom?.push({ name: 'quantity_edit', label: 'Quantité' });
    }
    return fields;
  }

  public async updateQuantity(
    item: Orderitem,
    quantity: number | null | undefined
  ) {
    const order = await firstValueFrom(
      this._order.fetch(item.order, {
        params: { fields: ['id', 'order'].join(',') },
      })
    );
    const res = await firstValueFrom(
      order.action<Order, { order: Order; items: Orderitem[] }>(
        'POST',
        'action',
        {
          body: {
            action: 'update_quantity',
            item: item.id,
            quantity: quantity,
          },
        }
      )
    );
    item.fromJson(res.items[0], { partial: true });
    this.reload?.next();
  }
}
