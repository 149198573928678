
<ng-container *ngIf="mode==='offcanvas'">
  <ng-container *ngIf="image$|async as image">
    <form [formGroup]="editForm">
      <div class="row">
        <div class="col-12 col-md-3">
          <lvadg-image-display [image]="image" mode="large"></lvadg-image-display>
        </div>
        <div class="col-12 col-md-9">
          <div class="row">
            <div class="col-12 col-md-3">
              <dl *ngIf="image">
                <dt>Type de fichier</dt>
                <dd>{{image.type||'-'}}</dd>
                <dt>État</dt>
                <dd>{{image.status||'-'}}</dd>
                <dt>Nom du fichier</dt>
                <dd>{{image.filename}}</dd>
                <dt>Taille du fichier</dt>
                <dd>
                  <ng-container *ngIf="image.filesize">{{image.filesize | bytes}}</ng-container>
                  <ng-container *ngIf="!image.filesize">-</ng-container>
                </dd>
                <dt>Type Mime</dt>
                <dd>{{image.mimetype}}</dd>
                <dt>Largeur image originale</dt>
                <dd>{{image.width}} px</dd>
                <dt>Hauteur image originale</dt>
                <dd>{{image.height}} px</dd>
              </dl>
            </div>
            <div class="col-12 col-md-4">
              <dl *ngIf="image">
                <dt>Titre de l'image</dt>
                <dd>
                  <input class="form-control form-control-sm" [formControlName]="'title'" type="text" placeholder="Titre de l'image">
                </dd>
                <dt>Slug de l'image</dt>
                <dd>
                  <input class="form-control form-control-sm" [formControlName]="'slug'" type="text" placeholder="Slug de l'image">
                </dd>
                <dt>URL Vidéo</dt>
                <dd>
                  <input class="form-control form-control-sm" [formControlName]="'video'" type="text" placeholder="URL Vidéo">
                </dd>
                <dt>Ordre d'affichage</dt>
                <dd>
                  <input class="form-control form-control-sm" [formControlName]="'order'" type="number" placeholder="Ordre d'affichage">
                </dd>
                <dt>Légende</dt>
                <dd>
                  <textarea class="form-control form-control-sm" [formControlName]="'legend'" placeholder="Légende"></textarea>
                </dd>
                <dt>Crédits</dt>
                <dd>
                  <textarea class="form-control form-control-sm" [formControlName]="'credits'" placeholder="Crédits"></textarea>
                </dd>
              </dl>
            </div>
            <div class="col-12 col-md-5">
              <dl *ngIf="image">
                <dt>Fichier</dt>
                <dd><a [href]="image.file" target="_blank">{{image.file}}</a></dd>
                <dt>Utilisations</dt>
                <dd>
                  <button class="btn btn-sm me-2 my-1" *ngFor="let u of USAGES" [class.btn-success]="image.usages.indexOf(u.value) &gt;= 0" [class.btn-outline-secondary]="image.usages.indexOf(u.value) &lt; 0" (click)="toggleUsage(image, u.value)">{{u.desc}}</button>
                </dd>
              </dl>
            </div>
            <div class="col-12 mt-3">
              <button class="btn btn-lg btn-primary w-100" (click)="save(image)">
                <lvadg-icon ri="save">Enregistrer les modifications</lvadg-icon>
              </button>
              <button class="btn btn-outline-secondary w-100 mt-3" (click)="closed.emit()">
                <lvadg-icon ri="close">Fermer</lvadg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>