
<ng-container *ngIf="data$|async; let data">
  <ng-template #ofcSlot let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Ajouter un destinataire</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-offer-notification-destination-create [offer]="data.offer" [offertypestorage]="data.offertypestorage" [offertype]="data.offertype" [resto]="currentResto()" [current_dest]="currentDest()" (created)="onCreatedDestination()"></lvadg-offer-notification-destination-create>
    </div>
  </ng-template>
  <ng-template #ofcContactSlot let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Modifier un contact</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <div class="container">
        <lvadg-contact-manage [contact]="currentContact()"></lvadg-contact-manage>
      </div>
    </div>
  </ng-template>
  <ng-template #ofcRemoveSlot let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title text-danger">
        <lvadg-icon ri="danger">Supprimer ce destinataire</lvadg-icon>
      </h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body d-flex flex-column justify-content-evenly align-items-center">
      <p class="text-center text-danger fs-4">
        Voulez-vous vraiment SUPPRIMER ce destinataire ? Cette action est irréversible, etsupprimera également tous l'historique des notifications de cet utilisateur, les liens des offres
        de ses emails, etc.. dans le doute, placez-le dans la corbeille, ou désactivez-le plutôt que de le supprimer...
      </p>
      <div>
        <button class="btn btn-outline-danger btn-lg w-100 my-5 py-4" (click)="processRemove(true)">
          <lvadg-icon ri="danger">Supprimer DÉFINITIVEMENT ce destinataire</lvadg-icon>
        </button>
        <button class="btn btn-outline-success btn-lg w-100 my-5 py-4" (click)="processRemove(false)">
          <lvadg-icon ri="delete">Placer dans la corbeille (plus sûr)</lvadg-icon>
        </button>
        <button class="btn btn-outline-secondary btn-lg w-100 my-5" (click)="offcanvas.close()">
          <lvadg-icon ri="close">Annuler</lvadg-icon>
        </button>
      </div>
    </div>
  </ng-template>
  <lvadg-offer-nav [routes]="data.o_menu" [offer]="data.offer" [offertypestorage]="data.offertypestorage" *ngIf="data.o_menu"></lvadg-offer-nav>
  <lvadg-header-action [title]="data.offer.title" level="h1" subtitle="Destinataires de l'offre" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-info btn-sm" (click)="updateDestinations(data.offer)" *ngIf="data.offer.have_level(OUL.o_write)">
        <lvadg-icon ri="sync">Mettre à jour</lvadg-icon>
      </button>
      <button class="btn btn-outline-primary btn-sm" (click)="addDestination()" *ngIf="data.offer.have_level(OUL.o_write)">
        <lvadg-icon ri="plus">Ajouter un destinataire</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-offer-notification-destination-list [filter]="offerFilter$" [default_fields]="default_fields" [actions]="data.offer.have_level(OUL.o_write) ? ['editContact', 'deleteDestination', 'addContact', 'addOtherContact', 'toggleDestination']:[]" [reload]="reload$" (action)="onListAction($any($event))" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [contact_detail_route]="contact_detail_route" [storage_detail_route]="storage_detail_route" [resto_detail_route]="resto_detail_route"></lvadg-offer-notification-destination-list>
</ng-container>