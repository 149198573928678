import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TariftypeStorage } from '../../../../models/tarifs/tariftype-storage/tariftype-storage';
import { TariftypeStorageManageComponent } from '../../../../models/tarifs/tariftype-storage/tariftype-storage-manage/tariftype-storage-manage.component';
import { BaseRouteParams } from '@solidev/data';

export interface TariftypeStorageDetailViewParams extends BaseRouteParams {}

export interface TariftypeStorageDetailViewData extends TariftypeStorageDetailViewParams {
  tariftypestorage: TariftypeStorage;
}

@Component({
  selector: 'lvadg-tariftype-storage-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TariftypeStorageManageComponent],
  templateUrl: './tariftype-storage-detail-view.component.pug',
  styleUrls: ['./tariftype-storage-detail-view.component.sass'],
})
export class TariftypeStorageDetailViewComponent extends BaseDetailViewComponent<
  TariftypeStorageDetailViewData,
  TariftypeStorage
> {
  public override setRouteData(data: TariftypeStorageDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
