import { Component, input, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StructureBase } from "../../structure.base";
import { Relation } from "../relation";
import { SimpleMapComponent } from "../../../locations/mapping/simple-map/simple-map.component";
import { Observable, of } from "rxjs";
import { MapData } from "../../../locations/mapping/constants";
import { Member } from "../../member/member";
import { Storage } from "../../storage/storage";
import { Producer } from "../../producer/producer";
import { Link } from "@solidev/data";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lvadg-relation-listmap",
  standalone: true,
  imports: [CommonModule, SimpleMapComponent, RouterLink],
  templateUrl: "./relation-listmap.component.pug",
  styleUrls: ["./relation-listmap.component.sass"],
})
export class RelationListmapComponent<T extends StructureBase>
  implements OnInit
{
  @Input() public model!: T;
  @Input() public relations: Relation[] = [];
  public storage_detail_route = input<Link<Storage>>();
  public mapData!: Observable<MapData>;

  public ngOnInit() {
    const data: MapData = {
      members: this.relations
        .filter((r) => r.member_details)
        .map((r) => r.member_details as Member),
      storages: this.relations
        .filter((r) => r.storage_details)
        .map((r) => r.storage_details as Storage),
      producers: this.relations
        .filter((r) => r.producer_details)
        .map((r) => r.producer_details as Producer),
    };
    if (this.model) {
      switch (this.model.map_pointer) {
        case "producer":
          data.producers = [
            ...(data.producers || []),
            this.model as unknown as Producer,
          ];
          break;
        case "storage":
          data.storages = [
            ...(data.storages || []),
            this.model as unknown as Storage,
          ];
          break;
        case "member":
          data.members = [
            ...(data.members || []),
            this.model as unknown as Member,
          ];
          break;
      }
    }
    this.mapData = of(data);
  }
}
