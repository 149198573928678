import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TariftypeStorage } from '../tariftype-storage';
import { TariftypeStorageService } from '../tariftype-storage.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { StorageBase } from '../../../structures/storage/storage.base';
import { TariftypeStoragePermissionDisplayComponent } from '../../tariftype-storage-permission/tariftype-storage-permission-display/tariftype-storage-permission-display.component';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { TariftypeStoragePermissionBase } from '../../tariftype-storage-permission/tariftype-storage-permission.base';
import { TariftypeStorageManageComponent } from '../tariftype-storage-manage/tariftype-storage-manage.component';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { TariftypeService } from '../../tariftype/tariftype.service';

@Component({
  selector: 'lvadg-tariftype-storage-list',
  standalone: true,
  templateUrl: './tariftype-storage-list.component.pug',
  styleUrls: ['./tariftype-storage-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    StorageDisplayComponent,
    SafeDeleteComponent,
    TariftypeDisplayComponent,
    TariftypeStoragePermissionDisplayComponent,
    TariftypeStorageManageComponent,
  ],
})
export class TariftypeStorageListComponent extends ModellistComponent<TariftypeStorage> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input()
  public permission_detail_route?: Link<TariftypeStoragePermissionBase>;
  public deleteFn = this._deleteFn.bind(this);

  constructor(
    coll: TariftypeStorageService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _tts: TariftypeService,
    private _ttact: TariftypeActionService
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }

  private async _deleteFn(
    model: TariftypeStorage
  ): Promise<{ success: boolean }> {
    if (!model.tariftype_details) {
      return { success: false };
    }
    model.tariftype_details._collection = this._tts;
    const res = await this._ttact.action(model.tariftype_details, {
      action: 'remove_storage',
      ttstorage: model.id,
    });
    return res;
  }
}
