import { RoutesConfig } from '@solidev/data';
import { RS } from '@vivalya/lvadg';

export const ADMIN_BASE_ROUTES_NAMES = ['help', 'home', 'login', 'logout', 'noop'] as const;
export const ADMIN_BASE_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_BASE_ROUTES_NAMES)[number]> = {
  help: {
    params: ['path'],
    route: (p: string) => ['/docs' + p],
    name: 'help',
  },
  home: {
    params: [],
    route: () => ['/'],
    name: 'home',
  },
  login: {
    params: [],
    route: () => ['/', RS.login],
    name: 'login',
  },
  logout: {
    params: [],
    route: () => ['/', RS.logout],
    name: 'logout',
  },
  noop: {
    params: [],
    route: () => [],
    name: 'noop',
  },
};
