import {
  charField,
  DataModel,
  datetimeField,
  decimalField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum ArticleTarifStatus {
  TOVALIDATE = 'TV',
  VALID = 'OK',
  NOPRICE = 'NC',
  NOTAVAILABLE = 'ND',
  INACTIVE = 'KO',
}

export const ArticleTarifStatusChoices = [
  {
    value: ArticleTarifStatus.TOVALIDATE,
    desc: 'A valider',
  },

  {
    value: ArticleTarifStatus.VALID,
    desc: 'Valide',
  },
  {
    value: ArticleTarifStatus.NOPRICE,
    desc: 'Non coté',
  },
  {
    value: ArticleTarifStatus.NOTAVAILABLE,
    desc: 'Non disponible',
  },
  {
    value: ArticleTarifStatus.INACTIVE,
    desc: 'Désactivé',
  },
];

// TODO: add status

export class ArticleTarifBase extends DataModel {
  static override readonly __name: string = 'ArticleTarifBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 10000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 48,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 49,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tarif',
    description: 'ID Tarif',
    related: 'Tarif',
    priority: -1,
  })
  public tarif!: number;

  @foreignKeyField({
    name: 'att',
    description: 'ID Article tarif',
    related: 'ArticleTarifTemplate',
    priority: -1,
  })
  public att!: number;

  @decimalField({
    name: 'price',
    factor: 10000,
    description: 'Prix',
    priority: 300,
  })
  public price!: number;

  @textField({
    name: 'message',
    description: 'Message',
    priority: 700,
  })
  public message!: string;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: ArticleTarifStatus.TOVALIDATE,
    maxLength: 4,
    choices: ArticleTarifStatusChoices,
    priority: 50,
  })
  public status: ArticleTarifStatus = ArticleTarifStatus.TOVALIDATE;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;

  @decimalField({
    name: 'ops_price',
    factor: 10000,
    readonly: true,
    description: 'Prix aprés calculs',
    priority: 200,
  })
  public ops_price!: number;

  @detailsField({
    name: 'ops',
    readonly: true,
    description: 'Calculs',
    deserialize: (d) => d,
    priority: 199,
  })
  public ops: string[] = [];
}
