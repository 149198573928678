
<ng-container *ngIf="data$|async; let data">
  <h2>Infos utilisateur</h2>
  <div class="row">
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-header">Infos utilisateur</div>
        <div class="card-body">
          <dl class="row">
            <div class="col-4">
              <dt>ID</dt>
              <dd>{{data.id}}</dd>
            </div>
            <div class="col-4">
              <dt>Nom</dt>
              <dd>{{data.last_name}}</dd>
            </div>
            <div class="col-4">
              <dt>Prénom</dt>
              <dd>{{data.first_name}}</dd>
            </div>
            <div class="col-4">
              <dt>Email</dt>
              <dd>{{data.email}}</dd>
            </div>
            <div class="col-4">
              <dt>Username</dt>
              <dd>{{data.username}}</dd>
            </div>
          </dl>
          <dl class="row">
            <div class="col-4">
              <dt>Actif ?</dt>
              <dd>{{data.is_active?'Oui':'Non'}}</dd>
            </div>
            <div class="col-4">
              <dt>Dernier login</dt>
              <dd>{{data.last_login|date:'dd/MM/yyyy HH:mm'}}</dd>
            </div>
            <div class="col-4">
              <dt>Flags</dt>
              <dd>
                <ul>
                  <li *ngFor="let f of data.flags">{{f}}</li>
                </ul>
              </dd>
            </div>
            <div class="col-4">
              <dt>Superuser ?</dt>
              <dd>{{data.is_superuser?'Oui':'Non'}}</dd>
            </div>
            <div class="col-4">
              <dt>Staff ?</dt>
              <dd>{{data.is_staff?'Oui':'Non'}}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-header">Structures utilisateur</div>
        <div class="card-body">
          <ul>
            <li *ngFor="let org of data.structures"><span class="me-3"><span class="badge bg-warning" *ngIf="org.is_admin">Admin</span><span class="badge bg-success" *ngIf="!org.is_admin">Utilisateur</span></span>
              <lvadg-member-display [member]="org.member" *ngIf="org.member" [detail_route]="member_detail_route"></lvadg-member-display>
              <lvadg-storage-display [storage]="org.storage" *ngIf="org.storage" [detail_route]="storage_detail_route"></lvadg-storage-display>
              <lvadg-client-display [client]="org.client" *ngIf="org.client" [detail_route]="client_detail_route"></lvadg-client-display>
              <lvadg-resto-display [resto]="org.resto" *ngIf="org.resto" [detail_route]="resto_detail_route"></lvadg-resto-display>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-header">Consultation tarifs</div>
        <div class="card-body">
          <dl class="row">
            <div class="col-4">
              <dt>Vue tarifs ?</dt>
              <dd>{{data.tarifs_allowed?'Oui':'Non'}}</dd>
            </div>
            <div class="col-4">
              <dt>Notification tous tarifs ?</dt>
              <dd>{{data.notifications_all?'Oui':'Non'}}</dd>
            </div>
            <div class="col-4">
              <dt>Aucune notification de tarif ?</dt>
              <dd>{{data.notifications_none?'Oui':'Non'}}</dd>
            </div>
          </dl>
          <dl>
            <dt>Détails notifications personnalisées</dt>
            <dd>
              <ul class="max-height-200 overflow-auto">
                <li *ngFor="let t of data.notifications_tarifs">
                  <lvadg-tariftype-display [tariftype]="t" [detail_route]="tariftype_detail_route"></lvadg-tariftype-display>
                </li>
              </ul>
            </dd>
            <dt>Pilote tarifaire</dt>
            <dd>
              <ul>
                <li *ngFor="let t of data.tarifs_pilote">
                  <lvadg-tariftype-display [tariftype]="t" [detail_route]="tariftype_detail_route"></lvadg-tariftype-display>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</ng-container>