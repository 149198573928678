import { OffertypeStoragePermissionBase } from './offertype-storage-permission.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';
import { OffertypeStorageBase } from '../offertype-storage/offertype-storage.base';

export class OffertypeStoragePermission extends OffertypeStoragePermissionBase {
  static override readonly __name: string = 'OffertypeStoragePermission';
  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;

  @detailsField({
    model: OffertypeStorageBase,
    description: 'Modèle offre dépôt',
    readonly: true,
  })
  public offertypestorage_details?: OffertypeStorageBase;
}
