import {
  charField,
  DataModel,
  integerField,
  primaryField,
} from '@solidev/data';

export class CiqualAlimBase extends DataModel {
  static override readonly __name: string = 'CiqualAlimBase';
  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @integerField({
    name: 'alim_code',
    description: 'Code aliment',
    priority: 1000,
  })
  public alim_code!: number;
  @charField({
    name: 'alim_grp_code',
    description: 'Code groupe',
    maxLength: 20,
    priority: 900,
  })
  public alim_grp_code!: string;
  @charField({
    name: 'alim_ssgrp_code',
    description: 'Code sous-groupe',
    maxLength: 20,
    priority: 800,
  })
  public alim_ssgrp_code!: string;
  @charField({
    name: 'alim_ssssgrp_code',
    description: 'Code sous-sous-groupe',
    maxLength: 20,
    priority: 700,
  })
  public alim_ssssgrp_code!: string;
  @charField({
    name: 'alim_grp_nom_fr',
    description: 'Nom groupe',
    maxLength: 200,
    priority: 600,
  })
  public alim_grp_nom_fr!: string;
  @charField({
    name: 'alim_ssgrp_nom_fr',
    description: 'Nom sous-groupe',
    maxLength: 200,
    priority: 500,
  })
  public alim_ssgrp_nom_fr!: string;
  @charField({
    name: 'alim_ssssgrp_nom_fr',
    description: 'Nom sous-sous groupe',
    maxLength: 200,
    priority: 400,
  })
  public alim_ssssgrp_nom_fr!: string;
  @charField({
    name: 'alim_nom_fr',
    description: 'Nom aliment',
    maxLength: 200,
    priority: 300,
  })
  public alim_nom_fr!: string;
  @charField({
    name: 'alim_nom_sci',
    description: 'Nom scientifique aliment',
    maxLength: 200,
    priority: 200,
  })
  public alim_nom_sci!: string;

  public _display(): string {
    return `[${this.alim_code}] ${this.alim_nom_fr}`;
  }
}
