import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DocsService } from './docs.service';
import { Doc } from './doc';

@Injectable({
  providedIn: 'root',
})
export class DocpageResolver  {
  constructor(private _docs: DocsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Doc> {
    return this._docs.fetch(
      '__' +
        route.url
          .filter((u, p) => p > 0)
          .map((u) => u.path)
          .join('__')
    );
  }
}
