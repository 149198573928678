import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeStoragePermissionBase } from '../tariftype-storage-permission.base';
import { TariftypeStoragePermission } from '../tariftype-storage-permission';
import { Link, PChoicePipe } from '@solidev/data';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';

@Component({
  selector: 'lvadg-tariftype-storage-permission-display',
  standalone: true,
  imports: [CommonModule, UserDisplayComponent, GroupDisplayComponent, PChoicePipe],
  templateUrl: './tariftype-storage-permission-display.component.pug',
  styleUrls: ['./tariftype-storage-permission-display.component.sass'],
})
export class TariftypeStoragePermissionDisplayComponent {
  @Input() public permission?: TariftypeStoragePermission;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<TariftypeStoragePermissionBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
}
