import { Component, inject, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { LayoutData, LayoutTree } from "../layout/layout";
import { ProviderChoice } from "../rstypes/ProviderChoice";
import { FormsModule } from "@angular/forms";
import { DataMessageService, FkselectComponent } from "@solidev/data";
import { ArticleService } from "../../catalog/article/article.service";
import { Article } from "../../catalog/article/article";
import { StorageService } from "../../structures/storage/storage.service";
import { MemberService } from "../../structures/member/member.service";
import { Storage } from "../../structures/storage/storage";
import { Member } from "../../structures/member/member";
import { PrintService } from "../print/print.service";
import { ClientService } from "../../structures/client/client.service";
import { RestoService } from "../../structures/resto/resto.service";
import { ProductService } from "../../catalog/product/product.service";
import { ProducerService } from "../../structures/producer/producer.service";
import { Client } from "../../structures/client/client";

@Component({
  selector: "lvadg-layout-providers-editor",
  standalone: true,
  imports: [CommonModule, FormsModule, FkselectComponent],
  templateUrl: "./layout-providers-editor.component.pug",
  styleUrls: ["./layout-providers-editor.component.sass"],
})
export class LayoutProvidersEditorComponent {
  @Input({ required: true }) public tree!: LayoutTree;
  @Input({ required: true }) public layout$!: Observable<LayoutData | null>;
  @Input() public reload$ = new Subject<void>();
  public add: ProviderChoice | null = null;
  public members$ = inject(MemberService);
  public storages$ = inject(StorageService);
  public clients$ = inject(ClientService);
  public restos$ = inject(RestoService);
  public articles$ = inject(ArticleService);
  public products$ = inject(ProductService);
  public producers$ = inject(ProducerService);
  // public providers$ = inject(ProviderService)
  private _print = inject(PrintService);
  private _msgs = inject(DataMessageService);

  public async addMember(member: Member, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "member",
          description: member.name,
          queryset: {},
          id: member.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Adhérent ajouté avec succes au calque");
  }

  public async addStorage(storage: Storage, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "storage",
          description: storage.name,
          queryset: {},
          id: storage.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Dépot ajouté avec succes au calque");
  }

  public async addClient(client: Client, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "client",
          description: client.name,
          queryset: {},
          id: client.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Client ajouté avec succes au calque");
  }

  public async addResto(resto: Client, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "resto",
          description: resto.name,
          queryset: {},
          id: resto.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Resto ajouté avec succes au calque");
  }

  public async addArticle(article: Article, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "article",
          description: article.libelle,
          queryset: {},
          id: article.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Article ajouté avec succes au calque");
  }

  public async addProduct(product: Article, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "product",
          description: product.libelle,
          queryset: {},
          id: product.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Produit ajouté avec succes au calque");
  }

  public async addProducer(producer: Member, layout: LayoutData) {
    await firstValueFrom(
      this.tree.set_data_param(layout, {
        layout_id: layout.layout_id,
        data: {
          type: "producer",
          description: producer.name,
          queryset: {},
          id: producer.id,
        },
      }),
    );
    this.add = null;
    this._msgs.success("Producteur ajouté avec succes au calque");
  }

  // public async addProvider(provider: Member, layout: LayoutData) {
  //   await firstValueFrom(
  //     this.tree.set_data_param(layout, {
  //       layout_id: layout.layout_id,
  //       data: {
  //         type: 'provider',
  //         description: provider.name,
  //         id: provider.id,
  //       },
  //     }),
  //   );
  //   this.add = null;
  //   this._msgs.success('Fournisseur ajouté avec succes au calque');
  // }
}
