import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeDocument } from '../../tariftype-document/tariftype-document';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeMetadata } from '../tariftype-metadata';
import { TariftypeMetadataBase } from '../tariftype-metadata.base';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { DispeditComponent } from '@solidev/data';
import { ReactiveFormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { TariftypeMetadataService } from '../tariftype-metadata.service';

@Component({
  selector: 'lvadg-tariftype-metadata-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './tariftype-metadata-create.component.pug',
  styleUrls: ['./tariftype-metadata-create.component.sass'],
})
export class TariftypeMetadataCreateComponent implements OnInit {
  @Input() public tariftypedocument?: TariftypeDocument;
  @Input() public tariftype?: Tariftype;
  @Output() public created = new EventEmitter<TariftypeMetadata>();
  @Output() public cancelled = new EventEmitter<void>();
  public meta!: TariftypeMetadataBase;

  constructor(
    private _ttact: TariftypeActionService,
    private _tts: TariftypeService,
    private _ttm: TariftypeMetadataService
  ) {}

  ngOnInit(): void {
    this.meta = new TariftypeMetadataBase();
    if (this.tariftype) {
      this.meta.tariftype = this.tariftype.id;
    } else if (this.tariftypedocument && this.tariftypedocument.tariftype) {
      this.meta.tariftype = this.tariftypedocument.tariftype;
    } else {
      console.error('Missing tariftype data');
    }
  }

  public async save() {
    const tt = await firstValueFrom(this._tts.fetch(this.meta.tariftype));
    const res = await this._ttact.action(tt, {
      action: 'add_metadata',
      tariftype: this.meta.tariftype,
      document: this.tariftypedocument?.id,
      name: this.meta.name,
      type: this.meta.type,
    });
    if (res.success) {
      const meta = await firstValueFrom(
        this._ttm.fetch(res.data.metadata_id, { params: { db: 'sync' } })
      );
      this.created.emit(meta);
    }
  }
}
