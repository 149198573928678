import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '@solidev/data';
import { ProviderBase } from '../provider.base';
import { Provider } from '../provider';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-provider-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './provider-display.component.pug',
  styleUrls: ['./provider-display.component.sass'],
})
export class ProviderDisplayComponent {
  @Input() public provider?: ProviderBase | Provider;
  @Input() public mode = 'line' as const;
  @Input() public detail_route?: Link<ProviderBase | Provider>;
}
