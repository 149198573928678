import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdergroupBase } from '../ordergroup.base';
import { Ordergroup } from '../ordergroup';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-ordergroup-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './ordergroup-display.component.pug',
  styleUrls: ['./ordergroup-display.component.sass'],
})
export class OrdergroupDisplayComponent implements OnInit {
  @Input() public ordergroup?: OrdergroupBase | Ordergroup;
  @Input() public mode: 'line' | 'button' = 'line';
  @Input() public detail_route?: Link<OrdergroupBase>;

  constructor() {}

  ngOnInit(): void {}
}
