import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TariftypeRegionService } from './tariftype-region.service';
import { TariftypeRegion } from './tariftype-region';

@Injectable({
  providedIn: 'root',
})
export class TariftypeRegionResolver {
  constructor(private _rs: TariftypeRegionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TariftypeRegion> {
    return this._rs.fetch(+route.params['tariftyperegionId']);
  }
}
