import { Component } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Family } from "../family";
import { FamilyService } from "../family.service";
import {
  FiltersParams,
  ModelListAutocompleteMultiFilter,
  ModelListFlagsFilter,
  ModelListNumberFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  ModelListTreeFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { FlagsDisplayComponent } from "../../../../components/utils/flags-display/flags-display.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { FAMILY_TYPE } from "../family.base";
import { StorageService } from "../../../structures/storage/storage.service";
import { ProducerService } from "../../../structures/producer/producer.service";
import { LabelService } from "../../label/label.service";
import { LABEL_TYPE } from "../../label/label.base";
import { map } from "rxjs";
import { NgStringPipesModule } from "ngx-pipes";

@Component({
  selector: "lvadg-family-list",
  standalone: true,
  templateUrl: "./family-list.component.pug",
  styleUrls: ["./family-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    FlagsDisplayComponent,
    ImageDisplayComponent,
    PChoicePipe,
    NgStringPipesModule,
  ],
})
export class FamilyListComponent extends ModellistComponent<Family> {
  constructor(
    coll: FamilyService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _storages: StorageService,
    private _producers: ProducerService,
    private _families: FamilyService,
    private _labels: LabelService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "level",
          "parentfamily",
          "storages",
          // 'article-libelle',
          // 'tariftype-name',
        ],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        // Famille et sous-familles
        new ModelListTreeFilter({
          field: "parentfamily",
          name: "parentfamily",
          label: "Famille et sous-famille - sélection",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          help: "---------------------",
          display: (v) => `${v.name}`,
          collection: this._families,
        }),
        // Recherche par niveau hiérarchique
        new ModelListNumberFilter({
          field: "level",
          name: "level",
          label: "Niveau hiérarchique",
          help: "Niveau hiérarchique de la famille (0 = racine / 5 = fin)",
        }),
        // TODO: Recherche par dépôt via produits
        new ModelListAutocompleteMultiFilter({
          field: "products_storages",
          name: "products_storages",
          label: "Dépôt(s) via produits",
          help: "Nom du dépôt",
          collection: this._storages,
        }),
        // Recherche par nb de produits directs
        new ModelListNumberFilter({
          field: "products_count",
          name: "products_count",
          label: "Nb de produits directs",
          help: "Nombre de produits directs",
        }),
        // TODO: Recherche par nb de produits enfants
        // Recherche par égalim produit
        new ModelListSelectMultiFilter({
          field: "products_egalim",
          name: "products_egalim",
          label: "Labels produit",
          help: "Labels produit",
          choices: this._labels.byTypes(LABEL_TYPE.FL, LABEL_TYPE.SEA).pipe(
            map((labels) =>
              labels.map((label) => ({
                value: label.id,
                desc: `[${label.type_short}] ${label.name}`,
              })),
            ),
          ),
        }),
        // Recherche par égalim article
        new ModelListSelectMultiFilter({
          field: "articles_egalim",
          name: "articles_egalim",
          label: "Labels article",
          help: "Labels article",
          choices: this._labels.byTypes(LABEL_TYPE.FL, LABEL_TYPE.SEA).pipe(
            map((labels) =>
              labels.map((label) => ({
                value: label.id,
                desc: `[${label.type_short}] ${label.name}`,
              })),
            ),
          ),
        }),

        // TODO: Recherche par flag
        new ModelListFlagsFilter({
          field: "flags",
          name: "flags",
          label: "Recherche par flag",
          help: "Recherche par flag",
          collection: this.coll,
        }),
        // TODO: Recherche par lien produit/producteur : client
        // TODO: Recherche par lien produit/producteur : restaurant
        // TODO: Recherche par producteur (critères)
        new ModelListAutocompleteMultiFilter({
          field: "products_producers",
          name: "products_producers",
          label: "Recherche par producteur(s)",
          collection: this._producers,
          filter: { fields: ["id", "name"].join(",") },
        }),
        new ModelListSelectMultiFilter({
          field: "admin_checks",
          name: "admin_checks",
          label: "Checks administateur (tous)",
          choices: [
            { value: "missing_article_name", desc: "Nom d'article manquant" },
            {
              value: "missing_article_seasons",
              desc: "Saisonnalité article manquante",
            },
            {
              value: "missing_article_description",
              desc: "Description article manquante",
            },
          ],
        }),
        // TODO: Recherche par dépôt (critères)
        // TODO: Recherche par logo (existante ou non)
        // TODO: Recherche par clogo (existant ou non)
        // new ModelListTextFilter({
        //   field: 'article-libelle',
        //   name: 'article-libelle',
        //   label: '[Articles] Recherche par texte du libellé',
        // }),
        // new ModelListTextFilter({
        //   field: 'tariftype-name',
        //   name: 'tariftype-name',
        //   label: '[Tarif] Recherche par titre du tarif',
        // }),
      ],
    };
  }
}
