import { Component } from '@angular/core';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Contact } from '../../../models/users/contact/contact';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../components/baseview/basedetailview.imports';
import { ContactManageComponent } from '../../../models/users/contact/contact-manage/contact-manage.component';
import { BaseDetailViewComponent } from '../../../components/baseview/basedetailview.component';

export interface ContactDetailViewParams extends BaseRouteParams {
  resto_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
  member_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
}

export interface ContactDetailViewData extends ContactDetailViewParams {
  contact: Contact;
}

@Component({
  selector: 'lvadg-contact-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ContactManageComponent],
  templateUrl: './contact-detail-view.component.pug',
  styleUrls: ['./contact-detail-view.component.sass'],
})
export class ContactDetailViewComponent extends BaseDetailViewComponent<ContactDetailViewData, Contact> {
  public override setRouteData(data: ContactDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
