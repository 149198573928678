
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { VivalyaBillService} from "./vivalya-bill.service";
import { VivalyaBill } from "./vivalya-bill";

@Injectable({
  providedIn: 'root'
})
export class VivalyaBillResolver implements Resolve<VivalyaBill> {
  constructor(private _rs: VivalyaBillService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<VivalyaBill> {
    return this._rs.fetch(+route.params["vivalyabillId"])
  }
}
