import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseListRouteParams, BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { VivalyaBillItem } from '../../../../models/sales/vivalya-bill-item/vivalya-bill-item';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { VivalyaBillItemListComponent } from '../../../../models/sales/vivalya-bill-item/vivalya-bill-item-list/vivalya-bill-item-list.component';

export interface VivalyaBillItemListViewParams extends BaseListRouteParams {}

export interface VivalyaBillItemListViewData extends VivalyaBillItemListViewParams {}

@Component({
  selector: 'lvadg-vivalya-bill-item-list-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, VivalyaBillItemListComponent],
  templateUrl: './vivalya-bill-item-list-view.component.pug',
  styleUrls: ['./vivalya-bill-item-list-view.component.sass'],
})
export class VivalyaBillItemListViewComponent extends BaseListViewComponent<
  VivalyaBillItemListViewData,
  VivalyaBillItem
> {}
