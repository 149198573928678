import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdergroupService } from '../ordergroup.service';
import { OrdergroupBase } from '../ordergroup.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { OrderprocessService } from '../../orderprocess/orderprocess.service';

@Component({
  selector: 'lvadg-ordergroup-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './ordergroup-create.component.pug',
  styleUrls: ['./ordergroup-create.component.sass'],
})
export class OrdergroupCreateComponent implements OnInit {
  public obj!: OrdergroupBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<OrdergroupBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _svc: OrdergroupService,
    public process$: OrderprocessService
  ) {}

  public ngOnInit(): void {
    this.obj = new OrdergroupBase(this._svc);
  }

  public async save() {
    this.obj.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
