
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-atscache-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './atscache-display.component.pug',
  styleUrls: ['./atscache-display.component.sass']
})
export class AtscacheDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
