import { RoutesConfig } from '@solidev/data';
import {
  Distance,
  Genericregion,
  Geocommune,
  Geocountry,
  Geodepartement,
  Georegion,
  Location,
  RS,
  UserMap,
} from '@vivalya/lvadg';

export const ADMIN_LOCATIONS_ROUTES_NAMES = [
  'locations',
  'locations_distances',
  'locations_distances_distance',
  'locations_genericregions',
  'locations_genericregions_genericregion',
  'locations_geocommunes',
  'locations_geocommunes_geocommune',
  'locations_geocountries',
  'locations_geocountries_geocountry',
  'locations_geodepartements',
  'locations_geodepartements_geodepartement',
  'locations_georegions',
  'locations_georegions_georegion',
  'locations_locations',
  'locations_locations_location',
  'locations_usermaps',
  'locations_usermaps_usermap',
] as const;
export const ADMIN_LOCATIONS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_LOCATIONS_ROUTES_NAMES)[number]> = {
  locations: {
    params: [],
    route: () => ['/', RS.locations],
    name: 'locations',
  },
  locations_distances: {
    params: [],
    route: () => ['/', RS.locations, RS.distances],
    name: 'locations_distances',
  },
  locations_distances_distance: {
    params: ['distance'],
    route: (distance: Distance) => ['/', RS.locations, RS.distances, distance.id],
    name: 'locations_distances_distance',
  },
  locations_genericregions: {
    params: [],
    route: () => ['/', RS.locations, RS.genericregions],
    name: 'locations_genericregions',
  },
  locations_genericregions_genericregion: {
    params: ['genericregion'],
    route: (genericregion: Genericregion) => ['/', RS.locations, RS.genericregions, genericregion.id],
    name: 'locations_genericregions_genericregion',
  },
  locations_geocommunes: {
    params: [],
    route: () => ['/', RS.locations, RS.geocommunes],
    name: 'locations_geocommunes',
  },
  locations_geocommunes_geocommune: {
    params: ['geocommune'],
    route: (geocommune: Geocommune) => ['/', RS.locations, RS.geocommunes, geocommune.id],
    name: 'locations_geocommunes_geocommune',
  },
  locations_geocountries: {
    params: [],
    route: () => ['/', RS.locations, RS.geocountries],
    name: 'locations_geocountries',
  },
  locations_geocountries_geocountry: {
    params: ['geocountry'],
    route: (geocountry: Geocountry) => ['/', RS.locations, RS.geocountries, geocountry.id],
    name: 'locations_geocountries_geocountry',
  },
  locations_geodepartements: {
    params: [],
    route: () => ['/', RS.locations, RS.geodepartements],
    name: 'locations_geodepartements',
  },
  locations_geodepartements_geodepartement: {
    params: ['geodepartement'],
    route: (geodepartement: Geodepartement) => ['/', RS.locations, RS.geodepartements, geodepartement.id],
    name: 'locations_geodepartements_geodepartement',
  },
  locations_georegions: {
    params: [],
    route: () => ['/', RS.locations, RS.georegions],
    name: 'locations_georegions',
  },
  locations_georegions_georegion: {
    params: ['georegion'],
    route: (georegion: Georegion) => ['/', RS.locations, RS.georegions, georegion.id],
    name: 'locations_georegions_georegion',
  },
  locations_locations: {
    params: [],
    route: () => ['/', RS.locations, RS.locations],
    name: 'locations_locations',
  },
  locations_locations_location: {
    params: ['location'],
    route: (location: Location) => ['/', RS.locations, RS.locations, location.id],
    name: 'locations_locations_location',
  },
  locations_usermaps: {
    params: [],
    route: () => ['/', RS.locations, RS.usermaps],
    name: 'locations_usermaps',
  },
  locations_usermaps_usermap: {
    params: ['usermap'],
    route: (usermap: UserMap) => ['/', RS.locations, RS.usermaps, usermap.id],
    name: 'locations_usermaps_usermap',
  },
};
