import { Tarif } from '../../tarif/tarif';
import { CustomField } from '@solidev/data';
import { TARIFTYPE_REGION_MODE, TARIFTYPE_STORAGE_MODE } from '../../tariftype/tariftype.base';
import { MemberBase } from '../../../structures/member/member.base';
import { Storage } from '../../../structures/storage/storage';
import { TARIF_STATUS, TarifBase } from '../../tarif/tarif.base';
import { TarifStorageStatusBase } from '../../tarif-storage-status/tarif-storage-status.base';
import { LIFECYCLE_SUB_STEP } from '../../tarif/lifecycle/lifecycle-steps';
import { TariftypeRelationType } from '../../tariftype-relation/tariftype-relation.base';
import { Genericregion } from '../../../locations/genericregion/genericregion';
import { TarifRegionStatusBase } from '../../tarif-region-status/tarif-region-status.base';

export interface AtStorageColumn extends CustomField {
  storage: Storage;
  member: MemberBase;
  mode: 'price' | 'dispo' | 'dispoprice';
  status?: TarifStorageStatusBase;
  editable: boolean;
  default: boolean;
}

export interface AtRegionColumn extends CustomField {
  region: Genericregion;
  mode: 'price' | 'dispo' | 'dispoprice';
  status?: TarifRegionStatusBase;
  editable: boolean;
  default: boolean;
}

export interface AtReferenceColumn extends CustomField {
  storage: Storage;
  member: MemberBase;
  mode: 'price' | 'dispo' | 'dispoprice';
  editable: boolean;
  default: boolean;
}

export interface AtRelatedColumn extends CustomField {
  storage?: Storage;
  member?: MemberBase;
  tarif: TarifBase;
  mode: 'price' | 'dispo' | 'dispoprice';
  editable: boolean;
  default: boolean;
}

// TODO: sort columns by member
// TODO: sort editable columns first

export class ArticleTarifColumns {
  public storage_columns: AtStorageColumn[] = [];
  public region_columns: AtRegionColumn[] = [];
  public reference_columns: CustomField[] = [];
  public related_columns: AtRelatedColumn[] = [];
  public decondi_columns: CustomField[] = [];
  private _storages = new Map<number, any>();
  private _regions = new Map<number, any>();
  private _ststatuses = new Map<number, TarifStorageStatusBase>();
  private _rgstatuses = new Map<number, TarifRegionStatusBase>();

  constructor(private tarif: Tarif | undefined) {
    if (tarif) {
      this.init(tarif);
    }
  }

  public get priceMode(): 'price' | 'dispo' | 'dispoprice' {
    if (!this.tarif) {
      return 'price';
    }
    if (!this.tarif.tariftype_details) {
      return 'price';
    }
    switch (this.tarif.tariftype_details.storages_mode) {
      case TARIFTYPE_STORAGE_MODE.DISPO_PER_STORAGE:
        return 'dispo';
      case TARIFTYPE_STORAGE_MODE.PRICE_PER_STORAGE:
        return 'price';
      case TARIFTYPE_STORAGE_MODE.PRICE_DISPO_STORAGE:
        return 'dispoprice';
    }
    switch (this.tarif.tariftype_details.regions_mode) {
      case TARIFTYPE_REGION_MODE.DISPO_PER_REGION:
        return 'dispo';
      case TARIFTYPE_REGION_MODE.PRICE_PER_REGION:
        return 'price';
      case TARIFTYPE_REGION_MODE.PRICE_DISPO_REGION:
        return 'dispoprice';
    }
    return 'price';
  }

  public get mainPriceEditable(): boolean {
    if (!this.tarif) {
      return false;
    }
    if (!this.tarif.tariftype_details) {
      return false;
    }
    switch (this.tarif.tariftype_details.storages_mode) {
      case TARIFTYPE_STORAGE_MODE.DISPO_PER_STORAGE:
        return false;
      case TARIFTYPE_STORAGE_MODE.PRICE_PER_STORAGE:
        return false;
      case TARIFTYPE_STORAGE_MODE.PRICE_DISPO_STORAGE:
        return this.isGloballyEditable;
    }
    switch (this.tarif.tariftype_details.regions_mode) {
      case TARIFTYPE_REGION_MODE.DISPO_PER_REGION:
        return false;
      case TARIFTYPE_REGION_MODE.PRICE_PER_REGION:
        return false;
      case TARIFTYPE_REGION_MODE.PRICE_DISPO_REGION:
        return this.isGloballyEditable;
    }
    return (
      this.tarif.tariftype_details.storages_mode === TARIFTYPE_STORAGE_MODE.SIMPLE &&
      this.tarif.tariftype_details.regions_mode === TARIFTYPE_REGION_MODE.SIMPLE &&
      this.isGloballyEditable
    );
  }

  public get isGloballyEditable(): boolean {
    if (!this.tarif) {
      return false;
    }
    if (!this.tarif.tariftype_details) {
      return false;
    }
    return (
      this.tarif.status == TARIF_STATUS.PREPARATION || this.tarif.tstep_details?.step === LIFECYCLE_SUB_STEP.EDITION
    );
  }

  public init(tarif: Tarif) {
    this.tarif = tarif;
    this.setStorageColumns();
    this.setRegionColumns();
    this.updateStorageStatus();
    this.updateRegionStatus();
    this.setRelatedColumns();
  }

  public updateStorageStatus(st?: TarifStorageStatusBase[]) {
    if (!st) {
      st = this.tarif?.tarifstoragestatus_details;
    }
    if (st) {
      this._ststatuses = new Map(st.map((v) => [v.storage, v]));
    }
    for (const col of this.storage_columns) {
      const stt = this._ststatuses.get(col.storage.id);
      col.status = stt;
    }
  }

  public updateRegionStatus(st?: TarifRegionStatusBase[]) {
    if (!st) {
      st = this.tarif?.tarifregionstatus_details;
    }
    if (st) {
      this._rgstatuses = new Map(st.map((v) => [v.region, v]));
    }
    for (const col of this.region_columns) {
      const stt = this._rgstatuses.get(col.region.id);
      col.status = stt;
    }
  }

  private setStorageColumns() {
    if (!this.tarif || !this.tarif.tstorages_details) {
      return;
    }
    const editableMembers = new Map<number, number>();
    const cols: AtStorageColumn[] = [];
    for (const s of this.tarif.tstorages_details) {
      this._storages.set(s.storage, s);
      cols.push({
        name: `storage_${s.storage}`,
        label: `Dépôt : ${s.storage_details!.name} (${s.storage_details!.member_details!.name})`,
        storage: s.storage_details!,
        member: s.storage_details!.member_details!,
        mode: this.priceMode,
        editable: s.have_level('write'),
        default: false,
        priority: 100,
      });
      if (s.have_level('write')) {
        editableMembers.set(s.storage_details!.member_details!.id, 1);
      }
    }
    cols.sort((a, b) => {
      return (
        (a.editable ? 0 : 1) - (b.editable ? 0 : 1) ||
        (editableMembers.has(a.member.id) ? 0 : 1) - (editableMembers.has(b.member.id) ? 0 : 1) ||
        a.member.name.localeCompare(b.member.name) ||
        a.storage.name.localeCompare(b.storage.name)
      );
    });
    if (cols.length > 15) {
      for (const c of cols) {
        c.priority = 100;
        if (editableMembers.has(c.member.id)) {
          c.default = true;
        }
        this.storage_columns.push(c);
      }
    } else {
      for (const c of cols) {
        c.priority = 100;
        c.default = true;
        this.storage_columns.push(c);
      }
    }
  }

  private setRegionColumns() {
    if (!this.tarif || !this.tarif.tregions_details) {
      return;
    }
    const cols: AtRegionColumn[] = [];
    for (const s of this.tarif.tregions_details) {
      this._regions.set(s.region, s);
      cols.push({
        name: `region_${s.region}`,
        label: `Région : ${s.region_details!.name} (${s.region_details!.client_details!.name})`,
        region: s.region_details!,
        mode: this.priceMode,
        editable: s.have_level('write'),
        default: false,
        priority: 100,
      });
    }
    cols.sort((a, b) => {
      return (a.editable ? 0 : 1) - (b.editable ? 0 : 1) || a.region.name.localeCompare(b.region.name);
    });
    for (const c of cols) {
      c.priority = 100;
      c.default = true;
      this.region_columns.push(c);
    }
  }

  private setRelatedColumns() {
    if (!this.tarif || !this.tarif.destrelations_details) {
      return;
    }
    const cols: AtRelatedColumn[] = [];
    for (const r of this.tarif.destrelations_details) {
      if (r.relation_details?.type === TariftypeRelationType.REFERENCE) {
        cols.push({
          name: `related_${r.src_details!.id}`,
          label: `${r.src_details!.name}`,
          mode: 'price',
          tarif: r.src_details!,
          editable: false,
          default: true,
          priority: 150,
        });
      }
    }
    for (const c of cols) {
      this.related_columns.push(c);
    }
  }
}
