import { PrintSettings } from "../../layouts/print-settings";

export const ARTICLE_PRINT_SETTINGS: PrintSettings = [
  {
    label: "Fiche article",
    type: "single",
    provider: "article_with_nutri",
    limit: 100,
    actions: ["quick_print", "add_to_print"],
    template: "article_detail",
  },
  {
    label: "Fiche article",
    type: "selection",
    provider: "articles_with_nutri",
    limit: 100,
    actions: ["quick_print", "add_to_print"],
    template: "article_detail",
  },
  {
    label: "Fiche article",
    type: "single",
    provider: "article_with_nutri",
    limit: 1,
    actions: ["button"],
    template: "article_detail",
  },
  {
    label: "Fiche article",
    type: "queryset",
    provider: "articles_with_nutri_queryset",
    limit: 100,
    actions: ["quick_print", "add_to_print"],
    template: "article_detail",
  },
  {
    label: "Liste articles",
    type: "queryset",
    provider: "articles_queryset",
    limit: 1500,
    actions: ["quick_print", "add_to_print"],
    template: "article_list",
  },
  {
    label: "Liste articles",
    provider: "articles",
    limit: 100,
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "article_list",
  },
];
