import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

import { map, Observable } from "rxjs";
import { FilterDefaults, Link, RouteConfigItem } from "@solidev/data";
import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { TariftypeListComponent } from "../../../../../models/tarifs/tariftype/tariftype-list/tariftype-list.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { TariftypeBase } from "../../../../../models/tarifs/tariftype/tariftype.base";
import { GroupBase } from "../../../../../models/users/group/group.base";
import { UserBase } from "../../../../../models/users/user/user.base";
import { ClientBase } from "../../../../../models/structures/client/client.base";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";

export interface ArticleTarifsViewParams extends ArticleViewParams {
  tariftype_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
}

export interface ArticleTarifsViewData
  extends ArticleTarifsViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-tarifs-view",
  standalone: true,
  imports: [
    CommonModule,
    TariftypeListComponent,
    ArticleNavComponent,
    HeaderActionComponent,
  ],
  templateUrl: "./article-tarifs-view.component.pug",
  styleUrls: ["./article-tarifs-view.component.sass"],
})
export class ArticleTarifsViewComponent extends BaseDetailViewComponent<
  ArticleTarifsViewData,
  Article
> {
  public articleTarifFilter$!: Observable<FilterDefaults>;
  public tariftype_detail_route!: Link<TariftypeBase>;
  public group_detail_route!: Link<GroupBase>;
  public user_detail_route!: Link<UserBase>;
  public client_detail_route!: Link<ClientBase>;
  public default_fields = [
    "id",
    "code",
    "name",
    "client_details",
    "base_interval",
    "lifecycle",
    "segment",
    "flags",
    "status",
  ];

  public override postNgOnInit() {
    super.postNgOnInit();
    this.articleTarifFilter$ = this.data$.pipe(
      map((data) => ({ article: data.article.id })),
    );
  }

  public override setRouteData(data: ArticleTarifsViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(
      data.tariftype_detail_route,
      data,
    );
    this.group_detail_route = new Link<GroupBase>(
      data.group_detail_route,
      data,
    );
    this.user_detail_route = new Link<UserBase>(data.user_detail_route, data);
    this.client_detail_route = new Link<ClientBase>(
      data.client_detail_route,
      data,
    );
  }
}
