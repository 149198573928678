
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifNotificationStatus } from '../tarif-notification-status';
@Component({
  selector: 'lvadg-tarif-notification-status-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-notification-status-manage.component.pug',
  styleUrls: ['./tarif-notification-status-manage.component.sass']
})
export class TarifNotificationStatusManageComponent implements OnInit {
  @Input() public tarifnotificationstatus?: TarifNotificationStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
