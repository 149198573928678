import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from "@solidev/data";

export enum OFFERNOTIFICATIONSTATUS_STATUSES {
  PENDING = "PEN",
  TOSEND = "SND",
  SEND_PREPARE = "SNP",
  SEND_QUEUE = "SNQ",
  SENDING = "SNI",
  SENT = "SNT",
  OPENED = "OPN",
  CLICKED = "CLK",
  SKIP = "SKP",
  TEMPORARY_ERROR = "ATT",
  FATAL_ERROR = "FAT",
  INVALID = "NOK",
}

export const OFFERNOTIFICATIONSTATUS_STATUSES_CHOICES = [
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.PENDING,
    desc: "En attente",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.TOSEND,
    desc: "A envoyer",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.SEND_PREPARE,
    desc: "Préparation envoi",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.SEND_QUEUE,
    desc: "Dans la file d'envoi",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.SENDING,
    desc: "En cours d'envoi",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.SENT,
    desc: "Envoyé",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.OPENED,
    desc: "Ouvert",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.CLICKED,
    desc: "Cliqué",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.SKIP,
    desc: "Ne pas envoyer",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.TEMPORARY_ERROR,
    desc: "Erreur temporaire, en attente nouvel essai",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.FATAL_ERROR,
    desc: "Erreur fatale, échec envoi",
  },
  {
    value: OFFERNOTIFICATIONSTATUS_STATUSES.INVALID,
    desc: "Invalide",
  },
];

export class OfferNotificationStatusBase extends DataModel {
  static override readonly __name: string = "OfferNotificationStatusBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 150,
  })
  public updated!: Date;

  @foreignKeyField({
    name: "notification",
    description: "ID Notification",
    related: "OfferNotification",
    priority: -1,
  })
  public notification!: number;

  @foreignKeyField({
    name: "destination",
    description: "ID Destination",
    related: "OfferNotificationDestination",
    priority: -1,
  })
  public destination!: number;

  @foreignKeyField({
    name: "user",
    description: "ID Utilisateur",
    related: "User",
    priority: -1,
  })
  public user!: number;

  @charField({
    name: "error_zone",
    description: "Zone erreur",
    maxLength: 300,
    priority: 200,
    readonly: true,
  })
  public error_zone?: string;

  @charField({
    name: "error_source",
    description: "Source erreur",
    maxLength: 300,
    priority: 250,
    readonly: true,
  })
  public error_source?: string;

  @textField({
    name: "error_message",
    description: "Message erreur",
    maxLength: 300,
    priority: 250,
    readonly: true,
  })
  public error_message?: string;

  @charField({
    name: "status",
    description: "État",
    defaultValue: OFFERNOTIFICATIONSTATUS_STATUSES.PENDING,
    maxLength: 3,
    choices: OFFERNOTIFICATIONSTATUS_STATUSES_CHOICES,
    priority: 300,
  })
  public status: OFFERNOTIFICATIONSTATUS_STATUSES =
    OFFERNOTIFICATIONSTATUS_STATUSES.PENDING;

  @charField({
    name: "sref",
    description: "Code envoi",
    maxLength: 64,
    priority: -1,
  })
  public sref!: string;

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -1,
  })
  public orig_id!: number;
}
