import { Component, Input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Provider } from "../provider";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LocationManageComponent } from "../../../locations/location/location-manage/location-manage.component";
import { LocationCreateComponent } from "../../../locations/location/location-create/location-create.component";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { LOCATION_TYPE } from "../../../locations/location/location.base";
import { RI } from "../../../../constants";
import { ProviderActionService } from "../provider-action.service";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { LabelsManagerComponent } from "../../../catalog/label/labels-manager/labels-manager.component";

@Component({
  selector: "lvadg-provider-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    IconComponent,
    LocationManageComponent,
    LocationCreateComponent,
    LogoManageComponent,
    GalleryManageComponent,
    LabelsManagerComponent,
  ],
  templateUrl: "./provider-manage.component.pug",
  styleUrls: ["./provider-manage.component.sass"],
})
export class ProviderManageComponent {
  @Input() public provider!: Provider;
  public RI = RI;
  public LBT = LABEL_TYPE;
  public LT = LOCATION_TYPE;
  protected allow_labels_edition = signal(false);

  constructor(public pact: ProviderActionService) {}
}
