import { Injectable } from "@angular/core";
import { Offer } from "./offer";
import { OpLog } from "../../../components/utils/oplog/oplog";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";
import { OffertypeActionResult } from "../offertype/offertype-action.service";
import { OfferActionResult } from "./offer-action.service";
import { OfferService } from "./offer.service";
import { OFFER_TYPES, OFFER_ZONES } from "./offer.base";

interface OffersCreateOffer {
  action: "create_offer";
  datestart: string | Date;
  dateend: string | Date;
  storage: number; // pk du dépôt
  client?: number; // pk du client
  zone?: OFFER_ZONES; // zone de l'offre
  type?: OFFER_TYPES; // type de l'offre
  name?: string; // nom de la nouvelle offre
  last?: number; // pk de l'offre source (pour copie)
}

export type OffersActionParams = OffersCreateOffer;

export interface OffersActionResult<T> {
  offer?: Offer;
  log?: OpLog;
  data?: T;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: "root",
})
export class OffersActionService {
  constructor(
    private _msgs: DataMessageService,
    private _os: OfferService,
  ) {}

  public async action<T>(
    action: OffersActionParams,
    messages: Record<string, string> = {},
  ): Promise<OfferActionResult<T>> {
    try {
      const res = await firstValueFrom(
        this._os.action<OffertypeActionResult<T>>(null, "POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { message: message, success: false };
    }
  }
}
