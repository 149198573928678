
<ng-container *ngIf="data$|async; let data">
  <lvadg-offertype-nav [routes]="data.ot_menu" [offertype]="data.offertype" *ngIf="data.ot_menu &amp;&amp; data.offertype"></lvadg-offertype-nav>
  <lvadg-header-action [title]="data.offertype?._display" level="h1" subtitle="Dépôts du modèle" ri="offer">
    <ng-container actions>
      <button class="btn btn-primary btn-sm" (click)="create.set(!create())">
        <lvadg-icon ri="add">Ajouter un dépot</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="create()">
    <div class="card-header">
      <lvadg-icon ri="add">Ajouter un dépot</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-offertype-storage-create [offertype]="data.offertype||null" (created)="reload$.next(true); detail_route.navigate({offertypestorage: $event}).then(); create.set(false);" (cancelled)="create.set(false)"></lvadg-offertype-storage-create>
    </div>
  </div>
  <lvadg-offertype-storage-list [name]="data.route.name+&quot;-&quot;+data.offertype?.code" [detail_route]="offertypestorage_detail_route" [filter]="offertypeFilter$" [default_fields]="offertypestorages_default_fields" [offertype_detail_route]="offertype_detail_route" [tariftype_detail_route]="tariftype_detail_route" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [ots_permission_detail_route]="ots_permission_detail_route" [actions]="['delete', 'permissions']" [reload]="reload$"></lvadg-offertype-storage-list>
</ng-container>