import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Geocountry } from '../geocountry';
import { GeocountryBase } from '../geocountry.base';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-geocountry-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './geocountry-display.component.pug',
  styleUrls: ['./geocountry-display.component.sass'],
})
export class GeocountryDisplayComponent {
  @Input() public geocountry?: Geocountry;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<GeocountryBase>;
}
