import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Label } from '../label';
import { DispeditComponent } from '@solidev/data';
import { LogoManageComponent } from '../../../documents/image/logo-manage/logo-manage.component';

@Component({
  selector: 'lvadg-label-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, LogoManageComponent],
  templateUrl: './label-manage.component.pug',
  styleUrls: ['./label-manage.component.sass'],
})
export class LabelManageComponent implements OnInit {
  @Input() public label?: Label;

  constructor() {}

  ngOnInit(): void {}
}
