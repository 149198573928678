import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { Subject } from 'rxjs';
import { lifecycleNav, TarifLifecycleAction } from '../../../lifecycle-actions';
import { TarifLifecycleActionService } from '../../../tarif-lifecycle-action.service';
import { TarifImportGenericPricesComponent } from '../../../../tarif-import/tarif-import-generic-prices/tarif-import-generic-prices.component';
import { Tariftype } from '../../../../../tariftype/tariftype';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TarifImportCustomArticlesComponent } from '../../../../tarif-import/tarif-import-custom-articles/tarif-import-custom-articles.component';
import { TarifImportCustomPricesComponent } from '../../../../tarif-import/tarif-import-custom-prices/tarif-import-custom-prices.component';
import { TarifExportCustomPricesComponent } from '../../../../tarif-export/tarif-export-custom-prices/tarif-export-custom-prices.component';
import { TARIF_ROUTES } from '../../../../../../../views/tarifs/tarifs/detail/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-negoupdate',
  standalone: true,
  imports: [
    CommonModule,
    TarifImportGenericPricesComponent,
    TarifImportCustomArticlesComponent,
    TarifImportCustomPricesComponent,
    TarifExportCustomPricesComponent,
  ],
  templateUrl: './tarif-lifecycle-step-negoupdate.component.pug',
  styleUrls: ['./tarif-lifecycle-step-negoupdate.component.sass'],
})
export class TarifLifecycleStepNegoupdateComponent {
  @Input() public tarif!: Tarif;
  @Input() public tariftype!: Tariftype;

  @Input() public step!: TarifLifecycleStep;
  @Input() public steps!: TarifLifecycleStep[];
  @Input() public menu?: TARIF_ROUTES;
  @Input() public reload$!: Subject<void | boolean>;
  @ViewChild('specialop', { static: true })
  public ofcSlot!: ElementRef<HTMLDivElement>;

  public running: Record<string, boolean> = {};

  constructor(
    public taction: TarifLifecycleActionService,
    private router: Router,
    private ofc: NgbOffcanvas,
  ) {}

  public async action(action: TarifLifecycleAction) {
    if (action.action === 'nav') {
      await lifecycleNav(action.dest, this.menu, this.router, this.tarif, this.tariftype);
    } else {
      this.running[action.action] = true;
      const res = await this.taction.action(this.tarif, action);
      this.reload$.next(true);
      this.running[action.action] = false;
    }
  }

  public ofcOp(operation: string) {
    this.running[operation] = true;
    this.ofc.open(this.ofcSlot, { position: 'end', panelClass: 'w-50' }).result.then(
      () => {
        this.running[operation] = false;
      },
      () => {
        this.running[operation] = false;
      },
    );
  }
}
