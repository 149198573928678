
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-cdata-key-choice-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdata-key-choice-create.component.pug',
  styleUrls: ['./cdata-key-choice-create.component.sass']
})
export class CdataKeyChoiceCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
