import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelBase } from '../label.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { LabelService } from '../label.service';

@Component({
  selector: 'lvadg-label-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './label-create.component.pug',
  styleUrls: ['./label-create.component.sass'],
})
export class LabelCreateComponent implements OnInit {
  public label!: LabelBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<LabelBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _labels: LabelService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.label = new LabelBase(this._labels);
  }

  public async save() {
    this.label.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.label.save({ updateModel: true }));
      this.created.emit(this.label);
      this._msgs.success('Label créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
