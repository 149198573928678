
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-rnmtarif-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmtarif-create.component.pug',
  styleUrls: ['./rnmtarif-create.component.sass']
})
export class RnmtarifCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
