import { ClientBase } from './client.base';
import { detailsField } from '@solidev/data';
import { LocationBase } from '../../locations/location/location.base';
import { Image } from '../../documents/image/image';
import { Label } from '../../catalog/label/label';

export class Client extends ClientBase {
  static override readonly __name: string = 'Client';
  @detailsField({
    name: 'location_details',
    description: 'Adresse',
    readonly: true,
    model: LocationBase,
  })
  public location_details?: LocationBase;

  @detailsField({
    name: 'logo_details',
    description: 'Logo',
    readonly: true,
    model: Image,
  })
  public logo_details?: Image;

  @detailsField({
    name: 'images_details',
    description: 'Images',
    readonly: true,
    model: Image,
    many: true,
  })
  public images_details?: Image[];

  @detailsField({
    name: 'slabels_details',
    description: 'Labels',
    readonly: true,
    model: Label,
    many: true,
  })
  public slabels_details: Label[] = [];
}
