import { charField, DataModel, datetimeField, detailsField, foreignKeyField, primaryField } from '@solidev/data';

export enum PRINT_STATUS {
  PRP = 'PRP',
  ARC = 'ARC',
}

export class PrintBase extends DataModel {
  static override readonly __name: string = 'PrintBase';

  @primaryField({ name: 'id', description: 'ID Impresssion', priority: 1000 })
  public override id!: number;

  @charField({ name: 'name', description: "Nom de l'impression", maxLength: 200, priority: 900 })
  public name!: string;

  @foreignKeyField({
    name: 'owner',
    related: 'User',
    description: 'Id utilisateur',
    priority: -100,
  })
  public owner!: number;

  @detailsField({ name: 'flags', description: 'Flags', defaultValue: [], priority: 250 })
  public flags: string[] = [];

  @datetimeField({ name: 'created', description: 'Date de création', readonly: true, priority: 50 })
  public created!: Date;

  @datetimeField({ name: 'updated', description: 'Date de MAJ', readonly: true, priority: 100 })
  public updated!: Date;

  @charField({
    name: 'status',
    description: 'Etat',
    maxLength: 3,
    defaultValue: PRINT_STATUS.PRP,
    choices: [
      { value: PRINT_STATUS.PRP, desc: 'En préparation' },
      { value: PRINT_STATUS.ARC, desc: 'Archivée' },
    ],
    priority: 200,
  })
  public status: string = 'PRP';
}
