import { VivalyaBillItemBase } from './vivalya-bill-item.base';
import { computedField, detailsField } from '@solidev/data';
import { VivalyaBill } from '../vivalya-bill/vivalya-bill';
import { Article } from '../../catalog/article/article';
import { Earticle } from '../../catalog/earticle/earticle';
import { Storage } from '../../structures/storage/storage';
import { Client } from '../../structures/client/client';
import { Member } from '../../structures/member/member';
import { Resto } from '../../structures/resto/resto';

export class VivalyaBillItem extends VivalyaBillItemBase {
  static override readonly __name: string = 'VivalyaBillItem';

  @detailsField({
    name: 'bill_details',
    description: 'Facture',
    model: VivalyaBill,
    readonly: true,
    priority: 950,
  })
  public bill_details?: VivalyaBill;

  @detailsField({
    name: 'article_details',
    description: 'Article',
    model: Article,
    readonly: true,
    priority: 880,
  })
  public article_details?: Article;
  @detailsField({
    name: 'adh_article_details',
    description: 'Article adhérent',
    model: Earticle,
    readonly: true,
    priority: 870,
  })
  public adh_article_details?: Earticle;
  @detailsField({
    name: 'gc_article_details',
    description: 'Article grand compte',
    model: Earticle,
    readonly: true,
    priority: 790,
  })
  public gc_article_details?: Earticle;

  @computedField({
    name: 'storage_details',
    description: 'Dépôt',
    priority: 890,
  })
  public get storage_details(): Storage | undefined {
    return this.bill_details?.storage_details;
  }

  @computedField({
    name: 'client_details',
    description: 'Client',
    priority: 889,
  })
  public get client_details(): Client | undefined {
    return this.bill_details?.client_details;
  }

  @computedField({
    name: 'member_details',
    description: 'Adhérent',
    priority: 891,
  })
  public get member_details(): Member | undefined {
    return this.bill_details?.storage_details?.member_details;
  }

  @computedField({
    name: 'resto_details',
    description: 'Resto',
    priority: 885,
  })
  public get resto_details(): Resto | undefined {
    return this.bill_details?.resto_details;
  }
}
