import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Group } from '../group';
import { MemberService } from '../../../structures/member/member.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { ClientService } from '../../../structures/client/client.service';
import { RestoService } from '../../../structures/resto/resto.service';
import { TariftypeService } from '../../../tarifs/tariftype/tariftype.service';
import { OrderprocessService } from '../../../orders/orderprocess/orderprocess.service';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-group-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './group-manage.component.pug',
  styleUrls: ['./group-manage.component.sass'],
})
export class GroupManageComponent implements OnInit {
  @Input() public group?: Group;

  constructor(
    public members$: MemberService,
    public storages$: StorageService,
    public clients$: ClientService,
    public restos$: RestoService,
    public tariftypes$: TariftypeService,
    public orderprocesses$: OrderprocessService
  ) {}

  ngOnInit(): void {}
}
