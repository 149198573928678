import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';
import { AllergenBase } from '../allergen.base';
import { AllergenService } from '../allergen.service';

@Component({
  selector: 'lvadg-allergen-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './allergen-create.component.pug',
  styleUrls: ['./allergen-create.component.sass'],
})
export class AllergenCreateComponent implements OnInit {
  public allergen!: AllergenBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<AllergenBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _allergens: AllergenService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.allergen = new AllergenBase(this._allergens);
  }

  public async save() {
    this.allergen.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.allergen.save({ updateModel: true }));
      this.created.emit(this.allergen);
      this._msgs.success('Allergène créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
