
<ng-container *ngIf="data$|async; let data">
  <lvadg-article-nav [routes]="data.a_menu" [article]="data.article"></lvadg-article-nav>
  <lvadg-header-action level="h1" ri="catalog" [title]="data.article.libelle" subtitle="Détails article">
    <ng-container actions>
      <lvadg-iocode-button class="me-3" [model]="data.article">
        <lvadg-icon class="fs-3 px-1" role="button" ri="iocode" [icon_only]="true"></lvadg-icon>
      </lvadg-iocode-button>
      <lvadg-quickprint-button [model]="data.article" [print_settings]="print_settings" [print_context]="print_context" [template]="'article_detail'" [title]="'Impression fiche article'">
        <lvadg-icon class="fs-3 px-1" ri="fiche" [icon_only]="true"></lvadg-icon>
      </lvadg-quickprint-button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-article-detail [article]="data.article"></lvadg-article-detail>
</ng-container>