
import {Component} from '@angular/core';
import {ModellistComponent} from "../../../../includes/modellist/modellist.component";
import { CdataKeyChoice } from "../cdata-key-choice";
import { CdataKeyChoiceService} from "../cdata-key-choice.service";
import {FiltersParams, ModelListService, ModelListTextFilter} from "@solidev/data";
import {MODELLIST_IMPORTS} from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-cdata-key-choice-list',
  standalone: true,
  templateUrl: './cdata-key-choice-list.component.pug',
  styleUrls: ['./cdata-key-choice-list.component.sass'],
  imports: [...MODELLIST_IMPORTS]
})
export class CdataKeyChoiceListComponent extends ModellistComponent<CdataKeyChoice> {
  constructor(coll: CdataKeyChoiceService,
              list: ModelListService,
              ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte"
        }),
      ]
    };
  }
}
