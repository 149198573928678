import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { Rnm } from './rnm';

@Injectable({
  providedIn: 'root',
})
export class RnmService extends Collection<Rnm> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/rnms', Rnm);
  }
}
