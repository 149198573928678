import { TarifNotificationStatusBase } from './tarif-notification-status.base';
import { detailsField } from '@solidev/data';
import { TarifNotification } from '../tarif-notification/tarif-notification';
import { User } from '../../users/user/user';

export class TarifNotificationStatus extends TarifNotificationStatusBase {
  static override readonly __name: string = 'TarifNotificationStatus';
  @detailsField({
    model: TarifNotification,
    description: 'Notification',
    readonly: true,
  })
  public notification_details?: TarifNotification;

  @detailsField({
    model: User,
    description: 'Destinataire (lvadg)',
    readonly: true,
  })
  public user_details?: User;
}
