import { Component, computed, inject, input, signal } from "@angular/core";
import { Producer } from "../producer";
import { StorageService } from "../../storage/storage.service";
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from "@angular/core/rxjs-interop";
import { Storage } from "../../storage/storage";
import { ProducerSpecificActionService } from "../producer-action.service";
import { ProducerService } from "../producer.service";
import { combineLatest, Subject, switchMap, tap } from "rxjs";
import { DataMessageService } from "@solidev/data";

interface AssociatedStorage {
  storage: Storage;
  associated: boolean;
}

@Component({
  selector: "lvadg-producer-storages-manage",
  standalone: true,
  imports: [],
  template: `
    @for (r of storages(); track r.storage.id) {
      @if (r.associated) {
        <button class="btn btn-primary my-2 me-2" (click)="disable(r.storage)">
          {{ r.storage.name }}
        </button>
      } @else {
        <button
          class="btn btn-outline-secondary my-2 me-2"
          (click)="enable(r.storage)"
        >
          {{ r.storage.name }}
        </button>
      }
    }
  `,
  styleUrl: "./producer-storages-manage.component.sass",
})
export class ProducerStoragesManageComponent {
  public producer = input.required<Producer>();
  public editable = input<boolean>(false);
  public prelations = signal<Producer | null>(null);
  public refresh = new Subject<boolean>();
  private _producers = inject(ProducerService);
  private _storages = toSignal(inject(StorageService).list());
  public storages = computed(() => {
    const producer = this.prelations();
    if (!producer) {
      return [];
    }
    const storages = this._storages();
    const relations: AssociatedStorage[] = [];
    for (const s of storages || []) {
      relations.push({
        storage: s,
        associated:
          producer.storage_relations_details?.find(
            (r) => r.storage_details?.id === s.id,
          ) !== undefined,
      });
    }
    return relations;
  });
  private _msgs = inject(DataMessageService);
  private _pact = inject(ProducerSpecificActionService);

  constructor() {
    combineLatest([this.refresh, toObservable(this.producer)])
      .pipe(
        takeUntilDestroyed(),
        switchMap(([, p]) => {
          return this._producers.fetch(p.id, {
            params: {
              fields: ["id", "name", "storage_relations_details"].join(","),
            },
          });
        }),
        tap((p: Producer) => {
          this.prelations.set(p);
        }),
      )
      .subscribe();
    this.refresh.next(true);
  }

  protected async disable(storage: Storage) {
    if (!this.editable()) {
      this._msgs.warning("Édition désactivée");
      return;
    }
    await this._pact.action(this.producer(), {
      action: "remove_storage",
      storage: storage.id,
    });
    this.refresh.next(true);
  }

  protected async enable(storage: Storage) {
    if (!this.editable()) {
      this._msgs.warning("Édition désactivée");
      return;
    }
    await this._pact.action(this.producer(), {
      action: "add_storage",
      storage: storage.id,
    });
    this.refresh.next(true);
  }
}
