import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '../../../../../models/structures/storage/storage';
import { STORAGE_ROUTES } from '../menu';
import { map, Observable, Subject } from 'rxjs';
import { ProducerBase } from '../../../../../models/structures/producer/producer.base';
import { ProviderBase } from '../../../../../models/structures/provider/provider.base';
import { StorageBase } from '../../../../../models/structures/storage/storage.base';
import { ClientBase } from '../../../../../models/structures/client/client.base';
import { RestoBase } from '../../../../../models/structures/resto/resto.base';
import { RelationBase } from '../../../../../models/structures/relation/relation.base';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { RelationListComponent } from '../../../../../models/structures/relation/relation-list/relation-list.component';
import { StorageNavComponent } from '../_nav/storage-nav.component';
import { RelationCreateComponent } from '../../../../../models/structures/relation/relation-create/relation-create.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';

export interface StorageRelationsViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
  producer_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  relation_detail_route?: RouteConfigItem;
}

export interface StorageRelationsViewData extends StorageRelationsViewParams {
  storage: Storage;
}

@Component({
  selector: 'lvadg-storage-relations-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    RelationListComponent,
    RelationCreateComponent,
    StorageNavComponent,
  ],
  templateUrl: './storage-relations-view.component.pug',
  styleUrls: ['./storage-relations-view.component.sass'],
})
export class StorageRelationsViewComponent extends BaseDetailViewComponent<StorageRelationsViewData, Storage> {
  public storageFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public producer_detail_route?: Link<ProducerBase>;
  public provider_detail_route?: Link<ProviderBase>;
  public storage_detail_route?: Link<StorageBase>;
  public client_detail_route?: Link<ClientBase>;
  public resto_detail_route?: Link<RestoBase>;
  public relation_detail_route?: Link<RelationBase>;
  public default_fields: string[] = [];
  public createRelation = false;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageRelationsViewData) {
    super.setRouteData(data);
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(data.producer_detail_route, data, this._router);
    }
    if (data.provider_detail_route) {
      this.provider_detail_route = new Link(data.provider_detail_route, data, this._router);
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(data.storage_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link(data.client_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link(data.resto_detail_route, data, this._router);
    }
    if (data.relation_detail_route) {
      this.relation_detail_route = new Link(data.relation_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.storage.id,
      })),
    );
  }
}
