import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Order } from '../../../../models/orders/order/order';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';

export interface OrderDetailViewParams extends BaseRouteParams {
  ordergroup_detail_route: RouteConfigItem;
}

export interface OrderDetailViewData extends OrderDetailViewParams {
  order: Order;
  orderprocess?: Orderprocess;
}
