import { Component, EventEmitter, inject, input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  SUPPORT_MESSAGE_KIND,
  SUPPORT_MESSAGE_TYPE_CHOICES,
} from "../support-message.base";
import { AuthService } from "../../../users/auth.service";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { SupportMessage } from "../support-message";
import {
  SUPPORT_PROJECT_ID,
  SupportMessageService,
} from "../support-message.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "lvadg-support-message-create",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent],
  template: ` <form class="row g-1" [formGroup]="form" (submit)="create()">
    <div class="col-4">
      <label for="type">Type</label>
      <select
        class="form-select form-select-sm"
        id="type"
        formControlName="type"
      >
        @for (type of SUPPORT_MESSAGE_TYPE_CHOICES; track type.value) {
          <option [ngValue]="type.value">{{ type.desc }}</option>
        }
      </select>
    </div>
    <div class="col-8">
      <label for="title">Titre</label>
      <input
        class="form-control form-control-sm"
        id="title"
        formControlName="title"
      />
    </div>
    <div class="col-12">
      <label for="text">Texte</label>
      <textarea
        class="form-control form-control-sm font-monospace"
        id="text"
        rows="15"
        formControlName="text"
      ></textarea>
    </div>
    <div class="col-12">
      <button class="btn btn-primary btn-sm w-100" type="submit">
        <lvadg-icon ri="save">Créer le message</lvadg-icon>
      </button>
    </div>
  </form>`,
  styleUrls: ["./support-message-create.component.sass"],
})
export class SupportMessageCreateComponent {
  public zone = input<string>();
  public route = input<string>();
  public url = input<string>();
  public project_id =
    inject<number>(SUPPORT_PROJECT_ID, { optional: true }) || 1;
  public form = new FormGroup({
    type: new FormControl<SUPPORT_MESSAGE_KIND>(
      SUPPORT_MESSAGE_KIND.ENHANCEMENT,
      {
        nonNullable: true,
      },
    ),
    title: new FormControl<string>("", { nonNullable: true }),
    text: new FormControl<string>("", { nonNullable: true }),
  });
  @Output() public created = new EventEmitter<SupportMessage>();
  protected readonly SUPPORT_MESSAGE_TYPE_CHOICES =
    SUPPORT_MESSAGE_TYPE_CHOICES;
  private _auth = inject(AuthService);
  private _sms = inject(SupportMessageService);

  constructor() {}

  public async create() {
    const msg = this._sms.create({
      ...this.form.value,
      route: this.route(),
      zone: this.zone(),
      url: this.url(),
      project_id: this.project_id,
    });
    await firstValueFrom(msg.save({ updateModel: true }));
    this.created.emit(msg);
    this.form.reset();
  }
}
