import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Storage } from "../../../../../models/structures/storage/storage";
import { STORAGE_ROUTES } from "../menu";
import { map, Observable, Subject } from "rxjs";
import { Offer } from "../../../../../models/offers/offer/offer";
import { Client } from "../../../../../models/structures/client/client";
import { Resto } from "../../../../../models/structures/resto/resto";
import { User } from "../../../../../models/users/user/user";
import { Offertype } from "../../../../../models/offers/offertype/offertype";
import { OffertypeStorage } from "../../../../../models/offers/offertype-storage/offertype-storage";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { OfferListComponent } from "../../../../../models/offers/offer/offer-list/offer-list.component";
import { StorageNavComponent } from "../_nav/storage-nav.component";

export interface StorageOffersViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
  offer_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
}

export interface StorageOffersViewData extends StorageOffersViewParams {
  storage: Storage;
}

@Component({
  selector: "lvadg-storage-offers-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OfferListComponent,
    StorageNavComponent,
  ],
  templateUrl: "./storage-offers-view.component.pug",
  styleUrls: ["./storage-offers-view.component.sass"],
})
export class StorageOffersViewComponent extends BaseDetailViewComponent<
  StorageOffersViewData,
  Storage
> {
  public storageFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public default_fields: string[] = [
    "type",
    "title",
    "client_details",
    "datestart",
    "dateend",
    "author_details",
    "products_count",
    "offerrestos_details",
    "status",
  ];
  public offer_detail_route?: Link<Offer>;
  public client_detail_route?: Link<Client>;
  public storage_detail_route?: Link<Storage>;
  public resto_detail_route?: Link<Resto>;
  public user_detail_route?: Link<User>;
  public offertype_detail_route?: Link<Offertype>;
  public offertypestorage_detail_route?: Link<OffertypeStorage>;
  public offertypestorage_offer_detail_route?: Link<Offer>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageOffersViewData) {
    super.setRouteData(data);
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(
        data.client_detail_route,
        data,
        this._router,
      );
    }
    if (data.offer_detail_route) {
      this.offer_detail_route = new Link<Offer>(
        data.offer_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link<Storage>(
        data.storage_detail_route,
        data,
        this._router,
      );
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link<Resto>(
        data.resto_detail_route,
        data,
        this._router,
      );
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(
        data.user_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertype_detail_route) {
      this.offertype_detail_route = new Link<Offertype>(
        data.offertype_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertypestorage_detail_route) {
      this.offertypestorage_detail_route = new Link<OffertypeStorage>(
        data.offertypestorage_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertypestorage_offer_detail_route) {
      this.offertypestorage_offer_detail_route = new Link<Offer>(
        data.offertypestorage_offer_detail_route,
        data,
        this._router,
      );
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.storage.id,
      })),
    );
  }
}
