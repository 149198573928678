import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable } from 'rxjs';
import { TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';
import { UserBase } from '../../../../../models/users/user/user.base';
import { GroupBase } from '../../../../../models/users/group/group.base';
import { TarifLogListComponent } from '../../../../../models/tarifs/tarif-log/tarif-log-list/tarif-log-list.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeTarifsLogsViewParams extends BaseRouteParams {
  tarif_detail_route: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeTarifsLogsViewData extends TariftypeTarifsLogsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-tarifs-logs-view',
  standalone: true,
  imports: [CommonModule, TarifLogListComponent, HeaderActionComponent, IconComponent, TariftypeDetailNavComponent],
  templateUrl: './tariftype-tarifs-logs-view.component.pug',
  styleUrls: ['./tariftype-tarifs-logs-view.component.sass'],
})
export class TariftypeTarifsLogsViewComponent extends BaseDetailViewComponent<TariftypeTarifsLogsViewData, Tariftype> {
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public tarif_detail_route?: Link<TarifBase>;
  public user_detail_route?: Link<UserBase>;
  public group_detail_route?: Link<GroupBase>;
  public tlog_default_fields = [
    'id',
    'created',
    'author_details',
    'tarif_details',
    'user_details',
    'operation',
    'step',
    'ptstep_details',
    'tstep_details',
    'data',
  ];

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeTarifsLogsViewData) {
    super.setRouteData(data);
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (this.tarif_detail_route) {
      this.tarif_detail_route = new Link(data.tarif_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }
}
