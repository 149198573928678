import { Component, inject } from "@angular/core";
import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { map, Observable, Subject } from "rxjs";
import { ProviderService } from "../../../../../models/structures/provider/provider.service";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";
import { ProviderDocumentListComponent } from "../../../../../models/structures/provider-document/provider-document-list/provider-document-list.component";
import { Provider } from "../../../../../models/structures/provider/provider";

export interface ArticleProviderDocumentsViewParams
  extends ArticleViewParams,
    BaseRouteParams {
  provider_detail_route: RouteConfigItem;
}

export interface ArticleProviderDocumentsViewData
  extends ArticleProviderDocumentsViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-providerdocuments-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ArticleNavComponent,
    ProviderDocumentListComponent,
  ],
  templateUrl: "./article-providerdocuments-view.component.pug",
  styleUrl: "./article-providerdocuments-view.component.sass",
})
export class ArticleProviderdocumentsViewComponent extends BaseDetailViewComponent<
  ArticleProviderDocumentsViewData,
  Article
> {
  public providerdocumentsFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = [];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public provider_detail_route?: Link<Provider>;
  private _providers = inject(ProviderService);

  public override setRouteData(data: ArticleProviderDocumentsViewData) {
    super.setRouteData(data);
    if (data.provider_detail_route) {
      this.provider_detail_route = new Link(
        data.provider_detail_route,
        data,
        this._router,
      );
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.providerdocumentsFilter$ = this.data$.pipe(
      map((data) => {
        return data.article.fournisseur
          ? { provider: data.article.fournisseur, article: data.article.id }
          : { provider: -1 };
      }),
    );
  }
}
