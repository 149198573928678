
<ng-container *ngIf="data$|async; let data">
  <lvadg-tariftype-detail-nav [routes]="data.menu" [tariftype]="data.tariftype" *ngIf="data.menu &amp;&amp; data.tariftype"></lvadg-tariftype-detail-nav>
  <lvadg-header-action [title]="data.tariftype.name" level="h2" *ngIf="data.tariftype" subtitle="Gestion des dépôts" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-sm btn-primary" (click)="createStorage=!createStorage">
        <lvadg-icon ri="add">Ajouter un dépôt</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-header-action title="Gestion des associations tarifs/dépôts" level="h1" *ngIf="!data.tariftype" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-sm btn-primary" (click)="createStorage=!createStorage">
        <lvadg-icon ri="add">Ajouter une association tarif/dépôt</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createStorage">
    <div class="card-header">Ajouter un dépôt</div>
    <div class="card-body">
      <lvadg-tariftype-storage-create [tariftype]="data.tariftype" (cancelled)="createStorage=false" (created)="reload$.next(true); createStorage=false"></lvadg-tariftype-storage-create>
    </div>
  </div>
  <lvadg-tariftype-storage-list [name]="data.route.name" [filter]="tarifTypeFilter$" [default_fields]="default_fields" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [storage_detail_route]="storage_detail_route" [reload]="reload$" [actions]="['delete', 'permissions']"></lvadg-tariftype-storage-list>
</ng-container>