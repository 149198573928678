import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams } from '@solidev/data';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { TARIFTYPE_ROUTES } from '../menu';

export interface TariftypeDisplayViewParams extends BaseRouteParams {
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeDisplayViewData extends TariftypeDisplayViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-display-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tariftype-display-view.component.pug',
  styleUrls: ['./tariftype-display-view.component.sass'],
})
export class TariftypeDisplayViewComponent extends BaseDetailViewComponent<TariftypeDisplayViewData, Tariftype> {}
