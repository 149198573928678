import { CLIENT_LINKS_ROUTES, CLIENT_ROUTES } from './menu';
import { RData, RouteConfigItem, RoutesDefinition } from '@solidev/data';
import { ClientDisplayViewComponent, ClientDisplayViewParams } from './display/client-display-view.component';
import { ClientEditViewComponent, ClientEditViewParams } from './edit/client-edit-view.component';
import { RS } from '../../../../constants';
import { ClientRestosViewComponent, ClientRestosViewParams } from './restos/client-restos-view.component';
import { ClientOffersViewComponent, ClientOffersViewParams } from './offers/client-offers-view.component';
import { ClientSalesViewComponent, ClientSalesViewParams } from './sales/client-sales-view.component';
import { ClientStatsViewComponent, ClientStatsViewParams } from './stats/client-stats-view.component';
import {
  ClientTariftypesViewComponent,
  ClientTariftypesViewParams,
} from './tariftypes/client-tariftypes-view.component';
import { ClientEarticlesViewComponent, ClientEarticlesViewParams } from './earticles/client-earticles-view.component';
import { ClientAttsViewComponent, ClientAttsViewParams } from './atts/client-atts-view.component';
import { ClientUsersViewComponent, ClientUsersViewParams } from './users/client-users-view.component';
import { ClientRelationsViewComponent, ClientRelationsViewParams } from './relations/client-relations-view.component';

export const gen_client_detail_routes = (
  routes: CLIENT_ROUTES,
  links: CLIENT_LINKS_ROUTES,
  default_route: RouteConfigItem,
): RoutesDefinition => {
  const routes_def: RoutesDefinition = [];
  if (routes.client_display_route) {
    routes_def.push({
      path: '',
      pathMatch: 'full',
      component: ClientDisplayViewComponent,
      data: RData<ClientDisplayViewParams>({
        skip: true,
        route: routes.client_display_route,
        c_menu: routes,
      }),
    });
  }
  if (routes.client_edit_route) {
    routes_def.push({
      path: RS.edit,
      component: ClientEditViewComponent,
      data: RData<ClientEditViewParams>({
        title: 'Édition',
        route: routes.client_edit_route,
        c_menu: routes,
      }),
    });
  }
  if (routes.client_restos_route) {
    routes_def.push({
      path: RS.restos,
      component: ClientRestosViewComponent,
      data: RData<ClientRestosViewParams>({
        title: 'Restaurants',
        route: routes.client_restos_route,
        c_menu: routes,
        resto_detail_route: links.resto_detail_route,
        client_detail_route: links.client_detail_route,
      }),
    });
  }
  if (routes.client_offers_route) {
    routes_def.push({
      path: RS.offers,
      component: ClientOffersViewComponent,
      data: RData<ClientOffersViewParams>({
        title: 'Offres',
        route: routes.client_offers_route,
        offer_detail_route: links.offer_detail_route,
        offertypestorage_offer_detail_route: links.offertypestorage_offer_detail_route,
        c_menu: routes,
      }),
    });
  }
  if (routes.client_sales_route) {
    routes_def.push({
      path: RS.sales,
      component: ClientSalesViewComponent,
      data: RData<ClientSalesViewParams>({
        title: 'Ventes',
        route: routes.client_sales_route,
        c_menu: routes,
        client_detail_route: links.client_detail_route,
        storage_detail_route: links.storage_detail_route,
        resto_detail_route: links.resto_detail_route,
        bill_detail_route: links.bill_detail_route,
      }),
    });
  }
  if (routes.client_stats_route) {
    routes_def.push({
      path: RS.stats,
      component: ClientStatsViewComponent,
      data: RData<ClientStatsViewParams>({
        title: 'Statistiques',
        route: routes.client_stats_route,
        c_menu: routes,
      }),
    });
  }
  if (routes.client_tariftypes_route) {
    routes_def.push({
      path: RS.tariftypes,
      component: ClientTariftypesViewComponent,
      data: RData<ClientTariftypesViewParams>({
        title: 'Tarifs',
        route: routes.client_tariftypes_route,
        c_menu: routes,
      }),
    });
  }
  if (routes.client_earticles_route) {
    routes_def.push({
      path: RS.earticles,
      component: ClientEarticlesViewComponent,
      data: RData<ClientEarticlesViewParams>({
        title: 'Articles',
        route: routes.client_earticles_route,
        c_menu: routes,
        client_detail_route: links.client_detail_route,
        storage_detail_route: links.storage_detail_route,
        collection_detail_route: links.collection_detail_route,
        earticle_detail_route: links.earticle_detail_route,
        member_detail_route: links.member_detail_route,
        provider_detail_route: links.provider_detail_route,
      }),
    });
  }
  if (routes.client_atts_route) {
    routes_def.push({
      path: RS.atts,
      component: ClientAttsViewComponent,
      data: RData<ClientAttsViewParams>({
        title: 'Articles de tarifs',
        route: routes.client_atts_route,
        c_menu: routes,
        att_detail_route: links.att_detail_route,
        article_detail_route: links.article_detail_route,
        earticle_detail_route: links.earticle_detail_route,
        tariftype_detail_route: links.tariftype_detail_route,
      }),
    });
  }
  if (routes.client_users_route) {
    routes_def.push({
      path: RS.users,
      component: ClientUsersViewComponent,
      data: RData<ClientUsersViewParams>({
        title: 'Utilisateurs',
        route: routes.client_users_route,
        c_menu: routes,
        group_detail_route: links.group_detail_route,
        user_detail_route: links.user_detail_route,
      }),
    });
  }
  if (routes.client_relations_route) {
    routes_def.push({
      path: RS.relations,
      component: ClientRelationsViewComponent,
      data: RData<ClientRelationsViewParams>({
        title: 'Relations',
        route: routes.client_relations_route,
        c_menu: routes,
        client_detail_route: links.client_detail_route,
        producer_detail_route: links.producer_detail_route,
        provider_detail_route: links.provider_detail_route,
        storage_detail_route: links.storage_detail_route,
        resto_detail_route: links.resto_detail_route,
        relation_detail_route: links.relation_detail_route,
      }),
    });
  }

  return routes_def;
};
