
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TreatmentService} from "./treatment.service";
import { Treatment } from "./treatment";

@Injectable({
  providedIn: 'root'
})
export class TreatmentResolver  {
  constructor(private _rs: TreatmentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Treatment> {
    return this._rs.fetch(+route.params["treatmentId"])
  }
}
