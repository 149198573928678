
<form [formGroup]="createForm" (submit)="save()">
  <div class="row">
    <data-dispedit class="col-4" [model]="obj" field="code" mode="form" [form]="createForm">Code</data-dispedit>
    <data-dispedit class="col-4" [model]="obj" field="process" mode="form" [form]="createForm" [collection]="process$">Processus de commande</data-dispedit>
    <data-dispedit class="col-4" [model]="obj" field="storage" mode="form" [form]="createForm" [collection]="storage$">Dépôt</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>
<!--h1 Création commande achat-->
<!---->
<!--dl.row-->
<!--  .col-3-->
<!--    dt Fournisseur-->
<!--    dd XXXX-->
<!--  .col-3-->
<!--    dt Commande-->
<!--    dd XXXXX-->
<!--  .col-3-->
<!--    dt Date de commande-->
<!--    dd-->
<!--      input.form-control(type="date")-->
<!--  .col-3-->
<!--    dt Date enlèvement-->
<!--    dd-->
<!--      input.form-control(type="date")-->
<!---->
<!--table.table.table-striped.table-bordered.mt-4-->
<!--  thead-->
<!--    tr-->
<!--      th Code-->
<!--      th Désignation-->
<!--      th UCI-->
<!--      th Quantité-->
<!--      th UV-->
<!--      th Poids-->
<!--      th DLC-->
<!--      th UF-->
<!--      th PU-->
<!--      th Total HT-->
<!--  tbody-->
<!--    tr-->
<!--      td 2321-->
<!--      td BATAVIA FEUILLE-->
<!--      td 5 x 500g-->
<!--      td-->
<!--        input.form-control.form-control-sm(type="number")-->
<!--      td CO-->
<!--      td xxxxx-->
<!--      td XXXXX-->
<!--      td KG-->
<!--      td xxxx-->
<!--      td xxxx-->
<!--    tr-->
<!--      td 2321-->
<!--      td BATAVIA FEUILLE-->
<!--      td 5 x 500g-->
<!--      td-->
<!--        input.form-control.form-control-sm(type="number")-->
<!--      td CO-->
<!--      td xxxxx-->
<!--      td XXXXX-->
<!--      td KG-->
<!--      td xxxx-->
<!--      td xxxx-->
<!--  tfoot-->
<!--    tr-->
<!--      th TOTAUX-->
<!--      th-->
<!--      th-->
<!--      th XXXX-->
<!--      th-->
<!--      th XXXX-->
<!--      th-->
<!--      th-->
<!--      th-->
<!--      th XXXX-->
<!---->
<!--.clearfix-->
<!--  .float-end-->
<!--    button.mt-2.btn.btn-lg.btn-primary Commander-->