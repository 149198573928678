import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Eacollection } from '../eacollection';

@Component({
  selector: 'lvadg-eacollection-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './eacollection-manage.component.pug',
  styleUrls: ['./eacollection-manage.component.sass'],
})
export class EacollectionManageComponent implements OnInit {
  @Input() public eacollection?: Eacollection;

  constructor() {}

  ngOnInit(): void {}
}
