
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Eacollection } from "./eacollection";

@Injectable({
  providedIn: 'root'
})
export class EacollectionService extends Collection<Eacollection> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/eacollections", Eacollection);
  }
}
