import { charField, foreignKeyField, primaryField } from '@solidev/data';
import { StructureBase } from '../structure.base';

export enum PlatformType {
  PROVIDER = 'PROV',
}

export enum PlatformStatus {
  VALID = 'OK',
  INVALID = 'KO',
}

export class PlatformBase extends StructureBase {
  static override readonly __name: string = 'PlatformBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: PlatformType.PROVIDER,
    maxLength: 4,
    choices: [{ value: PlatformType.PROVIDER, desc: 'Plateforme fournisseur' }],
  })
  public type: PlatformType = PlatformType.PROVIDER;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: PlatformStatus.VALID,
    required: true,
    maxLength: 4,
    choices: [
      { value: PlatformStatus.VALID, desc: 'Valide' },
      { value: PlatformStatus.INVALID, desc: 'Non valide' },
    ],
  })
  public status: PlatformStatus = PlatformStatus.VALID;

  @foreignKeyField({
    name: 'provider',
    description: 'ID Fournisseur',
    related: 'Provider',
  })
  public provider!: number;

  @foreignKeyField({
    name: 'producer',
    description: 'ID Producteur',
    related: 'Producer',
  })
  public producer!: number;
}
