import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsageBase } from '../usage.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UsageService } from '../usage.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-usage-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './usage-create.component.pug',
  styleUrls: ['./usage-create.component.sass'],
})
export class UsageCreateComponent implements OnInit {
  public usage!: UsageBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<UsageBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _usages: UsageService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.usage = new UsageBase(this._usages);
  }

  public async save() {
    this.usage.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.usage.save({ updateModel: true }));
      this.created.emit(this.usage);
      this._msgs.success('Usage créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
