
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OfferText } from "./offer-text";

@Injectable({
  providedIn: 'root'
})
export class OfferTextService extends Collection<OfferText> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offertexts", OfferText);
  }
}
