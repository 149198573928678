
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifLifecycleStep } from '../tarif-lifecycle-step';
@Component({
  selector: 'lvadg-tarif-lifecycle-step-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-lifecycle-step-manage.component.pug',
  styleUrls: ['./tarif-lifecycle-step-manage.component.sass']
})
export class TarifLifecycleStepManageComponent implements OnInit {
  @Input() public tariflifecyclestep?: TarifLifecycleStep;

  constructor() { }

  ngOnInit(): void {
  }

}
