import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Process } from '../process';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-process-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './process-manage.component.pug',
  styleUrls: ['./process-manage.component.sass'],
})
export class ProcessManageComponent implements OnInit {
  @Input() public process!: Process;

  constructor() {}

  ngOnInit(): void {}
}
