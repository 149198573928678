import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { ProducerDocument } from "../producer-document";
import { ProducerDocumentService } from "../producer-document.service";
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgMathPipesModule } from "ngx-pipes";
import { ProducerDisplayComponent } from "../../producer/producer-display/producer-display.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { ProductDisplayComponent } from "../../../catalog/product/product-display/product-display.component";
import { PRODUCERDOCUMENT_STATUSES } from "../producer-document.base";
import { MimetypeIconComponent } from "../../../../components/utils/mimetype-icon/mimetype-icon.component";
import { LabelService } from "../../../catalog/label/label.service";
import { map } from "rxjs";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { ProductService } from "../../../catalog/product/product.service";
import { MemberService } from "../../member/member.service";
import { StorageService } from "../../storage/storage.service";
import { Producer } from "../../producer/producer";
import { Product } from "../../../catalog/product/product";
import { IconComponent } from "../../../../components/utils/icon/icon.component";

@Component({
  selector: "lvadg-producer-document-list",
  standalone: true,
  templateUrl: "./producer-document-list.component.pug",
  styleUrls: ["./producer-document-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgMathPipesModule,
    PChoicePipe,
    ProducerDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    IconComponent,
    ProductDisplayComponent,
    SafeDeleteComponent,
    MimetypeIconComponent,
  ],
})
export class ProducerDocumentListComponent extends ModellistComponent<ProducerDocument> {
  @Input() public producer_detail_route?: Link<Producer>;
  @Input() public product_detail_route?: Link<Product>;
  public showFilename: boolean = false;

  constructor(
    coll: ProducerDocumentService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _labels: LabelService,
    private _products: ProductService,
    private _members: MemberService,
    private _storages: StorageService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "status",
          "type",
          "label_products",
        ],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectMultiFilter({
          field: "status",
          name: "status",
          label: "État(s)",
          model: ProducerDocument,
        }),
        new ModelListSelectMultiFilter({
          field: "type",
          name: "type",
          label: "Type(s)",
          model: ProducerDocument,
        }),
        new ModelListSelectMultiFilter({
          field: "label_products",
          name: "label_products",
          label: "Label produits (s)",
          choices: this._labels.byTypes(LABEL_TYPE.FL, LABEL_TYPE.SEA).pipe(
            map((labels) =>
              labels.map((label) => ({
                value: label.id,
                desc: `[${label.type_short}] ${label.name}`,
              })),
            ),
          ),
        }),
        new ModelListAutocompleteMultiFilter({
          field: "products",
          name: "products",
          label: "Produit(s)",
          collection: this._products,
          filter: {
            with_producerdocument: true,
            fields: ["id", "name"].join(","),
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: "storages",
          name: "storages",
          label: "Dépôt(s)",
          collection: this._storages,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "members",
          name: "members",
          label: "Adhérent(s)",
          collection: this._members,
        }),
      ],
    };
  }

  public override getRowClasses(row: ProducerDocument): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === PRODUCERDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE) {
      cls.push("table-danger");
    }
    if (row.status === PRODUCERDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE) {
      cls.push("text-muted");
    }
    if (row.status === PRODUCERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D) {
      cls.push("table-warning");
    }
    if (row.status === PRODUCERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M) {
      cls.push("text-warning");
    }

    return cls;
  }
}
