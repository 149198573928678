
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tariftype">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tariftype" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.tariftype}}</td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article}}</td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.eaclient}}</td>
        </ng-container>
        <ng-container cdkColumnDef="datestart">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="datestart" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.datestart|date:'short'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="dateend">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="dateend" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.dateend|date:'short'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="eastorages">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eastorages" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.eastorages}}</td>
        </ng-container>
        <ng-container cdkColumnDef="externals">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="externals" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.externals}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tariftype_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tariftype_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-tariftype-display [tariftype]="row.tariftype_details" mode="line" [detail_route]="tariftype_detail_route"></lvadg-tariftype-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_details" [sort]="true" sortField="article__libelle"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-display [article]="row.article_details" mode="line" [detail_route]="article_detail_route"></lvadg-article-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article__uf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__uf"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details|choice:'udf_unit'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__code" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.article_details?.code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="article__family">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__family"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details?.family_details?.full_path}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__uci">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__uci"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details?.uci}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__calibre">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__calibre"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details?.calibre_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__categorie">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__categorie"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details?.categorie_details?.code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__origine">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__origine"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details?.origine_details?.name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__local">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__local"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details|choice:'local'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__colisage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__colisage"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_details?.colisage}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__udv_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__udv_value"></data-model-list-field-header>
          </th>
          <td class="text-center" cdk-cell *cdkCellDef="let row">{{row.article_details|factor:'udv_value'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article__egalim">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__egalim"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let l of row.article_details?.egalim_details">
              <lvadg-label-display [label]="l"></lvadg-label-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article__decondi_mode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article__decondi_mode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.article_details?.decondi_mode">{{row.article_details|choice:'decondi_mode'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient__code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient__code"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.eaclient_details?.code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient__libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient__libelle"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.eaclient_details?.libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-earticle-display [earticle]="row.eaclient_details" mode="line" [detail_route]="earticle_detail_route"></lvadg-earticle-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="in_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="in_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.in_op? 'Oui' : 'Non'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="actions"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <button class="btn btn-link btn-sm text-danger" *ngIf="haveAction('archive') &amp;&amp; row.status !== AttStatus.ARCHIVED" (click)="action.emit({model: row, action: 'archive'})"><i class="bi bi-eye-slash"></i></button>
            <button class="btn btn-link btn-sm text-danger" *ngIf="haveAction('unarchive') &amp;&amp; row.status !== AttStatus.ACTIVE" (click)="action.emit({model: row, action: 'unarchive'})"><i class="bi bi-eye"></i></button>
            <button class="btn btn-link btn-sm text-danger" *ngIf="haveAction('remove') &amp;&amp; row.status === AttStatus.ARCHIVED" (click)="action.emit({model: row, action: 'remove'})"><i class="bi bi-trash"></i></button>
            <button class="btn btn-primary btn-sm" *ngIf="haveAction('select')" (click)="action.emit({model: row, action: 'select'})">Sélectionner</button>
            <button class="btn btn-primary btn-sm" *ngIf="haveAction('selectOp') &amp;&amp; !row.in_op" (click)="action.emit({model: row, action: 'selectOp'})">Sélectionner</button>
            <data-safe-delete class="text-bg-dark small py-1" *ngIf="haveAction('delete')" [model]="row" [service]="coll" display="icon" title="Supprimer ?" (deleted)="reload?.next(true)"></data-safe-delete>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>