import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeago',
  standalone: true,
})
export class TimeagoPipe implements PipeTransform {
  transform(value: Date | string | undefined | null): unknown {
    if (value) {
      let dt: Date;
      if (typeof value === 'string') {
        dt = new Date(value);
      } else {
        dt = value;
      }
      const seconds = Math.floor((+new Date() - +dt) / 1000);
      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return "à l'instant";
      const intervals: [string, number][] = [
        ['année', 31536000],
        ['mois', 2592000],
        ['semaine', 604800],
        ['jour', 86400],
        ['heure', 3600],
        ['minute', 60],
        ['seconde', 1],
      ];
      let counter;
      for (const v of intervals) {
        counter = Math.floor(seconds / v[1]);
        if (counter > 0)
          if (counter === 1 || v[0] === 'mois') {
            return 'il y a ' + counter + ' ' + v[0]; // singular (1 day ago)
          } else {
            return 'il y a ' + counter + ' ' + v[0] + 's'; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}
