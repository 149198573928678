import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Client } from '../client';
import { ClientService } from '../client.service';
import {
  FiltersParams,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { LocationFiltersService } from '../../../locations/common/location-filters.service';
import { NgStringPipesModule } from 'ngx-pipes';
import { ClientStatus } from '../client.base';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { LabelDisplayComponent } from '../../../catalog/label/label-display/label-display.component';
import { LocationDisplayComponent } from '../../../locations/location/location-display/location-display.component';

@Component({
  selector: 'lvadg-client-list',
  standalone: true,
  templateUrl: './client-list.component.pug',
  styleUrls: ['./client-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule,
    LocationDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
  ],
})
export class ClientListComponent extends ModellistComponent<Client> {
  constructor(
    coll: ClientService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _locFilters: LocationFiltersService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Filtrer par état',
          model: Client,
        }),
        new ModelListSelectMultiFilter({
          field: 'type',
          name: 'type',
          label: 'Filtrer par type(s)',
          model: Client,
        }),
        ...this._locFilters.getFilters(),
      ],
    };
  }

  public override getRowClasses(row: Client): string[] {
    const cls = super.getRowClasses(row);
    // if (row.status === ClientStatus.INACTIVE) {
    //   cls.push('table-secondary');
    //   cls.push('text-muted');
    // }
    if (row.status === ClientStatus.ACTIVE_NEW) {
      cls.push('table-warning');
    }
    return cls;
  }
}
