export const filterNullish = <
  T extends Record<string, number | string | undefined | null | boolean>,
>(
  obj: T,
): Partial<T> => {
  const res: Partial<T> = {};
  for (const [k, v] of Object.entries(obj)) {
    if (v !== null && v !== undefined) {
      (res as Record<string, number | string | boolean>)[k] = v;
    }
  }
  return res;
};
