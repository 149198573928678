import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderitem } from '../orderitem';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { filter, ReplaySubject } from 'rxjs';
import { PFactorPipe } from '@solidev/data';
import { OrderStatus } from '../../order/order.base';
import { OrderItemStatus } from '../orderitem.base';

@Component({
  selector: 'lvadg-orderitem-quantity',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PFactorPipe, PFactorPipe],
  templateUrl: './orderitem-quantity.component.pug',
  styleUrls: ['./orderitem-quantity.component.sass'],
})
export class OrderitemQuantityComponent implements OnInit {
  @Input() public orderitem!: Orderitem;
  @Input() public index?: number;
  @Input() public focus?: ReplaySubject<number>;
  @Input() public editableOrderStatuses: OrderStatus[] = [
    OrderStatus.PREPARATION,
  ];
  @Input() public editableOrderItemStatuses: OrderItemStatus[] = [
    OrderItemStatus.PREPARATION,
    OrderItemStatus.EMPTY,
  ];

  @Output() public selected = new EventEmitter<Orderitem>();
  @Output() public updated = new EventEmitter<number | null | undefined>();

  /** Editor view child, for focus and selection */
  @ViewChild('editorEl') editorEl?: ElementRef<HTMLInputElement>;

  public editor = new FormControl<number>(0);
  public last: any;

  constructor() {}

  ngOnInit(): void {
    if (this.focus) {
      this.focus.pipe(filter((id) => id === this.index)).subscribe(() => {
        this.editorEl?.nativeElement.focus({ preventScroll: false });
        this.editorEl?.nativeElement.select();
      });
    }
    this.setEditorQuantity(this.orderitem.quantity);
  }

  public setEditorQuantity(intvalue: number | null | undefined) {
    if (intvalue) {
      this.editor.setValue(intvalue / 100000, { emitEvent: false });
    } else {
      this.editor.setValue(null, { emitEvent: false });
    }
  }

  onFocus($event: FocusEvent) {
    this.selected.emit(this.orderitem);
  }

  onBlur($event: FocusEvent) {
    console.log('on blur save');
    this.save(false, false);
  }

  onClick($event: MouseEvent) {
    console.log('clicked');
  }

  save(up: boolean, down: boolean) {
    if (this.last !== this.editor.value) {
      this.updated.next(this.editor.value);
    }
    this.last = this.editor.value;
    if (up) {
      this.focus?.next((this.index || 0) + 1);
    } else if (down) {
      this.focus?.next((this.index || 0) - 1);
    }
  }

  isEditable(orderitem: Orderitem) {
    if (!orderitem.unit_price) {
      return false;
    }

    if (orderitem.order_details?.status) {
      return (
        this.editableOrderStatuses.indexOf(orderitem.order_details?.status) !==
          -1 && this.editableOrderItemStatuses.indexOf(orderitem.status) !== -1
      );
    } else {
      return this.editableOrderItemStatuses.indexOf(orderitem.status) !== -1;
    }
  }
}
