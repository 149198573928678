
<ng-template #catalogSlot let-offcanvas>
  <div class="offcanvas-header">
    <h4>Catalogue / ajout d'articles</h4>
    <button class="btn-close" (click)="offcanvas.close()"></button>
  </div>
  <div class="offcanvas-body" *ngIf="data$|async; let data">
    <lvadg-article-list [name]="data.route.name+'_catalog'" [actions]="['tariftype_add']" [filter]="catalogTarifTypeFilter$" [default_fields]="catalog_default_fields" (action)="catalogAction(data.tariftype, $event)" [reload]="reloadTarif$"></lvadg-article-list>
  </div>
</ng-template>
<ng-container *ngIf="data$|async; let data">
  <lvadg-tarif-detail-nav [routes]="data.menu" [tarif]="data.tarif" [tariftype]="data.tariftype" *ngIf="data.menu"></lvadg-tarif-detail-nav>
  <lvadg-header-action [title]="data.tarif.name" subtitle="Détails des prix" level="h2" ri="tarifs">
    <ng-container actions><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route.route({tariftype: data.tariftype})"><i class="bi bi-table me-2"></i>Modèle</a>
      <button class="btn btn-outline-warning btn-sm" *ngIf="canEdit(data, TTUL.pilote)" title="Mise à jour des articles" (click)="updateArticles(data).then()"><i class="bi bi-arrow-clockwise me-2"></i>Articles</button>
      <button class="btn btn-outline-warning btn-sm" *ngIf="canEdit(data, TTUL.pilote)" title="Ajout d'articles" (click)="openCatalog()"><i class="bi bi-plus-circle me-2"></i>Article</button>
      <data-safe-delete display="fbutton" [model]="data.tarif" *ngIf="canDelete(data)" (deleted)="tariftype_detail_route.navigate($any({id: data.tarif.tariftype})).then()" [messages]="{success: 'Tarif supprimé avec succès', error: 'Erreur lors de la suppression du tarif'}"></data-safe-delete>
    </ng-container>
  </lvadg-header-action>
  <lvadg-article-tarif-list [name]="data.route.name+&quot;_&quot;+data.tariftype.code+&quot;_lines&quot;" [tarif]="data.tarif" [filter]="tarifFilter$" [reload]="reloadTarif$" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" [family_detail_route]="family_detail_route" [tarif_detail_route]="tarif_detail_route" [default_fields]="articletarif_default_fields"></lvadg-article-tarif-list>
</ng-container>