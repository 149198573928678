import { RoutesConfig } from "@solidev/data";
import {
  Allergen,
  Article,
  Brand,
  Calibre,
  Category,
  CiqualAlim,
  CiqualColumn,
  Eacollection,
  Earticle,
  Family,
  Label,
  Origin,
  Packaging,
  Particularity,
  Presentation,
  Process,
  Product,
  Production,
  Region,
  RS,
  Treatment,
  Usage,
} from "@vivalya/lvadg";

export const ADMIN_CATALOG_ROUTES_NAMES = [
  "catalog",
  "catalog_articles",
  "catalog_articles_article",
  "catalog_articles_article_articledocuments",
  "catalog_articles_article_eamappings",
  "catalog_articles_article_edit",
  "catalog_articles_article_prices",
  "catalog_articles_article_provider",
  "catalog_articles_article_providerdocuments",
  "catalog_articles_article_quality",
  "catalog_articles_article_tarifs",
  "catalog_articles_documents",
  "catalog_articles_documents_document",
  "catalog_ciqual_alims",
  "catalog_ciqual_alims_alim",
  "catalog_ciqual_columns",
  "catalog_ciqual_columns_column",
  "catalog_eacollections",
  "catalog_eacollections_eacollection",
  "catalog_earticles",
  "catalog_earticles_earticle",
  "catalog_families",
  "catalog_families_family",
  "catalog_families_match",
  "catalog_labels",
  "catalog_labels_label",
  "catalog_meta",
  "catalog_meta_allergens",
  "catalog_meta_allergens_allergen",
  "catalog_meta_brands",
  "catalog_meta_brands_brand",
  "catalog_meta_calibres",
  "catalog_meta_calibres_calibre",
  "catalog_meta_categories",
  "catalog_meta_categories_category",
  "catalog_meta_origins",
  "catalog_meta_origins_origin",
  "catalog_meta_packagings",
  "catalog_meta_packagings_packaging",
  "catalog_meta_particularities",
  "catalog_meta_particularities_particularity",
  "catalog_meta_presentations",
  "catalog_meta_presentations_presentation",
  "catalog_meta_processes",
  "catalog_meta_processes_process",
  "catalog_meta_productions",
  "catalog_meta_productions_production",
  "catalog_meta_regions",
  "catalog_meta_regions_region",
  "catalog_meta_treatments",
  "catalog_meta_treatments_treatment",
  "catalog_meta_usages",
  "catalog_meta_usages_usage",
  "catalog_products",
  "catalog_products_product",
  "catalog_products_product_edit",
  "catalog_products_product_offers",
  "catalog_products_product_articles",
  "catalog_products_product_documents",
  "catalog_products_product_producer",
] as const;

export const ADMIN_CATALOG_ROUTES_CONFIG: RoutesConfig<
  (typeof ADMIN_CATALOG_ROUTES_NAMES)[number]
> = {
  catalog: {
    params: [],
    route: () => ["/", RS.catalog],
    name: "catalog",
  },
  catalog_articles: {
    params: [],
    route: () => ["/", RS.catalog, RS.articles],
    name: "catalog_articles",
  },
  catalog_articles_article: {
    params: ["article"],
    route: (article: Article) => ["/", RS.catalog, RS.articles, article.id],
    name: "catalog_articles_article",
  },
  catalog_articles_article_articledocuments: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.articledocuments,
    ],
    name: "catalog_articles_article_articledocuments",
  },
  catalog_articles_article_eamappings: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.earticles,
    ],
    name: "catalog_articles_article_eamappings",
  },
  catalog_articles_article_edit: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.edit,
    ],
    name: "catalog_articles_article_edit",
  },
  catalog_articles_article_prices: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.prices,
    ],
    name: "catalog_articles_article_prices",
  },
  catalog_articles_article_provider: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.provider,
    ],
    name: "catalog_articles_article_provider",
  },
  catalog_articles_article_providerdocuments: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.providerdocuments,
    ],
    name: "catalog_articles_article_providerdocuments",
  },
  catalog_articles_article_quality: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.quality,
    ],
    name: "catalog_articles_article_quality",
  },
  catalog_articles_article_tarifs: {
    params: ["article"],
    route: (article: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      article.id,
      RS.tarifs,
    ],
    name: "catalog_articles_article_tarifs",
  },

  catalog_articles_documents: {
    params: [],
    route: () => ["/", RS.catalog, RS.articles, RS.documents],
    name: "catalog_articles_documents",
  },
  catalog_articles_documents_document: {
    params: ["articledocument"],
    route: (articledocument: Article) => [
      "/",
      RS.catalog,
      RS.articles,
      RS.documents,
      articledocument.id,
    ],
    name: "catalog_articles_documents_document",
  },

  catalog_ciqual_alims: {
    params: [],
    route: () => ["/", RS.catalog, RS.ciqual, RS.aliments],
    name: "catalog_ciqual_alims",
  },
  catalog_ciqual_alims_alim: {
    params: ["ciqualalim"],
    route: (ca: CiqualAlim) => ["/", RS.catalog, RS.ciqual, RS.aliments, ca.id],
    name: "catalog_ciqual_alims_alim",
  },
  catalog_ciqual_columns: {
    params: [],
    route: () => ["/", RS.catalog, RS.ciqual, RS.columns],
    name: "catalog_ciqual_columns",
  },
  catalog_ciqual_columns_column: {
    params: ["ciqualcolumn"],
    route: (cc: CiqualColumn) => [
      "/",
      RS.catalog,
      RS.ciqual,
      RS.columns,
      cc.id,
    ],
    name: "catalog_ciqual_columns_column",
  },
  catalog_eacollections: {
    params: [],
    route: () => ["/", RS.catalog, RS.eacollections],
    name: "catalog_eacollections",
  },
  catalog_eacollections_eacollection: {
    params: ["eacollection"],
    route: (eacollection: Eacollection) => [
      "/",
      RS.catalog,
      RS.eacollections,
      eacollection.id,
    ],
    name: "catalog_eacollections_eacollection",
  },
  catalog_earticles: {
    params: [],
    route: () => ["/", RS.catalog, RS.earticles],
    name: "catalog_earticles",
  },
  catalog_earticles_earticle: {
    params: ["earticle"],
    route: (earticle: Earticle) => ["/", RS.catalog, RS.earticles, earticle.id],
    name: "catalog_earticles_earticle",
  },
  catalog_families: {
    params: [],
    route: () => ["/", RS.catalog, RS.families],
    name: "catalog_families",
  },

  catalog_families_family: {
    params: ["family"],
    route: (family: Family) => ["/", RS.catalog, RS.families, family.id],
    name: "catalog_families_family",
  },
  catalog_families_match: {
    params: [],
    route: (mode: "FL" | "SEA") => [
      "/",
      RS.catalog,
      RS.families,
      RS.match,
      mode,
    ],
    name: "catalog_families_match",
  },
  catalog_labels: {
    params: [],
    route: () => ["/", RS.catalog, RS.labels],
    name: "catalog_labels",
  },
  catalog_labels_label: {
    params: ["label"],
    route: (label: Label) => ["/", RS.catalog, RS.labels, label.id],
    name: "catalog_labels_label",
  },
  catalog_meta: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta],
    name: "catalog_meta",
  },
  catalog_meta_allergens: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.allergens],
    name: "catalog_meta_allergens",
  },
  catalog_meta_allergens_allergen: {
    params: ["allergen"],
    route: (allergen: Allergen) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.allergens,
      allergen.id,
    ],
    name: "catalog_meta_allergens_allergen",
  },
  catalog_meta_brands: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.brands],
    name: "catalog_meta_brands",
  },
  catalog_meta_brands_brand: {
    params: ["brand"],
    route: (brand: Brand) => ["/", RS.catalog, RS.meta, RS.brands, brand.id],
    name: "catalog_meta_brands_brand",
  },
  catalog_meta_calibres: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.calibres],
    name: "catalog_meta_calibres",
  },
  catalog_meta_calibres_calibre: {
    params: ["calibre"],
    route: (calibre: Calibre) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.calibres,
      calibre.id,
    ],
    name: "catalog_meta_calibres_calibre",
  },
  catalog_meta_categories: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.categories],
    name: "catalog_meta_categories",
  },
  catalog_meta_categories_category: {
    params: ["category"],
    route: (category: Category) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.categories,
      category.id,
    ],
    name: "catalog_meta_categories_category",
  },
  catalog_meta_origins: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.origins],
    name: "catalog_meta_origins",
  },
  catalog_meta_origins_origin: {
    params: ["origin"],
    route: (origin: Origin) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.origins,
      origin.id,
    ],
    name: "catalog_meta_origins_origin",
  },
  catalog_meta_packagings: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.packagings],
    name: "catalog_meta_packagings",
  },
  catalog_meta_packagings_packaging: {
    params: ["packaging"],
    route: (packaging: Packaging) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.packagings,
      packaging.id,
    ],
    name: "catalog_meta_packagings_packaging",
  },
  catalog_meta_particularities: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.particularities],
    name: "catalog_meta_particularities",
  },
  catalog_meta_particularities_particularity: {
    params: ["particularity"],
    route: (particularity: Particularity) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.particularities,
      particularity.id,
    ],
    name: "catalog_meta_particularities_particularity",
  },
  catalog_meta_presentations: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.presentations],
    name: "catalog_meta_presentations",
  },
  catalog_meta_presentations_presentation: {
    params: ["presentation"],
    route: (presentation: Presentation) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.presentations,
      presentation.id,
    ],
    name: "catalog_meta_presentations_presentation",
  },
  catalog_meta_processes: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.processes],
    name: "catalog_meta_processes",
  },
  catalog_meta_processes_process: {
    params: ["process"],
    route: (process: Process) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.processes,
      process.id,
    ],
    name: "catalog_meta_processes_process",
  },

  catalog_meta_productions: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.productions],
    name: "catalog_meta_productions",
  },
  catalog_meta_productions_production: {
    params: ["production"],
    route: (production: Production) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.productions,
      production.id,
    ],
    name: "catalog_meta_productions_production",
  },
  catalog_meta_regions: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.regions],
    name: "catalog_meta_regions",
  },
  catalog_meta_regions_region: {
    params: ["region"],
    route: (region: Region) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.regions,
      region.id,
    ],
    name: "catalog_meta_regions_region",
  },
  catalog_meta_treatments: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.treatments],
    name: "catalog_meta_treatments",
  },
  catalog_meta_treatments_treatment: {
    params: ["treatment"],
    route: (treatment: Treatment) => [
      "/",
      RS.catalog,
      RS.meta,
      RS.treatments,
      treatment.id,
    ],
    name: "catalog_meta_treatments_treatment",
  },
  catalog_meta_usages: {
    params: [],
    route: () => ["/", RS.catalog, RS.meta, RS.usages],
    name: "catalog_meta_usages",
  },
  catalog_meta_usages_usage: {
    params: ["usage"],
    route: (usage: Usage) => ["/", RS.catalog, RS.meta, RS.usages, usage.id],
    name: "catalog_meta_usages_usage",
  },
  catalog_products: {
    params: [],
    route: () => ["/", RS.catalog, RS.products],
    name: "catalog_products",
  },
  catalog_products_product: {
    params: ["product"],
    route: (product: Product) => ["/", RS.catalog, RS.products, product.id],
    name: "catalog_products_product",
  },
  catalog_products_product_edit: {
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.catalog,
      RS.products,
      product.id,
      RS.edit,
    ],
    name: "catalog_products_product_edit",
  },
  catalog_products_product_offers: {
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.catalog,
      RS.products,
      product.id,
      RS.offers,
    ],
    name: "catalog_products_product_offers",
  },
  catalog_products_product_articles: {
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.catalog,
      RS.products,
      product.id,
      RS.articles,
    ],
    name: "catalog_products_product_articles",
  },
  catalog_products_product_documents: {
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.catalog,
      RS.products,
      product.id,
      RS.documents,
    ],
    name: "catalog_products_product_documents",
  },
  catalog_products_product_producer: {
    params: ["product"],
    route: (product: Product) => [
      "/",
      RS.catalog,
      RS.products,
      product.id,
      RS.producer,
    ],
    name: "catalog_products_product_producer",
  },
};
