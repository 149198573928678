import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { TarifNotificationDestinationService } from '../../../../../tarif-notification-destination/tarif-notification-destination.service';
import { Observable, tap } from 'rxjs';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

interface TarifMessageDestination {
  last_name?: string;
  first_name?: string;
  email: string;
}

export interface TarifMessageContacts {
  excluded: TarifMessageDestination[];
  temporary: TarifMessageDestination[];
}

@Component({
  selector: 'lvadg-tarif-message-contacts',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './tarif-message-contacts.component.pug',
  styleUrls: ['./tarif-message-contacts.component.sass'],
})
export class TarifMessageContactsComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public mode: 'nego' | 'publish' | 'erratum' = 'nego';
  @Input() public step!: TarifLifecycleStep;
  @Output() public contacts = new EventEmitter<TarifMessageContacts>();
  public contactForm = new FormGroup({
    last_name: new FormControl(''),
    first_name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  public contacts$!: Observable<TarifMessageDestination[]>;
  public temporary: TarifMessageDestination[] = [];
  public disabled: TarifMessageDestination[] = [];
  public create: boolean = false;

  constructor(private _tnd: TarifNotificationDestinationService) {}

  public ngOnInit() {
    if (this.tarif) {
      this.contacts$ = this.tarif
        .action<Tarif, TarifMessageDestination[]>('GET', 'contacts', {
          params: { mode: 'nego' },
        })
        .pipe(tap((c) => this.contacts.emit({ excluded: [], temporary: [] })));
    }
  }

  saveTemporary() {
    this.temporary.push(this.contactForm.value as TarifMessageDestination);
    this.contactForm.reset();
    this.create = false;
    this.contacts.emit({ excluded: this.disabled, temporary: this.temporary });
  }

  isEnabled(c: TarifMessageDestination) {
    return !this.disabled.find((v) => v.email === c.email);
  }

  toggleContact(c: TarifMessageDestination) {
    const pos = this.disabled.findIndex((v) => v.email === c.email);
    if (pos !== -1) {
      this.disabled.splice(pos);
    } else {
      this.disabled.push(c);
    }
    this.contacts.emit({ excluded: this.disabled, temporary: this.temporary });
  }
}
