import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Order } from '../order';
import { OrderService } from '../order.service';
import {
  FactorcPipe,
  FactorPipe,
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { OrdergroupDisplayComponent } from '../../ordergroup/ordergroup-display/ordergroup-display.component';
import { OrderprocessDisplayComponent } from '../../orderprocess/orderprocess-display/orderprocess-display.component';
import { OrdergroupBase } from '../../ordergroup/ordergroup.base';

@Component({
  selector: 'lvadg-order-list',
  standalone: true,
  templateUrl: './order-list.component.pug',
  styleUrls: ['./order-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    FactorcPipe,
    FactorPipe,
    SafeDeleteComponent,
    OrderprocessDisplayComponent,
    OrdergroupDisplayComponent,
  ],
})
export class OrderListComponent extends ModellistComponent<Order> {
  @Input() public ordergroup_detail_route?: Link<OrdergroupBase>;

  constructor(coll: OrderService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override detailRoute(item: Order): (string | number)[] | null {
    if (this.detail_route) {
      return this.detail_route.route({
        order: item,
        orderprocess: { id: item.process } as any,
      });
    }
    return super.detailRoute(item);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
