import {
  Component,
  inject,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Storage } from "../../../../../models/structures/storage/storage";
import { STORAGE_ROUTES } from "../menu";
import {
  PRODUCER_TYPES,
  ProducerBase,
} from "../../../../../models/structures/producer/producer.base";
import {
  STORAGE_TYPES,
  STORAGE_USER_LEVELS,
  StorageBase,
} from "../../../../../models/structures/storage/storage.base";
import { map, Observable, Subject } from "rxjs";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProducerListComponent } from "../../../../../models/structures/producer/producer-list/producer-list.component";
import { StorageNavComponent } from "../_nav/storage-nav.component";
import { PrintSettings } from "../../../../../models/layouts/print-settings";
import { PRODUCER_PRINT_SETTINGS } from "../../../../../models/structures/producer/producer.print";
import {
  NgbModalModule,
  NgbOffcanvas,
  NgbOffcanvasRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ProducerUserCreateComponent } from "../../../../../models/structures/producer/producer-user-create/producer-user-create.component";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { CdataKey } from "../../../../../models/cdata/cdata-key/cdata-key";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
} from "../../../../../models/catalog/product/product.base";

export interface StorageProducersViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
  producer_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  local: LOCAL_FILTERS;
  type: PRODUCT_TYPES;
}

export interface StorageProducersViewData extends StorageProducersViewParams {
  storage: Storage;
  cdatakeys: CdataKey[];
}

@Component({
  selector: "lvadg-storage-producers-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProducerListComponent,
    ProducerUserCreateComponent,
    NgbModalModule,
    TodoComponent,
    IconComponent,
    StorageNavComponent,
  ],
  templateUrl: "./storage-producers-view.component.pug",
  styleUrls: ["./storage-producers-view.component.sass"],
})
export class StorageProducersViewComponent extends BaseDetailViewComponent<
  StorageProducersViewData,
  Storage
> {
  public reload$ = new Subject<void | boolean>();
  public storageFilter$!: Observable<FilterDefaults>;
  public readonly PT = PRODUCER_TYPES;

  public producer_detail_route?: Link<ProducerBase>;
  public storage_detail_route?: Link<StorageBase>;
  public default_fields = [
    // 'id',
    "name",
    // 'logo_details',
    // 'slabels_details',
    "plabels_details",
    "location_details",
    "storage_relations_details",
    // 'location_postcode',
    // 'location_city',
    // 'activity', // TODO: activity stats
    "nb_products",
    // 'documents_details', // TODO: documents links
    // 'status',
    // 'flags',
    "actions",
  ];
  public printSettings: PrintSettings = PRODUCER_PRINT_SETTINGS;
  // Header
  public subtitle = signal("Producteurs");

  // Creation items
  public createProducerType = signal<PRODUCER_TYPES>(PRODUCER_TYPES.FL);
  public canCreate = signal<boolean>(false);
  public ofc$ = inject(NgbOffcanvas);
  public createOfcRef?: NgbOffcanvasRef;
  @ViewChild("createOfcSlot", { read: TemplateRef })
  createOfcSlot!: TemplateRef<unknown>;
  protected readonly STORAGE_TYPES = STORAGE_TYPES;
  protected readonly PRODUCER_TYPES = PRODUCER_TYPES;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageProducersViewData) {
    super.setRouteData(data);
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(
        data.producer_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(
        data.storage_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage) {
      this.canCreate.set(data.storage.have_level(STORAGE_USER_LEVELS.ADMIN));
    }
    if (data.local === LOCAL_FILTERS.LOCAL_CONTEXT) {
      this.subtitle.set("Producteurs locaux");
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.storage.id,
        local: data.local,
      })),
    );
  }

  public create(storage?: Storage, type?: PRODUCER_TYPES) {
    // Guess type from data
    if (!type && storage) {
      if (storage.type === STORAGE_TYPES.FL) {
        type = PRODUCER_TYPES.FL;
      } else if (storage.type === STORAGE_TYPES.SEA) {
        type = PRODUCER_TYPES.MR;
      }
    }
    this.createProducerType.set(type || PRODUCER_TYPES.FL);

    if (this.createOfcRef) {
      this.createOfcRef.close();
      delete this.createOfcRef;
    } else {
      this.createOfcRef = this.ofc$.open(this.createOfcSlot, {
        position: "bottom",
        panelClass: "h-auto",
      });
    }
  }
}
