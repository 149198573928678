import { RoutesConfig } from '@solidev/data';
import { RS } from '@vivalya/lvadg';

export const FRONT_METEO_ROUTES_NAMES = ['meteo'] as const;

export const FRONT_METEO_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_METEO_ROUTES_NAMES)[number]> = {
  meteo: {
    params: [],
    route: () => ['/', RS.meteo],
    name: 'meteo',
  },
};
