import { Component, Input, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Client } from "../client";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { ClientService } from "../client.service";
import { LocationManageComponent } from "../../../locations/location/location-manage/location-manage.component";
import { LocationCreateComponent } from "../../../locations/location/location-create/location-create.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { LOCATION_TYPE } from "../../../locations/location/location.base";
import { ClientActionService } from "../client-action.service";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { LabelsManagerComponent } from "../../../catalog/label/labels-manager/labels-manager.component";

@Component({
  selector: "lvadg-client-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    IconComponent,
    LocationManageComponent,
    LocationCreateComponent,
    LogoManageComponent,
    GalleryManageComponent,
    LabelsManagerComponent,
  ],
  templateUrl: "./client-manage.component.pug",
  styleUrls: ["./client-manage.component.sass"],
})
export class ClientManageComponent implements OnInit {
  @Input() public client!: Client;
  public LBT = LABEL_TYPE;
  public LT = LOCATION_TYPE;
  protected allow_labels_edition = signal(false);

  constructor(
    public client$: ClientService,
    public cact: ClientActionService,
  ) {}

  ngOnInit(): void {}
}
