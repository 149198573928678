import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { GENERICREGION_TYPE, GenericregionBase } from '../genericregion.base';
import { GenericregionService } from '../genericregion.service';
import { ClientService } from '../../../structures/client/client.service';

@Component({
  selector: 'lvadg-genericregion-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './genericregion-create.component.pug',
  styleUrls: ['./genericregion-create.component.sass'],
})
export class GenericregionCreateComponent implements OnInit {
  public genericregion!: GenericregionBase;
  public createForm = new FormGroup({});
  public GENERICREGION_TYPE = GENERICREGION_TYPE;
  @Output() public created = new EventEmitter<GenericregionBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _genericregions: GenericregionService,
    private _msgs: DataMessageService,
    public clients$: ClientService,
  ) {}

  ngOnInit(): void {
    this.genericregion = new GenericregionBase(this._genericregions);
  }

  public async save() {
    this.genericregion.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.genericregion.save({ updateModel: true }));
      this.created.emit(this.genericregion);
      this._msgs.success('Région générique créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
