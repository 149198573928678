export enum ZONES {
  FLD = 'FLD',
  FLMRD = 'FLMRD',
  MRD = 'MRD',
  FLT = 'FLT',
  FLMRT = 'FLMRT',
  MRT = 'MRT',
}

export const ZONES_LIST = [
  ZONES.FLD,
  ZONES.FLMRD,
  ZONES.MRD,
  ZONES.FLT,
  ZONES.FLMRT,
  ZONES.MRT,
];

export const ZONES_LABELS: { [key in ZONES]: string } = {
  [ZONES.FLD]: 'F&L - Zone documents',
  [ZONES.FLMRD]: 'F&L&Marée - Zone documents',
  [ZONES.MRD]: 'Marée - Zone documents',
  [ZONES.FLT]: 'F&L - Zone tarifs', // ?? voir si on peut supprimer
  [ZONES.FLMRT]: 'F&L&Marée - Zone tarifs', // ?? voir si on peut supprimer
  [ZONES.MRT]: 'Marée - Zone tarifs', // ?? voir si on peut supprimer
};
