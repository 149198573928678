import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Resto } from "../resto";
import { RestoService } from "../resto.service";
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgStringPipesModule } from "ngx-pipes";
import { ClientBase } from "../../client/client.base";
import { LocationFiltersService } from "../../../locations/common/location-filters.service";
import { ClientService } from "../../client/client.service";
import { RestoStatus } from "../resto.base";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { RelationDisplayComponent } from "../../relation/relation-display/relation-display.component";
import { Storage } from "../../storage/storage";
import {RelationBase} from "../../relation/relation.base";

@Component({
  selector: "lvadg-resto-list",
  standalone: true,
  templateUrl: "./resto-list.component.pug",
  styleUrls: ["./resto-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule,
    LocationDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    RelationDisplayComponent,
    IconComponent,
  ],
})
export class RestoListComponent extends ModellistComponent<Resto> {
  @Input() client_detail_route?: Link<ClientBase>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public relation_detail_route?: Link<RelationBase>;

  constructor(
    private _clients: ClientService,
    private _locFilters: LocationFiltersService,
    coll: RestoService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filtrer par état",
          model: Resto,
        }),
        new ModelListSelectMultiFilter({
          field: "type",
          name: "type",
          label: "Filtrer par type(s)",
          model: Resto,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "clients",
          name: "clients",
          label: "Recherche par client(s)",
          display: "name",
          collection: this._clients,
        }),
        ...this._locFilters.getFilters(),
      ],
    };
  }

  public override getRowClasses(row: Resto): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === RestoStatus.INACTIVE) {
      cls.push("table-secondary");
      cls.push("text-muted");
    }
    if (row.status === RestoStatus.ACTIVE_NEW) {
      cls.push("table-warning");
    }
    return cls;
  }
}
