import { Component, inject } from "@angular/core";
import {
  BaseRouteParams,
  DataMessageService,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
} from "@solidev/data";
import { PROVIDER_ROUTES } from "../menu";
import { Provider } from "../../../../../models/structures/provider/provider";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProviderNavComponent } from "../_nav/provider-nav.component";
import { ProviderManageComponent } from "../../../../../models/structures/provider/provider-manage/provider-manage.component";
import { DB_CHECKS } from "../../../../../constants";

export interface ProviderEditViewParams extends BaseRouteParams {
  pr_menu: PROVIDER_ROUTES;
  provider_list_route?: RouteConfigItem;
}

export interface ProviderEditViewData extends ProviderEditViewParams {
  provider: Provider;
}

@Component({
  selector: "lvadg-provider-edit-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProviderNavComponent,
    SafeDeleteComponent,
    ProviderManageComponent,
  ],
  templateUrl: "./provider-edit-view.component.pug",
  styleUrl: "./provider-edit-view.component.sass",
})
export class ProviderEditViewComponent extends BaseDetailViewComponent<
  ProviderEditViewData,
  Provider
> {
  public provider_list_route?: Link<never>;
  protected readonly DB_CHECKS = DB_CHECKS;
  private _msgs = inject(DataMessageService);

  public override setRouteData(data: ProviderEditViewData) {
    super.setRouteData(data);
    if (data.provider_list_route) {
      this.provider_list_route = new Link(
        data.provider_list_route,
        data,
        this._router,
      );
    }
  }

  public deleted() {
    this._msgs.success("Le fournisseur a été supprimé avec succès.");
    if (this.provider_list_route) {
      this.provider_list_route.navigate();
    }
    this._router.navigate(["../.."], { relativeTo: this._route });
  }
}
