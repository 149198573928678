import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VivalyaBill } from '../vivalya-bill';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-vivalya-bill-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './vivalya-bill-display.component.pug',
  styleUrls: ['./vivalya-bill-display.component.sass'],
})
export class VivalyaBillDisplayComponent {
  @Input() public vivalyabill?: VivalyaBill;
  @Input() public mode = 'line' as const;
  @Input() public detail_route?: Link<VivalyaBill>;

  constructor() {}
}
