import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifBase } from '../../tarif.base';
import { TarifLifecycleStepService } from '../../../tarif-lifecycle-step/tarif-lifecycle-step.service';
import { TarifLogService } from '../../../tarif-log/tarif-log.service';
import { BehaviorSubject, combineLatest, map, Observable, switchMap } from 'rxjs';
import { TarifLifecycleStep } from '../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { UserDisplayComponent } from '../../../../users/user/user-display/user-display.component';
import { LIFECYCLE_MAIN_STEP, LIFECYCLE_SUB_STEP } from '../lifecycle-steps';
import { TarifService } from '../../tarif.service';
import { TarifLifecycleStepEditComponent } from './steps/tarif-lifecycle-step-edit/tarif-lifecycle-step-edit.component';
import { TarifLifecycleStepDoneComponent } from './steps/tarif-lifecycle-step-done/tarif-lifecycle-step-done.component';
import { TarifLifecycleStepNegosendComponent } from './steps/tarif-lifecycle-step-negosend/tarif-lifecycle-step-negosend.component';
import { TarifLifecycleStepNegoupdateComponent } from './steps/tarif-lifecycle-step-negoupdate/tarif-lifecycle-step-negoupdate.component';
import { TarifLifecycleStepPublishComponent } from './steps/tarif-lifecycle-step-publish/tarif-lifecycle-step-publish.component';
import { TarifLifecycleStepUploadComponent } from './steps/tarif-lifecycle-step-upload/tarif-lifecycle-step-upload.component';
import { TarifLifecycleStepArticlesComponent } from './steps/tarif-lifecycle-step-articles/tarif-lifecycle-step-articles.component';
import { TarifLifecycleActionService } from '../tarif-lifecycle-action.service';
import { TarifLifecycleAction } from '../lifecycle-actions';
import { Tarif } from '../../tarif';
import { Tariftype } from '../../../tariftype/tariftype';
import { TARIF_ROUTES } from '../../../../../views/tarifs/tarifs/detail/menu';

@Component({
  selector: 'lvadg-tarif-lifecycle',
  standalone: true,
  imports: [
    CommonModule,
    UserDisplayComponent,
    TarifLifecycleStepEditComponent,
    TarifLifecycleStepUploadComponent,
    TarifLifecycleStepDoneComponent,
    TarifLifecycleStepNegosendComponent,
    TarifLifecycleStepNegoupdateComponent,
    TarifLifecycleStepPublishComponent,
    TarifLifecycleStepArticlesComponent,
  ],
  templateUrl: './tarif-lifecycle.component.pug',
  styleUrls: ['./tarif-lifecycle.component.sass'],
})
export class TarifLifecycleComponent implements OnInit {
  @Input() public tarif!: TarifBase;
  @Input() public tariftype!: Tariftype;
  @Input() public menu?: TARIF_ROUTES;
  @Output() public changed = new EventEmitter<Tarif>();
  public stept$?: Observable<TarifLifecycleStep[]>;
  public mainStep?: TarifLifecycleStep;
  public subStep?: TarifLifecycleStep;
  public reload = new BehaviorSubject<void | boolean>(true);
  public LifecycleSubSteps = LIFECYCLE_SUB_STEP;
  public LifecycleMainSteps = LIFECYCLE_MAIN_STEP;

  constructor(
    private _steps: TarifLifecycleStepService,
    private _tar: TarifService,
    private _tlog: TarifLogService,
    public taction: TarifLifecycleActionService,
  ) {}

  ngOnInit(): void {
    if (this.tarif) {
      const tar$ = this._tar.fetch(this.tarif.id, { params: { db: 'sync' } });
      const steps$ = this._steps.list({
        tariftype: this.tarif.tariftype,
        root: true,
        fields: ['id', 'step', 'substeps_details', 'order', 'message', 'data'].join(','),
      });
      const log$ = this._tlog.list({
        tarif: this.tarif.id,
        db: 'sync',
        ordering: 'id',
        fields: ['id', 'created', 'updated', 'operation', 'author', 'author_details', 'tstep', 'tstep_details'].join(
          ',',
        ),
      });
      let firstReload = true;
      this.stept$ = this.reload.pipe(
        switchMap(() => combineLatest([tar$, steps$, log$])),
        map(([tar, st, lg]) => {
          console.log('Loaded', tar, st, lg);
          for (const l of lg) {
            for (const s of st) {
              if (s.substeps_details) {
                for (const c of s.substeps_details) {
                  if (tar.tstep === c.id) {
                    this.mainStep = s;
                    this.subStep = c;
                  }
                  if (l.tstep === c.id) {
                    c.log_items.push(l);
                  }
                }
              }
            }
          }
          if (!firstReload) {
            this.changed.emit(tar);
          }
          firstReload = false;
          return st;
        }),
      );
    }
    this.reload.next(true);
  }

  public async action(action: TarifLifecycleAction) {
    const res = await this.taction.action(this.tarif, action);
    if (res.success) {
      this.reload.next(true);
    } else {
      console.error(res);
    }
  }

  public isMainDone(tarif: Tarif, steps: TarifLifecycleStep[], curs: TarifLifecycleStep): boolean {
    const mains = steps.find((s) => s.id === (curs.parent || curs.id));
    if (!mains || !mains.substeps_details) {
      return false;
    }
    let all = true;
    for (const s of mains.substeps_details) {
      all = all && !!(s.last_log_item && s.last_log_item.operation === 'done');
    }
    return all;
  }
}
