import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Article } from "../article";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { ArticleLocal } from "../article.base";
import { LabelDisplayComponent } from "../../label/label-display/label-display.component";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { PChoicePipe, PFactorcPipe, PFactorPipe } from "@solidev/data";
import { EGALIM_STATUS } from "../../common";
import { ProviderDocumentDisplayComponent } from "../../../structures/provider-document/provider-document-display/provider-document-display.component";
import { ArticleDocumentDisplayComponent } from "../../article-document/article-document-display/article-document-display.component";

@Component({
  selector: "lvadg-article-detail",
  standalone: true,
  imports: [
    CommonModule,
    ImageDisplayComponent,
    LabelDisplayComponent,
    SeasonsManageComponent,
    PFactorcPipe,
    PFactorPipe,
    ProviderDocumentDisplayComponent,
    ArticleDocumentDisplayComponent,
    PChoicePipe,
  ],
  templateUrl: "./article-detail.component.pug",
  styleUrls: ["./article-detail.component.sass"],
})
export class ArticleDetailComponent {
  @Input({ required: true }) public article!: Article;
  public readonly EGS = EGALIM_STATUS;
  public readonly AL = ArticleLocal;
}
