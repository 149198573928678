import { RoutesConfig } from '@solidev/data';
import { News, RS } from '@vivalya/lvadg';

export const FRONT_RNM_ROUTES_NAMES = ['tarifs_rnm', 'tarifs_rnm_tarifs_rnm'] as const;

export const FRONT_RNM_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_RNM_ROUTES_NAMES)[number]> = {
  tarifs_rnm: {
    name: 'tarifs_rnm',
    params: [],
    route: () => ['/', RS.rnmtarifs],
  },
  tarifs_rnm_tarifs_rnm: {
    name: 'tarifs_rnm_tarifs_rnm',
    params: ['rnmtarif'],
    route: (r: News) => ['/', RS.rnmtarifs, r.id],
  },
};
