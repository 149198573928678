import { Component } from '@angular/core';

import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { map, Observable } from 'rxjs';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { Provider } from '../../../../models/structures/provider/provider';
import { ArticleListComponent } from '../../../../models/catalog/article/article-list/article-list.component';
import { ProviderManageComponent } from '../../../../models/structures/provider/provider-manage/provider-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { ArticleBase } from '../../../../models/catalog/article/article.base';
import { FamilyBase } from '../../../../models/catalog/family/family.base';

export interface ProviderDetailViewParams extends BaseRouteParams {
  article_detail_route: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
}

export interface ProviderDetailViewData extends ProviderDetailViewParams {
  provider: Provider;
}

@Component({
  selector: 'lvadg-provider-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ArticleListComponent, ProviderManageComponent],
  templateUrl: './provider-detail-view.component.pug',
  styleUrls: ['./provider-detail-view.component.sass'],
})
export class ProviderDetailViewComponent extends BaseDetailViewComponent<ProviderDetailViewData, Provider> {
  public article_detail_route?: Link<ArticleBase>;
  public family_detail_route?: Link<FamilyBase>;
  public fournisseur$!: Observable<FilterDefaults>;
  public articles_default_fields = [
    'id',
    'code',
    'libelle',
    'fournisseur_details',
    'udf_unit',
    'udv',
    'egalim_details',
    'origine_details',
    'local',
    'egalim_status',
    'calibre_details',
    'categorie_details',
    'colisage',
    'uci',
    'flags',
    'updated',
  ];

  public override setRouteData(data: ProviderDetailViewData) {
    super.setRouteData(data);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data);
    if (data.family_detail_route) {
      this.family_detail_route = new Link<FamilyBase>(data.family_detail_route, data);
    }

    // this.some_route = new Link<any>(data.some_route, this);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.fournisseur$ = this.data$.pipe(map((data) => ({ fournisseurs: data.provider.id })));
  }
}
