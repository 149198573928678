import { UserBase } from './user.base';
import { detailsField } from '@solidev/data';
import { Usergrouplink } from '../usergrouplink/usergrouplink';

export class User extends UserBase {
  static override readonly __name: string = 'User';

  @detailsField({
    readonly: true,
    description: 'Détails groupes',
    many: true,
    model: Usergrouplink,
    priority: 500,
  })
  public grouplinks_details?: Usergrouplink[];

  @detailsField({
    readonly: false,
    description: 'Groupes courants',
    priority: 450,
  })
  public current_groups_slugs?: string[];

  /**
   * Check if the user is in one of the given groups.
   * @param groups The groups to check, as a string or a regexp.
   */
  public have_group(...groups: (string | RegExp)[]) {
    const regexps = groups.filter((g) => g instanceof RegExp) as RegExp[];
    const strings = groups.filter((g) => !(g instanceof RegExp)) as string[];
    return this.current_groups_slugs?.some((g) => {
      return regexps.some((r) => r.test(g)) || strings.includes(g);
    });
  }
}
