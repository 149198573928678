import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

import { map, Observable } from "rxjs";
import { FilterDefaults, Link, RouteConfigItem } from "@solidev/data";

import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { EamappingListComponent } from "../../../../../models/catalog/eamapping/eamapping-list/eamapping-list.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { ArticleBase } from "../../../../../models/catalog/article/article.base";
import { EarticleBase } from "../../../../../models/catalog/earticle/earticle.base";
import { TariftypeBase } from "../../../../../models/tarifs/tariftype/tariftype.base";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";

export interface ArticleEamappingsViewParams extends ArticleViewParams {
  article_detail_route: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
}

export interface ArticleEamappingsViewData
  extends ArticleEamappingsViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-eamappings-view",
  standalone: true,
  imports: [
    CommonModule,
    EamappingListComponent,
    ArticleNavComponent,
    HeaderActionComponent,
  ],
  templateUrl: "./article-eamappings-view.component.pug",
  styleUrls: ["./article-eamappings-view.component.sass"],
})
export class ArticleEamappingsViewComponent extends BaseDetailViewComponent<
  ArticleEamappingsViewData,
  Article
> {
  public articleEamappingFilter$!: Observable<FilterDefaults>;
  public article_detail_route!: Link<ArticleBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public tariftype_detail_route!: Link<TariftypeBase>;
  public default_fields: string[] = [
    "id",
    "earticle_details",
    "tariftype_details",
    "earticle_code",
    // 'earticle_gamme',
    // 'earticle_sgamme',
    "earticle_libelle",
    // 'earticle_libelle2',
    "earticle_categorie",
    "earticle_calibre",
    "earticle_labels",
    "earticle_uf",
    "earticle_uc",
    "earticle_ucuf",
    "earticle_ucond",
    // 'earticle_ucond_string',
    // 'earticle_ucond_unit',
    // 'earticle_ucond_value',
    "earticle_nego_unit",
    "earticle_marque",
    "status",
    "updated",
  ];

  public override postNgOnInit() {
    super.postNgOnInit();
    this.articleEamappingFilter$ = this.data$.pipe(
      map((data) => ({ article: data.article.id })),
    );
  }

  public override setRouteData(data: ArticleEamappingsViewData) {
    super.setRouteData(data);
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      this,
    );
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link<EarticleBase>(
        data.earticle_detail_route,
        this,
      );
    }
    this.tariftype_detail_route = new Link<TariftypeBase>(
      data.tariftype_detail_route,
      this,
    );
  }
}
