import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  decimalField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';
import { IntervalGrouper } from '../../../components/utils/periods';

export class RnmatcacheBase extends DataModel {
  static override readonly __name: string = 'RnmatcacheBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @dateField({
    name: 'date',
    description: 'Date',
  })
  public date!: Date;

  @charField({
    name: 'grouper',
    description: 'Regroupement',
    defaultValue: 'd',
    maxLength: 1,
    choices: [
      {
        value: IntervalGrouper.YEAR,
        desc: 'Année',
      },
      {
        value: IntervalGrouper.QUARTER,
        desc: 'Trimestre',
      },
      {
        value: IntervalGrouper.MONTH,
        desc: 'Mois',
      },
      {
        value: IntervalGrouper.WEEK,
        desc: 'Semaine',
      },
      {
        value: IntervalGrouper.DAY,
        desc: 'Jour',
      },
    ],
  })
  public grouper: IntervalGrouper = IntervalGrouper.DAY;

  @foreignKeyField({
    name: 'marche',
    description: 'ID Marché',
    related: 'RnmMarche',
  })
  public marche!: number;

  @foreignKeyField({
    name: 'libelle',
    description: 'ID Libellé',
    related: 'RnmLibelle',
  })
  public libelle!: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
  })
  public article!: number;

  @decimalField({
    name: 'price',
    description: 'price',
    factor: 10000,
  })
  public price!: number;
}
