
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class TarifStorageStatusBase extends DataModel {
  static override readonly __name: string = "TarifStorageStatusBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @datetimeField({"name": "created", "description": "created", "readonly": true})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated", "readonly": true})
  public updated!: Date;

  @foreignKeyField({"name": "tarif", "description": "tarif", "related": "Tarif"})
  public tarif!: number;

  @foreignKeyField({"name": "storage", "description": "storage", "related": "Storage"})
  public storage!: number;

  @foreignKeyField({"name": "user", "description": "user", "related": "User"})
  public user!: number;

  @charField({"name": "status", "description": "status", "defaultValue": "EM", "maxLength": 2, "choices": [{"value": "EM", "desc": "Aucune saisie"}, {"value": "FL", "desc": "Saisie en cours"}, {"value": "VA", "desc": "Valid\u00e9"}]})
  public status: string = "EM";


}
