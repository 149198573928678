import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { Subject } from 'rxjs';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { TariftypeListComponent } from '../../../../models/tarifs/tariftype/tariftype-list/tariftype-list.component';
import { TariftypeCreateComponent } from '../../../../models/tarifs/tariftype/tariftype-create/tariftype-create.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { GroupBase } from '../../../../models/users/group/group.base';
import { ClientBase } from '../../../../models/structures/client/client.base';
import { UserBase } from '../../../../models/users/user/user.base';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';

export interface TariftypeListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
}

export interface TariftypeListViewData extends TariftypeListViewParams {}

@Component({
  selector: 'lvadg-tariftype-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TariftypeListComponent, TariftypeCreateComponent, IconComponent],
  templateUrl: './tariftype-list-view.component.pug',
  styleUrls: ['./tariftype-list-view.component.sass'],
})
export class TariftypeListViewComponent extends BaseListViewComponent<TariftypeListViewData, Tariftype> {
  public group_detail_route?: Link<GroupBase>;
  public client_detail_route?: Link<ClientBase>;
  public user_detail_route?: Link<UserBase>;
  public default_fields = [
    'id',
    'code',
    'name',
    'client_details',
    'base_interval',
    'lifecycle',
    'segment',
    'flags',
    'status',
  ];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: TariftypeListViewData) {
    super.setRouteData(data);
    if (data.group_detail_route) {
      this.group_detail_route = new Link<GroupBase>(data.group_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link<ClientBase>(data.client_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link<UserBase>(data.user_detail_route, data, this._router);
    }
  }
}
