
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Contact } from "./contact";

@Injectable({
  providedIn: 'root'
})
export class ContactService extends Collection<Contact> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/contacts", Contact);
  }
}
