import { Component, inject, input, model, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Family } from "../family";
import {
  DispeditComponent,
  FilterDefaults,
  Link,
  SafeDeleteComponent,
} from "@solidev/data";
import { FamilyService } from "../family.service";
import { FAMILY_TYPE, FamilyBase } from "../family.base";
import { FamilyDisplayComponent } from "../family-display/family-display.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { debounceTime, firstValueFrom, switchMap } from "rxjs";
import { FamilyActionService } from "../family-action.service";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lvadg-family-manage",
  standalone: true,
  imports: [
    CommonModule,
    FamilyDisplayComponent,
    IconComponent,
    ReactiveFormsModule,
    ImageDisplayComponent,
    LogoManageComponent,
    GalleryManageComponent,
    SeasonsManageComponent,
    DispeditComponent,
    SafeDeleteComponent,
    RouterLink,
  ],
  templateUrl: "./family-manage.component.pug",
  styleUrls: ["./family-manage.component.sass"],
})
export class FamilyManageComponent implements OnInit {
  public family = model.required<Family>();
  public family_detail_route = input<Link<FamilyBase>>();

  public family$ = toObservable(this.family);
  public destForm = new FormControl("");
  public filter: FilterDefaults = {};
  public familyActions$ = inject(FamilyActionService);
  public families$ = inject(FamilyService);
  public children$ = this.family$.pipe(
    switchMap((f) =>
      this.families$.list({
        parent: f.id,
        fields: [
          "id",
          "parent",
          "name",
          "tree_path",
          "tree_depth",
          "nbproducts",
          "nbarticles",
        ].join(","),
      }),
    ),
  );
  public stats$ = this.family$.pipe(
    switchMap((f) =>
      this.families$.fetch(f.id, {
        params: {
          fields: ["id", "nbproducts", "nbarticles"].join(","),
        },
      }),
    ),
  );

  public destSelected = signal<Family | null>(null);
  public destResults = this.destForm.valueChanges.pipe(
    takeUntilDestroyed(),
    debounceTime(500),
    switchMap((value) =>
      this.families$.list({
        search: value,
        type: FAMILY_TYPE.VIVALYA,
        fields: [
          "id",
          "parent",
          "name",
          "tree_path",
          "tree_depth",
          "nbproducts",
          "nbarticles",
        ].join(","),
      }),
    ),
  );

  ngOnInit(): void {
    this.filter = { exclude_childs: this.family()?.id };
  }

  public async saveSeasons(s: number[]) {
    this.family().article_seasons = s;
    await firstValueFrom(this.family().update(["article_seasons"]));
  }

  public async updateComputedFields() {
    await this.familyActions$.action(this.family(), {
      action: "refresh_computed_fields",
    });
  }

  public async moveFamily(dest: number, doit: boolean = true) {
    const res = await this.familyActions$.action(this.family(), {
      action: "move",
      dest,
      doit,
    });
    console.log(res);
    this.family.set(
      await firstValueFrom(this.families$.fetch(this.family().id)),
    );
  }

  public async mergeFamily(dest: number, doit: boolean = true) {
    const res = await this.familyActions$.action(this.family(), {
      action: "merge",
      dest,
      doit,
    });
    console.log(res);
    if (res.success && res.family) {
      const family = await firstValueFrom(this.families$.fetch(res.family.id));
      this.family_detail_route()?.navigate({ family });
    }
  }

  public async moveProducts(dest: number, doit: boolean = true) {
    const res = await this.familyActions$.action(this.family(), {
      action: "move_products",
      dest,
      doit,
    });
    console.log(res);
    this.family.set(
      await firstValueFrom(this.families$.fetch(this.family().id)),
    );
  }

  public async moveArticles(dest: number, doit: boolean = true) {
    const res = await this.familyActions$.action(this.family(), {
      action: "move_articles",
      dest,
      doit,
    });
    console.log(res);
    this.family.set(
      await firstValueFrom(this.families$.fetch(this.family().id)),
    );
  }
}
