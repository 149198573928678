import { TariftypeStoragePermissionBase } from './tariftype-storage-permission.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { StorageBase } from '../../structures/storage/storage.base';

export class TariftypeStoragePermission extends TariftypeStoragePermissionBase {
  static override readonly __name: string = 'TariftypeStoragePermission';

  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: StorageBase,
    readonly: true,
    description: 'Dépôt',
  })
  public storage_details?: StorageBase;
}
