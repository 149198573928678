
<!-- Creation form for location with address, poscode, city and country fields-->
<form *ngIf="createForm" [formGroup]="createForm" (submit)="create()">
  <div class="row g-3 mb-3">
    <data-dispedit class="col-12" [model]="item" field="address" mode="form" [form]="createForm">Adresse</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="postcode" mode="form" [form]="createForm">Code postal</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="city" mode="form" [form]="createForm">Ville</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="country" mode="form" [form]="createForm">Code Pays</data-dispedit>
  </div>
  <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
  <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
</form>