import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '../../../../../models/structures/storage/storage';
import { map, Observable, Subject } from 'rxjs';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { CdataListComponent } from '../../../../../models/cdata/cdata/cdata-list/cdata-list.component';
import { CdataCreateComponent } from '../../../../../models/cdata/cdata/cdata-create/cdata-create.component';
import { StorageNavComponent } from '../_nav/storage-nav.component';
import { STORAGE_ROUTES } from '../menu';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';

export interface StorageCdatasViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
}

export interface StorageCdatasViewData extends StorageCdatasViewParams {
  storage: Storage;
}

@Component({
  selector: 'lvadg-storage-cdatas-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    CdataListComponent,
    CdataCreateComponent,
    StorageNavComponent,
  ],
  templateUrl: './storage-cdatas-view.component.pug',
  styleUrls: ['./storage-cdatas-view.component.sass'],
})
export class StorageCdatasViewComponent extends BaseDetailViewComponent<StorageCdatasViewData, Storage> {
  public reload$ = new Subject<void | boolean>();
  public storageFilter$!: Observable<FilterDefaults>;
  public createCData = false;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageCdatasViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.storage.id,
      })),
    );
  }
}
