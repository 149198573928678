
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-flags-display [item]="row"></lvadg-flags-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'short'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="type" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.type|pchoice:row:'type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="client">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.client}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="storage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storage" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.storage}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="member">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="member" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.member}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="collection">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="collection" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.collection}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="code" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="icode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="icode" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.icode}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="ean13">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ean13" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.ean13}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="gamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="gamme" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.gamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="sgamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="sgamme" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.sgamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="marque">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="marque" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.marque}}</td>
        </ng-container>
        <ng-container cdkColumnDef="libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="libelle" [sort]="true"></data-model-list-field-header>
          </th>
          <td class="d200" cdk-cell *cdkCellDef="let row">{{row.libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="libelle2">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="libelle2" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.libelle2}}</td>
        </ng-container>
        <ng-container cdkColumnDef="categorie">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="categorie" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.categorie}}</td>
        </ng-container>
        <ng-container cdkColumnDef="calibre">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="calibre" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.calibre}}</td>
        </ng-container>
        <ng-container cdkColumnDef="origine">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="origine" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.origine}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uf" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uf}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uf_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uf_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uf_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uf_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uf_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uf_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uc">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uc" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uc}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uc_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uc_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uc_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="uc_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="uc_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.uc_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ul">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ul"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ul}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ul_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ul_value"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ul_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ul_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ul_unit"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ul_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="pe">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pe" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.pe}}</td>
        </ng-container>
        <ng-container cdkColumnDef="pe_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pe_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.pe_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="pe_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pe_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.pe_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ucuf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ucuf" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ucuf}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ucul">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ucul"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ucul}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ucond">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ucond" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ucond}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ucond_value">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ucond_value" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ucond_value}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ucond_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ucond_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ucond_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tax">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tax"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.tax}}</td>
        </ng-container>
        <ng-container cdkColumnDef="conservation">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="conservation"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.conservation}}</td>
        </ng-container>
        <ng-container cdkColumnDef="nego_unit">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="nego_unit" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.nego_unit}}</td>
        </ng-container>
        <ng-container cdkColumnDef="importref">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="importref" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.importref}}</td>
        </ng-container>
        <ng-container cdkColumnDef="xlsxhash">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="xlsxhash" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.xlsxhash}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_data">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_data" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <pre class="json">{{row.orig_data|json}}</pre>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="articles">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="articles" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.articles}}</td>
        </ng-container>
        <ng-container cdkColumnDef="labels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="labels"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.labels}}</td>
        </ng-container>
        <ng-container cdkColumnDef="code_gamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="code_gamme"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.code_gamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="code_sgamme">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="code_sgamme"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.code_sgamme}}</td>
        </ng-container>
        <ng-container cdkColumnDef="typologie">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="typologie"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.typologie}}</td>
        </ng-container>
        <ng-container cdkColumnDef="client_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="client_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-client-display [client]="row.client_details" mode="line" [detail_route]="client_detail_route">)</lvadg-client-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="storage_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storage_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-storage-display [storage]="row.storage_details" mode="line" [detail_route]="storage_detail_route"></lvadg-storage-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="member_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="member_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-member-display [member]="row.member_details" mode="line" [detail_route]="member_detail_route"></lvadg-member-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="collection_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="collection_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-eacollection-display [eacollection]="row.collection_details" mode="line" [detail_route]="collection_detail_route"></lvadg-eacollection-display>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>