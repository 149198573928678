
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Cartographie</h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <lvadg-todo>
      <div class="item">TODO: gestion cartographie</div>
    </lvadg-todo>
  </div>
</ng-template>
<lvadg-icon class="fs-5" role="button" ri="map" (click)="clickIcon()" [icon_only]="true" title="Afficher la cartographie"></lvadg-icon>