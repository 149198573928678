import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CdataKey } from '../cdata-key';
import { firstValueFrom } from 'rxjs';
import { CdataKeyService } from '../cdata-key.service';

@Component({
  selector: 'lvadg-cdata-key-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './cdata-key-create.component.pug',
  styleUrls: ['./cdata-key-create.component.sass'],
})
export class CdataKeyCreateComponent implements OnInit {
  public createForm!: FormGroup<{ name?: FormControl<string> }>;
  @Output() public created = new EventEmitter<CdataKey>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: CdataKey;

  constructor(private _items: CdataKeyService, public keys$: CdataKeyService) {}

  ngOnInit(): void {
    this.item = new CdataKey(this._items);
    this.createForm = this.item.FC([
      'name',
      'description',
      'code',
      'type',
      'unit',
      'dest',
      'parent',
    ]);
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }
}
