import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class TarifLogBase extends DataModel {
  static override readonly __name: string = 'TarifLogBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({ name: 'tarif', description: 'ID Tarif', related: 'Tarif' })
  public tarif!: number;

  @foreignKeyField({
    name: 'author',
    description: 'ID Auteur',
    related: 'User',
  })
  public author!: number;

  @charField({
    name: 'operation',
    description: 'Opération',
    maxLength: 100,
  })
  public operation!: string;

  @charField({
    name: 'step',
    description: 'Étape',
    maxLength: 10,
  })
  public step!: string;

  @detailsField({
    name: 'data',
    description: 'Data',
    defaultValue: {},
  })
  public data: any = {};

  @integerField({
    name: 'orig_id',
    description: 'orig id',
  })
  public orig_id!: number;

  @foreignKeyField({
    name: 'ptstep',
    description: 'ID Step précédent',
    related: 'TarifLifecycleStep',
  })
  public ptstep!: number;

  @foreignKeyField({
    name: 'tstep',
    description: 'ID Step après',
    related: 'TarifLifecycleStep',
  })
  public tstep!: number;
}
