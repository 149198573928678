import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberBase } from '../member.base';
import { Member } from '../member';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-member-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './member-display.component.pug',
  styleUrls: ['./member-display.component.sass'],
})
export class MemberDisplayComponent implements OnInit {
  @Input() public member?: Member | MemberBase;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<MemberBase>;

  constructor() {}

  ngOnInit(): void {}
}
