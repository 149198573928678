import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import {
  OFFER_TYPES,
  OFFER_ZONES,
} from "../../../../models/offers/offer/offer.base";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { BaseViewComponent } from "../../../../components/baseview/baseview.component";
import { combineLatest, debounceTime, map, Observable, startWith } from "rxjs";
import { OffertypeStorage } from "../../../../models/offers/offertype-storage/offertype-storage";
import { Offer } from "../../../../models/offers/offer/offer";
import { OffertypeStorageShortlistComponent } from "../../../../models/offers/offertype-storage/offertype-storage-shortlist/offertype-storage-shortlist.component";
import { TodoComponent } from "../../../../components/utils/todo/todo.component";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { StorageService } from "../../../../models/structures/storage/storage.service";
import { ClientService } from "../../../../models/structures/client/client.service";
import { OFFERTYPE_ALARM } from "../../../../models/offers/offertype-storage/offertype-storage.base";
import { filterNullish } from "../../../../utils";
import { OFFERTYPE_TYPES } from "../../../../models/offers/offertype/offertype.base";

export interface OffertypeStorageSummaryViewParams extends BaseRouteParams {
  ots_detail_routes: { [key in OFFERTYPE_TYPES]?: RouteConfigItem };
  offer_detail_route: RouteConfigItem;
  offer_zones: OFFER_ZONES[];
  offer_types: OFFER_TYPES[];
  page_title?: string;
}

export interface OffertypeStorageSummaryViewData
  extends OffertypeStorageSummaryViewParams {}

@Component({
  selector: "lvadg-offertype-storage-summary-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OffertypeStorageShortlistComponent,
    TodoComponent,
    ReactiveFormsModule,
  ],
  templateUrl: "./offertype-storage-summary-view.component.pug",
  styleUrls: ["./offertype-storage-summary-view.component.sass"],
})
export class OffertypeStorageSummaryViewComponent extends BaseViewComponent<OffertypeStorageSummaryViewData> {
  public offerTypeFilter$!: Observable<FilterDefaults>;
  public ots_detail_routes: {
    [key in OFFERTYPE_TYPES]?: Link<OffertypeStorage>;
  } = {};
  public offer_detail_route!: Link<Offer>;

  public searchForm = new FormControl("");
  public storageForm = new FormControl("");
  public clientForm = new FormControl("");
  public statusForm = new FormControl<OFFERTYPE_ALARM | "all" | "date">("all");
  public onlyMineForm = new FormControl(false);

  public statusList = [
    { id: "all", name: "Toutes les offres" },
    { id: OFFERTYPE_ALARM.NO_CURRENT, name: "Aucune offre en cours" },
    { id: "date", name: "En retard / probleme de date" },
  ];

  public storages$ = inject(StorageService).queryset();
  public clients$ = inject(ClientService).queryset();

  public override setRouteData(data: OffertypeStorageSummaryViewData) {
    super.setRouteData(data);
    for (const key in data.ots_detail_routes) {
      const k = key as OFFERTYPE_TYPES;
      if (data.ots_detail_routes[k]) {
        this.ots_detail_routes[k] = new Link(
          data.ots_detail_routes[k]!,
          data,
          this._router,
        );
      }
    }
    this.offer_detail_route = new Link(
      data.offer_detail_route,
      data,
      this._router,
    );
    const ots_filter: FilterDefaults = {
      for_ots:
        (data.offer_zones || []) + "|" + (data.offer_types || []).join(","),
    };
    this.storages$
      .filter(ots_filter)
      .setFields(["id", "name", "cvva"])
      .paginateBy(100)
      .get()
      .subscribe();
    this.clients$
      .filter(ots_filter)
      .setFields(["id", "name", "cvva"])
      .paginateBy(100)
      .get()
      .subscribe();
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offerTypeFilter$ = combineLatest([
      this.data$,
      this.searchForm.valueChanges.pipe(startWith(null), debounceTime(300)),
      this.storageForm.valueChanges.pipe(startWith(null)),
      this.clientForm.valueChanges.pipe(startWith(null)),
      this.statusForm.valueChanges.pipe(startWith("all")),
      this.onlyMineForm.valueChanges.pipe(startWith(false)),
    ]).pipe(
      map(([data, search, storage, client, alarm]) =>
        filterNullish({
          zones: (data.offer_zones || []).join(","),
          types: (data.offer_types || []).join(","),
          client: client || undefined,
          storage: storage || undefined,
          search: search || undefined,
          alarm: alarm || undefined,
          only_mine: this.onlyMineForm.value ? "true" : "false",
        }),
      ),
    );
  }
}
