import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Client } from '../../../../../models/structures/client/client';
import { CLIENT_ROUTES } from '../menu';
import { ClientBase } from '../../../../../models/structures/client/client.base';
import { RestoBase } from '../../../../../models/structures/resto/resto.base';
import { RestoListComponent } from '../../../../../models/structures/resto/resto-list/resto-list.component';
import { ClientNavComponent } from '../_nav/client-nav.component';

export interface ClientRestosViewParams extends BaseRouteParams {
  c_menu: CLIENT_ROUTES;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
}

export interface ClientRestosViewData extends ClientRestosViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-restos-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, RestoListComponent, ClientNavComponent, IconComponent],
  templateUrl: './client-restos-view.component.pug',
  styleUrls: ['./client-restos-view.component.sass'],
})
export class ClientRestosViewComponent extends BaseDetailViewComponent<ClientRestosViewData, Client> {
  public clientFilter$!: Observable<FilterDefaults>;
  public client_detail_route?: Link<ClientBase>;
  public reload$ = new Subject<void | boolean>();
  public default_fields: string[] = [];
  public resto_detail_route?: Link<RestoBase>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ClientRestosViewData) {
    super.setRouteData(data);
    if (data.client_detail_route) {
      this.client_detail_route = new Link(data.client_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link(data.resto_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.clientFilter$ = this.data$.pipe(map((d) => ({ client: d.client.id })));
  }
}
