import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, Link, RouteConfigItem, SafeDeleteComponent, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TARIFTYPE_USER_LEVEL, TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { TARIF_STATUS } from '../../../../../models/tarifs/tarif/tarif.base';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TarifManageComponent } from '../../../../../models/tarifs/tarif/tarif-manage/tarif-manage.component';
import { TARIF_ROUTES } from '../menu';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';

export interface TarifManageViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifManageViewData extends TarifManageViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-manage-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    HeaderActionComponent,
    IconComponent,
    SafeDeleteComponent,
    TarifManageComponent,
    TarifDetailNavComponent,
  ],
  templateUrl: './tarif-manage-view.component.pug',
  styleUrls: ['./tarif-manage-view.component.sass'],
})
export class TarifManageViewComponent extends BaseDetailViewComponent<TarifManageViewData, Tarif> {
  public tariftype_detail_route!: Link<TariftypeBase>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifManageViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }

  public canDelete(data: TarifManageViewData) {
    if (data.tariftype.have_level(TARIFTYPE_USER_LEVEL.sadmin)) {
      return true;
    }
    return data.tariftype.have_level(TARIFTYPE_USER_LEVEL.pilote) && data.tarif.status === TARIF_STATUS.PREPARATION;
  }
}
