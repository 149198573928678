
<form [formGroup]="createForm" (submit)="save()">
  <div class="row">
    <data-dispedit class="col-4" [model]="family" field="parent" mode="form" [form]="createForm" [collection]="families$" *ngIf="!parent">Parent</data-dispedit>
    <div class="col-4" *ngIf="parent">
      <dt>Parent<i class="bi bi-trash" role="button" (click)="parent=undefined"></i></dt>
      <dd>{{parent.full_path}}</dd>
    </div>
    <data-dispedit class="col-4" [model]="family" field="name" mode="form" [form]="createForm">Nom</data-dispedit>
    <data-dispedit class="col-4" [model]="family" field="type" mode="form" [form]="createForm">Type</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>