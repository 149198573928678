import { Component } from '@angular/core';
import { GeocountryDetailViewData } from './geocountry-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { GeocountryManageComponent } from '../../../../models/locations/geocountry/geocountry-manage/geocountry-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Geocountry } from '../../../../models/locations/geocountry/geocountry';

@Component({
  selector: 'lvadg-geocountry-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, GeocountryManageComponent],
  templateUrl: './geocountry-detail-view.component.pug',
  styleUrls: ['./geocountry-detail-view.component.sass'],
})
export class GeocountryDetailViewComponent extends BaseDetailViewComponent<GeocountryDetailViewData, Geocountry> {
  public override setRouteData(data: GeocountryDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
