import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypeStorageViewData, OffertypeStorageViewParams } from '../menu';
import { OffertypeStorageNavComponent } from '../_nav/offertype-storage-nav.component';
import { OffertypeStoragePermissionListComponent } from '../../../../../models/offers/offertype-storage-permission/offertype-storage-permission-list/offertype-storage-permission-list.component';
import { OffertypeStoragePermissionCreateComponent } from '../../../../../models/offers/offertype-storage-permission/offertype-storage-permission-create/offertype-storage-permission-create.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { OffertypeStoragePermission } from '../../../../../models/offers/offertype-storage-permission/offertype-storage-permission';
import { map, Observable, Subject } from 'rxjs';
import { User } from '../../../../../models/users/user/user';
import { Group } from '../../../../../models/users/group/group';

export interface OffertypeStoragePermissionsViewParams extends OffertypeStorageViewParams {
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
}

export interface OffertypeStoragePermissionsViewData
  extends OffertypeStoragePermissionsViewParams,
    OffertypeStorageViewData {}

@Component({
  selector: 'lvadg-offertype-storage-permissions-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    OffertypeStorageNavComponent,
    OffertypeStoragePermissionListComponent,
    OffertypeStoragePermissionCreateComponent,
  ],
  templateUrl: './offertype-storage-permissions-view.component.pug',
  styleUrls: ['./offertype-storage-permissions-view.component.sass'],
})
export class OffertypeStoragePermissionsViewComponent extends BaseDetailViewComponent<
  OffertypeStoragePermissionsViewData,
  OffertypeStoragePermission
> {
  public reload$ = new Subject<void | boolean>();
  public offertypestorageFilter$!: Observable<FilterDefaults>;
  public create_permission = false;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offertypestorageFilter$ = this.data$.pipe(
      map((data) => {
        return { offertypestorage: data.offertypestorage.id };
      }),
    );
  }

  public override setRouteData(data: OffertypeStoragePermissionsViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
  }
}
