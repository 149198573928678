import { Component } from "@angular/core";
import {
  BaseRouteParams,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { ProviderDocument } from "../../../../../models/structures/provider-document/provider-document";
import { ProviderDocumentManageComponent } from "../../../../../models/structures/provider-document/provider-document-manage/provider-document-manage.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";

export type ProviderDocumentDetailViewParams = BaseRouteParams;

export interface ProviderDocumentDetailViewData
  extends ProviderDocumentDetailViewParams {
  providerdocument: ProviderDocument;
}

@Component({
  selector: "lvadg-provider-document-detail-view",
  standalone: true,
  imports: [
    ProviderDocumentManageComponent,
    CommonModule,
    HeaderActionComponent,
    SafeDeleteComponent,
    IconComponent,
    PChoicePipe,
  ],
  templateUrl: "./provider-document-detail-view.component.pug",
  styleUrl: "./provider-document-detail-view.component.sass",
})
export class ProviderDocumentDetailViewComponent extends BaseDetailViewComponent<
  ProviderDocumentDetailViewData,
  ProviderDocument
> {
  public override setRouteData(data: ProviderDocumentDetailViewData) {
    super.setRouteData(data);
  }
}
