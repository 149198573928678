import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { RESTO_ROUTES } from '../menu';
import { Resto } from '../../../../../models/structures/resto/resto';
import { Link } from '@solidev/data';

@Component({
  selector: 'lvadg-resto-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './resto-nav.component.pug',
  styleUrl: './resto-nav.component.sass',
})
export class RestoNavComponent implements OnInit {
  @Input() public routes!: RESTO_ROUTES;
  @Input() public resto!: Resto;

  public resto_display_route?: Link<Resto>;
  public resto_edit_route?: Link<Resto>;
  public resto_relations_route?: Link<Resto>;
  public resto_offers_route?: Link<Resto>;
  public resto_sales_route?: Link<Resto>;
  public resto_users_route?: Link<Resto>;

  public RUL = {
    admin: 'admin',
  };

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes) {
      throw new Error('Missing routes input');
    }
    if (this.routes.resto_display_route) {
      this.resto_display_route = new Link(this.routes.resto_display_route, this, this._router);
    }
    if (this.routes.resto_edit_route) {
      this.resto_edit_route = new Link(this.routes.resto_edit_route, this, this._router);
    }
    if (this.routes.resto_relations_route) {
      this.resto_relations_route = new Link(this.routes.resto_relations_route, this, this._router);
    }
    if (this.routes.resto_offers_route) {
      this.resto_offers_route = new Link(this.routes.resto_offers_route, this, this._router);
    }
    if (this.routes.resto_sales_route) {
      this.resto_sales_route = new Link(this.routes.resto_sales_route, this, this._router);
    }
    if (this.routes.resto_users_route) {
      this.resto_users_route = new Link(this.routes.resto_users_route, this, this._router);
    }
  }
}
