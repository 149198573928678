import { DataModel, datetimeField, foreignKeyField, primaryField } from '@solidev/data';

export class OfferProductLinkBase extends DataModel {
  static override readonly __name: string = 'OfferProductLinkBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'offerproduct',
    description: 'ID Produit offre',
    related: 'OfferProduct',
    priority: -1,
  })
  public offerproduct!: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article lié',
    related: 'Article',
    priority: -1,
  })
  public article!: number;

  @foreignKeyField({
    name: 'earticle',
    description: 'ID Article client lié',
    related: 'ExternalArticle',
    priority: -1,
  })
  public earticle!: number;

  @foreignKeyField({
    name: 'eamapping',
    description: 'ID Mapping article',
    related: 'ExternalArticleMapping',
    priority: -1,
  })
  public eamapping!: number;

  @foreignKeyField({
    name: 'att',
    description: 'ID Article de tarif',
    related: 'ArticleTarifTemplate',
    priority: -1,
  })
  public att!: number;

  @foreignKeyField({
    name: 'at',
    description: 'ID Article tarif',
    related: 'ArticleTarif',
    priority: -1,
  })
  public at!: number;
}
