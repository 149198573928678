import { charField, primaryField } from '@solidev/data';
import { StructureBase } from '../structure.base';

export enum MemberStatus {
  ACTIVE = 'OK',
  ACTIVE_NEW = 'NEW',
  INACTIVE = 'KO',
}

export enum MemberType {
  GROUP = 'GPE',
  MONOSITE = 'SMS',
}

export class MemberBase extends StructureBase {
  static override readonly __name: string = 'MemberBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: MemberStatus.ACTIVE,
    required: true,
    maxLength: 4,
    choices: [
      {
        value: MemberStatus.ACTIVE,
        desc: 'Actif',
      },
      {
        value: MemberStatus.ACTIVE_NEW,
        desc: 'Actif - nouveau',
      },
      {
        value: MemberStatus.INACTIVE,
        desc: 'Non actif',
      },
    ],
    priority: 100,
  })
  public status: MemberStatus = MemberStatus.ACTIVE;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: MemberType.GROUP,
    maxLength: 4,
    choices: [
      {
        value: MemberType.GROUP,
        desc: 'Groupe',
      },
      {
        value: MemberType.MONOSITE,
        desc: 'Société monosite',
      },
    ],
    priority: 800,
  })
  public type: MemberStatus = MemberStatus.ACTIVE;

  public get _display(): string {
    return `${this.name}`;
  }

  public have_level(level: string): boolean {
    return true;
  }
}
