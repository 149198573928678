import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Particularity } from '../../../../../models/catalog/meta/particularity/particularity';

export interface ParticularityDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface ParticularityDetailViewData extends ParticularityDetailViewParams {
  particularity: Particularity;
}
