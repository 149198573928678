import { Component } from "@angular/core";
import { BaseRouteParams } from "@solidev/data";
import { PRODUCER_ROUTES } from "../menu";
import { Producer } from "../../../../../models/structures/producer/producer";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProducerNavComponent } from "../_nav/producer-nav.component";
import { ProducerDetailsComponent } from "../../../../../models/structures/producer/producer-details/producer-details.component";
import { Storage } from "../../../../../models/structures/storage/storage";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";
import { PrintSettings } from "../../../../../models/layouts/print-settings";
import { QuickprintButtonComponent } from "../../../../../models/layouts/print/quickprint-button/quickprint-button.component";
import { IocodeButtonComponent } from "../../../../../models/lvadgio/iocode/iocode-button/iocode-button.component";
import { LOCAL_FILTERS } from "../../../../../models/catalog/product/product.base";

export interface ProducerDisplayViewParams extends BaseRouteParams {
  pr_menu: PRODUCER_ROUTES;
  local: LOCAL_FILTERS;
}

export interface ProducerDisplayViewData extends ProducerDisplayViewParams {
  producer: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-producer-display-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProducerNavComponent,
    IconComponent,
    TodoComponent,
    ProducerDetailsComponent,
    QuickprintButtonComponent,
    IocodeButtonComponent,
  ],
  templateUrl: "./producer-display-view.component.pug",
  styleUrl: "./producer-display-view.component.sass",
})
export class ProducerDisplayViewComponent extends BaseDetailViewComponent<
  ProducerDisplayViewData,
  Producer
> {
  public print_settings: PrintSettings = [
    // Affiches producteurs
    {
      label: "Affiche producteur",
      provider: "producer",
      type: "single",
      actions: ["button"],
      template: "producer_poster",
      limit: 100,
    },
    // Fiches producteurs
    {
      label: "Fiche Producteur",
      provider: "producer",
      type: "single",
      actions: ["button"],
      template: "producer_detail",
      limit: 100,
    },
    // Fiches et produits producteurs
    {
      label: "Fiche et produits producteur",
      provider: "producer_with_products",
      type: "single",
      actions: ["button"],
      template: "producer_detail_products",
      limit: 100,
    },
  ];
}
