import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Presentation } from '../../../../../models/catalog/meta/presentation/presentation';

export interface PresentationDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface PresentationDetailViewData extends PresentationDetailViewParams {
  presentation: Presentation;
}
