
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action title="Modèles de tarif" level="h1" ri="tarifs">
    <ng-container actions>
      <button class="btn btn-primary" (click)="create=!create">
        <lvadg-icon ri="add">Ajouter un modèle de tarif</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="create">
    <div class="card-header">
      <lvadg-icon ri="add">Ajouter un modèle de tarif</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-tariftype-create (cancelled)="create=false" (created)="reload$.next(true); create=false; detail_route.navigate({tariftype: $event})"></lvadg-tariftype-create>
    </div>
  </div>
  <lvadg-tariftype-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [group_detail_route]="group_detail_route" [client_detail_route]="client_detail_route" [user_detail_route]="user_detail_route" [reload]="reload$"></lvadg-tariftype-list>
</ng-container>