import { RoutesConfig } from '@solidev/data';
import {
  ArticleTarifTemplate,
  RS,
  Tarif,
  Tariftype,
  TariftypeBase,
  TariftypeDocument,
  TariftypeMetadata,
  TariftypeRelationBase,
} from '@vivalya/lvadg';

export const FRONT_PILOTE_ROUTES_NAMES = [
  'pilote',
  'pilote_tariftypes',
  'pilote_tariftypes_tariftype',
  'pilote_tariftypes_tariftype_tarifs',
  'pilote_tariftypes_tariftype_tarifs_tarif',
  'pilote_tariftypes_tariftype_tarifs_tarif_manage',
  'pilote_tariftypes_tariftype_tarifs_tarif_lines',
  'pilote_tariftypes_tariftype_tarifs_tarif_documents',
  'pilote_tariftypes_tariftype_tarifs_tarif_notifications',
  'pilote_tariftypes_tariftype_tarifs_tarif_notifications_logs',
  'pilote_tariftypes_tariftype_tarifs_tarif_relations',
  'pilote_tariftypes_tariftype_tarifs_tarif_lifecycle',
  'pilote_tariftypes_tariftype_manage',
  'pilote_tariftypes_tariftype_documents',
  'pilote_tariftypes_tariftype_documents_document',
  'pilote_tariftypes_tariftype_documents_document_metadata',
  'pilote_tariftypes_tariftype_articles',
  'pilote_tariftypes_tariftype_articles_article',
  'pilote_tariftypes_tariftype_relations',
  'pilote_tariftypes_tariftype_relations_relation',
  'pilote_tariftypes_tariftype_storages',
  'pilote_tariftypes_tariftype_regions',
  'pilote_tariftypes_tariftype_catalog',
  'pilote_tariftypes_tariftype_destinations',
  'pilote_tariftypes_tariftype_notifications',
  'pilote_tariftypes_tariftype_notification_logs',
  'pilote_tariftypes_tariftype_permissions',
  'pilote_tariftypes_tariftype_tarifs_logs',
  'pilote_tariftypes_tariftype_prices_logs',
  'pilote_tariftypes_tariftype_lifecycle',
  'pilote_tariftypes_tariftype_mappings',
] as const;

export const FRONT_PILOTE_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_PILOTE_ROUTES_NAMES)[number]> = {
  pilote: { name: 'tarifs', params: [], route: () => ['/', RS.pilote] },
  pilote_tariftypes: {
    name: 'pilote_tariftypes',
    params: [],
    route: () => ['/', RS.pilote, RS.tariftypes],
  },

  pilote_tariftypes_tariftype: {
    name: 'pilote_tariftypes_tariftype',
    params: ['tariftype'],
    route: (tt: TariftypeBase) => ['/', RS.pilote, RS.tariftypes, tt.id],
  },

  pilote_tariftypes_tariftype_manage: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.edit],
    name: 'pilote_tariftypes_tariftype_manage',
  },
  pilote_tariftypes_tariftype_tarifs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tarifs],
    name: 'pilote_tariftypes_tariftype_tarifs',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_manage: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.edit,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_manage',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_lines: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.prices,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_lines',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_documents: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.documents,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_documents',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_notifications: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.tarifnotifications,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_notifications',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_notifications_logs: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.tarifnotificationstatuses,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_notifications_logs',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_relations: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.tarifrelations,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_relations',
  },
  pilote_tariftypes_tariftype_tarifs_tarif_lifecycle: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tarifs,
      tarif.id,
      RS.lifecycle,
    ],
    name: 'pilote_tariftypes_tariftype_tarifs_tarif_lifecycle',
  },
  pilote_tariftypes_tariftype_tarifs_tarif: {
    params: ['tariftype', 'tarif'],
    route: (tariftype: Tariftype, tarif: Tarif) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tarifs, tarif.id],
    name: 'pilote_tariftypes_tariftype_pilote_tarif',
  },

  pilote_tariftypes_tariftype_articles: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.articletariftemplates],
    name: 'pilote_tariftypes_tariftype_articles',
  },
  pilote_tariftypes_tariftype_articles_article: {
    params: ['tariftype', 'articletariftemplate'],
    route: (tariftype: Tariftype, att: ArticleTarifTemplate) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.articles,
      att.id,
    ],
    name: 'pilote_tariftypes_tariftype_articles_article',
  },
  pilote_tariftypes_tariftype_relations: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tariftyperelations],
    name: 'pilote_tariftypes_tariftype_relations',
  },

  pilote_tariftypes_tariftype_relations_relation: {
    params: ['tariftype', 'tariftyperelation'],
    route: (tariftype: Tariftype, ttrel: TariftypeRelationBase) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.tariftyperelations,
      ttrel.id,
    ],
    name: 'pilote_tariftypes_tariftype_relations_relation',
  },

  pilote_tariftypes_tariftype_storages: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.storages],
    name: 'pilote_tariftypes_tariftype_storages',
  },
  pilote_tariftypes_tariftype_regions: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.genericregions],
    name: 'pilote_tariftypes_tariftype_regions',
  },
  pilote_tariftypes_tariftype_documents: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.documents],
    name: 'pilote_tariftypes_tariftype_documents',
  },

  pilote_tariftypes_tariftype_documents_document: {
    params: ['tariftype', 'tariftypedocument'],
    route: (tariftype: Tariftype, ttdoc: TariftypeDocument) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.documents,
      ttdoc.id,
    ],
    name: 'pilote_tariftypes_tariftype_documents_document',
  },

  pilote_tariftypes_tariftype_documents_document_metadata: {
    params: ['tariftype', 'tariftypedocument', 'tariftypemetadata'],
    route: (tariftype: Tariftype, ttdoc: TariftypeDocument, ttmeta: TariftypeMetadata) => [
      '/',
      RS.pilote,
      RS.tariftypes,
      tariftype.id,
      RS.documents,
      ttdoc.id,
      RS.tariftypemetadatas,
      ttmeta.id,
    ],
    name: 'pilote_tariftypes_tariftype_documents_document_metadata',
  },
  pilote_tariftypes_tariftype_catalog: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.catalog],
    name: 'pilote_tariftypes_tariftype_catalog',
  },
  pilote_tariftypes_tariftype_destinations: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tarifnotificationdestinations],
    name: 'pilote_tariftypes_tariftype_destinations',
  },
  pilote_tariftypes_tariftype_notifications: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tarifnotifications],
    name: 'pilote_tariftypes_tariftype_notifications',
  },
  pilote_tariftypes_tariftype_notification_logs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tarifnotificationstatuses],
    name: 'pilote_tariftypes_tariftype_notification_logs',
  },
  pilote_tariftypes_tariftype_tarifs_logs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tariflogs],
    name: 'pilote_tariftypes_tariftype_pilote_logs',
  },
  pilote_tariftypes_tariftype_prices_logs: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.articletariflogs],
    name: 'pilote_tariftypes_tariftype_prices_logs',
  },
  pilote_tariftypes_tariftype_lifecycle: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.lifecycle],
    name: 'pilote_tariftypes_tariftype_lifecycle',
  },
  pilote_tariftypes_tariftype_permissions: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.tariftypepermissions],
    name: 'pilote_tariftypes_tariftype_permissions',
  },
  pilote_tariftypes_tariftype_mappings: {
    params: ['tariftype'],
    route: (tariftype: Tariftype) => ['/', RS.pilote, RS.tariftypes, tariftype.id, RS.mappings],
    name: 'pilote_tariftypes_tariftype_mappings',
  },
};
