
<ng-template #ofcslot let-offcanvas>
  <ng-container *ngIf="selection">
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Gestion de la sélection</h4>
      <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="list-group mt-1">
        <li class="list-group-item list-group-item-warning list-group-item-action" role="button" (click)="deselectAll()"><i class="bi bi-trash"></i> Vider la sélection</li>
        <li class="list-group-item list-group-item-action" role="button" (click)="selectAll()"><i class="bi bi-check2-square"></i> Sélectionner tous les éléments visibles</li>
        <li class="list-group-item list-group-item-action" role="button" (click)="invertSelection()"><i class="bi bi-check2-square"></i> Inverser la sélection</li>
      </ul>
      <ul class="list-group mt-2" *ngIf="!selection.isEmpty()">
        <ng-container *ngIf="print_settings">
          <ng-container *ngFor="let ps of print_settings">
            <ng-container *ngIf="ps.type==='selection'">
              <ng-container *ngFor="let a of ps.actions">
                <ng-container *ngIf="a==='quick_print' || a==='button'">
                  <li class="list-group-item list-group-item-action" role="button" (click)="print(ps, a)" *ngIf="a!=='button'"><i class="bi bi-printer me-2"></i><span>{{ps.label}}</span><span class="ms-2" *ngIf="a==='quick_print'">(impression rapide)</span></li>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
      <h4 class="mt-3">Éléments sélectionnés</h4>
      <div *ngFor="let item of selection.display()">
        <lvadg-icon role="button" ri="delete" (click)="selection.removeById(item.id)">{{ item.label }}</lvadg-icon>
      </div>
      <p class="text-warning text-center my-4" *ngIf="selection.isEmpty()"><i class="bi bi-exclamation-triangle me-2"></i> Aucun élément sélectionné</p>
    </div>
  </ng-container>
</ng-template>
<ng-container *ngIf="selection"><i class="bi bi-square" *ngIf="selection.isEmpty()" (click)="clickIcon()"></i>
  <ng-container *ngIf="!selection.isEmpty()"><span class="curselect" (click)="clickIcon()" role="button"><i class="bi bi-check-square"></i><span class="count">{{ selection.size() }}</span></span></ng-container>
</ng-container>