import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from '../../upload/media-upload/media-upload.component';
import { UploadFile } from '@solidev/data';
import { Folder } from '../../folder/folder';
import { Document } from '../document';
import { FolderService } from '../../folder/folder.service';
import { DocumentService } from '../document.service';
import { OpLog } from '../../../../components/utils/oplog/oplog';

export interface DocumentUploadResponse {
  folder: Folder;
  log: OpLog;
  message: string;
}

@Component({
  selector: 'lvadg-document-create',
  standalone: true,
  imports: [CommonModule, MediaUploadComponent],
  templateUrl: './document-create.component.pug',
  styleUrls: ['./document-create.component.sass'],
})
export class DocumentCreateComponent implements OnInit {
  @Input() public folder!: Folder;
  @Output() public uploaded = new EventEmitter<
    UploadResult<Document, DocumentUploadResponse>
  >();
  public doc!: Document;
  public uploadPrepare: (
    model: Document,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<Document>> = this._uploadPrepare.bind(this);

  constructor(
    private _folders: FolderService,
    public document$: DocumentService
  ) {}

  ngOnInit(): void {
    this.doc = new Document(this.document$);
  }

  private async _uploadPrepare(
    model: Document,
    file: UploadFile
  ): Promise<IPreparedUploadFile<Document>> {
    return {
      model,
      url: this._folders.getUrl(this.folder.id, { suffix: '/action' }),
      data: {
        action: 'add_document',
        folder: this.folder.id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
      },
    };
  }
}
