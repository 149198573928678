import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from "@solidev/data";

export enum CDATAKEY_TYPE {
  INTEGER = "INT",
  DECIMAL = "DEC",
  SHORT_TEXT = "STX",
  LONG_TEXT = "LTX",
  RICH_TEXT = "RTX",
  CURRENCY = "CUR",
  DATE = "DTE",
  DATETIME = "DTH",
  SINGLE_CHOICE = "CH1",
  MULTIPLE_CHOICE = "CHX",
  DOCUMENT = "DOC",
  FILE = "FIL",
  FOLDER = "FOL",
  CATEGORY = "CAT",
}

export const CDATAKEY_TYPE_CHOICES = [
  { value: CDATAKEY_TYPE.INTEGER, desc: "Entier" },
  { value: CDATAKEY_TYPE.DECIMAL, desc: "Décimal" },
  { value: CDATAKEY_TYPE.SHORT_TEXT, desc: "Texte court" },
  { value: CDATAKEY_TYPE.LONG_TEXT, desc: "Texte long" },
  { value: CDATAKEY_TYPE.RICH_TEXT, desc: "Texte riche" },
  { value: CDATAKEY_TYPE.CURRENCY, desc: "Monétaire" },
  { value: CDATAKEY_TYPE.DATE, desc: "Date" },
  { value: CDATAKEY_TYPE.DATETIME, desc: "Date et heure" },
  { value: CDATAKEY_TYPE.SINGLE_CHOICE, desc: "Choix unique" },
  { value: CDATAKEY_TYPE.MULTIPLE_CHOICE, desc: "Choix multiple" },
  { value: CDATAKEY_TYPE.DOCUMENT, desc: "Document" },
  { value: CDATAKEY_TYPE.FILE, desc: "Fichier" },
  { value: CDATAKEY_TYPE.FOLDER, desc: "Dossier" },
  { value: CDATAKEY_TYPE.CATEGORY, desc: "Catégorie" },
];

export enum CDATAKEY_DEST {
  MEMBER = "MBR",
  STORAGE = "STO",
  PROVIDER = "PRV",
  PRODUCER = "PRO",
  PRODUCT = "PRD",
  CLIENT = "CLI",
  RESTAURANT = "RES",
  FAMILY = "FAM",
  ARTICLE = "ART",
}

export enum CDATAKEY_STATUSES {
  ACTIVE = "ACT",
  ARCHIVED = "ARC",
}

export class CdataKeyBase extends DataModel {
  static override readonly __name: string = "CdataKeyBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: "flags",
    description: "Flags",
    defaultValue: [],
    priority: 100,
  })
  public flags: string[] = [];

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 90,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 95,
  })
  public updated!: Date;

  @foreignKeyField({
    name: "parent",
    description: "ID Parent",
    related: "CDataKey",
    priority: -1,
  })
  public parent!: number;

  @charField({
    name: "name",
    description: "Nom",
    maxLength: 250,
    priority: 900,
  })
  public name!: string;

  @textField({
    name: "description",
    description: "Description",
    defaultValue: "",
    priority: 800,
  })
  public description: string = "";

  @charField({
    name: "code",
    description: "Code",
    maxLength: 250,
    priority: 850,
  })
  public code!: string;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: CDATAKEY_TYPE.SHORT_TEXT,
    required: true,
    maxLength: 3,
    choices: CDATAKEY_TYPE_CHOICES,
    priority: 500,
  })
  public type: CDATAKEY_TYPE = CDATAKEY_TYPE.SHORT_TEXT;

  @charField({
    name: "unit",
    description: "Unité",
    defaultValue: "",
    maxLength: 250,
    priority: 600,
  })
  public unit: string = "";

  @charField({
    name: "dest",
    description: "Destination",
    defaultValue: CDATAKEY_DEST.STORAGE,
    required: true,
    maxLength: 3,
    choices: [
      { value: CDATAKEY_DEST.MEMBER, desc: "Membre" },
      { value: CDATAKEY_DEST.STORAGE, desc: "Stockage" },
      { value: CDATAKEY_DEST.PROVIDER, desc: "Fournisseur" },
      { value: CDATAKEY_DEST.PRODUCER, desc: "Producteur" },
      { value: CDATAKEY_DEST.PRODUCT, desc: "Produit" },
      { value: CDATAKEY_DEST.CLIENT, desc: "Client" },
      { value: CDATAKEY_DEST.RESTAURANT, desc: "Restaurant" },
      { value: CDATAKEY_DEST.FAMILY, desc: "Famille" },
      { value: CDATAKEY_DEST.ARTICLE, desc: "Article" },
    ],
    priority: 850,
  })
  public dest: CDATAKEY_DEST = CDATAKEY_DEST.STORAGE;

  @charField({
    name: "status",
    description: "Statut",
    defaultValue: CDATAKEY_STATUSES.ACTIVE,
    required: true,
    maxLength: 3,
    choices: [
      { value: CDATAKEY_STATUSES.ACTIVE, desc: "Actif" },
      { value: CDATAKEY_STATUSES.ARCHIVED, desc: "Archivé" },
    ],
    priority: 200,
  })
  public status: CDATAKEY_STATUSES = CDATAKEY_STATUSES.ACTIVE;

  @integerField({
    name: "position",
    description: "Position",
    defaultValue: 0,
    required: true,
    priority: 400,
  })
  public position: number = 0;

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -1,
  })
  public orig_id!: number;

  @manyToManyField({
    name: "children",
    description: "IDS sous categories",
    defaultValue: [],
    related: "CDataKey",
    priority: -1,
  })
  public children: number[] = [];

  @integerField({
    name: "tree_depth",
    description: "Niveau hiérarchie",
    readonly: true,
    priority: -1,
  })
  public tree_depth: number = 0;

  @detailsField({
    name: "tree_path",
    description: "Hiérarchie",
    readonly: true,
    priority: -1,
  })
  public tree_path: { name: string; id: number }[] = [];

  public get full_path(): string {
    return this.tree_path.map((p) => p.name).join(" : ");
  }

  public get _display(): string {
    return this.full_path;
  }

  public truncated_path(
    start: number = 0,
    end: number = 8,
    sep: string = " : ",
  ): string {
    return this.tree_path
      .filter((v, i) => i >= start && i <= end)
      .map((p) => p.name)
      .join(sep);
  }
}
