import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Storage } from '../storage';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { StorageService } from '../storage.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { MemberService } from '../../member/member.service';

@Component({
  selector: 'lvadg-storage-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './storage-create.component.pug',
  styleUrls: ['./storage-create.component.sass'],
})
export class StorageCreateComponent implements OnInit {
  public storage!: Storage;
  public createForm!: FormGroup<{
    cvva: FormControl<string>;
    member: FormControl<number>;
    name: FormControl<string>;
    type: FormControl<string>;
  }>;
  @Output() public created = new EventEmitter<Storage>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _storages: StorageService,
    public members$: MemberService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.storage = new Storage(this._storages);
    this.createForm = this.storage.FC(['cvva', 'member', 'name', 'type']);
  }

  public async save() {
    this.storage.fromJson(
      { parent: null, ...this.createForm.value },
      { partial: true }
    );
    try {
      await firstValueFrom(this.storage.save({ updateModel: true }));
      this.created.emit(this.storage);
      this._msgs.success('Dépôt créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
