import { RoutesConfig } from '@solidev/data';

export const FRONT_BASE_ROUTES_NAMES = ['noop', 'home', 'help'] as const;

export const FRONT_BASE_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_BASE_ROUTES_NAMES)[number]> = {
  noop: {
    params: [],
    route: () => ['/todo'],
    name: 'front_noop',
  },
  home: {
    params: [],
    route: () => ['/'],
    name: 'front_home',
  },
  help: {
    params: ['path'],
    route: (p: string) => ['/docs' + p],
    name: 'help',
  },
};
