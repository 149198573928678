
<button class="btn btn-primary float-end mx-2" (click)="groupCreate=!groupCreate"><i class="bi bi-plus-circle me-2"></i>Ajouter une consolidation</button>
<button class="btn btn-primary float-end" (click)="create=!create"><i class="bi bi-plus-circle me-2"></i>Ajouter une commande</button>
<h1 class="page-title">Commandes</h1>
<div class="card my-3" *ngIf="create">
  <div class="card-header">Ajouter une commande</div>
  <div class="card-body">
    <lvadg-order-create (created)="reload$.next(true); create=false" (cancelled)="create=false"></lvadg-order-create>
  </div>
</div>
<div class="card my-3" *ngIf="groupCreate">
  <div class="card-header">Ajouter une consolidation</div>
  <div class="card-body">
    <lvadg-ordergroup-create (created)="reload$.next(true); create=false" (cancelled)="create=false"></lvadg-ordergroup-create>
  </div>
</div>
<!-- ng-container(*ngIf="data$|async; let data")-->
<lvadg-order-list [name]="'fuck'" [detail_route]="detail_route" [default_fields]="default_fields" [reload]="reload$"></lvadg-order-list>