import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults } from '@solidev/data';
import { PRODUCT_ROUTES } from '../menu';
import { Product } from '../../../../../models/catalog/product/product';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ProductNavComponent } from '../_nav/product-nav.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { CommonModule } from '@angular/common';
import { Producer } from '../../../../../models/structures/producer/producer';
import { Storage } from '../../../../../models/structures/storage/storage';
import { ProducerDocumentListComponent } from '../../../../../models/structures/producer-document/producer-document-list/producer-document-list.component';
import { ProducerDocumentCreateComponent } from '../../../../../models/structures/producer-document/producer-document-create/producer-document-create.component';
import { map, Observable, Subject } from 'rxjs';

export interface ProductDocumentsViewParams extends BaseRouteParams {
  p_menu: PRODUCT_ROUTES;
}

export interface ProductDocumentsViewData extends ProductDocumentsViewParams {
  product: Product;
  producer?: Producer;
  storage?: Storage;
}

@Component({
  selector: 'lvadg-product-documents-view',
  standalone: true,
  imports: [
    ProductNavComponent,
    HeaderActionComponent,
    CommonModule,
    ProducerDocumentListComponent,
    ProducerDocumentCreateComponent,
  ],
  templateUrl: './product-documents-view.component.pug',
  styleUrl: './product-documents-view.component.sass',
})
export class ProductDocumentsViewComponent extends BaseDetailViewComponent<ProductDocumentsViewData, Product> {
  public productFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();

  public override postNgOnInit() {
    super.postNgOnInit();
    this.productFilter$ = this.data$.pipe(map((data) => ({ product: data.product.id })));
  }
}
