import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { Eacollection } from '../eacollection';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-eacollection-display',
  standalone: true,
  imports: [NgIf, RouterLink],
  templateUrl: './eacollection-display.component.pug',
  styleUrls: ['./eacollection-display.component.sass'],
})
export class EacollectionDisplayComponent {
  @Input() public eacollection?: Eacollection;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<Eacollection>;
}
