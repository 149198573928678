
<div *ngIf="resto">
  <div class="card">
    <div class="card-header">
      <lvadg-icon ri="resto">Détails resto</lvadg-icon>
    </div>
    <div class="card-body">
      <dl class="row border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="id" [editable]="false" mode="dd">ID</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="cvva" [editable]="true" mode="dd">Code Vivalya</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="name" [editable]="true" mode="dd">Nom</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="client" [editable]="false" mode="dd" [collection]="clients$">Client</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="type" [editable]="true" mode="dd">Type</data-dispedit>
        <data-dispedit class="col-6 col-lg-9" [model]="resto" field="content_src" [editable]="true" mode="dd">Description</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="status" [editable]="true" mode="dd">État</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="created" [editable]="false" mode="dd">Date de création</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="updated" [editable]="false" mode="dd">Date de MAJ</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="cvva_updated" [editable]="false" mode="dd">Date de MAJ vivalya</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="phone" [editable]="true" mode="dd">Téléphone</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="fax" [editable]="true" mode="dd">Fax</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="website" [editable]="true" mode="dd">Site internet</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="legal" [editable]="true" mode="dd">Type structure</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="capital" [editable]="true" mode="dd">Capital</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="siren" [editable]="true" mode="dd">SIREN</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="siret" [editable]="true" mode="dd">SIRET</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="tvaic" [editable]="true" mode="dd">TVA IC</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="resto" field="naf" [editable]="true" mode="dd">Code NAF</data-dispedit>
      </dl>
      <dl class="row mt-3">
        <div class="col-6">
          <dt class="d-flex flex-row justify-content-start"><span>Labels structure</span>
            <div class="form-check form-switch ms-3">
              <input class="form-check-input" id="allow_labels_edition" type="checkbox" #cbb (change)="allow_labels_edition.set(cbb.checked||false)">
              <label class="form-check-label" for="allow_labels_edition">Éditer les labels</label>
            </div>
          </dt>
          <dd>
            <lvadg-labels-manager class="col-12" [item]="resto" [type]="LBT.STRUCTURE" source="slabels" [save]="true" [catalog_only]="false" [mode]="allow_labels_edition()?'edit':'view'"></lvadg-labels-manager>
          </dd>
        </div>
        <data-flags class="col-6 col-lg-3" [model]="resto">Flags</data-flags>
      </dl>
    </div>
  </div>
  <div class="card mt-3" *ngIf="!resto.location_details">
    <div class="card-header">
      <lvadg-icon ri="location">Adresse et géolocalisation</lvadg-icon>
    </div>
    <div class="card-body">
      <ng-container>
        <div class="text-danger py-2">Aucune adresse renseignée, ajoutez une adresse pour pouvoir géolocaliser le resto</div>
        <lvadg-location-create [structure]="resto" [action]="ract" [type]="LT.RESTO" (created)="resto.location_details = $event; resto.location = $event.id"></lvadg-location-create>
      </ng-container>
    </div>
  </div>
  <div class="card mt-3" *ngIf="resto.location_details">
    <div class="card-header">
      <lvadg-icon ri="location">Adresse et géolocalisation</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-location-manage [location]="resto.location_details" [structure]="$any(resto)" [action]="ract"></lvadg-location-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="image">Logo</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-logo-manage [model]="resto"></lvadg-logo-manage>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="image">Images</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-gallery-manage [model]="resto"></lvadg-gallery-manage>
    </div>
  </div>
</div>