import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';

export enum OrderProcessStoragePermission {
  READ = 'READ',
  WRITE = 'WRITE',
}

export class OrderprocessstoragePermissionBase extends DataModel {
  static override readonly __name: string = 'OrderprocessstoragePermissionBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    choices: [
      {
        value: OrderProcessStoragePermission.READ,
        desc: 'Consultation de commande dépôt',
      },
      {
        value: OrderProcessStoragePermission.WRITE,
        desc: 'Création / saisie de commande dépôt',
      },
    ],
  })
  public permission!: string;

  @foreignKeyField({
    name: 'target',
    description: 'ID dépôt / plateforme',
    related: 'OrderProcessStorage',
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID User',
    related: 'User',
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
  })
  public group!: number;
}
