
<div class="fpdialog">
  <div class="text-center">
    <h1 class="my-5">Connexion #lavieadugout
      <div class="small">Réinitialisation de mot de passe</div>
    </h1>
  </div>
  <div class="card" [class.border-danger]="error!=''">
    <div class="card-header login" [class.bg-danger]="error!=''" [class.text-white]="error!=''"><i class="bi bi-box-arrow-in-right me-2"></i>Réinitialiser mon mot de passe</div>
    <div class="card-body">
      <form [formGroup]="pwresetForm">
        <div class="mb-3">
          <label class="form-label" for="username">Identifiant ou adresse email</label>
          <input class="form-control" type="text" aria-describedby="usernamehelp" formControlName="username" autocomplete="username email" id="username">
          <div class="form-text" id="usernamehelp">Identifiant ou adresse email</div>
        </div>
        <button class="btn btn-primary w-100" *ngIf="mode==='ask'" (click)="requestPwReset()">Envoyer un email de validation</button>
        <p class="text-info" *ngIf="mode==='waiting'">Une demande de validation a été transmise pour le compte {{pwresetForm.value.username}}. Si cette adresse email est bien associée à un compte #lavieadugout, vous devriez recevoir un email. Vous pouvez cliquer sur le lien contenu dans ce mail ou bien coller le code ci-dessous.</p>
        <ng-container *ngIf="mode!=='ask'">
          <div class="mb-3">
            <label class="form-label" for="token">Jeton de réactivation</label>
            <input class="form-control" type="text" aria-describedby="usernamehelp" formControlName="token" id="token">
            <div class="form-text" id="tokenhelp">Jeton de validation, reçu par email</div>
          </div>
          <div class="mb-3" *ngIf="pwresetForm.value.token">
            <label class="form-label" for="password">Nouveau mot de passe</label>
            <input class="form-control" type="password" aria-describedby="passwordhelp" formControlName="password" autocomplete="new-password" id="password">
            <div class="form-text" id="passwordhelp">Nouveau mot de passe, 8 caractères minimum</div>
          </div>
          <button class="btn btn-primary w-100" [disabled]="pwresetForm.invalid" (click)="processPwReset()">Modifier mon mot de passe</button>
        </ng-container>
      </form>
    </div>
  </div>
  <div class="text-center mt-5"><img src="/assets/images/logos/vivalya.png" width="128px"></div>
</div>