
<ng-container *ngIf="data$|async; let data">
  <lvadg-offer-nav [routes]="data.o_menu" [offer]="data.offer" [offertypestorage]="data.offertypestorage" *ngIf="data.o_menu"></lvadg-offer-nav>
  <lvadg-header-action [title]="data.offer.title" level="h2" subtitle="Mise en page de l'offre" ri="offer"></lvadg-header-action>
  <ng-template #noLayout>
    <div class="text-center">
      <h3>Pas d'impression pour cette offre</h3>
      <p class="text-info">Les impressions de la v2 n'ont pas été migrées dans la v3. Vous pouvez les recréer si nécessaire.</p>
      <button class="btn btn-outline-primary" type="button" (click)="createLayout(data.offer)"><i class="fa fa-plus"></i>Créer une mise en page</button>
    </div>
  </ng-template>
  <ng-container *ngIf="data.offer.layout; else noLayout">
    <div class="row g-2 my-2">
      <div class="col-12">
        <div class="text-info">Les modifications de mise en page sont communes à l'ensemble des impressions de cette offre.</div>
      </div>
      <div class="col-6 col-lg-4">
        <button class="btn btn-outline-primary w-100" (click)="restoForm.setValue({resto: null})">Aperçu offre générique</button>
      </div>
      <div class="col-6 col-lg-4">
        <form [formGroup]="restoForm">
          <select class="form-select w-100" formControlName="resto">
            <option [ngValue]="null">Aperçu offre restaurant : sélectionnez un restaurant</option>
            <option *ngFor="let r of restos$|async" [ngValue]="r.resto_details?.id">{{r.resto_details?.name}}</option>
          </select>
        </form>
      </div>
      <div class="col-6 col-lg-2 col-xl-3">
        <button class="btn btn-outline-primary w-100" (click)="launchPrint(data.offer)">
          <lvadg-icon ri="print">Imprimer</lvadg-icon>
        </button>
      </div>
      <div class="col-6 col-lg-2 col-xl-1">
        <button class="btn btn-outline-warning w-100" (click)="reload()">
          <lvadg-icon ri="sync">Recharger</lvadg-icon>
        </button>
      </div>
    </div>
    <lvadg-layout-editor [print$]="print$" [printParams$]="params"></lvadg-layout-editor>
  </ng-container>
</ng-container>