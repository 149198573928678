import { OrderprocessarticleBase } from './orderprocessarticle.base';
import { detailsField } from '@solidev/data';
import { ProcessBase } from '../../catalog/meta/process/process.base';
import { TariftypeBase } from '../../tarifs/tariftype/tariftype.base';
import { ArticleBase } from '../../catalog/article/article.base';
import { Article } from '../../catalog/article/article';
import { OrderprocessBase } from '../orderprocess/orderprocess.base';
import { ArticleTarifTemplate } from '../../tarifs/article-tarif-template/article-tarif-template';

export class Orderprocessarticle extends OrderprocessarticleBase {
  static override readonly __name: string = 'Orderprocessarticle';
  @detailsField({
    model: ProcessBase,
    description: 'Processus de commande',
    readonly: true,
  })
  public process_details?: OrderprocessBase;

  @detailsField({
    model: TariftypeBase,
    description: 'Tarif associé',
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: ArticleTarifTemplate,
    description: 'Article de tarif',
    readonly: true,
  })
  public att_details?: ArticleTarifTemplate;

  @detailsField({
    model: ArticleBase,
    description: 'Article',
    readonly: true,
  })
  public article_details?: ArticleBase;
}
