
<ng-container *ngFor="let c of coll$|async">
  <div class="d-inline-block me-2 my-1" ngbDropdown>
    <button class="btn btn-outline-primary" role="button" [disabled]="running[c.id]" ngbDropdownToggle>
      <ng-container *ngIf="running[c.id]"><i class="spinner-border spinner-border-sm text-primary me-2"></i></ng-container>Export {{c.name}}
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem (click)="exportEaCollection(c, 'xlsx')"><i class="bi bi-file-spreadsheet me-2"></i>XLSX</button>
      <button ngbDropdownItem (click)="exportEaCollection(c, 'csv')"><i class="bi bi-file-spreadsheet me-2"></i>CSV</button>
      <button ngbDropdownItem (click)="exportEaCollection(c, 'csv')"><i class="bi bi-file-spreadsheet me-2"></i>JSON</button>
    </div>
  </div>
</ng-container>