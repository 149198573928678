import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessstoragePermissionBase } from '../orderprocessstorage-permission.base';
import { OrderprocessstoragePermission } from '../orderprocessstorage-permission';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-orderprocessstorage-permission-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './orderprocessstorage-permission-display.component.pug',
  styleUrls: ['./orderprocessstorage-permission-display.component.sass'],
})
export class OrderProcessStoragePermissionDisplayComponent implements OnInit {
  @Input() public permission?: OrderprocessstoragePermission;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<OrderprocessstoragePermissionBase>;

  constructor() {}

  ngOnInit(): void {}
}
