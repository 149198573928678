import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { firstValueFrom, map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { PrintBase } from '../print/print.base';
import { LayoutTemplate, TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from './template';
import { FullLayout } from '../rstypes/FullLayout';
import { PrintLayoutTreeStructureItem } from '../rstypes/PrintLayoutTreeStructureItem';
import { Provider } from '../rstypes/Provider';
import { LayoutType } from '../rstypes/LayoutType';
import { AddProviderAction } from '../rstypes/AddProviderAction';
import { UrlParamValue } from '../rstypes/UrlParamValue';

function stripNulls(obj: Partial<UrlParamValue>) {
  return Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
}

/**
 * Base class for all layouts.
 * All 4 layout types are represented by a class that extends this one.
 */
export class BaseLayout implements PrintLayoutTreeStructureItem {
  public tree_id: number;
  public layout_id: number;
  public parent_id: number | null;
  public children: number[] = [];

  public name: string;
  public description: string;
  public providers: Provider[];
  public template: LayoutTemplate | null = null;
  public group: [string, number][] | null;
  public layout_type: LayoutType;
  public group_provider: Provider | null;
  public group_name: string | null;

  constructor(data: PrintLayoutTreeStructureItem) {
    this.tree_id = data.tree_id;
    this.layout_id = data.layout_id;
    this.parent_id = data.parent_id ?? null;
    this.name = data.name ?? '?';
    this.description = data.description ?? '';
    this.providers = data.providers && data.providers.length > 0 ? data.providers : [];
    this.children = data.children ?? [];
    this.layout_type = data.layout_type;
    this.group = data.group ?? null;
    this.group_provider = data.group_provider ?? null;
    this.group_name = data.group_name ?? null;
  }

  /** Unique identifier for this layout */
  public get uid(): string {
    return `${this.tree_id}-${this.layout_id}`;
  }
}

/**
 * Layout container.
 * This can be used to provide data for child layouts.
 */
export class PrintContainerLayout extends BaseLayout {
  public override layout_type = 'container' as const;

  constructor(data: PrintLayoutTreeStructureItem) {
    super(data);
  }
}

/**
 * Layout template.
 * This is a final layout that can be rendered, using context and parameters.
 */
export class PrintTemplateLayout extends BaseLayout {
  public override layout_type = 'template' as const;

  constructor(data: PrintLayoutTreeStructureItem) {
    super(data);
  }
}

/**
 * Group layout.
 * This is a layout for repeating children layouts.
 */
export class PrintGroupLayout extends BaseLayout {
  public override layout_type = 'group' as const;

  constructor(data: PrintLayoutTreeStructureItem) {
    super(data);
  }
}

/**
 * Group instance layout.
 * This is the first repeated child of a group layout.
 */
export class PrintGroupInstanceLayout extends BaseLayout {
  public override layout_type = 'group_instance' as const;
  public override group: [string, number][];

  constructor(data: PrintLayoutTreeStructureItem) {
    super(data);
    this.group = data.group!;
  }

  /** Unique identifier for this layout */
  public override get uid(): string {
    return `${this.tree_id}-${this.layout_id}-${this.group.map((g) => g[0] + g[1].toString()).join('-')}`;
  }
}

/**
 * Type for all layout types.
 */
export type LayoutData = PrintContainerLayout | PrintTemplateLayout | PrintGroupLayout | PrintGroupInstanceLayout;

/**
 * Layout tree structure.
 * This is used to display the layout tree in the UI.
 */
export class LayoutTree {
  /** Print data */
  public print!: { id: number; name: string };
  /** URL parameters */
  public params: Partial<UrlParamValue>;
  /** Tree roots */
  public roots: LayoutData[];
  /** Layout maps, by tree_id */
  public layouts: Map<number, LayoutData>;

  // Print tree control
  /** Tree control */
  public control: NestedTreeControl<LayoutData, number>;
  /** Tree data source */
  public data: ArrayDataSource<LayoutData>;
  /** Highlighted layouts */
  public highlighted = new Map<number, string>();
  /** Expanded layouts */
  public expanded = new Map<number, boolean>();
  /** Current layout */
  public current: number | null = null;
  private groupMode: Record<number, boolean> = {};

  /**
   * Constructor. Creates a new LayoutTree.
   * @param print Print object
   * @param params URL parameters
   * @param data Layout data
   * @param _http HttpClient instance
   * @param _printApiUrl Print API URL (from environment)
   * @param _domSanitizer DomSanitizer instance
   */
  constructor(
    print: PrintBase,
    params: Partial<UrlParamValue>,
    data: LayoutData[],
    private _http: HttpClient,
    private _printApiUrl: string,
    private _domSanitizer: DomSanitizer,
  ) {
    this.print = print;
    this.params = params;
    // create the layout map, instantiating the correct layout type for each layout
    this.layouts = new Map(
      data.map((layout): [number, LayoutData] => {
        switch (layout.layout_type) {
          case 'container':
            return [layout.tree_id, new PrintContainerLayout(layout)];
          case 'template':
            return [layout.tree_id, new PrintTemplateLayout(layout)];
          case 'group':
            return [layout.tree_id, new PrintGroupLayout(layout)];
          case 'group_instance':
            return [layout.tree_id, new PrintGroupInstanceLayout(layout)];
        }
      }),
    );
    // set the root layouts
    this.roots = Array.from(this.layouts.values()).filter((layout: LayoutData) => layout.parent_id === null);
    // create the datasource and tree control
    this.data = new ArrayDataSource(this.roots);
    this.control = new NestedTreeControl<LayoutData, number>(
      (node) => {
        return node.children.map((tid) => this.layouts.get(tid)).filter((l) => l !== undefined) as LayoutData[];
      },
      { trackBy: (d) => d.tree_id },
    );
    // TODO: check subscriptions and unsubscribe
  }

  /** Current layout subject */
  private _currentLayout$ = new ReplaySubject<LayoutData | null>(1);

  public get currentLayout$(): Observable<LayoutData | null> {
    return this._currentLayout$.asObservable();
  }

  /**
   * Expand a node
   * @param node Node to expand
   */
  public expand(node: LayoutData) {
    this.expanded.set(node.tree_id, true);
    this.control.expand(node);
  }

  /**
   * Click a node.
   * If node is current, then toggle expand/collapse.
   * In any case, set the node as current.
   * @param node
   */
  public async clickNode(node: LayoutData) {
    if (this.current === node.tree_id) {
      if (this.control.isExpanded(node)) {
        this.collapse(node);
      } else {
        this.expand(node);
      }
    }
    this.setCurrent(node);
    this._currentLayout$.next(await firstValueFrom(this.loadNode(node)));
  }

  /**
   * Get the HTML rendering of a layout.
   * @param tree_id Layout tree_id
   */
  public getRender(tree_id: number): Observable<SafeHtml | null> {
    return this._http
      .get<{
        html: string;
      }>(`${this._printApiUrl}/prints/${this.print.id}/layouts/${tree_id}/render`, {
        params: {
          ...stripNulls(this.params),
          with_group: this.groupMode[tree_id] === undefined ? true : this.groupMode[tree_id],
        },
      })
      .pipe(
        map((data) => {
          if (data.html === null) {
            return null;
          }
          return this._domSanitizer.bypassSecurityTrustHtml(data.html);
        }),
      );
  }

  /**
   * Perform layout action.
   *
   * TODO: fix parameters and types
   * @param param
   */
  set_fragment_param(param: {
    layout: LayoutData;
    group: [string, number][] | null;
    fragment: TemplateFragment;
    param: TemplateParam;
    provider: TemplateProvider;
    choice: TemplateChoice;
    value?: string;
  }) {
    this._http
      .post(
        `${this._printApiUrl}/prints/${this.print.id}/layouts/${param.layout.tree_id}/action`,
        {
          action: 'set_fragment_param',
          layout: param.layout.layout_id,
          group: this.get_group_mode(param.layout) ? param.group : undefined,
          template: param.layout.template?.name,
          fragment: param.fragment.name,
          param: param.param.name,
          provider: param.provider.name,
          choice: param.choice.name,
          value: param.value || '',
        },
        {
          params: stripNulls(this.params),
        },
      )
      .subscribe(() => {
        this.loadNode(param.layout).subscribe((layout) => {
          this._currentLayout$.next(layout);
        });
      });
  }

  /**
   * Perform layout data action.
   *
   * @param layout LayoutData
   * @param data ProviderData
   */
  public set_data_param(layout: LayoutData, data: AddProviderAction) {
    return this._http
      .post(
        `${this._printApiUrl}/prints/${this.print.id}/layouts/${layout.tree_id}/action`,
        {
          action: 'add_provider',
          layout: layout.layout_id,
          ...data,
        },
        {
          params: stripNulls(this.params),
        },
      )
      .pipe(
        switchMap(() => this.loadNode(layout)),
        tap((layout) => this._currentLayout$.next(layout)),
      );
  }

  public toggle_group_mode(layout: LayoutData) {
    if (this.groupMode[layout.tree_id] === undefined) {
      this.groupMode[layout.tree_id] = false;
    } else {
      this.groupMode[layout.tree_id] = !this.groupMode[layout.tree_id];
    }
    this.loadNode(layout, { with_group: this.groupMode[layout.tree_id] }).subscribe((layout) => {
      this._currentLayout$.next(layout);
    });
  }

  public get_group_mode(layout: LayoutData): boolean {
    return this.groupMode[layout.tree_id] === undefined ? true : this.groupMode[layout.tree_id];
  }

  /**
   * Reset a fragment parameter.
   * @param layout LayoutData
   * @param frag TemplateFragment
   * @param param TemplateParam
   */
  public reset_fragment_param(layout: LayoutData, frag: TemplateFragment, param: TemplateParam) {
    return this._http
      .post(
        `${this._printApiUrl}/prints/${this.print.id}/layouts/${layout.tree_id}/action`,
        {
          action: 'reset_fragment_param',
          layout: layout.layout_id,
          template: layout.template?.name,
          fragment: frag.name,
          group: this.get_group_mode(layout) ? layout.group : undefined,
          param: param.name,
        },
        {
          params: stripNulls(this.params),
        },
      )
      .pipe(
        switchMap(() => this.loadNode(layout)),
        tap((layout) => this._currentLayout$.next(layout)),
      )
      .subscribe();
  }

  /**
   * (re)load a node, getting the node full layout data from the API.
   * @param node to be (re)loaded
   * @param mode with_group: if true, load the group specific data, if false, load the layout data without group specific data
   */
  private loadNode(
    node: LayoutData,
    mode: {
      with_group: boolean;
    } = { with_group: true },
  ): Observable<LayoutData | null> {
    return this._http
      .get<FullLayout>(`${this._printApiUrl}/prints/${this.print.id}/layouts/${node.tree_id}`, {
        params: {
          ...stripNulls(this.params),
          with_group: mode.with_group,
        },
      })
      .pipe(
        map((data) => {
          if (data.template === null) {
            node.template = null;
          } else {
            node.template = new LayoutTemplate(data.template);
          }
          return node;
        }),
        tap((data) => console.log('Template', data)),
      );
  }

  /**
   * Collapse a node
   * @param node Node to collapse
   */
  private collapse(node: LayoutData) {
    this.expanded.delete(node.tree_id);
    this.control.collapse(node);
  }

  /**
   * Set the current node
   * @param node Node to set as current
   */
  private setCurrent(node: LayoutData | null) {
    if (node === null) {
      this.current = null;
    } else {
      this.current = node.tree_id;
    }
  }
}
