import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpLog } from '../oplog';

@Component({
  selector: 'lvadg-oplog-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './oplog-display.component.pug',
  styleUrls: ['./oplog-display.component.sass'],
})
export class OplogDisplayComponent {
  @Input() public result?: OpLog;
  @Input() public minLog = 2;
  public LEVELS: Record<number, string> = {
    0: 'DETAILS FINS',
    1: 'DETAILS',
    2: 'INFO',
    3: 'WARNING',
    4: 'ERREUR',
    5: 'CRITIQUE',
  };
}
