import { RData, RouteConfigItem, RoutesDefinition } from "@solidev/data";
import { STORAGE_LINKS_ROUTES, STORAGE_ROUTES } from "./menu";
import {
  StorageDisplayViewComponent,
  StorageDisplayViewParams,
} from "./display/storage-display-view.component";
import { RS } from "../../../../constants";
import {
  StorageEditViewComponent,
  StorageEditViewParams,
} from "./edit/storage-edit-view.component";
import {
  StorageRelationsViewComponent,
  StorageRelationsViewParams,
} from "./relations/storage-relations-view.component";
import {
  StorageProducersViewComponent,
  StorageProducersViewParams,
} from "./producers/storage-producers-view.component";
import {
  StorageProductsViewComponent,
  StorageProductsViewParams,
} from "./products/storage-products-view.component";
import {
  StorageOffersViewComponent,
  StorageOffersViewParams,
} from "./offers/storage-offers-view.component";
import {
  StorageStatsViewComponent,
  StorageStatsViewParams,
} from "./stats/storage-stats-view.component";
import {
  StorageSalesViewComponent,
  StorageSalesViewParams,
} from "./sales/storage-sales-view.component";
import {
  StorageCdatasViewComponent,
  StorageCdatasViewParams,
} from "./cdatas/storage-cdatas-view.component";
import {
  StorageUsersViewComponent,
  StorageUsersViewParams,
} from "./users/storage-users-view.component";
import { CdataKeysResolver } from "../../../../models/cdata/cdata-key/cdata-key.resolver";
import { CDATAKEY_DEST } from "../../../../models/cdata/cdata-key/cdata-key.base";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
} from "../../../../models/catalog/product/product.base";

export const gen_storage_detail_routes = (
  routes: STORAGE_ROUTES,
  link_routes: STORAGE_LINKS_ROUTES,
  default_route: RouteConfigItem,
): RoutesDefinition => {
  const gen_routes: RoutesDefinition = [];
  if (routes.storage_display_route) {
    gen_routes.push({
      path: "",
      pathMatch: "full",
      component: StorageDisplayViewComponent,
      data: RData<StorageDisplayViewParams>({
        skip: true,
        route: routes.storage_display_route || default_route,
        s_menu: routes,
      }),
    });
  }
  if (routes.storage_edit_route) {
    gen_routes.push({
      path: RS.edit,
      component: StorageEditViewComponent,
      data: RData<StorageEditViewParams>({
        route: routes.storage_edit_route,
        title: "Édition",
        s_menu: routes,
      }),
    });
  }
  if (routes.storage_relations_route) {
    gen_routes.push({
      path: RS.relations,
      component: StorageRelationsViewComponent,
      data: RData<StorageRelationsViewParams>({
        route: routes.storage_relations_route,
        title: "Relations",
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        client_detail_route: link_routes.client_detail_route,
        resto_detail_route: link_routes.resto_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        provider_detail_route: link_routes.provider_detail_route,
        relation_detail_route: link_routes.relation_detail_route,
      }),
    });
  }
  if (
    routes.type === PRODUCT_TYPES.LVADG_FL &&
    routes.local === LOCAL_FILTERS.GLOBAL_CONTEXT &&
    routes.storage_global_producers_route &&
    !routes.storage_global_producers_detail_routes
  ) {
    gen_routes.push({
      path: RS.producers,
      component: StorageProducersViewComponent,
      data: RData<StorageProducersViewParams>({
        title: "Producteurs",
        route: routes.storage_global_producers_route,
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        local: routes.local,
        type: routes.type,
      }),
      resolve: {
        cdatakeys: CdataKeysResolver(CDATAKEY_DEST.PRODUCER),
      },
    });
  }
  if (
    routes.type === PRODUCT_TYPES.LVADG_FL &&
    routes.local === LOCAL_FILTERS.GLOBAL_CONTEXT &&
    routes.storage_global_products_route &&
    !routes.storage_global_products_detail_routes
  ) {
    gen_routes.push({
      path: RS.products,
      component: StorageProductsViewComponent,
      data: RData<StorageProductsViewParams>({
        route: routes.storage_global_products_route,
        title: "Produits",
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        product_detail_route: link_routes.product_detail_route,
        local: routes.local,
        type: routes.type,
      }),
    });
  }

  if (
    routes.type === PRODUCT_TYPES.LVADG_FL &&
    routes.local === LOCAL_FILTERS.LOCAL_CONTEXT &&
    routes.storage_local_producers_route &&
    !routes.storage_local_producers_detail_routes
  ) {
    gen_routes.push({
      path: RS.producers,
      component: StorageProducersViewComponent,
      data: RData<StorageProducersViewParams>({
        title: "Producteurs",
        route: routes.storage_local_producers_route,
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        local: routes.local,
        type: routes.type,
      }),
      resolve: {
        cdatakeys: CdataKeysResolver(CDATAKEY_DEST.PRODUCER),
      },
    });
  }
  if (
    routes.type === PRODUCT_TYPES.LVADG_FL &&
    routes.local === LOCAL_FILTERS.LOCAL_CONTEXT &&
    routes.storage_local_products_route &&
    !routes.storage_local_products_detail_routes
  ) {
    gen_routes.push({
      path: RS.products,
      component: StorageProductsViewComponent,
      data: RData<StorageProductsViewParams>({
        route: routes.storage_local_products_route,
        title: "Produits",
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        product_detail_route: link_routes.product_detail_route,
        local: routes.local,
        type: routes.type,
      }),
    });
  }

  if (
    routes.type === PRODUCT_TYPES.LVADG_SEA &&
    routes.storage_sea_producers_route &&
    !routes.storage_sea_producers_detail_routes
  ) {
    gen_routes.push({
      path: RS.producers,
      component: StorageProducersViewComponent,
      data: RData<StorageProducersViewParams>({
        title: "Producteurs",
        route: routes.storage_sea_producers_route,
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        local: LOCAL_FILTERS.GLOBAL_CONTEXT,
        type: routes.type,
      }),
      resolve: {
        cdatakeys: CdataKeysResolver(CDATAKEY_DEST.PRODUCER),
      },
    });
  }
  if (
    routes.type === PRODUCT_TYPES.LVADG_SEA &&
    routes.storage_sea_products_route &&
    !routes.storage_sea_products_detail_routes
  ) {
    gen_routes.push({
      path: RS.products,
      component: StorageProductsViewComponent,
      data: RData<StorageProductsViewParams>({
        route: routes.storage_sea_products_route,
        title: "Produits",
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        producer_detail_route: link_routes.producer_detail_route,
        product_detail_route: link_routes.product_detail_route,
        local: LOCAL_FILTERS.GLOBAL_CONTEXT,
        type: routes.type,
      }),
    });
  }

  // FIXME: add generic routes for all products and producers

  if (routes.storage_offers_route) {
    gen_routes.push({
      path: RS.offers,
      component: StorageOffersViewComponent,
      data: RData<StorageOffersViewParams>({
        route: routes.storage_offers_route,
        s_menu: routes,
        title: "Offres",
        storage_detail_route: link_routes.storage_detail_route,
        offer_detail_route: link_routes.offer_detail_route,
        client_detail_route: link_routes.client_detail_route,
        resto_detail_route: link_routes.resto_detail_route,
        user_detail_route: link_routes.user_detail_route,
        offertype_detail_route: link_routes.offertype_detail_route,
        offertypestorage_detail_route:
          link_routes.offertypestorage_detail_route,
        offertypestorage_offer_detail_route:
          link_routes.offertypestorage_offer_detail_route,
      }),
    });
  }
  if (routes.storage_stats_route) {
    gen_routes.push({
      path: RS.stats,
      component: StorageStatsViewComponent,
      data: RData<StorageStatsViewParams>({
        route: routes.storage_stats_route,
        s_menu: routes,
      }),
    });
  }
  if (routes.storage_sales_route) {
    gen_routes.push({
      path: RS.sales,
      component: StorageSalesViewComponent,
      data: RData<StorageSalesViewParams>({
        route: routes.storage_sales_route,
        s_menu: routes,
        storage_detail_route: link_routes.storage_detail_route,
        client_detail_route: link_routes.client_detail_route,
        resto_detail_route: link_routes.resto_detail_route,
        bill_detail_route: link_routes.bill_detail_route,
      }),
    });
  }
  if (routes.storage_cdatas_route) {
    gen_routes.push({
      path: RS.cdatas,
      component: StorageCdatasViewComponent,
      data: RData<StorageCdatasViewParams>({
        route: routes.storage_cdatas_route,
        skip: true,
        s_menu: routes,
      }),
    });
  }
  if (routes.storage_users_route) {
    gen_routes.push({
      path: RS.users,
      component: StorageUsersViewComponent,
      data: RData<StorageUsersViewParams>({
        route: routes.storage_users_route,
        skip: true,
        s_menu: routes,
      }),
    });
  }
  return gen_routes;
};
