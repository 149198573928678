
<form [formGroup]="createForm" (submit)="save()">
  <div class="row">
    <data-dispedit class="col" [model]="obj" field="process" *ngIf="!orderprocess" mode="form" [form]="createForm" [collection]="process$">Processus de commande</data-dispedit>
    <data-dispedit class="col" [model]="obj" field="storage" mode="form" [form]="createForm" [collection]="storage$" [filter]="{fields: ['id', 'name', 'cvva'].join(',')}">Dépôt (obligatoire)</data-dispedit>
    <data-dispedit class="col" [model]="obj" field="platform" mode="form" [form]="createForm" [collection]="platform$" [filter]="{fields: ['id', 'name', 'cvva'].join(',')}">Plateforme (optionnelle)</data-dispedit>
  </div>
  <div class="clearfix mt-2">
    <div class="text-danger" *ngIf="createForm.invalid">Formulaire invalide</div>
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>