
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class DocumentPermissionBase extends DataModel {
  static override readonly __name: string = "DocumentPermissionBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @datetimeField({"name": "created", "description": "created", "readonly": true})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated", "readonly": true})
  public updated!: Date;

  @charField({"name": "permission", "description": "permission", "maxLength": 10, "choices": [{"value": "READ", "desc": "Lecture"}, {"value": "OWNER", "desc": "Propri\u00e9taire"}]})
  public permission!: string;

  @foreignKeyField({"name": "target", "description": "target", "related": "Document"})
  public target!: number;

  @foreignKeyField({"name": "user", "description": "user", "related": "User"})
  public user!: number;

  @foreignKeyField({"name": "group", "description": "group", "related": "Group"})
  public group!: number;


}
