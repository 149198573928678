import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDefaults, Link } from '@solidev/data';
import { Observable } from 'rxjs';
import { Product } from '../product';
import { ProductService } from '../product.service';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';

@Component({
  selector: 'lvadg-product-shortlist',
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent, ImageDisplayComponent],
  templateUrl: './product-shortlist.component.pug',
  styleUrls: ['./product-shortlist.component.sass'],
})
export class ProductShortlistComponent implements OnInit {
  @HostBinding('class') public readonly class = 'card h-100';
  @Input() public display = 'card' as const;
  @Input() public title = 'Produits';
  @Input() public detail_route?: Link<Product>;
  @Input() public filter?: FilterDefaults = {};
  @Input() public order: string = '-created';
  @Input() public limit: number = 20;
  public products$!: Observable<Product[]>;

  constructor(private _products: ProductService) {}

  public ngOnInit(): void {
    this.products$ = this._products.list({
      page_size: this.limit,
      ordering: this.order,
      fields: ['id', 'name', 'created', 'producer_details'].join(','),
      ...this.filter,
    });
  }
}
