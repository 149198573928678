import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

import { map, Observable, Subject, switchMap } from "rxjs";
import {
  FilterDefaults,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
  TabMemoryService,
} from "@solidev/data";
import { ActivatedRoute, Router } from "@angular/router";

import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ArticleBase } from "../../../../../models/catalog/article/article.base";
import { Tariftype } from "../../../../../models/tarifs/tariftype/tariftype";
import { Earticle } from "../../../../../models/catalog/earticle/earticle";
import { EARTICLE_TYPE } from "../../../../../models/catalog/earticle/earticle.base";
import { TariftypeService } from "../../../../../models/tarifs/tariftype/tariftype.service";
import { ArticleActionService } from "../../../../../models/catalog/article/article-action.service";
import { EarticleService } from "../../../../../models/catalog/earticle/earticle.service";
import { Article } from "../../../../../models/catalog/article/article";
import { ArticleSimilarityListComponent } from "../../../../../models/catalog/article-similarity/article-similarity-list/article-similarity-list.component";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { ArticleSimilarity } from "../../../../../models/catalog/article-similarity/article-similarity";
import { ArticleNavComponent } from "../_nav/article-nav.component";

export interface ArticleQualityViewParams extends ArticleViewParams {
  article_detail_route: RouteConfigItem;
  article_list_route: RouteConfigItem;
  article_edit_route: RouteConfigItem;
  family_detail_route: RouteConfigItem;
}

export interface ArticleQualityViewData
  extends ArticleQualityViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-quality-view",
  standalone: true,
  imports: [
    CommonModule,
    ArticleNavComponent,
    HeaderActionComponent,
    ArticleSimilarityListComponent,
    SafeDeleteComponent,
  ],
  templateUrl: "./article-quality-view.component.pug",
  styleUrls: ["./article-quality-view.component.sass"],
})
export class ArticleQualityViewComponent extends BaseDetailViewComponent<
  ArticleQualityViewData,
  Article
> {
  public default_fields = [
    "similar_details",
    "differences",
    "description",
    "similar_tarifs_count",
    "similar_mappings_count",
    "created",
    "updated",
    "actions",
  ];
  public reload$ = new Subject<void | boolean>();
  protected articleFilter$!: Observable<FilterDefaults>;
  protected article_detail_route!: Link<ArticleBase>;
  protected article_list_route!: Link<ArticleBase>;
  protected article_edit_route!: Link<ArticleBase>;
  protected articleTarifTypes$!: Observable<Tariftype[]>;
  protected articleMappings$!: Observable<Earticle[]>;
  protected EAT = EARTICLE_TYPE;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _tts: TariftypeService,
    private _aas: ArticleActionService,
    private _eams: EarticleService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit(): void {
    super.postNgOnInit();
    this.articleFilter$ = this.data$.pipe(
      map((data) => ({ article: data.article.id })),
    );
    this.articleTarifTypes$ = this.data$.pipe(
      switchMap((data) =>
        this._tts.list({
          article: data.article.id,
          fields: ["id", "name"].join(","),
        }),
      ),
    );
    this.articleMappings$ = this.data$.pipe(
      switchMap((data) =>
        this._eams.list({
          article: data.article.id,
          fields: ["id", "libelle", "type"].join(","),
        }),
      ),
    );
  }

  public override setRouteData(data: ArticleQualityViewData): void {
    super.setRouteData(data);
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      data,
      this._router,
    );
    this.article_list_route = new Link<ArticleBase>(
      data.article_list_route,
      data,
      this._router,
    );
    this.article_edit_route = new Link<ArticleBase>(
      data.article_edit_route,
      data,
      this._router,
    );
  }

  public async clone(article: Article) {
    const res = await this._aas.action(article, { action: "clone_article" });
    if (res.success && this.article_detail_route) {
      await this._router.navigate(
        this.article_edit_route.route({ article: res.article }),
      );
    }
  }

  public async refreshQuality(article: Article) {
    const res = await this._aas.action(article, { action: "refresh_quality" });
    if (res.success) {
      this.reload$.next(true);
    }
  }

  public async similarityAction(
    article: Article,
    act: ModelListAction<ArticleSimilarity>,
  ) {
    if (
      act.action === "merge" &&
      act.model &&
      (act.model.similar || act.model.similar_details)
    ) {
      const similar = act.model.similar_details?.id || act.model.similar;
      const res = await this._aas.action(article, {
        action: "merge_article",
        source_id: similar,
      });
      if (res.success) {
        this.reload$.next(true);
      }
    }
  }
}
