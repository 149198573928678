
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action title="Régions géographiques" ri="location" level="h1" [subtitle]="data.georegion.name">
    <ng-container actions>
      <data-safe-delete [model]="data.georegion" (deleted)="deleted()" display="button"><span class="ms-2">Supprimer la région géographique</span></data-safe-delete>
    </ng-container>
  </lvadg-header-action>
  <div class="card">
    <div class="card-header">Éditer la région géographique</div>
    <div class="card-body">
      <lvadg-georegion-manage [georegion]="data.georegion"></lvadg-georegion-manage>
    </div>
  </div>
</ng-container>