
<div class="row" *ngIf="family(); let f">
  <div class="col-md-4">
    <div class="card h-100">
      <div class="card-header">
        <lvadg-icon ri="info">Informations sur la famille</lvadg-icon>
      </div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12">
            <dt>Arborescence (parents)</dt>
            <dd>
              <lvadg-family-display [family]="f" mode="path" [detail_route]="family_detail_route()"></lvadg-family-display>
            </dd>
          </div>
          <div class="col-12">
            <dt>Nom complet (calculé)</dt>
            <dd>{{ f.cfullname }}</dd>
          </div>
          <data-dispedit class="col-4" [model]="f" field="type" mode="dd">Type de famille</data-dispedit>
          <data-dispedit class="col-4" [model]="f" field="dmode" mode="dd">Mode d'affichage</data-dispedit>
          <data-dispedit class="col-4" [model]="f" field="dispname" mode="dd">Afficher dans l'arborescence ?</data-dispedit>
          <data-dispedit class="col-12" [model]="f" field="name" mode="dd">Nom de la famille</data-dispedit>
          <data-dispedit class="col-12" [model]="f" field="content_src" mode="dd">Description de la famille (markdown)</data-dispedit>
          <!-- data-dispedit.col-6([model]="f", field="status", mode="dd") État de la famille (non utilisé)-->
        </dl>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card h-100">
      <div class="card-header">
        <lvadg-icon ri="info">Informations articles/produits par défaut</lvadg-icon>
      </div>
      <div class="card-body">
        <dl class="row">
          <data-dispedit class="col-12" [model]="f" field="article_name" mode="dd">Nom article par défaut</data-dispedit>
          <div class="col-12">
            <dt>Saisonnalité par défaut</dt>
            <dd>
              <lvadg-seasons-manage [seasons]="f.article_seasons" [editable]="true" size="medium" [zone]="f.dmode" (changed)="saveSeasons($event).then()"></lvadg-seasons-manage>
            </dd>
          </div>
          <data-dispedit class="col-12" [model]="f" field="article_description" mode="dd">Description article par défaut (markdown)</data-dispedit>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card h-100">
      <div class="card-header">
        <lvadg-icon ri="info">Enfants directs</lvadg-icon>
      </div>
      <div class="card-body">
        <ul>
          <li *ngFor="let child of children$|async"><a [routerLink]="family_detail_route()?.route({family: child})">
              <div class="d-flex justify-content-between align-items-center"><span>{{ child.name }}</span><span><span class="badge bg-primary mx-1">{{ child.nbproducts }} produits</span><span class="badge bg-primary mx-1">{{ child.nbarticles }} articles</span></span></div></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3" *ngIf="family(); let f">
  <div class="card-header">
    <lvadg-icon ri="image">Image</lvadg-icon>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-3 text-center">
        <div class="text-info">Image de la famille (en propre ou héritée)</div>
        <lvadg-image-display [image]="f.clogo_details" mode="preview"></lvadg-image-display>
      </div>
      <div class="col-sm-9">
        <div class="text-info text-center">Image de la famille (spécifique)</div>
        <lvadg-logo-manage [model]="f" [collection]="families$"></lvadg-logo-manage>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3" *ngIf="family(); let f">
  <div class="card-header">
    <lvadg-icon ri="image">Galerie d'images</lvadg-icon>
  </div>
  <div class="card-body">
    <lvadg-gallery-manage [model]="f" [collection]="families$"></lvadg-gallery-manage>
  </div>
</div>
<div class="card mt-3" *ngIf="family(); let f">
  <div class="card-header">Opérations d'administration</div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-3">
        <div class="p-4 text-center">
          <button class="btn btn-primary" (click)="updateComputedFields()">Mettre à jour les champs calculés</button>
        </div>
        <div class="div text-center p-4 bg-warning-subtle mt-4">
          <data-safe-delete [model]="f" [service]="families$" display="button">SUPPRIMER LA FAMILLE ?</data-safe-delete>
        </div>
      </div>
      <div class="col-sm-9" *ngIf="stats$|async; let stats">
        <h3 class="text-danger text-center">Déplacements / fusions</h3>
        <input class="form-control" type="text" placeholder="Destination" [formControl]="destForm">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Destination</th>
              <th>Niveau</th>
              <th>Produits</th>
              <th>Articles</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let dest of destResults|async">
              <tr *ngIf="dest.id != f.id">
                <td>{{ dest.full_path }}</td>
                <td>{{ dest.tree_path.length }}</td>
                <td>{{ dest.nbproducts }}</td>
                <td>{{ dest.nbarticles }}</td>
                <td>
                  <button class="btn btn-primary btn-sm mx-2" (click)="moveFamily(dest.id).then()" *ngIf="f.tree_path.length == dest.tree_path.length+1">
                    <lvadg-icon ri="move">Famille</lvadg-icon>
                  </button>
                  <button class="btn btn-primary btn-sm mx-2" (click)="moveProducts(dest.id).then()" *ngIf="(stats?.nbproducts||0)&gt; 0 &amp;&amp; dest.tree_path.length &gt;= 4">
                    <lvadg-icon ri="move">{{stats!.nbproducts!}} produit(s)</lvadg-icon>
                  </button>
                  <button class="btn btn-primary btn-sm mx-2" (click)="moveArticles(dest.id).then()" *ngIf="(stats?.nbarticles||0)&gt; 0 &amp;&amp; f.tree_path.length==6 &amp;&amp; dest.tree_path.length == 6">
                    <lvadg-icon ri="move"> {{stats!.nbarticles!}} article(s)</lvadg-icon>
                  </button>
                  <button class="btn btn-primary btn-sm mx-2" (click)="mergeFamily(dest.id).then()" *ngIf="f.tree_path.length==6 &amp;&amp; dest.tree_path.length == 6">
                    <lvadg-icon ri="merge">Fusion</lvadg-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>