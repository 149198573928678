import { BaseRouteParams, RouteConfigItem } from '@solidev/data';

export interface OrderprocessstorageListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  orderprocess_detail_route: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  platform_detail_route: RouteConfigItem;
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
  orderprocessstoragepermission_detail_route: RouteConfigItem;
}

export interface OrderprocessstorageListViewData
  extends OrderprocessstorageListViewParams {}
