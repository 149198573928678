import { Component } from '@angular/core';
import { OrderitemListViewData } from './orderitem-list-view.component.params';
import { Subject } from 'rxjs';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { OrderitemListComponent } from '../../../../models/orders/orderitem/orderitem-list/orderitem-list.component';
import { OrderitemCreateComponent } from '../../../../models/orders/orderitem/orderitem-create/orderitem-create.component';
import { Orderitem } from '../../../../models/orders/orderitem/orderitem';

@Component({
  selector: 'lvadg-orderitem-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, OrderitemListComponent, OrderitemCreateComponent],
  templateUrl: './orderitem-list-view.component.pug',
  styleUrls: ['./orderitem-list-view.component.sass'],
})
export class OrderitemListViewComponent extends BaseListViewComponent<OrderitemListViewData, Orderitem> {
  public create: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();

  public override setRouteData(data: OrderitemListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
