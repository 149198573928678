
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="article(); let a">
  <li class="nav-item" *ngIf="article_detail_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Fiche article</a></li>
  <ng-container *ngIf="a.fournisseur">
    <li class="nav-item" *ngIf="article_provider_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Fournisseur</a></li>
    <li class="nav-item" *ngIf="article_providerdocuments_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Documents fournisseur</a></li>
  </ng-container>
  <li class="nav-item" *ngIf="article_edit_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Édition article</a></li>
  <li class="nav-item" *ngIf="article_articledocuments_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Documents article</a></li>
  <li class="nav-item" *ngIf="article_eamappings_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Mappings</a></li>
  <li class="nav-item" *ngIf="article_tarifs_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Tarifs</a></li>
  <li class="nav-item" *ngIf="article_prices_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Prix tarifs</a></li>
  <li class="nav-item" *ngIf="article_quality_route(); let r"><a class="nav-link" [routerLink]="r.route({article: a})" routerLinkActive="active">Contrôle</a></li>
</ul>