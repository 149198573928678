import { LabelBase } from './label.base';
import { detailsField } from '@solidev/data';
import { ImageDisplay } from '../../documents/image/image.base';

export class Label extends LabelBase {
  static override readonly __name: string = 'Label';

  @detailsField({
    model: ImageDisplay,
    readonly: true,
    description: 'Image du logo',
  })
  public logo_details?: ImageDisplay;
}
