import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyIntervalStat } from '../family-stats';
import { ArticleIntervalStat } from '../article-stats';

@Component({
  selector: 'lvadg-tarif-interval-stat-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-interval-stat-display.component.pug',
  styleUrls: ['./tarif-interval-stat-display.component.sass'],
})
export class TarifIntervalStatDisplayComponent implements OnInit {
  @Input() public stat?: FamilyIntervalStat | ArticleIntervalStat;
  @Output() public selected = new EventEmitter();
  public color: string = '';

  constructor() {}

  ngOnInit(): void {
    if (this.stat) {
      if (this.stat.sdev > 0.5 * this.stat.avg) {
        this.color = 'text-danger';
      } else if (this.stat.sdev > 0.2 * this.stat.avg) {
        this.color = 'text-warning';
      }
    }
  }
}
