import { Component, Input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Article } from "../article";
import { DispeditComponent, Link } from "@solidev/data";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { PackagingService } from "../../meta/packaging/packaging.service";
import { ArticleActionService } from "../article-action.service";
import { ArticleService } from "../article.service";
import { FamilyBase } from "../../family/family.base";
import { Router, RouterLink } from "@angular/router";
import { FamilyService } from "../../family/family.service";
import { CalibreService } from "../../meta/calibre/calibre.service";
import { CategoryService } from "../../meta/category/category.service";
import { ParticularityService } from "../../meta/particularity/particularity.service";
import { PresentationService } from "../../meta/presentation/presentation.service";
import { ProcessService } from "../../meta/process/process.service";
import { TreatmentService } from "../../meta/treatment/treatment.service";
import { ProductionService } from "../../meta/production/production.service";
import { BrandService } from "../../meta/brand/brand.service";
import { ProviderService } from "../../../structures/provider/provider.service";
import { OriginService } from "../../meta/origin/origin.service";
import { RegionService } from "../../meta/region/region.service";
import { UsageService } from "../../meta/usage/usage.service";
import { AllergenService } from "../../meta/allergen/allergen.service";
import { firstValueFrom } from "rxjs";
import { LabelDisplayComponent } from "../../label/label-display/label-display.component";
import { LABEL_TYPE } from "../../label/label.base";
import { CiqualAlimService } from "../../ciqual-alim/ciqual-alim.service";
import { PACKAGING_TYPE } from "../../meta/packaging/packaging.base";
import { LabelsManagerComponent } from "../../label/labels-manager/labels-manager.component";

@Component({
  selector: "lvadg-article-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    SeasonsManageComponent,
    LabelDisplayComponent,
    RouterLink,
    LabelsManagerComponent,
  ],
  templateUrl: "./article-manage.component.pug",
  styleUrls: ["./article-manage.component.sass"],
})
export class ArticleManageComponent {
  @Input() public article?: Article;
  @Input() public family_detail_route?: Link<FamilyBase>;
  public LBL = LABEL_TYPE;
  public PKTYPE = PACKAGING_TYPE;
  protected allow_labels_edition = signal(false);

  constructor(
    private _router: Router,
    private _article: ArticleService,
    public families$: FamilyService,
    public calibres$: CalibreService,
    public categories$: CategoryService,
    public packaging$: PackagingService,
    public particularites$: ParticularityService,
    public presentations$: PresentationService,
    public processes$: ProcessService,
    public traitements$: TreatmentService,
    public productions$: ProductionService,
    public marques$: BrandService,
    public providers$: ProviderService,
    public origines$: OriginService,
    public regions$: RegionService,
    // public clients$: ClientService,
    public usages$: UsageService,
    public allergenes$: AllergenService,
    public ciquals$: CiqualAlimService,
    private aactions: ArticleActionService,
  ) {}

  public async genLibelle(a: Article) {
    const res = await this.aactions.action(a, { action: "get_auto_libelle" });
    this.article = this._article.create(res.article);
  }

  public async useAutoLibelle(a: Article) {
    const res = await this.aactions.action(a, { action: "use_auto_libelle" });
    this.article = this._article.create(res.article);
    // Route reload, to update the titles and data
    this._router.navigate([this._router.url], {
      onSameUrlNavigation: "reload",
    });
  }

  public async updateField(a: Article, field: string, value?: any) {
    if (this.article) {
      if (value !== undefined) {
        this.article.setFV(field, value);
      }
      const res = await firstValueFrom(
        this.article.update([field], { updateModel: true }),
      );
    }
  }

  public async reload() {
    this.article = await firstValueFrom(
      this._article.fetch(this.article!.id, { params: { db: "sync" } }),
    );
  }
}
