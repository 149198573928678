
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OfferRestoService} from "./offer-resto.service";
import { OfferResto } from "./offer-resto";

@Injectable({
  providedIn: 'root'
})
export class OfferRestoResolver implements Resolve<OfferResto> {
  constructor(private _rs: OfferRestoService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OfferResto> {
    return this._rs.fetch(+route.params["offerrestoId"])
  }
}
