import { TariftypeRegionPermissionBase } from './tariftype-region-permission.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { Genericregion } from '../../locations/genericregion/genericregion';

export class TariftypeRegionPermission extends TariftypeRegionPermissionBase {
  static override readonly __name: string = 'TariftypeRegionPermission';
  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: Genericregion,
    readonly: true,
    description: 'Région',
  })
  public region_details?: Genericregion;
}
