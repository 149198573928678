
<form [formGroup]="createForm">
  <div class="row g-3 mb-3">
    <div class="col-12" *ngIf="!producer">
      <data-dispedit [model]="doc" [form]="createForm" field="producer" [collection]="producers$" [filter]="{fields: ['id', 'name'].join(',')}">Producteur</data-dispedit>
    </div>
    <div class="col-12">
      <data-dispedit [model]="doc" [form]="createForm" field="type">Type</data-dispedit>
    </div>
  </div>
</form>
<lvadg-media-upload [service]="producerdocument$" [enabled]="createForm.valid &amp;&amp; (!!producer || !!createForm.value.producer)" [model]="doc" [prepare]="uploadPrepare" (uploadDone)="uploaded.emit($event)"></lvadg-media-upload>