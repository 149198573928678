import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Orderprocess } from '../orderprocess';
import { OrderprocessService } from '../orderprocess.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { OrderprocessPermissionDisplayComponent } from '../../orderprocess-permission/orderprocess-permission-display/orderprocess-permission-display.component';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';
import { TariftypeBase } from '../../../tarifs/tariftype/tariftype.base';
import { RruleDisplayComponent } from '../../../../components/utils/rrule-display/rrule-display.component';

@Component({
  selector: 'lvadg-orderprocess-list',
  standalone: true,
  templateUrl: './orderprocess-list.component.pug',
  styleUrls: ['./orderprocess-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    OrderprocessPermissionDisplayComponent,
    TariftypeDisplayComponent,
    RruleDisplayComponent,
  ],
})
export class OrderprocessListComponent extends ModellistComponent<Orderprocess> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;

  constructor(coll: OrderprocessService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
