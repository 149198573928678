
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnmtarif } from '../rnmtarif';
@Component({
  selector: 'lvadg-rnmtarif-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmtarif-manage.component.pug',
  styleUrls: ['./rnmtarif-manage.component.sass']
})
export class RnmtarifManageComponent implements OnInit {
  @Input() public rnmtarif?: Rnmtarif;

  constructor() { }

  ngOnInit(): void {
  }

}
