import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../tarif';
import { Upload } from '../../../../documents/upload/upload';
import { UploadFile } from '@solidev/data';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
} from '../../../../documents/upload/media-upload/media-upload.component';
import { TarifService } from '../../tarif.service';
import { TarifActionService } from '../../tarif-action.service';

interface LineDiff {
  libelle: string;
  oldprice: number | null;
  newprice: number | null;
}

@Component({
  selector: 'lvadg-tarif-import-generic-prices',
  standalone: true,
  imports: [CommonModule, MediaUploadComponent],
  templateUrl: './tarif-import-generic-prices.component.pug',
  styleUrls: ['./tarif-import-generic-prices.component.sass'],
})
export class TarifImportGenericPricesComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Output() public cancelled = new EventEmitter<void>();
  @Output() public done = new EventEmitter<void>();
  @Input() public action:
    | 'reimport_prices'
    | 'read_custom_articles'
    | 'read_custom_prices' = 'reimport_prices';
  public doc!: Upload;
  public step = 0;

  public result?: {
    delta: LineDiff[];
    upload: number;
  };
  public uploadPrepare: (
    model: Upload,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<Upload>> = this._uploadPrepare.bind(this);
  public uploadUrl!: string;

  constructor(
    private _tarifs: TarifService,
    private _tact: TarifActionService
  ) {}

  ngOnInit(): void {
    this.doc = new Upload();
    if (this.tarif) {
      this.uploadUrl = this._tarifs.getUrl(this.tarif.id, {
        suffix: '/action',
      });
    }
  }

  onUploaded($event: {
    model: Upload;
    response: { data: { delta: LineDiff[]; upload: number } };
    status: number;
  }) {
    if ($event.response.data) {
      this.result = $event.response.data;
      this.step = 1;
    }
  }

  public async validateImport(doit: boolean) {
    if (doit) {
      const res = await this._tact.action(this.tarif, {
        action: this.action,
        confirm: true,
        upload_id: this.result?.upload,
        metadata_id: 2,
      } as any);
      if (res.success) {
        this.step = 3;
        this.done.emit();
      }
    } else {
      this.step = 3;
      this.cancelled.emit();
    }
  }

  private async _uploadPrepare(
    model: Upload,
    file: UploadFile
  ): Promise<IPreparedUploadFile<Upload>> {
    return {
      model,
      url: this.uploadUrl,
      data: {
        action: this.action,
        tarif: this.tarif.id,
        metadata_id: 2,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
      },
    };
  }
}
