
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Article } from "./article";

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends Collection<Article> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/articles", Article);
  }
}
