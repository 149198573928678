import { Component } from "@angular/core";
import { BaseRouteParams } from "@solidev/data";
import { Provider } from "../../../../../models/structures/provider/provider";
import { PROVIDER_ROUTES } from "../menu";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProviderNavComponent } from "../_nav/provider-nav.component";

export interface ProviderDisplayViewParams extends BaseRouteParams {
  pr_menu: PROVIDER_ROUTES;
}

export interface ProviderDisplayViewData extends ProviderDisplayViewParams {
  provider: Provider;
}

@Component({
  selector: "lvadg-provider-display-view",
  standalone: true,
  imports: [AsyncPipe, HeaderActionComponent, ProviderNavComponent, NgIf],
  templateUrl: "./provider-display-view.component.pug",
  styleUrl: "./provider-display-view.component.sass",
})
export class ProviderDisplayViewComponent extends BaseDetailViewComponent<
  ProviderDisplayViewData,
  Provider
> {
  public override setRouteData(data: ProviderDisplayViewData) {
    super.setRouteData(data);
  }
}
