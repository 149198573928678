import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { FamilyStats } from '../family-stats';
import { FamilyStatsService } from '../family-stats.service';
import {
  FieldsParams,
  FiltersParams,
  ModelListAutocompleteMultiFilter,
  ModelListNumberFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TarifFamilyStatsComponent } from '../tarif-family-stats/tarif-family-stats.component';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { TarifIntervalStatDisplayComponent } from '../tarif-interval-stat-display/tarif-interval-stat-display.component';
import { INTERVAL_GROUPER_CHOICES, PERIOD_CHOICES } from '../../../../components/utils/periods';
import { Article } from '../../../catalog/article/article';
import { Tariftype } from '../../tariftype/tariftype';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';
import { LABEL_TYPE } from '../../../catalog/label/label.base';
import { LabelService } from '../../../catalog/label/label.service';
import { OriginService } from '../../../catalog/meta/origin/origin.service';
import { NgStringPipesModule } from 'ngx-pipes';

@Component({
  selector: 'lvadg-tarif-families-stats',
  standalone: true,
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    FlagsDisplayComponent,
    ImageDisplayComponent,
    TarifFamilyStatsComponent,
    TarifIntervalStatDisplayComponent,
    NgStringPipesModule,
  ],
  templateUrl: './tarif-families-stats.component.pug',
  styleUrls: ['./tarif-families-stats.component.sass'],
})
export class TarifFamiliesStatsComponent extends ModellistComponent<FamilyStats> {
  public stats_fields: string[] = ['name', 'id'];
  public stats_rows: string[] = [];
  public override ctype = 'family';

  constructor(
    coll: FamilyStatsService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _ls: LabelService,
    private _ors: OriginService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['tarifs_stats_grouper', 'tarifs_stats_period', 'search'],
      filters: [
        new ModelListSelectFilter({
          field: 'tarifs_stats_grouper',
          name: 'tarifs_stats_grouper',
          label: 'Intervalle de statistique',
          choices: INTERVAL_GROUPER_CHOICES,
        }),
        new ModelListSelectFilter({
          field: 'tarifs_stats_period',
          name: 'tarifs_stats_period',
          label: 'Période de statistique',
          choices: PERIOD_CHOICES,
        }),
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListTextFilter({
          field: 'article-libelle',
          name: 'article-libelle',
          label: '[Articles] Recherche par texte du libellé',
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'article-egalims',
          name: 'article-egalims',
          label: '[Articles] Recherche par label(s)',
          collection: this._ls,
          display: (l) => `[${l.type_short}] ${l.name}`,
          filter: {
            types: [LABEL_TYPE.FL, LABEL_TYPE.SEA, LABEL_TYPE.OTHER].join(','),
          },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'article-origines',
          name: 'article-origines',
          label: '[Articles] Recherche par origine(s)',
          collection: this._ors,
          display: (o) => `[${o.code}] ${o.name}`,
        }),
        new ModelListSelectFilter({
          field: 'article-local',
          name: 'article-local',
          label: '[Articles] Recherche local/non local',
          mfield: 'local',
          model: Article,
        }),

        new ModelListSelectFilter({
          field: 'article-udf_unit',
          name: 'article-udf_unit',
          label: '[Articles] Filtrer par uf',
          model: Article,
          mfield: 'udf_unit',
        }),

        new ModelListTextFilter({
          field: 'tariftype-name',
          name: 'tariftype-name',
          label: '[Tarif] Recherche par titre du tarif',
        }),
        new ModelListSelectFilter({
          field: 'tariftype-segment',
          name: 'tariftype-segment',
          label: '[Tarif] Filtrer par segment',
          model: Tariftype,
          mfield: 'segment',
        }),
        new ModelListNumberFilter({
          field: 'sdev',
          name: 'sdev',
          label: 'Ecart type',
          operations: ['gte', 'lte'],
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const fields = super.getFields();
    return fields;
  }

  public override tapPreResults(res: FamilyStats[]) {
    const intervals = new Set<string>([]);
    for (const r of res) {
      if (r.interval_stats) {
        for (const i of r.interval_stats.intervals) {
          if (!intervals.has(i)) {
            intervals.add(i);
          }
        }
      }
    }
    this.stats_rows = Array.from(intervals.values()).sort();
    this.stats_fields = this.list.fields.enabled.concat(this.stats_rows);
  }
}
