
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { TariftypeRegion } from "./tariftype-region";

@Injectable({
  providedIn: 'root'
})
export class TariftypeRegionService extends Collection<TariftypeRegion> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/tariftyperegions", TariftypeRegion);
  }
}
