import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable } from 'rxjs';
import { Resto } from '../../../../../models/structures/resto/resto';
import { RESTO_ROUTES } from '../menu';
import { RestoNavComponent } from '../_nav/resto-nav.component';

export interface RestoDisplayViewParams extends BaseRouteParams {
  r_menu: RESTO_ROUTES;
}

export interface RestoDisplayViewData extends RestoDisplayViewParams {
  resto: Resto;
}

@Component({
  selector: 'lvadg-resto-display-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, RestoNavComponent, IconComponent],
  templateUrl: './resto-display-view.component.pug',
  styleUrls: ['./resto-display-view.component.sass'],
})
export class RestoDisplayViewComponent extends BaseDetailViewComponent<RestoDisplayViewData, Resto> {
  public restoFilter$!: Observable<FilterDefaults>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: RestoDisplayViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.restoFilter$ = this.data$.pipe(map((d) => ({ resto: d.resto.id })));
  }
}
