
<div class="fpdialog">
  <div class="text-center">
    <h1 class="my-5">Connexion #lavieadugout</h1>
  </div>
  <div class="card" [class.border-danger]="error!=''">
    <div class="card-header" [class.bg-danger]="error!=''" [class.text-white]="error!=''"><i class="bi bi-box-arrow-in-right me-2"></i>Connexion</div>
    <div class="card-body">
      <div class="my-4" *ngIf="canLoginByIp()">
        <p class="text-info">Vous êtes connecté depuis une adresse IP connue. Vous pouvez vous connecter sans identifiant en cliquant sur le bouton ci-dessous.</p>
        <button class="btn btn-primary w-100 p-3" (click)="submit(true)">Connexion automatique</button>
        <div class="my-4">
          <hr>
          <p class="text-info">Vous pouvez également vous connecter en saisissant votre identifiant et votre mot de passe ci-dessous.</p>
        </div>
      </div>
      <form [formGroup]="loginForm" (submit)="submit(false)">
        <div class="mb-3">
          <label class="form-label" for="username">Identifiant ou adresse email</label>
          <input class="form-control" type="email" aria-describedby="usernamehelp" formControlName="username" autocomplete="username email" id="username">
          <div class="form-text" id="usernamehelp">Identifiant ou adresse email</div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="password">Mot de passe</label>
          <input class="form-control" type="password" aria-describedby="passwordhelp" formControlName="password" autocomplete="current-password" id="password">
          <div class="form-text" id="passwordhelp">Mot de passe</div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="!reactivate &amp;&amp; error!=''">
            <p class="text-danger">{{error}}</p>
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary w-100" type="submit" [disabled]="loginForm.pristine || !loginForm.valid">Connexion</button>
          </div>
          <div class="col-md-6" *ngIf="pwResetRoute"><a class="btn btn-link w-100 text-wrap" [routerLink]="pwResetRoute" [queryParams]="{email: this.loginForm.value.username, mode: 'ask'}">Mot de passe oublié ?</a></div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12" *ngIf="reactivate &amp;&amp; reactivateRoute">
          <div class="text-center mt-4">
            <div class="text-warning my-2">Votre compte a été désactivé. Vous pouvez le réactiver en confirmant à nouveau votre email.</div>
            <button class="btn btn-outline-warning my-2" [routerLink]="reactivateRoute" [queryParams]="{email: this.loginForm.value.username, mode: 'ask'}">Réactiver mon compte</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-5"><img src="/assets/images/logos/vivalya.png" width="128px"></div>
</div>