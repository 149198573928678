import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  AuthService,
  LiveMessageComponent,
  RouteSupportService,
  SupportMessageZoneComponent,
  WsService,
} from "@vivalya/lvadg";
import {
  BreadcrumbComponent,
  MessageZoneComponent,
  UpdateComponent,
} from "@solidev/data";
import { HeaderComponent } from "./parts/header/header.component";
import { FooterComponent } from "./parts/footer/footer.component";

@Component({
  selector: "lva-root",
  templateUrl: "./app.component.pug",
  styleUrls: ["./app.component.sass"],
  standalone: true,
  imports: [
    RouterModule,
    MessageZoneComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    UpdateComponent,
    LiveMessageComponent,
    SupportMessageZoneComponent,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminAppComponent {
  private _todos = inject(RouteSupportService);
  private _auth = inject(AuthService);
  private _ws = inject(WsService);
}
