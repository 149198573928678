
<ng-container *ngIf="offertype">
  <ng-container *ngIf="mode==='cline'">
    <ng-container *ngIf="!detail_route">[{{offertype.id}}] {{offertype.name}}<small class="ms-1">{{offertype.client_details?.name}}</small></ng-container>
    <ng-container *ngIf="detail_route"><a [routerLink]="detail_route.route({offertype})">[{{offertype.id}}] {{offertype.name}}<small class="ms-1">{{offertype.client_details?.name}}</small></a><i class="bi bi-link-45deg ms-1"></i></ng-container>
  </ng-container>
  <ng-container *ngIf="mode==='id'"><code *ngIf="!detail_route">{{offertype.id}}</code><a *ngIf="detail_route" [routerLink]="detail_route.route({offertype})"><code>{{offertype.id}}</code><i class="bi bi-link-45deg ms-1"></i></a></ng-container>
  <ng-container *ngIf="mode==='line'">
    <ng-container *ngIf="!detail_route">[{{offertype.id}}] {{offertype.name}}</ng-container>
    <ng-container *ngIf="detail_route"><a [routerLink]="detail_route.route({offertype})">[{{offertype.id}}] {{offertype.name}}</a><i class="bi bi-link-45deg ms-1"></i></ng-container>
  </ng-container>
</ng-container>