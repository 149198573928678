import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { LayoutData, LayoutTree } from '../layout/layout';
import { TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from '../layout/template';
import { LayoutTemplateProviderCheckboxComponent } from './layout-template-provider-checkbox/layout-template-provider-checkbox.component';
import { LayoutTemplateProviderSelectComponent } from './layout-template-provider-select/layout-template-provider-select.component';
import { LayoutTemplateProviderSelectImageComponent } from './layout-template-provider-select-image/layout-template-provider-select-image.component';
import { LayoutTemplateProviderUploadImageComponent } from './layout-template-provider-upload-image/layout-template-provider-upload-image.component';
import { LayoutTemplateProviderSingleComponent } from './layout-template-provider-single/layout-template-provider-single.component';
import { LayoutTemplateProviderCustomTextComponent } from './layout-template-provider-custom-text/layout-template-provider-custom-text.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutTemplateProviderCustomFormattedTextComponent } from './layout-template-provider-custom-formatted-text/layout-template-provider-custom-formatted-text.component';

@Component({
  selector: 'lvadg-layout-template-editor',
  standalone: true,
  imports: [
    CommonModule,
    LayoutTemplateProviderCheckboxComponent,
    LayoutTemplateProviderSelectComponent,
    LayoutTemplateProviderSelectImageComponent,
    LayoutTemplateProviderUploadImageComponent,
    LayoutTemplateProviderSingleComponent,
    LayoutTemplateProviderCustomTextComponent,
    LayoutTemplateProviderCustomFormattedTextComponent,
    NgbAccordionModule,
  ],
  templateUrl: './layout-template-editor.component.pug',
  styleUrls: ['./layout-template-editor.component.sass'],
})
export class LayoutTemplateEditorComponent {
  @Input({ required: true }) public tree!: LayoutTree;
  @Input({ required: true }) public layout$!: Observable<LayoutData | null>;
  @Input() public reload$ = new Subject<void>();
  public openedFragments: string[] = [];

  public select(
    layout: LayoutData,
    fragment: TemplateFragment,
    param: TemplateParam,
    prov: TemplateProvider,
    choice: TemplateChoice,
  ) {
    this.tree.set_fragment_param({
      layout: layout,
      group: layout.group,
      fragment: fragment,
      param: param,
      provider: prov,
      choice,
      value: choice.name,
    });
  }

  public onShow(frag: string) {
    this.openedFragments.push(frag);
  }

  public onHide(frag: string) {
    this.openedFragments = this.openedFragments.filter((f) => f !== frag);
  }

  public async toggleGroup(layout: LayoutData) {
    this.tree.toggle_group_mode(layout);
  }

  public resetParam(layout: LayoutData, frag: TemplateFragment, param: TemplateParam) {
    this.tree.reset_fragment_param(layout, frag, param);
  }
}
