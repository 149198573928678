import { TarifRelationBase } from './tarif-relation.base';
import { detailsField } from '@solidev/data';
import { TariftypeRelationBase } from '../tariftype-relation/tariftype-relation.base';
import { TarifBase } from '../tarif/tarif.base';

export class TarifRelation extends TarifRelationBase {
  static override readonly __name: string = 'TarifRelation';
  @detailsField({
    model: TariftypeRelationBase,
    description: 'Relation',
    readonly: true,
  })
  public relation_details?: TariftypeRelationBase;

  @detailsField({
    model: TarifBase,
    description: 'Tarif source',
    readonly: true,
  })
  public src_details?: TarifBase;

  @detailsField({
    model: TarifBase,
    description: 'Tarif cible',
    readonly: true,
  })
  public dest_details?: TarifBase;
}
