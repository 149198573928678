import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { ArticleTarifTemplate } from "../article-tarif-template";
import { ArticleTarifTemplateService } from "../article-tarif-template.service";
import {
  FactorPipe,
  FieldsParams,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  SafeDeleteComponent,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { ArticleBase } from "../../../catalog/article/article.base";
import { TariftypeBase } from "../../tariftype/tariftype.base";
import { EarticleBase } from "../../../catalog/earticle/earticle.base";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { AttStatus } from "../article-tarif-template.base";
import { ArticleDisplayComponent } from "../../../catalog/article/article-display/article-display.component";
import { EarticleDisplayComponent } from "../../../catalog/earticle/earticle-display/earticle-display.component";
import { TariftypeDisplayComponent } from "../../tariftype/tariftype-display/tariftype-display.component";
import { Article } from "../../../catalog/article/article";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { LabelService } from "../../../catalog/label/label.service";

@Component({
  selector: "lvadg-article-tarif-template-list",
  standalone: true,
  templateUrl: "./article-tarif-template-list.component.pug",
  styleUrls: ["./article-tarif-template-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    LabelDisplayComponent,
    FactorPipe,
    SafeDeleteComponent,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
    TariftypeDisplayComponent,
  ],
})
export class ArticleTarifTemplateListComponent extends ModellistComponent<ArticleTarifTemplate> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public earticle_detail_route?: Link<EarticleBase>;
  public AttStatus = AttStatus;

  constructor(
    coll: ArticleTarifTemplateService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _ls: LabelService,
  ) {
    super(coll, list, ofc);
  }

  public override getFields(): FieldsParams {
    const fields = super.getFields();
    fields.custom?.push({ name: "article__uf", label: "UF Article" });
    fields.custom?.push({ name: "article__code", label: "Code Article" });
    fields.custom?.push({ name: "article__family", label: "Famille Article" });
    fields.custom?.push({ name: "article__uci", label: "UCI" });
    fields.custom?.push({ name: "article__calibre", label: "Calibre" });
    fields.custom?.push({ name: "article__categorie", label: "Catégorie" });
    fields.custom?.push({ name: "article__origine", label: "Origine" });
    fields.custom?.push({ name: "article__local", label: "Local ?" });
    fields.custom?.push({ name: "article__colisage", label: "Colisage" });
    fields.custom?.push({ name: "article__udv_value", label: "UDV" });
    fields.custom?.push({ name: "article__decondi_mode", label: "Décondi" });
    fields.custom?.push({
      name: "eaclient__code",
      label: "Code article client",
    });
    fields.custom?.push({
      name: "eaclient__libelle",
      label: "Libellé article client",
    });
    fields.custom?.push({
      name: "article__egalim",
      label: "Labels et certifications",
    });
    return fields;
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "egalim_status",
          "udf_unit",
          "egalims",
        ],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filtrer par état",
          model: ArticleTarifTemplate,
          mfield: "status",
        }),
        new ModelListSelectFilter({
          field: "egalim_status",
          name: "egalim_status",
          label: "Recherche par état Égalim",
          model: Article,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "egalims",
          name: "egalims",
          label: "Recherche par label(s)",
          collection: this._ls,
          display: (l) => `[${l.type_short}] ${l.name}`,
          filter: {
            types: [LABEL_TYPE.FL, LABEL_TYPE.SEA, LABEL_TYPE.OTHER].join(","),
          },
        }),
        new ModelListSelectFilter({
          field: "udf_unit",
          name: "udf_unit",
          label: "Recherche par unité de facturation",
          model: Article,
        }),
      ],
    };
  }

  public override getRowClasses(row: ArticleTarifTemplate): string[] {
    const cls = super.getRowClasses(row);
    if (row.in_op && row.in_op > 0) {
      cls.push("table-success");
    }
    if (row.status === AttStatus.DELETED) {
      cls.push("table-secondary");
      cls.push("text-muted");
    }
    if (row.status === AttStatus.ARCHIVED) {
      cls.push("table-secondary");
    }
    return cls;
  }
}
