import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Calibre } from '../calibre';
import { firstValueFrom } from 'rxjs';
import { CalibreService } from '../calibre.service';
import { FamilyService } from '../../../family/family.service';

@Component({
  selector: 'lvadg-calibre-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './calibre-create.component.pug',
  styleUrls: ['./calibre-create.component.sass'],
})
export class CalibreCreateComponent implements OnInit {
  public createForm!: FormGroup<{
    name?: FormControl<string>;
    code: FormControl<string>;
  }>;
  @Output() public created = new EventEmitter<Calibre>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: Calibre;

  constructor(
    private _items: CalibreService,
    public families$: FamilyService
  ) {}

  ngOnInit(): void {
    this.item = new Calibre(this._items);
    this.createForm = this.item.FC(['name', 'type', 'code', 'family']);
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }
}
