import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { Observable } from 'rxjs';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TarifLifecycleComponent } from '../../../../../models/tarifs/tarif/lifecycle/tarif-lifecycle/tarif-lifecycle.component';
import { TARIF_ROUTES } from '../menu';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';

export interface TarifLifecycleViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifLifecycleViewData extends TarifLifecycleViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-lifecycle-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    HeaderActionComponent,
    IconComponent,
    TarifLifecycleComponent,
    TarifDetailNavComponent,
  ],
  templateUrl: './tarif-lifecycle-view.component.pug',
  styleUrls: ['./tarif-lifecycle-view.component.sass'],
})
export class TarifLifecycleViewComponent extends BaseDetailViewComponent<TarifLifecycleViewData, Tarif> {
  public tariftype_detail_route!: Link<TariftypeBase>;
  public tarifFilter$!: Observable<FilterDefaults>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifLifecycleViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(data.tariftype_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
  }
}
