import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
} from '@solidev/data';

export enum EARTICLE_TYPE {
  CLIENT = 'CLI',
  STORAGE = 'STO',
  MEMBER = 'MEM',
  PROVIDER = 'PRO',
  LVADG = 'LVG',
  CLIENTBULK = 'CLB',
  GROUP = 'ADG',
}

export class EarticleBase extends DataModel {
  static override readonly __name: string = 'EarticleBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 110,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'type',
    description: 'Type',
    maxLength: 3,
    choices: [
      { value: EARTICLE_TYPE.CLIENT, desc: 'Client' },
      { value: EARTICLE_TYPE.STORAGE, desc: 'Dépôt' },
      {
        value: EARTICLE_TYPE.MEMBER,
        desc: 'Adhérent',
      },
      { value: EARTICLE_TYPE.LVADG, desc: 'Lavieadugout historique' },
      {
        value: EARTICLE_TYPE.CLIENTBULK,
        desc: 'Client import bulk',
      },
      { value: EARTICLE_TYPE.GROUP, desc: 'Groupe de dépôts' },
    ],
    priority: 990,
  })
  public type?: EARTICLE_TYPE;

  @foreignKeyField({
    name: 'client',
    description: 'ID Client',
    related: 'Client',
    priority: -1,
  })
  public client!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -2,
  })
  public storage!: number;

  @foreignKeyField({
    name: 'member',
    description: 'ID Adhérent',
    related: 'Member',
    priority: -3,
  })
  public member!: number;

  @foreignKeyField({
    name: 'collection',
    description: 'ID Collection',
    related: 'ExternalArticleCollection',
    priority: -5,
  })
  public collection!: number;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 200,
    priority: 980,
  })
  public code!: string;

  @charField({
    name: 'icode',
    description: 'Code alternatif',
    maxLength: 200,
    priority: -10,
  })
  public icode!: string;

  @charField({
    name: 'ean13',
    description: 'Code barre',
    maxLength: 13,
    priority: -10,
  })
  public ean13!: string;

  @charField({
    name: 'gamme',
    description: 'Classif. niv1',
    maxLength: 200,
    priority: 950,
  })
  public gamme!: string;

  @charField({
    name: 'code_gamme',
    description: 'Code classif 1',
    maxLength: 50,
    priority: 940,
  })
  public code_gamme!: string;

  @charField({
    name: 'sgamme',
    description: 'Classif. niv2',
    maxLength: 200,
    priority: 945,
  })
  public sgamme!: string;

  @charField({
    name: 'code_sgamme',
    description: 'Code classif 2',
    maxLength: 50,
    priority: 935,
  })
  public code_sgamme!: string;

  @charField({
    name: 'typlogie',
    description: 'Typologie produit',
    maxLength: 200,
    priority: 930,
  })
  public typologie?: string;

  @charField({
    name: 'libelle',
    description: 'Libellé',
    maxLength: 400,
    priority: 970,
  })
  public libelle!: string;

  @charField({
    name: 'libelle2',
    description: 'Libellé alternatif',
    maxLength: 400,
    priority: -50,
  })
  public libelle2!: string;

  @charField({
    name: 'categorie',
    description: 'Catégorie',
    maxLength: 200,
    priority: 800,
  })
  public categorie!: string;

  @charField({
    name: 'calibre',
    description: 'Calibre',
    maxLength: 200,
    priority: 800,
  })
  public calibre!: string;

  @charField({
    name: 'origine',
    description: 'Origine',
    maxLength: 200,
    priority: 800,
  })
  public origine!: string;

  @charField({
    name: 'labels',
    description: 'Labels',
    maxLength: 200,
    priority: 800,
  })
  public labels!: string;

  @charField({
    name: 'uf',
    description: 'Unité de facturation (complète)',
    maxLength: 200,
    priority: 700,
  })
  public uf!: string;

  @charField({
    name: 'uf_value',
    description: 'UF (valeur)',
    maxLength: 30,
    priority: 700,
  })
  public uf_value!: number;

  @charField({
    name: 'uf_unit',
    description: 'UF (unité)',
    maxLength: 30,
    priority: 700,
  })
  public uf_unit!: string;

  @charField({
    name: 'uc',
    description: 'Unité de commande (complète)',
    maxLength: 200,
    priority: 680,
  })
  public uc!: string;

  @charField({
    name: 'uc_value',
    description: 'UC (valeur)',
    maxLength: 30,
    priority: 680,
  })
  public uc_value!: number;

  @charField({
    name: 'uc_unit',
    description: 'UC (unité)',
    maxLength: 30,
    priority: 680,
  })
  public uc_unit!: string;
  @charField({
    name: 'ucuf',
    description: 'Ratio UC/UF',
    maxLength: 200,
    priority: 675,
  })
  public ucuf!: string;
  @charField({
    name: 'ul',
    description: 'Unité de livraison (compléte)',
    maxLength: 200,
    priority: 650,
  })
  public ul!: string;

  @charField({
    name: 'ul_value',
    description: 'UL (valeur)',
    maxLength: 30,
    priority: 650,
  })
  public ul_value!: number;

  @charField({
    name: 'ul_unit',
    description: 'UL (unité)',
    maxLength: 30,
    priority: 650,
  })
  public ul_unit!: string;

  @charField({
    name: 'ucul',
    description: 'Ratio UC/UL',
    maxLength: 200,
    priority: 645,
  })
  public ucul!: string;

  @charField({
    name: 'ucond',
    description: 'Unité conditionnement / consommation (compléte)',
    maxLength: 200,
    priority: 610,
  })
  public ucond!: string;

  @charField({
    name: 'ucond_value',
    description: 'UCO (valeur)',
    maxLength: 30,
    priority: 610,
  })
  public ucond_value!: string;

  @charField({
    name: 'ucond_unit',
    description: 'UCO (unité)',
    maxLength: 30,
    priority: 610,
  })
  public ucond_unit!: string;

  @charField({
    name: 'nego_unit',
    description: 'Unité de négociation',
    maxLength: 200,
    priority: 600,
  })
  public nego_unit!: string;

  @charField({
    name: 'tax',
    description: 'Taxes',
    maxLength: 30,
    priority: 580,
  })
  public tax!: string;

  @charField({
    name: 'pe',
    description: 'Poids égoutté (complet)',
    maxLength: 30,
    priority: 570,
  })
  public pe!: string;

  @charField({
    name: 'pe_value',
    description: 'PE (valeur)',
    maxLength: 30,
    priority: 570,
  })
  public pe_value!: number;

  @charField({
    name: 'pe_unit',
    description: 'PE (unité)',
    maxLength: 30,
    priority: 570,
  })
  public pe_unit!: string;

  // conservation
  @charField({
    name: 'conservation',
    description: 'Conservation',
    maxLength: 200,
    priority: 550,
  })
  public conservation!: string;

  @charField({
    name: 'marque',
    description: 'Marque',
    maxLength: 200,
    priority: 400,
  })
  public marque!: string;

  @charField({
    name: 'importref',
    description: 'importref - deprecated',
    maxLength: 200,
    priority: -1000,
  })
  public importref!: string;

  @charField({
    name: 'xlsxhash',
    description: 'xlsxhash - deprecated',
    maxLength: 400,
    priority: -1000,
  })
  public xlsxhash!: string;

  @detailsField({
    name: 'orig_data',
    description: 'Données originales',
    defaultValue: {},
    priority: -100,
  })
  public orig_data: any = {};

  @integerField({ name: 'orig_id', description: 'orig_id', priority: -1500 })
  public orig_id!: number;

  @manyToManyField({
    name: 'articles',
    description: 'IDs articles',
    related: 'Article',
    priority: -500,
  })
  public articles!: number[];

  public get _display(): string {
    if (this.code) {
      return `[${this.code}] ${this.libelle}`;
    }
    return `${this.libelle}`;
  }
}
