import { Component } from '@angular/core';

import { OrdergroupListViewData } from './ordergroup-list-view.component.params';
import { Link } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { OrderprocessBase } from '../../../../models/orders/orderprocess/orderprocess.base';
import {OrdergroupListComponent} from "../../../../models/orders/ordergroup/ordergroup-list/ordergroup-list.component";
import {Ordergroup} from "../../../../models/orders/ordergroup/ordergroup";

@Component({
  selector: 'lvadg-ordergroup-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, OrdergroupListComponent],
  templateUrl: './ordergroup-list-view.component.pug',
  styleUrls: ['./ordergroup-list-view.component.sass'],
})
export class OrdergroupListViewComponent extends BaseListViewComponent<OrdergroupListViewData, Ordergroup> {
  public orderprocess_detail_route?: Link<OrderprocessBase>;

  public override setRouteData(data: OrdergroupListViewData) {
    super.setRouteData(data);
    this.orderprocess_detail_route = new Link(data.orderprocess_detail_route, this);
  }
}
