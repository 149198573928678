
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnm } from '../rnm';
@Component({
  selector: 'lvadg-rnm-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnm-manage.component.pug',
  styleUrls: ['./rnm-manage.component.sass']
})
export class RnmManageComponent implements OnInit {
  @Input() public rnm?: Rnm;

  constructor() { }

  ngOnInit(): void {
  }

}
