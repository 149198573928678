import { RoutesConfig } from "@solidev/data";
import {
  OfferBase,
  OfferProductBase,
  OffertypeBase,
  OffertypeStorageBase,
  OffertypeTextBase,
  OffertypeTextTypeBase,
  RS,
} from "@vivalya/lvadg";

export const ADMIN_OFFERS_ROUTES_NAMES = [
  "offers",
  "offers_offerproducts",
  "offers_offerproducts_offerproduct",
  "offers_offers",
  "offers_offers_offer",
  "offers_offers_offer_articles",
  "offers_offers_offer_documents",
  "offers_offers_offer_edit",
  "offers_offers_offer_notifications",
  "offers_offers_offer_destinations",
  "offers_offers_offer_notificationsstatuses",
  "offers_offers_offer_printpreview",
  "offers_offers_offer_products",
  "offers_offers_offer_restos",
  "offers_offertypes",
  "offers_offertypes_offertype",
  "offers_offertypes_offertype_edit",
  "offers_offertypes_offertype_offers",
  "offers_offertypes_offertype_offers_offer",
  "offers_offertypes_offertype_offers_offer_articles",
  "offers_offertypes_offertype_offers_offer_documents",
  "offers_offertypes_offertype_offers_offer_edit",
  "offers_offertypes_offertype_offers_offer_notifications",
  "offers_offertypes_offertype_offers_offer_destinations",
  "offers_offertypes_offertype_offers_offer_notificationsstatuses",
  "offers_offertypes_offertype_offers_offer_products",
  "offers_offertypes_offertype_offers_offer_restos",
  "offers_offertypes_offertype_permissions",
  "offers_offertypes_offertype_storages",
  "offers_offertypes_offertype_storages_storage",
  "offers_offertypes_offertype_storages_storage_edit",
  "offers_offertypes_offertype_storages_storage_offers",
  "offers_offertypes_offertype_storages_storage_offers_offer",
  "offers_offertypes_offertype_storages_storage_offers_offer_articles",
  "offers_offertypes_offertype_storages_storage_offers_offer_documents",
  "offers_offertypes_offertype_storages_storage_offers_offer_edit",
  "offers_offertypes_offertype_storages_storage_offers_offer_notifications",
  "offers_offertypes_offertype_storages_storage_offers_offer_notificationsstatuses",
  "offers_offertypes_offertype_storages_storage_offers_offer_products",
  "offers_offertypes_offertype_storages_storage_offers_offer_restos",
  "offers_offertypes_offertype_storages_storage_permissions",
  "offers_offertypes_offertype_texttypes",
  "offers_offertypes_offertype_texttypes_texttype",
  "offers_offertypes_offertype_texttypes_texttype_texts",
  "offers_offertypes_offertype_texttypes_texttype_texts_text",
  "offers_offertypestorages",
  "offers_offertypestorages_offertypestorage",
  "offers_offertypestorages_offertypestorage_edit",
  "offers_offertypestorages_offertypestorage_permissions",
  "offers_offertypestorages_offertypestorage_offers",
  "offers_offertypestorages_offertypestorage_offers_offer",
  "offers_offertypestorages_offertypestorage_offers_offer_articles",
  "offers_offertypestorages_offertypestorage_offers_offer_documents",
  "offers_offertypestorages_offertypestorage_offers_offer_edit",
  "offers_offertypestorages_offertypestorage_offers_offer_notifications",
  "offers_offertypestorages_offertypestorage_offers_offer_notificationsstatuses",
  "offers_offertypestorages_offertypestorage_offers_offer_products",
  "offers_offertypestorages_offertypestorage_offers_offer_restos",
] as const;

export const ADMIN_OFFERS_ROUTES_CONFIG: RoutesConfig<
  (typeof ADMIN_OFFERS_ROUTES_NAMES)[number]
> = {
  offers: {
    params: [],
    route: () => ["/", RS.offers],
    name: "offers",
  },
  offers_offerproducts: {
    params: [],
    route: () => ["/", RS.offers, RS.offerproducts],
    name: "offers_offerproducts",
  },
  offers_offerproducts_offerproduct: {
    params: ["offerproduct"],
    route: (op: OfferProductBase) => ["/", RS.offers, RS.offerproducts, op.id],
    name: "offers_offerproducts_offerproduct",
  },
  offers_offers: {
    params: [],
    route: () => ["/", RS.offers, RS.offers],
    name: "offers_offers",
  },
  offers_offers_offer: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id],
    name: "offers_offers_offer",
  },
  offers_offers_offer_articles: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id, RS.articles],
    name: "offers_offers_offer_articles",
  },
  offers_offers_offer_destinations: {
    params: ["offer"],
    route: (o: OfferBase) => [
      "/",
      RS.offers,
      RS.offers,
      o.id,
      RS.offernotificationdestinations,
    ],
    name: "offers_offers_offer_destinations",
  },
  offers_offers_offer_documents: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id, RS.documents],
    name: "offers_offers_offer_documents",
  },
  offers_offers_offer_edit: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id, RS.edit],
    name: "offers_offers_offer_edit",
  },
  offers_offers_offer_notifications: {
    params: ["offer"],
    route: (o: OfferBase) => [
      "/",
      RS.offers,
      RS.offers,
      o.id,
      RS.offernotifications,
    ],
    name: "offers_offers_offer_notifications",
  },
  offers_offers_offer_notificationsstatuses: {
    params: ["offer"],
    route: (o: OfferBase) => [
      "/",
      RS.offers,
      RS.offers,
      o.id,
      RS.offernotificationstatuses,
    ],
    name: "offers_offers_offer_notificationsstatuses",
  },
  offers_offers_offer_printpreview: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id, RS.print],
    name: "offers_offers_offer_printpreview",
  },
  offers_offers_offer_products: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id, RS.products],
    name: "offers_offers_offer_products",
  },
  offers_offers_offer_restos: {
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.offers, RS.offers, o.id, RS.restos],
    name: "offers_offers_offer_restos",
  },
  offers_offertypes: {
    params: [],
    route: () => ["/", RS.offers, RS.offertypes],
    name: "offers_offertypes",
  },
  offers_offertypes_offertype: {
    params: ["offertype"],
    route: (ot: OffertypeBase) => ["/", RS.offers, RS.offertypes, ot.id],
    name: "offers_offertypes_offertype",
  },
  offers_offertypes_offertype_edit: {
    params: ["offertype"],
    route: (ot: OffertypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.edit,
    ],
    name: "offers_offertypes_offertype_edit",
  },
  offers_offertypes_offertype_offers: {
    params: ["offertype"],
    route: (ot: OffertypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
    ],
    name: "offers_offertypes_offertype_offers",
  },
  offers_offertypes_offertype_offers_offer: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
    ],
    name: "offers_offertypes_offertype_offers_offer",
  },
  offers_offertypes_offertype_offers_offer_articles: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.articles,
    ],
    name: "offers_offertypes_offertype_offers_offer_articles",
  },
  offers_offertypes_offertype_offers_offer_destinations: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.offernotificationdestinations,
    ],
    name: "offers_offertypes_offertype_offers_offer_destinations",
  },
  offers_offertypes_offertype_offers_offer_documents: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.documents,
    ],
    name: "offers_offertypes_offertype_offers_offer_documents",
  },
  offers_offertypes_offertype_offers_offer_edit: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.edit,
    ],
    name: "offers_offertypes_offertype_offers_offer_edit",
  },
  offers_offertypes_offertype_offers_offer_notifications: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.offernotifications,
    ],
    name: "offers_offertypes_offertype_offers_offer_notifications",
  },
  offers_offertypes_offertype_offers_offer_notificationsstatuses: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.offernotificationstatuses,
    ],
    name: "offers_offertypes_offertype_offers_offer_notificationsstatuses",
  },
  offers_offertypes_offertype_offers_offer_products: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.offerproducts,
    ],
    name: "offers_offertypes_offertype_offers_offer_products",
  },
  offers_offertypes_offertype_offers_offer_restos: {
    params: ["offertype", "offer"],
    route: (ot: OffertypeBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offers,
      o.id,
      RS.restos,
    ],
    name: "offers_offertypes_offertype_offers_offer_restos",
  },
  offers_offertypes_offertype_permissions: {
    params: ["offertype"],
    route: (ot: OffertypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.permissions,
    ],
    name: "offers_offertypes_offertype_permissions",
  },
  offers_offertypes_offertype_storages: {
    params: ["offertype"],
    route: (ot: OffertypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
    ],
    name: "offers_offertypes_offertype_storages",
  },
  offers_offertypes_offertype_storages_storage: {
    params: ["offertype", "offertypestorage"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
    ],
    name: "offers_offertypes_offertype_storages_storage",
  },
  offers_offertypes_offertype_storages_storage_edit: {
    params: ["offertype", "offertypestorage"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.edit,
    ],
    name: "offers_offertypes_offertype_storages_storage_edit",
  },
  offers_offertypes_offertype_storages_storage_offers: {
    params: ["offertype", "offertypestorage"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers",
  },
  offers_offertypes_offertype_storages_storage_offers_offer: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer",
  },
  offers_offertypes_offertype_storages_storage_offers_offer_articles: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.articles,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer_articles",
  },
  offers_offertypes_offertype_storages_storage_offers_offer_documents: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.documents,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer_documents",
  },
  offers_offertypes_offertype_storages_storage_offers_offer_edit: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.edit,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer_edit",
  },
  offers_offertypes_offertype_storages_storage_offers_offer_notifications: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.offernotifications,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer_notifications",
  },
  offers_offertypes_offertype_storages_storage_offers_offer_notificationsstatuses:
    {
      params: ["offertype", "offertypestorage", "offer"],
      route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
        "/",
        RS.offers,
        RS.offertypes,
        ot.id,
        RS.offertypestorages,
        ots.id,
        RS.offers,
        o.id,
        RS.offernotificationstatuses,
      ],
      name: "offers_offertypes_offertype_storages_storage_offers_offer_notificationsstatuses",
    },
  offers_offertypes_offertype_storages_storage_offers_offer_products: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.offerproducts,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer_products",
  },
  offers_offertypes_offertype_storages_storage_offers_offer_restos: {
    params: ["offertype", "offertypestorage", "offer"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.restos,
    ],
    name: "offers_offertypes_offertype_storages_storage_offers_offer_restos",
  },
  offers_offertypes_offertype_storages_storage_permissions: {
    params: ["offertype", "offertypestorage"],
    route: (ot: OffertypeBase, ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.offertypestorages,
      ots.id,
      RS.permissions,
    ],
    name: "offers_offertypes_offertype_storages_storage_permissions",
  },
  offers_offertypes_offertype_texttypes: {
    params: ["offertype"],
    route: (ot: OffertypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.texttypes,
    ],
    name: "offers_offertypes_offertype_texttypes",
  },
  offers_offertypes_offertype_texttypes_texttype: {
    params: ["offertype", "offertypetexttype"],
    route: (ot: OffertypeBase, tt: OffertypeTextTypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.texttypes,
      tt.id,
    ],
    name: "offers_offertypes_offertype_texttypes_texttype",
  },
  offers_offertypes_offertype_texttypes_texttype_texts: {
    params: ["offertype", "offertypetexttype"],
    route: (ot: OffertypeBase, tt: OffertypeTextTypeBase) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.texttypes,
      tt.id,
      RS.texts,
    ],
    name: "offers_offertypes_offertype_texttypes_texttype_texts",
  },
  offers_offertypes_offertype_texttypes_texttype_texts_text: {
    params: ["offertype", "offertypetexttype", "offertypetext"],
    route: (
      ot: OffertypeBase,
      tt: OffertypeTextTypeBase,
      t: OffertypeTextBase,
    ) => [
      "/",
      RS.offers,
      RS.offertypes,
      ot.id,
      RS.texttypes,
      tt.id,
      RS.texts,
      t.id,
    ],
    name: "offers_offertypes_offertype_texttypes_texttype_texts_text",
  },
  offers_offertypestorages: {
    params: [],
    route: () => ["/", RS.offers, RS.offertypestorages],
    name: "offers_offertypestorages",
  },
  offers_offertypestorages_offertypestorage: {
    params: ["offertypestorage"],
    route: (ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
    ],
    name: "offers_offertypestorages_offertypestorage",
  },
  offers_offertypestorages_offertypestorage_edit: {
    params: ["offertypestorage"],
    route: (ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.edit,
    ],
    name: "offers_offertypestorages_offertypestorage_edit",
  },
  offers_offertypestorages_offertypestorage_offers: {
    params: ["offertypestorage"],
    route: (ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
    ],
    name: "offers_offertypestorages_offertypestorage_offers",
  },
  offers_offertypestorages_offertypestorage_offers_offer: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer",
  },
  offers_offertypestorages_offertypestorage_offers_offer_articles: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.articles,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer_articles",
  },
  offers_offertypestorages_offertypestorage_offers_offer_documents: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.documents,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer_documents",
  },
  offers_offertypestorages_offertypestorage_offers_offer_edit: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.edit,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer_edit",
  },
  offers_offertypestorages_offertypestorage_offers_offer_notifications: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.offernotifications,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer_notifications",
  },
  offers_offertypestorages_offertypestorage_offers_offer_notificationsstatuses:
    {
      params: ["offertypestorage", "offer"],
      route: (ots: OffertypeStorageBase, o: OfferBase) => [
        "/",
        RS.offers,
        RS.offertypestorages,
        ots.id,
        RS.offers,
        o.id,
        RS.offernotificationstatuses,
      ],
      name: "offers_offertypestorages_offertypestorage_offers_offer_notificationsstatuses",
    },
  offers_offertypestorages_offertypestorage_offers_offer_products: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.offerproducts,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer_products",
  },
  offers_offertypestorages_offertypestorage_offers_offer_restos: {
    params: ["offertypestorage", "offer"],
    route: (ots: OffertypeStorageBase, o: OfferBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.offers,
      o.id,
      RS.restos,
    ],
    name: "offers_offertypestorages_offertypestorage_offers_offer_restos",
  },
  offers_offertypestorages_offertypestorage_permissions: {
    params: ["offertypestorage"],
    route: (ots: OffertypeStorageBase) => [
      "/",
      RS.offers,
      RS.offertypestorages,
      ots.id,
      RS.permissions,
    ],
    name: "offers_offertypestorages_offertypestorage_permissions",
  },
};
