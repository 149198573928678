import { RoutesConfig } from "@solidev/data";
import {
  OfferBase,
  OffertypeStorage,
  ProducerBase,
  ProductBase,
  RS,
} from "@vivalya/lvadg";

export const FRONT_SEA_ROUTES_NAMES = [
  "sea",
  "sea_producers",
  "sea_producers_producer",
  "sea_producers_producer_edit",
  "sea_producers_producer_relations",
  "sea_producers_producer_documents",
  "sea_producers_producer_offers",
  "sea_producers_producer_products",
  "sea_producers_producer_products_product",
  "sea_producers_producer_products_product_edit",
  "sea_producers_producer_products_product_documents",
  "sea_producers_producer_products_product_offers",
  "sea_producers_producer_products_product_articles",
  "sea_products",
  "sea_products_product",
  "sea_products_product_edit",
  "sea_products_product_documents",
  "sea_products_product_offers",
  "sea_products_product_articles",
  "sea_products_product_producer",
  "sea_stats",
  "sea_offertypestorages",
  "sea_offertypestorages_offertypestorage",
  "sea_offertypestorages_offertypestorage_edit",
  "sea_offertypestorages_offertypestorage_permissions",
  "sea_offertypestorages_offertypestorage_offers",
  "sea_offertypestorages_offertypestorage_offers_offer",
  "sea_offertypestorages_offertypestorage_offers_offer_edit",
  "sea_offertypestorages_offertypestorage_offers_offer_restos",
  "sea_offertypestorages_offertypestorage_offers_offer_destinations",
  "sea_offertypestorages_offertypestorage_offers_offer_articles",
  "sea_offertypestorages_offertypestorage_offers_offer_documents",
  "sea_offertypestorages_offertypestorage_offers_offer_notifications",
  "sea_offertypestorages_offertypestorage_offers_offer_notificationstatuses",
  "sea_offertypestorages_offertypestorage_offers_offer_printpreview",
  "sea_offertypestorages_offertypestorage_offers_offer_products",
  "sea_offertypestorages_offertypestorage_offers_offer_texts",
  "sea_offers",
  "sea_offers_offer",
  "sea_offers_offer_edit",
  "sea_offers_offer_restos",
  "sea_offers_offer_destinations",
  "sea_offers_offer_articles",
  "sea_offers_offer_documents",
  "sea_offers_offer_notifications",
  "sea_offers_offer_notificationstatuses",
  "sea_offers_offer_print_preview",
  "sea_offers_offer_products",
  "sea_offers_offer_texts",
] as const;

export const FRONT_SEA_ROUTES_CONFIG: RoutesConfig<
  (typeof FRONT_SEA_ROUTES_NAMES)[number]
> = {
  sea: {
    name: "sea",
    params: [],
    route: () => ["/", RS.sea],
  },
  sea_offers: {
    name: "sea_offers",
    params: [],
    route: () => ["/", RS.sea, RS.offers],
  },
  sea_offers_offer: {
    name: "sea_offers_offer",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id],
  },
  sea_offers_offer_articles: {
    name: "sea_offers_offer_articles",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.articles],
  },
  sea_offers_offer_destinations: {
    name: "sea_offers_offer_destinations",
    params: ["offer"],
    route: (o: OfferBase) => [
      "/",
      RS.sea,
      RS.offers,
      o.id,
      RS.offernotificationdestinations,
    ],
  },
  sea_offers_offer_documents: {
    name: "sea_offers_offer_documents",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.documents],
  },
  sea_offers_offer_edit: {
    name: "sea_offers_offer_edit",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.edit],
  },
  sea_offers_offer_notifications: {
    name: "sea_offers_offer_notifications",
    params: ["offer"],
    route: (o: OfferBase) => [
      "/",
      RS.sea,
      RS.offers,
      o.id,
      RS.offernotifications,
    ],
  },
  sea_offers_offer_notificationstatuses: {
    name: "sea_offers_offer_notificationstatuses",
    params: ["offer"],
    route: (o: OfferBase) => [
      "/",
      RS.sea,
      RS.offers,
      o.id,
      RS.offernotificationstatuses,
    ],
  },
  sea_offers_offer_print_preview: {
    name: "sea_offers_offer_print_preview",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.print],
  },
  sea_offers_offer_products: {
    name: "sea_offers_offer_products",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.products],
  },
  sea_offers_offer_restos: {
    name: "sea_offers_offer_restos",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.restos],
  },
  sea_offers_offer_texts: {
    name: "sea_offers_offer_texts",
    params: ["offer"],
    route: (o: OfferBase) => ["/", RS.sea, RS.offers, o.id, RS.texts],
  },

  sea_offertypestorages: {
    name: "sea_offertypestorages",
    params: [],
    route: () => ["/", RS.sea, RS.offertypestorages],
  },
  sea_offertypestorages_offertypestorage: {
    name: "sea_offertypestorages_offertypestorage",
    params: ["offertypestorage"],
    route: (o: OffertypeStorage) => ["/", RS.sea, RS.offertypestorages, o.id],
  },
  sea_offertypestorages_offertypestorage_edit: {
    name: "sea_offertypestorages_offertypestorage_edit",
    params: ["offertypestorage"],
    route: (o: OffertypeStorage) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.edit,
    ],
  },
  sea_offertypestorages_offertypestorage_offers: {
    name: "sea_offertypestorages_offertypestorage_offers",
    params: ["offertypestorage"],
    route: (o: OffertypeStorage) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer: {
    name: "sea_offertypestorages_offertypestorage_offers_offer",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_articles: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_articles",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.articles,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_destinations: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_destinations",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.offernotificationdestinations,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_documents: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_documents",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.documents,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_edit: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_edit",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.edit,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_notifications: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_notifications",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.offernotifications,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_notificationstatuses: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_notification_statuses",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.offernotificationstatuses,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_printpreview: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_printpreview",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.print,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_products: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_products",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.products,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_texts: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_texts",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.texts,
    ],
  },
  sea_offertypestorages_offertypestorage_offers_offer_restos: {
    name: "sea_offertypestorages_offertypestorage_offers_offer_restos",
    params: ["offertypestorage", "offer"],
    route: (o: OffertypeStorage, of: OfferBase) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.offers,
      of.id,
      RS.restos,
    ],
  },
  sea_offertypestorages_offertypestorage_permissions: {
    name: "sea_offertypestorages_offertypestorage_permissions",
    params: ["offertypestorage"],
    route: (o: OffertypeStorage) => [
      "/",
      RS.sea,
      RS.offertypestorages,
      o.id,
      RS.permissions,
    ],
  },
  sea_producers: {
    name: "sea_producers",
    params: [],
    route: () => ["/", RS.sea, RS.producers],
  },
  sea_producers_producer: {
    name: "sea_producers_producer",
    params: ["producer"],
    route: (p: ProducerBase) => ["/", RS.sea, RS.producers, p.id],
  },
  sea_producers_producer_documents: {
    name: "sea_producers_producer_documents",
    params: ["producer"],
    route: (p: ProducerBase) => ["/", RS.sea, RS.producers, p.id, RS.documents],
  },
  sea_producers_producer_edit: {
    name: "sea_producers_producer_edit",
    params: ["producer"],
    route: (p: ProducerBase) => ["/", RS.sea, RS.producers, p.id, RS.edit],
  },
  sea_producers_producer_offers: {
    name: "sea_producers_producer_offers",
    params: ["producer"],
    route: (p: ProducerBase) => ["/", RS.sea, RS.producers, p.id, RS.offers],
  },
  sea_producers_producer_products: {
    name: "sea_producers_producer_products",
    params: ["producer"],
    route: (p: ProducerBase) => ["/", RS.sea, RS.producers, p.id, RS.products],
  },
  sea_producers_producer_products_product: {
    name: "sea_producers_producer_products_product",
    params: ["producer", "product"],
    route: (p: ProducerBase, pr: ProductBase) => [
      "/",
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
    ],
  },
  sea_producers_producer_products_product_articles: {
    name: "sea_producers_producer_products_product_articles",
    params: ["producer", "product"],
    route: (p: ProducerBase, pr: ProductBase) => [
      "/",
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.articles,
    ],
  },
  sea_producers_producer_products_product_documents: {
    name: "sea_producers_producer_products_product_documents",
    params: ["producer", "product"],
    route: (p: ProducerBase, pr: ProductBase) => [
      "/",
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.documents,
    ],
  },
  sea_producers_producer_products_product_edit: {
    name: "sea_producers_producer_products_product_edit",
    params: ["producer", "product"],
    route: (p: ProducerBase, pr: ProductBase) => [
      "/",
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.edit,
    ],
  },
  sea_producers_producer_products_product_offers: {
    name: "sea_producers_producer_products_product_offers",
    params: ["producer", "product"],
    route: (p: ProducerBase, pr: ProductBase) => [
      "/",
      RS.sea,
      RS.producers,
      p.id,
      RS.products,
      pr.id,
      RS.offers,
    ],
  },
  sea_producers_producer_relations: {
    name: "sea_producers_producer_relations",
    params: ["producer"],
    route: (p: ProducerBase) => ["/", RS.sea, RS.producers, p.id, RS.relations],
  },
  sea_products: {
    name: "sea_products",
    params: [],
    route: () => ["/", RS.sea, RS.products],
  },
  sea_products_product: {
    name: "sea_products_product",
    params: ["product"],
    route: (pr: ProductBase) => ["/", RS.sea, RS.products, pr.id],
  },
  sea_products_product_articles: {
    name: "sea_products_product_articles",
    params: ["product"],
    route: (pr: ProductBase) => ["/", RS.sea, RS.products, pr.id, RS.articles],
  },
  sea_products_product_documents: {
    name: "sea_products_product_documents",
    params: ["product"],
    route: (pr: ProductBase) => ["/", RS.sea, RS.products, pr.id, RS.documents],
  },
  sea_products_product_edit: {
    name: "sea_products_product_edit",
    params: ["product"],
    route: (pr: ProductBase) => ["/", RS.sea, RS.products, pr.id, RS.edit],
  },
  sea_products_product_offers: {
    name: "sea_products_product_offers",
    params: ["product"],
    route: (pr: ProductBase) => ["/", RS.sea, RS.products, pr.id, RS.offers],
  },
  sea_products_product_producer: {
    name: "sea_products_product_producer",
    params: ["product"],
    route: (pr: ProductBase) => ["/", RS.sea, RS.products, pr.id, RS.producer],
  },

  sea_stats: { name: "sea_stats", params: [], route: () => [] },
};
