import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypeViewParams } from '../menu';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Offertype } from '../../../../../models/offers/offertype/offertype';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OffertypePermissionListComponent } from '../../../../../models/offers/offertype-permission/offertype-permission-list/offertype-permission-list.component';
import { map, Observable, Subject } from 'rxjs';
import { FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { OffertypeNavComponent } from '../_nav/offertype-nav.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { OffertypePermissionCreateComponent } from '../../../../../models/offers/offertype-permission/offertype-permission-create/offertype-permission-create.component';
import { User } from '../../../../../models/users/user/user';
import { Group } from '../../../../../models/users/group/group';

export interface OffertypePermissionsViewParams extends OffertypeViewParams {
  user_detail_route: RouteConfigItem;
  group_detail_route: RouteConfigItem;
}

export interface OffertypePermissionsViewData extends OffertypePermissionsViewParams {
  offertype: Offertype;
}

@Component({
  selector: 'lvadg-offertype-permissions-view',
  standalone: true,
  imports: [
    CommonModule,
    OffertypeNavComponent,
    HeaderActionComponent,
    OffertypePermissionListComponent,
    OffertypePermissionCreateComponent,
    IconComponent,
  ],
  templateUrl: './offertype-permissions-view.component.pug',
  styleUrls: ['./offertype-permissions-view.component.sass'],
})
export class OffertypePermissionsViewComponent extends BaseDetailViewComponent<
  OffertypePermissionsViewData,
  Offertype
> {
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public offertypeFilter$!: Observable<FilterDefaults>;
  public create_permission = false;
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offertypeFilter$ = this.data$.pipe(
      map((data) => {
        return { offertype: data.offertype.id };
      }),
    );
  }

  public override setRouteData(data: OffertypePermissionsViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link<User>(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link<Group>(data.group_detail_route, data, this._router);
    }
  }
}
