
<ng-container *ngIf="data$|async; let data">
  <lvadg-storage-nav [storage]="data.storage" [routes]="data.s_menu"></lvadg-storage-nav>
  <div class="container">
    <lvadg-header-action [title]="data.storage._display" level="h1" ri="storage" subtitle="Fiche dépôt">
      <ng-container actions>
        <lvadg-iocode-button class="me-3" [model]="data.storage">
          <lvadg-icon class="fs-3 px-1" role="button" ri="iocode" [icon_only]="true"></lvadg-icon>
        </lvadg-iocode-button>
        <!--lvadg-quickprint-button(-->
        <!--  [model]="data.storage", [print_settings]="print_settings",-->
        <!--  [template]="'storage_detail'",-->
        <!--  [title]="'Impression fiche dépôt'")-->
        <!--  lvadg-icon(ri="fiche", [icon_only]="true").fs-3.px-1-->
      </ng-container>
    </lvadg-header-action>
    <lvadg-storage-details [storage]="data.storage"></lvadg-storage-details>
  </div>
</ng-container>