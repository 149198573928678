import { detailsField, integerField } from '@solidev/data';
import { Article } from '../../catalog/article/article';

export interface ArticleIntervalStat {
  interval: string;
  date: Date;
  avg: number;
  min: number;
  max: number;
  sdev: number;
  tariftypes: number;
  tarifs: number;
}

export class ArticleIntervalStats {
  public raw: ArticleIntervalStat[] = [];
  private _data = new Map<string, ArticleIntervalStat>();

  constructor(data: ArticleIntervalStat[]) {
    for (const d of data) {
      d.date = new Date(d.date);
    }
    this._data = new Map(data.map((p) => [p.interval, p]));
    this.raw = data;
  }

  public get intervals(): string[] {
    return Array.from(this._data.keys());
  }

  public get(key: string): ArticleIntervalStat | undefined {
    return this._data.get(key);
  }

  public has(key: string): boolean {
    return this._data.has(key);
  }
}

export class ArticleStats extends Article {
  static override readonly __name: string = 'ArticleStats';

  @integerField({
    description: 'Prix moyen',
    readonly: true,
  })
  public period_stats_price?: number;

  @integerField({
    description: 'Ecart type max',
    readonly: true,
  })
  public period_stats_sdev?: number;

  @detailsField({
    name: 'interval_stats',
    many: true,
    deserialize: (data) => {
      return new ArticleIntervalStats(data);
    },
    readonly: true,
    description: 'Stats par intervalle',
  })
  public interval_stats?: ArticleIntervalStats;
}
