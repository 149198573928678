import { GroupBase } from './group.base';
import { detailsField, integerField } from '@solidev/data';
import { MemberBase } from '../../structures/member/member.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { ClientBase } from '../../structures/client/client.base';
import { RestoBase } from '../../structures/resto/resto.base';
import { TariftypeBase } from '../../tarifs/tariftype/tariftype.base';
import { OrderprocessBase } from '../../orders/orderprocess/orderprocess.base';

export class Group extends GroupBase {
  static override readonly __name: string = 'Group';

  @detailsField({
    name: 'member_details',
    model: MemberBase,
    readonly: true,
  })
  public member_details?: MemberBase;

  @detailsField({
    name: 'storage_details',
    model: StorageBase,
    readonly: true,
  })
  public storage_details?: StorageBase;

  @detailsField({
    name: 'client_details',
    model: ClientBase,
    readonly: true,
  })
  public client_details?: ClientBase;

  @detailsField({
    name: 'resto_details',
    model: RestoBase,
    readonly: true,
  })
  public resto_details?: RestoBase;

  @detailsField({
    name: 'tariftype_details',
    model: TariftypeBase,
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    name: 'orderprocess_details',
    model: OrderprocessBase,
    readonly: true,
  })
  public orderprocess_details?: OrderprocessBase;

  @integerField({
    name: 'users_count',
    description: 'Nb utilisateurs',
    readonly: true,
  })
  public users_count?: number;
}
