import { DistanceBase } from './distance.base';
import { detailsField } from '@solidev/data';
import { LocationBase } from '../location/location.base';

export class Distance extends DistanceBase {
  static override readonly __name: string = 'Distance';
  @detailsField({
    description: 'Point de départ',
    model: LocationBase,
    priority: 900,
  })
  public location_a_details?: LocationBase;

  @detailsField({
    description: 'Point de passage',
    model: LocationBase,
    priority: 900,
  })
  public location_through_details?: LocationBase;

  @detailsField({
    description: "Point d'arrivée",
    model: LocationBase,
    priority: 900,
  })
  public location_b_details?: LocationBase;
}
