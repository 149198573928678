import { Injectable } from '@angular/core';
import { DataMessageService } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../components/utils/errormessage';
import { Group } from './group';
import { GroupBase } from './group.base';

export interface GroupActionResult {
  group: Group;
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GroupActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action(
    group: Group | GroupBase,
    action: string,
    body: Record<string, any>,
    messages: Record<string, string> = {}
  ): Promise<GroupActionResult> {
    try {
      const res = await firstValueFrom(
        group.action<Group, GroupActionResult>('POST', 'action', {
          body: {
            action,
            ...body,
          },
        })
      );
      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { group, message, success: false };
    }
  }
}
