import { TariftypeDocumentBase } from './tariftype-document.base';
import { detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { ImageDisplay } from '../../documents/image/image.base';
import { TariftypeMetadata } from '../tariftype-metadata/tariftype-metadata';

export class TariftypeDocument extends TariftypeDocumentBase {
  static override readonly __name: string = 'TariftypeDocument';

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
    priority: 450,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: ImageDisplay,
    description: 'Aperçu',
    readonly: true,
    priority: 600,
  })
  public image_details?: ImageDisplay;

  @detailsField({
    model: TariftypeMetadata,
    description: 'Metadatas',
    readonly: true,
    many: true,
    priority: 250,
  })
  public metadatas_details?: TariftypeMetadata[];
}
