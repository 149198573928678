
<div class="container text-center mt-5">
  <ng-container [ngSwitch]="mode|async">
    <ng-container *ngSwitchCase="ERROR_MODE.unknown">
      <h1>OOPS...PAGE NON TROUVÉE</h1>
      <ng-container *ngIf="auth.isAnonymous">
        <p class="text-danger fs-4">Vous n'êtes pas connecté, veuillez vous connecter pour accéder à cette page.</p><a class="btn btn-link fs-4" [href]="'/login?next='+url">Page de connexion</a>
      </ng-container>
      <ng-container *ngIf="!auth.isAnonymous"><a class="btn btn-link fs-4" href="/">Retour à l'accueil</a></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ERROR_MODE.resnotfound">
      <h1>OOPS...RESSOURCE NON TROUVÉE</h1>
      <ng-container *ngIf="auth.isAnonymous">
        <p class="text-danger fs-4">Vous n'êtes pas connecté, veuillez vous connecter pour accéder à cette page.</p><a class="btn btn-link fs-4" [href]="'/login?next='+url">Page de connexion</a>
      </ng-container>
      <ng-container *ngIf="!auth.isAnonymous"><a class="btn btn-link fs-4" href="/">Retour à l'accueil</a></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ERROR_MODE.apinotavailable">
      <h1>OOPS...SERVEUR NON DISPONIBLE</h1>
      <p class="text-info">Veuillez réessayer ultérieurement; si cette erreur persiste, merci de contacter <a href="mailto:support@lavieadugout.fr?subject=Erreur%20API_NOT_AVAILABLE%20sur%20le%20site%20lavieadugout.fr">support&#64;lavieadugout.fr</a></p><a class="btn btn-link fs-4" href="/">Retour à l'accueil</a>
    </ng-container>
    <ng-container *ngSwitchCase="ERROR_MODE.apierror">
      <h1>OOPS...ERREUR DU SERVEUR</h1>
      <p class="text-info">Veuillez réessayer ultérieurement; si cette erreur persiste, merci de contacter <a href="mailto:support@lavieadugout.fr?subject=Erreur%20API_ERROR%20sur%20le%20site%20lavieadugout.fr">support&#64;lavieadugout.fr</a></p><a class="btn btn-link fs-4" href="/">Retour à l'accueil</a>
    </ng-container>
    <ng-container *ngSwitchCase="ERROR_MODE.forbidden">
      <h1>OOPS... VOUS N'AVEZ PAS LES DROITS POUR ACCEDER A CETTE PAGE</h1>
      <ng-container *ngIf="auth.isAnonymous">
        <p class="text-danger fs-4">Vous n'êtes pas connecté, veuillez vous connecter pour accéder à cette page.</p><a class="btn btn-link fs-4" [href]="'/login?next='+url">Page de connexion</a>
      </ng-container>
      <ng-container *ngIf="!auth.isAnonymous"><a class="btn btn-link fs-4" href="/">Retour à l'accueil</a></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <h1>OOPS...UNE ERREUR INCONNUE EST SURVENUE...</h1>
      <ng-container *ngIf="auth.isAnonymous">
        <p class="text-danger fs-4">Vous n'êtes pas connecté, veuillez vous connecter pour accéder à cette page.</p><a class="btn btn-link fs-4" [href]="'/login?next='+url">Page de connexion</a>
      </ng-container>
      <ng-container *ngIf="!auth.isAnonymous"><a class="btn btn-link fs-4" href="/">Retour à l'accueil</a></ng-container>
    </ng-container>
  </ng-container>
</div>