
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails client</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2>
              <lvadg-icon ri="client"><code class="me-2">{{data.client.cvva}}</code>{{data.client.name}}<small>{{data.client.full_path}}</small></lvadg-icon>
            </h2>
          </div>
          <lvadg-client-manage [client]="data.client"></lvadg-client-manage>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>