import {
  Route,
  UrlMatchResult,
  UrlSegment,
  UrlSegmentGroup,
} from "@angular/router";
import { Folder } from "../folder";
import { slugify } from "@solidev/data";
import { RS } from "../../../../constants";

export const getFolderUrlTitle = (folder: Folder, url?: string): string => {
  if (!url) {
    return `${folder.name}`;
  }
  if (url === RS.folders) {
    return "Dossiers";
  }
  const id = +url.split("-").reverse()[0];
  if (id === folder.id) {
    return `${folder.name}`;
  }
  if (!folder.parents_details) {
    return `${folder.name}`;
  }
  for (const p of folder.parents_details) {
    if (p.id === id) {
      return `${p.name}`;
    }
  }
  return `${folder.name}`;
};

export const folderTreeRoutes = (folder: Folder): string[] => {
  if (!folder.parents_details) {
    if (folder.tree_path) {
      return folder.tree_path.map((p) => `${slugify(p.name)}-${p.id}`);
    } else {
      console.warn("No parents details", folder);
      return [`${folder.slug || slugify(folder.name)}-${folder.id}`];
    }
  }
  const out: string[] = [];
  for (const zp of folder.parents_details) {
    out.push(`${zp.slug || slugify(folder.name)}-${zp.id}`);
  }
  out.push(`${folder.slug || slugify(folder.name)}-${folder.id}`);
  return out;
};

export function folderTreeMatcher(
  url: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route,
): UrlMatchResult | null {
  if (url.length === 0) {
    return null;
  }
  const consumed: UrlSegment[] = [];
  if (url[0].path === RS.folders) {
    consumed.push(url[0]);
    url = url.slice(1);
    if (url.length === 0) {
      return {
        consumed: consumed,
        posParams: { folderId: consumed[0] },
      };
    }
  }
  for (const u of url) {
    const slugIdRegex = /^([-a-zA-Z0-9]+)-([0-9])+$/gm;
    if (slugIdRegex.exec(u.path) !== null) {
      consumed.push(u);
    } else {
      console.warn(u.path, "no match");
      break;
    }
  }
  return {
    consumed: consumed,
    posParams: { folderSlugId: consumed[consumed.length - 1] },
  };
}
