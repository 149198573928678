import { Injectable } from '@angular/core';
import { BanAdapter, ModelListAutocompleteMultiFilter, ModelListGeodistanceFilter } from '@solidev/data';
import { HttpClient } from '@angular/common/http';
import { ModelListFilter } from '@solidev/data/lib/modellist/model-list-filters/model-list-filter';
import { GeocommuneService } from '../geocommune/geocommune.service';
import { GeodepartementService } from '../geodepartement/geodepartement.service';
import { GeoregionService } from '../georegion/georegion.service';

@Injectable({
  providedIn: 'root',
})
export class LocationFiltersService {
  constructor(
    private _http: HttpClient,
    private _geocommunes: GeocommuneService,
    private _geodepartement: GeodepartementService,
    private _georegion: GeoregionService,
  ) {}

  public getFilters(): ModelListFilter[] {
    const adapter = new BanAdapter(this._http, 'https://gis.lavieadugout.fr/geocoder/search');
    return [
      new ModelListGeodistanceFilter({
        name: 'geodistance',
        field: 'near',
        label: 'Recherche à proximité de',
        desc: 'Distance en km',
        help: 'Ville ou adresse (en France)',
        geolocator: (pos: string) => adapter.search(pos),
      }),
      new ModelListAutocompleteMultiFilter({
        name: 'geocommunes',
        field: 'geocommunes',
        label: 'Recherche par commune(s)',
        desc: 'Nom ou code postal de la commune',
        help: 'Nom ou code postal de la commune',
        filter: { country_code: 'fr' },
        queryset: this._geocommunes.queryset(),
      }),
      new ModelListAutocompleteMultiFilter({
        name: 'geodepartements',
        field: 'geodepartements',
        label: 'Recherche par département(s)',
        desc: 'Nom ou code du département',
        help: 'Nom ou code du département',
        filter: { country_code: 'fr' },
        queryset: this._geodepartement.queryset().setFields(['id', 'name', 'code']),
      }),
      new ModelListAutocompleteMultiFilter({
        name: 'georegions',
        field: 'georegions',
        label: 'Recherche par région(s)',
        desc: 'Nom de la région',
        help: 'Nom de la région',
        filter: { country_code: 'fr' },
        queryset: this._georegion.queryset().setFields(['id', 'name']),
      }),
    ];
  }
}
