import {
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { DATA_API_URL, FilterDefaults, ModelList } from "@solidev/data";
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  tap,
  throttleTime,
} from "rxjs";
import { MAPPING_BASE_STYLES, MAPPING_STYLES } from "../constants";
import { toObservable } from "@angular/core/rxjs-interop";
import { MapContainer, MapService } from "../services/map.service";
import { AuthService } from "../../../users/auth.service";
import { Product } from "../../../catalog/product/product";
import { CommonModule } from "@angular/common";
import { filterNullish } from "../../../../utils";
import { ProductService } from "../../../catalog/product/product.service";

@Component({
  selector: "lvadg-products-map",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./products-map.component.pug",
  styleUrl: "./products-map.component.sass",
})
export class ProductsMapComponent implements OnInit {
  @Input() public list?: ModelList<Product>;
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults>;
  @Input() public mapStyle: MAPPING_BASE_STYLES =
    MAPPING_BASE_STYLES.OSM_LIBERTY_EU;
  @Input() public position: [number, number] = [
    2.6390772508210603, 46.441826066304316,
  ];
  @Input() public zoom: number = 5.24;

  @ViewChild("map", { static: true })
  public mapContainer!: ElementRef<HTMLDivElement>;
  public displayProducers = signal(true);
  public displayStorages = signal(false);
  public displayProducersLegend = signal(false);
  public displayStoragesLegend = signal(true);
  private _displayProducers$ = toObservable(this.displayProducers);
  private _displayStorages$ = toObservable(this.displayStorages);
  private _displayProducersLegend$ = toObservable(this.displayProducersLegend);
  private _displayStoragesLegend$ = toObservable(this.displayStoragesLegend);
  private _map?: MapContainer;
  private _mps = inject(MapService);
  private _products = inject(ProductService);
  private _auth = inject(AuthService);
  private _url = inject(DATA_API_URL);

  public async ngOnInit() {
    if (!this.list) {
      return;
    }
    let filter: Observable<FilterDefaults> = of({});
    if (this.filter) {
      if (this.filter instanceof Observable) {
        filter = this.filter;
      } else {
        filter = of(this.filter);
      }
    }
    const token = this._auth.accessToken;

    this._map = await this._mps.create(
      "simplemap",
      this.mapContainer.nativeElement,
      {
        container: "",
        style: MAPPING_STYLES.get(this.mapStyle)
          ? MAPPING_STYLES.get(this.mapStyle)!.url
          : MAPPING_STYLES.get(MAPPING_BASE_STYLES.OSM_LIBERTY_EU)!.url, // stylesheet location
        center: this.position, // starting position [lng, lat]
        zoom: this.zoom, // starting zoom,
        minZoom: 3, // was 4.4
        attributionControl: false,
        transformRequest: (url, resourceType) => {
          if (resourceType === "Source" && url.startsWith(this._url)) {
            return {
              url: url,
              headers: { Authorization: "Bearer " + token },
            };
          } else {
            return {
              url: url,
            };
          }
        },
      },
    );
    // FIXME: remove all this logging if delayed display works in production
    console.log("launching mapping");
    combineLatest([
      this._map.map$.pipe(tap(() => console.log("map$"))),
      this.list.filters.output.pipe(tap(() => console.log("filter output"))),
      filter.pipe(tap(() => console.log("filter"))),
      this._displayProducers$.pipe(tap(() => console.log("displayProducers$"))),
      this._displayStorages$.pipe(tap(() => console.log("displayStorages$"))),
      this._displayProducersLegend$.pipe(
        tap(() => console.log("displayProducersLegend$")),
      ),
      this._displayStoragesLegend$.pipe(
        tap(() => console.log("displayStoragesLegend$")),
      ),
    ])
      .pipe(
        map(([, f, fd]) => {
          const url = new URL(
            this._products.getUrl(null, { suffix: "geojson" }),
          );
          const ff = Object.assign({}, f, fd);
          ff["fields"] =
            "id,producer_location_details,storage_relations_details";
          url.search = new URLSearchParams(
            Object.entries(filterNullish(ff)).map(([k, v]) => [
              k,
              v!.toString(),
            ]),
          ).toString();
          return url.toString();
        }),
        distinctUntilChanged(),
        throttleTime(500),
      )
      .subscribe((url) => {
        console.log("url", url);
        if (this.displayProducers()) {
          this._map!.setGeoJsonLayer(
            "producers",
            url + "&geojson_type=producers",
            this.displayProducersLegend(),
          );
        } else {
          this._map!.removeGeoJsonLayer("producers");
        }
        if (this.displayStorages()) {
          this._map!.setGeoJsonLayer(
            "storages",
            url + "&geojson_type=storages",
            this.displayStoragesLegend(),
          );
        } else {
          this._map!.removeGeoJsonLayer("storages");
        }
      });
  }
}
