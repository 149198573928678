import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeRegionPermission } from '../../../../models/tarifs/tariftype-region-permission/tariftype-region-permission';
import { UserBase } from '../../../../models/users/user/user.base';
import { GroupBase } from '../../../../models/users/group/group.base';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { GenericregionBase } from '../../../../models/locations/genericregion/genericregion.base';
import { TariftypeRegionPermissionListComponent } from '../../../../models/tarifs/tariftype-region-permission/tariftype-region-permission-list/tariftype-region-permission-list.component';

export interface TariftypeRegionPermissionListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  region_detail_route?: RouteConfigItem;
}

export interface TariftypeRegionPermissionListViewData extends TariftypeRegionPermissionListViewParams {}

@Component({
  selector: 'lvadg-tariftype-region-permission-list-view',
  standalone: true,
  imports: [CommonModule, TariftypeRegionPermissionListComponent],
  templateUrl: './tariftype-region-permission-list-view.component.pug',
  styleUrls: ['./tariftype-region-permission-list-view.component.sass'],
})
export class TariftypeRegionPermissionListViewComponent extends BaseListViewComponent<
  TariftypeRegionPermissionListViewData,
  TariftypeRegionPermission
> {
  public user_detail_route!: Link<UserBase>;
  public group_detail_route!: Link<GroupBase>;
  public tariftype_detail_route!: Link<TariftypeBase>;
  public region_detail_route!: Link<GenericregionBase>;
  public default_fields = [
    'id',
    'tariftype_details',
    'region_details',
    'user_details',
    'group_details',
    'permission',
    'created',
    'updated',
  ];

  public override setRouteData(data: TariftypeRegionPermissionListViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(data.tariftype_detail_route, data);
    }
    if (data.region_detail_route) {
      this.region_detail_route = new Link(data.region_detail_route, data);
    }
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
