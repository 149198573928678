
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content mx-4">
          <lvadg-header-action [title]="data.orderprocess._display" subtitle="Édition du processus" ri="order">
            <ng-container actions>
              <data-safe-delete [model]="data.orderprocess" display="custom" [service]="orderprocess$">
                <button class="btn btn-danger btn-sm"><i class="bi bi-trash me-2"></i>Supprimer le processus de commande ?</button>
              </data-safe-delete>
            </ng-container>
          </lvadg-header-action>
          <lvadg-orderprocess-manage [orderprocess]="data.orderprocess"></lvadg-orderprocess-manage>
          <hr>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="permissions"><a ngbNavLink>Permissions</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-header-action [title]="data.orderprocess._display" subtitle="Permissions globales" ri="order">
            <ng-container actions>
              <button class="btn btn-primary btn-sm" (click)="create['permission']=!create['permission']"><i class="bi bi-plus-circle me-2"></i>Ajouter une permission</button>
            </ng-container>
          </lvadg-header-action>
          <div class="card my-3" *ngIf="create['permission']">
            <div class="card-header">Ajouter une permission</div>
            <div class="card-body">
              <lvadg-orderprocess-permission-create [orderprocess]="data.orderprocess" (created)="reload$.next(); create['permission']=false" (cancelled)="create['permission']=false"></lvadg-orderprocess-permission-create>
            </div>
          </div>
          <lvadg-orderprocess-permission-list [name]="data.route.name+'_'+data.orderprocess.code+'_permissions'" [default_fields]="permissions_default_fields" [reload]="reload$" [filter]="permissionProcessFilter$" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [actions]="['delete']"></lvadg-orderprocess-permission-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="storages"><a ngbNavLink>Dépôts / plateformes</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-header-action [title]="data.orderprocess._display" subtitle="Paramétrages dépôt / plateforme" ri="order">
            <ng-container actions>
              <button class="btn btn-primary btn-sm" (click)="create['storage']=!create['storage']"><i class="bi bi-plus-circle me-2"></i>Ajouter une association</button>
            </ng-container>
          </lvadg-header-action>
          <div class="card my-3" *ngIf="create['storage']">
            <div class="card-header">Ajouter une association</div>
            <div class="card-body">
              <lvadg-orderprocessstorage-create [orderprocess]="data.orderprocess" (created)="reload$.next(); create['storage']=false" (cancelled)="create['storage']=false"></lvadg-orderprocessstorage-create>
            </div>
          </div>
          <lvadg-orderprocessstorage-list [name]="data.route.name+'_'+data.orderprocess.code+'_storages'" [default_fields]="storages_default_fields" [detail_route]="orderprocessstorage_detail_route" [storage_detail_route]="storage_detail_route" [filter]="orderprocessFilter$" [platform_detail_route]="platform_detail_route" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [reload]="reload$" [actions]="['delete']"></lvadg-orderprocessstorage-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="articles"><a ngbNavLink>Articles</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="clearfix mb-2">
            <button class="btn btn-primary float-end" (click)="create['article']=!create['article']"><i class="bi bi-plus-circle me-2"></i>Ajouter un article</button>
            <h2>Articles proposés à la commande</h2>
          </div>
          <div class="card my-3" *ngIf="create['article']">
            <div class="card-header">Ajouter un article</div>
            <div class="card-body">
              <lvadg-orderprocessarticle-create [orderprocess]="data.orderprocess" (created)="reload$.next(); create['article']=false" (cancelled)="create['article']=false"></lvadg-orderprocessarticle-create>
            </div>
          </div>
          <lvadg-orderprocessarticle-list [name]="data.route.name+'_'+data.orderprocess.code+'_articles'" [filter]="orderprocessFilter$" [article_detail_route]="article_detail_route" [tariftype_detail_route]="tariftype_detail_route" [default_fields]="articles_default_fields" [reload]="reload$" [actions]="['delete']"></lvadg-orderprocessarticle-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="orders"><a ngbNavLink>Commandes</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="clearfix mb-2">
            <button class="btn btn-primary float-end mx-2" (click)="create['ogroup']=!create['ogroup']"><i class="bi bi-plus-circle me-2"></i>Ajouter une consolidation</button>
            <button class="btn btn-primary float-end" (click)="create['order']=!create['order']"><i class="bi bi-plus-circle me-2"></i>Ajouter une commande</button>
            <h2>Commandes</h2>
          </div>
          <div class="card my-3" *ngIf="create['order']">
            <div class="card-header">Ajouter une commande</div>
            <div class="card-body">
              <lvadg-order-create (created)="reload$.next(); create['order']=false" (cancelled)="create['order']=false"></lvadg-order-create>
            </div>
          </div>
          <div class="card my-3" *ngIf="create['ogroup']">
            <div class="card-header">Ajouter une consolidation</div>
            <div class="card-body">
              <lvadg-ordergroup-create (created)="reload$.next(); create['ogroup']=false" (cancelled)="create['ogroup']=false"></lvadg-ordergroup-create>
            </div>
          </div>
          <lvadg-order-list [name]="data.route.name+'_'+data.orderprocess.code+'_orders'" [detail_route]="order_detail_route" [ordergroup_detail_route]="ordergroup_detail_route" [filter]="orderprocessFilter$" [default_fields]="orders_default_fields" [reload]="reload$" [actions]="['delete']"></lvadg-order-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="ordergroups"><a ngbNavLink>Consolidations</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-ordergroup-list [name]="data.route.name+'_'+data.orderprocess.code+'_ordergroups'" [detail_route]="ordergroup_detail_route" [default_fields]="ordergroup_default_fields" [filter]="orderprocessFilter$" [reload]="reload$" [actions]="['delete']"></lvadg-ordergroup-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="notifications"><a class="text-muted" ngbNavLink>Notifications</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <pre class="todo">TODO: concevoir et ajouter les notifs auto (post-v3)</pre>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>