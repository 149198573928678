import { Component } from '@angular/core';

import { CalibreListViewData } from './calibre-list-view.component.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { CalibreListComponent } from '../../../../../models/catalog/meta/calibre/calibre-list/calibre-list.component';
import { CalibreCreateComponent } from '../../../../../models/catalog/meta/calibre/calibre-create/calibre-create.component';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { Calibre } from '../../../../../models/catalog/meta/calibre/calibre';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { Subject } from 'rxjs';
import { AllergenListViewData } from '../../allergen/list/allergen-list-view.component.params';

@Component({
  selector: 'lvadg-calibre-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    IconComponent,
    HeaderActionComponent,
    CalibreListComponent,
    CalibreCreateComponent,
  ],
  templateUrl: './calibre-list-view.component.pug',
  styleUrls: ['./calibre-list-view.component.sass'],
})
export class CalibreListViewComponent extends BaseListViewComponent<CalibreListViewData, Calibre> {
  public default_fields = ['id', 'type', 'code', 'name', 'family_details', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
