
<ng-container *ngIf="data$|async; let data">
  <ng-template let-offcanvas #docEditSlot>
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">Documents fournisseur</h5>
      <button class="btn-close" (click)="offcanvas.close()"></button>
    </div>
    <div class="offcanvas-body" *ngIf="currentDocument(); let cd">
      <lvadg-provider-document-manage [providerdocument]="cd"></lvadg-provider-document-manage>
    </div>
  </ng-template>
</ng-container>
<lvadg-header-action level="h1" title="Documents fournisseurs" subtitle="Liste des documents fournisseurs" ri="document">
  <ng-container actions>
    <button class="btn btn-sm btn-outline-primary" type="button" (click)="create.set(!create())" *ngIf="canEdit()"><i class="bi bi-plus-circle me-2"></i>Créer un document</button>
  </ng-container>
</lvadg-header-action>
<div class="card my-3" *ngIf="create()">
  <div class="card-header">Création de document fournisseur</div>
  <div class="card-body">
    <lvadg-provider-document-create (created)="created($event)"></lvadg-provider-document-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-provider-document-list [name]="data.route.name" [detail_route]="detail_route" [reload]="reload$" [provider_detail_route]="provider_detail_route" [article_detail_route]="article_detail_route" [default_fields]="default_fields" [actions]="canEdit() ? ['delete', 'edit'] : []" (action)="onAction($event, data)"></lvadg-provider-document-list>
</ng-container>