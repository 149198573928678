import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DocsDisplayViewData } from './docs-display-view.component.params';
import { map, Observable } from 'rxjs';
import { Doc } from '../../../models/docs/doc';
import { DocDisplayComponent } from '../../../models/docs/doc-display/doc-display.component';
import { TocDisplayComponent } from '../../../models/docs/toc-display/toc-display.component';
import { DoctocDisplayComponent } from '../../../models/docs/doctoc-display/doctoc-display.component';

@Component({
  selector: 'lvadg-docs-display-view',
  standalone: true,
  imports: [
    CommonModule,
    TocDisplayComponent,
    DoctocDisplayComponent,
    DocDisplayComponent,
  ],
  templateUrl: './docs-display-view.component.pug',
  styleUrls: ['./docs-display-view.component.sass'],
})
export class DocsDisplayViewComponent implements OnInit {
  public doc$!: Observable<Doc>;

  constructor(private _route: ActivatedRoute) {}

  public ngOnInit() {
    const rd = this._route.data.pipe(map((d) => d as DocsDisplayViewData));
    this.doc$ = rd.pipe(map((d) => d.docpage));
  }
}
