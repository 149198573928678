import {
  Component,
  ElementRef,
  inject,
  input,
  signal,
  ViewChild,
} from "@angular/core";
import { firstValueFrom } from "rxjs";
import { CommonModule } from "@angular/common";
import { IocodeDetails } from "../iocode.base";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  IOQRCODE_COLOR,
  IOQRCODE_COLOR_CHOICES,
  IOQRCODE_LOGO,
  IOQRCODE_LOGO_CHOICES,
  IOQRCODE_SIZES,
  IOQRCODE_SIZES_CHOICES,
} from "../../ioqrcode/ioqrcode.base";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Article } from "../../../catalog/article/article";
import { Storage } from "../../../structures/storage/storage";
import { Producer } from "../../../structures/producer/producer";
import { Product } from "../../../catalog/product/product";

// This fails since typescript 5.4... ???
// type IoCodeModel = DataModel & { iocode: IocodeDetails };
type IoCodeModel = Article | Product | Producer | Storage;

@Component({
  selector: "lvadg-iocode-button",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: "./iocode-button.component.pug",
  styleUrl: "./iocode-button.component.sass",
})
export class IocodeButtonComponent {
  public model = input.required<IoCodeModel>();
  public iocode = signal<IocodeDetails | null>(null);
  public qrcodeUrl = signal<string | null>(null);
  public qrForm = new FormGroup({
    size: new FormControl<IOQRCODE_SIZES>(IOQRCODE_SIZES.EXTRA_SMALL),
    color: new FormControl<IOQRCODE_COLOR>(IOQRCODE_COLOR.BLACK),
    logo: new FormControl<IOQRCODE_LOGO>(IOQRCODE_LOGO.NONE),
  });
  public readonly QRS = IOQRCODE_SIZES_CHOICES;
  public readonly QRC = IOQRCODE_COLOR_CHOICES;
  public readonly QRL = IOQRCODE_LOGO_CHOICES;
  private _modal = inject(NgbModal);

  @ViewChild("qrModal", { static: true }) private _iocodeModal!: ElementRef;

  constructor() {
    this.qrForm.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.iocode()) return;
      this.qrcodeUrl.set(
        `${this.iocode()!.ioqr}?size=${v.size}&color=${v.color}&logo=${v.logo}`,
      );
    });
  }

  public async getIoCode() {
    const cmodel = await firstValueFrom(
      this.model()._collection.fetch(this.model().id, {
        params: { fields: "id,iocode" },
      }),
    );
    this.iocode.set(cmodel.iocode);
    this.qrcodeUrl.set(cmodel.iocode.ioqr);
    this._modal.open(this._iocodeModal, { size: "lg" });
  }
}
