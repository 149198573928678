import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypeStorage } from '../offertype-storage';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-offertype-storage-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './offertype-storage-display.component.pug',
  styleUrls: ['./offertype-storage-display.component.sass'],
})
export class OffertypeStorageDisplayComponent {
  @Input() public offertypestorage?: OffertypeStorage;
  @Input() public mode = 'line' as const;
  @Input() public detail_route?: Link<OffertypeStorage>;

  constructor() {}
}
