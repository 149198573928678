
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Platform } from '../platform';
@Component({
  selector: 'lvadg-platform-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './platform-manage.component.pug',
  styleUrls: ['./platform-manage.component.sass']
})
export class PlatformManageComponent implements OnInit {
  @Input() public platform?: Platform;

  constructor() { }

  ngOnInit(): void {
  }

}
