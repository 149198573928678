
<div class="progress-zone" *ngIf="slot==='global'">
  <ng-container *ngFor="let p of (progress$| async)">
    <ng-container *ngIf="!p.closed">
      <ngb-alert [type]="p.current&lt;p.total ? 'info' : 'success'" (closed)="close(p)" [dismissible]="true"><span class="my-1" *ngIf="p.name">{{p.name}}</span>
        <ng-container *ngIf="p.current&lt;p.total">
          <ngb-progressbar [max]="p.total" [value]="p.current" [striped]="!p.done" [animated]="!p.done" [type]="'warning'">{{p.step || '' }}</ngb-progressbar><span *ngIf="p.message">{{p.message}}</span>
        </ng-container>
        <ng-container *ngIf="p.current&gt;=p.total">
          <ngb-progressbar [max]="p.total" [value]="p.current" [striped]="!p.done" [animated]="!p.done" [type]="'light'">{{p.step || '' }}</ngb-progressbar><span *ngIf="p.message">{{p.message}}</span>
        </ng-container>
        <div class="actions">
          <ng-container *ngIf="p.link"><a class="btn btn btn-secondary" [href]="p.link" title="Téléchargement" target="_blank">
              <lvadg-icon ri="download">Télécharger</lvadg-icon></a></ng-container>
          <ng-container *ngIf="p.actions">
            <ng-container *ngFor="let a of p.actions">
              <ng-container *ngIf="a.route"><a class="btn btn-light" [routerLink]="a.route" [title]="a.label" target="_blank">
                  <lvadg-icon [ri]="a.icon" *ngIf="a.icon">{{a.label}}</lvadg-icon>
                  <ng-container *ngIf="!a.icon">{{a.label}}</ng-container></a></ng-container>
              <ng-container *ngIf="a.action">
                <button class="btn btn-light" (click)="a.action(p)">
                  <lvadg-icon [ri]="a.icon" *ngIf="a.icon">{{a.label}}</lvadg-icon>
                  <ng-container *ngIf="!a.icon">{{a.label}}</ng-container>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ngb-alert>
    </ng-container>
  </ng-container>
</div>