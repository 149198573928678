
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Rnmcotation } from "./rnmcotation";

@Injectable({
  providedIn: 'root'
})
export class RnmcotationService extends Collection<Rnmcotation> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/rnmcotations", Rnmcotation);
  }
}
