import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { DATA_API_URL, FilterDefaults, ModelList } from "@solidev/data";
import { Provider } from "../../../structures/provider/provider";
import { combineLatest, map, Observable, of } from "rxjs";
import { ProviderService } from "../../../structures/provider/provider.service";
import { MAPPING_BASE_STYLES, MAPPING_STYLES } from "../constants";
import { MapContainer, MapService } from "../services/map.service";
import { filterNullish } from "../../../../utils";
import { AuthService } from "../../../users/auth.service";
import { CommonModule } from "@angular/common";
import { toObservable } from "@angular/core/rxjs-interop";

@Component({
  selector: "lvadg-providers-map",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./providers-map.component.pug",
  styleUrl: "./providers-map.component.sass",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvidersMapComponent implements OnInit {
  @Input() public list?: ModelList<Provider>;
  @Input() public filter?: FilterDefaults | Observable<FilterDefaults>;
  @Input() public mapStyle: MAPPING_BASE_STYLES =
    MAPPING_BASE_STYLES.OSM_LIBERTY_EU;
  @Input() public position: [number, number] = [
    2.6390772508210603, 46.441826066304316,
  ];
  @Input() public zoom: number = 5.24;

  @ViewChild("map", { static: true })
  public mapContainer!: ElementRef<HTMLDivElement>;
  public displayProviders = signal(true);
  public displayProvidersLegend = signal(false);
  private _displayProviders$ = toObservable(this.displayProviders);
  private _displayProvidersLegend$ = toObservable(this.displayProvidersLegend);
  private _map?: MapContainer;
  private _mps = inject(MapService);
  private _providers = inject(ProviderService);
  private _auth = inject(AuthService);
  private _url = inject(DATA_API_URL);

  public async ngOnInit() {
    if (!this.list) {
      return;
    }
    let filter: Observable<FilterDefaults> = of({});
    if (this.filter) {
      if (this.filter instanceof Observable) {
        filter = this.filter;
      } else {
        filter = of(this.filter);
      }
    }
    const token = this._auth.accessToken;

    this._map = await this._mps.create(
      "simplemap",
      this.mapContainer.nativeElement,
      {
        container: "",
        style: MAPPING_STYLES.get(this.mapStyle)
          ? MAPPING_STYLES.get(this.mapStyle)!.url
          : MAPPING_STYLES.get(MAPPING_BASE_STYLES.OSM_LIBERTY_EU)!.url, // stylesheet location
        center: this.position, // starting position [lng, lat]
        zoom: this.zoom, // starting zoom,
        minZoom: 3, // was 4.4
        attributionControl: false,
        transformRequest: (url, resourceType) => {
          if (resourceType === "Source" && url.startsWith(this._url)) {
            return {
              url: url,
              headers: { Authorization: "Bearer " + token },
            };
          } else {
            return {
              url: url,
            };
          }
        },
      },
    );
    combineLatest([
      this._map.map$,
      this.list.filters.output,
      filter,
      this._displayProviders$,
      this._displayProvidersLegend$,
    ])
      .pipe(
        map(([, f, fd]) => {
          const url = new URL(
            this._providers.getUrl(null, { suffix: "geojson" }),
          );
          const ff = Object.assign({}, f, fd);
          ff["fields"] = "id,name,location_details,storage_relations_details";
          url.search = new URLSearchParams(
            Object.entries(filterNullish(ff)).map(([k, v]) => [
              k,
              v!.toString(),
            ]),
          ).toString();
          return url.toString();
        }),
      )
      .subscribe((url) => {
        if (this.displayProviders()) {
          this._map!.setGeoJsonLayer(
            "providers",
            url + "&geojson_type=providers",
            this.displayProvidersLegend(),
          );
        } else {
          this._map!.removeGeoJsonLayer("providers");
        }
      });
  }
}
