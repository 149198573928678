
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class SupportMessageCommentBase extends DataModel {
  static override readonly __name: string = "SupportMessageCommentBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @foreignKeyField({"name": "author", "description": "author", "related": "User"})
  public author!: number;

  @foreignKeyField({"name": "message", "description": "message", "related": "SupportMessage"})
  public message!: number;

  @textField({"name": "content_src", "description": "content src"})
  public content_src!: string;

  @textField({"name": "content_html", "description": "content html"})
  public content_html!: string;

  @datetimeField({"name": "created", "description": "created"})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated"})
  public updated!: Date;


}
