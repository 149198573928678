import { Component } from '@angular/core';
import { OrderprocessstoragePermissionDetailViewData } from './orderprocessstorage-permission-detail-view.component.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import {
  OrderprocessstoragePermissionManageComponent
} from "../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission-manage/orderprocessstorage-permission-manage.component";
import {BaseDetailViewComponent} from "../../../../components/baseview/basedetailview.component";
import {
  OrderprocessstoragePermission
} from "../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission";

@Component({
  selector: 'lvadg-orderprocessstorage-permission-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, OrderprocessstoragePermissionManageComponent],
  templateUrl: './orderprocessstorage-permission-detail-view.component.pug',
  styleUrls: ['./orderprocessstorage-permission-detail-view.component.sass'],
})
export class OrderprocessstoragePermissionDetailViewComponent extends BaseDetailViewComponent<
  OrderprocessstoragePermissionDetailViewData,
  OrderprocessstoragePermission
> {
  public override setRouteData(data: OrderprocessstoragePermissionDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
