import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OfferProductLink } from '../offer-product-link';
import { OfferProductLinkService } from '../offer-product-link.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ArticleDisplayComponent } from '../../../catalog/article/article-display/article-display.component';
import { EarticleDisplayComponent } from '../../../catalog/earticle/earticle-display/earticle-display.component';
import { ArticleTarifTemplateDisplayComponent } from '../../../tarifs/article-tarif-template/article-tarif-template-display/article-tarif-template-display.component';
import { ArticleTarifDisplayComponent } from '../../../tarifs/article-tarif/article-tarif-display/article-tarif-display.component';
import { Article } from '../../../catalog/article/article';
import { Earticle } from '../../../catalog/earticle/earticle';
import { ArticleTarifTemplate } from '../../../tarifs/article-tarif-template/article-tarif-template';
import { Tarif } from '../../../tarifs/tarif/tarif';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-offer-product-link-list',
  standalone: true,
  templateUrl: './offer-product-link-list.component.pug',
  styleUrls: ['./offer-product-link-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
    ArticleTarifTemplateDisplayComponent,
    ArticleTarifDisplayComponent,
    IconComponent,
  ],
})
export class OfferProductLinkListComponent extends ModellistComponent<OfferProductLink> {
  @Input() public article_detail_route?: Link<Article>;
  @Input() public earticle_detail_route?: Link<Earticle>;
  @Input() public att_detail_route?: Link<ArticleTarifTemplate>;
  @Input() public tarif_detail_route?: Link<Tarif>;

  constructor(coll: OfferProductLinkService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
