import { RoutesConfig } from '@solidev/data';
import { RS } from '@vivalya/lvadg';

export const FRONT_ACCOUNT_ROUTES_NAMES = ['login', 'logout', 'reactivate', 'pwreset', 'account'] as const;

export const FRONT_ACCOUNT_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_ACCOUNT_ROUTES_NAMES)[number]> = {
  login: {
    params: [],
    route: () => ['/', RS.login],
    name: 'front_login',
  },
  logout: {
    params: [],
    route: () => ['/', RS.logout],
    name: 'front_logout',
  },
  pwreset: {
    params: [],
    route: () => ['/', RS.pwreset],
    name: 'front_pwreset',
  },

  reactivate: {
    params: [],
    route: () => ['/', RS.reactivate],
    name: 'front_reactivate',
  },

  account: {
    name: 'account',
    params: [],
    route: () => ['/', RS.account],
  },
};
