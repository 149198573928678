import { Component } from '@angular/core';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { PRODUCER_ROUTES } from '../menu';
import { Producer } from '../../../../../models/structures/producer/producer';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { CommonModule } from '@angular/common';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { ProducerNavComponent } from '../_nav/producer-nav.component';
import { OfferListComponent } from '../../../../../models/offers/offer/offer-list/offer-list.component';
import { map, Observable, Subject } from 'rxjs';
import { Offer } from '../../../../../models/offers/offer/offer';
import { Storage } from '../../../../../models/structures/storage/storage';

export interface ProducerOffersViewParams extends BaseRouteParams {
  pr_menu: PRODUCER_ROUTES;
  offer_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
}

export interface ProducerOffersViewData extends ProducerOffersViewParams {
  producer: Producer;
  storage?: Storage;
}

@Component({
  selector: 'lvadg-producer-offers-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OfferListComponent, ProducerNavComponent],
  templateUrl: './producer-offers-view.component.pug',
  styleUrl: './producer-offers-view.component.sass',
})
export class ProducerOffersViewComponent extends BaseDetailViewComponent<ProducerOffersViewData, Producer> {
  public producerFilter$!: Observable<FilterDefaults>;
  public default_fields: string[] = [
    'id',
    'type',
    'title',
    'storage_details',
    'client_details',
    'zone',
    'datestart',
    'dateend',
    'status',
    'author_details',
    'created',
    'updated',
  ];
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public offer_detail_route?: Link<Offer>;
  public offertypestorage_offer_detail_route?: Link<Offer>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.producerFilter$ = this.data$.pipe(map((d) => ({ producer: d.producer.id })));
  }

  public override setRouteData(data: ProducerOffersViewData) {
    super.setRouteData(data);
    if (data.offer_detail_route) {
      this.offer_detail_route = new Link<Offer>(data.offer_detail_route, data, this._router);
    }
    if (data.offertypestorage_offer_detail_route) {
      this.offertypestorage_offer_detail_route = new Link<Offer>(
        data.offertypestorage_offer_detail_route,
        data,
        this._router,
      );
    }
  }
}
