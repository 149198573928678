import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, DataMessageService, FilterDefaults, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TariftypeArticlesViewData } from '../articles/tariftype-articles-view.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';
import { firstValueFrom, map, Observable, Subject } from 'rxjs';
import { ModelListAction } from '../../../../../includes/modellist/modellist.component';
import { Article } from '../../../../../models/catalog/article/article';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypeCatalogViewParams extends BaseRouteParams {
  article_detail_route: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypeCatalogViewData extends TariftypeCatalogViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-catalog-view',
  standalone: true,
  imports: [CommonModule, ArticleListComponent, HeaderActionComponent, IconComponent, TariftypeDetailNavComponent],
  templateUrl: './tariftype-catalog-view.component.pug',
  styleUrls: ['./tariftype-catalog-view.component.sass'],
})
export class TariftypeCatalogViewComponent extends BaseDetailViewComponent<TariftypeCatalogViewData, Tariftype> {
  public catalogTarifTypeFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();

  public catalog_default_fields = [
    'code',
    'libelle',
    'family_details',
    'udf_unit',
    'egalim_details',
    'local',
    'egalim_status',
    'calibre_details',
    'categorie_details',
    'origine_details',
    'colisage',
    'uci',
    'in_tariftype',
    'actions',
  ];

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypeArticlesViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.catalogTarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { in_tariftype: d.tariftype.id };
      }),
    );
  }

  public async catalogAction(tt: Tariftype, action: ModelListAction<Article>) {
    if (action.action == 'tariftype_add' && action.model) {
      try {
        await firstValueFrom(
          tt.action('POST', 'action', {
            body: {
              action: 'article_add',
              article: action.model.id,
            },
          }),
        );
        this.reload$.next();
        this._msgs.success('Article ajouté au tarif');
      } catch (e) {
        console.error(e);
        this._msgs.error(
          "Erreur lors de l'ajout de l'article",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          `${(e as any).error?.message || (e as any).message || ''}`,
          e,
        );
      }
    }
  }
}
