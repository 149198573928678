
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TariftypeStoragePermissionService} from "./tariftype-storage-permission.service";
import { TariftypeStoragePermission } from "./tariftype-storage-permission";

@Injectable({
  providedIn: 'root'
})
export class TariftypeStoragePermissionResolver  {
  constructor(private _rs: TariftypeStoragePermissionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TariftypeStoragePermission> {
    return this._rs.fetch(+route.params["tariftypestoragepermissionId"])
  }
}
