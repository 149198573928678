
<ng-container *ngIf="product">
  <div class="card">
    <div class="card-header">Infos produit</div>
    <div class="card-body">
      <dl class="row">
        <data-dispedit class="col-4" [model]="product" field="id" mode="dd" [editable]="false">ID</data-dispedit>
        <data-dispedit class="col-4" [model]="product" field="status" mode="dd" [editable]="true">État</data-dispedit>
        <data-dispedit class="col-4" [model]="product" field="type" mode="dd" [editable]="true">Type</data-dispedit>
        <data-dispedit class="col-4" [model]="product" field="name" mode="dd" [editable]="true">Nom du produit</data-dispedit>
        <data-dispedit class="col-4" [model]="product" field="producer" mode="dd" [editable]="false" [collection]="producers$">Producteur</data-dispedit>
        <data-dispedit class="col-4" [model]="product" field="provider" mode="dd" [editable]="false" [collection]="providers$">Fournisseur</data-dispedit>
        <div class="col-xl-6">
          <dt>Classification (liens)</dt>
          <dd>
            <ng-container *ngFor="let f of product.family_details?.tree_path"><a class="link" [routerLink]="family_detail_route?.route({family: $any({id: f.id})})"><span class="me-2">&gt; {{f.name}}</span></a></ng-container>
          </dd>
          <dd>
            <data-dispedit [model]="product" field="family" mode="dd" [collection]="families$" [filter]="{type: FAMILY_TYPE.VIVALYA, level_gte: 4}">Modification de la famille</data-dispedit>
          </dd>
        </div>
        <div class="col-xl-6">
          <dt>Saisonnalité</dt>
          <dd>
            <lvadg-seasons-manage [seasons]="product.seasons" [zone]="product.type" [per_line]="12" [editable]="true" size="medium" (changed)="updateField(product, 'seasons', $event)"></lvadg-seasons-manage>
          </dd>
        </div>
        <div class="col-xl-6">
          <dt class="d-flex flex-row justify-content-start"><span>Labels & certifications</span>
            <div class="form-check form-switch ms-3">
              <input class="form-check-input" id="allow_labels_edition" type="checkbox" #cbb (change)="allow_labels_edition.set(cbb.checked||false)">
              <label class="form-check-label" for="allow_labels_edition">Éditer les labels</label>
            </div>
          </dt>
          <dd>
            <lvadg-labels-manager class="col-12" [item]="product" [type]="product.type" source="labels" [save]="true" [catalog_only]="false" [mode]="allow_labels_edition()?'edit':'view'"></lvadg-labels-manager>
          </dd>
        </div>
      </dl>
    </div>
  </div>
  <div class="row mt-4" *ngIf="product.type==PRODUCT_TYPES.LVADG_SEA">
    <div class="col-12">
      <div class="card h-100">
        <div class="card-header">Infos spécifiques</div>
        <div class="card-body">
          <lvadg-cdata-listmanage [model]="product" [dest]="CDATAKEY_DEST.PRODUCT"></lvadg-cdata-listmanage>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-4 col-xl-2">
      <div class="card h-100">
        <div class="card-header">Logo actuel
          <div class="badge bg-info ms-3">
            <ng-container *ngIf="product.logo_details">spécifique</ng-container>
            <ng-container *ngIf="!product.logo_details">hérité</ng-container>
          </div>
        </div>
        <div class="card-body">
          <div class="text-center">
            <lvadg-image-display [image]="product.clogo_details" mode="preview"></lvadg-image-display>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Logo spécifique</div>
        <div class="card-body">
          <lvadg-logo-manage [model]="product"></lvadg-logo-manage>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6 mt-3 mt-xl-0">
      <div class="card h-100">
        <div class="card-header">Galerie d'images</div>
        <div class="card-body">
          <lvadg-gallery-manage [model]="product"></lvadg-gallery-manage>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="card h-100">
        <div class="card-header">Galerie d'images héritées</div>
        <div class="card-body">
          <div class="row g-2">
            <div class="d-flex flex-row flex-wrap align-content-start align-items-start">
              <ng-container *ngFor="let img of product.cimages_details">
                <div class="d-flex flex-column justify-content-center">
                  <lvadg-image-display [image]="img" mode="preview"></lvadg-image-display><span class="badge bg-info m-1" *ngFor="let u of img.usages">{{IMAGE_USAGE_CHOICES_DICT[u]}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>