import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../../includes/modellist/modellist.component';
import { Production } from '../production';
import { ProductionService } from '../production.service';
import { FiltersParams, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-production-list',
  standalone: true,
  templateUrl: './production-list.component.pug',
  styleUrls: ['./production-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, PChoicePipe],
})
export class ProductionListComponent extends ModellistComponent<Production> {
  constructor(coll: ProductionService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
