import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../tarif/tarif';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
  UploadResult,
} from '../../../documents/upload/media-upload/media-upload.component';
import { TarifDocumentService } from '../tarif-document.service';
import { TarifDocument } from '../tarif-document';
import { UploadFile } from '@solidev/data';
import { TARIFDOCUMENT_TYPES } from '../tarif-document.base';
import { TarifService } from '../../tarif/tarif.service';

@Component({
  selector: 'lvadg-tarif-document-create',
  standalone: true,
  imports: [CommonModule, MediaUploadComponent],
  templateUrl: './tarif-document-create.component.pug',
  styleUrls: ['./tarif-document-create.component.sass'],
})
export class TarifDocumentCreateComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Output() public uploaded = new EventEmitter<
    UploadResult<TarifDocument, any> // FIXME: remove this any, add proper type
  >();
  public doc!: TarifDocument;
  public uploadPrepare: (
    model: TarifDocument,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<TarifDocument>> =
    this._uploadPrepare.bind(this);

  constructor(
    private _tarifs: TarifService,
    public tarifdocument$: TarifDocumentService
  ) {}

  ngOnInit(): void {
    this.doc = new TarifDocument(this.tarifdocument$);
    if (this.tarif) {
      this.doc.tarif = this.tarif.id;
      this.doc.tarif_details = this.tarif;
    }
  }

  private async _uploadPrepare(
    model: TarifDocument,
    file: UploadFile
  ): Promise<IPreparedUploadFile<TarifDocument>> {
    return {
      model,
      url: this._tarifs.getUrl(this.tarif.id, { suffix: '/action' }),
      data: {
        action: 'add_document',
        tarif: this.tarif.id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
        type: TARIFDOCUMENT_TYPES.EXPORT_TARIF,
      },
    };
  }
}
