
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CiqualValue } from '../ciqual-value';
@Component({
  selector: 'lvadg-ciqual-value-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ciqual-value-manage.component.pug',
  styleUrls: ['./ciqual-value-manage.component.sass']
})
export class CiqualValueManageComponent implements OnInit {
  @Input() public ciqualvalue?: CiqualValue;

  constructor() { }

  ngOnInit(): void {
  }

}
