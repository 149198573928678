import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeDocument } from '../tariftype-document';
import { DispeditComponent } from '@solidev/data';
import { TariftypeService } from '../../tariftype/tariftype.service';

@Component({
  selector: 'lvadg-tariftype-document-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tariftype-document-manage.component.pug',
  styleUrls: ['./tariftype-document-manage.component.sass'],
})
export class TariftypeDocumentManageComponent implements OnInit {
  @Input() public tariftypedocument?: TariftypeDocument;

  constructor(public tariftypes$: TariftypeService) {}

  ngOnInit(): void {}
}
