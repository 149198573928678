
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { EamappingService} from "./eamapping.service";
import { Eamapping } from "./eamapping";

@Injectable({
  providedIn: 'root'
})
export class EamappingResolver  {
  constructor(private _rs: EamappingService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Eamapping> {
    return this._rs.fetch(+route.params["eamappingId"])
  }
}
