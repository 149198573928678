import { charField, DataModel, foreignKeyField, integerField, manyToManyField, primaryField } from '@solidev/data';

export class GeoregionBase extends DataModel {
  static override readonly __name: string = 'GeoregionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @foreignKeyField({
    name: 'country',
    description: 'ID Pays',
    related: 'GeoCountry',
    priority: -1,
  })
  public country!: number;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 200,
    priority: 900,
  })
  public name!: string;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 20,
    priority: 800,
  })
  public code!: string;

  @charField({
    name: 'insee',
    description: 'Code INSEE',
    defaultValue: '??',
    required: true,
    maxLength: 20,
    priority: 700,
  })
  public insee: string = '??';

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1000,
  })
  public orig_id!: number;

  @manyToManyField({
    name: 'departements',
    description: 'IDs départements',
    defaultValue: [],
    related: 'GeoDepartement',
    priority: -1,
  })
  public departements: number[] = [];
}
