import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';
import { OrderprocessDisplayViewData } from './orderprocess-display-view.component.params';
import { OrderprocessManageComponent } from '../../../../models/orders/orderprocess/orderprocess-manage/orderprocess-manage.component';

@Component({
  selector: 'lvadg-orderprocess-display-view',
  standalone: true,
  imports: [CommonModule, OrderprocessManageComponent],
  templateUrl: './orderprocess-display-view.component.pug',
  styleUrls: ['./orderprocess-display-view.component.sass'],
})
export class OrderprocessDisplayViewComponent extends BaseDetailViewComponent<
  OrderprocessDisplayViewData,
  Orderprocess
> {}
