import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeMetadata } from '../tariftype-metadata';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { TARIFTYPEMETADATA_TYPE } from '../tariftype-metadata.base';
import { TariftypeDocument } from '../../tariftype-document/tariftype-document';
import { TariftypeDocumentService } from '../../tariftype-document/tariftype-document.service';
import { firstValueFrom } from 'rxjs';
import { XlsxParamsComponent } from '../../../../components/utils/xlsx-params/xlsx-params.component';
import {
  XlsxDescription,
  XlsxParams,
} from '../../../../components/utils/xlsx-params/xlsx-params';
import { MetadataTypesService } from '../metadata-types.service';

@Component({
  selector: 'lvadg-tariftype-metadata-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, XlsxParamsComponent],
  templateUrl: './tariftype-metadata-manage.component.pug',
  styleUrls: ['./tariftype-metadata-manage.component.sass'],
})
export class TariftypeMetadataManageComponent implements OnInit {
  @Input() public tariftypemetadata?: TariftypeMetadata;
  public tariftypedocument?: TariftypeDocument;
  public xlsxData?: XlsxDescription;
  public TTMT = TARIFTYPEMETADATA_TYPE;

  constructor(
    private _ttds: TariftypeDocumentService,
    private _msgs: DataMessageService,
    public mparams$: MetadataTypesService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.tariftypemetadata && this.tariftypemetadata.document) {
      this.tariftypedocument = await firstValueFrom(
        this._ttds.fetch(this.tariftypemetadata?.document)
      );
      this.xlsxData = await firstValueFrom(
        this.tariftypedocument.action<TariftypeDocument, XlsxDescription>(
          'POST',
          'describe'
        )
      );
    }
  }

  public async saveParams(params: XlsxParams) {
    if (this.tariftypemetadata) {
      this.tariftypemetadata.data = params as any;
      await firstValueFrom(
        this.tariftypemetadata.update(['data'], { updateModel: true })
      );
      this._msgs.success('Paramètres enregistrés avec succès');
    }
  }
}
