import { ComposerMessage } from './tarif-lifecycle/messages/tarif-message-composer/tarif-message-composer.component';
import { TarifMessageContacts } from './tarif-lifecycle/messages/tarif-message-contacts/tarif-message-contacts.component';
import { Link } from '@solidev/data';
import { TARIF_ROUTES } from '../../../../views/tarifs/tarifs/detail/menu';
import { Router } from '@angular/router';
import { Tarif } from '../tarif';
import { Tariftype } from '../../tariftype/tariftype';

interface TarifLifecycleExportAction {
  action: 'export[default]' | 'export[nego]' | 'export[custom]' | 'export[custom+nego]';
  mode?: string;
}

interface TarifLifecycleNavAction {
  action: 'nav';
  dest: 'prices' | 'details' | 'documents';
}

interface TarifLifecycleNextAction {
  action: 'next';
}

interface TarifLifecyclePreviousAction {
  action: 'back';
}

interface TarifLifecycleStepAction {
  action: 'step';
  dest: number;
}

interface TarifLifecycleNegoSendAction {
  action: 'negosend';
  message: ComposerMessage;
  contacts: TarifMessageContacts;
}

interface TarifLifecyclePublishAction {
  action: 'publish';
  message: ComposerMessage;
  //  contacts: TarifMessageContacts;
}

interface TarifLifecycleCancelPublishAction {
  action: 'publish_cancel';
}

export interface TarifLifecycleActionResult {
  message: string;
  success: boolean;
}

export type TarifLifecycleAction =
  | TarifLifecycleExportAction
  | TarifLifecycleNavAction
  | TarifLifecycleNextAction
  | TarifLifecyclePreviousAction
  | TarifLifecyclePublishAction
  | TarifLifecycleCancelPublishAction
  | TarifLifecycleNegoSendAction
  | TarifLifecycleStepAction;

export async function lifecycleNav(
  dest: 'prices' | 'details' | 'documents',
  menu: TARIF_ROUTES | undefined,
  router: Router,
  tarif: Tarif,
  tariftype: Tariftype,
) {
  if (!menu) return;
  switch (dest) {
    case 'prices':
      if (menu?.tarif_lines_route) {
        await new Link(
          menu?.tarif_lines_route,
          {
            tarif,
            tariftype,
          },
          router,
        ).navigate();
      }
      break;
    case 'details':
      if (menu?.tarif_manage_route) {
        await new Link(
          menu?.tarif_manage_route,
          {
            tarif,
            tariftype,
          },
          router,
        ).navigate();
      }
      break;
    case 'documents':
      if (menu?.tarif_documents_route) {
        await new Link(
          menu?.tarif_documents_route,
          {
            tarif,
            tariftype,
          },
          router,
        ).navigate();
      }
      break;
  }
}
