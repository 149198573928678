
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action [title]="data.vivalyabill._display" level="h1" ri="sales" subtitle="Détails facture"></lvadg-header-action>
  <div class="row g-3 mb-3">
    <div class="col-sm-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Détails facture</div>
        <div class="card-body">
          <dl class="row g-3">
            <div class="col-md-6">
              <dt>N° facture</dt>
              <dd>{{data.vivalyabill.code}}</dd>
            </div>
            <div class="col-md-6">
              <dt>Date facture</dt>
              <dd>{{data.vivalyabill.billdate|date:'dd/MM/yyyy'}}</dd>
            </div>
            <div class="col-md-6">
              <dt>Dépôt émetteur</dt>
              <dd>
                <lvadg-storage-display [storage]="data.vivalyabill.storage_details" mode="line" [detail_route]="storage_detail_route"></lvadg-storage-display>
              </dd>
            </div>
            <div class="col-md-6">
              <dt>Client</dt>
              <dd>
                <lvadg-client-display [client]="data.vivalyabill.client_details" mode="line" [detail_route]="client_detail_route"></lvadg-client-display>
              </dd>
            </div>
            <div class="col-md-6">
              <dt>Restaurant</dt>
              <dd>
                <lvadg-resto-display [resto]="data.vivalyabill.resto_details" mode="line" [detail_route]="resto_detail_route"></lvadg-resto-display>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Détails montants</div>
        <div class="card-body">
          <dl class="row g-3">
            <div class="col-md-6">
              <dt>Montant total brut</dt>
              <dd>{{data.vivalyabill.brut_amount|pfactorc:data.vivalyabill:'brut_amount':'EUR'}}</dd>
            </div>
            <div class="col-md-6">
              <dt>Montant total net</dt>
              <dd>{{data.vivalyabill.net_amount|pfactorc:data.vivalyabill:'net_amount':'EUR'}}</dd>
            </div>
            <div class="col-md-6">
              <dt>TVA</dt>
              <dd>{{data.vivalyabill.vat_amount|pfactorc:data.vivalyabill:'vat_amount':'EUR'}}</dd>
            </div>
            <div class="col-md-6">
              <dt>Montant total TTC</dt>
              <dd>{{data.vivalyabill.wvat_amount|pfactorc:data.vivalyabill:'wvat_amount':'EUR'}}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-4">
      <div class="card h-100">
        <div class="card-header">Infos géographiques</div>
        <div class="card-body">
          <pre class="todo">TODO: carte avec dépôt, resto, et localisation approx fournisseurs + résumé distances ?</pre>
        </div>
      </div>
    </div>
  </div>
  <h2 class="my-3">Lignes de facturation</h2>
  <lvadg-vivalya-bill-item-list [name]="data.route.name" [member_detail_route]="member_detail_route" [storage_detail_route]="storage_detail_route" [resto_detail_route]="resto_detail_route" [detail_route]="billitem_detail_route" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" [client_detail_route]="client_detail_route" [filter]="billFilter$"></lvadg-vivalya-bill-item-list>
</ng-container>