
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-user-map-layer-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-map-layer-create.component.pug',
  styleUrls: ['./user-map-layer-create.component.sass']
})
export class UserMapLayerCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
