
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VivalyaBill } from '../vivalya-bill';
@Component({
  selector: 'lvadg-vivalya-bill-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vivalya-bill-manage.component.pug',
  styleUrls: ['./vivalya-bill-manage.component.sass']
})
export class VivalyaBillManageComponent implements OnInit {
  @Input() public vivalyabill?: VivalyaBill;

  constructor() { }

  ngOnInit(): void {
  }

}
