import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TarifRelationService } from './tarif-relation.service';
import { TarifRelation } from './tarif-relation';

@Injectable({
  providedIn: 'root',
})
export class TarifRelationResolver {
  constructor(private _rs: TarifRelationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TarifRelation> {
    return this._rs.fetch(+route.params['tarifrelationId']);
  }
}
