export enum LIFECYCLE_MAIN_STEP {
  PREPARATION = 'PRP',
  NEGOCIATION = 'NEG',
  PUBLICATION = 'PUB',
  UPDATE_1 = 'UPD1',
  UPDATE_2 = 'UPD2',
  UPDATE_3 = 'UPD3',
  UPDATE_4 = 'UPD4',
  UPDATE_5 = 'UPD5',
  UPDATE_6 = 'UPD6',
  UPDATE_7 = 'UPD7',
  UPDATE_8 = 'UPD8',
  UPDATE_9 = 'UPD9',
}

export enum LIFECYCLE_SUB_STEP {
  EDITION = 'edit',
  UPLOAD = 'upload',
  ARTICLES = 'articles',
  NEGOSEND = 'negosend',
  NEGOUPD = 'negoupd',
  PUBLISH = 'publish',
  DONE = 'done',
}

export interface LifeCycleDefault {
  stitle?: string;
  title?: string;
  message?: string;
  params?: Record<string, any>;
}

export const LifeCycleMainDefaults: {
  [key in LIFECYCLE_MAIN_STEP]?: LifeCycleDefault;
} = {
  [LIFECYCLE_MAIN_STEP.PREPARATION]: {
    title: 'Préparation',
    stitle: 'Préparation',
  },
  [LIFECYCLE_MAIN_STEP.NEGOCIATION]: {
    title: 'Négociation',
    stitle: 'Négociation',
  },
  [LIFECYCLE_MAIN_STEP.PUBLICATION]: {
    title: 'Publication',
    stitle: 'Publication',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_1]: {
    title: 'Erratum / mise à jour 1',
    stitle: 'Erratum 1',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_2]: {
    title: 'Erratum / mise à jour 2',
    stitle: 'Erratum 2',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_3]: {
    title: 'Erratum / mise à jour 3',
    stitle: 'Erratum 3',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_4]: {
    title: 'Erratum / mise à jour 4',
    stitle: 'Erratum 4',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_5]: {
    title: 'Erratum / mise à jour 5',
    stitle: 'Erratum 5',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_6]: {
    title: 'Erratum / mise à jour 6',
    stitle: 'Erratum 6',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_7]: {
    title: 'Erratum / mise à jour 7',
    stitle: 'Erratum 7',
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_8]: {
    title: 'Erratum / mise à jour 8',
    stitle: 'Erratum 8',
  },
};
export const LifeCycleDefaults: {
  [key in LIFECYCLE_MAIN_STEP]?: {
    [key in LIFECYCLE_SUB_STEP]?: LifeCycleDefault;
  };
} = {
  [LIFECYCLE_MAIN_STEP.PREPARATION]: {
    [LIFECYCLE_SUB_STEP.EDITION]: {
      stitle: 'Édition initiale',
      title: 'Édition initiale du prix et des infos tarifs',
      message:
        '<b>Mise à jour des prix,</b>, du message tarif. Vous pouvez télécharger le tarif au format Excel si nécessaire',
    },
    [LIFECYCLE_SUB_STEP.UPLOAD]: {
      stitle: 'Envoi du fichier de tarif',
      title: 'Mise en ligne du fichier de tarif',
      message: 'Utiliser de préférence des fichiers au format XLSX et non XLS',
    },
    [LIFECYCLE_SUB_STEP.ARTICLES]: {},
    [LIFECYCLE_SUB_STEP.DONE]: {
      stitle: 'Validation',
      title: 'Validation',
      message: `En passant à l'étape suivante, la phase de préparation sera enregistrée comme terminée, et vous pourrez passer à la publication du tarif.`,
    },
  },
  [LIFECYCLE_MAIN_STEP.NEGOCIATION]: {
    [LIFECYCLE_SUB_STEP.NEGOSEND]: {
      stitle: 'Envoi négo',
      title: 'Envoi du fichier de négociation au client',
      message:
        'Vous pouvez envoyer le fichier de négociation aux différents contacts de négociation, ou bien le télécharger pour un envoi manuel',
    },
    [LIFECYCLE_SUB_STEP.NEGOUPD]: {
      stitle: 'Retour négo',
      title: 'Retour de négociation',
      message:
        'Retour de négociation, vous pouvez réimporter le fichier excel avec les nouveaux prix dans la colonne négo, ou bien modifier les prix directement dans le tarif.',
    },
  },
  [LIFECYCLE_MAIN_STEP.PUBLICATION]: {
    [LIFECYCLE_SUB_STEP.PUBLISH]: {
      stitle: 'Publier le tarif',
      title: 'Publication du tarif',
      message:
        'Lors de la publication, le tarif sera envoyé aux destinataires du tarif. Les publications en priorité 1 sont envoyées immédiatement, celles en priorité 2 partent en lot toutes les 2 heures, et celles en priorité 3 ne donnent lieu à aucun envoi de mail',
    },
    [LIFECYCLE_SUB_STEP.DONE]: {
      stitle: 'Publication effectuée',
      title: 'Publication effectuée',
      message: `Tant que les notifications ne sont pas envoyées, vous pouvez annuler la publication.<br> Dès lors que les notifications ont commencé à être envoyées, vous ne pouvez plus annuler la publication, mais vous pouvez créer un erratum`,
    },
  },
  [LIFECYCLE_MAIN_STEP.UPDATE_1]: {
    [LIFECYCLE_SUB_STEP.EDITION]: {
      stitle: 'Edition erratum',
      title: 'Edition du prix et des infos tarifs post-publication (erratum)',
      message:
        '<b>Mise à jour des prix,</b>, du message tarif. Vous pouvez télécharger le tarif au format Excel si nécessaire',
    },
    [LIFECYCLE_SUB_STEP.UPLOAD]: {
      stitle: 'Envoi du fichier de tarif corrigé',
      title: 'Mise en ligne du fichier de tarif corrigé',
      message: 'Utiliser de préférence des fichiers au format XLSX et non XLS',
    },
    [LIFECYCLE_SUB_STEP.ARTICLES]: {},
    [LIFECYCLE_SUB_STEP.NEGOSEND]: {},
    [LIFECYCLE_SUB_STEP.NEGOUPD]: {},
    [LIFECYCLE_SUB_STEP.PUBLISH]: {
      stitle: "Publier l'erratum",
      title: "Publication d'un erratum",
      message:
        'Pensez à préciser la raison de la mise à jour dans le message de publication.',
    },
    [LIFECYCLE_SUB_STEP.DONE]: {
      stitle: 'Publication effectuée',
      title: 'Publication effectuée',
      message: `Les publications d'erratum ne peuvent pas être annulées pour l'instant`,
    },
  },
};
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_2] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_3] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_4] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_5] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_6] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_7] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_8] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];
LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_9] =
  LifeCycleDefaults[LIFECYCLE_MAIN_STEP.UPDATE_1];

export const EDITION_ALLOWED_STEPS = [
  LIFECYCLE_MAIN_STEP.PREPARATION,
  LIFECYCLE_MAIN_STEP.UPDATE_1,
  LIFECYCLE_MAIN_STEP.UPDATE_2,
  LIFECYCLE_MAIN_STEP.UPDATE_3,
  LIFECYCLE_MAIN_STEP.UPDATE_4,
  LIFECYCLE_MAIN_STEP.UPDATE_5,
  LIFECYCLE_MAIN_STEP.UPDATE_6,
  LIFECYCLE_MAIN_STEP.UPDATE_7,
  LIFECYCLE_MAIN_STEP.UPDATE_8,
  LIFECYCLE_MAIN_STEP.UPDATE_9,
];
