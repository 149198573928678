import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifLog } from '../tarif-log';
import { TarifLogService } from '../tarif-log.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { TarifBase } from '../../tarif/tarif.base';
import { UserBase } from '../../../users/user/user.base';
import { TarifDisplayComponent } from '../../tarif/tarif-display/tarif-display.component';

@Component({
  selector: 'lvadg-tarif-log-list',
  standalone: true,
  templateUrl: './tarif-log-list.component.pug',
  styleUrls: ['./tarif-log-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, UserDisplayComponent, TarifDisplayComponent, PChoicePipe],
})
export class TarifLogListComponent extends ModellistComponent<TarifLog> {
  @Input() public tarif_detail_route?: Link<TarifBase>;
  @Input() public user_detail_route?: Link<UserBase>;

  constructor(coll: TarifLogService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
