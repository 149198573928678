import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../tarif/tarif';
import { StorageBase } from '../../../../structures/storage/storage.base';
import { firstValueFrom, Subject } from 'rxjs';
import { TarifActionService } from '../../../tarif/tarif-action.service';
import { ArticleTarifColumns } from '../article-tarif-columns';
import { TarifService } from '../../../tarif/tarif.service';

@Component({
  selector: 'lvadg-article-tarif-storage-column-operations',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-tarif-storage-column-operations.component.pug',
  styleUrls: ['./article-tarif-storage-column-operations.component.sass'],
})
export class ArticleTarifStorageColumnOperationsComponent {
  @Input() public columns!: ArticleTarifColumns;
  @Input() public tarif!: Tarif;
  @Input() public storage!: StorageBase;
  @Input() public reload?: Subject<void | boolean>;

  @Output() public closed = new EventEmitter<void>();

  public importingPrices = false;
  public validatingStorage = false;

  constructor(
    private _tas: TarifActionService,
    private _tarifs: TarifService,
  ) {}

  public async importPrices() {
    this.importingPrices = true;
    const res = await this._tas.action(this.tarif, {
      action: 'storage_import_items',
      storage: this.storage.id,
    });
    if (res.success) {
      this.reload?.next(true);
      this.tarif = await firstValueFrom(this._tarifs.fetch(this.tarif.id));
      this.columns.updateStorageStatus(this.tarif.tarifstoragestatus_details);
    }
    this.importingPrices = false;
    this.closed.emit();
  }

  public async validateStorage() {
    this.validatingStorage = true;
    const res = await this._tas.action(this.tarif, {
      action: 'storage_validate_items',
      storage: this.storage.id,
    });
    if (res.success) {
      this.reload?.next(true);
      this.tarif = await firstValueFrom(this._tarifs.fetch(this.tarif.id));
      this.columns.updateStorageStatus(this.tarif.tarifstoragestatus_details);
    }
    this.validatingStorage = false;
    this.closed.emit();
  }
}
