import {
  charField,
  DataModel,
  dateField,
  datetimeField,
  decimalField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';
import { IntervalGrouper } from '../../../../components/utils/periods';

export class AtscacheBase extends DataModel {
  static override readonly __name: string = 'AtscacheBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @dateField({
    name: 'date',
    description: 'Date début groupe',
  })
  public date!: Date;

  @charField({
    name: 'grouper',
    description: 'grouper',
    defaultValue: IntervalGrouper.WEEK,
    maxLength: 1,
    choices: [
      {
        value: IntervalGrouper.YEAR,
        desc: 'Année',
      },
      {
        value: IntervalGrouper.QUARTER,
        desc: 'Trimestre',
      },
      {
        value: IntervalGrouper.MONTH,
        desc: 'Mois',
      },
      {
        value: IntervalGrouper.WEEK,
        desc: 'Semaine',
      },
      {
        value: IntervalGrouper.DAY,
        desc: 'Jour',
      },
    ],
  })
  public grouper: IntervalGrouper = IntervalGrouper.WEEK;

  @foreignKeyField({
    name: 'family',
    description: 'ID Famille',
    related: 'Family',
  })
  public family!: number;

  @foreignKeyField({
    name: 'att',
    description: 'ID Modèle article tarif',
    related: 'ArticleTarifTemplate',
  })
  public att!: number;

  @foreignKeyField({
    name: 'line',
    description: 'ID Ligne Article tarif',
    related: 'ArticleTarif',
  })
  public line!: number;

  @decimalField({
    name: 'price',
    description: 'price',
    factor: 10000,
  })
  public price!: number;
}
