import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';
import { RegionBase } from '../region.base';
import { RegionService } from '../region.service';

@Component({
  selector: 'lvadg-region-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './region-create.component.pug',
  styleUrls: ['./region-create.component.sass'],
})
export class RegionCreateComponent implements OnInit {
  public region!: RegionBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<RegionBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _regions: RegionService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.region = new RegionBase(this._regions);
  }

  public async save() {
    this.region.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.region.save({ updateModel: true }));
      this.created.emit(this.region);
      this._msgs.success('Région créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
