import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarifTemplate } from '../article-tarif-template';
import { DispeditComponent, Link } from '@solidev/data';
import { ArticleService } from '../../../catalog/article/article.service';
import { ArticleDisplayComponent } from '../../../catalog/article/article-display/article-display.component';
import { EarticleService } from '../../../catalog/earticle/earticle.service';
import { EarticleDisplayComponent } from '../../../catalog/earticle/earticle-display/earticle-display.component';
import { Article } from '../../../catalog/article/article';
import { Earticle } from '../../../catalog/earticle/earticle';

@Component({
  selector: 'lvadg-article-tarif-template-manage',
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
  ],
  templateUrl: './article-tarif-template-manage.component.pug',
  styleUrls: ['./article-tarif-template-manage.component.sass'],
})
export class ArticleTarifTemplateManageComponent implements OnInit {
  @Input() public articletariftemplate?: ArticleTarifTemplate;
  @Input() public article_detail_route?: Link<Article>;
  @Input() public earticle_detail_route?: Link<Earticle>;

  constructor(
    public articles$: ArticleService,
    public earticles$: EarticleService
  ) {}

  ngOnInit(): void {}
}
