
<ng-container *ngIf="user">
  <dl class="row">
    <data-dispedit class="col-4" [model]="user" field="username" mode="dd">Identifiant utilisateur</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="email" mode="dd">Email</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="orig_password" mode="dd">Mot de passe initial</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="title" mode="dd">Titre</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="last_name" mode="dd">Nom de famille</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="first_name" mode="dd">Prénom</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="phone" mode="dd">Téléphone fixe</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="mobile" mode="dd">Téléphone portable</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="birth_date" mode="dd">Date de naissance</data-dispedit>
  </dl>
  <dl class="row text-bg-danger pt-2" *ngIf="isSuperUser|async">
    <data-dispedit class="col-4" [model]="user" field="is_superuser" mode="dd">Superutilisateur ?</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="is_staff" mode="dd">Equipe Vivalya ?</data-dispedit>
    <data-dispedit class="col-4" [model]="user" field="is_active" mode="dd">Actif ?</data-dispedit>
  </dl>
  <data-flags class="col-6" [model]="user" field="flags">Flags</data-flags>
</ng-container>