import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Calibre } from '../calibre';
import { DispeditComponent } from '@solidev/data';
import { FamilyService } from '../../../family/family.service';

@Component({
  selector: 'lvadg-calibre-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './calibre-manage.component.pug',
  styleUrls: ['./calibre-manage.component.sass'],
})
export class CalibreManageComponent implements OnInit {
  @Input() public calibre!: Calibre;

  constructor(public families$: FamilyService) {}

  ngOnInit(): void {}
}
