import { RESTO_LINKS_ROUTES, RESTO_ROUTES } from './menu';
import { RData, RouteConfigItem, RoutesDefinition } from '@solidev/data';
import { RestoDisplayViewComponent, RestoDisplayViewParams } from './display/resto-display-view.component';
import { RestoEditViewComponent, RestoEditViewParams } from './edit/resto-edit-view.component';
import { RS } from '../../../../constants';
import { RestoRelationsViewComponent, RestoRelationsViewParams } from './relations/resto-relations-view.component';
import { RestoOffersViewComponent, RestoOffersViewParams } from './offers/resto-offers-view.component';
import { RestoSalesViewComponent, RestoSalesViewParams } from './sales/resto-sales-view.component';
import { RestoUsersViewComponent, RestoUsersViewParams } from './users/resto-users-view.component';

export const gen_resto_detail_routes = (
  routes: RESTO_ROUTES,
  links: RESTO_LINKS_ROUTES,
  default_route: RouteConfigItem,
): RoutesDefinition => {
  const routes_def: RoutesDefinition = [];
  if (routes.resto_display_route) {
    routes_def.push({
      path: '',
      pathMatch: 'full',
      component: RestoDisplayViewComponent,
      data: RData<RestoDisplayViewParams>({
        route: routes.resto_display_route,
        skip: true,
        r_menu: routes,
      }),
    });
  }

  if (routes.resto_edit_route) {
    routes_def.push({
      path: RS.edit,
      component: RestoEditViewComponent,
      data: RData<RestoEditViewParams>({
        route: routes.resto_edit_route,
        title: 'Édition',
        r_menu: routes,
      }),
    });
  }

  if (routes.resto_relations_route) {
    routes_def.push({
      path: RS.relations,
      component: RestoRelationsViewComponent,
      data: RData<RestoRelationsViewParams>({
        route: routes.resto_relations_route,
        title: 'Relations',
        r_menu: routes,
        client_detail_route: links.client_detail_route,
        resto_detail_route: links.resto_detail_route,
        producer_detail_route: links.producer_detail_route,
        storage_detail_route: links.storage_detail_route,
        provider_detail_route: links.provider_detail_route,
        relation_detail_route: links.relation_detail_route,
      }),
    });
  }
  if (routes.resto_offers_route) {
    routes_def.push({
      path: RS.offers,
      component: RestoOffersViewComponent,
      data: RData<RestoOffersViewParams>({
        route: routes.resto_offers_route,
        title: 'Offres',
        r_menu: routes,
        client_detail_route: links.client_detail_route,
        resto_detail_route: links.resto_detail_route,
        storage_detail_route: links.storage_detail_route,
        offer_detail_route: links.offer_detail_route,
        offertype_detail_route: links.offertype_detail_route,
        user_detail_route: links.user_detail_route,
        offertypestorage_detail_route: links.offertypestorage_detail_route,
        offertypestorage_offer_detail_route: links.offertypestorage_offer_detail_route,
      }),
    });
  }
  if (routes.resto_sales_route) {
    routes_def.push({
      path: RS.sales,
      component: RestoSalesViewComponent,
      data: RData<RestoSalesViewParams>({
        route: routes.resto_sales_route,
        title: 'Factures',
        r_menu: routes,
        client_detail_route: links.client_detail_route,
        resto_detail_route: links.resto_detail_route,
        storage_detail_route: links.storage_detail_route,
        bill_detail_route: links.bill_detail_route,
      }),
    });
  }
  if (routes.resto_users_route) {
    routes_def.push({
      path: RS.users,
      component: RestoUsersViewComponent,
      data: RData<RestoUsersViewParams>({
        route: routes.resto_users_route,
        title: 'Utilisateurs',
        r_menu: routes,
        user_detail_route: links.user_detail_route,
        group_detail_route: links.group_detail_route,
      }),
    });
  }
  return routes_def;
};
