
<ng-container *ngIf="fdata$|async; let d">
  <ng-template #ofcslot_add let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Ajouter un produit à l'offre</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-product-list [name]="d.data.route.name+'-add'" [keep]="false" [reload]="reload$" [filter]="forOfferFilter$" [actions]="['offer_add']" [default_fields]="add_product_default_fields" (action)="addProduct($event)" displayMode="ml-top"></lvadg-product-list>
    </div>
  </ng-template>
  <ng-template #ofcslot_remove let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Supprimer tous les produits de l'offre</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <p class="text-center text-danger">Voulez-vous vraiment supprimer tous les produits de l'offre ?</p>
      <div class="p-5 text-center">
        <button class="btn btn-outline-danger btn-lg w-100 my-5 py-2" (click)="processRemoveAll()">
          <lvadg-icon ri="delete">Supprimer tous les produits</lvadg-icon>
        </button>
        <button class="btn btn-outline-secondary w-100" (click)="offcanvas.close()">
          <lvadg-icon ri="close">Annuler</lvadg-icon>
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #ofcslot_import let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Importer les produits d'un autre offre</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-offer-list [name]="d.data.route.name+'-import'" [keep]="false" [filter]="forOfferFilter$" [actions]="['products_import']" [default_fields]="products_import_default_fields" [default_filters]="['search', 'clients', 'storages']" [sorter]="['-dateend']" (action)="processImportProducts($event)" displayMode="ml-top"></lvadg-offer-list>
    </div>
  </ng-template>
  <ng-template #ofcslot_edit let-offcanvas>
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Édition du produit</h4>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-offer-product-edit *ngIf="currentProduct" [offer]="d.data.offer" [offerproduct]="currentProduct" [product_detail_route]="product_detail_route" [producer_detail_route]="producer_detail_route" [family_detail_route]="family_detail_route" [tarif_detail_route]="tarif_detail_route" [att_detail_route]="att_detail_route" [earticle_detail_route]="earticle_detail_route" [article_detail_route]="article_detail_route" (link_action)="onLinkAction($event)" [reload]="reload$"></lvadg-offer-product-edit>
    </div>
  </ng-template>
  <lvadg-offer-nav [routes]="d.data.o_menu" [offer]="d.data.offer" *ngIf="d.data.o_menu" [offertype]="d.data.offertype" [offertypestorage]="d.data.offertypestorage"></lvadg-offer-nav>
  <lvadg-header-action [title]="d.data.offer.title" level="h1" subtitle="Produits de l'offre" ri="offer">
    <ng-container actions>
      <button class="btn btn-outline-info btn-sm" (click)="openImportProducts()" *ngIf="canEdit()">
        <lvadg-icon ri="add">Importer</lvadg-icon>
      </button>
      <button class="btn btn-outline-danger btn-sm" (click)="openRemoveAll()" *ngIf="canEdit()">
        <lvadg-icon ri="delete">Supprimer tout</lvadg-icon>
      </button>
      <button class="btn btn-outline-primary btn-sm" (click)="openAddProduct()" *ngIf="canEdit()">
        <lvadg-icon ri="add">Ajouter un produit</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-offer-product-list [name]="d.data.route.name" [reload]="reload$" [filter]="offerFilter$" [actions]="canEdit() ? ['edit', 'delete']:[]" (action)="onProductAction($event)" [default_fields]="default_fields" [product_detail_route]="product_detail_route" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" [att_detail_route]="att_detail_route" [tarif_detail_route]="tarif_detail_route" [family_detail_route]="family_detail_route" [producer_detail_route]="producer_detail_route"></lvadg-offer-product-list>
</ng-container>