import {
  booleanField,
  charField,
  DataModel,
  datetimeField,
  decimalField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from "@solidev/data";

export enum TARIFTYPE_TYPE {
  CLIENT = "CLI",
  GENERIC = "GEN",
}

export enum TARIFTYPE_ALARM {
  NO_CURRENT = "alarm:nocurrent",
  NO_RRULE = "alarm:no_rrule",
  DATE_PUBLICATION = "alarm:date_publication",
  DATE_VALIDATION = "alarm:date_validation",
  DATE_PREPARATION = "alarm:date_preparation",
}

export const TARIFTYPE_FLAGS_HUMANIZED = {
  alarm: { desc: "Toute alarme", class: "warning" },
  date: { desc: "Toute alarme de date", class: "warning" },
  "alarm:nocurrent": { desc: "Pas de tarif", class: "danger" },
  "alarm:date_publication": { desc: "Date de publication", class: "warning" },
  "alarm:date_validation": { desc: "Date de publication", class: "info" },
  "alarm:date_preparation": { desc: "Date de publication", class: "warning" },
  "alarm:no_rrule": { desc: "Pas de récurrence", class: "danger" },
};

export enum TARIFTYPE_LIFECYCLE {
  UPLOAD = "UPLOAD",
  GENERIC = "GENERIC",
  GENERIC_ADMIN = "GENERICA",
  GENNOVAL = "GENNOVAL",
  GENNOVAL_ADMIN = "GENNOVALA",
}

export enum TARIFTYPE_STORAGE_MODE {
  SIMPLE = "BASE",
  DISPO_PER_STORAGE = "DISP",
  PRICE_PER_STORAGE = "STOR",
  PRICE_DISPO_STORAGE = "PRST",
}

export enum TARIFTYPE_REGION_MODE {
  SIMPLE = "BASE",
  DISPO_PER_REGION = "DISP",
  PRICE_PER_REGION = "STOR",
  PRICE_DISPO_REGION = "PRST",
}

export enum TARIFTYPE_ZONE {
  FL = "FL",
  SEA = "SEA",
  G45 = "45G",
}

export enum TariftypeInterval {
  W1 = "W1",
  W2 = "W2",
  M1 = "M1",
  M2 = "M2",
  M3 = "M3",
  M6 = "M6",
  M10 = "M10",
  M12 = "M12",
  CS = "CS",
}

export enum TariftypeDecondiStatus {
  CTR = "CTR",
  KPK = "KPK",
  NDC = "NDC",
  UNK = "UNK",
}

export enum TARIFTYPE_STATUS {
  FUTUR = "PRP",
  CURRENT = "CUR",
  ARCHIVED = "ARC",
}

export enum TariftypeSegment {
  REST_COMM = "RCM",
  REST_COLL = "RCL",
  CENTRE_REF = "CRF",
  GMS = "GMS",
  UNKNOWN = "UNK",
}

export enum TARIFTYPE_USER_LEVEL {
  sadmin = "sadmin",
  admin = "admin",
  pilote = "pilote",
  write = "write",
  storage = "storage",
  read = "read",
  anon = "anon",
}

export enum TARIFTYPE_PRICE_TYPE {
  BRUT = "BRT",
  NET = "NET",
  NET2 = "NT2",
  FRANCO = "FRC",
  CUSTOM = "CST",
}

export enum TARIFTYPE_EXPORT_INACTIVES_MODE {
  NO = "NO",
  OUT_QUOTE = "OQ",
  ALL = "AL",
}

export class TariftypeBase extends DataModel {
  static override readonly __name: string = "TariftypeBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: "flags",
    description: "Flags",
    defaultValue: [],
    priority: 150,
  })
  public flags: string[] = [];

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 125,
  })
  public updated!: Date;

  @charField({
    name: "name",
    description: "Nom",
    maxLength: 200,
    priority: 950,
  })
  public name!: string;

  @charField({
    name: "code",
    description: "Code",
    maxLength: 50,
    priority: 975,
  })
  public code!: string;

  @charField({
    name: "name_template",
    description: "Modèle de nom de tarif",
    maxLength: 400,
    priority: 925,
  })
  public name_template!: string;

  @textField({
    name: "message",
    description: "Message",
    priority: 400,
  })
  public message!: string;

  @textField({
    name: "contract_message",
    description: "Message contrat",
    priority: 400,
  })
  public contract_message!: string;

  @textField({
    name: "internal_notes",
    description: "Notes internes",
    required: false,
    priority: 200,
  })
  public internal_notes?: string;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: TARIFTYPE_TYPE.CLIENT,
    maxLength: 3,
    choices: [
      { value: TARIFTYPE_TYPE.GENERIC, desc: "Tarif générique" },
      { value: TARIFTYPE_TYPE.CLIENT, desc: "Tarif client" },
    ],
    priority: 850,
  })
  public type: TARIFTYPE_TYPE = TARIFTYPE_TYPE.CLIENT;

  @charField({
    name: "segment",
    description: "Segment",
    defaultValue: TariftypeSegment.UNKNOWN,
    maxLength: 3,
    choices: [
      { value: TariftypeSegment.REST_COMM, desc: "Restauration Commerciale" },
      { value: TariftypeSegment.REST_COLL, desc: "Restauration Collective" },
      { value: TariftypeSegment.CENTRE_REF, desc: "Centrale de référencement" },
      { value: TariftypeSegment.GMS, desc: "GMS" },
      { value: TariftypeSegment.UNKNOWN, desc: "Inconnu / non renseigné" },
    ],
    priority: 500,
  })
  public segment: TariftypeSegment = TariftypeSegment.UNKNOWN;

  @charField({
    name: "lifecycle",
    description: "Cycle de vie",
    defaultValue: TARIFTYPE_LIFECYCLE.UPLOAD,
    maxLength: 10,
    choices: [
      {
        value: TARIFTYPE_LIFECYCLE.UPLOAD,
        desc: "Upload tarif simple",
      },
      {
        value: TARIFTYPE_LIFECYCLE.GENERIC,
        desc: "Cycle de vie générique",
      },
      {
        value: TARIFTYPE_LIFECYCLE.GENERIC_ADMIN,
        desc: "Cycle de vie générique, admins seulement",
      },
      {
        value: TARIFTYPE_LIFECYCLE.GENNOVAL,
        desc: "Cycle de vie générique sans validation",
      },
      {
        value: TARIFTYPE_LIFECYCLE.GENNOVAL_ADMIN,
        desc: "Cycle de vie générique sans validation, admins seulement",
      },
    ],
    priority: 520,
  })
  public lifecycle: TARIFTYPE_LIFECYCLE = TARIFTYPE_LIFECYCLE.UPLOAD;
  @charField({
    name: "zone",
    description: "Zone",
    defaultValue: TARIFTYPE_ZONE.FL,
    maxLength: 3,
    choices: [
      {
        value: TARIFTYPE_ZONE.FL,
        desc: "Fruits et légumes",
      },
      {
        value: TARIFTYPE_ZONE.SEA,
        desc: "Marée",
      },
      {
        value: TARIFTYPE_ZONE.G45,
        desc: "4/5eme gamme",
      },
    ],
    priority: 500,
  })
  public zone: TARIFTYPE_ZONE = TARIFTYPE_ZONE.FL;
  @foreignKeyField({
    name: "client",
    description: "ID Client",
    related: "Client",
    priority: -1,
  })
  public client!: number;
  @foreignKeyField({
    name: "member",
    description: "ID Adhérent",
    related: "Member",
    priority: -1,
  })
  public member!: number;
  @foreignKeyField({
    name: "storage",
    description: "ID Dépôt",
    related: "Storage",
    priority: -1,
  })
  public storage!: number;

  @foreignKeyField({
    name: "region",
    description: "ID Région",
    related: "GenericRegion",
    priority: -1,
  })
  public region!: number;

  @charField({
    name: "storages_mode",
    description: "Mode détails par dépôt",
    defaultValue: TARIFTYPE_STORAGE_MODE.SIMPLE,
    maxLength: 5,
    choices: [
      {
        value: TARIFTYPE_STORAGE_MODE.SIMPLE,
        desc: "Pas de détails par dépôt",
      },
      {
        value: TARIFTYPE_STORAGE_MODE.DISPO_PER_STORAGE,
        desc: "Infos de disponibilité par dépôt",
      },
      {
        value: TARIFTYPE_STORAGE_MODE.PRICE_PER_STORAGE,
        desc: "Prix par dépôt",
      },
      {
        value: TARIFTYPE_STORAGE_MODE.PRICE_DISPO_STORAGE,
        desc: "Tarif global, dispo par dépôt",
      },
    ],
    priority: 600,
  })
  public storages_mode: TARIFTYPE_STORAGE_MODE = TARIFTYPE_STORAGE_MODE.SIMPLE;

  @charField({
    name: "regions_mode",
    description: "Mode détails par région",
    defaultValue: TARIFTYPE_REGION_MODE.SIMPLE,
    maxLength: 5,
    choices: [
      {
        value: TARIFTYPE_REGION_MODE.SIMPLE,
        desc: "Pas de détails par région",
      },
      {
        value: TARIFTYPE_REGION_MODE.DISPO_PER_REGION,
        desc: "Infos de disponibilité par région",
      },
      {
        value: TARIFTYPE_REGION_MODE.PRICE_PER_REGION,
        desc: "Prix par région",
      },
      {
        value: TARIFTYPE_REGION_MODE.PRICE_DISPO_REGION,
        desc: "Tarif global, dispo par région",
      },
    ],
    priority: 600,
  })
  public regions_mode: TARIFTYPE_REGION_MODE = TARIFTYPE_REGION_MODE.SIMPLE;

  @charField({
    name: "base_interval",
    description: "Intervalle de base",
    defaultValue: TariftypeInterval.CS,
    maxLength: 3,
    choices: [
      {
        value: TariftypeInterval.W1,
        desc: "1 semaine",
      },
      {
        value: TariftypeInterval.W2,
        desc: "2 semaines",
      },
      {
        value: TariftypeInterval.M1,
        desc: "1 mois",
      },
      {
        value: TariftypeInterval.M2,
        desc: "2 mois",
      },
      {
        value: TariftypeInterval.M3,
        desc: "3 mois",
      },
      {
        value: TariftypeInterval.M6,
        desc: "6 mois",
      },
      {
        value: TariftypeInterval.M10,
        desc: "10 mois",
      },
      {
        value: TariftypeInterval.M12,
        desc: "12 mois",
      },
      {
        value: TariftypeInterval.CS,
        desc: "Non définie",
      },
    ],
    priority: 520,
  })
  public base_interval: TariftypeInterval = TariftypeInterval.CS;
  @textField({
    name: "rrule",
    description: "Règle de récurrence",
    defaultValue: "",
    priority: 519,
  })
  public rrule: string = "";
  @integerField({
    name: "delta_prepared",
    description: "Delta préparation",
    defaultValue: 84,
    priority: 518,
  })
  public delta_prepared: number = 84;
  @integerField({
    name: "delta_validated",
    description: "Delta validation",
    defaultValue: 60,
    priority: 517,
  })
  public delta_validated: number = 60;
  @integerField({
    name: "delta_published",
    description: "Delta publication",
    defaultValue: 36,
    priority: 516,
  })
  public delta_published: number = 36;
  @foreignKeyField({
    name: "asscom",
    description: "ID Ass. comm.",
    related: "User",
    priority: -1,
  })
  public asscom!: number;
  @foreignKeyField({
    name: "respcom",
    description: "ID Resp. comm.",
    related: "User",
    priority: -1,
  })
  public respcom!: number;
  @charField({
    name: "decondi_status",
    description: "État déconditionnement",
    defaultValue: TariftypeDecondiStatus.UNK,
    maxLength: 3,
    choices: [
      {
        value: TariftypeDecondiStatus.CTR,
        desc: "Négocié au contrat",
      },
      {
        value: TariftypeDecondiStatus.KPK,
        desc: "Au cas par cas",
      },
      {
        value: TariftypeDecondiStatus.NDC,
        desc: "Pas de déconditionnement",
      },
      {
        value: TariftypeDecondiStatus.UNK,
        desc: "Inconnu",
      },
    ],
    priority: 450,
  })
  public decondi_status: TariftypeDecondiStatus = TariftypeDecondiStatus.UNK;

  @decimalField({
    name: "decondi_pce",
    description: "Déconditionnement €/pièce ",
    defaultValue: 0,
    factor: 10000,
    priority: 450,
  })
  public decondi_pce: number = 0;

  @decimalField({
    name: "decondi_kg",
    description: "Déconditionnement €/kg",
    factor: 10000,
    defaultValue: 0,
    priority: 450,
  })
  public decondi_kg: number = 0;
  @detailsField({
    name: "parameters",
    description: "Paramètres",
    defaultValue: {},
    priority: -1,
  })
  public parameters: any = {};
  @charField({ name: "orig_name", description: "Nom orig", maxLength: 200 })
  public orig_name!: string;
  @charField({
    name: "status",
    description: "État",
    defaultValue: TARIFTYPE_STATUS.CURRENT,
    maxLength: 4,
    choices: [
      {
        value: TARIFTYPE_STATUS.FUTUR,
        desc: "Futur",
      },
      {
        value: TARIFTYPE_STATUS.CURRENT,
        desc: "Courant",
      },
      {
        value: TARIFTYPE_STATUS.ARCHIVED,
        desc: "Archivé",
      },
    ],
    priority: 210,
  })
  public status: TARIFTYPE_STATUS = TARIFTYPE_STATUS.CURRENT;

  @charField({
    name: "price_type",
    description: "Type de prix",
    maxLength: 3,
    choices: [
      {
        value: TARIFTYPE_PRICE_TYPE.BRUT,
        desc: "Brut",
      },
      {
        value: TARIFTYPE_PRICE_TYPE.NET,
        desc: "Net",
      },
      {
        value: TARIFTYPE_PRICE_TYPE.NET2,
        desc: "Net2",
      },

      {
        value: TARIFTYPE_PRICE_TYPE.CUSTOM,
        desc: "Custom",
      },
    ],
    priority: 380,
  })
  public price_type?: TARIFTYPE_PRICE_TYPE;

  @charField({
    name: "brut_to_net_op",
    description: "Opération brut vers net",
    maxLength: 50,
    priority: 390,
  })
  public brut_to_net_op: string = "";

  @charField({
    name: "brut_to_net2_op",
    description: "Opération brut vers net2",
    maxLength: 50,
    priority: 390,
  })
  public brut_to_net2_op: string = "";

  @charField({
    name: "custom_column_1_title",
    description: "Titre colonne specifique 1",
    maxLength: 150,
    priority: 390,
  })
  public custom_column_1_title: string = "";

  @charField({
    name: "custom_column_1_op",
    description: "Opération colonne specifique 1",
    maxLength: 50,
    priority: 390,
  })
  public custom_column_1_op: string = "";

  @charField({
    name: "ref_price_op",
    description: "Calcul prix de référence",
    maxLength: 50,
    priority: 390,
  })
  public ref_price_op: string = "";

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -1,
  })
  public orig_id!: number;

  @manyToManyField({
    name: "pilotes",
    description: "IDs pilotes",
    defaultValue: [],
    related: "User",
    priority: -1,
  })
  public pilotes: number[] = [];
  @manyToManyField({
    name: "starred",
    description: "IDs users starred",
    defaultValue: [],
    related: "User",
    priority: -1,
  })
  public starred: number[] = [];
  @manyToManyField({
    name: "notified",
    description: "IDs users notif",
    defaultValue: [],
    related: "User",
    priority: -1,
  })
  public notified: number[] = [];
  @booleanField({
    name: "user_starred",
    description: "Préféré ?",
    readonly: true,
    priority: -1,
  })
  public user_starred?: boolean;
  @booleanField({
    name: "user_notified",
    description: "Notifié ?",
    readonly: true,
    priority: -1,
  })
  public user_notified?: boolean;
  @detailsField({
    name: "user_level",
    description: "Permissions utilisateur",
    deserialize: (d) => d,
    priority: -1,
  })
  public user_level: TARIFTYPE_USER_LEVEL[] = [];

  @charField({
    name: "export_template",
    description: "Export spécifique",
    required: false,
    maxLength: 300,
    priority: 350,
  })
  public export_template?: string;

  // price_precision
  @integerField({
    name: "price_precision",
    description: "Décimales prix",
    defaultValue: 2,
    priority: 390,
  })
  public price_precision: number = 2;

  // export inactives
  @charField({
    name: "export_inactives",
    description: "Exporter les articles inactifs ?",
    choices: [
      {
        value: TARIFTYPE_EXPORT_INACTIVES_MODE.NO,
        desc: "Ne pas exporter les articles inactifs",
      },
      {
        value: TARIFTYPE_EXPORT_INACTIVES_MODE.OUT_QUOTE,
        desc: "Exporter les articles inactifs à la sortie de cotation",
      },
      {
        value: TARIFTYPE_EXPORT_INACTIVES_MODE.ALL,
        desc: "Exporter tous les articles inactifs",
      },
    ],
    required: false,
    priority: 390,
  })
  public export_inactives: TARIFTYPE_EXPORT_INACTIVES_MODE =
    TARIFTYPE_EXPORT_INACTIVES_MODE.NO;

  @booleanField({
    name: "client_data_link",
    description: "Liaison trame client ?",
    required: false,
    priority: 390,
  })
  public client_data_link: boolean = false;

  public get lifecycle_have_nego(): boolean {
    return (
      this.lifecycle === TARIFTYPE_LIFECYCLE.GENERIC ||
      this.lifecycle === TARIFTYPE_LIFECYCLE.GENERIC_ADMIN
    );
  }

  public get _display(): string {
    return `[${this.code}] ${this.name}`;
  }

  public have_level(level: TARIFTYPE_USER_LEVEL): boolean {
    return this.user_level?.includes(level);
  }
}
