import { Print, RS } from '@vivalya/lvadg';
import { RoutesConfig } from '@solidev/data';

export const FRONT_PRINTS_ROUTES_NAMES = ['prints', 'prints_print'] as const;

export const FRONT_PRINTS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_PRINTS_ROUTES_NAMES)[number]> = {
  prints: {
    name: 'prints',
    params: [],
    route: () => ['/', RS.prints],
  },
  prints_print: {
    name: 'prints_print',
    params: ['print'],
    route: (p: Print) => ['/', RS.prints, p.id],
  },
};
