import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Presentation } from '../presentation';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-presentation-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './presentation-manage.component.pug',
  styleUrls: ['./presentation-manage.component.sass'],
})
export class PresentationManageComponent {
  @Input() public presentation!: Presentation;

  constructor() {}
}
