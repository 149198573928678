import { InjectionToken } from "@angular/core";
import { PresetTemplate } from "./rstypes/PresetTemplate";
import { ProviderDataName } from "./rstypes/ProviderDataName";
import { Storage } from "../structures/storage/storage";
import { Producer } from "../structures/producer/producer";
import { Client } from "../structures/client/client";
import { Member } from "../structures/member/member";
import { Resto } from "../structures/resto/resto";
import { ProviderData } from "./rstypes/ProviderData";
import { FilterData } from "@solidev/data";
import { LOCAL_FILTERS } from "../catalog/product/product.base";
import { Provider } from "../structures/provider/provider";

export interface PrintSettingsItem {
  label: string;
  provider: ProviderDataName;
  type: "single" | "selection" | "queryset";
  actions: ("button" | "quick_print" | "add_to_print")[];
  template: PresetTemplate;
  limit: number;
}

export interface PrintContext {
  storage?: Storage;
  producer?: Producer;
  provider?: Provider;
  client?: Client;
  member?: Member;
  resto?: Resto;
  local?: LOCAL_FILTERS;
}

export const intoProviderData = (context: PrintContext): ProviderData[] => {
  const pd: ProviderData[] = [];
  const qs: Record<string, string> = {};
  if (context.local) {
    qs["local"] = context.local;
  }
  if (context.storage) {
    pd.push({
      type: "storage",
      id: context.storage.id,
      description: context.storage._display,
      queryset: qs,
    });
  }
  if (context.producer) {
    pd.push({
      type: "producer",
      id: context.producer.id,
      description: context.producer._display,
      queryset: qs,
    });
  }
  if (context.client) {
    pd.push({
      type: "client",
      id: context.client.id,
      description: context.client._display,
      queryset: qs,
    });
  }
  if (context.member) {
    pd.push({
      type: "member",
      id: context.member.id,
      description: context.member._display,
      queryset: qs,
    });
  }
  if (context.resto) {
    pd.push({
      type: "resto",
      id: context.resto.id,
      description: context.resto._display,
      queryset: qs,
    });
  }
  // FIXME: provider is not a valid type, add it to ProviderDataName
  // if (context.provider) {
  //   pd.push({
  //     type: "provider",
  //     id: context.provider.id,
  //     description: context.provider.name,
  //     queryset: qs,
  //   });
  // }
  return pd;
};

/**
 * Ensure that all values are string from filterdata.
 * @param fd FilterData
 * @returns FilterData with all values as string
 */
export const strictStringFilter = (
  fd: FilterData,
): Record<string, string> => {
  const f: Record<string, string> = {};
  for (const k in fd) {
    if (fd[k] !== undefined) {
      f[k] = `${fd[k]}`;
    }
  }
  return f;
};

export type PrintSettings = PrintSettingsItem[];
/// Injectable for printer url
export const PRINT_API_URL = new InjectionToken<string>("print.api.url");
