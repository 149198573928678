import { Component, inject, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";
import { Product } from "../../../../../models/catalog/product/product";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { PRODUCT_ROUTES } from "../menu";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProductNavComponent } from "../_nav/product-nav.component";
import { Producer } from "../../../../../models/structures/producer/producer";
import { Storage } from "../../../../../models/structures/storage/storage";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { ProductDetailsComponent } from "../../../../../models/catalog/product/product-details/product-details.component";
import { QuickprintButtonComponent } from "../../../../../models/layouts/print/quickprint-button/quickprint-button.component";
import { PRODUCT_PRINT_SETTINGS } from "../../../../../models/catalog/product/product.print";
import { IocodeButtonComponent } from "../../../../../models/lvadgio/iocode/iocode-button/iocode-button.component";
import { PrintContext } from "../../../../../models/layouts/print-settings";
import { ProducerService } from "../../../../../models/structures/producer/producer.service";
import { first } from "rxjs";
import { LOCAL_FILTERS } from "../../../../../models/catalog/product/product.base";

export interface ProductDisplayViewParams extends BaseRouteParams {
  p_menu: PRODUCT_ROUTES;
  local: LOCAL_FILTERS;
  producer_detail_route?: RouteConfigItem;
}

export interface ProductDisplayViewData extends ProductDisplayViewParams {
  product: Product;
  producer?: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-product-display-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProductNavComponent,
    IconComponent,
    ProductDetailsComponent,
    QuickprintButtonComponent,
    IocodeButtonComponent,
  ],
  templateUrl: "./product-display-view.component.pug",
  styleUrls: ["./product-display-view.component.sass"],
})
export class ProductDisplayViewComponent extends BaseDetailViewComponent<
  ProductDisplayViewData,
  Product
> {
  public producer_detail_route?: Link<Producer>;
  public producer = signal<Producer | undefined>(undefined);
  public print_settings = PRODUCT_PRINT_SETTINGS;
  public print_context: PrintContext = {};
  private _producers = inject(ProducerService);

  public override setRouteData(data: ProductDisplayViewData) {
    super.setRouteData(data);
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(
        data.producer_detail_route,
        data,
        this._router,
      );
    }
    if (data.producer) {
      this.print_context.producer = data.producer;
      this.producer.set(data.producer);
    } else {
      this._producers
        .fetch(data.product.producer, { params: { local: data.local } })
        .pipe(first())
        .subscribe((producer) => {
          this.print_context.producer = producer;
          this.producer.set(producer);
        });
    }
    if (data.storage) {
      this.print_context.storage = data.storage;
    }
    if (data.local) {
      this.print_context.local = data.local;
    }
  }
}
