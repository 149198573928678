import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-flags-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flags-display.component.pug',
  styleUrls: ['./flags-display.component.sass'],
})
export class FlagsDisplayComponent implements OnChanges {
  @Input() public item?: { flags: string[] };
  @Input() public exclude: string[] = [];
  @Input() public humanize: Record<string, { desc: string; class?: string }> = {};

  public flags: string[] = [];

  constructor() {}

  ngOnChanges(): void {
    this.flags = [];
    if (this.item && this.item.flags) {
      for (const f of this.item.flags) {
        if (this.exclude.indexOf(f) !== -1) {
          continue;
        }
        this.flags.push(f);
      }
    }
  }

  public getName(f: string): string {
    if (this.humanize && this.humanize[f]) {
      return this.humanize[f].desc;
    }
    return f;
  }

  public getClass(f: string): string {
    if (this.humanize && this.humanize[f] && this.humanize[f].class) {
      return 'bg-' + (this.humanize[f].class || 'primary');
    }
    return 'bg-primary';
  }
}
