// Interceptor for all api request adding the current page url as X-ROUTE-URL header
import { inject, Injectable } from "@angular/core";
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { DATA_API_URL } from "@solidev/data";

@Injectable({
  providedIn: "root",
})
export class UrlInterceptor implements HttpInterceptor {
  private API_URL = inject(DATA_API_URL);

  intercept(req: HttpRequest<never>, next: HttpHandler) {
    if (!req.url.startsWith(this.API_URL)) {
      return next.handle(req);
    }
    const headers = req.headers.append("X-ROUTE-URL", window.location.href);
    const newReq = req.clone({
      headers,
    });

    return next.handle(newReq);
  }
}
