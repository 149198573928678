import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Earticle } from '../earticle';
import { EarticleService } from '../earticle.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-earticle-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './earticle-create.component.pug',
  styleUrls: ['./earticle-create.component.sass'],
})
export class EarticleCreateComponent implements OnInit {
  @Output() public created = new EventEmitter<Earticle>();
  @Output() public cancelled = new EventEmitter<void>();
  public createForm = new FormGroup({});

  public earticle!: Earticle;

  constructor(
    private _earticles: EarticleService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.earticle = this._earticles.create();
  }

  public async save() {
    this.earticle.fromJson(
      {
        ...this.createForm.value,
      },
      { partial: true },
    );
    try {
      await firstValueFrom(this.earticle.save({ updateModel: true }));
      this.created.emit(this.earticle);
      this._msgs.success('Article externe créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
