import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Usergrouplink } from '../usergrouplink';
import { UsergrouplinkService } from '../usergrouplink.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GroupDisplayComponent } from '../../group/group-display/group-display.component';

@Component({
  selector: 'lvadg-usergrouplink-list',
  standalone: true,
  templateUrl: './usergrouplink-list.component.pug',
  styleUrls: ['./usergrouplink-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, GroupDisplayComponent],
})
export class UsergrouplinkListComponent extends ModellistComponent<Usergrouplink> {
  constructor(
    coll: UsergrouplinkService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
