import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';

export enum ORDERPROCESS_PERMISSION {
  ADMIN = 'ADMIN',
  WRITE = 'WRITE',
  STORAGE = 'STORAGE',
  READ = 'READ',
}

export class OrderprocessPermissionBase extends DataModel {
  static override readonly __name: string = 'OrderprocessPermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    choices: [
      {
        value: ORDERPROCESS_PERMISSION.READ,
        desc: 'Consultation des commandes',
      },
      {
        value: ORDERPROCESS_PERMISSION.WRITE,
        desc: 'Création / saisie de commande',
      },
      {
        value: ORDERPROCESS_PERMISSION.STORAGE,
        desc: 'Création / saisie de commande par dépôt',
      },
      {
        value: ORDERPROCESS_PERMISSION.ADMIN,
        desc: 'Administrateur du process de commande',
      },
    ],
  })
  public permission!: ORDERPROCESS_PERMISSION;

  @foreignKeyField({
    name: 'target',
    description: 'ID Process',
    related: 'OrderProcess',
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID User',
    related: 'User',
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
  })
  public group!: number;
}
