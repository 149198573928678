import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { RnmBase } from '../rnm.base';

@Component({
  selector: 'lvadg-rnm-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnm-display.component.pug',
  styleUrls: ['./rnm-display.component.sass'],
})
export class RnmDisplayComponent implements OnInit {
  public rnm$ = new ReplaySubject<RnmBase>(1);

  constructor() {}

  @Input()
  public set rnm(rnm: RnmBase) {
    this.rnm$.next(rnm);
  }

  ngOnInit(): void {}
}
