import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessService } from '../../../models/orders/orderprocess/orderprocess.service';
import { OrderprocessstorageService } from '../../../models/orders/orderprocessstorage/orderprocessstorage.service';
import { OrderprocessarticleService } from '../../../models/orders/orderprocessarticle/orderprocessarticle.service';
import { OrderService } from '../../../models/orders/order/order.service';
import { Observable } from 'rxjs';
import { Orderprocess } from '../../../models/orders/orderprocess/orderprocess';
import { Orderprocessstorage } from '../../../models/orders/orderprocessstorage/orderprocessstorage';
import {
  ChoicePipe,
  DataMessageService,
  FactorcPipe,
  FactorPipe,
  Link,
} from '@solidev/data';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { OrderListComponent } from '../../../models/orders/order/order-list/order-list.component';
import { Order } from '../../../models/orders/order/order';
import { Router } from '@angular/router';
import { OrderprocessActionService } from '../../../models/orders/orderprocess/orderprocess-action.service';

@Component({
  selector: 'lvadg-order-create-wizard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FactorPipe,
    ChoicePipe,
    FactorcPipe,
    OrderListComponent,
  ],
  templateUrl: './order-create-wizard.component.pug',
  styleUrls: ['./order-create-wizard.component.sass'],
})
export class OrderCreateWizardComponent implements OnInit {
  /** Order edit route */
  @Input() public order_edit_route?: Link<Order>;
  /** Created signal */
  @Output() public created = new EventEmitter<Order>();

  /** Observable of user allowed order processes */
  public processes$!: Observable<Orderprocess[]>;
  /** Observable of user editable storages */
  public storages$?: Observable<Orderprocessstorage[]>;
  /** Observable of current valid delivery dates */
  public deliverydates$?: Observable<Date[]>;

  /** Order form content */
  public orderForm = new FormGroup({
    curprocess: new FormControl<Orderprocess | null | undefined>(
      null,
      Validators.required
    ),
    curstorage: new FormControl<Orderprocessstorage | null | undefined>(
      {
        value: null,
        disabled: true,
      },
      Validators.required
    ),
    curdelivery: new FormControl<Date | null | undefined>(
      {
        value: null,
        disabled: true,
      },
      Validators.required
    ),
  });

  constructor(
    private _processes: OrderprocessService,
    private _pstorages: OrderprocessstorageService,
    private _particles: OrderprocessarticleService,
    private _opsa: OrderprocessActionService,
    private _orders: OrderService,
    private _router: Router,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    // List of processes with user level
    this.processes$ = this._processes.list({
      fields: ['id', 'user_level', 'name'].join(','),
    });

    // Current process change
    // Launches storages queryset linked to curprocess value
    const storages$ = this._pstorages.queryset();
    this.orderForm.controls.curprocess.valueChanges.subscribe((cp) => {
      // Reset storage and delivery date
      this.orderForm.controls.curstorage.reset({ value: null, disabled: true });
      // On current process set, enable storage form control, and load storages
      if (cp) {
        this.orderForm.controls.curstorage.enable();
        this.storages$ = storages$.filter({ process: cp.id }).get();
      }
    });

    // Curdelivery change
    this.orderForm.controls.curstorage.valueChanges.subscribe((cs) => {
      // Reset delivery
      this.orderForm.controls.curdelivery.reset({
        value: null,
        disabled: true,
      });

      // On current storage set, get delivery dates and enable form control for dates
      if (cs) {
        this.orderForm.controls.curdelivery.enable();
        this.deliverydates$ = this.orderForm.value.curstorage!.action<
          Orderprocessstorage,
          Date[]
        >('GET', 'allowed_delivery_dates');
      }
    });
  }

  /**
   * Order creation.
   */
  public async createOrder() {
    const process = this.orderForm.value.curprocess || null;
    const storage = this.orderForm.value.curstorage || null;
    const delivery = this.orderForm.value.curdelivery || null;

    if (!(process && storage && delivery)) {
      return;
    }
    const res = await this._opsa.action(process, 'create_order', {
      pstorage: storage.id,
      delivery: delivery,
    });
    if (res.success && res.order) {
      if (this.order_edit_route) {
        this.order_edit_route
          .navigate(
            { order: res.order, orderprocess: process as any },
            this._router
          )
          .then();
      } else {
        this._msgs.warning(
          `Vous pouvez désormais éditer la commande ${res.order?.code}`
        );
      }
      this.created.emit(res.order);
    }
  }
}
