
<ng-container *ngIf="earticle">
  <div class="row g-3">
    <div class="col-12 col-lg-6">
      <div class="card h-100">
        <div class="card-header"><i class="bi bi-info-circle-fill me-2"></i>Infos article</div>
        <div class="card-body">
          <dl class="row g-3">
            <div class="col-12 text-secondary">Infos article</div>
            <data-dispedit class="col-4" [model]="earticle" field="id" [editable]="false" mode="dd">ID</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="created" [editable]="false" mode="dd">Date de création</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="updated" [editable]="false" mode="dd">Date de MAJ</data-dispedit>
            <div class="col-12 text-secondary border-top">Type et liens</div>
            <data-dispedit class="col-6" [model]="earticle" field="type" [editable]="true" mode="dd">Type</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="client" [editable]="true" mode="dd" [collection]="clients$" *ngIf="earticle.type===EAT.CLIENT || earticle.type===EAT.CLIENTBULK || earticle.type===EAT.LVADG">Client</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="storage" [editable]="true" mode="dd" *ngIf="earticle.type===EAT.STORAGE" [collection]="storages$">Dépôt</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="member" [editable]="true" mode="dd" *ngIf="earticle.type===EAT.MEMBER" [collection]="members$">Adhérent</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="provider" [editable]="true" mode="dd" *ngIf="earticle.type===EAT.PROVIDER" [collection]="providers$">Fournisseur</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="collection" [editable]="true" mode="dd" *ngIf="earticle.type===EAT.GROUP" [collection]="collections$">Collection</data-dispedit>
            <div class="col-12 text-secondary border-top">Libellé et codes</div>
            <data-dispedit class="col-8" [model]="earticle" field="libelle" [editable]="true" mode="dd">Libellé</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="libelle2" [editable]="true" mode="dd">Libellé 2</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="code" [editable]="true" mode="dd">Code</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="icode" [editable]="true" mode="dd">Autre code</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ean13" [editable]="true" mode="dd">Code barre</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="card h-100">
        <div class="card-header"><i class="bi bi-truck me-2"></i>Conditionnement et unités</div>
        <div class="card-body">
          <dl class="row g-3">
            <div class="col-12 text-secondary">Conditionnement et unités</div>
            <data-dispedit class="col-4" [model]="earticle" field="uf" [editable]="true" mode="dd">Unité de facturation (complète)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="uf_value" [editable]="true" mode="dd">UF (valeur)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="uf_unit" [editable]="true" mode="dd">UF (unité)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="uc" [editable]="true" mode="dd">Unité de commande (complète)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="uc_value" [editable]="true" mode="dd">UC (valeur)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="uc_unit" [editable]="true" mode="dd">UC (unité)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ul" [editable]="true" mode="dd">Unité de livraison (complète)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ul_value" [editable]="true" mode="dd">UL (valeur)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ul_unit" [editable]="true" mode="dd">UL (unité)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ucond" [editable]="true" mode="dd">Unité de conditionnement / consommation</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ucond_value" [editable]="true" mode="dd">UCO (valeur)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ucond_unit" [editable]="true" mode="dd">UCO (unité)</data-dispedit>
            <div class="col-12 text-secondary border-top">Poids égoutté</div>
            <data-dispedit class="col-4" [model]="earticle" field="pe" [editable]="true" mode="dd">Poids égoutté (complet)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="pe_value" [editable]="true" mode="dd">PE (valeur)</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="pe_unit" [editable]="true" mode="dd">PE (unité)</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="card h-100">
        <div class="card-header"><i class="bi bi-folder me-2"></i>Classification et caractéristiques</div>
        <div class="card-body">
          <dl class="row g-3">
            <div class="col-12 text-secondary">Classification</div>
            <data-dispedit class="col-3" [model]="earticle" field="gamme" [editable]="true" mode="dd">Gamme / famille</data-dispedit>
            <data-dispedit class="col-3" [model]="earticle" field="code_gamme" [editable]="true" mode="dd">Code gamme / famille</data-dispedit>
            <data-dispedit class="col-3" [model]="earticle" field="sgamme" [editable]="true" mode="dd">Sous-gamme / sous-famille</data-dispedit>
            <data-dispedit class="col-3" [model]="earticle" field="code_sgamme" [editable]="true" mode="dd">Code sous-gamme / sous-famille</data-dispedit>
            <div class="col-12 text-secondary border-top">Catégorisation</div>
            <data-dispedit class="col-4" [model]="earticle" field="typologie" [editable]="true" mode="dd">Typologie produit</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="categorie" [editable]="true" mode="dd">Catégorie</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="calibre" [editable]="true" mode="dd">Calibre</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="origine" [editable]="true" mode="dd">Origine</data-dispedit>
            <data-dispedit class="col-6" [model]="earticle" field="labels" [editable]="true" mode="dd">Labels</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="card h-100">
        <div class="card-header"><i class="bi bi-info-circle-fill me-2"></i>Infos complémentaires</div>
        <div class="card-body">
          <dl class="row g-3">
            <div class="col-12 text-secondary">Infos complémentaires</div>
            <data-dispedit class="col-4" [model]="earticle" field="ucuf" [editable]="true" mode="dd">Ratio uc/uf</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="ucul" [editable]="true" mode="dd">Ratio uc/ul</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="nego_unit" [editable]="true" mode="dd">Unité de négociation</data-dispedit>
            <div class="col-12 text-secondary border-top">Infos diverses</div>
            <data-dispedit class="col-4" [model]="earticle" field="tax" [editable]="true" mode="dd">Taxes</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="marque" [editable]="true" mode="dd">Marque</data-dispedit>
            <data-dispedit class="col-4" [model]="earticle" field="conservation" [editable]="true" mode="dd">Conservation</data-dispedit>
            <div class="col-12 text-secondary border-top">Flags</div>
            <data-flags class="col-12" [model]="earticle" [editable]="false">Flags</data-flags>
          </dl>
        </div>
      </div>
    </div>
  </div>
</ng-container>