
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { User } from "./user";

@Injectable({
  providedIn: 'root'
})
export class UserService extends Collection<User> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/users", User);
  }
}
