import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OfferResto } from "../offer-resto";
import { OfferRestoService } from "../offer-resto.service";
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { OfferDisplayComponent } from "../../offer/offer-display/offer-display.component";
import { RestoDisplayComponent } from "../../../structures/resto/resto-display/resto-display.component";
import { Resto } from "../../../structures/resto/resto";
import { Offer } from "../../offer/offer";
import { Contact } from "../../../users/contact/contact";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { OFFERRESTO_STATUSES } from "../offer-resto.base";
import { RelationDisplayComponent } from "../../../structures/relation/relation-display/relation-display.component";
import { Relation } from "../../../structures/relation/relation";
import { Storage } from "../../../structures/storage/storage";
import { Client } from "../../../structures/client/client";
import { ClientDisplayComponent } from "../../../structures/client/client-display/client-display.component";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { Location } from "../../../locations/location/location";

@Component({
  selector: "lvadg-offer-resto-list",
  standalone: true,
  templateUrl: "./offer-resto-list.component.pug",
  styleUrls: ["./offer-resto-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    OfferDisplayComponent,
    RestoDisplayComponent,
    ClientDisplayComponent,
    LocationDisplayComponent,
    RelationDisplayComponent,
    IconComponent,
    PChoicePipe,
  ],
})
export class OfferRestoListComponent extends ModellistComponent<OfferResto> {
  @Input() public resto_detail_route?: Link<Resto>;
  @Input() public offer_detail_route?: Link<Offer>;
  @Input() public contact_detail_route?: Link<Contact>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public relation_detail_route?: Link<Relation>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public location_detail_route?: Link<Location>;
  public readonly ORS = OFFERRESTO_STATUSES;

  constructor(
    coll: OfferRestoService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
      ],
    };
  }

  public override getRowClasses(row: OfferResto): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === OFFERRESTO_STATUSES.INACTIVE) {
      cls.push("table-secondary");
    }
    return cls;
  }
}
