
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferNotificationDestination } from '../offer-notification-destination';
@Component({
  selector: 'lvadg-offer-notification-destination-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-notification-destination-manage.component.pug',
  styleUrls: ['./offer-notification-destination-manage.component.sass']
})
export class OfferNotificationDestinationManageComponent implements OnInit {
  @Input() public offernotificationdestination?: OfferNotificationDestination;

  constructor() { }

  ngOnInit(): void {
  }

}
