
<ng-container *ngIf="data$|async; let data">
  <!-- lvadg-header-action([title]="data.tariftype._display", level="h1", ri="tarifs")-->
  <ul class="nav nav-tabs nav-fill">
    <!-- Résumé (à faire)-->
    <!--li.nav-item(*ngIf="tariftype_detail_route")-->
    <!--  a.nav-link([routerLink]="tariftype_detail_route.route({tariftype: data.tariftype})",-->
    <!--    [routerLinkActiveOptions]="{exact: true}",-->
    <!--    routerLinkActive="active") Résumé-->
    <!-- Tarifs-->
    <li class="nav-item" *ngIf="tarifs_detail_route &amp;&amp; data.tariftype.have_level(TTUL.read)"><a class="nav-link" [routerLink]="tarifs_detail_route.route({tariftype: data.tariftype})" routerLinkActive="active">Tarifs</a></li>
    <!-- Paramétrages-->
    <li class="nav-item" *ngIf="tariftype_manage_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_manage_route.route({tariftype: data.tariftype})" routerLinkActive="active">Paramétrage</a></li>
    <!-- Documents & modèles-->
    <li class="nav-item" *ngIf="tariftype_documents_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_documents_route.route({tariftype: data.tariftype})" routerLinkActive="active">Documents & modèles</a></li>
    <!-- Liaisons-->
    <li class="nav-item" *ngIf="tariftype_relations_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_relations_route.route({tariftype: data.tariftype})" routerLinkActive="active">Liaisons</a></li>
    <!-- Dépôts-->
    <li class="nav-item" *ngIf="tariftype_storages_route &amp;&amp; data.tariftype.have_level(TTUL.admin) &amp;&amp; data.tariftype.storages_mode!==TTSM.SIMPLE"><a class="nav-link" [routerLink]="tariftype_storages_route.route({tariftype: data.tariftype})" routerLinkActive="active">Dépôts</a></li>
    <!-- Articles-->
    <li class="nav-item" *ngIf="tariftype_atts_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_atts_route.route({tariftype: data.tariftype})" routerLinkActive="active">Articles</a></li>
    <!-- Catalogue et ajout articles-->
    <li class="nav-item" *ngIf="tariftype_catalog_route &amp;&amp; data.tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tariftype_catalog_route.route({tariftype: data.tariftype})" routerLinkActive="active">Catalogue</a></li>
    <!-- Destinataires-->
    <li class="nav-item" *ngIf="tariftype_destinations_route &amp;&amp; (data.tariftype.have_level(TTUL.admin) || (data.tariftype.have_level(TTUL.pilote) &amp;&amp; data.tariftype.lifecycle_have_nego))"><a class="nav-link" [routerLink]="tariftype_destinations_route.route({tariftype: data.tariftype})" routerLinkActive="active">Destinataires</a></li>
    <!-- Notifications-->
    <li class="nav-item" *ngIf="tariftype_notifications_route &amp;&amp; data.tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tariftype_notifications_route.route({tariftype: data.tariftype})" routerLinkActive="active">Notifications</a></li>
    <!-- Suivi des envois-->
    <li class="nav-item" *ngIf="tariftype_notification_logs_route &amp;&amp; data.tariftype.have_level(TTUL.pilote)"><a class="nav-link" [routerLink]="tariftype_notification_logs_route.route({tariftype: data.tariftype})" routerLinkActive="active">Suivi des envois</a></li>
    <!-- Permissions-->
    <li class="nav-item" *ngIf="tariftype_permissions_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_permissions_route.route({tariftype: data.tariftype})" routerLinkActive="active">Permissions</a></li>
    <!-- Historique tarif-->
    <li class="nav-item" *ngIf="tariftype_tarifs_logs_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_tarifs_logs_route.route({tariftype: data.tariftype})" routerLinkActive="active">Historique tarif</a></li>
    <!-- Historique prix-->
    <li class="nav-item" *ngIf="tariftype_prices_logs_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_prices_logs_route.route({tariftype: data.tariftype})" routerLinkActive="active">Historique prix</a></li>
    <!-- Cycle de vie-->
    <li class="nav-item" *ngIf="tariftype_lifecycle_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_lifecycle_route.route({tariftype: data.tariftype})" routerLinkActive="active">Cycle de vie</a></li>
    <!-- Mapping client-->
    <li class="nav-item" *ngIf="tariftype_mappings_route &amp;&amp; data.tariftype.have_level(TTUL.admin)"><a class="nav-link" [routerLink]="tariftype_mappings_route.route({tariftype: data.tariftype})" routerLinkActive="active">Mapping client</a></li>
  </ul>
</ng-container>