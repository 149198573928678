import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Rnmcotation } from '../rnmcotation';
import { RnmcotationService } from '../rnmcotation.service';
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NgStringPipesModule } from 'ngx-pipes';
import { RnmmarcheBase } from '../../rnmmarche/rnmmarche.base';
import { RnmmarcheService } from '../../rnmmarche/rnmmarche.service';

@Component({
  selector: 'lvadg-rnmcotation-list',
  standalone: true,
  templateUrl: './rnmcotation-list.component.pug',
  styleUrls: ['./rnmcotation-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, NgStringPipesModule],
})
export class RnmcotationListComponent extends ModellistComponent<Rnmcotation> {
  @Input() public rnmmarche_detail_route?: Link<RnmmarcheBase>;

  constructor(
    coll: RnmcotationService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _rnmmarches: RnmmarcheService
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'marches'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListAutocompleteMultiFilter({
          name: 'marches',
          label: 'Filtrer par marché(s)',
          field: 'marches',
          collection: this._rnmmarches,
        }),
      ],
    };
  }
}
