import { CiqualValueBase } from './ciqual-value.base';
import { computedField, detailsField } from '@solidev/data';
import { CiqualColumn } from '../ciqual-column/ciqual-column';

export class CiqualValue extends CiqualValueBase {
  static override readonly __name: string = 'CiqualValue';
  // @detailsField({
  //   model: CiqualAlimBase,
  //   description: 'Aliment',
  //   readonly: true,
  // })
  // public alim_details?: CiqualAlimBase;

  @detailsField({
    model: CiqualColumn,
    description: 'Colonne',
    readonly: true,
    priority: 600,
  })
  public column_details?: CiqualColumn;

  @computedField({
    description: 'Unité',
    readonly: true,
    priority: 400,
  })
  public get column_unit(): string {
    return this.column_details?.unit || '??';
  }

  @computedField({
    description: 'Grandeur',
    readonly: true,
    priority: 400,
  })
  public get column_name(): string {
    return this.column_details?.name || '??';
  }
}
