import { Injectable } from "@angular/core";
import { DataMessageService } from "@solidev/data";
import { firstValueFrom } from "rxjs";
import { getErrorContext } from "../../../components/utils/errormessage";
import { ArticleService } from "./article.service";

export interface ArticlesActionResult {
  message: string;
  data: unknown;
  success: boolean;
}

interface ArticlesActionQualityComputeLibelleStatsParams {
  action: "quality_compute_libelle_stats";
}

interface ArticlesActionExportParams {
  action: "export_articles";
  format: "xlsx" | "csv";
}

export type ArticlesActionParams =
  | ArticlesActionQualityComputeLibelleStatsParams
  | ArticlesActionExportParams;

@Injectable({
  providedIn: "root",
})
export class ArticlesActionService {
  constructor(
    private _arts: ArticleService,
    private _msgs: DataMessageService,
  ) {}

  public async action(
    action: ArticlesActionParams,
    messages: Record<string, string> = {},
  ): Promise<ArticlesActionResult> {
    try {
      const res = await firstValueFrom(
        this._arts.action<ArticlesActionResult>(null, "POST", "action", {
          body: {
            ...action,
          },
        }),
      );
      if (messages["success"]) {
        this._msgs.success(messages["success"]);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { message: message, data: undefined, success: false };
    }
  }
}
