import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Genericregion } from '../../../../models/locations/genericregion/genericregion';

export interface GenericregionDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
}

export interface GenericregionDetailViewData extends GenericregionDetailViewParams {
  genericregion: Genericregion;
}
