// Structures public api
//  storages
export * from "./storages/list/storage-list-view.component";
export * from "./storages/detail/menu";
export * from "./storages/detail/storage.routes";
export * from "./storages/detail/display/storage-display-view.component";
export * from "./storages/detail/relations/storage-relations-view.component";
export * from "./storages/detail/producers/storage-producers-view.component";
export * from "./storages/detail/products/storage-products-view.component";
export * from "./storages/detail/offers/storage-offers-view.component";
export * from "./storages/detail/stats/storage-stats-view.component";
export * from "./storages/detail/sales/storage-sales-view.component";
export * from "./storages/detail/edit/storage-edit-view.component";
export * from "./storages/detail/cdatas/storage-cdatas-view.component";

// producers
export * from "./producers/list/producer-list-view.component";
export * from "./producers/detail/display/producer-display-view.component";
export * from "./producers/detail/relations/producer-relations-view.component";
export * from "./producers/detail/products/producer-products-view.component";
export * from "./producers/detail/edit/producer-edit-view.component";
export * from "./producers/detail/offers/producer-offers-view.component";
export * from "./producers/detail/menu";
export * from "./producers/detail/producer.routes";
// clients
export * from "./clients/list/client-list-view.component";
export * from "./clients/detail/client-detail-view.component";
export * from "./clients/detail/menu";
export * from "./clients/detail/client.routes";
export * from "./clients/detail/display/client-display-view.component";
export * from "./clients/detail/restos/client-restos-view.component";
export * from "./clients/detail/offers/client-offers-view.component";
export * from "./clients/detail/sales/client-sales-view.component";
export * from "./clients/detail/stats/client-stats-view.component";
export * from "./clients/detail/edit/client-edit-view.component";
export * from "./clients/detail/users/client-users-view.component";
export * from "./clients/detail/tariftypes/client-tariftypes-view.component";
export * from "./clients/detail/earticles/client-earticles-view.component";
export * from "./clients/detail/atts/client-atts-view.component";

// restos
export * from "./restos/list/resto-list-view.component";
export * from "./restos/detail/menu";
export * from "./restos/detail/resto.routes";
export * from "./restos/detail/display/resto-display-view.component";
export * from "./restos/detail/edit/resto-edit-view.component";
export * from "./restos/detail/relations/resto-relations-view.component";
export * from "./restos/detail/offers/resto-offers-view.component";
export * from "./restos/detail/sales/resto-sales-view.component";
// providers
export * from "./providers/list/provider-list-view.component";
export * from "./providers/detail/provider-detail-view.component";
export * from "./providers/documents/list/provider-document-list-view.component";
export * from "./providers/documents/detail/provider-document-detail-view.component";
export * from "./providers/detail/display/provider-display-view.component";
export * from "./providers/detail/articles/provider-articles-view.component";
export * from "./providers/detail/edit/provider-edit-view.component";
export * from "./providers/detail/documents/provider-providerdocuments-view.component";

export * from "./providers/detail/menu";
export * from "./providers/detail/provider.routes";
// members
export * from "./members/list/member-list-view.component";
export * from "./members/detail/display/member-display-view.component";
export * from "./members/detail/relations/member-relations-view.component";
export * from "./members/detail/products/member-products-view.component";
export * from "./members/detail/edit/member-edit-view.component";
export * from "./members/detail/offers/member-offers-view.component";
export * from "./members/detail/storages/member-storages-view.component";
export * from "./members/detail/producers/member-producers-view.component";
export * from "./members/detail/sales/member-sales-view.component";
export * from "./members/detail/menu";
export * from "./members/detail/member.routes";
