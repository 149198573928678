import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferViewData, OfferViewParams } from "../menu";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Offer } from "../../../../../models/offers/offer/offer";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { OfferNotificationListComponent } from "../../../../../models/offers/offer-notification/offer-notification-list/offer-notification-list.component";
import { firstValueFrom, interval, map, Observable, Subject } from "rxjs";
import {
  FilterDefaults,
  Link,
  PChoicePipe,
  TabMemoryService,
} from "@solidev/data";
import { OfferNotificationCreateComponent } from "../../../../../models/offers/offer-notification/offer-notification-create/offer-notification-create.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { OfferNotificationDestinationListComponent } from "../../../../../models/offers/offer-notification-destination/offer-notification-destination-list/offer-notification-destination-list.component";
import { OfferNotification } from "../../../../../models/offers/offer-notification/offer-notification";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OfferActionService } from "../../../../../models/offers/offer/offer-action.service";
import { OFFER_STATUSES } from "../../../../../models/offers/offer/offer.base";
import { OfferService } from "../../../../../models/offers/offer/offer.service";

export interface OfferNotificationsViewParams extends OfferViewParams {}

export interface OffertNotificationsViewData
  extends OfferViewData,
    OfferNotificationsViewParams {}

@Component({
  selector: "lvadg-offer-notifications-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    OfferNotificationListComponent,
    OfferNotificationCreateComponent,
    OfferNotificationDestinationListComponent,
    OfferNavComponent,
    PChoicePipe,
  ],
  templateUrl: "./offer-notifications-view.component.pug",
  styleUrls: ["./offer-notifications-view.component.sass"],
})
export class OfferNotificationsViewComponent extends BaseDetailViewComponent<
  OffertNotificationsViewData,
  Offer
> {
  public offer_notification_statuses_route?: Link<OfferNotification>;
  public offerFilter$!: Observable<FilterDefaults>;
  public createNotification = false;
  public reload$ = new Subject<void | boolean>();
  protected readonly OFFER_STATUSES = OFFER_STATUSES;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _oa: OfferActionService,
    private _os: OfferService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offerFilter$ = this.data$.pipe(
      map((data) => ({ offer: data.offer.id, nocache: 1 })),
    );
    interval(15000).subscribe(() => {
      this.reload$.next(true);
    });
  }

  public override setRouteData(data: OffertNotificationsViewData) {
    super.setRouteData(data);
    if (data.o_menu.offer_notification_statuses_route) {
      this.offer_notification_statuses_route = new Link(
        data.o_menu.offer_notification_statuses_route,
        data,
        this._router,
      );
    }
  }

  public async onNotificationAction(
    offer: Offer,
    $event: ModelListAction<OfferNotification>,
  ) {
    console.log($event);
    if ($event.action === "send" && $event.model) {
      await this._oa.action(offer, {
        action: "send_notification",
        offernotification: $event.model.id,
      });
    } else if ($event.action === "cancel" && $event.model) {
      await this._oa.action(offer, {
        action: "cancel_notification",
        offernotification: $event.model.id,
      });
    }
    this.reload$.next(true);
  }

  public async publishOffer(offer: Offer) {
    const res = await this._oa.action(offer, { action: "publish" });
    if (res.success) {
      offer.status = res.offer!.status;
    }
  }

  public async unPublishOffer(offer: Offer) {
    const res = await this._oa.action(offer, { action: "unpublish" });
    if (res.success) {
      offer.status = res.offer!.status;
    }
  }

  public async refresh(offer: Offer) {
    this.reload$.next(true);
    const o = await firstValueFrom(
      this._os.fetch(offer.id, {
        params: { fields: ["id", "status"].join(","), nocache: "1" },
      }),
    );
    offer.status = o.status;
  }
}
