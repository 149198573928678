import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseListRouteParams, BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { VivalyaBillListComponent } from '../../../../models/sales/vivalya-bill/vivalya-bill-list/vivalya-bill-list.component';
import { VivalyaBill } from '../../../../models/sales/vivalya-bill/vivalya-bill';

export interface VivalyaBillListViewParams extends BaseListRouteParams {}

export interface VivalyaBillListViewData extends VivalyaBillListViewParams {}

@Component({
  selector: 'lvadg-vivalya-bill-list-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, VivalyaBillListComponent],
  templateUrl: './vivalya-bill-list-view.component.pug',
  styleUrls: ['./vivalya-bill-list-view.component.sass'],
})
export class VivalyaBillListViewComponent extends BaseListViewComponent<VivalyaBillListViewData, VivalyaBill> {}
