import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BaseRouteParams,
  DataMessageService,
  FilterDefaults,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
  TabMemoryService,
} from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Tarif } from '../../../../../models/tarifs/tarif/tarif';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { TARIFTYPE_USER_LEVEL, TariftypeBase } from '../../../../../models/tarifs/tariftype/tariftype.base';
import { firstValueFrom, map, Observable, Subject } from 'rxjs';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { FamilyBase } from '../../../../../models/catalog/family/family.base';
import { EarticleBase } from '../../../../../models/catalog/earticle/earticle.base';
import { TARIF_STATUS, TarifBase } from '../../../../../models/tarifs/tarif/tarif.base';
import {
  EDITION_ALLOWED_STEPS,
  LIFECYCLE_MAIN_STEP,
  LIFECYCLE_SUB_STEP,
} from '../../../../../models/tarifs/tarif/lifecycle/lifecycle-steps';
import { TarifActionService } from '../../../../../models/tarifs/tarif/tarif-action.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ModelListAction } from '../../../../../includes/modellist/modellist.component';
import { Article } from '../../../../../models/catalog/article/article';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';
import { ArticleTarifListComponent } from '../../../../../models/tarifs/article-tarif/article-tarif-list/article-tarif-list.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { TARIF_ROUTES } from '../menu';
import { TarifDetailNavComponent } from '../nav/tarif-detail-nav.component';

export interface TarifLinesViewParams extends BaseRouteParams {
  tariftype_detail_route: RouteConfigItem;
  article_detail_route?: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
  menu?: TARIF_ROUTES;
}

export interface TarifLinesViewData extends TarifLinesViewParams {
  tarif: Tarif;
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tarif-lines-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ArticleListComponent,
    ArticleTarifListComponent,
    HeaderActionComponent,
    SafeDeleteComponent,
    TarifDetailNavComponent,
  ],
  templateUrl: './tarif-lines-view.component.pug',
  styleUrls: ['./tarif-lines-view.component.sass'],
})
export class TarifLinesViewComponent extends BaseDetailViewComponent<TarifLinesViewData, Tarif> {
  @ViewChild('catalogSlot') public catalogSlot!: ElementRef<HTMLDivElement>;

  public TTUL = TARIFTYPE_USER_LEVEL;
  public reloadTarif$: Subject<void | boolean> = new Subject<void | boolean>();
  public tariftype_detail_route!: Link<TariftypeBase>;
  public article_detail_route?: Link<ArticleBase>;
  public family_detail_route?: Link<FamilyBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public tarif_detail_route!: Link<TarifBase>;
  public articletarif_default_fields = [
    'article_details',
    'message',
    'article_family',
    'article_labels',
    'activation',
    'price',
    'article_uf',
    'price_history_1',
  ];
  public catalog_default_fields = [
    'code',
    'libelle',
    'family_details',
    'udf_unit',
    'egalim_details',
    'local',
    'egalim_status',
    'calibre_details',
    'categorie_details',
    'origine_details',
    'colisage',
    'uci',
    'in_tariftype',
    'actions',
  ];
  public tarifFilter$!: Observable<FilterDefaults>;
  public catalogTarifTypeFilter$!: Observable<FilterDefaults>;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    public tAction: TarifActionService,
    private _ofc: NgbOffcanvas,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TarifLinesViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<Tariftype>(data.tariftype_detail_route, data, this._router);
    if (data.article_detail_route) {
      this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
    }
    if (data.family_detail_route) {
      this.family_detail_route = new Link<FamilyBase>(data.family_detail_route, data, this._router);
    }
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link<EarticleBase>(data.earticle_detail_route, data, this._router);
    }
    this.tarif_detail_route = new Link<Tarif>(data.tarif_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifFilter$ = this.data$.pipe(
      map((d) => {
        return { tarif: d.tarif.id };
      }),
    );
    this.catalogTarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { in_tariftype: d.tariftype.id };
      }),
    );
  }

  public canEdit(data: TarifLinesViewData, level: TARIFTYPE_USER_LEVEL) {
    if (data.tariftype.have_level(TARIFTYPE_USER_LEVEL.sadmin)) {
      return true;
    }
    return (
      data.tariftype.have_level(level) &&
      (data.tarif.status === TARIF_STATUS.PREPARATION ||
        (EDITION_ALLOWED_STEPS as (LIFECYCLE_MAIN_STEP | undefined)[]).includes(
          data.tarif.tstep_details?.pstep || undefined,
        ) ||
        data.tarif.tstep_details?.step === LIFECYCLE_SUB_STEP.EDITION)
    );
  }

  public canDelete(data: TarifLinesViewData) {
    if (data.tariftype.have_level(TARIFTYPE_USER_LEVEL.sadmin)) {
      return true;
    }
    return data.tariftype.have_level(TARIFTYPE_USER_LEVEL.pilote) && data.tarif.status === TARIF_STATUS.PREPARATION;
  }

  public async updateArticles(data: TarifLinesViewData) {
    await this.tAction.action(data.tarif, { action: 'sync_articles' });
    this.reloadTarif$.next();
  }

  public openCatalog() {
    this._ofc.open(this.catalogSlot, {
      position: 'bottom',
      panelClass: 'h-auto',
    });
  }

  public async catalogAction(tt: Tariftype, action: ModelListAction<Article>) {
    if (action.action == 'tariftype_add' && action.model) {
      try {
        await firstValueFrom(
          tt.action('POST', 'action', {
            body: {
              action: 'article_add',
              article: action.model.id,
            },
          }),
        );
        this.reloadTarif$.next();
        this._msgs.success('Article ajouté au tarif');
      } catch (e) {
        console.error(e);
        this._msgs.error(
          "Erreur lors de l'ajout de l'article",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          `${(e as any).error?.message || (e as any).message || ''}`,
          e,
        );
      }
    }
  }
}
