import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum FOLDER_PERMISSION {
  READ = 'READ',
  OWNER = 'OWNER',
}

export class FolderPermissionBase extends DataModel {
  static override readonly __name: string = 'FolderPermissionBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @charField({
    name: 'permission',
    description: 'Permission',
    maxLength: 10,
    defaultValue: FOLDER_PERMISSION.READ,
    choices: [
      { value: FOLDER_PERMISSION.READ, desc: 'Lecture' },
      { value: FOLDER_PERMISSION.OWNER, desc: 'Propriétaire' },
    ],
    priority: 600,
  })
  public permission: FOLDER_PERMISSION = FOLDER_PERMISSION.READ;

  @foreignKeyField({
    name: 'target',
    description: 'ID Dossier',
    related: 'Folder',
    priority: -1,
  })
  public target!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID User',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @foreignKeyField({
    name: 'group',
    description: 'ID Groupe',
    related: 'Group',
    priority: -1,
  })
  public group!: number;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1000,
  })
  public orig_id!: number;
}
