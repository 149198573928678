import { Component } from '@angular/core';

import { ParticularityListViewData } from './particularity-list-view.component.params';
import { Subject } from 'rxjs';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { ParticularityListComponent } from '../../../../../models/catalog/meta/particularity/particularity-list/particularity-list.component';
import { Particularity } from '../../../../../models/catalog/meta/particularity/particularity';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { ParticularityCreateComponent } from '../../../../../models/catalog/meta/particularity/particularity-create/particularity-create.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-particularity-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    ParticularityListComponent,
    HeaderActionComponent,
    IconComponent,
    ParticularityCreateComponent,
  ],
  templateUrl: './particularity-list-view.component.pug',
  styleUrls: ['./particularity-list-view.component.sass'],
})
export class ParticularityListViewComponent extends BaseListViewComponent<ParticularityListViewData, Particularity> {
  public create = false;
  public reload$ = new Subject<void | boolean>();
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];

  public override setRouteData(data: ParticularityListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
