
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OfferProductLink } from "./offer-product-link";

@Injectable({
  providedIn: 'root'
})
export class OfferProductLinkService extends Collection<OfferProductLink> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offerproductlinks", OfferProductLink);
  }
}
