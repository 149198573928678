import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RI } from '../../../constants';

@Component({
  selector: 'lvadg-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.pug',
  styleUrls: ['./icon.component.sass'],
})
export class IconComponent implements OnInit {
  @Input() public ri?: keyof typeof RI;
  @Input() icon?: string | string[];
  @Input() icon_only?: boolean | string = false;
  public iconCss: string | string[] = '';

  public ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.iconCss = (RI as any)[this.ri || ''] || this.icon;
  }
}
