import { RoutesConfig } from '@solidev/data';
import { RS, TariftypeBase } from '@vivalya/lvadg';

export const FRONT_TARIFS_ROUTES_NAMES = ['tarifs', 'tarifs_tariftype', 'tarifs_tariftype_tarifs'] as const;

export const FRONT_TARIFS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_TARIFS_ROUTES_NAMES)[number]> = {
  tarifs: { name: 'tarifs', params: [], route: () => ['/', RS.tarifs] },
  tarifs_tariftype: {
    name: 'tarifs_tariftype',
    params: ['tariftype'],
    route: (tt: TariftypeBase) => ['/', RS.tarifs, tt.id],
  },
  tarifs_tariftype_tarifs: {
    name: 'tarifs_tariftype_tarifs',
    params: ['tariftype'],
    route: (tt: TariftypeBase) => ['/', RS.tarifs, tt.id, RS.tarifs],
  },
};
