import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Iocode } from "../iocode";
import { IocodeService } from "../iocode.service";
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgMathPipesModule, NgStringPipesModule } from "ngx-pipes";
import { UserBase } from "../../../users/user/user.base";
import { UserDisplayComponent } from "../../../users/user/user-display/user-display.component";
import { TimeagoPipe } from "../../../../components/utils/timeago.pipe";

@Component({
  selector: "lvadg-iocode-list",
  standalone: true,
  templateUrl: "./iocode-list.component.pug",
  styleUrls: ["./iocode-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    NgStringPipesModule,
    NgMathPipesModule,
    UserDisplayComponent,
    TimeagoPipe,
    SafeDeleteComponent,
  ],
})
export class IocodeListComponent extends ModellistComponent<Iocode> {
  @Input() public user_detail_route?: Link<UserBase>;

  constructor(coll: IocodeService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "status", "type"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filter par état",
          model: Iocode,
        }),
        new ModelListSelectFilter({
          field: "type",
          name: "type",
          label: "Filtrer par type",
          model: Iocode,
        }),
      ],
    };
  }

  public urlClass(url: string): string {
    if (url && url.length) {
      return `url-${Math.floor(url.length / 50)}`;
    }
    return "";
  }
}
