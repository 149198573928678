
<ul class="nav nav-tabs nav-fill mb-2" *ngIf="offertype">
  <!-- Summary-->
  <li class="nav-item" *ngIf="offertype_detail_route"><a class="nav-link" [routerLink]="offertype_detail_route.route({offertype: offertype})" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Résumé</a></li>
  <!-- Settings-->
  <li class="nav-item" *ngIf="offertype_edit_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_edit_route.route({offertype: offertype})" routerLinkActive="active">Paramétrage</a></li>
  <!-- Permissions-->
  <li class="nav-item" *ngIf="offertype_permissions_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_permissions_route.route({offertype: offertype})" routerLinkActive="active">Permissions</a></li>
  <!-- Types de textes-->
  <li class="nav-item" *ngIf="offertype_text_types_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_text_types_route.route({offertype: offertype})" routerLinkActive="active">Textes</a></li>
  <!-- Storages-->
  <li class="nav-item" *ngIf="offertype_storages_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_storages_route.route({offertype: offertype})" routerLinkActive="active">Dépôts</a></li>
  <!-- Offres-->
  <li class="nav-item" *ngIf="offertype_offers_route &amp;&amp; offertype.have_level(OTUL.read)"><a class="nav-link fw-bold" [routerLink]="offertype_offers_route.route({offertype: offertype})" routerLinkActive="active">Offres</a></li>
  <!-- Destinataires-->
  <li class="nav-item" *ngIf="offertype_destinations_route &amp;&amp; (offertype.have_level(OTUL.admin))"><a class="nav-link" [routerLink]="offertype_destinations_route.route({offertype: offertype})" routerLinkActive="active">Destinataires</a></li>
  <!-- Notifications-->
  <li class="nav-item" *ngIf="offertype_notifications_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_notifications_route.route({offertype: offertype})" routerLinkActive="active">Notifications</a></li>
  <!-- Suivi des envois-->
  <li class="nav-item" *ngIf="offertype_notification_logs_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_notification_logs_route.route({offertype: offertype})" routerLinkActive="active">Suivi des envois</a></li>
  <!-- Historique des offres-->
  <li class="nav-item" *ngIf="offertype_offers_logs_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_offers_logs_route.route({offertype: offertype})" routerLinkActive="active">Historique des offres</a></li>
  <!-- Statistiques des offres-->
  <li class="nav-item" *ngIf="offertype_offers_stats_route &amp;&amp; offertype.have_level(OTUL.admin)"><a class="nav-link" [routerLink]="offertype_offers_stats_route.route({offertype: offertype})" routerLinkActive="active">Statistiques des offres</a></li>
</ul>