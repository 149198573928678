import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OffertypeTextType } from "../offertype-text-type";
import { OffertypeTextTypeService } from "../offertype-text-type.service";
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { GenericregionDisplayComponent } from "../../../locations/genericregion/genericregion-display/genericregion-display.component";
import { OffertypeDisplayComponent } from "../../offertype/offertype-display/offertype-display.component";
import { Offertype } from "../../offertype/offertype";
import { Genericregion } from "../../../locations/genericregion/genericregion";

@Component({
  selector: "lvadg-offertype-text-type-list",
  standalone: true,
  templateUrl: "./offertype-text-type-list.component.pug",
  styleUrls: ["./offertype-text-type-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    GenericregionDisplayComponent,
    OffertypeDisplayComponent,
  ],
})
export class OffertypeTextTypeListComponent extends ModellistComponent<OffertypeTextType> {
  @Input() public offertype_detail_route?: Link<Offertype>;
  @Input() public generic_region_detail_route?: Link<Genericregion>;

  constructor(
    coll: OffertypeTextTypeService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
      ],
    };
  }
}
