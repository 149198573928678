import { Component } from '@angular/core';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { TarifLifecycleStep } from '../../../../models/tarifs/tarif-lifecycle-step/tarif-lifecycle-step';
import { TarifLifecycleStepManageComponent } from '../../../../models/tarifs/tarif-lifecycle-step/tarif-lifecycle-step-manage/tarif-lifecycle-step-manage.component';
import { BaseRouteParams } from '@solidev/data';

export interface TarifLifecycleStepDetailViewParams extends BaseRouteParams {}

export interface TarifLifecycleStepDetailViewData extends TarifLifecycleStepDetailViewParams {
  tariflifecyclestep: TarifLifecycleStep;
}

@Component({
  selector: 'lvadg-tarif-lifecycle-step-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, TarifLifecycleStepManageComponent],
  templateUrl: './tarif-lifecycle-step-detail-view.component.pug',
  styleUrls: ['./tarif-lifecycle-step-detail-view.component.sass'],
})
export class TarifLifecycleStepDetailViewComponent extends BaseDetailViewComponent<
  TarifLifecycleStepDetailViewData,
  TarifLifecycleStep
> {
  public override setRouteData(data: TarifLifecycleStepDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
