import { Component, Input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Resto } from "../resto";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { LocationManageComponent } from "../../../locations/location/location-manage/location-manage.component";
import { LocationCreateComponent } from "../../../locations/location/location-create/location-create.component";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { LOCATION_TYPE } from "../../../locations/location/location.base";
import { RestoActionService } from "../resto-action.service";
import { ClientService } from "../../client/client.service";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { LabelsManagerComponent } from "../../../catalog/label/labels-manager/labels-manager.component";

@Component({
  selector: "lvadg-resto-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    IconComponent,
    LogoManageComponent,
    GalleryManageComponent,
    LocationManageComponent,
    LocationCreateComponent,
    LabelsManagerComponent,
  ],
  templateUrl: "./resto-manage.component.pug",
  styleUrls: ["./resto-manage.component.sass"],
})
export class RestoManageComponent {
  @Input() public resto!: Resto;
  public LBT = LABEL_TYPE;
  public LT = LOCATION_TYPE;
  protected allow_labels_edition = signal(false);

  constructor(
    public clients$: ClientService,
    public ract: RestoActionService,
  ) {}
}
