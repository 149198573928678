import { TarifNotificationDestinationBase } from './tarif-notification-destination.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { ContactBase } from '../../users/contact/contact.base';

export class TarifNotificationDestination extends TarifNotificationDestinationBase {
  static override readonly __name: string = 'TarifNotificationDestination';

  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;

  @detailsField({
    model: ContactBase,
    description: 'Contact',
    readonly: true,
  })
  public contact_details?: ContactBase;

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;
}
