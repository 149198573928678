
<div class="row g-3">
  <div class="card">
    <div class="card-header">Informations</div>
    <div class="card-body">
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="id" [editable]="false" mode="dd">ID</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="title" [editable]="true" mode="dd">Titre</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="type" [editable]="true" mode="dd">Type (INT/EXT)</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="url" [editable]="true" mode="dd">URL lien</data-dispedit>
      </dl>
      <dl class="row mt-3">
        <data-richedit class="col-12" [model]="news" field="content" [editable]="true" mode="dd">Contenu</data-richedit>
      </dl>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="news">Publication</lvadg-icon>
    </div>
    <div class="card-body">
      <dl class="row mt-3">
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="categories" [editable]="true" mode="dd" [collection]="category$">Catégories</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <dt>Destination</dt>
        <dd class="d-flex flex-row justify-content-between">
          <button class="btn me-2 flex-grow-1" *ngFor="let d of destinations" [ngClass]="{'btn-outline-success': !haveDest(d), 'btn-success': haveDest(d)}" (click)="setUnsetDest(d)">{{d.name}}</button>
        </dd>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="status" [editable]="true" mode="dd">État</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="order" [editable]="true" mode="dd">Ordre d'affichage</data-dispedit>
      </dl>
      <dl class="row mt-3 border-bottom">
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="datestart" [editable]="true" mode="dd">Début de publication</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="dateend" [editable]="true" mode="dd">Fin de publication</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="created" [editable]="false" mode="dd">Date de création</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" field="updated" [editable]="false" mode="dd">Date de mise à jour</data-dispedit>
      </dl>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-header">
      <lvadg-icon ri="news">Logo</lvadg-icon>
    </div>
    <div class="card-body">
      <dl class="row" *ngIf="news">
        <div class="col-sm-12">
          <!-- data-dispedit.col-6.col-lg-3([model]="news", field="icon", [editable]="true", mode="dd") Icône fontawesome-->
          <lvadg-logo-manage [model]="news" [collection]="news$"></lvadg-logo-manage>
        </div>
      </dl>
    </div>
  </div>
</div>
<div class="card mt-3" *ngIf="news.type=='INT'">
  <div class="card-header">
    <lvadg-icon ri="news">Documents</lvadg-icon>
  </div>
  <div class="card-body">
    <dl class="row" *ngIf="news">
      <div class="col-sm-12">
        <!-- FIXME: afficher l'arborescence des dossiers pour la sélection du document-->
        <data-dispedit class="col-6 col-lg-3" [model]="news" [collection]="documents$" field="document" [editable]="true" mode="dd">Document lié</data-dispedit>
        <data-dispedit class="col-6 col-lg-3" [model]="news" [collection]="folders$" field="folder" [editable]="true" mode="dd">Dossier lié</data-dispedit>
      </div>
    </dl>
  </div>
</div>