import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';
import { PresentationBase } from '../presentation.base';
import { PresentationService } from '../presentation.service';

@Component({
  selector: 'lvadg-presentation-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './presentation-create.component.pug',
  styleUrls: ['./presentation-create.component.sass'],
})
export class PresentationCreateComponent implements OnInit {
  public presentation!: PresentationBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<PresentationBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _presentations: PresentationService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.presentation = new PresentationBase(this._presentations);
  }

  public async save() {
    this.presentation.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.presentation.save({ updateModel: true }));
      this.created.emit(this.presentation);
      this._msgs.success('Allergène créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
