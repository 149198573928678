import { Component } from '@angular/core';
import { OrderitemDetailViewData } from './orderitem-detail-view.component.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import {
  OrderitemManageComponent
} from "../../../../models/orders/orderitem/orderitem-manage/orderitem-manage.component";
import {Orderitem} from "../../../../models/orders/orderitem/orderitem";

@Component({
  selector: 'lvadg-orderitem-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, OrderitemManageComponent],
  templateUrl: './orderitem-detail-view.component.pug',
  styleUrls: ['./orderitem-detail-view.component.sass'],
})
export class OrderitemDetailViewComponent extends BaseDetailViewComponent<OrderitemDetailViewData, Orderitem> {
  public override setRouteData(data: OrderitemDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
