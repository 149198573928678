
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { ArticleSimilarityService} from "./article-similarity.service";
import { ArticleSimilarity } from "./article-similarity";

@Injectable({
  providedIn: 'root'
})
export class ArticleSimilarityResolver implements Resolve<ArticleSimilarity> {
  constructor(private _rs: ArticleSimilarityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleSimilarity> {
    return this._rs.fetch(+route.params["articlesimilarityId"])
  }
}
