
<ng-template #catalogSlot let-offcanvas>
  <ng-container *ngIf="curMapping">
    <div class="offcanvas-header">
      <h4 class="my-0">Catalogue / ajout d'articles<code class="ms-2">{{curMapping.earticle_libelle}}</code></h4>
      <button class="btn-close" (click)="offcanvas.close()"></button>
    </div>
    <div class="offcanvas-body">
      <lvadg-article-list [name]="name+'_ofc_catalog'" [actions]="['earticle_add']" [default_fields]="catalog_default_fields" [filter]="{in_eamapping: tariftype.id, in_tariftype: tariftype.id}" (action)="catalogAction($event)" [reload]="reload"></lvadg-article-list>
    </div>
  </ng-container>
</ng-template>
<lvadg-eamapping-list *ngIf="tariftype" [name]="name+'_eamappings'" [filter]="{tariftype: tariftype.id}" [tarifType]="tariftype" [reload]="reload" [actions]="tariftype.have_level(TARIFTYPE_USER_LEVEL.sadmin) ? mapping_sactions : mapping_actions" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" [tariftype_detail_route]="tariftype_detail_route" (action)="eamappingAction($event)" [default_fields]="eamapping_default_fields"></lvadg-eamapping-list>