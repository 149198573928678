
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnmlibelle } from '../rnmlibelle';
@Component({
  selector: 'lvadg-rnmlibelle-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmlibelle-manage.component.pug',
  styleUrls: ['./rnmlibelle-manage.component.sass']
})
export class RnmlibelleManageComponent implements OnInit {
  @Input() public rnmlibelle?: Rnmlibelle;

  constructor() { }

  ngOnInit(): void {
  }

}
