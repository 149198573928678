import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { UserService } from '../../../users/user/user.service';
import { GroupService } from '../../../users/group/group.service';
import { TariftypePermission } from '../tariftype-permission';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-tariftype-permission-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tariftype-permission-create.component.pug',
  styleUrls: ['./tariftype-permission-create.component.sass'],
})
export class TariftypePermissionCreateComponent implements OnInit {
  @Input() public tariftype?: Tariftype;
  @Output() public created = new EventEmitter();
  @Output() public cancelled = new EventEmitter();
  public perm!: TariftypePermission;

  constructor(
    private _ttact: TariftypeActionService,
    private _ttcoll: TariftypeService,
    public user$: UserService,
    public group$: GroupService
  ) {}

  ngOnInit(): void {
    this.perm = new TariftypePermission();
    if (this.tariftype) {
      this.perm.target = this.tariftype.id;
      // FIXME: rename tariftype_details to target_details or add alias
      this.perm.tariftype_details = this.tariftype;
    } else {
      console.error('No tariftype available');
    }
  }

  public async save() {
    const tt = this.tariftype;
    if (tt && tt.id) {
      tt._collection = this._ttcoll;
      const res = await this._ttact.action(tt, {
        action: 'add_permission',
        user: this.perm.user,
        group: this.perm.group,
        permission: this.perm.permission,
      });
      if (res.success) {
        this.created.next(true);
      }
    }
  }
}
