import { RoutesConfig } from '@solidev/data';
import { Cdata, CdataKey, CdataKeyChoice, RS } from '@vivalya/lvadg';

export const ADMIN_CDATAS_ROUTES_NAMES = [
  'cdatas',
  'cdatas_cdatakeys',
  'cdatas_cdatakeys_cdatakey',
  'cdatas_cdatakeys_cdatakey_cdatakeychoices',
  'cdatas_cdatakeys_cdatakey_cdatakeychoices_cdatakeychoice',
  'cdatas_cdatakeys_cdatakey_cdatas',
  'cdatas_cdatakeys_cdatakey_cdatas_cdata',
] as const;

export const ADMIN_CDATAS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_CDATAS_ROUTES_NAMES)[number]> = {
  cdatas: {
    params: [],
    route: () => ['/', RS.cdatas],
    name: 'cdata',
  },
  cdatas_cdatakeys: {
    params: [],
    route: () => ['/', RS.cdatas, RS.cdatakeys],
    name: 'cdatas_cdatakeys',
  },
  cdatas_cdatakeys_cdatakey: {
    params: ['cdatakey'],
    route: (cdatakey: CdataKey) => ['/', RS.cdatas, RS.cdatakeys, cdatakey.id],
    name: 'cdatas_cdatakeys_cdatakey',
  },
  cdatas_cdatakeys_cdatakey_cdatakeychoices: {
    params: ['cdatakey'],
    route: (cdatakey: CdataKey) => ['/', RS.cdatas, RS.cdatakeys, cdatakey.id, RS.cdatakeychoices],
    name: 'cdatas_cdatakeys_cdatakey_cdatakeychoices',
  },
  cdatas_cdatakeys_cdatakey_cdatakeychoices_cdatakeychoice: {
    params: ['cdatakey', 'cdatakeychoice'],
    route: (cdatakey: CdataKey, cdatakeychoice: CdataKeyChoice) => [
      '/',
      RS.cdatas,
      RS.cdatakeys,
      cdatakey.id,
      RS.cdatakeychoices,
      cdatakeychoice.id,
    ],
    name: 'cdatas_cdatakeys_cdatakey_cdatakeychoices_cdatakeychoice',
  },
  cdatas_cdatakeys_cdatakey_cdatas: {
    params: ['cdatakey'],
    route: (cdatakey: CdataKey) => ['/', RS.cdatas, RS.cdatakeys, cdatakey.id, RS.cdatas],
    name: 'cdatas_cdatakeys_cdatakey_cdatas',
  },
  cdatas_cdatakeys_cdatakey_cdatas_cdata: {
    params: ['cdatakey', 'cdata'],
    route: (cdatakey: CdataKey, cdata: Cdata) => ['/', RS.cdatas, RS.cdatakeys, cdatakey.id, RS.cdatas, cdata.id],
    name: 'cdatas_cdatakeys_cdatakey_cdatas_cdata',
  },
};
