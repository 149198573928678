import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tariftype } from '../tariftype';
import { TariftypeMetadataService } from '../../tariftype-metadata/tariftype-metadata.service';
import { TARIFTYPEMETADATA_TYPE } from '../../tariftype-metadata/tariftype-metadata.base';
import { Observable } from 'rxjs';
import { DataMessageService, UploadFile } from '@solidev/data';
import { Upload } from '../../../documents/upload/upload';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { OpLog } from '../../../../components/utils/oplog/oplog';
import { OplogDisplayComponent } from '../../../../components/utils/oplog/oplog-display/oplog-display.component';
import {
  IPreparedUploadFile,
  MediaUploadComponent,
} from '../../../documents/upload/media-upload/media-upload.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TariftypeMetadata } from '../../tariftype-metadata/tariftype-metadata';
import { TarifDocument } from '../../tarif-document/tarif-document';
import { TariftypeDocumentService } from '../../tariftype-document/tariftype-document.service';
import { TariftypeDocument } from '../../tariftype-document/tariftype-document';
import { TARIFTYPEDOCUMENT_TYPE } from '../../tariftype-document/tariftype-document.base';
import { TariftypeService } from '../tariftype.service';

interface ActionResponseData {
  data: {
    result: {
      mapped: { code: string; libelle: string }[];
      created: { code: string; libelle: string }[];
      updated: { code: string; libelle: string }[];
    };
    upload: number;
  };
  log: OpLog;
  message: string;
  success: boolean;
  tariftype: Tariftype;
}

interface ActionUploadData {
  data: {
    tariftypedocument_id?: number;
    upload_id?: number;
  };
  message: string;
}

@Component({
  selector: 'lvadg-tariftype-earticles-import',
  standalone: true,
  imports: [
    CommonModule,
    MediaUploadComponent,
    ReactiveFormsModule,
    OplogDisplayComponent,
  ],
  templateUrl: './tariftype-earticles-import.component.pug',
  styleUrls: ['./tariftype-earticles-import.component.sass'],
})
export class TariftypeEarticlesImportComponent implements OnInit {
  @Input() public tariftype!: Tariftype;
  @Output() public cancelled = new EventEmitter<void>();
  @Output() public done = new EventEmitter<void>();
  public doc!: Upload;
  public uploadPrepare: (
    model: Upload,
    file: UploadFile
  ) => Promise<IPreparedUploadFile<Upload>> = this._uploadPrepare.bind(this);
  public uploadUrl!: string;
  public step = 0;
  public result?: ActionResponseData;
  public form = new FormGroup({
    metadata: new FormControl<TariftypeMetadata | null>(null, [
      Validators.required,
    ]),
    nokeep: new FormControl<boolean>(false),
    document: new FormControl<TarifDocument | null>(null),
  });
  public docs$!: Observable<TariftypeDocument[]>;
  public metas$!: Observable<TariftypeMetadata[]>;
  public upload_id?: number;
  public tariftypedocument_id?: number;
  public processing: boolean = false;

  constructor(
    private _ttypes: TariftypeService,
    private _ttmeta: TariftypeMetadataService,
    private _ttdocs: TariftypeDocumentService,
    private _tact: TariftypeActionService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.docs$ = this._ttdocs.list({
      tariftype: this.tariftype.id,
      type: TARIFTYPEDOCUMENT_TYPE.EACLIENT,
    });
    this.metas$ = this._ttmeta.list({
      tariftype: this.tariftype.id,
      type: TARIFTYPEMETADATA_TYPE.EACLIENT_IMPORT_PARAMS,
    });
    this.doc = new Upload();
    if (this.tariftype) {
      this.uploadUrl = this._ttypes.getUrl(this.tariftype.id, {
        suffix: '/action',
      });
    }
  }

  public onUploaded($event: { response: ActionUploadData; status: number }) {
    if ($event.response.data) {
      if ($event.response.data.upload_id) {
        this.upload_id = $event.response.data.upload_id;
      } else if ($event.response.data.tariftypedocument_id) {
        this.tariftypedocument_id = $event.response.data.tariftypedocument_id;
      }
      this._msgs.success($event.response.message);
    }
  }

  public async launch(confirm: boolean = false) {
    if (this.form.value.metadata) {
      this.step = confirm ? 2 : 1;
      this.processing = true;
      delete this.result;
      this.result = await this._tact.action<ActionResponseData>(
        this.tariftype,
        {
          action: 'import_client_earticles',
          document_id:
            this.tariftypedocument_id || this.form.value.document?.id,
          upload_id: this.upload_id,
          metadata_id: this.form.value.metadata.id,
          confirm,
        }
      );
      this.processing = false;
    }
  }

  public xlsxExport(result: ActionResponseData) {
    const res = result.data.result;
    if (!res) {
      return;
    }
    setTimeout(async () => {
      await import('xlsx').then(({ utils, writeFileXLSX }) => {
        const wb = utils.book_new();
        if (res.created) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.created.map((v) => [v.code, v.libelle])),
            '01-Créés'
          );
        }
        if (res.mapped) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.mapped.map((v) => [v.code, v.libelle])),
            '02-Mapping ajoutés'
          );
        }
        if (res.updated) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(res.updated.map((v) => [v.code, v.libelle])),
            '03-Mis à jour'
          );
        }
        if (result.log) {
          utils.book_append_sheet(
            wb,
            utils.aoa_to_sheet(
              result.log.map((v) => [v.line, v.level, v.message, v.data])
            ),
            '99-Log'
          );
        }
        writeFileXLSX(wb, 'résultat_importation.xlsx');
      });
    });
  }

  private async _uploadPrepare(
    model: Upload,
    file: UploadFile
  ): Promise<IPreparedUploadFile<Upload>> {
    return {
      model,
      url: this.uploadUrl,
      data: {
        action: 'import_client_earticles',
        keep_file: !this.form.value.nokeep,
        tariftype: this.tariftype.id,
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
        title: file.name,
      },
    };
  }
}
