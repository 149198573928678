
<ng-container *ngIf="producer &amp;&amp; mode=='line'">
  <ng-container *ngIf="detail_route"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container><a class="text-decoration-none" [routerLink]="detail_route?.route({producer})">{{producer.name}}</a>
  <div class="small" *ngIf="show_location">
    <lvadg-location-display [location]="producer.location_details" mode="short"></lvadg-location-display>
  </div>
</ng-container>
<ng-container *ngIf="producer &amp;&amp; mode=='linewloc'"><a [routerLink]="detail_route?.route({producer})">{{producer.name}}</a>
  <div class="small">
    <lvadg-location-display [location]="producer.location_details" mode="short"></lvadg-location-display>
  </div>
</ng-container>
<ng-container *ngIf="producer &amp;&amp; mode=='linewcpville'"><a class="text-decoration-none" [routerLink]="detail_route?.route({producer})">
    <ng-container *ngIf="detail_route"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container>{{producer.name}}</a>
  <div class="small">{{producer.location_details?.postcode}} {{producer.location_details?.city}}</div>
</ng-container>
<ng-container *ngIf="producer &amp;&amp; mode=='1linewcpville'"><a class="text-decoration-none" [routerLink]="detail_route?.route({producer})">
    <ng-container *ngIf="detail_route"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container>{{producer.name}}<span class="small ms-4">
      <lvadg-location-display [location]="producer.location_details" mode="cpcity"></lvadg-location-display></span></a></ng-container>