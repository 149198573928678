import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CiqualColumn } from '../ciqual-column';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-ciqual-column-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './ciqual-column-display.component.pug',
  styleUrls: ['./ciqual-column-display.component.sass'],
})
export class CiqualColumnDisplayComponent implements OnInit {
  @Input() public ciqualcolumn?: CiqualColumn;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<CiqualColumn>;

  constructor() {}

  ngOnInit(): void {}
}
