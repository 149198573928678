import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { PRODUCER_ROUTES } from "../menu";
import { Producer } from "../../../../../models/structures/producer/producer";
import { Link } from "@solidev/data";
import { Storage } from "../../../../../models/structures/storage/storage";
import { AuthService } from "../../../../../models/users/auth.service";
import { PRODUCER_USER_LEVELS } from "../../../../../models/structures/producer/producer.base";
import { ADMIN_GROUPS } from "../../../../../constants";

@Component({
  selector: "lvadg-producer-nav",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: "./producer-nav.component.pug",
  styleUrl: "./producer-nav.component.sass",
})
export class ProducerNavComponent implements OnInit {
  @Input() public routes!: PRODUCER_ROUTES;
  @Input() public producer!: Producer;
  @Input() public storage?: Storage;

  public producer_detail_route?: Link<Producer>;
  public producer_edit_route?: Link<Producer>;
  public producer_relations_route?: Link<Producer>;
  public producer_documents_route?: Link<Producer>;
  public producer_offers_route?: Link<Producer>;
  public producer_products_route?: Link<Producer>;

  public readonly canEdit = signal(false);
  public readonly canAdmin = signal(false);
  public readonly PUL = PRODUCER_USER_LEVELS;
  private _auth = inject(AuthService).currentUser$;

  constructor(private _router: Router) {}

  public ngOnInit() {
    this._auth.subscribe((user) => {
      if (user) {
        if (user.is_superuser) {
          this.canEdit.set(true);
          this.canAdmin.set(true);
        } else if (
          this.producer.have_level(PRODUCER_USER_LEVELS.WRITE) ||
          user.have_group(ADMIN_GROUPS.producers_editor)
        ) {
          this.canEdit.set(true);
          this.canAdmin.set(false);
        } else {
          this.canEdit.set(false);
          this.canAdmin.set(false);
        }
      }
    });
    if (!this.routes) {
      throw new Error("Missing routes input");
    }
    if (this.routes.producer_detail_route) {
      this.producer_detail_route = new Link(
        this.routes.producer_detail_route,
        this,
        this._router,
      );
    }
    if (this.routes.producer_edit_route) {
      this.producer_edit_route = new Link(
        this.routes.producer_edit_route,
        this,
        this._router,
      );
    }
    if (this.routes.producer_relations_route) {
      this.producer_relations_route = new Link(
        this.routes.producer_relations_route,
        this,
        this._router,
      );
    }
    if (this.routes.producer_documents_route) {
      this.producer_documents_route = new Link(
        this.routes.producer_documents_route,
        this,
        this._router,
      );
    }
    if (this.routes.producer_offers_route) {
      this.producer_offers_route = new Link(
        this.routes.producer_offers_route,
        this,
        this._router,
      );
    }
    if (this.routes.producer_products_route) {
      this.producer_products_route = new Link(
        this.routes.producer_products_route,
        this,
        this._router,
      );
    }
  }
}
