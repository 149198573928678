import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleBase } from '../article.base';
import { Article } from '../article';
import { DataMessageService, Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-article-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './article-display.component.pug',
  styleUrls: ['./article-display.component.sass'],
})
export class ArticleDisplayComponent {
  @Input() public article?: ArticleBase | Article;
  @Input() public mode: 'line' | 'button' = 'line';
  @Input() public detail_route?: Link<ArticleBase>;

  constructor(private _msgs: DataMessageService) {}

  public async clip() {
    await navigator.clipboard.writeText(this.article?.code || '');
    this._msgs.success('Code article copié dans le presse-papier');
  }
}
