
<div class="row">
  <div *ngIf="mode!=='out'" [class.col-12]="mode==='in'" [class.col-xxl-6]="mode===&quot;inout&quot;">
    <div class="d-flex flex-column flex-md-row justify-content-between my-2">
      <h3>Relations entrantes</h3>
      <div class="actions">
        <button class="btn btn-sm btn-primary" (click)="addInputRelation=!addInputRelation"><i class="bi bi-plus me-2"></i>Ajouter une relation entrante</button>
      </div>
    </div>
    <div class="card my-2" *ngIf="addInputRelation">
      <div class="card-header">Ajouter une relation entrante</div>
      <div class="card-body">
        <div class="my-3">
          <h4></h4>
          <lvadg-tariftype-relation-create [dest]="tariftype" (created)="addInputRelation=false; reload$.next()" (cancelled)="addInputRelation=false"></lvadg-tariftype-relation-create>
        </div>
      </div>
    </div>
    <lvadg-tariftype-relation-list [name]="'tariftyperelations_input'" [filter]="destFilter" [default_fields]="['id', 'src_details', 'type', 'ops', 'actions']" [detail_route]="tariftyperelation_detail_route" [keep]="false" [tariftype_detail_route]="tariftype_detail_route" [actions]="['edit', 'articles', 'delete']" [reload]="reload$"></lvadg-tariftype-relation-list>
  </div>
  <div *ngIf="mode!=='in'" [class.col-12]="mode==='out'" [class.col-xxl-6]="mode===&quot;inout&quot;" [class.mt-3]="mode===&quot;inout&quot;" [class.mt-xxl-0]="mode===&quot;inout&quot;">
    <div class="d-flex flex-column flex-md-row justify-content-between my-2">
      <h3>Relations sortantes</h3>
      <div class="actions">
        <button class="btn btn-sm btn-primary" (click)="addOutputRelation=!addOutputRelation"><i class="bi bi-plus me-2"></i>Ajouter une relation sortante</button>
      </div>
    </div>
    <div class="card" *ngIf="addOutputRelation">
      <div class="card-header">Ajouter une relation sortante</div>
      <div class="card-body">
        <div class="my-3">
          <h4></h4>
          <lvadg-tariftype-relation-create [src]="tariftype" (created)="addOutputRelation=false; reload$.next()" (cancelled)="addOutputRelation=false"></lvadg-tariftype-relation-create>
        </div>
      </div>
    </div>
    <lvadg-tariftype-relation-list [name]="'tariftyperelations_output'" [filter]="srcFilter" [default_fields]="['id', 'dest_details', 'type', 'ops', 'actions']" [detail_route]="tariftyperelation_detail_route" [keep]="false" [tariftype_detail_route]="tariftype_detail_route" [actions]="['edit','articles', 'delete']"></lvadg-tariftype-relation-list>
  </div>
</div>