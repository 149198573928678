import { Injectable } from '@angular/core';
import { StorageService } from '../../structures/storage/storage.service';
import { MetadataDesc, TARIFTYPEMETADATA_TYPE } from './tariftype-metadata.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { map, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetadataTypesService {
  private _types?: Observable<{
    [key in TARIFTYPEMETADATA_TYPE]: MetadataDesc;
  }>;

  constructor(private _storages: StorageService) {}

  public get TYPES() {
    if (!this._types) {
      this._types = this._storages.list({ page_size: 400, fields: 'id,name,cvva' }).pipe(
        map((st) => {
          return {
            [TARIFTYPEMETADATA_TYPE.EACLIENT_IMPORT_PARAMS]: {
              desc_mandatory: {
                enabled: true,
                title: 'Obligatoire ?',
                description: 'Champ dont la présence est obligatoire pour que la ligne soit traitée',
                label: 'Obligatoire',
              },
              desc_keep: {
                enabled: true,
                title: 'Conserver ?',
                description: 'Conserver la valeur',
                label: 'Conserver',
              },
              desc_match: {
                enabled: true,
                title: 'Champs de correspondance article client',
                description: 'Champs permettant de faire la correspondance avec les articles client',
              },
              desc_update: {
                enabled: true,
                title: 'Champs à mettre à jour',
                description: 'Champs à mettre à jour si la correspondance est trouvée',
              },
              desc_create: {
                enabled: true,
                title: 'Champs à créer',
                description: "Champs à créer si aucune corresponance n'est trouvée",
              },
              dest_match_columns: [
                { field: 'id', desc: 'ID Vivalya' },
                { field: 'code', desc: 'Code client (exact)' },
                { field: 'code__iexact', desc: 'Code client (CI)' },
                { field: 'icode', desc: 'Code client 2' },
                { field: 'ean13', desc: 'Code barre' },
                { field: 'gamme', desc: 'Classif niveau 1' },
                { field: 'code_gamme', desc: 'Code classif niveau 1' },
                { field: 'sgamme', desc: 'Classif niveau 2' },
                { field: 'code_sgamme', desc: 'Code classif niveau 2' },
                { field: 'typologie', desc: 'Typologie produit' },
                { field: 'marque', desc: 'Marque' },
                { field: 'labels', desc: 'Labels' },
                { field: 'libelle', desc: 'Libellé (exact)' },
                { field: 'libelle__iexact', desc: 'Libellé (CI)' },
                { field: 'libelle2', desc: 'Libellé 2' },
                { field: 'categorie', desc: 'Catégorie' },
                { field: 'calibre', desc: 'Calibre' },
                { field: 'origine', desc: 'Origine (exact)' },
                { field: 'origine__iexact', desc: 'Origine (CI)' },
                { field: 'uf', desc: 'U. facturation' },
              ],
              dest_update_columns: [
                { field: 'id', desc: 'ID Vivalya' },
                { field: 'code', desc: 'Code client' },
                { field: 'icode', desc: 'Code client 2' },
                { field: 'ean13', desc: 'Code barre' },
                { field: 'gamme', desc: 'Classif niveau 1' },
                { field: 'code_gamme', desc: 'Code classif niveau 1' },
                { field: 'sgamme', desc: 'Classif niveau 2' },
                { field: 'code_sgamme', desc: 'Code classif niveau 2' },
                { field: 'typologie', desc: 'Typologie produit' },
                { field: 'marque', desc: 'Marque' },
                { field: 'labels', desc: 'Labels' },
                { field: 'libelle', desc: 'Libellé' },
                { field: 'libelle2', desc: 'Libellé 2' },
                { field: 'categorie', desc: 'Catégorie' },
                { field: 'calibre', desc: 'Calibre' },
                { field: 'origine', desc: 'Origine' },
                { field: 'uf', desc: 'U. facturation' },
                { field: 'uf_value', desc: '[valeur] facturation' },
                { field: 'uf_unit', desc: '[unité] facturation (unité)' },
                { field: 'ul', desc: 'U. livraison' },
                { field: 'ul_value', desc: '[valeur] U. livraison' },
                { field: 'ul_unit', desc: '[unité] U. livraison' },
                { field: 'ucuf', desc: 'Ratio uc/uf' },
                { field: 'ucul', desc: 'Ratio uc/ul' },
                { field: 'ucond', desc: 'U. conditionnement' },
                { field: 'ucond_value', desc: '[valeur] U. conditionnement' },
                { field: 'ucond_unit', desc: '[unité] U. conditionnement' },
                { field: 'uc', desc: 'U. commande' },
                { field: 'uc_value', desc: '[valeur] U. commande' },
                { field: 'uc_unit', desc: '[unité] U. commande' },
                { field: 'nego_unit', desc: 'U. négociation' },
                { field: 'tax', desc: 'Taxes' },
                { field: 'conservation', desc: 'Mode de conservation' },
                { field: 'pe', desc: 'Poids égoutté' },
                { field: 'pe_value', desc: '[valeur] Poids égoutté' },
                { field: 'pe_unit', desc: '[unité] Poids égoutté' },
              ],
              dest_create_columns: [
                { field: 'id', desc: 'ID Vivalya' },
                { field: 'code', desc: 'Code client' },
                { field: 'icode', desc: 'Code client 2' },
                { field: 'ean13', desc: 'Code barre' },
                { field: 'gamme', desc: 'Classif niveau 1' },
                { field: 'code_gamme', desc: 'Code classif niveau 1' },
                { field: 'sgamme', desc: 'Classif niveau 2' },
                { field: 'code_sgamme', desc: 'Code classif niveau 2' },
                { field: 'typologie', desc: 'Typologie produit' },
                { field: 'marque', desc: 'Marque' },
                { field: 'labels', desc: 'Labels' },
                { field: 'libelle', desc: 'Libellé' },
                { field: 'libelle2', desc: 'Libellé 2' },
                { field: 'categorie', desc: 'Catégorie' },
                { field: 'calibre', desc: 'Calibre' },
                { field: 'origine', desc: 'Origine' },
                { field: 'uf', desc: 'U. facturation' },
                { field: 'uf_value', desc: '[valeur] facturation' },
                { field: 'uf_unit', desc: '[unité] facturation (unité)' },
                { field: 'ul', desc: 'U. livraison' },
                { field: 'ul_value', desc: '[valeur] U. livraison' },
                { field: 'ul_unit', desc: '[unité] U. livraison' },
                { field: 'ucuf', desc: 'Ratio uc/uf' },
                { field: 'ucul', desc: 'Ratio uc/ul' },
                { field: 'ucond', desc: 'U. conditionnement' },
                { field: 'ucond_value', desc: '[valeur] U. conditionnement' },
                { field: 'ucond_unit', desc: '[unité] U. conditionnement' },
                { field: 'uc', desc: 'U. commande' },
                { field: 'uc_value', desc: '[valeur] U. commande' },
                { field: 'uc_unit', desc: '[unité] U. commande' },
                { field: 'nego_unit', desc: 'U. négociation' },
                { field: 'tax', desc: 'Taxes' },
                { field: 'conservation', desc: 'Mode de conservation' },
                { field: 'pe', desc: 'Poids égoutté' },
                { field: 'pe_value', desc: '[valeur] Poids égoutté' },
                { field: 'pe_unit', desc: '[unité] Poids égoutté' },
              ],
            },
            [TARIFTYPEMETADATA_TYPE.EACLIENT_READ_PARAMS]: {
              desc_mandatory: {
                enabled: true,
                title: 'Obligatoire ?',
                description: 'Valeur obligatoire pour que la ligne soit prise en compte',
                label: 'Obligatoire',
              },
              desc_keep: {
                enabled: false,
                title: '',
                description: '',
              },
              desc_match: {
                enabled: true,
                title: 'Champs de correspondance article tarif',
                description: 'Champs permettant de rechercher un article tarif associé à chaque ligne',
              },
              desc_update: {
                enabled: true,
                title: 'Champs de disponibilité',
                description: "Champs permettant d'activer / désactiver les lignes de tarif",
              },
              desc_create: {
                enabled: true,
                title: 'Champs de correspondance pour info',
                description: 'Champs utilisés pour affichage uniquement',
              },
              dest_match_columns: [
                {
                  field: 'att__article__code',
                  desc: '[vivalya] Code article ARxxxxx',
                },
                {
                  field: 'att__article__libelle',
                  desc: '[vivalya] Libellé article',
                },
                {
                  field: 'att__eaclient__code',
                  desc: '[client] Code article (exact)',
                },
                {
                  field: 'att__eaclient__code__iexact',
                  desc: '[client] Code article (CI)',
                },
                {
                  field: 'att__eaclient__libelle',
                  desc: '[client] Libellé article (exact)',
                },
                {
                  field: 'att__eaclient__libelle__iexact',
                  desc: '[client] Libellé article (CI)',
                },
                {
                  field: 'att__eaclient__origine',
                  desc: '[client] Origine (exact)',
                },
                {
                  field: 'att__eaclient__origine__iexact',
                  desc: '[client] Origine (CI)',
                },
                {
                  field: 'att__id',
                  desc: '[lvadg] ID Article tarif',
                },
              ],
              dest_update_columns: [
                { field: 'price', desc: 'Prix' },
                { field: 'required', desc: 'Demandé ?' },
              ],
              dest_create_columns: [
                { field: 'price', desc: 'Prix' },
                { field: 'code', desc: 'Code client' },
                { field: 'icode', desc: 'Code client 2' },
                { field: 'gamme', desc: 'Classif niveau 1' },
                { field: 'code_gamme', desc: 'Code classif niveau 1' },
                { field: 'sgamme', desc: 'Classif niveau 2' },
                { field: 'code_sgamme', desc: 'Code classif niveau 2' },
                { field: 'typologie', desc: 'Typologie produit' },
                { field: 'marque', desc: 'Marque' },
                { field: 'labels', desc: 'Labels' },
                { field: 'libelle', desc: 'Libellé' },
                { field: 'libelle2', desc: 'Libellé 2' },
                { field: 'categorie', desc: 'Catégorie' },
                { field: 'calibre', desc: 'Calibre' },
                { field: 'origine', desc: 'Origine' },
                { field: 'uf', desc: 'U. facturation' },
              ],
            },
            [TARIFTYPEMETADATA_TYPE.EAPRICE_READ_PARAMS]: {
              desc_mandatory: {
                enabled: true,
                title: 'Obligatoire ?',
                description: 'Obligatoire',
              },
              desc_keep: {
                enabled: false,
                title: '',
                description: '',
              },
              desc_match: {
                enabled: true,
                title: 'Correspondances article tarif',
                description: 'Eléments permettant de faire la correspondance avec les lignes du tarif',
              },
              desc_update: {
                enabled: true,
                title: 'Prix à importer, le type de prix doit correspondre à celui du tarif (brut, net, etc..)',
                description: 'Une seule colonne de prix',
              },
              desc_create: {
                enabled: false,
                title: '',
                description: '',
              },
              dest_match_columns: [
                {
                  field: 'att__article__code',
                  desc: '[vivalya] Code article',
                },
                {
                  field: 'att__article__libelle',
                  desc: '[vivalya] Libellé article',
                },
                {
                  field: 'att__eaclient__code',
                  desc: '[client] Code article (exact)',
                },
                {
                  field: 'att__eaclient__code__iexact',
                  desc: '[client] Code article (CI)',
                },
                {
                  field: 'att__eaclient__libelle',
                  desc: '[client] Libellé article (exact)',
                },
                {
                  field: 'att__eaclient__libelle__iexact',
                  desc: '[client] Libellé article (CI)',
                },
                {
                  field: 'att__eaclient__libelle2',
                  desc: '[client] Libellé 2 article',
                },
                {
                  field: 'att__eaclient__origine',
                  desc: '[client] Origine (exact)',
                },
                {
                  field: 'att__eaclient__origine__iexact',
                  desc: '[client] Origine (CI)',
                },
                {
                  field: 'att__id',
                  desc: '[lvadg] ID Article tarif',
                },
              ],
              dest_update_columns: [
                { field: 'price', desc: 'Prix brut' },
                { field: 'price_net', desc: 'Prix net' },
                { field: 'price_net2', desc: 'Prix net net' },
                { field: 'price_dirty', desc: 'Prix brut (filtré)' },
                { field: 'price_dirty_net', desc: 'Prix net (filtré)' },
                { field: 'price_dirty_net2', desc: 'Prix net net (filtré)' },
                { field: 'price_franco', desc: 'Prix franco' },
                { field: 'price_custom', desc: 'Prix spécifique' },
                { field: 'price_brut_to_net', desc: 'Prix brut -> net' },
                { field: 'price_brut_to_net2', desc: 'Prix brut -> netnet' },
              ],
              dest_create_columns: [],
            },
            [TARIFTYPEMETADATA_TYPE.EAPRICE_WRITE_PARAMS]: {
              desc_mandatory: {
                enabled: true,
                title: 'Obligatoire ?',
                description: 'Champs devant être présents pour que la ligne soit prise en compte',
              },
              desc_keep: {
                enabled: false,
                title: '',
                description: '',
              },
              desc_match: {
                enabled: true,
                title: 'Champs de correspondance avec le tarif',
                description: 'Champs permettant de faire la correspondance avec le tarif',
              },
              desc_update: {
                enabled: true,
                title: 'Champs à mettre à jour',
                description: 'Données à mettre à jour à partir du tarif',
              },
              desc_create: {
                enabled: false,
                title: '',
                description: '',
              },
              dest_match_columns: [
                {
                  field: 'att__article__code',
                  desc: '[vivalya] Code article',
                },
                {
                  field: 'att__article__libelle',
                  desc: '[vivalya] Libellé article',
                },
                {
                  field: 'att__eaclient__code',
                  desc: '[client] Code article',
                },
                {
                  field: 'att__eaclient__libelle',
                  desc: '[client] Libellé article',
                },
                {
                  field: 'att__eaclient__libelle2',
                  desc: '[client] Libellé 2 article',
                },
                {
                  field: 'att__eaclient__origine',
                  desc: '[client] Origine',
                },
                { field: 'att__id', desc: '[lvadg] ID Article tarif' },
              ],
              dest_update_columns: [
                { field: 'price', desc: 'Prix' },
                { field: 'prevprice', desc: 'Prix tarif précédent' },
                {
                  field: 'evolprice',
                  desc: 'Evolution prix tarif précédent',
                },
                { field: 'article_code', desc: 'Code article Vivalya' },
                {
                  field: 'article_libelle',
                  desc: 'Libellé article Vivalya',
                },
                {
                  field: 'article_libelle_lower',
                  desc: 'Libellé article Vivalya (Title Case)',
                },
                {
                  field: 'article_libelle_client',
                  desc: 'Libellé article spécifique client',
                },
                {
                  field: 'article_uf',
                  desc: 'U. facturation article Vivalya',
                },
                {
                  field: 'article_colisage_full',
                  desc: 'Colisage article Vivalya (nombre conditionnement x poids unité)',
                },
                {
                  field: 'article_colisage_nombre',
                  desc: 'Colisage article Vivalya (nombre)',
                },
                {
                  field: 'article_colisage_cond',
                  desc: 'Colisage article Vivalya (conditionnement)',
                },
                {
                  field: 'article_colisage_poids',
                  desc: 'Colisage article Vivalya (poids)',
                },
                {
                  field: 'article_colisage_unit',
                  desc: 'Colisage article Vivalya (unité)',
                },
                {
                  field: 'article_origin',
                  desc: 'Origine article Vivalya',
                },
                {
                  field: 'article_uci_full',
                  desc: 'UCI article Vivalya',
                },
                {
                  field: 'article_uci_cond',
                  desc: 'UCI article Vivalya (conditionnement)',
                },
                {
                  field: 'article_uci_value',
                  desc: 'UCI article Vivalya (valeur)',
                },
                {
                  field: 'article_uci_unit',
                  desc: 'UCI article Vivalya (unité)',
                },
                {
                  field: 'article_labels',
                  desc: 'Labels article Vivalya',
                },

                { field: 'att_id', desc: 'ID Article tarif' },
                ...st.map((s: StorageBase) => ({
                  field: `storage__${s.cvva}`,
                  desc: `Dispo dépôt ${s.name}`,
                })),
              ],
              dest_create_columns: [],
            },
          };
        }),
        shareReplay(1),
      );
    }
    return this._types;
  }
}
