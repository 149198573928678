import {
  charField,
  DataModel,
  decimalField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum CIQUAL_COLUMN_DISPLAY {
  STARRED = 'ST',
  STARRED_SHORT = 'SS',
  NORMAL = 'NM',
  HIDDEN = 'HD',
}

export class CiqualColumnBase extends DataModel {
  static override readonly __name: string = 'CiqualColumnBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 10,
    priority: 900,
  })
  public code!: string;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 200,
    priority: 800,
  })
  public name!: string;

  @charField({
    name: 'unit',
    description: 'Unité',
    maxLength: 5,
    priority: 700,
  })
  public unit!: string;

  @integerField({
    name: 'order',
    description: 'Ordre (décroissant)',
    defaultValue: 0,
    priority: 600,
  })
  public order: number = 0;

  @charField({
    name: 'group',
    description: 'Groupe',
    defaultValue: '',
    maxLength: 200,
    priority: 500,
  })
  public group: string = '';

  @decimalField({
    name: 'ajr',
    description: 'AJR',
    defaultValue: 0,
    factor: 100000,
    priority: 400,
  })
  public ajr: number = 0;

  @decimalField({
    name: 'maxjr',
    description: 'Max AJR',
    defaultValue: 0,
    factor: 100000,
    priority: 300,
  })
  public maxjr: number = 0;

  @textField({
    name: 'desc',
    description: 'Description',
    maxLength: 5000,
    priority: 200,
  })
  public desc!: string;

  @charField({
    name: 'display',
    description: 'Affichage',
    defaultValue: CIQUAL_COLUMN_DISPLAY.NORMAL,
    required: true,
    maxLength: 2,
    choices: [
      {
        value: CIQUAL_COLUMN_DISPLAY.STARRED,
        desc: 'Starred',
      },
      {
        value: CIQUAL_COLUMN_DISPLAY.STARRED_SHORT,
        desc: 'Starred short',
      },
      {
        value: CIQUAL_COLUMN_DISPLAY.NORMAL,
        desc: 'Normal',
      },
      {
        value: CIQUAL_COLUMN_DISPLAY.HIDDEN,
        desc: 'Hidden',
      },
    ],
    priority: 100,
  })
  public display: CIQUAL_COLUMN_DISPLAY = CIQUAL_COLUMN_DISPLAY.NORMAL;
}
