import { Injectable } from '@angular/core';
import { DataMessageService, FilterData } from '@solidev/data';
import { UserService } from './user.service';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../components/utils/errormessage';

export interface UsersActionResult<T> {
  message: string;
  data?: T;
  success: boolean;
}

interface UsersActionExport {
  action: 'export';
  filter: FilterData;
}

export type UsersAction = UsersActionExport;

@Injectable({
  providedIn: 'root',
})
export class UsersActionService {
  constructor(
    private _msgs: DataMessageService,
    private _users: UserService,
  ) {}

  public async action<T>(
    action: UsersAction,
    messages: Record<string, string> = {},
  ): Promise<UsersActionResult<T>> {
    try {
      const res = await firstValueFrom(
        this._users.action<UsersActionResult<T>>(null, 'POST', 'action', {
          body: {
            ...action,
          },
        }),
      );

      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { message: message, success: false };
    }
  }
}
