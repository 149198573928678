import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OfferNotificationDestination } from '../offer-notification-destination';
import { OfferNotificationDestinationService } from '../offer-notification-destination.service';
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { RestoDisplayComponent } from '../../../structures/resto/resto-display/resto-display.component';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { Resto } from '../../../structures/resto/resto';
import { Contact } from '../../../users/contact/contact';
import { Group } from '../../../users/group/group';
import { User } from '../../../users/user/user';
import { ContactDisplayComponent } from '../../../users/contact/contact-display/contact-display.component';
import {
  OFFERNOTIFICATIONDESTINATION_STATUSES,
  OFFERNOTIFICATIONDESTINATION_TARGET,
} from '../offer-notification-destination.base';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { Client } from '../../../structures/client/client';
import { Storage } from '../../../structures/storage/storage';
import { IconComponent } from '../../../../components/utils/icon/icon.component';

@Component({
  selector: 'lvadg-offer-notification-destination-list',
  standalone: true,
  templateUrl: './offer-notification-destination-list.component.pug',
  styleUrls: ['./offer-notification-destination-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    RestoDisplayComponent,
    UserDisplayComponent,
    GroupDisplayComponent,
    ContactDisplayComponent,
    ClientDisplayComponent,
    StorageDisplayComponent,
    PChoicePipe,
    IconComponent,
  ],
})
export class OfferNotificationDestinationListComponent extends ModellistComponent<OfferNotificationDestination> {
  @Input() public resto_detail_route?: Link<Resto>;
  @Input() public user_detail_route?: Link<User>;
  @Input() public group_detail_route?: Link<Group>;
  @Input() public contact_detail_route?: Link<Contact>;
  @Input() public client_detail_route?: Link<Client>;
  @Input() public storage_detail_route?: Link<Storage>;
  public readonly ONDS = OFFERNOTIFICATIONDESTINATION_STATUSES;

  constructor(coll: OfferNotificationDestinationService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'target', 'status'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'target',
          name: 'target',
          label: 'Filtrer par cible',
          model: OfferNotificationDestination,
        }),

        new ModelListSelectFilter({
          field: 'status',
          name: 'status',
          label: 'Filtrer par état',
          model: OfferNotificationDestination,
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          label: 'Filtrer par type',
          model: OfferNotificationDestination,
        }),
      ],
    };
  }

  public override getRowClasses(row: OfferNotificationDestination): string[] {
    const cls = super.getRowClasses(row);
    if (
      row.status === OFFERNOTIFICATIONDESTINATION_STATUSES.INACTIVE ||
      row.status === OFFERNOTIFICATIONDESTINATION_STATUSES.EMPTY
    ) {
      cls.push('table-secondary text-muted');
    }
    if (row.status === OFFERNOTIFICATIONDESTINATION_STATUSES.REMOVED) {
      cls.push('text-secondary');
    }
    if (row.status === OFFERNOTIFICATIONDESTINATION_STATUSES.ERRORS) {
      cls.push('text-danger');
    }
    if (row.target === OFFERNOTIFICATIONDESTINATION_TARGET.GENERIC) {
      cls.push('text-primary');
    }

    return cls;
  }
}
