
<ng-template #qrModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">QR Code / URL publique</h4>
    <button class="btn-close" type="button" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="qrForm">
      <div class="col-4">
        <label for="size">Taille</label>
        <select class="form-select" formControlName="size" id="size">
          <option *ngFor="let sopt of QRS" [ngValue]="sopt.value">{{sopt.desc}}</option>
        </select>
      </div>
      <div class="col-4">
        <label for="color">Couleur</label>
        <select class="form-select" formControlName="color" id="color">
          <option *ngFor="let copt of QRC" [ngValue]="copt.value">{{copt.desc}}</option>
        </select>
      </div>
      <div class="col-4">
        <label for="logo">Logo</label>
        <select class="form-select" formControlName="logo" id="logo">
          <option *ngFor="let lopt of QRL" [ngValue]="lopt.value">{{lopt.desc}}</option>
        </select>
      </div>
    </form>
    <ng-container *ngIf="iocode(); let io">
      <div class="mt-3 text-center" *ngIf="qrcodeUrl(); let url"><img class="img-fluid" [src]="url" [alt]="io.code" [title]="io.code"></div>
      <div class="text-info text-center">Clic droit sur l'image pour la télécharger</div><code class="d-block my-3 text-center fs-2">{{io.iourl}}</code><a class="d-block text-center" [href]="io.iourl" target="_blank">{{io.iourl}}</a>
    </ng-container>
  </div>
</ng-template>@if(model()) {<span role="button" (click)="getIoCode().then()">
  <ng-content></ng-content></span>}