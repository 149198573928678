import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OrderprocessstoragePermission } from '../orderprocessstorage-permission';
import { OrderprocessstoragePermissionService } from '../orderprocessstorage-permission.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { OrderprocessBase } from '../../orderprocess/orderprocess.base';
import { OrderprocessstorageBase } from '../../orderprocessstorage/orderprocessstorage.base';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { OrderprocessstorageDisplayComponent } from '../../orderprocessstorage/orderprocessstorage-display/orderprocessstorage-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { PlatformDisplayComponent } from '../../../structures/platform/platform-display/platform-display.component';
import { OrderprocessDisplayComponent } from '../../orderprocess/orderprocess-display/orderprocess-display.component';
import { StorageBase } from '../../../structures/storage/storage.base';
import { PlatformBase } from '../../../structures/platform/platform.base';

@Component({
  selector: 'lvadg-orderprocessstorage-permission-list',
  standalone: true,
  templateUrl: './orderprocessstorage-permission-list.component.pug',
  styleUrls: ['./orderprocessstorage-permission-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    UserDisplayComponent,
    GroupDisplayComponent,
    OrderprocessstorageDisplayComponent,
    StorageDisplayComponent,
    PlatformDisplayComponent,
    OrderprocessDisplayComponent,
  ],
})
export class OrderprocessstoragePermissionListComponent extends ModellistComponent<OrderprocessstoragePermission> {
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public platform_detail_route?: Link<PlatformBase>;
  @Input() public orderprocess_detail_route?: Link<OrderprocessBase>;
  @Input()
  public orderprocessstorage_detail_route?: Link<OrderprocessstorageBase>;

  constructor(
    coll: OrderprocessstoragePermissionService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
