import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
  TabMemoryService,
} from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Member } from "../../../../../models/structures/member/member";
import { MEMBER_ROUTES } from "../menu";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProducerListComponent } from "../../../../../models/structures/producer/producer-list/producer-list.component";
import { MemberNavComponent } from "../_nav/member-nav.component";
import { map, Observable, Subject } from "rxjs";
import { ProducerBase } from "../../../../../models/structures/producer/producer.base";
import { StorageBase } from "../../../../../models/structures/storage/storage.base";
import { CdataKey } from "../../../../../models/cdata/cdata-key/cdata-key";

export interface MemberProducersViewParams extends BaseRouteParams {
  m_menu: MEMBER_ROUTES;
  producer_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
}

export interface MemberProducersViewData extends MemberProducersViewParams {
  member: Member;
  producers_cdatakeys: CdataKey[];
}

@Component({
  selector: "lvadg-member-producers-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProducerListComponent,
    MemberNavComponent,
  ],
  templateUrl: "./member-producers-view.component.pug",
  styleUrls: ["./member-producers-view.component.sass"],
})
export class MemberProducersViewComponent extends BaseDetailViewComponent<
  MemberProducersViewData,
  Member
> {
  public memberFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public producer_detail_route?: Link<ProducerBase>;
  public storage_detail_route?: Link<StorageBase>;
  public default_fields = [
    "id",
    "name",
    "logo_details",
    "nb_products",
    // 'slabels_details',
    "plabels_details",
    "storage_relations_details",
    "location_postcode",
    "location_city",
    "activity", // TODO: activity stats
    "documents_details", // TODO: documents links
    "status",
    "flags",
  ];

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: MemberProducersViewData) {
    super.setRouteData(data);
    if (data.producer_detail_route) {
      this.producer_detail_route = new Link(
        data.producer_detail_route,
        data,
        this._router,
      );
    }
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link(
        data.storage_detail_route,
        data,
        this._router,
      );
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.memberFilter$ = this.data$.pipe(
      map((data) => ({ member: data.member.id })),
    );
  }
}
