
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <h4 class="offcanvas-title" *ngIf="selected">Édition produit : {{selected.name}}</h4>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <lvadg-product-manage [product]="selected" *ngIf="selected"></lvadg-product-manage>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <lvadg-products-map [list]="list" [filter]="filter" *ngIf="localMapStatus()"></lvadg-products-map>
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.flags}}</td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="type" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name" [sort]="true" [sortField]="'name'">
              <div>Produit<i class="bi bi-grid-fill ms-2" (click)="simple_name=!simple_name" role="button"></i></div>
            </data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div class="d-flex flex-row justify-content-start align-items-center" *ngIf="!simple_name">
              <lvadg-image-display class="me-2" *ngIf="row.clogo_details" [image]="row.clogo_details" mode="list-image" [thumbnail]="false" [hover]="false"></lvadg-image-display>
              <div><a class="fw-bold text-decoration-none" [routerLink]="detailRoute(row)">
                  <ng-container *ngIf="detailRoute(row)"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container>{{row.name}}</a>
                <div class="small text-muted">
                  <lvadg-family-display [family]="row.family_details" mode="line" [start]="2" [detail_route]="family_detail_route"></lvadg-family-display>
                </div>
              </div>
            </div><a class="fw-bold text-decoration-none" [routerLink]="detailRoute(row)" *ngIf="simple_name">{{row.name}}</a>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="seasons">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="seasons" [sort]="false">
              <div>Saisons<i class="bi bi-grid-fill ms-2" (click)="toggleSeasons()" role="button"></i></div>
            </data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-seasons-manage [seasons]="row.seasons" [per_line]="seasons_perline" [zone]="row.type"></lvadg-seasons-manage>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="egalim_status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="egalim_status"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'egalim_status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="logo">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="logo" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.logo}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="content_src">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="content_src"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.content_src">{{row.content_src|shorten:30:'…'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="content_html">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="content_html"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.content_html">{{row.content_html|stripTags|shorten:30:'…'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="content_type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="content_type"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'content_type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="logo_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="logo_details" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-image-display *ngIf="row.logo_details" [image]="row.logo_details" mode="list"></lvadg-image-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="clogo_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="clogo_details" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-image-display *ngIf="row.clogo_details" [image]="row.clogo_details" mode="list-preview"></lvadg-image-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="family">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="family" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.family}}</td>
        </ng-container>
        <ng-container cdkColumnDef="producer">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.producer}}</td>
        </ng-container>
        <ng-container cdkColumnDef="provider">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="provider" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.provider}}</td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="images">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="images" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.images}}</td>
        </ng-container>
        <ng-container cdkColumnDef="labels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="labels" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let l of row.labels">
              <ng-container *ngIf="labels$$.get(l)|async; let label">{{label.code}}</ng-container>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="labels_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="labels_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-label-display *ngFor="let l of row.labels_details" [label]="l" [mode]="row.labels_details?.length &gt; 1 ? 'list-medium':'medium'" [thumbnail]="false" [hover]="false"></lvadg-label-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="producer_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_details" [sort]="true" [sortField]="'producer__name'"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-producer-display [producer]="row.producer_details" mode="linewcpville" [detail_route]="producer_detail_route"></lvadg-producer-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="storage_relations_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div><span>Dépôt(s)</span><i class="bi bi-file-excel ms-1" role="button" (click)="xlsxMode=!xlsxMode"></i></div>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let r of row.storage_relations_details">
              <lvadg-relation-display [relation]="r" mode="line" [distance]="!xlsxMode" [location]="!xlsxMode" [storage_detail_route]="storage_detail_route"></lvadg-relation-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="family_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="family_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-family-display [family]="row.family_details" mode="line" [detail_route]="family_detail_route"></lvadg-family-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="producer_postcode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_postcode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.producer_postcode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="producer_city">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_city"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.producer_city|uppercase}}</td>
        </ng-container>
        <ng-container cdkColumnDef="producer_dptcode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_dptcode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.producer_dptcode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="producer_dptname">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_dptname"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.producer_dptname}}</td>
        </ng-container>
        <ng-container cdkColumnDef="producer_location_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="producer_location_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-location-display [location]="row.producer_location_details" mode="line"></lvadg-location-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="search_producer_near_distances">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div><span>Proximité producteur</span><i class="bi bi-file-excel ms-1" role="button" (click)="xlsxMode=!xlsxMode"></i></div><small *ngIf="producerDistanceRef$|async; let distanceRef"><i class="bi bi-pin-map me-2"></i>{{distanceRef.label}}<span *ngIf="xlsxMode">&nbsp;&leftrightarrow;&nbsp;Producteur</span></small>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="producerDistanceRef$|async; let distanceRef">
              <div class="d-flex flex-row justify-content-between" *ngIf="row.producer_location_details||row.producer_details?.location_details"><span *ngIf="!xlsxMode"><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;Producteur</span></span>
                <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[row.producer_location_details?.lng||row.producer_details?.location_details?.lng, row.producer_location_details?.lat||0]" [mode]="xlsxMode?'raw':'pretty'"></lvadg-distance-cache-display>
              </div>
              <ng-container *ngIf="!xlsxMode">
                <div *ngFor="let r of row.storage_relations_details">
                  <div class="d-flex flex-row justify-content-between"><span><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;{{r.storage_details?.name}}</span></span>
                    <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[r.storage_details?.location_details?.lng||0, r.storage_details?.location_details?.lat||0]"></lvadg-distance-cache-display>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="search_storage_near_distances">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div><span>Proximité dépôt(s)</span><i class="bi bi-file-excel ms-1" role="button" (click)="xlsxMode=!xlsxMode"></i></div><small *ngIf="storageDistanceRef$|async; let distanceRef"><i class="bi bi-pin-map me-2"></i>{{distanceRef.label}}<span *ngIf="xlsxMode">&nbsp;&leftrightarrow;&nbsp;Dépôt(s)</span></small>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="storageDistanceRef$|async; let distanceRef">
              <ng-container *ngIf="!xlsxMode">
                <div class="d-flex flex-row justify-content-between"><span><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;Producteur</span></span>
                  <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[row.producer_location_details?.lng||0, row.producer_location_details?.lat||0]"></lvadg-distance-cache-display>
                </div>
              </ng-container>
              <div *ngFor="let r of row.storage_relations_details">
                <div class="d-flex flex-row justify-content-between"><span *ngIf="!xlsxMode || (xlsxMode &amp;&amp; row.storage_relations_details.length&gt;1)"><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;{{r.storage_details?.name}}</span></span>
                  <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[r.storage_details?.location_details?.lng||0, r.storage_details?.location_details?.lat||0]" [mode]="xlsxMode?'raw':'pretty'"></lvadg-distance-cache-display>
                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="images_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="images_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let im of row.images_details">
              <lvadg-image-display class="d-inline-block m-1" [image]="im" mode="list"></lvadg-image-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="cimages_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cimages_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let im of row.cimages_details">
              <lvadg-image-display class="d-inline-block m-1" [image]="im" mode="list"></lvadg-image-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="documents_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="documents_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div *ngFor="let d of row.documents_details">
              <lvadg-producer-document-display [producerdocument]="d" mode="line"></lvadg-producer-document-display>
            </div>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="actions"><span>Actions</span>
              <lvadg-icon class="ms-1" ri="edit" [icon_only]="true" role="button" (click)="displayEditors.set(!displayEditors())" *ngIf="haveAction('edit')"></lvadg-icon>
            </data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <button class="btn btn-primary" *ngIf="haveAction('offer_add') &amp;&amp; !row.in_offer" (click)="action.emit({action:'offer_add', model:row})">
              <lvadg-icon ri="add">Ajouter à l'offre</lvadg-icon>
            </button>
            <lvadg-quickprint-button *ngIf="haveAction('print_affiche')" [model]="row" [print_settings]="print_settings" [print_context]="print_context" [list]="list" [filter]="filter" [template]="'product_poster'" [title]="'Impression affiche produit'">
              <lvadg-icon class="px-2" ri="poster" [icon_only]="true"></lvadg-icon>
            </lvadg-quickprint-button>
            <lvadg-quickprint-button *ngIf="haveAction('print_fiche')" [model]="row" [print_settings]="print_settings" [print_context]="print_context" [list]="list" [filter]="filter" [template]="'product_detail'" [title]="'Impression fiche produit'">
              <lvadg-icon class="px-2" ri="fiche" [icon_only]="true"></lvadg-icon>
            </lvadg-quickprint-button>
            <lvadg-icon class="px-1 mx-1" ri="edit" role="button" [icon_only]="true" *ngIf="haveAction('edit') &amp;&amp; row.have_level(PUL.WRITE) &amp;&amp; displayEditors()" (click)="editProduct(row)"></lvadg-icon>
            <data-safe-delete *ngIf="haveAction('delete')&amp;&amp; row.have_level(PUL.WRITE) &amp;&amp; displayEditors()" [model]="row" [service]="coll" display="icon" title="Supprimer ?" (deleted)="reload!.next(true)"></data-safe-delete>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="in_offer">
          <th cdk-header-cell *cdkHeaderCellDef><i class="bi bi-check" title="Produit présent dans l'offre"></i></th>
          <td cdk-cell *cdkCellDef="let row"><i class="bi bi-check" *ngIf="row.in_offer"></i></td>
        </ng-container>
        <ng-container cdkColumnDef="select">
          <th cdk-header-cell *cdkHeaderCellDef>
            <lvadg-select-header [selection]="selection" [list]="list" [filter]="filter" [filter_filter]="print_filters_whitelist()" [print_context]="print_context" [print_settings]="print_settings"></lvadg-select-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-select-item [selection]="selection" [item]="row"></lvadg-select-item>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="user_level">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="user_level"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.user_level}}</td>
        </ng-container>
        <ng-container cdkColumnDef="iocode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="iocode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-iocode-display [iocode]="row.iocode" mode="link"></lvadg-iocode-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="ciqual_alim">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ciqual_alim"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.ciqual_alim}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="ciqual_alim_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ciqual_alim_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <p class="text-info">Voir détail produit</p>
          </td>
        </ng-container>
        <ng-container *ngFor="let d of cdatakeys">
          <ng-container cdkColumnDef="cdata_{{d.id}}">
            <th cdk-header-cell *cdkHeaderCellDef>
              <data-model-list-field-header [list]="list" field="cdata_{{d.id}}"></data-model-list-field-header>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let cd of row.cdata_details">
                <ng-container *ngIf="cd.key===d.id">
                  <lvadg-cdata-display [cdata]="cd" mode="list"></lvadg-cdata-display>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <ng-container cdkColumnDef="cdata_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cdata_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div class="text-info">Voir les colonnes individuelles</div>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>