import { RnmcotationBase } from './rnmcotation.base';
import { detailsField } from '@solidev/data';
import { RnmmarcheBase } from '../rnmmarche/rnmmarche.base';

export class Rnmcotation extends RnmcotationBase {
  static override readonly __name: string = 'Rnmcotation';

  @detailsField({
    model: RnmmarcheBase,
    description: 'Marché',
    readonly: true,
  })
  public marche_details?: RnmmarcheBase;
}
