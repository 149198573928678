import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { WsService } from '../ws.service';
import { BroadcastMessage } from '../rstypes/BroadcastMessage';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveProgressComponent } from '../live-progress/live-progress.component';

@Component({
  selector: 'lvadg-live-message',
  standalone: true,
  imports: [NgIf, NgbAlertModule, LiveProgressComponent],
  templateUrl: './live-message.component.pug',
  styleUrls: ['./live-message.component.sass'],
})
export class LiveMessageComponent {
  // @ViewChild('liveMessage', { static: false }) staticAlert: NgbAlert;
  constructor(private _ws: WsService) {
    this._ws.broadcasts$().subscribe((message: BroadcastMessage) => {
      console.log('message', message);
    });
  }
}
