
<ng-container *ngIf="data$|async; let data">
  <ng-container *ngIf="data.display==='flat'">
    <lvadg-folder-list [name]="data.route.name" [reload]="reload$" [detail_route]="detail_route"></lvadg-folder-list>
  </ng-container>
  <ng-container *ngIf="data.display==='tree'">
    <div class="treedetail d-flex flex-row">
      <div class="tree me-2">
        <lvadg-folder-tree [name]="data.treeName" [detail_route]="detail_route" [reload]="reload$" [open]="currentFolder$" (selected)="current=$event"></lvadg-folder-tree>
      </div>
      <div class="details flex-grow-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
</ng-container>