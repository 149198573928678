
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { CiqualAlimService} from "./ciqual-alim.service";
import { CiqualAlim } from "./ciqual-alim";

@Injectable({
  providedIn: 'root'
})
export class CiqualAlimResolver  {
  constructor(private _rs: CiqualAlimService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CiqualAlim> {
    return this._rs.fetch(+route.params["ciqualalimId"])
  }
}
