import { FolderPermissionBase } from './folder-permission.base';
import { detailsField } from '@solidev/data';
import { User } from '../../users/user/user';
import { Group } from '../../users/group/group';

export class FolderPermission extends FolderPermissionBase {
  static override readonly __name: string = 'FolderPermission';

  @detailsField({
    name: 'user_details',
    description: 'Utilisateur',
    model: User,
    readonly: true,
    priority: 761,
  })
  public user_details!: User;

  @detailsField({
    name: 'group_details',
    description: 'Groupe',
    model: Group,
    readonly: true,
    priority: 760,
  })
  public group_details!: Group;
}
