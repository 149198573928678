import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProducerDocument } from '../producer-document';
import { DispeditComponent, FlagsComponent } from '@solidev/data';
import { LabelService } from '../../../catalog/label/label.service';
import { ProductService } from '../../../catalog/product/product.service';
import { ProducerService } from '../../producer/producer.service';
import { ImageDisplayComponent } from '../../../documents/image/image-display/image-display.component';

@Component({
  selector: 'lvadg-producer-document-manage',
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    ImageDisplayComponent,
  ],
  templateUrl: './producer-document-manage.component.pug',
  styleUrls: ['./producer-document-manage.component.sass'],
})
export class ProducerDocumentManageComponent {
  @Input() public producerdocument?: ProducerDocument;

  constructor(
    public labels$: LabelService,
    public products$: ProductService,
    public producers$: ProducerService
  ) {}
}
