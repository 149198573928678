import { Component, model } from "@angular/core";

export interface FamilyDisplayParams {
  display: boolean;
  mode: "line" | "button" | "path";
  start: number;
  end: number;
}

@Component({
  selector: "lvadg-family-display-list-header",
  standalone: true,
  imports: [],
  template: `
    @if (display().display) {
      <i
        role="button"
        class="bi bi-caret-left-fill"
        [class.disabled]="display().start === 0"
        (click)="familyDispUpdate('start', -1)"
      ></i>
      <i
        role="button"
        class="bi bi-caret-right-fill"
        [class.disabled]="display().start === display().end - 1"
        (click)="familyDispUpdate('start', 1)"
      ></i>
    }
    <span class="mx-2">Famille</span>
    @if (display().display) {
      <i
        role="button"
        class="bi bi-caret-left-fill"
        [class.disabled]="display().start === display().end - 1"
        (click)="familyDispUpdate('end', -1)"
      ></i>
      <i
        role="button"
        class="bi bi-caret-right-fill"
        [class.disabled]="display().end === 5"
        (click)="familyDispUpdate('end', 1)"
      ></i>
    }
  `,
  styleUrl: "./family-display-list-header.component.sass",
})
export class FamilyDisplayListHeaderComponent {
  public display = model<FamilyDisplayParams>({
    display: true,
    mode: "line",
    start: 0,
    end: 5,
  });

  public familyDispUpdate = (key: "start" | "end", value: number) => {
    this.display.update((val) => {
      const start = val.start;
      const end = val.end;
      if (key === "start") {
        if (start + value < 0) {
          return val;
        }
        if (start + value > end) {
          return val;
        }
        return {
          ...val,
          start: start + value,
        };
      } else {
        if (end + value > 5) {
          return val;
        }
        if (end + value < start) {
          return val;
        }
        return {
          ...val,
          end: val.end + value,
        };
      }
    });
  };
}
