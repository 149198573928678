
<ng-container *ngIf="ordergroup$|async; let ordergroup">
  <ng-container *ngIf="groupby==='article'">
    <button class="btn btn-primary float-end"><i class="bi bi-printer me-2"></i>Imprimer la fiche (TODO)</button>
    <h1>Regroupement par article</h1>
    <div *ngFor="let a of articles$|async">
      <h2>{{a.article_details?.libelle}}</h2>
      <lvadg-orderitem-list [name]="name+'_'+groupby+'_'+a.id" [filter]="{group: ordergroup.id, item: a.id}" [quantityEditor]="true" [dispStats]="true" [default_fields]="orderitems_articles_default_fields" [keep]="false" [dispFilters]="false" [dispPagination]="false" [dispFieldsSelector]="false" [dispFooters]="true" [dispLoading]="false" [storage_detail_route]="storage_detail_route" [platform_detail_route]="platform_detail_route" [order_detail_route]="order_detail_route" [article_detail_route]="article_detail_route" [tarif_detail_route]="tarif_detail_route"></lvadg-orderitem-list>
    </div>
  </ng-container>
  <ng-container *ngIf="groupby==='platform'">
    <button class="btn btn-primary float-end"><i class="bi bi-printer me-2"></i>Imprimer la fiche (TODO)</button>
    <h1>Regroupement par plateforme</h1>
    <div *ngFor="let a of platforms$|async">
      <h2>{{a.name}}</h2>
      <lvadg-orderitem-list [name]="name+'_'+groupby+'_'+a.id" [filter]="{group: ordergroup.id, platform: a.id}" [quantityEditor]="true" [dispStats]="true" [default_fields]="orderitems_platforms_default_fields" [keep]="false" [dispFilters]="false" [dispPagination]="false" [dispFieldsSelector]="false" [dispFooters]="true" [dispLoading]="false" [storage_detail_route]="storage_detail_route" [platform_detail_route]="platform_detail_route" [order_detail_route]="order_detail_route" [article_detail_route]="article_detail_route" [tarif_detail_route]="tarif_detail_route"></lvadg-orderitem-list>
    </div>
  </ng-container>
</ng-container>