
<ng-container *ngIf="step()==STEPS.SIREN">
  <div class="dheader">
    <nav class="nav nav-pills"><span class="w-25 nav-link active" role="button">1. Recherche par SIREN</span></nav>
  </div>
  <div class="dcontent">
    <p class="text-info">Vous êtes sur le point de créer un producteur. Pour éviter les doublons, nous avons besoin de son numéro SIREN. Pour les producteurs étrangers, merci de contacter Javier.</p>
    <form [formGroup]="sirenForm">
      <div class="form-group">
        <label class="fs-4" for="siren">Numéro SIREN du producteur</label>
        <input class="form-control form-control-lg fs-4" id="siren" type="text" formControlName="siren" placeholder="SIREN">
        <div class="invalid-feedback"><span *ngIf="sirenForm.get('siren')?.hasError('required')">Le SIREN est obligatoire.</span><span *ngIf="sirenForm.get('siren')?.hasError('minlength')">Le SIREN doit contenir 9 chiffres.</span><span *ngIf="sirenForm.get('siren')?.hasError('maxlength')">Le SIREN doit contenir 9 chiffres.</span><span *ngIf="sirenForm.get('siren')?.hasError('siren')">Ce SIREN n'est pas valide.</span></div>
      </div>
    </form>
  </div>
  <div class="dactions">
    <button class="btn btn-primary btn-block flex-grow-1 w-100" type="button" (click)="searchSiren()" [disabled]="sirenForm.pristine||sirenForm.invalid">
      <lvadg-icon ri="search">Rechercher</lvadg-icon>
    </button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelled.emit()">Annuler</button>
  </div>
</ng-container>
<ng-container *ngIf="step()==STEPS.FOUND_SIREN_CONFIRM">
  <div class="dheader">
    <nav class="nav nav-pills"><span class="w-25 nav-link" role="button">1. Recherche par SIREN</span><span class="w-25 nav-link active" role="button">2. Confirmation</span></nav>
  </div>
  <div class="dcontent">
    <p class="text-success fs-4">Un producteur a été trouvé avec ce numéro SIREN. Merci de vérifier les informations suivantes.</p>
    <div class="row fs-4" *ngIf="producer(); let p">
      <div class="col-4">
        <dl>
          <dt>Nom</dt>
          <dd>{{p.name}}</dd>
        </dl>
      </div>
      <div class="col-4">
        <dl>
          <dt>Adresse</dt>
          <dd>{{p.location_details?.address}}</dd>
        </dl>
      </div>
      <div class="col-4">
        <dl>
          <dt>Code postal / Ville</dt>
          <dd>{{p.location_details?.postcode}} {{p.location_details?.city}}</dd>
        </dl>
      </div>
      <div class="col-12" *ngIf="p.storage_relations_details &amp;&amp; p.storage_relations_details.length&gt;0">
        <dl>
          <dt>Dépôts associés</dt>
          <dd><span class="badge bg-primary fs-3 m-1" *ngFor="let s of p.storage_relations_details">{{s.storage_details?.name}}<span class="ms-1" *ngIf="s.distance_details?.road; let distance">({{distance/1000|number:'1.0-0'}} km)</span></span></dd>
        </dl>
      </div>
    </div>
  </div>
  <div class="dactions">
    <button class="btn btn-primary w-50" type="button" (click)="foundSirenConfirm()">Continuer avec ce producteur</button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="step.set(STEPS.SIREN)">Retour</button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelled.emit()">Annuler</button>
  </div>
</ng-container>
<ng-container *ngIf="step()==STEPS.CREATE_PRODUCER">
  <div class="dheader">
    <nav class="nav nav-pills"><span class="w-25 nav-link" role="button">1. Recherche par SIREN</span><span class="w-25 nav-link active" role="button">2. Création</span></nav>
  </div>
  <div class="dcontent">
    <p class="text-warning fs-3">Aucun producteur n'a été trouvé avec ce numéro SIREN. Merci de compléter les informations suivantes. Tous les champs sont obligatoires.</p>
    <form class="row fs-4 g-3" [formGroup]="producerForm">
      <div class="form-group col-12">
        <label for="name">Nom du producteur</label>
        <input class="form-control form-control-lg" id="name" type="text" formControlName="name" placeholder="Nom du producteur">
        <div class="invalid-feedback"><span *ngIf="producerForm.get('name')?.hasError('required')">Le nom est obligatoire.</span></div>
      </div>
      <div class="form-group col-12">
        <label for="description">Texte de présentation du producteur, de ses produits, de son activité, etc.</label>
        <textarea class="form-control form-control-lg" id="description" type="text" formControlName="description" placeholder="Description"></textarea>
        <div class="invalid-feedback"><span *ngIf="producerForm.get('description')?.hasError('required')">La description est obligatoire.</span><span *ngIf="producerForm.get('description')?.hasError('minlength')">La description est trop courte</span></div>
      </div>
      <div class="form-group col-12">
        <label for="address">Adresse</label>
        <input class="form-control form-control-lg" id="address" type="text" formControlName="address" placeholder="Adresse">
        <div class="invalid-feedback"><span *ngIf="producerForm.get('address')?.hasError('required')">L'adresse est obligatoire.</span></div>
      </div>
      <div class="form-group col-6">
        <label for="postcode">Code postal</label>
        <input class="form-control form-control-lg" id="postcode" type="text" formControlName="postcode" placeholder="Code postal">
        <div class="invalid-feedback"><span *ngIf="producerForm.get('postcode')?.hasError('required')">Le code postal est obligatoire.</span></div>
      </div>
      <div class="form-group col-6">
        <label for="city">Ville</label>
        <input class="form-control form-control-lg" id="city" type="text" formControlName="city" placeholder="Ville">
        <div class="invalid-feedback"><span *ngIf="producerForm.get('city')?.hasError('required')">La ville est obligatoire.</span></div>
      </div>
      <ng-container *ngIf="geoLocation(); let g">
        <div class="col text-success">
          <dl>
            <dt>Adresse géolocalisée</dt>
            <dd>{{g.label}} (score : {{g.score*100|number:'1.0-0'}}%)</dd>
          </dl>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="dactions">
    <button class="btn btn-primary flex-grow-1" type="button" (click)="createProducer()" [disabled]="producerForm.pristine||producerForm.invalid">Continuer</button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="step.set(STEPS.SIREN)">Retour</button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelled.emit()">Annuler</button>
  </div>
</ng-container>
<ng-container *ngIf="step()==STEPS.ASSOCIATE_STORAGES">
  <div class="dheader">
    <nav class="nav nav-pills"><span class="w-25 nav-link" role="button">1. Recherche par SIREN</span><span class="w-25 nav-link" role="button" *ngIf="producer()?.id">2. Confirmation</span><span class="w-25 nav-link" role="button" *ngIf="!producer()?.id">2. Création</span><span class="w-25 nav-link active" role="button">3. Sélection des dépôts</span></nav>
  </div>
  <div class="dcontent">
    <p class="text-info fs-4">Sélectionnez les dépôts à associer à ce producteur. Vous pouvez sélectionner plusieurs dépôts, seuls les dépôts à moins de 200 km seront affichés pour les F&L locaux.</p>
    <div class="row g-1">
      <div class="col-6 col-md-3" *ngFor="let ass of associations()">
        <button class="btn w-100 py-2" [class.btn-primary]="ass.association" [class.btn-outline-primary]="!ass.association" [disabled]="ass.readonly" (click)="associateStorage(ass.storage)">
          <div>{{ass.storage.name}}</div>
          <div *ngIf="geoLocation(); let gl">
            <ng-container *ngIf="gl.lat &amp;&amp; gl.lng &amp;&amp; ass.storage.location_details">
              <lvadg-distance-cache-display [start]="[gl.lng, gl.lat]" [end]="[ass.storage.location_details.lng, ass.storage.location_details.lat]"></lvadg-distance-cache-display>
            </ng-container>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="dactions">
    <button class="btn btn-primary flex-grow-1" type="button" (click)="associateStorages()" [disabled]="!associated()">Continuer</button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelled.emit()">Annuler</button>
  </div>
</ng-container>
<ng-container *ngIf="step()==STEPS.SUBMIT">
  <div class="dheader">
    <nav class="nav nav-pills"><span class="w-25 nav-link" role="button">1. Recherche par SIREN</span><span class="w-25 nav-link" role="button" *ngIf="producer()?.id">2. Confirmation</span><span class="w-25 nav-link" role="button" *ngIf="!producer()?.id">2. Création</span><span class="w-25 nav-link" role="button">3. Sélection des dépôts</span><span class="w-25 nav-link active" role="button">4. Confirmation</span></nav>
  </div>
  <div class="dcontent">
    <p class="text-info fs-4">Merci de vérifier les informations suivantes avant de valider la création du producteur.</p>
    <div class="row fs-4">
      <div class="col-6" *ngIf="producer(); let p">
        <dl>
          <dt>Nom</dt>
          <dd>{{p.name}}</dd>
          <dt>Adresse</dt>
          <dd>{{p.location_details?.address}} {{p.location_details?.postcode}} {{p.location_details?.city}}</dd>
        </dl>
      </div>
      <div class="col-6" *ngIf="associations(); let a">
        <dl>
          <dt>Dépôts associés</dt>
          <dd>
            <ul>
              <ng-container *ngFor="let ass of a">
                <li *ngIf="ass.association" [class.text-success]="!ass.readonly">{{ass.storage.name}}</li>
              </ng-container>
            </ul>
          </dd>
        </dl>
      </div>
    </div>
  </div>
  <div class="dactions">
    <button class="btn btn-primary flex-grow-1" type="button" (click)="submit()">Valider</button>
    <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelled.emit()">Annuler</button>
  </div>
</ng-container>