import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfferDocument } from "../offer-document";

@Component({
  selector: "lvadg-offer-document-manage",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./offer-document-manage.component.pug",
  styleUrls: ["./offer-document-manage.component.sass"],
})
export class OfferDocumentManageComponent implements OnInit {
  @Input() public offerdocument?: OfferDocument;

  constructor() {}

  ngOnInit(): void {}
}
