import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CommentService} from "./comment.service";
import {Comment} from "./comment";

@Injectable({
    providedIn: 'root'
})
export class CommentResolver {
    constructor(private _rs: CommentService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Comment> {
        return this._rs.fetch(+route.params["commentId"])
    }
}
