
<form [formGroup]="formGroup" (submit)="create()">
  <div class="row g-3">
    <data-dispedit class="col-2" [form]="formGroup" [model]="contact" field="title" mode="form">Titre</data-dispedit>
    <data-dispedit class="col-5" [form]="formGroup" [model]="contact" field="first_name" mode="form"><span class="fw-bold">Prénom</span></data-dispedit>
    <data-dispedit class="col-5" [form]="formGroup" [model]="contact" field="last_name" mode="form"><span class="fw-bold">Nom de famille</span></data-dispedit>
    <data-dispedit class="col-4" [form]="formGroup" [model]="contact" field="jobname" mode="form">Poste</data-dispedit>
    <data-dispedit class="col-8" [form]="formGroup" [model]="contact" field="email" mode="form"><span class="fw-bold">Email</span></data-dispedit>
    <data-dispedit class="col-4" [form]="formGroup" [model]="contact" field="phone" mode="form">Téléphone</data-dispedit>
    <data-dispedit class="col-4" [form]="formGroup" [model]="contact" field="mobile" mode="form">Mobile</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="formGroup.pristine||formGroup.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>