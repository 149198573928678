import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrintService} from '../print.service';
import {NgbOffcanvas} from '@ng-bootstrap/ng-bootstrap';
import {Print} from '../print';
import {DataMessageService, Link} from '@solidev/data';
import {RouterLink} from '@angular/router';

/**
 * Affiche une icône d'impression.
 * Au clic, affiche un panneau latéral avec la liste des impressions disponibles.
 */
@Component({
  selector: 'lvadg-print-current-icon',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './print-current-icon.component.pug',
  styleUrls: ['./print-current-icon.component.sass'],
})
export class PrintCurrentIconComponent {
  public available$ = this.prints$.available$;
  public current$ = this.prints$.current$;
  /** Route vers la vue détaillée d'une impression' */
  @Input() public print_detail_route?: Link<Print>;
  /** Route vers la liste des impressions' */
  @Input() public print_list_route?: Link<never>;
  /** Template du panneau latéral */
  @ViewChild('ofcslot', { static: true }) private ofcTemplate!: ElementRef<HTMLElement>;

  constructor(
    public prints$: PrintService,
    private ofc: NgbOffcanvas,
    private _msgs: DataMessageService,
  ) {}

  public open() {
    this.ofc.open(this.ofcTemplate, { position: 'end' });
  }

  /**
   * Crée une nouvelle impression, en mode préparation et la sélectionne en tant qu'impression courante.
   * Rafraîchit la liste des impressions disponibles.
   */
  public create() {
    this.prints$
      .create({ status: 'PRP' })
      .save()
      .subscribe(async (p) => {
        await this.prints$.setCurrent(p);
        this._msgs.success('Nouvelle impression créée avec succès');
      });
  }

  public async select(p: Print) {
    await this.prints$.setCurrent(p);
  }
}
