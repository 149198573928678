
import {Component} from '@angular/core';
import {ModellistComponent} from "../../../../includes/modellist/modellist.component";
import { SupportMessageComment } from "../support-message-comment";
import { SupportMessageCommentService} from "../support-message-comment.service";
import {FiltersParams, ModelListService, ModelListTextFilter} from "@solidev/data";
import {MODELLIST_IMPORTS} from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-support-message-comment-list',
  standalone: true,
  templateUrl: './support-message-comment-list.component.pug',
  styleUrls: ['./support-message-comment-list.component.sass'],
  imports: [...MODELLIST_IMPORTS]
})
export class SupportMessageCommentListComponent extends ModellistComponent<SupportMessageComment> {
  constructor(coll: SupportMessageCommentService,
              list: ModelListService,
              ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte"
        }),
      ]
    };
  }
}
