
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { TarifLifecycleStepService} from "./tarif-lifecycle-step.service";
import { TarifLifecycleStep } from "./tarif-lifecycle-step";

@Injectable({
  providedIn: 'root'
})
export class TarifLifecycleStepResolver  {
  constructor(private _rs: TarifLifecycleStepService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TarifLifecycleStep> {
    return this._rs.fetch(+route.params["tariflifecyclestepId"])
  }
}
