
<ng-template #ofc let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Propriétés de l'image</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body" *ngIf="image$">
    <lvadg-image-manage [image]="image$" mode="offcanvas" [model]="model" [endpoints]="endpoints" (updated)="updated.emit(model); reload()" (closed)="offcanvas.dismiss()"></lvadg-image-manage>
  </div>
</ng-template>
<ng-template #video let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Vidéo associée</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body" *ngIf="image$ | async as image">
    <lvadg-video-display [video]="image" [containerClass]="'ratio ratio-16x9'"></lvadg-video-display>
  </div>
</ng-template>
<ng-template #noImage>
  <div class="mt-4 text-center text-danger">Aucune image</div>
</ng-template>
<ng-container *ngIf="model">
  <div class="row g-3">
    <ng-container *ngIf="images$|async; let images">
      <div class="col-4 col-md-3 col-lg-2 text-center" *ngFor="let image of images">
        <div class="text-center">
          <lvadg-image-display [image]="image" mode="preview"></lvadg-image-display>
          <div class="mx-auto mt-1">
            <div *ngIf="image.video">
              <button class="btn btn-outline-success" (click)="playVideo(image)">
                <lvadg-icon ri="play">Lire la vidéo</lvadg-icon>
              </button>
            </div>
            <div *ngIf="image.title || image.credits">
              <div *ngIf="image.title"><span class="badge bg-secondary me-1">Titre</span>{{image.title}}</div>
              <div class="small" *ngIf="image.credits"><span class="badge bg-secondary me-1">Crédits</span>{{image.credits}}</div>
            </div>
            <div class="mb-2 w-50 mx-auto"><span class="badge bg-primary me-1" *ngFor="let usage of image.usages">{{IMAGE_USAGE[usage]}}</span></div>
            <button class="btn-sm btn btn-outline-primary me-2" (click)="edit(image)">
              <lvadg-icon ri="edit">Propriétés</lvadg-icon>
            </button>
            <button class="btn-sm btn btn-outline-danger" (click)="removeImage(image)">
              <lvadg-icon ri="delete">Supprimer</lvadg-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col mt-3">
    <lvadg-media-upload [model]="model" [prepare]="uploadImage" [service]="collection" (uploadDone)="reload()"></lvadg-media-upload>
  </div>
</ng-container>