import { Component } from "@angular/core";
import { OfferViewData, OfferViewParams } from "../menu";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Offer } from "../../../../../models/offers/offer/offer";
import { CommonModule } from "@angular/common";
import { LayoutEditorComponent } from "../../../../../models/layouts/layout-editor/layout-editor.component";
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  merge,
  Observable,
  Subject,
  switchMap,
} from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { DataMessageService, TabMemoryService } from "@solidev/data";
import { PrintService } from "../../../../../models/layouts/print/print.service";
import { PrintBase } from "../../../../../models/layouts/print/print.base";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { OfferActionService } from "../../../../../models/offers/offer/offer-action.service";
import { OfferRestoService } from "../../../../../models/offers/offer-resto/offer-resto.service";
import { OfferResto } from "../../../../../models/offers/offer-resto/offer-resto";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { WsService } from "../../../../../components/live/ws.service";
import { LiveProgressComponent } from "../../../../../components/live/live-progress/live-progress.component";
import { LayoutService } from "../../../../../models/layouts/layout/layout.service";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";

export interface OfferPrintPreviewViewParams extends OfferViewParams {}

export interface OfferPrintPreviewViewData
  extends OfferViewData,
    OfferPrintPreviewViewParams {}

@Component({
  selector: "lvadg-offer-print-preview-view",
  standalone: true,
  imports: [
    CommonModule,
    LayoutEditorComponent,
    OfferNavComponent,
    HeaderActionComponent,
    ReactiveFormsModule,
    LiveProgressComponent,
    IconComponent,
  ],
  templateUrl: "./offer-print-preview-view.component.pug",
  styleUrl: "./offer-print-preview-view.component.sass",
})
export class OfferPrintPreviewViewComponent extends BaseDetailViewComponent<
  OfferPrintPreviewViewData,
  Offer
> {
  public print$!: Observable<PrintBase>;
  public reloadLayout$ = new Subject<number>();
  public restos$!: Observable<OfferResto[]>;
  public restoForm = new FormGroup({
    resto: new FormControl<number | null>(null),
  });
  private _params = new BehaviorSubject<{
    resto: number | null;
  }>({ resto: null });
  public params = this._params.asObservable();

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    _tms: TabMemoryService,
    private _live: WsService,
    private _prints: PrintService,
    private _oa: OfferActionService,
    private _or: OfferRestoService,
    private _layouts: LayoutService,
    private _msgs: DataMessageService,
  ) {
    super(_router, _route, _tms);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    const layoutId$ = this.data$.pipe(
      filter((data) => !!data.offer.layout),
      map((data) => data.offer.layout!),
    );
    this.print$ = merge(layoutId$, this.reloadLayout$).pipe(
      switchMap((layout) => this._prints.fetch(layout)),
    );
    this.restos$ = this.data$.pipe(
      switchMap((data) => {
        return this._or.list({ offer: data.offer.id });
      }),
    );
    this.restoForm.valueChanges.subscribe((value) => {
      this._params.next({
        resto: value.resto !== undefined ? value.resto : null,
      });
    });
  }

  public override setRouteData(data: OfferPrintPreviewViewData) {
    super.setRouteData(data);
    this._live.progress(
      `offerprint:${data.offer.id}`,
      "global",
      100,
      `Impression : ${data.offer.title}`,
    );
  }

  public async createLayout(offer: Offer) {
    const res = await this._oa.action<{
      layout: number;
    }>(offer, { action: "create_layout" });
    if (res.success && res.data?.layout) {
      this.reloadLayout$.next(res.data.layout);
      offer.layout = res.data.layout;
    }
  }

  public async launchPrint(offer: Offer) {
    const res = await this._oa.action<{
      task: string;
      print: number;
    }>(offer, { action: "launch_print" });
    this._live.progress(
      `offerprint:${offer.id}`,
      "global",
      100,
      `Impression : ${offer.title}`,
    );
  }

  public async reload() {
    const print = await firstValueFrom(this.print$);
    await firstValueFrom(this._layouts.reset$(print));
    this.reloadLayout$.next(print.id);
    this._msgs.success("Rechargement effectué");
  }
}
