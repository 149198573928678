import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';
import { CategoryBase } from '../category.base';
import { CategoryService } from '../category.service';

@Component({
  selector: 'lvadg-category-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './category-create.component.pug',
  styleUrls: ['./category-create.component.sass'],
})
export class CategoryCreateComponent implements OnInit {
  public category!: CategoryBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<CategoryBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _categs: CategoryService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.category = new CategoryBase(this._categs);
  }

  public async save() {
    this.category.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.category.save({ updateModel: true }));
      this.created.emit(this.category);
      this._msgs.success('Catégorie créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
