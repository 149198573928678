
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessPermission } from '../orderprocess-permission';
@Component({
  selector: 'lvadg-orderprocess-permission-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './orderprocess-permission-manage.component.pug',
  styleUrls: ['./orderprocess-permission-manage.component.sass']
})
export class OrderprocessPermissionManageComponent implements OnInit {
  @Input() public orderprocesspermission?: OrderprocessPermission;

  constructor() { }

  ngOnInit(): void {
  }

}
