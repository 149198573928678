
<div *ngIf="supportmessage(); let s">
  <ng-template #view>
    <div class="card mb-2">
      <div class="card-body">
        <div class="fw-bold" (click)="edit.set(!edit())" role="button">
          <div class="d-flex flex-row align-items-start">
            <lvadg-icon ri="edit" [icon_only]="true"></lvadg-icon>
            <div class="badge bg-info mx-2">{{s.kind|pchoice:s:"kind"}}</div><span class="font-monospace">{{s.title}}</span>
            <div class="badge bg-info ms-auto">{{s.status}}</div>
          </div>
        </div>
        <pre class="small text-wrap m-0">{{s.text}}</pre>
        <dl class="row g-1 small m-0 p-0">
          <div class="col">
            <dt>Priorité</dt>
            <dd>{{s.priority|pchoice:s:"priority"}}</dd>
          </div>
          <div class="col">
            <dt>Deadline</dt>
            <dd>{{s.deadline|date:'shortDate'}}</dd>
          </div>
          <div class="col">
            <dt>Estimation</dt>
            <dd>
              <ng-container *ngIf="s.estimate">{{s.estimate/100}}h</ng-container>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="mode()==='small'">
    <ng-container *ngIf="edit(); else view">
      <div class="card mb-2">
        <div class="card-body">
          <div class="fw-bold">
            <lvadg-icon ri="edit" (click)="edit.set(!edit())" role="button">Édition du message</lvadg-icon>
          </div>
          <div class="row">
            <data-dispedit class="col-4" [model]="s" field="status" mode="dd" inputClasses="form-select form-select-sm">État</data-dispedit>
            <data-dispedit class="col-4" [model]="s" field="kind" mode="dd" inputClasses="form-select form-select-sm">Type</data-dispedit>
            <data-dispedit class="col-4" [model]="s" field="priority" mode="dd" inputClasses="form-select form-select-sm">Priorité</data-dispedit>
            <data-dispedit class="col-12" [model]="s" field="title" mode="dd" inputClasses="form-control form-control-sm">Titre</data-dispedit>
            <data-dispedit class="col-12" [model]="s" field="text" mode="dd" inputClasses="form-control form-control-sm font-monospace">Message</data-dispedit>
            <data-dispedit class="col-4" [model]="s" field="estimate" mode="dd" inputClasses="form-control form-control-sm">Estimation</data-dispedit>
            <data-dispedit class="col-4" [model]="s" field="deadline" mode="dd" inputClasses="form-control form-control-sm">Deadline</data-dispedit>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="mode()==='normal'">
    <div class="row">
      <data-dispedit class="col-4" [model]="s" field="status" mode="dd" inputClasses="form-select form-select-sm">État</data-dispedit>
      <data-dispedit class="col-4" [model]="s" field="kind" mode="dd" inputClasses="form-select form-select-sm">Type</data-dispedit>
      <data-dispedit class="col-4" [model]="s" field="priority" mode="dd" inputClasses="form-select form-select-sm">Priorité</data-dispedit>
      <data-dispedit class="col-12" [model]="s" field="title" mode="dd" inputClasses="form-control form-control-sm">Titre</data-dispedit>
      <data-dispedit class="col-12" [model]="s" field="text" mode="dd" inputClasses="form-control form-control-sm font-monospace">Message</data-dispedit>
      <data-dispedit class="col-4" [model]="s" field="estimate" mode="dd" inputClasses="form-control form-control-sm">Estimation</data-dispedit>
      <data-dispedit class="col-4" [model]="s" field="deadline" mode="dd" inputClasses="form-control form-control-sm">Deadline</data-dispedit>
    </div>
  </ng-container>
</div>