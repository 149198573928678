import { Component } from '@angular/core';
import { GeodepartementListViewData } from './geodepartement-list-view.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { GeodepartementListComponent } from '../../../../models/locations/geodepartement/geodepartement-list/geodepartement-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Geodepartement } from '../../../../models/locations/geodepartement/geodepartement';

@Component({
  selector: 'lvadg-geodepartement-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, GeodepartementListComponent],
  templateUrl: './geodepartement-list-view.component.pug',
  styleUrls: ['./geodepartement-list-view.component.sass'],
})
export class GeodepartementListViewComponent extends BaseListViewComponent<GeodepartementListViewData, Geodepartement> {
  public override setRouteData(data: GeodepartementListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
