
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnmatcache } from '../rnmatcache';
@Component({
  selector: 'lvadg-rnmatcache-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmatcache-manage.component.pug',
  styleUrls: ['./rnmatcache-manage.component.sass']
})
export class RnmatcacheManageComponent implements OnInit {
  @Input() public rnmatcache?: Rnmatcache;

  constructor() { }

  ngOnInit(): void {
  }

}
