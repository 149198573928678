import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Group} from '../group';
import {GroupBase} from '../group.base';
import {Link} from '@solidev/data';
import {RouterLink} from '@angular/router';

// TODO: add modal/offcanvas display of group details / users

@Component({
  selector: 'lvadg-group-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './group-display.component.pug',
  styleUrls: ['./group-display.component.sass'],
})
export class GroupDisplayComponent implements OnInit {
  @Input() public group?: Group | GroupBase;
  @Input() public mode: 'line' | 'badge' = 'badge';
  @Input() public color:
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'secondary' = 'primary';
  @Input() public detail_route?: Link<GroupBase>;

  constructor() {}

  ngOnInit(): void {}
}
