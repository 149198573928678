import {
  Component,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from "@angular/core";
import { OfferViewData, OfferViewParams } from "../menu";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Offer } from "../../../../../models/offers/offer/offer";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { CommonModule } from "@angular/common";
import { OfferNavComponent } from "../_nav/offer-nav.component";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { OfferActionService } from "../../../../../models/offers/offer/offer-action.service";
import { OfferTextService } from "../../../../../models/offers/offer-text/offer-text.service";
import { OfferText } from "../../../../../models/offers/offer-text/offer-text";
import { firstValueFrom, map, Observable } from "rxjs";
import { FilterDefaults, RicheditComponent } from "@solidev/data";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { TodoComponent } from "../../../../../components/utils/todo/todo.component";
import { OffertypeTextType } from "../../../../../models/offers/offertype-text-type/offertype-text-type";
import { OffertypeText } from "../../../../../models/offers/offertype-text/offertype-text";
import { NgStringPipesModule } from "ngx-pipes";
import { ModelListAction } from "../../../../../includes/modellist/modellist.component";
import { OfferListComponent } from "../../../../../models/offers/offer/offer-list/offer-list.component";

export interface OfferTextsViewParams extends OfferViewParams {}

export interface OffertTextsViewData
  extends OfferViewData,
    OfferTextsViewParams {}

/**
 * Available texts, returned by "get_available_texts" action
 */
interface AvailableTexts {
  texttypes: OffertypeTextType[];
  texts: OffertypeText[];
}

/**
 * Offer texts view.
 * Manage the texts for an offer.
 *
 * Not really tested features : regions.
 */
@Component({
  selector: "lvadg-offer-texts-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    OfferNavComponent,
    RicheditComponent,
    ReactiveFormsModule,
    TodoComponent,
    NgStringPipesModule,
    OfferListComponent,
  ],
  templateUrl: "./offer-texts-view.component.pug",
  styleUrl: "./offer-texts-view.component.sass",
})
export class OfferTextsViewComponent extends BaseDetailViewComponent<
  OffertTextsViewData,
  Offer
> {
  /** Current offer input signal */
  public offer = signal<Offer | null>(null);
  /** Offer texts signal, indexed by position */
  public ots = signal<Record<number, OfferText>>({});
  /** Offer text positions, 0 to 5 */
  public text_positions = [0, 1, 2, 3, 4, 5];

  // FORMS
  /** Free text form add */
  public addForm = new FormGroup({
    title: new FormControl(""),
    text: new FormControl(""),
    position: new FormControl(0),
  });

  /** Free text form edit */
  public editForm = new FormGroup({
    title: new FormControl(""),
    text: new FormControl(""),
  });

  /** Current offertext signal */
  public current = signal<OfferText | null>(null);
  /** Current position signal (for empty slots) */
  public current_position = signal<number | null>(null);
  /** Available texts signal, indexed by texttype */
  public available_texts = signal<Record<number, OffertypeText[]>>({});
  /** Available texttypes signal */
  public available_texttypes = signal<OffertypeTextType[]>([]);

  /** Text import utils */
  public forOfferFilter$!: Observable<FilterDefaults>;
  public texts_import_default_fields: string[] = [
    "title",
    "client_details",
    "storage_details",
    "datestart",
    "dateend",
    "status",
    "products_count",
    "storages_details",
    "actions",
  ];

  // Injections
  private _oact = inject(OfferActionService);
  private _ot = inject(OfferTextService);
  private _ofc = inject(NgbOffcanvas);
  private _oa = inject(OfferActionService);

  // Offcanvas instances
  @ViewChild("ofcAddTextSlot", { static: true })
  private _ofcAddTextSlot!: ElementRef<HTMLDivElement>;
  private _ofcAddTextInstance?: NgbOffcanvasRef;
  @ViewChild("ofcEditTextSlot", { static: true })
  private _ofcEditTextSlot!: ElementRef<HTMLDivElement>;
  private _ofcEditTextInstance?: NgbOffcanvasRef;
  @ViewChild("ofcMoveTextSlot", { static: true })
  private _ofcMoveTextSlot!: ElementRef<HTMLDivElement>;
  private _ofcMoveTextInstance?: NgbOffcanvasRef;
  // Select variant slot
  @ViewChild("ofcSelectVariantSlot", { static: true })
  private _ofcSelectVariantSlot!: ElementRef<HTMLDivElement>;
  private _ofcSelectVariantInstance?: NgbOffcanvasRef;
  // select predefined slot
  @ViewChild("ofcSelectPredefinedSlot", { static: true })
  private _ofcSelectPredefinedSlot!: ElementRef<HTMLDivElement>;
  private _ofcSelectPredefinedInstance?: NgbOffcanvasRef;
  // import texts slot
  @ViewChild("ofcImportTextSlot", { static: false })
  private _ofcImportTextsSlot!: ElementRef<HTMLDivElement>;
  private _ofcImportTextsInstance?: NgbOffcanvasRef;

  /** On route data reload, set offer and reload text signals */
  public override setRouteData(data: OffertTextsViewData) {
    super.setRouteData(data);
    this.offer.set(data.offer);
    this.reloadTexts$().then();
    this.reloadAvailableTexts$().then();
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.forOfferFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.offer.storage,
        for_offer: data.offer.id,
        in_offer: data.offer.id,
      })),
    );
  }

  /** Sync / reset texts action */
  public async syncTextsAction() {
    const of = this.offer();
    if (!of) return;

    await this._oact.action(of, { action: "sync_texts" });
    await this.reloadTexts$();
    await this.reloadAvailableTexts$();
  }

  /** Delete text action */
  public async deleteTextAction(ot: OfferText) {
    await this._oact.action(this.offer()!, {
      action: "delete_text",
      offertext: ot.id,
    });
    await this.reloadTexts$();
  }

  /** Open add text dialog */
  public addText(p: number) {
    this.addForm.reset({
      position: p,
      title: "",
      text: "",
    });

    this._ofcAddTextInstance = this._ofc.open(this._ofcAddTextSlot, {
      position: "end",
    });
  }

  /** Save added text action */
  public async addTextAction() {
    await this._oact.action(this.offer()!, {
      action: "add_free_text",
      position: this.addForm.value.position || 0,
      title: this.addForm.value.title || "",
      text: this.addForm.value.text || "",
    });
    this._ofcAddTextInstance?.dismiss();
    await this.reloadTexts$();
  }

  /** Launch text edition */
  public updateText(ot: OfferText) {
    this.current.set(ot);
    this.editForm.reset({
      title: ot.title,
      text: ot.text,
    });

    this._ofcEditTextInstance = this._ofc.open(this._ofcEditTextSlot, {
      position: "end",
    });
  }

  /** Save edited text action */
  public async updateTextAction() {
    await this._oact.action(this.offer()!, {
      action: "edit_free_text",
      offertext: this.current()!.id,
      title: this.editForm.value.title || "",
      text: this.editForm.value.text || "",
    });
    this._ofcEditTextInstance?.dismiss();
    await this.reloadTexts$();
  }

  /** Open move text dialog */
  public moveText(ot: OfferText) {
    this.current.set(ot);
    this._ofcMoveTextInstance = this._ofc.open(this._ofcMoveTextSlot, {
      position: "end",
    });
  }

  /** Save moved text action */
  public async moveTextAction(c: OfferText, p: number) {
    await this._oact.action(this.offer()!, {
      action: "move_text",
      offertext: c.id,
      position: p,
    });
    this._ofcMoveTextInstance?.dismiss();
    await this.reloadTexts$();
  }

  /** Open select variant dialog */
  public selectVariant(ot: OfferText) {
    this.current.set(ot);
    this._ofcSelectVariantInstance = this._ofc.open(
      this._ofcSelectVariantSlot,
      {
        position: "end",
      },
    );
  }

  /** Save selected variant action */
  public async selectedVariantAction(c: OfferText, t: OffertypeText) {
    await this._oact.action(this.offer()!, {
      action: "select_variant_text",
      offertext: c.id,
      offertype_texttype_text: t.id,
    });
    this._ofcSelectVariantInstance?.dismiss();
    await this.reloadTexts$();
    await this.reloadAvailableTexts$();
  }

  /** Open predefined text selection dialog */
  public selectPredefinedText(p: number) {
    this.current_position.set(p);
    this._ofcSelectPredefinedInstance = this._ofc.open(
      this._ofcSelectPredefinedSlot,
      {
        position: "end",
      },
    );
  }

  /** Save selected predefined text action */
  public async selectedPredefinedTextAction(pos: number, t: OffertypeText) {
    await this._oact.action(this.offer()!, {
      action: "add_predefined_text",
      position: pos,
      offertype_texttype_text: t.id,
    });
    this._ofcSelectPredefinedInstance?.dismiss();
    await this.reloadTexts$();
    await this.reloadAvailableTexts$();
  }

  /** Open import text selection dialog */
  public openImportTexts() {
    this._ofcImportTextsInstance = this._ofc.open(this._ofcImportTextsSlot, {
      panelClass: "w-75",
      position: "end",
    });
  }

  /** Import texts */
  public async processImportTexts($event: ModelListAction<Offer>) {
    if (!$event.model?.id) {
      return;
    }
    const offer = this.offer();
    if (!offer) {
      return;
    }
    await this._oa.action(offer, {
      action: "import_texts",
      source: $event.model.id,
    });
    this._ofcImportTextsInstance?.dismiss();
    await this.reloadTexts$();
    await this.reloadAvailableTexts$();
  }

  /** Reload texts, indexed by position */
  private async reloadTexts$() {
    const ots = await firstValueFrom(
      this._ot.list({ offer: this.offer()!.id }),
    );
    const dots: Record<number, OfferText> = {};
    for (const ot of ots) {
      dots[ot.position] = ot;
    }
    this.ots.set(dots);
  }

  /** Reload available texts, indexed by texttype, and full texttype list */
  private async reloadAvailableTexts$() {
    const texts = await this._oact.action<AvailableTexts>(
      this.offer()!,
      { action: "get_available_texts" },
      {
        success: "",
      },
    );
    const available_texts: Record<number, OffertypeText[]> = {};
    for (const t of texts.data!.texts) {
      available_texts[t.texttype] = available_texts[t.texttype] || [];
      available_texts[t.texttype].push(t);
    }
    this.available_texts.set(available_texts);
    this.available_texttypes.set(texts.data!.texttypes);
  }
}
