import { OffertypePermissionBase } from './offertype-permission.base';
import { detailsField } from '@solidev/data';
import { Offertype } from '../offertype/offertype';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';

export class OffertypePermission extends OffertypePermissionBase {
  static override readonly __name: string = 'OffertypePermission';
  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
    priority: 800,
  })
  public user_details?: UserBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
    priority: 700,
  })
  public group_details?: GroupBase;

  @detailsField({
    name: 'offertype_details',
    description: "Modèle d'offre",
    model: Offertype,
    priority: 900,
  })
  public offertype_details?: Offertype;
}
