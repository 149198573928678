import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Genericregion } from '../genericregion';
import { DispeditComponent } from '@solidev/data';
import { ClientService } from '../../../structures/client/client.service';
import { GENERICREGION_TYPE } from '../genericregion.base';
import { GeodepartementService } from '../../geodepartement/geodepartement.service';

@Component({
  selector: 'lvadg-genericregion-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './genericregion-manage.component.pug',
  styleUrls: ['./genericregion-manage.component.sass'],
})
export class GenericregionManageComponent {
  @Input() public genericregion?: Genericregion;
  public GENERICREGION_TYPE = GENERICREGION_TYPE;

  constructor(
    public clients$: ClientService,
    public geodepartements$: GeodepartementService,
  ) {}
}
