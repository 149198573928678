import { charField, foreignKeyField } from '@solidev/data';
import { BaseFile } from '../../documents/basefile';

export enum PRINTDOCUMENT_TYPES {
  LOGO = 'lg',
  HEADER = 'hd',
  PHOTO = 'ph',
  BACKGROUND = 'bg',
  PDFPAGE = 'pp',
  PRINT = 'pr',
  OTHER = 'ot',
}

export class PrintDocumentBase extends BaseFile {
  static override readonly __name: string = 'PrintDocumentBase';

  @foreignKeyField({ name: 'print', description: 'ID Print', related: 'Print' })
  public print!: number;

  @charField({
    name: 'type',
    description: 'Type',
    defaultValue: PRINTDOCUMENT_TYPES.OTHER,
    maxLength: 2,
    choices: [
      {
        value: PRINTDOCUMENT_TYPES.LOGO,
        desc: 'Logo',
      },
      {
        value: PRINTDOCUMENT_TYPES.HEADER,
        desc: 'En-tête',
      },
      {
        value: PRINTDOCUMENT_TYPES.PHOTO,
        desc: 'Photo',
      },
      {
        value: PRINTDOCUMENT_TYPES.BACKGROUND,
        desc: 'Arrière-plan',
      },
      {
        value: PRINTDOCUMENT_TYPES.PDFPAGE,
        desc: 'Page PDF',
      },
      {
        value: PRINTDOCUMENT_TYPES.PRINT,
        desc: 'Impression',
      },
      {
        value: PRINTDOCUMENT_TYPES.OTHER,
        desc: 'Autre',
      },
    ],
  })
  public type: PRINTDOCUMENT_TYPES = PRINTDOCUMENT_TYPES.OTHER;
}
