import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OfferNotificationStatus } from "../offer-notification-status";
import { OfferNotificationStatusService } from "../offer-notification-status.service";
import {
  FiltersParams,
  Link,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { OfferNotification } from "../../offer-notification/offer-notification";
import { OFFERNOTIFICATIONSTATUS_STATUSES } from "../offer-notification-status.base";
import { OfferNotificationDisplayComponent } from "../../offer-notification/offer-notification-display/offer-notification-display.component";
import { OfferNotificationDestinationDisplayComponent } from "../../offer-notification-destination/offer-notification-destination-display/offer-notification-destination-display.component";
import { UserDisplayComponent } from "../../../users/user/user-display/user-display.component";
import { OfferNotificationDestination } from "../../offer-notification-destination/offer-notification-destination";
import { User } from "../../../users/user/user";

@Component({
  selector: "lvadg-offer-notification-status-list",
  standalone: true,
  templateUrl: "./offer-notification-status-list.component.pug",
  styleUrls: ["./offer-notification-status-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    OfferNotificationDisplayComponent,
    OfferNotificationDestinationDisplayComponent,
    UserDisplayComponent,
  ],
})
export class OfferNotificationStatusListComponent extends ModellistComponent<OfferNotificationStatus> {
  @Input() public offernotification_detail_route?: Link<OfferNotification>;
  @Input()
  public offernotificationdestination_detail_route?: Link<OfferNotificationDestination>;
  @Input() public user_detail_route?: Link<User>;

  constructor(
    coll: OfferNotificationStatusService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "types", "statuses"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectMultiFilter({
          field: "statuses",
          name: "statuses",
          label: "Recherche par état(s)",
          help: "Sélectionnez un état de notification",
          model: OfferNotificationStatus,
          mfield: "status",
        }),
        new ModelListSelectFilter({
          field: "notification_types",
          name: "types",
          label: "Recherche par type(s)",
          model: OfferNotification,
          help: "Sélectionnez un type de notification",
          mfield: "type",
        }),
      ],
    };
  }

  public override getRowClasses(row: OfferNotificationStatus): string[] {
    const cls = super.getRowClasses(row);
    if ([OFFERNOTIFICATIONSTATUS_STATUSES.OPENED].indexOf(row.status) !== -1) {
      cls.push("text-success");
    }
    if ([OFFERNOTIFICATIONSTATUS_STATUSES.CLICKED].indexOf(row.status) !== -1) {
      cls.push("table-success");
    }
    if ([OFFERNOTIFICATIONSTATUS_STATUSES.SENT].indexOf(row.status) !== -1) {
      cls.push("text-info");
    }
    if (
      [
        OFFERNOTIFICATIONSTATUS_STATUSES.INVALID,
        OFFERNOTIFICATIONSTATUS_STATUSES.FATAL_ERROR,
      ].indexOf(row.status) !== -1
    ) {
      cls.push("table-danger");
    }
    if (
      [OFFERNOTIFICATIONSTATUS_STATUSES.TEMPORARY_ERROR].indexOf(row.status) !==
      -1
    ) {
      cls.push("table-warning");
    }
    if ([OFFERNOTIFICATIONSTATUS_STATUSES.PENDING].indexOf(row.status) !== -1) {
      cls.push("table-secondary");
    }
    if ([OFFERNOTIFICATIONSTATUS_STATUSES.SENDING].indexOf(row.status) !== -1) {
      cls.push("table-info");
    }

    return cls;
  }
}
