import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ProducerService } from "./producer.service";
import { Producer } from "./producer";

@Injectable({
  providedIn: "root",
})
export class ProducerResolver {
  constructor(private _rs: ProducerService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Producer> {
    const local: string | undefined = route.data["local"];
    if (local) {
      return this._rs.fetch(+route.params["producerId"], {
        params: { local: local },
      });
    }
    return this._rs.fetch(+route.params["producerId"]);
  }
}
