import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

import { map, Observable } from "rxjs";
import { FilterDefaults, Link, RouteConfigItem } from "@solidev/data";

import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { ArticleTarifListComponent } from "../../../../../models/tarifs/article-tarif/article-tarif-list/article-tarif-list.component";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { TariftypeBase } from "../../../../../models/tarifs/tariftype/tariftype.base";
import { TarifBase } from "../../../../../models/tarifs/tarif/tarif.base";
import { ArticleBase } from "../../../../../models/catalog/article/article.base";
import { FamilyBase } from "../../../../../models/catalog/family/family.base";
import { EarticleBase } from "../../../../../models/catalog/earticle/earticle.base";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";

export interface ArticlePricesViewParams extends ArticleViewParams {
  tariftype_detail_route: RouteConfigItem;
  tarif_detail_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
  family_detail_route: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
}

export interface ArticlePricesViewData
  extends ArticlePricesViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-prices",
  standalone: true,
  imports: [
    CommonModule,
    ArticleTarifListComponent,
    ArticleNavComponent,
    HeaderActionComponent,
  ],
  templateUrl: "./article-prices-view.component.pug",
  styleUrls: ["./article-prices-view.component.sass"],
})
export class ArticlePricesViewComponent extends BaseDetailViewComponent<
  ArticlePricesViewData,
  Article
> {
  public articleTarifFilter$!: Observable<FilterDefaults>;
  public tariftype_detail_route!: Link<TariftypeBase>;
  public tarif_detail_route!: Link<TarifBase>;
  public article_detail_route!: Link<ArticleBase>;
  public family_detail_route!: Link<FamilyBase>;
  public earticle_detail_route?: Link<EarticleBase>;
  public default_fields = [
    "id",
    "tarif_details",
    "tariftype_details",
    "eaclient_details",
    "article_details",
    "price",
    "status",
    "created",
    "updated",
  ];

  public override postNgOnInit() {
    super.postNgOnInit();
    this.articleTarifFilter$ = this.data$.pipe(
      map((data) => ({ article: data.article.id })),
    );
  }

  public override setRouteData(data: ArticlePricesViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link<TariftypeBase>(
      data.tariftype_detail_route,
      data,
    );
    this.tarif_detail_route = new Link<TarifBase>(
      data.tarif_detail_route,
      data,
    );
    this.article_detail_route = new Link<ArticleBase>(
      data.article_detail_route,
      data,
    );
    this.family_detail_route = new Link<FamilyBase>(
      data.family_detail_route,
      data,
    );
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link<EarticleBase>(
        data.earticle_detail_route,
        data,
      );
    }
  }
}
