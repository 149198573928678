import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent, slugify } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Folder } from '../folder';
import { firstValueFrom } from 'rxjs';
import { FolderService } from '../folder.service';

@Component({
  selector: 'lvadg-folder-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './folder-create.component.pug',
  styleUrls: ['./folder-create.component.sass'],
})
export class FolderCreateComponent implements OnInit {
  public createForm!: FormGroup<{
    name: FormControl<string>;
    status: FormControl<string>;
  }>;
   
  @Input({ required: true }) public parent!: Folder | null | undefined;
  @Output() public created = new EventEmitter<Folder>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: Folder;

  constructor(private _items: FolderService) {}

  ngOnInit(): void {
    this.item = new Folder(this._items);
    if (this.parent) {
      this.item.parent = this.parent.id;
      this.item.parent_details = this.parent;
    }
    this.createForm = this.item.FC(['name', 'status']);
  }

  public async create() {
    this.item.fromJson(this.createForm.value, { partial: true });
    this.item.slug = slugify(this.item.name);
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }
}
