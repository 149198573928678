import { Component, Input } from '@angular/core';

import { OrderprocessstorageListViewData } from './orderprocessstorage-list-view.component.params';
import { Subject } from 'rxjs';
import { Link } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import {
  OrderprocessstorageListComponent
} from "../../../../models/orders/orderprocessstorage/orderprocessstorage-list/orderprocessstorage-list.component";
import {
  OrderprocessstorageCreateComponent
} from "../../../../models/orders/orderprocessstorage/orderprocessstorage-create/orderprocessstorage-create.component";
import {HeaderActionComponent} from "../../../../components/utils/header-action/header-action.component";
import {BaseListViewComponent} from "../../../../components/baseview/baselistview.component";
import {Orderprocessstorage} from "../../../../models/orders/orderprocessstorage/orderprocessstorage";
import {OrderprocessBase} from "../../../../models/orders/orderprocess/orderprocess.base";
import {StorageBase} from "../../../../models/structures/storage/storage.base";
import {PlatformBase} from "../../../../models/structures/platform/platform.base";
import {UserBase} from "../../../../models/users/user/user.base";
import {GroupBase} from "../../../../models/users/group/group.base";
import {
  OrderprocessstoragePermissionBase
} from "../../../../models/orders/orderprocessstorage-permission/orderprocessstorage-permission.base";

@Component({
  selector: 'lvadg-orderprocessstorage-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    OrderprocessstorageListComponent,
    OrderprocessstorageCreateComponent,
    HeaderActionComponent,
  ],
  templateUrl: './orderprocessstorage-list-view.component.pug',
  styleUrls: ['./orderprocessstorage-list-view.component.sass'],
})
export class OrderprocessstorageListViewComponent extends BaseListViewComponent<
  OrderprocessstorageListViewData,
  Orderprocessstorage
> {
  @Input() orderprocess_detail_route?: Link<OrderprocessBase>;
  @Input() storage_detail_route?: Link<StorageBase>;
  @Input() platform_detail_route?: Link<PlatformBase>;
  @Input() user_detail_route?: Link<UserBase>;
  @Input() group_detail_route?: Link<GroupBase>;
  @Input()
  orderprocessstoragepermission_detail_route?: Link<OrderprocessstoragePermissionBase>;
  public create: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields = [
    'id',
    'process_details',
    'storage_details',
    'platform_details',
    'perms_details',
    'user_level',
    'created',
    'updated',
  ];

  public override setRouteData(data: OrderprocessstorageListViewData) {
    super.setRouteData(data);
    this.orderprocess_detail_route = new Link(data.orderprocess_detail_route, this);
    this.storage_detail_route = new Link(data.storage_detail_route, this);
    this.platform_detail_route = new Link(data.platform_detail_route, this);
    this.user_detail_route = new Link(data.user_detail_route, this);
    this.group_detail_route = new Link(data.group_detail_route, this);
    this.orderprocessstoragepermission_detail_route = new Link(data.orderprocessstoragepermission_detail_route, this);
  }
}
