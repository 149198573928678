import { RouteConfigItem } from "@solidev/data";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
} from "../../../../models/catalog/product/product.base";

export interface STORAGE_ROUTES {
  storage_display_route?: RouteConfigItem;
  storage_edit_route?: RouteConfigItem;
  storage_cdatas_route?: RouteConfigItem;
  storage_relations_route?: RouteConfigItem;
  storage_global_producers_route?: RouteConfigItem;
  storage_global_producers_detail_routes?: boolean;
  storage_global_products_route?: RouteConfigItem;
  storage_global_products_detail_routes?: boolean;
  storage_local_producers_route?: RouteConfigItem;
  storage_local_producers_detail_routes?: boolean;
  storage_local_products_route?: RouteConfigItem;
  storage_local_products_detail_routes?: boolean;
  storage_sea_producers_route?: RouteConfigItem;
  storage_sea_producers_detail_routes?: boolean;
  storage_sea_products_route?: RouteConfigItem;
  storage_sea_products_detail_routes?: boolean;
  storage_offers_route?: RouteConfigItem;
  storage_stats_route?: RouteConfigItem;
  storage_sales_route?: RouteConfigItem;
  storage_users_route?: RouteConfigItem;
  type?: PRODUCT_TYPES;
  local?: LOCAL_FILTERS;
}

export interface STORAGE_LINKS_ROUTES {
  producer_detail_route?: RouteConfigItem;
  product_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  relation_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
}
