import { Injectable } from '@angular/core';
import { Collection, DataBackend } from '@solidev/data';
import { OfferNotificationStatus } from './offer-notification-status';

@Injectable({
  providedIn: 'root',
})
export class OfferNotificationStatusService extends Collection<OfferNotificationStatus> {
  constructor(_backend: DataBackend) {
    super(_backend, '/v3/offernotificationstatuses', OfferNotificationStatus);
  }
}
