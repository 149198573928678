import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentBase } from '../treatment.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TreatmentService } from '../treatment.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-treatment-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './treatment-create.component.pug',
  styleUrls: ['./treatment-create.component.sass'],
})
export class TreatmentCreateComponent implements OnInit {
  public treatment!: TreatmentBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<TreatmentBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _treatments: TreatmentService,
    private _msgs: DataMessageService,
  ) {}

  ngOnInit(): void {
    this.treatment = new TreatmentBase(this._treatments);
  }

  public async save() {
    this.treatment.fromJson({ ...this.createForm.value }, { partial: true });
    try {
      await firstValueFrom(this.treatment.save({ updateModel: true }));
      this.created.emit(this.treatment);
      this._msgs.success('Traitement créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
