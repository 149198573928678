import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Ordergroup } from '../ordergroup';
import { Order } from '../../order/order';
import { DataMessageService } from '@solidev/data';

@Component({
  selector: 'lvadg-ordergroup-actions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ordergroup-actions.component.pug',
  styleUrls: ['./ordergroup-actions.component.sass'],
})
export class OrdergroupActionsComponent implements OnInit {
  @Input() public data$!: Observable<{ ordergroup: Ordergroup }>;
  public ordergroup$!: Observable<Ordergroup>;

  constructor(private _msgs: DataMessageService) {}

  public ngOnInit(): void {
    this.ordergroup$ = this.data$.pipe(map((d) => d.ordergroup));
  }

  public async action(
    ordergroup: Ordergroup,
    action: string,
    params: Record<string, any> = {}
  ) {
    try {
      const res = await firstValueFrom(
        ordergroup.action<
          Ordergroup,
          {
            ordergroup: Ordergroup;
            orders: Order[];
            message: string;
          }
        >('POST', 'action', {
          body: {
            action,
            ...params,
          },
        })
      );
      this._msgs.success('Action OK  TODO');
    } catch (e) {
      console.error(e);
      this._msgs.error(
        "Erreur lors de l'action",
        `${(e as any).error?.message || (e as any).message || ''}`,
        e
      );
    }
  }
}
