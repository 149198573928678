import { Component } from '@angular/core';
import { TreatmentListViewData } from './treatment-list-view.component.params';
import { Subject } from 'rxjs';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { TreatmentListComponent } from '../../../../../models/catalog/meta/treatment/treatment-list/treatment-list.component';
import { TreatmentCreateComponent } from '../../../../../models/catalog/meta/treatment/treatment-create/treatment-create.component';
import {Treatment} from "../../../../../models/catalog/meta/treatment/treatment";

@Component({
  selector: 'lvadg-treatment-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TreatmentCreateComponent, TreatmentListComponent],
  templateUrl: './treatment-list-view.component.pug',
  styleUrls: ['./treatment-list-view.component.sass'],
})
export class TreatmentListViewComponent extends BaseListViewComponent<TreatmentListViewData, Treatment> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: TreatmentListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
