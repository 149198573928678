import { OrderBase } from './order.base';
import { detailsField } from '@solidev/data';
import { ProcessBase } from '../../catalog/meta/process/process.base';
import { StorageBase } from '../../structures/storage/storage.base';
import { OrdergroupBase } from '../ordergroup/ordergroup.base';

export class Order extends OrderBase {
  static override readonly __name: string = 'Order';

  @detailsField({
    model: ProcessBase,
    description: 'Processus de commande',
    readonly: true,
  })
  public process_details?: ProcessBase;

  @detailsField({
    model: StorageBase,
    description: 'Dépôt',
    readonly: true,
  })
  public storage_details?: StorageBase;

  @detailsField({
    model: OrdergroupBase,
    description: 'Consolidation',
    readonly: true,
  })
  public group_details?: OrdergroupBase;
}
