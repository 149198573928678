import { OfferProductLinkBase } from './offer-product-link.base';
import { detailsField } from '@solidev/data';
import { Article } from '../../catalog/article/article';
import { Earticle } from '../../catalog/earticle/earticle';
import { ArticleTarifTemplate } from '../../tarifs/article-tarif-template/article-tarif-template';
import { ArticleTarif } from '../../tarifs/article-tarif/article-tarif';

export class OfferProductLink extends OfferProductLinkBase {
  static override readonly __name: string = 'OfferProductLink';

  @detailsField({
    name: 'article_details',
    description: 'Article catalogue',
    readonly: true,
    model: Article,
    priority: 800,
  })
  public article_details?: Article;

  @detailsField({
    name: 'earticle_details',
    description: 'Article client',
    readonly: true,
    model: Earticle,
    priority: 600,
  })
  public earticle_details?: Earticle;

  @detailsField({
    name: 'att_details',
    description: 'Article tarif',
    readonly: true,
    model: ArticleTarifTemplate,
    priority: 400,
  })
  public att_details?: ArticleTarifTemplate;

  @detailsField({
    name: 'at_details',
    description: 'Ligne de tarif',
    readonly: true,
    model: ArticleTarif,
    priority: 200,
  })
  public at_details?: ArticleTarif;
}
