import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagingBase } from '../packaging.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PackagingService } from '../packaging.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-packaging-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './packaging-create.component.pug',
  styleUrls: ['./packaging-create.component.sass'],
})
export class PackagingCreateComponent implements OnInit {
  public packaging!: PackagingBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<PackagingBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _packagings: PackagingService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.packaging = new PackagingBase(this._packagings);
  }

  public async save() {
    this.packaging.fromJson(
      { parent: null, ...this.createForm.value },
      { partial: true }
    );
    try {
      await firstValueFrom(this.packaging.save({ updateModel: true }));
      this.created.emit(this.packaging);
      this._msgs.success('Marque créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
