import { Component } from '@angular/core';

import { Subject } from 'rxjs';
import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { MemberListComponent } from '../../../../models/structures/member/member-list/member-list.component';
import { MemberCreateComponent } from '../../../../models/structures/member/member-create/member-create.component';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Member } from '../../../../models/structures/member/member';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';

export interface MemberListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface MemberListViewData extends MemberListViewParams {}

@Component({
  selector: 'lvadg-member-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    MemberListComponent,
    HeaderActionComponent,
    MemberCreateComponent,
    IconComponent,
  ],
  templateUrl: './member-list-view.component.pug',
  styleUrls: ['./member-list-view.component.sass'],
})
export class MemberListViewComponent extends BaseListViewComponent<MemberListViewData, Member> {
  public default_fields = [
    'id',
    'cvva',
    'name',
    'type',
    'location_details',
    'phone',
    'storages_count',
    'flags',
    'status',
  ];

  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: MemberListViewData) {
    super.setRouteData(data);
  }
}
