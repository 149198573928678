
<div class="row g-3">
  <div class="col-xl-6 col-12">
    <ng-container *ngIf="location$|async; let loc">
      <dl class="row border-bottom">
        <data-dispedit class="col-3" [model]="loc" field="loctype" mode="dd">Type de localisation</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="locname" mode="dd">Nom / entreprise</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="address" mode="dd">Adresse</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="caddress" mode="dd">Complément d'adresse</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="postcode" mode="dd">Code postal</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="city" mode="dd">Ville</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="country" mode="dd">Pays</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="cedex" mode="dd">CEDEX</data-dispedit>
      </dl>
      <dl class="row border-bottom">
        <data-dispedit class="col-3" [model]="loc" field="region" mode="dd">Region</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="website" mode="dd">Site internet</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="phone" mode="dd">Téléphone</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="fax" mode="dd">Fax</data-dispedit>
      </dl>
      <dl class="row border-bottom">
        <data-dispedit class="col-3" [model]="loc" field="status" mode="dd">État</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geotype" mode="dd">[geo] Précision de géolocalisation</data-dispedit>
        <data-flags class="col-6" [model]="loc">Flags</data-flags>
      </dl>
      <dl class="row border-bottom">
        <div class="col-12 text-info">Données de géolocalisation, écrasées par la géolocalisation automatique</div>
        <data-dispedit class="col-3" [model]="loc" field="geoprovider" mode="dd">[geo] Fournisseur géoloc</data-dispedit>
        <data-dispedit class="col-9" [model]="loc" field="geolabel" mode="dd">[geo] Adresse formatée</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geoname" mode="dd">[geo] Nom</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geohousenumber" mode="dd">[geo] Numéro ds rue</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geostreet" mode="dd">[geo] Rue</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geocontext" mode="dd">[geo] Contexte</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geopostcode" mode="dd">[geo] Code postal</data-dispedit>
        <data-dispedit class="col-6" [model]="loc" field="geocity" mode="dd">[geo] Ville</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geocitycode" mode="dd">[geo] Code ville</data-dispedit>
      </dl>
      <dl class="row">
        <data-dispedit class="col-3" [model]="loc" field="geocommune" mode="dd" [collection]="communes$" [filter]="{country: loc.country}">[geo] Commune</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geocountry" mode="dd" [collection]="countries$">[geo] Pays</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="geodepartement" mode="dd" [collection]="departements$" [filter]="{country: loc.country}">[geo] Département</data-dispedit>
        <data-dispedit class="col-3" [model]="loc" field="georegion" mode="dd" [collection]="regions$" [filter]="{country: loc.country}">[geo] Région</data-dispedit>
      </dl>
      <div class="row mt-3">
        <div class="col-8">
          <button class="btn btn-sm btn-outline-warning w-100" (click)="updateGeolocation(loc)">Géolocalisation automatique</button>
        </div>
        <div class="col-4">
          <button class="btn btn-sm btn-outline-primary w-100" (click)="updateGeolocation(loc, true)">Forcer GGmap</button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-xl-6 col-12">
    <ng-container *ngIf="location$|async; let loc">
      <button class="btn btn-sm btn-outline-primary w-100" (click)="geolocateMarker(loc)">Géolocaliser</button>
    </ng-container>
    <div class="w-100" #map [style.height]="mapHeight+'px'"></div>
    <ng-container *ngIf="location$|async; let loc">
      <ng-container *ngIf="markerMoved">
        <button class="btn btn-sm btn-outline-success w-100" (click)="savePosition(loc)">Enregistrer la position</button>
      </ng-container>
    </ng-container>
  </div>
</div>