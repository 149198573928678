import { DocumentFolderBase } from "./document-folder.base";
import { detailsField } from "@solidev/data";
import { FolderBase } from "../folder/folder.base";

export class DocumentFolder extends DocumentFolderBase {
  static override readonly __name: string = "DocumentFolder";

  @detailsField({
    name: "folder_details",
    description: "Dossier",
    model: FolderBase,
    readonly: true,
    priority: 500,
  })
  public folder_details!: FolderBase;
}
