import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TariftypeMetadata } from '../tariftype-metadata';
import { TariftypeMetadataService } from '../tariftype-metadata.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TariftypeMetadataRawEditComponent } from '../tariftype-metadata-raw-edit/tariftype-metadata-raw-edit.component';

@Component({
  selector: 'lvadg-tariftype-metadata-list',
  standalone: true,
  templateUrl: './tariftype-metadata-list.component.pug',
  styleUrls: ['./tariftype-metadata-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    TariftypeMetadataRawEditComponent,
    SafeDeleteComponent,
  ],
})
export class TariftypeMetadataListComponent extends ModellistComponent<TariftypeMetadata> {
  constructor(
    coll: TariftypeMetadataService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
