import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FamilyService } from '../family.service';
import { Family } from '../family';
import { FamilyBase } from '../family.base';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-family-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './family-create.component.pug',
  styleUrls: ['./family-create.component.sass'],
})
export class FamilyCreateComponent implements OnInit {
  public family!: Family;
  public createForm = new FormGroup({});
  @Input() public parent?: FamilyBase;
  @Output() public created = new EventEmitter<FamilyBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    public families$: FamilyService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.family = this.families$.create({
      parent: this.parent?.id,
    });
    if (this.parent) {
      (this.family as any).parent_details = this.parent;
    }
  }

  public async save() {
    this.family.fromJson(
      {
        ...this.createForm.value,
      },
      { partial: true }
    );
    try {
      await firstValueFrom(this.family.save({ updateModel: true }));
      this.created.emit(this.family);
      this._msgs.success('Famille créée avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
