import {
  charField,
  detailsField,
  manyToManyField,
  primaryField,
} from "@solidev/data";
import { StructureBase } from "../structure.base";

export enum PRODUCER_TYPES {
  FL = "FL",
  MR = "MR",
  FLMR = "FM",
}

// FIXME: remove NOT_LOCAL status
export enum ProducerStatus {
  OK = "OK",
  EMPTY = "EM",
  NO_STORAGE = "NA",
  EMPTY_NO_STORAGE = "EN",
  NOT_LOCAL = "NL",
  KO = "KO",
}

export enum ProducerPubImage {
  UNKNOWN = "",
  ACCEPTED = "OK",
  NOT_ACCEPTED = "NO",
  UNKNOWN2 = "NA",
  WAITING = "NR",
}

export enum PRODUCER_USER_LEVELS {
  SUPERUSER = "sadmin",
  WRITE = "write",
  READ = "read",
}

export class ProducerBase extends StructureBase {
  static override readonly __name: string = "ProducerBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: PRODUCER_TYPES.FL,
    maxLength: 4,
    choices: [
      {
        value: PRODUCER_TYPES.FL,
        desc: "Producteur fruits et légumes",
      },
      {
        value: PRODUCER_TYPES.MR,
        desc: "Producteur marée",
      },
      {
        value: PRODUCER_TYPES.FLMR,
        desc: "Producteurs fruits/légumes/marée",
      },
    ],
    priority: 990,
  })
  public type: PRODUCER_TYPES = PRODUCER_TYPES.FL;

  @charField({
    name: "status",
    description: "État",
    defaultValue: ProducerStatus.OK,
    required: true,
    maxLength: 4,
    choices: [
      {
        value: ProducerStatus.OK,
        desc: "En activité, valide",
      },
      {
        value: ProducerStatus.EMPTY,
        desc: "Vide (pas de produit)",
      },
      {
        value: ProducerStatus.NO_STORAGE,
        desc: "Pas de dépôt associé",
      },
      {
        value: ProducerStatus.EMPTY_NO_STORAGE,
        desc: "Vide + non associé",
      },
      // FIXME: remove this status
      {
        value: ProducerStatus.NOT_LOCAL,
        desc: "Aucun produit local",
      },
      {
        value: ProducerStatus.KO,
        desc: "Plus actif, désactivé",
      },
    ],
    priority: 101,
  })
  public status: ProducerStatus = ProducerStatus.OK;

  @charField({
    name: "pubimage",
    description: "Droit à l'image",
    defaultValue: ProducerPubImage.UNKNOWN,
    maxLength: 2,
    choices: [
      {
        value: ProducerPubImage.UNKNOWN,
        desc: "Inconnu",
      },
      {
        value: ProducerPubImage.ACCEPTED,
        desc: "Accepté",
      },
      {
        value: ProducerPubImage.NOT_ACCEPTED,
        desc: "Refusé",
      },
      {
        value: ProducerPubImage.UNKNOWN2,
        desc: "NA - à voir",
      },
      {
        value: ProducerPubImage.WAITING,
        desc: "En attente",
      },
    ],
    priority: 140,
  })
  public pubimage: ProducerPubImage = ProducerPubImage.UNKNOWN;

  @manyToManyField({
    name: "plabels",
    description: "IDs Labels produits",
    defaultValue: [],
    related: "Label",
    priority: -1,
  })
  public plabels: number[] = [];

  @detailsField({
    name: "user_level",
    description: "Droits utilisateur",
    readonly: true,
  })
  public user_level: PRODUCER_USER_LEVELS[] = [];

  public override get map_pointer(): string {
    return "producer";
  }

  public get _display(): string {
    return this.name;
  }

  public have_level(level: PRODUCER_USER_LEVELS): boolean {
    return this.user_level.includes(level);
  }
}
