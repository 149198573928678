import { Component } from '@angular/core';
import { DistanceListViewData } from './distance-list-view.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { DistanceListComponent } from '../../../../models/locations/distance/distance-list/distance-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Distance } from '../../../../models/locations/distance/distance';

@Component({
  selector: 'lvadg-distance-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, DistanceListComponent],
  templateUrl: './distance-list-view.component.pug',
  styleUrls: ['./distance-list-view.component.sass'],
})
export class DistanceListViewComponent extends BaseListViewComponent<DistanceListViewData, Distance> {
  public override setRouteData(data: DistanceListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
