import { OfferProductBase } from "./offer-product.base";
import { computedField, detailsField } from "@solidev/data";
import { Product } from "../../catalog/product/product";
import { Offer } from "../offer/offer";
import { Producer } from "../../structures/producer/producer";
import { Label } from "../../catalog/label/label";
import { OfferProductLink } from "../offer-product-link/offer-product-link";
import { Relation } from "../../structures/relation/relation";
import { Earticle } from "../../catalog/earticle/earticle";
import { ArticleTarif } from "../../tarifs/article-tarif/article-tarif";
import { ArticleTarifTemplate } from "../../tarifs/article-tarif-template/article-tarif-template";
import { Article } from "../../catalog/article/article";

export class OfferProduct extends OfferProductBase {
  static override readonly __name: string = "OfferProduct";
  @detailsField({
    name: "offer_details",
    description: "Offre",
    model: Offer,
    readonly: true,
    priority: 800,
  })
  public offer_details?: Offer;

  @detailsField({
    name: "product_details",
    description: "Produit",
    model: Product,
    readonly: true,
    priority: 700,
  })
  public product_details?: Product;
  @detailsField({
    name: "links_details",
    description: "Liens",
    readonly: true,
    many: true,
    model: OfferProductLink,
  })
  public links_details?: OfferProductLink[];

  @detailsField({
    name: "producer_details",
    description: "Détails producter",
    readonly: true,
    model: Producer,
    priority: 675,
  })
  public producer_details?: Producer;

  @detailsField({
    name: "producer_storage_relation_details",
    description: "Distance dépot-producteur",
    readonly: true,
    model: Relation,
    priority: 650,
  })
  public producer_storage_relation_details?: Relation;

  @computedField({
    name: "product_labels_details",
    description: "Labels produit",
    readonly: true,
    priority: 695,
  })
  public get product_labels_details(): Label[] {
    return this.product_details?.labels_details || [];
  }

  @computedField({
    name: "product_seasons",
    description: "Saisonnalité",
    readonly: true,
    priority: 690,
  })
  public get product_seasons(): number[] {
    return this.product_details?.seasons || [];
  }

  @computedField({
    name: "product_client_articles",
    description: "Articles client",
    readonly: true,
    priority: 685,
  })
  public get product_client_articles(): Earticle[] {
    return (this.links_details || [])
      .map((link) => link.earticle_details)
      .filter((earticle) => earticle !== undefined) as Earticle[];
  }

  @computedField({
    name: "product_tarif_lines",
    description: "Prix tarif",
    readonly: true,
    priority: 685,
  })
  public get product_tarif_lines(): ArticleTarif[] {
    return (this.links_details || [])
      .map((link) => link.at_details)
      .filter((at) => at !== undefined) as ArticleTarif[];
  }

  @computedField({
    name: "product_tarif_templates",
    description: "Templates tarif",
    readonly: true,
    priority: 685,
  })
  public get product_tarif_templates(): ArticleTarifTemplate[] {
    return (this.links_details || [])
      .map((link) => link.att_details)
      .filter((att) => att !== undefined) as ArticleTarifTemplate[];
  }

  @computedField({
    name: "product_articles",
    description: "Articles catalogue",
    readonly: true,
    priority: 685,
  })
  public get product_articles(): Article[] {
    return (this.links_details || [])
      .map((link) => link.article_details)
      .filter((article) => article !== undefined) as Article[];
  }
}
