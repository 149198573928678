/**
 * Layout template model.
 * Implements the `TemplateDescription` interface : Template > Fragment > Param > Provider > Choice.
 */
import { TemplateDescription } from '../rstypes/TemplateDescription';
import { FragmentDescription } from '../rstypes/FragmentDescription';
import { ParamDescription } from '../rstypes/ParamDescription';
import { ProviderDescription } from '../rstypes/ProviderDescription';
import { ChoiceDescription } from '../rstypes/ChoiceDescription';
import { ProviderMode } from '../rstypes/ProviderMode';
import { ParamMode } from '../rstypes/ParamMode';
import { ParamDisplay } from '../rstypes/ParamDisplay';
import { FragmentDisplay } from '../rstypes/FragmentDisplay';

export class TemplateChoice implements ChoiceDescription {
  public name: string;
  public description: string;
  public default: boolean;
  public enabled: boolean;
  public current: boolean;
  public generic: boolean;
  public group: boolean;
  public needs: boolean;
  public value: string | null;

  constructor(data: ChoiceDescription) {
    this.name = data.name;
    this.description = data.description;
    this.needs = data.needs;
    this.default = data.default;
    this.enabled = data.enabled;
    this.current = data.current;
    this.generic = data.generic;
    this.group = data.group;
    this.value = data.value;
  }

  /**
   * Returns the value of a boolean choice, or null if the choice is not a boolean.
   * @returns {boolean | null} The value of the choice, or null if the choice is not a boolean.
   */
  public choice_bool(): boolean | null {
    if (this.name !== 'true' && this.name !== 'false') return null;
    return this.name === 'true';
  }
}

export class TemplateProvider implements ProviderDescription {
  public name: string;
  public description: string;
  public choices: TemplateChoice[];
  public enabled: boolean;
  public mode: ProviderMode;
  // SEE: check if needed ?
  public value: string;
  public current: boolean;

  constructor(data: ProviderDescription) {
    this.name = data.name;
    this.description = data.description;
    this.choices = data.choices.map((c) => new TemplateChoice(c));
    this.enabled = data.enabled;
    this.mode = data.mode;
    this.current = data.current;
    this.value = '';
  }
}

export class TemplateParam implements ParamDescription {
  public name: string;
  public description: string;
  public providers: TemplateProvider[];
  public enabled: boolean;
  public mode: ParamMode;
  public display: ParamDisplay;

  constructor(data: ParamDescription) {
    this.name = data.name;
    this.description = data.description;
    this.providers = data.providers.map((p) => new TemplateProvider(p));
    this.enabled = data.enabled;
    this.mode = data.mode;
    this.display = data.display;
  }
}

export class TemplateFragment implements FragmentDescription {
  public name: string;
  public description: string;
  public params: TemplateParam[];
  public enabled: boolean;
  public display: FragmentDisplay;

  constructor(data: FragmentDescription) {
    this.name = data.name;
    this.description = data.description;
    this.params = data.params.map((p) => new TemplateParam(p));
    this.enabled = data.enabled;
    this.display = data.display;
  }
}

export class LayoutTemplate implements TemplateDescription {
  public name: string;
  public description: string;
  public fragments: TemplateFragment[] = [];

  constructor(data: TemplateDescription) {
    this.name = data.name;
    this.description = data.description;
    this.fragments = Object.entries(data.fragments).map(([, v]) => new TemplateFragment(v));
  }
}
