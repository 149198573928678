import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '@solidev/data';
import { Location } from '../location';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-location-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './location-display.component.pug',
  styleUrls: ['./location-display.component.sass'],
})
export class LocationDisplayComponent {
  @Input() location?: Location;
  @Input() public mode: 'line' | 'short' | 'cpcity' = 'line';
  @Input() public detail_route?: Link<Location>;
}
