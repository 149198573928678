export enum IntervalGrouper {
  YEAR = 'y',
  QUARTER = 'q',
  MONTH = 'm',
  WEEK = 'w',
  DAY = 'd',
}

export const INTERVAL_GROUPER_CHOICES = [
  { value: IntervalGrouper.WEEK, desc: 'Semaine' },
  { value: IntervalGrouper.MONTH, desc: 'Mois' },
  { value: IntervalGrouper.QUARTER, desc: 'Trimestre' },
  { value: IntervalGrouper.YEAR, desc: 'Année' },
];

export enum Period {
  CUR_WEEK = 'curweek',
  LAST_WEEK = 'lastweek',
  CUR_MONTH = 'curmonth',
  LAST_MONTH = 'lastmonth',
  CUR_TRIM = 'curtrim',
  LAST_TRIM = 'lasttrim',
  CUR_SEM = 'cursem',
  LAST_SEM = 'lastsem',
  CUR_YEAR = 'curyear',
  LAST_YEAR = 'lastyear',
  LAST_4W = 'last4w',
  LAST_100D = 'last100d',
}

export const PERIOD_CHOICES = [
  { value: Period.CUR_WEEK, desc: 'Semaine en cours' },
  { value: Period.LAST_WEEK, desc: 'Semaine passée' },
  { value: Period.CUR_MONTH, desc: 'Mois en cours' },
  { value: Period.LAST_MONTH, desc: 'Mois passé' },
  { value: Period.CUR_TRIM, desc: 'Trimestre en cours' },
  { value: Period.LAST_TRIM, desc: 'Trimestre passé' },
  { value: Period.CUR_SEM, desc: 'Semestre en cours' },
  { value: Period.LAST_SEM, desc: 'Semestre passé' },
  { value: Period.CUR_YEAR, desc: 'Année en cours' },
  { value: Period.LAST_YEAR, desc: 'Année passée' },
  { value: Period.LAST_4W, desc: '4 semaines' },
  { value: Period.LAST_100D, desc: '100 jours' },
];
