import { RoutesConfig } from "@solidev/data";
import {
  Client,
  Member,
  PlatformBase,
  Producer,
  ProducerDocument,
  Provider,
  ProviderDocument,
  Relation,
  Resto,
  RS,
  Storage,
} from "@vivalya/lvadg";

export const ADMIN_STRUCTURES_ROUTES_NAMES = [
  "structure",
  "structure_clients",
  "structure_clients_client",
  "structure_clients_client_edit",
  "structure_clients_client_restos",
  "structure_clients_client_offers",
  "structure_clients_client_earticles",
  "structure_clients_client_relations",
  "structure_clients_client_sales",
  "structure_clients_client_stats",
  "structure_clients_client_tariftypes",
  "structure_clients_client_users",
  "structure_clients_client_atts",
  "structure_members",
  "structure_members_member",
  "structure_members_member_edit",
  "structure_members_member_relations",
  "structure_members_member_products",
  "structure_members_member_producers",
  "structure_members_member_offers",
  "structure_members_member_storages",
  "structure_members_member_sales",
  "structure_members_member_users",
  "structure_platforms",
  "structure_platforms_platform",
  "structure_producers",
  "structure_producers_documents",
  "structure_producers_documents_document",
  "structure_producers_producer",
  "structure_producer_producer_edit",
  "structure_producer_producer_relations",
  "structure_producer_producer_documents",
  "structure_producer_producer_offers",
  "structure_producer_producer_products",
  "structure_providers",
  "structure_providers_documents",
  "structure_providers_documents_document",
  "structure_providers_provider",
  "structure_providers_provider_articles",
  "structure_providers_provider_documents",
  "structure_providers_provider_edit",
  "structure_relations",
  "structure_relations_relation",
  "structure_restos",
  "structure_restos_resto",
  "structure_restos_resto_edit",
  "structure_restos_resto_relations",
  "structure_restos_resto_offers",
  "structure_restos_resto_sales",
  "structure_restos_resto_users",
  "structure_storages",
  "structure_storages_storage",
  "structure_storages_storage_edit",
  "structure_storages_storage_products",
  "structure_storages_storage_producers",
  "structure_storages_storage_offers",
  "structure_storages_storage_sales",
  "structure_storages_storage_relations",
  "structure_storages_storage_cdatas",
  "structure_storages_storage_stats",
  "structure_storages_storage_users",
] as const;

export const ADMIN_STRUCTURES_ROUTES_CONFIG: RoutesConfig<
  (typeof ADMIN_STRUCTURES_ROUTES_NAMES)[number]
> = {
  structure: {
    params: [],
    route: () => ["/", RS.structure],
    name: "structure",
  },
  structure_clients: {
    params: [],
    route: () => ["/", RS.structure, RS.clients],
    name: "structure_clients",
  },
  structure_clients_client: {
    params: ["client"],
    route: (client: Client) => ["/", RS.structure, RS.clients, client.id],
    name: "structure_clients_client",
  },
  structure_clients_client_edit: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.edit,
    ],
    name: "structure_clients_client_edit",
  },
  structure_clients_client_restos: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.restos,
    ],
    name: "structure_clients_client_restos",
  },
  structure_clients_client_offers: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.offers,
    ],
    name: "structure_clients_client_offers",
  },
  structure_clients_client_relations: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.relations,
    ],
    name: "structure_clients_client_relations",
  },
  structure_clients_client_earticles: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.earticles,
    ],
    name: "structure_clients_client_earticles",
  },
  structure_clients_client_sales: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.sales,
    ],
    name: "structure_clients_client_sales",
  },
  structure_clients_client_stats: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.stats,
    ],
    name: "structure_clients_client_stats",
  },
  structure_clients_client_tariftypes: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.tariftypes,
    ],
    name: "structure_clients_client_tariftypes",
  },
  structure_clients_client_users: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.users,
    ],
    name: "structure_clients_client_users",
  },
  structure_clients_client_atts: {
    params: ["client"],
    route: (client: Client) => [
      "/",
      RS.structure,
      RS.clients,
      client.id,
      RS.atts,
    ],
    name: "structure_clients_client_atts",
  },
  structure_members: {
    params: [],
    route: () => ["/", RS.structure, RS.members],
    name: "structure_members",
  },
  structure_members_member: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id],
    name: "structure_members_member",
  },
  structure_members_member_edit: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.edit],
    name: "structure_members_member_edit",
  },
  structure_members_member_relations: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.relations],
    name: "structure_members_member_relations",
  },
  structure_members_member_products: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.products],
    name: "structure_members_member_products",
  },
  structure_members_member_producers: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.producers],
    name: "structure_members_member_producers",
  },
  structure_members_member_offers: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.offers],
    name: "structure_members_member_offers",
  },
  structure_members_member_storages: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.storages],
    name: "structure_members_member_storages",
  },
  structure_members_member_sales: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.sales],
    name: "structure_members_member_sales",
  },
  structure_members_member_users: {
    params: ["member"],
    route: (m: Member) => ["/", RS.structure, RS.members, m.id, RS.users],
    name: "structure_members_member_users",
  },
  structure_platforms: {
    params: [],
    route: () => ["/", RS.structure, RS.platforms],
    name: "structure_platforms",
  },
  structure_platforms_platform: {
    params: ["platform"],
    route: (pl: PlatformBase) => ["/", RS.structure, RS.platforms, pl.id],
    name: "structure_platforms_platform",
  },

  structure_producers: {
    params: [],
    route: () => ["/", RS.structure, RS.producers],
    name: "structure_producers",
  },
  structure_producers_documents: {
    params: [],
    route: () => ["/", RS.structure, RS.producers, RS.producerdocuments],
    name: "structure_producers_documents",
  },
  structure_producers_documents_document: {
    params: ["producerdocument"],
    route: (producerdocument: ProducerDocument) => [
      "/",
      RS.structure,
      RS.producers,
      RS.producerdocuments,
      producerdocument.id,
    ],
    name: "structure_producers_documents_document",
  },
  structure_producers_producer: {
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.structure,
      RS.producers,
      producer.id,
    ],
    name: "structure_producers_producer",
  },
  structure_producer_producer_edit: {
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.structure,
      RS.producers,
      producer.id,
      RS.edit,
    ],
    name: "structure_producer_producer_edit",
  },
  structure_producer_producer_offers: {
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.structure,
      RS.producers,
      producer.id,
      RS.offers,
    ],
    name: "structure_producer_producer_offers",
  },
  structure_producer_producer_documents: {
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.structure,
      RS.producers,
      producer.id,
      RS.documents,
    ],
    name: "structure_producer_producer_documents",
  },
  structure_producer_producer_products: {
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.structure,
      RS.producers,
      producer.id,
      RS.products,
    ],
    name: "structure_producer_producer_products",
  },
  structure_producer_producer_relations: {
    params: ["producer"],
    route: (producer: Producer) => [
      "/",
      RS.structure,
      RS.producers,
      producer.id,
      RS.relations,
    ],
    name: "structure_producer_producer_relations",
  },

  structure_providers: {
    params: [],
    route: () => ["/", RS.structure, RS.providers],
    name: "structure_providers",
  },
  structure_providers_documents: {
    params: [],
    route: () => ["/", RS.structure, RS.providers, RS.documents],
    name: "structure_providers_documents",
  },
  structure_providers_documents_document: {
    params: ["providerdocument"],
    route: (providerdocument: ProviderDocument) => [
      "/",
      RS.structure,
      RS.providers,
      RS.documents,
      providerdocument.id,
    ],
    name: "structure_providers_documents_document",
  },

  structure_providers_provider: {
    params: ["provider"],
    route: (provider: Provider) => [
      "/",
      RS.structure,
      RS.providers,
      provider.id,
    ],
    name: "structure_providers_provider",
  },
  structure_providers_provider_articles: {
    params: ["provider"],
    route: (provider: Provider) => [
      "/",
      RS.structure,
      RS.providers,
      provider.id,
      RS.articles,
    ],
    name: "structure_providers_provider_articles",
  },
  structure_providers_provider_documents: {
    params: ["provider"],
    route: (provider: Provider) => [
      "/",
      RS.structure,
      RS.providers,
      provider.id,
      RS.documents,
    ],
    name: "structure_providers_provider_documents",
  },
  structure_providers_provider_edit: {
    params: ["provider"],
    route: (provider: Provider) => [
      "/",
      RS.structure,
      RS.providers,
      provider.id,
      RS.edit,
    ],
    name: "structure_providers_provider_edit",
  },

  structure_relations: {
    params: [],
    route: () => ["/", RS.structure, RS.relations],
    name: "structure_relations",
  },
  structure_relations_relation: {
    params: ["relation"],
    route: (relation: Relation) => [
      "/",
      RS.structure,
      RS.relations,
      relation.id,
    ],
    name: "structure_relations_relation",
  },
  structure_restos: {
    params: [],
    route: () => ["/", RS.structure, RS.restos],
    name: "structure_restos",
  },
  structure_restos_resto: {
    params: ["resto"],
    route: (resto: Resto) => ["/", RS.structure, RS.restos, resto.id],
    name: "structure_restos_resto",
  },
  structure_restos_resto_edit: {
    params: ["resto"],
    route: (resto: Resto) => ["/", RS.structure, RS.restos, resto.id, RS.edit],
    name: "structure_restos_resto_edit",
  },
  structure_restos_resto_relations: {
    params: ["resto"],
    route: (resto: Resto) => [
      "/",
      RS.structure,
      RS.restos,
      resto.id,
      RS.relations,
    ],
    name: "structure_restos_resto_relations",
  },
  structure_restos_resto_offers: {
    params: ["resto"],
    route: (resto: Resto) => [
      "/",
      RS.structure,
      RS.restos,
      resto.id,
      RS.offers,
    ],
    name: "structure_restos_resto_offers",
  },
  structure_restos_resto_sales: {
    params: ["resto"],
    route: (resto: Resto) => ["/", RS.structure, RS.restos, resto.id, RS.sales],
    name: "structure_restos_resto_sales",
  },
  structure_restos_resto_users: {
    params: ["resto"],
    route: (resto: Resto) => ["/", RS.structure, RS.restos, resto.id, RS.users],
    name: "structure_restos_resto_users",
  },
  structure_storages: {
    params: [],
    route: () => ["/", RS.structure, RS.storages],
    name: "structure_storages",
  },
  structure_storages_storage: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id],
    name: "structure_storages_storage",
  },
  structure_storages_storage_edit: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.edit],
    name: "structure_storages_storage_edit",
  },
  structure_storages_storage_products: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.products],
    name: "structure_storages_storage_products",
  },
  structure_storages_storage_producers: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.producers],
    name: "structure_storages_storage_producers",
  },
  structure_storages_storage_offers: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.offers],
    name: "structure_storages_storage_offers",
  },
  structure_storages_storage_sales: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.sales],
    name: "structure_storages_storage_sales",
  },
  structure_storages_storage_relations: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.relations],
    name: "structure_storages_storage_relations",
  },
  structure_storages_storage_cdatas: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.cdatas],
    name: "structure_storages_storage_cdatas",
  },
  structure_storages_storage_stats: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.stats],
    name: "structure_storages_storage_stats",
  },
  structure_storages_storage_users: {
    params: ["storage"],
    route: (m: Storage) => ["/", RS.structure, RS.storages, m.id, RS.users],
    name: "structure_storages_storage_users",
  },
};
