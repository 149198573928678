
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show"><a ngbNavLink>Détails</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-header">Infos consolidation</div>
                <div class="card-body">
                  <lvadg-ordergroup-manage [data$]="data$"></lvadg-ordergroup-manage>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-header">Actions (todo)</div>
                <div class="card-body">
                  <lvadg-ordergroup-actions [data$]="data$"></lvadg-ordergroup-actions>
                </div>
              </div>
            </div>
          </div>
          <lvadg-order-list [name]="data.route.name+'_orders'" [default_fields]="orders_default_fields" [filter]="ordergroupFilter$" [detail_route]="order_detail_route"></lvadg-order-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="lines_by_article"><a ngbNavLink>Par article</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-ordergroup-lines [data$]="data$" groupby="article" [name]="data.route.name" [storage_detail_route]="storage_detail_route" [platform_detail_route]="platform_detail_route" [order_detail_route]="order_detail_route" [article_detail_route]="article_detail_route" [tarif_detail_route]="tarif_detail_route"></lvadg-ordergroup-lines>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="lines_by_platform"><a ngbNavLink>Par plateforme</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-ordergroup-lines [data$]="data$" groupby="platform" [name]="data.route.name" [storage_detail_route]="storage_detail_route" [platform_detail_route]="platform_detail_route" [order_detail_route]="order_detail_route" [article_detail_route]="article_detail_route" [tarif_detail_route]="tarif_detail_route"></lvadg-ordergroup-lines>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>