
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarif } from '../article-tarif';
@Component({
  selector: 'lvadg-article-tarif-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './article-tarif-manage.component.pug',
  styleUrls: ['./article-tarif-manage.component.sass']
})
export class ArticleTarifManageComponent implements OnInit {
  @Input() public articletarif?: ArticleTarif;

  constructor() { }

  ngOnInit(): void {
  }

}
