import { DocumentBase } from "./document.base";
import { detailsField } from "@solidev/data";
import { Image } from "../image/image";
import { DocumentFolder } from "../document-folder/document-folder";

export class Document extends DocumentBase {
  static override readonly __name: string = "Document";

  @detailsField({
    name: "image_details",
    description: "Image",
    model: Image,
    readonly: true,
    priority: 950,
  })
  public image_details!: Image;

  @detailsField({
    name: "documentfolders_details",
    description: "Dossiers associés",
    model: DocumentFolder,
    many: true,
    readonly: true,
    priority: 550,
  })
  public documentfolders_details!: DocumentFolder[];
}
