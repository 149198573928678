
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { DistanceService} from "./distance.service";
import { Distance } from "./distance";

@Injectable({
  providedIn: 'root'
})
export class DistanceResolver  {
  constructor(private _rs: DistanceService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Distance> {
    return this._rs.fetch(+route.params["distanceId"])
  }
}
