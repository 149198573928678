import { OfferNotificationStatusBase } from "./offer-notification-status.base";
import { OfferNotificationDestination } from "../offer-notification-destination/offer-notification-destination";
import { detailsField } from "@solidev/data";
import { User } from "../../users/user/user";
import { OfferNotification } from "../offer-notification/offer-notification";

export class OfferNotificationStatus extends OfferNotificationStatusBase {
  static override readonly __name: string = "OfferNotificationStatus";

  @detailsField({
    name: "destination_details",
    description: "Destination",
    priority: 300,
    readonly: true,
    model: OfferNotificationDestination,
  })
  public destination_details?: OfferNotificationDestination;

  @detailsField({
    name: "user_details",
    description: "Utilisateur",
    priority: 400,
    readonly: true,
    model: User,
  })
  public user_details?: User;

  @detailsField({
    name: "notification_details",
    description: "Notification",
    priority: 500,
    readonly: true,
    model: OfferNotification,
  })
  public notification_details?: OfferNotification;
}
