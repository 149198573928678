import {
  Component,
  ElementRef,
  inject,
  signal,
  viewChild,
} from "@angular/core";
import { RouteSupportService, SUPPORT_ZONE } from "../../route-support.service";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { SupportMessage } from "../support-message";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { SupportMessageManageComponent } from "../support-message-manage/support-message-manage.component";
import { SupportMessageCreateComponent } from "../support-message-create/support-message-create.component";
import { DataMessageService } from "@solidev/data";
import { SUPPORT_MESSAGE_STATUS } from "../support-message.base";

@Component({
  selector: "lvadg-support-message-zone",
  standalone: true,
  imports: [SupportMessageManageComponent, SupportMessageCreateComponent],
  template: `
    <ng-template #ofcSlot let-offcanvas>
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">
          Messages<br />
          @for (p of route_page().split("_"); track p) {
            <span class="badge bg-primary me-1">{{ p }}</span>
          }
          <div>
            @if (only_opened()) {
              <i
                class="bi bi-eye-slash"
                role="button"
                (click)="only_opened.set(false)"
              ></i>
              Afficher tous les messages
            } @else {
              <i
                class="bi bi-eye"
                role="button"
                (click)="only_opened.set(true)"
              ></i>
              Afficher uniquement les messages ouverts
            }
          </div>
        </h5>
        <button
          class="btn-close text-reset"
          (click)="offcanvas.close()"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="d-flex flex-column justify-content-start h-100">
          @for (m of messages(); track m.id) {
            <lvadg-support-message-manage [supportmessage]="m" />
          }

          <div class="card mt-auto">
            <div class="card-header">Nouveau message</div>
            <div class="card-body">
              <lvadg-support-message-create
                [route]="route_page()"
                [zone]="zone"
                [url]="route_url()"
                (created)="created($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    @if (displayed()) {
      <div class="support-zone">
        @if (messages().length === 0) {
          <i class="bi bi-sticky fs-3" role="button" (click)="toggle()"></i>
        } @else {
          <i
            class="bi bi-sticky-fill text-warning fs-3"
            role="button"
            (click)="toggle()"
          ></i>
        }
      </div>
    }
  `,
  styleUrl: "./support-message-zone.component.sass",
})
export class SupportMessageZoneComponent {
  public messages = signal<SupportMessage[]>([]);
  public route_page = signal<string>("");
  public route_url = signal<string>("");
  public zone = inject<string>(SUPPORT_ZONE);
  public only_opened = signal(false);
  public displayed = signal(false);
  private _rmsg = inject(RouteSupportService);
  private _msgs = inject(DataMessageService);
  private _ofc = inject(NgbOffcanvas);
  private _ofcSlot = viewChild.required<ElementRef<HTMLDivElement>>("ofcSlot");
  private _ofcInstance?: NgbOffcanvasRef;

  constructor() {
    if (localStorage.getItem("support") !== null) {
      this.displayed.set(true);
    }
    toObservable(this.only_opened)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this._rmsg.refresh();
      });
    this._rmsg.messages$.pipe(takeUntilDestroyed()).subscribe((m) => {
      if (this.only_opened()) {
        this.messages.set(
          m.messages.filter((m) =>
            [
              SUPPORT_MESSAGE_STATUS.OPEN,
              SUPPORT_MESSAGE_STATUS.WIP,
              SUPPORT_MESSAGE_STATUS.TESTING,
            ].includes(m.status),
          ),
        );
      } else {
        this.messages.set(m.messages);
      }

      this.route_page.set(m.route);
      this.route_url.set(m.path);
    });
  }

  public toggle() {
    if (this._ofcInstance) {
      this._ofcInstance.dismiss();
      delete this._ofcInstance;
    } else {
      this._ofcInstance = this._ofc.open(this._ofcSlot(), {
        position: "start",
      });
      this._ofcInstance.closed.subscribe(() => {
        delete this._ofcInstance;
      });
    }
  }

  created($event: SupportMessage) {
    this._msgs.success(`Message créé: ${$event.title}`);
    this._rmsg.refresh();
  }
}
