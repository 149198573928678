import { RouteConfigItem } from "@solidev/data";
import {
  LOCAL_FILTERS,
  PRODUCT_TYPES,
} from "../../../../models/catalog/product/product.base";

export interface PRODUCT_ROUTES {
  product_detail_route?: RouteConfigItem;
  product_edit_route?: RouteConfigItem;
  product_documents_route?: RouteConfigItem;
  product_offers_route?: RouteConfigItem;
  product_articles_route?: RouteConfigItem;
  product_producer_route?: RouteConfigItem;
}

export interface PRODUCT_LINKS_ROUTES {
  storage_detail_route?: RouteConfigItem;
  producer_detail_route?: RouteConfigItem;
  offer_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface PRODUCT_CONTEXT {
  type?: PRODUCT_TYPES;
  local: LOCAL_FILTERS;
}
