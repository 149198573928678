import { charField, DataModel, integerField, primaryField } from '@solidev/data';

export class GeocountryBase extends DataModel {
  static override readonly __name: string = 'GeocountryBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @integerField({
    name: 'icode',
    description: 'Code numérique',
    defaultValue: 0,
    priority: 400,
  })
  public icode: number = 0;

  @charField({
    name: 'code',
    description: 'Code',
    maxLength: 4,
    priority: 900,
  })
  public code!: string;

  @charField({
    name: 'code3',
    description: 'Code 3',
    maxLength: 4,
    priority: 800,
  })
  public code3!: string;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 100,
    priority: 600,
  })
  public name!: string;

  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;
}
