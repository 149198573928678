import { Injectable } from '@angular/core';
import { Article } from './article';
import { DataMessageService } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../components/utils/errormessage';
import { ArticleBase } from './article.base';

export interface ArticleActionResult {
  article: Article;
  message: string;
  data: any;
  success: boolean;
}

interface ArticleActionGetAutoLibelleParams {
  action: 'get_auto_libelle';
}

interface ArticleActionUseAutoLibelleParams {
  action: 'use_auto_libelle';
}

interface ArticleActionCloneParams {
  action: 'clone_article';
}

interface ArticleActionRefreshQualityParams {
  action: 'refresh_quality';
}

interface ArticleActionMergeParams {
  action: 'merge_article';
  source_id: number;
}

export type ArticleActionParams =
  | ArticleActionGetAutoLibelleParams
  | ArticleActionUseAutoLibelleParams
  | ArticleActionCloneParams
  | ArticleActionRefreshQualityParams
  | ArticleActionMergeParams;

@Injectable({
  providedIn: 'root',
})
export class ArticleActionService {
  constructor(private _msgs: DataMessageService) {}

  public async action(
    article: ArticleBase | Article,
    action: ArticleActionParams,
    messages: Record<string, string> = {}
  ): Promise<ArticleActionResult> {
    try {
      const res = await firstValueFrom(
        article.action<Article, ArticleActionResult>('POST', 'action', {
          body: {
            ...action,
          },
        })
      );
      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { article, message: message, data: undefined, success: false };
    }
  }
}
