import { Component, Input, signal } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { Article } from "../article";
import { ArticleService } from "../article.service";
import {
  ChoicePipe,
  FactorPipe,
  FieldsParams,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListFlagsFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  ModelListTreeFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgOptimizedImage } from "@angular/common";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { FAMILY_TYPE, FamilyBase } from "../../family/family.base";
import { LabelDisplayComponent } from "../../label/label-display/label-display.component";
import { FamilyService } from "../../family/family.service";
import { Family } from "../../family/family";
import { LabelService } from "../../label/label.service";
import { LABEL_TYPE } from "../../label/label.base";
import { OriginService } from "../../meta/origin/origin.service";
import { BrandService } from "../../meta/brand/brand.service";
import { RegionService } from "../../meta/region/region.service";
import { CategoryService } from "../../meta/category/category.service";
import { ClientService } from "../../../structures/client/client.service";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { ProviderService } from "../../../structures/provider/provider.service";
import { ProviderDisplayComponent } from "../../../structures/provider/provider-display/provider-display.component";
import { ProviderBase } from "../../../structures/provider/provider.base";
import { SelectionService } from "../../../../components/selection/selection.service";
import { SelectItemComponent } from "../../../../components/selection/select-item/select-item.component";
import { SelectHeaderComponent } from "../../../../components/selection/select-header/select-header.component";
import { QuickprintButtonComponent } from "../../../layouts/print/quickprint-button/quickprint-button.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { IocodeDisplayComponent } from "../../../lvadgio/iocode/iocode-display/iocode-display.component";
import { FamilyDisplayComponent } from "../../family/family-display/family-display.component";
import {
  FamilyDisplayListHeaderComponent,
  FamilyDisplayParams,
} from "../../family/family-display-list-header/family-display-list-header.component";
import { ProviderDocumentDisplayComponent } from "../../../structures/provider-document/provider-document-display/provider-document-display.component";
import { ArticleDocumentDisplayComponent } from "../../article-document/article-document-display/article-document-display.component";

@Component({
  selector: "lvadg-article-list",
  standalone: true,
  templateUrl: "./article-list.component.pug",
  styleUrls: ["./article-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgOptimizedImage,
    SeasonsManageComponent,
    LabelDisplayComponent,
    SafeDeleteComponent,
    ImageDisplayComponent,
    ProviderDisplayComponent,
    QuickprintButtonComponent,
    IocodeDisplayComponent,
    IconComponent,
    FactorPipe,
    ChoicePipe,
    PChoicePipe,
    SelectItemComponent,
    SelectHeaderComponent,
    FamilyDisplayComponent,
    FamilyDisplayListHeaderComponent,
    ProviderDocumentDisplayComponent,
    ArticleDocumentDisplayComponent,
  ],
})
export class ArticleListComponent extends ModellistComponent<Article> {
  @Input() public family_detail_route?: Link<FamilyBase>;
  @Input() public provider_detail_route?: Link<ProviderBase>;
  @Input() public select = true;
  public print_filters_whitelist = signal<string[]>([]);
  public familyDisplay = signal<FamilyDisplayParams>({
    display: true,
    mode: "line",
    start: 0,
    end: 5,
  });

  constructor(
    coll: ArticleService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _fs: FamilyService,
    private _ls: LabelService,
    private _ors: OriginService,
    private _brds: BrandService,
    private _regs: RegionService,
    private _cats: CategoryService,
    private _clients: ClientService,
    private _prov: ProviderService,
    private _select: SelectionService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    this.print_filters_whitelist.set([]);
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "libellecode",
          "search",
          "udf_unit",
          "in_family",
          "egalims",
          "egalim_status",
          "origines",
        ],
      filters: [
        new ModelListTextFilter({
          field: "libellecode",
          name: "libellecode",
          label: "Recherche par code ou libellé",
        }),
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte tous critères",
        }),
        new ModelListTextFilter({
          field: "name",
          name: "name",
          label: "Recherche par nom de produit",
        }),

        new ModelListTreeFilter<Family>({
          field: "in_family",
          name: "in_family",
          label: "Recherche par famille",
          collection: this._fs,
          help: "---------------------",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          display: (v) => `${v.name}`,
        }),

        new ModelListAutocompleteMultiFilter({
          field: "egalims",
          name: "egalims",
          label: "Recherche par label(s)",
          collection: this._ls,
          display: (l) => `[${l.type_short}] ${l.name}`,
          filter: {
            types: [LABEL_TYPE.FL, LABEL_TYPE.SEA, LABEL_TYPE.OTHER].join(","),
          },
        }),
        new ModelListSelectFilter({
          field: "egalim_status",
          name: "egalim_status",
          label: "Recherche par état Égalim",
          model: Article,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "origines",
          name: "origines",
          label: "Recherche par origine(s)",
          collection: this._ors,
          display: (o) => `[${o.code}] ${o.name}`,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "regions",
          name: "regions",
          label: "Recherche par région(s)",
          collection: this._regs,
          display: (o) => `[${o.type_sdesc}] ${o.code || o.name}`,
        }),

        new ModelListAutocompleteMultiFilter({
          field: "marques",
          name: "marques",
          label: "Recherche par marque",
          collection: this._brds,
          display: (o) => `${o.name || o.code}`,
        }),
        new ModelListSelectFilter({
          field: "local",
          name: "local",
          label: "Recherche local/non local",
          model: Article,
        }),

        new ModelListAutocompleteMultiFilter({
          field: "categories",
          name: "categories",
          label: "Recherche par catégorie",
          collection: this._cats,
          display: (o) => o.code,
        }),
        new ModelListTextFilter({
          field: "calibre",
          name: "calibre",
          label: "Recherche par calibre",
        }),
        new ModelListSelectFilter({
          field: "with_tarif",
          name: "with_tarif",
          label: "Recherche par présence dans un tarif",
          choices: [
            { value: "yes", desc: "Présent dans au moins un tarif" },
            { value: "no", desc: "Présent dans aucun tarif" },
          ],
        }),
        new ModelListAutocompleteMultiFilter({
          field: "with_client_tarifs",
          name: "with_client_tarifs",
          label: "Recherche par présence dans tarif client",
          collection: this._clients,
          display: (o) => `${o.name}`,
        }),
        new ModelListSelectFilter({
          field: "with_fournisseur",
          name: "with_fournisseur",
          label: "Recherche avec/sans fournisseur",
          choices: [
            { value: "yes", desc: "Avec un fournisseur référencé" },
            { value: "no", desc: "Sans fournisseur référencé" },
          ],
        }),
        new ModelListAutocompleteMultiFilter({
          field: "fournisseurs",
          name: "fournisseurs",
          label: "Recherche par fournisseur référencé",
          collection: this._prov,
          filter: {
            fields: ["id", "cvva", "name"].join(","),
            with_articles: "yes",
          },
          display: (o) => `${o.cvva} - ${o.name}`,
        }),
        new ModelListSelectFilter({
          field: "udf_unit",
          name: "udf_unit",
          label: "Recherche par unité de facturation",
          model: Article,
        }),
        new ModelListFlagsFilter({
          collection: this.coll,
          field: "flags",
          name: "flags",
          label: "Recherche par flag",
        }),
        new ModelListAutocompleteMultiFilter({
          collection: this.coll,
          field: "ids",
          name: "libellecodes",
          label: "Recherche par codes / libellés",
          searchparam: "libellecode",
          display: (o) => `${o.code} ${o.libelle}`,
          filter: { fields: ["code", "libelle"].join(",") },
        }),
        new ModelListSelectFilter({
          field: "have_similar",
          name: "have_similar",
          label: "Recherche par présence de similaires",
          choices: [
            { desc: "Identique", value: 0 },
            { desc: "1 différence", value: 1 },
            { desc: "2 différences", value: 2 },
          ],
        }),
      ],
    };
  }

  public override getFields(): FieldsParams {
    const flds = super.getFields();
    if (this.select) {
      flds.custom?.push({
        name: "select",
        label: "Sélection",
        priority: 2000,
      });
      if (!flds.defaults) {
        flds.defaults = [];
      }
      flds.defaults.unshift("select");
    }
    return flds;
  }

  public override getRowClasses(row: Article): string[] {
    const cls = super.getRowClasses(row);
    if (row.in_tariftype === true) {
      cls.push("table-info");
    }
    if (row.in_eamapping === true) {
      cls.push("table-info");
    }
    if (row.in_op === 1) {
      cls.push("table-success");
    }
    return cls;
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.dispPrint = true;
    if (this.select) {
      this.selection = this._select.getSelection("articles", this.list);
    }
  }
}
