import {Component, Input, OnInit} from "@angular/core";
import {DispeditComponent} from "@solidev/data";
import {CommonModule} from "@angular/common";
import {IconComponent} from "../../../../../components/utils/icon/icon.component";
import {LogoManageComponent} from "../../../../documents/image/logo-manage/logo-manage.component";
import {DocumentService} from "../../../../documents/document/document.service";
import {FolderService} from "../../../../documents/folder/folder.service";
import {NewsService} from "../../news.service";
import {News} from "../../news";
import {ImageDisplayComponent} from "../../../../documents/image/image-display/image-display.component";
import {CommentService} from "../../../comment/comment.service";
import {Comment} from "../../../comment/comment";
import {Observable} from "rxjs";

@Component({
    standalone: true,
    selector: "lvadgf-news-detail",
    templateUrl: "./news-detail.component.pug",
    styleUrls: ["./news-detail.component.sass"],
    imports: [
        CommonModule,
        IconComponent,
        DispeditComponent,
        LogoManageComponent,
        ImageDisplayComponent
    ]
})
export class NewsDetailComponent implements OnInit {
    @Input() public news!: News;
    public fcomment!: Observable<Comment[]>

    constructor(
        public documents$: DocumentService,
        public folders$: FolderService,
        public news$: NewsService,
        public comments$: CommentService
    ) {
    }

    public ngOnInit(): void {
    }
}
