import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTarifTemplateBase } from '../../../tarifs/article-tarif-template/article-tarif-template.base';
import { RnmService } from '../../rnm/rnm.service';
import { RnmattService } from '../rnmatt.service';
import { Rnmatt } from '../rnmatt';
import { ArticleTarifTemplate } from '../../../tarifs/article-tarif-template/article-tarif-template';
import { firstValueFrom, Observable, ReplaySubject, switchMap } from 'rxjs';
import { Rnm } from '../../rnm/rnm';

@Component({
  selector: 'lvadg-rnmatt-create',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmatt-create.component.pug',
  styleUrls: ['./rnmatt-create.component.sass'],
})
export class RnmattCreateComponent implements OnInit, OnChanges {
  @Input() public att?: ArticleTarifTemplateBase;

  public curAtt$ = new ReplaySubject<ArticleTarifTemplate>(1);
  public current$!: Observable<Rnmatt[]>;
  public rnmCandidates$!: Observable<Rnm[]>;
  public selected?: Rnm;

  constructor(private _rnm: RnmService, private _rnmatt: RnmattService) {}

  ngOnInit(): void {
    this.current$ = this.curAtt$.pipe(
      switchMap((att) => {
        return this._rnmatt.list({ att: att.id });
      })
    );
    this.rnmCandidates$ = this.curAtt$.pipe(
      switchMap((att) => {
        return this._rnm.list({ att_match: att.id });
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.curAtt$.next(this.att!);
  }

  async selectRnm(a: Rnm) {
    this.selected = a;
  }

  async associateRnm(a: Rnm) {
    await firstValueFrom(
      this._rnmatt
        .create({
          att: this.att!.id,
          rnm: a.id,
        })
        .save({ updateModel: true })
    );
    this.curAtt$.next(this.att!);
  }
}
