import { MemberBase } from './member.base';
import { detailsField, integerField } from '@solidev/data';
import { LocationBase } from '../../locations/location/location.base';
import { Label } from '../../catalog/label/label';
import { Image } from '../../documents/image/image';

export class Member extends MemberBase {
  static override readonly __name: string = 'Member';

  @integerField({
    name: 'storages_count',
    description: 'Nombre de dépôts',
    readonly: true,
  })
  public storages_count?: number;

  @integerField({
    name: 'near_distance',
    description: 'Distance',
    readonly: true,
  })
  public near_distance?: number;

  @detailsField({
    name: 'location_details',
    description: 'Adresse',
    readonly: true,
    model: LocationBase,
  })
  public location_details?: LocationBase;

  @detailsField({
    name: 'slabels_details',
    description: 'Labels',
    readonly: true,
    model: Label,
    many: true,
  })
  public slabels_details?: Label[];

  @detailsField({
    name: 'logo_details',
    description: 'Logo',
    readonly: true,
    model: Image,
  })
  public logo_details?: Image;

  @detailsField({
    name: 'images_details',
    description: 'Images',
    readonly: true,
    model: Image,
    many: true,
  })
  public images_details?: Image[];
}
