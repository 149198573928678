import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Cdata } from '../cdata';
import { CdataService } from '../cdata.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ProducerDisplayComponent } from '../../../structures/producer/producer-display/producer-display.component';
import { ProductDisplayComponent } from '../../../catalog/product/product-display/product-display.component';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';

@Component({
  selector: 'lvadg-cdata-list',
  standalone: true,
  templateUrl: './cdata-list.component.pug',
  styleUrls: ['./cdata-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    ProducerDisplayComponent,
    ProductDisplayComponent,
    StorageDisplayComponent,
    SafeDeleteComponent,
    PChoicePipe,
  ],
})
export class CdataListComponent extends ModellistComponent<Cdata> {
  constructor(coll: CdataService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
