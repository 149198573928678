import { RoutesConfig } from '@solidev/data';
import { News, RS } from '@vivalya/lvadg';

export const FRONT_NEWS_ROUTES_NAMES = ['actualites', 'actualites_actualite'] as const;

export const FRONT_NEWS_ROUTES_CONFIG: RoutesConfig<(typeof FRONT_NEWS_ROUTES_NAMES)[number]> = {
  actualites: {
    params: [],
    route: () => ['/', RS.news],
    name: 'actualites',
  },
  actualites_actualite: {
    params: ['news'],
    route: (n: News) => ['/', RS.news, n.id],
    name: '  actualites_actualite',
  },
};
