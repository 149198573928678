import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { Resto } from '../../../../../models/structures/resto/resto';
import { map, Observable } from 'rxjs';
import { RESTO_ROUTES } from '../menu';
import { RestoManageComponent } from '../../../../../models/structures/resto/resto-manage/resto-manage.component';
import { RestoNavComponent } from '../_nav/resto-nav.component';

export interface RestoEditViewParams extends BaseRouteParams {
  r_menu: RESTO_ROUTES;
}

export interface RestoEditViewData extends RestoEditViewParams {
  resto: Resto;
}

@Component({
  selector: 'lvadg-resto-edit-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, IconComponent, RestoNavComponent, RestoManageComponent],
  templateUrl: './resto-edit-view.component.pug',
  styleUrls: ['./resto-edit-view.component.sass'],
})
export class RestoEditViewComponent extends BaseDetailViewComponent<RestoEditViewData, Resto> {
  public restoFilter$!: Observable<FilterDefaults>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: RestoEditViewData) {
    super.setRouteData(data);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.restoFilter$ = this.data$.pipe(map((d) => ({ resto: d.resto.id })));
  }
}
