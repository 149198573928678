import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformService } from '../platform.service';
import { PlatformBase } from '../platform.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-platform-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './platform-create.component.pug',
  styleUrls: ['./platform-create.component.sass'],
})
export class PlatformCreateComponent implements OnInit {
  public plt!: PlatformBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<PlatformBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(private _plts: PlatformService) {}

  ngOnInit(): void {
    this.plt = new PlatformBase(this._plts);
  }

  public async save() {
    this.plt.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.plt.save({ updateModel: true }));
    this.created.emit(this.plt);
  }
}
