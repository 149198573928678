import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Meteo } from "./meteo";
import {MeteoFull} from "./meteo-full";
import {Observable, ReplaySubject} from "rxjs";
import {first} from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class MeteoService extends Collection<Meteo> {
  private _meteo?: MeteoFull;
  private _meteo$?: ReplaySubject<MeteoFull>;
  constructor(_backend: DataBackend) {
    super(_backend, "/v3/meteo", Meteo);
  }
  public get full(): Observable<MeteoFull> {
    if (!this._meteo$) {
      this._meteo$ = new ReplaySubject<MeteoFull>(1);
      this.action<MeteoFull>(null, "GET", "latest")
        .pipe(first())
        .subscribe((meteo) => {
          this._meteo = meteo;
          this._meteo$!.next(this._meteo);
        });

    }
    return this._meteo$.asObservable();
  }
}
