import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { User } from '../user';
import { UserService } from '../user.service';
import {
  FilterDefaults,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GroupService } from '../../group/group.service';
import { GroupBase } from '../../group/group.base';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { MemberService } from '../../../structures/member/member.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { ClientService } from '../../../structures/client/client.service';
import { RestoService } from '../../../structures/resto/resto.service';
import { UsersActionService } from '../users-action.service';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'lvadg-user-list',
  standalone: true,
  templateUrl: './user-list.component.pug',
  styleUrls: ['./user-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, IconComponent],
})
export class UserListComponent extends ModellistComponent<User> {
  @Input() public group_detail_route?: Link<GroupBase>;
  protected exportingXlsx: boolean = false;

  constructor(
    coll: UserService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _groups: GroupService,
    private _members: MemberService,
    private _storages: StorageService,
    private _clients: ClientService,
    private _restos: RestoService,
    private _uactions: UsersActionService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'is_active', 'groups', 'all_groups'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'is_active',
          name: 'is_active',
          label: 'Filtrer par activité',
          help: 'Actif / inactif',
          choices: [
            { desc: 'Actif', value: true },
            { desc: 'Inactif', value: false },
          ],
        }),
        new ModelListSelectFilter({
          field: 'is_staff',
          name: 'is_staff',
          label: 'Filtrer par statut staff',
          help: 'Staff / non staff',
          choices: [
            { desc: 'Staff', value: true },
            { desc: 'Non staff', value: false },
          ],
        }),
        new ModelListSelectFilter({
          field: 'is_superuser',
          name: 'is_superuser',
          label: 'Filtrer par statut superuser',
          help: 'Superuser / non superuser',
          choices: [
            { desc: 'Superuser', value: true },
            { desc: 'Non superuser', value: false },
          ],
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'groups',
          name: 'groups',
          label: 'Filtrer par groupe(s) - au moins un',
          desc: 'Sélectionnez un groupe',
          help: 'Recherche par nom de groupe / slug',
          collection: this._groups,
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'all_groups',
          name: 'all_groups',
          label: 'Filtrer par groupe(s) - tous',
          desc: 'Sélectionnez un groupe',
          help: 'Recherche par nom de groupe / slug',
          collection: this._groups,
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'members',
          name: 'members',
          label: 'Filtrer par adhérent(s)',
          desc: 'Sélectionnez un adhérent',
          help: 'Recherche par adhérent',
          collection: this._members,
          filter: { fields: ['id', 'cvva', 'name'].join(',') },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'storages',
          name: 'storages',
          label: 'Filtrer par dépôt(s)',
          desc: 'Sélectionnez un dépôt',
          help: 'Recherche par dépôt',
          collection: this._storages,
          filter: { fields: ['id', 'cvva', 'name'].join(',') },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'clients',
          name: 'clients',
          label: 'Filtrer par client(s)',
          desc: 'Sélectionnez un client',
          help: 'Recherche par client',
          collection: this._clients,
          filter: { fields: ['id', 'cvva', 'name'].join(',') },
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'restos',
          name: 'restos',
          label: 'Filtrer par resto(s)',
          desc: 'Sélectionnez un resto',
          help: 'Recherche par resto',
          collection: this._restos,
          filter: { fields: ['id', 'cvva', 'name'].join(',') },
        }),
      ],
    };
  }

  public override getRowClasses(row: User): string[] {
    const cls = super.getRowClasses(row);
    if (row.is_staff) {
      cls.push('table-warning');
    }
    if (row.is_superuser) {
      cls.push('table-danger');
    }
    if (!row.is_active) {
      cls.push('table-secondary');
    }
    return cls;
  }

  public async exportXlsx(): Promise<void> {
    if (this.exportingXlsx) {
      return;
    }
    this.exportingXlsx = true;
    let filters = (await firstValueFrom(this.list.filters.output)) || {};
    if (this.filter.subscribe) {
      filters = {
        ...filters,
        ...(await firstValueFrom(this.filter as Observable<FilterDefaults>)),
      };
    } else {
      filters = {
        ...filters,
        ...(this.filter as FilterDefaults),
      };
    }
    const res = await this._uactions.action<{ url: string }>({
      action: 'export',
      filter: filters,
    });
    this.exportingXlsx = false;
    if (res.success) {
      window.open(res.data?.url);
    }
  }
}
