import { foreignKeyField } from '@solidev/data';
import { ArticleMetaBase, META_BASE_TYPE } from '../meta.base';

export type CALIBRE_TYPE = META_BASE_TYPE;

export class CalibreBase extends ArticleMetaBase {
  static override readonly __name: string = 'CalibreBase';

  @foreignKeyField({
    name: 'family',
    description: 'ID Famille',
    related: 'Family',
    priority: -1,
  })
  public family!: number;
}
