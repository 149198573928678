
<ng-container *ngIf="data$|async; let data">
  <ng-template let-offcanvas #docEditSlot>
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">Documents fournisseur</h5>
      <button class="btn-close" (click)="offcanvas.close()"></button>
    </div>
    <div class="offcanvas-body" *ngIf="currentDocument(); let cd">
      <lvadg-provider-document-manage [providerdocument]="cd"></lvadg-provider-document-manage>
    </div>
  </ng-template>
  <lvadg-provider-nav [provider]="data.provider" [routes]="data.pr_menu"></lvadg-provider-nav>
  <lvadg-header-action [title]="data.provider.name" level="h1" ri="provider" subtitle="Documents fournisseur">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="createDocument=!createDocument"><i class="bi bi-plus-circle me-2"></i>Ajouter un document</button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="createDocument">
    <div class="card-body">
      <lvadg-provider-document-create [provider]="data.provider" (uploaded)="createDocument=false; reload$.next(true)"></lvadg-provider-document-create>
    </div>
  </div>
  <lvadg-provider-document-list [name]="data.route.name+'-documents'" [filter]="providerFilter$" [reload]="reload$" [actions]="['delete', 'edit']" [article_detail_route]="article_detail_route" (action)="onAction($event, data)" [default_fields]="default_fields"></lvadg-provider-document-list>
</ng-container>