import {
  charField,
  DataModel,
  datetimeField,
  decimalField,
  foreignKeyField,
  primaryField,
} from '@solidev/data';
import { Units, UnitsChoices } from '../../catalog/common/units';

export class OrderprocessarticleBase extends DataModel {
  static override readonly __name: string = 'OrderprocessarticleBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  // Tracking mixin
  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  // Base fields
  @foreignKeyField({
    name: 'process',
    description: 'ID Processus de commande',
    related: 'OrderProcess',
  })
  public process!: number;

  @foreignKeyField({
    name: 'att',
    description: 'ID Article Tarif',
    related: 'ArticleTarifTemplate',
  })
  public att?: number;

  @foreignKeyField({
    name: 'article',
    description: 'ID Article',
    related: 'Article',
  })
  public article!: number;

  // Article infos mixin
  @decimalField({
    name: 'unit_price',
    description: 'Prix HT / UDF',
    required: false,
    factor: 10000,
  })
  public unit_price?: number;

  @decimalField({
    name: 'unit_weight',
    description: 'Poids / UDV (kg)',
    required: false,
    factor: 100000,
  })
  public unit_weight?: number;

  @charField({
    name: 'colisage',
    description: 'Colisage',
    required: false,
  })
  public colisage?: string;

  @charField({
    name: 'udv_unit',
    description: 'Unité de vente',
    maxLength: 2,
    choices: UnitsChoices,
  })
  public udv_unit!: Units;

  @charField({
    name: 'udf_unit',
    description: 'Unité de facturation',
    maxLength: 2,
    choices: UnitsChoices,
  })
  public udf_unit!: Units;

  @decimalField({
    name: 'udv_to_udf_ratio',
    description: 'Ratio udv - udf',
    required: false,
    factor: 100000,
  })
  public udv_to_udf_ratio?: number;
}
