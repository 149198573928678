
<div class="row g-3" *ngIf="article">
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="bi bi-info-circle-fill me-2"></i>Infos article</div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12 subtitle first">Nomenclature & classification</div>
          <data-dispedit class="col-sm-6" [model]="article" field="name" mode="dd">Nom</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="code" mode="dd">Code</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="scname" mode="dd">Nom scientifique</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="libelle" mode="dd">Libellé complet</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="libelle_auto" mode="dd" [editable]="false">Libellé (auto)</data-dispedit>
          <div class="col-sm-6">
            <div class="btn-group">
              <button class="btn btn-outline-secondary" (click)="genLibelle(article)"><i class="fa fa-cogs me-2"></i>Rafraîchir le libellé auto</button>
              <button class="btn btn-outline-primary" (click)="useAutoLibelle(article)"><i class="fa fa-save me-2"></i>Utiliser le libellé auto</button>
            </div>
          </div>
          <data-dispedit class="col-sm-12" [model]="article" field="url" mode="dd">URL infos spécifiques</data-dispedit>
          <div class="col-sm-12">
            <dt>Classification (liens)</dt>
            <dd>
              <ng-container *ngFor="let f of article.family_details?.tree_path"><a class="link" [routerLink]="family_detail_route?.route({family: $any({id: f.id})})"><span class="me-2">&gt; {{f.name}}</span></a></ng-container>
            </dd>
          </div>
          <data-dispedit class="col-sm-12" [model]="article" field="family" mode="dd" [collection]="families$">Modification de la famille</data-dispedit>
        </dl>
        <dl class="row">
          <div class="col-12 subtitle">Saisonnalité et infos marché
            <!-- data-dispedit.col-sm-6([model]="article", field="rnm", mode="dd") RNM-->
          </div>
          <div class="col-xl-6">
            <dt>Saisonnalité</dt>
            <dd>
              <lvadg-seasons-manage [seasons]="article.seasons" [zone]="article.type" [per_line]="12" [editable]="true" size="medium" (changed)="updateField(article, 'seasons', $event)"></lvadg-seasons-manage>
            </dd>
          </div>
          <div class="col-xl-6">
            <dt>Infos marché</dt>
            <dd>
              <pre class="todo">en préparation</pre>
            </dd>
          </div>
        </dl>
        <dl class="row">
          <div class="col-12 subtitle">Infos internes</div>
          <div class="col-sm-4">
            <dt>Création</dt>
            <dd>{{article.created|date:'short'}}</dd>
          </div>
          <div class="col-sm-4">
            <dt>Mise à jour</dt>
            <dd>{{article.updated|date:'short'}}</dd>
          </div>
          <data-dispedit class="col-sm-4" [model]="article" field="vvid" mode="dd">ID Vivalya</data-dispedit>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="bi bi-truck me-2"></i>Infos conditionnement & facturation</div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12 subtitle first">DLC & Conservation</div>
          <data-dispedit class="col-sm-6" [model]="article" field="dlc_value" mode="dd">Valeur DLC</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="dlc_unit" mode="dd">Unité DLC</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="ddm_value" mode="dd">Valeur DDM</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="ddm_unit" mode="dd">Unité DDM</data-dispedit>
          <data-dispedit class="col-sm-12" [model]="article" field="temperature" mode="dd">Température de conservation</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">Poids min/moy/max (du colisage)</div>
          <data-dispedit class="col-sm-4" [model]="article" field="poids_min" mode="dd">Poids minimum (kg)</data-dispedit>
          <data-dispedit class="col-sm-4" [model]="article" field="poids_moyen" mode="dd">Poids minimum (kg)</data-dispedit>
          <data-dispedit class="col-sm-4" [model]="article" field="poids_max" mode="dd">Poids maxmimum (kg)</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">Colisage ([nombre] x [conditionnement] de [valeur] [unité])</div>
          <data-dispedit class="col-sm-2" [model]="article" field="colisage_nombre" mode="dd">Nombre</data-dispedit>
          <data-dispedit class="col-sm-3" [model]="article" field="colisage_cond" mode="dd" [collection]="packaging$" [filter]="{types: [PKTYPE.COLISAGE, PKTYPE.UCI_COLISAGE].join(',')}">Conditionnement</data-dispedit>
          <data-dispedit class="col-sm-2" [model]="article" field="colisage_poids" mode="dd">Poids / valeur</data-dispedit>
          <data-dispedit class="col-sm-2" [model]="article" field="colisage_unit" mode="dd">Unité</data-dispedit>
          <div class="col-sm-3 text-center border-start border-black">
            <dt>Résultat</dt>
            <dd>{{article.colisage}}</dd>
          </div>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">UCI ([conditionnement] de [valeur] [unité])</div>
          <data-dispedit class="col-sm-3" [model]="article" field="uci_cond" [collection]="packaging$" mode="dd" [filter]="{types: [PKTYPE.UCI, PKTYPE.UCI_COLISAGE].join(',')}">Conditionnement</data-dispedit>
          <data-dispedit class="col-sm-3" [model]="article" field="uci_value" mode="dd">Valeur</data-dispedit>
          <data-dispedit class="col-sm-3" [model]="article" field="uci_unit" mode="dd">Unité</data-dispedit>
          <div class="col-sm-3 text-center border-start border-black">
            <dt>Résultat</dt>
            <dd>{{article.uci}}</dd>
          </div>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">UDF / UDV</div>
          <data-dispedit class="col-xl-3 col-sm-6" [model]="article" field="udf_unit" mode="dd">Unité de facturation</data-dispedit>
          <data-dispedit class="col-xl-3 col-sm-6" [model]="article" field="udv_unit" mode="dd">Unité de vente</data-dispedit>
          <data-dispedit class="col-xl-3 col-sm-6" [model]="article" field="udv_value" mode="dd">Facteur UDV / UDF</data-dispedit>
          <data-dispedit class="col-xl-3 col-sm-6" [model]="article" field="decondi_mode" mode="dd">Mode de déconditionnement</data-dispedit>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="bi bi-info-circle-fill me-2"></i>Infos qualité</div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12 subtitle first">
            <div class="d-flex flex-row justify-content-center"><span>Egalim & labels</span>
              <div class="form-check form-switch ms-3">
                <input class="form-check-input" id="allow_labels_edition" type="checkbox" #cbb (change)="allow_labels_edition.set(cbb.checked||false)">
                <label class="form-check-label" for="allow_labels_edition">Éditer les labels</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <lvadg-labels-manager class="col-12" [item]="article" [type]="[LBL.FL, LBL.SEA, LBL.OTHER]" source="egalim" [save]="true" [catalog_only]="true" [mode]="allow_labels_edition()?'edit':'view'"></lvadg-labels-manager>
          </div>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">Infos qualité</div>
          <data-dispedit class="col-sm-6" [model]="article" field="calibre" [collection]="calibres$" [filter]="{article: article.id}" mode="dd">Calibre (dépend de la famille)</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="categorie" [collection]="categories$" mode="dd">Catégorie</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="particularite" [collection]="particularites$" mode="dd">Particularité</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="presentation" [collection]="presentations$" mode="dd">Présentation</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">Infos production</div>
          <data-dispedit class="col-sm-6" [model]="article" field="local" mode="dd">Local</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="process" [collection]="processes$" mode="dd">Process</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="traitement" [collection]="traitements$" mode="dd">Traitement</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="production" [collection]="productions$" mode="dd">Production</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <data-dispedit class="col-sm-6" [model]="article" field="marque" [collection]="marques$" mode="dd">Marque</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="fournisseur" [collection]="providers$" mode="dd">Fournisseur référencé</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">Infos géographiques</div>
          <data-dispedit class="col-sm-6" [model]="article" field="origine" [collection]="origines$" mode="dd">Origine</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="region" [collection]="regions$" mode="dd">Région</data-dispedit>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 subtitle">Infos spécifiques</div>
          <!-- data-dispedit.col-sm-6([model]="article", field="client", [collection]="clients$", mode="dd") Client-->
          <data-dispedit class="col-sm-6" [model]="article" field="usage" [collection]="usages$" mode="dd">Usage</data-dispedit>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa bi bi-bar-chart-fill me-2"></i>Infos nutritionnelles</div>
      <div class="card-body">
        <dl class="row" *ngIf="article">
          <data-dispedit class="col-sm-6" [model]="article" field="ciqual_alim" mode="dd" [collection]="ciquals$" (changed)="reload()">Référence ciqual</data-dispedit>
          <div class="col-sm-6">
            <p class="text-info" *ngIf="article.ciqual"><a [href]="'https://ciqual.anses.fr/#/aliments/'+article.ciqual_alim_details?.alim_code+'/data'" target="_blank"><i class="fa fa-external-link me-2"></i>Source: CIQUAL</a></p>
          </div>
          <data-dispedit class="col-sm-6" [model]="article" field="allergenes" [collection]="allergenes$" mode="dd">Allergènes</data-dispedit>
          <data-dispedit class="col-sm-6" [model]="article" field="recette" mode="dd">Recette</data-dispedit>
        </dl>
        <div class="maxh">
          <table class="table table-sm table-borderless table-striped" *ngIf="article.ciqual_alim">
            <tbody>
              <tr *ngFor="let c of article.ciqual_alim_details?.values_details">
                <td>{{c.column_name}}</td>
                <td>
                  <ng-container *ngIf="c.value!=null">{{c.less?'< ': ''}} {{c.value/100000}} {{c.column_unit}}</ng-container>
                  <ng-container *ngIf="c.value==null">{{c.traces?'traces':''}}</ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-file-archive me-2"></i>Documents<span class="todo ms-2">WIP</span></div>
      <div class="card-body">
        <!--h3 Fiches techniques-->
        <!--lvadg-detaildocument-listmanage([model]="article",-->
        <!--  [documents]="article.documents_details",-->
        <!--  (deleted)="onDocumentDeleted()",-->
        <!--  (added)="onUploaded($event)",-->
        <!--  [uploadData]="article.fournisseur?{provider: article.fournisseur}:{}",-->
        <!--  type="FT",-->
        <!--  [fields]="['title','type', 'status', 'prlabel', 'articles', 'articlefamily']")-->
        <!--h3 Autres documents-->
        <!--lvadg-detaildocument-listmanage([model]="article",-->
        <!--  [documents]="article.documents_details",-->
        <!--  (deleted)="onDocumentDeleted()",-->
        <!--  (added)="onUploaded($event)",-->
        <!--  [uploadData]="{}",-->
        <!--  type="OT",-->
        <!--  [fields]="['title','type', 'status', 'articles', 'articlefamily']")-->
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-image me-2"></i>Image & texte<span class="todo ms-2">WIP</span></div>
      <div class="card-body">
        <div class="row">
          <div class="col-4 text-info text-center mb-2"></div>
          <!--  div-->
          <!--    b Logo courant-->
          <!--  lvadg-rimg([src]="article.clogo_img", size="lg", display="gallery")-->
          <!--.col-8-->
          <!--  lvadg-images-manage([model]="article")-->
          <!--.col-12.text-info.text-center.mb-2-->
          <!--  b Texte complémentaire-->
          <!--data-dispedit.col-12([model]="article", field="content_src", mode="dd") Description longue-->
        </div>
      </div>
    </div>
  </div>
</div>