import { ContactBase } from './contact.base';
import { detailsField } from '@solidev/data';
import { Resto } from '../../structures/resto/resto';
import { User } from '../user/user';
import { Client } from '../../structures/client/client';
import { Member } from '../../structures/member/member';
import { Storage } from '../../structures/storage/storage';
import { Tariftype } from '../../tarifs/tariftype/tariftype';

export class Contact extends ContactBase {
  static override readonly __name: string = 'Contact';

  /** Resto details */
  @detailsField({
    name: 'resto_details',
    description: 'Restaurant',
    model: Resto,
    readonly: true,
    priority: 700,
  })
  public resto_details?: Resto;

  /** Owner details */
  @detailsField({
    name: 'owner_details',
    description: 'Propriétaire',
    model: User,
    readonly: true,
    priority: 300,
  })
  public owner_details?: User;

  /** Client details */
  @detailsField({
    name: 'client_details',
    description: 'Client',
    model: Client,
    readonly: true,
    priority: 700,
  })
  public client_details?: Client;

  /** Member details */
  @detailsField({
    name: 'member_details',
    description: 'Adhérent',
    model: Member,
    readonly: true,
    priority: 700,
  })
  public member_details?: Member;

  /** Storage details */
  @detailsField({
    name: 'storage_details',
    description: 'Dépôt',
    model: Storage,
    readonly: true,
    priority: 700,
  })
  public storage_details?: Resto;

  /** User details */
  @detailsField({
    name: 'user_details',
    description: 'Utilisateur',
    model: User,
    readonly: true,
    priority: 700,
  })
  public user_details?: User;

  /** Tariftype details */
  @detailsField({
    name: 'tariftype_details',
    description: 'Type de tarif',
    model: Tariftype,
    readonly: true,
    priority: 700,
  })
  public tariftype_details?: Tariftype;
}
