import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataModel, DispeditComponent } from '@solidev/data';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lvadg-note-dispedit',
  standalone: true,
  imports: [CommonModule, NgbPopoverModule, DispeditComponent],
  templateUrl: './note-dispedit.component.pug',
  styleUrls: ['./note-dispedit.component.sass'],
})
export class NoteDispeditComponent<T extends DataModel> {
  @Input() public model!: T;
  @Input() public note?: string;
  @Input() public field: string = 'message';
  @Input() public mode: 'icon' | 'text' = 'icon';
  public edit = false;
  public pop!: NgbPopover;

  constructor(private _san: DomSanitizer) {}

  public onClick(p: NgbPopover, edit: boolean = false): void {
    this.pop = p;
    if (p.isOpen()) {
      this.edit = true;
    } else if (edit) {
      this.pop = p;
      p.open();
      this.edit = true;
    }
  }

  public onEnter(p: NgbPopover): void {
    this.pop = p;
    p.open();
  }

  public onLeave(p: NgbPopover): void {
    this.pop = p;
    if (!this.edit) {
      p.close();
    }
  }

  public onUpdated() {
    this.pop.close();
    this.edit = false;
  }
}
