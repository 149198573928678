import { Component } from '@angular/core';

import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { TarifNotificationDestinationListComponent } from '../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination-list/tarif-notification-destination-list.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TarifNotificationDestination } from '../../../../models/tarifs/tarif-notification-destination/tarif-notification-destination';

export interface TarifNotificationDestinationListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface TarifNotificationDestinationListViewData extends TarifNotificationDestinationListViewParams {}

@Component({
  selector: 'lvadg-tarif-notification-destination-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TarifNotificationDestinationListComponent],
  templateUrl: './tarif-notification-destination-list-view.component.pug',
  styleUrls: ['./tarif-notification-destination-list-view.component.sass'],
})
export class TarifNotificationDestinationListViewComponent extends BaseListViewComponent<
  TarifNotificationDestinationListViewData,
  TarifNotificationDestination
> {
  public override setRouteData(data: TarifNotificationDestinationListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
