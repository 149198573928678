import { Component, inject, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OfferProduct } from "../offer-product";
import { OfferProductService } from "../offer-product.service";
import {
  BanAdapter,
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListFilterGroup,
  ModelListGeodistanceFilter,
  ModelListNumberFilter,
  ModelListSelectFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  ModelListTreeFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { Product } from "../../../catalog/product/product";
import { Offer } from "../../offer/offer";
import { OfferDisplayComponent } from "../../offer/offer-display/offer-display.component";
import { ProductDisplayComponent } from "../../../catalog/product/product-display/product-display.component";
import { ProducerDisplayComponent } from "../../../structures/producer/producer-display/producer-display.component";
import { Producer } from "../../../structures/producer/producer";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { LocationDisplayComponent } from "../../../locations/location/location-display/location-display.component";
import { ArticleTarifTemplateDisplayComponent } from "../../../tarifs/article-tarif-template/article-tarif-template-display/article-tarif-template-display.component";
import { ArticleDisplayComponent } from "../../../catalog/article/article-display/article-display.component";
import { EarticleDisplayComponent } from "../../../catalog/earticle/earticle-display/earticle-display.component";
import { ClientService } from "../../../structures/client/client.service";
import { ArticleTarifDisplayComponent } from "../../../tarifs/article-tarif/article-tarif-display/article-tarif-display.component";
import { Tarif } from "../../../tarifs/tarif/tarif";
import { Article } from "../../../catalog/article/article";
import { ArticleTarifTemplate } from "../../../tarifs/article-tarif-template/article-tarif-template";
import { Earticle } from "../../../catalog/earticle/earticle";
import { OfferActionService } from "../../offer/offer-action.service";
import { OfferService } from "../../offer/offer.service";
import { firstValueFrom, map } from "rxjs";
import { Family } from "../../../catalog/family/family";
import { ProducerService } from "../../../structures/producer/producer.service";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { FAMILY_TYPE } from "../../../catalog/family/family.base";
import { LabelService } from "../../../catalog/label/label.service";
import { FamilyService } from "../../../catalog/family/family.service";
import { RelationDisplayComponent } from "../../../structures/relation/relation-display/relation-display.component";
import { Storage } from "../../../structures/storage/storage";
import { Relation } from "../../../structures/relation/relation";
import { SeasonsManageComponent } from "../../../catalog/common/seasons-manage/seasons-manage.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "lvadg-offer-product-list",
  standalone: true,
  templateUrl: "./offer-product-list.component.pug",
  styleUrls: ["./offer-product-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    OfferDisplayComponent,
    ProductDisplayComponent,
    ProducerDisplayComponent,
    LabelDisplayComponent,
    LocationDisplayComponent,
    ArticleTarifTemplateDisplayComponent,
    ArticleTarifDisplayComponent,
    ArticleDisplayComponent,
    EarticleDisplayComponent,
    RelationDisplayComponent,
    SeasonsManageComponent,
  ],
})
export class OfferProductListComponent extends ModellistComponent<OfferProduct> {
  @Input() public product_detail_route?: Link<Product>;
  @Input() public offer_detail_route?: Link<Offer>;
  @Input() public producer_detail_route?: Link<Producer>;
  @Input() public tarif_detail_route?: Link<Tarif>;
  @Input() public article_detail_route?: Link<Article>;
  @Input() public att_detail_route?: Link<ArticleTarifTemplate>;
  @Input() public earticle_detail_route?: Link<Earticle>;
  @Input() public family_detail_route?: Link<Family>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public relation_detail_route?: Link<Relation>;

  private _producers$ = inject(ProducerService);
  private _labels$ = inject(LabelService);
  private _families$ = inject(FamilyService);

  constructor(
    coll: OfferProductService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _http: HttpClient,
    private _oa: OfferActionService,
    private _os: OfferService,
    private _clients$: ClientService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    const adapter = new BanAdapter(
      this._http,
      "https://gis.lavieadugout.fr/geocoder/search",
    );
    const generalSearch = new ModelListFilterGroup({
      name: "general",
      desc: "Recherche générale",
      label: "Recherche générale",
      allowed: true,
      enabled: true,
      position: 0,
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
      ],
    });

    const producerSearch = new ModelListFilterGroup({
      name: "producer",
      desc: "Recherche par producteur",
      label: "Producteur",
      allowed: true,
      enabled: true,
      position: 0,
      filters: [
        new ModelListAutocompleteMultiFilter({
          field: "producers",
          name: "producers",
          label: "Recherche par producteur(s)",
          desc: "Sélectionnez un ou plusieurs producteurs",
          collection: this._producers$,
          // SEE: add a filter to get only producers of **this** offer
          filter: { have_offers: true, fields: ["id", "name"].join(",") },
        }),
        new ModelListNumberFilter({
          name: "producer_storage_distance",
          field: "producer_storage_distance",
          label: "Recherche par distance dépôt-producteur",
          desc: "Distance en km",
        }),
        // Recherche par producteur à proximité
        new ModelListGeodistanceFilter({
          name: "producer_near",
          field: "producer_near",
          label: "Recherche producteur à proximité de  (à vol d'oiseau)",
          desc: "Distance en km  (à vol d'oiseau)",
          help: "Ville ou adresse (en France)",
          geolocator: (pos: string) => adapter.search(pos),
        }),
        // Commune du dépôt
      ],
    });

    const productsFilter = new ModelListFilterGroup({
      name: "products_filter",
      label: "Recherche par caractéristiques produit",
      desc: "Filtres sur les caractéristiques produit",
      position: 4,
      filters: [
        // Product filter
        new ModelListSelectMultiFilter({
          field: "product_labels",
          name: "product_labels",
          label: "Recherche par label(s) produit(s)",
          // FIXME: get label type from some input
          choices: this._labels$.byType(LABEL_TYPE.FL).pipe(
            map((labels) =>
              labels.map((l) => ({
                value: l.id,
                desc: `${l.type} : ${l.name}`,
              })),
            ),
          ),
        }),
        // État Égalim
        new ModelListSelectFilter({
          field: "product_egalim_status",
          name: "product_egalim_status",
          label: "Recherche par état Égalim",
          model: Product,
          mfield: "egalim_status",
        }),
        // Saisonnalité
        new ModelListSelectMultiFilter({
          field: "product_seasons",
          name: "product_seasons",
          label: "Saisonnalité(s) (au moins une)",
          choices: [
            { desc: "Janvier", value: "1" },
            { desc: "Février", value: "2" },
            { desc: "Mars", value: "3" },
            { desc: "Avril", value: "4" },
            { desc: "Mai", value: "5" },
            { desc: "Juin", value: "6" },
            { desc: "Juillet", value: "7" },
            { desc: "Août", value: "8" },
            { desc: "Septembre", value: "9" },
            { desc: "Octobre", value: "10" },
            { desc: "Novembre", value: "11" },
            { desc: "Décembre", value: "12" },
          ],
        }),
        // Saisonnalité
        new ModelListSelectMultiFilter({
          field: "product_seasons_all",
          name: "product_seasons_all",
          label: "Saisonnalité(s) (toutes)",
          choices: [
            { desc: "Janvier", value: "1" },
            { desc: "Février", value: "2" },
            { desc: "Mars", value: "3" },
            { desc: "Avril", value: "4" },
            { desc: "Mai", value: "5" },
            { desc: "Juin", value: "6" },
            { desc: "Juillet", value: "7" },
            { desc: "Août", value: "8" },
            { desc: "Septembre", value: "9" },
            { desc: "Octobre", value: "10" },
            { desc: "Novembre", value: "11" },
            { desc: "Décembre", value: "12" },
          ],
        }),
        // // REMOVED : not easy to use
        // // Famille (exacte)
        // new ModelListTreeFilter({
        //   field: "product_exact_family",
        //   name: "product_exact_family",
        //   label: "Famille (exacte) - sélection",
        //   help: "---------------------",
        //   filter: {
        //     fields: ["id", "name", "parent"].join(","),
        //     type: FAMILY_TYPE.VIVALYA,
        //   },
        //   display: (v) => `${v.name}`,
        //   collection: this._families$,
        // }),
        // Famille et sous-familles
        new ModelListTreeFilter({
          field: "product_parent_family",
          name: "product_parent_family",
          label: "Famille et sous-famille - sélection",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          help: "---------------------",
          display: (v) => `${v.name}`,
          collection: this._families$,
        }),
        // Recherche par famille(s) exacte(s)
        new ModelListAutocompleteMultiFilter({
          field: "product_exact_families",
          name: "product_exact_families",
          label: "Recherche par famille(s) exacte(s)",
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
          collection: this._families$,
        }),
        // Recherche par famille(s) contenant
        new ModelListAutocompleteMultiFilter({
          field: "product_parent_families",
          name: "product_parent_families",
          label: "Recherche par famille(s) et sous-familles",
          collection: this._families$,
          filter: {
            fields: ["id", "name", "parent"].join(","),
            type: FAMILY_TYPE.VIVALYA,
          },
        }),
      ],
    });

    const specialFilters = new ModelListFilterGroup({
      name: "special",
      label: "Filtres spéciaux",
      desc: "Filtres spéciaux",
      position: 5,
      filters: [
        new ModelListSelectFilter({
          field: "type",
          name: "type",
          label: "Filtrer par type de produit",
          model: OfferProduct,
        }),
        new ModelListAutocompleteMultiFilter({
          field: "clients",
          name: "clients",
          label: "Filtrer par client(s)",
          desc: "Sélectionnez un ou plusieurs clients",
          collection: this._clients$,
          filter: { have_offers: true },
        }),
        new ModelListSelectFilter({
          field: "with_links",
          name: "with_links",
          label: "Filtrer par lien(s)",
          choices: [
            { value: "article", desc: "Avec lien catalogue" },
            { value: "earticle", desc: "Avec lien catalogue client" },
            { value: "att", desc: "Avec lien modèle tarif" },
            { value: "at", desc: "Avec ligne de tarif" },
            { value: "any", desc: "Tout lien disponible" },
            { value: "none", desc: "Sans aucun lien" },
          ],
        }),
      ],
    });

    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "producers",
          "product_parent_families",
          "product_egalim_status",
          "product_labels",
        ],
      filters: [generalSearch, producerSearch, productsFilter, specialFilters],
    };
  }

  public async deleteOfferProduct(offerproduct: OfferProduct) {
    const oid = offerproduct.offer || offerproduct.offer_details?.id;
    if (!oid) {
      throw new Error("OfferProduct has no offer");
    }

    const offer = await firstValueFrom(this._os.fetch(oid));
    const res = await this._oa.action(offer, {
      action: "remove_product",
      offerproduct: offerproduct.id,
    });
    if (res) {
      this.reload?.next();
    }
    this.ofcInstance?.close();
  }
}
