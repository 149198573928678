
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OffertypeTextType } from "./offertype-text-type";

@Injectable({
  providedIn: 'root'
})
export class OffertypeTextTypeService extends Collection<OffertypeTextType> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offertypetexttypes", OffertypeTextType);
  }
}
