import { TarifNotificationBase } from './tarif-notification.base';
import { charField, detailsField } from '@solidev/data';
import { TariftypeBase } from '../tariftype/tariftype.base';
import { TarifBase } from '../tarif/tarif.base';
import {
  TarifNotificationStatusChoices,
  TarifNotificationStatusStatus,
} from '../tarif-notification-status/tarif-notification-status.base';

export class TarifNotification extends TarifNotificationBase {
  static override readonly __name: string = 'TarifNotification';

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
    priority: 500,
  })
  public tariftype_details?: TariftypeBase;

  @detailsField({
    model: TarifBase,
    description: 'Tarif',
    readonly: true,
    priority: 450,
  })
  public tarif_details?: TarifBase;

  @charField({
    name: 'user_status',
    description: 'Etat utilisateur',
    maxLength: 3,
    readonly: true,
    choices: TarifNotificationStatusChoices,
    priority: -1,
  })
  public user_status?: TarifNotificationStatusStatus;
}
