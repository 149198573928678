import { Component, Inject } from '@angular/core';

import { distinctUntilChanged, filter, map, mergeWith, Observable, Subject, tap } from 'rxjs';
import { ActivatedRoute, ActivationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { BaseRouteParams, DATA_AUTH_SERVICE, RouteConfigItem } from '@solidev/data';
import { BaseListViewComponent } from '../../../components/baseview/baselistview.component';
import { Folder } from '../../../models/documents/folder/folder';
import { AuthService } from '../../../models/users/auth.service';
import { BASE_LIST_VIEW_IMPORTS } from '../../../components/baseview/baselistview.imports';
import { FolderListComponent } from '../../../models/documents/folder/folder-list/folder-list.component';
import { FolderCreateComponent } from '../../../models/documents/folder/folder-create/folder-create.component';
import { FolderTreeComponent } from '../../../models/documents/folder/folder-tree/folder-tree.component';
import { IconComponent } from '../../../components/utils/icon/icon.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface FolderListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  display: 'flat' | 'tree';
  treeName: string;
}

export interface FolderListViewData extends FolderListViewParams {
  folder: Folder | null;
}

@Component({
  selector: 'lvadg-folder-list-view',
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    FolderListComponent,
    RouterLink,
    RouterOutlet,
    FolderCreateComponent,
    FolderTreeComponent,
    IconComponent,
  ],
  templateUrl: './folder-list-view.component.pug',
  styleUrls: ['./folder-list-view.component.sass'],
})
export class FolderListViewComponent extends BaseListViewComponent<FolderListViewData, Folder> {
  public create: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public current: Folder | null = null;
  public currentFolder$?: Observable<Folder>;

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    @Inject(DATA_AUTH_SERVICE) public auth: AuthService,
  ) {
    super(_router, _route);
  }

  public override setRouteData(data: FolderListViewData) {
    super.setRouteData(data);
    this.current = data.folder;
  }

  public override postNgOnInit() {
    super.postNgOnInit();

    const childNavigation = this._router.events.pipe(
      takeUntilDestroyed(this._destroyRef),
      filter((routerEvent) => routerEvent instanceof ActivationEnd),
      filter(() => !!this._route.snapshot.firstChild?.data),
      map(() => this._route.snapshot.firstChild?.data as FolderListViewData),
      filter((data) => !!data.folder),
      map((data) => data.folder!),
    );
    const selfNavigation = this.data$.pipe(
      takeUntilDestroyed(this._destroyRef),
      filter(() => !!this._route.snapshot.firstChild?.data),
      map(() => this._route.snapshot.firstChild?.data as FolderListViewData),
      filter((data) => !!data.folder),
      map((data) => data.folder as Folder),
    );
    this.currentFolder$ = selfNavigation.pipe(
      mergeWith(childNavigation),
      tap((folder) => (this.current = folder)),
      distinctUntilChanged((a, b) => a.id === b.id),
    );
  }

  public userCanEdit(): boolean {
    console.log(this.auth.currentUser);
    return !!(this.auth.currentUser && (this.auth.currentUser.is_superuser || this.auth.currentUser.is_staff));
  }
}
