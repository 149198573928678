import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offer } from '../offer';
import { OffertypeService } from '../../offertype/offertype.service';
import { OFFER_USER_LEVEL } from '../offer.base';
import { DispeditComponent, FilterDefaults } from '@solidev/data';
import { OffertypeStorageService } from '../../offertype-storage/offertype-storage.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { UserService } from '../../../users/user/user.service';
import { ClientService } from '../../../structures/client/client.service';
import { OfferService } from '../offer.service';
import { TarifDisplayComponent } from '../../../tarifs/tarif/tarif-display/tarif-display.component';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { ModelListAction } from '../../../../includes/modellist/modellist.component';
import { Tarif } from '../../../tarifs/tarif/tarif';
import { TarifListComponent } from '../../../tarifs/tarif/tarif-list/tarif-list.component';
import { first, Observable, ReplaySubject } from 'rxjs';
import { OfferActionService } from '../offer-action.service';

@Component({
  selector: 'lvadg-offer-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, IconComponent, TarifDisplayComponent, TarifListComponent],
  templateUrl: './offer-manage.component.pug',
  styleUrls: ['./offer-manage.component.sass'],
})
export class OfferManageComponent implements OnInit {
  @Input() public offer?: Offer;
  public OUL = OFFER_USER_LEVEL;
  @ViewChild('ofcslot', { static: false }) public ofcSlotAdd!: ElementRef<HTMLDivElement>;
  public ofcInstance?: NgbOffcanvasRef;
  public offer$?: Observable<Offer>;
  public tarifFilter$ = new ReplaySubject<FilterDefaults>(1);
  public tariflist_default_fields = ['code', 'tariftype_details', 'name', 'datestart', 'dateend', 'status', 'actions'];

  constructor(
    public offertype$: OffertypeService,
    public storages$: StorageService,
    public users$: UserService,
    public clients$: ClientService,
    public offers$: OfferService,
    public offertypestorage$: OffertypeStorageService,
    public _oa: OfferActionService,
    public _ofc: NgbOffcanvas,
  ) {}

  public ngOnInit(): void {
    if (!this.offer) return;
    this.offer$ = this.offers$.fetch(this.offer?.id).pipe(first());
  }

  public openAddTarif() {
    this.tarifFilter$.next({ for_offer: this.offer?.id });
    this.ofcInstance = this._ofc.open(this.ofcSlotAdd, { position: 'end', panelClass: 'w-75' });
  }

  public async addTarif(mla: ModelListAction<Tarif>) {
    console.log('Add tarif', mla);
    if (mla.model && this.offer) {
      await this._oa.action(this.offer, { action: 'add_tarif', tarif: mla.model.id });
    }
    this.offer$ = this.offers$.fetch(this.offer!.id).pipe(first());
    this.ofcInstance?.dismiss();
  }

  public async removeTarif(t: Tarif) {
    if (this.offer) {
      await this._oa.action(this.offer, { action: 'remove_tarif', tarif: t.id });
    }
    this.offer$ = this.offers$.fetch(this.offer!.id).pipe(first());
  }
}
