import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { UserService } from './user.service';
import { User } from './user';
import { ResolverErrorService } from '../../../components/utils/resolver-error.service';

@Injectable({
  providedIn: 'root',
})
export class UserResolver  {
  constructor(private _rs: UserService, private _re: ResolverErrorService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> {
    return this._rs
      .fetch(+route.params['userId'])
      .pipe(catchError(this._re.catch));
  }
}
