import { Component } from '@angular/core';
import { UsageListViewData } from './usage-list-view.component.params';
import { Subject } from 'rxjs';
import { AllergenListViewData } from '../../allergen/list/allergen-list-view.component.params';
import { UsageCreateComponent } from '../../../../../models/catalog/meta/usage/usage-create/usage-create.component';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../../components/baseview/baselistview.component';
import { UsageListComponent } from '../../../../../models/catalog/meta/usage/usage-list/usage-list.component';
import {Usage} from "../../../../../models/catalog/meta/usage/usage";

@Component({
  selector: 'lvadg-usage-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, UsageCreateComponent, UsageListComponent],
  templateUrl: './usage-list-view.component.pug',
  styleUrls: ['./usage-list-view.component.sass'],
})
export class UsageListViewComponent extends BaseListViewComponent<UsageListViewData, Usage> {
  public default_fields = ['id', 'type', 'code', 'name', 'articles_count', 'updated'];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();

  public override setRouteData(data: AllergenListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
