import { Component, Input } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { ProviderDocument } from "../provider-document";
import { ProviderDocumentService } from "../provider-document.service";
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListSelectMultiFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgMathPipesModule } from "ngx-pipes";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { LabelDisplayComponent } from "../../../catalog/label/label-display/label-display.component";
import { MimetypeIconComponent } from "../../../../components/utils/mimetype-icon/mimetype-icon.component";
import { ProviderDisplayComponent } from "../../provider/provider-display/provider-display.component";
import { ArticleDisplayComponent } from "../../../catalog/article/article-display/article-display.component";
import { ProviderBase } from "../../provider/provider.base";
import { ArticleBase } from "../../../catalog/article/article.base";
import { ArticleService } from "../../../catalog/article/article.service";
import { LabelService } from "../../../catalog/label/label.service";
import { ProviderService } from "../../provider/provider.service";
import { ProducerDocument } from "../../producer-document/producer-document";
import { LABEL_TYPE } from "../../../catalog/label/label.base";
import { map } from "rxjs";
import { PROVIDERDOCUMENT_STATUSES } from "../provider-document.base";
import { IconComponent } from "../../../../components/utils/icon/icon.component";

@Component({
  selector: "lvadg-provider-document-list",
  standalone: true,
  templateUrl: "./provider-document-list.component.pug",
  styleUrls: ["./provider-document-list.component.sass"],
  imports: [
    ...MODELLIST_IMPORTS,
    NgMathPipesModule,
    PChoicePipe,
    ProviderDisplayComponent,
    ArticleDisplayComponent,
    ImageDisplayComponent,
    LabelDisplayComponent,
    SafeDeleteComponent,
    MimetypeIconComponent,
    IconComponent,
  ],
})
export class ProviderDocumentListComponent extends ModellistComponent<ProviderDocument> {
  public showFilename: boolean = false;
  @Input() public provider_detail_route?: Link<ProviderBase>;
  @Input() public article_detail_route?: Link<ArticleBase>;

  constructor(
    coll: ProviderDocumentService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _articles: ArticleService,
    private _labels: LabelService,
    private _providers: ProviderService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || [
          "search",
          "status",
          "type",
          "label_articles",
        ],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectMultiFilter({
          field: "status",
          name: "status",
          label: "État(s)",
          model: ProducerDocument,
        }),
        new ModelListSelectMultiFilter({
          field: "type",
          name: "type",
          label: "Type(s)",
          model: ProducerDocument,
        }),
        new ModelListSelectMultiFilter({
          field: "label_articles",
          name: "label_articles",
          label: "Label article(s)",
          choices: this._labels.byTypes(LABEL_TYPE.FL, LABEL_TYPE.SEA).pipe(
            map((labels) =>
              labels.map((label) => ({
                value: label.id,
                desc: `[${label.type_short}] ${label.name}`,
              })),
            ),
          ),
        }),
        new ModelListAutocompleteMultiFilter({
          field: "articles",
          name: "articles",
          label: "Article(s)",
          collection: this._articles,
          filter: {
            with_providerdocument: true,
            fields: ["id", "libelle"].join(","),
          },
        }),
      ],
    };
  }

  public override getRowClasses(row: ProviderDocument): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === PROVIDERDOCUMENT_STATUSES.ARCHIVED_NO_UPDATE) {
      cls.push("text-primary");
    }
    if (row.status === PROVIDERDOCUMENT_STATUSES.ARCHIVED_WITH_UPDATE) {
      cls.push("text-muted");
    }
    if (row.status === PROVIDERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_15D) {
      cls.push("text-warning");
    }
    if (row.status === PROVIDERDOCUMENT_STATUSES.OK_NO_UPDATE_EXP_1M) {
      cls.push("text-warning");
    }

    return cls;
  }
}
