import { ProviderBase } from "./provider.base";
import { detailsField, integerField } from "@solidev/data";
import { LocationBase } from "../../locations/location/location.base";
import { Image } from "../../documents/image/image";
import { Label } from "../../catalog/label/label";
import { ProviderDocument } from "../provider-document/provider-document";

export class Provider extends ProviderBase {
  static override readonly __name: string = "Provider";
  @detailsField({
    name: "location_details",
    description: "Adresse",
    readonly: true,
    model: LocationBase,
    priority: 500,
  })
  public location_details?: LocationBase;

  @integerField({
    name: "nb_articles",
    description: "# articles",
    readonly: true,
    priority: 450,
  })
  public nb_articles?: number;

  @detailsField({
    name: "logo_details",
    description: "Logo",
    readonly: true,
    model: Image,
    priority: 730,
  })
  public logo_details?: Image;

  @detailsField({
    name: "images_details",
    description: "Images",
    readonly: true,
    model: Image,
    many: true,
    priority: 720,
  })
  public images_details?: Image[];

  @detailsField({
    name: "slabels_details",
    description: "Labels",
    readonly: true,
    model: Label,
    many: true,
    priority: 600,
  })
  public slabels_details: Label[] = [];

  @detailsField({
    name: "documents_details",
    description: "Documents",
    readonly: true,
    model: ProviderDocument,
    many: true,
    priority: 350,
  })
  public documents_details: ProviderDocument[] = [];
}
