import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  primaryField,
  textField,
} from "@solidev/data";

export enum OFFERTYPE_TEXT_STATUS {
  OK = "OK",
  FUTUR = "FUT",
  ARCHIVED = "ARC",
}

export class OffertypeTextBase extends DataModel {
  static override readonly __name: string = "OffertypeTextBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: "texttype",
    description: "ID type de texte",
    related: "OfferTypeTextType",
    priority: -1,
  })
  public texttype!: number;

  @charField({
    name: "title",
    description: "Titre",
    maxLength: 255,
    priority: 800,
  })
  public title!: string;

  @textField({ name: "text", description: "Contenu texte", priority: 700 })
  public text!: string;

  @datetimeField({
    name: "date_start",
    description: "Date de début",
    priority: 500,
  })
  public date_start!: Date;

  @datetimeField({
    name: "date_end",
    description: "Date de fin",
    priority: 400,
  })
  public date_end!: Date;

  @datetimeField({
    name: "date_applicable",
    description: "Date d'application",
    priority: 450,
  })
  public date_applicable!: Date;

  @charField({
    name: "status",
    description: "État",
    maxLength: 4,
    choices: [
      { value: OFFERTYPE_TEXT_STATUS.OK, desc: "Texte courant" },
      {
        value: OFFERTYPE_TEXT_STATUS.FUTUR,
        desc: "Texte futur",
      },
      { value: OFFERTYPE_TEXT_STATUS.ARCHIVED, desc: "Texte archivé" },
    ],
    priority: 300,
  })
  public status: OFFERTYPE_TEXT_STATUS = OFFERTYPE_TEXT_STATUS.OK;
}
