import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeStorage } from '../../tariftype-storage/tariftype-storage';
import { TariftypeStoragePermission } from '../tariftype-storage-permission';
import { TariftypeActionService } from '../../tarif/tariftype-action.service';
import { UserService } from '../../../users/user/user.service';
import { GroupService } from '../../../users/group/group.service';
import { DispeditComponent } from '@solidev/data';
import { TariftypeService } from '../../tariftype/tariftype.service';

@Component({
  selector: 'lvadg-tariftype-storage-permission-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tariftype-storage-permission-create.component.pug',
  styleUrls: ['./tariftype-storage-permission-create.component.sass'],
})
export class TariftypeStoragePermissionCreateComponent implements OnInit {
  @Input() public tariftypestorage?: TariftypeStorage;
  @Output() public created = new EventEmitter<void>();
  @Output() public cancelled = new EventEmitter<void>();
  public perm!: TariftypeStoragePermission;

  constructor(
    private _ttact: TariftypeActionService,
    private _ttcoll: TariftypeService,
    public user$: UserService,
    public group$: GroupService
  ) {}

  ngOnInit(): void {
    this.perm = new TariftypeStoragePermission();
    if (this.tariftypestorage && this.tariftypestorage.tariftype_details?.id) {
      this.perm.target = this.tariftypestorage.id;
    } else {
      console.error('No tariftype available');
    }
  }

  public async save() {
    const tt = this.tariftypestorage?.tariftype_details;
    if (tt && tt.id && this.tariftypestorage?.id) {
      tt._collection = this._ttcoll;
      const res = await this._ttact.action(tt, {
        action: 'add_storage_permission',
        ttstorage: this.tariftypestorage.id,
        user: this.perm.user,
        group: this.perm.group,
        permission: this.perm.permission,
      });
      if (res.success) {
        this.created.next();
      }
    }
  }
}
