import { TariftypePermissionBase } from './tariftype-permission.base';
import { detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';
import { GroupBase } from '../../users/group/group.base';
import { TariftypeBase } from '../tariftype/tariftype.base';

export class TariftypePermission extends TariftypePermissionBase {
  static override readonly __name: string = 'TariftypePermission';

  @detailsField({
    model: UserBase,
    description: 'Utilisateur',
    readonly: true,
  })
  public user_details?: UserBase;

  @detailsField({
    model: GroupBase,
    description: 'Groupe',
    readonly: true,
  })
  public group_details?: GroupBase;

  @detailsField({
    model: TariftypeBase,
    description: 'Modèle de tarif',
    readonly: true,
  })
  public tariftype_details?: TariftypeBase;
}
