import { Component } from '@angular/core';

import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeRelation } from '../../../../models/tarifs/tariftype-relation/tariftype-relation';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { TariftypeRelationListComponent } from '../../../../models/tarifs/tariftype-relation/tariftype-relation-list/tariftype-relation-list.component';

export interface TariftypeRelationListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  tariftype_detail_route: RouteConfigItem;
}

export interface TariftypeRelationListViewData extends TariftypeRelationListViewParams {}

@Component({
  selector: 'lvadg-tariftype-relation-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, TariftypeRelationListComponent],
  templateUrl: './tariftype-relation-list-view.component.pug',
  styleUrls: ['./tariftype-relation-list-view.component.sass'],
})
export class TariftypeRelationListViewComponent extends BaseListViewComponent<
  TariftypeRelationListViewData,
  TariftypeRelation
> {
  public tariftype_detail_route!: Link<Tariftype>;
  public default_fields = ['id', 'src_details', 'dest_details', 'type', 'ops', 'actions'];

  public override setRouteData(data: TariftypeRelationListViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, this);
  }
}
