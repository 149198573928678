import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffertypeStorageViewData, OffertypeStorageViewParams } from '../menu';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { OffertypeStorageManageComponent } from '../../../../../models/offers/offertype-storage/offertype-storage-manage/offertype-storage-manage.component';
import { OffertypeStorageNavComponent } from '../_nav/offertype-storage-nav.component';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { OffertypeStorage } from '../../../../../models/offers/offertype-storage/offertype-storage';

export interface OffertypeStorageEditViewParams extends OffertypeStorageViewParams {}

export interface OffertypeStorageEditViewData extends OffertypeStorageEditViewParams, OffertypeStorageViewData {}

@Component({
  selector: 'lvadg-offertype-storage-edit-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, OffertypeStorageManageComponent, OffertypeStorageNavComponent],
  templateUrl: './offertype-storage-edit-view.component.pug',
  styleUrls: ['./offertype-storage-edit-view.component.sass'],
})
export class OffertypeStorageEditViewComponent extends BaseDetailViewComponent<
  OffertypeStorageEditViewData,
  OffertypeStorage
> {}
