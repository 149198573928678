import { Component } from '@angular/core';

import { OrderprocessarticleListViewData } from './orderprocessarticle-list-view.component.params';
import { Subject } from 'rxjs';
import { Link } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Orderprocessarticle } from '../../../../models/orders/orderprocessarticle/orderprocessarticle';
import { ArticleBase } from '../../../../models/catalog/article/article.base';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { OrderprocessBase } from '../../../../models/orders/orderprocess/orderprocess.base';
import { OrderprocessarticleListComponent } from '../../../../models/orders/orderprocessarticle/orderprocessarticle-list/orderprocessarticle-list.component';
import { OrderprocessarticleCreateComponent } from '../../../../models/orders/orderprocessarticle/orderprocessarticle-create/orderprocessarticle-create.component';

@Component({
  selector: 'lvadg-orderprocessarticle-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, OrderprocessarticleListComponent, OrderprocessarticleCreateComponent],
  templateUrl: './orderprocessarticle-list-view.component.pug',
  styleUrls: ['./orderprocessarticle-list-view.component.sass'],
})
export class OrderprocessarticleListViewComponent extends BaseListViewComponent<
  OrderprocessarticleListViewData,
  Orderprocessarticle
> {
  public create: boolean = false;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public default_fields = ['id', 'process_details', 'tariftype_details', 'article_details', 'created', 'updated'];
  public article_detail_route?: Link<ArticleBase>;
  public tariftype_detail_route?: Link<TariftypeBase>;
  public orderprocess_detail_route?: Link<OrderprocessBase>;

  public override setRouteData(data: OrderprocessarticleListViewData) {
    super.setRouteData(data);
    this.article_detail_route = new Link(data.article_detail_route, this);
    this.orderprocess_detail_route = new Link(data.orderprocess_detail_route, this);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, this);
  }
}
