
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { ArticleTarifItemService} from "./article-tarif-item.service";
import { ArticleTarifItem } from "./article-tarif-item";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifItemResolver  {
  constructor(private _rs: ArticleTarifItemService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleTarifItem> {
    return this._rs.fetch(+route.params["article-tarif-itemId"])
  }
}
