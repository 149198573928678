import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformBase } from '../platform.base';
import { Platform } from '../platform';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-platform-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './platform-display.component.pug',
  styleUrls: ['./platform-display.component.sass'],
})
export class PlatformDisplayComponent implements OnInit {
  @Input() public platform?: PlatformBase | Platform;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<PlatformBase>;

  constructor() {}

  ngOnInit(): void {}
}
