
import {
  charField,
  textField,
  integerField,
  booleanField,
  datetimeField,
  dateField,
  detailsField,
  foreignKeyField,
  manyToManyField,
  primaryField,
  DataModel,
} from "@solidev/data";

export class UsergrouplinkBase extends DataModel {
  static override readonly __name: string = "UsergrouplinkBase"

  @primaryField({"name": "id", "description": "ID"})
  public override id!: number;

  @datetimeField({"name": "created", "description": "created", "readonly": true})
  public created!: Date;

  @datetimeField({"name": "updated", "description": "updated", "readonly": true})
  public updated!: Date;

  @foreignKeyField({"name": "user", "description": "user", "related": "User"})
  public user!: number;

  @foreignKeyField({"name": "group", "description": "group", "related": "Group"})
  public group!: number;

  @datetimeField({"name": "datestart", "description": "datestart"})
  public datestart!: Date;

  @datetimeField({"name": "dateend", "description": "dateend"})
  public dateend!: Date;

  @integerField({"name": "orig_id", "description": "orig id"})
  public orig_id!: number;


}
