
<div class="row">
  <div class="col-sm-3">
    <lvadg-toc-display></lvadg-toc-display>
  </div>
  <div class="col-sm-6">
    <lvadg-doc-display [doc$]="doc$"></lvadg-doc-display>
  </div>
  <div class="col-sm-3">
    <lvadg-doctoc-display [doc$]="doc$"></lvadg-doctoc-display>
  </div>
</div>