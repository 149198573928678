
<ng-container *ngIf="step">
  <h4 class="my-2">{{step.title$||'Édition du tarif et des infos du tarif'}}</h4>
  <p class="text-info" [innerHTML]="step.message$"></p>
  <div class="row">
    <div class="col-12">
      <lvadg-tarif-document-create [tarif]="tarif" (uploaded)="uploaded($event)"></lvadg-tarif-document-create>
    </div>
    <div class="col-12">
      <button class="btn btn-outline-primary w-100 p-3 my-2" (click)="action({action: 'nav', dest: 'documents'})"><i class="bi bi-file-spreadsheet me-2"></i>Consulter / vérifier les documents</button>
    </div>
  </div>
  <button class="btn btn-outline-success w-100 p-3 my-2" (click)="action({action: 'next'})"><i class="bi bi-check-lg me-2"></i>Passer à l'étape suivante</button>
</ng-container>