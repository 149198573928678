import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Offer} from "../offer";
import {Offertype} from "../../offertype/offertype";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {OfferDisplayComponent} from "../offer-display/offer-display.component";
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepicker,
  NgbDatepickerModule,
  NgbDatepickerNavigateEvent,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import {
  ngbd2s,
  titleTemplate,
  toDateString,
} from "../../../tarifs/tarif/tarif-utils";
import {
  debounceTime,
  firstValueFrom,
  map,
  Observable,
  switchMap,
  tap,
} from "rxjs";
import {RRule, RRuleSet, rrulestr} from "rrule";
import {DataMessageService} from "@solidev/data";
import {OfferService} from "../offer.service";
import {OffertypeActionService} from "../../offertype/offertype-action.service";
import {OffertypeStorage} from "../../offertype-storage/offertype-storage";
import {StorageService} from "../../../structures/storage/storage.service";
import {
  OffertypeStorageService
} from "../../offertype-storage/offertype-storage.service";
import {Storage} from "../../../structures/storage/storage";
import {Client} from "../../../structures/client/client";
import {STORAGE_STATUS} from "../../../structures/storage/storage.base";
import {ClientService} from "../../../structures/client/client.service";
import {OffertypeService} from "../../offertype/offertype.service";
import {OffersActionService} from "../offers-action.service";
import {filterNullish} from "../../../../utils";
import {OFFER_TYPES, OFFER_ZONES} from "../offer.base";

/**
 * Offer creation component.
 *
 * This component is used to create offers :
 * - it can be used in free mode (no offertype provided) -> select storage if not provided, then client
 * - it can be used in offertype mode (offertype provided) -> select offertypestorage if not provided
 * - it can be used in offertype and offertypestorage mode (offertype and offertypestorage provided) -> no selection
 */
@Component({
  selector: "lvadg-offer-create",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OfferDisplayComponent,
    NgbDatepickerModule,
  ],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  templateUrl: "./offer-create.component.pug",
  styleUrls: ["./offer-create.component.sass"],
})
export class OfferCreateComponent implements OnInit {
  /** Offer type; if not provided, starts in free mode */
  @Input() public offertype?: Offertype;
  /** Offerstorage; if not available, we should use free mode instead */
  @Input() public offertypestorage?: OffertypeStorage;
  /** Offer zone */
  @Input() public zone?: OFFER_ZONES;
  /** Offer type */
  @Input() public type?: OFFER_TYPES;
  /** Storage source of the offer */
  @Input() public storage?: Storage;
  /** Client destination */
  @Input() public client?: Client;

  /** Created event, emits the created offer */
  @Output() public created = new EventEmitter<Offer>();
  /** Cancelled event, emits nothing */
  @Output() public cancelled = new EventEmitter<void>();
  /** Date string to offer(s) map */
  public noClient = false;
  public date_to_offers = new Map<string, Offer[]>();
  /** Ultimate create form, contains data to be sent to api */
  public createForm = new FormGroup({
    datestart: new FormControl<string>(toDateString(new Date(), true)),
    dateend: new FormControl<string>(toDateString(new Date(), false)),
    name: new FormControl<string>("", { nonNullable: true }),
  });
  public clientSelectForm = new FormGroup({
    client: new FormControl<string>("", Validators.minLength(1)),
  });
  /** Observable for last 5 offers */
  public lasts$!: Observable<Offer[]>;
  /** Available storages */
  public storages$ = this._storages.list({
    page_size: 1000,
    fields: ["id", "cvva", "name"].join(","),
    sort: "name",
    status: STORAGE_STATUS.ACTIVE,
  });
  /** Available offertypestorages */
  public offertypestorages$!: Observable<OffertypeStorage[]>;
  public clients$?: Observable<Client[]>;

  /** Last offer */
  public last?: Offer;

  // Calendar utils
  public startDate!: NgbDate;
  /** Hovered date */
  public hoveredDate: NgbDate | null = null;
  /** Interval start date */
  public fromDate: NgbDate | null = null;
  /** Interval end date */
  public toDate: NgbDate | null = null;
  /** Offer name edition locked / unlocked ? */
  public lockedName: boolean = true;
  /** Last offer is locked */
  public lockedLast: boolean = true;
  /** Are we currently creating the offer */
  public creating: boolean = false;
  /** Offer ruleset */
  private rst?: RRule | RRuleSet;
  // FIXME: remove this, with 1+ year of offers, it is not useful
  private havedispo: boolean = false;

  constructor(
    private _offers: OfferService,
    private _dad: NgbDateAdapter<Date>,
    private calendar: NgbCalendar,
    private _msgs: DataMessageService,
    private _ots: OffertypeService,
    private _otact: OffertypeActionService,
    private _ofact: OffersActionService,
    private _storages: StorageService,
    private _clients: ClientService,
    private _otss: OffertypeStorageService,
  ) {}

  /** Check if date is disabled (e.g. already have an offer) */
   
  public checkDisabled = (
    date: NgbDateStruct,
    _current?: {
      year: number;
      month: number;
    },
  ): boolean => {
    if (!this.noClient) {
      return this.haveOffer(date);
    }
    return false;
  };

  /**
   * Init:
   * - set calendar boundaries
   * - get offers within calendar boundaries
   * - compute defaults
   */
  public async ngOnInit(): Promise<void> {
    // Disable name edition by default
    this.toggleNameLock(true);
    // Create form changes subscriptions
    this.createForm.valueChanges.subscribe((v) => {
      this.updateFromForm(v);
    });
    this.clients$ = this.clientSelectForm.valueChanges.pipe(
      debounceTime(500),
      switchMap((v) => {
        return this._clients.list({
          page_size: 10,
          sort: "name",
          search: v.client,
          fields: ["id", "name", "cvva"].join(","),
          level: 0,
        });
      }),
    );

    // Ensure we have full data for offertype and offertypestorage
    // If we have offertypestorage, we can use it to get offertype
    if (this.offertype || this.offertypestorage?.offertype) {
      this.offertype = await firstValueFrom(
        this._ots.fetch(this.offertype?.id || this.offertypestorage!.offertype),
      );
      if (this.offertype.client_details && !this.client) {
        this.client = this.offertype.client_details;
      }
    }
    if (this.offertypestorage) {
      this.offertypestorage = await firstValueFrom(
        this._otss.fetch(this.offertypestorage.id),
      );
      if (this.offertypestorage?.storage_details && !this.storage) {
        this.storage = this.offertypestorage.storage_details;
      }
    }
    if (!this.offertype) {
      return;
    }

    this.offertypestorages$ = this._otss.list(
      filterNullish({
        page_size: 1000,
        offertype: this.offertype.id,
        fields: ["id", "storage", "storage_details"].join(","),
        sort: "storage__name",
      }),
    );
    if (this.offertype.rrule) {
      this.rst = rrulestr(this.offertype.rrule);
    }

    this.updateLast();
  }

  public updateLast() {
    // Get last offers
    this.lasts$ = this._offers
      .list(
        filterNullish({
          offertype: this.offertype?.id,
          offertypestorage: this.offertypestorage?.id,
          storage: this.storage?.id,
          client: this.client?.id,
          page_size: 52,
          fields: ["id", "title", "datestart", "dateend", "status"].join(","),
          sort: "-dateend",
        }),
      )
      .pipe(
        tap((lasts) => {
          // Init view from last offers
          this.populateOffers(lasts);
          this.initFromLastOffer(lasts[0]);
        }),
        map((lasts) => lasts.slice(0, 6)),
      );
  }

  public toggleNameLock(force?: boolean) {
    if (force !== undefined) {
      this.lockedName = force;
    } else {
      this.lockedName = !this.lockedName;
    }
    if (this.lockedName) {
      this.createForm.controls.name.disable({ emitEvent: false });
    } else {
      this.createForm.controls.name.enable({ emitEvent: false });
    }
  }

  /** Check if date have an offer */
  public haveOffer(date: NgbDateStruct) {
    return !!(this.date_to_offers.get(ngbd2s(date)) || []).length;
  }

  /** Is inside interval check */
  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /** Check if date is in range */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  /** Is hovered */
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public selectLast(o: Offer | undefined) {
    if (this.lockedLast) {
      this._msgs.warning(
        "Changement de source verrouillé",
        "Si vous souhaitez réellement changer la source des éléments de l'offre, utilisez le cadenas pour déverrouiller la fonctionnalité",
      );
    } else {
      this.last = o;
    }
  }

  /** Calendar date selection action : update interval */
  public onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.createForm.patchValue({ datestart: toDateString(date, true) });
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      if (this.checkInterval(this.fromDate, date)) {
        this.toDate = date;
        this.createForm.patchValue({ dateend: toDateString(date, false) });
      } else {
        this.fromDate = null;
        this.createForm.patchValue({ datestart: null });
      }
    } else {
      this.toDate = null;
      this.createForm.patchValue({ dateend: null });
      this.fromDate = date;
      this.createForm.patchValue({ datestart: toDateString(date, true) });
    }
  }

  public async onNavigation(
    event: NgbDatepickerNavigateEvent,
    dp: NgbDatepicker,
  ) {
    if (!this.havedispo) {
      event.preventDefault();
      await firstValueFrom(
        this._offers.list(
          filterNullish({
            offertype: this.offertype?.id,
            offertypestorage: this.offertypestorage?.id,
            storage: this.storage?.id,
            client: this.client?.id,
            page_size: 5,
            fields: ["id", "title", "datestart", "dateend", "status"].join(","),
            sort: "-dateend",
          }),
        ),
      );
      this.havedispo = true;
      dp.navigateTo(event.next);
    }
  }

  /**
   * Do offer creating using data from createForm and last.
   */
  public async create() {
    this.creating = true;
    if (!this.offertypestorage?.storage && !this.storage) {
      this._msgs.error("Aucun dépôt n'est sélectionné");
      return;
    }
    if (this.offertype) {
      const res = await this._otact.action(
        this.offertype!,
        {
          action: "create_offer",
          last: this.last?.id,
          zone: this.zone,
          type: this.type,
          name: this.createForm.getRawValue().name!, // use raw value to ensure value even if control is disabled
          datestart: this.createForm.value.datestart!,
          dateend: this.createForm.value.dateend!,
          storage: (this.offertypestorage?.storage || this.storage?.id)!,
        },
        {
          error: "Erreur lors de la création de l'offre",
        },
      );
      this.creating = false;
      if (res.success) {
        this.created.emit(res.offer);
      }
    } else {
      if (!this.storage) {
        this._msgs.error("Aucun dépôt n'est sélectionné");
        return;
      }
      if (!this.client && !this.noClient) {
        this._msgs.error("Aucun client n'est sélectionné");
        return;
      }
      const res = await this._ofact.action(
        {
          action: "create_offer",
          last: this.last?.id,
          name: this.createForm.getRawValue().name!, // use raw value to ensure value even if control is disabled
          zone: this.zone,
          type: this.type,
          datestart: this.createForm.value.datestart!,
          dateend: this.createForm.value.dateend!,
          storage: this.storage!.id,
          client: this.client?.id,
        },
        {
          error: "Erreur lors de la création de l'offre",
        },
      );
      this.creating = false;
      if (res.success) {
        this.created.emit(res.offer);
      }
    }
  }

  /** Select a storage */
  public selectStorage(s: Storage) {
    this.storage = s;
    this.updateLast();
  }

  /** Select an offertype storage */
  public selectOffertypeStorage(o: OffertypeStorage) {
    this.offertypestorage = o;
    this.updateLast();
  }

  /** Select a client */
  public selectClient(c: Client | null) {
    if (c === null) {
      this.noClient = true;
      this.lockedLast = false;
      this.updateLast();
    } else {
      this.client = c;
      this.updateLast();
    }
  }

  /**
   * Fill offers map with provided offers dates
   * @param offers list of offers
   * @private
   */
  private populateOffers(offers: Offer[]) {
    const checkAdd = (date: NgbDate, o: Offer) => {
      const dt = ngbd2s(date);
      const cur = this.date_to_offers.get(dt) || [];
      if (!cur.find((tr) => o.id == tr.id)) {
        cur.push(o);
        this.date_to_offers.set(dt, cur);
      }
    };
    for (const t of offers) {
      const start = NgbDate.from(this._dad.fromModel(t.datestart))!;
      const end = NgbDate.from(this._dad.fromModel(t.dateend))!;
      let date = start;
      if (end.after(start)) {
        while (date.before(end)) {
          checkAdd(date, t);
          date = this.calendar.getNext(date, "d", 1);
        }
        checkAdd(date, t);
      }
    }
  }

  /**
   * Set next primary interval for offers, using last offers and rrule
   * @param last last offer
   * @private
   */
  private initFromLastOffer(last: Offer | undefined) {
    // By default, use this month for calendar start
    this.startDate = this.calendar.getToday();
    if (!last) {
      return;
    }
    // Compute start date from rrule
    const start = this.rst?.after(last.dateend);
    let end: Date | undefined;
    if (start) {
      // We have a start date, update calendar and compute end
      this.startDate = NgbDate.from(this._dad.fromModel(new Date(start)))!;
      end = this.rst?.after(start) || undefined;
    }
    // Display previous month if we are less than 15 of month
    if (this.startDate.day <= 15) {
      this.startDate = this.calendar.getPrev(this.startDate, "m", 1);
    }

    // We have a full interval, set form values
    if (start && end) {
      end.setDate(end.getDate() - 1);
      this.createForm.patchValue(
        {
          datestart: toDateString(start, true),
          dateend: toDateString(end, false),
        },
        { emitEvent: true },
      );
    }
    // Set last
    this.last = last;
  }

  /**
   * Update calendar from form
   * @param v
   * @private
   */
  private updateFromForm(
    v: Partial<{
      datestart: string | null | undefined;
      name: string | null | undefined;
      dateend: string | null | undefined;
    }>,
  ) {
    // On form change, set from / to dates in calendar
    if (v.datestart) {
      this.fromDate = NgbDate.from(
        // use datestart
        this._dad.fromModel(new Date(v.datestart)),
      );
    }
    if (v.dateend) {
      this.toDate = NgbDate.from(
        // use dateend
        this._dad.fromModel(new Date(v.dateend)),
      );
    }
    // If both dates are OK, write name from template if name is locked
    if (v.datestart && v.dateend && this.lockedName) {
      const locale = this.type === OFFER_TYPES.PRODUCT_LOCAL ? "locale " : "";
      this.createForm.patchValue(
        {
          name: titleTemplate(
            this.offertype?.name_template ||
              `Offre ${locale}S(DW) du (DJ)/(DM)/(DY) au (FJ)/(FM)/(FY)`,
            new Date(v.datestart),
            new Date(v.dateend),
          ),
        },
        { emitEvent: false },
      );
    }
  }

  /** Check if interval already contains offers */
  private checkInterval(fromDate: NgbDate, toDate: NgbDate): boolean {
    if (fromDate.after(toDate)) {
      return false;
    }
    if (this.noClient) {
      return true;
    }
    let date = fromDate;
    while (date.before(toDate) || date.equals(toDate)) {
      const dt = ngbd2s(date);
      const tl = this.date_to_offers.get(dt) || [];
      if (tl.length) {
        return false;
      }
      date = this.calendar.getNext(date, "d", 1);
    }
    return true;
  }
}
