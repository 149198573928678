
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { ContactService} from "./contact.service";
import { Contact } from "./contact";

@Injectable({
  providedIn: 'root'
})
export class ContactResolver  {
  constructor(private _rs: ContactService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Contact> {
    return this._rs.fetch(+route.params["contactId"])
  }
}
