import { Component } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OfferText } from "../offer-text";
import { OfferTextService } from "../offer-text.service";
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-offer-text-list",
  standalone: true,
  templateUrl: "./offer-text-list.component.pug",
  styleUrls: ["./offer-text-list.component.sass"],
  imports: [...MODELLIST_IMPORTS],
})
export class OfferTextListComponent extends ModellistComponent<OfferText> {
  constructor(
    coll: OfferTextService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ["search"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
      ],
    };
  }
}
