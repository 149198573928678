import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterData } from '@solidev/data';
import { ArticleStats } from '../article-stats';
import { ArticleStatsService } from '../article-stats.service';
import bb, { areaSplineRange, line, spline } from 'billboard.js';
import { AtscacheService } from '../atscache/atscache.service';
import { group, map as d3map } from 'd3-array';
import { Atscache } from '../atscache/atscache';
import { RnmatcacheService } from '../../../rnm/rnmatcache/rnmatcache.service';
import { Rnmatcache } from '../../../rnm/rnmatcache/rnmatcache';
import { IntervalService } from '../../../../components/utils/interval.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'lvadg-tarif-article-stats',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-article-stats.component.pug',
  styleUrls: ['./tarif-article-stats.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class TarifArticleStatsComponent implements OnInit {
  @Input() public article?: ArticleStats;
  @Input() public filter: FilterData | null = {};
  @ViewChild('chart', { static: true })
  public chartDiv!: ElementRef<HTMLDivElement>;
  @ViewChild('summarychart', { static: true })
  public summaryChartDiv!: ElementRef<HTMLDivElement>;

  constructor(
    private _stats: ArticleStatsService,
    private _details: AtscacheService,
    private _rnmtarifs: RnmatcacheService,
    private _intervals: IntervalService,
  ) {}

  ngOnInit(): void {
    const grouper = (
      this.filter && this.filter['tarifs_stats_grouper'] ? this.filter['tarifs_stats_grouper'] : 'w'
    ) as any;
    const period = (
      this.filter && this.filter['tarifs_stats_period'] ? this.filter['tarifs_stats_period'] : 'last100d'
    ) as any;

    if (this.article) {
      // Fetch données temporelles d'intervalle
      const intervals$ = this._intervals.get(grouper, period);
      // Fetch détails des prix par tarif
      const details$ = this._details.list({
        by_article: this.article.id,
        tarifs_stats_grouper: grouper,
        tarifs_stats_period: period,
        page_size: 1000,
        fields: ['id', 'tarif_details', 'tariftype_details'].join(','),
        ...(this.filter ? this.filter : {}),
      });

      // Fetch article stats
      const stats$ = this._stats.fetch(this.article.id, {
        params: {
          fields: ['id', 'libelle', 'interval_stats'].join(','),
          tarifs_stats_grouper: grouper,
          tarifs_stats_period: period,
          ...(this.filter ? this.filter : {}),
        },
      });

      // Fetch article rnms
      const rnms$ = this._rnmtarifs.list({
        article: this.article.id,
        grouper: grouper,
        period: period,
        page_size: 1000,
        ...(this.filter ? this.filter : {}),
        fields: ['id', 'date', 'marche_details', 'libelle_details', 'price'].join(','),
      });

      combineLatest([intervals$, details$, stats$]).subscribe(([i, d, s]) => {
        this.setDetailChart(i, d, s);
      });
      // combineLatest([intervals$, rnms$]).subscribe(([i, r]) => {
      //   this.setSummaryChart(i, r);
      // });
    }
  }

  public setDetailChart(intervals: Date[], ats: Atscache[], fam: ArticleStats) {
    const data = group(ats, (d) => d.tariftype_details!.name);
    const stats = fam.interval_stats!.raw!;
    const statsByInterval = group(stats, (d) => d.date);

    const byinterval = group(
      ats,
      (d) => d.date,
      (d) => d.tariftype_details!.name,
    );
    const chart = bb.generate({
      data: {
        x: 'x',
        columns: [
          ['x', ...intervals],
          [
            'Prix moyen vente',
            ...intervals.map((i) => {
              const v = statsByInterval.get(i);
              if (v && v.length == 1) {
                return [
                  Math.round(v[0].max / 100) / 100,
                  Math.round(v[0].avg / 100) / 100,
                  Math.round(v[0].min / 100) / 100,
                ];
              }
              return null;
            }),
          ],
          ...d3map(data.keys(), (name) => [
            name,
            ...d3map(intervals, (date) => {
              const v = byinterval.get(date)?.get(name);
              if (v) {
                return Math.round(v[0].price / 100) / 100;
              }
              return null;
            }),
          ]),
        ],
        types: {
          'Prix moyen vente': areaSplineRange(),
          ...Object.fromEntries(d3map(data.keys(), (name) => [name, line()])),
        },
      },
      line: {
        classes: ['line-class-prix-moyen', ...d3map(data.keys(), (name) => '')],
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d',
          },
        },
        y: {
          min: 0,
        },
      },
      tooltip: {
        order: 'desc',
      },
      bindto: this.chartDiv.nativeElement,
    });
  }

  public setSummaryChart(intervals: Date[], tar: Rnmatcache[]) {
    const rnmGrouper = (d: Rnmatcache) =>
      `${d.marche_details!.lname} - ${d.libelle_details!.libelle} ${d.libelle_details!.ulibelle}`;
    const rnmData = group(tar, rnmGrouper);
    const rnmByInterval = group(tar, (d) => d.date, rnmGrouper);
    const _chart = bb.generate({
      data: {
        x: 'x',
        columns: [
          ['x', ...intervals],
          ...d3map(rnmData.keys(), (name) => [
            name,
            ...d3map(intervals, (date) => {
              const v = rnmByInterval.get(date)?.get(name);
              if (v) {
                return Math.round(v[0].price / 100) / 100;
              }
              return null;
            }),
          ]),
        ],
        types: {
          ...Object.fromEntries(d3map(rnmData.keys(), (name) => [name, spline()])),
        },
      },
      // line: {
      //   classes: [...d3map(rnmData.keys(), (name) => '')],
      // },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d',
          },
        },
        y: {
          min: 0,
        },
      },
      tooltip: {
        order: 'desc',
      },
      legend: {
        position: 'right',
      },
      bindto: this.summaryChartDiv.nativeElement,
    });
  }
}
