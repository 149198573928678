import { RoutesConfig } from "@solidev/data";
import { FRONT_FL_ROUTES_CONFIG, FRONT_FL_ROUTES_NAMES } from "../pages/fl/fl";
import {
  FRONT_SEA_ROUTES_CONFIG,
  FRONT_SEA_ROUTES_NAMES,
} from "../pages/sea/sea";
import {
  FRONT_PILOTE_ROUTES_CONFIG,
  FRONT_PILOTE_ROUTES_NAMES,
} from "../pages/pilote/pilote";
import {
  FRONT_TARIFS_ROUTES_CONFIG,
  FRONT_TARIFS_ROUTES_NAMES,
} from "../pages/tarifs/tarifs";
import {
  FRONT_CATALOG_ROUTES_CONFIG,
  FRONT_CATALOG_ROUTES_NAMES,
} from "../pages/catalog/catalog";
import {
  FRONT_CLIENTS_ROUTES_CONFIG,
  FRONT_CLIENTS_ROUTES_NAMES,
} from "../pages/clients/clients";
import {
  FRONT_NEWS_ROUTES_CONFIG,
  FRONT_NEWS_ROUTES_NAMES,
} from "../pages/news/news";
import {
  FRONT_RESTOS_ROUTES_CONFIG,
  FRONT_RESTOS_ROUTES_NAMES,
} from "../pages/restos/restos";
import {
  FRONT_PROVIDERS_ROUTES_CONFIG,
  FRONT_PROVIDERS_ROUTES_NAMES,
} from "../pages/providers/providers";
import {
  FRONT_STORAGES_ROUTES_CONFIG,
  FRONT_STORAGES_ROUTES_NAMES,
} from "../pages/storages/storages";
import {
  FRONT_METEO_ROUTES_CONFIG,
  FRONT_METEO_ROUTES_NAMES,
} from "../pages/meteo/meteo";
import {
  FRONT_RNM_ROUTES_CONFIG,
  FRONT_RNM_ROUTES_NAMES,
} from "../pages/rnm/rnm";
import {
  FRONT_VIVALYA_ROUTES_CONFIG,
  FRONT_VIVALYA_ROUTES_NAMES,
} from "../pages/vivalya/vivalya";
import {
  FRONT_ORDERS_ROUTES_CONFIG,
  FRONT_ORDERS_ROUTES_NAMES,
} from "../pages/orders/orders";
import {
  FRONT_PRINTS_ROUTES_CONFIG,
  FRONT_PRINTS_ROUTES_NAMES,
} from "../pages/prints/prints";
import {
  FRONT_DOCUMENTS_ROUTES_CONFIG,
  FRONT_DOCUMENTS_ROUTES_NAMES,
} from "../pages/documents/documents";
import {
  FRONT_ACCOUNT_ROUTES_CONFIG,
  FRONT_ACCOUNT_ROUTES_NAMES,
} from "../pages/account/account";
import {
  FRONT_BASE_ROUTES_CONFIG,
  FRONT_BASE_ROUTES_NAMES,
} from "../pages/base";

export const FRONT_ROUTES_NAMES = [
  ...FRONT_FL_ROUTES_NAMES,
  ...FRONT_SEA_ROUTES_NAMES,
  ...FRONT_PILOTE_ROUTES_NAMES,
  ...FRONT_TARIFS_ROUTES_NAMES,
  ...FRONT_CATALOG_ROUTES_NAMES,
  ...FRONT_CLIENTS_ROUTES_NAMES,
  ...FRONT_NEWS_ROUTES_NAMES,
  ...FRONT_RESTOS_ROUTES_NAMES,
  ...FRONT_PROVIDERS_ROUTES_NAMES,
  ...FRONT_STORAGES_ROUTES_NAMES,
  ...FRONT_METEO_ROUTES_NAMES,
  ...FRONT_RNM_ROUTES_NAMES,
  ...FRONT_VIVALYA_ROUTES_NAMES,
  ...FRONT_ORDERS_ROUTES_NAMES,
  ...FRONT_PRINTS_ROUTES_NAMES,
  ...FRONT_DOCUMENTS_ROUTES_NAMES,
  ...FRONT_ACCOUNT_ROUTES_NAMES,
  ...FRONT_BASE_ROUTES_NAMES,
] as const;

export const FRONT_ROUTES: RoutesConfig<(typeof FRONT_ROUTES_NAMES)[number]> = {
  ...FRONT_FL_ROUTES_CONFIG,
  ...FRONT_SEA_ROUTES_CONFIG,
  ...FRONT_PILOTE_ROUTES_CONFIG,
  ...FRONT_TARIFS_ROUTES_CONFIG,
  ...FRONT_CATALOG_ROUTES_CONFIG,
  ...FRONT_CLIENTS_ROUTES_CONFIG,
  ...FRONT_NEWS_ROUTES_CONFIG,
  ...FRONT_RESTOS_ROUTES_CONFIG,
  ...FRONT_PROVIDERS_ROUTES_CONFIG,
  ...FRONT_STORAGES_ROUTES_CONFIG,
  ...FRONT_METEO_ROUTES_CONFIG,
  ...FRONT_RNM_ROUTES_CONFIG,
  ...FRONT_VIVALYA_ROUTES_CONFIG,
  ...FRONT_ORDERS_ROUTES_CONFIG,
  ...FRONT_PRINTS_ROUTES_CONFIG,
  ...FRONT_DOCUMENTS_ROUTES_CONFIG,
  ...FRONT_ACCOUNT_ROUTES_CONFIG,
  ...FRONT_BASE_ROUTES_CONFIG,
};
