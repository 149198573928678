import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMapLayerBase } from '../user-map-layer.base';
import { Link, PChoicePipe } from '@solidev/data';

@Component({
  selector: 'lvadg-user-map-layer-display',
  standalone: true,
  imports: [CommonModule, PChoicePipe],
  templateUrl: './user-map-layer-display.component.pug',
  styleUrls: ['./user-map-layer-display.component.sass'],
})
export class UserMapLayerDisplayComponent {
  @Input() public layer!: UserMapLayerBase;
  @Input() public mode = 'line' as const;
  @Input() public detail_route?: Link<UserMapLayerBase>;

  constructor() {}
}
