import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, DOCUMENT, NgFor, NgIf } from '@angular/common';
import { Folder } from '../folder';
import { combineLatest, concat, firstValueFrom, Observable, of, Subject, switchMap } from 'rxjs';
import { FolderService } from '../folder.service';
import { DocumentService } from '../../document/document.service';
import { Document as FolderDocument } from '../../document/document';
import { ImageDisplayComponent } from '../../image/image-display/image-display.component';
import { DOCUMENT_STATUS } from '../../document/document.base';
import { FOLDER_STATUS, FOLDER_USER_LEVEL } from '../folder.base';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-folder-content',
  standalone: true,
  imports: [NgFor, NgIf, RouterLink, AsyncPipe, ImageDisplayComponent],
  templateUrl: './folder-content.component.pug',
  styleUrls: ['./folder-content.component.sass'],
})
export class FolderContentComponent implements OnInit {
   
  @Input({ required: true }) public folder$!: Observable<Folder | null>;
  @Input() public reload?: Subject<boolean | void>;
  @Input() folder_detail_route?: Link<Folder>;
  @Input() public editable: boolean = true;
  @Output() public folder_click = new EventEmitter<Folder>();
  @Output() public document_click = new EventEmitter<FolderDocument>();
  @Output() public folder_dblclick = new EventEmitter<Folder>();
  @Output() public document_dblclick = new EventEmitter<FolderDocument>();
  public subfolders$!: Observable<Folder[]>;
  public documents$!: Observable<FolderDocument[]>;
  public DS = DOCUMENT_STATUS;
  public FS = FOLDER_STATUS;
  public FUL = FOLDER_USER_LEVEL;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _folders: FolderService,
    private _documents: DocumentService,
  ) {}

  public ngOnInit(): void {
    const folderqs = this._folders.queryset();
    const documentsqs = this._documents.queryset();
    const reload$ = concat(of(false), this.reload || new Subject<boolean | void>());
    this.subfolders$ = combineLatest([reload$, this.folder$]).pipe(
      switchMap(([refresh, folder]) =>
        folderqs
          .filter({ parent: folder ? folder.id : 'null' })
          .setFields(['id', 'name', 'slug', 'image', 'image_details', 'status'])
          .get(true, !!refresh),
      ),
    );

    this.documents$ = combineLatest([reload$, this.folder$]).pipe(
      switchMap(([refresh, folder]) =>
        documentsqs
          .filter({ folder: folder ? folder.id : -1 }) // SEE: add "null" to filter orphans
          .setFields([
            'id',
            'slug',
            'title',
            'image',
            'file', // this is restricted, only for superuser, others have download link
            'mimetype',
            'filename',
            'image_details',
            'status',
          ])
          .get(true, !!refresh),
      ),
    );
  }

  public async download(doc: FolderDocument) {
    const res = await firstValueFrom(doc.action<FolderDocument, { url: string }>('GET', 'download'));
    console.log(res);
    const a = this._document.createElement('a');
    this._document.body.appendChild(a);
    a.href = res.url;
    a.target = '_blank';
    a.download = doc.filename;
    a.click();
    this._document.body.removeChild(a);
  }
}
