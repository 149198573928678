import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Georegion } from '../georegion';
import { DispeditComponent } from '@solidev/data';
import { GeodepartementService } from '../../geodepartement/geodepartement.service';
import { GeocountryService } from '../../geocountry/geocountry.service';

@Component({
  selector: 'lvadg-georegion-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './georegion-manage.component.pug',
  styleUrls: ['./georegion-manage.component.sass'],
})
export class GeoregionManageComponent {
  @Input() public georegion!: Georegion;

  constructor(
    public geodepartements$: GeodepartementService,
    public geocountries$: GeocountryService,
  ) {}
}
