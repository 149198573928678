
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Rnmatt } from '../rnmatt';
@Component({
  selector: 'lvadg-rnmatt-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rnmatt-manage.component.pug',
  styleUrls: ['./rnmatt-manage.component.sass']
})
export class RnmattManageComponent implements OnInit {
  @Input() public rnmatt?: Rnmatt;

  constructor() { }

  ngOnInit(): void {
  }

}
