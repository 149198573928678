import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum TARIF_STATUS {
  PREPARATION = 'PR',
  TOVALIDATE = 'TV',
  VALID = 'VA',
  PUBLISHED = 'OK',
  ARCHIVED = 'AR',
}

export const TarifStatusChoices = [
  {
    value: TARIF_STATUS.PREPARATION,
    desc: 'En préparation',
  },
  {
    value: TARIF_STATUS.TOVALIDATE,
    desc: 'A valider',
  },
  {
    value: TARIF_STATUS.VALID,
    desc: 'Validé',
  },
  {
    value: TARIF_STATUS.PUBLISHED,
    desc: 'Publié',
  },
  {
    value: TARIF_STATUS.ARCHIVED,
    desc: 'Archivé',
  },
];

export class TarifBase extends DataModel {
  static override readonly __name: string = 'TarifBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'tariftype',
    description: 'ID Modèle',
    related: 'TarifType',
  })
  public tariftype!: number;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: TARIF_STATUS.TOVALIDATE,
    maxLength: 2,
    choices: TarifStatusChoices,
  })
  public status: TARIF_STATUS = TARIF_STATUS.TOVALIDATE;

  @charField({
    name: 'step',
    description: 'Étape',
    maxLength: 10,
  })
  public step!: string;

  @foreignKeyField({
    name: 'tstep',
    description: 'ID Étape',
    related: 'TarifLifecycleStep',
  })
  public tstep?: number;

  @charField({
    name: 'name',
    description: 'Nom du tarif',
    maxLength: 300,
  })
  public name!: string;

  @textField({
    name: 'message',
    description: 'Message',
  })
  public message!: string;

  @datetimeField({
    name: 'datestart',
    description: 'Date de début',
  })
  public datestart!: Date;

  @datetimeField({
    name: 'dateend',
    description: 'Date de fin',
  })
  public dateend!: Date;

  @datetimeField({
    name: 'datevalidation',
    description: 'Date de validation',
  })
  public datevalidation!: Date;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;

  public get isEditable(): boolean {
    return this.status === TARIF_STATUS.PREPARATION;
  }
}
