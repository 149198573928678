import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeRegion } from '../tariftype-region';
import { Subject } from 'rxjs';
import { FilterDefaults } from '@solidev/data';
import { TariftypeRegionPermissionCreateComponent } from '../../tariftype-region-permission/tariftype-region-permission-create/tariftype-region-permission-create.component';
import { TariftypeRegionPermissionListComponent } from '../../tariftype-region-permission/tariftype-region-permission-list/tariftype-region-permission-list.component';

@Component({
  selector: 'lvadg-tariftype-region-manage',
  standalone: true,
  imports: [CommonModule, TariftypeRegionPermissionCreateComponent, TariftypeRegionPermissionListComponent],
  templateUrl: './tariftype-region-manage.component.pug',
  styleUrls: ['./tariftype-region-manage.component.sass'],
})
export class TariftypeRegionManageComponent implements OnInit {
  @Input() public tariftyperegion?: TariftypeRegion;
  public reload$ = new Subject<void | boolean>();
  public permFilter: FilterDefaults = {};
  public perm_default_fields = ['user_details', 'group_details', 'permission'];
  public addPermission: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.tariftyperegion) {
      this.permFilter = { target: this.tariftyperegion.id };
    }
  }
}
