import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
  textField,
} from '@solidev/data';

export enum NEWS_TYPE_CHOICES {
  INT = 'INT',
  EXT = 'EXT',
}

export enum NEWS_STATUSES {
  OK = 'OK',
  KO = 'KO',
}

export class NewsBase extends DataModel {
  static override readonly __name: string = 'NewsBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 255,
    priority: 950,
  })
  public title!: string;

  @textField({
    name: 'content',
    description: 'Contenu Actualité',
    maxLength: 2000,
    priority: -1,
  })
  public content!: string;

  @datetimeField({
    name: 'datestart',
    description: 'Date de debut publication',
    priority: 500,
  })
  public datestart!: Date;

  @datetimeField({
    name: 'dateend',
    description: 'Date de fin publication',
    priority: 490,
  })
  public dateend!: Date;

  @charField({
    name: 'tags',
    description: 'Flags',
    maxLength: 30,
    priority: 50,
  })
  public tags!: string;

  /* FIXME: check si utils */
  @detailsField({
    name: 'dest',
    description: 'Destination',
    required: false,
    priority: 100,
  })
  public dest!: string[];

  /** FIXME: à supprimer ou revoir */
  @charField({
    name: 'icon',
    description: 'Icône',
    maxLength: 100,
    choices: [
      {
        value: '',
        desc: 'Aucune',
      },
      {
        value: 'fab fa-facebook',
        desc: 'Facebook',
      },
      {
        value: 'fab fa-twitter',
        desc: 'Twitter',
      },
      {
        value: 'fab fa-instagram',
        desc: 'Instagram',
      },
      {
        value: 'fab fa-youtube',
        desc: 'Youtube',
      },
      {
        value: 'fa fa-exclamation-triangle',
        desc: 'Warning',
      },
      {
        value: 'fa fa-smile',
        desc: 'Smile',
      },
      {
        value: 'fa fa-smile-wink',
        desc: 'Smile wink',
      },
      {
        value: 'fa fa-grin',
        desc: 'Grin',
      },
      {
        value: 'fa fa-heart',
        desc: 'Heart',
      },
      {
        value: 'fa fa-grin-hearts',
        desc: 'Grin hearts',
      },
      {
        value: 'fa fa-tv-retro',
        desc: 'Television',
      },
      {
        value: 'fa fa-newspaper',
        desc: 'Newspaper',
      },
      {
        value: 'fa fa-folder',
        desc: 'Folder',
      },
      {
        value: 'fa fa-thumbs-up',
        desc: 'Thumbs up',
      },
    ],
    priority: -1,
  })
  public icon!: string;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 110,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 120,
  })
  public updated!: Date;

  @charField({
    name: 'type',
    description: 'Type',
    maxLength: 4,
    choices: [
      {
        value: NEWS_TYPE_CHOICES.INT,
        desc: 'Interne',
      },
      {
        value: NEWS_TYPE_CHOICES.EXT,
        desc: 'Externe',
      },
    ],
    priority: 800,
  })
  public type!: string;

  @charField({
    name: 'url',
    description: 'URL externe',
    maxLength: 255,
    priority: 600,
  })
  public url!: string;

  @integerField({
    name: 'order',
    description: "Ordre d'affichage",
    priority: 400,
  })
  public order!: number;

  @charField({
    name: 'status',
    description: 'État',
    maxLength: 2,
    choices: [
      {
        value: NEWS_STATUSES.KO,
        desc: 'Non publié',
      },
      {
        value: NEWS_STATUSES.OK,
        desc: 'Publié',
      },
    ],
    priority: 700,
  })
  public status!: string;

  @foreignKeyField({
    name: 'document',
    description: 'ID Document lié',
    related: 'Document',
    required: false,
    priority: -1,
  })
  public document!: number;

  @foreignKeyField({
    name: 'folder',
    description: 'ID Dossier lié',
    related: 'Folder',
    required: false,
    priority: -1,
  })
  public folder!: number;

  @manyToManyField({
    name: 'categories',
    description: 'ID catégories liés',
    defaultValue: [],
    related: 'CategoryNews',
    priority: -1,
  })
  public categories: number[] = [];
}
