import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Region } from '../../../../../models/catalog/meta/region/region';

export interface RegionDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface RegionDetailViewData extends RegionDetailViewParams {
  region: Region;
}
