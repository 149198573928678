import { Component, inject, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OffertypeTextType } from "../offertype-text-type";
import { DispeditComponent } from "@solidev/data";
import { GenericregionService } from "../../../locations/genericregion/genericregion.service";

@Component({
  selector: "lvadg-offertype-text-type-manage",
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: "./offertype-text-type-manage.component.pug",
  styleUrls: ["./offertype-text-type-manage.component.sass"],
})
export class OffertypeTextTypeManageComponent {
  @Input() public offertypetexttype?: OffertypeTextType;
  public genericregions$ = inject(GenericregionService);
}
