
<div class="mb-3 text-start" *ngIf="step===0">
  <div class="text-warning">Importation des prix à partir d'un fichier <b>XLSX</b> de tarif, en utilisant un modèle d'importation prédéfini</div>
  <form class="row mb-3 g-3" [formGroup]="form">
    <div class="col-12">
      <select class="form-select" formControlName="metadata">
        <option selected [ngValue]="null">-- Choix du modèle d'importation --</option>
        <option *ngFor="let d of metas$|async" [ngValue]="d">{{d.name}}</option>
      </select>
    </div>
    <div class="col-12">
      <label>Fichier à utiliser pour l'importation</label>
      <select class="form-select" formControlName="document">
        <option selected [ngValue]="null">&downdownarrows; Envoyer un nouveau fichier &downdownarrows;</option>
        <option *ngFor="let d of docs$|async" [ngValue]="d">{{d.filename}}</option>
      </select>
    </div>
    <div class="col-12" *ngIf="form.value.document===null">
      <label>Envoyer un nouveau fichier</label>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="nokeep">
        <label class="form-check-label">Ne pas conserver le fichier dans les documents de tarif</label>
      </div>
      <lvadg-media-upload [url]="uploadUrl" [enabled]="true" [model]="doc" [prepare]="uploadPrepare" (uploadDone)="onUploaded($event)"></lvadg-media-upload>
    </div>
    <div class="col-12">
      <button class="btn btn-primary w-100" (click)="launch()" [disabled]="form.pristine||form.invalid &amp;&amp;!(form.value.document || upload_id || tarifdocument_id)">Tester l'importation de prix</button>
    </div>
  </form>
</div>
<div class="mb-3 text-start" *ngIf="step&gt;=1">
  <div class="text-center my-4" *ngIf="processing">
    <p>Lecture en cours, merci de patienter</p>
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="visually-hidden">Loading..</span></div>
  </div>
  <ng-container *ngIf="result &amp;&amp; result.success">
    <lvadg-oplog-display [result]="result.log" [minLog]="4"></lvadg-oplog-display>
    <button class="btn btn-outline-secondary w-100 my-3" (click)="xlsxExport(result)"><i class="bi bi-file-excel me-2"></i>Télécharger le rapport d'importation</button>
    <p class="text-warning my-3 fs-4" *ngIf="result.data.result.prices &amp;&amp; result.data.result.prices.length==0">Aucun changement de prix</p>
    <div *ngIf="result.data.result.prices &amp;&amp; result.data.result.prices.length">
      <table class="table table-striped table-sm maxh-400">
        <thead>
          <tr>
            <th>Code article</th>
            <th>Libellé</th>
            <th>Code client</th>
            <th>Libellé client</th>
            <th>Ancien prix</th>
            <th>Nouveau prix</th>
            <th>Changé ?</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let d of result.data.result.prices">
            <td>{{d.code}}</td>
            <td>{{d.libelle}}</td>
            <td>{{d.ccode}}</td>
            <td>{{d.clibelle}}</td>
            <td>
              <ng-container *ngIf="d.old_price">{{d.old_price/10000|currency:'EUR'}}</ng-container>
            </td>
            <td>
              <ng-container *ngIf="d.new_price">{{d.new_price/10000|currency:'EUR'}}</ng-container>
            </td>
            <td>{{d.changed?'oui':'non'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" *ngIf="step==1">
      <div class="col">
        <button class="btn btn-warning w-100" (click)="launch(true)">Lancer l'importation réelle</button>
      </div>
      <div class="col">
        <button class="btn btn-outline-secondary w-100" (click)="cancelled.emit()">Annuler</button>
      </div>
    </div>
    <button class="btn btn-success w-100" (click)="done.emit()" *ngIf="step==2">Terminer</button>
  </ng-container>
</div>