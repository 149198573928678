import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '../../../../../models/structures/storage/storage';
import { STORAGE_ROUTES } from '../menu';
import { map, Observable, Subject } from 'rxjs';
import { Client } from '../../../../../models/structures/client/client';
import { Resto } from '../../../../../models/structures/resto/resto';
import { VivalyaBill } from '../../../../../models/sales/vivalya-bill/vivalya-bill';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { VivalyaBillListComponent } from '../../../../../models/sales/vivalya-bill/vivalya-bill-list/vivalya-bill-list.component';
import { StorageNavComponent } from '../_nav/storage-nav.component';

export interface StorageSalesViewParams extends BaseRouteParams {
  s_menu: STORAGE_ROUTES;
  storage_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
}

export interface StorageSalesViewData extends StorageSalesViewParams {
  storage: Storage;
}

@Component({
  selector: 'lvadg-storage-sales-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, VivalyaBillListComponent, StorageNavComponent],
  templateUrl: './storage-sales-view.component.pug',
  styleUrls: ['./storage-sales-view.component.sass'],
})
export class StorageSalesViewComponent extends BaseDetailViewComponent<StorageSalesViewData, Storage> {
  public storageFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public storage_detail_route?: Link<Storage>;
  public client_detail_route?: Link<Client>;
  public resto_detail_route?: Link<Resto>;
  public bill_detail_route?: Link<VivalyaBill>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: StorageSalesViewData) {
    super.setRouteData(data);
    if (data.storage_detail_route) {
      this.storage_detail_route = new Link<Storage>(data.storage_detail_route, data, this._router);
    }
    if (data.client_detail_route) {
      this.client_detail_route = new Link<Client>(data.client_detail_route, data, this._router);
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link<Resto>(data.resto_detail_route, data, this._router);
    }
    if (data.bill_detail_route) {
      this.bill_detail_route = new Link<VivalyaBill>(data.bill_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.storageFilter$ = this.data$.pipe(
      map((data) => ({
        storage: data.storage.id,
      })),
    );
  }
}
