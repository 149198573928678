
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { UserMap } from "./user-map";

@Injectable({
  providedIn: 'root'
})
export class UserMapService extends Collection<UserMap> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/usermaps", UserMap);
  }
}
