import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tariftype } from '../../tariftype/tariftype';
import { TariftypeRelation } from '../tariftype-relation';
import { TariftypeRelationService } from '../tariftype-relation.service';
import { TariftypeService } from '../../tariftype/tariftype.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-tariftype-relation-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './tariftype-relation-create.component.pug',
  styleUrls: ['./tariftype-relation-create.component.sass'],
})
export class TariftypeRelationCreateComponent implements OnInit {
  @Input() public dest?: Tariftype;
  @Input() public src?: Tariftype;
  @Output() public created = new EventEmitter<TariftypeRelation>();
  @Output() public cancelled = new EventEmitter<void>();

  public obj!: TariftypeRelation;

  constructor(
    public ttrels$: TariftypeRelationService,
    public tariftypes$: TariftypeService,
    public msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.obj = this.ttrels$.create({
      dest: this.dest?.id,
      dest_details: this.dest,
      src: this.src?.id,
      src_details: this.src,
    });
  }

  public async save() {
    // FIXME: use action to allow permissions checks
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
    this.msgs.success('Relation ajoutée avec succès');
  }
}
