export enum CDATA_VALUE_TYPE {
  INTEGER = 'INT',
  DECIMAL = 'DEC',
  SHORT_TEXT = 'STX',
  LONG_TEXT = 'LTX',
  RICH_TEXT = 'RTX',
  CURRENCY = 'CUR',
  DATE = 'DTE',
  DATETIME = 'DTH',
  JSON = 'JSON',
}
