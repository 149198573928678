import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject, switchMap, tap, throttleTime } from 'rxjs';
import { LayoutData, LayoutTree } from '../layout/layout';
import { SafeHtml } from '@angular/platform-browser';

interface PreviewData {
  html: SafeHtml | null;
  loading: boolean;
  empty: boolean;
}

@Component({
  selector: 'lvadg-layout-render-preview',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './layout-render-preview.component.pug',
  styleUrls: ['./layout-render-preview.component.sass'],
})
export class LayoutRenderPreviewComponent implements OnInit {
  @Input({ required: true }) public tree!: LayoutTree;
  @Input({ required: true }) public layout$!: Observable<LayoutData | null>;
  @Input() public reload$ = new Subject<void>();
  public zoom = 50;
  public zooms = [10, 25, 33, 50, 75, 100, 125, 150, 200];
  private _preview: BehaviorSubject<PreviewData> = new BehaviorSubject<PreviewData>({
    html: null,
    loading: false,
    empty: true,
  });
  public preview$ = this._preview.asObservable();

  public ngOnInit(): void {
    this.layout$
      .pipe(
        tap((node) => {
          this._preview.next({
            html: null,
            loading: true,
            empty: node === null,
          });
        }),
        throttleTime(1000, undefined, { trailing: true }),
        switchMap((node) => {
          if (node === null) {
            return of(null);
          }
          return this.tree.getRender(node.tree_id);
        }),
      )
      .subscribe((html) => {
        this._preview.next({
          html,
          loading: false,
          empty: html === null,
        });
      });
  }

  setZoom(z: number) {
    this.zoom = z;
  }
}
