import { Component } from '@angular/core';
import { FilterDefaults, Link } from '@solidev/data';
import { Brand } from '../../../../../models/catalog/meta/brand/brand';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { META_ARTICLE_LIST_FIELDS } from '../../constants';
import { map, Observable } from 'rxjs';
import { BrandDetailViewData } from './brand-detail-view.component.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { BrandManageComponent } from '../../../../../models/catalog/meta/brand/brand-manage/brand-manage.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';

@Component({
  selector: 'lvadg-brand-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, ArticleListComponent, BrandManageComponent],
  templateUrl: './brand-detail-view.component.pug',
  styleUrls: ['./brand-detail-view.component.sass'],
})
export class BrandDetailViewComponent extends BaseDetailViewComponent<BrandDetailViewData, Brand> {
  public list_route!: Link<Brand>;
  public article_detail_route!: Link<ArticleBase>;
  public article_list_fields = META_ARTICLE_LIST_FIELDS;

  public metaFilter$!: Observable<FilterDefaults>;

  public override setRouteData(data: BrandDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.metaFilter$ = this.data$.pipe(map((d) => ({ particularites: d.brand.id })));
  }

  deleted() {
    this.list_route.navigate();
  }
}
