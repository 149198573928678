
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { OrderprocessstoragePermission } from "./orderprocessstorage-permission";

@Injectable({
  providedIn: 'root'
})
export class OrderprocessstoragePermissionService extends Collection<OrderprocessstoragePermission> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/orderprocessstoragepermissions", OrderprocessstoragePermission);
  }
}
