import {
  booleanField,
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export enum DISTANCE_PROVIDER {
  UNKNOWN = 'UNK',
  GOOGLE_MAPS = 'GOO',
  OPEN_ROUTE_SERVICE = 'ORS',
  MANUAL = 'MAN',
}

export class DistanceBase extends DataModel {
  static override readonly __name: string = 'DistanceBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 25,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 50,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'location_a',
    description: 'ID Point de départ',
    related: 'Location',
    priority: -1,
  })
  public location_a!: number;

  @foreignKeyField({
    name: 'location_through',
    description: 'ID Point de passage',
    related: 'Location',
    priority: -1,
  })
  public location_through!: number;

  @foreignKeyField({
    name: 'location_b',
    description: 'ID Point arrivée',
    related: 'Location',
    priority: -1,
  })
  public location_b!: number;

  @charField({
    name: 'distance_provider',
    description: 'Fournisseur de distance',
    defaultValue: DISTANCE_PROVIDER.UNKNOWN,
    maxLength: 4,
    choices: [
      { value: DISTANCE_PROVIDER.UNKNOWN, desc: 'Inconnu' },
      { value: DISTANCE_PROVIDER.GOOGLE_MAPS, desc: 'Google Maps' },
      {
        value: DISTANCE_PROVIDER.OPEN_ROUTE_SERVICE,
        desc: 'Open Route Service',
      },
      { value: DISTANCE_PROVIDER.MANUAL, desc: 'Manuel' },
    ],
    priority: 300,
  })
  public distance_provider: DISTANCE_PROVIDER = DISTANCE_PROVIDER.UNKNOWN;

  @integerField({
    name: 'hops',
    description: 'Etapes',
    defaultValue: 2,
    required: true,
    priority: 500,
  })
  public hops: number = 2;

  @integerField({
    name: 'road',
    description: 'Distance route',
    priority: 700,
  })
  public road!: number;

  @integerField({
    name: 'rtime',
    description: 'Durée de trajet',
    priority: 500,
  })
  public rtime!: number;

  @integerField({
    name: 'direct',
    description: 'Distance directe',
    priority: 500,
  })
  public direct!: number;

  @booleanField({
    name: 'need_refresh',
    description: 'Besoin de rafraîchir',
    defaultValue: false,
    priority: 450,
  })
  public need_refresh: boolean = false;

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];
}
