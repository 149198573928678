import { ArticleTarifItemBase } from './article-tarif-item.base';
import { detailsField } from '@solidev/data';
import { StorageBase } from '../../structures/storage/storage.base';

export class ArticleTarifItem extends ArticleTarifItemBase {
  static override readonly __name: string = 'ArticleTarifItem';

  @detailsField({
    model: StorageBase,
    description: 'Dépôt',
    readonly: true,
  })
  public storage_details?: StorageBase;
}
