// Predefined views
export * from './catalog/public-api';
export * from './orders/public-api';
export * from './locations/public-api';
export * from './tarifs/public-api';
export * from './structures/public-api';
export * from './offers/public-api';
export * from './sales/public-api';
export * from './documents/public-api';
export * from './contacts/public-api';

export * from './tarifs/full-tariftype-detail-view/tariftype-detail-view.component';
export * from './tarifs/full-tarif-detail-view/tarif-detail-view.component';
export * from './docs/docs-display-view/docs-display-view.component';
export * from './auth/public-api';
export * from './generic-error-view/generic-error-view.component';
