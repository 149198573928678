import { Component } from '@angular/core';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { RouterLink } from '@angular/router';
import { BASE_VIEW_IMPORTS } from '../../../../components/baseview/baseview.imports';
import { BaseViewComponent } from '../../../../components/baseview/baseview.component';

export interface TarifStatsViewParams extends BaseRouteParams {
  stats_families_route: RouteConfigItem;
  stats_articles_route: RouteConfigItem;
}

export interface TarifStatsViewData extends TarifStatsViewParams {}

@Component({
  selector: 'lvadg-tarif-stats-view',
  standalone: true,
  imports: [...BASE_VIEW_IMPORTS, RouterLink],
  templateUrl: './tarif-stats-view.component.pug',
  styleUrls: ['./tarif-stats-view.component.sass'],
})
export class TarifStatsViewComponent extends BaseViewComponent<TarifStatsViewData> {
  public tarif_stats_families_route!: Link<void>;
  public tarif_stats_articles_route!: Link<void>;

  public override setRouteData(data: TarifStatsViewData) {
    super.setRouteData(data);
    this.tarif_stats_articles_route = new Link(data.stats_articles_route, data, this._router);
    this.tarif_stats_families_route = new Link(data.stats_families_route, data, this._router);
  }
}
