import { ArticleSimilarityBase } from './article-similarity.base';
import { computedField, detailsField } from '@solidev/data';
import { ArticleBase } from '../article/article.base';

export class ArticleSimilarity extends ArticleSimilarityBase {
  static override readonly __name: string = 'ArticleSimilarity';
  @detailsField({
    name: 'article_details',
    description: 'Article',
    model: ArticleBase,
    readonly: true,
  })
  public article_details?: ArticleBase;

  @detailsField({
    name: 'similar_details',
    description: 'Article similaire',
    model: ArticleBase,
    readonly: true,
  })
  public similar_details?: ArticleBase;

  @computedField({
    name: 'article_code',
    description: 'Code article',
  })
  public get article_code(): string {
    return this.article_details?.code ?? '';
  }

  @computedField({
    name: 'similar_code',
    description: 'Code article similaire',
  })
  public get similar_code(): string {
    return this.similar_details?.code ?? '';
  }

  @computedField({
    name: 'article_libelle',
    description: 'Libellé article',
  })
  public get article_libelle(): string {
    return this.article_details?.libelle ?? '';
  }

  @computedField({
    name: 'similar_libelle',
    description: 'Libellé article similaire',
  })
  public get similar_libelle(): string {
    return this.similar_details?.libelle ?? '';
  }
}
