import { RData, RoutesDefinition } from "@solidev/data";
import { MEMBER_LINKS_ROUTES, MEMBER_ROUTES } from "./menu";
import {
  MemberDisplayViewComponent,
  MemberDisplayViewParams,
} from "./display/member-display-view.component";
import {
  MemberEditViewComponent,
  MemberEditViewParams,
} from "./edit/member-edit-view.component";
import {
  MemberRelationsViewComponent,
  MemberRelationsViewParams,
} from "./relations/member-relations-view.component";
import {
  MemberProductsViewComponent,
  MemberProductsViewParams,
} from "./products/member-products-view.component";
import {
  MemberOffersViewComponent,
  MemberOffersViewParams,
} from "./offers/member-offers-view.component";
import {
  MemberStoragesViewComponent,
  MemberStoragesViewParams,
} from "./storages/member-storages-view.component";
import {
  MemberSalesViewComponent,
  MemberSalesViewParams,
} from "./sales/member-sales-view.component";
import { RS } from "../../../../constants";
import {
  MemberProducersViewComponent,
  MemberProducersViewParams,
} from "./producers/member-producers-view.component";
import {
  MemberUsersViewComponent,
  MemberUsersViewParams,
} from "./users/member-users-view.component";
import { CdataKeysResolver } from "../../../../models/cdata/cdata-key/cdata-key.resolver";
import { CDATAKEY_DEST } from "../../../../models/cdata/cdata-key/cdata-key.base";

export const gen_member_detail_routes = (
  routes: MEMBER_ROUTES,
  links: MEMBER_LINKS_ROUTES,
): RoutesDefinition => {
  const routes_def: RoutesDefinition = [];
  if (routes.member_display_route) {
    routes_def.push({
      path: "",
      pathMatch: "full",
      component: MemberDisplayViewComponent,
      data: RData<MemberDisplayViewParams>({
        route: routes.member_display_route,
        title: "Details adherent",
        m_menu: routes,
        skip: true,
      }),
    });
  }
  if (routes.member_edit_route) {
    routes_def.push({
      path: RS.edit,
      component: MemberEditViewComponent,
      data: RData<MemberEditViewParams>({
        route: routes.member_edit_route,
        title: "Édition",
        m_menu: routes,
      }),
    });
  }
  if (routes.member_relations_route) {
    routes_def.push({
      path: RS.relations,
      component: MemberRelationsViewComponent,
      data: RData<MemberRelationsViewParams>({
        route: routes.member_relations_route,
        title: "Relations",
        m_menu: routes,
        storage_detail_route: links.storage_detail_route,
        client_detail_route: links.client_detail_route,
        provider_detail_route: links.provider_detail_route,
        producer_detail_route: links.producer_detail_route,
        resto_detail_route: links.resto_detail_route,
        relation_detail_route: links.relation_detail_route,
      }),
    });
  }
  if (routes.member_products_route) {
    routes_def.push({
      path: RS.products,
      component: MemberProductsViewComponent,
      data: RData<MemberProductsViewParams>({
        route: routes.member_products_route,
        title: "Produits",
        m_menu: routes,
        storage_detail_route: links.storage_detail_route,
        product_detail_route: links.product_detail_route,
        producer_detail_route: links.producer_detail_route,
      }),
    });
  }
  if (routes.member_offers_route) {
    routes_def.push({
      path: RS.offers,
      component: MemberOffersViewComponent,
      data: RData<MemberOffersViewParams>({
        route: routes.member_offers_route,
        title: "Offres",
        m_menu: routes,
        client_detail_route: links.client_detail_route,
        offer_detail_route: links.offer_detail_route,
        storage_detail_route: links.storage_detail_route,
        resto_detail_route: links.resto_detail_route,
        user_detail_route: links.user_detail_route,
        offertype_detail_route: links.offertype_detail_route,
        offertypestorage_detail_route: links.offertypestorage_detail_route,
        offertypestorage_offer_detail_route:
          links.offertypestorage_offer_detail_route,
      }),
    });
  }
  if (routes.member_storages_route) {
    routes_def.push({
      path: RS.storages,
      component: MemberStoragesViewComponent,
      data: RData<MemberStoragesViewParams>({
        route: routes.member_storages_route,
        title: "Dépôts",
        m_menu: routes,
        storage_detail_route: links.storage_detail_route,
      }),
    });
  }
  if (routes.member_sales_route) {
    routes_def.push({
      path: RS.sales,
      component: MemberSalesViewComponent,
      data: RData<MemberSalesViewParams>({
        route: routes.member_sales_route,
        title: "Ventes",
        m_menu: routes,
        storage_detail_route: links.storage_detail_route,
        bill_detail_route: links.bill_detail_route,
        client_detail_route: links.client_detail_route,
        resto_detail_route: links.resto_detail_route,
      }),
    });
  }
  if (routes.member_producers_route) {
    routes_def.push({
      path: RS.producers,
      component: MemberProducersViewComponent,
      data: RData<MemberProducersViewParams>({
        route: routes.member_producers_route,
        title: "Producteurs",
        m_menu: routes,
        producer_detail_route: links.producer_detail_route,
        storage_detail_route: links.storage_detail_route,
      }),
      resolve: {
        producers_cdatakeys: CdataKeysResolver(CDATAKEY_DEST.PRODUCER),
      },
    });
  }
  if (routes.member_users_route) {
    routes_def.push({
      path: RS.users,
      component: MemberUsersViewComponent,
      data: RData<MemberUsersViewParams>({
        route: routes.member_users_route,
        title: "Utilisateurs",
        m_menu: routes,
        user_detail_route: links.user_detail_route,
        group_detail_route: links.group_detail_route,
      }),
    });
  }

  return routes_def;
};
