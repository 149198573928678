
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-container *ngIf="ofcMode==&quot;storage_actions&quot;">{{ofcValue.storage.name}}<small class="ms-2">Opérations globales (dépôts)</small></ng-container>
      <ng-container *ngIf="ofcMode==&quot;regions_actions&quot;">{{ofcValue.region.name}}<small class="ms-2">Opérations globales (régions)</small></ng-container>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngIf="tarif &amp;&amp; ofcMode==&quot;storage_actions&quot;">
      <lvadg-article-tarif-storage-column-operations [columns]="columns" [tarif]="tarif" [storage]="ofcValue.storage" [reload]="reload" (closed)="ofcInstance?.close()"></lvadg-article-tarif-storage-column-operations>
    </ng-container>
    <ng-container *ngIf="tarif &amp;&amp; ofcMode==&quot;region_actions&quot;">
      <lvadg-article-tarif-region-column-operations [columns]="columns" [tarif]="tarif" [region]="ofcValue.region" [reload]="reload" (closed)="ofcInstance?.close()"></lvadg-article-tarif-region-column-operations>
    </ng-container>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'short'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tarif">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tarif" [sort]="false"></data-model-list-field-header>
          </th>
          <td class="pcol" cdk-cell *cdkCellDef="let row">{{row.tarif}}</td>
        </ng-container>
        <ng-container cdkColumnDef="att">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="att" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.att}}</td>
        </ng-container>
        <ng-container cdkColumnDef="price">
          <th class="pcol" cdk-header-cell *cdkHeaderCellDef><span [ngSwitch]="tarif?.tariftype_details?.price_type">
              <ng-container *ngSwitchCase="PT.BRUT">Prix brut</ng-container>
              <ng-container *ngSwitchCase="PT.NET">Prix net</ng-container>
              <ng-container *ngSwitchCase="PT.NET2">Prix netnet</ng-container>
              <ng-container *ngSwitchCase="PT.FRANCO">Prix franco</ng-container>
              <ng-container *ngSwitchCase="PT.CUSTOM">Prix spécifique</ng-container>
              <ng-container *ngSwitchDefault>Prix ???</ng-container></span></th>
          <td class="pcol" cdk-cell *cdkCellDef="let row; let i=index">
            <lvadg-article-tarif-price-dispedit [at]="row" [index]="i" *ngIf="columns.priceMode==='price' || columns.priceMode==='dispoprice'" [editable]="!readonly &amp;&amp; columns.mainPriceEditable" [focus$]="editors.focus$" (register)="editors.register($event)" (unregister)="editors.unregister($event)" (selected)="onSelect($event)" [saveFn]="editPriceFn" (next)="editors.next($event, 1)" (previous)="editors.next($event, -1)"></lvadg-article-tarif-price-dispedit>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="message">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="message" [sort]="false"><span role="button" (click)="messageDisplay=(messageDisplay=='icon'?'text':'icon')" title="Cliquer pour basculer l'affichage des messages"><i class="bi bi-sticky"></i><span class="mx-3" *ngIf="messageDisplay=='text'">Messages</span></span></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-note-dispedit [model]="row" [note]="row.message" field="message" [mode]="messageDisplay"></lvadg-note-dispedit>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.status|pchoice:row:"status"}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_details" [sticky]="true">
          <th class="articlecol" cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_details" [sort]="true" sortField="att__article__libelle"></data-model-list-field-header>
          </th>
          <td class="articlecol" cdk-cell *cdkCellDef="let row">
            <lvadg-article-display [article]="row.article_details" mode="line" [detail_route]="article_detail_route"></lvadg-article-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="activation">
          <th cdk-header-cell *cdkHeaderCellDef><i class="bi bi-check-circle mx-2" title="Activation / désactivation article"></i></th>
          <td class="text-center" cdk-cell *cdkCellDef="let row"><i class="bi bi-check-circle-fill" [class.text-success]="row.status!='KO'" [class.text-muted]="row.status=='KO'" [attr.aria-label]="row.status!=='KO'?'Désactivation':'Activation'" role="button" (click)="toggleStatus(row)" title="Activation / désactivation article"></i></td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient_details" [sticky]="true">
          <th class="articlecol" cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient_details"></data-model-list-field-header>
          </th>
          <td class="articlecol" cdk-cell *cdkCellDef="let row">
            <lvadg-earticle-display [earticle]="row.eaclient_details" mode="line" [detail_route]="earticle_detail_route"></lvadg-earticle-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_family">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_family" [sort]="true" sortField="att__article__family__cfullname"><i class="bi bi-caret-left-fill" (click)="familyDispUpdate('start', -1)" role="button" *ngIf="showFamily"></i><i class="bi bi-caret-right-fill" (click)="familyDispUpdate('start', 1)" role="button" *ngIf="showFamily"></i><span class="mx-3"><span *ngIf="showFamily">Famille</span><i class="bi bi-folder" *ngIf="!showFamily" title="Affichage des familles"></i><i class="bi bi-eye px-2" (click)="showFamily=false" role="button" *ngIf="showFamily" title="Affichage détaillé"></i><i class="bi bi-eye-slash px-2" (click)="showFamily=true" role="button" *ngIf="!showFamily" title="Affichage résumé"></i></span><i class="bi bi-caret-left-fill px-1" (click)="familyDispUpdate('end', -1)" role="button" *ngIf="showFamily"></i><i class="bi bi-caret-right-fill px-1" (click)="familyDispUpdate('end', 1)" role="button" *ngIf="showFamily"></i></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div class="smallfamily" *ngIf="showFamily">
              <lvadg-family-display [family]="row.article_family" mode="line" [detail_route]="family_detail_route" [start]="familyDispStart" [end]="familyDispEnd"></lvadg-family-display>
            </div>
            <div class="smallfamily" *ngIf="!showFamily">
              <div class="d-flex flex-row"><i class="bi bi-folder me-1 me-2" [title]="row.article_family.full_path"></i>
                <div class="names">
                  <div class="name">{{row.article_family.tree_path[2]?.name}}</div>
                  <div class="name ms-2">{{row.article_family.tree_path[4]?.name}}</div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="price_history">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="price_history"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-tarif-price-history [at]="row" [references]="row.reference_tarifs" [price]="row.price" [prices]="row.price_history"></lvadg-article-tarif-price-history>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="reference_tarifs">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="reference_tarifs"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let t of row.reference_tarifs; let index=index"><a class="me-2" [routerLink]="tarif_detail_route?.route({tarif: t})">T{{index+1}}</a></ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="article_uf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_uf"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.article_uf}}</td>
        </ng-container>
        <ng-container cdkColumnDef="article_labels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="article_labels" [sort]="true" sortField="att__article__egalim__code"><i class="bi bi-patch-check d-xxl-none"></i><span class="d-none d-xxl-inline">Labels</span></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.article_labels">
              <lvadg-label-display *ngFor="let l of row.article_labels" [label]="l" mode="small"></lvadg-label-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="price_history_1">
          <th cdk-header-cell *cdkHeaderCellDef><i class="bi bi-eye" role="button" (click)="tooglePriceHistory()"></i>
            <data-model-list-field-header [list]="list" field="price_history_1"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-tarif-price-history [at]="row" [references]="row.reference_tarifs" [price]="row.price" [display]="priceHistoryDisplay" [prices]="row.price_history" [offset]="1"></lvadg-article-tarif-price-history>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="price_history_2">
          <th cdk-header-cell *cdkHeaderCellDef><i class="bi bi-eye" role="button" (click)="tooglePriceHistory()"></i>
            <data-model-list-field-header [list]="list" field="price_history_2"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-tarif-price-history [at]="row" [references]="row.reference_tarifs" [price]="row.price" [display]="priceHistoryDisplay" [prices]="row.price_history" [offset]="2"></lvadg-article-tarif-price-history>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="price_history_3">
          <th cdk-header-cell *cdkHeaderCellDef><i class="bi bi-eye" role="button" (click)="tooglePriceHistory()"></i>
            <data-model-list-field-header [list]="list" field="price_history_3"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-tarif-price-history [at]="row" [references]="row.reference_tarifs" [price]="row.price" [display]="priceHistoryDisplay" [prices]="row.price_history" [offset]="3"></lvadg-article-tarif-price-history>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="price_history_4">
          <th cdk-header-cell *cdkHeaderCellDef><i class="bi bi-eye" role="button" (click)="tooglePriceHistory()"></i>
            <data-model-list-field-header [list]="list" field="price_history_4"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-article-tarif-price-history [at]="row" [references]="row.reference_tarifs" [price]="row.price" [display]="priceHistoryDisplay" [prices]="row.price_history" [offset]="4"></lvadg-article-tarif-price-history>
          </td>
        </ng-container>
        <ng-container *ngFor="let stc of columns.storage_columns">
          <ng-container [cdkColumnDef]="stc.name">
            <th class="stcol" cdk-header-cell *cdkHeaderCellDef>
              <div class="member">{{stc.member.name}}</div>
              <div class="storage">{{stc.storage.name}}</div>
              <div class="badge mt-1" role="button" [attr.aria-label]="'Opérations '+stc.storage.name" [class.bg-danger]="!stc.status || (stc.status &amp;&amp; stc.status.status==='EM')" [class.bg-warning]="stc.status &amp;&amp; stc.status.status==='FL'" [class.bg-success]="stc.status &amp;&amp; stc.status.status==='VA'" [class.bg-secondary]="stc.status &amp;&amp; stc.status.status==='UN'" *ngIf="!readonly &amp;&amp; stc.editable &amp;&amp; columns.isGloballyEditable" (click)="ofc(undefined, 'storage_actions', stc, {position: 'end'})">Opérations</div>
            </th>
            <td class="stcol" cdk-cell *cdkCellDef="let row; let i=index">
              <lvadg-article-tarif-price-dispedit [at]="row" [index]="i" [editable]="!readonly &amp;&amp; stc.editable &amp;&amp; columns.isGloballyEditable" [focus$]="editors.focus$" type="storage" [storage]="stc.storage" [mode]="stc.mode" (register)="editors.register($event)" (unregister)="editors.unregister($event)" (selected)="onSelect($event)" [saveFn]="editPriceFn" (next)="editors.next($event, 1)" (previous)="editors.next($event, -1)"></lvadg-article-tarif-price-dispedit>
            </td>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let stc of columns.region_columns">
          <ng-container [cdkColumnDef]="stc.name">
            <th class="stcol" cdk-header-cell *cdkHeaderCellDef>
              <div class="region">{{stc.region.name}}</div>
              <div class="badge mt-1" role="button" [attr.aria-label]="'Opérations '+stc.region.name" [class.bg-danger]="!stc.status || (stc.status &amp;&amp; stc.status.status==='EM')" [class.bg-warning]="stc.status &amp;&amp; stc.status.status==='FL'" [class.bg-success]="stc.status &amp;&amp; stc.status.status==='VA'" [class.bg-secondary]="stc.status &amp;&amp; stc.status.status==='UN'" *ngIf="!readonly &amp;&amp; stc.editable &amp;&amp; columns.isGloballyEditable" (click)="ofc(undefined, 'region_actions', stc, {position: 'end', panelClass:'w-50'})">Opérations</div>
            </th>
            <td class="stcol" cdk-cell *cdkCellDef="let row; let i=index">
              <lvadg-article-tarif-price-dispedit [at]="row" [index]="i" [editable]="!readonly &amp;&amp; stc.editable &amp;&amp; columns.isGloballyEditable" [focus$]="editors.focus$" type="region" [region]="stc.region" [mode]="stc.mode" (register)="editors.register($event)" (unregister)="editors.unregister($event)" (selected)="onSelect($event)" [saveFn]="editPriceFn" (next)="editors.next($event, 1)" (previous)="editors.next($event, -1)"></lvadg-article-tarif-price-dispedit>
            </td>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let rlc of columns.related_columns">
          <ng-container [cdkColumnDef]="rlc.name">
            <th class="relcol" cdk-header-cell *cdkHeaderCellDef>
              <div class="tarif">{{rlc.label}}</div>
            </th>
            <td class="relcol" cdk-cell *cdkCellDef="let row">
              <lvadg-article-tarif-price-related [at]="row" [tarif]="rlc.tarif" [current]="row.price"></lvadg-article-tarif-price-related>
            </td>
          </ng-container>
        </ng-container>
        <ng-container cdkColumnDef="items_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="items_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">...</td>
        </ng-container>
        <ng-container cdkColumnDef="related_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="related_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">...</td>
        </ng-container>
        <ng-container cdkColumnDef="ops">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ops"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ops}}</td>
        </ng-container>
        <ng-container cdkColumnDef="ops_price">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="ops_price"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.ops_price|pfactorc:row:'ops_price':'EUR'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tstep_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tstep_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.tstep_details.step}}</td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient_libelle">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient_libelle"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.eaclient_libelle}}</td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient_code">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient_code"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.eaclient_code}}</td>
        </ng-container>
        <ng-container cdkColumnDef="eaclient_origin">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="eaclient_origin"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.eaclient_origin}}</td>
        </ng-container>
        <ng-container cdkColumnDef="tarif_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tarif_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-tarif-display [tarif]="row.tarif_details" mode="line" [detail_route]="tarif_detail_route"></lvadg-tarif-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="tariftype_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tariftype_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-tariftype-display [tariftype]="row.tariftype_details" mode="line" [detail_route]="tariftype_detail_route"></lvadg-tariftype-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="in_op">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="in_op"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.in_op? 'Oui' : 'Non'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="actions"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <button class="btn btn-outline-primary btn-sm mr-2" role="button" (click)="action.emit({action: 'selectOp', model: row})" *ngIf="haveAction('selectOp') &amp;&amp; !row.in_op"><i class="bi bi-check-circle-fill me-2"></i>Sélectionner</button>
            <button class="btn btn-outline-primary btn-sm mr-2" role="button" (click)="action.emit({action: 'select', model: row})" *ngIf="haveAction('select')"><i class="bi bi-check-circle-fill me-2"></i>Sélectionner</button>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>