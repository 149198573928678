
<dl class="row" *ngIf="group">
  <data-dispedit class="col-6" [model]="group" field="slug" mode="dd">Identifiant du groupe</data-dispedit>
  <data-dispedit class="col-6" [model]="group" field="name" mode="dd">Description du groupe</data-dispedit>
</dl>
<dl class="row" *ngIf="group">
  <data-dispedit class="col-6" [model]="group" field="member" mode="dd" [collection]="members$" [filter]="{'fields':'id,name,cvva'}">Lien adhérent</data-dispedit>
  <data-dispedit class="col-6" [model]="group" field="storage" mode="dd" [collection]="storages$" [filter]="{'fields':'id,name,cvva'}">Lien dépôt</data-dispedit>
  <data-dispedit class="col-6" [model]="group" field="client" mode="dd" [collection]="clients$" [filter]="{'fields':'id,name,cvva'}">Lien client</data-dispedit>
  <data-dispedit class="col-6" [model]="group" field="resto" mode="dd" [collection]="restos$" [filter]="{'fields':'id,name,cvva'}">Lien resto</data-dispedit>
  <data-dispedit class="col-6" [model]="group" field="tariftype" mode="dd" [collection]="tariftypes$" [filter]="{'fields':'id,name,code'}">Lien tarif</data-dispedit>
  <data-dispedit class="col-6" [model]="group" field="orderprocess" mode="dd" [collection]="orderprocesses$" [filter]="{'fields':'id,name,code'}">Lien process commande</data-dispedit>
</dl>