import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import {News} from "./news";

@Injectable({
    providedIn: 'root'
})
export class NewsService extends Collection<News> {

    constructor(_backend: DataBackend) {
        super(_backend, "/v3/news", News);
    }
}
