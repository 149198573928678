import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IMAGE_USAGE, IMAGE_USAGE_CHOICES, ImageDisplay} from '../image.base';
import {DataModel, DispeditComponent} from '@solidev/data';
import {IconComponent} from '../../../../components/utils/icon/icon.component';
import {ImageDisplayComponent} from '../image-display/image-display.component';
import {filter, firstValueFrom, Observable, switchMap, tap} from 'rxjs';
import {Image} from '../image';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NgMathPipesModule} from 'ngx-pipes';

/**
 * This component is used to manage an image, through model endpoints.
 *
 * Editable fields are :
 * - title
 * - slug
 * - video
 * - order
 * - legend
 * - credits
 *
 * Usage is managed through on/off toggle buttons.
 *
 * Endpoints are provided as input, with default values.
 * - endpoint used to get the image is `get` endpoint.
 * - endpoint used to update the image is `update` endpoint.
 *
 * Image is provided as required component input, and is an observable of an ImageDisplay object.
 * Model is provided as required component input.
 */
@Component({
  selector: 'lvadg-image-manage',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    DispeditComponent,
    ReactiveFormsModule,
    ImageDisplayComponent,
    NgMathPipesModule,
  ],
  templateUrl: './image-manage.component.pug',
  styleUrls: ['./image-manage.component.sass'],
})
export class ImageManageComponent<T extends DataModel> implements OnInit {
  /** Data model that will be used to get/update the image. */
  @Input({ required: true }) public model!: T;
  /** Image observable. */
  @Input({ required: true }) public image!: Observable<ImageDisplay | null>;
  /** Mode of the component. Only offcanvas is available for now */
  @Input() public mode = 'offcanvas' as const;
  /** Endpoints used to get and update the image. */
  @Input() public endpoints: {
    get: string;
    update: string;
  } = {
    get: 'get_logo',
    update: 'update_logo',
  };
  /** Event emitted when the image is updated (after successful save). */
  @Output() public updated = new EventEmitter<T>();
  /** Event emitted when the component is closed. */
  @Output() public closed = new EventEmitter<void>();
  public editForm = new FormGroup({
    title: new FormControl(''),
    slug: new FormControl(''),
    video: new FormControl(''),
    order: new FormControl(0),
    legend: new FormControl(''),
    credits: new FormControl(''),
  });
  public image$!: Observable<Image>;
  public USAGES = IMAGE_USAGE_CHOICES;

  /**
   * Setups the component, piping the image observable to get the image, and patching the form with the image values.
   */
  public ngOnInit(): void {
    this.image$ = this.image.pipe(
      filter((i) => !!i),
      switchMap((i) => this.model.action<T, Image>('GET', this.endpoints.get, { params: { image: `${i!.id}` } })),
      tap((i) => {
        this.editForm.patchValue({
          title: i.title,
          slug: i.slug,
          video: i.video,
          order: i.order,
          legend: i.legend,
          credits: i.credits,
        });
      }),
    );
  }

  /**
   * Toggles the usage of an image.
   * @param image Image to toggle the usage.
   * @param value Usage to toggle.
   */
  public toggleUsage(image: Image, value: IMAGE_USAGE) {
    if (!image) return;
    if (image.usages.indexOf(value) > -1) {
      image.usages = image.usages.filter((u) => u !== value);
    } else {
      image.usages.push(value);
    }
  }

  /**
   * Saves the image, using the update endpoint.
   * Emits the updated event, and the closed event.
   * @param image Image to save.
   */
  public async save(image: Image) {
    if (!image) return;
    if (this.editForm.invalid) return;
    await firstValueFrom(
      this.model.action('PATCH', this.endpoints.update, {
        body: {
          id: image.id,
          usages: image.usages,
          title: this.editForm.value.title,
          slug: this.editForm.value.slug,
          video: this.editForm.value.video,
          order: this.editForm.value.order,
          legend: this.editForm.value.legend,
          credits: this.editForm.value.credits,
        },
      }),
    );
    this.updated.emit(this.model);
    this.closed.emit();
  }
}
