import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberBase } from '../member.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MemberService } from '../member.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-member-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './member-create.component.pug',
  styleUrls: ['./member-create.component.sass'],
})
export class MemberCreateComponent implements OnInit {
  public member!: MemberBase;
  public createForm = new FormGroup({});
  @Output() public created = new EventEmitter<MemberBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _members: MemberService,
    private _msgs: DataMessageService
  ) {}

  ngOnInit(): void {
    this.member = new MemberBase(this._members);
  }

  public async save() {
    this.member.fromJson(
      { parent: null, ...this.createForm.value },
      { partial: true }
    );
    try {
      await firstValueFrom(this.member.save({ updateModel: true }));
      this.created.emit(this.member);
      this._msgs.success('Adhérent créé avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
