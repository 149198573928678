import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Georegion } from '../../../../models/locations/georegion/georegion';

export interface GeoregionDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
}

export interface GeoregionDetailViewData extends GeoregionDetailViewParams {
  georegion: Georegion;
}
