import { Component } from '@angular/core';
import { ParticularityDetailViewData } from './particularity-detail-view.component.params';

import { FilterDefaults, Link, SafeDeleteComponent } from '@solidev/data';
import { map, Observable } from 'rxjs';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../../components/baseview/basedetailview.imports';
import { ParticularityManageComponent } from '../../../../../models/catalog/meta/particularity/particularity-manage/particularity-manage.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { ArticleListComponent } from '../../../../../models/catalog/article/article-list/article-list.component';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { Particularity } from '../../../../../models/catalog/meta/particularity/particularity';
import { ArticleBase } from '../../../../../models/catalog/article/article.base';
import { META_ARTICLE_LIST_FIELDS } from '../../constants';

@Component({
  selector: 'lvadg-particularity-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    ParticularityManageComponent,
    HeaderActionComponent,
    SafeDeleteComponent,
    ArticleListComponent,
  ],
  templateUrl: './particularity-detail-view.component.pug',
  styleUrls: ['./particularity-detail-view.component.sass'],
})
export class ParticularityDetailViewComponent extends BaseDetailViewComponent<
  ParticularityDetailViewData,
  Particularity
> {
  public list_route!: Link<Particularity>;
  public article_detail_route!: Link<ArticleBase>;
  public article_list_fields = META_ARTICLE_LIST_FIELDS;

  public metaFilter$!: Observable<FilterDefaults>;

  public override setRouteData(data: ParticularityDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link(data.list_route, data, this._router);
    this.article_detail_route = new Link<ArticleBase>(data.article_detail_route, data, this._router);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.metaFilter$ = this.data$.pipe(map((d) => ({ particularites: d.particularity.id })));
  }

  deleted() {
    this.list_route.navigate();
  }
}
