import { StorageBase } from "./storage.base";
import { detailsField, integerField } from "@solidev/data";
import { MemberBase } from "../member/member.base";
import { LocationBase } from "../../locations/location/location.base";
import { Image } from "../../documents/image/image";
import { Label } from "../../catalog/label/label";
import { CdataBase } from "../../cdata/cdata/cdata.base";
import { IocodeDetails } from "../../lvadgio/iocode/iocode.base";

export class Storage extends StorageBase {
  static override readonly __name: string = "Storage";

  @detailsField({
    name: "member_details",
    description: "Adhérent",
    model: MemberBase,
    readonly: true,
    priority: 850,
  })
  public member_details?: MemberBase;

  @integerField({
    name: "near_distance",
    description: "Distance",
    readonly: true,
    priority: 400,
  })
  public near_distance?: number;

  @detailsField({
    name: "location_details",
    description: "Adresse",
    readonly: true,
    model: LocationBase,
    priority: 500,
  })
  public location_details?: LocationBase;

  @detailsField({
    name: "logo_details",
    description: "Logo",
    readonly: true,
    model: Image,
    priority: 950,
  })
  public logo_details?: Image;

  @detailsField({
    name: "images_details",
    description: "Images",
    readonly: true,
    model: Image,
    many: true,
    priority: 250,
  })
  public images_details?: Image[];

  @detailsField({
    name: "slabels_details",
    description: "Labels",
    readonly: true,
    model: Label,
    many: true,
    priority: 750,
  })
  public slabels_details: Label[] = [];

  @detailsField({
    description: "Données spécifiques",
    model: CdataBase,
    many: true,
    readonly: true,
  })
  public cdata_details: CdataBase[] = [];

  @detailsField({
    description: "Lien .io",
    name: "iocode",
    priority: 800,
    readonly: true,
  })
  public iocode?: IocodeDetails;
}
