
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Offer } from "./offer";

@Injectable({
  providedIn: 'root'
})
export class OfferService extends Collection<Offer> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/offers", Offer);
  }
}
