import {
  charField,
  DataModel,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class GeodepartementBase extends DataModel {
  static override readonly __name: string = 'GeodepartementBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'country',
    description: 'country',
    related: 'GeoCountry',
  })
  public country!: number;

  @charField({ name: 'name', description: 'name', maxLength: 200 })
  public name!: string;

  @charField({
    name: 'insee',
    description: 'insee',
    defaultValue: '?????',
    required: true,
    maxLength: 20,
  })
  public insee: string = '?????';

  @charField({ name: 'code', description: 'code', maxLength: 20 })
  public code!: string;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;

  public get _display(): string {
    return `${this.name} (${this.code})`;
  }
}
