
<form *ngIf="createForm" [formGroup]="createForm" (submit)="create()">
  <div class="row g-3 mb-3">
    <data-dispedit class="col-4" [model]="item" field="parent" mode="form" [form]="createForm" [collection]="keys$" [filter]="{parent: null}">Parent</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="type" mode="form" [form]="createForm">Type</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="dest" mode="form" [form]="createForm">Destination</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="name" mode="form" [form]="createForm">Nom</data-dispedit>
    <data-dispedit class="col-4" [model]="item" field="description" mode="form" [form]="createForm">Description</data-dispedit>
    <data-dispedit class="col-2" [model]="item" field="code" mode="form" [form]="createForm">Code</data-dispedit>
    <data-dispedit class="col-2" [model]="item" field="unit" mode="form" [form]="createForm">Unité</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>