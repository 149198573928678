import { charField, DataModel, datetimeField, detailsField, foreignKeyField, primaryField } from '@solidev/data';
import { OrderProcessUserLevel } from '../orderprocess/orderprocess.base';

export class OrderprocessstorageBase extends DataModel {
  static override readonly __name: string = 'OrderprocessstorageBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'process',
    description: 'ID Processus commande',
    related: 'OrderProcess',
  })
  public process!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
  })
  public storage!: number;

  @foreignKeyField({
    name: 'platform',
    description: 'ID Plateforme',
    related: 'Platform',
    required: false,
  })
  public platform?: number;

  @charField({
    name: 'delivery_dates_rrule',
    description: 'Règles de récurrence des dates de livraison',
    maxLength: 200,
    required: false,
  })
  public delivery_dates_rrule?: string;

  @detailsField({
    name: 'user_level',
    description: 'Permissions utilisateur',
    deserialize: (d) => d,
  })
  public user_level: OrderProcessUserLevel[] = [];

  @detailsField({
    name: 'params',
    description: 'Paramètres',
    defaultValue: {},
  })
  public params: any = {};

  public have_level(level: OrderProcessUserLevel): boolean {
    return this.user_level.indexOf(level) !== -1;
  }
}
