import { NewsCategory } from '../newscategory';
import { Component } from '@angular/core';
import { FiltersParams, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { NewscategoryService } from '../newscategory.service';
import { DatePipe } from '@angular/common';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';

@Component({
  standalone: true,
  selector: 'lvadg-newscategorie-list',
  templateUrl: './newscategory-list.component.pug',
  imports: [...MODELLIST_IMPORTS, DatePipe, PChoicePipe],
  styleUrls: ['./newscategory-list.component.sass'],
})
export class NewscategoryListComponent extends ModellistComponent<NewsCategory> {
  constructor(coll: NewscategoryService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
