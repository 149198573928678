
<ng-container *ngIf="product">
  <ng-container *ngIf="mode=='line'"><code class="me-2">{{product.id}}</code><a class="text-decoration-none" [routerLink]="detail_route?.route({product})">{{product.name}}<i class="bi bi-link-45deg ms-2" *ngIf="detail_route"></i></a></ng-container>
  <ng-container *ngIf="mode==='rich'">
    <div class="d-flex flex-row justify-content-start align-items-center">
      <lvadg-image-display class="me-2" *ngIf="product.clogo_details" [image]="product.clogo_details" mode="list-image" [thumbnail]="false"></lvadg-image-display>
      <div><a class="fw-bold text-decoration-none" [routerLink]="detail_route?.route({product:product})">
          <ng-container *ngIf="detail_route"><i class="bi bi-link-45deg"></i>&nbsp;</ng-container>{{product.name}}</a>
        <div class="small text-muted">
          <lvadg-family-display [family]="product.family_details" mode="line" [start]="2" [detail_route]="family_detail_route"></lvadg-family-display>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>