
<lvadg-header-action level="h1" title="Documents article" subtitle="Liste des documents article" ri="document">
  <ng-container actions>
    <button class="btn btn-sm btn-outline-primary" type="button" (click)="create.set(!create())"><i class="bi bi-plus-circle me-2"></i>Créer un document</button>
  </ng-container>
</lvadg-header-action>
<div class="card my-3" *ngIf="create()">
  <div class="card-header">Création de document article</div>
  <div class="card-body">
    <lvadg-article-document-create (created)="created($event)"></lvadg-article-document-create>
  </div>
</div>
<ng-container *ngIf="data$|async; let data">
  <lvadg-article-document-list [name]="data.route.name" [detail_route]="detail_route" [reload]="reload$" [article_detail_route]="article_detail_route"></lvadg-article-document-list>
</ng-container>