import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  inject,
  input,
  signal,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { FilterDefaults } from "@solidev/data";

import {
  PRODUCER_STAT_TYPES,
  ProducerStatsService,
  StatsResponse,
} from "../producer-stats.service";

@Component({
  selector: "lvadg-producer-stats",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: "./producer-stats.component.pug",
  styleUrl: "./producer-stats.component.sass",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProducerStatsComponent {
  @HostBinding("class") public readonly class = "card h-100";
  public display = input("card");
  public title = input("Statistiques produits");
  public filter = input<FilterDefaults>({});
  public types = input<PRODUCER_STAT_TYPES[]>([
    PRODUCER_STAT_TYPES.COUNT,
    PRODUCER_STAT_TYPES.AVG_DISTANCE,
    PRODUCER_STAT_TYPES.PER_DISTANCE,
  ]);
  public stats_titles = input({
    [PRODUCER_STAT_TYPES.AVG_DISTANCE]: {
      global: "Distance moyenne (globale)",
      local: "Distance moyenne (locale)",
    },
    [PRODUCER_STAT_TYPES.PER_DISTANCE]: "Par distance",
    [PRODUCER_STAT_TYPES.COUNT]: "Total",
  });
  public stats_display = input({
    [PRODUCER_STAT_TYPES.AVG_DISTANCE]: "default",
    [PRODUCER_STAT_TYPES.PER_DISTANCE]: "default",
    [PRODUCER_STAT_TYPES.COUNT]: "default",
  });

  public readonly PST = PRODUCER_STAT_TYPES;

  public stats = signal<StatsResponse>({} as StatsResponse);
  private _stats = inject(ProducerStatsService);

  constructor() {
    effect(async () => {
      const stats = await this._stats.get_stats(this.types(), this.filter());
      console.log(stats);
      this.stats.set(stats);
    });
  }
}
