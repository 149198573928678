import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutData, LayoutTree } from '../../layout/layout';
import { TemplateChoice, TemplateFragment, TemplateParam, TemplateProvider } from '../../layout/template';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lvadg-layout-template-provider-custom-text',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './layout-template-provider-custom-text.component.pug',
  styleUrls: ['./layout-template-provider-custom-text.component.sass'],
})
export class LayoutTemplateProviderCustomTextComponent implements OnInit {
  @Input() public tree!: LayoutTree;
  @Input() public layout!: LayoutData;
  @Input() public fragment!: TemplateFragment;
  @Input() public param!: TemplateParam;
  @Input() public provider!: TemplateProvider;
  public show_input = false;
  public custom_text = new FormControl<string | null>(null);

  public ngOnInit() {
    if (this.provider?.current && this.provider?.choices[0]!.current) {
      this.custom_text.setValue(this.provider.choices[0]!.value, { emitEvent: false });
      this.show_input = true;
    }
  }

  public select(choice: TemplateChoice) {
    if (this.custom_text.value !== null) {
      this.tree.set_fragment_param({
        layout: this.layout,
        group: this.layout.group,
        fragment: this.fragment,
        param: this.param,
        provider: this.provider,
        choice,
        value: this.custom_text.value,
      });
    }
  }
}
