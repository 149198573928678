import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ordergroup } from '../ordergroup';
import { map, Observable, switchMap } from 'rxjs';
import { OrderprocessarticleService } from '../../orderprocessarticle/orderprocessarticle.service';
import { Orderprocessarticle } from '../../orderprocessarticle/orderprocessarticle';
import { OrderitemListComponent } from '../../orderitem/orderitem-list/orderitem-list.component';
import { PlatformService } from '../../../structures/platform/platform.service';
import { Platform } from '../../../structures/platform/platform';
import { StorageBase } from '../../../structures/storage/storage.base';
import { Link } from '@solidev/data';
import { PlatformBase } from '../../../structures/platform/platform.base';
import { OrderBase } from '../../order/order.base';
import { ArticleBase } from '../../../catalog/article/article.base';
import { TarifBase } from '../../../tarifs/tarif/tarif.base';

@Component({
  selector: 'lvadg-ordergroup-lines',
  standalone: true,
  imports: [CommonModule, OrderitemListComponent],
  templateUrl: './ordergroup-lines.component.pug',
  styleUrls: ['./ordergroup-lines.component.sass'],
})
export class OrdergroupLinesComponent implements OnInit {
  @Input() public name: string = 'ordergrouplines';
  @Input() public data$!: Observable<{ ordergroup: Ordergroup }>;
  @Input() public groupby: 'article' | 'platform' = 'article';

  @Input() public storage_detail_route?: Link<StorageBase>;
  @Input() public platform_detail_route?: Link<PlatformBase>;
  @Input() public order_detail_route?: Link<OrderBase>;
  @Input() public article_detail_route?: Link<ArticleBase>;
  @Input() public tarif_detail_route?: Link<TarifBase>;

  public ordergroup$!: Observable<Ordergroup>;
  public articles$!: Observable<Orderprocessarticle[]>;
  public orderitems_articles_default_fields = [
    'order_details',
    'storage_details',
    'platform_details',
    'colisage',
    'udv_unit',
    'quantity_edit',
    'udf_unit',
    'unit_price',
    'total_weight',
    'total_price',
    'tarif_details',
    'status',
  ];
  public orderitems_platforms_default_fields = [
    'order_details',
    'item_details',
    'storage_details',
    'colisage',
    'udv_unit',
    'quantity_edit',
    'udf_unit',
    'unit_price',
    'total_weight',
    'total_price',
    'tarif_details',
    'status',
  ];
  public platforms$!: Observable<Platform[]>;

  constructor(
    private _articles: OrderprocessarticleService,
    private _platforms: PlatformService
  ) {}

  ngOnInit(): void {
    this.ordergroup$ = this.data$.pipe(map((d) => d.ordergroup));
    this.articles$ = this.ordergroup$.pipe(
      switchMap((g: Ordergroup) => {
        return this._articles.list({ ordergroup: g.id });
      })
    );
    this.platforms$ = this.ordergroup$.pipe(
      switchMap((g: Ordergroup) => {
        return this._platforms.list({ ordergroup: g.id });
      })
    );
  }
}
