import { Component } from '@angular/core';
import { GeocommuneDetailViewData } from './geocommune-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { GeocommuneManageComponent } from '../../../../models/locations/geocommune/geocommune-manage/geocommune-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Geocommune } from '../../../../models/locations/geocommune/geocommune';

@Component({
  selector: 'lvadg-geocommune-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, GeocommuneManageComponent],
  templateUrl: './geocommune-detail-view.component.pug',
  styleUrls: ['./geocommune-detail-view.component.sass'],
})
export class GeocommuneDetailViewComponent extends BaseDetailViewComponent<GeocommuneDetailViewData, Geocommune> {
  public override setRouteData(data: GeocommuneDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
