import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Contact } from '../contact';
import { DispeditComponent } from '@solidev/data';

@Component({
  selector: 'lvadg-contact-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent],
  templateUrl: './contact-manage.component.pug',
  styleUrls: ['./contact-manage.component.sass'],
})
export class ContactManageComponent {
  @Input() public contact?: Contact | null;

  constructor() {}
}
