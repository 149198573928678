
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Eamapping } from '../eamapping';
@Component({
  selector: 'lvadg-eamapping-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './eamapping-manage.component.pug',
  styleUrls: ['./eamapping-manage.component.sass']
})
export class EamappingManageComponent implements OnInit {
  @Input() public eamapping?: Eamapping;

  constructor() { }

  ngOnInit(): void {
  }

}
