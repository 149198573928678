import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '../location';
import { LocationService } from '../location.service';
import { GeoLocationStatus, LOCATION_TYPE } from '../location.base';
import { StructureBase } from '../../../structures/structure.base';
import { StructureBaseAction } from '../../../structures/structure-base-action';

@Component({
  selector: 'lvadg-location-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './location-create.component.pug',
  styleUrls: ['./location-create.component.sass'],
})
export class LocationCreateComponent<T extends StructureBase>
  implements OnInit
{
  public createForm!: FormGroup<{
    address: FormControl<string>;
    postcode: FormControl<string>;
    city: FormControl<string>;
    country: FormControl<string>;
  }>;
  @Input() public structure!: T;
  @Input() public type!: LOCATION_TYPE;
  @Input() public action!: StructureBaseAction<T>;
  @Output() public created = new EventEmitter<Location>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: Location;

  constructor(private _items: LocationService) {}

  ngOnInit(): void {
    if (!this.structure || !this.type || !this.action) {
      throw new Error('Structure, type and action service are required');
    }
    this.item = new Location(this._items);
    this.createForm = this.item.FC(['address', 'postcode', 'city', 'country']);
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    this.item.status = GeoLocationStatus.VALID;
    this.item.loctype = this.type;
    const res = await this.action.action(this.structure, {
      action: 'create_location',
      address: this.createForm.value.address!,
      postcode: this.createForm.value.postcode!,
      city: this.createForm.value.city!,
      country: this.createForm.value.country!,
    });
    if (res.success) {
      this.created.emit(res.data!);
    }
  }
}
