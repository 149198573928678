
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { ProcessService} from "./process.service";
import { Process } from "./process";

@Injectable({
  providedIn: 'root'
})
export class ProcessResolver  {
  constructor(private _rs: ProcessService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Process> {
    return this._rs.fetch(+route.params["processId"])
  }
}
