import { Component, EventEmitter, inject, input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OffertypeTextType } from "../offertype-text-type";
import { Offertype } from "../../offertype/offertype";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DispeditComponent } from "@solidev/data";
import { GenericregionService } from "../../../locations/genericregion/genericregion.service";
import { firstValueFrom } from "rxjs";
import { OffertypeTextTypeService } from "../offertype-text-type.service";

@Component({
  selector: "lvadg-offertype-text-type-create",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: "./offertype-text-type-create.component.pug",
  styleUrls: ["./offertype-text-type-create.component.sass"],
})
export class OffertypeTextTypeCreateComponent {
  public offertype = input<Offertype>();
  @Output() public created = new EventEmitter<OffertypeTextType>();
  @Output() public cancelled = new EventEmitter<void>();

  public form = new FormGroup({});
  public customRegion$ = inject(GenericregionService);
  private ottt = inject(OffertypeTextTypeService);
  public item = this.ottt.create({ offertype: this.offertype()?.id });

  constructor() {}

  public async save() {
    const ot = this.offertype();
    if (this.form.valid && ot) {
      this.item.fromJson(this.form.value, { partial: true, check: true });
      this.item.offertype = ot.id;
      await firstValueFrom(this.item.save({ updateModel: true }));
      this.created.emit(this.item);
    }
  }
}
