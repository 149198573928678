import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum OFFERNOTIFICATION_STATUSES {
  PREPARATION = 'PRP',
  PENDING = 'PEN',
  SENDING = 'SND',
  SENT = 'SNT',
}

export enum OFFERNOTIFICATION_TYPES {
  PUBLICATION = 'PUB',
  UPDATE = 'UPD',
  ALARM = 'ALA',
}

export class OfferNotificationBase extends DataModel {
  static override readonly __name: string = 'OfferNotificationBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 100,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 50,
  })
  public updated!: Date;

  @charField({
    name: 'type',
    description: 'Type de notification',
    defaultValue: OFFERNOTIFICATION_TYPES.PUBLICATION,
    maxLength: 3,
    choices: [
      { value: OFFERNOTIFICATION_TYPES.PUBLICATION, desc: 'Publication' },
      { value: OFFERNOTIFICATION_TYPES.UPDATE, desc: 'Mise à jour' },
      {
        value: OFFERNOTIFICATION_TYPES.ALARM,
        desc: 'Alarm',
      },
    ],
    priority: 800,
  })
  public type: OFFERNOTIFICATION_TYPES = OFFERNOTIFICATION_TYPES.PUBLICATION;

  @foreignKeyField({
    name: 'offertype',
    description: 'ID Offre contractuelle',
    related: 'OfferType',
    priority: -1,
  })
  public offertype!: number;

  @foreignKeyField({
    name: 'offertypestorage',
    description: 'ID Offre contractuelle/dépôt',
    related: 'OfferTypeStorage',
    priority: -1,
  })
  public offertypestorage!: number;

  @foreignKeyField({
    name: 'offer',
    description: 'ID Offre',
    related: 'Offer',
    priority: -1,
  })
  public offer!: number;

  @foreignKeyField({
    name: 'resto',
    description: 'ID Restaurant',
    related: 'Resto',
    priority: -1,
  })
  public resto!: number;

  @foreignKeyField({
    name: 'author',
    description: 'ID Auteur',
    related: 'User',
    priority: -1,
  })
  public author!: number;

  @charField({
    name: 'title',
    description: 'Titre',
    maxLength: 300,
    priority: 500,
  })
  public title!: string;

  @textField({
    name: 'message',
    description: 'Message',
    maxLength: 30000,
    priority: 450,
  })
  public message!: string;

  @charField({
    name: 'status',
    description: 'État',
    defaultValue: OFFERNOTIFICATION_STATUSES.PREPARATION,
    maxLength: 3,
    choices: [
      { value: OFFERNOTIFICATION_STATUSES.PREPARATION, desc: 'En préparation' },
      {
        value: OFFERNOTIFICATION_STATUSES.PENDING,
        desc: "En attente d'envoi",
      },
      { value: OFFERNOTIFICATION_STATUSES.SENDING, desc: "En cours d'envoi" },
      { value: OFFERNOTIFICATION_STATUSES.SENT, desc: 'Envoyée' },
    ],
    priority: 700,
  })
  public status: OFFERNOTIFICATION_STATUSES = OFFERNOTIFICATION_STATUSES.PREPARATION;

  @integerField({ name: 'orig_id', description: 'orig id', priority: -1 })
  public orig_id!: number;
}
