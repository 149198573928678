import { Component } from "@angular/core";

import { Subject } from "rxjs";
import { BaseRouteParams, RouteConfigItem } from "@solidev/data";
import { BASE_LIST_VIEW_IMPORTS } from "../../../../components/baseview/baselistview.imports";
import { ProviderListComponent } from "../../../../models/structures/provider/provider-list/provider-list.component";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { ProviderCreateComponent } from "../../../../models/structures/provider/provider-create/provider-create.component";
import { BaseListViewComponent } from "../../../../components/baseview/baselistview.component";
import { Provider } from "../../../../models/structures/provider/provider";

export interface ProviderListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
}

export interface ProviderListViewData extends ProviderListViewParams {}

@Component({
  selector: "lvadg-provider-list-view",
  standalone: true,
  imports: [
    ...BASE_LIST_VIEW_IMPORTS,
    ProviderListComponent,
    IconComponent,
    ProviderCreateComponent,
  ],
  templateUrl: "./provider-list-view.component.pug",
  styleUrls: ["./provider-list-view.component.sass"],
})
export class ProviderListViewComponent extends BaseListViewComponent<
  ProviderListViewData,
  Provider
> {
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();
  // TODO: add default fields for admins ?
  public default_fields = [
    "name",
    "logo_details",
    "slabels_details",
    "nb_articles",
    "location_details",
    "ref_1g",
    "ref_4g",
    "ref_5g",
    "actions",
  ];

  public override setRouteData(data: ProviderListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
