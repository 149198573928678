import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariftypeRelationBase } from '../tariftype-relation.base';
import { TariftypeRelation } from '../tariftype-relation';
import { Link, PChoicePipe } from '@solidev/data';
import { RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'lvadg-tariftype-relation-display',
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref, PChoicePipe],
  templateUrl: './tariftype-relation-display.component.pug',
  styleUrls: ['./tariftype-relation-display.component.sass'],
})
export class TariftypeRelationDisplayComponent implements OnInit {
  @Input() public tariftyperelation?: TariftypeRelationBase | TariftypeRelation;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<TariftypeRelationBase>;
  @Input() public classes: Record<string, boolean> = {};

  constructor() {}

  ngOnInit(): void {}
}
