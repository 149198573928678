import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArticleDocument } from "../article-document";
import { LabelService } from "../../label/label.service";
import { ArticleService } from "../../article/article.service";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";

@Component({
  selector: "lvadg-article-document-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    FlagsComponent,
    ImageDisplayComponent,
  ],
  templateUrl: "./article-document-manage.component.pug",
  styleUrls: ["./article-document-manage.component.sass"],
})
export class ArticleDocumentManageComponent {
  @Input() public articledocument?: ArticleDocument;

  constructor(
    public labels$: LabelService,
    public articles$: ArticleService,
  ) {}
}
