import { RoutesConfig } from '@solidev/data';
import { News, NewsCategory, RS } from '@vivalya/lvadg';

export const ADMIN_NEWS_ROUTES_NAMES = [
  'news',
  'news_comments',
  'news_news',
  'news_newscategories',
  'news_newscategories_newscategory',
  'news_news_news',
] as const;
export const ADMIN_NEWS_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_NEWS_ROUTES_NAMES)[number]> = {
  news: {
    params: [],
    route: () => ['/', RS.news],
    name: 'news',
  },

  news_comments: {
    params: [],
    route: () => ['/', RS.news, RS.comments],
    name: 'news_comments',
  },
  news_news: {
    params: [],
    route: () => ['/', RS.news, RS.news],
    name: 'news_news',
  },
  news_news_news: {
    params: ['news'],
    route: (n: News) => ['/', RS.news, RS.news, n.id],
    name: 'news_news_news',
  },
  news_newscategories: {
    params: [],
    route: () => ['/', RS.news, RS.newscategories],
    name: 'news_newscategories',
  },
  news_newscategories_newscategory: {
    params: ['newscategory'],
    route: (n: NewsCategory) => ['/', RS.news, RS.newscategories, n.id],
    name: 'news_newscategories_newscategory',
  },
};
