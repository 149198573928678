import { Injectable } from '@angular/core';
import { TarifService } from '../tarif.service';
import { Tarif } from '../tarif';
import {
  TarifLifecycleAction,
  TarifLifecycleActionResult,
} from './lifecycle-actions';
import { firstValueFrom } from 'rxjs';
import { blobDownload } from '../../../documents/download/download-blob';
import { getErrorContext } from '../../../../components/utils/errormessage';
import { DataMessageService } from '@solidev/data';

@Injectable({
  providedIn: 'root',
})
export class TarifLifecycleActionService {
  constructor(
    private _tarifs: TarifService,
    private _msgs: DataMessageService
  ) {}

  public async action(
    tarif: Tarif,
    action: TarifLifecycleAction
  ): Promise<TarifLifecycleActionResult> {
    switch (action.action) {
      case 'export[default]': {
        const blob$ = this._tarifs.blob({
          method: 'POST',
          suffix: `${tarif.id}/xlsx_export`,
          params: { mode: 'default' },
        });
        await firstValueFrom(blobDownload(blob$, tarif.name, '.xlsx'));
        return { message: 'Export généré avec succès', success: true };
      }
      case 'export[nego]': {
        const blob$ = this._tarifs.blob({
          method: 'POST',
          suffix: `${tarif.id}/xlsx_export`,
          params: { mode: 'nego' },
        });
        await firstValueFrom(blobDownload(blob$, tarif.name, '.xlsx'));
        return { message: 'Export [nego] généré avec succès', success: true };
      }
      case 'export[custom]': {
        const blob$ = this._tarifs.blob({
          method: 'POST',
          suffix: `${tarif.id}/xlsx_export`,
          params: { template: 'custom' },
        });
        await firstValueFrom(blobDownload(blob$, tarif.name, '.xlsx'));
        return { message: 'Export [custom] généré avec succès', success: true };
      }
      case 'export[custom+nego]': {
        const blob$ = this._tarifs.blob({
          method: 'POST',
          suffix: `${tarif.id}/xlsx_export`,
          params: { template: 'custom', mode: 'nego' },
        });
        await firstValueFrom(blobDownload(blob$, tarif.name, '.xlsx'));
        return {
          message: 'Export [custom+nego] généré avec succès',
          success: true,
        };
      }

      case 'next':
      case 'step':
      case 'back':
      case 'publish':
      case 'publish_cancel':
      case 'negosend':
        try {
          const res = await firstValueFrom(
            this._tarifs.action<TarifLifecycleActionResult>(
              tarif,
              'POST',
              'lifecycle',
              {
                body: action,
              }
            )
          );
          return res;
        } catch (e) {
          const { title, message, context } = getErrorContext(e, {});
          this._msgs.error(title, message, context);
          return { message: title + ' ' + message, success: false };
        }
    }

    return { message: 'Action non implémentée', success: false };
  }
}
