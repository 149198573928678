import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataModel } from '@solidev/data';
import { Selection } from '../selection.service';

@Component({
  selector: 'lvadg-select-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './select-item.component.pug',
  styleUrls: ['./select-item.component.css'],
})
export class SelectItemComponent<T extends DataModel> {
  @Input({ required: true }) public selection?: Selection<T>;
  @Input({ required: true }) public item!: T;

  isChecked() {
    return this.selection?.isSelected(this.item);
  }

  toggleCheck() {
    return this.selection?.toggleCheck(this.item);
  }
}
