import { RnmarticleBase } from './rnmarticle.base';
import { detailsField } from '@solidev/data';
import { ArticleBase } from '../../catalog/article/article.base';
import { RnmBase } from '../rnm/rnm.base';

export class Rnmarticle extends RnmarticleBase {
  static override readonly __name: string = 'Rnmarticle';
  @detailsField({
    name: 'article_details',
    model: ArticleBase,
    description: 'Article',
  })
  public article_details?: ArticleBase;

  @detailsField({
    name: 'rnm_details',
    model: RnmBase,
    description: 'Pivot RNM',
  })
  public rnm_details?: RnmBase;
}
