
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Client } from "./client";

@Injectable({
  providedIn: 'root'
})
export class ClientService extends Collection<Client> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/clients", Client);
  }
}
