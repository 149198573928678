import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OFFERTYPE_STORAGE_ROUTES } from '../menu';
import { Offertype } from '../../../../../models/offers/offertype/offertype';
import { OffertypeStorage } from '../../../../../models/offers/offertype-storage/offertype-storage';
import { OFFERTYPE_STORAGE_USER_LEVEL } from '../../../../../models/offers/offertype-storage/offertype-storage.base';
import { Link } from '@solidev/data';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { OFFERTYPE_USER_LEVEL } from '../../../../../models/offers/offertype/offertype.base';

@Component({
  selector: 'lvadg-offertype-storage-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './offertype-storage-nav.component.pug',
  styleUrls: ['./offertype-storage-nav.component.sass'],
})
export class OffertypeStorageNavComponent implements OnInit {
  @Input({ required: true }) public routes!: OFFERTYPE_STORAGE_ROUTES;
  @Input() public offertype?: Offertype;
  @Input() public offertypestorage!: OffertypeStorage;
  public ot!: Offertype;
  public offertype_storage_detail_route?: Link<OffertypeStorage>;
  public offertype_storage_edit_route?: Link<OffertypeStorage>;
  public offertype_storage_permissions_route?: Link<OffertypeStorage>;
  public offertype_storage_offers_route?: Link<OffertypeStorage>;
  public OTSUL = OFFERTYPE_STORAGE_USER_LEVEL;
  public OTUL = OFFERTYPE_USER_LEVEL;

  constructor(private _router: Router) {}

  public ngOnInit() {
    if (!this.routes || !this.offertypestorage) {
      console.error('OffertypeStorageNavComponent: missing required inputs');
      return;
    }
    this.ot = this.offertype || this.offertypestorage.offertype_details;

    if (this.routes.offertype_storage_detail_route) {
      this.offertype_storage_detail_route = new Link(
        this.routes.offertype_storage_detail_route,
        { offertype: this.ot },
        this._router,
      );
    }
    if (this.routes.offertype_storage_edit_route) {
      this.offertype_storage_edit_route = new Link(
        this.routes.offertype_storage_edit_route,
        { offertype: this.ot },
        this._router,
      );
    }
    if (this.routes.offertype_storage_permissions_route) {
      this.offertype_storage_permissions_route = new Link(
        this.routes.offertype_storage_permissions_route,
        { offertype: this.ot },
        this._router,
      );
    }
    if (this.routes.offertype_storage_offers_route) {
      this.offertype_storage_offers_route = new Link(
        this.routes.offertype_storage_offers_route,
        { offertype: this.ot },
        this._router,
      );
    }
  }
}
