import { RelationBase } from "./relation.base";
import { computedField, detailsField } from "@solidev/data";
import { Storage } from "../storage/storage";
import { DistanceBase } from "../../locations/distance/distance.base";
import { Client } from "../client/client";
import { Member } from "../member/member";
import { Provider } from "../provider/provider";
import { ProducerBase } from "../producer/producer.base";
import { RestoBase } from "../resto/resto.base";

export class Relation extends RelationBase {
  static override readonly __name: string = "Relation";
  @detailsField({
    description: "Producteur",
    model: ProducerBase,
    readonly: true,
    priority: 500,
  })
  public producer_details?: ProducerBase;

  @detailsField({
    description: "Fournisseur",
    model: Provider,
    readonly: true,
    priority: 500,
  })
  public provider_details?: Provider;

  @detailsField({
    description: "Dépôt",
    model: Storage,
    readonly: true,
    priority: 500,
  })
  public storage_details?: Storage;

  @detailsField({
    description: "Client",
    model: Client,
    readonly: true,
    priority: 500,
  })
  public client_details?: Client;

  @detailsField({
    description: "Restaurant",
    model: RestoBase,
    readonly: true,
    priority: 500,
  })
  public resto_details?: RestoBase;

  @detailsField({
    description: "Adhérent",
    model: Member,
    readonly: true,
    priority: 500,
  })
  public member_details?: Member;

  @detailsField({
    description: "Distance",
    model: DistanceBase,
    readonly: true,
    priority: -1,
  })
  public distance_details?: DistanceBase;

  @computedField({
    name: "distance_road",
    description: "Distance route",
    readonly: true,
    priority: 400,
  })
  public get distance_road(): number {
    return this.distance_details?.road ?? 0;
  }

  @computedField({
    name: "distance_direct",
    description: "Distance directe",
    readonly: true,
    priority: 390,
  })
  public get distance_direct(): number {
    return this.distance_details?.direct ?? 0;
  }

  @computedField({
    name: "distance_rtime",
    description: "Temps de route",
    readonly: true,
    priority: 380,
  })
  public get distance_rtime(): number {
    return this.distance_details?.rtime ?? 0;
  }

  @computedField({
    name: "distance_hops",
    description: "Nombre d'étapes",
    readonly: true,
    priority: 370,
  })
  public get distance_hops(): number {
    return (this.distance_details?.hops ?? 2) - 2;
  }

  @computedField({
    name: "distance_provider",
    description: "Source",
    readonly: true,
    priority: 360,
  })
  public get distance_provider(): string {
    return this.distance_details?.distance_provider ?? "";
  }

  @computedField({
    name: "distance_status",
    description: "État distance",
    readonly: true,
  })
  public get distance_status(): boolean {
    return this.distance_details?.need_refresh ?? false;
  }
}
