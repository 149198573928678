
<ng-template #t let-date="date" let-focused="focused"><span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" [class.used]="haveOffer(date)" (mouseenter)="hoveredDate=date" (mouseleave)="hoveredDate=null">{{ date.day }}</span></ng-template>
<ng-container *ngIf="!offertype &amp;&amp; !storage">
  <ng-container *ngIf="storages$|async; let storages">
    <h3>Selectionnez le dépôt source de l'offre</h3>
    <ul class="list-group maxh-select overflow-auto">
      <li class="list-group-item list-group-item-action" *ngFor="let s of storages" role="button" (click)="selectStorage(s)">{{ s?.name }}</li>
    </ul>
    <div class="d-flex justify-content-end">
      <button class="btn btn-outline-secondary w-25" (click)="cancelled.emit()">Annuler</button>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="!offertype &amp;&amp; storage &amp;&amp; (!client &amp;&amp; !noClient)">
  <h3>Selectionnez la destination de l'offre (client)</h3>
  <form class="row" [formGroup]="clientSelectForm">
    <div class="col-8">
      <input class="form-control" type="text" formControlName="client" placeholder="Rechercher un client">
    </div>
    <div class="col-4">
      <button class="btn btn-primary w-100" (click)="selectClient(null)">Aucun client (offre libre)</button>
    </div>
  </form>
  <ul class="list-group maxh-select overflow-auto">
    <li class="list-group-item list-group-item-action" *ngFor="let c of clients$|async" role="button" (click)="selectClient(c)">{{ c?.name }}</li>
  </ul>
  <div class="d-flex justify-content-end">
    <button class="btn btn-outline-secondary w-25" (click)="cancelled.emit()">Annuler</button>
  </div>
</ng-container>
<ng-container *ngIf="offertype &amp;&amp; !offertypestorage &amp;&amp; !storage">
  <div class="row">
    <!--/ Offertype provided, try to get the storage from offertypes-->
    <ng-container *ngIf="offertype">
      <ng-container *ngIf="offertypestorages$|async; let ofts">
        <div class="col" *ngIf="ofts.length">
          <h3>Sélectionnez un dépôt (via le modèle)</h3>
          <ul class="list-group maxh-select overflow-auto">
            <li class="list-group-item list-group-item-action" *ngFor="let ofts of offertypestorages$|async" role="button" (click)="selectOffertypeStorage(ofts)">{{ ofts.storage_details?.name }}</li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="storages$|async; let storages">
      <div class="col" *ngIf="storages.length">
        <h3>Sélectionnez un dépôt (libre)</h3>
        <ul class="list-group maxh-select overflow-auto">
          <li class="list-group-item list-group-item-action" *ngFor="let s of storages" role="button" (click)="selectStorage(s)">{{ s?.name }}</li>
        </ul>
      </div>
    </ng-container>
    <div class="d-flex justify-content-end">
      <button class="btn btn-outline-secondary w-25" (click)="cancelled.emit()">Annuler</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(offertype &amp;&amp; (offertypestorage || storage)) || (!offertype &amp;&amp; storage &amp;&amp; (client || noClient))">
  <h3>Création de l'offre : {{ storage?.name || offertypestorage?.storage_details?.name }} &rightarrow; {{ client?.name || '?' }}</h3>
  <ng-container *ngIf="lasts$|async; let lasts">
    <div class="text-info" *ngIf="!noClient">Les dates des offres ne peuvent pas se chevaucher. Pour corriger une offre, mettez à jour l'offre existante.</div>
    <div class="row">
      <div class="col-lg-4">
        <div class="text-info">Sélectionnez la source des éléments de l'offre pour la création<i class="bi bi-lock ms-3" *ngIf="lockedLast" title="Débloquer la sélection de la source" (click)="lockedLast=false" role="button"></i><i class="bi bi-unlock ms-3" *ngIf="!lockedLast" title="Débloquer la sélection de la source" (click)="lockedLast=true" role="button"></i></div>
        <ul>
          <li *ngFor="let t of lasts">
            <lvadg-offer-display [offer]="t" mode="line" [classes]="{&quot;text-success&quot;: t.id === last?.id}" (click)="selectLast(t)" role="button"></lvadg-offer-display>
          </li>
          <li class="text-warning" role="button" (click)="selectLast(undefined)" [class.fw-bold]="!last">Créer une offre vierge</li>
        </ul>
        <form class="row" [formGroup]="createForm">
          <div class="col-6">
            <label id="startdate">Date de début</label>
            <input class="form-control" type="datetime-local" formControlName="datestart" aria-labelledby="startdate">
          </div>
          <div class="col-6">
            <label id="enddate">Date de fin</label>
            <input class="form-control" type="datetime-local" formControlName="dateend" aria-labelledby="enddate">
          </div>
          <div class="col-12 my-2">
            <label id="offername">Titre de l'offre</label>
            <div class="input-group"><i class="bi bi-lock input-group-text" *ngIf="lockedName" (click)="toggleNameLock()" role="button"></i><i class="bi bi-unlock input-group-text" *ngIf="!lockedName" (click)="toggleNameLock()" role="button"></i>
              <input class="form-control" type="text" formControlName="name" aria-labelledby="offername">
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-8">
        <div class="text-center my-3">
          <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [startDate]="startDate" [displayMonths]="3" [dayTemplate]="t" outsideDays="hidden" [showWeekNumbers]="true" [markDisabled]="checkDisabled" (navigate)="onNavigation($event, dp)"></ngb-datepicker>
        </div>
      </div>
      <div class="col-8">
        <button class="btn btn-primary w-100" (click)="create()" [disabled]="creating || createForm.invalid">
          <ng-container *ngIf="!creating"><i class="bi bi-save me-2"></i>Créer la nouvelle offre</ng-container>
          <ng-container *ngIf="creating"><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Création en cours, merci de patienter...</ng-container>
        </button>
      </div>
      <div class="col-4">
        <button class="btn btn-outline-secondary w-100" (click)="cancelled.emit()">Annuler</button>
      </div>
    </div>
  </ng-container>
</ng-container>