
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OfferNotificationStatusService} from "./offer-notification-status.service";
import { OfferNotificationStatus } from "./offer-notification-status";

@Injectable({
  providedIn: 'root'
})
export class OfferNotificationStatusResolver implements Resolve<OfferNotificationStatus> {
  constructor(private _rs: OfferNotificationStatusService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OfferNotificationStatus> {
    return this._rs.fetch(+route.params["offernotificationstatusId"])
  }
}
