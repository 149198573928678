import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Geodepartement } from '../geodepartement';
import { GeodepartementService } from '../geodepartement.service';
import {
  FiltersParams,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-geodepartement-list',
  standalone: true,
  templateUrl: './geodepartement-list.component.pug',
  styleUrls: ['./geodepartement-list.component.sass'],
  imports: [...MODELLIST_IMPORTS],
})
export class GeodepartementListComponent extends ModellistComponent<Geodepartement> {
  constructor(
    coll: GeodepartementService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
