import { Component, Input } from '@angular/core';
import { Folder } from '../folder';
import { Link } from '@solidev/data';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-folder-display',
  standalone: true,
  imports: [NgIf, RouterLink],
  templateUrl: './folder-display.component.pug',
  styleUrls: ['./folder-display.component.sass'],
})
export class FolderDisplayComponent {
  @Input() public folder!: Folder;
  @Input() public mode: 'line' | 'line-path' | 'button' = 'line';
  @Input() public detail_route?: Link<Folder>;
  @Input() public start: number = 0;
  @Input() public end: number = 5;
}
