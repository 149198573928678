import { PrintSettings } from "../../layouts/print-settings";

export const PRODUCER_PRINT_SETTINGS: PrintSettings = [
  // Affiches producteurs
  {
    label: "Affiche producteur",
    provider: "producer",
    type: "single",
    actions: ["quick_print", "add_to_print", "button"],
    template: "producer_poster",
    limit: 100,
  },
  {
    label: "Affiche producteur",
    provider: "producers",
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "producer_poster",
    limit: 100,
  },
  {
    label: "Affiche producteur",
    provider: "producers_queryset",
    type: "queryset",
    actions: ["quick_print", "add_to_print"],
    template: "producer_poster",
    limit: 200,
  },
  // Fiches producteurs
  {
    label: "Fiche Producteur",
    provider: "producer",
    type: "single",
    actions: ["quick_print", "add_to_print", "button"],
    template: "producer_detail",
    limit: 250,
  },
  {
    label: "Fiches Producteurs",
    provider: "producers",
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "producer_detail",
    limit: 250,
  },
  {
    label: "Fiches Producteurs",
    provider: "producers_queryset",
    type: "queryset",
    actions: ["quick_print", "add_to_print"],
    template: "producer_detail",
    limit: 250,
  },
  // Fiches et produits producteurs
  {
    label: "Fiche et produits producteur",
    provider: "producer_with_products",
    type: "single",
    actions: ["quick_print", "add_to_print", "button"],
    template: "producer_detail_products",
    limit: 100,
  },
  {
    label: "Fiches et produits producteurs",
    provider: "producers_with_products",
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "producer_detail_products",
    limit: 100,
  },
  {
    label: "Fiches et produits producteurs",
    provider: "producers_with_products_queryset",
    type: "queryset",
    actions: ["quick_print", "add_to_print"],
    template: "producer_detail_products",
    limit: 100,
  },
  // Liste de producteurs
  {
    label: "Liste de producteurs",
    provider: "producers_short",
    type: "selection",
    actions: ["quick_print", "add_to_print"],
    template: "producer_list",
    limit: 1000,
  },
  {
    label: "Liste de producteurs",
    provider: "producers_short_queryset",
    type: "queryset",
    actions: ["quick_print", "add_to_print"],
    template: "producer_list",
    limit: 1000,
  },
];
