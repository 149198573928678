import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispeditComponent } from '@solidev/data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Provider } from '../provider';
import { firstValueFrom } from 'rxjs';
import { ProviderService } from '../provider.service';

@Component({
  selector: 'lvadg-provider-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './provider-create.component.pug',
  styleUrls: ['./provider-create.component.sass'],
})
export class ProviderCreateComponent implements OnInit {
  public createForm!: FormGroup<{ name?: FormControl<string> }>;
  @Output() public created = new EventEmitter<Provider>();
  @Output() public cancelled = new EventEmitter<void>();
  public item!: Provider;

  constructor(private _items: ProviderService) {}

  ngOnInit(): void {
    this.item = new Provider(this._items);
    this.createForm = this.item.FC(['name', 'cvva', 'type']);
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }
}
