import { Component } from '@angular/core';
import { DistanceDetailViewData } from './distance-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { DistanceManageComponent } from '../../../../models/locations/distance/distance-manage/distance-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Distance } from '../../../../models/locations/distance/distance';

@Component({
  selector: 'lvadg-distance-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, DistanceManageComponent],
  templateUrl: './distance-detail-view.component.pug',
  styleUrls: ['./distance-detail-view.component.sass'],
})
export class DistanceDetailViewComponent extends BaseDetailViewComponent<DistanceDetailViewData, Distance> {
  public override setRouteData(data: DistanceDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
