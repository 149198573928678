
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Document } from "./document";

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends Collection<Document> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/documents", Document);
  }
}
