import { FamilyBase } from './family.base';
import { detailsField, integerField } from '@solidev/data';
import { Image } from '../../documents/image/image';

interface FamilyLink {
  id: number;
  source: number;
  dest: number;
  dest_details: FamilyBase;
}

export class Family extends FamilyBase {
  static override readonly __name: string = 'Family';

  @integerField({
    description: '# Produits (direct)',
    readonly: true,
    priority: 550,
  })
  public nbproducts?: number;

  @integerField({
    description: '# Produits (descendants)',
    readonly: true,
    priority: 540,
  })
  public nbsubproducts?: number;

  @integerField({
    description: '# Articles (direct)',
    readonly: true,
    priority: 570,
  })
  public nbarticles?: number;

  @integerField({
    description: '# Articles (descendants)',
    readonly: true,
    priority: 560,
  })
  public nbsubarticles?: number;

  @detailsField({
    description: 'Destination lien',
    deserialize: (data) => {
      const out = [];
      for (const d of data) {
        if (d.dest_details) {
          const df = new FamilyBase();
          df.fromJson(d.dest_details);
          d.dest_details = df;
        }
        out.push(d);
      }
      return out;
    },
    priority: -1,
  })
  public linksources_details?: FamilyLink[];

  @detailsField({
    description: 'Image',
    priority: 950,
    model: Image,
    readonly: true,
  })
  public logo_details?: Image;

  @detailsField({
    description: 'Image (héritée)',
    priority: 950,
    model: Image,
    readonly: true,
  })
  public clogo_details?: Image;

  @detailsField({
    description: 'Photos',
    priority: 930,
    model: Image,
    many: true,
    readonly: true,
  })
  public images_details?: Image[];
}
