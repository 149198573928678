import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DocsService } from '../docs.service';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { Toc } from '../toc';
import { CdkTreeModule, NestedTreeControl } from '@angular/cdk/tree';

@Component({
  selector: 'lvadg-toc-display',
  standalone: true,
  imports: [CommonModule, RouterLink, CdkTreeModule],
  templateUrl: './toc-display.component.pug',
  styleUrls: ['./toc-display.component.sass'],
})
export class TocDisplayComponent implements OnInit {
  public tocs$!: Observable<Toc[]>;
  public treeControl = new NestedTreeControl<Toc>((node) => node.children);
  public tocmap = new Map<number, Toc>();
  public curpath?: string;

  constructor(
    private route: ActivatedRoute,
    private _docs: DocsService,
    private _router: Router
  ) {}

  hasChild = (_: number, node: Toc) =>
    !!node.children && node.children.length > 0;

  public trackBy(index: number, item: Toc) {
    return item.id;
  }

  public isCurrent(node: Toc) {
    return this.curpath?.startsWith(node.fullpath);
  }

  public isExact(node: Toc) {
    return this.curpath === node.fullpath;
  }

  public ngOnInit() {
    this.tocs$ = combineLatest([this._docs.toc(), this.route.url]).pipe(
      tap(([tocs, urls]) => {
        this.tocmap = tocs.items;
        this.curpath = urls
          .filter((i, n) => n > 0)
          .map((u) => u.path)
          .join('/');
        let selected: Toc | null = null;
        for (const t of tocs.items.values()) {
          if (t.fullpath === this.curpath) {
            selected = t;
            break;
          }
        }
        const expanded: Toc[] = [];
        if (selected) {
          for (const p of selected.parents) {
            expanded.push(p);
          }
        }
        if (!selected) {
          for (const tc of tocs.tree) {
            expanded.push(tc);
            for (const c of tc.children) {
              expanded.push(c);
            }
          }
        }
        this.treeControl.expansionModel.select(...expanded);
      }),
      map(([tocs, urls]) => tocs.tree)
    );
  }

  public changed($event: any) {
    console.log($event);
  }
}
