import { RoutesConfig } from '@solidev/data';
import { ADMIN_BASE_ROUTES_CONFIG, ADMIN_BASE_ROUTES_NAMES } from '../pages/base';
import { ADMIN_SALES_ROUTES_CONFIG, ADMIN_SALES_ROUTES_NAMES } from '../pages/sales/sales';
import { ADMIN_TARIFS_ROUTES_CONFIG, ADMIN_TARIFS_ROUTES_NAMES } from '../pages/tarifs/tarifs';
import { ADMIN_STRUCTURES_ROUTES_CONFIG, ADMIN_STRUCTURES_ROUTES_NAMES } from '../pages/structures/structures';
import { ADMIN_ACCESS_ROUTES_CONFIG, ADMIN_ACCESS_ROUTES_NAMES } from '../pages/access/access';
import { ADMIN_CATALOG_ROUTES_CONFIG, ADMIN_CATALOG_ROUTES_NAMES } from '../pages/catalog/catalog';
import { ADMIN_CDATAS_ROUTES_CONFIG, ADMIN_CDATAS_ROUTES_NAMES } from '../pages/cdatas/cdatas';
import { ADMIN_DOCUMENTS_ROUTES_CONFIG, ADMIN_DOCUMENTS_ROUTES_NAMES } from '../pages/documents/documents';
import { ADMIN_LAYOUTS_ROUTES_CONFIG, ADMIN_LAYOUTS_ROUTES_NAMES } from '../pages/layouts/layouts';
import { ADMIN_LOCATIONS_ROUTES_CONFIG, ADMIN_LOCATIONS_ROUTES_NAMES } from '../pages/locations/locations';
import { ADMIN_NEWS_ROUTES_CONFIG, ADMIN_NEWS_ROUTES_NAMES } from '../pages/news/news';
import { ADMIN_OFFERS_ROUTES_CONFIG, ADMIN_OFFERS_ROUTES_NAMES } from '../pages/offers/offers';
import { ADMIN_ORDERS_ROUTES_CONFIG, ADMIN_ORDERS_ROUTES_NAMES } from '../pages/orders/orders';
import { ADMIN_RNM_ROUTES_CONFIG, ADMIN_RNM_ROUTES_NAMES } from '../pages/rnm/rnm';
import { ADMIN_UTILS_ROUTES_CONFIG, ADMIN_UTILS_ROUTES_NAMES } from '../pages/utils/utils';

export const ADMIN_ROUTES_NAMES = [
  ...ADMIN_BASE_ROUTES_NAMES,
  ...ADMIN_ACCESS_ROUTES_NAMES,
  ...ADMIN_CATALOG_ROUTES_NAMES,
  ...ADMIN_CDATAS_ROUTES_NAMES,
  ...ADMIN_DOCUMENTS_ROUTES_NAMES,
  ...ADMIN_LAYOUTS_ROUTES_NAMES,
  ...ADMIN_LOCATIONS_ROUTES_NAMES,
  ...ADMIN_NEWS_ROUTES_NAMES,
  ...ADMIN_OFFERS_ROUTES_NAMES,
  ...ADMIN_ORDERS_ROUTES_NAMES,
  ...ADMIN_RNM_ROUTES_NAMES,
  ...ADMIN_STRUCTURES_ROUTES_NAMES,
  ...ADMIN_SALES_ROUTES_NAMES,
  ...ADMIN_TARIFS_ROUTES_NAMES,
  ...ADMIN_UTILS_ROUTES_NAMES,
] as const;

export const ADMIN_ROUTES: RoutesConfig<(typeof ADMIN_ROUTES_NAMES)[number]> = {
  ...ADMIN_BASE_ROUTES_CONFIG,
  ...ADMIN_ACCESS_ROUTES_CONFIG,
  ...ADMIN_CATALOG_ROUTES_CONFIG,
  ...ADMIN_CDATAS_ROUTES_CONFIG,
  ...ADMIN_DOCUMENTS_ROUTES_CONFIG,
  ...ADMIN_LAYOUTS_ROUTES_CONFIG,
  ...ADMIN_LOCATIONS_ROUTES_CONFIG,
  ...ADMIN_NEWS_ROUTES_CONFIG,
  ...ADMIN_OFFERS_ROUTES_CONFIG,
  ...ADMIN_ORDERS_ROUTES_CONFIG,
  ...ADMIN_RNM_ROUTES_CONFIG,
  ...ADMIN_STRUCTURES_ROUTES_CONFIG,
  ...ADMIN_SALES_ROUTES_CONFIG,
  ...ADMIN_TARIFS_ROUTES_CONFIG,
  ...ADMIN_UTILS_ROUTES_CONFIG,
};
