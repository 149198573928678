import { Component } from '@angular/core';
import { LocationListViewData } from './location-list-view.params';

import { Link } from '@solidev/data';
import { MemberBase } from '../../../../models/structures/member/member.base';
import { ProviderBase } from '../../../../models/structures/provider/provider.base';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { StorageBase } from '../../../../models/structures/storage/storage.base';
import { ClientBase } from '../../../../models/structures/client/client.base';
import { RestoBase } from '../../../../models/structures/resto/resto.base';
import { ProducerBase } from '../../../../models/structures/producer/producer.base';
import { LocationListComponent } from '../../../../models/locations/location/location-list/location-list.component';
import { Location } from '../../../../models/locations/location/location';

@Component({
  selector: 'lvadg-location-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, LocationListComponent],
  templateUrl: './location-list-view.component.pug',
  styleUrls: ['./location-list-view.component.sass'],
})
export class LocationListViewComponent extends BaseListViewComponent<LocationListViewData, Location> {
  public member_detail_route?: Link<MemberBase>;
  public storage_detail_route?: Link<StorageBase>;
  public client_detail_route?: Link<ClientBase>;
  public resto_detail_route?: Link<RestoBase>;
  public producer_detail_route?: Link<ProducerBase>;
  public provider_detail_route?: Link<ProviderBase>;
  public default_fields = [
    'id',
    'loctype',
    'related_details',
    'address',
    'postcode',
    'city',
    'country',
    'geoprovider',
    'geotype',
    'geolabel',
    'georegion_details',
    'geocountry_details',
    'status',
    'flags',
    'updated',
  ];

  public override setRouteData(data: LocationListViewData) {
    super.setRouteData(data);
    this.member_detail_route = new Link<MemberBase>(data.member_detail_route, this, this._router);
    this.storage_detail_route = new Link<StorageBase>(data.storage_detail_route, this, this._router);
    this.client_detail_route = new Link<ClientBase>(data.client_detail_route, this, this._router);
    this.resto_detail_route = new Link<RestoBase>(data.resto_detail_route, this, this._router);
    this.producer_detail_route = new Link<ProducerBase>(data.producer_detail_route, this, this._router);
    this.provider_detail_route = new Link<ProviderBase>(data.provider_detail_route, this, this._router);
  }
}
