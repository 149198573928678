import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { RicheditComponent, tmpl } from '@solidev/data';
import { TarifNotification } from '../../../../../tarif-notification/tarif-notification';
import { TarifNotificationService } from '../../../../../tarif-notification/tarif-notification.service';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TARIFNOTIFICATION_PRIORITY } from '../../../../../tarif-notification/tarif-notification.base';

export interface ComposerMessage {
  priority: TARIFNOTIFICATION_PRIORITY;
  title: string;
  message: string;
}

@Component({
  selector: 'lvadg-tarif-message-composer',
  standalone: true,
  imports: [CommonModule, RicheditComponent, ReactiveFormsModule],
  templateUrl: './tarif-message-composer.component.pug',
  styleUrls: ['./tarif-message-composer.component.sass'],
})
export class TarifMessageComposerComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public mode: 'nego' | 'publish' | 'erratum' = 'nego';
  @Input() public step!: TarifLifecycleStep;
  @Output() public message = new EventEmitter<ComposerMessage | null>();
  public model: TarifNotification = new TarifNotification();
  public priorities = TARIFNOTIFICATION_PRIORITY;
  public messageForm = new FormGroup({
    priority: new FormControl<TARIFNOTIFICATION_PRIORITY>(
      TARIFNOTIFICATION_PRIORITY.P2
    ),
    title: new FormControl<string>('', Validators.required),
    message: new FormControl<string | null>(''),
  });

  constructor(private _tns: TarifNotificationService) {}

  public ngOnInit() {
    const defaults = {
      nego: {
        prio: TARIFNOTIFICATION_PRIORITY.P1,
        title_template: 'Négociation tarif <%= obj.tarif.name %>',
        message_template: `<p>Vous trouverez ci-dessous le tarif <%= obj.tarif.name %></p>
    <%= obj.diff %>
`,
      },
      publish: {
        prio: TARIFNOTIFICATION_PRIORITY.P2,
        title_template: 'Publication tarif <%= obj.tarif.name %>',
        message_template: `<p>Vous trouverez ci-dessous le tarif <%= obj.tarif.name %></p>
    <%= obj.diff %>
`,
      },
      erratum: {
        prio: TARIFNOTIFICATION_PRIORITY.P1,
        title_template: 'Mise à jour tarif <%= obj.tarif.name %>',
        message_template: `<p>Vous trouverez ci-dessous le tarif <%= obj.tarif.name %></p>
    <%= obj.diff %>
`,
      },
    };

    if (this.tarif) {
      this.tarif
        .action<Tarif, { diff: string }>('GET', 'diff')
        .subscribe(({ diff }) => {
          this.messageForm.setValue({
            priority: defaults[this.mode].prio,
            title: tmpl(
              this.step.params$.title_template ||
                defaults[this.mode].title_template,
              { tarif: this.tarif, diff }
            ),
            message: tmpl(
              this.step.params$.message_template ||
                defaults[this.mode].message_template,
              { tarif: this.tarif, diff }
            ),
          });
        });
    }
    this.messageForm.valueChanges.subscribe((v) => {
      if (v.priority && v.message && v.title) {
        this.message.emit({
          priority: v.priority,
          title: v.title,
          message: v.message,
        });
      } else {
        this.message.emit(null);
      }
    });
  }

  public setPriority(p: TARIFNOTIFICATION_PRIORITY) {
    this.messageForm.patchValue({ priority: p });
  }

  public useModel() {
    const text = `Germanus, brevis advenas satis desiderium de barbatus, domesticus silva.`;
    this.messageForm.patchValue({
      message: this.messageForm.value.message + text,
    });
  }
}
