
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">Gestion des impressions</h5>
    <button class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <button class="btn btn-primary w-100 my-2" [routerLink]="print_list_route?.route()" (click)="offcanvas.dismiss()"><i class="bi bi-list-check me-2"></i> Toutes les impressions</button>
    <hr>
    <h4>Liste des impressions en préparation</h4>
    <button class="btn btn-outline-primary w-100 my-2 text-center" (click)="create()"><i class="bi bi-plus-circle me-2"></i> Nouvelle impression</button>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Impression</th>
          <th>Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="current$|async; let current">
        <ng-container *ngFor="let p of available$|async">
          <tr [class.table-success]="p.id==current?.id">
            <td><a [routerLink]="print_detail_route?.route({print: p})" (click)="offcanvas.dismiss()" title="Aller à l'impression"><i class="bi bi-link me-2"></i>{{p.id}}</a></td>
            <td>{{p.name}}</td>
            <td>{{p.updated|date:'shortDate'}}</td>
            <td><span class="px-2" role="button" (click)="select(p)" title="Sélectionner comme impression courante"><i class="bi bi-bullseye"></i></span></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="offcanvas-footer">
    <button class="btn btn-secondary" (click)="offcanvas.dismiss()">Close</button>
  </div>
</ng-template>
<ng-template #noCurrent><i class="bi bi-printer text-white" (click)="open()" role="button"></i></ng-template>
<ng-container *ngIf="current$|async; let current; else noCurrent"><i class="bi bi-printer" (click)="open()" role="button"></i></ng-container>