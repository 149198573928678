import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessBase } from '../orderprocess.base';
import { Orderprocess } from '../orderprocess';
import { Link } from '@solidev/data';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lvadg-orderprocess-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './orderprocess-display.component.pug',
  styleUrls: ['./orderprocess-display.component.sass'],
})
export class OrderprocessDisplayComponent implements OnInit {
  @Input() public orderprocess?: OrderprocessBase | Orderprocess;
  @Input() public mode: 'line' = 'line';
  @Input() public detail_route?: Link<OrderprocessBase>;

  constructor() {}

  ngOnInit(): void {}
}
