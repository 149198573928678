import { Component, inject } from "@angular/core";
import {
  BaseRouteParams,
  DataMessageService,
  Link,
  RouteConfigItem,
  SafeDeleteComponent,
} from "@solidev/data";
import { PRODUCER_ROUTES } from "../menu";
import { Producer } from "../../../../../models/structures/producer/producer";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProducerNavComponent } from "../_nav/producer-nav.component";
import { ProducerManageComponent } from "../../../../../models/structures/producer/producer-manage/producer-manage.component";
import { Storage } from "../../../../../models/structures/storage/storage";
import { DB_CHECKS } from "../../../../../constants";
import { PRODUCER_USER_LEVELS } from "../../../../../models/structures/producer/producer.base";

export interface ProducerEditViewParams extends BaseRouteParams {
  pr_menu: PRODUCER_ROUTES;
  producer_list_route?: RouteConfigItem;
}

export interface ProducerEditViewData extends ProducerEditViewParams {
  producer: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-producer-edit-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProducerNavComponent,
    SafeDeleteComponent,
    ProducerManageComponent,
  ],
  templateUrl: "./producer-edit-view.component.pug",
  styleUrl: "./producer-edit-view.component.sass",
})
export class ProducerEditViewComponent extends BaseDetailViewComponent<
  ProducerEditViewData,
  Producer
> {
  public producer_list_route?: Link<never>;
  protected readonly DB_CHECKS = DB_CHECKS;
  protected readonly PRODUCER_USER_LEVELS = PRODUCER_USER_LEVELS;
  private _msgs = inject(DataMessageService);

  public override setRouteData(data: ProducerEditViewData) {
    super.setRouteData(data);
    if (data.producer_list_route) {
      this.producer_list_route = new Link(
        data.producer_list_route,
        data,
        this._router,
      );
    }
  }

  public deleted() {
    this._msgs.success("Le producteur a été supprimé avec succès.");
    if (this.producer_list_route) {
      this.producer_list_route.navigate();
    }
    this._router.navigate(["../.."], { relativeTo: this._route });
  }
}
