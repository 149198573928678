
<form [formGroup]="form" (submit)="save()">
  <div class="row g-2">
    <data-dispedit class="col-6" [model]="ot" field="name" mode="form" [form]="form">Nom</data-dispedit>
    <data-dispedit class="col-6" [model]="ot" field="type" mode="form" [form]="form">Type</data-dispedit>
    <data-dispedit class="col-6" [model]="ot" field="client" mode="form" [form]="form" [collection]="clients$" [filter]="{level: 1, fields: ['id', 'cvva', 'name'].join(',')}">Client</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="form.pristine||form.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>