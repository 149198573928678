import { Component, inject } from "@angular/core";
import { ArticleViewData, ArticleViewParams } from "../article-view.params";
import { BaseRouteParams } from "@solidev/data";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { Article } from "../../../../../models/catalog/article/article";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ArticleNavComponent } from "../_nav/article-nav.component";
import { ProviderDetailsComponent } from "../../../../../models/structures/provider/provider-details/provider-details.component";
import { Observable, of, switchMap } from "rxjs";
import { ProviderService } from "../../../../../models/structures/provider/provider.service";
import { Provider } from "../../../../../models/structures/provider/provider";

export interface ArticleProviderViewParams
  extends ArticleViewParams,
    BaseRouteParams {}

export interface ArticleProviderViewData
  extends ArticleProviderViewParams,
    ArticleViewData {}

@Component({
  selector: "lvadg-article-provider-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ArticleNavComponent,
    ProviderDetailsComponent,
  ],
  templateUrl: "./article-provider-view.component.pug",
  styleUrl: "./article-provider-view.component.sass",
})
export class ArticleProviderViewComponent extends BaseDetailViewComponent<
  ArticleProviderViewData,
  Article
> {
  public provider$!: Observable<Provider | null>;
  private _providers = inject(ProviderService);

  public override postNgOnInit() {
    super.postNgOnInit();
    this.provider$ = this.data$.pipe(
      switchMap((data) => {
        return data.article.fournisseur
          ? this._providers.fetch(data.article.fournisseur)
          : of(null);
      }),
    );
  }
}
