import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  textField,
} from '@solidev/data';

export enum ContentType {
  TEXT = 'txt',
  MARKDOWN = 'md',
  HTML = 'html',
}

export class StructureBase extends DataModel {
  static override readonly __name: string = 'StructureBase';

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 150,
  })
  public flags: string[] = [];

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 75,
  })
  public updated!: Date;

  @charField({
    name: 'name',
    description: 'Nom',
    maxLength: 150,
    priority: 950,
  })
  public name!: string;

  @foreignKeyField({
    name: 'location',
    description: 'ID Adresse',
    related: 'Location',
    priority: -1,
  })
  public location!: number;

  @charField({
    name: 'phone',
    description: 'Téléphone',
    maxLength: 20,
    priority: 400,
  })
  public phone!: string;

  @charField({
    name: 'fax',
    description: 'Fax',
    maxLength: 20,
    priority: 400,
  })
  public fax!: string;

  @charField({
    name: 'website',
    description: 'Site internet',
    maxLength: 400,
    priority: 400,
  })
  public website!: string;

  @charField({
    name: 'siren',
    description: 'SIREN',
    maxLength: 20,
    priority: 300,
  })
  public siren!: string;

  @charField({
    name: 'siret',
    description: 'SIRET',
    maxLength: 20,
    priority: 300,
  })
  public siret!: string;

  @charField({
    name: 'tvaic',
    description: 'TVAIC',
    maxLength: 20,
    priority: 300,
  })
  public tvaic!: string;

  @charField({
    name: 'naf',
    description: 'Code NAF',
    maxLength: 20,
    priority: 300,
  })
  public naf!: string;

  @charField({
    name: 'legal',
    description: 'Type légal',
    defaultValue: 'UNK',
    maxLength: 50,
    choices: [
      { value: 'APEA', desc: 'Affaire personnelle exploitant agricole' },
      {
        value: 'EARL',
        desc: 'Entreprise agricole \u00e0 responsabilit\u00e9 limit\u00e9e',
      },
      { value: 'EI', desc: 'Entreprise individuelle' },
      {
        value: 'EIRL',
        desc: 'Entreprise individuelle \u00e0 responsabilit\u00e9 limit\u00e9e (01.01.2010)',
      },
      {
        value: 'EURL',
        desc: 'Entreprise unipersonnelle \u00e0 responsabilit\u00e9 limit\u00e9e',
      },
      {
        value: 'GAEC',
        desc: "Groupement agricole d'exploitation en commun",
      },
      {
        value: 'GEIE',
        desc: "Groupement europ\u00e9en d'int\u00e9r\u00eat \u00e9conomique",
      },
      {
        value: 'GIE',
        desc: "Groupement d'int\u00e9r\u00eat \u00e9conomique",
      },
      {
        value: 'SARL',
        desc: 'Soci\u00e9t\u00e9 \u00e0 responsabilit\u00e9 limit\u00e9e',
      },
      {
        value: 'SA',
        desc: 'Soci\u00e9t\u00e9 anonyme',
      },
      { value: 'SAS', desc: 'Soci\u00e9t\u00e9 par actions simplifi\u00e9e' },
      {
        value: 'SASU',
        desc: 'Soci\u00e9t\u00e9 par actions simplifi\u00e9e unipersonnelle',
      },
      { value: 'SC', desc: 'Soci\u00e9t\u00e9 civile' },
      {
        value: 'SCA',
        desc: 'Soci\u00e9t\u00e9 en commandite par actions',
      },
      { value: 'SCI', desc: 'Soci\u00e9t\u00e9 civile immobili\u00e8re' },
      {
        value: 'SCIC',
        desc: "Soci\u00e9t\u00e9 coop\u00e9rative d'int\u00e9r\u00eat collectif",
      },
      { value: 'SCM', desc: 'Soci\u00e9t\u00e9 civile de moyens' },
      {
        value: 'SCOP',
        desc: 'Soci\u00e9t\u00e9 coop\u00e9rative ouvri\u00e8re de production',
      },
      { value: 'SCP', desc: 'Soci\u00e9t\u00e9 civile provessionnelle' },
      {
        value: 'SCS',
        desc: 'Soci\u00e9t\u00e9 en commandite simple',
      },
      { value: 'SEL', desc: "Soci\u00e9t\u00e9 d'exercice lib\u00e9ral" },
      {
        value: 'SELAFA',
        desc: "Soci\u00e9t\u00e9 d'exercice lib\u00e9ral \u00e0 forme anonyme",
      },
      {
        value: 'SELARL',
        desc: "Soci\u00e9t\u00e9 d'exercice lib\u00e9ral \u00e0 responsabilit\u00e9 limit\u00e9e",
      },
      {
        value: 'SELAS',
        desc: "Soci\u00e9t\u00e9 d'exercice lib\u00e9ral par actions simplifi\u00e9e",
      },
      {
        value: 'SELCA',
        desc: "Soci\u00e9t\u00e9 d'exercice lib\u00e9ral en commandite par actions",
      },
      { value: 'SEM', desc: "Soci\u00e9t\u00e9 d'\u00e9conomie mixte" },
      {
        value: 'SEML',
        desc: "Soci\u00e9t\u00e9 d'\u00e9conomie mixte locale",
      },
      { value: 'SEP', desc: 'Soci\u00e9t\u00e9 en participation' },
      {
        value: 'SICA',
        desc: "Soci\u00e9t\u00e9 d'int\u00e9r\u00eat collectif agricole",
      },
      { value: 'SNC', desc: 'Soci\u00e9t\u00e9 en nom collectif' },
      {
        value: 'OTH',
        desc: 'Autre forme l\u00e9gale',
      },
      { value: 'UNK', desc: 'Forme l\u00e9gale non renseign\u00e9e' },
    ],
    priority: 300,
  })
  public legal: string = 'UNK';

  @charField({
    name: 'capital',
    description: 'Capital',
    maxLength: 20,
    priority: 300,
  })
  public capital!: string;

  @foreignKeyField({
    name: 'logo',
    description: 'ID Logo',
    related: 'Image',
    priority: -1,
  })
  public logo!: number;

  @textField({
    name: 'content_src',
    description: 'Description (source)',
    required: false,
    priority: 500,
  })
  public content_src?: string;

  @textField({
    name: 'content_html',
    description: 'Description (html)',
    required: false,
    priority: -1,
  })
  public content_html?: string;

  @charField({
    name: 'content_type',
    description: 'Description (type)',
    required: false,
    choices: [
      { value: ContentType.TEXT, desc: 'Texte brut' },
      { value: ContentType.MARKDOWN, desc: 'Markdown' },
      { value: ContentType.HTML, desc: 'HTML' },
    ],
    defaultValue: ContentType.MARKDOWN,
    priority: -1,
  })
  public content_type: ContentType = ContentType.MARKDOWN;

  @charField({
    name: 'cvva',
    description: 'Code Vivalya',
    maxLength: 50,
    priority: 930,
  })
  public cvva!: string;

  @datetimeField({
    name: 'cvva_updated',
    description: 'Dernière MAJ Vivalya',
    priority: 25,
  })
  public cvva_updated!: Date;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -100,
  })
  public orig_id!: number;

  @manyToManyField({
    name: 'slabels',
    description: 'IDs Labels structure',
    defaultValue: [],
    related: 'Label',
    priority: -1,
  })
  public slabels: number[] = [];

  @manyToManyField({
    name: 'images',
    description: 'IDs images',
    defaultValue: [],
    related: 'Image',
    priority: -1,
  })
  public images: number[] = [];

  public get map_pointer(): string {
    return '';
  }
}
