import { RouteConfigItem } from '@solidev/data';

export interface RESTO_ROUTES {
  resto_display_route?: RouteConfigItem;
  resto_edit_route?: RouteConfigItem;
  resto_relations_route?: RouteConfigItem;
  resto_offers_route?: RouteConfigItem;
  resto_sales_route?: RouteConfigItem;
  resto_users_route?: RouteConfigItem;
}

export interface RESTO_LINKS_ROUTES {
  offer_detail_route?: RouteConfigItem;
  bill_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  relation_detail_route?: RouteConfigItem;
  client_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  storage_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
  producer_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
}
