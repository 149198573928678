import { RoutesConfig } from '@solidev/data';
import {
  RnmarticleBase,
  RnmatcacheBase,
  RnmattBase,
  RnmBase,
  RnmcotationBase,
  RnmlibelleBase,
  RnmmarcheBase,
  RnmtarifBase,
  RS,
} from '@vivalya/lvadg';

export const ADMIN_RNM_ROUTES_NAMES = [
  'rnm',
  'rnm_articles',
  'rnm_articles_article',
  'rnm_atcaches',
  'rnm_atcaches_atcache',
  'rnm_atts',
  'rnm_atts_att',
  'rnm_cotations',
  'rnm_cotations_cotation',
  'rnm_libelles',
  'rnm_libelles_libelle',
  'rnm_marches',
  'rnm_marches_marche',
  'rnm_rnms',
  'rnm_rnms_rnm',
  'rnm_tarifs',
  'rnm_tarifs_tarif',
] as const;

export const ADMIN_RNM_ROUTES_CONFIG: RoutesConfig<(typeof ADMIN_RNM_ROUTES_NAMES)[number]> = {
  rnm: {
    params: [],
    route: () => ['/', RS.rnm],
    name: 'rnm',
  },

  rnm_articles: {
    params: [],
    route: () => ['/', RS.rnm, RS.articles],
    name: 'rnm_articles',
  },
  rnm_articles_article: {
    params: ['rnmarticle'],
    route: (a: RnmarticleBase) => ['/', RS.rnm, RS.articles, a.id],
    name: 'rnm_articles_article',
  },
  rnm_atcaches: {
    params: [],
    route: () => ['/', RS.rnm, RS.atcaches],
    name: 'rnm_atcaches',
  },
  rnm_atcaches_atcache: {
    params: ['rnmatcache'],
    route: (a: RnmatcacheBase) => ['/', RS.rnm, RS.atcaches, a.id],
    name: 'rnm_atcaches_atcache',
  },

  rnm_atts: {
    params: [],
    route: () => ['/', RS.rnm, RS.atts],
    name: 'rnm_atts',
  },
  rnm_atts_att: {
    params: ['rnmatt'],
    route: (a: RnmattBase) => ['/', RS.rnm, RS.atts, a.id],
    name: 'rnm_atts_att',
  },

  rnm_cotations: {
    params: [],
    route: () => ['/', RS.rnm, RS.cotations],
    name: 'rnm_cotations',
  },
  rnm_cotations_cotation: {
    params: ['rnmcotation'],
    route: (c: RnmcotationBase) => ['/', RS.rnm, RS.cotations, c.id],
    name: 'rnm_cotations_cotation',
  },

  rnm_libelles: {
    params: [],
    route: () => ['/', RS.rnm, RS.libelles],
    name: 'rnm_libelles',
  },
  rnm_libelles_libelle: {
    params: ['rnmlibelle'],
    route: (l: RnmlibelleBase) => ['/', RS.rnm, RS.libelles, l.id],
    name: 'rnm_libelles_libelle',
  },

  rnm_marches: {
    params: [],
    route: () => ['/', RS.rnm, RS.marches],
    name: 'rnm_marches',
  },
  rnm_marches_marche: {
    params: ['rnmmarche'],
    route: (m: RnmmarcheBase) => ['/', RS.rnm, RS.marches, m.id],
    name: 'rnm_marches_marche',
  },
  rnm_rnms: {
    params: [],
    route: () => ['/', RS.rnm, RS.rnms],
    name: 'rnm_rnms',
  },
  rnm_rnms_rnm: {
    params: ['rnm'],
    route: (r: RnmBase) => ['/', RS.rnm, RS.rnms, r.id],
    name: 'rnm_rnms_rnm',
  },
  rnm_tarifs: {
    params: [],
    route: () => ['/', RS.rnm, RS.tarifs],
    name: 'rnm_tarifs',
  },
  rnm_tarifs_tarif: {
    params: ['rnmtarif'],
    route: (t: RnmtarifBase) => ['/', RS.rnm, RS.tarifs, t.id],
    name: 'rnm_tarifs_tarif',
  },
};
