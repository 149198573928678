import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderprocessstorageService } from '../orderprocessstorage.service';
import { OrderprocessstorageBase } from '../orderprocessstorage.base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { OrderprocessService } from '../../orderprocess/orderprocess.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { PlatformService } from '../../../structures/platform/platform.service';
import { Orderprocess } from '../../orderprocess/orderprocess';
import { Orderprocessstorage } from '../orderprocessstorage';

@Component({
  selector: 'lvadg-orderprocessstorage-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './orderprocessstorage-create.component.pug',
  styleUrls: ['./orderprocessstorage-create.component.sass'],
})
export class OrderprocessstorageCreateComponent implements OnInit {
  public obj!: Orderprocessstorage;
  public createForm = new FormGroup({});
  @Input() public orderprocess?: Orderprocess;
  @Output() public created = new EventEmitter<OrderprocessstorageBase>();
  @Output() public cancelled = new EventEmitter<void>();

  constructor(
    private _svc: OrderprocessstorageService,
    public process$: OrderprocessService,
    public storage$: StorageService,
    public platform$: PlatformService
  ) {}

  public ngOnInit(): void {
    this.obj = new Orderprocessstorage(this._svc);
    if (this.orderprocess) {
      this.obj.process = this.orderprocess.id;
      this.obj.process_details = this.orderprocess;
    }
  }

  public async save() {
    this.obj.fromJson(this.createForm.value, { partial: true });
    await firstValueFrom(this.obj.save({ updateModel: true }));
    this.created.emit(this.obj);
  }
}
