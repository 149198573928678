import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../../tarif';
import { TarifLifecycleStep } from '../../../../../tarif-lifecycle-step/tarif-lifecycle-step';
import { TarifDocumentCreateComponent } from '../../../../../tarif-document/tarif-document-create/tarif-document-create.component';
import { UploadResult } from '../../../../../../documents/upload/media-upload/media-upload.component';
import { TarifDocument } from '../../../../../tarif-document/tarif-document';
import { lifecycleNav, TarifLifecycleAction } from '../../../lifecycle-actions';
import { firstValueFrom, Subject } from 'rxjs';
import { TarifLifecycleActionService } from '../../../tarif-lifecycle-action.service';
import { DataMessageService } from '@solidev/data';
import { TarifDocumentService } from '../../../../../tarif-document/tarif-document.service';
import { TARIFDOCUMENT_STATUSES } from '../../../../../tarif-document/tarif-document.base';
import { Tariftype } from '../../../../../tariftype/tariftype';
import { TARIF_ROUTES } from '../../../../../../../views/tarifs/tarifs/detail/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'lvadg-tarif-lifecycle-step-upload',
  standalone: true,
  imports: [CommonModule, TarifDocumentCreateComponent],
  templateUrl: './tarif-lifecycle-step-upload.component.pug',
  styleUrls: ['./tarif-lifecycle-step-upload.component.sass'],
})
export class TarifLifecycleStepUploadComponent {
  @Input() public tarif!: Tarif;
  @Input() public tariftype!: Tariftype;
  @Input() public step!: TarifLifecycleStep;
  @Input() public menu?: TARIF_ROUTES;
  @Output() public next = new EventEmitter<void>();
  @Input() public reload$!: Subject<void | boolean>;
  public running: Record<string, boolean> = {};
  public valid?: TarifDocument[];

  constructor(
    public taction: TarifLifecycleActionService,
    public tds: TarifDocumentService,
    private _msgs: DataMessageService,
    private router: Router,
  ) {}

  public async uploaded($event: UploadResult<TarifDocument, any>) {
    // FIXME: remove this any, add proper type
    this._msgs.success('Document uploadé avec succès');
    this.valid = await firstValueFrom(
      this.tds.list({
        tarif: this.tarif.id,
        status: TARIFDOCUMENT_STATUSES.VALID,
        dbsync: 1,
      }),
    );
    if (this.valid.length) {
      // this.next.emit();
    }
  }

  public async action(action: TarifLifecycleAction) {
    if (action.action === 'nav') {
      await lifecycleNav(action.dest, this.menu, this.router, this.tarif, this.tariftype);
    } else {
      this.running[action.action] = true;
      const res = await this.taction.action(this.tarif, action);
      this.reload$.next(true);
      this.running[action.action] = false;
    }
  }
}
