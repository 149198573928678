import { OfferNotificationDestinationBase } from './offer-notification-destination.base';
import { detailsField } from '@solidev/data';
import { Resto } from '../../structures/resto/resto';
import { User } from '../../users/user/user';
import { Group } from '../../users/group/group';
import { Contact } from '../../users/contact/contact';
import { Storage } from '../../structures/storage/storage';
import { Client } from '../../structures/client/client';

export class OfferNotificationDestination extends OfferNotificationDestinationBase {
  static override readonly __name: string = 'OfferNotificationDestination';

  @detailsField({
    name: 'storage_details',
    description: 'Dépôt',
    model: Storage,
    readonly: true,
    priority: 700,
  })
  public storage_details?: Storage;

  @detailsField({
    name: 'client_details',
    description: 'Client',
    model: Client,
    readonly: true,
    priority: 700,
  })
  public client_details?: Client;

  @detailsField({
    name: 'resto_details',
    description: 'Restaurant',
    model: Resto,
    readonly: true,
    priority: 500,
  })
  public resto_details?: Resto;

  @detailsField({
    name: 'user_details',
    description: 'Utilisateur',
    model: User,
    readonly: true,
    priority: 800,
  })
  public user_details?: User;

  @detailsField({
    name: 'group_details',
    description: 'Groupe',
    model: Group,
    readonly: true,
    priority: 810,
  })
  public group_details?: Group;

  @detailsField({
    name: 'contact_details',
    description: 'Contact',
    model: Contact,
    readonly: true,
    priority: 820,
  })
  public contact_details?: Contact;
}
