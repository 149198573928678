import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Offertype } from '../offertype';
import { OffertypeService } from '../offertype.service';
import { FiltersParams, Link, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../../../structures/client/client';
import { RruleDisplayComponent } from '../../../../components/utils/rrule-display/rrule-display.component';
import { Tariftype } from '../../../tarifs/tariftype/tariftype';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';
import { ClientDisplayComponent } from '../../../structures/client/client-display/client-display.component';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { User } from '../../../users/user/user';

@Component({
  selector: 'lvadg-offertype-list',
  standalone: true,
  templateUrl: './offertype-list.component.pug',
  styleUrls: ['./offertype-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    PChoicePipe,
    TariftypeDisplayComponent,
    ClientDisplayComponent,
    RruleDisplayComponent,
    UserDisplayComponent,
  ],
})
export class OffertypeListComponent extends ModellistComponent<Offertype> {
  @Input() public client_detail_route?: Link<Client>;
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  @Input() public user_detail_route?: Link<User>;

  constructor(coll: OffertypeService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
