import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tarif } from '../../../tarif/tarif';
import { firstValueFrom, Subject } from 'rxjs';
import { TarifActionService } from '../../../tarif/tarif-action.service';
import { ArticleTarifColumns } from '../article-tarif-columns';
import { TarifService } from '../../../tarif/tarif.service';
import { GenericregionBase } from '../../../../locations/genericregion/genericregion.base';
import { TarifImportCustomPricesComponent } from '../../../tarif/tarif-import/tarif-import-custom-prices/tarif-import-custom-prices.component';
import { TarifImportCustomArticlesComponent } from '../../../tarif/tarif-import/tarif-import-custom-articles/tarif-import-custom-articles.component';

@Component({
  selector: 'lvadg-article-tarif-region-column-operations',
  standalone: true,
  imports: [CommonModule, TarifImportCustomPricesComponent, TarifImportCustomArticlesComponent],
  templateUrl: './article-tarif-region-column-operations.component.pug',
  styleUrls: ['./article-tarif-region-column-operations.component.sass'],
})
export class ArticleTarifRegionColumnOperationsComponent {
  @Input() public columns!: ArticleTarifColumns;
  @Input() public tarif!: Tarif;
  @Input() public region!: GenericregionBase;
  @Input() public reload?: Subject<void | boolean>;

  @Output() public closed = new EventEmitter<void>();

  public importingPrices = false;
  public validatingRegion = false;
  public importRegionPrices = false;
  public checkArticles = false;

  constructor(
    private _tas: TarifActionService,
    private _tarifs: TarifService,
  ) {}

  public async importPrices() {
    this.importingPrices = true;
    const res = await this._tas.action(this.tarif, {
      action: 'region_import_items',
      region: this.region.id,
    });
    if (res.success) {
      this.reload?.next(true);
      this.tarif = await firstValueFrom(this._tarifs.fetch(this.tarif.id));
      this.columns.updateRegionStatus(this.tarif.tarifregionstatus_details);
    }
    this.importingPrices = false;
    this.closed.emit();
  }

  public async validateRegion() {
    this.validatingRegion = true;
    const res = await this._tas.action(this.tarif, {
      action: 'region_validate_items',
      region: this.region.id,
    });
    if (res.success) {
      this.reload?.next(true);
      this.tarif = await firstValueFrom(this._tarifs.fetch(this.tarif.id));
      this.columns.updateRegionStatus(this.tarif.tarifregionstatus_details);
    }
    this.validatingRegion = false;
    this.closed.emit();
  }
}
