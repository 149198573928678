import { Component } from '@angular/core';
import { OrderprocessarticleDetailViewData } from './orderprocessarticle-detail-view.component.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Orderprocessarticle } from '../../../../models/orders/orderprocessarticle/orderprocessarticle';
import { OrderprocessarticleManageComponent } from '../../../../models/orders/orderprocessarticle/orderprocessarticle-manage/orderprocessarticle-manage.component';

@Component({
  selector: 'lvadg-orderprocessarticle-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, OrderprocessarticleManageComponent],
  templateUrl: './orderprocessarticle-detail-view.component.pug',
  styleUrls: ['./orderprocessarticle-detail-view.component.sass'],
})
export class OrderprocessarticleDetailViewComponent extends BaseDetailViewComponent<
  OrderprocessarticleDetailViewData,
  Orderprocessarticle
> {
  public override setRouteData(data: OrderprocessarticleDetailViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
