import {
  charField,
  DataModel,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from '@solidev/data';

export class GeocommuneBase extends DataModel {
  static override readonly __name: string = 'GeocommuneBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @foreignKeyField({
    name: 'country',
    description: 'country',
    related: 'GeoCountry',
  })
  public country!: number;

  @charField({ name: 'insee', description: 'insee', maxLength: 20 })
  public insee!: string;

  @charField({ name: 'name', description: 'name', maxLength: 200 })
  public name!: string;

  @charField({ name: 'postcode', description: 'postcode', maxLength: 200 })
  public postcode!: string;

  @charField({ name: 'postname', description: 'postname', maxLength: 200 })
  public postname!: string;

  @charField({ name: 'altname', description: 'altname', maxLength: 200 })
  public altname!: string;

  @detailsField({ name: 'position', description: 'position' })
  public position!: any;

  @foreignKeyField({
    name: 'departement',
    description: 'departement',
    related: 'GeoDepartement',
  })
  public departement!: number;

  @integerField({ name: 'orig_id', description: 'orig id' })
  public orig_id!: number;

  public get _display(): string {
    return `${this.name} (${this.postcode})`;
  }
}
