import { map, Observable } from 'rxjs';
import { slugify } from '@solidev/data';

export const blobDownload = (
  qry: Observable<Blob>,
  name: string,
  ext: string
) => {
  const a: HTMLAnchorElement = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const createFileFromBlob = (file: Blob) => {
    a.download = slugify(name) + (ext || '.xlsx');
    a.href = window.URL.createObjectURL(file);
    a.click();
    return true;
  };
  return qry.pipe(map((b) => createFileFromBlob(b)));
};
