
<ng-container *ngIf="mode==='icon'">
  <ng-template #popContent>
    <div class="msgpopup" *ngIf="edit">
      <button class="btn btn-close float-end" (click)="p.close(); edit=false;"></button>
      <dl>
        <data-dispedit [model]="model" [field]="field" mode="dd" (changed)="onUpdated()" [edit]="edit" help="ctrl+entrée pour valider">Editer le message</data-dispedit>
      </dl>
    </div>
    <div class="msgpopup" *ngIf="!edit &amp;&amp; note">
      <div>{{note}}</div>
    </div>
  </ng-template><i class="bi bi-sticky p-1" [class.text-warning]="note" [ngbPopover]="popContent" [autoClose]="false" triggers="manual" #p="ngbPopover" (click)="onClick(p)" (mouseenter)="onEnter(p)" (mouseleave)="onLeave(p)" role="button"></i>
</ng-container>
<ng-container *ngIf="mode==='text'">
  <ng-template #popContent>
    <div class="msgpopup" *ngIf="edit">
      <button class="btn btn-close float-end" (click)="p.close(); edit=false;"></button>
      <dl>
        <data-dispedit [model]="model" [field]="field" mode="dd" (changed)="onUpdated()" [edit]="edit" help="ctrl+entrée pour valider">Editer le message</data-dispedit>
      </dl>
    </div>
    <div class="msgpopup" *ngIf="!edit &amp;&amp; note">
      <div>{{note}}</div>
    </div>
  </ng-template><span [class.text-info]="note" [ngbPopover]="popContent" [autoClose]="false" triggers="manual" #p="ngbPopover" (click)="onClick(p, true)" role="button"><i class="bi bi-sticky p-1"></i>{{note}}</span>
</ng-container>