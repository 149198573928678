import { BaseRouteParams, RouteConfigItem } from '@solidev/data';
import { Origin } from '../../../../../models/catalog/meta/origin/origin';

export interface OriginDetailViewParams extends BaseRouteParams {
  list_route: RouteConfigItem;
  article_detail_route: RouteConfigItem;
}

export interface OriginDetailViewData extends OriginDetailViewParams {
  origin: Origin;
}
