
<div class="content">
  <div class="container">
    <div class="comments">
      <h3>Comments</h3>
      <ul *ngFor="let comment of fcomment|async ">
        <li>
          <p>{{ comment.description}}</p>
          <cite>&#64;<b>{{comment.user_details.last_name}} {{comment.user_details.first_name}}</b>, {{comment.created | date}}</cite>
        </li>
      </ul>
    </div>
    <form class="form-check-inline" [formGroup]="createForm" (submit)="save()">
      <div class="input-group mb-2 mr-sm-2">
        <div class="input-group-prepend input-group-text">
          <div (click)="toggleEmojiPicker()">🙂</div>
          <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" title="Choisissez votre emoji…" (emojiClick)="addEmoji($event)"></emoji-mart>
        </div>
        <div class="form-control">
          <textarea class="form-control" type="text" formControlName="description" aria-labelledby="description" #description (focus)="onFocus()"></textarea>
          <button class="btn btn-primary comment-submit" value="submit">Envoyer</button>
        </div>
      </div>
    </form>
  </div>
</div>