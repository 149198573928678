import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Group } from '../group';
import { GroupService } from '../group.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lvadg-group-create',
  standalone: true,
  imports: [CommonModule, DispeditComponent, ReactiveFormsModule],
  templateUrl: './group-create.component.pug',
  styleUrls: ['./group-create.component.sass'],
})
export class GroupCreateComponent implements OnInit {
  @Output() public created = new EventEmitter<Group>();
  @Output() public cancelled = new EventEmitter<void>();
  public form = new FormGroup({});
  public group = new Group();

  constructor(private _gps: GroupService) {}

  ngOnInit(): void {}

  public async create() {
    const group = this._gps.create({ ...this.form.value });
    await firstValueFrom(group.save({ updateModel: true }));
    this.created.emit(group);
  }
}
