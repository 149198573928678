
<div class="form-group mx-1" *ngIf="provider?.enabled">
  <label [for]="chid" [class.text-success]="provider.current">{{provider.description}}
    <div class="ms-1 small devonly">
      <div class="ms-1" *ngFor="let c of provider.choices"><span class="badge ms-1" [class.bg-danger]="!c.enabled" [class.bg-success]="c.enabled">enb</span><span class="badge ms-1" [class.bg-danger]="!c.group" [class.bg-success]="c.group">grp</span><span class="badge ms-1" [class.bg-danger]="!c.generic" [class.bg-success]="c.generic">gen</span><span class="badge ms-1" [class.bg-danger]="!c.default" [class.bg-success]="c.default">def</span></div>
    </div>
  </label>
  <select class="form-select form-select-sm nobsvalid" [formControl]="fc" [id]="chid">
    <ng-container *ngFor="let c of provider.choices">
      <option *ngIf="c.enabled" [ngValue]="c">{{c.description}}</option>
    </ng-container>
  </select>
</div>