import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Folder } from "../folder";
import { DispeditComponent, FlagsComponent } from "@solidev/data";
import { FolderService } from "../folder.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "lvadg-folder-manage",
  standalone: true,
  imports: [CommonModule, DispeditComponent, FlagsComponent],
  templateUrl: "./folder-manage.component.pug",
  styleUrls: ["./folder-manage.component.sass"],
})
export class FolderManageComponent {
  @Input() public folder?: Folder;
  @Output() public statusChanged = new EventEmitter<Folder>();
  @Output() public folderMoved = new EventEmitter<Folder>();
  @Output() public detailsChanged = new EventEmitter<Folder>();

  constructor(public folders$: FolderService) {}

  public async refresh() {
    if (this.folder) {
      this.folder.fromJson(
        await firstValueFrom(this.folders$.fetch(this.folder.id)),
      );
    }
  }
}
