import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { map, Observable, Subject } from 'rxjs';
import { Client } from '../../../../../models/structures/client/client';
import { CLIENT_ROUTES } from '../menu';
import { ArticleTarifTemplate } from '../../../../../models/tarifs/article-tarif-template/article-tarif-template';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { Article } from '../../../../../models/catalog/article/article';
import { Earticle } from '../../../../../models/catalog/earticle/earticle';
import { ArticleTarifTemplateListComponent } from '../../../../../models/tarifs/article-tarif-template/article-tarif-template-list/article-tarif-template-list.component';
import { ClientNavComponent } from '../_nav/client-nav.component';

export interface ClientAttsViewParams extends BaseRouteParams {
  c_menu: CLIENT_ROUTES;
  att_detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  article_detail_route?: RouteConfigItem;
  earticle_detail_route?: RouteConfigItem;
}

export interface ClientAttsViewData extends ClientAttsViewParams {
  client: Client;
}

@Component({
  selector: 'lvadg-client-atts-view',
  standalone: true,
  imports: [CommonModule, HeaderActionComponent, IconComponent, ArticleTarifTemplateListComponent, ClientNavComponent],
  templateUrl: './client-atts-view.component.pug',
  styleUrls: ['./client-atts-view.component.sass'],
})
export class ClientAttsViewComponent extends BaseDetailViewComponent<ClientAttsViewData, Client> {
  public clientFilter$!: Observable<FilterDefaults>;
  public reload$ = new Subject<void | boolean>();
  public att_detail_route?: Link<ArticleTarifTemplate>;
  public default_fields: string[] = [];
  public tariftype_detail_route?: Link<Tariftype>;
  public article_detail_route?: Link<Article>;
  public earticle_detail_route?: Link<Earticle>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: ClientAttsViewData) {
    super.setRouteData(data);
    if (data.att_detail_route) {
      this.att_detail_route = new Link<ArticleTarifTemplate>(data.att_detail_route, data, this._router);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link<Tariftype>(data.tariftype_detail_route, data, this._router);
    }
    if (data.article_detail_route) {
      this.article_detail_route = new Link<Article>(data.article_detail_route, data, this._router);
    }
    if (data.earticle_detail_route) {
      this.earticle_detail_route = new Link<Earticle>(data.earticle_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.clientFilter$ = this.data$.pipe(map((d) => ({ client: d.client.id })));
  }
}
