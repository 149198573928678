import { Component } from '@angular/core';

import { OrderprocessListViewData } from './orderprocess-list-view.component.params';
import { BehaviorSubject } from 'rxjs';
import { Link } from '@solidev/data';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { TariftypeBase } from '../../../../models/tarifs/tariftype/tariftype.base';
import { Orderprocess } from '../../../../models/orders/orderprocess/orderprocess';
import { OrderprocessListComponent } from '../../../../models/orders/orderprocess/orderprocess-list/orderprocess-list.component';
import { OrderprocessCreateComponent } from '../../../../models/orders/orderprocess/orderprocess-create/orderprocess-create.component';
import {HeaderActionComponent} from "../../../../components/utils/header-action/header-action.component";

@Component({
  selector: 'lvadg-orderprocess-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, OrderprocessListComponent, OrderprocessCreateComponent, HeaderActionComponent],
  templateUrl: './orderprocess-list-view.component.pug',
  styleUrls: ['./orderprocess-list-view.component.sass'],
})
export class OrderprocessListViewComponent extends BaseListViewComponent<OrderprocessListViewData, Orderprocess> {
  public tariftype_detail_route?: Link<TariftypeBase>;
  public default_fields = [];
  public create: boolean = false;
  public reload$: BehaviorSubject<void | boolean> = new BehaviorSubject<void | boolean>(undefined);

  public override setRouteData(data: OrderprocessListViewData) {
    super.setRouteData(data);
    this.tariftype_detail_route = new Link(data.tariftype_detail_route, data);
  }
}
