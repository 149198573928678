import {
  charField,
  DataModel,
  datetimeField,
  detailsField,
  foreignKeyField,
  integerField,
  manyToManyField,
  primaryField,
} from "@solidev/data";

export enum OFFER_TYPES {
  PRODUCT_LOCAL = "LP",
  PRODUCT_GLOBAL = "GP",
  ARTICLE_PROVIDER = "AP",
  ARTICLE_CATALOG = "AC",
  ARTICLE_TARIF = "AT",
}

export enum OFFER_STATUSES {
  PREPARATION = "PRP",
  PUBLISHED = "CUR",
  PUBLISHED_SENT = "SNT",
  ARCHIVED = "ARC",
  ARCHIVED_NOT_PUBLISHED = "ARN",
}

export enum OFFER_USER_LEVEL {
  sadmin = "sadmin",
  admin = "admin",
  vivalya = "vivalya",
  o_author = "o_author",
  o_write = "o_write",
  o_read = "o_read",
  ot_admin = "ot_admin",
  ot_spilote = "ot_spilote",
  ot_read = "ot_read",
  ots_read = "ots_read",
  ots_write = "ots_write",
  anon = "anon",
}

export enum OFFER_ZONES {
  FL = "FL",
  SEA = "SEA",
  "45G" = "45G",
}

export class OfferBase extends DataModel {
  static override readonly __name: string = "OfferBase";

  @primaryField({
    name: "id",
    description: "ID",
    priority: 1000,
  })
  public override id!: number;

  @detailsField({
    name: "flags",
    description: "Flags",
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: "offertype",
    description: "ID Modèle",
    related: "OfferType",
    priority: -1,
  })
  public offertype!: number;

  @foreignKeyField({
    name: "offertypestorage",
    description: "ID Modèle par dépôt",
    related: "OfferTypeStorage",
    priority: -1,
  })
  public offertypestorage!: number;

  @foreignKeyField({
    name: "storage",
    description: "ID Dépôt",
    related: "Storage",
    priority: -1,
  })
  public storage!: number;

  @charField({
    name: "type",
    description: "Type",
    defaultValue: OFFER_TYPES.PRODUCT_LOCAL,
    maxLength: 2,
    choices: [
      { value: OFFER_TYPES.PRODUCT_LOCAL, desc: "Offre locale" },
      { value: OFFER_TYPES.PRODUCT_GLOBAL, desc: "Offre nationale" },
      {
        value: OFFER_TYPES.ARTICLE_PROVIDER,
        desc: "Article fournisseur",
      },
      { value: OFFER_TYPES.ARTICLE_CATALOG, desc: "Article catalogue" },
      { value: OFFER_TYPES.ARTICLE_TARIF, desc: "Article tarif" },
    ],
    priority: 900,
  })
  public type: OFFER_TYPES = OFFER_TYPES.PRODUCT_LOCAL;

  @charField({
    name: "zone",
    description: "Zone",
    defaultValue: OFFER_ZONES.FL,
    maxLength: 3,
    choices: [
      { value: OFFER_ZONES.FL, desc: "F&L" },
      {
        value: OFFER_ZONES.SEA,
        desc: "Marée",
      },
      { value: OFFER_ZONES["45G"], desc: "4/5eme gamme" },
    ],
    priority: 700,
  })
  public zone: OFFER_ZONES = OFFER_ZONES.FL;

  @foreignKeyField({
    name: "author",
    description: "ID Auteur",
    related: "User",
    priority: -1,
  })
  public author!: number;

  @charField({
    name: "title",
    description: "Titre",
    maxLength: 255,
    priority: 800,
  })
  public title!: string;

  @foreignKeyField({
    name: "client",
    description: "ID Client",
    related: "Client",
    priority: -1,
  })
  public client!: number;

  @datetimeField({
    name: "datestart",
    description: "Date de début",
    priority: 700,
  })
  public datestart!: Date;

  @datetimeField({
    name: "dateend",
    description: "Date de fin",
    priority: 600,
  })
  public dateend!: Date;

  @datetimeField({
    name: "datevalidation",
    description: "Date de validation",
    priority: 500,
  })
  public datevalidation!: Date;

  @foreignKeyField({
    name: "parent",
    description: "ID Parent",
    related: "Offer",
    priority: -1,
  })
  public parent!: number;

  @foreignKeyField({
    name: "layout",
    description: "ID Impression",
    related: "Print",
    priority: -1,
  })
  public layout!: number;

  @charField({
    name: "status",
    description: "État",
    defaultValue: OFFER_STATUSES.PREPARATION,
    maxLength: 4,
    choices: [
      { value: OFFER_STATUSES.PREPARATION, desc: "En préparation" },
      {
        value: OFFER_STATUSES.PUBLISHED,
        desc: "Publiée sur le site",
      },
      { value: OFFER_STATUSES.PUBLISHED_SENT, desc: "Publiée et envoyée" },
      { value: OFFER_STATUSES.ARCHIVED, desc: "Archivée (publiée)" },
      {
        value: OFFER_STATUSES.ARCHIVED_NOT_PUBLISHED,
        desc: "Archivée (non publiée)",
      },
    ],
    priority: 300,
  })
  public status: OFFER_STATUSES = OFFER_STATUSES.PREPARATION;

  @integerField({
    name: "orig_id",
    description: "orig id",
    priority: -10,
  })
  public orig_id!: number;

  @manyToManyField({
    name: "restos",
    description: "IDs restos",
    defaultValue: [],
    related: "Resto",
    priority: -1,
  })
  public restos: number[] = [];
  @detailsField({
    name: "user_level",
    description: "Niveau utilisateur",
    defaultValue: [OFFER_USER_LEVEL.anon],
    priority: 400,
  })
  public user_level: OFFER_USER_LEVEL[] = [OFFER_USER_LEVEL.anon];

  public get _display(): string {
    return this.title;
  }

  public have_level(...level: OFFER_USER_LEVEL[]): boolean {
    return this.user_level.some((l) => level.includes(l));
  }
}
