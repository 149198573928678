import { Component, Input } from '@angular/core';
import { GenericregionDetailViewData } from './genericregion-detail-view.params';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { GenericregionManageComponent } from '../../../../models/locations/genericregion/genericregion-manage/genericregion-manage.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { Genericregion } from '../../../../models/locations/genericregion/genericregion';
import { Link } from '@solidev/data';

@Component({
  selector: 'lvadg-genericregion-detail-view',
  standalone: true,
  imports: [...BASE_DETAIL_VIEW_IMPORTS, GenericregionManageComponent],
  templateUrl: './genericregion-detail-view.component.pug',
  styleUrls: ['./genericregion-detail-view.component.sass'],
})
export class GenericregionDetailViewComponent extends BaseDetailViewComponent<
  GenericregionDetailViewData,
  Genericregion
> {
  @Input() public list_route?: Link<Genericregion>;

  public override setRouteData(data: GenericregionDetailViewData) {
    super.setRouteData(data);
    this.list_route = new Link<Genericregion>(data.list_route, data, this._router);
  }

  deleted() {
    this.list_route?.navigate();
  }
}
