import { Component, EventEmitter, inject, input, OnInit, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offer } from '../../offer/offer';
import { OfferNotificationDestination } from '../offer-notification-destination';
import { OffertypeStorage } from '../../offertype-storage/offertype-storage';
import { Offertype } from '../../offertype/offertype';
import { OfferActionService, OfferAddDestination } from '../../offer/offer-action.service';
import { TodoComponent } from '../../../../components/utils/todo/todo.component';
import {
  OFFERNOTIFICATIONDESTINATION_TARGET,
  OFFERNOTIFICATIONDESTINATION_TYPES,
} from '../offer-notification-destination.base';
import { Resto } from '../../../structures/resto/resto';
import { OFFER_USER_LEVEL } from '../../offer/offer.base';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { Contact } from '../../../users/contact/contact';
import { ContactDisplayComponent } from '../../../users/contact/contact-display/contact-display.component';
import { ContactService } from '../../../users/contact/contact.service';
import { firstValueFrom } from 'rxjs';
import { CONTACT_TYPES } from '../../../users/contact/contact.base';
import { ContactCreateComponent } from '../../../users/contact/contact-create/contact-create.component';
import { ModelListAction } from '../../../../includes/modellist/modellist.component';
import { FilterDefaults } from '@solidev/data';
import { RestoListComponent } from '../../../structures/resto/resto-list/resto-list.component';
import { filterNullish } from '../../../../utils';

@Component({
  selector: 'lvadg-offer-notification-destination-create',
  standalone: true,
  imports: [
    CommonModule,
    TodoComponent,
    ReactiveFormsModule,
    IconComponent,
    ContactDisplayComponent,
    ContactCreateComponent,
    RestoListComponent,
  ],
  templateUrl: './offer-notification-destination-create.component.pug',
  styleUrls: ['./offer-notification-destination-create.component.sass'],
})
export class OfferNotificationDestinationCreateComponent implements OnInit {
  // Inputs
  public readonly offer = input<Offer>();
  public readonly offertypestorage = input<OffertypeStorage>();
  public readonly offertype = input<Offertype>();
  public readonly resto = input<Resto | null>();
  public readonly current_dest = input<OfferNotificationDestination | null>();

  // Current statuses and values.
  public readonly is_admin = signal(false);
  public readonly dest_type = signal<OFFERNOTIFICATIONDESTINATION_TYPES | null>(null);
  public readonly dest_target = signal<OFFERNOTIFICATIONDESTINATION_TARGET | null>(null);
  public readonly dest_resto = signal<Resto | null>(null);

  // Contact search form and results
  public contactForm = new FormGroup({
    search: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });
  public readonly contactSearchResult = signal<Contact[] | null>(null);

  // Resto list parameters
  public resto_add_default_fields: string[] = ['cvva', 'name', 'location_details', 'status', 'actions'];
  public restoAddFilter: FilterDefaults = {};

  // Constants
  public readonly ONT = OFFERNOTIFICATIONDESTINATION_TYPES;
  public readonly TGT = OFFERNOTIFICATIONDESTINATION_TARGET;
  public readonly CT = CONTACT_TYPES;
  // Outputs
  @Output() public created = new EventEmitter<OfferNotificationDestination>();
  @Output() public canceled = new EventEmitter<void>();
  // Services
  private readonly _oaction = inject(OfferActionService);
  private readonly _contacts = inject(ContactService);

  constructor() {}

  ngOnInit(): void {
    // Set default values
    if (!this.offer() && (this.offertypestorage() || this.offertype())) {
      console.error('TODO: ADMIN MODE, later');
      this.is_admin.set(true);
    } else if (this.offer()) {
      this.restoAddFilter = { offer: this.offer()!.id };
      if (this.offer()?.have_level(OFFER_USER_LEVEL.admin)) {
        console.error('TODO: ADMIN MODE, later');
        this.is_admin.set(true);
      } else {
        // User mode
        this.is_admin.set(false);
        this.dest_type.set(OFFERNOTIFICATIONDESTINATION_TYPES.PUBLISH);
        if (this.resto()) {
          this.dest_target.set(OFFERNOTIFICATIONDESTINATION_TARGET.RESTO);
          this.dest_resto.set(this.resto() || null);
        }
      }
    }
  }

  /**
   * Search for contacts using the typed search string.
   * Updates the contactSearchResult signal.
   */
  async searchContact() {
    this.contactSearchResult.set(null);
    this.contactSearchResult.set(
      await firstValueFrom(
        this._contacts.list({
          search: this.contactForm.value.search,
          type: CONTACT_TYPES.OFFER,
        }),
      ),
    );
  }

  /**
   * Select a contact from the search results and create the destination.
   * @param c The contact to select.
   */
  public async selectContact(c: Contact) {
    if (!this.offer()) {
      return;
    }
    const res = await this._oaction.action<OfferNotificationDestination>(
      this.offer()!,
      filterNullish({
        action: 'add_offer_destination',
        target: this.dest_target() || OFFERNOTIFICATIONDESTINATION_TARGET.GENERIC,
        type: this.dest_type() || OFFERNOTIFICATIONDESTINATION_TYPES.PUBLISH,
        contact_id: c.id,
        resto_id: this.dest_resto()?.id,
      }) as OfferAddDestination,
    );
    if (res.success) {
      this.created.emit(res.data);
    }
  }

  /**
   * Create a new contact and create the destination.
   * @param c The contact to create.
   */
  public async createContact(c: Contact) {
    if (!this.offer()) {
      return;
    }
    const res = await this._oaction.action<OfferNotificationDestination>(
      this.offer()!,
      filterNullish({
        action: 'add_offer_destination',
        target: this.dest_target() || OFFERNOTIFICATIONDESTINATION_TARGET.GENERIC,
        type: this.dest_type() || OFFERNOTIFICATIONDESTINATION_TYPES.PUBLISH,
        new_contact: c.toJson(),
        resto_id: this.dest_resto()?.id,
      }) as OfferAddDestination,
    );
    if (res.success) {
      this.created.emit(res.data);
    }
  }

  /**
   * Select the resto if action is select from the resto list.
   * @param $event
   */
  public async onRestoAddAction($event: ModelListAction<Resto>) {
    if ($event.action === 'select' && $event.model) {
      this.dest_resto.set($event.model);
    }
  }
}
