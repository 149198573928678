import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lvadg-mimetype-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mimetype-icon.component.pug',
  styleUrls: ['./mimetype-icon.component.sass'],
})
export class MimetypeIconComponent {
  @Input() public mimetype!: string;
  @Input() public size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'md';

  public get icon(): string {
    // Get bootstrap icon class from common mimetypes
    switch (this.mimetype) {
      case 'application/pdf':
        return 'bi-file-earmark-pdf';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return 'bi-file-earmark-word';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return 'bi-file-earmark-spreadsheet';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return 'bi-file-earmark-ppt';
      case 'application/zip':
      case 'application/x-rar-compressed':
      case 'application/x-7z-compressed':
        return 'bi-file-earmark-zip';
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/svg+xml':
        return 'bi-file-earmark-image';
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/wav':
        return 'bi-file-earmark-music';
      case 'video/mp4':
      case 'video/ogg':
      case 'video/webm':
        return 'bi-file-earmark-play';
      default:
        return 'bi-file-earmark';
    }
  }
}
