
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { Usage } from "./usage";

@Injectable({
  providedIn: 'root'
})
export class UsageService extends Collection<Usage> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/usages", Usage);
  }
}
