import {
  Component,
  inject,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  BaseRouteParams,
  FilterDefaults,
  Link,
  RouteConfigItem,
} from "@solidev/data";
import { PRODUCER_ROUTES } from "../menu";
import { Producer } from "../../../../../models/structures/producer/producer";
import { BaseDetailViewComponent } from "../../../../../components/baseview/basedetailview.component";
import { CommonModule } from "@angular/common";
import { HeaderActionComponent } from "../../../../../components/utils/header-action/header-action.component";
import { ProducerNavComponent } from "../_nav/producer-nav.component";
import { ProductListComponent } from "../../../../../models/catalog/product/product-list/product-list.component";
import { map, Observable, Subject } from "rxjs";
import { Product } from "../../../../../models/catalog/product/product";
import { ProductCreateComponent } from "../../../../../models/catalog/product/product-create/product-create.component";
import { Storage } from "../../../../../models/structures/storage/storage";
import { PRODUCT_PRINT_SETTINGS } from "../../../../../models/catalog/product/product.print";
import { LOCAL_FILTERS } from "../../../../../models/catalog/product/product.base";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { PRODUCER_USER_LEVELS } from "../../../../../models/structures/producer/producer.base";
import { IconComponent } from "../../../../../components/utils/icon/icon.component";
import { PrintContext } from "../../../../../models/layouts/print-settings";
import { ADMIN_GROUPS } from "../../../../../constants";
import { AuthService } from "../../../../../models/users/auth.service";

export interface ProducerProductsViewParams extends BaseRouteParams {
  pr_menu: PRODUCER_ROUTES;
  product_detail_route?: RouteConfigItem;
  local: LOCAL_FILTERS;
}

export interface ProducerProductsViewData extends ProducerProductsViewParams {
  producer: Producer;
  storage?: Storage;
}

@Component({
  selector: "lvadg-producer-products-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    ProducerNavComponent,
    ProductCreateComponent,
    ProductListComponent,
    IconComponent,
  ],
  templateUrl: "./producer-products-view.component.pug",
  styleUrl: "./producer-products-view.component.sass",
})
export class ProducerProductsViewComponent extends BaseDetailViewComponent<
  ProducerProductsViewData,
  Producer
> {
  public producerFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public product_detail_route?: Link<Product>;
  public default_fields: string[] = [
    "name",
    "labels_details",
    "seasons",
    "storage_relations_details",
    "actions",
  ];
  public print_settings = PRODUCT_PRINT_SETTINGS;
  public print_context: PrintContext = {};
  public canCreate = signal<boolean>(false);
  public ofc$ = inject(NgbOffcanvas);
  public createOfcRef?: NgbOffcanvasRef;
  public user = inject(AuthService).currentUser;

  @ViewChild("createOfcSlot", { read: TemplateRef })
  createOfcSlot!: TemplateRef<unknown>;

  public override postNgOnInit() {
    super.postNgOnInit();
    this.producerFilter$ = this.data$.pipe(
      map((d) => ({
        local: d.local,
        producer: d.producer.id,
      })),
    );
  }

  public override setRouteData(data: ProducerProductsViewData) {
    super.setRouteData(data);
    if (data.product_detail_route) {
      this.product_detail_route = new Link<Product>(
        data.product_detail_route,
        data,
        this._router,
      );
    }
    if (data.producer) {
      this.print_context.producer = data.producer;
      // Creation allowed for user with write level, superuser and producer editors
      this.canCreate.set(
        !!(
          data.producer.have_level(PRODUCER_USER_LEVELS.WRITE) ||
          (this.user &&
            (this.user.is_superuser ||
              this.user.have_group(ADMIN_GROUPS.producers_editor)))
        ),
      );
    }
    if (data.local) {
      this.print_context.local = data.local;
    }
  }

  public create() {
    if (this.createOfcRef) {
      this.createOfcRef.close();
      delete this.createOfcRef;
    } else {
      this.createOfcRef = this.ofc$.open(this.createOfcSlot, {
        position: "bottom",
        panelClass: "h-75",
      });
    }
  }
}
