import { Component } from '@angular/core';
import { GeocountryListViewData } from './geocountry-list-view.params';
import { BASE_LIST_VIEW_IMPORTS } from '../../../../components/baseview/baselistview.imports';
import { GeocountryListComponent } from '../../../../models/locations/geocountry/geocountry-list/geocountry-list.component';
import { BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { Geocountry } from '../../../../models/locations/geocountry/geocountry';

@Component({
  selector: 'lvadg-geocountry-list-view',
  standalone: true,
  imports: [...BASE_LIST_VIEW_IMPORTS, GeocountryListComponent],
  templateUrl: './geocountry-list-view.component.pug',
  styleUrls: ['./geocountry-list-view.component.sass'],
})
export class GeocountryListViewComponent extends BaseListViewComponent<GeocountryListViewData, Geocountry> {
  public override setRouteData(data: GeocountryListViewData) {
    super.setRouteData(data);
    // this.some_route = new Link<any>(data.some_route, this);
  }
}
