import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, FilterDefaults, Link, RouteConfigItem, TabMemoryService } from '@solidev/data';
import { BaseDetailViewComponent } from '../../../../../components/baseview/basedetailview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Tariftype } from '../../../../../models/tarifs/tariftype/tariftype';
import { map, Observable, Subject } from 'rxjs';
import { UserBase } from '../../../../../models/users/user/user.base';
import { TariftypePermissionCreateComponent } from '../../../../../models/tarifs/tariftype-permission/tariftype-permission-create/tariftype-permission-create.component';
import { TariftypePermissionListComponent } from '../../../../../models/tarifs/tariftype-permission/tariftype-permission-list/tariftype-permission-list.component';
import { HeaderActionComponent } from '../../../../../components/utils/header-action/header-action.component';
import { GroupBase } from '../../../../../models/users/group/group.base';
import { IconComponent } from '../../../../../components/utils/icon/icon.component';
import { TARIFTYPE_ROUTES } from '../menu';
import { TariftypeDetailNavComponent } from '../nav/tariftype-detail-nav.component';

export interface TariftypePermissionsViewParams extends BaseRouteParams {
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  menu?: TARIFTYPE_ROUTES;
}

export interface TariftypePermissionsViewData extends TariftypePermissionsViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-permissions-view',
  standalone: true,
  imports: [
    CommonModule,
    TariftypePermissionCreateComponent,
    TariftypePermissionListComponent,
    HeaderActionComponent,
    IconComponent,
    TariftypeDetailNavComponent,
  ],
  templateUrl: './tariftype-permissions-view.component.pug',
  styleUrls: ['./tariftype-permissions-view.component.sass'],
})
export class TariftypePermissionsViewComponent extends BaseDetailViewComponent<
  TariftypePermissionsViewData,
  Tariftype
> {
  public createPermission = false;
  public tarifTypeFilter$!: Observable<FilterDefaults>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();

  public tperms_default_fields = [
    'id',
    'permission',
    'tariftype_details',
    'user_details',
    'group_details',
    'updated',
    'created',
    'actions',
  ];
  public user_detail_route?: Link<UserBase>;
  public group_detail_route?: Link<GroupBase>;

  constructor(_router: Router, _route: ActivatedRoute, _tms: TabMemoryService) {
    super(_router, _route, _tms);
  }

  public override setRouteData(data: TariftypePermissionsViewData) {
    super.setRouteData(data);
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.tarifTypeFilter$ = this.data$.pipe(
      map((d) => {
        return { tariftype: d.tariftype.id };
      }),
    );
  }
}
