import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../../includes/modellist/modellist.component';
import { Packaging } from '../packaging';
import { PackagingService } from '../packaging.service';
import { FiltersParams, ModelListService, ModelListTextFilter, PChoicePipe } from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lvadg-packaging-list',
  standalone: true,
  templateUrl: './packaging-list.component.pug',
  styleUrls: ['./packaging-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, PChoicePipe],
})
export class PackagingListComponent extends ModellistComponent<Packaging> {
  constructor(coll: PackagingService, list: ModelListService, ofc: NgbOffcanvas) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
