import { OffertypeStorageBase } from "./offertype-storage.base";
import { detailsField } from "@solidev/data";
import { Storage } from "../../structures/storage/storage";
import { Offertype } from "../offertype/offertype";
import { OffertypeStoragePermission } from "../offertype-storage-permission/offertype-storage-permission";
import { Tariftype } from "../../tarifs/tariftype/tariftype";

export class OffertypeStorage extends OffertypeStorageBase {
  static override readonly __name: string = "OffertypeStorage";

  /// Storage details (Storage)
  @detailsField({
    name: "storage_details",
    description: "Dépôt",
    model: Storage,
    priority: 850,
  })
  public storage_details?: Storage;

  /// Offertype details (Offertype)
  /// This field is always provided.
  @detailsField({
    name: "offertype_details",
    description: "Modèle d'offre",
    model: Offertype,
    priority: 900,
  })
  public offertype_details!: Offertype;
  /// Tariftypes details (Tariftype)
  @detailsField({
    name: "tariftypes_details",
    description: "Modèles de tarifs spécifiques",
    model: Tariftype,
    many: true,
    priority: 850,
  })
  public tariftypes_details: Tariftype[] = [];

  @detailsField({
    model: OffertypeStoragePermission,
    description: "Permissions",
    readonly: true,
    many: true,
    priority: 600,
  })
  public perms_details?: OffertypeStoragePermission[];

  @detailsField({
    name: "last_offer",
    description: "Dernière offre",
    readonly: true,
    priority: 800,
  })
  public last_offer?: Date;

  @detailsField({
    name: "offer_count",
    description: "Nombre d'offres",
    readonly: true,
    priority: 800,
  })
  public offer_count?: number;

  public get _display(): string {
    return `${this.storage_details?.name} ➔ ${this.offertype_details?.client_details?.name}`;
  }
}
