import { Component } from '@angular/core';
import { AuthService } from '../../../models/users/auth.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BaseRouteParams } from '@solidev/data';

export interface LogoutViewParams extends BaseRouteParams {}

export interface LogoutViewData extends LogoutViewParams {}

@Component({
  selector: 'lvadg-logout-view',
  templateUrl: './logout-view.component.pug',
  styleUrls: ['./logout-view.component.sass'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class LogoutViewComponent {
  constructor(public auth: AuthService) {}

  public logout() {
    this.auth.logout();
  }
}
