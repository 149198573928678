import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { OffertypeStorage } from '../offertype-storage';
import { OffertypeStorageService } from '../offertype-storage.service';
import {
  FiltersParams,
  Link,
  ModelListAutocompleteMultiFilter,
  ModelListFlagsFilter,
  ModelListService,
  ModelListTextFilter,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Tariftype } from '../../../tarifs/tariftype/tariftype';
import { Offertype } from '../../offertype/offertype';
import { Storage } from '../../../structures/storage/storage';
import { StorageDisplayComponent } from '../../../structures/storage/storage-display/storage-display.component';
import { OffertypeDisplayComponent } from '../../offertype/offertype-display/offertype-display.component';
import { TariftypeDisplayComponent } from '../../../tarifs/tariftype/tariftype-display/tariftype-display.component';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { User } from '../../../users/user/user';
import { FlagsDisplayComponent } from '../../../../components/utils/flags-display/flags-display.component';
import { OffertypeStoragePermission } from '../../offertype-storage-permission/offertype-storage-permission';
import { OffertypeStoragePermissionDisplayComponent } from '../../offertype-storage-permission/offertype-storage-permission-display/offertype-storage-permission-display.component';
import { Group } from '../../../users/group/group';
import { OffertypeService } from '../../offertype/offertype.service';
import { OffertypeActionService } from '../../offertype/offertype-action.service';
import { OffertypeStoragePermissionListmanageComponent } from '../../offertype-storage-permission/offertype-storage-permission-listmanage/offertype-storage-permission-listmanage.component';
import { OFFERTYPE_ALARM } from '../offertype-storage.base';
import { StorageService } from '../../../structures/storage/storage.service';

@Component({
  selector: 'lvadg-offertype-storage-list',
  standalone: true,
  templateUrl: './offertype-storage-list.component.pug',
  styleUrls: ['./offertype-storage-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    StorageDisplayComponent,
    OffertypeDisplayComponent,
    UserDisplayComponent,
    TariftypeDisplayComponent,
    FlagsDisplayComponent,
    SafeDeleteComponent,
    OffertypeStoragePermissionDisplayComponent,
    OffertypeStoragePermissionListmanageComponent,
  ],
})
export class OffertypeStorageListComponent extends ModellistComponent<OffertypeStorage> {
  @Input() public tariftype_detail_route?: Link<Tariftype>;
  @Input() public storage_detail_route?: Link<Storage>;
  @Input() public offertype_detail_route?: Link<Offertype>;
  @Input() public user_detail_route?: Link<User>;
  @Input() public group_detail_route?: Link<Group>;
  @Input() public ots_permission_detail_route?: Link<OffertypeStoragePermission>;
  public deleteFn = this._deleteFn.bind(this);

  constructor(
    coll: OffertypeStorageService,
    list: ModelListService,
    ofc: NgbOffcanvas,
    private _ots: OffertypeService,
    private _otact: OffertypeActionService,
    private _storages: StorageService,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search', 'storages'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListAutocompleteMultiFilter({
          field: 'storages',
          name: 'storages',
          label: 'Filtrer par dépot(s)',
          filter: { fields: ['id', 'name', 'cvva'].join(',') },
          collection: this._storages,
        }),
        new ModelListFlagsFilter({
          field: 'flags',
          name: 'flags',
          label: 'Filtrer par flag',
          collection: this.coll,
        }),
      ],
    };
  }

  override getRowClasses(row: OffertypeStorage): string[] {
    const cls = super.getRowClasses(row);
    // if (row.status === TARIFTYPE_STATUS.ARCHIVED) {
    //   cls.push('table-secondary');
    // }
    if (row.flags.includes(OFFERTYPE_ALARM.NO_CURRENT)) {
      cls.push('text-danger');
      return cls;
    }
    if (row.flags.includes(OFFERTYPE_ALARM.DATE_PUBLICATION)) {
      cls.push('text-warning');
      return cls;
    }
    if (row.flags.includes(OFFERTYPE_ALARM.DATE_VALIDATION)) {
      cls.push('text-warning');
      return cls;
    }
    if (row.flags.includes(OFFERTYPE_ALARM.DATE_PREPARATION)) {
      cls.push('text-warning');
      return cls;
    }

    return cls;
  }

  private async _deleteFn(model: OffertypeStorage): Promise<{ success: boolean }> {
    if (!model.offertype_details) {
      return { success: false };
    }
    model.offertype_details._collection = this._ots;
    const res = await this._otact.action(model.offertype_details, {
      action: 'remove_storage',
      otstorage: model.id,
    });
    return res;
  }
}
