import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistanceCacheService } from '../distance-cache.service';
import { filter, map, ReplaySubject, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'lvadg-distance-cache-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './distance-cache-display.component.pug',
  styleUrls: ['./distance-cache-display.component.sass'],
})
export class DistanceCacheDisplayComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public start!: [number, number];
  @Input() public end!: [number, number];
  @Input() public mode: 'pretty' | 'raw' = 'pretty';
  public value$ = new ReplaySubject<{
    mode: 'direct' | 'road';
    distance: number | null;
  }>(1);
  private found = false;
  private _subscriptions = new Subject<void>();

  constructor(private _dcache: DistanceCacheService) {}

  public ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  public ngOnInit() {
    // Comput direct distance in meters from start to end, using the haversine formula
    // https://en.wikipedia.org/wiki/Haversine_formula
    if (!this.start || !this.end) {
      return;
    }
    const dist = this._dcache.getDistance(this.start, this.end);

    if (dist) {
      this.value$.next({ mode: 'road', distance: dist });
      this.found = true;
    } else {
      const R = 6371e3; // metres
      const φ1 = (this.start[1] * Math.PI) / 180; // φ, λ in radians
      const φ2 = (this.end[1] * Math.PI) / 180;
      const Δφ = ((this.end[1] - this.start[1]) * Math.PI) / 180;
      const Δλ = ((this.end[0] - this.start[0]) * Math.PI) / 180;
      const direct = Math.round(
        R *
          2 *
          Math.atan2(
            Math.sqrt(
              Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2),
            ),
            Math.sqrt(1 - Math.sin(Δφ / 2) * Math.sin(Δφ / 2)),
          ),
      );
      this.value$.next({ mode: 'direct', distance: direct });
      this._dcache.results$
        .pipe(
          map(() => this._dcache.getDistance(this.start, this.end)),
          filter((d) => !!d),
          tap((d) => this.value$.next({ mode: 'road', distance: d })),
          takeUntil(this._subscriptions),
        )
        .subscribe();
    }
  }

  public ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }
}
