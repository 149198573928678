import { DataModel, datetimeField, detailsField, foreignKeyField, manyToManyField, primaryField } from '@solidev/data';

export enum OFFERTYPE_STORAGE_USER_LEVEL {
  sadmin = 'sadmin',
  admin = 'admin',
  write = 'write',
  read = 'read',
  anon = 'anon',
}

export enum OFFERTYPE_ALARM {
  NO_CURRENT = 'alarm:nocurrent',
  NO_RRULE = 'alarm:no_rrule',
  DATE_PUBLICATION = 'alarm:date_publication',
  DATE_VALIDATION = 'alarm:date_validation',
  DATE_PREPARATION = 'alarm:date_preparation',
}

export class OffertypeStorageBase extends DataModel {
  static override readonly __name: string = 'OffertypeStorageBase';

  @primaryField({
    name: 'id',
    description: 'ID',
    priority: 1000,
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
    priority: 100,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'offertype',
    description: "ID Modèle d'offre",
    related: 'OfferType',
    priority: -1,
  })
  public offertype!: number;

  @foreignKeyField({
    name: 'storage',
    description: 'ID Dépôt',
    related: 'Storage',
    priority: -1,
  })
  public storage!: number;

  @manyToManyField({
    name: 'tariftypes',
    description: 'ID Modèles de tarifs',
    defaultValue: [],
    related: 'TarifType',
    priority: -1,
  })
  public tariftypes: number[] = [];

  @detailsField({
    name: 'flags',
    description: 'Flags',
    defaultValue: [],
    priority: 25,
  })
  public flags: string[] = [];

  @detailsField({
    name: 'user_level',
    description: 'Permissions utilisateur',
    deserialize: (d) => d,
    priority: -1,
  })
  public user_level: OFFERTYPE_STORAGE_USER_LEVEL[] = [];

  public have_level(...level: OFFERTYPE_STORAGE_USER_LEVEL[]): boolean {
    return this.user_level.some((l) => level.includes(l));
  }
}
