import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderprocessstorage } from '../orderprocessstorage';
import { firstValueFrom } from 'rxjs';
import { DispeditComponent } from '@solidev/data';
import { RruleEditorComponent } from '../../../../components/utils/rrule-editor/rrule-editor.component';
import { PlatformService } from '../../../structures/platform/platform.service';
import { StorageService } from '../../../structures/storage/storage.service';

@Component({
  selector: 'lvadg-orderprocessstorage-manage',
  standalone: true,
  imports: [CommonModule, DispeditComponent, RruleEditorComponent],
  templateUrl: './orderprocessstorage-manage.component.pug',
  styleUrls: ['./orderprocessstorage-manage.component.sass'],
})
export class OrderprocessstorageManageComponent implements OnInit {
  @Input() public orderprocessstorage?: Orderprocessstorage;

  constructor(
    public platform$: PlatformService,
    public storage$: StorageService
  ) {}

  ngOnInit(): void {}

  public async saveRrule(ops: Orderprocessstorage, rrule: string) {
    ops.params.delivery_dates_rrule = rrule;
    await firstValueFrom(ops.save({ updateModel: true }));
  }
}
