import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TarifNotificationService } from './tarif-notification.service';
import { TarifNotification } from './tarif-notification';

@Injectable({
  providedIn: 'root',
})
export class TarifNotificationResolver  {
  constructor(private _rs: TarifNotificationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TarifNotification> {
    return this._rs.fetch(+route.params['tarifnotificationId']);
  }
}
