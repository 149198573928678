
<ng-container *ngIf="data$|async; let data">
  <lvadg-product-nav [routes]="data.p_menu" [product]="data.product" [producer]="data.producer" [storage]="data.storage" *ngIf="data.p_menu"></lvadg-product-nav>
  <div class="container">
    <lvadg-header-action [title]="page_title()||data.product.name" level="h1" subtitle="Fiche produit" ri="product">
      <ng-container actions>
        <lvadg-iocode-button class="me-3" [model]="data.product">
          <lvadg-icon class="fs-3 px-1" role="button" ri="iocode" [icon_only]="true"></lvadg-icon>
        </lvadg-iocode-button>
        <lvadg-quickprint-button [model]="data.product" [print_settings]="print_settings" [print_context]="print_context" [template]="'product_poster'" [title]="'Impression affiche produit'">
          <lvadg-icon class="fs-3 px-1" ri="poster" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
        <lvadg-quickprint-button [model]="data.product" [print_settings]="print_settings" [print_context]="print_context" [template]="'product_detail'" [title]="'Impression fiche produit'">
          <lvadg-icon class="fs-3 px-1" ri="fiche" [icon_only]="true"></lvadg-icon>
        </lvadg-quickprint-button>
      </ng-container>
    </lvadg-header-action>
    <lvadg-product-details [product]="data.product" [producer]="producer()" [producer_detail_route]="producer_detail_route"></lvadg-product-details>
  </div>
</ng-container>