import { Component, computed, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseRouteParams, Link, RouteConfigItem } from "@solidev/data";

import { Subject } from "rxjs";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { BaseListViewComponent } from "../../../../components/baseview/baselistview.component";
import { FamilyBase } from "../../../../models/catalog/family/family.base";
import { ProviderBase } from "../../../../models/structures/provider/provider.base";
import { ADMIN_GROUPS } from "../../../../constants";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { Article } from "../../../../models/catalog/article/article";
import { ArticleCreateComponent } from "../../../../models/catalog/article/article-create/article-create.component";
import { ArticleListComponent } from "../../../../models/catalog/article/article-list/article-list.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { AuthService } from "../../../../models/users/auth.service";
import { ARTICLE_PRINT_SETTINGS } from "../../../../models/catalog/article/article-print-settings";

export interface ArticleListViewParams extends BaseRouteParams {
  detail_route: RouteConfigItem;
  family_detail_route?: RouteConfigItem;
  provider_detail_route?: RouteConfigItem;
}

export interface ArticleListViewData extends ArticleListViewParams {}

@Component({
  selector: "lvadg-article-list-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    IconComponent,
    ArticleCreateComponent,
    ArticleListComponent,
  ],
  templateUrl: "./article-list-view.component.pug",
  styleUrls: ["./article-list-view.component.sass"],
})
export class ArticleListViewComponent extends BaseListViewComponent<
  ArticleListViewData,
  Article
> {
  public family_detail_route?: Link<FamilyBase>;
  public provider_detail_route?: Link<ProviderBase>;
  public default_fields = [
    "code",
    "libelle",
    "clogo_details",
    "udf_unit",
    "udv",
    "egalim_details",
    "origine_details",
    "local",
    "egalim_status",
    "calibre_details",
    "categorie_details",
    "colisage",
    "uci",
    "actions",
  ];
  public create: boolean = false;
  public reload$ = new Subject<void | boolean>();
  public print_settings = ARTICLE_PRINT_SETTINGS;
  private _user = toSignal(inject(AuthService).currentUser$);
  public canCreate = computed(() => {
    const user = this._user();
    return (
      user &&
      (user.is_superuser ||
        user.is_staff ||
        (user.current_groups_slugs?.includes(ADMIN_GROUPS.catalog_editor) ??
          false))
    );
  });

  public override setRouteData(data: ArticleListViewData) {
    super.setRouteData(data);
    this.family_detail_route = data.family_detail_route
      ? new Link<FamilyBase>(data.family_detail_route, this)
      : undefined;
    this.provider_detail_route = data.provider_detail_route
      ? new Link<ProviderBase>(data.provider_detail_route, this)
      : undefined;
    // if (this.canCreate(data.user)) {
    //   this.default_fields.push('flags');
    //   this.default_fields.push('updated');
    // }
  }
}
