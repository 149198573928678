import {
  DataModel,
  dateField,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export class RnmcotationBase extends DataModel {
  static override readonly __name: string = 'RnmcotationBase';

  @primaryField({
    name: 'id',
    description: 'ID',
  })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'marche',
    description: 'ID Marché',
    related: 'RnmMarche',
  })
  public marche!: number;

  @dateField({
    name: 'date',
    description: 'Date de cotation',
  })
  public date!: Date;

  @textField({
    name: 'message',
    description: 'Message',
    maxLength: 10000,
  })
  public message!: string;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
  })
  public orig_id!: number;
}
