
<form [formGroup]="createForm" (submit)="save()">
  <div class="row">
    <data-dispedit class="col-6" [model]="obj" field="process" mode="form" [form]="createForm" [collection]="process$">Processus de commande</data-dispedit>
    <data-dispedit class="col-6" [model]="obj" field="date" mode="form" [form]="createForm">Date</data-dispedit>
    <data-dispedit class="col-6" [model]="obj" field="delivery_date" mode="form" [form]="createForm">Date d'enlèvement</data-dispedit>
    <data-dispedit class="col-6" [model]="obj" field="status" mode="form" [form]="createForm">État</data-dispedit>
  </div>
  <div class="clearfix">
    <div class="float-end">
      <button class="btn btn-primary" type="submit" [disabled]="createForm.pristine||createForm.invalid">Enregistrer</button>
      <button class="btn btn-secondary ms-2" type="cancel" (click)="cancelled.emit(); $event.preventDefault()">Annuler</button>
    </div>
  </div>
</form>