
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarifNotification } from '../tarif-notification';
@Component({
  selector: 'lvadg-tarif-notification-manage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tarif-notification-manage.component.pug',
  styleUrls: ['./tarif-notification-manage.component.sass']
})
export class TarifNotificationManageComponent implements OnInit {
  @Input() public tarifnotification?: TarifNotification;

  constructor() { }

  ngOnInit(): void {
  }

}
