import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offertype } from '../../offertype/offertype';
import { OffertypeStorage } from '../offertype-storage';
import { OffertypeStorageService } from '../offertype-storage.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OffertypeService } from '../../offertype/offertype.service';
import { StorageService } from '../../../structures/storage/storage.service';
import { DataMessageService, DispeditComponent } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../../../components/utils/errormessage';

@Component({
  selector: 'lvadg-offertype-storage-create',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DispeditComponent],
  templateUrl: './offertype-storage-create.component.pug',
  styleUrls: ['./offertype-storage-create.component.sass'],
})
export class OffertypeStorageCreateComponent {
  public offertype = input<Offertype | null>(null);
  @Output() public created = new EventEmitter<OffertypeStorage>();
  @Output() public cancelled = new EventEmitter<void>();
  public createForm = new FormGroup({});
  public offertype$ = inject(OffertypeService);
  public storage$ = inject(StorageService);
  private _ots = inject(OffertypeStorageService);
  public ots = this._ots.create();
  private _msgs = inject(DataMessageService);

  public async save() {
    this.ots.fromJson({
      offertype: this.offertype()?.id,
      ...this.createForm.value,
    });
    try {
      await firstValueFrom(this.ots.save({ updateModel: true }));
      this.created.emit(this.ots);
      this._msgs.success('Dépôt ajouté avec succès');
    } catch (e) {
      const ectx = getErrorContext(e, {});
      this._msgs.error(ectx.title, ectx.message, ectx.context);
    }
  }
}
