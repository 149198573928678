
import {Injectable} from '@angular/core';
import {Collection, DataBackend} from "@solidev/data";
import { ArticleTarif } from "./article-tarif";

@Injectable({
  providedIn: 'root'
})
export class ArticleTarifService extends Collection<ArticleTarif> {

  constructor(_backend: DataBackend) {
    super(_backend, "/v3/articletarifs", ArticleTarif);
  }
}
