
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { GeodepartementService} from "./geodepartement.service";
import { Geodepartement } from "./geodepartement";

@Injectable({
  providedIn: 'root'
})
export class GeodepartementResolver  {
  constructor(private _rs: GeodepartementService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Geodepartement> {
    return this._rs.fetch(+route.params["geodepartementId"])
  }
}
