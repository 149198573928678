import { OfferRestoBase } from "./offer-resto.base";
import { detailsField } from "@solidev/data";
import { OfferBase } from "../offer/offer.base";
import { Resto } from "../../structures/resto/resto";
import { Relation } from "../../structures/relation/relation";
import { Client } from "../../structures/client/client";
import { Location } from "../../locations/location/location";

export class OfferResto extends OfferRestoBase {
  static override readonly __name: string = "OfferResto";

  @detailsField({
    name: "offer_details",
    description: "Offre",
    model: OfferBase,
    readonly: true,
    priority: 900,
  })
  public offer_details?: OfferBase;

  @detailsField({
    name: "resto_details",
    description: "Resto",
    model: Resto,
    readonly: true,
    priority: 800,
  })
  public resto_details?: Resto;

  @detailsField({
    name: "resto_client_details",
    description: "Client",
    model: Client,
    readonly: true,
    priority: 800,
  })
  public resto_client_details?: Client;

  @detailsField({
    name: "resto_location_details",
    description: "Adresse",
    model: Location,
    readonly: true,
  })
  public resto_location_details?: Location;

  @detailsField({
    name: "storage_resto_relation_details",
    description: "Distance dépot-restaurant",
    readonly: true,
    model: Relation,
    priority: 750,
  })
  public storage_resto_relation_details?: Relation;
}
