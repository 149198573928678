
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import { OfferDocumentService} from "./offer-document.service";
import { OfferDocument } from "./offer-document";

@Injectable({
  providedIn: 'root'
})
export class OfferDocumentResolver implements Resolve<OfferDocument> {
  constructor(private _rs: OfferDocumentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OfferDocument> {
    return this._rs.fetch(+route.params["offerdocumentId"])
  }
}
