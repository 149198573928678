
<ng-container *ngIf="value$|async; let d">
  <ng-container class="text-warning" *ngIf="d.mode==='direct'">
    <ng-container *ngIf="mode==='pretty'"><span *ngIf="d.distance">{{d.distance/1000|number:'1.0-0'}}&nbsp;km</span><span *ngIf="!d.distance">-</span><i class="spinner-grow-sm spinner-grow text-warning ms-2"></i></ng-container>
    <ng-container *ngIf="mode==='raw'"><span *ngIf="d.distance">{{d.distance/1000|number:'1.0-0'}}</span></ng-container>
  </ng-container>
  <ng-container *ngIf="d.mode==='road'">
    <ng-container *ngIf="mode==='pretty'"><span *ngIf="d.distance"> {{d.distance/1000|number:'1.0-0'}}&nbsp;km</span><span *ngIf="!d.distance">-</span><i class="bi bi-check-lg text-success ms-2"></i></ng-container>
    <ng-container *ngIf="mode==='raw'"><span *ngIf="d.distance">{{d.distance/1000|number:'1.0-0'}}</span></ng-container>
  </ng-container>
</ng-container>