import { Component } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { OffertypeText } from "../offertype-text";
import { OffertypeTextService } from "../offertype-text.service";
import {
  FiltersParams,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgStringPipesModule } from "ngx-pipes";

@Component({
  selector: "lvadg-offertype-text-list",
  standalone: true,
  templateUrl: "./offertype-text-list.component.pug",
  styleUrls: ["./offertype-text-list.component.sass"],
  imports: [...MODELLIST_IMPORTS, NgStringPipesModule, PChoicePipe],
})
export class OffertypeTextListComponent extends ModellistComponent<OffertypeText> {
  constructor(
    coll: OffertypeTextService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "status"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filtrer par état",
          model: OffertypeText,
        }),
      ],
    };
  }
}
