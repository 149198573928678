import {
  Component,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DispeditComponent} from "@solidev/data";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {Product} from "../product";
import {Storage} from "../../../structures/storage/storage";
import {firstValueFrom} from "rxjs";
import {ProductService} from "../product.service";
import {Producer} from "../../../structures/producer/producer";
import {ProducerService} from "../../../structures/producer/producer.service";
import {FamilyService} from "../../family/family.service";
import {Family} from "../../family/family";
import {
  SeasonsManageComponent
} from "../../common/seasons-manage/seasons-manage.component";
import {
  ImageDisplayComponent
} from "../../../documents/image/image-display/image-display.component";
import {
  ProducerSpecificActionService
} from "../../../structures/producer/producer-action.service";
import {FAMILY_TYPE} from "../../family/family.base";
import {
  ProductManageComponent
} from "../product-manage/product-manage.component";

/** Création de produit */
@Component({
  selector: "lvadg-product-create",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    ReactiveFormsModule,
    SeasonsManageComponent,
    ImageDisplayComponent,
    ProductManageComponent,
  ],
  templateUrl: "./product-create.component.pug",
  styleUrls: ["./product-create.component.sass"],
})
export class ProductCreateComponent implements OnInit {
  public producer = input<Producer>();
  public storage = input<Storage>();
  @Output() public created = new EventEmitter<Product>();
  @Output() public cancelled = new EventEmitter<void>();

  public producerDetails = signal<Producer | null>(null);

  public familyDetails = signal<Family | null>(null);
  public producers$ = inject(ProducerService);
  public createForm!: FormGroup<{
    name: FormControl<string>;
    content_src: FormControl<string>;
    seasons: FormControl<number[]>;
  }>;
  public producerForm!: FormGroup<{
    producer?: FormControl<number | null>;
  }>;
  public families$ = inject(FamilyService);
  public familyForm!: FormGroup<{
    family?: FormControl<number | null>;
  }>;
  public product = signal<Product | null>(null);
  protected readonly FAMILY_TYPE = FAMILY_TYPE;
  private _items = inject(ProductService);
  public item = new Product(this._items);
  private _pact = inject(ProducerSpecificActionService);

  ngOnInit(): void {
    this.item = new Product(this._items);
    this.createForm = new FormGroup({
      name: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      content_src: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      seasons: new FormControl<number[]>([], {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
    this.producerForm = this.item.FC(["producer"]);
    const p = this.producer();
    if (p) {
      this.item.producer = p.id;
      this.item.producer_details = p;
      this.producerForm.patchValue({ producer: p.id });
      this.updateProducerDetails().then();
    }
    this.familyForm = this.item.FC(["family"]);
  }

  public async create() {
    this.item.fromJson(this.createForm.value);
    await firstValueFrom(this.item.save({ updateModel: true }));
    this.created.emit(this.item);
  }

  saveProducer() {
    if (this.producerForm.value.producer) {
      this.item.producer = this.producerForm.value.producer;
      this.updateProducerDetails().then();
    }
  }

  public async updateProducerDetails() {
    if (this.item.producer) {
      this.producerDetails.set(
        await firstValueFrom(this.producers$.fetch(this.item.producer)),
      );
    } else {
      this.producerDetails.set(null);
    }
  }

  public async updateFamilyDetails() {
    if (this.item.family) {
      const family = await firstValueFrom(
        this.families$.fetch(this.item.family),
      );
      this.item.content_src = family.article_description;
      this.item.name = family.article_name;
      this.item.seasons = family.article_seasons;
      this.createForm.patchValue({
        seasons: family.article_seasons,
      });
      this.familyDetails.set(family);
    } else {
      this.familyDetails.set(null);
    }
  }

  saveFamily() {
    if (this.familyForm.value.family) {
      this.item.family = this.familyForm.value.family;
      this.updateFamilyDetails().then();
    }
  }

  async saveProduct() {
    const producer = this.producerDetails();
    if (!producer) {
      return;
    }
    this.item.fromJson(this.createForm.value, { partial: true, check: true });
    this._pact
      .action(producer, {
        action: "create_product",
        name: this.item.name,
        family: this.item.family,
        content_src: this.item.content_src || "",
        seasons: this.item.seasons,
      })
      .then(async (res) => {
        if (res.success) {
          this.product.set(
            await firstValueFrom(this._items.fetch(res.data!.id)),
          );
        }
      });
  }

  seasonsChanged($event: number[]) {
    this.createForm.patchValue({ seasons: $event });
  }
}
