import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { Storage } from "../storage";
import { FilterDefaults, Link } from "@solidev/data";
import { StorageService } from "../storage.service";
import { Observable } from "rxjs";
import { STORAGE_STATUS } from "../storage.base";

@Component({
  selector: "lvadg-storage-shortlist",
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent],
  templateUrl: "./storage-shortlist.component.pug",
  styleUrls: ["./storage-shortlist.component.sass"],
})
export class StorageShortlistComponent implements OnInit {
  @HostBinding("class") public readonly class = "card h-100";
  @Input() public display: "card" = "card";
  @Input() public title = "Dépôts";
  @Input() public detail_route?: Link<Storage>;
  @Input() public filter?: FilterDefaults = {};
  public storages$!: Observable<Storage[]>;

  constructor(private _storages: StorageService) {}

  public ngOnInit(): void {
    this.storages$ = this._storages.list({
      page_size: 1000,
      fields: ["id", "cvva", "name"].join(","),
      order: "name",
      status: STORAGE_STATUS.ACTIVE,
      ...this.filter,
    });
  }
}
