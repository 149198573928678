import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  primaryField,
} from "@solidev/data";

export enum OFFERTYPE_TEXT_TYPE_TYPE {
  VIVALYA_ONLY = "VVO",
  VIVALYA_BASE = "VVB",
  STORAGE = "STO",
}

export enum OFFERTYPE_TEXT_TYPE_MANDATORY {
  MANDATORY = "MND",
  OPTIONAL = "OPT",
}

export class OffertypeTextTypeBase extends DataModel {
  static override readonly __name: string = "OffertypeTextTypeBase";

  @primaryField({ name: "id", description: "ID", priority: 1000 })
  public override id!: number;

  @datetimeField({
    name: "created",
    description: "Date de création",
    readonly: true,
    priority: 50,
  })
  public created!: Date;

  @datetimeField({
    name: "updated",
    description: "Date de MAJ",
    readonly: true,
    priority: 10,
  })
  public updated!: Date;

  @charField({
    name: "type",
    description: "Type de saisie",
    defaultValue: OFFERTYPE_TEXT_TYPE_TYPE.VIVALYA_ONLY,
    required: true,
    maxLength: 3,
    choices: [
      {
        value: OFFERTYPE_TEXT_TYPE_TYPE.VIVALYA_ONLY,
        desc: "Saisie Vivalya seulement",
      },
      {
        value: OFFERTYPE_TEXT_TYPE_TYPE.VIVALYA_BASE,
        desc: "Saisie Vivalya, personnalisation dépot OK",
      },
      {
        value: OFFERTYPE_TEXT_TYPE_TYPE.STORAGE,
        desc: "Saisie par dépot seulement",
      },
    ],
    priority: 500,
  })
  public type: OFFERTYPE_TEXT_TYPE_TYPE = OFFERTYPE_TEXT_TYPE_TYPE.VIVALYA_ONLY;

  @charField({
    name: "title",
    description: "Description / titre",
    required: true,
    maxLength: 255,
    defaultValue: "",
    priority: 800,
  })
  public title!: string;

  @foreignKeyField({
    name: "offertype",
    description: "ID modèle offre",
    related: "OfferType",
    priority: -1,
  })
  public offertype!: number;

  @foreignKeyField({
    name: "region",
    description: "ID région spécifique",
    related: "GenericRegion",
    priority: -1,
  })
  public region?: number;

  @charField({
    name: "mandatory",
    description: "Obligatoire ?",
    defaultValue: OFFERTYPE_TEXT_TYPE_MANDATORY.MANDATORY,
    required: true,
    maxLength: 3,
    choices: [
      { value: OFFERTYPE_TEXT_TYPE_MANDATORY.MANDATORY, desc: "Obligatoire" },
      {
        value: OFFERTYPE_TEXT_TYPE_MANDATORY.OPTIONAL,
        desc: "Optionnel",
      },
    ],
    priority: 300,
  })
  public mandatory: OFFERTYPE_TEXT_TYPE_MANDATORY =
    OFFERTYPE_TEXT_TYPE_MANDATORY.MANDATORY;
}
