import { Component } from '@angular/core';
import { OrdergroupDetailViewData } from './ordergroup-detail-view.component.params';

import { FilterDefaults, Link } from '@solidev/data';
import { map, Observable } from 'rxjs';
import { BASE_DETAIL_VIEW_IMPORTS } from '../../../../components/baseview/basedetailview.imports';
import { OrdergroupManageComponent } from '../../../../models/orders/ordergroup/ordergroup-manage/ordergroup-manage.component';
import { OrdergroupLinesComponent } from '../../../../models/orders/ordergroup/ordergroup-lines/ordergroup-lines.component';
import { OrderListComponent } from '../../../../models/orders/order/order-list/order-list.component';
import { OrdergroupActionsComponent } from '../../../../models/orders/ordergroup/ordergroup-actions/ordergroup-actions.component';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { StorageBase } from '../../../../models/structures/storage/storage.base';
import { PlatformBase } from '../../../../models/structures/platform/platform.base';
import { OrderBase } from '../../../../models/orders/order/order.base';
import { ArticleBase } from '../../../../models/catalog/article/article.base';
import { TarifBase } from '../../../../models/tarifs/tarif/tarif.base';
import { Ordergroup } from '../../../../models/orders/ordergroup/ordergroup';

@Component({
  selector: 'lvadg-ordergroup-detail-view',
  standalone: true,
  imports: [
    ...BASE_DETAIL_VIEW_IMPORTS,
    OrdergroupManageComponent,
    OrdergroupLinesComponent,
    OrderListComponent,
    OrdergroupActionsComponent,
  ],
  templateUrl: './ordergroup-detail-view.component.pug',
  styleUrls: ['./ordergroup-detail-view.component.sass'],
})
export class OrdergroupDetailViewComponent extends BaseDetailViewComponent<OrdergroupDetailViewData, Ordergroup> {
  public ordergroupFilter$!: Observable<FilterDefaults>;
  public storage_detail_route?: Link<StorageBase>;
  public platform_detail_route?: Link<PlatformBase>;
  public order_detail_route?: Link<OrderBase>;
  public article_detail_route?: Link<ArticleBase>;
  public tarif_detail_route?: Link<TarifBase>;
  public orders_default_fields = [
    'id',
    'code',
    'date',
    'delivery_date',
    'storage_details',
    'total_price',
    'total_weight',
    'total_quantity',
    'status',
    'group_details',
    'flags',
    'actions',
  ];

  public override setRouteData(data: OrdergroupDetailViewData) {
    super.setRouteData(data);
    this.storage_detail_route = new Link(data.storage_detail_route, data);
    this.platform_detail_route = new Link(data.platform_detail_route, data);
    this.order_detail_route = new Link(data.order_detail_route, data);
    this.article_detail_route = new Link(data.article_detail_route, data);
    this.tarif_detail_route = new Link(data.tarif_detail_route, data);
    // this.some_route = new Link<any>(data.some_route, this);
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.ordergroupFilter$ = this.data$.pipe(
      map((d) => {
        return { group: d.ordergroup.id };
      }),
    );
  }
}
