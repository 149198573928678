import { ArticleTarifLogBase } from './article-tarif-log.base';
import { detailsField } from '@solidev/data';
import { ArticleTarifItem } from '../article-tarif-item/article-tarif-item';
import { UserBase } from '../../users/user/user.base';
import { TarifBase } from '../tarif/tarif.base';
import { ArticleTarif } from '../article-tarif/article-tarif';
import { ArticleBase } from '../../catalog/article/article.base';

export class ArticleTarifLog extends ArticleTarifLogBase {
  static override readonly __name: string = 'ArticleTarifLog';
  @detailsField({
    model: ArticleTarifItem,
    description: 'Sous article tarif',
    readonly: true,
  })
  public articletarifitem_details?: ArticleTarifItem;

  @detailsField({
    model: ArticleTarif,
    description: 'Article tarif',
    readonly: true,
  })
  public articletarif_details?: ArticleTarif;

  @detailsField({
    model: UserBase,
    description: 'Auteur',
    readonly: true,
  })
  public author_details?: UserBase;

  @detailsField({
    model: TarifBase,
    description: 'Tarif',
    readonly: true,
  })
  public tarif_details?: TarifBase;

  @detailsField({
    model: ArticleBase,
    description: 'Article',
    readonly: true,
  })
  public article_details?: ArticleBase;
}
