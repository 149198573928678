import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRouteParams, Link, RouteConfigItem } from '@solidev/data';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { BaseDetailViewComponent } from '../../../../components/baseview/basedetailview.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  TARIFTYPE_STORAGE_MODE,
  TARIFTYPE_USER_LEVEL,
  TariftypeBase,
} from '../../../../models/tarifs/tariftype/tariftype.base';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';

export interface TariftypeDetailViewParams extends BaseRouteParams {
  tariftype_detail_route?: RouteConfigItem;
  tarifs_detail_route?: RouteConfigItem;
  tariftype_manage_route?: RouteConfigItem;
  tariftype_documents_route?: RouteConfigItem;
  tariftype_atts_route?: RouteConfigItem;
  tariftype_relations_route?: RouteConfigItem;
  tariftype_storages_route?: RouteConfigItem;
  tariftype_catalog_route?: RouteConfigItem;
  tariftype_destinations_route?: RouteConfigItem;
  tariftype_notifications_route?: RouteConfigItem;
  tariftype_notification_logs_route?: RouteConfigItem;
  tariftype_permissions_route?: RouteConfigItem;
  tariftype_tarifs_logs_route?: RouteConfigItem;
  tariftype_prices_logs_route?: RouteConfigItem;
  tariftype_lifecycle_route?: RouteConfigItem;
  tariftype_mappings_route?: RouteConfigItem;
}

export interface TariftypeDetailViewData extends TariftypeDetailViewParams {
  tariftype: Tariftype;
}

@Component({
  selector: 'lvadg-tariftype-detail-view',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterOutlet, RouterLinkActive, HeaderActionComponent],
  templateUrl: './tariftype-detail-view.component.pug',
  styleUrls: ['./tariftype-detail-view.component.sass'],
})
export class TariftypeDetailViewComponent extends BaseDetailViewComponent<TariftypeDetailViewData, Tariftype> {
  public tarifs_detail_route?: Link<TariftypeBase>;
  public tariftype_detail_route?: Link<TariftypeBase>;
  public tariftype_manage_route?: Link<TariftypeBase>;
  public tariftype_documents_route?: Link<TariftypeBase>;
  public tariftype_atts_route?: Link<TariftypeBase>;
  public tariftype_relations_route?: Link<TariftypeBase>;
  public tariftype_storages_route?: Link<TariftypeBase>;
  public tariftype_catalog_route?: Link<TariftypeBase>;
  public tariftype_destinations_route?: Link<TariftypeBase>;
  public tariftype_notifications_route?: Link<TariftypeBase>;
  public tariftype_notification_logs_route?: Link<TariftypeBase>;
  public tariftype_permissions_route?: Link<TariftypeBase>;
  public tariftype_tarifs_logs_route?: Link<TariftypeBase>;
  public tariftype_prices_logs_route?: Link<TariftypeBase>;
  public tariftype_lifecycle_route?: Link<TariftypeBase>;
  public tariftype_mappings_route?: Link<TariftypeBase>;

  public TTSM = TARIFTYPE_STORAGE_MODE;
  public TTUL = TARIFTYPE_USER_LEVEL;

  public override setRouteData(data: TariftypeDetailViewData) {
    super.setRouteData(data);
    if (data.tarifs_detail_route) {
      this.tarifs_detail_route = new Link(data.tarifs_detail_route, data, this._router);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
    }
    if (data.tariftype_manage_route) {
      this.tariftype_manage_route = new Link(data.tariftype_manage_route, data, this._router);
    }
    if (data.tariftype_documents_route) {
      this.tariftype_documents_route = new Link(data.tariftype_documents_route, data, this._router);
    }
    if (data.tariftype_atts_route) {
      this.tariftype_atts_route = new Link(data.tariftype_atts_route, data, this._router);
    }
    if (data.tariftype_relations_route) {
      this.tariftype_relations_route = new Link(data.tariftype_relations_route, data, this._router);
    }
    if (data.tariftype_storages_route) {
      this.tariftype_storages_route = new Link(data.tariftype_storages_route, data, this._router);
    }
    if (data.tariftype_catalog_route) {
      this.tariftype_catalog_route = new Link(data.tariftype_catalog_route, data, this._router);
    }
    if (data.tariftype_destinations_route) {
      this.tariftype_destinations_route = new Link(data.tariftype_destinations_route, data, this._router);
    }
    if (data.tariftype_notifications_route) {
      this.tariftype_notifications_route = new Link(data.tariftype_notifications_route, data, this._router);
    }
    if (data.tariftype_notification_logs_route) {
      this.tariftype_notification_logs_route = new Link(data.tariftype_notification_logs_route, data, this._router);
    }
    if (data.tariftype_permissions_route) {
      this.tariftype_permissions_route = new Link(data.tariftype_permissions_route, data, this._router);
    }
    if (data.tariftype_tarifs_logs_route) {
      this.tariftype_tarifs_logs_route = new Link(data.tariftype_tarifs_logs_route, data, this._router);
    }
    if (data.tariftype_prices_logs_route) {
      this.tariftype_prices_logs_route = new Link(data.tariftype_prices_logs_route, data, this._router);
    }
    if (data.tariftype_lifecycle_route) {
      this.tariftype_lifecycle_route = new Link(data.tariftype_lifecycle_route, data, this._router);
    }
    if (data.tariftype_mappings_route) {
      this.tariftype_mappings_route = new Link(data.tariftype_mappings_route, data, this._router);
    }
  }
}
