import { CalibreBase } from './calibre.base';
import { detailsField } from '@solidev/data';
import { FamilyBase } from '../../family/family.base';

export class Calibre extends CalibreBase {
  static override readonly __name: string = 'Calibre';

  @detailsField({
    model: FamilyBase,
    readonly: true,
    description: 'Famille',
    priority: 500,
  })
  public family_details?: FamilyBase;
}
