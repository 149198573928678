import { PackagingBase } from './packaging.base';
import { integerField } from '@solidev/data';

export class Packaging extends PackagingBase {
  static override readonly __name: string = 'Packaging';

  @integerField({
    name: 'articles_count_uci',
    description: 'Nb UCI',
    priority: 400,
    readonly: true,
  })
  public articles_count_uci?: number;

  @integerField({
    name: 'articles_count_colisage',
    description: 'Nb colisage',
    priority: 400,
    readonly: true,
  })
  public articles_count_colisage?: number;
}
