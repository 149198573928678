import { Component, HostListener, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsService } from '../docs.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Doc } from '../doc';

@Component({
  selector: 'lvadg-doc-display',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './doc-display.component.pug',
  styleUrls: ['./doc-display.component.sass'],
})
export class DocDisplayComponent {
  @Input() public doc$?: Observable<Doc>;

  constructor(
    private route: ActivatedRoute,
    private _docs: DocsService,
    private _router: Router
  ) {}

  @HostListener('click', ['$event'])
  onClick(evt: MouseEvent) {
    console.log('button', evt);
    if (evt.target && (evt.target as any).attributes?.href) {
      console.log('checking link');
      const a = evt.target as HTMLAnchorElement;
      const href = a.attributes.getNamedItem('href');
      if (href && href.value.startsWith('/docs')) {
        evt.preventDefault();
        this._router.navigate([href.value]);
      }
    }
  }
}
