import { Injectable } from "@angular/core";
import maplibregl, {
  type GeoJSONSource,
  LngLatBounds,
  Map as GlMap,
  type MapOptions,
} from "maplibre-gl";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { MapData, MapLayers } from "../constants";
import { MapLayerService } from "./map-layer.service";
import { PopupData, PopupDataService } from "./popup-data.service";

import {
  DPI,
  Format,
  MaplibreExportControl,
  PageOrientation,
  Size,
} from "@watergis/maplibre-gl-export";

export interface MarkerDragEvent {
  marker: string;
  position: [number, number];
}

export type MapDataBounds = Record<string, LngLatBounds>;

/**
 * Provides an abstraction over the maplibre-gl map.
 */
export class MapContainer {
  // Data bounds
  private _dataBounds: MapDataBounds = {};

  constructor(
    private _mls: MapLayerService,
    private _pds: PopupDataService,
    private _map: maplibregl.Map,
  ) {
    _map.on("load", () => {
      this._map$.next(_map);
    });
    _map.on("styledata", () => {
      this._smap$.next(_map);
    });
  }

  // Map data bounds
  private _bounds$ = new ReplaySubject<MapDataBounds>(1);

  /** Map data bounds observable */
  public get bounds$(): Observable<MapDataBounds> {
    return this._bounds$.asObservable();
  }

  /// Styled map instance (updated on styledata events)
  private _smap$ = new ReplaySubject<GlMap>(1);

  /** Styled map instance (updated on styledata events) */
  public get smap$(): Observable<GlMap> {
    return this._smap$.asObservable();
  }

  // Loaded map instance (updated on load events)
  private _map$ = new ReplaySubject<GlMap>(1);

  /** Loaded map instance (updated on load events) */
  public get map$(): Observable<GlMap> {
    return this._map$.asObservable();
  }

  /** Create or update data layers depending on the data$ observable */
  public setDataLayers(data$: Observable<MapData>) {
    combineLatest([this._smap$, data$]).subscribe(([map, data]) => {
      for (const l of MapLayers) {
        const gjson = this._mls.makeGeoJson(data, l);
        this._dataBounds[l] = gjson.bounds;
        if (!this._map.getSource(l)) {
          map.addSource(l, gjson.geojson);
        } else {
          (this._map.getSource(l) as GeoJSONSource)!.setData(
            gjson.geojson.data,
          );
        }
        if (!this._map.getLayer(l)) {
          map.addLayer(this._mls.getSymbolLayer(l));
        }
        if (!this._map.getLayer(l + "-text")) {
          map.addLayer(this._mls.getSymbolTextLayer(l));
        }
      }
      this._bounds$.next(this._dataBounds);
    });
  }

  /** Force a map resize */
  public checkSize() {
    this._map.resize();
  }

  /** Fit to bounds */
  public fitBounds(bounds: LngLatBounds) {
    this._map.fitBounds(bounds, { padding: 100, maxZoom: 11 });
  }

  public setDataLayersPopup(cb: (data: PopupData) => void) {
    for (const l of MapLayers) {
      this._map.on("click", l, async (e) => {
        for (const p of await this._pds.clickPopup(this._map, l, e)) {
          cb(p);
        }
      });
    }
  }

  /**
   * Add or update a geojson layer to the map.
   * @param layer layer name
   * @param url layer url
   * @param texts display texts (default: true)
   */
  public setGeoJsonLayer(layer: string, url: string, texts: boolean = true) {
    if (this._map.getSource(layer)) {
      (this._map.getSource(layer) as GeoJSONSource)!.setData(url);
    } else {
      this._map.addSource(layer, {
        type: "geojson",
        data: url,
      });
    }
    if (!this._map.getLayer(layer)) {
      this._map.addLayer(this._mls.getSymbolLayer(layer));
    }
    if (texts && !this._map.getLayer(layer + "-text")) {
      this._map.addLayer(this._mls.getSymbolTextLayer(layer));
    } else if (!texts && this._map.getLayer(layer + "-text")) {
      this._map.removeLayer(layer + "-text");
    }
  }

  /**
   * Remove a geojson layer from the map.
   * @param layer layer name
   */
  public removeGeoJsonLayer(layer: string) {
    if (this._map.getLayer(layer)) {
      this._map.removeLayer(layer);
    }
    if (this._map.getLayer(layer + "-text")) {
      this._map.removeLayer(layer + "-text");
    }
    if (this._map.getSource(layer)) {
      this._map.removeSource(layer);
    }
  }
}

/**
 * Map creation and management service.
 */
@Injectable({
  providedIn: "root",
})
export class MapService {
  private _maps = new Map<string, MapContainer>();

  constructor(
    private _mls: MapLayerService,
    private _pds: PopupDataService,
  ) {}

  public async create(
    name: string,
    container: string | HTMLElement,
    options: MapOptions,
  ): Promise<MapContainer> {
    const opts = Object.assign(options, { container });
    const map = new maplibregl.Map(opts);

    map.addControl(
      new maplibregl.AttributionControl({
        compact: true,
        customAttribution: ["Vivalya", "OpenStreetmap"],
      }),
    );

    map.addControl(
      new MaplibreExportControl({
        PageSize: Size.A3,
        PageOrientation: PageOrientation.Portrait,
        Format: Format.PNG,
        DPI: DPI[96],
        Crosshair: true,
        PrintableArea: true,
        Local: "fr",
      }),
      "top-right",
    );
    map.addControl(new maplibregl.ScaleControl({}));
    map.addControl(new maplibregl.FullscreenControl({}));
    map.addControl(new maplibregl.GeolocateControl({}));
    map.addControl(new maplibregl.NavigationControl({ visualizePitch: true }));
    const cmap = new MapContainer(this._mls, this._pds, map);
    this._maps.set(name, cmap);
    return cmap;
  }

  public destroy(name: string) {
    this._maps.delete(name);
  }
}
