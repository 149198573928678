import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseListRouteParams, BaseListViewComponent } from '../../../../components/baseview/baselistview.component';
import { HeaderActionComponent } from '../../../../components/utils/header-action/header-action.component';
import { OffertypeStorageListComponent } from '../../../../models/offers/offertype-storage/offertype-storage-list/offertype-storage-list.component';
import { OffertypeStorage } from '../../../../models/offers/offertype-storage/offertype-storage';
import { FilterDefaults, Link, RouteConfigItem } from '@solidev/data';
import { OffertypeNavComponent } from '../../offertypes/detail/_nav/offertype-nav.component';
import { User } from '../../../../models/users/user/user';
import { Group } from '../../../../models/users/group/group';
import { OffertypeStoragePermission } from '../../../../models/offers/offertype-storage-permission/offertype-storage-permission';
import { Offertype } from '../../../../models/offers/offertype/offertype';
import { map, Observable, Subject } from 'rxjs';
import { OFFERTYPE_ROUTES } from '../../offertypes/detail/menu';
import { Tariftype } from '../../../../models/tarifs/tariftype/tariftype';
import { IconComponent } from '../../../../components/utils/icon/icon.component';
import { OffertypeStorageCreateComponent } from '../../../../models/offers/offertype-storage/offertype-storage-create/offertype-storage-create.component';
import { TodoComponent } from '../../../../components/utils/todo/todo.component';

export interface OffertypeStorageListViewParams extends BaseListRouteParams {
  offertypestorage_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  group_detail_route?: RouteConfigItem;
  ots_permission_detail_route?: RouteConfigItem;
  offertype_detail_route?: RouteConfigItem;
  tariftype_detail_route?: RouteConfigItem;
  ot_menu?: OFFERTYPE_ROUTES;
}

export interface OffertypeStorageListViewData extends OffertypeStorageListViewParams {
  offertype?: Offertype;
}

@Component({
  selector: 'lvadg-offertype-storage-list-view',
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OffertypeStorageListComponent,
    OffertypeNavComponent,
    IconComponent,
    OffertypeStorageCreateComponent,
    TodoComponent,
  ],
  templateUrl: './offertype-storage-list-view.component.pug',
  styleUrls: ['./offertype-storage-list-view.component.sass'],
})
export class OffertypeStorageListViewComponent extends BaseListViewComponent<
  OffertypeStorageListViewData,
  OffertypeStorage
> {
  public user_detail_route?: Link<User>;
  public group_detail_route?: Link<Group>;
  public ots_permission_detail_route?: Link<OffertypeStoragePermission>;
  public offertypestorage_detail_route?: Link<OffertypeStorage>;
  public offertype_detail_route?: Link<Offertype>;
  public reload$: Subject<void | boolean> = new Subject<void | boolean>();
  public offertypeFilter$!: Observable<FilterDefaults>;
  public offertypestorages_default_fields = [];
  public tariftype_detail_route!: Link<Tariftype>;
  public create = signal(false);

  public override postNgOnInit() {
    super.postNgOnInit();
    this.offertypeFilter$ = this.data$.pipe(
      map((data) => {
        return { offertype: data.offertype?.id };
      }),
    );
  }

  public override setRouteData(data: OffertypeStorageListViewData) {
    super.setRouteData(data);
    if (data.offertypestorage_detail_route) {
      this.offertypestorage_detail_route = new Link(data.offertypestorage_detail_route, data, this._router);
    }
    if (data.user_detail_route) {
      this.user_detail_route = new Link(data.user_detail_route, data, this._router);
    }
    if (data.group_detail_route) {
      this.group_detail_route = new Link(data.group_detail_route, data, this._router);
    }
    if (data.ots_permission_detail_route) {
      this.ots_permission_detail_route = new Link(data.ots_permission_detail_route, data, this._router);
    }
    if (data.offertype_detail_route) {
      this.offertype_detail_route = new Link(data.offertype_detail_route, data, this._router);
    }
    if (data.tariftype_detail_route) {
      this.tariftype_detail_route = new Link(data.tariftype_detail_route, data, this._router);
    }
  }
}
