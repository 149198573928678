import {
  charField,
  DataModel,
  datetimeField,
  foreignKeyField,
  integerField,
  primaryField,
  textField,
} from '@solidev/data';

export enum TarifNotificationStatusStatus {
  PENDING = 'PEN',
  TOSEND = 'SND',
  SEND_PREPARE = 'SNP',
  SEND_QUEUE = 'SNQ',
  SENDING = 'SNI',
  SENT = 'SNT',
  OPENED = 'OPN',
  CLICKED = 'CLK',
  SKIP = 'SKP',
  TEMPORARY_ERROR = 'ATT',
  FATAL_ERROR = 'FAT',
  INVALID = 'NOK',
}

export const TarifNotificationStatusChoices = [
  {
    value: TarifNotificationStatusStatus.PENDING,
    desc: 'En attente',
  },
  {
    value: TarifNotificationStatusStatus.TOSEND,
    desc: 'A envoyer',
  },
  {
    value: TarifNotificationStatusStatus.SEND_PREPARE,
    desc: 'Préparation envoi',
  },
  {
    value: TarifNotificationStatusStatus.SEND_QUEUE,
    desc: "Dans la file d'envoi",
  },
  {
    value: TarifNotificationStatusStatus.SENDING,
    desc: "En cours d'envoi",
  },
  {
    value: TarifNotificationStatusStatus.SENT,
    desc: 'Envoyé',
  },
  {
    value: TarifNotificationStatusStatus.OPENED,
    desc: 'Ouvert',
  },
  {
    value: TarifNotificationStatusStatus.CLICKED,
    desc: 'Cliqué',
  },
  {
    value: TarifNotificationStatusStatus.SKIP,
    desc: 'Ne pas envoyer',
  },
  {
    value: TarifNotificationStatusStatus.TEMPORARY_ERROR,
    desc: 'Erreur temporaire, en attente nouvel essai',
  },
  {
    value: TarifNotificationStatusStatus.FATAL_ERROR,
    desc: 'Erreur fatale, échec envoi',
  },
  {
    value: TarifNotificationStatusStatus.INVALID,
    desc: 'Invalide',
  },
];

export class TarifNotificationStatusBase extends DataModel {
  static override readonly __name: string = 'TarifNotificationStatusBase';

  @primaryField({ name: 'id', description: 'ID' })
  public override id!: number;

  @datetimeField({
    name: 'created',
    description: 'Date de création',
    readonly: true,
  })
  public created!: Date;

  @datetimeField({
    name: 'updated',
    description: 'Date de MAJ',
    readonly: true,
  })
  public updated!: Date;

  @foreignKeyField({
    name: 'notification',
    description: 'ID Notification',
    related: 'TarifNotification',
    priority: -1,
  })
  public notification!: number;

  @foreignKeyField({
    name: 'user',
    description: 'ID Utilisateur lvadg',
    related: 'User',
    priority: -1,
  })
  public user!: number;

  @charField({
    name: 'destination',
    description: 'Destination',
    maxLength: 300,
  })
  public destination!: string;

  @charField({
    name: 'status',
    description: 'Ètat',
    defaultValue: TarifNotificationStatusStatus.PENDING,
    maxLength: 3,
    choices: TarifNotificationStatusChoices,
  })
  public status: TarifNotificationStatusStatus = TarifNotificationStatusStatus.PENDING;

  @charField({
    name: 'error_zone',
    description: 'Zone erreur',
    maxLength: 300,
  })
  public error_zone?: string;

  @charField({
    name: 'error_source',
    description: 'Source erreur',
    maxLength: 300,
  })
  public error_source?: string;

  @textField({
    name: 'error_message',
    description: 'Message erreur',
  })
  public error_message?: string;

  @charField({
    name: 'sref',
    description: 'Ref envoi',
    maxLength: 64,
    priority: -1,
  })
  public sref!: string;

  @integerField({
    name: 'orig_id',
    description: 'orig id',
    priority: -1,
  })
  public orig_id!: number;
}
