
<ng-container *ngIf="data$|async; let data">
  <ng-template #createOfcSlot let-offcanvas>
    <div class="offcanvas-header">
      <lvadg-icon ri="add">Ajouter un produit</lvadg-icon>
      <button class="btn-close" type="button" aria-label="Close" (click)="offcanvas.dismiss()"></button>
    </div>
    <div class="offcanvas-body">
      <div class="container">
        <lvadg-product-create [producer]="data.producer" (cancelled)="create()" (created)="reload$.next(true); create()"></lvadg-product-create>
      </div>
    </div>
  </ng-template>
  <lvadg-producer-nav [producer]="data.producer" [storage]="data.storage" [routes]="data.pr_menu"></lvadg-producer-nav>
  <lvadg-header-action [title]="data.producer.name" level="h2" ri="producer" subtitle="Produits producteur">
    <ng-container actions>
      <button class="btn btn-sm btn-outline-primary" (click)="create()" *ngIf="canCreate()"><i class="bi bi-plus-circle me-2"></i>Ajouter un produit</button>
    </ng-container>
  </lvadg-header-action>
  <lvadg-product-list [default_fields]="default_fields" [filter]="producerFilter$" [reload]="reload$" [print_settings]="print_settings" [print_context]="print_context" [actions]="['print_affiche', 'print_fiche']" [detail_route]="product_detail_route"></lvadg-product-list>
</ng-container>