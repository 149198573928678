
<div class="row g-2" *ngIf="meta">
  <data-dispedit class="col" [model]="meta" field="type">Type de paramétrage</data-dispedit>
  <data-dispedit class="col" [model]="meta" field="name">Nom / description</data-dispedit>
</div>
<div class="row mt-3">
  <div class="col-8">
    <button class="btn btn-primary w-100" (click)="save()"><i class="bi bi-save me-2"></i>Enregistrer</button>
  </div>
  <div class="col-4">
    <button class="btn btn-secondary w-100" (click)="cancelled.emit()">Annuler</button>
  </div>
</div>