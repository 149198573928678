
<ng-container *ngIf="data$|async; let data">
  <lvadg-header-action title="Adhérents" level="h1" ri="member">
    <ng-container actions>
      <button class="btn btn-primary" (click)="create=!create">
        <lvadg-icon ri="add">Ajouter un adhérent</lvadg-icon>
      </button>
    </ng-container>
  </lvadg-header-action>
  <div class="card my-3" *ngIf="create">
    <div class="card-header">
      <lvadg-icon ri="add">Ajouter un adhérent</lvadg-icon>
    </div>
    <div class="card-body">
      <lvadg-member-create (cancelled)="create=false" (created)="reload$.next(true); create=false; detail_route.navigate({member: $event})"></lvadg-member-create>
    </div>
  </div>
  <lvadg-member-list [name]="data.route.name" [detail_route]="detail_route" [default_fields]="default_fields" [reload]="reload$"></lvadg-member-list>
</ng-container>