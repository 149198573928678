
<div class="row g-1" *ngIf="ordergroup$|async; let ordergroup">
  <div class="col-4">
    <button class="w-100 btn btn-outline-primary">Ajouter les commandes</button>
  </div>
  <div class="col-4">
    <button class="w-100 btn btn-outline-primary">Tout consolider</button>
  </div>
  <div class="col-4">
    <button class="w-100 btn btn-outline-danger" (click)="action(ordergroup, 'close')">Fermer la consolidation</button>
  </div>
  <div class="col-12">
    <pre class="todo">Voir quelles actions on met là dedans</pre>
  </div>
</div>