import { IocodeBase } from './iocode.base';
import { charField, detailsField } from '@solidev/data';
import { UserBase } from '../../users/user/user.base';

export class Iocode extends IocodeBase {
  static override readonly __name: string = 'Iocode';

  @detailsField({
    name: 'user_details',
    model: UserBase,
    description: 'Créateur',
    readonly: true,
    priority: 300,
  })
  public user_details: UserBase | null = null;

  @charField({
    name: 'iourl',
    description: 'IO URL',
    priority: 850,
  })
  public iourl: string | null = null;

  @charField({
    name: 'ioqr',
    description: 'IO QR Code',
    priority: 830,
  })
  public ioqr: string | null = null;
}
