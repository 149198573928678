import { DataMessageService } from '@solidev/data';
import { firstValueFrom } from 'rxjs';
import { getErrorContext } from '../../components/utils/errormessage';
import { StructureBase } from './structure.base';
import { OpLog } from '../../components/utils/oplog/oplog';
import { Location } from '../locations/location/location';

export interface StructureBaseActionResult<S extends StructureBase, D> {
  model?: S;
  data?: D;
  log: OpLog;
  message: string;
  success: boolean;
}

interface StructureCreateLocationParams {
  action: 'create_location';
  address: string;
  postcode: string;
  city: string;
  country: string;
}

interface StructureFixLocationParams {
  action: 'fix_location';
  force_ggmap: boolean;
}

interface StructureUpdateLocationPositionParams {
  action: 'update_location_position';
  latitude: number;
  longitude: number;
}

export type StructureBaseActionParams =
  | StructureCreateLocationParams
  | StructureFixLocationParams
  | StructureUpdateLocationPositionParams;

export class StructureBaseAction<S extends StructureBase> {
  constructor(protected _msgs: DataMessageService) {}

  public async action(
    model: S,
    action: StructureCreateLocationParams,
    messages?: Record<string, string>,
  ): Promise<StructureBaseActionResult<S, Location>>;
  public async action(
    model: S,
    action: StructureFixLocationParams,
    messages?: Record<string, string>,
  ): Promise<StructureBaseActionResult<S, Location>>;
  public async action(
    model: S,
    action: StructureUpdateLocationPositionParams,
    messages?: Record<string, string>,
  ): Promise<StructureBaseActionResult<S, Location>>;

  public async action<R extends StructureBaseActionResult<S, unknown>>(
    model: S,
    action: StructureBaseActionParams,
    messages: Record<string, string> = {},
  ): Promise<R> {
    try {
      const res = await firstValueFrom(
        model.action<S, R>('POST', 'action', {
          body: {
            ...action,
          },
        }),
      );
      if (messages['success']) {
        this._msgs.success(messages['success']);
      } else if (res.message) {
        this._msgs.success(res.message);
      }
      res.success = true;
      return res as R;
    } catch (e) {
      const { title, message, context } = getErrorContext(e, messages);
      this._msgs.error(title, message, context);
      return { model, message: message, success: false } as R;
    }
  }
}
