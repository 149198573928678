
<ng-container *ngIf="tarif">
  <div class="row g-3">
    <div class="col-lg-6">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos tarif</div>
        <div class="card-body">
          <dl class="row">
            <data-dispedit class="col-6" [model]="tarif" field="id" mode="dd" [editable]="false">ID</data-dispedit>
            <data-dispedit class="col-6" [model]="tarif" field="status" mode="dd" [editable]="tariftype?.have_level(TTUL.sadmin)||false">État</data-dispedit>
            <ng-container *ngIf="tariftype?.have_level(TTUL.admin)||false">
              <div class="col-12" *ngIf="renewable">
                <div class="text-info"></div>
                <button class="btn btn-warning w-100" (click)="renew()" [disabled]="renewing"><span *ngIf="!renewing">Réactiver le tarif</span><span *ngIf="renewing"><span class="spinner-grow spinner-grow-sm me-2"></span>Réactivation en cours...</span></button>
              </div>
              <div class="col-12" *ngIf="!renewable &amp;&amp; this.tarif.status === TS.ARCHIVED">
                <div class="text-danger">Ce tarif est archivé, vous pouvez le réactiver après avoir changé sa date de fin (attention, aucun chevauchement de date n'est autorisé). Pensez aussi éventuellement à changer le titre du tarif (date de fin, etc.).</div>
              </div>
            </ng-container>
          </dl>
          <dl class="row mt-3">
            <data-dispedit class="col-12" [model]="tarif" field="name" mode="dd" [editable]="tariftype?.have_level(TTUL.pilote)||false">Nom</data-dispedit>
            <data-dispedit class="col-6" [model]="tarif" field="datestart" mode="dd" [editable]="tariftype?.have_level(TTUL.admin)||false">Date début</data-dispedit>
            <data-dispedit class="col-6" [model]="tarif" field="dateend" mode="dd" [editable]="tariftype?.have_level(TTUL.pilote)||false">Date fin</data-dispedit>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-sticky-note me-2"></i>Message</div>
        <div class="card-body">
          <data-richedit class="col-12" [model]="tarif" field="message" mode="dd" [editable]="tariftype?.have_level(TTUL.pilote)||false">Message du tarif</data-richedit>
        </div>
      </div>
    </div>
  </div>
</ng-container>