import { BaseRouteParams, DataModel, NavDriver, TabMemoryService } from '@solidev/data';
import { BaseViewComponent } from './baseview.component';
import { Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive()
export class BaseDetailViewComponent<
  RD extends BaseRouteParams,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends DataModel,
> extends BaseViewComponent<RD> {
  public navId!: NavDriver;
  public defaultNavId = 'show';

  constructor(
    _router: Router,
    _route: ActivatedRoute,
    protected _tms: TabMemoryService,
  ) {
    super(_router, _route);
  }

  public override setRouteData(data: RD) {
    super.setRouteData(data);
    this.navId = this._tms.memFor([this.name], this.defaultNavId, true, this._route.snapshot.fragment);
  }
}
