import { Inject, Injectable } from '@angular/core';
import { DATA_API_URL } from '@solidev/data';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Doc, IDoc } from './doc';
import { map, Observable, shareReplay } from 'rxjs';
import { Toc } from './toc';

@Injectable({
  providedIn: 'root',
})
export class DocsService {
  constructor(
    @Inject(DATA_API_URL) private api_url: string,
    private _san: DomSanitizer,
    private _http: HttpClient
  ) {}

  public fetch(doc: string): Observable<Doc> {
    return this._http
      .get<IDoc>(`${this.api_url}/v3/docs/${doc}`)
      .pipe(map((d) => new Doc(d, this._san)));
  }

  public toc(): Observable<{ tree: Toc[]; items: Map<number, Toc> }> {
    return this._http.get<{ results: Toc[] }>(`${this.api_url}/v3/tocs`).pipe(
      map((r) => r.results),
      map((r) => {
        const parents_map = new Map<number, Toc[]>();
        const toc_map = new Map<number, Toc>();
        for (const t of r) {
          const toc = new Toc(t, parents_map);
          toc_map.set(toc.id, toc);
          let ch: Toc[];
          if (parents_map.get(toc.parent)) {
            ch = parents_map.get(toc.parent)!;
          } else {
            ch = [];
          }
          ch.push(toc);
          parents_map.set(toc.parent, ch);
        }

        for (const t of toc_map.values()) {
          t.children = parents_map.get(t.id) || [];
          let p: number = t.parent;
          t.parents = [t];
          while (p != 0 && toc_map.get(p)) {
            const par = toc_map.get(p)!;
            t.parents.push(par);
            p = par.parent;
          }
          t.parents.reverse();
        }
        return { tree: parents_map.get(0)!, items: toc_map };
      }),
      shareReplay(1)
    );
  }
}
