import { Component, Input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Product } from "../product";
import { DispeditComponent, Link } from "@solidev/data";
import { FamilyService } from "../../family/family.service";
import { ProducerService } from "../../../structures/producer/producer.service";
import { ProviderService } from "../../../structures/provider/provider.service";
import { firstValueFrom } from "rxjs";
import { SeasonsManageComponent } from "../../common/seasons-manage/seasons-manage.component";
import { LogoManageComponent } from "../../../documents/image/logo-manage/logo-manage.component";
import { GalleryManageComponent } from "../../../documents/image/gallery-manage/gallery-manage.component";
import { ImageDisplayComponent } from "../../../documents/image/image-display/image-display.component";
import { FAMILY_TYPE, FamilyBase } from "../../family/family.base";
import { RouterLink } from "@angular/router";
import { CdataListmanageComponent } from "../../../cdata/cdata/cdata-listmanage/cdata-listmanage.component";
import { CDATAKEY_DEST } from "../../../cdata/cdata-key/cdata-key.base";
import { PRODUCT_TYPES } from "../product.base";
import {
  IMAGE_USAGE,
  IMAGE_USAGE_CHOICES_DICT,
} from "../../../documents/image/image.base";
import { LabelsManagerComponent } from "../../label/labels-manager/labels-manager.component";

@Component({
  selector: "lvadg-product-manage",
  standalone: true,
  imports: [
    CommonModule,
    DispeditComponent,
    SeasonsManageComponent,
    LogoManageComponent,
    GalleryManageComponent,
    ImageDisplayComponent,
    CdataListmanageComponent,
    RouterLink,
    LabelsManagerComponent,
  ],
  templateUrl: "./product-manage.component.pug",
  styleUrls: ["./product-manage.component.sass"],
})
export class ProductManageComponent {
  @Input() public product!: Product;
  @Input() public family_detail_route?: Link<FamilyBase>;
  protected readonly CDATAKEY_DEST = CDATAKEY_DEST;
  protected readonly PRODUCT_TYPES = PRODUCT_TYPES;
  protected readonly IMAGE_USAGE = IMAGE_USAGE;
  protected readonly IMAGE_USAGE_CHOICES_DICT = IMAGE_USAGE_CHOICES_DICT;
  protected allow_labels_edition = signal(false);
  protected readonly FAMILY_TYPE = FAMILY_TYPE;

  constructor(
    public families$: FamilyService,
    public producers$: ProducerService,
    public providers$: ProviderService,
  ) {}

  public async updateField(a: Product, field: string, value?: any) {
    if (this.product) {
      if (value !== undefined) {
        this.product.setFV(field, value);
      }
      await firstValueFrom(this.product.update([field], { updateModel: true }));
    }
  }
}
