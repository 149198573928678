import { Component, Input } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { TarifNotificationDestination } from '../tarif-notification-destination';
import { TarifNotificationDestinationService } from '../tarif-notification-destination.service';
import {
  FiltersParams,
  Link,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
  SafeDeleteComponent,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TariftypeDisplayComponent } from '../../tariftype/tariftype-display/tariftype-display.component';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { TariftypeBase } from '../../tariftype/tariftype.base';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';
import { ContactDisplayComponent } from '../../../users/contact/contact-display/contact-display.component';
import { ContactBase } from '../../../users/contact/contact.base';

@Component({
  selector: 'lvadg-tarif-notification-destination-list',
  standalone: true,
  templateUrl: './tarif-notification-destination-list.component.pug',
  styleUrls: ['./tarif-notification-destination-list.component.sass'],
  imports: [
    ...MODELLIST_IMPORTS,
    TariftypeDisplayComponent,
    UserDisplayComponent,
    GroupDisplayComponent,
    ContactDisplayComponent,
    SafeDeleteComponent,
    PChoicePipe,
  ],
})
export class TarifNotificationDestinationListComponent extends ModellistComponent<TarifNotificationDestination> {
  @Input() public tariftype_detail_route?: Link<TariftypeBase>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
  @Input() public contact_detail_route?: Link<ContactBase>;

  constructor(
    coll: TarifNotificationDestinationService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters || this.filters?.defaults || ['search'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
      ],
    };
  }
}
