
<ng-template #ofcslot let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <ng-content select="[ofctitle]"></ng-content>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.close('x')"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content select="[ofcbody]"></ng-content>
  </div>
  <div class="offcanvas-bottom">
    <ng-content select="[ofcbottom]"></ng-content>
  </div>
</ng-template>
<div class="modellist-container" [class.ml-over]="displayMode=='ml-over'" [class.ml-over-end]="displayMode=='ml-over-end'" [class.ml-hidden]="displayMode=='ml-hidden'" [class.ml-pinned]="displayMode=='ml-pinned'" [class.ml-top]="displayMode=='ml-top'">
  <div class="modellist-header">
    <div class="modellist-utils-displaymode-start"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="toggleDisplayMode()" role="button"></i></div>
    <div class="modellist-utils-fields" *ngIf="dispFieldsSelector">
      <data-model-list-fields-selector [list]="list"></data-model-list-fields-selector>
    </div>
    <div class="modellist-header-fields" *ngIf="dispFieldsSelector">
      <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="badge_summary"></data-model-list-filters-select>
    </div>
    <div class="modellist-utils-listname"><code class="small" *ngIf="site_defaults.MODELLIST_SHOW_LISTNAME">{{list.name}}</code></div>
    <div class="modellist-utils-xlsx" *ngIf="dispXlsxExport">
      <lvadg-modellist-xlsx-export [list]="list" [dataTableSlot]="getTableSlot" [name]="name" (xlsxExporting)="xlsxExportMode($event)"></lvadg-modellist-xlsx-export>
    </div>
    <div class="modellist-utils-print" *ngIf="dispPrint">
      <lvadg-modellist-print-button [list]="list" [filter]="filter" [print_settings]="print_settings" [print_context]="print_context" [selection]="selection"></lvadg-modellist-print-button>
    </div>
    <div class="modellist-utils-map" *ngIf="dispMap()!='none'">
      <lvadg-modellist-map-button [list]="list" [filter]="filter" [selection]="selection" [mode]="dispMap()" (localMapStatus)="localMapStatus.set($event)"></lvadg-modellist-map-button>
    </div>
    <div class="modellist-utils-displaymod-end"><i class="bi bi-funnel" *ngIf="displayMode==='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i><i class="bi bi-funnel-fill" *ngIf="displayMode!=='ml-hidden'" (click)="displayMode='ml-over-end'" role="button"></i></div>
  </div>
  <div class="modellist-filters">
    <div class="modellist-filters-wrapper">
      <div class="d-flex justify-content-end" *ngIf="displayMode==='ml-over' || displayMode==='ml-pinned'"><i class="bi bi-pin px-2" (click)="displayMode='ml-pinned'" *ngIf="displayMode=='ml-over'" role="button"></i><i class="bi bi-pin-fill px-2" (click)="displayMode='ml-over'" *ngIf="displayMode=='ml-pinned'" role="button"></i><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over' || displayMode=='ml-pinned'" role="button"></i></div>
      <div class="d-flex justify-content-start" *ngIf="displayMode==='ml-over-end'"><i class="bi bi-x-lg px-2" (click)="displayMode='ml-hidden'" *ngIf="displayMode=='ml-over-end'" role="button"></i></div>
      <div class="modellist-filters-list">
        <data-model-list-filters [list]="list" *ngIf="dispFilters"></data-model-list-filters>
      </div>
      <div class="modellist-filters-select">
        <data-model-list-filters-select [list]="list" *ngIf="dispFilters" mode="select"></data-model-list-filters-select>
      </div>
    </div>
  </div>
  <div class="modellist-table">
    <lvadg-producers-map [list]="list" [filter]="filter" *ngIf="localMapStatus()"></lvadg-producers-map>
    <div class="position-relative modellist table-responsive" [class.loading]="dispLoading &amp;&amp; (ldata.loading|async)">
      <div class="loading-overlay">
        <div class="spinner-border text-danger" role="status"></div>
        <div class="message sr-only">Loading...</div>
      </div>
      <table class="table" cdk-table #datatable [attr.aria-label]="name" [dataSource]="ldata.results" [trackBy]="eltId" [class.xlsx-exporting]="xlsxExporting" [ngClass]="getTableClasses()">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="id" [sort]="true"><b>ID</b></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="fw-bold" [routerLink]="detailRoute(row)">#&nbsp;{{row.id|number:'6.0-0'}}</a></td>
          <td cdk-footer-cell *cdkFooterCellDef></td>
        </ng-container>
        <ng-container cdkColumnDef="flags">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="flags" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-flags-display [item]="row"></lvadg-flags-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="created">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="created" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.created|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="name" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="text-decoration-none fw-bold" [routerLink]="detailRoute(row)">{{row.name}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="location">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="location" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.location}}</td>
        </ng-container>
        <ng-container cdkColumnDef="phone">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="phone" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a class="text-decoration-none" *ngIf="row.phone" [href]="'tel:'+row.phone">{{row.phone}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="fax">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="fax" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.fax}}</td>
        </ng-container>
        <ng-container cdkColumnDef="website">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="website" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><a *ngIf="row.website" [href]="row.website" target="_blank">{{row.website}}</a></td>
        </ng-container>
        <ng-container cdkColumnDef="siren">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="siren" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.siren}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="siret">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="siret" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.siret}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="tvaic">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="tvaic" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.tvaic}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="naf">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="naf" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.naf}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="legal">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="legal" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'legal'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="capital">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="capital" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.capital}}</td>
        </ng-container>
        <ng-container cdkColumnDef="logo">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="logo" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.logo}}</td>
        </ng-container>
        <ng-container cdkColumnDef="cvva">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cvva" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.cvva}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="cvva_updated">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cvva_updated" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.cvva_updated|date:'shortDate'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="orig_id">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="orig_id" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.orig_id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="type" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="status" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'status'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="pubimage">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="pubimage" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'pubimage'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="slabels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="slabels" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.slabels}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="plabels">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="plabels" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><code>{{row.plabels}}</code></td>
        </ng-container>
        <ng-container cdkColumnDef="images">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="images" [sort]="false"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.images}}</td>
        </ng-container>
        <ng-container cdkColumnDef="location_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="location_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-location-display [location]="row.location_details" mode="cpcity"></lvadg-location-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="content_src">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="content_src"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.content_src">{{row.content_src|shorten:30:'…'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="content_html">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="content_html"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.content_html">{{row.content_html|stripTags|shorten:30:'…'}}</ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="content_type">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="content_type"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row|choice:'content_type'}}</td>
        </ng-container>
        <ng-container cdkColumnDef="location_city">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="location_city"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.location_details?.city}}</td>
        </ng-container>
        <ng-container cdkColumnDef="location_postcode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="location_postcode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.location_details?.postcode}}</td>
        </ng-container>
        <ng-container cdkColumnDef="nb_products">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="nb_products" [sort]="true"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.nb_products}}</td>
        </ng-container>
        <ng-container cdkColumnDef="storages_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="storages_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">{{row.storages_details}}</td>
        </ng-container>
        <ng-container cdkColumnDef="logo_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="logo_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.logo_details">
              <lvadg-image-display class="d-block mx-auto" [image]="row.logo_details" mode="list" [thumbnail]="true"></lvadg-image-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="images_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="images_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let im of row.images_details">
              <lvadg-image-display class="d-inline-block m-1" [image]="im" mode="list" [thumbnail]="false"></lvadg-image-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="slabels_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="slabels_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let sl of row.slabels_details">
              <lvadg-label-display class="d-inline-block mx-1" [label]="sl" mode="list" [thumbnail]="false"></lvadg-label-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="plabels_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="plabels_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let prl of row.plabels_details">
              <lvadg-label-display class="d-inline-block mx-1" [label]="prl" mode="list" [thumbnail]="false"></lvadg-label-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="documents_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="documents_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div *ngFor="let sl of row.documents_details">
              <lvadg-producer-document-display class="mx-1" [producerdocument]="sl" mode="line"></lvadg-producer-document-display>
            </div>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="storage_relations_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div><span>Dépôt(s)</span><i class="bi bi-file-excel ms-1" role="button" (click)="xlsxMode=!xlsxMode"></i></div>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngFor="let r of row.storage_relations_details">
              <lvadg-relation-display [relation]="r" mode="line" [storage_detail_route]="storage_detail_route" [distance]="!xlsxMode" [location]="!xlsxMode"></lvadg-relation-display>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="select">
          <th cdk-header-cell *cdkHeaderCellDef>
            <lvadg-select-header [selection]="selection" [list]="list" [filter]="filter" [filter_filter]="print_filters_whitelist()" [print_context]="print_context" [print_settings]="print_settings"></lvadg-select-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-select-item [selection]="selection" [item]="row"></lvadg-select-item>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="search_producer_near_distances">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div><span>Proximité producteur</span><i class="bi bi-file-excel ms-1" role="button" (click)="xlsxMode=!xlsxMode"></i></div><small *ngIf="producerDistanceRef$|async; let distanceRef"><i class="bi bi-pin-map me-2"></i>{{distanceRef.label}}<span *ngIf="xlsxMode">&nbsp;&leftrightarrow;&nbsp;Producteur</span></small>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="producerDistanceRef$|async; let distanceRef">
              <div class="d-flex flex-row justify-content-between"><span *ngIf="!xlsxMode"><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;Producteur</span></span>
                <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[row.location_details?.lng||0, row.location_details?.lat||0]" [mode]="xlsxMode?'raw':'pretty'"></lvadg-distance-cache-display>
              </div>
              <ng-container *ngIf="!xlsxMode">
                <div *ngFor="let r of row.storage_relations_details">
                  <div class="d-flex flex-row justify-content-between"><span><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;{{r.storage_details?.name}}</span></span>
                    <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[r.storage_details?.location_details?.lng||0, r.storage_details?.location_details?.lat||0]"></lvadg-distance-cache-display>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="search_storage_near_distances">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div><span>Proximité dépôt(s)</span><i class="bi bi-file-excel ms-1" role="button" (click)="xlsxMode=!xlsxMode"></i></div><small *ngIf="storageDistanceRef$|async; let distanceRef"><i class="bi bi-pin-map me-2"></i>{{distanceRef.label}}<span *ngIf="xlsxMode">&nbsp;&leftrightarrow;&nbsp;Dépôt(s)</span></small>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="storageDistanceRef$|async; let distanceRef">
              <ng-container *ngIf="!xlsxMode">
                <div class="d-flex flex-row justify-content-between"><span><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;Producteur</span></span>
                  <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[row.location_details?.lng||0, row.location_details?.lat||0]"></lvadg-distance-cache-display>
                </div>
              </ng-container>
              <div *ngFor="let r of row.storage_relations_details">
                <div class="d-flex flex-row justify-content-between"><span *ngIf="!xlsxMode || (xlsxMode &amp;&amp; row.storage_relations_details.length&gt;1)"><i class="bi bi-pin-map"></i><span>&nbsp;&leftrightarrow;&nbsp;{{r.storage_details?.name}}</span></span>
                  <lvadg-distance-cache-display [end]="distanceRef.pos" [start]="[r.storage_details?.location_details?.lng||0, r.storage_details?.location_details?.lat||0]" [mode]="xlsxMode?'raw':'pretty'"></lvadg-distance-cache-display>
                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="iocode">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="iocode"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-iocode-display [iocode]="row.iocode" mode="link"></lvadg-iocode-display>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="user_level">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="user_level"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row"><span class="badge bg-info me-1" *ngFor="let r of row.user_level">{{r}}</span></td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="actions"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <lvadg-quickprint-button *ngIf="haveAction('print_affiche')" [model]="row" [print_settings]="print_settings" [print_context]="print_context" [list]="list" [filter]="filter" [template]="'producer_poster'" [title]="'Impression affiche producteur'">
              <lvadg-icon class="px-1" ri="poster" [icon_only]="true"></lvadg-icon>
            </lvadg-quickprint-button>
            <lvadg-quickprint-button *ngIf="haveAction('print_fiche')" [model]="row" [print_settings]="print_settings" [print_context]="print_context" [list]="list" [filter]="filter" [template]="'producer_detail'" [title]="'Impression fiche producteur'">
              <lvadg-icon class="px-1" ri="fiche" [icon_only]="true"></lvadg-icon>
            </lvadg-quickprint-button>
            <lvadg-quickprint-button *ngIf="haveAction('print_fiche_products')" [model]="row" [print_settings]="print_settings" [print_context]="print_context" [list]="list" [filter]="filter" [template]="'producer_detail_products'" [title]="'Impression fiche producteur et produits'">
              <lvadg-icon class="px-1" ri="fiches" [icon_only]="true"></lvadg-icon>
            </lvadg-quickprint-button>
          </td>
        </ng-container>
        <ng-container *ngFor="let d of cdatakeys">
          <ng-container cdkColumnDef="cdata_{{d.id}}">
            <th cdk-header-cell *cdkHeaderCellDef>
              <data-model-list-field-header [list]="list" field="cdata_{{d.id}}"></data-model-list-field-header>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let cd of row.cdata_details">
                <ng-container *ngIf="cd.key===d.id">
                  <lvadg-cdata-display [cdata]="cd" mode="list"></lvadg-cdata-display>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <ng-container cdkColumnDef="cdata_details">
          <th cdk-header-cell *cdkHeaderCellDef>
            <data-model-list-field-header [list]="list" field="cdata_details"></data-model-list-field-header>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div class="text-info">Voir les colonnes individuelles</div>
          </td>
        </ng-container>
        <thead *ngIf="dispHeaders">
          <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
        </thead>
        <tfoot *ngIf="dispFooters">
          <tr cdk-footer-row *cdkFooterRowDef="list.fields.enabled"></tr>
        </tfoot>
        <tbody>
          <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled;" [attr.aria-label]="$any(row)._ariaLabel || $any(row).code || $any(row)._display || $any(row).name || $any(row).title || $any(row).id" [ngClass]="getRowClasses(row)"></tr>
        </tbody>
      </table>
    </div>
    <data-model-list-paginator [list]="list" *ngIf="dispPagination" [maxPages]="maxPages"></data-model-list-paginator>
  </div>
</div>