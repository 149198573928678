
<ng-template #specialop let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="modal-title">
      <ng-container *ngIf="running['earticleImport']">Importation articles client</ng-container>
    </h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <lvadg-tariftype-earticles-import [tariftype]="current" *ngIf="running['earticleImport'] &amp;&amp; current" (done)="reload$.next(true); running['earticleImport']=false; offcanvas.close()" (cancelled)="offcanvas.close()"></lvadg-tariftype-earticles-import>
  </div>
</ng-template>
<ng-container *ngIf="data$|async; let data">
  <nav class="nav-tabs nav-justified" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="show" *ngIf="data.tariftype.have_level(TTUL.read)"><a ngbNavLink>Tarifs</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Liste des tarifs</small></h2>
            <div class="actions">
              <button class="btn btn-primary" (click)="createTarif=!createTarif" *ngIf="data.tariftype.have_level(TTUL.pilote)"><i class="bi bi-plus me-2"></i>Créer un tarif</button>
            </div>
          </div>
          <div class="card my-3" *ngIf="createTarif">
            <div class="card-header">Créer un tarif</div>
            <div class="card-body">
              <lvadg-tarif-create [tariftype]="data.tariftype" (cancelled)="createTarif=false" (created)="reload$.next(true); createTarif=false; tarif_detail_route?.navigate({tarif: $event})"></lvadg-tarif-create>
            </div>
          </div>
          <lvadg-tarif-list [name]="data.route.name+'_tarifs_'+data.tariftype.code" [filter]="tarifTypeFilter$" [reload]="reload$" [default_fields]="tarifs_default_fields" [default_filters]="['search', 'status', 'validat']" [detail_route]="tarif_detail_route"></lvadg-tarif-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="params" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink>Paramétrage</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Paramètres du tarif</small></h2>
          </div>
          <lvadg-tariftype-manage [tariftype]="data.tariftype" [tariftype_detail_route]="tariftype_detail_route"></lvadg-tariftype-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="ttdocuments" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink>Documents & modèles</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Documents et modèles</small></h2>
          </div>
        </div>
        <lvadg-tariftype-document-create [tariftype]="data.tariftype" (uploaded)="reload$.next(true)"></lvadg-tariftype-document-create>
        <lvadg-tariftype-document-list [name]="data.route.name+&quot;_ttdocs_&quot;+data.tariftype.code" [filter]="tarifTypeFilter$" [detail_route]="tariftypedocument_detail_route" [tariftypemetadata_detail_route]="tariftypemetadata_detail_route" [reload]="reload$" [actions]="['delete']"></lvadg-tariftype-document-list>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="links" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink>Liaisons</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Liaisons</small></h2>
          </div>
          <lvadg-tariftype-links-manage [tariftype]="data.tariftype" [tariftyperelation_detail_route]="tariftyperelation_detail_route" [tariftype_detail_route]="tariftype_detail_route"></lvadg-tariftype-links-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="storages" *ngIf="data.tariftype.have_level(TTUL.admin) &amp;&amp; data.tariftype.storages_mode!==TTSM.SIMPLE"><a ngbNavLink>Dépôts</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Dépôts associés au tarif</small></h2>
            <div class="actions">
              <button class="btn btn-sm btn-primary" (click)="createStorage=!createStorage"><i class="bi bi-plus me-2"></i>Ajouter un dépôt</button>
            </div>
          </div>
          <div class="card my-3" *ngIf="createStorage">
            <div class="card-header">Ajouter un dépôt</div>
            <div class="card-body">
              <lvadg-tariftype-storage-create [tariftype]="data.tariftype" (cancelled)="createStorage=false" (created)="reload$.next(true); createStorage=false"></lvadg-tariftype-storage-create>
            </div>
          </div>
          <lvadg-tariftype-storage-list [name]="data.route.name+&quot;_storages_&quot;+data.tariftype.code" [filter]="tarifTypeFilter$" [default_fields]="storages_default_fields" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [storage_detail_route]="storage_detail_route" [reload]="reload$" [actions]="['delete', 'permissions']"></lvadg-tariftype-storage-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="atts" *ngIf="(data.tariftype.have_level(TTUL.pilote) &amp;&amp; data.tariftype.lifecycle !== TTLC.UPLOAD) || data.tariftype.have_level(TTUL.admin)"><a ngbNavLink>Articles</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Articles du tarif</small></h2>
          </div>
          <lvadg-article-tarif-template-list [name]="data.route.name+&quot;_atts_&quot;+data.tariftype.code" [detail_route]="att_detail_route" [filter]="tarifTypeFilter$" [default_fields]="atts_default_fields" [reload]="reload$" [actions]="[data.tariftype.have_level(TTUL.sadmin)?'delete':'remove','remove', 'archive', 'unarchive']" [article_detail_route]="article_detail_route" [earticle_detail_route]="earticle_detail_route" (action)="attAction(data.tariftype, $event)"></lvadg-article-tarif-template-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="catalog" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Catalogue</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Catalogue / ajout d'articles</small></h2>
          </div>
          <lvadg-article-list [name]="data.route.name+'_catalog_'+data.tariftype.code" [actions]="['tariftype_add']" [filter]="catalogTarifTypeFilter$" [default_fields]="catalog_default_fields" (action)="catalogAction(data.tariftype, $event)" [reload]="reload$"></lvadg-article-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="destinations" *ngIf="data.tariftype.have_level(TTUL.admin) || (data.tariftype.have_level(TTUL.pilote) &amp;&amp; data.tariftype.lifecycle_have_nego)"><a ngbNavLink>Destinataires</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Contacts du tarif</small></h2>
            <div class="actions">
              <button class="btn btn-primary btn-sm" (click)="createDestination=!createDestination"><i class="bi bi-plus me-2"></i>Ajouter un contact</button>
            </div>
          </div>
          <div class="card my-3" *ngIf="createDestination">
            <div class="card-header">Ajouter un contact</div>
            <div class="card-body">
              <lvadg-tarif-notification-destination-create [tariftype]="data.tariftype" (cancelled)="createDestination=false" (created)="reload$.next(true); createDestination=false"></lvadg-tarif-notification-destination-create>
            </div>
          </div>
          <lvadg-tarif-notification-destination-list [name]="data.route.name+'_tndests_'+data.tariftype.code" [filter]="tarifTypeFilter$" [default_fields]="tndest_default_fields" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [actions]="['remove']" (action)="destinationAction(data.tariftype, $event)" [reload]="reload$"></lvadg-tarif-notification-destination-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="notifications" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Notifications</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>suivi des envois</small></h2>
          </div>
          <lvadg-tarif-notification-list [name]="data.route.name+&quot;_notifications&quot;" [default_fields]="tn_default_fields" [filter]="tarifTypeFilter$" [tarif_detail_route]="tarif_detail_route" [reload]="reload$"></lvadg-tarif-notification-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="notifstatuses" *ngIf="data.tariftype.have_level(TTUL.pilote)"><a ngbNavLink>Suivi des envois</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>suivi des envois</small></h2>
          </div>
          <lvadg-tarif-notification-status-list [name]="data.route.name+'_notifstatuses_'+data.tariftype.code" [filter]="tarifTypeFilter$" [default_fields]="tns_default_fields" [tarif_detail_route]="tarif_detail_route" [tariftype_detail_route]="tariftype_detail_route" [tarifnotification_detail_route]="tarifnotification_detail_route" [reload]="reload$" [user_detail_route]="user_detail_route"></lvadg-tarif-notification-status-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="groups" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink>Permissions</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Gestion des permissions</small></h2>
            <div class="actions">
              <button class="btn btn-sm btn-primary" (click)="createPermission=!createPermission"><i class="bi bi-plus me-2"></i>Ajouter une permission</button>
            </div>
          </div>
          <div class="card my-3" *ngIf="createPermission">
            <div class="card-header">Ajouter une permission</div>
            <div class="card-body">
              <lvadg-tariftype-permission-create [tariftype]="data.tariftype" (cancelled)="createPermission=false" (created)="reload$.next(true); createPermission=false"></lvadg-tariftype-permission-create>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <lvadg-tariftype-permission-list [name]="data.route.name+'_perms_'+data.tariftype.code" [filter]="tarifTypeFilter$" [default_fields]="tperms_default_fields" [tariftype_detail_route]="tariftype_detail_route" [user_detail_route]="user_detail_route" [group_detail_route]="group_detail_route" [actions]="['delete']" [reload]="reload$"></lvadg-tariftype-permission-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="log" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink> Historique tarifs</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Historique des opérations</small></h2>
          </div>
          <lvadg-tarif-log-list [name]="data.route.name+'_tariflogs_'+data.tariftype.code" [filter]="tarifTypeFilter$" [tarif_detail_route]="tarif_detail_route" [user_detail_route]="user_detail_route" [default_fields]="tlog_default_fields"></lvadg-tarif-log-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="logat" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink> Historique prix</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Historique des modifications de prix</small></h2>
          </div>
          <lvadg-article-tarif-log-list [name]="data.route.name+'_articletariflogs_'+data.tariftype.code" [filter]="tarifTypeFilter$" [tarif_detail_route]="tarif_detail_route" [user_detail_route]="user_detail_route" [default_fields]="atilog_default_fields"></lvadg-article-tarif-log-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="lifecycle" *ngIf="data.tariftype.have_level(TTUL.admin)"><a class="text-warning" ngbNavLink>Cycle de vie</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Gestion des étapes du cycle de vie</small></h2>
            <div class="actions">
              <button class="btn btn-outline-danger btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'gen_lifecycle_steps'})">MAJ Étapes</button>
            </div>
          </div>
          <lvadg-tarif-lifecycle-step-list [name]="data.route.name+&quot;_lifecyclesteps_&quot;+data.tariftype.code" [filter]="tarifTypeFilter$" [paginator]="{page: 1, page_size: 100}" [default_fields]="lifecyclesteps_default_fields"></lvadg-tarif-lifecycle-step-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="earticles" *ngIf="data.tariftype.have_level(TTUL.admin)"><a ngbNavLink>Mapping client</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="tab-title">
            <h2><i class="me-2" [ngClass]="RI.tarifs"></i>{{data.tariftype.name}}<small>Importation et match articles client</small></h2>
            <div class="actions">
              <button class="btn btn-outline-warning btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'eamapping_to_tarif'})">Ciblés &rightarrow; tarif</button>
              <button class="btn btn-outline-primary btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'eamapping_update_statuses'})">MAJ états</button>
              <button class="btn btn-outline-primary btn-sm" (click)="ttActionProcess(data.tariftype, {action: 'eamapping_get_from_bulk'})" *ngIf="data.tariftype.have_level(TTUL.sadmin)">Match fred</button>
              <button class="btn btn-outline-primary btn-sm" (click)="ofcOp('earticleImport')">Importer des articles</button>
            </div>
          </div>
          <lvadg-tariftype-earticle-mappings [name]="data.route.name+'_earticles_'+data.tariftype.code" [tariftype]="data.tariftype" [reload]="reload$"></lvadg-tariftype-earticle-mappings>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>