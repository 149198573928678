import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {NewsService} from "./news.service";
import {News} from "./news";

@Injectable({
    providedIn: 'root'
})
export class NewsResolver {
    constructor(private _rs: NewsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<News> {
        return this._rs.fetch(+route.params["newsId"])
    }
}
