import { Component, inject, signal } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FilterDefaults, Link, RouteConfigItem } from "@solidev/data";
import {
  BaseListRouteParams,
  BaseListViewComponent,
} from "../../../../components/baseview/baselistview.component";
import { User } from "../../../../models/users/user/user";
import { Resto } from "../../../../models/structures/resto/resto";
import { Client } from "../../../../models/structures/client/client";
import { Storage } from "../../../../models/structures/storage/storage";
import { Offer } from "../../../../models/offers/offer/offer";
import { OfferListComponent } from "../../../../models/offers/offer/offer-list/offer-list.component";
import { HeaderActionComponent } from "../../../../components/utils/header-action/header-action.component";
import { Offertype } from "../../../../models/offers/offertype/offertype";
import { IconComponent } from "../../../../components/utils/icon/icon.component";
import { OfferCreateComponent } from "../../../../models/offers/offer/offer-create/offer-create.component";
import { OffertypeStorage } from "../../../../models/offers/offertype-storage/offertype-storage";
import { map, Observable } from "rxjs";
import { OffertypeStorageNavComponent } from "../../offertype-storages/detail/_nav/offertype-storage-nav.component";
import { OffertypeNavComponent } from "../../offertypes/detail/_nav/offertype-nav.component";
import { OFFERTYPE_ROUTES } from "../../offertypes/detail/menu";
import { OFFERTYPE_STORAGE_ROUTES } from "../../offertype-storages/detail/menu";
import {
  OFFER_TYPES,
  OFFER_ZONES,
} from "../../../../models/offers/offer/offer.base";
import { AuthService } from "../../../../models/users/auth.service";
import { OFFERTYPE_USER_LEVEL } from "../../../../models/offers/offertype/offertype.base";
import { OFFERTYPE_STORAGE_USER_LEVEL } from "../../../../models/offers/offertype-storage/offertype-storage.base";
import { LVADG_GROUPS } from "../../../../constants";

export interface OfferListViewParams extends BaseListRouteParams {
  page_title?: string;
  page_subtitle?: string;
  offertype_detail_route?: RouteConfigItem;
  offertypestorage_detail_route?: RouteConfigItem;
  offertypestorage_offer_detail_route?: RouteConfigItem;
  user_detail_route?: RouteConfigItem;
  resto_detail_route?: RouteConfigItem;
  storage_detail_route: RouteConfigItem;
  client_detail_route: RouteConfigItem;
  ots_menu?: OFFERTYPE_STORAGE_ROUTES;
  ot_menu?: OFFERTYPE_ROUTES;
  zone?: OFFER_ZONES;
  type?: OFFER_TYPES;
  free?: boolean;
}

export interface OfferListViewData extends OfferListViewParams {
  offertype?: Offertype;
  offertypestorage?: OffertypeStorage;
}

@Component({
  selector: "lvadg-offer-list-view",
  standalone: true,
  imports: [
    CommonModule,
    HeaderActionComponent,
    OfferListComponent,
    IconComponent,
    OfferCreateComponent,
    OffertypeStorageNavComponent,
    OffertypeNavComponent,
  ],
  templateUrl: "./offer-list-view.component.pug",
  styleUrls: ["./offer-list-view.component.sass"],
})
export class OfferListViewComponent extends BaseListViewComponent<
  OfferListViewData,
  Offer
> {
  public user_detail_route?: Link<User>;
  public resto_detail_route?: Link<Resto>;
  public storage_detail_route!: Link<Storage>;
  public client_detail_route!: Link<Client>;
  public offertype_detail_route?: Link<Offertype>;
  public offertypestorage_detail_route?: Link<OffertypeStorage>;
  public offertypestorage_offer_detail_route?: Link<Offer>;
  public filter$!: Observable<FilterDefaults>;
  public create = false;
  public default_fields: string[] = [
    "id",
    "title",
    "storage_details",
    "client_details",
    "author_details",
    "datestart",
    "dateend",
    "products_count",
    "offerrestos_details",
    "status",
  ];
  public canCreate = signal(false);
  public user = inject(AuthService).currentUser;

  public override setRouteData(data: OfferListViewData) {
    super.setRouteData(data);
    this.storage_detail_route = new Link(
      data.storage_detail_route,
      data,
      this._router,
    );
    this.client_detail_route = new Link(
      data.client_detail_route,
      data,
      this._router,
    );
    if (data.user_detail_route) {
      this.user_detail_route = new Link(
        data.user_detail_route,
        data,
        this._router,
      );
    }
    if (data.resto_detail_route) {
      this.resto_detail_route = new Link(
        data.resto_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertype_detail_route) {
      this.offertype_detail_route = new Link(
        data.offertype_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertypestorage_detail_route) {
      this.offertypestorage_detail_route = new Link(
        data.offertypestorage_detail_route,
        data,
        this._router,
      );
    }
    if (data.offertypestorage_offer_detail_route) {
      this.offertypestorage_offer_detail_route = new Link(
        data.offertypestorage_offer_detail_route,
        data,
        this._router,
      );
    }
    // Allow creation if user is admin, has write access to offertypestorage or is a member
    if (
      data.offertype?.have_level(OFFERTYPE_USER_LEVEL.admin) ||
      data.offertypestorage?.have_level(OFFERTYPE_STORAGE_USER_LEVEL.write) ||
      (!data.offertype &&
        !data.offertypestorage &&
        this.user?.have_group(LVADG_GROUPS.members))
    ) {
      this.canCreate.set(true);
    }
  }

  public override postNgOnInit() {
    super.postNgOnInit();
    this.filter$ = this.data$.pipe(
      map((data) => {
        const f: FilterDefaults = {
          offertypestorage: data.offertypestorage?.id,
          offertype: data.offertype?.id,
        };
        if (data.zone) {
          f["zone"] = data.zone;
        }
        if (data.free !== undefined) {
          f["free"] = `${data.free}`;
        }
        if (data.type) {
          f["type"] = data.type;
        }
        return f;
      }),
    );
  }
}
