
<ng-container *ngIf="contact">
  <dl class="row">
    <data-dispedit class="col-12" [model]="contact" field="type" mode="dd" [editable]="false">Type</data-dispedit>
    <data-dispedit class="col-4" [model]="contact" field="title" mode="dd">Titre</data-dispedit>
    <data-dispedit class="col-4" [model]="contact" field="last_name" mode="dd">Nom</data-dispedit>
    <data-dispedit class="col-4" [model]="contact" field="first_name" mode="dd">Prénom</data-dispedit>
    <data-dispedit class="col-12" [model]="contact" field="jobname" mode="dd">Poste</data-dispedit>
    <data-dispedit class="col-12" [model]="contact" field="email" mode="dd">Email</data-dispedit>
    <data-dispedit class="col-12" [model]="contact" field="phone" mode="dd">Téléphone</data-dispedit>
  </dl>
</ng-container>