import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDisplayComponent } from '../../../users/user/user-display/user-display.component';
import { GroupDisplayComponent } from '../../../users/group/group-display/group-display.component';
import { Link, PChoicePipe } from '@solidev/data';
import { OffertypeStoragePermission } from '../offertype-storage-permission';
import { UserBase } from '../../../users/user/user.base';
import { GroupBase } from '../../../users/group/group.base';

@Component({
  selector: 'lvadg-offertype-storage-permission-display',
  standalone: true,
  imports: [CommonModule, UserDisplayComponent, GroupDisplayComponent, PChoicePipe],
  templateUrl: './offertype-storage-permission-display.component.pug',
  styleUrls: ['./offertype-storage-permission-display.component.sass'],
})
export class OffertypeStoragePermissionDisplayComponent {
  @Input() public permission?: OffertypeStoragePermission;
  @Input() public mode = 'line' as const;
  @Input() public detail_route?: Link<OffertypeStoragePermission>;
  @Input() public user_detail_route?: Link<UserBase>;
  @Input() public group_detail_route?: Link<GroupBase>;
}
