import { Component } from "@angular/core";
import { ModellistComponent } from "../../../../includes/modellist/modellist.component";
import { SupportMessage } from "../support-message";
import { SupportMessageService } from "../support-message.service";
import {
  FiltersParams,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
  PChoicePipe,
} from "@solidev/data";
import { MODELLIST_IMPORTS } from "../../../../includes/modellist/modellist.imports";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { UserDisplayComponent } from "../../../users/user/user-display/user-display.component";
import {
  SUPPORT_MESSAGE_PRIORITY,
  SUPPORT_MESSAGE_STATUS,
} from "../support-message.base";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "lvadg-support-message-list",
  standalone: true,
  templateUrl: "./support-message-list.component.pug",
  styleUrls: ["./support-message-list.component.sass"],
  imports: [...MODELLIST_IMPORTS, UserDisplayComponent, PChoicePipe],
})
export class SupportMessageListComponent extends ModellistComponent<SupportMessage> {
  constructor(
    coll: SupportMessageService,
    list: ModelListService,
    ofc: NgbOffcanvas,
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ["search", "statuses", "status"],
      filters: [
        new ModelListTextFilter({
          field: "search",
          name: "search",
          label: "Recherche par texte",
        }),
        new ModelListSelectFilter({
          field: "statuses",
          name: "statuses",
          label: "Filtrer par état courant",
          choices: [
            {
              desc: "En cours",
              value: [
                SUPPORT_MESSAGE_STATUS.OPEN,
                SUPPORT_MESSAGE_STATUS.WIP,
              ].join(","),
            },
            {
              desc: "Terminé",
              value: [
                SUPPORT_MESSAGE_STATUS.TESTING,
                SUPPORT_MESSAGE_STATUS.DONE,
              ].join(","),
            },
            {
              desc: "Archivé",
              value: [SUPPORT_MESSAGE_STATUS.ARCHIVE].join(","),
            },
          ],
        }),
        new ModelListSelectFilter({
          field: "status",
          name: "status",
          label: "Filtrer par état",
          model: SupportMessage,
        }),
        new ModelListSelectFilter({
          field: "priority",
          name: "priority",
          label: "Filtrer par priorité",
          model: SupportMessage,
        }),
      ],
    };
  }

  public override getRowClasses(row: SupportMessage): string[] {
    const cls = super.getRowClasses(row);
    if (row.status === SUPPORT_MESSAGE_STATUS.ARCHIVE) {
      cls.push("text-muted");
    }
    if (row.priority === SUPPORT_MESSAGE_PRIORITY.CRITICAL) {
      cls.push("table-danger");
    }
    if (row.priority === SUPPORT_MESSAGE_PRIORITY.HIGH) {
      cls.push("table-warning");
    }
    if (row.status === SUPPORT_MESSAGE_STATUS.TESTING) {
      cls.push("text-info");
    }
    if (row.status === SUPPORT_MESSAGE_STATUS.DONE) {
      cls.push("text-success");
    }
    if (row.status === SUPPORT_MESSAGE_STATUS.WIP) {
      cls.push("table-info");
    }

    return cls;
  }

  public async createGitlab(row: SupportMessage) {
    if (row.gitlab_issue_id) {
      return;
    }
    await firstValueFrom(
      this.coll.action(row, "POST", "action", {
        body: '"create_issue"',
        headers: { "Content-Type": "application/json" },
      }),
    );
    this.reload?.next(true);
  }
}
