import { Component } from '@angular/core';
import { ModellistComponent } from '../../../../includes/modellist/modellist.component';
import { Rnmmarche } from '../rnmmarche';
import { RnmmarcheService } from '../rnmmarche.service';
import {
  FiltersParams,
  ModelListSelectFilter,
  ModelListService,
  ModelListTextFilter,
} from '@solidev/data';
import { MODELLIST_IMPORTS } from '../../../../includes/modellist/modellist.imports';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { RnmmarcheBase } from '../rnmmarche.base';
import { RnmmarcheManageComponent } from '../rnmmarche-manage/rnmmarche-manage.component';

@Component({
  selector: 'lvadg-rnmmarche-list',
  standalone: true,
  templateUrl: './rnmmarche-list.component.pug',
  styleUrls: ['./rnmmarche-list.component.sass'],
  imports: [...MODELLIST_IMPORTS, RnmmarcheManageComponent],
})
export class RnmmarcheListComponent extends ModellistComponent<Rnmmarche> {
  constructor(
    coll: RnmmarcheService,
    list: ModelListService,
    ofc: NgbOffcanvas
  ) {
    super(coll, list, ofc);
  }

  public override getFilters(): FiltersParams {
    return {
      defaults: this.default_filters ||
        this.filters?.defaults || ['search', 'type'],
      filters: [
        new ModelListTextFilter({
          field: 'search',
          name: 'search',
          label: 'Recherche par texte',
        }),
        new ModelListSelectFilter({
          field: 'type',
          name: 'type',
          model: RnmmarcheBase,
          mfield: 'type',
          label: 'Filtrer par type',
        }),
      ],
    };
  }
}
